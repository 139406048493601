import React from 'react';
import PropTypes from 'prop-types';

import { TranslatorComponent_Text as Translator } from 'ggApp/modules/translator';

import { OldPrice, PlanPriceText, Price, Suffixes } from './styled';

const PriceBlock = ({
    oldPrice,
    price,
    selectedMinDuration,
    accentColor,
    textColor,
    activeLanguage,
    handpicked,
}) => {
    if (!price) return null;
    return (
        <PlanPriceText handpicked={handpicked} textColor={textColor}>
            {selectedMinDuration < 1 && <Translator tk="PRODUCT_CARD_COST_PREFIX" />}
            {oldPrice && <OldPrice handpicked={handpicked}>{oldPrice}</OldPrice>}
            <Price
                accentColor={accentColor}
                withOldPrice={!!oldPrice}
                handpicked={handpicked}
                textColor={textColor}
            >
                {price}
            </Price>
            <Suffixes en={activeLanguage === 'en'}>
                {selectedMinDuration <= 1 ? (
                    <Translator tk="PRODUCT_CARD_PLAN_PERIOD_LABEL_SHORT" />
                ) : (
                    <Translator
                        withValues={{ duration: selectedMinDuration }}
                        tk="PRODUCT_CARD_PLAN_PERIOD_LABEL"
                    />
                )}
            </Suffixes>
        </PlanPriceText>
    );
};

PriceBlock.propTypes = {
    oldPrice: PropTypes.string,
    price: PropTypes.string,
    accentColor: PropTypes.string,
    textColor: PropTypes.string,
    selectedMinDuration: PropTypes.number,
    handpicked: PropTypes.bool,
};

PriceBlock.defaultProps = {
    oldPrice: '',
    price: undefined,
    accentColor: '',
    textColor: '',
    selectedMinDuration: 0,
    handpicked: false,
};

export default PriceBlock;

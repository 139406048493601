import objectPath from 'object-path';

import ggAdyen from 'ggApp/modules/adyen';

const { ADYEN_PAYMENT, ADYEN_PAYMENT_CC, ADYEN_PAYMENT_SEPA } = ggAdyen.constants;
export const PAYMENT_SERVICE_METHOD_TYPE = 'Payments::UserPaymentMethod';

// TODO: extract this knowledge about type to sdk
// so it lives there until old crap deleted
const mapPaymentServicePaymentMethod = (paymentMethod) => {
    const { id, meta, type, flow_type: flowType } = paymentMethod;
    const {
        credit_card_expr_year: expirationYear,
        credit_card_expr_month: expirationMonth,
        credit_card_last_4_digits: cardNumberLast4Digits,
        account_name: accountName,
        mandate_id: mandateId,
        iban_last_2_digits: IbanLast2Digits,
        iban_last_4_digits: IbanLast4Digits,
        iban_first_4_digits: IbanFirst4Digits,
        bank_name: bankName,
    } = meta;

    switch (flowType) {
        case 'redirect': {
            if (mandateId) {
                const IbanLastDigits = IbanLast4Digits || IbanLast2Digits;
                return {
                    id,
                    type,
                    name: 'SEPA',
                    description: [bankName, `IBAN: ${IbanFirst4Digits}****${IbanLastDigits}`],
                };
            }
            return {
                id,
                type,
                name: 'PayPal',
                description: [`Account name: ${accountName}`],
            };
        }
        case 'credit-card':
            return {
                id,
                type,
                name: `Credit card / Debit card **** ${cardNumberLast4Digits}`,
                description: [`Expiration date: ${expirationMonth}/${expirationYear}`],
            };
        default:
            return paymentMethod;
    }
};

function adyenCC(p) {
    const cardData = p || {};

    return {
        id: cardData.id,
        type: cardData.type,
        name: `Credit card / Debit card **** ${cardData.number}`,
        description: [`Expiration date: ${cardData.expiry_month}/${cardData.expiry_year}`],
    };
}

function adyenSepa(p) {
    const sepaData = p || {};

    return {
        id: sepaData.id,
        type: sepaData.type,
        name: 'SEPA Direct Debit',
        description: [`IBAN: ${sepaData.iban}`, `${sepaData.owner_name}`],
    };
}

function creditcard(p) {
    return {
        id: p.id,
        type: p.type,
        name: `Credit card / Debit card **** ${p.last_digits}`,
        description: [`Expiration date: ${p.expiry}`],
    };
}

function paypal(p) {
    return {
        id: p.id,
        type: p.type,
        name: 'Paypal',
        description: [`Account: ${p.payer_email}`],
    };
}

function detectAdyenPayment(p) {
    const adyenPaymentType = objectPath.get(p, 'contract_type', '');

    switch (adyenPaymentType) {
        case ADYEN_PAYMENT_CC:
            return adyenCC(p);

        case ADYEN_PAYMENT_SEPA:
            return adyenSepa(p);

        default:
            return p;
    }
}

export function detectPayment(paymentMethod) {
    const { type } = paymentMethod;
    if (type === 'CreditCard') {
        return creditcard(paymentMethod);
    }
    if (type === 'PaypalExpressCheckout') {
        return paypal(paymentMethod);
    }
    if (type === ADYEN_PAYMENT) {
        return detectAdyenPayment(paymentMethod);
    }
    if (type === PAYMENT_SERVICE_METHOD_TYPE) {
        return mapPaymentServicePaymentMethod(paymentMethod);
    }

    /**
     * Grover API might send the exact structure that components use:
     * {
     *   id: [number],
     *   type: [string],
     *   name: [string],
     *   description: [string]
     * }
     *
     * This is how PayPal support is added
     * TODO: remove this functionality
     *  */
    return paymentMethod;
}

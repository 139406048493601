import { useEffect, useState } from 'react';

const useDetectChangeLanguage = (): string | null => {
    const [htmlLang, setHtmlLang] = useState<string | null>(null);

    useEffect(() => {
        setHtmlLang(document.documentElement.getAttribute('lang'));

        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'lang') {
                    setHtmlLang(document.documentElement.getAttribute('lang'));
                }
            });
        });

        observer.observe(document.documentElement, {
            attributes: true,
        });

        return (): void => {
            observer.disconnect();
        };
    }, []);

    return htmlLang;
};

export default useDetectChangeLanguage;

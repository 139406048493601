/* eslint-disable */
import { CURRENCIES_ASSOCIATION } from 'ggApp/modules/store/constants';
import { detectPayment } from 'ggApp/utils/detectPayment';
import {
    GET_SUBSCRIPTIONS,
    GET_SUBSCRIPTIONS_SUCCESS,
    GET_SUBSCRIPTIONS_ERROR,
    GET_SUBSCRIPTION_SUCCESS,
    UPDATE_SUBSCRIPTION_PAYMENT_METHOD,
    UPDATE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS,
    UPDATE_SUBSCRIPTION_PAYMENT_METHOD_ERROR,
    PURCHASE_SUBSCRIPTION,
    PURCHASE_SUBSCRIPTION_SUCCESS,
    PURCHASE_SUBSCRIPTION_ERROR,
    UPDATE_SUBSCRIPTION_RENTAL_PLAN,
    UPDATE_SUBSCRIPTION_RENTAL_PLAN_ERROR,
    UPDATE_SUBSCRIPTION_RENTAL_PLAN_SUCCESS,
    SET_SUBSCRIPTION_SYNCING_STATE,
    UPDATE_SUBSCRIPTION_DATA,
} from '../actionTypes';

import { actionsByStatus } from '../associations';

const defaultState = {
    loading: null,
    error: {},
    data: [],
};

function getAdditionalData(subscriptionData) {
    return {
        sourceEdited: detectPayment(subscriptionData.source || {}),
        currencySymbol:
            CURRENCIES_ASSOCIATION[subscriptionData.currency] || '€',
        ui: actionsByStatus(subscriptionData),
        isSyncing: false,
    };
}

export default function (state = defaultState, action) {
    switch (action.type) {
    case GET_SUBSCRIPTIONS:
    case UPDATE_SUBSCRIPTION_PAYMENT_METHOD:
    case PURCHASE_SUBSCRIPTION:
    case UPDATE_SUBSCRIPTION_RENTAL_PLAN:
        return {
            ...state,
            loading: true,
            error: {},
        };

    case GET_SUBSCRIPTIONS_SUCCESS:
        return {
            ...state,
            loading: false,
            data: (action.data || []).reverse().map((subscription) => ({
                ...subscription,
                ...getAdditionalData(subscription),
            })),
        };

        case GET_SUBSCRIPTION_SUCCESS:
        return {
            ...state,
            loading: false,
            data: state.data.length > 0
                ? [
                    ...state.data.filter(s => s.sf_id !== action.data.sf_id),
                    action.data
                ].map(
                    (subscription) => (
                        subscription.sf_id === action.data.sf_id
                            ? {
                                ...action.data,
                                ...getAdditionalData(action.data),
                            } : subscription
                    ),
                )
                : [action.data],
        };

    case UPDATE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS:
    case PURCHASE_SUBSCRIPTION_SUCCESS:
    case UPDATE_SUBSCRIPTION_RENTAL_PLAN_SUCCESS:
        case UPDATE_SUBSCRIPTION_DATA:
        return {
            ...state,
            loading: false,
            data: state.data.map((subscription) => (subscription.sf_id === action.data.sf_id
                ? {
                    ...action.data,
                    ...getAdditionalData(action.data),
                }
                : subscription)),
        };

    case GET_SUBSCRIPTIONS_ERROR:
    case UPDATE_SUBSCRIPTION_PAYMENT_METHOD_ERROR:
    case PURCHASE_SUBSCRIPTION_ERROR:
    case UPDATE_SUBSCRIPTION_RENTAL_PLAN_ERROR:
        return {
            ...state,
            loading: false,
            error: action.error,
        };

    case SET_SUBSCRIPTION_SYNCING_STATE:
        return {
            ...state,
            data: state.data.map((subscription) => (subscription.sf_id === action.subscriptionId
                ? {
                    ...subscription,
                    isSyncing: true,
                }
                : subscription)),
        };

    default:
        return state;
    }
}

import React from 'react';
import { IconPropTypes } from 'ggApp/types/propTypes';

const Small = ({ className, color, size }) => (
    <svg
        className={className}
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M11.4385 12.7305C10.4231 11.7565 8.7769 11.7565 7.76152 12.7305C6.74616 13.7049 6.74616 15.2841 7.76152 16.2584L11.4385 12.7305ZM20 24.4721L18.1616 26.2362L20 28L21.8385 26.2362L20 24.4721ZM32.2385 16.2584C33.2538 15.2841 33.2538 13.7049 32.2385 12.7305C31.2232 11.7565 29.5769 11.7565 28.5616 12.7305L32.2385 16.2584ZM7.76152 16.2584L18.1616 26.2362L21.8385 22.7083L11.4385 12.7305L7.76152 16.2584ZM21.8385 26.2362L32.2385 16.2584L28.5616 12.7305L18.1616 22.7083L21.8385 26.2362Z" />
    </svg>
);

Small.propTypes = {
    ...IconPropTypes,
};

Small.defaultProps = {
    className: '',
    color: '#333333',
    size: '24px',
};

export default Small;

import objectPath from 'object-path';

import * as at from './actionTypes';
import * as api from './api';
import { ADYEN_PAYMENT_CC, ADYEN_PAYMENT_SEPA } from './constants';

export function getPaymentGateways(orderNumber) {
    return (dispatch) => {
        dispatch({ type: at.GET_PAYMENT_GATEWAYS });
        return api.paymentGatewayList_get(orderNumber).then(
            (result) => {
                const data = objectPath.get(result, 'data.paymentMethods', []);

                dispatch({
                    type: at.GET_PAYMENT_GATEWAYS_SUCCESS,
                    data,
                });
                return data;
            },
            ({ response: error }) => {
                dispatch({
                    type: at.GET_PAYMENT_GATEWAYS_ERROR,
                    error: error.data,
                });
            },
        );
    };
}

export function getPaymentList() {
    return (dispatch) => {
        dispatch({ type: at.GET_PAYMENT_LIST });
        return api.paymentList_get().then(
            (result) => {
                const data = objectPath.get(result, 'data.paymentMethods', []);

                dispatch({
                    type: at.GET_PAYMENT_LIST_SUCCESS,
                    data,
                });
                return data;
            },
            ({ response: error }) => {
                dispatch({
                    type: at.GET_PAYMENT_LIST_ERROR,
                    error: error.data,
                });
            },
        );
    };
}

function generatePaymentGatewayRequest(paymentType, data, options) {
    switch (paymentType) {
        case ADYEN_PAYMENT_CC: {
            const { encryptedCard } = data;
            return api.submitCreditCard_post({ paymentType, encryptedCard }, options);
        }

        case ADYEN_PAYMENT_SEPA: {
            const { iban, holderName, countryCode, agreementAccepted } = data;
            return api.submitPayment_post(
                {
                    paymentType,
                    iban,
                    holderName,
                    countryCode,
                    agreementAccepted,
                },
                options,
            );
        }

        default:
            return null;
    }
}

export function submitPayment(paymentType, data, orderNumber, delayTime = 3000) {
    return (dispatch) => {
        const delay = (result) =>
            new Promise((resolve) => setTimeout(() => resolve(result), delayTime));
        const requestOptions = orderNumber
            ? {
                  headers: {
                      'X-Grover-Order-Number': orderNumber,
                  },
              }
            : {};

        dispatch({ type: at.SUBMIT_PAYMENT });
        return generatePaymentGatewayRequest(paymentType, data, requestOptions)
            .then((result) => delay(result))
            .then((result) => {
                const resultData = objectPath.get(result, 'data', {});

                dispatch({
                    type: at.SUBMIT_PAYMENT_SUCCESS,
                    data: resultData,
                });

                return resultData;
            })
            .catch(({ response: error = {} }) => {
                dispatch({
                    type: at.SUBMIT_PAYMENT_ERROR,
                    error: error.data,
                });
            });
    };
}

export function savePaymentData(paymentType, data) {
    return {
        type: at.SAVE_PAYMENT_DATA,
        paymentType,
        data,
    };
}

export function resetSubmittedPaymentError() {
    return {
        type: at.RESET_SUBMITTED_PAYMENT_ERROR,
    };
}

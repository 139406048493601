export const MODULE_NAME = 'store';

export const CURRENCIES_ASSOCIATION = {
    EUR: '€',
    USD: '$',
    GBP: '£',
};

export const STORE_TYPE = {
    BUSINESS: 'business',
    MAIN: 'main',
    GROVER_PAY: 'grover_pay',
    RETAIL: 'retail',
};

export const languageTks = {
    en: 'LANGUAGE_PICKER_EN',
    de: 'LANGUAGE_PICKER_DE',
    nl: 'LANGUAGE_PICKER_NL',
    es: 'LANGUAGE_PICKER_ES',
};

export const storeTks = {
    de: 'STORE_PICKER_DE',
    at: 'STORE_PICKER_AT',
    nl: 'STORE_PICKER_NL',
    es: 'STORE_PICKER_ES',
    us: 'STORE_PICKER_US',
    business: 'STORE_PICKER_B2B_DE',
    business_at: 'STORE_PICKER_B2B_AT',
    business_nl: 'STORE_PICKER_B2B_NL',
    business_es: 'STORE_PICKER_B2B_ES',
    business_us: 'STORE_PICKER_B2B_US',
};

export const storeDictionary = {
    en: {
        at: 'Austria',
        de: 'Germany',
        nl: 'Netherlands',
        es: 'Spain',
    },
    de: {
        at: 'Österreich',
        de: 'Deutschland',
        nl: 'Niederlande',
        es: 'Spanien',
    },
    nl: {
        at: 'Oostenrijk',
        de: 'Duitsland',
        nl: 'Nederland',
        es: 'Spanje',
    },
    es: {
        at: 'Austria',
        de: 'Alemania',
        nl: 'Países Bajos',
        es: 'España',
    },
    us: {
        at: 'Vereinigte Staaten',
        de: 'Vereinigte Staaten',
        nl: 'Verenigde Staten',
        es: 'Estados Unidos',
    },
};

export const validStoreCountries = [
    'AT',
    'DE',
    'ES',
    'NL',
    'US',
    'BUSINESS',
    'BUSINESS_AT',
    'BUSINESS_ES',
    'BUSINESS_NL',
    'BUSINESS_US',
];

export const defaultLanguageByCountry = {
    AT: 'de',
    DE: 'de',
    ES: 'es',
    NL: 'nl',
    US: 'en',
};

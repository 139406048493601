import axios from 'ggApp/utils/requests';
import ggForm from 'ggApp/modules/form';
import ggStore from 'ggApp/modules/store';

import ggRouter from 'ggApp/modules/router';
import ggAxiosConfig from 'ggApp/modules/axiosConfig';

import {
    registerAvailabilityOfProduct,
    registerVoucherValidation,
} from 'ggApp/modules/gaAnalytics/actions';

import { orderActive } from 'ggApp/modules/order/selectors';
import { user as userSelector } from 'ggApp/modules/auth/selectors';

import CookiesManager, { injectCookiesToConfig } from 'ggApp/utils/CookiesManager';
import { getOrder as getOrderFromCookies, deleteOrder } from '../storage';
import * as at from '../actionTypes';

const isServer = __IS_SERVER__;

export function resetActiveOrderToDefaultState() {
    return { type: at.RESET_ORDER_TO_DEFAULT_STATE };
}

/**
 * handleOrderResponse
 * This function will clear active order data
 * if will get 204 code from the server
 * and return action payload with the order in the other case.
 * @param {*} dispatch
 * @param {*} response
 * @returns action payload
 */
function handleOrderResponse(dispatch, response) {
    if (response.status === 204) {
        dispatch(resetActiveOrderToDefaultState());
    }

    return {
        order: response.data.order || {},
    };
}

export function getOrders() {
    return (dispatch) => {
        dispatch({ type: at.GET_ORDERS });
        return axios
            .get('/orders')
            .then((result) => {
                dispatch({
                    type: at.GET_ORDERS_SUCCESS,
                    data: result.data.orders,
                });
            })
            .catch((error) => {
                if (error.response) {
                    dispatch({
                        type: at.GET_ORDERS_ERROR,
                        error: error.response,
                    });
                }
            });
    };
}

export function cancelOrder(values, orderNumber) {
    return (dispatch) => {
        dispatch({ type: at.CANCEL_ORDER });
        return axios
            .patch(`/orders/${orderNumber}/cancel`, {
                password: values.password,
            })
            .then(
                (result) => {
                    dispatch({
                        type: at.CANCEL_ORDER_SUCCESS,
                        data: result.data.order,
                    });
                    return result;
                },
                ({ response: error }) => {
                    if (error.data) {
                        dispatch({
                            type: at.CANCEL_ORDER_ERROR,
                            error: error.data,
                        });
                        ggForm.validations.serverValidation(error);
                    }
                },
            );
    };
}

export function getOrder(order = null, req) {
    const cookiesManager = new CookiesManager(req);
    let requestConfig = { cookiesManager };
    return (dispatch, getState) => {
        const { code: storeCode } = ggStore.selectors.storeActive(getState());
        const { orderNumber, orderToken } = getOrderFromCookies(storeCode, cookiesManager);

        if (typeof orderNumber === 'string' && typeof orderToken === 'string') {
            axios.defaults.headers['X-Grover-Order-Number'] = orderNumber;
            axios.defaults.headers['X-Grover-Order-Token'] = orderToken;
        }

        const currentOrderNumber = order || orderNumber;
        if (!currentOrderNumber) {
            dispatch({
                type: 'GET_ORDER_SUCCESS',
                data: {
                    order: {},
                },
            });

            dispatch(ggRouter.actions.transitionTo('/cart'));
            return false;
        }

        if (isServer) {
            requestConfig = ggAxiosConfig.selectors.config(getState());
            injectCookiesToConfig(cookiesManager, requestConfig);
        }

        return dispatch({
            type: 'GET_ORDER',
            promise: axios.get(`/orders/${currentOrderNumber}`, requestConfig).then(
                (res) => ({
                    order: res.data.order,
                }),
                ({ response: error }) => {
                    if (error.status === 400 || error.status === 401) {
                        return {
                            order: {},
                        };
                    }
                    return Promise.reject(error);
                },
            ),
        });
    };
}

export function updateOrder(order) {
    return {
        type: 'UPDATE_ORDER_SUCCESS',
        data: { order },
    };
}

export function createOrderLineItem(orderNumber, variantId, quantity) {
    return (dispatch) =>
        dispatch({
            type: 'UPDATE_ORDER',
            promise: axios
                .post(`/orders/${orderNumber}/line_items`, {
                    variantId,
                    quantity,
                })
                .then((res) => ({
                    order: res.data.order,
                })),
        });
}

export function addToCartSuccess({ productId, cart }) {
    return { type: at.ADD_TO_CART_SUCCESS, productId, cart };
}

export function removeFromCartSuccess({ product, quantity, cart, orderNumber }) {
    return { type: at.REMOVE_FROM_CART_SUCCESS, product, quantity, cart, orderNumber };
}

export function updateOrderStepSuccess(order, orderStep) {
    const { step } = orderStep;
    return { type: at.UPDATE_ORDER_STEP_SUCCESS, step, order };
}

export function purchaseSuccess(order) {
    return { type: at.PURCHASE_SUCCESS, order };
}

export function updateOrderOptionSuccess(order, step, option) {
    return {
        type: at.UPDATE_ORDER_OPTION_SUCCESS,
        step,
        order,
        option,
    };
}

export function changeOrderLineItem(orderNumber, itemId, quantity) {
    return (dispatch) =>
        dispatch({
            type: 'UPDATE_ORDER',
            promise: axios
                .patch(`/orders/${orderNumber}/line_items/${itemId}`, {
                    quantity,
                })
                .then((response) => handleOrderResponse(dispatch, response)),
        });
}

export function removeOrderLineItem(orderNumber, itemId) {
    return (dispatch) =>
        dispatch({
            type: 'UPDATE_ORDER',
            method: 'delete',
            promise: axios
                .delete(`/orders/${orderNumber}/line_items/${itemId}`)
                .then((response) => handleOrderResponse(dispatch, response)),
        });
}

export function silentRemoveOrderLineItem(itemId) {
    return (dispatch, getState) => {
        const orderNumber = orderActive(getState()).number;
        return axios.delete(`/orders/${orderNumber}/line_items/${itemId}`).then((response) => ({
            order: response.data.order || {},
        }));
    };
}

export function updateOrderPlan(orderNumber, planId) {
    return (dispatch) =>
        dispatch({
            type: 'UPDATE_ORDER',
            promise: axios.patch(`/orders/${orderNumber}/subscribe`, { planId }).then((res) => ({
                order: res.data.order,
            })),
        });
}

export function updateOrderAddress(orderNumber, addressId, addressType) {
    return (dispatch, getState) =>
        dispatch({
            type: 'UPDATE_ORDER',
            promise: axios
                .patch(`/orders/${orderNumber}/address`, {
                    [`${addressType.toLowerCase()}_address_id`]: addressId,
                })
                .then((res) => {
                    dispatch(
                        updateOrderOptionSuccess(
                            orderActive(getState()),
                            'address',
                            addressType.toLowerCase(),
                        ),
                    );
                    return { order: res.data.order };
                }),
        });
}

export function updateOrderPaymentMethod(orderNumber, paymentMethodId, paymentMethodType) {
    return (dispatch, getState) =>
        dispatch({
            type: 'UPDATE_ORDER',
            promise: axios
                .patch(`/orders/${orderNumber}/payment_method`, {
                    payment_method_id: paymentMethodId,
                    payment_method_type: paymentMethodType,
                })
                .then((res) => {
                    dispatch(
                        updateOrderOptionSuccess(
                            orderActive(getState()),
                            'payment',
                            paymentMethodType,
                        ),
                    );
                    return { order: res.data.order };
                }),
        });
}

export function submitVoucher(orderNumber, voucher) {
    return (dispatch, getState) => {
        const { id: userID } = userSelector(getState());
        dispatch({ type: 'UPDATE_ORDER' });
        return axios
            .patch(`/orders/${orderNumber}/apply_coupon`, { coupon_code: voucher })
            .then((res) => {
                dispatch(
                    registerVoucherValidation({
                        eventLabel: 'valid',
                        orderNumber,
                        userID,
                        voucher,
                    }),
                );
                dispatch(updateOrder(res.data.order));
            })
            .catch((error) => {
                dispatch(
                    registerVoucherValidation({
                        eventLabel: 'not_valid',
                        orderNumber,
                        userID,
                        voucher,
                        error:
                            error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.base,
                    }),
                );
                ggForm.validations.serverValidation(error);
            });
    };
}

export function resetVoucher(orderNumber) {
    return (dispatch) =>
        dispatch({
            type: 'UPDATE_ORDER',
            promise: axios.patch(`/orders/${orderNumber}/reset_coupon`).then((res) => ({
                order: res.data.order,
            })),
        });
}

export function completeOrder(orderNumber, checkoutAttemptReference) {
    return (dispatch, getState) => {
        const { code: storeCode } = ggStore.selectors.storeActive(getState());
        const user = userSelector(getState()) || {};
        const order = orderActive(getState());

        const payload = {
            id: order.number,
            order_id: order.number,
            meta: { attempt_reference: checkoutAttemptReference },
        };

        return dispatch({
            type: 'COMPLETE_ORDER',
            promise: axios
                .patch(`/orders/${orderNumber}/complete`, payload)
                .then(() => {
                    deleteOrder(storeCode);
                    dispatch(ggRouter.actions.mainTransition(`/confirmation/${orderNumber}`, true));
                    dispatch(updateOrderStepSuccess(order, { step: 'complete' }));
                    dispatch(purchaseSuccess(order));
                })
                .catch((error) => {
                    try {
                        const skuList = error.response.data.base;
                        if (Array.isArray(skuList)) {
                            registerAvailabilityOfProduct('enter', {
                                productSKU: skuList,
                                userID: user.id,
                                orderID: orderNumber,
                            });
                            dispatch({
                                type: 'UPDATE_UNAVAILABLE_SKU_LIST',
                                data: skuList,
                            });
                            dispatch(ggRouter.actions.transitionTo('/cart'));
                        }

                        throw error;
                    } catch (e) {
                        const OrderError = new Error('Order complete failed');
                        OrderError.childError = e;
                        throw OrderError;
                    }
                }),
        });
    };
}

export function updateOrderStepState(orderNumber, orderStep) {
    const { step } = orderStep;
    return (dispatch) =>
        dispatch({
            type: 'UPDATE_ORDER',
            promise: axios.patch(`/orders/${orderNumber}`, { step }).then((res) => ({
                order: res.data.order,
            })),
        });
}

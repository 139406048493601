import React from 'react';
import PropTypes from 'prop-types';
import { Svg, Path } from 'react-native-svg';

const GroverTransparentLogo = ({ width, height, color, className }) => (
    <Svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 298 85"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M159.6 32.0997V24.9997C159.6 24.5997 159.2 24.1997 158.8 24.2997C153.1 24.4997 149.7 26.9997 147.8 29.0997C143 34.2997 143.3 41.4997 143.3 43.2997V58.9997C143.3 59.3997 143.6 59.6997 144 59.6997H151.1C151.5 59.6997 151.8 59.3997 151.8 58.9997V42.8997C151.8 42.8997 151.5 37.6997 154 34.8997C155.1 33.6997 156.7 32.9997 158.8 32.8997C159.2 32.8997 159.6 32.4997 159.6 32.0997Z"
            fill={color}
        />
        <Path
            d="M291.2 32.0997V24.9997C291.2 24.5997 290.8 24.1997 290.4 24.2997C284.7 24.4997 281.3 26.9997 279.4 29.0997C274.6 34.2997 274.9 41.4997 274.9 43.2997V58.9997C274.9 59.3997 275.2 59.6997 275.6 59.6997H282.7C283.1 59.6997 283.4 59.3997 283.4 58.9997V42.8997C283.4 42.8997 283.1 37.6997 285.6 34.8997C286.7 33.6997 288.3 32.9997 290.4 32.8997C290.9 32.8997 291.2 32.4997 291.2 32.0997Z"
            fill={color}
        />
        <Path
            d="M46.7001 64.5999C33.9001 64.5999 23.6001 54.1999 23.6001 41.4999C23.6001 28.7999 34.0001 18.2999 46.7001 18.2999C59.5001 18.2999 69.8001 28.6999 69.8001 41.3999C69.8001 54.0999 59.5001 64.5999 46.7001 64.5999ZM81.1001 20.7999C80.2001 17.3999 78.7001 14.1999 76.4001 11.7999C74.0001 9.49992 70.8001 8.09992 67.4001 7.09992C58.5001 4.59992 48.2001 4.79992 39.2001 6.79992C30.1001 8.89992 23.5001 14.7999 18.1001 22.1999C13.1001 29.0999 8.4001 37.5999 7.7001 46.1999C7.0001 55.1999 11.5001 63.8999 18.0001 70.1999C24.3001 76.6999 32.9001 81.1999 42.0001 80.4999C50.6001 79.7999 59.1001 75.0999 66.0001 70.0999C73.4001 64.6999 79.3001 58.1999 81.4001 49.0999C83.4001 39.9999 83.6001 29.6999 81.1001 20.7999Z"
            fill={color}
        />
        <Path
            d="M227.4 24.9998L215.8 50.3998L204.1 24.9998C203.9 24.5998 203.5 24.3998 203.1 24.3998H196.3C195.8 24.3998 195.4 24.9998 195.6 25.3998L211.1 59.2998C211.2 59.5998 211.5 59.6998 211.8 59.6998H219.7C220 59.6998 220.3 59.4998 220.4 59.2998L235.9 25.3998C236.1 24.8998 235.8 24.3998 235.2 24.3998H228.4C227.9 24.2998 227.5 24.5998 227.4 24.9998Z"
            fill={color}
        />
        <Path
            d="M242.9 37.6998L243 37.4998C244.8 33.7998 248.6 31.2998 252.7 31.2998C256.8 31.2998 260.7 33.6998 262.4 37.4998L262.5 37.6998H242.9ZM251.7 23.4998C242.2 23.9998 234.6 31.6998 234.1 41.0998C233.5 51.7998 242.1 60.7998 252.7 60.7998C258.6 60.7998 264.2 57.9998 267.7 53.2998C268 52.9998 267.9 52.4998 267.5 52.1998L262.1 48.6998C261.8 48.4998 261.4 48.5998 261.1 48.8998C259.1 51.3998 256 52.8998 252.7 52.8998C248 52.8998 243.9 49.8998 242.4 45.3998L242.3 45.1998H263H270.5C270.9 45.1998 271.2 44.8998 271.2 44.4998C271.3 43.6998 271.4 42.8998 271.4 42.0998C271.4 31.4998 262.5 22.8998 251.7 23.4998Z"
            fill={color}
        />
        <Path
            d="M178.7 52.7999C172.8 52.7999 168 47.9999 168 42.0999C168 36.1999 172.8 31.3999 178.7 31.3999C184.6 31.3999 189.4 36.1999 189.4 42.0999C189.5 47.9999 184.6 52.7999 178.7 52.7999ZM178.7 23.3999C168.4 23.3999 160 31.7999 160 42.0999C160 52.3999 168.4 60.7999 178.7 60.7999C189 60.7999 197.4 52.3999 197.4 42.0999C197.4 31.7999 189 23.3999 178.7 23.3999Z"
            fill={color}
        />
        <Path
            d="M116.7 18.2998C103.9 18.2998 93.5 28.6998 93.5 41.4998C93.5 54.2998 103.9 64.6998 116.7 64.6998C129.5 64.6998 139.9 54.2998 139.9 41.4998C139.9 40.2998 139.8 39.0998 139.6 37.7998C139.5 37.3998 139.2 37.1998 138.9 37.1998H118.4C118 37.1998 117.7 37.4998 117.7 37.8998V44.9998C117.7 45.3998 118 45.6998 118.4 45.6998H130.8L130.7 45.8998C128.7 52.0998 123 56.1998 116.4 56.0998C108.4 55.8998 101.9 49.1998 102.1 41.0998C102.3 33.1998 108.8 26.7998 116.8 26.7998C121.5 26.7998 125.9 28.9998 128.6 32.7998C128.8 33.0998 129.3 33.1998 129.6 32.9998L135.5 29.1998C135.9 28.9998 135.9 28.4998 135.7 28.1998C131.3 21.9998 124.3 18.2998 116.7 18.2998Z"
            fill={color}
        />
    </Svg>
);

GroverTransparentLogo.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    color: PropTypes.string,
    className: PropTypes.string,
};

GroverTransparentLogo.defaultProps = {
    width: 97,
    height: 32,
    color: 'rgba(51, 51, 51, 0.2)',
    className: '',
};

export default GroverTransparentLogo;

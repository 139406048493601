/* eslint-disable */
import { errorToObj } from 'ggApp/utils/requests/errorHandler';

export default function promiseMiddleware() {
    return next => (action) => {
        const {
            promise, callback, errorCallback, type, ...rest
        } = action;

        if (!promise) return next(action);

        const REQUEST = type;
        const SUCCESS = `${type}_SUCCESS`;
        const FAILURE = `${type}_FAILURE`;
        const NOTIFICATION_ADD = 'ADD_NOTIFICATION';

        next({ type: REQUEST });

        return promise
            .then(
                (res) => {
                    next({
                        ...rest,
                        data: res,
                        type: SUCCESS,
                    });

                    if (!callback) return true;
                    callback(res);
                },
                (error) => {
                    // eslint-disable-next-line
                    console.log("PROMISE ERROR ->", type, error, FAILURE);

                    next({ type: FAILURE, data: error });
                    if (error.status === 500) {
                        next({
                            type: NOTIFICATION_ADD,
                            data: errorToObj(error),
                        });
                    }

                    if (!errorCallback) return false;
                    errorCallback(error);
                },
            )
            .catch((error) => {
                if (error instanceof Error) {
                    setTimeout(() => {
                        throw error;
                    });
                }

                // eslint-disable-next-line
                console.log("PROMISE ERROR ->", type);
                // eslint-disable-next-line
                console.log(error);

                next({ type: FAILURE, data: error });
                if (error.status === 500) {
                    next({
                        type: NOTIFICATION_ADD,
                        data: errorToObj(error),
                    });
                }

                if (!errorCallback) return false;
                errorCallback(error);
            });
    };
}

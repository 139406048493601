import styled, { css } from 'styled-components';

import Typography from 'ggApp/shared/components/typography';
import ContentfulResolutionImage from 'ggApp/shared/components/resolutionImage/ContentfulResolutionImage';
import { screens } from 'ggApp/utils/mixins/screens';

export const Wrapper = styled.div`
    width: 100%;
    max-width: 1600px;
    position: relative;
    height: 400px;
    overflow-y: hidden;
    @media (${screens.wideScreen}) {
        height: 500px;
    }

    a {
        text-decoration: none;
    }
`;

export const Grid = styled.div`
    display: flex;
    padding-left: 5%;
    height: 400px;
    background-color: ${({ backgroundColor, theme }) =>
        backgroundColor || `${theme.colors.Background}`};
    @media (${screens.wideScreen}) {
        height: 500px;
    }
`;

export const ContentWrap = styled.div`
    width: 45%;
    display: flex;
    align-items: center;
    background-color: ${({ backgroundColor, theme }) =>
        backgroundColor || `${theme.colors.Background}`};
    @media (${screens.desktop}) {
        width: 36%;
    }
    @media (${screens.wideDesktop}) {
        width: 40%;
    }
    @media (${screens.wideScreen}) {
        width: 45%;
        max-width: 536px;
    }
    @media (min-width: 1900px) {
        width: 30%;
        max-width: 536px;
    }
    @media (${screens.extraWideDesktop}) {
        width: 25%;
        max-width: unset;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 72px;
    max-width: 500px;
    @media (${screens.desktop}) {
        margin-right: unset;
        margin-left: ${({ theme }) => theme.spacers.L};
    }
    @media (${screens.largeDesktop}) {
        margin-right: 72px;
    }
`;

export const Header = styled.h2`
    ${({ theme }) => css(theme.typography.Headline)};
    color: ${({ textColor }) => textColor || '#ffffff'};
`;

export const Body = styled(Typography)`
    margin: 30px 0px;
    ${({ theme }) => css(theme.typography.Paragraph)};
    line-height: 27px !important;
    color: ${({ textColor }) => textColor || '#ffffff'};
    * {
        color: ${({ textColor }) => textColor || '#ffffff'};
    }
`;

export const Banner = styled(ContentfulResolutionImage)`
    width: 55%;
    height: auto;
    > img {
        object-fit: ${(props) => (props.backgroundType === 'solid' ? 'contain' : 'cover')};
    }
    @media (${screens.desktop}) {
        width: 75%;
    }
    @media (${screens.wideDesktop}) {
        width: 60%;
    }
    @media (${screens.wideScreen}) {
        width: 65%;
        max-width: 984px;
    }
    @media (min-width: 1900px) {
        max-width: 976px;
    }
`;

export const CtaWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.Background};
    width: min-content;
    border-radius: 88px;
`;

export function globalLoaderHide() {
    return {
        type: 'layout/GLOBAL_LOADER_HIDE',
    };
}

export function addBodyClass(className) {
    document.body.classList.add(className);
}

export function removeBodyClass(className) {
    document.body.classList.remove(className);
}

export function addHtmlClass(className) {
    document.getElementsByTagName('html')[0].classList.add(className);
}
export function removeHtmlClass(className) {
    document.getElementsByTagName('html')[0].classList.remove(className);
}

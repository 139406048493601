import { PureComponent } from 'react';

import { canUseDOM } from 'ggApp/utils/dom';

import {
    loadIntercomWidget,
    shutdownIntercom,
    createSettingsForIntercom,
    updateIntercomSettings,
    appId,
    bootIntercom,
    subscribeToUnreadMessages,
    listenToIntercomMessengerAppearance,
    publishUserLoggedIn,
} from '../../service';

class Intercom extends PureComponent {
    constructor(props) {
        super(props);

        const settings = createSettingsForIntercom(props);

        if (!appId || !canUseDOM) {
            return null;
        }

        loadIntercomWidget(appId, settings);
        bootIntercom(settings);
    }

    componentDidMount() {
        const { getHMAC, user, isLoggedIn, updateMessageCount } = this.props;

        if (isLoggedIn) {
            getHMAC(user);
            subscribeToUnreadMessages(updateMessageCount);
            listenToIntercomMessengerAppearance();
        } else {
            const settings = createSettingsForIntercom(this.props);
            updateIntercomSettings(settings);
        }
    }

    componentWillReceiveProps(nextProps) {
        const { user, isLoggedIn: isLoggedInNextProps } = nextProps;

        const { getHMAC, updateMessageCount, isLoggedIn: isLoggedInCurrentProps } = this.props;

        if (!isLoggedInCurrentProps && isLoggedInNextProps) {
            getHMAC(user);
            subscribeToUnreadMessages(updateMessageCount);
            publishUserLoggedIn();
        } else if (isLoggedInCurrentProps && !isLoggedInNextProps) {
            shutdownIntercom();
        } else {
            const settings = createSettingsForIntercom(nextProps);
            updateIntercomSettings(settings);
        }
    }

    render() {
        return null;
    }
}

export { Intercom };

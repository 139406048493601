/* eslint-disable */
import { CATALOG_ROUTES_FOR_LIST, LIST_NAME } from 'ggApp/modules/gaAnalytics/constants';

/**
 * @description Fromat price to dataLayer standarts
 * @param {Number} price
 * @returns {String} Formatter price
 */
export function formatPrice(price) {
    if (!isFinite(price)) return '';
    return (price / 100).toFixed(2);
}

export function getListNameByRoute(pathname = '', params = {}) {
    if (params.subCategorySlug) {
        return LIST_NAME.subcategory;
    }

    if (params.categorySlug) {
        return LIST_NAME.category;
    }

    const foundRoute = Object.values(CATALOG_ROUTES_FOR_LIST).find(route => pathname.includes(route));
    if (foundRoute) {
        return LIST_NAME[foundRoute];
    }
    const isHome = new RegExp(`/(${params.store}-${params.language})/?$`).test(pathname);
    if (isHome) {
        return LIST_NAME.home;
    }
    return null;
}

export function cheapestRentalPlanInfo(productFromState) {
    const { rental_plans: rentalPlans = [] } = productFromState;

    if (rentalPlans.length === 0) {
        return undefined;
    }

    const cheapestPlan = rentalPlans[rentalPlans.length - 1];
    const planPrice = cheapestPlan.rental_plan_price_cents;

    return {
        metric1: formatPrice(planPrice), // cheapest plan
    };
}

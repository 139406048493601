import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { decode } from 'html-entities';

import CircleTick from 'ggApp/shared/icons/CircleTick';
import Stars from 'ggApp/shared/icons/Stars';
import { PureTranslator } from 'ggApp/modules/translator/components';
import {
    validateReviewData,
    scoreToPercentage,
    trimLong,
    checkAddress,
    translateDate,
} from 'ggApp/shared/components/ReviewIO/utils';

import {
    ReviewCardWrapper,
    ReviewText,
    ReviewerDetails,
    ReviewerName,
    VerifiedWrapper,
    VerifiedText,
    ReviewContent,
    AddressAndDate,
    Address,
} from './styles';

export type ReviewCardProps = {
    review: {
        reviewer: {
            first_name: string;
            last_name: string;
            verified_buyer: string;
            address: string;
        };
        rating: number;
        comments: string;
        date: string;
    };
    storeCode: string;
};

export const ReviewCard = ({ review, storeCode }: ReviewCardProps) => {
    const theme = useContext(ThemeContext);
    const validReviewData = validateReviewData(review);
    if (!validReviewData) {
        return null;
    }
    const { reviewer, rating, comments, date } = review;
    const { first_name: first, last_name: last, verified_buyer: verified, address } = reviewer;
    const percentage = scoreToPercentage(rating);
    const comment = decode(comments);
    const trimmedComment = trimLong(comment);
    const checkedAddress = checkAddress(address);
    const translatedDate = translateDate(date);
    return (
        <ReviewCardWrapper data-testid="review-card">
            <ReviewText>
                <ReviewerDetails>
                    <ReviewerName>
                        {first} {last}{' '}
                    </ReviewerName>
                    <Stars percentage={percentage} fillColor={theme.colors.Foreground} />
                </ReviewerDetails>
                {verified ? (
                    <VerifiedWrapper>
                        <CircleTick color={theme.colors.Foreground} size="16px" />
                        <VerifiedText>
                            <PureTranslator tk="REVIEWS_VERIFIED_CUSTOMER" />
                        </VerifiedText>
                    </VerifiedWrapper>
                ) : null}
                <ReviewContent data-testid="ReviewsComment">{trimmedComment}</ReviewContent>
            </ReviewText>

            <AddressAndDate>
                {storeCode !== 'us' ? <Address>{checkedAddress}</Address> : null}
                {translatedDate}
            </AddressAndDate>
        </ReviewCardWrapper>
    );
};

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import { CountdownTimer } from './CountdownTimer';
import { CountdownTimerProps } from './types';

const enhance = compose<CountdownTimerProps, {}>(
    connect(
        createStructuredSelector({
            activeStore: activeStoreSelector,
        }),
    ),
);

export default enhance(CountdownTimer);

import { CheckoutAPI } from './services/integration';

export type Mode<D extends string> = D;

export type ApiCollection<D extends string> = {
    [key in D]: CheckoutAPI;
};

interface CartContext {
    cart: any;
}

export type RedirectionFlow = {
    enabled: boolean;
    success: boolean;
};

export interface Guard<T extends CartContext> {
    isEmptyOrGuest: (arg: T) => boolean;
    emptyHomeAddress: (arg: T) => boolean;
    readyForReview: (arg: T) => boolean;
    emptyPaymentMethod: (arg: T) => boolean;
    shouldFetchUserData: (arg: T) => boolean;
    isCheckoutInactive: (ctx: { isCheckoutActive: boolean }) => boolean;
    returningFromRedirect: (ctx: { redirectionFlow: RedirectionFlow }) => boolean;
    canPrefillOrderData: (arg: T) => boolean;
    canFetchOrder: (arg: T) => boolean;
    twoFaRequired: (arg: T) => boolean;
    isHomeAddressUpdate: (arg: T) => boolean;
    isShippingAddressUpdate: (arg: T) => boolean;
}

export interface MachineConfig {
    services: any;
    actions: any;
    guards: any;
    activities?: any;
}

export enum OrderModes {
    Flex = 'FLEX',
    Mix = 'MIX',
}

export enum PhoneRequestVerificationOrigin {
    HomeAddressAdd = 'error.platform.homeAddressAdd',
    ShippingAddressAdd = 'error.platform.shippingAddressAdd',
}

type Address = {
    additional_info: string;
    address1: string;
    address2: string;
    city: string;
    country_id: number;
    country_name: string;
    zipcode: string;
};

type AddressPayload = {
    address: Address;
};

export type PhoneVerificationPendingType = {
    origin: PhoneRequestVerificationOrigin;
    requestPayload: AddressPayload;
    timestamp: string;
    timeoutSeconds: number;
    phoneLastDigits: string;
};

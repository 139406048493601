/* eslint-disable */
import CookiesManager, { injectCookiesToConfig } from 'ggApp/utils/CookiesManager';
import axios from 'ggApp/utils/requests';
import ggAxiosConfig from 'ggApp/modules/axiosConfig';
import {
    GET_CATEGORY_BY_ID,
    GET_CATEGORY_BY_ID_SUCCESS,
    GET_CATEGORY_BY_ID_ERROR,
    GET_CATEGORIES,
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_ERROR,
    GET_SUB_CATEGORY,
    GET_SUB_CATEGORY_SUCCESS,
    GET_SUB_CATEGORY_ERROR,
} from '../actionTypes';

export function getCategory({ categorySlug }, location, req) {
    if (!categorySlug) return;
    const cookiesManager = new CookiesManager(req);
    return (dispatch, getState) => {
        const config = ggAxiosConfig.selectors.config(getState());
        injectCookiesToConfig(cookiesManager, config);
        dispatch({ type: GET_CATEGORY_BY_ID });

        return axios
            .get(`/categories/${categorySlug}`, config)
            .then((result) => {
                dispatch({
                    type: GET_CATEGORY_BY_ID_SUCCESS,
                    data: result.data.category,
                });
            })
            .catch(({ response: error = {} })=> {
                dispatch({
                    type: GET_CATEGORY_BY_ID_ERROR,
                    data: error && error.data,
                });

                if (error && error.status === 404) {
                    return Promise.reject({
                        code: error.status,
                    });
                }

                return Promise.reject(
                    `Failed to fetch category ${categorySlug}`,
                );
            });
    };
}

export function getCategories(params, location, req) {
    const cookiesManager = new CookiesManager(req);
    return (dispatch, getState) => {
        const config = ggAxiosConfig.selectors.config(getState());
    injectCookiesToConfig(cookiesManager, config);
    dispatch({ type: GET_CATEGORIES, });
    return axios.get(`/categories`, config)
      .then(result => {
        dispatch({
          type: GET_CATEGORIES_SUCCESS,
          data: result.data.categories,
        })
      })
      .catch(({ response: error = {} })=> {
        if( error.data ) {
          dispatch({
            type: GET_CATEGORIES_ERROR,
            error: error.data,
          })
        }
      })
  }
}

export function getSubCategory({ categorySlug, subCategorySlug }, location, req) {
    const cookiesManager = new CookiesManager(req);
    return (dispatch, getState) => {
        const config = ggAxiosConfig.selectors.config(getState());
    injectCookiesToConfig(cookiesManager, config);
    dispatch({ type: GET_SUB_CATEGORY, });
    return axios.get(`/sub_categories/${categorySlug}/${subCategorySlug}`, config)
      .then(result => {
        dispatch({
          type: GET_SUB_CATEGORY_SUCCESS,
          data: result.data.category,
        })
      })
      .catch(({ response: error = {} })=> {
        dispatch({
          type: GET_SUB_CATEGORY_ERROR,
          error: error && error.data,
        })

                if (error && error.status === 404) {
                    return Promise.reject({
                        code: error.status,
                    });
                }

                return Promise.reject(
                    `Failed to fetch subCategory ${categorySlug}/${subCategorySlug}`,
                );
            });
    };
}

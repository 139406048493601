import CookiesManager from 'ggApp/utils/CookiesManager';

import { appName, DISCOVER_CARD } from './constants';

export const isAppUser = (headers) => {
    const userAgent = window?.navigator?.userAgent;
    return !!(
        window.ReactNativeWebView ||
        userAgent?.includes(appName) ||
        (headers && headers['user-agent']?.includes(appName))
    );
};

export const isAndroidAppUser = () => {
    const userAgentHeader = window?.navigator?.userAgent;
    return userAgentHeader?.includes(appName) && userAgentHeader?.includes('Android');
};

export const isAppleMobileUser = () => {
    return (
        ['iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
};

export const iosAppUserMarketingConsent = () => {
    const userAgentHeader = window?.navigator?.userAgent;
    const isIosUser =
        userAgentHeader?.includes(appName) && userAgentHeader?.indexOf('Android') === -1;
    if (isIosUser) {
        const cookiesManager = new CookiesManager();
        return Boolean(cookiesManager.get('marketing_cookie_consent'));
    }
    return false;
};

export const isDiscoveringCard = () => {
    const userAgent = window?.navigator?.userAgent;

    return isAppUser() && userAgent === DISCOVER_CARD;
};

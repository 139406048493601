import loadConfiguration from 'ggApp/utils/configuration';

const { DD_ENV, GROVER_WORK_BASE_URL } = loadConfiguration();

const workUrlRegex = /https:\/\/work(-ui(-pr-[0-9]{1,10})?\.eu-staging(\.internal)?)?\.grover\.com(\/)?/;
const dashboardUrlRegex = /https:\/\/([0-9]{1,10}-merge\.)?dashboard-ui(-dev)?\.eu-staging\.grover\.com(\/([0-9]|[a-z]|\/|-)*)?/;

export const isRedirectionOK = (isAppUser: boolean, redirection?: string | null): boolean => {
    if (isAppUser || !redirection) return false;
    if (DD_ENV === 'production') {
        return false;
    }

    return (
        [workUrlRegex, dashboardUrlRegex].some((regex) => regex.test(redirection)) ||
        redirection === GROVER_WORK_BASE_URL
    );
};

import styled, { css } from 'styled-components';
import Link from 'ggApp/shared/containers/FrontshopLink';
import FavoriteButtonComponent from 'ggApp/shared/components/cards/favoriteButton';
import { TransformingImage } from 'ggApp/shared/components/resolutionImage/TransformingImage/TransformingImage';
import { SmallHeadline, Paragraph } from 'ggApp/shared/common/Typography';
import { elevation, elevationTransition } from 'ggApp/utils/mixins';
import Price from 'ggApp/shared/components/cards/Price';
import ShimmerEffect from 'ggApp/shared/components/shimmerEffect/v2';

const ShimmerTransformer = ShimmerEffect(TransformingImage);

export const FavoriteButton = styled(FavoriteButtonComponent)`
    position: absolute;
    right: ${({ theme }) => theme.spacers.L};
    top: ${({ theme }) => theme.spacers.L};
`;

export const FavoriteButtonGap = styled.div`
    width: 20px;
    height: 20px;
    float: right;
`;

export const HorizontalRow = styled.div`
    display: flex;
`;

export const ChipsBlock = styled.div`
    position: initial;
    margin-bottom: 7px;
`;

export const PriceBlock = styled(Price)``;

export const Image = styled(ShimmerTransformer)`
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;

export const ImageContainer = styled.div`
    height: 80px;
    min-width: 80px;
    max-width: 80px;
    max-height: 80px;
    margin-right: 12px;
    float: left;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const TextWrap = styled.div`
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-right: 0px;
`;

export const Name = styled(SmallHeadline)`
    color: ${({ theme }) => theme.colors.Foreground};
    overflow: hidden;
    margin: auto 0 4px;
    display: inline;
    width: 100%;
`;

export const Description = styled(Paragraph)`
    color: ${({ theme }) => theme.colors['Level 5']};
    overflow: hidden;
    margin-bottom: 4px;
    -webkit-line-clamp: 2;
    font-size: 14px;
    line-height: 20px;
`;

const RootStyles = css`
    position: relative;
    background: ${({ theme }) => theme.colors.Background};
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    padding: 12px 16px 12px 16px;
    transition-property: all;
    ${({ handpickedProduct, theme }) =>
        handpickedProduct
            ? css``
            : css`
                  border: 2px solid ${theme.colors['Level 1']};
                  ${elevation(0)};
                  ${elevationTransition()};
              `};

    &:focus {
        outline: none;
    }

    @media not all and (any-pointer: coarse) {
        &:hover {
            ${({ theme }) => css`
                ${elevation(3, true, theme.colors['Level 1'], theme.colors['Level 2'])}
            `}
        }

        &:active {
            ${({ theme }) => css`
                ${elevation(1, true, theme.colors['Level 1'], theme.colors['Level 2'])}
            `}
        }
    }
`;

export const RootLink = styled(Link)`
    ${RootStyles}
`;
export const RootDiv = styled.div`
    ${RootStyles}
`;

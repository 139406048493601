/* eslint-disable */
import parseToRgb from 'ggApp/utils/color/helpers/parseToRgb';
import { numberToHex, reduceHexValue } from 'ggApp/utils/color/lighten/hslaHelpers';

function rgb(
    value,
    green,
    blue,
) {
    if (
        typeof value === 'number'
        && typeof green === 'number'
        && typeof blue === 'number'
    ) {
        return reduceHexValue(`#${numberToHex(value)}${numberToHex(green)}${numberToHex(blue)}`);
    } if (
        typeof value === 'object'
        && green === undefined
        && blue === undefined
    ) {
        return reduceHexValue(
            `#${numberToHex(value.red)}${numberToHex(value.green)}${numberToHex(value.blue)}`,
        );
    }

    throw new Error('Wrong color format');
}

export default function rgba(
    firstValue,
    secondValue,
    thirdValue,
    fourthValue,
) {
    if (!firstValue) return;
    if (typeof firstValue === 'string' && typeof secondValue === 'number') {
        const rgbValue = parseToRgb(firstValue);
        return `rgba(${rgbValue.red},${rgbValue.green},${
            rgbValue.blue
        },${secondValue})`;
    } if (
        typeof firstValue === 'number'
        && typeof secondValue === 'number'
        && typeof thirdValue === 'number'
        && typeof fourthValue === 'number'
    ) {
        return fourthValue >= 1
            ? rgb(firstValue, secondValue, thirdValue)
            : `rgba(${firstValue},${secondValue},${thirdValue},${fourthValue})`;
    } if (
        typeof firstValue === 'object'
        && secondValue === undefined
        && thirdValue === undefined
        && fourthValue === undefined
    ) {
        return firstValue.alpha >= 1
            ? rgb(firstValue.red, firstValue.green, firstValue.blue)
            : `rgba(${firstValue.red},${firstValue.green},${firstValue.blue},${
                firstValue.alpha
            })`;
    }
    throw new Error('Wrong color format');
}

/* eslint-disable */
import { connect } from 'react-redux';
import { compose, mapProps } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';

import Footer from './Footer';

const enhance = compose(
    connect(
        createStructuredSelector({
            activeStore: activeStoreSelector,
        }),
    ),
    mapProps(
        ({
            activeStore: { code: storeCode, activeLanguage },
            ...restProps
        }) => ({
            storeCode,
            activeLanguage,
            ...restProps,
        }),
    ),
);

export default enhance(Footer);

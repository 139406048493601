import { MODULE_NAME } from '../constants';

export const LOGIN = 'auth/LOGIN';
export const SIGN_UP = 'auth/SIGN_UP';
export const LOGOUT = 'auth/LOGOUT';
export const PHONE_VERIFICATION = 'auth/PHONE_VERIFICATION';
export const GET_USER = 'auth/GET_USER';

export const CONFIRM_EMAIL = `${MODULE_NAME}/CONFIRM_EMAIL`;
export const CONFIRM_EMAIL_SUCCESS = `${MODULE_NAME}/CONFIRM_EMAIL_SUCCESS`;
export const CONFIRM_EMAIL_ERROR = `${MODULE_NAME}/CONFIRM_EMAIL_ERROR`;

export const CHANGE_PASSWORD = `${MODULE_NAME}/CHANGE_PASSWORD`;
export const CHANGE_PASSWORD_SUCCESS = `${MODULE_NAME}/CHANGE_PASSWORD_SUCCESS`;
export const CHANGE_PASSWORD_ERROR = `${MODULE_NAME}/CHANGE_PASSWORD_ERROR`;

export const RESET_PASSWORD = `${MODULE_NAME}/RESET_PASSWORD`;
export const RESET_PASSWORD_SUCCESS = `${MODULE_NAME}/RESET_PASSWORD_SUCCESS`;
export const RESET_PASSWORD_ERROR = `${MODULE_NAME}/RESET_PASSWORD_ERROR`;

export const SUBSCRIBE_TO_NEWSLETTER = `${MODULE_NAME}/SUBSCRIBE_TO_NEWSLETTER`;
export const SUBSCRIBE_TO_NEWSLETTER_SUCCESS = `${MODULE_NAME}/SUBSCRIBE_TO_NEWSLETTER_SUCCESS`;
export const SUBSCRIBE_TO_NEWSLETTER_ERROR = `${MODULE_NAME}/SUBSCRIBE_TO_NEWSLETTER_ERROR`;

export const USER_LOGOUT = `${MODULE_NAME}/USER_LOGOUT`;
export const USER_LOGOUT_SUCCESS = `${MODULE_NAME}/USER_LOGOUT_SUCCESS`;
export const USER_LOGOUT_ERROR = `${MODULE_NAME}/USER_LOGOUT_ERROR`;

export const GQL_FETCH_COMPANY = `${MODULE_NAME}/GQL_FETCH_COMPANY`;

export const USER_SHOULD_BE_REDIRECTED = `${MODULE_NAME}/USER_SHOULD_BE_REDIRECTED`;

/* eslint-disable */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withTheme } from 'styled-components';
import { compose } from 'recompose';
import { isLoggedIn as isLoggedInSelector } from 'ggApp/modules/auth/selectors';

import {
    addToFavorites as addToFavoritesAction,
    removeFromFavorites as removeFromFavoritesAction,
} from 'ggApp/modules/favorites/actions';
import { favoriteList } from 'ggApp/modules/favorites/selectors';

import FavoriteButton from './FavoriteButton';


export default compose(
    connect(
        createStructuredSelector({
            isLoggedIn: isLoggedInSelector,
            active: (state, ownProps) => favoriteList(state).includes(ownProps.id),
        }),
        dispatch => ({
            removeFromFavorites: bindActionCreators(removeFromFavoritesAction, dispatch),
            addToFavorites: bindActionCreators(addToFavoritesAction, dispatch),
        })
    ),
    withTheme
)(FavoriteButton);

import { GET_CONTENTFUL_PAGE_SUCCESS } from '../constants';

const initialState = {};

export function pages(state = initialState, action) {
    const { type, pageName, data } = action;
    switch (type) {
        case GET_CONTENTFUL_PAGE_SUCCESS:
            return {
                ...state,
                [pageName]: data,
            };

        default:
            return state;
    }
}

// @flow
import type { ReduxStorePayload, CookiePayload, GTMPayload } from '../flowTypes';

export function adaptCookiePayloadToReduxStorePayload(
    cookiePayload: CookiePayload,
): ReduxStorePayload {
    return {
        preferenceCookieConsent: cookiePayload?.preferenceCookie || false,
        statisticsCookieConsent: cookiePayload?.statisticsCookie || false,
        marketingCookieConsent: cookiePayload?.marketingCookie || false,
        consentTimestamp: cookiePayload.consentTimestamp,
        hasConsent: true,
    };
}

export function adaptReduxStorePayloadToCookiePayload(
    reduxStorePayload: ReduxStorePayload,
): CookiePayload {
    return {
        preferenceCookie: reduxStorePayload.preferenceCookieConsent,
        statisticsCookie: reduxStorePayload.statisticsCookieConsent,
        marketingCookie: reduxStorePayload.marketingCookieConsent,
        consentTimestamp: reduxStorePayload.consentTimestamp,
    };
}

export function adaptReduxStorePayloadToGTMPayload(
    reduxStorePayload: ReduxStorePayload,
): GTMPayload {
    return {
        'preference-cookie-consent': reduxStorePayload.preferenceCookieConsent,
        'statistics-cookie-consent': reduxStorePayload.statisticsCookieConsent,
        'marketing-cookie-consent': reduxStorePayload.marketingCookieConsent,
    };
}

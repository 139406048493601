import CookiesManager from 'ggApp/utils/CookiesManager';
import nanoid from 'nanoid';
import { DEVICE_ID_COOKIE_NAME } from 'ggApp/utils/DeviceIdCookie/constant';

const ONE_DECADE_EXPIRATION_TIME_IN_SECONDS = 10 * 365 * 24 * 60 * 60;
const setDeviceIdCookie = (req = null) => {
    const cookiesManager = new CookiesManager(req);
    let deviceId = cookiesManager.get(DEVICE_ID_COOKIE_NAME);
    if (deviceId) {
        return;
    }
    deviceId = nanoid();
    cookiesManager.set(DEVICE_ID_COOKIE_NAME, deviceId, {
        maxAge: ONE_DECADE_EXPIRATION_TIME_IN_SECONDS,
        httpOnly: false,
    });
};

export default setDeviceIdCookie;

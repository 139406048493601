import axios from 'ggApp/utils/requests';
import { registerVoucherValidationV2 } from 'ggApp/modules/gaAnalytics/actions';
import { CheckoutMachine } from 'ggApp/shared/context/Order/services/integration';

export const vouchers: any = {
    submit: (context: CheckoutMachine, event: { voucher: string }) => {
        const { orderNumber, user } = context;
        const { id: userID } = user || {};
        if (!orderNumber || !userID) {
            return Promise.reject(
                new Error(
                    `(VoucherSubmit) OrderNumber: ${orderNumber} or UserId: ${userID} is not defined!`,
                ),
            );
        }
        const { voucher } = event;

        return axios
            .patch(`orders/${orderNumber}/apply_coupon`, { coupon_code: voucher })
            .then((res): object => {
                registerVoucherValidationV2({ eventLabel: 'valid', orderNumber, userID, voucher });

                return res.data;
            });
    },
    delete: ({ orderNumber }: CheckoutMachine) => {
        return axios.patch(`orders/${orderNumber}/reset_coupon`).then((res): object => res.data);
    },
};

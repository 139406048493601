import React from 'react';
import { Svg, G, Path } from 'react-native-svg';

const GroverLogo = ({ className, color = '#FE1251' }) => (
    <Svg
        className={className}
        viewBox="0 0 297.6 85"
        fill="none"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
    >
        <G>
            <Path
                fill={color}
                d="M159.6,32.1V25c0-0.4-0.4-0.8-0.8-0.7c-5.7,0.2-9.1,2.7-11,4.8c-4.8,5.2-4.5,12.4-4.5,14.2V59
		c0,0.4,0.3,0.7,0.7,0.7h7.1c0.4,0,0.7-0.3,0.7-0.7V42.9c0,0-0.3-5.2,2.2-8c1.1-1.2,2.7-1.9,4.8-2C159.2,32.9,159.6,32.5,159.6,32.1
		"
            />
            <Path
                fill={color}
                d="M291.2,32.1V25c0-0.4-0.4-0.8-0.8-0.7c-5.7,0.2-9.1,2.7-11,4.8c-4.8,5.2-4.5,12.4-4.5,14.2V59
		c0,0.4,0.3,0.7,0.7,0.7h7.1c0.4,0,0.7-0.3,0.7-0.7V42.9c0,0-0.3-5.2,2.2-8c1.1-1.2,2.7-1.9,4.8-2C290.9,32.9,291.2,32.5,291.2,32.1
		"
            />
            <Path
                fill={color}
                d="M46.7,64.6c-12.8,0-23.1-10.4-23.1-23.1S34,18.3,46.7,18.3c12.8,0,23.1,10.4,23.1,23.1S59.5,64.6,46.7,64.6
		 M81.1,20.8c-0.9-3.4-2.4-6.6-4.7-9c-2.4-2.3-5.6-3.7-9-4.7c-8.9-2.5-19.2-2.3-28.2-0.3c-9.1,2.1-15.7,8-21.1,15.4
		c-5,6.9-9.7,15.4-10.4,24c-0.7,9,3.8,17.7,10.3,24c6.3,6.5,14.9,11,24,10.3c8.6-0.7,17.1-5.4,24-10.4c7.4-5.4,13.3-11.9,15.4-21
		C83.4,40,83.6,29.7,81.1,20.8"
            />
            <Path
                fill={color}
                d="M227.4,25l-11.6,25.4L204.1,25c-0.2-0.4-0.6-0.6-1-0.6h-6.8c-0.5,0-0.9,0.6-0.7,1l15.5,33.9
		c0.1,0.3,0.4,0.4,0.7,0.4h7.9c0.3,0,0.6-0.2,0.7-0.4l15.5-33.9c0.2-0.5-0.1-1-0.7-1h-6.8C227.9,24.3,227.5,24.6,227.4,25"
            />
            <Path
                fill={color}
                d="M242.9,37.7l0.1-0.2c1.8-3.7,5.6-6.2,9.7-6.2c4.1,0,8,2.4,9.7,6.2l0.1,0.2H242.9z M251.7,23.5
		c-9.5,0.5-17.1,8.2-17.6,17.6c-0.6,10.7,8,19.7,18.6,19.7c5.9,0,11.5-2.8,15-7.5c0.3-0.3,0.2-0.8-0.2-1.1l-5.4-3.5
		c-0.3-0.2-0.7-0.1-1,0.2c-2,2.5-5.1,4-8.4,4c-4.7,0-8.8-3-10.3-7.5l-0.1-0.2H263v0h7.5c0.4,0,0.7-0.3,0.7-0.7
		c0.1-0.8,0.2-1.6,0.2-2.4C271.4,31.5,262.5,22.9,251.7,23.5"
            />
            <Path
                fill={color}
                d="M178.7,52.8c-5.9,0-10.7-4.8-10.7-10.7c0-5.9,4.8-10.7,10.7-10.7c5.9,0,10.7,4.8,10.7,10.7
		C189.5,48,184.6,52.8,178.7,52.8 M178.7,23.4c-10.3,0-18.7,8.4-18.7,18.7c0,10.3,8.4,18.7,18.7,18.7c10.3,0,18.7-8.4,18.7-18.7
		C197.4,31.8,189,23.4,178.7,23.4"
            />
            <Path
                fill={color}
                d="M116.7,18.3c-12.8,0-23.2,10.4-23.2,23.2c0,12.8,10.4,23.2,23.2,23.2c12.8,0,23.2-10.4,23.2-23.2
		c0-1.2-0.1-2.4-0.3-3.7c-0.1-0.4-0.4-0.6-0.7-0.6h-20.5c-0.4,0-0.7,0.3-0.7,0.7V45c0,0.4,0.3,0.7,0.7,0.7h12.4l-0.1,0.2
		c-2,6.2-7.7,10.3-14.3,10.2c-8-0.2-14.5-6.9-14.3-15c0.2-7.9,6.7-14.3,14.7-14.3c4.7,0,9.1,2.2,11.8,6c0.2,0.3,0.7,0.4,1,0.2
		l5.9-3.8c0.4-0.2,0.4-0.7,0.2-1C131.3,22,124.3,18.3,116.7,18.3"
            />
        </G>
    </Svg>
);

export default GroverLogo;

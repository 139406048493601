import styled, { css } from 'styled-components';
import Link from 'ggApp/shared/containers/FrontshopLink';
import FavoriteButtonComponent from 'ggApp/shared/components/cards/favoriteButton';
import { TransformingImage } from 'ggApp/shared/components/resolutionImage/TransformingImage/TransformingImage';
import { elevation, elevationTransition } from 'ggApp/utils/mixins';
import Price from 'ggApp/shared/components/cards/Price';
import ShimmerEffect from 'ggApp/shared/components/shimmerEffect/v2';

const ShimmerTransformer = ShimmerEffect(TransformingImage);

const RootStyles = css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: ${({ carousel }) => (carousel ? '280px' : 'unset')};
    max-width: ${({ carousel }) => carousel && '280px'};
    padding: ${({ theme }) => theme.spacers.L};
    overflow: hidden;
    ${({ handpickedProduct, theme }) =>
        handpickedProduct
            ? css`
                  background: ${theme.palette.grey[4]};
                  ${theme.superQuery.all.and.maxWidth.tabletMax.css`
                    margin-left: ${theme.spacers.L};
                    margin-right: ${theme.spacers.L};
                `}
              `
            : css`
                  background: ${theme.colors.Background};
                  border: 2px solid ${theme.colors['Level 1']};
                  ${elevation(0)};
                  ${elevationTransition()};
              `}
    border-radius: 8px;
    transition-property: all;

    &:focus {
        outline: none;
    }

    @media not all and (any-pointer: coarse) {
        &:hover {
            ${({ theme }) => css`
                ${elevation(3, true, theme.colors['Level 1'], theme.colors['Level 2'])}
            `}
        }

        &:active {
            ${({ theme }) => css`
                ${elevation(1, true, theme.colors['Level 1'], theme.colors['Level 2'])}
            `}
        }
    }
`;

export const RootLink = styled(Link)`
    ${RootStyles};
    ${({ theme }) => theme.superQuery.all.and.maxWidth.mobileMax.css`
        display: block;
    `}
`;
export const RootDiv = styled.div`
    ${RootStyles}
`;

export const ImageContainer = styled.div`
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    margin: 24px auto;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: ${({ carousel }) => (carousel ? '221px' : '18vw')};
    min-height: ${({ carousel }) => carousel && '221px'};
    ${({ theme }) => theme.superQuery.all.and.maxWidth.mobileMax.css`
        max-height: 221px;
        min-height: ${({ handpickedProduct }) => handpickedProduct && '221px'};
        height: ${({ handpickedProduct }) => handpickedProduct && '221px'};
    `}

    img {
        height: auto;
        max-height: 100%;
    }
`;

export const Image = styled(ShimmerTransformer)`
    height: 100%;
`;

export const ChipsAndLabelBlock = styled.div`
    display: flex;
    position: absolute;
    top: ${({ theme }) => theme.spacers.L};
    right: ${({ theme }) => theme.spacers.L};
    left: ${({ theme }) => theme.spacers.L};
`;

export const TextWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 0px;
`;

export const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 39px;
    margin-bottom: 4px;
`;

export const Name = styled.h2`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    ${({ theme }) => css(theme.typography.SuperTinyHeadline)};
    color: ${({ theme }) => theme.colors.Foreground};
`;
export const DefaultName = styled.span`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    ${({ theme }) => css(theme.typography.SuperTinyHeadline)};
    color: ${({ theme }) => theme.colors.Foreground};
`;
export const Description = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 38px;
    margin-bottom: 8px;
    ${({ theme }) => css(theme.typography.Paragraph)};
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors['Level 5']};
`;

export const PriceBlock = styled(Price)``;

export const FavoriteButton = styled(FavoriteButtonComponent)`
    position: absolute;
    right: ${({ theme }) => theme.spacers.L};
    top: ${({ theme }) => theme.spacers.L};
`;

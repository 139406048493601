import { css } from 'styled-components';

const modifier = (modifierName) => ({
    css: (...args) => (props) => props[modifierName] && css(...args),
});

const propWithValue = (propName, value) => ({
    css: (...args) => (props) => props[propName] === value && css(...args),
});

export { modifier, propWithValue };

import { MODULE_NAME } from '../constants';

export const GET_FAVORITES_PRODUCTS = `${MODULE_NAME}/GET_FAVORITES_PRODUCTS`;
export const GET_FAVORITES_PRODUCTS_SUCCESS = `${MODULE_NAME}/GET_FAVORITES_PRODUCTS_SUCCESS`;
export const GET_FAVORITES_PRODUCTS_ERROR = `${MODULE_NAME}/GET_FAVORITES_PRODUCTS_ERROR`;

export const ADD_TO_FAVORITES = `${MODULE_NAME}/ADD_TO_FAVORITES`;
export const ADD_TO_FAVORITES_SUCCESS = `${MODULE_NAME}/ADD_TO_FAVORITES_SUCCESS`;
export const ADD_TO_FAVORITES_ERROR = `${MODULE_NAME}/ADD_TO_FAVORITES_ERROR`;

export const REMOVE_FROM_FAVORITES = `${MODULE_NAME}/REMOVE_FROM_FAVORITES`;
export const REMOVE_FROM_FAVORITES_SUCCESS = `${MODULE_NAME}/REMOVE_FROM_FAVORITES_SUCCESS`;
export const REMOVE_FROM_FAVORITES_ERROR = `${MODULE_NAME}/REMOVE_FROM_FAVORITES_ERROR`;

export const ADD_PRODUCTS_TO_WISHLIST_STATE = `${MODULE_NAME}/ADD_PRODUCTS_TO_WISHLIST_STATE`;

import ggAuth from 'ggApp/modules/auth';
import ggAxiosConfig from 'ggApp/modules/axiosConfig';

import axios from 'ggApp/utils/requests';
import qs from 'query-string';
import CookiesManager, { injectCookiesToConfig } from 'ggApp/utils/CookiesManager';
import {
    ADD_TO_FAVORITES,
    ADD_TO_FAVORITES_ERROR,
    ADD_TO_FAVORITES_SUCCESS,
    REMOVE_FROM_FAVORITES,
    REMOVE_FROM_FAVORITES_ERROR,
    REMOVE_FROM_FAVORITES_SUCCESS,
    GET_FAVORITES_PRODUCTS,
    GET_FAVORITES_PRODUCTS_SUCCESS,
    GET_FAVORITES_PRODUCTS_ERROR,
    ADD_PRODUCTS_TO_WISHLIST_STATE,
} from '../actionTypes';

export const defaultQueryPayload = {
    page: 1,
    per: 48,
};

const defaultQuery = qs.stringify(defaultQueryPayload);
export function getFavoritesProducts(query = defaultQuery, reset, req) {
    const cookiesManager = new CookiesManager(req);
    return (dispatch, getState) => {
        const config = ggAxiosConfig.selectors.config(getState());
        injectCookiesToConfig(cookiesManager, config);
        const user = ggAuth.selectors.user(getState());
        const userId = user && user.id;
        if (!userId) {
            dispatch({
                type: GET_FAVORITES_PRODUCTS_ERROR,
                error: 'User is empty',
            });
            return null;
        }
        dispatch({ type: GET_FAVORITES_PRODUCTS, reset });
        return axios
            .get(`/users/${userId}/favorites?${query}`, config)
            .then((result) => {
                dispatch({
                    type: GET_FAVORITES_PRODUCTS_SUCCESS,
                    data: result.data,
                    userId,
                });
            })
            .catch(({ response: error = {} }) => {
                if (error.data) {
                    dispatch({
                        type: GET_FAVORITES_PRODUCTS_ERROR,
                        error: error.data,
                    });
                }
            });
    };
}

export function getFavoritesByPagination(page, reset) {
    return (dispatch) => {
        const payload = {
            page,
            per: 48,
        };
        const query = qs.stringify(payload);

        return dispatch(getFavoritesProducts(query, reset));
    };
}

export const removeFromFavorites = ({ slug, id }) => (dispatch) => {
    dispatch({
        type: REMOVE_FROM_FAVORITES,
        slug,
        id,
    });
    return axios
        .delete(`/products/${slug}/favorite`)
        .then((result) => {
            dispatch({
                type: REMOVE_FROM_FAVORITES_SUCCESS,
                data: result.data,
                slug,
                id,
            });
        })
        .catch(({ response: error = {} }) => {
            if (error.data) {
                dispatch({
                    type: REMOVE_FROM_FAVORITES_ERROR,
                    error: error.data,
                });
            }
        });
};

export const addToFavorites = ({ slug, id }) => (dispatch) => {
    dispatch({
        type: ADD_TO_FAVORITES,
        slug,
        id,
    });
    return axios
        .post(`/products/${slug}/favorite`)
        .then(({ data }) => {
            dispatch({
                type: ADD_TO_FAVORITES_SUCCESS,
                data: [data.product],
                slug,
                id,
            });
        })
        .catch(({ response: error = {} }) => {
            if (error.data) {
                dispatch({
                    type: ADD_TO_FAVORITES_ERROR,
                    error: error.data,
                });
            }
        });
};

export const mergeProductsToFavoritesState = ({ products }) => (dispatch) => {
    if (products) {
        dispatch({
            type: ADD_PRODUCTS_TO_WISHLIST_STATE,
            data: {
                products,
            },
        });
    }
};

import styled from 'styled-components';
import { ButtonV2 } from '@getgrover/ui';

import Modal from 'ggApp/modules/modal/components/v3/modal';

import DropDowns from './DropDowns'

const MODAL_CONTAINER_CLASS_NAME = 'CountryRedirectionContainer';
const MODAL_CONTENT_CLASS_NAME = 'CountryRedirectionContent';

export const StyledModal = styled(Modal).attrs({
    classNames: {
        container: MODAL_CONTAINER_CLASS_NAME,
        content: MODAL_CONTENT_CLASS_NAME,
    },
})`
    display: flex;
    flex-direction: column;

    & .${MODAL_CONTAINER_CLASS_NAME} {
        width: auto;
        height: auto;
        min-height: unset;
        ${({ theme }) => theme.superQuery.all.and.minWidth.tabletMin.css`
            max-width: 480px;
        `}
    }

    & .${MODAL_CONTENT_CLASS_NAME} {
        padding: 24px;
    }
`;

export const Wrap = styled.section`
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

export const HeaderText = styled.h2`
    ${({ theme }) => theme.typography.Subheadline};
`;

export const CloseButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border: 2px solid ${({ theme }) => theme.colors['Level 1']};
    border-radius: 50%;
    cursor: pointer;
    margin-top: -8px;
    margin-right: -8px;
`;

export const Description = styled.p`
    margin-bottom: 32px;
    color: ${({ theme }) => theme.colors['Level 5']};
    ${({ theme }) => theme.typography.Paragraph};
`;

export const SelectText = styled.p`
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors['Level 5']};
    ${({ theme }) => theme.typography.SmallCapsHeadline};
`;

export const StoreLangDropDowns = styled(DropDowns)`
    margin-bottom: 32px;
`;

export const WarningCard = styled.div`
    display: flex;
    background-color: ${({ theme }) => theme.colors.cautionBackground};
    color: #EDA210;
    padding: 8px;
    margin-bottom: 32px;
    border-radius: 8px;
    > svg {
        flex: 0 0 auto;
    }
`;

export const Warning = styled.p`
    margin-left: 8px;
    ${({ theme }) => theme.typography.Paragraph};
`;

export const Cta = styled(ButtonV2)``;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MarkdownText from 'ggApp/shared/common/MarkdownText';

import { TypographyTag } from './styles';

export default class Typography extends Component {
    render() {
        const {
            tag,
            html,
            className,
            styleMixin,
            style,
            innerRef,
            children,
            onClick,
            overrides,
        } = this.props;

        return !html ? (
            <TypographyTag
                as={tag}
                ref={innerRef}
                className={className}
                style={style}
                onClick={onClick}
                tagStyle={styleMixin || tag}
            >
                {children}
            </TypographyTag>
        ) : (
            <span ref={innerRef} className={className} style={style} onClick={onClick}>
                <MarkdownText overrides={overrides}>{html}</MarkdownText>
            </span>
        );
    }
}

Typography.propTypes = {
    tag: PropTypes.string,
    html: PropTypes.string,
    className: PropTypes.string,
    styleMixin: PropTypes.string,
    style: PropTypes.shape({}),
    innerRef: PropTypes.func,
    children: PropTypes.node,
};

Typography.defaultProps = {
    tag: 'p',
    html: undefined,
    className: undefined,
    styleMixin: undefined,
    style: undefined,
    children: undefined,
    innerRef: () => {},
};

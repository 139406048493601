import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { mapProps } from 'recompose';

import { addBodyClass, removeBodyClass } from 'ggApp/modules/layout/actions';
import { loadingStatus } from 'ggApp/modules/requestStatus/selectors';

import {
    GlobalDisableScroll,
    OverlaySwitcherContainer,
    OverlaySwitcherBackground,
    OverlaySwitcherWrap,
    OverlaySwitcherContentWrap,
} from './styles';

@connect(
    createStructuredSelector({
        loading: loadingStatus,
    }),
)
@mapProps(({ loading, location, ...rest }) => ({
    ...rest,
    location: {
        ...location,
        query: { ...location.query, overlay: loading ? 'loading' : location.query.overlay },
    },
}))
export default class BaseLayoutOverlaySwitcher extends Component {
    constructor(props) {
        super(props);
        const { overlays, location } = props;
        const renderOverlay = overlays[location.query.overlay];
        this.state = { renderOverlay };
    }

    componentDidMount() {
        const {
            location: {
                query: { overlay: newOverlayQuery },
            },
            overlays,
        } = this.props;

        if (overlays[newOverlayQuery]) {
            addBodyClass('disableScroll');
        }
    }

    componentWillReceiveProps(nextProps) {
        const {
            location: {
                pathname,
                query: { overlay: overlayQuery },
            },
            overlays,
        } = this.props;
        const {
            location: {
                pathname: nextPathName,
                query: { overlay: newOverlayQuery },
            },
        } = nextProps;
        if (overlayQuery !== newOverlayQuery) {
            this.setNewOverlay(overlays[newOverlayQuery]);
        }
        if (pathname !== nextPathName && window) {
            window.scrollTo(0, 0);
        }
    }

    setNewOverlay = (overlay) => {
        if (!overlay) {
            setTimeout(() => this.setState({ renderOverlay: null }), 500);
            removeBodyClass('disableScroll');
            return;
        }
        this.setState({ renderOverlay: overlay });
        addBodyClass('disableScroll');
        this.overlayContainer.scrollTop = 0;
    };

    contentOnScroll = () => {
        const { overlays, location } = this.props;
        return !overlays[location.query.overlay];
    };

    render() {
        const { overlays, location, children, headerGap } = this.props;
        const showOverlay = overlays[location.query.overlay];
        const { renderOverlay } = this.state;

        return (
            <OverlaySwitcherContainer>
                <GlobalDisableScroll />
                <OverlaySwitcherBackground visible={Boolean(showOverlay)} />

                <OverlaySwitcherWrap
                    ref={(e) => {
                        this.overlayContainer = e;
                    }}
                    visible={Boolean(showOverlay)}
                >
                    {renderOverlay && headerGap}
                    {renderOverlay && renderOverlay()}
                </OverlaySwitcherWrap>

                <OverlaySwitcherContentWrap
                    hide={Boolean(showOverlay)}
                    onScroll={this.contentOnScroll}
                >
                    {children}
                </OverlaySwitcherContentWrap>
            </OverlaySwitcherContainer>
        );
    }
}

BaseLayoutOverlaySwitcher.propTypes = {
    children: PropTypes.node,
    headerGap: PropTypes.node,
    location: PropTypes.shape({}).isRequired,
    overlays: PropTypes.shape({}).isRequired,
};

BaseLayoutOverlaySwitcher.defaultProps = {
    children: null,
    headerGap: null,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import { IconGap, LanguageText, List, ListElement } from './styled';

class Options extends Component {
    handleChange = (index) => {
        const { currentValue, list, onChange } = this.props;
        const currentListItem = list.find((t) => t.value === currentValue);
        if (currentListItem.value === list[index].value) return;
        onChange(list[index].value);
    };

    render() {
        const { className, list, isOpen, isMinimal, theme, iconComponent: Icon } = this.props;
        return (
            <List className={className} isOpen={isOpen} isMinimal={isMinimal}>
                {list.map((item, index) => (
                    <ListElement
                        key={item.value}
                        onClick={() => this.handleChange(index)}
                        isMinimal={isMinimal}
                    >
                        {index === 0 ? <Icon color={theme.colors.Foreground} /> : <IconGap />}
                        <LanguageText>{item.label}</LanguageText>
                    </ListElement>
                ))}
            </List>
        );
    }
}

Options.propTypes = {
    className: PropTypes.string,
    currentValue: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func,
    isOpen: PropTypes.bool,
    isMinimal: PropTypes.bool,
};

Options.defaultProps = {
    className: '',
    currentValue: '',
    list: [],
    onChange: () => {},
    isOpen: false,
    isMinimal: false,
};

export default withTheme(Options);

import styled, { css } from 'styled-components';
import { SonntagButtonV2 } from '@getgrover/ui';

import Link from 'ggApp/shared/containers/FrontshopLink';
import { elevation, elevationTransition } from 'ggApp/utils/mixins';
import rgba from 'ggApp/utils/color/rgba';

const Cta = styled(Link)`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 16px;
    margin-right: auto;
    border-radius: 88px;
    background: ${({ theme }) => theme.colors['Level 1']};
    color: ${({ theme }) => theme.colors.Foreground};
    ${elevation(0, false)}
    ${elevationTransition(false)}
    transition-property: all;
    &:hover,
    &:focus {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.Foreground};
        background: ${({ theme }) => theme.colors['Level 3']};
        ${elevation(2, false)};
        ${({ accentColor }) => accentColor && css`
          ${elevation(2, false, rgba(accentColor, 0.05), rgba(accentColor, 0.13))}
        `};
    }
    &:focus {
        outline: none;
    }
    &:active {
        ${elevation(1, false)}
        transition-duration: 0s;
        color: ${({ theme }) => theme.colors.Foreground};
    }
    ${({ widgetCtaTheme }) => widgetCtaTheme === 'light'
        && css`
            background: rgba(255, 255, 255, 0.3);
            color: ${({ theme }) => theme.colors.Background};
            &:hover,
            &:focus {
                color: ${({ theme }) => theme.colors.Background};
                background: rgba(255, 255, 255, 0.4);
                ${elevation(2, false, 'rgba(255, 255, 255, 0.05)', 'rgba(255, 255, 255, 0.13)')};
            }
            &:active {
                background: rgba(255, 255, 255, 0.3);
                color: ${({ theme }) => theme.colors.Background};
            }
        `};
    ${({ widgetCtaTheme }) => widgetCtaTheme === 'dark'
        && css`
            background: rgba(51, 51, 51, 0.1);
            color: ${({ theme }) => theme.colors.Foreground};
            &:hover,
            &:focus {
                background: rgba(255, 255, 255, 0.5);
                color: ${({ theme }) => theme.colors.Foreground};
            }
            &:active {
                background: rgba(51, 51, 51, 0.1);
                color: ${({ theme }) => theme.colors.Foreground};
            }
        `};
`;

const Button = styled(SonntagButtonV2)`
    margin-top: ${({ theme }) => theme.spacers.L};
    width: fit-content;
    overflow: visible;
`;


export { Cta, Button };

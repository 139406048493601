/* eslint-disable */
const
  defaultState = {
    submitted: false,
    err: {},
    payload: {},
    setup: {
      callback: () => {},
    },
    instance: null,
  };


export default function (state = defaultState, action) {
  switch (action.type) {

    case 'SET_BT_CC_DATA':
      return Object.assign({}, state, action.data);

    case 'BRAINTREE_CONFIRM':
      return Object.assign({}, state, {
        err: defaultState.err,
      });

    case 'BRAINTREE_CONFIRM_FAILURE':
      return Object.assign({}, state, {
        err: action.data,
      });

    default:
      return state;
  }
}

/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable consistent-return */
import axios from 'ggApp/utils/requests';
import ggAxiosConfig from 'ggApp/modules/axiosConfig';
import getLanguageList from 'ggApp/utils/storeLanguageList';
import CookiesManager, { injectCookiesToConfig } from 'ggApp/utils/CookiesManager';
import { isFunction } from 'ggApp/utils/function';
import { fireEvent } from 'ggApp/modules/inwebview_api';

import { setLocaleCookie } from '../utils';
import { GET_STORE, GET_STORE_SUCCESS, GET_STORE_ERROR } from '../actionTypes';
import {
    userChosenLanguage,
    storeActive as storeActiveSelector,
    isStoreReady as isStoreReadySelector,
} from '../selectors';

export function setActiveLanguage(language, cookiesManager = new CookiesManager()) {
    setLocaleCookie(language, cookiesManager);

    return {
        type: 'SET_ACTIVE_STORE_LANGUAGE',
        language,
    };
}

export function matchStore(createI18n, location, req) {
    return async (dispatch, getState) => {
        const state = getState();
        const cookiesManager = new CookiesManager(req);
        const locale = cookiesManager.get('locale');
        const config = ggAxiosConfig.selectors.config(state);
        const storeParam = state.router.params.store;
        const langParam = state.router.params.language;
        const hasParam = langParam != null;

        injectCookiesToConfig(cookiesManager, config);

        try {
            dispatch({
                type: GET_STORE,
            });

            const storeReady = isStoreReadySelector(state);
            const storeData = storeReady
                ? storeActiveSelector(state)
                : await axios
                      .get(`/stores/${storeParam}`, config)
                      .then((result) => result && result.data && result.data.store);

            let language = storeData && storeData.default_language;
            const hasLocale = Boolean(locale) && storeData.languages.indexOf(locale) !== -1;

            if (!hasParam && hasLocale) {
                language = locale;
            }

            if (hasParam) {
                const languageList = getLanguageList(storeData);
                const currentLanguage = languageList.find((l) => l === langParam);
                if (!currentLanguage) {
                    // 404 page as a result of invalid language param, does not reliably have access to store data,
                    // hence setting activeLanguage to reduxStoreCreator default returned locale.
                    dispatch(setActiveLanguage('en', cookiesManager));
                    return Promise.reject({
                        code: 404,
                        storeCodeParam: storeParam,
                        languageParam: null,
                    });
                }

                language = currentLanguage;
            }

            if (!locale || locale !== language) {
                dispatch(setActiveLanguage(language, cookiesManager));
            }
            dispatch(
                ggAxiosConfig.actions.setHeaders({
                    'X-Grover-Store': storeParam,
                    'X-Grover-Language': language,
                }),
            );

            axios.defaults.headers['X-Grover-Store'] = storeParam;
            cookiesManager.set('shop_country_code', storeParam);

            fireEvent(GET_STORE_SUCCESS, {
                action: {
                    store: {
                        storeId: storeData.store_id,
                        storeCode: storeData.code,
                        activeLanguage: language,
                    },
                },
            });

            dispatch({
                type: GET_STORE_SUCCESS,
                store: {
                    ...storeData,
                    activeLanguage: language,
                },
            });

            if (isFunction(createI18n)) {
                createI18n(language);
            }
        } catch (error) {
            // If we got here, we have no store entry in the state.
            // Since translations are relying on having a store entry with
            // an `activeLanguage`, we need to set one.
            // `userChosenLanguage` encapsulates the logic of having a language
            // or falling back to reasonable default
            // TODO: rename `userChosenLanguage` cause the name makes no sense in this use-case
            const fallbackLanguage = userChosenLanguage(cookiesManager)(getState());

            dispatch(setActiveLanguage(fallbackLanguage, cookiesManager));

            dispatch({
                type: GET_STORE_ERROR,
                error,
            });

            axios.defaults.headers['X-Grover-Store'] = storeParam;

            return Promise.reject({
                code: 404,
                storeCodeParam: storeParam,
                languageParam: null,
            });
        }
    };
}

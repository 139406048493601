import React, { useMemo } from 'react';
import { parse } from 'query-string';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { RedirectFlowStatus } from 'groverPaymentSDK';

import {
    newCheckoutFlagData as newCheckoutFlagDataSelector,
    newCheckoutOrderCustomizeColorFlag,
    newCheckoutOrderCustomizePlanFlag,
    linkProductsInCartFlagEnabled,
} from 'ggApp/modules/featureFlag/selectors';
import { getNewCheckoutFeatureFlag as getNewCheckoutFeatureFlagAction } from 'ggApp/modules/featureFlag/actions';
import { user as userSelector } from 'ggApp/modules/auth/selectors';
import flows from 'ggApp/modules/newCheckout/constants/flowNames';
import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import {
    registerAddToCart as registerAddToCartAction,
    registerRemoveFromCart as registerRemoveFromCartAction,
    registerLegacyEePurchaseEvent,
    registerCartContentChange as registerCartContentChangeAction,
} from 'ggApp/modules/gaAnalytics/actions';
import {
    storeParam as storeParamSelector,
    locationSearch as locationSearchSelector,
} from 'ggApp/modules/router/selectors';

import didReceiveRedirect, {
    getRedirectFlowDetails,
} from 'ggApp/shared/common/Payment/PaymentGatewayList/utils/didReceiveRedirect';
import {
    registerCriteoCartUpdate,
    registerCriteoOrderConfirmation,
} from 'ggApp/shared/context/Order/services/tracking/actions';
import { getOrderTypeFromCookies } from 'ggApp/shared/context/Order/cookies';

import { OrderContextProps } from './OrderContext';
import { OrderProvider } from './OrderProvider';
import { registerBasketEventsConnector } from './services/tracking/connectors';

const Controller = ({
    children,
    user,
    activeStore,
    storeParam,
    newCheckoutFlag,
    locationSearch,
    registerAddToCart,
    registerRemoveFromCart,
    registerCartContentChange,
    customizeColorFlag,
    customizePlanFlag,
    linkProductsInCartFlag,
}: OrderContextProps): JSX.Element | null => {
    const newCheckoutFlow =
        newCheckoutFlag && newCheckoutFlag.variantKey === 'with-home-address'
            ? flows.CHECKOUT_WITH_HOME_ADDRESS
            : flows.CHECKOUT_NO_HOME_ADDRESS;

    const redirectionFlow = useMemo(() => {
        const query = parse(window?.location?.search ?? locationSearch);
        const { status } = getRedirectFlowDetails(query) || {};
        return {
            enabled: didReceiveRedirect(query),
            success: status === RedirectFlowStatus.Success,
        };
    }, [locationSearch]);

    const storeActiveCode = activeStore?.code ?? storeParam;

    const orderMode = getOrderTypeFromCookies(storeActiveCode) || 'FLEX';

    return (
        <OrderProvider
            orderMode={orderMode}
            user={user}
            activeStore={activeStore}
            redirectionFlow={redirectionFlow}
            newCheckoutFlow={newCheckoutFlow}
            newCheckoutFlag={newCheckoutFlag}
            customizeColorFlag={customizeColorFlag}
            customizePlanFlag={customizePlanFlag}
            linkProductsInCartFlag={linkProductsInCartFlag}
            snowplowCallbacks={{
                registerBasketUpdate: registerBasketEventsConnector(
                    registerAddToCart,
                    registerRemoveFromCart,
                    registerLegacyEePurchaseEvent,
                    registerCriteoCartUpdate,
                    registerCriteoOrderConfirmation,
                    registerCartContentChange,
                ),
            }}
        >
            {children}
        </OrderProvider>
    );
};

const enhance = compose(
    connect(
        createStructuredSelector({
            user: userSelector,
            activeStore: activeStoreSelector,
            storeParam: storeParamSelector,
            newCheckoutFlag: newCheckoutFlagDataSelector,
            customizeColorFlag: newCheckoutOrderCustomizeColorFlag,
            customizePlanFlag: newCheckoutOrderCustomizePlanFlag,
            locationSearch: locationSearchSelector,
            linkProductsInCartFlag: linkProductsInCartFlagEnabled,
        }),
        (dispatch) => ({
            registerAddToCart: bindActionCreators(registerAddToCartAction, dispatch),
            registerRemoveFromCart: bindActionCreators(registerRemoveFromCartAction, dispatch),
            registerCartContentChange: bindActionCreators(
                registerCartContentChangeAction,
                dispatch,
            ),
            getNewCheckoutFeatureFlag: bindActionCreators(
                getNewCheckoutFeatureFlagAction,
                dispatch,
            ),
        }),
    ),
);

export const OrderController = enhance(Controller);

/* eslint-disable no-return-assign */
import { TranslatorComponent_Text as Translator } from 'ggApp/modules/translator';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import NotificationSystem from 'react-notification-system';
import isObjEqual from 'lodash/isEqual';

import * as NotificationSelectors from '../../selectors';
import * as NotificationActions from '../../actions';
import { AUTO_DISMISS } from '../../constants';

import { NotificationBar, Info, Row } from './styles';
@connect(
    createStructuredSelector({
        notificationList: NotificationSelectors.list,
    }),
    (dispatch) => ({ dispatch }),
)
export default class NotificationsBar extends Component {
    constructor(props) {
        super(props);

        this.addNotification = this.addNotification.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const { notificationList, dispatch } = this.props;

        if (
            !isObjEqual(notificationList, nextProps.notificationList) &&
            notificationList.length !== nextProps.notificationList.length &&
            notificationList.length < nextProps.notificationList.length &&
            nextProps.notificationList.length !== 0
        ) {
            const lastNotification =
                nextProps.notificationList[nextProps.notificationList.length - 1];

            this.addNotification({
                autoDismiss: AUTO_DISMISS,
                message: lastNotification.message ? (
                    <Translator tk={lastNotification.message} />
                ) : (
                    'Notification error!'
                ),
                level: lastNotification.level,
                children: !!lastNotification.status && (
                    <Info>
                        <Row>
                            <div>Status:</div> <div>{lastNotification.status}</div>
                        </Row>
                    </Info>
                ),
                onRemove: () =>
                    dispatch(NotificationActions.removeNotification(lastNotification.id)),
            });
        }
    }

    addNotification(data) {
        this.notificationSystemRef.addNotification(data);
    }

    render() {
        return (
            <NotificationBar>
                <NotificationSystem ref={(c) => (this.notificationSystemRef = c)} />
            </NotificationBar>
        );
    }
}

import {
    ValidationError,
    UntranslatedValidationError,
    TwoFactorAuthenticationError,
} from '../utils';

const handleError = (mapErrors) => (error) => {
    if (error instanceof TwoFactorAuthenticationError) {
        return Promise.reject(error);
    }

    const { status, data, statusText } = error?.response ?? {};
    const isNetworkError = !!error.isAxiosError && !error.response;

    if (isNetworkError) {
        return Promise.reject(
            new UntranslatedValidationError({
                baseError: 'NETWORK_CONNECTION_ERROR',
            }),
        );
    }

    if (status === 409 && data && (data.password_locked || data.user)) {
        return Promise.reject(
            new ValidationError({
                baseError: 'protective',
                errors: {
                    email: data.email,
                    // eslint-disable-next-line camelcase
                    phone_number: data?.phone_number ?? data?.user?.last_phone_digits,
                },
            }),
        );
    }

    if (Array.isArray(data)) {
        return Promise.reject(
            new ValidationError({
                baseError: data.join(', '),
            }),
        );
    }

    if ((status === 400 || status === 401 || status === 422) && data) {
        const { base: baseError, ...restErrors } = mapErrors(data) ?? {};

        return Promise.reject(
            new ValidationError({
                errors: restErrors,
                baseError,
            }),
        );
    }

    if (data?.base) {
        return Promise.reject(
            new ValidationError({
                baseError: data.base,
            }),
        );
    }

    if (typeof data === 'string' && data !== '') {
        return Promise.reject(
            new ValidationError({
                baseError: data,
            }),
        );
    }

    return Promise.reject(
        new UntranslatedValidationError({
            message: statusText,
            baseError: 'AUTHENTICATION_UNKNOWN_ERROR',
        }),
    );
};

export default handleError;

/* eslint-disable */
import { UPDATE_ORDER_STEP_SUCCESS } from 'ggApp/modules/order/actionTypes';
import { registerCheckout } from 'ggApp/modules/gaAnalytics/actions';

const CHECKOUT_STATUSES = ['cart', 'address', 'payment', 'summary', 'complete'];
const checkoutEventMiddleware = store => next => (action) => {
    next(action);
    if (
        [UPDATE_ORDER_STEP_SUCCESS].includes(action.type)
        && CHECKOUT_STATUSES.includes(action.step)
    ) {
        store.dispatch(
            registerCheckout(
                action.order,
                CHECKOUT_STATUSES.indexOf(action.step) + 1,
            ),
        );
    }
};

export default checkoutEventMiddleware;

import {
    SUBMIT_ADYEN_PAYMENT_DETAILS,
    SUBMIT_ADYEN_PAYMENT_DETAILS_SUCCESS,
    SUBMIT_ADYEN_PAYMENT_DETAILS_ERROR,
} from '../actionTypes';

const defaultState = {
    loading: null,
    error: {},
    data: null,
};

export default function(state = defaultState, action) {
    switch (action.type) {
        case SUBMIT_ADYEN_PAYMENT_DETAILS:
            return {
                ...state,
                loading: true,
                error: {},
            };

        case SUBMIT_ADYEN_PAYMENT_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data,
            };

        case SUBMIT_ADYEN_PAYMENT_DETAILS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
}

import { findAddressById } from 'ggApp/shared/context/Order/helpers/findAddressById';
import { OrderModes } from 'ggApp/shared/context/Order/types';
import { findPaymentMethodById } from './findPaymentMethodById';

export interface RentalPlansProps {
    minimunTerm?: string | number;
}

export interface ItemProps {
    id: number;
    name: string;
    coreAttribute: string;
    imageUrl: string;
    quantity: number;
    category?: {
        id: string;
        title?: string;
        permalink: string;
    };
    variant: {
        id?: string | number;
        colorTitle: string;
        colorCodes: Array<string>;
        product?: {
            sku: string;
            slug?: string;
            category?: {
                id?: string;
                title?: string;
                permalink?: string;
            };
        };
    };
    priceCents?: number;
    totalCents?: number;
    purchaseOption?: {
        monthsAfterRrp?: number;
        monthsRequired?: number;
        straightPrice?: number;
        marketPrice?: number;
    };
    rentalPlanId?: number;
    selectedPlan?: RentalPlansProps; // minimumTermMonths
    rentalPlans?: Array<RentalPlansProps>;
    buyoutMonths?: number;
    oldPrice?: number;
}

export interface OrderProps {
    orderNumber: string;
    guestToken?: string;
    shippingAddressId?: string | number;
    homeAddressId?: string | number;
    paymentMethod?: {
        id: string;
        type: string;
        meta: any;
    };
    items?: Array<ItemProps>;
    itemTotal?: number | string;
    shipmentPrice?: number | string;
    discountTotal?: number;
    voucher?: {
        name?: string;
        discountCents: string | number;
    };
    totalPrice?: number | string;
    storeCode?: string;
}

export const mapItems = ({ items, mode }: any): Array<ItemProps> => {
    if (!items || !mode) return [];

    if (mode === 'FLEX') {
        return items.map((item: any) => ({
            id: item.id,
            name: item?.variant?.name ?? null,
            coreAttribute: item?.variant?.core_attribute ?? null,
            imageUrl: item?.variant?.pictures[0]?.image_urls?.small ?? null,
            quantity: item.quantity,
            variant: {
                id: item?.variant?.id ?? null,
                sku: item?.variant?.sku ?? null,
                colorTitle: item?.variant?.color?.title ?? null,
                colorCodes: item?.variant?.color?.codes ?? null,
                mixable: item?.variant?.mixable ?? null,
                product: {
                    slug: item?.variant?.product?.slug ?? null,
                    sku: item?.variant?.product?.sku ?? null,
                    brand: item?.variant?.product?.brand ?? null,
                    name: item?.variant?.product?.name ?? null,
                    category: {
                        categoryId: item?.variant?.product?.category?.category_id ?? null,
                        subCategoryId: item?.variant?.product?.category?.sub_category_id ?? null,
                        title: item?.variant?.product?.category?.title ?? null,
                        permalink: item?.variant?.product?.category?.permalink ?? null,
                    },
                },
            },
            priceCents: item?.price_cents ?? null,
            totalCents: item?.total_cents ?? null,
            rentalPlanId: item?.rental_plan_id ?? null,
            selectedPlan: item?.minimum_term_months ?? null,
            purchaseOption: {
                monthsAfterRrp: item?.buyout?.months_to_own?.months_after_rrp ?? null,
                monthsRequired: item?.buyout?.months_to_own?.months_required ?? null,
                straightPrice: item?.buyout?.months_to_own?.straight_price_cents ?? null,
                marketPrice: item?.buyout?.months_to_own?.market_price_cents ?? null,
            },
            buyoutMonths: item?.buyout?.months_to_own?.months_required ?? null,
            oldPrice: item?.product?.old_price ?? null,
            product: item?.product,
            variants: item?.product?.variants,
            rentalPlans: item?.product?.rental_plans,
        }));
    }

    if (mode === 'US') {
        return items.map((item: any) => {
            const { meta, quantity, id } = item;
            return {
                id: id || null,
                name: meta?.product?.name ?? null,
                coreAttribute: meta?.product?.coreAttribute ?? null,
                imageUrl: meta?.variant?.imageUrl ?? null,
                priceCents: meta?.price?.inCents ?? null,
                totalCents: meta?.total?.inCents ?? null,
                selectedPlan: meta?.committedMonths ?? null,
                quantity,
                variant: {
                    id: meta?.variant?.id,
                    sku: meta?.variant?.sku,
                    colorTitle: meta?.variant?.colorTitle,
                    colorCodes: meta?.variant?.colorCodes,
                    mixable: meta?.variant?.mixable,
                    product: {
                        slug: meta?.product?.slug ?? null,
                        sku: meta?.product?.sku ?? null,
                        category: {
                            categoryId: item?.meta?.category?.categoryId ?? null,
                            subCategoryId: item?.meta?.category?.subCategoryId ?? null,
                        },
                    },
                },
            };
        });
    }

    return items.map((item: any) => {
        const { meta, quantity, id } = item;
        return {
            id: id || null,
            name: meta?.product?.name ?? null,
            coreAttribute: meta?.product?.coreAttribute ?? null,
            imageUrl: meta?.variant?.imageUrl ?? null,
            quantity,
            variant: {
                id: meta?.variant?.id,
                sku: meta?.variant?.sku,
                colorTitle: meta?.variant?.colorTitle,
                colorCodes: meta?.variant?.colorCodes,
                mixable: meta?.variant?.mixable,
                product: {
                    slug: meta?.product?.slug ?? null,
                    sku: meta?.product?.sku ?? null,
                    category: {
                        categoryId: item?.meta?.category?.categoryId ?? null,
                        subCategoryId: item?.meta?.category?.subCategoryId ?? null,
                    },
                },
            },
        };
    });
};

export const mapPayment = (payment: any): any => {
    return {
        id: payment.id ?? null,
        type: payment.type ?? null,
        flowType: payment.flow_type ?? null,
        contractType: payment.contract_type ?? null,
        paymentGatewayType: payment.payment_gateway_type ?? null,
        description: payment.description ?? null,
        number: payment.number ?? null,
        meta: payment.meta ?? null,
        billingAccountId: payment.billing_account_id ?? null,
    };
};

export const fillAddress = ({ cart, userAddresses }: any) => {
    const defaultShipping = userAddresses.find(
        (address: any) => address['is_current_ship_address?'],
    );
    const defaultBilling = userAddresses.find(
        (address: any) => address['is_current_bill_address?'],
    );
    if (defaultShipping || defaultBilling) {
        return {
            ...cart,
            shippingAddressId: defaultShipping?.id ?? null,
            homeAddressId: defaultBilling?.id ?? null,
        };
    }
    return { ...cart };
};

export const fillPayment = ({ cart, userPayments, orderMode }: any) => {
    let paymentMethodList = [...userPayments];
    if (orderMode === OrderModes.Mix) {
        paymentMethodList = paymentMethodList.filter(
            (method: any) => method.billing_account_id !== null,
        );
    }
    if (paymentMethodList.length > 0) {
        const defaultPayment = paymentMethodList[0];
        return {
            ...cart,
            paymentMethod: defaultPayment ? mapPayment(defaultPayment) : null,
        };
    }
    return { ...cart };
};

export const orderMapper = ({ order, mode, ...context }: any): OrderProps | {} => {
    const { user } = context;

    const isValidAddressId = (addressId: number): number | null =>
        !!findAddressById({
            userAddresses: context.userAddresses,
            addressId,
        })
            ? addressId
            : null;

    const { lineItems, ...rest } = order;

    if (mode === OrderModes.Flex && context?.activeStore?.code?.toLowerCase() !== 'us') {
        return {
            orderNumber: order.number,
            guestToken: order.guest_token,
            shippingAddressId: isValidAddressId(user?.ship_address?.id ?? null),
            homeAddressId: isValidAddressId(user?.bill_address?.id ?? null),
            items: mapItems({ items: order.items, mode: 'FLEX' }),
            itemTotal: order.item_total_cents,
            shipmentPrice: order.shipment_total_cents,
            discountTotal: order?.voucher?.discount_cents ?? null,
            voucher: order.voucher,
            totalPrice: order.total_cents,
            storeCode: order.store_code,
            paymentMethod: order?.payment_method ? mapPayment(order?.payment_method) : null,

            // shippingAddressId, homeAddressId, paymentMethod are based on User data
            orderPaymentMethod: order?.payment_method ? mapPayment(order?.payment_method) : null,
            orderShipAddress: order?.shipping_address_id,
            orderBillAddress: order?.billing_address_id,
        };
    }

    const isUS = context?.activeStore?.code?.toLowerCase() === 'us';

    return {
        ...rest,
        shippingAddressId: order?.shippingAddressId ?? null,
        homeAddressId: order?.billingAddressId ?? null,
        items: mapItems({
            items: order?.MixLineItem ?? order.lineItems,
            mode: isUS ? 'US' : 'MIX',
        }),
        totalPrice: isUS ? order?.total?.inCents : 9900,
        itemTotal: isUS ? order?.itemTotal?.inCents : 9900,
        shipmentPrice: isUS ? order?.shippingCost?.inCents : null,
        discountTotal: isUS
            ? order?.itemTotalDiscount?.inCents
            : order?.pricingDetails?.discountAmount?.inCents ?? null,
        voucher: order?.pricingDetails?.voucherCode ?? null,
        paymentMethod: findPaymentMethodById({
            ...context,
            billingAccountId: order?.paymentMethod?.billingAccountId,
            paymentMethodId: order?.paymentMethod?.paymentMethodId,
        }),
    };
};

/* eslint-disable no-empty-pattern */
import { registerVoucherValidationV2 } from 'ggApp/modules/gaAnalytics/actions';
import loadConfiguration from 'ggApp/utils/configuration';

import {
    saveOrder,
    getOrderFromCookies,
    deleteOldCookies,
} from 'ggApp/shared/context/Order/cookies';
import CookiesManager from 'ggApp/utils/CookiesManager';
import { IP_ADDRESS_COOKIE_NAME } from 'ggApp/utils/ipAddressCookie/constants';
import { USER_AGENT } from 'ggApp/utils/userAgent/constants';
import { client } from './client';
import { APISelection, CartItemModel, CheckoutMachine } from '../integration';

const { APP_CONFIG } = loadConfiguration();

const { apiV2Url } = APP_CONFIG;
export interface OrderApiSelection extends APISelection<any> {
    submitVoucher?: (
        ctx: CheckoutMachine,
        event: { userID: number; voucher: string; order: CartItemModel },
    ) => Promise<CartItemModel>;
    resetVoucher?: (
        ctx: CheckoutMachine,
        event: { userID: number; voucher: string; order: CartItemModel },
    ) => Promise<CartItemModel>;
}
export const submitVoucher = ({ orderNumber }, event: any) => {
    const { userID, voucher } = event;

    return client({
        endpoint: `orders/${orderNumber}/apply_coupon`,
        method: 'patch',
        body: { coupon_code: voucher },
    }).then((res): object => {
        registerVoucherValidationV2({ eventLabel: 'valid', orderNumber, userID, voucher });

        return res.data;
    });
};
export const resetVoucher = ({ orderNumber }, event: any) => {
    return client({
        endpoint: `orders/${orderNumber}/reset_coupon`,
        method: 'patch',
    }).then((res): object => res.data);
};

export const order: OrderApiSelection = {
    get: async (context: any) => {
        const { activeStore, customizeColorFlag, customizePlanFlag } = context;
        const orderNumber = getOrderFromCookies('FLEX', activeStore.code) || null;
        if (!orderNumber) return null;

        try {
            const includeProduct =
                customizePlanFlag || customizeColorFlag ? '?include_product=true' : '';

            const { data } = await client({
                endpoint: `orders/${orderNumber}${includeProduct}`,
                method: 'get',
            });
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    add: async ({}, event) => {
        const itemPayload = event?.items.map(
            (item: {
                variant: { id: number; sku: string };
                quantity: number;
                minimumMonths: number;
            }) => ({
                any_variant: false,
                variant_sku: item.variant.sku,
                quantity: item.quantity,
                committed_months: item.minimumMonths,
            }),
        );
        const { data } = await client({
            endpoint: `${apiV2Url}/orders`,
            method: 'post',
            body: { items: itemPayload },
        });
        deleteOldCookies('FLEX', data.order.store_code);
        saveOrder({
            orderMode: 'FLEX',
            storeCode: data.order.store_code,
            orderNumber: data.order.number,
            orderToken: data.order.guest_token,
        });
        if (event?.callback && typeof event?.callback === 'function') {
            event?.callback(data.order.number);
        }
        return data;
    },
    submit: ({ orderNumber }, event) => {
        const cookiesManager: any = new CookiesManager();
        const { meta } = event?.data ?? event;
        let payload: any = {
            id: orderNumber,
            order_id: orderNumber,
            meta,
            user_ip_address: cookiesManager.get(IP_ADDRESS_COOKIE_NAME),
            user_agent: cookiesManager.get(USER_AGENT),
        };

        if (cookiesManager.get('fbclid')) {
            payload = {
                ...payload,
                marketing_source_reference: 'facebook',
                marketing_campaign_reference: cookiesManager.get('fbclid'),
            };
        }

        return client({
            endpoint: `orders/${orderNumber}/complete`,
            method: 'patch',
            body: payload,
        }).then((res): object => {
            cookiesManager.remove('fbclid', { path: '/' });
            return res.data;
        });
    },
};

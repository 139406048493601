import {
    lightBaseTheme as lightSonntagTheme,
    darkBaseTheme as darkSonntagTheme,
    palette,
} from '@getgrover/ui';

import baseTheme from './baseTheme';

const lightTheme = {
    ...lightSonntagTheme,
    ...baseTheme,
    name: 'light',
    colors: {
        ...lightSonntagTheme.colors,
        ...baseTheme.colors,
    },
    typography: {
        ...lightSonntagTheme.typography,
        ...baseTheme.typography,
    },
    palette,
};

const darkTheme = {
    ...darkSonntagTheme,
    ...baseTheme,
    name: 'dark',
    colors: {
        ...darkSonntagTheme.colors,
        ...baseTheme.colors,
    },
    typography: {
        ...lightSonntagTheme.typography,
        ...baseTheme.typography,
    },
    palette,
};

export { lightTheme, darkTheme };

import React from 'react';
import PropTypes from 'prop-types';

import axios from 'ggApp/utils/requests';
import { getUrlPrefix } from 'ggApp/utils/storeLangInjection';
import { fireEvent } from 'ggApp/modules/inwebview_api';
import { setLocaleCookie, getStoreDefaultLanguage } from 'ggApp/modules/store/utils';

import DropDown from '../DropDown';
import { Icon, CrawlableStoreLink } from './styled';

class StoreSelector extends React.PureComponent {
    constructor(props) {
        super(props);
        const { filteredStoreList, selectedIndex } = props;
        const selectedItem = filteredStoreList && filteredStoreList[selectedIndex];
        this.state = {
            selected: selectedItem && selectedItem.value ? selectedItem.value : null,
        };
    }

    isLegacyStore = (store) => store && store.store_type === 'legacy' && store.url;

    changeUserStorePreference = (storeCode) => {
        axios.get(`/stores/${storeCode}`).then((result) => {
            const storeData = result && result.data && result.data.store;
            const defaultLanguage = storeData.default_language;
            const randomLangFromList = Array.isArray(storeData.languages) && storeData.languages[0];
            const language = defaultLanguage || randomLangFromList;
            setLocaleCookie(language);
            if (window) {
                window.location.href = getUrlPrefix({ storeCode, language });
            }
        });
    };

    handleChange = (value) => {
        fireEvent('STORE_CHANGED', {});
        this.setState({ selected: value });
        this.changeUserStorePreference(value);
    };

    render() {
        const { className, filteredStoreList, minimal } = this.props;
        const { selected, shouldOpenDropDown } = this.state;
        const currentLanguageLabel =
            (filteredStoreList.find((t) => t.value === selected) || {}).label || selected;

        return (
            <>
                <DropDown
                    className={className}
                    shouldOpenDropDown={shouldOpenDropDown}
                    minimal={minimal}
                    currentLabel={currentLanguageLabel}
                    handleChange={this.handleChange}
                    selected={selected}
                    optionsList={filteredStoreList}
                    iconComponent={Icon}
                />
                {filteredStoreList.map((store) => {
                    const language = getStoreDefaultLanguage(store.value);
                    return (
                        <CrawlableStoreLink key={store.value} href={`/${store.value}-${language}`}>
                            {store.label}
                        </CrawlableStoreLink>
                    );
                })}
            </>
        );
    }
}

StoreSelector.propTypes = {
    className: PropTypes.string,
    filteredStoreList: PropTypes.arrayOf(PropTypes.object),
    selectedIndex: PropTypes.number,
    minimal: PropTypes.bool,
};

StoreSelector.defaultProps = {
    className: '',
    filteredStoreList: [],
    selectedIndex: 0,
    storeList: [],
    routes: [],
    params: {},
    storeActions: {},
    minimal: false,
};

export default StoreSelector;

import getLanguageList from 'ggApp/utils/storeLanguageList';
import { STORE_TYPE } from 'ggApp/modules/store/constants';

const mapStoreType = (storeType) => {
    switch (storeType) {
        case 'main-business':
            return STORE_TYPE.BUSINESS;
        case 'main':
            return STORE_TYPE.MAIN;
        case 'grover_pay':
            return STORE_TYPE.GROVER_PAY;
        case 'retail':
            return STORE_TYPE.RETAIL;
        default:
            throw new Error('Unknown store type in static configuration');
    }
};

const mapStoreDeliveryEstimation = (deliveryDaysFrom, deliveryDaysTo) => {
    // avoid `undefined` appearing
    // as one of the days
    if (!deliveryDaysFrom || !deliveryDaysTo) return null;
    return {
        from: deliveryDaysFrom,
        to: deliveryDaysTo,
    };
};

export const mapStore = (store) => ({
    type: mapStoreType(store.store_type),
    languages: getLanguageList(store),
    storeCode: store.code.toLowerCase(),
    countryCode: store.country_code,
    disableSignup: Boolean(store.is_signup_disabled),
    disableBusinessCustomerSignup: Boolean(store.is_business_signup_disabled),
    disablePrivateCustomerSignup: Boolean(store.is_private_signup_disabled),
    cookieConsentModalDisabled: Boolean(store.is_consent_cookie_modal_disabled),
    deliveryEstimation: mapStoreDeliveryEstimation(
        store.delivery_days_from,
        store.delivery_days_to,
    ),
    enabled: store.enabled_in_store_selector,
    showNationalIdStep: store.show_national_id_step,
});

// todo: fix accessibility of partner store pages on backoffice level
export const isExcludedForSeo = (store) => {
    const isPartnerStore = [STORE_TYPE.GROVER_PAY, STORE_TYPE.RETAIL, null].includes(
        store.store_type,
    );
    const isExcludedBusinessStore = store.code.startsWith('business_');
    return isPartnerStore || isExcludedBusinessStore;
};

import React from 'react';
import PropTypes from 'prop-types';

import { extractCallbacksFromProps } from 'ggApp/modules/tracking/hocs/withListEventHandlers';

import { GridWrap, ProductCard } from './styles';

export default class TopProducts extends React.PureComponent {
    render() {
        const { className, products, productImpressionCallbacks, userDevice, testId } = this.props;

        return (
            <GridWrap noMargin className={className} testId={testId}>
                {(products || []).map((product, index) => {
                    const { slug } = product;
                    return (
                        <ProductCard
                            key={slug}
                            product={product}
                            cardType={userDevice === 'mobile' ? 'horizontal' : 'vertical'}
                            {...extractCallbacksFromProps(productImpressionCallbacks, index)}
                        />
                    );
                })}
            </GridWrap>
        );
    }
}

TopProducts.propTypes = {
    className: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.object),
    store: PropTypes.shape({}),
    productImpressionCallbacks: PropTypes.arrayOf(PropTypes.func),
};

TopProducts.defaultProps = {
    className: '',
    products: [],
    store: {},
    productImpressionCallbacks: [],
};

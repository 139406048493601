import styled from 'styled-components';

import Message from 'ggApp/shared/icons/Message';

export const Icon = styled(Message)`
    height: 24px;
    width: 24px;
    margin-right: ${({ theme }) => theme.spacers.M};
`;

export const CrawlableLanguageLink = styled.a`
    display: none;
`;

import styled, { css } from 'styled-components';
import { elevation } from 'ggApp/utils/mixins/elevation';
import { SmallHeadline } from 'ggApp/shared/common/Typography';
import NavigationLink from 'ggApp/shared/common/NavigationLink';

export const MobileTaskBarContainer = styled.div`
    ${({ inWebView }) => inWebView
        && css`
            background: #000;
    `}
`;

export const MobileTaskBarWrap = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 25px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.ElementBackground};
    border-radius: 8px 8px 0px 0px;
    ${elevation(3, false)};
    z-index: 7;
    align-items: center;

    ${({ inWebView }) => inWebView
        && css`
            display: none;
    `}
`;

export const Title = styled(SmallHeadline)`
    color: ${({ theme }) => theme.colors.Foreground};
    font-size: 14px;
    margin-top: ${({ theme }) => theme.spacers.S};
`;

export const AccountItem = styled.div`
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    opacity: 0.5;
`;

export const StyledNavigationLink = styled(NavigationLink)`
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    opacity: 0.5;

    ${({ isActive }) => isActive
        && css`
            opacity: 1;
    `}
`;

import React from 'react';
import { Transformation, Image } from 'cloudinary-react';
import PropTypes from 'prop-types';
import loadConfiguration from 'ggApp/utils/configuration';

import { ImageContainer, CloudinaryImage } from './styles';

const { APP_CONFIG } = loadConfiguration();
const { cloudinarySpace } = APP_CONFIG;

export const defaultTransformations = [
    { effect: 'trim' },
    { quality: 'auto', fetchFormat: 'auto' },
];

export const TransformingImage = ({
    className,
    src,
    onLoad,
    alt,
    onError,
    transformations,
    width,
    height,
    noCss = false,
}) => {
    const cloudId = src && src.split('image/upload/')[1];
    if (!cloudId) return null;
    const imageTransformations = transformations?.length
        ? [...transformations]
        : defaultTransformations;

    const ImageComponent = ({ children }) => {
        if (noCss) {
            return (
                <Image
                    cloudName={cloudinarySpace}
                    publicId={cloudId}
                    onLoad={onLoad}
                    alt={alt}
                    onError={onError}
                    width={width}
                    height={height}
                >
                    {children}
                </Image>
            );
        }

        return (
            <CloudinaryImage
                cloudName={cloudinarySpace}
                publicId={cloudId}
                onLoad={onLoad}
                alt={alt}
                onError={onError}
                width={width}
                height={height}
            >
                {children}
            </CloudinaryImage>
        );
    };

    return (
        <ImageContainer className={className}>
            {cloudId && (
                <ImageComponent>
                    {imageTransformations.map((props, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Transformation key={index} {...props} />
                    ))}
                </ImageComponent>
            )}
        </ImageContainer>
    );
};

TransformingImage.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
};

TransformingImage.defaultProps = {
    className: undefined,
    alt: undefined,
    onLoad: () => null,
    onError: () => null,
    transformations: defaultTransformations,
};

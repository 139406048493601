export const MODULE_NAME = 'tracking';

export const PRODUCT_CTA_RESERVE_CLASS = 'reserve_cta';
export const PRODUCT_CTA_CROSSSELL_CLASS = 'wl_cross_sell';
export const PRODUCT_CTA_SUBSCRIBE_CLASS = 'subscribe_cta';
export const KINESIS_STREAM_FIRST_REQUEST = 'grover_first_request';
export const KINESIS_STREAM_VIEW_RENTAL_PLAN = 'grover_view_plan';
export const CART_CACHE_KEY = 'cache-key';
export const TRACKING_COOKIE_NAME = 'ggId';
export const IP_COOKIE_NAME = 'original_request_ip';

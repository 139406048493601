import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import useMount from 'ggApp/shared/hooks/useMount';
import { ReviewsIODataType, fetchReviews } from 'ggApp/shared/components/ReviewIO/fetchReviews';
import Stars from 'ggApp/shared/icons/Stars';
import { scoreToPercentage } from 'ggApp/shared/components/ReviewIO/utils';
import ReviewIOLogo from 'ggApp/shared/icons/ReviewIOLogo/FullLogo';
import { PureTranslator } from 'ggApp/modules/translator/components';
import withDeviceInfo from 'ggApp/shared/components/viewport/withDeviceInfo';
import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';

import { ReviewCard } from 'ggApp/shared/components/ReviewIO/components/ReviewCard';

import { Locale } from 'ggApp/types/common';
import {
    ReviewsContainer,
    SummaryText,
    SummaryWord,
    Bold,
    Carousel,
    ReviewIOLogoWrapper,
} from './styles';

interface ReviewCarouselProps {
    userDevice: string;
    activeStore: {
        activeLanguage: string;
        code: string;
    };
    setReviewsFetched?: (state: boolean) => void;
}

export const ReviewCarousel = ({
    userDevice,
    activeStore: { activeLanguage, code },
    setReviewsFetched,
}: ReviewCarouselProps) => {
    const theme = useContext(ThemeContext);
    const [reviewsData, setReviewsData] = useState<ReviewsIODataType | null>(null);

    let reviewTag = activeLanguage;

    if (activeLanguage === 'en' && code !== 'us') {
        reviewTag = 'eu-en';
    }

    if (code === 'de' || code === 'business') {
        reviewTag = Locale.de;
    }

    useMount(() => {
        fetchReviews(reviewTag).then((data) => setReviewsData(data));
    });
    if (reviewsData?.error) {
        return null;
    }
    if (reviewsData) {
        if (setReviewsFetched) {
            setReviewsFetched(true);
        }
        const { data } = reviewsData;
        const percentage = scoreToPercentage(data?.stats?.average_rating);
        const mobile = userDevice === 'mobile' || userDevice === 'tablet';
        return (
            <>
                <ReviewsContainer>
                    {!mobile && (
                        <SummaryText>
                            <SummaryWord>
                                <PureTranslator tk="REVIEWS_HEADER_WORD" />
                            </SummaryWord>
                            <Stars percentage={percentage} fillColor={theme.colors.Foreground} />{' '}
                            <Bold>
                                <PureTranslator
                                    tk="REVIEWS_HEADER_SUMMARY"
                                    withValues={{
                                        average: data?.stats?.average_rating,
                                        total: data?.stats?.total_reviews,
                                    }}
                                />
                            </Bold>
                        </SummaryText>
                    )}
                    <Carousel data-testid="review-carousel">
                        {data?.reviews &&
                            data?.reviews?.map((review) => (
                                <ReviewCard
                                    key={review.store_review_id}
                                    review={review}
                                    storeCode={code}
                                />
                            ))}
                    </Carousel>
                </ReviewsContainer>
                {mobile ? (
                    <SummaryText>
                        <div>
                            <SummaryWord>
                                <PureTranslator tk="REVIEWS_HEADER_WORD" />
                            </SummaryWord>
                            <Stars percentage={percentage} fillColor={theme.colors.Foreground} />
                        </div>
                        <Bold>
                            <PureTranslator
                                tk="REVIEWS_HEADER_SUMMARY"
                                withValues={{
                                    average: data?.stats?.average_rating,
                                    total: data?.stats?.total_reviews,
                                }}
                            />
                        </Bold>
                    </SummaryText>
                ) : (
                    <ReviewIOLogoWrapper>
                        <ReviewIOLogo width="120" />
                    </ReviewIOLogoWrapper>
                )}
            </>
        );
    }
    return null;
};

const enhance = compose(
    connect(
        createStructuredSelector({
            activeStore: activeStoreSelector,
        }),
    ),
    withDeviceInfo,
);

export default enhance(ReviewCarousel);

import React from 'react';
import { interpret } from 'xstate';
import CookiesManager from 'ggApp/utils/CookiesManager';
import { getCheckoutCountry } from 'ggApp/shared/context/Order/helpers/countryHelpers';
import { getTrackingHooks } from 'ggApp/shared/context/Order/services/tracking';

import { createNewCheckoutMachine } from './machine';
import { OrderContext } from './OrderContext';
import { createConfig } from './services';

export class OrderProvider extends React.Component {
    constructor(props) {
        super(props);

        const {
            orderMode,
            user,
            activeStore,
            newCheckoutFlag,
            redirectionFlow,
            newCheckoutFlow,
            snowplowCallbacks,
            customizeColorFlag,
            customizePlanFlag,
            linkProductsInCartFlag,
        } = props;

        const cookiesManager = new CookiesManager();
        const config = createConfig(
            orderMode,
            activeStore.code || cookiesManager.get('shop_country_code'),
        );

        const options = {
            ...config,
            actions: {
                ...config.actions,
                ...getTrackingHooks({
                    newCheckoutFlag,
                }),
                ...snowplowCallbacks,
            },
        };
        const checkoutMachine = createNewCheckoutMachine({
            activeStore,
            options,
            orderMode,
            redirectionFlow,
            user,
            newCheckoutFlow,
            newCheckoutFlag,
            customizeColorFlag,
            customizePlanFlag,
            linkProductsInCartFlag,
        });

        this.state = {
            current: checkoutMachine.initialState,
        };

        this.service = interpret(checkoutMachine, { devTools: true }).onTransition(this.transition);

        this.checkoutMachine = checkoutMachine;
    }

    componentDidMount() {
        this.service.start();
    }

    componentDidUpdate(prevProps) {
        const { send } = this.service;
        const {
            user = {},
            activeStore = {},
            newCheckoutFlow,
            customizeColorFlag,
            customizePlanFlag,
        } = this.props;

        const {
            user: prevUser = {},
            activeStore: prevActiveStore = {},
            newCheckoutFlow: prevNewCheckoutFlow,
            customizeColorFlag: prevCustomizeColorFlag,
            customizePlanFlag: prevCustomizePlanFlag,
        } = prevProps;

        if (
            prevCustomizeColorFlag !== customizeColorFlag ||
            customizePlanFlag !== prevCustomizePlanFlag
        ) {
            send('UPDATE_FLAGS', { customizeColorFlag, customizePlanFlag });
        }

        if (!prevUser?.id && user?.id) {
            send('SET_USER', { data: user });
        }

        if (!prevActiveStore.code && activeStore.code) {
            send('UPDATE_ACTIVE_STORE', { activeStore });
        }

        if (prevNewCheckoutFlow !== newCheckoutFlow) {
            send('UPDATE_CHECKOUT_FLOW', { data: newCheckoutFlow });
        }
    }

    componentWillUnmount() {
        this.service.stop();
    }

    transition = (current) => this.setState({ current });

    updateMachine = (mode) => {
        const config = createConfig(mode);

        this.service.stop();

        const { current } = this.state;
        const newMachine = this.checkoutMachine
            .withConfig({ ...this.checkoutMachine.config, ...config })
            .withContext({ ...current.context, orderMode: mode, orderNumber: null });

        this.checkoutMachine = newMachine;
        this.service = interpret(newMachine, { devTools: true }).onTransition(this.transition);

        this.service.start(newMachine.initialState);
        return this.service;
    };

    render() {
        // todo: newCheckoutFlag is not acute in the machine, it needs to be updated in componentDidUpdate
        const { activeStore, children, user, newCheckoutFlag } = this.props;
        const { current } = this.state;
        const { send } = this.service;
        const {
            addressError,
            phoneVerificationConfirmError,
            phoneVerificationPending,
            cart,
            inEditMode,
            orderMode,
            isOverlayActive,
            userDataFetched,
            redirectionFlow,
            unavailableSku,
            userAddresses,
            userPayments,
            cartLoaded,
            isCheckoutActive,
            formSubmitting,
            previewVoucher,
            customizeColorFlag,
            customizePlanFlag,
            linkProductsInCartFlag,
        } = current.context || {};

        return (
            <OrderContext.Provider
                value={{
                    cart,
                    activeStore,
                    addressError,
                    phoneVerificationConfirmError,
                    phoneVerificationPending,
                    cartLoaded,
                    countryId: activeStore.country_id,
                    countryName: getCheckoutCountry(activeStore),
                    current,
                    inEditMode,
                    orderMode,
                    orderNumber: cart?.orderNumber ?? null,
                    isOverlayActive,
                    userDataFetched,
                    isCheckoutActive,
                    redirectionFlow,
                    send,
                    unavailableSku,
                    updateMachine: this.updateMachine,
                    user,
                    userAddresses,
                    userPayments,
                    newCheckoutFlag,
                    formSubmitting,
                    previewVoucher,
                    customizeColorFlag,
                    customizePlanFlag,
                    linkProductsInCartFlag,
                }}
            >
                {children}
            </OrderContext.Provider>
        );
    }
}

OrderProvider.defaultProps = {
    orderMode: 'FLEX',
};

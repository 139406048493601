/* eslint-disable */
import React, { PureComponent } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import PropTypes from 'prop-types';
import ScrollLock from 'react-scrolllock-configurable';

import { Content } from './styles';

/**
 * ModalContent
 * This component represents content of the modal.
 * It calculates it's overflowing state and calls `onOverflowUpdate` callback.
 * @class ModalContent
 * @extends {PureComponent}
 */
class ModalContent extends PureComponent {
    static propTypes = {
        onOverflowUpdate: PropTypes.func.isRequired,
        className: PropTypes.string,
        children: PropTypes.node,
    };

    static defaultProps = {
        className: undefined,
        children: null,
    };

    constructor(props) {
        super(props);

        this.createContentRef = this.createContentRef.bind(this);
        this.contentSizeChangeHandler = this.contentSizeChangeHandler.bind(this);
    }

    // It needs to calculate if the content is overflowed after being mounted
    componentDidMount() {
        this.contentSizeChangeHandler();
    }

    /**
     * createContentRef
     * This method saves the ref on Content component.
     * @param {*} element
     * @memberof Modal
     */
    createContentRef(element) {
        this.content = element;
    }

    /**
     * contentSizeChangeHandler
     * This method checks if the Content component is overflowed.
     * @memberof Modal
     */
    contentSizeChangeHandler() {
        const { content } = this;
        const { onOverflowUpdate } = this.props;

        if (content && typeof onOverflowUpdate === 'function') {
            const hasOverflowingContent = content.offsetHeight < content.scrollHeight;

            onOverflowUpdate(hasOverflowingContent);
        }
    }

    render() {
        const { children, className, isShown } = this.props;

        return (
            // Using ScrollLock to prevent body scroll.
            <ScrollLock isActive={isShown}>
                <Content className={className} ref={this.createContentRef}>
                    {children}
                    <ReactResizeDetector
                        handleWidth
                        handleHeight
                        refreshMode="debounce"
                        refreshRate={1000}
                        onResize={this.contentSizeChangeHandler}
                    />
                </Content>
            </ScrollLock>
        );
    }
}

export default ModalContent;

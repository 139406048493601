import React from 'react';

import { product as productUrlBuilder } from 'ggApp/utils/urlBuilder/frontshop';

import ChipMapper from 'ggApp/shared/components/cards/Chips';

import { EnergyClass } from 'ggApp/shared/components/cards/ProductDetailsCard/EnergyLabels/styles';
import { energyRatingIcon } from 'ggApp/shared/components/cards/ProductDetailsCard/EnergyLabels/utils/energyLabelsMapper';
import { MappedProductType } from 'ggApp/modules/product/constants/MappedProductType';
import { isAppUser } from 'ggApp/routes/_wrap/MobileWrapper/utils';

import {
    ChipsBlock,
    Description,
    FavoriteButton,
    FavoriteButtonGap,
    HorizontalRow,
    Image,
    ImageContainer,
    Name,
    PriceBlock,
    RootDiv,
    RootLink,
    TextWrap,
} from './horizontalStyles';

type HorizontalCardType = {
    className: string;
    product: MappedProductType;
    style?: React.CSSProperties;
    onClick: () => void;
    onClickCallback: ({ isFavorite }: { isFavorite: boolean }) => void;
    browserLink: boolean;
    nodeRef?: unknown;
    noCssOnImage: boolean;
    hidePrices?: boolean;
};

export const HorizontalCard = ({
    className = '',
    product: {
        id,
        productName = '',
        slug = '',
        productCoreAttributes = '',
        selectedMinDuration,
        rentalPlan,
        oldPrice,
        price,
        chips = [],
        originalImage,
        energyClass,
    },
    style,
    onClick,
    onClickCallback,
    browserLink,
    nodeRef,
    noCssOnImage = false,
    hidePrices = false,
}: HorizontalCardType) => {
    const Container = slug ? RootLink : RootDiv;

    const ImageTransform = [
        { quality: 'auto', fetchFormat: 'webp' },
        { effect: 'trim' },
        { width: '80', height: '80', crop: 'pad', background: 'white', dpr: '2.0' },
    ];

    // Skip setting selectedMinDuration if React Native App due to bug (CNV-545)
    const isGroverApp = isAppUser();
    const minDuration = isGroverApp ? false : selectedMinDuration;

    return (
        <Container
            className={className}
            style={{ ...style }}
            to={productUrlBuilder(slug, minDuration)}
            cardType="horizontal"
            onClick={onClick}
            browserLink={browserLink}
        >
            {/* Passing nodeRef to ImageContainer since it is a div and scrolls as part of the container */}
            <ImageContainer ref={nodeRef}>
                <Image
                    className={className}
                    src={originalImage}
                    alt={productName}
                    transformations={ImageTransform}
                    width="80"
                    height="80"
                    noCss={noCssOnImage}
                />
            </ImageContainer>
            <TextWrap>
                <HorizontalRow>
                    {chips && chips.length > 0 ? (
                        <ChipsBlock>
                            <ChipMapper chips={chips} selectedRentalPlan={rentalPlan} />
                        </ChipsBlock>
                    ) : null}
                    {energyClass ? (
                        <EnergyClass icon={energyRatingIcon[energyClass]} width={29} height={16} />
                    ) : null}
                </HorizontalRow>
                <Name>
                    <FavoriteButtonGap />
                    {productName}
                </Name>
                {productCoreAttributes !== '' && <Description>{productCoreAttributes}</Description>}
                {!hidePrices ? (
                    <PriceBlock
                        oldPrice={oldPrice}
                        price={price}
                        selectedMinDuration={selectedMinDuration}
                    />
                ) : null}
            </TextWrap>
            <FavoriteButton id={id} slug={slug} onClickCallback={onClickCallback} />
        </Container>
    );
};

import styled from 'styled-components';

import { SmallCapsHeadline, SubHeadline, Paragraph } from 'ggApp/shared/common/Typography';
import Link from 'ggApp/shared/common/Button/Link';
import { Nessie } from 'ggApp/shared/screens/ErrorPage/NotFound/images/Nessie';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${({ theme }) => theme.spacers.M};
`;

const Image = styled(Nessie)`
    width: 30%;
    height: auto;
    margin: 0 auto;
    ${props => props.theme.superQuery.all.and.maxWidth.mobileMax.css`
         width: 90%;
    `}

    ${props => props.theme.superQuery.minWidth.tabletMin.and.maxWidth.tabletMax
        .css`
        width: 40%;
    `}
`;

const ErrorText = styled(SmallCapsHeadline)`
    margin-top: ${({ theme }) => theme.spacers.L};
    color: ${props => props.theme.colors.Foreground};
`;

const Title = styled(SubHeadline)`
    margin-top: ${({ theme }) => theme.spacers.L};
    color: ${props => props.theme.colors.Foreground};
    text-align: center;
`;

const Text = styled(Paragraph)`
    margin-top: ${({ theme }) => theme.spacers.L};
    color: ${props => props.theme.colors.Foreground};
    text-align: center;
    width: 50%;

    ${props => props.theme.superQuery.all.and.maxWidth.mobileMax.css`
        width: 90%;
    `}
`;

const LinkButton = styled(Link)`
    margin-top: ${({ theme }) => theme.spacers.L};
    text-decoration: none;
    background-color: ${({ theme }) => theme.colors['Level 1']};
    color: ${({ theme }) => theme.colors.Foreground};
    &:hover, &:focus {
        background: ${({ theme }) => theme.colors["Level 3"]};
        color: ${({ theme }) => theme.colors.Foreground};
    }
    &:active {
        background: ${({ theme }) => theme.colors["Level 2"]};
        color: ${({ theme }) => theme.colors.Foreground};
    }
`;

export {
    Container, Image, ErrorText, Text, Title, LinkButton,
};

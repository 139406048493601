import ActionTypes from './actionTypes';
import { Action } from './actions';

export const defaultState: NotificationStateType = false;

export type NotificationStateType = boolean;

export default function reducer(
    state: NotificationStateType = defaultState,
    action: Action,
): NotificationStateType {
    if (action.type === ActionTypes.DISPLAY_NOTIFICATION) {
        return true;
    }
    if (action.type === ActionTypes.HIDE_NOTIFICATION) {
        return false;
    }

    return state;
}

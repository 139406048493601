import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';

import { registerAddToCart } from 'ggApp/modules/gaAnalytics/actions';
import { criteoCartCheckoutUpdate } from 'ggApp/modules/criteo/actions';
import { config as axiosConfigSelector } from 'ggApp/modules/axiosConfig/selectors';
import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import { user as userSelector } from 'ggApp/modules/auth/selectors';
import { Provider } from './Context';

const enhance = compose(
    connect(
        createStructuredSelector({
            axiosConfig: axiosConfigSelector,
            activeStore: activeStoreSelector,
            user: userSelector,
        }),
        (dispatch) => ({
            registerAddToCart: bindActionCreators(registerAddToCart, dispatch),
            criteoCartCheckoutUpdate: bindActionCreators(criteoCartCheckoutUpdate, dispatch),
        }),
    ),
);

export { CartContext } from './Context';
export const CartContextProvider = enhance(Provider);

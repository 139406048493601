/* eslint-disable */
import { GET_STATUSES, GET_STATUSES_SUCCESS, GET_STATUSES_ERROR, } from './../actionTypes';

const defaultState = {
  loading: null,
  error: {},
  data: {
    requests: [],
    current: [],
    past: [],
  },
};

export default function (state = defaultState, action) {
  switch (action.type) {

    case GET_STATUSES:
      return Object.assign({}, state, {
        loading: true,
      })

    case GET_STATUSES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.data,
      })

    case GET_STATUSES_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      })

    default:
      return state;
  }
}

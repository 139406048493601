// the sorting logic here should be the same as in src/ggApp/shared/components/widgets/utils/widgetAdapter.js?
export const extractProductsFromWidgets = (widgetList) => {
    if (!widgetList) {
        return {
            topProducts: {},
            newProducts: {},
            category1: {},
            category2: {},
            mostPopular: {},
            handpicked1: {},
            handpicked2: {},
            handpicked3: {},
            deals: {},
        };
    }
    const topProductsWidget = widgetList.find((widget) => widget.type === 'TOP_PRODUCTS');
    const newProductsWidget = widgetList.find((widget) => widget.type === 'NEW_PRODUCTS');
    const categoryWidgets = widgetList.filter(
        (widget) => widget.type === 'RANDOM_CATEGORY_TOP_PRODUCTS',
    );
    const mostPopularProducts = widgetList.find((widget) => widget.type === 'TOP_ORDERED_PRODUCTS');
    const handpickedProductsWidgets = widgetList.filter(
        (widget) => widget.type === 'HANDPICKED_PRODUCT',
    );
    const dealProductsWidget = widgetList.find((widget) => widget.type === 'DEAL_PRODUCTS');

    const concat = (...arrays) => [].concat(...arrays.filter(Array.isArray));

    return concat(
        topProductsWidget?.products ?? null,
        newProductsWidget?.products ?? null,
        categoryWidgets[0]?.products ?? null,
        categoryWidgets[1]?.products ?? null,
        mostPopularProducts?.products ?? null,
        handpickedProductsWidgets[0]?.products ?? null,
        handpickedProductsWidgets[1]?.products ?? null,
        handpickedProductsWidgets[2]?.products ?? null,
        dealProductsWidget?.products ?? null,
    );
};

const rentalPlanMapper = (plan) => ({
    id: plan.id,
    minimum_term_months: plan.length.value,
    old_price: plan?.oldPrice?.inCents ?? 0,
    rental_plan_price_cents: plan.price.inCents,
});

export const widgetListProductsMapper = (widgetProducts) => {
    if (!widgetProducts.length) return [];
    return widgetProducts.map((product) => ({
        id: product.id,
        slug: product.slug,
        name: product.name,
        core_attribute: product.coreAttribute,
        original_image_url: product.originalImageUrl,
        promotional_labels: product.promotionalLabels,
        is_new: product.isNew,
        variants: product.variants,
        brand: product.brandName,
        sku: product.sku,
        rental_plans:
            !!product.rentalPlans.length &&
            product.rentalPlans.map((plan) => rentalPlanMapper(plan)),
        cheapest_rental_plan:
            !!product.rentalPlans.length &&
            rentalPlanMapper(product.rentalPlans[product.rentalPlans.length - 1]),
        category: {
            title: product.category.parent?.title,
            permalink: product.category.parent?.permalink,
            sub_category_id: product.category?.id,
            category_id: product.category.parent?.id,
        },
    }));
};

import styled from 'styled-components';

export const Picture = styled.picture`
    height: 100%;
    width: 100%;
`;

export const Image = styled.img`
    object-fit: contain;
    height: 100%;
    width: 100%;
`;

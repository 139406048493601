/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { setGaUserID } from '../../actions';

export default class GaAuthenticationReporter extends React.Component {
    static propTypes = {
        user: PropTypes.shape({}),
    };

    static defaultProps = {
        user: {},
    };

    componentDidMount() {
        const { user } = this.props;
        if (user && user.id) {
            setGaUserID(user.id);
        }
    }

    shouldComponentUpdate() {
        return false;
    }

    render() {
        return null;
    }
}

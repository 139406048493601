import React from 'react';
import withWebView from 'ggApp/shared/hocs/withWebView';
import NotFound from 'ggApp/shared/screens/ErrorPage/NotFound';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { redirectus } from 'ggApp/modules/router/actions';
import { translationFunctionSelector } from 'ggApp/modules/translator';
import { initDone as initAppDoneSelector } from 'ggApp/modules/init/selectors';
import { mainRouteWithStoreAndLanguage as mainRouteWithStoreAndLanguageSelector } from 'ggApp/modules/store/selectors';
import Footer from 'ggApp/shared/screens/Auth/Footer';

import { Layout, Content, StyledLogo, Background } from 'ggApp/shared/screens/Auth/Auth.styles';
import { StyledCircleLoader } from './styles';

const AuthFooterWithChecker = withWebView(Footer);

function SurveyScreen({ children, location, redirect, initAppDone, translate, mainRoute }) {
    const searchParams = new URLSearchParams(location.search);
    const mood = searchParams.get('mood');
    const identityCheck = searchParams.get('idc');
    const customerType = searchParams.get('customer_type');
    const uid = searchParams.get('uid');
    const answer = searchParams.get('answer');

    // handle missing important parameters
    const isHatsAc = location.pathname.split('/').pop() === 'hats-ac';
    if ((isHatsAc && !answer) || !uid) return <NotFound errorStatus={404} />;

    const renderedChildren = React.Children.map(children, (child) => {
        return React.cloneElement(child, {
            mood,
            identityCheck,
            uid,
            customerType,
            answer,
            t: translate,
            redirect: () => redirect(mainRoute, true),
        });
    });

    return (
        <Layout>
            <Background />

            <Content>
                <StyledLogo />

                {initAppDone ? renderedChildren : <StyledCircleLoader />}

                <AuthFooterWithChecker />
            </Content>
        </Layout>
    );
}

const enhance = compose(
    connect(
        createStructuredSelector({
            initAppDone: initAppDoneSelector,
            translate: translationFunctionSelector,
            mainRoute: mainRouteWithStoreAndLanguageSelector,
        }),
        (dispatch) => ({
            redirect: bindActionCreators(redirectus, dispatch),
        }),
    ),
);

export default enhance(SurveyScreen);

import { GET_STRUCTURED_SPECS_CONFIG_SUCCESS } from '../actionTypes';

const defaultState = {
    data: [],
};

export default function(state = defaultState, action) {
    switch (action.type) {
        case GET_STRUCTURED_SPECS_CONFIG_SUCCESS:
            return {
                ...state,
                data: action.data,
            };

        default:
            return state;
    }
}

import { STORE_TYPE } from 'ggApp/modules/store/constants';
import rawStores from './stores';
import { mapStore } from './utils';

const stores = rawStores.map(mapStore);

export const getAlternateLinkStores = () =>
    stores.filter((store) => store.type === STORE_TYPE.MAIN && store.enabled);

export const getDisabledCookieModalStores = () =>
    stores
        .filter(({ cookieConsentModalDisabled }) => cookieConsentModalDisabled)
        .map((store) => store.storeCode);

export const getDisabledSignupStores = () =>
    stores.filter(({ disableSignup }) => disableSignup).map((store) => store.storeCode);

export const getDisabledBusinessSignupStores = () =>
    stores
        .filter(({ disableBusinessCustomerSignup }) => disableBusinessCustomerSignup)
        .map((store) => store.storeCode);

export const getDisabledPrivateSignupStores = () =>
    stores
        .filter(({ disablePrivateCustomerSignup }) => disablePrivateCustomerSignup)
        .map((store) => store.storeCode);

export const shouldShowNationalIdRegistration = (storeCode) => {
    const store = stores.find((element) => element.storeCode === storeCode);

    if (!store) return false;

    return store.showNationalIdStep;
};

export const getStoreConfig = (storeCode) => {
    const store = stores.find((element) => element.storeCode === storeCode);
    if (!store) return null;
    return store;
};

export const getDeliveryEstimation = (storeCode) => {
    const store = stores.find((element) => element.storeCode === storeCode);

    if (!store) return null;

    return store.deliveryEstimation;
};

export const getLanguagesFromStaticStore = (storeCode) => {
    const store = rawStores.find((element) => element.code === storeCode);
    if (!store) return null;
    return [store.default_language, ...store.languages];
};

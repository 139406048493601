import { css } from 'styled-components';

function transitionTime(timeSlug) {
    if (timeSlug === 'short') {
        return '0.1s';
    }
    if (timeSlug === 'medium-mobile') {
        return '0.25s';
    }
    if (timeSlug === 'medium-desktop' || timeSlug === 'long-mobile') {
        return '0.3s';
    }
    if (timeSlug === 'long-desktop') {
        return '0.35s';
    }
    return '0.2s';
}

export function transitionTimingFunction(type) {
    switch (type) {
        case 'in':
            return 'cubic-bezier(0.0, 0.0, 0.2, 1)';
        case 'out':
            return 'cubic-bezier(0.4, 0.0, 1, 1)';
        case 'standard':
            return 'cubic-bezier(0.4, 0.0, 0.2, 1)';
        default:
            return '';
    }
}

export function transition(property, timeSlug, type) {
    switch (type) {
        case 'in':
            return css`
                transition: ${property} ${transitionTime(timeSlug)}
                    ${transitionTimingFunction(type)};
            `;
        case 'out':
            return css`
                transition: ${property} ${transitionTime(timeSlug)}
                    ${transitionTimingFunction(type)};
            `;
        case 'standard':
            return css`
                transition: ${property} ${transitionTime(timeSlug)}
                    ${transitionTimingFunction(type)};
            `;
        default:
            return css``;
    }
}

const SHIPPING_ADDRESS = 'shippingAddress';
const HOME_ADDRESS = 'homeAddress';
const PAYMENT_METHODS = 'paymentMethod';
const ORDER_SUMMARY = 'review';
const ORDER_CONFIRMATION = 'confirmation';
const PHONE_VERIFICATION = 'phoneVerification';
const TWOFA_HELP = '2faHelp';
const ORDER_ERROR = 'orderError';

// this step is not part of flow, just a modal in order review step
const ORDER_UNDO = 'ORDER_UNDO';

const stepNames = {
    SHIPPING_ADDRESS,
    HOME_ADDRESS,
    PAYMENT_METHODS,
    ORDER_SUMMARY,
    ORDER_CONFIRMATION,
    ORDER_UNDO,
    PHONE_VERIFICATION,
    TWOFA_HELP,
    ORDER_ERROR,
};

export default stepNames;

import { sendStructEvent } from '../../api';

interface PayloadModel {
    orderID?: string;
    userID?: number | null;
    list: string;
    position?: number | null;
    productData: {
        name: string;
        productSKU: string;
        price: string;
        subscriptionLength: number;
        productVariant?: string | null;
        non_discountedPrice?: string | null;
        discountAmount?: string | null;
        discountPercentage?: number | null;
        deliveryTime?: string | null;
    };
    tracingId?: string;
}

export const sendProductClickEvent = (payload: PayloadModel): void => {
    const {
        orderID = null,
        userID = null,
        list,
        position = null,
        productData,
        tracingId = null,
    } = payload;
    if (tracingId) {
        sendStructEvent({
            eventCategory: 'productDiscovery',
            eventAction: 'productClick',
            eventLabel: productData.productSKU,
            eventProperty: {
                orderID,
                userID,
                list,
                position,
                productData,
                tracingId,
            },
        });
    } else {
        sendStructEvent({
            eventCategory: 'productDiscovery',
            eventAction: 'productClick',
            eventLabel: productData.productSKU,
            eventProperty: {
                orderID,
                userID,
                list,
                position,
                productData,
            },
        });
    }
};

/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

import { transition } from 'ggApp/utils/mixins';

const Container = styled.div`
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    overflow-y: auto;
    background: none;
    visibility: hidden;
    ${transition('all', 'long-mobile', 'out')};
    ${({ shouldShow }) => shouldShow
        && css`
            background: rgba(0, 0, 0, 0.5);
            visibility: visible;
            ${transition('all', 'long-mobile', 'in')};
        `}
`;

export { Container };

import axios from 'axios';
import memoize from 'fast-memoize';

const STORE_ID = 'grover';
export const DEFAULT_SCORE = '4.11';

const validateResponse = (response) => {
    const { data } = response;
    if (data && data.stats && data.stats.average_rating) {
        return data.stats.average_rating;
    }
    return DEFAULT_SCORE;
};

// enough to have one call per session
export const fetchScore = memoize(() =>
    axios
        .get(`https://api.reviews.co.uk/merchant/reviews?store=${STORE_ID}`)
        .then(validateResponse)
        .catch(() => DEFAULT_SCORE),
);

/* eslint-disable */
import PropTypes from 'prop-types';

export const routerActionsPropTypes = PropTypes.shape({
    mainTransition: PropTypes.func,
    redirectus: PropTypes.func,
    reload: PropTypes.func,
    replaceTo: PropTypes.func,
    transitionTo: PropTypes.func,
});

import styled, { css } from 'styled-components';

import { Headline } from 'ggApp/shared/common/Typography';
import { Card as WidgetProductCardComponent } from 'ggApp/shared/components/cards/widgetProductCard';
import WidgetGrid from 'ggApp/shared/components/widgets/grid';
import WidgetCta from 'ggApp/shared/components/widgets/cta';
import { screens } from 'ggApp/utils/mixins/screens';
import CountdownTimer from 'ggApp/shared/components/CountdownTimer';

const Wrap = styled.div`
    border-radius: 8px;
`;

const TitleWrap = styled.div`
    display: flex;
    flex-direction: column;
    @media (${screens.tablet}) {
        flex-direction: row;
    }
    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
        padding: 0 ${theme.spacers.L};
    `};
`;

const Title = styled(Headline)`
    ${({ theme }) => css(theme.typography.Headline)};
    font-size: 32px;
    line-height: 1.3;
    color: ${({ theme }) => theme.colors.Foreground};
    margin-bottom: ${({ theme }) => theme.spacers.L};
    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
        ${theme.typography.Subheadline}
    `};
`;

const Countdown = styled(CountdownTimer)`
    font-weight: 800;
    font-size: 14px;
    @media (${screens.tablet}) {
        margin-left: 16px;
        font-size: 24px;
        line-height: 40px;
    }
`;

const Grid = styled(WidgetGrid)`
    && {
        margin: 0;

        ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
            margin: 0 ${theme.spacers.L};
        `};
    }
`;

const ProductCard = styled(WidgetProductCardComponent)`
    grid-column: span 3;
`;

const Cta = styled(WidgetCta)`
    align-self: flex-start;
    margin: 24px 0 0;
    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
        position: relative;
        margin-left: ${theme.spacers.L};
        margin-right: ${theme.spacers.L};
    `};
`;

export { Wrap, TitleWrap, Title, Countdown, Grid, ProductCard, Cta };

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { ThemeContext, isDarkTheme } from 'ggApp/shared/components/ThemeContext';
import { Container, ActionsContainer, Text } from './styles';

const ANIMATION_DURATION_IN_MS = 200;

function Snackbar({
    isShown,
    children,
    renderActions,
    className,
    animationDuration,
    backgroundColor,
    textColor,
    realign,
}) {
    const theme = useContext(ThemeContext);
    const isDarkMode = isDarkTheme(theme);
    return (
        <CSSTransition
            in={isShown}
            timeout={animationDuration}
            classNames="snackbar"
            appear
            mountOnEnter
            unmountOnExit
        >
            {(animationState) => (
                <Container
                    className={className}
                    animationState={animationState}
                    animationDuration={animationDuration}
                    isDarkMode={isDarkMode}
                    backgroundColor={backgroundColor}
                    realign={realign}
                >
                    <Text textColor={textColor}>{children}</Text>
                    <ActionsContainer>{renderActions && renderActions()}</ActionsContainer>
                </Container>
            )}
        </CSSTransition>
    );
}

Snackbar.propTypes = {
    animationDuration: PropTypes.number,
};

Snackbar.defaultProps = {
    animationDuration: ANIMATION_DURATION_IN_MS,
};

export { Snackbar };

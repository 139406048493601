import { enrichAnalyticsUser } from '@devsbb/analytics-client';
import CookiesManager from 'ggApp/utils/CookiesManager';

const CONSENT_KEY = 'ajs_cookie_consent';

export type CookieConsentValue = 'yes' | 'no' | 'none';

export interface CookieConsentCategories {
    strict: CookieConsentValue;
    functional: CookieConsentValue;
    performance: CookieConsentValue;
    profiling: CookieConsentValue;
}
const defaultConsentCategories: CookieConsentCategories = {
    strict: 'yes',
    functional: 'none',
    performance: 'none',
    profiling: 'none',
};
export const trackCookieConsentAnalytics = (
    consent: CookieConsentCategories = defaultConsentCategories,
): void => {
    const cookiesManager: any = new CookiesManager();
    const previousConsent = sessionStorage.getItem(CONSENT_KEY);
    if (JSON.stringify(previousConsent) === JSON.stringify(consent)) return;

    const userId = cookiesManager.get('user_id');

    sessionStorage.setItem(CONSENT_KEY, JSON.stringify(consent));
    enrichAnalyticsUser({
        cookie_consent: consent,
        ...(userId ? { id: userId } : {}),
    });
};

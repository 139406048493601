/* eslint-disable */
import {
  GET_STORES_SUCCESS,
} from 'gg-modules/store/actionTypes';

const defaultState = [];

export default function (state = defaultState, action) {
  switch (action.type) {

    case 'SET_STORES':
    case GET_STORES_SUCCESS:
      return action.data;

    default:
      return state;
  }
}

import { FilterEventPayload, FilterEventProperties } from 'analytics/filter/types';
import { SegmentEvents } from 'analytics/events';
import { sendAnalyticsEvent } from '@devsbb/analytics-client';

export const trackProductFilter = (
    storeId: number,
    eventName: SegmentEvents,
    payload: FilterEventProperties,
) =>
    sendAnalyticsEvent<FilterEventPayload>(eventName, {
        store_id: storeId,
        ...payload,
    });

export const SNOWPLOW_EVENT = {
    newTracker: 'newTracker',
    setUserIdFromCookie: 'setUserIdFromCookie',
    setUserId: 'setUserId',
    enableActivity: 'enableActivityTracking',
    pageView: 'trackPageView',
    structEvent: 'trackStructEvent',
};

export const APP_CONTEXT = 'app';
export const WEB_APPLICATION_CONTEXT = 'web';

export const SNOWPLOW_NAMESPACE = '__SP__';

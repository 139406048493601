import styled, { css } from 'styled-components';

import { transition } from 'ggApp/utils/mixins';
import breakpoints from 'ggApp/shared/components/viewport/breakpoints';

import GroverLogo from './GroverLogo';

export const Layout = styled.div`
    position: relative;
    box-sizing: border-box;

    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    overflow-x: hidden;
    overflow-y: auto;

    ${(props) => css`
        padding: ${props.theme.spacers.L} 14px;
    `}
`;

export const Background = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;

    z-index: -1;

    background-color: ${(props) => props.theme.colors.Background};
    transition: background-color 0.3s;

    ${transition('background-color', 'default', 'standard')}
`;

export const Content = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    min-width: 384px;
    max-width: 100%;

    @media only screen and (max-width: 384px) {
        min-width: 100%;
    }
`;

export const StyledLogo = styled(GroverLogo)`
    flex-shrink: 0;
`;

export const AuthCard = styled.div`
    flex: 0 0 auto;
    align-self: stretch;

    min-height: 640px;

    display: flex;
    flex-direction: column;

    overflow: hidden;
    border-radius: 10px;

    border: 2px solid rgba(51, 51, 51, 0.07);

    ${(props) => css`
        margin-top: ${props.theme.spacers.XL};
        margin-bottom: ${props.theme.spacers.XL};

        @media (max-width: ${breakpoints.mobile.max}px) {
            margin-top: ${props.theme.spacers.L};
            margin-bottom: ${props.theme.spacers.L};
        }
    `}
`;

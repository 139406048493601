/* eslint-disable */
const defaultState = [];

export default function (state = defaultState, action) {
    switch (action.type) {
    case 'GET_PAYMENT_METHODS_BY_USER_SUCCESS':
        return action.data;

    default:
        return state;
    }
}

import { browserHistory } from 'react-router';
import { PageTypes, pageTypes } from '@devsbb/analytics-client';

/**
 * Helper for sending feature flags to analytics platform.
 * Since flags are sent on every page change, to limit the number of calls to the analytics platform
 * we cache the previous flags in sessionStorage and compare with the new one to determine if the call
 * should be made.
 */
export const haveFeatureFlagsChanged = (featureFlags: Record<string, string>) => {
    const FEATURE_FLAGS_CACHE = 'ajs_feature_flags';

    const cache = sessionStorage.getItem(FEATURE_FLAGS_CACHE);

    if (cache === null) {
        sessionStorage.setItem(FEATURE_FLAGS_CACHE, JSON.stringify(featureFlags));
        return true;
    }

    const cachedFlags = JSON.parse(cache);
    const keys = Object.keys(featureFlags);
    if (keys.some((flagKey) => featureFlags[flagKey] !== cachedFlags[flagKey])) {
        sessionStorage.setItem(
            FEATURE_FLAGS_CACHE,
            JSON.stringify({ ...cachedFlags, ...featureFlags }),
        );
        return true;
    }

    return false;
};

export const getMappedFeatureFlags = (featureFlags: Record<string, { variantKey: string }>) => {
    return Object.keys(featureFlags).reduce(
        (flags, flag) => ({
            ...flags,
            [flag]: featureFlags[flag].variantKey,
        }),
        {},
    );
};

export const hasUserIdChanged = (userId: string) => {
    const USER_ID_CACHE = 'ajs_user_id';

    const cache = sessionStorage.getItem(USER_ID_CACHE);

    if (userId !== cache) {
        sessionStorage.setItem(USER_ID_CACHE, userId);
        return true;
    }
    return false;
};

export const getNameByRoute = (): PageTypes | null => {
    const pathName = browserHistory.getCurrentLocation().pathname;
    const isHome = pathName.replace(/\//g, '');

    if (isHome.length <= 5) {
        return 'home';
    }

    if (pathName.includes('products')) {
        return 'pdp';
    }

    const foundRoute = pageTypes.find((page) => pathName.includes(page));
    if (foundRoute) {
        return foundRoute;
    }
    return null;
};

import React from 'react';
import { withTheme } from 'styled-components';
import { IconPropTypes } from 'ggApp/types/propTypes';

const InstagramIcon = ({ className, size, color, theme }) => {
    const fill = color || theme.colors.Foreground;
    return (
        <svg
            className={className}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12ZM12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7Z"
                fill={fill}
            />
            <path
                d="M15.7514 7.00054C15.7514 7.68984 16.3102 8.24862 16.9995 8.24862C17.6887 8.24862 18.2475 7.68984 18.2475 7.00054C18.2475 6.31125 17.6887 5.75247 16.9995 5.75247C16.3102 5.75247 15.7514 6.31125 15.7514 7.00054Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 4H8C5.79086 4 4 5.79086 4 8V16C4 18.2091 5.79086 20 8 20H16C18.2091 20 20 18.2091 20 16V8C20 5.79086 18.2091 4 16 4ZM8 2C4.68629 2 2 4.68629 2 8V16C2 19.3137 4.68629 22 8 22H16C19.3137 22 22 19.3137 22 16V8C22 4.68629 19.3137 2 16 2H8Z"
                fill={fill}
            />
        </svg>
    );
};

InstagramIcon.propTypes = {
    ...IconPropTypes,
};

InstagramIcon.defaultProps = {
    className: '',
    size: '24px',
};

export default withTheme(InstagramIcon);

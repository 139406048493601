import { MODULE_NAME as RENTAL_PLAN_MODULE_NAME } from 'ggApp/modules/rentalPlan/constants';
import rentalPlanReducer from 'ggApp/modules/rentalPlan/reducers';
import { MODULE_NAME as REQUEST_STATUS_MODULE_NAME } from 'ggApp/modules/requestStatus/constants';
import requestStatusReducer from 'ggApp/modules/requestStatus/reducer';
import { MODULE_NAME as ADYEN_MODULE_NAME } from 'ggApp/modules/adyen/constants';
import adyenReducer from 'ggApp/modules/adyen/reducers';
import { MODULE_NAME as LAYOUT_MODULE_NAME } from 'ggApp/modules/layout/constants';
import layoutReducer from 'ggApp/modules/layout/reducers';
import { MODULE_NAME as FORM_MODULE_NAME } from 'ggApp/modules/form/constants';
import formReducer from 'ggApp/modules/form/reducers';
import { MODULE_NAME as ROUTER_MODULE_NAME } from 'ggApp/modules/router/constants';
import { routerStateReducer } from 'redux-router';
import { MODULE_NAME as STORE_MODULE_NAME } from 'ggApp/modules/store/constants';
import storeReducer from 'ggApp/modules/store/reducers';
import { MODULE_NAME as AUTH_MODULE_NAME } from 'ggApp/modules/auth/constants';
import authReducer from 'ggApp/modules/auth/reducers';
import { MODULE_NAME as TRANSLATOR_MODULE_NAME } from 'ggApp/modules/translator/constants';
import translatorReducer from 'ggApp/modules/translator/reducers';
import { MODULE_NAME as ORDER_MODULE_NAME } from 'ggApp/modules/order/constants';
import orderReducer from 'ggApp/modules/order/reducers';
import { MODULE_NAME as ADDRESS_MODULE_NAME } from 'ggApp/modules/address/constants';
import addressReducer from 'ggApp/modules/address/reducers';
import { MODULE_NAME as PAYMENT_MODULE_NAME } from 'ggApp/modules/payment/constants';
import paymentReducer from 'ggApp/modules/payment/reducers';
import { MODULE_NAME as BRAINTREE_MODULE_NAME } from 'ggApp/modules/braintree/constants';
import braintreeReducer from 'ggApp/modules/braintree/reducers';
import { MODULE_NAME as COUNTRY_MODULE_NAME } from 'ggApp/modules/country/constants';
import countryReducer from 'ggApp/modules/country/reducers';
import { MODULE_NAME as NAVIGATOR_MODULE_NAME } from 'ggApp/modules/navigator/constants';
import navigatorReducer from 'ggApp/modules/navigator/reducers';
import { MODULE_NAME as NOTIFICATION_MODULE_NAME } from 'ggApp/modules/notification/constants';
import notificationReducer from 'ggApp/modules/notification/reducers';
import { MODULE_NAME as SUBSCRIPTION_MODULE_NAME } from 'ggApp/modules/subscription/constants';
import subscriptionReducer from 'ggApp/modules/subscription/reducers';
import { MODULE_NAME as TRANSACTION_MODULE_NAME } from 'ggApp/modules/transaction/constants';
import transactionReducer from 'ggApp/modules/transaction/reducers';
import { MODULE_NAME as STATUS_MODULE_NAME } from 'ggApp/modules/status/constants';
import statusReducer from 'ggApp/modules/status/reducers';
import { MODULE_NAME as CATEGORY_MODULE_NAME } from 'ggApp/modules/category/constants';
import categoryReducer from 'ggApp/modules/category/reducers';
import { MODULE_NAME as PRODUCT_MODULE_NAME } from 'ggApp/modules/product/constants';
import productReducer from 'ggApp/modules/product/reducers';
import { MODULE_NAME as FAVORITES_MODULE_NAME } from 'ggApp/modules/favorites/constants';
import favoritesReducer from 'ggApp/modules/favorites/reducers';
import { MODULE_NAME as LISTING_MODULE_NAME } from 'ggApp/modules/listing/constants';
import listingReducer from 'ggApp/modules/listing/reducers';
import { MODULE_NAME as FILTER_MODULE_NAME } from 'ggApp/modules/filter/constants';
import filterReducer from 'ggApp/modules/filter/reducers';
import { MODULE_NAME as HOME_MODULE_NAME } from 'ggApp/modules/home/constants';
import homeReducer from 'ggApp/modules/home/reducers';
import { MODULE_NAME as AXIOS_CONFIG_MODULE_NAME } from 'ggApp/modules/axiosConfig/constants';
import axiosConfigReducer from 'ggApp/modules/axiosConfig/reducers';
import { MODULE_NAME as STATIC_CONFIG_MODULE_NAME } from 'ggApp/modules/static/constants';
import staticReducer from 'ggApp/modules/static/reducers';
import { MODULE_NAME as FEATURE_FLAG_MODULE_NAME } from 'ggApp/modules/featureFlag/constants';
import featureFlagReducer from 'ggApp/modules/featureFlag/reducers';
import { MODULE_NAME as SEARCH_MODULE_NAME } from 'ggApp/modules/search/constants';
import searchReducer from 'ggApp/modules/search/reducer';
import { MODULE_NAME as REFERRALS_MODULE_NAME } from 'ggApp/modules/referrals/constants';
import referralsReducer from 'ggApp/modules/referrals/reducers';
import { COOKIE_POLICY_MODULE_NAME } from 'ggApp/modules/cookiePolicy/state/constants';
import { cookiePolicyReducers } from 'ggApp/modules/cookiePolicy/state/reducers';
import { CONTENTFUL_MODULE_NAME } from 'ggApp/modules/contentful/state/constants';
import { contentfulReducers } from 'ggApp/modules/contentful/state/reducers';
import { INTERCOM_MODULE_NAME } from 'ggApp/modules/intercom/constants';
import { intercomReducers } from 'ggApp/modules/intercom/reducers';
import { MODULE_NAME as PRODUCT_WIDGET_MODULE_NAME } from 'ggApp/modules/productWidget/constants';
import { productWidgetReducer } from 'ggApp/modules/productWidget/reducers';
import { MODULE_NAME as INIT_MODULE_NAME } from 'ggApp/modules/init/constants';
import { MODULE_NAME as COMPANY_DASHBOARD_MODULE_NAME } from 'ggApp/modules/company-dashboard/constants';
import initReducer from 'ggApp/modules/init/reducers';
import companyDashboardReducer from 'ggApp/modules/company-dashboard/reducers';

export default {
    [RENTAL_PLAN_MODULE_NAME]: rentalPlanReducer,
    [REQUEST_STATUS_MODULE_NAME]: requestStatusReducer,
    [ADYEN_MODULE_NAME]: adyenReducer,
    [LAYOUT_MODULE_NAME]: layoutReducer,
    [FORM_MODULE_NAME]: formReducer,
    [ROUTER_MODULE_NAME]: routerStateReducer,
    [STORE_MODULE_NAME]: storeReducer,
    [AUTH_MODULE_NAME]: authReducer,
    [TRANSLATOR_MODULE_NAME]: translatorReducer,
    [ORDER_MODULE_NAME]: orderReducer,
    [ADDRESS_MODULE_NAME]: addressReducer,
    [PAYMENT_MODULE_NAME]: paymentReducer,
    [BRAINTREE_MODULE_NAME]: braintreeReducer,
    [COUNTRY_MODULE_NAME]: countryReducer,
    [NAVIGATOR_MODULE_NAME]: navigatorReducer,
    [NOTIFICATION_MODULE_NAME]: notificationReducer,
    [SUBSCRIPTION_MODULE_NAME]: subscriptionReducer,
    [TRANSACTION_MODULE_NAME]: transactionReducer,
    [STATUS_MODULE_NAME]: statusReducer,
    [CATEGORY_MODULE_NAME]: categoryReducer,
    [PRODUCT_MODULE_NAME]: productReducer,
    [FAVORITES_MODULE_NAME]: favoritesReducer,
    [LISTING_MODULE_NAME]: listingReducer,
    [FILTER_MODULE_NAME]: filterReducer,
    [HOME_MODULE_NAME]: homeReducer,
    [AXIOS_CONFIG_MODULE_NAME]: axiosConfigReducer,
    [STATIC_CONFIG_MODULE_NAME]: staticReducer,
    [FEATURE_FLAG_MODULE_NAME]: featureFlagReducer,
    [SEARCH_MODULE_NAME]: searchReducer,
    [REFERRALS_MODULE_NAME]: referralsReducer,
    [COOKIE_POLICY_MODULE_NAME]: cookiePolicyReducers,
    [CONTENTFUL_MODULE_NAME]: contentfulReducers,
    [INTERCOM_MODULE_NAME]: intercomReducers,
    [PRODUCT_WIDGET_MODULE_NAME]: productWidgetReducer,
    [INIT_MODULE_NAME]: initReducer,
    [COMPANY_DASHBOARD_MODULE_NAME]: companyDashboardReducer,
};

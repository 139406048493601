import {
    GET_RELATED_PRODUCTS,
    GET_RELATED_PRODUCTS_SUCCESS,
    GET_RELATED_PRODUCTS_ERROR,
} from '../actionTypes';

const defaultState = {
    data: {},
    loading: null,
    error: {},
};
export default function(state = defaultState, action) {
    switch (action.type) {
        case GET_RELATED_PRODUCTS:
            return {
                ...state,
                loading: true,
            };

        case GET_RELATED_PRODUCTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    products: action.data.products,
                },
            };
        }

        case GET_RELATED_PRODUCTS_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error,
                data: {
                    ...state.data,
                    products: [],
                },
            };
        }

        default:
            return state;
    }
}

import React, { ReactNode } from 'react';
import Markdown from 'markdown-to-jsx';

import {
    Headline,
    SubHeadline,
    SmallHeadline,
    SmallCapsHeadline,
    Paragraph,
} from 'ggApp/shared/common/Typography';
import { Overrides } from 'ggApp/shared/hooks/useOverrides';

type MarkdownTextProps = {
    children: ReactNode;
    overrides?: Overrides;
};

const MarkdownText = ({ className, children, overrides = {} }: MarkdownTextProps) => (
    <Markdown
        className={className}
        options={{
            overrides: {
                h1: Headline,
                h2: SubHeadline,
                h3: SmallHeadline,
                label: SmallCapsHeadline,
                p: Paragraph,
                ...overrides,
            },
        }}
    >
        {children}
    </Markdown>
);

export default MarkdownText;

import React, { useRef } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { registerFavoritesEvent as registerSnowplowFavoritesEvent } from 'ggApp/modules/gaAnalytics/actions';
import withOnClickEvent from 'ggApp/modules/tracking/hocs/withOnClickEvent';
import { isFunction } from 'ggApp/utils/function';
import { MappedProductType } from 'ggApp/modules/product/constants/MappedProductType';
import useIntersection from 'ggApp/shared/hooks/useIntersection';
import { ActiveStore, storeActive as activeStoreSelector } from 'ggApp/modules/store';
import { isLoggedIn as isLoggedInSelector } from 'ggApp/modules/auth/selectors';
import { getOrderFromCookies } from 'ggApp/shared/context/Order/cookies';
import { getPriceInfo } from 'ggApp/modules/gaAnalytics';
import { trackProductClicked } from 'analytics/product/trackProductClicked';
import { getDeliveryEstimation } from 'staticStoreConfig';
import { formatProductPrice } from 'ggApp/modules/tracking/selectors';
import {
    locationPathname as locationPathnameSelector,
    params as paramsSelector,
} from 'ggApp/modules/router/selectors';
import { getListNameByRoute } from 'ggApp/modules/gaAnalytics/utils';
import {
    productWidgetList,
    widgetListProducts as widgetListAllProducts,
} from 'ggApp/modules/productWidget/selectors';
import { RouteComponentProps } from 'react-router';
import { withProductMapper } from './withProductAdapter';
import { HorizontalCard } from './HorizontalCard';
import { VerticalCard } from './VerticalCard';
import { WidgetProduct } from '../../widgets/utils/types';

type WidgetProductCardType = {
    className: string;
    onClick: () => void;
    onClickCallback: ({ isFavorite }: { isFavorite: boolean }) => void;
    browserLink?: boolean;
    cardType: 'horizontal' | 'vertical';
    product: MappedProductType;
    handpickedProduct: boolean;
    carousel: boolean;
    style: React.CSSProperties;
    intersectionCallback: () => void;
    noCssOnImage: boolean;
    activeStore: ActiveStore;
    pathname: string;
    firstIndexProduct?: number;
    widgetListProducts: WidgetProduct[];
    hidePrices?: boolean;
    isLoggedIn?: boolean;
};

export const WidgetProductCard = ({
    cardType,
    className,
    product,
    style,
    onClick,
    browserLink = false,
    activeStore,
    carousel,
    handpickedProduct,
    noCssOnImage,
    onClickCallback,
    pathname,
    routeParams,
    intersectionCallback,
    widgetListProducts,
    hidePrices = false,
    isLoggedIn,
}: WidgetProductCardType & RouteComponentProps<{}, {}>) => {
    const nodeRef = useRef(null);

    useIntersection({
        node: nodeRef,
        onEnterCallback: () => intersectionCallback(),
        onExitCallback: undefined,
    });

    const onFavoriteCb = ({ isFavorite }: { isFavorite: boolean }) => {
        if (isFunction(onClickCallback)) {
            onClickCallback({ isFavorite });
        }
        const variant = product?.originalProduct?.variants[0] || null;

        registerSnowplowFavoritesEvent({
            isFavorite,
            product,
            currentVariant: variant,
            selectedRentalPlan: null,
            isLoggedIn,
        });
    };

    const handleProductCardClick = () => {
        const { originalProduct } = product;
        const orderID = getOrderFromCookies(activeStore.code) || undefined;
        const { rentalPlan } = product;
        const { sku, name, variants } = originalProduct;
        const variant = variants[0] || {};
        const deliveryEstimates = getDeliveryEstimation(activeStore.code) ?? null;
        const deliveryTime =
            (deliveryEstimates && `${deliveryEstimates.from}-${deliveryEstimates.to}`) || undefined;
        const priceInfo = rentalPlan
            ? getPriceInfo(rentalPlan, false)
            : formatProductPrice(originalProduct);
        const productCategoryTitle = originalProduct.category.permalink.split('/');
        const listName = getListNameByRoute(pathname, routeParams);
        const productIndex = widgetListProducts.findIndex(
            (wProduct: WidgetProduct) => wProduct.id === product.id.toString(),
        );
        const productClickPayload = {
            brand: originalProduct?.brand ?? product?.brand,
            currency: originalProduct?.currency ?? activeStore.default_currency ?? '',
            category: productCategoryTitle[0] || '',
            delivery_time: deliveryTime,
            discount_amount: Number(priceInfo?.discountAmount),
            discount_percentage: Number(priceInfo?.discountPercentage),
            displayed_price: Number(priceInfo?.price),
            list: listName,
            position: productIndex,
            name,
            image_url:
                originalProduct?.original_image_url ?? originalProduct?.originalImageUrl ?? '',
            non_discounted_price: priceInfo?.non_discountedPrice
                ? Number(priceInfo.non_discountedPrice)
                : '',
            order_id: orderID,
            price: Number(priceInfo?.price),
            product_id: sku,
            product_name: name,
            product_sku: sku,
            product_variant: String(variant.id),
            sku,
            store_id: activeStore.store_id,
            sub_category: productCategoryTitle[1] || '',
            variant: String(variant.id),
            variant_sku: variant.sku,
            quantity: 1,
        };
        // Sends formatted product clicked data to segment
        trackProductClicked(productClickPayload);
        onClick();
    };

    if (cardType === 'horizontal') {
        return (
            <HorizontalCard
                className={className}
                product={product}
                onClick={handleProductCardClick}
                onClickCallback={onFavoriteCb}
                style={style}
                browserLink={browserLink}
                nodeRef={nodeRef}
                noCssOnImage={noCssOnImage}
                hidePrices={hidePrices}
            />
        );
    }

    return (
        <VerticalCard
            className={className}
            product={product}
            onClick={handleProductCardClick}
            onClickCallback={onFavoriteCb}
            style={style}
            recentlyViewedSection
            browserLink={browserLink}
            carousel={carousel}
            handpickedProduct={handpickedProduct}
            nodeRef={nodeRef}
            noCssOnImage={noCssOnImage}
            hidePrices={hidePrices}
        />
    );
};

const enhance = compose(
    connect(
        createStructuredSelector({
            activeStore: activeStoreSelector,
            isLoggedIn: isLoggedInSelector,
            pathname: locationPathnameSelector,
            routeParams: paramsSelector,
            widgetListProducts: widgetListAllProducts,
            widgetList: productWidgetList,
        }),
    ),
);

const EnhancedCard = enhance(WidgetProductCard);

export const Card = withOnClickEvent(EnhancedCard);

export const CardWithTrackingWithProductMapper = withProductMapper(Card);
export const CardWithProductMapper = withProductMapper(EnhancedCard);

export const PAYMENT_METHOD_STATUS = {
    DRAFT: 'draft',
    CREATED: 'published',
};

export const RedirectFlow = {
    QueryParamPaymentStatus: 'p-status',
    QueryParamPaymentId: 'p-id',
    PaymentQueryParamName: 'transaction_key_name',
};

export const RedirectFlowStatus = {
    Success: 's',
    Failure: 'f',
};

export const GATEWAY_FLOW_TYPE = {
    REDIRECT: 'redirect',
    CREDIT_CARD: 'credit-card',
};

import React, { ComponentType, Component } from 'react';

import CircleLoader from 'ggApp/shared/components/CircleLoader';

type OptionsPayload<LP> = {
    componentName: string;
    timeout: number;
    loader: Component<LP>;
    loaderProps: LP;
};

type StateType<P> = {
    component: Component<P> | null;
};

const defaultOptions: OptionsPayload<{ className?: string }> = {
    componentName: 'default',
    timeout: 500,
    loader: CircleLoader,
    loaderProps: {},
};

export default function asyncComponent<P = any, LP = any>(
    importComponent: () => Promise<ComponentType<P>>,
    options: Partial<OptionsPayload<LP>>,
): ComponentType<P> {
    class AsyncComponent extends Component<P, StateType<P>> {
        options: OptionsPayload<LP> | typeof defaultOptions;

        constructor(props: P) {
            super(props);

            this.state = {
                component: null,
            };

            this.options = { ...defaultOptions, ...options };
        }

        componentDidMount() {
            const { componentName } = this.options;

            Promise.all([importComponent(), this.timeout()])
                .then(([module]) => {
                    this.setState({
                        component: (module as any)[componentName],
                    });
                })
                .catch((error) => {
                    if (__DEVELOPMENT__) {
                        console.error('ERROR LOADING ASYNC COMPONENT: ', error);
                    }
                });
        }

        timeout(): Promise<void> {
            const { timeout } = this.options;
            return new Promise((resolve) => setTimeout(() => resolve(), timeout || 0));
        }

        render() {
            const { loader: Loader, loaderProps } = this.options;
            const { component: LoadedComponent } = this.state;

            if (LoadedComponent) {
                return <LoadedComponent {...this.props} />;
            }

            if (Loader) {
                return <Loader {...loaderProps} />;
            }

            return null;
        }
    }

    return AsyncComponent;
}

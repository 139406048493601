import React from 'react';
import { Svg, Path } from 'react-native-svg';
import { IconPropTypes } from 'ggApp/types/propTypes';
import { withTheme } from 'styled-components';

const YourTechOutlineIcon = ({ color, size, className, theme }) => {
    const fill = color || theme.colors.Foreground;
    return (
        <Svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
        >
            <Path
                d="M3 7.99985C3 6.343 4.34315 4.99985 6 4.99985H12L11 6.99985H6C5.44772 6.99985 5 7.44757 5 7.99985V18.9998C5 19.5521 5.44772 19.9998 6 19.9998H12C12.5523 19.9998 13 19.5521 13 18.9998H15C15 20.6567 13.6569 21.9998 12 21.9998H6C4.34315 21.9998 3 20.6567 3 18.9998V7.99985Z"
                fill={fill}
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.3993 4.67302C10.8281 3.07262 12.4732 2.12287 14.0736 2.5517L19.8691 4.10461C21.4695 4.53344 22.4193 6.17845 21.9904 7.77885L19.1434 18.404C18.7146 20.0044 17.0696 20.9542 15.4692 20.5254L9.67364 18.9724C8.07324 18.5436 7.12349 16.8986 7.55231 15.2982L10.3993 4.67302ZM13.5559 4.48355C13.0225 4.34061 12.4741 4.65719 12.3312 5.19066L9.48417 15.8158C9.34122 16.3493 9.65781 16.8976 10.1913 17.0406L15.9868 18.5935C16.5203 18.7364 17.0686 18.4199 17.2116 17.8864L20.0586 7.26121C20.2015 6.72774 19.8849 6.17941 19.3515 6.03646L13.5559 4.48355Z"
                fill={fill}
            />
        </Svg>
    );
};

YourTechOutlineIcon.propTypes = {
    ...IconPropTypes,
};

YourTechOutlineIcon.defaultProps = {
    className: '',
    size: '24px',
};

export default withTheme(YourTechOutlineIcon);

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withProps, branch, renderNothing } from 'recompose';

import { isLoggedIn as isLoggedInSelector } from 'ggApp/modules/auth/selectors';
import { registerTeaserClickEvent } from 'ggApp/modules/gaAnalytics/actions';
import { WIDGET_NAMES } from 'ggApp/modules/gaAnalytics/constants';
import withIntersectionObserver from 'ggApp/modules/tracking/hocs/withIntersectionObserverV2';

import withDeviceInfo from 'ggApp/shared/components/viewport/withDeviceInfo';

import { DesktopCarouselCard } from './Desktop/CarouselCard';
import { MobileCarouselCard } from './Mobile/CarouselCard';

const enhance = compose(
    withDeviceInfo,
    connect(
        createStructuredSelector({
            isLoggedIn: isLoggedInSelector,
        }),
    ),
    withProps(({ isLoggedIn }) => ({
        clickClassName: isLoggedIn ? 'gtm-promo-widget-logged-in' : 'gtm-promo-widget-logged-out',
    })),
    branch(({ campaignBannerContent }) => !campaignBannerContent, renderNothing),
);

const DesktopCard = enhance(DesktopCarouselCard);
const MobileCard = enhance(MobileCarouselCard);

export const DesktopCarouselCardWithEvents = withProps(
    ({ campaignBannerContent: { title, cta } }) => ({
        // onEnterCallback: registerTeaserImpressionEvent({
        //     widgetName: WIDGET_NAMES.marketing1,
        //     title,
        //     url: cta?.url ?? null,
        // }),
        onClickCTA: registerTeaserClickEvent({
            widgetName: WIDGET_NAMES.marketing1,
            title,
            url: cta?.url ?? null,
        }),
    }),
)(withIntersectionObserver(DesktopCard));

export const MobileCarouselCardWithEvents = withProps(
    ({ campaignBannerContent: { title, cta } }) => ({
        // onEnterCallback: registerTeaserImpressionEvent({
        //     widgetName: WIDGET_NAMES.marketing1,
        //     title,
        //     url: cta?.url ?? null,
        // }),
        onClickCTA: registerTeaserClickEvent({
            widgetName: WIDGET_NAMES.marketing1,
            title,
            url: cta?.url ?? null,
        }),
    }),
)(withIntersectionObserver(MobileCard));

/* Ha, gotcha! There ARE typings in this project */

import { Flex, SubscriptionDetails, ContractState, SubscriptionState } from 'ggApp/types/graphql';
import ClientCookies from 'universal-cookie';

interface GraphqlType {
    __typename?: string;
}

export function isString(value: any): value is string {
    return typeof value === 'string';
}

export function isNumber(value: any): value is number {
    return typeof value === 'number';
}

export function isFunction(value: any): value is Function {
    return typeof value === 'function';
}

export function isBoolean(value: any): value is boolean {
    return typeof value === 'boolean';
}

export interface FlagData {
    flagID: number;
    flagKey: string;
    flagSnapshotID: number;
    segmentID: number;
    variantID: number;
    variantKey: string;
}

export type ValueOf<T> = T[keyof T];

export type Maybe<T> = T | null;

export type TypeOfKey<T, K extends keyof T> = T[K];

export type DeepPartial<T> = {
    [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export function isSubscriptionDetails(value: any): value is SubscriptionDetails {
    return value?.__typename === 'SubscriptionDetails';
}

export function isFlex(value: any): value is Flex {
    return value?.__typename === 'Flex';
}

export function isGraphqlType<T extends GraphqlType>(
    typename: T['__typename'],
    value: any,
): value is T {
    return value?.__typename === typename;
}

export const SubscriptionStateKeymap = {
    [ContractState.ACTIVE]: SubscriptionState.active,
    [ContractState.CANCELLED]: SubscriptionState.cancelled,
    [ContractState.DRAFT]: SubscriptionState.processing,
    [ContractState.ENDED]: SubscriptionState.ended,
    [ContractState.FULFILLING]: SubscriptionState.processing,
    [ContractState.PAUSED]: SubscriptionState.paused,
    [ContractState.REFUSED]: SubscriptionState.declined,
};

export type Cookies = Pick<ClientCookies, 'get' | 'set' | 'remove'>;

export enum Currency {
    EUR = 'EUR',
    USD = 'USD',
    GBP = 'GBP',
}

export enum Locale {
    de = 'de',
    en = 'en',
    es = 'es',
    nl = 'nl',
}

import React from 'react';
import PropTypes from 'prop-types';

import { SpinnerContainer, SpinnerCircle } from './styles';

const Spinner = ({ className, style }) => (
    <SpinnerContainer className={className} style={style}>
        <SpinnerCircle />
    </SpinnerContainer>
);

Spinner.propTypes = {
    className: PropTypes.string,
    style: PropTypes.shape({}),
};

Spinner.defaultProps = {
    className: undefined,
    style: undefined,
};

export default Spinner;

import styled from 'styled-components';
import { SonntagButtonV2 } from '@getgrover/ui';

import { Paragraph, SubHeadline } from 'ggApp/shared/common/Typography';
import Modal from 'ggApp/modules/modal/components/v3/modal';
import BusinessLogoWhite from 'ggApp/shared/icons/BusinessLogo/BusinessLogoWhite';

const MODAL_CONTAINER_CLASS_NAME = 'BusinessRedirectionContainer';
const MODAL_CONTENT_CLASS_NAME = 'BusinessRedirectionContent';

export const StyledModal = styled(Modal).attrs({
    classNames: {
        container: MODAL_CONTAINER_CLASS_NAME,
        content: MODAL_CONTENT_CLASS_NAME,
    },
})`
    display: flex;
    flex-direction: column;

    & .${MODAL_CONTAINER_CLASS_NAME} {
        width: 384px;
        height: auto;
        max-height: 100%;
        min-width: unset;
        ${({ theme }) => theme.superQuery.all.and.maxWidth.mobileMax.css`
            min-height: 560px;
        `};
    }

    & .${MODAL_CONTENT_CLASS_NAME} {
        padding: 0 0 ${props => props.theme.spacers.XXL};
        user-select: none;
        display: flex;
        flex-direction: column;
        ${({ theme }) => theme.superQuery.all.and.maxWidth.mobileMax.css`
            padding-bottom: ${theme.spacers.L};
        `};
    }
`;

export const Top = styled.section`
  background: #091849;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 260px;
`;

export const Bottom = styled.section`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  padding: 0 ${props => props.theme.spacers.XXL};
  ${({ theme }) => theme.superQuery.all.and.maxWidth.mobileMax.css`
    padding: 0 ${theme.spacers.L};
  `};
`;

export const Header = styled(SubHeadline)`
  margin-top: ${props => props.theme.spacers.XXL};
`;

export const Description = styled(Paragraph)`
  margin-top: ${props => props.theme.spacers.M};
`;

export const RedirectButton = styled(SonntagButtonV2)`
  margin-top: ${props => props.theme.spacers.L};
`;

export const ImageBusiness = styled.img`
  height: 150px;
  width: 150px;
  margin-top: ${props => props.theme.spacers.XXL};
`;

export const Logo = styled(BusinessLogoWhite)`
  height: 50px;
  width: 190px;
  margin-bottom: ${props => props.theme.spacers.XL};
`;

import objectPath from 'object-path';
import { format, formatPriceByLanguage } from 'ggApp/utils/money';
import { rentalPlanAdapter } from 'ggApp/modules/rentalPlan/utils/adapters';
import { CURRENCIES_ASSOCIATION } from 'ggApp/modules/store/constants';

export const getVariantImagePictureOfSize = ({
    picture,
    pictureSizeId,
    imageField = 'image_urls',
    defaultValue,
}) => {
    // try to extract the desired picture
    const pic = objectPath.get(picture, [imageField, pictureSizeId]);
    // try the default image field if not found
    if (!pic && imageField !== 'image_urls') {
        return getVariantImagePictureOfSize({
            picture,
            pictureSizeId,
            imageField: 'image_urls',
            defaultValue,
        });
    }

    return pic || defaultValue;
};

export const getVariantPictures = ({ pictureSizeId, variant = {} }) => {
    const { pictures = [] } = variant;
    const hasPictureSet = Array.isArray(pictures) && pictures.length > 0;

    if (!hasPictureSet) {
        return [];
    }

    return variant.pictures.map((picture) =>
        getVariantImagePictureOfSize({ picture, pictureSizeId }),
    );
};

export const getCurrentVariant = ({ availableVariants, variantId }) => {
    const variant = availableVariants.find((v) => v.id && String(v.id) === String(variantId));

    if (!variant) {
        return availableVariants[0];
    }

    return variant;
};

export const buildListWithBanners = ({ bannerList, productList }) => {
    const allComponents = productList.data.map((p) => ({
        ...p,
        type: 'product',
    }));

    for (let i = 0; i < bannerList.length; i += 1) {
        const insertIndex = (i + 1) * 4;
        allComponents.splice(insertIndex, 0, bannerList[i]);
    }

    return allComponents;
};

export const getCurrentProduct = ({ params, state }) => {
    const product = state.productBySlug.data[params.productSlug];

    if (!product) {
        // Trying to get product data in case params are emplty
        const dataKeys = Object.keys(state.productBySlug.data);
        return state.productBySlug.data[dataKeys[0]];
    }

    return product;
};

export const getProductOverview = ({
    currentProduct,
    contentfulProduct,
    availableVariants,
    pdpCommon,
    variantId,
    availableOffers,
}) => ({
    name: currentProduct.frontname || contentfulProduct.frontname || currentProduct.name,
    attributes: currentProduct.core_attribute || contentfulProduct.core_attribute,
    features: [contentfulProduct.feature_1, contentfulProduct.feature_2],
    short_description: contentfulProduct.short_description,
    delivery_description: pdpCommon.delivery_description,
    price_description: pdpCommon.price_description,
    selectedVariantId: variantId,
    variants: availableOffers[currentProduct.id]
        ? availableOffers[currentProduct.id].variants || []
        : availableVariants,
    paymentIcons: pdpCommon.payments_icons || [],
    offers: availableOffers,
    pdpCommon,
});

export const getProductDescription = ({ contentfulProduct }) => {
    const title = contentfulProduct.description;
    const text = contentfulProduct.experience_grid_image_description;

    if (!title || !text) return null;

    return {
        title,
        text,
    };
};

export const composeTopFeatures = ({ contentfulProduct, pdpCommon }) => {
    const { top_feature_widget: topFeatureWidget } = contentfulProduct;
    const { top_features_heading: topFeaturesHeading } = pdpCommon;

    if (!topFeatureWidget || topFeatureWidget.length === 0) {
        return null;
    }

    return {
        title: topFeaturesHeading,
        list: topFeatureWidget,
    };
};

export const composeKeySpecs = ({ contentfulProduct, pdpCommon }) => {
    const { key_specifications_widget: widget } = contentfulProduct;
    const { key_specs_heading: heading, see_all_specs: showMoreLabel } = pdpCommon;

    if (!widget || widget.length === 0) {
        return null;
    }

    return {
        list: widget,
        heading,
        showMoreLabel,
    };
};

export const composeExperiences = (data) => {
    const { experience_widget_2: widgets } = data;

    if (!widgets || widgets.length === 0) {
        return null;
    }
    const [widget] = widgets;
    const { description: title, video_url: id } = widget || {};
    return {
        title,
        id: (id && id.trim && id.trim()) || '',
    };
};

export const getSimilarProductsWithContentfulHeading = ({ product, pdpCommon }) => {
    const { similar_products_heading: title } = pdpCommon;

    if (!product) {
        return null;
    }

    const { similar_products: products } = product;

    if (!products || !products.length || products.length === 0) {
        return null;
    }

    return {
        title,
        products,
    };
};

export const getSimilarProducts = ({ currentProduct }) => {
    if (!currentProduct) {
        return null;
    }

    const { similar_products: products } = currentProduct;

    if (!products || !products.length || products.length === 0) {
        return null;
    }

    return products;
};

export const extractFoundNothing = ({ pdpCommon }) => {
    const {
        found_nothing_text: title,
        back_to_overview_link: url,
        back_to_overview_text: linkText,
    } = pdpCommon;

    return {
        title,
        target: '_self',
        link: {
            text: linkText,
            url,
        },
    };
};

export const getMetaPageInfo = ({ contentful, product }) => ({
    title: contentful.meta_title || `${product.frontname || ''} - Grover` || null,
    description: contentful.meta_description || null,
    keywords: contentful.meta_keywords || null,
});

export const getTechSpecs = ({ currentProduct }) => {
    const specs = currentProduct.specifications;

    if (!specs) {
        return [];
    }

    return Object.keys(specs).map((key) => ({
        label: key,
        value: specs[key],
    }));
};

export function getFormattedPlanPrice({ rentalPlan = {} }) {
    if (rentalPlan.price) {
        return format(rentalPlan.price);
    }

    if (rentalPlan.formattedFallbackPrice) {
        return rentalPlan.formattedFallbackPrice;
    }

    return undefined;
}

export function getFormattedProductCardPrice({ rentalPlan = {} }) {
    if (rentalPlan.rental_plan_price_cents) {
        return format(rentalPlan.rental_plan_price_cents);
    }

    if (rentalPlan.rental_plan_price) {
        return rentalPlan.rental_plan_price;
    }

    return undefined;
}

export const getProductCategoryDetails = ({ currentProduct = {} }) => {
    const { parent_category: productCategory, category: productSubCategory } = currentProduct;
    if (!productCategory || !productSubCategory) return null;

    return {
        productCategory,
        productSubCategory,
    };
};

export const isDealActive = (product) =>
    Boolean(
        product.rental_plans &&
            product.rental_plans.reduce((prev, current) => {
                if (current.old_price) {
                    return true;
                }
                return prev;
            }, false),
    );

export const isMixable = (product) =>
    product.variants && product.variants.some((variant) => variant.mixable === true);

export const getProductCardChips = ({
    product,
    preorderProduct = null,
    productPromotions = null,
}) => {
    const chips = [];
    const isDealProduct = isDealActive(product);
    const isMixProduct = isMixable(product);
    if (isDealProduct) {
        chips.push({ tk: 'CHIP_DEAL_LABEL' });
    }
    if (isMixProduct) {
        chips.push({
            label: 'MIX',
            type: 'promotional',
        });
    }
    if (product.promotional_labels) {
        product.promotional_labels.forEach((label) => {
            chips.push({ label, type: 'promotional' });
        });
    }
    if (product.is_new) {
        chips.push({ tk: 'CHIP_NEW_LABEL', type: 'promotional' });
    }
    if (preorderProduct) {
        chips.push({ tk: 'PREORDER_BADGE', type: 'promotional' });
    }
    if (productPromotions) {
        const plans = productPromotions?.product?.rentalPlans || productPromotions?.rentalPlans;
        const variants = productPromotions?.product?.variants || productPromotions.variants;
        if (plans?.length > 0) {
            const discounts = plans.map((plan) => {
                return {
                    planLength: plan.length?.value,
                    percentDiscount: Math.round(plan.discount?.percentage),
                };
            });
            if (discounts?.length > 0) {
                discounts.forEach((mappedDiscount) => {
                    if (
                        mappedDiscount.percentDiscount !== 0 &&
                        Number.isFinite(mappedDiscount.percentDiscount)
                    ) {
                        chips.push({
                            type: 'discountPercentage',
                            label: `-${mappedDiscount.percentDiscount}%`,
                            planLength: mappedDiscount.planLength,
                        });
                    }
                });
            }
        }
        if (variants?.length > 0) {
            variants.forEach((variant) => {
                if (variant?.flags?.length > 0) {
                    variant.flags.forEach((flag) => {
                        if (flag.isActive && flag.name === 'Low stock') {
                            chips.push({
                                type: 'lowStock',
                                tk: 'LOW_STOCK_CHIP',
                                id: variant.id,
                            });
                        }
                    });
                }
            });
        }
    }
    return chips;
};

/*
    - 'is_available?' controlled in backoffice, if a product is
    in waiting list mode or not
    - having 0 rental plans is a way to turn a product off,
    will be replaced by https://byebuyglobaloperations.atlassian.net/wiki/spaces/TECH/pages/518455376/ProductVariant+availability+strategies
*/
export const productAvailability = ({ currentVariant }) => {
    return Boolean(currentVariant && currentVariant['is_available?']);
};

export const productAdapter = ({
    product,
    defaultCurrency,
    activeLanguage,
    selectedMinDuration,
    promotions,
}) => {
    const {
        id,
        slug,
        variants,
        cheapest_rental_plan: cheapestRentalPlan,
        core_attribute: productCoreAttributes,
        favorite,
        rental_plans: rentalPlans,
        original_image_url: originalImage,
        brand,
        category = {},
    } = product;
    const name =
        product.frontname ||
        (product.contentful_record && product.contentful_record.frontname) ||
        product.name;
    const rentalPlan = rentalPlanAdapter({
        selectedMinDuration,
        rentalPlans,
        cheapestRentalPlan,
    });
    const productPromotions =
        promotions?.products?.find((promoProduct) => String(promoProduct.id) === String(id)) ??
        null;

    const isPreorderProduct = productPromotions?.variants.some((v) => v.preorder !== null);

    const { energyRating: energyClass } = productPromotions?.energyLabel ?? {};
    const chips = getProductCardChips({
        product,
        productPromotions,
        preorderProduct: isPreorderProduct,
    });
    if (!variants) return null;
    const variant = variants[0] || {};
    const { price: rentalPlanPrice, label: rentalPlanLabel, oldPrice: rentalPlanOldPrice } =
        rentalPlan || {};
    const price = formatPriceByLanguage(
        rentalPlanPrice,
        CURRENCIES_ASSOCIATION[defaultCurrency],
        activeLanguage,
    );
    const oldPrice =
        rentalPlanOldPrice &&
        formatPriceByLanguage(
            rentalPlanOldPrice,
            CURRENCIES_ASSOCIATION[defaultCurrency],
            activeLanguage,
        );
    return {
        accentColor: variant.accent_color,
        productName: name,
        productCoreAttributes,
        id,
        slug,
        price,
        oldPrice,
        priceLabel: rentalPlanLabel,
        chips,
        favorite,
        selectedMinDuration,
        rentalPlan,
        sku: product.sku,
        originalImage,
        brand,
        subCategory: category?.title,
        productVariant: variant.id,
        originalProduct: product,
        energyClass,
        isPreorderProduct,
    };
};

import { formatIfNumber } from 'ggApp/utils/money';
import { DEFAULT_VALUE_RENTAL_PLAN } from 'ggApp/modules/filter/constants';
import { cheapestRentalPlanInfo, formatPrice } from '../utils/index';

export function productCategories(permalink) {
    if (!permalink) return null;
    const productCategoryDetails = permalink.substring(1).split('/');
    return {
        category: productCategoryDetails[0],
        dimension13: productCategoryDetails[1],
    };
}
export function productInfo(productFromState) {
    const { name = '', sku = '', brand = '', category } =
        productFromState?.product ?? productFromState;
    const categories = category ? productCategories(category.permalink) : null;
    return {
        name,
        id: sku,
        brand,
        ...categories,
    };
}

export function productId(productFromState) {
    const { id } = productFromState;
    return {
        variant: id && id.toString(),
    };
}

export function lineItemProductInfo(lineItemVariantFromState) {
    const {
        product: { sku, brand, category },
    } = lineItemVariantFromState;
    const categories = productCategories(category.permalink) || null;
    return {
        id: sku,
        brand,
        ...categories,
    };
}

export function productCurrency(productVariantFromState) {
    const { currency } = productVariantFromState;
    return currency;
}

export function productQuantity(product, nextQuantity) {
    const { quantity } = product;
    return { quantity: nextQuantity ? quantity - nextQuantity : quantity };
}

export function productForTeaserClick(product) {
    if (typeof product !== 'object' || product === null) return {};
    const { sku, name, cheapest_rental_plan: cheapestRentalPlan } = product;
    if (!cheapestRentalPlan) {
        return {
            sku,
            product_name: name,
        };
    }
    const {
        minimum_term_months: minimumMonths,
        rental_plan_price_cents: price,
        old_price: oldPrice,
    } = cheapestRentalPlan;
    const originalPrice = oldPrice || price;
    const discountAmount = formatPrice(originalPrice - price);
    return {
        sku,
        product_name: name,
        product_price: originalPrice,
        discounted_price: discountAmount,
        sub_length: minimumMonths,
    };
}

export function getMixPayload({ price = '99.00', count = 0 } = {}) {
    // TODO: price can be changed
    return {
        mixData: {
            mix_price: price,
            mix_cart_count: count,
        },
    };
}

export function getProductDataForWishlist({ product, variant, selectedRentalPlan }) {
    let name;
    let subCategory;
    let price;
    let productVariant;
    const { brand, sku } = product || {};

    const isPayloadFromPdp = variant && selectedRentalPlan;
    const variantSku = variant?.sku || null;

    if (isPayloadFromPdp) {
        name = variant.name;
        productVariant = variant?.id;
        subCategory = product.category?.title;
        price = selectedRentalPlan?.price;
    } else {
        name = product.productName;
        price = product?.price && +product.price.replace(/[^\d]/g, '');
        productVariant = product.productVariant;
        subCategory = product.subCategory;
    }

    const productData = {
        brand,
        displayed_price: formatIfNumber(price, '.'),
        product_name: name,
        product_sku: sku,
        product_variant: productVariant,
        variant_sku: variantSku,
        sub_category: subCategory,
        subscription_length: cheapestRentalPlanInfo(product) ?? DEFAULT_VALUE_RENTAL_PLAN,
    };
    return productData;
}

function getSelectedVariant(item, variant) {
    return item.variant_id || variant.id;
}

function getSelectedRental(item, product) {
    const months = item.committed_months || product.selectedPlan;
    const currentRentalPlanByMonth = product.rentalPlans.find(
        (rentalPlan) => rentalPlan.minimum_term_months === months,
    );

    if (currentRentalPlanByMonth) return currentRentalPlanByMonth;

    const currentRentalPlanId = item.rental_plan_id || product.rentalPlanId;
    const currentRentalPlanById = product.rentalPlans.find(
        (rentalPlan) => rentalPlan.id === currentRentalPlanId,
    );

    return currentRentalPlanById;
}

function getSubcategory(productPayload) {
    const subCategory = productPayload.dimension13 || '';
    return subCategory.length && subCategory[0].toUpperCase() + subCategory.slice(1);
}

export function getProductDataForCartContentChange({ product, item }) {
    const { variant } = product;
    const productPayload = productInfo(variant?.product);
    const rentalPlan = getSelectedRental(item, product);

    return {
        name: productPayload.name,
        brand: productPayload.brand,
        productSKU: productPayload.id,
        sub_category: getSubcategory(productPayload),
        productVariant: getSelectedVariant(item, variant),
        subscriptionLength: rentalPlan.minimum_term_months,
        price: formatPrice(rentalPlan.rental_plan_price_cents),
    };
}

import PropTypes from 'prop-types';

export const mappedProductPropTypes = PropTypes.shape({
    id: PropTypes.number.isRequired,
    accentColor: PropTypes.string,
    chips: PropTypes.arrayOf(PropTypes.object),
    favorite: PropTypes.bool,
    oldPrice: PropTypes.string,
    price: PropTypes.string,
    productCoreAttributes: PropTypes.string,
    productImages: PropTypes.array,
    productName: PropTypes.string,
    slug: PropTypes.string,
});

export const mappedProductDefaultProps = {
    accentColor: undefined,
    chips: [],
    favorite: false,
    oldPrice: undefined,
    price: undefined,
    productCoreAttributes: '',
    productImages: [],
    productName: '',
    slug: '',
};

/* eslint-disable */
import objectPath from 'object-path';

import {
  SET_ADYEN_PAYMENT_STATUS,
  GET_ADYEN_PAYMENT_INFO_SUCCESS,
} from './../actionTypes';


const defaultState = '';

export default function (state = defaultState, action) {
  switch (action.type) {

    case SET_ADYEN_PAYMENT_STATUS:
      return action.data

    case GET_ADYEN_PAYMENT_INFO_SUCCESS:
      return state ? state : objectPath.get(action, 'data.status', defaultState)
    default:
      return state;
  }
}

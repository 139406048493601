import React, { useRef } from 'react';
import { useQuery } from '@apollo/client';

import CircleLoader from 'ggApp/shared/components/CircleLoader';
import { extractCallbacksFromProps } from 'ggApp/modules/tracking/hocs/withListEventHandlers';
import {
    registerTeaserClickEvent,
    registerTeaserImpressionEvent,
} from 'ggApp/modules/gaAnalytics/actions';
import useIntersection from 'ggApp/shared/hooks/useIntersection';
import DisplayIf from 'ggApp/shared/components/viewport';
import Carousel from 'ggApp/shared/components/Carousel';
import { PureTranslator } from 'ggApp/modules/translator/components';
import GetCountdownTimerById from 'ggApp/shared/screens/GPage/queries/GetCountdownTimerById.graphql';
import { WIDGET_NAMES } from 'ggApp/modules/gaAnalytics/constants';

import { Wrap, TitleWrap, Title, Countdown, Cta, Grid, ProductCard } from './styles';

export type CountdownWidgetProps = {
    className?: string;
    products: any[];
    properties: {
        countdownId?: string;
    };
    title?: string;
    cta?: { internal: boolean; url: string; label: string };
    testId?: string;
    productImpressionCallbacks: any;
    _registerTeaserClickProductEvent: (arg: {
        widgetName: string;
        url?: string;
        title?: string;
        firstIndexProduct: number;
        productIndex: number;
    }) => void;
    firstIndexProduct: number;
    ctaViewAll: boolean;
    hideTimerWhenActualDateOutOfRange?: boolean;
    isDealsOfTheWeekWidget?: boolean;
};

const CountdownWidget = ({
    className,
    properties,
    products,
    title,
    cta,
    testId,
    productImpressionCallbacks,
    firstIndexProduct,
    _registerTeaserClickProductEvent,
    ctaViewAll,
    hideTimerWhenActualDateOutOfRange,
    isDealsOfTheWeekWidget,
}: CountdownWidgetProps): JSX.Element | null => {
    const countdownRef = useRef(null);

    const countdownId = properties?.countdownId;
    const { data, loading } = useQuery(GetCountdownTimerById, {
        variables: { id: countdownId },
        skip: !countdownId,
    });
    const countdownData = data?.findCountdownTimerById;
    const { label, url } = cta || {};

    const countDownURL = isDealsOfTheWeekWidget ? url || undefined : url || '/trending';

    useIntersection({
        node: countdownRef,
        onExitCallback: null,
        onEnterCallback: registerTeaserImpressionEvent({
            widgetName: WIDGET_NAMES.countdown,
            url: countDownURL,
            title,
        }),
    });

    const onClickCTA = registerTeaserClickEvent({
        widgetName: WIDGET_NAMES.countdown,
        url: countDownURL,
        title,
        ctaViewAll,
    });

    const onClickProduct = (productSlug: string, productIndex: number) =>
        _registerTeaserClickProductEvent({
            widgetName: WIDGET_NAMES.countdown,
            url: productSlug,
            title,
            firstIndexProduct,
            productIndex,
        });

    if (loading) {
        return <CircleLoader className={className} />;
    }

    if (!countdownData?.isActive) return null;

    return (
        <Wrap className={className} data-testid={testId} ref={countdownRef}>
            <TitleWrap>
                <Title>
                    <PureTranslator tk={title} />
                </Title>
                {countdownData ? (
                    <Countdown
                        countdownById={countdownData}
                        hideTimerWhenActualDateOutOfRange={hideTimerWhenActualDateOutOfRange}
                    />
                ) : null}
            </TitleWrap>
            <DisplayIf tablet desktop largeDesktop widescreen>
                <Grid>
                    {products?.map((product: any, index: number) => (
                        <ProductCard
                            key={`${product.slug}`}
                            product={product}
                            cardType="vertical"
                            onClick={() => onClickProduct(product.slug, index)}
                            {...extractCallbacksFromProps(productImpressionCallbacks, index)}
                        />
                    ))}
                </Grid>
            </DisplayIf>
            <DisplayIf mobile>
                <Carousel
                    products={products}
                    cardWidth={280}
                    productImpressionCallbacks={productImpressionCallbacks}
                />
            </DisplayIf>
            {!!countDownURL && (
                <Cta
                    onClick={onClickCTA}
                    target="_self"
                    linkTo={countDownURL}
                    text={<PureTranslator tk={label} />}
                />
            )}
        </Wrap>
    );
};

export default CountdownWidget;

/* eslint-disable */
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { loadingStatus } from 'ggApp/modules/requestStatus/selectors';
import { compose } from 'recompose';
import LoadingModal from 'ggApp/shared/common/LoadingModal/LoadingModal';


export default compose(
    connect(
        createStructuredSelector({
            loading: loadingStatus,
        }),
    ),
)(LoadingModal);

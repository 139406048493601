import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import { translationFunctionSelector } from '../../selectors';
import { TranslatorContext } from './TranslatorContext';
import { TranslatorProviderProps, TranslatorProviderEnhancedProps } from './types';

const enhance = connect<TranslatorProviderEnhancedProps, {}, TranslatorProviderProps>((state) => ({
    translate: translationFunctionSelector(state),
}));

const BasicTranslatorProvider = ({
    children,
    translate,
}: TranslatorProviderProps & TranslatorProviderEnhancedProps): JSX.Element => {
    const providerValue = useMemo(() => ({ t: translate }), [translate]);

    return (
        <TranslatorContext.Provider value={providerValue}>{children}</TranslatorContext.Provider>
    );
};

export const TranslatorProvider = enhance(BasicTranslatorProvider);

import React, { PureComponent } from 'react';

import { TranslatorComponent_Text as Translator } from 'ggApp/modules/translator';

import { ButtonWrapper, Link, Button, Text } from './styles';

export default class GgButton extends PureComponent {
    render() {
        const {
            to,
            tk,
            onClick,
            loading,
            disabled,
            type,
            black,
            inputSize,
            fixedToBottom,
            noBorder,
            autoWidth,
            paypal,
            noArrow,
            modalMarginBottom,
            fixedToBottomMobile,
            noBorderMobile,
            noMarginMobile,
            dark,
        } = this.props;

        return to ? (
            <ButtonWrapper
                disabled={disabled || loading}
                loading={loading}
                fixedToBottom={fixedToBottom}
                autoWidth={autoWidth}
                modalMarginBottom={modalMarginBottom}
                fixedToBottomMobile={fixedToBottomMobile}
                noBorderMobile={noBorderMobile}
                noMarginMobile={noMarginMobile}
                dark={dark}
            >
                <Link
                    onClick={onClick}
                    to={to}
                    disabled={disabled}
                    black={black}
                    inputSize={inputSize}
                    autoWidth={autoWidth}
                    paypal={paypal}
                    noBorder={noBorder}
                    dark={dark}
                    loading={loading}
                    noBorderMobile={noBorderMobile}
                    flex
                >
                    <Text noArrow={noArrow}>
                        <Translator tk={tk} />
                    </Text>
                </Link>
            </ButtonWrapper>
        ) : (
            <ButtonWrapper disabled={disabled}>
                <Button
                    disabled={disabled}
                    onClick={onClick}
                    type={type}
                    black={black}
                    inputSize={inputSize}
                    autoWidth={autoWidth}
                    paypal={paypal}
                    noBorder={noBorder}
                    dark={dark}
                    loading={loading}
                    noBorderMobile={noBorderMobile}
                >
                    <Text noArrow={noArrow}>
                        <Translator tk={tk} />
                    </Text>
                </Button>
            </ButtonWrapper>
        );
    }
}

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
import './styles.scss';

import loadConfiguration from 'ggApp/utils/configuration';
import { fireEvent } from 'ggApp/modules/inwebview_api';
import { isAppUser } from 'ggApp/routes/_wrap/MobileWrapper/utils';
import { canUseDOM } from 'ggApp/utils/dom';

import { INTERCOM_LAUNCHER_LINK_CLASS } from '../constants';
import { SHUTDOWN_INTERCOM } from '../constants/actionTypes';

const { APP_CONFIG } = loadConfiguration();
export const appId = APP_CONFIG.intercomAppId;

const isServer = __IS_SERVER__;
const TOGGLE_INTERCOM = 'TOGGLE_INTERCOM';

let __isIntercomButtonShown = true;

export function isIntercomLoaded() {
    return !isServer && typeof window.Intercom === 'function';
}

// WARNING: This is dirty hack. Please remove it,
// when custom Intercom button will be presented
function updateIntercomButtonShownState(isShown) {
    if (document) {
        const body = document.getElementsByTagName('body')[0];
        body && body.classList.toggle('hidden-intercom-launcher', !isShown);
        __isIntercomButtonShown = isShown;
    }
}

/**
 * createSettingsForIntercom
 * @description create settings for Intercom
 * @function createSettingsForIntercom
 * @param {Object} - props
 * @param {String} props.intercomHMAC - hmac to enable identity verification
 * @returns {Object} settings {
 *  @string app_id -  workspace ID (won't work without it!)
 *  @string user_id - id of the user
 *  @string phone - phone number of the user
 *  @string name - full name of the user
 *  @string email - email of the user
 *  @string user_hash - HMAC calculated on either the user’s email or user_id
 *  @string company_name - company name in case of Business account
 * }
 */

export function createSettingsForIntercom({
    intercomHMAC,
    activeStore,
    user,
    hasCookiePolicyConsent,
}) {
    const settings = {
        app_id: appId,
        custom_launcher_selector: `.${INTERCOM_LAUNCHER_LINK_CLASS}`,
        flavour: window.ReactNativeWebView ? 'app' : 'web',
        active_store: activeStore.code,
    };

    if (intercomHMAC) {
        settings.user_id = user.id;
        settings.phone = `+${user.phone}`;
        settings.name = `${user.first_name} ${user.last_name}`;
        settings.email = user.email;
        settings.user_hash = intercomHMAC;

        if (user.company) {
            const { name, id, ust } = user.company;

            settings.company = {
                name,
                id,
                ust,
            };

            settings.has_company_access = true;
        }
    }
    if (hasCookiePolicyConsent) {
        settings.cookie_consent_recorded = hasCookiePolicyConsent;
    }

    return settings;
}

// eslint-disable-next-line consistent-return
export function loadIntercomWidget(intercomSettings) {
    if (!canUseDOM()) {
        return null;
    }
    const intercom = window.Intercom;

    if (typeof intercom === 'function') {
        intercom('reattach_activator');
        intercom('update', intercomSettings);
    } else {
        const i = function int(...args) {
            i.c(args);
        };

        i.q = [];
        i.c = function int(args) {
            i.q.push(args);
        };

        window.Intercom = i;

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.defer = 'defer';
        script.src = `https://widget.intercom.io/widget/${appId}`;
        const firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.insertBefore(script, firstScript);
    }

    window.intercomSettings = intercomSettings;
}

export function updateIntercomSettings(settings) {
    if (!isIntercomLoaded()) {
        return;
    }

    if (settings) {
        window.Intercom('update', settings);
    } else {
        window.Intercom('update');
    }
}

export function bootIntercom(settings) {
    if (isIntercomLoaded()) {
        window.Intercom('boot', settings);
    }
}

export function shutdownIntercom() {
    if (isIntercomLoaded()) {
        window.Intercom('shutdown');
    }

    if (isAppUser()) {
        fireEvent(SHUTDOWN_INTERCOM);
    }
}

export function showIntercomButton() {
    updateIntercomButtonShownState(true);
}

export function hideIntercomButton() {
    updateIntercomButtonShownState(false);
}

export function isIntercomButtonShown() {
    return __isIntercomButtonShown;
}

export function subscribeToUnreadMessages(callback) {
    if (isIntercomLoaded()) {
        window.Intercom('onUnreadCountChange', callback);
    }
}

export function openIntercomWithPresetMessage(presetMessage) {
    if (isIntercomLoaded()) {
        window.Intercom('showNewMessage', presetMessage);
    }
}

export function openNewIntercomMessage() {
    if (isIntercomLoaded()) {
        window.Intercom('showNewMessage');
    }
}

export function setIntercomUserAttribute(payload) {
    if (isIntercomLoaded()) {
        window.Intercom('update', payload);
    }
}

export function publishUserLoggedIn() {
    if (isIntercomLoaded()) {
        window.Intercom('trackEvent', 'user-logged-in', {
            visitor_id: window.Intercom('getVisitorId'),
        });
    }
}

export function listenToIntercomMessengerAppearance() {
    if (isIntercomLoaded()) {
        window.Intercom('onShow', () => {
            fireEvent(TOGGLE_INTERCOM, { visible: true });
        });

        window.Intercom('onHide', () => {
            fireEvent(TOGGLE_INTERCOM, { visible: false });
        });
    }
}

import qs from 'query-string';
import { DEFAULT_VALUE_RENTAL_PLAN } from 'ggApp/modules/filter/constants';

export function category(permalink) {
    let categorySlug = permalink;

    if (permalink.indexOf('/categories2/') !== -1) {
        [, categorySlug] = permalink.split('categories2');
    }

    return `${categorySlug}`;
}

export function product(slug, selectedMinDuration) {
    const params =
        selectedMinDuration && selectedMinDuration !== DEFAULT_VALUE_RENTAL_PLAN
            ? `?selectedMinDuration=${selectedMinDuration}`
            : '';
    return `/products/${slug}${params}`;
}

export function search(query) {
    const filter = qs.stringify({ search: query }, { arrayFormat: 'bracket' });
    return `/search?filter=${encodeURIComponent(filter)}`;
}

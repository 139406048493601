/* eslint-disable */
import * as action from '../actionTypes';

const defaultState = {
    data: {
        code: '',
        owner: {},
        list: [],
    },
    error: {},
};

export default (state = defaultState, { type, payload, error }) => {
    switch (type) {
        case action.GET_VOUCHER_CODE_SUCCESS: {
            const { code } = payload;
            return {
                data: {
                    ...state.data,
                    code,
                },
            };
        }
        case action.GET_VOUCHER_OWNER_SUCCESS: {
            const { user } = payload;
            return {
                data: {
                    ...state.data,
                    owner: user,
                },
            };
        }
        case action.GET_VOUCHER_LIST_SUCCESS: {
            const { list } = payload;
            return {
                data: {
                    ...state.data,
                    list,
                },
            };
        }
        case action.GET_VOUCHER_CODE_ERROR:
        case action.GET_VOUCHER_OWNER_ERROR:
        case action.GET_VOUCHER_LIST_ERROR:
            return {
                ...state,
                error,
            };
        default:
            return state;
    }
};
import { combineReducers } from 'redux';
import user from './user';
import newsletter from './newsletter';
import passwordRecovery from './passwordRecovery';
import fetching from './fetching';
import phoneVerification from './phoneVerification';
import company from './company';
import b2bRedirection from './b2bRedirection';
import twoFactorAuthentication from './twoFactorAuthentication';

export default combineReducers({
    user,
    newsletter,
    phoneVerification,
    fetching,
    passwordRecovery,
    company,
    b2bRedirection,
    twoFactorAuthentication,
});

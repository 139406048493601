import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import CloseIcon from 'ggApp/shared/icons/close';

import { Container, ActionsContainer, ANIMATION_DURATION_IN_MS, CloseButton } from './styles';
import ModalContent from './content';
import ModalBackground from './background';

const modalClassNamesPropType = PropTypes.shape({
    container: PropTypes.string,
    content: PropTypes.string,
    actionsContainer: PropTypes.string,
});

/**
 * Base component for modal window.
 * @class Modal
 * @extends {PureComponent}
 * @prop {func} onBackgroundClick - This callback calls on background area click.
 * @prop {func} onExitAnimationDone - This callback calls when modal window completely hidden.
 * @prop {func} renderActions - Render prop for action area rendering.
 * @prop {bool} isShown - Prop to show up the modal. It needs for animations.
 * @prop {string} width - `width` value of modal container.
 * @prop {string} maxWidth - `max-width` value of modal container.
 * @prop {object} classNames - Dictionary of the classNames for components.
 */
class Modal extends PureComponent {
    constructor(props) {
        super(props);

        this.content = null;
        this.state = {
            hasOverflowingContent: false,
        };

        this.contentOverflowHandler = this.contentOverflowHandler.bind(this);
    }

    /**
     * contentOverflowHandler
     * This callback calls when content overflow state changes
     * @param {bool} hasOverflowingContent
     * @memberof Modal
     */
    contentOverflowHandler(hasOverflowingContent) {
        this.setState({
            hasOverflowingContent,
        });
    }

    renderModalActions = () => {
        const { renderActions, classNames, withBackgroundColor } = this.props;
        const { hasOverflowingContent } = this.state;

        if (typeof renderActions === 'function') {
            return (
                <ActionsContainer
                    isFixed={hasOverflowingContent}
                    withBackgroundColor={withBackgroundColor}
                    className={classNames.actionsContainer}
                >
                    {renderActions({ hasOverflowingContent })}
                </ActionsContainer>
            );
        }

        return null;
    };

    render() {
        const {
            children,
            isShown,
            onBackgroundClick,
            onExitAnimationDone,
            height,
            width,
            maxWidth,
            animationDuration,
            className,
            classNames,
            closeIcon,
            onCloseIconClick,
            withBackgroundColor,
        } = this.props;
        return (
            // eslint-disable-next-line react/jsx-filename-extension
            <Transition
                in={isShown}
                timeout={animationDuration}
                appear
                mountOnEnter
                unmountOnExit
                onExited={onExitAnimationDone}
            >
                {(animationState) => (
                    <ModalBackground
                        animationState={animationState}
                        animationDuration={animationDuration}
                        className={className}
                        onBackgroundClick={onBackgroundClick}
                        withBackgroundColor={withBackgroundColor}
                    >
                        <Container
                            height={height}
                            width={width}
                            maxWidth={maxWidth}
                            animationState={animationState}
                            animationDuration={animationDuration}
                            className={classNames.container}
                        >
                            {closeIcon && (
                                <CloseButton onClick={onCloseIconClick}>
                                    <CloseIcon />
                                </CloseButton>
                            )}
                            <ModalContent
                                onOverflowUpdate={this.contentOverflowHandler}
                                className={classNames.content}
                                isShown={isShown}
                            >
                                {children}
                            </ModalContent>

                            {this.renderModalActions()}
                        </Container>
                    </ModalBackground>
                )}
            </Transition>
        );
    }
}

Modal.propTypes = {
    children: PropTypes.node,
    renderActions: PropTypes.func,
    onBackgroundClick: PropTypes.func,
    onExitAnimationDone: PropTypes.func,
    isShown: PropTypes.bool,
    height: PropTypes.string,
    width: PropTypes.string,
    maxWidth: PropTypes.string,
    animationDuration: PropTypes.number,
    className: PropTypes.string,
    classNames: modalClassNamesPropType,
    closeIcon: PropTypes.bool,
    onCloseIconClick: PropTypes.func,
};

Modal.defaultProps = {
    children: null,
    renderActions: undefined,
    isShown: false,
    animationDuration: ANIMATION_DURATION_IN_MS,
    height: '',
    width: '',
    maxWidth: '',
    className: undefined,
    classNames: {},
    onBackgroundClick: () => {},
    onExitAnimationDone: () => {},
    closeIcon: false,
    onCloseIconClick: () => {},
};

export default Modal;
export { modalClassNamesPropType };

import styled, { css } from 'styled-components';
import { CtaButton, ButtonV2 } from '@getgrover/ui';

import Cta from 'ggApp/shared/components/widgets/cta';
import Typography from 'ggApp/shared/components/typography';
import ContentfulResolutionImage from 'ggApp/shared/components/resolutionImage/ContentfulResolutionImage';

const Wrapper = styled.div`
    position: relative;
    height: 420px;
`;

const Banner = styled(ContentfulResolutionImage)`
    > img {
        border-radius: 8px;
        object-fit: cover;
        ${({ theme }) => theme.superQuery.all.and.maxWidth.mobileMax.css`
            background-image: ${({ backgroundImage }) => `url("${backgroundImage}")`};
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top right;
        `}
    }
`;

const ContentWrap = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
`;

const Content = styled.div`
    margin: 0 55px;
    width: 400px;
    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
        margin: 0 ${theme.spacers.L}};
    `}
    ${({ theme }) => theme.superQuery.all.and.maxWidth.mobileMax.css`
        width: 100%;
        margin: 0 ${theme.spacers.L}};
    `}
    display: flex;
    flex-direction: column;
`;

const Header = styled.h2`
 ${({theme}) => css(theme.typography.Headline)};
    margin-bottom: 16px;
    line-height: 43px !important;
    color: ${({ textColor }) => textColor || '#ffffff'};
    ${({ theme }) => theme.superQuery.all.and.maxWidth.mobileMax.css`
        font-size: 32px !important;
    `}
    ${({ theme }) => theme.superQuery.minWidth.tabletMin.and.maxWidth.tabletMax
        .css`
        font-size: 24px !important;
        line-height: 29px !important;
    `}
    ${({ theme }) => theme.superQuery.all.and.maxWidth.desktopMax.css`
        margin-bottom: ${theme.spacers.L}};
    `}
`;

const Body = styled(Typography)`
 ${({theme}) => css(theme.typography.Paragraph)};
    line-height: 27px !important;
    color: ${({ textColor }) => textColor || '#ffffff'};
    * {
        color: ${({ textColor }) => textColor || '#ffffff'};
    }
    margin-bottom: ${({ theme }) => theme.spacers.L};
`;

const WidgetCta = styled(Cta)`
    margin-top: ${({ theme }) => theme.spacers.L};
    height: 48px;
    border-radius: 24px;
    justify-self: start;
    background: ${({ theme }) => theme.colors.Color1};
    color: white;
    padding: 0;
    transition-property: none;
`;

const LinkContent = styled(CtaButton)``;

export const CtaWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.Background};
    width: min-content;
    border-radius: 88px;
`;

export const RedCTAButton = styled(ButtonV2)`
    &:hover {
        color: ${({ theme }) => theme.colors.Foreground} !important;
        background-color: ${({ theme }) => theme.colors.Background} !important;
    }
`;

export {
    Wrapper,
    ContentWrap,
    Content,
    Header,
    Body,
    WidgetCta,
    LinkContent,
    Banner,
};

export const convertAddressPayload = ({
    additional_info: additionalInfo,
    address1: line1,
    address2: line2,
    city,
    country_name: country,
    id,
    type,
    zipcode: zip,
    country_code: countryIso,
}: any) => ({ additionalInfo, line1, line2, city, countryIso, country, id, type, zip });

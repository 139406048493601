import { sendStructEvent } from '../../api';
import { EventProperty } from '../../types';

interface PayloadModel {
    eventAction: string;
    eventLabel: string;
    eventProperty: EventProperty;
}

export const sendSitewideBannerEvent = (payload: PayloadModel): void => {
    const { eventAction, eventLabel, eventProperty } = payload;
    sendStructEvent({
        eventCategory: 'Sitewide Top Banner',
        eventAction,
        eventLabel,
        eventProperty,
    });
};

import React from 'react';
import PropTypes from 'prop-types';

import { Translator } from 'ggApp/modules/translator/components';
import If from 'ggApp/shared/components/Conditional';
import { SmallHeadline } from 'ggApp/shared/common/Typography';

import { Container, Image, ErrorText, Text, Title, LinkButton } from './styled';

const NotFound = ({ className, errorStatus, hasButton }) => (
    <Container className={className}>
        <Image />
        <ErrorText>
            <Translator tk="ERROR_PAGE_404_SMALLCAPS_HEADLINE" withValues={{ errorStatus }} />
        </ErrorText>
        <Title>
            <Translator tk="ERROR_PAGE_404_TITLE" />
        </Title>
        <Text>
            <Translator tk="ERROR_PAGE_404_TEXT" />
        </Text>
        <If condition={hasButton}>
            <LinkButton url="https://www.grover.com" type="countrySpecificLink">
                <SmallHeadline>
                    <Translator tk="ERROR_PAGE_404_BUTTON_TEXT" />
                </SmallHeadline>
            </LinkButton>
        </If>
    </Container>
);

NotFound.propTypes = {
    className: PropTypes.string,
    errorStatus: PropTypes.number,
    hasButton: PropTypes.bool,
};

NotFound.defaultProps = {
    className: '',
    errorStatus: 404,
    hasButton: true,
};

export default NotFound;

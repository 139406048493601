import React from 'react';
import { canUseDOM } from 'ggApp/utils/dom';
import loadConfiguration from 'ggApp/utils/configuration';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { user as userSelector } from 'ggApp/modules/auth/selectors';
import { compose } from 'recompose';
import isTruthy from 'ggApp/utils/truthy';
import {
    locationPathname as locationPathnameSelector,
    params as paramsSelector,
} from 'ggApp/modules/router/selectors';
import { hasMarketingConsent as hasMarketingConsentSelector } from 'ggApp/modules/cookiePolicy/state/selectors';

import {
    brazeChangeUser,
    enableBrazeTracking,
    disableBrazeTracking,
    brazeInitialize,
    brazeAutomaticallyShowNewInAppMessages,
} from './api';
import { prepareBrazeClient, loadBrazeSdk } from './sdk.script';
import { BrazeContext } from './context';

const { BRAZE_API_KEY, BRAZE_SDK_ENDPOINT, BRAZE_WEB_PUSH_ID } = loadConfiguration();

const sdkEnabled = isTruthy([BRAZE_API_KEY, BRAZE_SDK_ENDPOINT]);
const BRAZE_LOADING_ERROR = 'Braze was not loaded.';

// Use Default export!
// This one is only for testing
export class BrazeLoader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            started: false,
            loaded: false,
            disabled: true,
        };

        if (!canUseDOM() || !sdkEnabled) {
            return;
        }

        prepareBrazeClient();
    }

    componentDidMount() {
        loadBrazeSdk()
            .then(() => this.setState({ loaded: true }))
            .catch(() => {
                console.warn(BRAZE_LOADING_ERROR);
            });

        if (!this.isTrackingAvailable) {
            this.disable();
            brazeInitialize({
                apiKey: BRAZE_API_KEY,
                sdkUrl: BRAZE_SDK_ENDPOINT,
                webPushId: BRAZE_WEB_PUSH_ID,
                enableLogging: false,
            });
        } else {
            this.enable();
        }
        this.start();
    }

    componentDidUpdate(prevProps) {
        const { user, pathname, hasMarketingConsent } = this.props;
        const { loaded, disabled } = this.state;
        if (
            prevProps.user.id !== user.id ||
            prevProps.pathname !== pathname ||
            prevProps.hasMarketingConsent !== hasMarketingConsent
        ) {
            if (this.isTrackingAvailable) {
                if (!loaded) {
                    loadBrazeSdk()
                        .then(() => this.setState({ loaded: true }))
                        .catch(() => {
                            console.warn(BRAZE_LOADING_ERROR);
                        });
                }
                if (disabled) {
                    this.enable();
                }
                if ((!prevProps.user.id && prevProps.user.id !== user.id) || disabled) {
                    brazeChangeUser(user.id);
                }
                this.start();
            } else {
                this.disable();
            }
        }
    }

    get isTrackingAvailable() {
        const { hasMarketingConsent } = this.props;
        return hasMarketingConsent;
    }

    // eslint-disable-next-line class-methods-use-this
    enable() {
        const { disabled } = this.state;
        if (!disabled) {
            return;
        }
        enableBrazeTracking();
        brazeInitialize({
            apiKey: BRAZE_API_KEY,
            sdkUrl: BRAZE_SDK_ENDPOINT,
            webPushId: BRAZE_WEB_PUSH_ID,
            enableLogging: false,
        });
        brazeAutomaticallyShowNewInAppMessages();
        this.setState({ disabled: false });
    }

    disable() {
        const { disabled, started } = this.state;
        if (disabled && started) {
            return;
        }
        disableBrazeTracking();
        this.setState({ disabled: true });
    }

    start() {
        const { started } = this.state;
        const { user } = this.props;

        if (started) {
            return;
        }

        if (user.id) {
            brazeChangeUser(user.id);
        }

        this.setState({ started: true });
    }

    render() {
        const { started } = this.state;
        const { children } = this.props;
        return <BrazeContext.Provider value={{ started }}>{children}</BrazeContext.Provider>;
    }
}

BrazeLoader.propTypes = {
    pathname: PropTypes.string.isRequired,
    params: PropTypes.shape({
        store: PropTypes.string,
        language: PropTypes.string,
    }).isRequired,
    user: PropTypes.shape({
        id: PropTypes.number,
    }),
};

BrazeLoader.defaultProps = {
    user: {},
};

const enhance = compose(
    connect(
        createStructuredSelector({
            user: userSelector,
            pathname: locationPathnameSelector,
            params: paramsSelector,
            hasMarketingConsent: hasMarketingConsentSelector,
        }),
        null,
    ),
);

export default enhance(BrazeLoader);

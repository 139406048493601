/* eslint-disable */
const
  defaultState = {
    err: {},
    setup: {
      url: {
        success: '',
        failure: '',
      },
    },
    instance: null,
  };


export default function (state = defaultState, action) {
  switch (action.type) {

    case 'SET_BT_PP_DATA':
      return Object.assign({}, state, action.data);

    case 'BRAINTREE_CONFIRM_PAYPAL':
      return Object.assign({}, state, {
        err: defaultState.err,
      });

    case 'BRAINTREE_CONFIRM_PAYPAL_FAILURE':
      return Object.assign({}, state, {
        err: action.data,
      });

    default:
      return state;
  }
}

import styled from 'styled-components';

import { Headline } from 'ggApp/shared/common/Typography';
import { Card as WidgetProductCardComponent } from 'ggApp/shared/components/cards/widgetProductCard';
import WidgetGrid from 'ggApp/shared/components/widgets/grid';
import WidgetCta from 'ggApp/shared/components/widgets/cta';

const Wrap = styled.div``;

const Title = styled(Headline)`
    color: ${({ theme }) => theme.colors.Foreground};
    margin-bottom: ${({ theme }) => theme.spacers.L};
    ${({ theme }) => theme.typography.Headline};
    font-size: 32px;
    line-height: 1.3;
    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
        ${theme.typography.Subheadline};
        padding: 0 ${theme.spacers.L};
    `};
`;

const Grid = styled(WidgetGrid)`
    && {
        margin: ${({ theme }) => theme.spacers.L} 0px 0px;

        ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
            margin: 0 ${theme.spacers.L};
        `};
    }
`;

const ProductCard = styled(WidgetProductCardComponent)`
    grid-column: span 3;
`;

const Cta = styled(WidgetCta)`
    align-self: flex-start;
    margin: 24px 0 0;
    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
        position: relative;
        margin-left: ${theme.spacers.L};
        margin-right: ${theme.spacers.L};
    `};
`;

export {
    Wrap,
    Title,
    Grid,
    ProductCard,
    Cta,
};

import React from 'react';
import { withTheme } from 'styled-components';
import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';

import { useFeatureFlags, ENCLOSED_CART } from 'ggApp/modules/featureFlag';
import { redirectus } from 'ggApp/modules/router/actions';
import { Wrap, Button, Icon, UnstyledDiv } from './styles';

const CartButton = ({ className, theme, activeStore, hideButtonStyling }) => {
    const { isFlagEnabled } = useFeatureFlags();
    const WrapComponent = hideButtonStyling ? UnstyledDiv : Wrap;
    const ButtonComponent = hideButtonStyling ? UnstyledDiv : Button;
    return (
        <WrapComponent
            className={className}
            onClick={
                isFlagEnabled(ENCLOSED_CART)
                    ? redirectus(`/${activeStore.code}-${activeStore.activeLanguage}/cart`, true)
                    : redirectus(`/${activeStore.code}-${activeStore.activeLanguage}`, true)
            }
            data-testid="cart-button"
        >
            <ButtonComponent>
                <Icon color={theme.colors.Foreground} />
            </ButtonComponent>
        </WrapComponent>
    );
};

const enhance = compose(
    connect(
        createStructuredSelector({
            activeStore: activeStoreSelector,
        }),
    ),
    withTheme,
);

export default enhance(CartButton);

/* eslint-disable */
import objectPath from 'object-path';

export function variantSelectorList(variants) {
    return variants
        .filter(isValidVariant)
        .map(variantMapper)
}

function isValidVariant(variant) {
    const {
        id,
        property = {},
    } = variant || {};
    const {
        title,
        codes,
    } = property;
    return Boolean(id) &&
        Boolean(title) &&
        Boolean(codes);
}

function variantMapper(variant) {
    const variantId = variant.id;
    const name = variant.property.title;
    const borderColor = objectPath.get(variant, 'property.codes.0', null);
    const backgroundColor = objectPath.get(variant, 'property.codes.1', borderColor);

    return {
        variantId,
        name,
        borderColor,
        backgroundColor,
    }
}

export const ANALYTIC_EVENTS = {
    gtmEvent: 'gtmEvent',
    productDetailVImpression: 'eeProductDetailVImpression',
    productImpression: 'eeProductImpression',
    productClick: 'eeProductClick',
    addToBasket: 'eeAddToBasket',
    removeFromBasket: 'eeRemoveFromBasket',
    eeCheckout: 'eeCheckout',
    eePurchase: 'eePurchase',
    purchase: 'Purchase',
    checkoutOptions: 'eeCheckoutOptions',
    basket: 'Basket',
    pdpView: 'Pdp view',
    productImpressionSnowplow: 'Product Impression',
    productClickSnowplow: 'Product Click',
    productPage: 'Product page',
    cartHighlightInRegistration: 'registration to checkout',
    paypalTest: 'paypal_test',
    loggedOutReferrals: 'Logged out referrals',
    loggedInReferrals: 'Logged In referrals',
    availabilityService: 'Availability Service',
    homepageCards: 'Homepage Cards',
    newCheckout: 'checkout',
    mixLpClick: 'MIX LP Click',
    bundleImpression: 'bundleImpression',
    addBundleToCart: 'addBundleToCart',
    pagination: 'pagination',
    subCatMarketingCardClick: 'SubCat Marketing Card Click',
    footerCtaClick: 'Footer Cta Click',
    landingPageCtaClick: 'Landing Page Cta Click',
};

export const WIDGET_NAMES = {
    marketing1: 'MC1',
    marketing2: 'MC2',
    marketing3: 'MC3',
    myFeed: 'My Feed Widget',
    mostPopular: 'Most Popular Widget',
    howItWorks: 'How it works widget',
    productListing: 'Product Listing Widget',
    productHighlight: 'Product Highlight',
    countdown: 'Countdown Widget',
};

export const SEARCH_OVERLAY_ACTION = {
    enter: 'enter',
    productClick: 'product_click',
    ctaClick: 'cta_click',
    exit: 'exit',
    pressEnter: 'press_enter',
};

export const CATALOG_ROUTES_FOR_LIST = {
    forYou: 'for-you',
    favorites: 'favorites',
    trending: 'trending',
    deals: 'deals',
    search: 'search',
    products: 'products',
    featured: 'featured',
    referFriend: 'invite-friend',
    join: 'join',
    newReferralsHost: 'referrals',
    newReferralsGuest: 'referred',
    confirmation: 'confirmation',
    gPage: 'g-',
};

export const LIST_NAME = {
    category: 'categoryPage',
    subcategory: 'subcategory',
    home: 'HomePage',
    [CATALOG_ROUTES_FOR_LIST.forYou]: 'forYou',
    [CATALOG_ROUTES_FOR_LIST.search]: 'search',
    [CATALOG_ROUTES_FOR_LIST.trending]: 'trending',
    [CATALOG_ROUTES_FOR_LIST.featured]: 'seoProducts',
    [CATALOG_ROUTES_FOR_LIST.deals]: 'deals',
    [CATALOG_ROUTES_FOR_LIST.forYou]: 'forYou',
    [CATALOG_ROUTES_FOR_LIST.products]: 'pdp',
    [CATALOG_ROUTES_FOR_LIST.favorites]: 'favorites',
    [CATALOG_ROUTES_FOR_LIST.referFriend]: 'inviteFriend',
    [CATALOG_ROUTES_FOR_LIST.join]: 'join',
    [CATALOG_ROUTES_FOR_LIST.newReferralsHost]: 'referralCode',
    [CATALOG_ROUTES_FOR_LIST.newReferralsGuest]: 'referralCode',
    [CATALOG_ROUTES_FOR_LIST.confirmation]: 'confirmation',
    [CATALOG_ROUTES_FOR_LIST.gPage]: 'LPB',
};

export const SHARING_PDP_EVENTS = {
    enter: 'enter',
    exitByClose: 'exit.modal_close',
    exitBySharingEvent: 'exit.confirm',
};

export const SUBSCRIPTION_EVENTS = {
    upgradeSubscriptionPlan: 'upgrade_subscription_plan',
    viewSubscriptionDetails: 'view_subscription_details',
    viewSubUpgradeOptions: 'view_sub_upgrade_options',
    viewSubUpgradeOptionDetail: 'view_sub_upgrade_option_detail',
};

export const PAYMENTS_EVENTS = {
    expandOrderDetails: 'expand_order_details',
    downloadInvoice: 'download_invoice',
    paymentView: 'payment_view',
    viewSubscriptions: 'view_subscriptions',
};

export const PROFILE_EVENTS = {
    viewGetVerified: 'view_get_verified',
    startVerification: 'start_verification',
    confirmPersonalInfo: 'confirm_personal_info',
};

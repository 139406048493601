export interface AddressProps {
    id?: string;
    address1: string;
    address2: string;
    city: string;
    country: string;
    zip: string;
    countryCode: number;
    additionalInfo?: string;
    delivery?: any;
}

export const findAddressById = ({
    userAddresses = [],
    addressId,
}: {
    addressId: string | number;
    userAddresses: Array<any>;
}): AddressProps => {
    const address = userAddresses.find((i) => i.id === addressId);
    if (!address) {
        return null;
    }

    return {
        id: address?.id ?? null,
        address1: address?.address1 ?? null,
        address2: address?.address2 ?? null,
        city: address?.city ?? null,
        country: address?.country_name ?? null,
        zip: address?.zipcode ?? null,
        countryCode: address?.country_id ?? null,
        additionalInfo: address?.additional_info ?? null,
    };
};

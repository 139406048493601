import flows from 'ggApp/modules/newCheckout/constants/flowNames';
import stepNames from 'ggApp/modules/newCheckout/constants/stepNames';
import { CheckoutMachine } from 'ggApp/shared/context/Order/services/integration';
import { formatPrice } from 'ggApp/modules/gaAnalytics/utils';
import { ProductDataForCheckoutStepModel } from 'snowplow';

export const getCheckoutFlow = (newCheckoutFlag: { variantKey: string }): string => {
    return newCheckoutFlag && newCheckoutFlag.variantKey === 'with-home-address'
        ? flows.CHECKOUT_WITH_HOME_ADDRESS
        : flows.CHECKOUT_NO_HOME_ADDRESS;
};

export const getStepFromState = (state: object): string | undefined => {
    return Object.values(stepNames).find((state as { matches: (s: string) => boolean }).matches);
};

export const getDataFromContext = (ctx: CheckoutMachine) => {
    let userId;
    let orderId;
    try {
        const {
            orderNumber,
            user: { id },
        } = ctx;
        [userId, orderId] = [id, orderNumber];
    } catch (e) {
        [userId, orderId] = [null, null];
    }

    return { userId, orderId };
};

interface ItemModel {
    name: string;
    variant: {
        product: {
            sku: string;
            category: {
                permalink: string;
            };
        };
    };
    selectedPlan: number;
    priceCents: number;
}

export const getOrderData = (data: {
    price: number;
    items: ItemModel[];
    storeId: number;
    isFlex: boolean;
}): { price: string; productData: ProductDataForCheckoutStepModel } => {
    const { price, items, storeId, isFlex = false } = data;
    const productData = {
        name: '',
        productSKU: '',
        subscriptionLength: '',
        price: '',
        sub_category: '',
    };
    const subCategories = new Set();
    for (let i = 0; i < items.length; i += 1) {
        const product = items[i];
        productData.name += i ? `,${product.name}` : product.name;
        productData.productSKU += i
            ? `,${product.variant.product.sku}`
            : `${product.variant.product.sku}`;
        const [subCategory] =
            product.variant.product.category?.permalink?.split('/').slice(-1) ?? '';
        subCategories.add(subCategory);
        if (isFlex) {
            productData.subscriptionLength += i
                ? `,${product.selectedPlan}`
                : `${product.selectedPlan}`;
            productData.price += i
                ? `,${formatPrice(product.priceCents)}`
                : `${formatPrice(product.priceCents)}`;
        }
    }

    productData.sub_category = Array.from(subCategories).join(',');

    return {
        price: formatPrice(price),
        store: storeId,
        productData,
    };
};

const configurationShape = (configurationConstants) => {
    const {
        G_PAGES_CONTENTFUL_API_HOST,
        CONTENTFUL_SPACE_ID,
        CONTENTFUL_DELIVERY_ACCESS_TOKEN,
        G_PAGES_CONTENTFUL_SPACE_ID,
        G_PAGES_CONTENTFUL_DELIVERY_ACCESS_TOKEN,
        G_PAGES_CONTENTFUL_SPACE_ENVIRONMENT,
        FRONTSHOP_GOOGLE_PLACES_KEY,
        ADYEN_LIBRARY_URL,
        ADYEN_PUBLIC_KEY,
        ADYEN_CLIENT_KEY,
        ADYEN_ENVIRONMENT,
        APP_BASE_URL,
        API_LEGACY_BASE_URL,
        API_BASE_URL,
        API_V2_BASE_URL,
        GRAPHQL_API_V1_URL,
        GRAPHQL_API_V2_URL,
        FLAGR_SERVICE_BASE_URL,
        STATIC_ASSET_BASE_URL,
        CLOUDINARY_SPACE_NAME,
        INTERCOM_APP_ID,
        GTM_CONTAINER_SRC,
        FACEBOOK_ID,
        IXOPAY_PAYMENT_LIBRARY_URL,
        IXOPAY_PAYMENT_LIBRARY_VERSION,
        SNOWPLOW_STATIC_JS_URL,
        SNOWPLOW_COLLECTOR_URL,
        SNOWPLOW_TRACKER_NAME,
        NETHONE_CLIENT_URL,
        BRAZE_API_KEY,
        BRAZE_SDK_ENDPOINT,
        BRAZE_SDK_STATIC,
        BRAZE_WEB_PUSH_ID,
        INTERNAL_TOOLS_ENABLED,
        SPEEDCURVE_URL,
        DATADOG_CLIENT_TOKEN,
        DATADOG_RUM_CLIENT_TOKEN,
        DATADOG_RUM_APP_ID,
        // remove DATADOG_ENV after https://github.com/devsbb/devops/pull/599
        DATADOG_ENV,
        DD_ENV,
        DD_VERSION,
        GROVER_WORK_BASE_URL,
        IS_MOBILE_BUILD,
        SEGMENT_WRITE_KEY,
        KONTOMATIK_CLIENT_ID,
        ELEVIO_ACCOUNT_ID,
    } = configurationConstants;

    return {
        G_PAGES_CONTENTFUL_API_HOST,
        CONTENTFUL_SPACE_ID,
        CONTENTFUL_DELIVERY_ACCESS_TOKEN,
        G_PAGES_CONTENTFUL_SPACE_ID,
        G_PAGES_CONTENTFUL_DELIVERY_ACCESS_TOKEN,
        G_PAGES_CONTENTFUL_SPACE_ENVIRONMENT,
        FRONTSHOP_GOOGLE_PLACES_KEY,
        ADYEN_LIBRARY_URL,
        ADYEN_PUBLIC_KEY,
        ADYEN_CLIENT_KEY,
        ADYEN_ENVIRONMENT,
        FACEBOOK_ID,
        IXOPAY_PAYMENT_LIBRARY_URL,
        IXOPAY_PAYMENT_LIBRARY_VERSION,
        SNOWPLOW_STATIC_JS_URL,
        SNOWPLOW_COLLECTOR_URL,
        SNOWPLOW_TRACKER_NAME,
        NETHONE_CLIENT_URL,
        APP_CONFIG: {
            shopHost: APP_BASE_URL,
            apiUrl: API_BASE_URL,
            apiLegacyUrl: API_LEGACY_BASE_URL,
            apiV2Url: API_V2_BASE_URL,
            graphQLv1: GRAPHQL_API_V1_URL,
            graphQLv2: GRAPHQL_API_V2_URL,
            flagr: FLAGR_SERVICE_BASE_URL,
            dynamicStaticBaseUrl: STATIC_ASSET_BASE_URL,
            cloudinarySpace: CLOUDINARY_SPACE_NAME,
            // Leaving the app id and the module so dashboard can work
            // We will create a cleanup task after dixa migration completed
            intercomAppId: INTERCOM_APP_ID,
            gtmSrc: GTM_CONTAINER_SRC,
        },
        BRAZE_API_KEY,
        BRAZE_SDK_ENDPOINT,
        BRAZE_SDK_STATIC,
        BRAZE_WEB_PUSH_ID,
        INTERNAL_TOOLS_ENABLED,
        SPEEDCURVE_URL,
        DATADOG_CLIENT_TOKEN,
        DATADOG_RUM_CLIENT_TOKEN,
        DATADOG_RUM_APP_ID,
        // remove DATADOG_ENV after https://github.com/devsbb/devops/pull/599
        DATADOG_ENV,
        DD_ENV,
        DD_VERSION,
        GROVER_WORK_BASE_URL,
        IS_MOBILE_BUILD,
        SEGMENT_WRITE_KEY,
        KONTOMATIK_CLIENT_ID,
        ELEVIO_ACCOUNT_ID,
    };
};

export default function loadConfiguration() {
    if (__IS_SERVER__) {
        return configurationShape(global.configurationConstants);
    }
    if (typeof window === 'object') {
        return configurationShape(window.configurationConstants);
    }
    throw new Error('Could not load configuration: "configurationConstants" is not defined');
}

import React, { useCallback, useContext, useState } from 'react';

import { Translator } from 'ggApp/modules/translator';
import CloseIcon from '../../icons/close';
import BusinessProductsIcon from './Assets/BusinessProductsIcon.svg';

import {
    StyledModal,
    Header,
    Description,
    Top,
    CloseButton,
    CloseButtonWrapper,
    Bottom,
    LogoutButton,
    StayButton,
    IconContainer,
    TextContainer,
} from './styles';

const B2CRedirection = ({ modalRef, transitionToB2C }) => {
    const [modal, setModal] = useState();

    const createModalRef = useCallback(
        (newModal) => {
            setModal(newModal);
            modalRef(newModal);
        },
        [modalRef],
    );

    const closeModal = useCallback(
        (payload) => {
            if (modal) {
                modal.closeModal(payload);
            }
        },
        [modal],
    );

    const handleLogout = async () => {
        transitionToB2C();
        if (modal) modal.closeModal();
    };

    return (
        <StyledModal modalRef={createModalRef}>
            <CloseButtonWrapper>
                <CloseButton
                    onClick={closeModal}
                    icon={<CloseIcon />}
                    outlined
                    small
                    data-testid="b2c-redirection-modal-close"
                />
            </CloseButtonWrapper>
            <Top>
                <IconContainer>
                    <img src={BusinessProductsIcon} alt="Business Items" />
                </IconContainer>
                <TextContainer>
                    <Header>
                        <Translator tk="B2B_REDIRECT_MODAL_TOP" />
                    </Header>
                    <Description>
                        <Translator tk="B2B_REDIRECT_MODAL_BODY" />
                    </Description>
                </TextContainer>
            </Top>
            <Bottom>
                <StayButton onClick={closeModal}>
                    <Translator tk="B2B_REDIRECT_MODAL_STAY" />
                </StayButton>
                <LogoutButton onClick={handleLogout}>
                    <Translator tk="PROFILE_LINKS_LOG_OUT" />
                </LogoutButton>
            </Bottom>
        </StyledModal>
    );
};

export default B2CRedirection;

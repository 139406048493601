import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { initDone as initDoneSelector } from 'ggApp/modules/init/selectors';
import { compose, branch, renderNothing } from 'recompose';
import { initAnalytics, enrichAnalyticsUser } from '@devsbb/analytics-client';
import CookiesManager from 'ggApp/utils/CookiesManager';
import { haveFeatureFlagsChanged, hasUserIdChanged, getMappedFeatureFlags } from 'analytics/utils';

import { flagsDataForEventTracking as flagsDataForEventTrackingSelector } from 'ggApp/modules/featureFlag';

import { canUseDOM } from 'ggApp/utils/dom';
import { flagObjects } from 'ggApp/modules/featureFlag/constants';
import { loadSnowplow } from '../../utils';
import { initializeSnowplow, initializePageViewTracking } from '../../api';

class SnowplowLoader extends React.Component {
    constructor(props) {
        super(props);

        if (!canUseDOM()) {
            return;
        }

        initializeSnowplow();
        loadSnowplow().catch(() => console.warn('Snowplow was not loaded.'));

        const cookiesManager = new CookiesManager();
        const userId = cookiesManager.get('user_id');

        const { featureFlags } = this.props;

        const mappedFeatureFlags = getMappedFeatureFlags(featureFlags);
        enrichAnalyticsUser({
            catalogUiStoreMigration: flagObjects.catalogUiStoreMigration,
            ...(haveFeatureFlagsChanged(mappedFeatureFlags)
                ? { featureFlags: mappedFeatureFlags }
                : {}),
            ...(userId && hasUserIdChanged(userId) ? { id: userId } : {}),
        });
    }

    componentDidMount() {
        const { featureFlags } = this.props;
        const cookiesManager = new CookiesManager();
        const userId = cookiesManager.get('user_id');

        const mappedFeatureFlags = getMappedFeatureFlags(featureFlags);

        if (haveFeatureFlagsChanged(mappedFeatureFlags)) {
            enrichAnalyticsUser({
                featureFlags: mappedFeatureFlags,
                catalogUiStoreMigration: 'frontshop',
                ...(userId ? { id: userId } : {}),
            });
        }
        initializePageViewTracking({ featureFlags });
    }

    render() {
        return null;
    }
}

const enhanced = compose(
    connect(
        createStructuredSelector({
            featureFlags: flagsDataForEventTrackingSelector,
            initDone: initDoneSelector,
        }),
    ),
    // initDone – catalog entry
    branch(({ initDone }) => !initDone, renderNothing),
)(SnowplowLoader);

export { enhanced as SnowplowLoader };

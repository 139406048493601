import { renameKeys, filterProps } from 'ggApp/utils/object';

/**
 * This function gets errors from GroverAPI response and maps it to required fields.
 * Because GroverAPI returns error messages as arrays of strings this function join them into one string.
 * It also removes all non-string error fields.
 * @param {object} apiErrors
 * @param {object} keysMapper
 * @returns {object} mappedErrors
 */
function mapAPIErrors(apiErrors, keysMapper) {
    if (typeof apiErrors !== 'object' || apiErrors === null) {
        return null;
    }

    const renamedAPIErrors = renameKeys(apiErrors, keysMapper);
    const onlyStringOrArrayErrors = filterProps(
        renamedAPIErrors,
        (key, value) => typeof value === 'string' || Array.isArray(value),
    );

    const mappedErrors = {};

    Object.keys(onlyStringOrArrayErrors).forEach((errorField) => {
        const errors = onlyStringOrArrayErrors[errorField];

        if (Array.isArray(errors)) {
            mappedErrors[errorField] = errors
                .filter((error) => typeof error === 'string')
                .join(', ');
        } else {
            mappedErrors[errorField] = errors;
        }
    });

    return mappedErrors;
}

export default mapAPIErrors;

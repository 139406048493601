import styled, { css } from 'styled-components';

export const LokaliseText = styled.span`
    ${({ highlight }) => highlight && css`
        border: 2px solid #00ff00;
        &:hover {
            &::before {
                content: ${({ tk }) => `"${tk}"`};
                position: absolute;
                z-index: 100;
                background-color: ${({ theme }) => theme.colors.Background};
                color: ${({ theme }) => theme.colors.Foreground} !important;
            }
        }
    `}
`;

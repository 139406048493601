/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import {
    Wrap, Header, Image, Text,
} from './styled';

const Card = ({
    className,
    title,
    description,
    imageUrl,
    carouselStyle,
    cardWidth,
}) => (
        <Wrap className={className} carousel={carouselStyle} cardWidth={cardWidth}>
            <Image
                src={imageUrl}
                sizes={{
                    mobile: '50vw',
                    default: '25vw',
                }}
            />
            {title && <Header>{title}</Header>}
            {description && <Text>{description}</Text>}
        </Wrap>
    );

Card.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    carouselStyle: PropTypes.bool,
};

Card.defaultProps = {
    className: '',
    title: '',
    description: '',
    imageUrl: '',
    carouselStyle: false,
};

export default Card;

import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import CookiesManager from 'ggApp/utils/CookiesManager';
import CREATE_REFERRAL_CONNECTION from 'ggApp/shared/screens/NewReferrals/queries/createReferralConnection.graphql';

interface Props {
    isLoggedIn: boolean;
}

export const ReferralConnectionCreate = ({ isLoggedIn }: Props) => {
    const [initIsLoggedIn, setInitIsLoggedIn] = useState(isLoggedIn);
    const [createReferralConnection] = useMutation(CREATE_REFERRAL_CONNECTION);

    useEffect(() => {
        if (window?.location && !initIsLoggedIn && isLoggedIn) {
            setInitIsLoggedIn(isLoggedIn);

            const cookiesManager = new CookiesManager();
            const referralCode = cookiesManager.get('referral_code');
            const pathName = window.location.pathname;

            if (!referralCode || pathName.includes('referred')) return;

            createReferralConnection({ variables: { code: referralCode } })
                .catch(console.log)
                .finally(() => {
                    cookiesManager.remove('referral_code', { path: '/' });
                });
        }
    }, [isLoggedIn, initIsLoggedIn, createReferralConnection]);

    return null;
};

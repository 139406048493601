import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withProps, branch, renderNothing } from 'recompose';

import { isLoggedIn as isLoggedInSelector } from 'ggApp/modules/auth/selectors';
import {
    registerTeaserClickEvent,
    registerTeaserImpressionEvent,
} from 'ggApp/modules/gaAnalytics/actions';
import { WIDGET_NAMES } from 'ggApp/modules/gaAnalytics/constants';
import withIntersectionObserver from 'ggApp/modules/tracking/hocs/withIntersectionObserverV2';
import withDeviceInfo from 'ggApp/shared/components/viewport/withDeviceInfo';

import { CampaignsWidget } from './CampaignsWidget';

const enhance = compose(
    withDeviceInfo,
    connect(
        createStructuredSelector({
            isLoggedIn: isLoggedInSelector,
        }),
    ),
    withProps(({ userDevice }) => ({
        isMobileUser: userDevice === 'mobile',
    })),
    withProps(({ isMobileUser, campaignBannerContent: { image, mobileImage } }) => ({
        bannerImageUrl:
            (isMobileUser && mobileImage && mobileImage.url) || (image && image.url) || null,
    })),
    withProps(({ isLoggedIn }) => ({
        clickClassName: isLoggedIn ? 'gtm-promo-widget-logged-in' : 'gtm-promo-widget-logged-out',
    })),
    branch(({ campaignBannerContent }) => !campaignBannerContent, renderNothing),
);

const Widget = enhance(CampaignsWidget);

export { Widget as CampaignsWidget };
export const CampaignsWidgetWithEvents = withProps(({ campaignBannerContent: { title, cta } }) => ({
    // onEnterCallback: registerTeaserImpressionEvent({
    //     widgetName: WIDGET_NAMES.marketing1,
    //     title,
    //     url: cta?.url ?? null,
    // }),
    onClickCTA: registerTeaserClickEvent({
        widgetName: WIDGET_NAMES.marketing1,
        title,
        url: cta?.url ?? null,
    }),
}))(withIntersectionObserver(Widget));

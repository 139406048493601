/* eslint-disable react/jsx-filename-extension */
import { Translator } from 'ggApp/modules/translator/components';
import { LayoutComponent_Button as LayoutComponentButton } from 'ggApp/modules/layout';
import { StoreComponent_StoreShowPrice as ShowPrice } from 'ggApp/modules/store';
import React, { PureComponent } from 'react';
import cn from 'classnames';
import { CUSTOM_VOUCHER_ERRORS as voucherErrorMapper } from 'ggApp/modules/form/constants';

import styles from './index.module.scss';

export const getKeyForVoucherError = (error) => {
    return voucherErrorMapper[error] || 'VOUCHER_ERROR_MESSAGE_GENERIC';
};

export default class FormsVoucherFields extends PureComponent {
    render() {
        const { disabled, label, loading, reqMark, currencySymbol, globalError } = this.props;
        const {
            voucher: { name, discount },
        } = this.props;
        const errorCondition = (name.meta.touched && !!name.meta.error) || !!globalError;
        const validCondition = name.meta.valid;

        return (
            <div
                className={`${styles.formsVoucherFields} ${
                    disabled ? styles['formsVoucherFields--disabled'] : ''
                }`}
            >
                <div className={styles.formsVoucherFields__wrap}>
                    <div className={styles.formsVoucherFields__boxWrap}>
                        <div
                            className={cn(styles.formsVoucherFields__box, {
                                [styles['formsVoucherFields__box--input']]: true,
                                [styles['formsVoucherFields__box--active']]:
                                    name.meta.active || name.input.value.length !== 0,
                                [styles['formsVoucherFields__box--error']]: errorCondition,
                                [styles['formsVoucherFields__box--valid']]: validCondition,
                            })}
                        >
                            {label && (
                                <div className={styles.formsVoucherFields__label}>
                                    <div>
                                        <Translator tk={label} /> {reqMark ? '*' : ''}
                                    </div>
                                </div>
                            )}
                            <input {...name.input} type="text" />
                        </div>
                    </div>
                    <LayoutComponentButton
                        className={styles.formsVoucherFields__button}
                        disabled={!disabled && !validCondition}
                        loading={loading}
                        inputSize
                        autoWidth
                        noBorderMobile
                        noArrow
                        tk={disabled ? 'RESET' : 'REDEEM'}
                    />
                </div>
                {errorCondition && (
                    <div className={styles.formsVoucherFields__error}>
                        <Translator tk={name.meta.error || getKeyForVoucherError(globalError)} />
                    </div>
                )}
                {!!discount.input.value && (
                    <div className={styles.formsVoucherFields__info}>
                        <Translator tk="DISCOUNT" />:{' '}
                        <ShowPrice
                            price={discount.input.value}
                            orderCurrencySymbol={currencySymbol}
                        />
                    </div>
                )}
            </div>
        );
    }
}

import { createSelector } from 'reselect';
import objectPath from 'object-path';

import { storeActive as activeStoreSelector } from 'ggApp/modules/store';

import { MODULE_NAME } from '../constants';
import { replaceTranslatedStringValues } from '../utils';

export const moduleState = (state) => state[MODULE_NAME];

export const keys = createSelector(moduleState, (m) => m.keys || {});

export function translate(translations, language, key, withValues) {
    const translation = objectPath.get(translations?.[language], key);

    if (!translation) {
        return key;
    }

    if (withValues) {
        return replaceTranslatedStringValues(translation, withValues);
    }

    return translation;
}

export const translationFunctionSelector = createSelector(
    activeStoreSelector,
    keys,
    (activeStore, translationKeys) => {
        const activeStoreLanguage = objectPath.get(activeStore, 'activeLanguage', '');
        return (key, withValues) =>
            translate(translationKeys, activeStoreLanguage, key, withValues);
    },
);

import {
    user as userSelector,
    companyTypeIsIndividual as companyTypeIsIndividualSelector,
} from 'ggApp/modules/auth/selectors';
import { storeParam as storeParamSelector } from 'ggApp/modules/router/selectors';

import CookiesManager from 'ggApp/utils/CookiesManager';
import { DEVICE_ID_COOKIE_NAME } from 'ggApp/utils/DeviceIdCookie';
import { storeType as storeTypeSelector } from 'ggApp/modules/store/selectors';
import { batchEvaluateFlags, evaluateFlag, entityIdKey } from './service';
import {
    GET_FEATURE_FLAGS,
    GET_FEATURE_FLAGS_ERROR,
    GET_FEATURE_FLAGS_SUCCESS,
    SPA_FEATURE_FLAG_KEY,
    SMART_BANNER_FEATURE_FLAG_KEY,
    PDP_MARKET_PRICE_FLAG_KEY,
    AUTH_WITH_ID_VERIFICATION_KEY,
    GET_ALL_FEATURE_FLAGS_ERROR,
    GET_ALL_FEATURE_FLAGS,
    GET_ALL_FEATURE_FLAGS_SUCCESS,
    NEW_PAYMENT_METHOD_FLOW_FEATURE_FLAG,
    DISCOUNT_BANNER,
    NEW_CHECKOUT,
    BANNER_FOR_ALL_USERS,
    APP_RATING_FEATURE_FLAG,
    YOUR_PAYMENTS_FEATURE_FLAG_KEY,
    SUBSCRIPTION_PAYMENTS_FEATURE_FLAG_KEY,
    NEW_CHECKOUT_ORDER_CUSTOMISATION_RENTAL,
    NEW_CHECKOUT_ORDER_CUSTOMISATION_COLOR,
    PDP_BUNDLES_COMPONENT,
    PLP_BUNDLES_COMPONENT,
    REASON_POLL_STEP_FEATURE_FLAG_KEY,
    GROVER_CASH_WEB_FLAG_KEY,
    NEW_REFERRALS_HOST_FLAG_KEY,
    NEW_REFERRALS_GUEST_CTA_COPY,
    NEW_REFERRALS_HOST_GAMIFICATION,
    NEW_REFERRALS_COPY_BUTTON,
    MIX_CATALOG_FEATURE_FLAG_KEY,
    YOUR_TECH_MIX_FEATURE_FLAG_KEY,
    MIX_GLOBAL_FEATURE_FLAG_KEY,
    MIX_PRICE_INTERNAL_USERS_FLAG_KEY,
    BUSINESS_STORE_DEALS_FLAG_KEY,
    PREORDER_PRODUCTS_FEATURE_FLAG_KEY,
    ADDRESS_2FA_FLAG_KEY,
    REQUEST_OFFER_B2B_FLAG_KEY,
    SEO_CONTENT_FOR_CATALOGUE_PAGES_KEY,
    CATALOG_PAGINATION,
    SWAP_CHECKOUT_ADDRESS_FEATURE_KEY,
    MIX_SWAPPING_FEATURE_FLAG_KEY,
    THREEDS_WARNING_MESSAGE_FLAG_KEY,
    PRODUCT_ANNOUNCEMENTS_FLAG_KEY,
    RENTAL_PLAN_TESTS_FLAG_KEY,
    APP_LOGGED_OUT_BROWSING,
    PURCHASE_OPTION_AB_TEST_FLAG_KEY,
    DISPLAY_PURCHASE_OPTION_PDP_FLAG_KEY,
    PURCHASE_OPTION_HIDDEN_FLAG_KEY,
    PURCHASE_OPTION_EXTENDED_FAQ_FLAG_KEY,
    STRUCTURED_SPECS_FLAG_KEY,
    PRODUCT_FREE_TRIAL,
    NOTIFICATIONS_FLAG_KEY,
    MIX_LINK_ON_NAV_FLAG_KEY,
    CART_OVERLAY_VOUCHER_PREVIEW_FLAG_KEY,
    LINK_PRODUCTS_IN_CART_FLAG_KEY,
    BLOCK_MANAGED_ACCOUNT_CHECKOUT_FLAG_KEY,
    MGM_HACK_FLAG_KEY,
    REFER_A_FRIEND_FOOTER_LINK,
    AVAILABILITY_FILTER_PRE_SELECTION,
    YOUR_CARD_FEATURE_FLAG_KEY,
    CARD_LINK_ON_WEB_FLAG_KEY,
    CONTACT_NOW_CTA_LANDING_PAGE,
    YOUR_TECH_PAYMENT_METHOD_MODAL_AND_BUTTON,
    LONG_REVOCATION_PERIOD_TEST,
    SIGN_UP_BUSINESS_WITH_COMPANY_SIZE,
    CARD_LANDING_TEASER_TOGGLE,
    BLACK_FRIDAY_LANDING_PAGES,
    PRODUCT_DISCOUNT_PERCENTAGE_CHIP,
    PRODUCT_ENERGY_LABEL,
    CHECKOUT_VOUCHER_EXPLANATION,
    PRODUCT_CUSTOMIZATION_SERVICE_DATA,
    LOW_STOCK_CHIP,
    SITEWIDE_BANNER,
    COUNTDOWN_TIMERS,
    PDP_PURCHASE_OPTION_TEXT,
    PRODUCT_CUSTOMIZATION_SERVICE_DATA_LISTING_PAGES,
    RECOMMENDATION_ENGINE,
    HOW_IT_WORKS_PAGE_VARIANT,
    PURCHASE_FLOW_RETENTION_TEST_FLAG_DATA,
    SATISFACTION_SURVEY_COMPONENT,
    BLOCK_MANAGED_ACCOUNT_SELF_SERVE_PURCHASE_KEY,
    LOCKED_DEVICE_RETURN_STEP,
    ENHANCE_FLEX_CONTRACT_RENTAL_PERIOD,
    YOUR_TECH_PAYMENT_METHOD_PAGE_BUTTON,
    CATEGORIES_WIDGET_ON_HOMEPAGE,
    CHECKOUT_ORDER_REVIEW_CTA,
    CHECKOUT_CART_OVERLAY_CTA,
    OTP_ADYEN_DROP_IN_REPLACEMENT,
    B2B_SELF_CHECKOUT,
    B2B_CREATE_COMPANY_USE_SERVICE,
    COUNTDOWN_DEALS_OF_THE_WEEK,
    DEALS_OF_THE_WEEK_EXPERIMENT,
    HOMEPAGE_MC1_AB_TEST,
    FREELANCER_COMPANY_TYPE,
    B2B_OPEN_STORE,
    TERMS_AND_CONDITIONS,
    CARD_NATIVE_FLOW_TOGGLE,
    B2B_SIGNUP_PKG_ENABLED,
    TOGGLE_CARD_NEW_LANDING_PAGE,
    BLACK_FRIDAY_DEALS_FILTER,
    VALUE_PROPS_ENABLED,
    SKIP_ONFIDO_B2B,
    COMPANY_DASHBOARD_ENABLED,
    VERIFICATION_CAMPAIGN,
    GROVER_CASH_RECEIVE_PERCENTAGE,
    PAID_GROVER_CARE,
    SUBSCRIPTION_CANCELLATION_LINK_FLAG_KEY,
    ENCLOSED_CHECKOUT,
    PERSONAL_INFORMATION_ON_DASHBOARD,
    ACCOUNT_SECURITY_ON_DASHBOARD,
    ENCLOSED_CART,
    GROVER_CASH_ON_DASHBOARD,
    COMPANY_DASHBOARD_ON_DASHBOARD,
    NOTIFICATIONS_ON_DASHBOARD,
} from './constants';

const isServer = __IS_SERVER__;

const getFlavour = (req) => {
    const isApp = req?.header('User-Agent')?.includes('Grover') || window?.ReactNativeWebView;
    return isApp ? 'app' : 'web';
};

export function featureFlagRequestBuilder(flagKey, entityIdProp = entityIdKey.deviceId) {
    return (params, location, req) => (dispatch, getState) => {
        const cookiesManager = new CookiesManager(req);
        const ggId = isServer ? 0 : cookiesManager.get('ggId');
        const deviceId = isServer ? 0 : cookiesManager.get(DEVICE_ID_COOKIE_NAME);
        const userIdCookie = isServer ? 0 : cookiesManager.get('user_id');
        const userId = userIdCookie ? parseInt(userIdCookie, 10) : 0;
        const { user_type: userType = ' ' } = userSelector(getState()) || {};
        const storeCode = storeParamSelector(getState()) || '';
        const storeType = storeTypeSelector(getState()) || '';
        const individual = companyTypeIsIndividualSelector(getState()) || false;
        const flavour = getFlavour(req);

        let entityId;
        // TODO extract to flagr service
        switch (entityIdProp) {
            case entityIdKey.ggId:
                entityId = ggId;
                break;
            case entityIdKey.userId:
                entityId = userId;
                break;
            case entityIdKey.deviceId:
                entityId = deviceId;
                break;

            default:
                entityId = deviceId;
                break;
        }

        dispatch({
            flagKey,
            type: GET_FEATURE_FLAGS,
        });
        return evaluateFlag({
            flagKey,
            ggId,
            userId,
            entityId,
            deviceId,
            storeCode,
            flavour,
            userType,
            storeType,
            individual,
        })
            .then((response) =>
                dispatch({
                    type: GET_FEATURE_FLAGS_SUCCESS,
                    flagKey,
                    response: response.data,
                }),
            )
            .catch((error) =>
                dispatch({
                    type: GET_FEATURE_FLAGS_ERROR,
                    flagKey,
                    error: error.response,
                }),
            );
    };
}

export function batchFeatureFlagRequestBuilder(flagKeyList, entityIdProp = entityIdKey.deviceId) {
    return (params, location, req) => (dispatch, getState) => {
        const cookiesManager = new CookiesManager(req);
        const ggId = isServer ? 0 : cookiesManager.get('ggId');
        const userIdCookie = isServer ? 0 : cookiesManager.get('user_id');
        const userId = userIdCookie ? parseInt(userIdCookie, 10) : 0;
        const {
            user_type: userType = ' ',
            default_store: { country_code: countryCode = ' ' } = {},
        } = userSelector(getState()) || {};
        const storeCode = storeParamSelector(getState()) || '';
        const storeType = storeTypeSelector(getState()) || '';
        const deviceId = isServer ? 0 : cookiesManager.get(DEVICE_ID_COOKIE_NAME);
        let entityId;
        const flavour = getFlavour(req);

        switch (entityIdProp) {
            case entityIdKey.ggId:
                entityId = ggId;
                break;
            case entityIdKey.userId:
                entityId = userId;
                break;
            case entityIdKey.deviceId:
                entityId = deviceId;
                break;

            default:
                entityId = deviceId;
                break;
        }

        const individual = companyTypeIsIndividualSelector(getState()) || false;

        dispatch({
            flagKeys: flagKeyList,
            type: GET_ALL_FEATURE_FLAGS,
        });

        return batchEvaluateFlags({
            flagKeyList,
            ggId,
            userId,
            countryCode,
            entityId,
            deviceId,
            storeCode,
            flavour,
            storeType,
            userType,
            individual,
        })
            .then((response) =>
                dispatch({
                    type: GET_ALL_FEATURE_FLAGS_SUCCESS,
                    flagKeys: flagKeyList,
                    response: response.data,
                }),
            )
            .catch((error) =>
                dispatch({
                    type: GET_ALL_FEATURE_FLAGS_ERROR,
                    flagKeys: flagKeyList,
                    error: error.response,
                }),
            );
    };
}

export const getAllDeviceFeatureFlags = batchFeatureFlagRequestBuilder(
    [
        SPA_FEATURE_FLAG_KEY,
        SMART_BANNER_FEATURE_FLAG_KEY,
        PDP_MARKET_PRICE_FLAG_KEY,
        AUTH_WITH_ID_VERIFICATION_KEY,
        DISCOUNT_BANNER,
        BANNER_FOR_ALL_USERS,
        YOUR_PAYMENTS_FEATURE_FLAG_KEY,
        SUBSCRIPTION_PAYMENTS_FEATURE_FLAG_KEY,
        REASON_POLL_STEP_FEATURE_FLAG_KEY,
        MIX_CATALOG_FEATURE_FLAG_KEY,
        YOUR_TECH_MIX_FEATURE_FLAG_KEY,
        MIX_GLOBAL_FEATURE_FLAG_KEY,
        MIX_PRICE_INTERNAL_USERS_FLAG_KEY,
        BUSINESS_STORE_DEALS_FLAG_KEY,
        PREORDER_PRODUCTS_FEATURE_FLAG_KEY,
        ADDRESS_2FA_FLAG_KEY,
        REQUEST_OFFER_B2B_FLAG_KEY,
        APP_RATING_FEATURE_FLAG,
        SEO_CONTENT_FOR_CATALOGUE_PAGES_KEY,
        PDP_BUNDLES_COMPONENT,
        PLP_BUNDLES_COMPONENT,
        CATALOG_PAGINATION,
        SWAP_CHECKOUT_ADDRESS_FEATURE_KEY,
        MIX_SWAPPING_FEATURE_FLAG_KEY,
        THREEDS_WARNING_MESSAGE_FLAG_KEY,
        PRODUCT_ANNOUNCEMENTS_FLAG_KEY,
        RENTAL_PLAN_TESTS_FLAG_KEY,
        APP_LOGGED_OUT_BROWSING,
        PURCHASE_OPTION_AB_TEST_FLAG_KEY,
        DISPLAY_PURCHASE_OPTION_PDP_FLAG_KEY,
        STRUCTURED_SPECS_FLAG_KEY,
        PRODUCT_FREE_TRIAL,
        NEW_REFERRALS_HOST_FLAG_KEY,
        NEW_REFERRALS_GUEST_CTA_COPY,
        NEW_REFERRALS_HOST_GAMIFICATION,
        NEW_REFERRALS_COPY_BUTTON,
        NOTIFICATIONS_FLAG_KEY,
        MIX_LINK_ON_NAV_FLAG_KEY,
        CART_OVERLAY_VOUCHER_PREVIEW_FLAG_KEY,
        LINK_PRODUCTS_IN_CART_FLAG_KEY,
        MGM_HACK_FLAG_KEY,
        REFER_A_FRIEND_FOOTER_LINK,
        AVAILABILITY_FILTER_PRE_SELECTION,
        YOUR_CARD_FEATURE_FLAG_KEY,
        CARD_LINK_ON_WEB_FLAG_KEY,
        CONTACT_NOW_CTA_LANDING_PAGE,
        LONG_REVOCATION_PERIOD_TEST,
        SIGN_UP_BUSINESS_WITH_COMPANY_SIZE,
        CARD_LANDING_TEASER_TOGGLE,
        BLACK_FRIDAY_LANDING_PAGES,
        PRODUCT_DISCOUNT_PERCENTAGE_CHIP,
        PRODUCT_ENERGY_LABEL,
        CHECKOUT_VOUCHER_EXPLANATION,
        PRODUCT_CUSTOMIZATION_SERVICE_DATA,
        LOW_STOCK_CHIP,
        SITEWIDE_BANNER,
        COUNTDOWN_TIMERS,
        PRODUCT_CUSTOMIZATION_SERVICE_DATA_LISTING_PAGES,
        RECOMMENDATION_ENGINE,
        PDP_PURCHASE_OPTION_TEXT,
        HOW_IT_WORKS_PAGE_VARIANT,
        PURCHASE_FLOW_RETENTION_TEST_FLAG_DATA,
        SATISFACTION_SURVEY_COMPONENT,
        LOCKED_DEVICE_RETURN_STEP,
        CATEGORIES_WIDGET_ON_HOMEPAGE,
        B2B_SELF_CHECKOUT,
        B2B_CREATE_COMPANY_USE_SERVICE,
        COUNTDOWN_DEALS_OF_THE_WEEK,
        DEALS_OF_THE_WEEK_EXPERIMENT,
        HOMEPAGE_MC1_AB_TEST,
        FREELANCER_COMPANY_TYPE,
        B2B_OPEN_STORE,
        B2B_SIGNUP_PKG_ENABLED,
        TOGGLE_CARD_NEW_LANDING_PAGE,
        BLACK_FRIDAY_DEALS_FILTER,
        VALUE_PROPS_ENABLED,
        SKIP_ONFIDO_B2B,
        COMPANY_DASHBOARD_ENABLED,
        VERIFICATION_CAMPAIGN,
        GROVER_CASH_RECEIVE_PERCENTAGE,
        PAID_GROVER_CARE,
    ],
    entityIdKey.deviceId,
);

export const getAllUserFeatureFlags = batchFeatureFlagRequestBuilder(
    [
        NEW_PAYMENT_METHOD_FLOW_FEATURE_FLAG,
        NEW_CHECKOUT,
        GROVER_CASH_WEB_FLAG_KEY,
        MIX_CATALOG_FEATURE_FLAG_KEY,
        NEW_CHECKOUT_ORDER_CUSTOMISATION_RENTAL,
        NEW_CHECKOUT_ORDER_CUSTOMISATION_COLOR,
        BLOCK_MANAGED_ACCOUNT_CHECKOUT_FLAG_KEY,
        BLOCK_MANAGED_ACCOUNT_SELF_SERVE_PURCHASE_KEY,
        YOUR_TECH_PAYMENT_METHOD_MODAL_AND_BUTTON,
        ENHANCE_FLEX_CONTRACT_RENTAL_PERIOD,
        YOUR_TECH_PAYMENT_METHOD_PAGE_BUTTON,
        CHECKOUT_ORDER_REVIEW_CTA,
        CHECKOUT_CART_OVERLAY_CTA,
        TERMS_AND_CONDITIONS,
        CARD_NATIVE_FLOW_TOGGLE,
        PURCHASE_OPTION_HIDDEN_FLAG_KEY,
        PURCHASE_OPTION_EXTENDED_FAQ_FLAG_KEY,
        SUBSCRIPTION_CANCELLATION_LINK_FLAG_KEY,
        PERSONAL_INFORMATION_ON_DASHBOARD,
        ACCOUNT_SECURITY_ON_DASHBOARD,
        ENCLOSED_CHECKOUT,
        ENCLOSED_CART,
        GROVER_CASH_ON_DASHBOARD,
        COMPANY_DASHBOARD_ON_DASHBOARD,
        NOTIFICATIONS_ON_DASHBOARD,
    ],
    entityIdKey.userId,
);

// Temporary hack (LS-202) to enable redirect to new referral page for entitled userId
export const getNewReferralsFeatureFlag = featureFlagRequestBuilder(
    NEW_REFERRALS_HOST_FLAG_KEY,
    entityIdKey.userId,
);

export const getPaymentFlowFeatureFlag = featureFlagRequestBuilder(
    NEW_PAYMENT_METHOD_FLOW_FEATURE_FLAG,
    entityIdKey.userId,
);

export const getNewCheckoutFeatureFlag = featureFlagRequestBuilder(
    NEW_CHECKOUT,
    entityIdKey.userId,
);

export const otpAdyenDropInReplacementFlag = featureFlagRequestBuilder(
    OTP_ADYEN_DROP_IN_REPLACEMENT,
);

import { isAppUser } from 'ggApp/routes/_wrap/MobileWrapper/utils';

export function attachInWebViewAPI(globalObject) {
    if ((globalObject.InWebViewAPI || {}).attached) {
        throw new Error('InWebViewAPI was already attached, can not be attached twice.');
    }

    const queuedCommands = globalObject.InWebViewAPI || [];

    const events = {};

    function handleListen(eventName, eventHandler) {
        if (events[eventName]) {
            events[eventName].push(eventHandler);
        } else {
            events[eventName] = [eventHandler];
        }
    }

    function handleRemove(eventName, eventHandler) {
        if (!events[eventName]) return;

        const index = events[eventName].indexOf(eventHandler);
        if (index !== -1) {
            events[eventName].splice(index, 1);
        }
    }

    function handleFire(eventName, ...args) {
        const globalRNWebViewObj = globalObject.ReactNativeWebView;
        if (globalRNWebViewObj && typeof globalRNWebViewObj.postMessage === 'function') {
            globalRNWebViewObj.postMessage(
                JSON.stringify({
                    eventName,
                    args,
                }),
            );
        }

        if (!events[eventName]) return;

        const evs = events[eventName];
        const l = evs.length;
        for (let i = 0; i < l; i += 1) {
            evs[i].apply(null, args);
        }
    }

    // For unit tests purpose
    function getEventHandlersCount(eventName) {
        return events?.[eventName]?.length ?? 0;
    }

    // eslint-disable-next-line no-param-reassign
    globalObject.InWebViewAPI = {
        attached: true,
        getEventHandlersCount,
        push: (parameters) => {
            const [methodName, ...args] = parameters;
            if (methodName === 'listen') {
                handleListen(...args);
            } else if (methodName === 'remove') {
                handleRemove(...args);
            } else if (methodName === 'fire') {
                handleFire(...args);
            }
        },
    };

    while (queuedCommands.length > 0) {
        globalObject.InWebViewAPI.push(queuedCommands.pop());
    }
}

function windowInWebViewAPIPush(args) {
    if (!isAppUser()) return; // this only works in browsers
    (window.InWebViewAPI = window.InWebViewAPI || []).push(args);
}

export function addEventListener(eventName, eventHandler) {
    return windowInWebViewAPIPush(['listen', eventName, eventHandler]);
}

export function removeEventListener(eventName, eventHandler) {
    return windowInWebViewAPIPush(['remove', eventName, eventHandler]);
}

export function fireEvent(eventName, args) {
    return windowInWebViewAPIPush(['fire', eventName, args]);
}

export function addEventListenerWithPromise(event, { timeout }) {
    return new Promise((resolve, reject) => {
        let timeoutId = 0;

        function eventHandler(result) {
            window.clearTimeout(timeoutId);
            resolve({
                result,
                eventHandler,
            });
        }

        function timeoutHandler() {
            removeEventListener(event, eventHandler);
            reject(new Error(`Waiting period of ${timeout} milliseconds has timed out`));
        }

        addEventListener(event, eventHandler);
        timeoutId = window.setTimeout(timeoutHandler, timeout);
    }).then(({ result, eventHandler }) => {
        removeEventListener(event, eventHandler);

        return result;
    });
}

if (window) {
    attachInWebViewAPI(window);
}

/* eslint-disable */
import { createSelector } from 'reselect';
import { INTERCOM_MODULE_NAME } from '../constants';

export const moduleState = state => state[INTERCOM_MODULE_NAME] || {};

export const intercomHMAC = createSelector(
    moduleState,
    moduleState => moduleState.intercomHMAC.data,
);

export const unreadMessages = createSelector(
    moduleState,
    moduleState => moduleState.unreadMessages,
);

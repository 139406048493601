import { MODULE_NAME } from '../constants';

export const GET_PRODUCT_BY_ID = `${MODULE_NAME}/GET_PRODUCT_BY_ID`;
export const GET_PRODUCT_BY_ID_SUCCESS = `${MODULE_NAME}/GET_PRODUCT_BY_ID_SUCCESS`;
export const GET_PRODUCT_BY_ID_ERROR = `${MODULE_NAME}/GET_PRODUCT_BY_ID_ERROR`;

export const SELECT_PRODUCT_PROPERTY = `${MODULE_NAME}/SELECT_PRODUCT_PROPERTY`;

export const GET_PRODUCTS = `${MODULE_NAME}/GET_PRODUCTS`;
export const GET_PRODUCTS_SUCCESS = `${MODULE_NAME}/GET_PRODUCTS_SUCCESS`;
export const GET_PRODUCTS_ERROR = `${MODULE_NAME}/GET_PRODUCTS_ERROR`;

export const ADD_TO_WAITING_LIST = `${MODULE_NAME}/ADD_TO_WAITING_LIST`;
export const ADD_TO_WAITING_LIST_SUCCESS = `${MODULE_NAME}/ADD_TO_WAITING_LIST_SUCCESS`;
export const ADD_TO_WAITING_LIST_ERROR = `${MODULE_NAME}/ADD_TO_WAITING_LIST_ERROR`;

export const GET_PRODUCT_OFFER = `${MODULE_NAME}/GET_PRODUCT_OFFER`;
export const GET_PRODUCT_OFFER_SUCCESS = `${MODULE_NAME}/GET_PRODUCT_OFFER_SUCCESS`;
export const GET_PRODUCT_OFFER_ERROR = `${MODULE_NAME}/GET_PRODUCT_OFFER_ERROR`;

export const GET_RELATED_PRODUCTS = `${MODULE_NAME}/GET_RELATED_PRODUCTS`;
export const GET_RELATED_PRODUCTS_SUCCESS = `${MODULE_NAME}/GET_RELATED_PRODUCTS_SUCCESS`;
export const GET_RELATED_PRODUCTS_ERROR = `${MODULE_NAME}/GET_RELATED_PRODUCTS_ERROR`;

export const GET_LANDING_PAGE_PRODUCTS = `${MODULE_NAME}/GET_LANDING_PAGE_PRODUCTS`;
export const GET_LANDING_PAGE_PRODUCTS_SUCCESS = `${MODULE_NAME}/GET_LANDING_PAGE_PRODUCTS_SUCCESS`;
export const GET_LANDING_PAGE_PRODUCTS_ERROR = `${MODULE_NAME}/GET_LANDING_PAGE_PRODUCTS_ERROR`;

export const GET_RECENTLY_VIEW_PRODUCTS_SUCCESS = `${MODULE_NAME}/GET_RECENTLY_VIEW_PRODUCTS_SUCCESS`;

export const GET_PRODUCT_PROMOTIONS = `${MODULE_NAME}/GET_PRODUCT_PROMOTIONS`;
export const GET_PRODUCT_PROMOTIONS_SUCCESS = `${MODULE_NAME}/GET_PRODUCT_PROMOTIONS_SUCCESS`;
export const GET_PRODUCT_PROMOTIONS_ERROR = `${MODULE_NAME}/GET_PRODUCT_PROMOTIONS_ERROR`;

export const GET_PRODUCTS_GRAPHQL_SUCCESS = `${MODULE_NAME}/GET_PRODUCTS_GRAPHQL_SUCCESS`;

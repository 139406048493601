import { storeTks, languageTks } from 'ggApp/modules/store/constants';
import { getLanguagesFromStaticStore } from 'staticStoreConfig';
import { translate } from 'ggApp/modules/translator/selectors';

const reorderSelectedFirst = (selected, list) => {
    const selectedIndex = list.findIndex((item) => selected === item.value);
    if (selectedIndex === 0) {
        return list;
    }
    const selectedItem = list.splice(selectedIndex, 1)[0];
    list.unshift(selectedItem);
    return list;
};

// gets store options in the correct language and with the selected store first
export const getStoreList = ({ selectedStoreValue, translationKeys, activeLanguage }) => {
    const stores = [];
    const filteredStoreTks = storeTks;

    const currentStores = Object.keys(filteredStoreTks);

    currentStores.forEach((key) => {
        stores.push({
            label: translate(translationKeys, activeLanguage, storeTks[key]),
            value: key,
        });
    });
    return reorderSelectedFirst(selectedStoreValue, stores);
};

// gets language options in the correct language and with the selected language first
export const getLanguageList = ({
    selectedStoreValue,
    selectedLanguageValue,
    translationKeys,
    activeLanguage,
}) => {
    const languages = [];
    const availableLanguages = getLanguagesFromStaticStore(selectedStoreValue);
    availableLanguages.forEach((key) => {
        languages.push({
            label: translate(translationKeys, activeLanguage, languageTks[key]),
            value: key,
        });
    });
    return reorderSelectedFirst(selectedLanguageValue, languages);
};

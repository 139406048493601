/* eslint-disable */
import PropTypes from 'prop-types'
import React, {PureComponent,} from 'react';

class FavoritesIcon extends PureComponent {
    static propTypes = {
        background: PropTypes.string,
        color: PropTypes.string,
    };
    static defaultProps = {
        background: 'transparent',
        color: "#333333",
    };

    render() {
        const {background, color, className, style,} = this.props;
        return (
            <svg className={className} style={{maxWidth: '30px', maxHeight: '30px', ...style,}} fill={background}>
                <path
                    d="M9.24282 3.49861L10.0003 4.37747L10.7578 3.49861C11.5964 2.52562 12.6571 1.48187 14.511 1.48187C16.7283 1.48187 18.5406 3.33234 18.5406 5.61718C18.5406 7.91108 16.9301 10.2047 14.8214 12.2571C13.7919 13.2591 12.6947 14.1554 11.7186 14.9154C11.3815 15.1779 11.0491 15.4312 10.7374 15.6688C10.4738 15.8699 10.2248 16.0596 10.0003 16.2341C9.77574 16.0596 9.52682 15.8699 9.26314 15.6688C8.95144 15.4312 8.61911 15.1779 8.28199 14.9154C7.30593 14.1554 6.20866 13.2591 5.17918 12.2571C3.07046 10.2047 1.46002 7.91108 1.46002 5.61718C1.46002 3.30202 3.30199 1.48187 5.48962 1.48187C7.34351 1.48187 8.40422 2.52562 9.24282 3.49861Z"
                    fill={background} stroke={color} strokeWidth="2"/>
            </svg>

        );
    }
}

export default FavoritesIcon;

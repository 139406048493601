type RedirectionUrl = {
    activeStoreCode: string;
    referralCode?: string;
    selectedCountryCode: string;
    selectedLanguageCode: string;
};
export const buildCountryRedirectionUrl = (params: RedirectionUrl) => {
    const { activeStoreCode, referralCode, selectedCountryCode, selectedLanguageCode } = params;
    const isBusinessStore = activeStoreCode.startsWith('business');
    let redirectUrl = '';

    const businessStorePrefix =
        (selectedLanguageCode === 'en' || selectedLanguageCode === 'de') &&
        (selectedCountryCode === 'us' || selectedCountryCode === 'de')
            ? `-${selectedLanguageCode}`
            : `_${selectedCountryCode}-${selectedLanguageCode}`;
    if (referralCode) {
        // do a "proper" redirect for referrals only, we could have issues with PDP redirect
        redirectUrl = isBusinessStore
            ? `/business${businessStorePrefix}/referred/${referralCode}`
            : `/${selectedCountryCode}-${
                  selectedCountryCode === 'us' ? 'en' : selectedLanguageCode
              }/referred/${referralCode}`;
    } else {
        redirectUrl = isBusinessStore
            ? `/business${businessStorePrefix}`
            : ` /${selectedCountryCode}-${
                  selectedCountryCode === 'us' ? 'en' : selectedLanguageCode
              }`;
    }
    return redirectUrl;
};

import { createSelector } from 'reselect';

import { mapGroverAPIFeaturedList } from 'ggApp/modules/contentful';
import getProductAnnouncementData from 'ggApp/modules/product/utils/getProductAnnouncementData';

import ggRouter from 'ggApp/modules/router';
import ggStatic from 'ggApp/modules/static';
import { storeActive } from 'ggApp/modules/store/selectors';
import { productAnnouncementsFlagData } from 'ggApp/modules/featureFlag/selectors';
import {
    getCurrentVariant,
    buildListWithBanners,
    getCurrentProduct,
    getVariantPictures,
    getProductOverview,
    getProductDescription,
    composeTopFeatures,
    composeKeySpecs,
    getSimilarProductsWithContentfulHeading,
    getSimilarProducts,
    extractFoundNothing,
    getMetaPageInfo,
    getTechSpecs,
    getProductCategoryDetails,
    composeExperiences,
} from 'ggApp/utils/product';
import { getDeliveryEstimation } from 'staticStoreConfig';
import { variantSelectorList } from '../adapters';
import { MODULE_NAME } from '../constants';

export const moduleState = (state) => state[MODULE_NAME] || {};

export const list = createSelector(moduleState, (state) => state.list);

export const cardListSelector = createSelector(list, (productList) => {
    const result = (productList.data || []).map((product) => ({
        type: 'product',
        ...product,
    }));
    return mapGroverAPIFeaturedList(result);
});

export const listWithBanners = (bannersSelector) =>
    createSelector(bannersSelector, list, (bannerList, productList) =>
        buildListWithBanners({ bannerList, productList }),
    );

export const listData = createSelector(list, ({ data }) => data);

export const relatedProductsBySlug = createSelector(
    moduleState,
    (state) => state.relatedProductsBySlug.data.products || [],
);

export const currentProduct = createSelector(
    ggRouter.selectors.params,
    moduleState,
    (params, state) => getCurrentProduct({ params, state }),
);

export const campaigns = createSelector(
    currentProduct,
    (product) => (product || {}).campaigns || [],
);

export const currentProductById = createSelector(
    ggRouter.selectors.locationQuery,
    moduleState,
    (locationQuery, state) => state.productById.data[locationQuery.product_id],
);

export function productsBySlug(state) {
    return state[MODULE_NAME].productBySlug.data || {};
}

export function productsById(state) {
    return state[MODULE_NAME].productById.data || {};
}

export const isProductLoading = createSelector(moduleState, (state) => state.productBySlug.loading);

export const isProductListLoading = createSelector(moduleState, (state) => state.list.loading);

export const selectedVariantId = createSelector(moduleState, (state) => state.selectedVariantId);

export const variants = createSelector(currentProduct, (product) => (product || {}).variants || []);

export const everyVariantIsUnavailable = createSelector(variants, (variantList) =>
    variantList.every((variant) => !(variant && variant['is_available?'])),
);

export const currentVariant = createSelector(
    variants,
    selectedVariantId,
    (availableVariants, variantId) => getCurrentVariant({ availableVariants, variantId }),
);

export const variantPictures = (pictureSizeId = 'medium') =>
    createSelector(currentVariant, (variant = {}) =>
        getVariantPictures({ pictureSizeId, variant }),
    );

export const offers = createSelector(
    moduleState,
    (state) => (state.waitingList && state.waitingList.offers) || [],
);

export const contentfulProduct = createSelector(currentProduct, (p) => p.contentful_record || {});

export const productOverview = createSelector(
    currentProduct,
    contentfulProduct,
    variants,
    ggStatic.selectors.pdpCommon,
    selectedVariantId,
    offers,
    (product, contentful, availableVariants, pdpCommon, variantId, availableOffers) =>
        getProductOverview({
            currentProduct: product,
            contentfulProduct: contentful,
            availableVariants,
            pdpCommon,
            variantId,
            availableOffers,
        }),
);

export const imageList = createSelector(
    selectedVariantId,
    variants,
    (variantId, availableVariants) => {
        const variant = getCurrentVariant({ availableVariants, variantId });
        const { name: productName, property } = variant;
        const variantTitle = (property && property.title) || null;
        const altText = [variantTitle, productName].filter(Boolean).join(' ');
        let productImages;
        try {
            productImages = variant && variant.pictures;
        } catch (e) {
            productImages = null;
        }
        return productImages.map((image, index) => ({
            ...image,
            alt: `${altText}.${index + 1}`,
        }));
    },
);

export const productDescription = createSelector(contentfulProduct, (contentful) =>
    getProductDescription({ contentfulProduct: contentful }),
);

export const topFeatures = createSelector(
    contentfulProduct,
    ggStatic.selectors.pdpCommon,
    (contentful, pdpCommon) =>
        composeTopFeatures({
            contentfulProduct: contentful,
            pdpCommon,
        }),
);

export const videoWidget = createSelector(contentfulProduct, (contentful) =>
    composeExperiences(contentful),
);

export const keySpecs = createSelector(
    contentfulProduct,
    ggStatic.selectors.pdpCommon,
    (contentful, pdpCommon) =>
        composeKeySpecs({
            contentfulProduct: contentful,
            pdpCommon,
        }),
);

export const similarProductsWithContentfulHeading = createSelector(
    currentProduct,
    ggStatic.selectors.pdpCommon,
    (product, pdpCommon) => getSimilarProductsWithContentfulHeading({ product, pdpCommon }),
);

export const similarProducts = createSelector(currentProduct, (product) =>
    getSimilarProducts({ currentProduct: product }),
);

export const foundNothing = createSelector(ggStatic.selectors.pdpCommon, (pdpCommon) =>
    extractFoundNothing({ pdpCommon }),
);

export const pagination = createSelector(moduleState, (state) => state.pagination || {});

export const metaPageInfo = createSelector(
    contentfulProduct,
    currentProduct,
    (contentful, product) => getMetaPageInfo({ contentful, product }),
);

export const currentVariantAccentColor = createSelector(
    currentVariant,
    (variant) => variant && variant.accent_color,
);

export const currentVariantTechSpecs = createSelector(currentProduct, (product) =>
    getTechSpecs({ currentProduct: product }),
);

export const productPropertiesList = createSelector(variants, (availableVariants) =>
    variantSelectorList(availableVariants),
);

export const productCoreAttributes = createSelector(
    currentProduct,
    contentfulProduct,
    (product, contentful) => product.core_attribute || contentful.core_attribute,
);

export const isDronesCategory = createSelector(
    currentProduct,
    (product) => product && product.category_name === 'Drones',
);

export const productCategoryDetails = createSelector(currentProduct, (product) =>
    getProductCategoryDetails({ currentProduct: product }),
);

export const productCategoryIds = createSelector(currentProduct, (product) => {
    const category = product?.category || null;
    const { sub_category_id: subCategoryId = null, category_id: categoryId = null } =
        category || {};
    return { subCategoryId, categoryId };
});

export const deliveryEstimates = createSelector(currentVariant, storeActive, (variant, store) => {
    const estimates = variant.delivery_estimates || getDeliveryEstimation(store.code) || null;
    return estimates;
});

export const accessoriesNames = createSelector(currentProduct, (product = {}) => {
    const { accessories } = product;
    if (Array.isArray(accessories)) {
        return accessories;
    }
    return [];
});

export const announcementDataForCurrentProduct = createSelector(
    productAnnouncementsFlagData,
    storeActive,
    currentProduct,
    (announcementsFlagData, activeStore, product) =>
        getProductAnnouncementData(product.sku, activeStore.code, announcementsFlagData),
);

export const promotions = createSelector(moduleState, (state) => state.promotions.data || null);

export const currentProductPromotions = createSelector(
    promotions,
    currentProduct,
    (productPromotions, product) =>
        productPromotions?.products.find(({ id }) => id === String(product.id)),
);

export const listGraphql = createSelector(moduleState, (state) => {
    const values = state.listProductsGraphql.data;
    const total = (values || []).reduce(
        (acc, curr) => Object.assign(acc, { [curr.sku]: curr.inStores }),
        {},
    );
    return total;
});

/* eslint-disable */
import {
  SET_HEADERS,
} from './../actionTypes';

const defaultState = {};

export default function (state = defaultState, action) {
  switch (action.type) {

    case SET_HEADERS: {
      const headers = Object.assign({}, state, action.headers)
      return JSON.parse( JSON.stringify(headers) )
    }

    default:
      return state;
  }
}
import { formatPriceByLanguage } from 'ggApp/utils/money';
import { CURRENCIES_ASSOCIATION } from 'ggApp/modules/store/constants';
import { isMixable } from 'ggApp/utils/product';

const isDealActive = (product) =>
    product?.rentalPlans?.some(
        (rentalPlan) =>
            (typeof rentalPlan?.oldPrice === 'number' && rentalPlan?.oldPrice > 0) ||
            rentalPlan?.oldPrice?.inCents > 0,
    );

export const getProductCardChips = (product) => {
    const dealActive = isDealActive(product);
    const mixable = isMixable(product);
    return [
        dealActive
            ? {
                  tk: 'CHIP_DEAL_LABEL',
                  type: null,
              }
            : null,
        ...(product.promotionalLabels
            ? product.promotionalLabels.map((label) => ({
                  label,
                  type: 'promotional',
              }))
            : []),
        product.isNew
            ? {
                  tk: 'CHIP_NEW_LABEL',
                  type: 'promotional',
              }
            : null,
        mixable
            ? {
                  label: 'MIX',
                  type: 'promotional',
              }
            : null,
    ].filter(Boolean);
};

export const productAdapter = ({ product, defaultCurrency, activeLanguage, favorite }) => {
    const {
        id,
        slug,
        variants,
        coreAttribute: productCoreAttributes,
        rentalPlans,
        originalImageUrl: originalImage,
        category = {},
        brandName,
    } = product;

    const sortedRentalPlans = [...rentalPlans].sort((a, b) => b.length.value - a.length.value);

    const rentalPlan = sortedRentalPlans[0];
    const selectedMinDuration = rentalPlan?.length?.value;

    const chips = getProductCardChips(product);

    if (!variants) return null;
    const variant = variants[0] || {};

    const price = formatPriceByLanguage(
        rentalPlan?.price?.inCents,
        CURRENCIES_ASSOCIATION[defaultCurrency],
        activeLanguage,
    );

    const oldPrice = !!rentalPlan?.oldPrice?.inCents
        ? formatPriceByLanguage(
              rentalPlan.oldPrice.inCents,
              CURRENCIES_ASSOCIATION[defaultCurrency],
              activeLanguage,
          )
        : '';
    return {
        brand: brandName,
        chips,
        favorite,
        id: parseInt(id, 10),
        oldPrice,
        originalImage,
        price,
        productCoreAttributes,
        productName: product.name,
        productVariant: variant.id,
        selectedMinDuration,
        sku: product.sku,
        originalProduct: { ...product },
        slug,
        subCategory: category?.title,
    };
};

/* eslint-disable */
// @flow

type State = {
  loading: ?boolean,
  error: Object,
  data: Array<mixed>,
}

import {
  GET_PAYMENT_LIST,
  GET_PAYMENT_LIST_SUCCESS,
  GET_PAYMENT_LIST_ERROR,
} from './../actionTypes';

const defaultState: State = {
  loading: null,
  error: {},
  data: [],
};

const AdyenPaymentMethodsReducer = function (state: State = defaultState, action: any) {
  switch (action.type) {

    case GET_PAYMENT_LIST:
      return Object.assign({}, state, {
        loading: true,
        error: {},
      })

    case GET_PAYMENT_LIST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.data,
      })

    case GET_PAYMENT_LIST_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      })

    default:
      return state;
  }
}

export default AdyenPaymentMethodsReducer
export type AdyenPaymentState = State

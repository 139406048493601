import React from 'react';
import { Route, IndexRedirect } from 'react-router';

import { onEnterVerify, onEnterBankAccountVerify } from 'ggApp/modules/router/utils';
import FLOW_NAMES from 'ggApp/modules/auth/v3/constants/flowNames';

const VerifyRoutes = (
    <Route>
        <Route path="verify" onEnter={onEnterVerify}>
            <IndexRedirect to={`/:store-:language/auth?flow=${FLOW_NAMES.IDENTITY_VERIFICATION}`} />
        </Route>

        <Route path="verify-bank-account" onEnter={onEnterBankAccountVerify}>
            <IndexRedirect
                to={`/:store-:language/auth?flow=${FLOW_NAMES.BANK_ACCOUNT_VERIFICATION}`}
            />
        </Route>
    </Route>
);

export default VerifyRoutes;

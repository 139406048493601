import React from 'react';
import { IndexRoute, Route, Redirect, IndexRedirect } from 'react-router';

import Loadable from 'react-loadable';
import CircleLoader from 'ggApp/shared/components/CircleLoader';
import {
    onEnterForYou,
    onEnterFavorites,
    onEnterHome,
    onEnterVerify,
} from 'ggApp/modules/router/utils';

import AppWrapper from './BaseLayout';

const Loading = () => <CircleLoader />;

const HowItWorks = Loadable({
    loader: () => import(/* webpackChunkName: "HowItWorks" */ './HowItWorks'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});
const B2Bv2 = Loadable({
    loader: () => import(/* webpackChunkName: "B2bv2" */ './b2bV2'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});
const B2B = Loadable({
    loader: () => import(/* webpackChunkName: "B2b" */ './b2b'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});
const Category = Loadable({
    loader: () => import(/* webpackChunkName: "Category" */ './Category'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});
const ForYou = Loadable({
    loader: () => import(/* webpackChunkName: "ForYou" */ './ForYou'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});
const Favorites = Loadable({
    loader: () => import(/* webpackChunkName: "Favorites" */ './Favorites'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});
const OrderConfirmation = Loadable({
    loader: () => import(/* webpackChunkName: "OrderConfirmation" */ './OrderConfirmation'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});
const ProductDetails = Loadable({
    loader: () => import(/* webpackChunkName: "ProductDetails" */ './ProductDetails'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});
const CostComparison = Loadable({
    loader: () => import(/* webpackChunkName: "CostComparison" */ './CostComparison'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});
const Search = Loadable({
    loader: () => import(/* webpackChunkName: "Search" */ './search'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});
const SubCategory = Loadable({
    loader: () => import(/* webpackChunkName: "SubCategory" */ './SubCategory'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});
const Terms = Loadable({
    loader: () => import(/* webpackChunkName: "Terms" */ './Terms'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});
const Trending = Loadable({
    loader: () => import(/* webpackChunkName: "Trending" */ './Trending'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});
const Deals = Loadable({
    loader: () => import(/* webpackChunkName: "Deals" */ './Deals'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});
const GPage = Loadable({
    loader: () => import(/* webpackChunkName: "GPage" */ './GPage'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});
const ReferralsFriend = Loadable({
    loader: () => import(/* webpackChunkName: "ReferralsFriend" */ './ReferralsFriend'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});
const ReferralsUser = Loadable({
    loader: () => import(/* webpackChunkName: "ReferralsUser" */ './ReferralsUser'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});

const ReferralHost = Loadable({
    loader: () => import(/* webpackChunkName: "ReferralHost" */ './ReferralHost'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});

const ReferralGuest = Loadable({
    loader: () => import(/* webpackChunkName: "ReferralGuest" */ './ReferralGuest'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});

const Home = Loadable({
    loader: () => import(/* webpackChunkName: "Home" */ './Home'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});
const BlackFridayThanks = Loadable({
    loader: () => import(/* webpackChunkName: "BlackFridayThanks" */ './BlackFridayThanks'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});

const BlackFridaySignUp = Loadable({
    loader: () => import(/* webpackChunkName: "BlackFridaySignup" */ './BlackFridaySignup'),
    loading: Loading,
    delay: 300,
    timeout: 10000,
});

const catalogRoutes = (
    <Route component={AppWrapper}>
        <IndexRoute component={Home} onEnter={onEnterHome} />
        <Route path="for-you" components={ForYou} onEnter={onEnterForYou} />
        <Route path="how-it-works" component={HowItWorks} />
        <Route path="favorites" components={Favorites} onEnter={onEnterFavorites} />
        <Route path="g-:gType/:gSlug" components={GPage} />
        <Route path="terms" components={Terms} />
        <Route path="trending" components={Trending} />
        <Route path="deals" components={Deals} />
        <Route path="search" components={Search} />
        <Route path="products">
            <Route path=":productSlug" components={ProductDetails} />
        </Route>
        <Route path="comparison">
            <Route path=":productSlug" components={CostComparison} />
        </Route>
        <Route path="confirmation/:orderNumber" component={OrderConfirmation} />
        <Route path="partners" component={B2B} />
        <Route path="invite-friend" component={ReferralsUser} />
        <Route path="join" component={ReferralsFriend} />
        <Route path="referrals" component={ReferralHost} />
        <Route path="referred/:referralCode" component={ReferralGuest} />
        <Route path="referral-code">
            <IndexRedirect to="/:store-:language/referrals" />
            <Redirect from=":referralCode" to="/:store-:language/referred/:referralCode" />
        </Route>
        <Route path="for-business" component={B2Bv2} />
        <Route path="verify" onEnter={onEnterVerify} />
        <Route path="black-friday-signup" component={BlackFridaySignUp} />
        <Route path="black-friday-thanks" component={BlackFridayThanks} />
        <Route path=":categorySlug">
            <IndexRoute component={Category} />
            <Route path=":subCategorySlug" component={SubCategory} />
        </Route>
    </Route>
);

export default catalogRoutes;

/* eslint-disable */
const defaultState = true;

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'navigator/STATUSBAR_CHECKOUT_TRIGGER':
      return !state;

    default:
      return state;
  }
}

export function canUseDOM() {
    return Boolean(
        typeof window !== 'undefined' && window.document && window.document.createElement,
    );
}

export function injectScript(src: string): Promise<HTMLScriptElement> {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
            resolve(script);
        };
        script.onerror = reject;
        document.head.appendChild(script);
    });
}

export function injectLink(href: string): Promise<HTMLLinkElement> {
    return new Promise((resolve, reject) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = href;
        link.onload = () => {
            resolve(link);
        };
        link.onerror = reject;
        document.head.appendChild(link);
    });
}

export function waitForElement(selector: string) {
    return new Promise((resolve) => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(() => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        return observer.observe(document.body, {
            childList: true,
            subtree: true,
        });
    });
}

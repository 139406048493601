import axios from 'axios';
/* eslint-disable-next-line */
import { DeviceInfo } from 'react-native';
import loadConfiguration from 'ggApp/utils/configuration';
import CookiesManager, { injectCookiesToConfig } from 'ggApp/utils/CookiesManager';
import { fireEvent } from 'ggApp/modules/inwebview_api';
import HeadersInterceptor from './headersInterceptor';

const isServer = __IS_SERVER__;
const isDashboardApp = __IS_DASHBOARD_APP__;
const { APP_CONFIG } = loadConfiguration();
const { apiUrl } = APP_CONFIG;

/**
 * Method creates an axios instance with preset baseUrl and headers.
 * Another axios config could be provided as a param.
 * If custom config was provided its headers will be merged with default ones.
 *
 * @param {AxiosRequestConfig} [config={}]
 * @returns {AxiosInstance} axiosInstance
 */
export default function createAxiosInstance(config = {}) {
    const extraHeader = window.ReactNativeWebView ? { 'X-User-Agent': DeviceInfo.userAgent } : {};

    let headers = {
        ...extraHeader,
        'Content-Type': 'application/json',
    };

    if (isServer) {
        headers = {
            ...headers,
            Origin: 'https://getgrover.com',
        };
    }

    // Needed for cart to work
    if (!isServer) {
        // Client side code: There is no need to pass parameter for CookiesManager init
        const cookiesManager = new CookiesManager();
        headers = {
            ...headers,
            'X-Grover-Session-ID': cookiesManager.get('ggId'),
            'X-Grover-User': cookiesManager.get('user_id'),
        };
    }

    if (isDashboardApp && !isServer) {
        // Client side code: There is no need to pass parameter for CookiesManager init
        const cookiesManager = new CookiesManager();
        headers = {
            ...headers,
            'X-Grover-Store': cookiesManager.get('shop_country_code'), // Integration with main ruby website
        };
    }

    const baseConfig = {
        baseURL: apiUrl,
        ...config,
        headers: JSON.parse(
            JSON.stringify({
                ...headers,
                ...(config.headers || {}),
            }),
        ),
    };

    if (!isServer) {
        injectCookiesToConfig(new CookiesManager(), baseConfig);
    }

    const axiosInstance = axios.create(baseConfig);

    axiosInstance.interceptors.request.use(HeadersInterceptor);
    axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
            fireEvent('HTTP_ERROR_TRIGGERED', { error });
            return Promise.reject(error);
        },
    );

    return axiosInstance;
}

/* eslint-disable no-shadow */
import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    b2bRedirection as b2bRedirectionSelector,
    user as userSelector,
} from 'ggApp/modules/auth/selectors';
import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import { params as paramsSelector } from 'ggApp/modules/router/selectors';

import { bindActionCreators } from 'redux';
import { redirectus } from 'ggApp/modules/router/actions';
import { BUSINESS_REDIRECT_QUERY_KEY } from 'ggApp/modules/router/constants';
import { getBusinessRedirectPath } from 'ggApp/modules/router/utils';
import BusinessRedirection from './BusinessRedirection';

const enhance = compose(
    connect(
        createStructuredSelector({
            urlParams: paramsSelector,
            user: userSelector,
            store: activeStoreSelector,
            b2bRedirection: b2bRedirectionSelector,
        }),
        (dispatch) => ({
            redirectus: bindActionCreators(redirectus, dispatch),
        }),
    ),
    mapProps(({ redirectus, urlParams, user, store, ...rest }) => {
        const lang = urlParams.language || store.activeLanguage;
        // eslint-disable-next-line camelcase
        const redirectionStoreCode = user?.default_store?.code ?? 'business';
        // eslint-disable-next-line no-nested-ternary
        const redirectionLink = urlParams.productSlug
            ? `/${redirectionStoreCode}-${lang}/products/${urlParams.productSlug}?${BUSINESS_REDIRECT_QUERY_KEY}=true`
            : getBusinessRedirectPath(redirectionStoreCode, lang);

        return {
            ...rest,
            store,
            // store url will be generated on api side when grow-380 and grow-336 are done
            transitionToBusiness: () => redirectus(redirectionLink, true),
        };
    }),
);

export default enhance(BusinessRedirection);

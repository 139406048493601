/* eslint-disable no-param-reassign */
import { getCookiesFromConfig } from 'ggApp/utils/CookiesManager';
import { DEVICE_ID_COOKIE_NAME } from 'ggApp/utils/DeviceIdCookie';

// TODO: generate default headers here instead of axiosFactory
function headersInterceptor(config) {
    try {
        const cookiesManager = getCookiesFromConfig(config);

        if (!cookiesManager) {
            return config;
        }

        const accessToken = cookiesManager.get('api_access_token');
        const groverLanguageCookie = cookiesManager.get('locale');
        const groverImpersonatedUser = cookiesManager.get('impersonated_user_id');
        const deviceIdCookie = cookiesManager.get(DEVICE_ID_COOKIE_NAME);

        if (groverLanguageCookie) {
            config.headers['X-Grover-Language'] = groverLanguageCookie;
        }

        if (deviceIdCookie) {
            config.headers['X-Grover-Device-ID'] = deviceIdCookie;
        }

        if (groverImpersonatedUser) {
            config.headers['X-Grover-Impersonated-User'] = groverImpersonatedUser;
        }

        /*
            If access token is presented in cookies
            and Authorization header was not reassigned in a query
            set Authorization header
        */
        if (accessToken && accessToken !== 'undefined' && !config.headers.Authorization) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
    } catch (error) {
        return config;
    }
}

export default headersInterceptor;

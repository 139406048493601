import isFunction from 'ggApp/utils/function/isFunction';

export const brazeChangeUser = (userId) => {
    if (!window.appboy) return;

    window.appboy.changeUser(userId);
};

// You must call initialize after calling this method before calling subsequent methods.
export const enableBrazeTracking = () => {
    if (!window.appboy) return;

    window.appboy.resumeWebTracking();
};

export const disableBrazeTracking = () => {
    if (!window.appboy) return;

    window.appboy.stopWebTracking();
};

export const brazeInitialize = (
    options = { apiKey: '', sdkUrl: '', webPushId: '', enableLogging: false },
) => {
    if (!window.appboy) return;

    window.appboy.initialize(options.apiKey, {
        baseUrl: options.sdkUrl,
        doNotLoadFontAwesome: true,
        allowUserSuppliedJavascript: true,
        manageServiceWorkerExternally: true,
        enableLogging: options.enableLogging,
        safariWebsitePushId: options.webPushId || 'web.com.getgrover.staging',
    });
};

export const registerPushMessages = (success, denied) => {
    if (!window.appboy) return;

    window.appboy.registerAppboyPushMessages(success, denied);
};

export const isPushSupported = () => {
    if (!window.appboy) return false;

    return window.appboy.isPushSupported();
};

export const isPushPermissionGranted = () => {
    if (!window.appboy) return false;

    return window.appboy.isPushSupported() && window.appboy.isPushPermissionGranted();
};

export const isPushPermissionBlocked = () => {
    if (!window.appboy) return false;

    return window.appboy.isPushSupported() && window.appboy.isPushBlocked();
};

export const unregisterPushMessages = (success, denied) => {
    if (!window.appboy) return;

    window.appboy.unregisterAppboyPushMessages(success, denied);
};

export const brazeAutomaticallyShowNewInAppMessages = () => {
    if (!window.appboy) return;

    window.appboy.display.automaticallyShowNewInAppMessages();
};

export const sendBrazeEvent = ({ eventName, data }) => {
    if (!window.appboy) return;
    window.appboy.logCustomEvent(eventName, data);
};

export const resetUserForBraze = () => {
    if (!window.appboy || !isFunction(window.appboy.wipeData)) return;

    window.appboy.wipeData();
};

export const contentCardsRefresh = () => {
    if (!window.appboy) return;
    window.appboy.requestContentCardsRefresh();
};

export const logContentCardClick = (card) => {
    if (!window.appboy) return;
    window.appboy.logCardClick(card, true);
};

export const logContentCardImpression = (card) => {
    if (!window.appboy) return;
    window.appboy.logCardImpressions([card], true);
};

export const getNotificationTypes = () => {
    return window?.appboy?.User.NotificationSubscriptionTypes || {};
};

export const setEmailNotificationSubscriptionType = (type) => {
    const user = window?.appboy?.getUser() || null;
    if (!user) return false;

    return user.setEmailNotificationSubscriptionType(type);
};

import { compose, mapProps, branch, renderNothing } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    isLoggedIn as isLoggedInSelector,
    userId as userIdSelector,
} from 'ggApp/modules/auth/selectors';
import {
    location as locationSelector,
    langParam as langParamSelector,
} from 'ggApp/modules/router/selectors';

import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import {
    withHasCookiePolicyConsent,
    withCookieConsentActions,
    withCookiePolicySettingsLoader,
} from '../../enhancers';

import { CookiePolicy } from './CookiePolicy';

const enhance = compose(
    withCookiePolicySettingsLoader,
    withCookieConsentActions,
    withHasCookiePolicyConsent,
    connect(
        createStructuredSelector({
            isLoggedIn: isLoggedInSelector,
            location: locationSelector,
            userId: userIdSelector,
            langParam: langParamSelector,
            activeStore: activeStoreSelector,
        }),
    ),
    mapProps(
        ({
            updateCookiePolicySettings,
            consentCookiePolicy,
            hasCookiePolicyConsent,
            hasMarketingConsent,
            userId,
            getAllConsents,
            isCookiePolicyInitialized,
            location: { pathname },
            isLoggedIn,
            activeStore,
            hasExplicitConsent,
        }) => ({
            updateCookiePolicySettings,
            consentCookiePolicy,
            isCookiePolicyInitialized,
            hasExplicitConsent,
            userId,
            getAllConsents,
            hasCookiePolicyConsent,
            isShown: !hasCookiePolicyConsent && isCookiePolicyInitialized,
            isOnPdp: /products/.test(pathname),
            isOnPlp: /landing/.test(pathname),
            isOnCart: /cart/.test(pathname),
            isLoggedIn,
            countryCode: activeStore.country_code,
            storeCode: activeStore.code,
            activeLanguage: activeStore.activeLanguage,
        }),
    ),
    branch(({ storeCode }) => storeCode === 'us', renderNothing),
);

export default enhance(CookiePolicy);

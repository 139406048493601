export const MODULE_NAME = 'address';

export const SHIPPING_NAME = 'SHIPPING';
export const BILLING_NAME = 'BILLING';

export const ADDRESS_TYPE = {
    SHIPPING: 'shipping',
    BILLING: 'billing',
};

export const UserAddressesType = {
    [ADDRESS_TYPE.SHIPPING]: 'ship_address',
    [ADDRESS_TYPE.BILLING]: 'bill_address',
};

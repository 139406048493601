/* eslint-disable react/no-array-index-key */
import React from 'react';

import Chip from './Chip';
import { ChipProps, ChipMapperProps, EnhancedChipMapperProps } from './types';

const filterChips = ({
    chips,
    chipMapperData,
}: {
    chips: ChipProps[];
    chipMapperData: ChipMapperProps & EnhancedChipMapperProps;
}) => {
    let filteredChips = chips
        .filter(
            (chip) =>
                chip.type !== 'discountPercentage' ||
                (chipMapperData?.showProductDiscountPercentageChip &&
                    chip.type === 'discountPercentage' &&
                    String(chip.planLength) ===
                        String(chipMapperData?.selectedRentalPlan?.minimumMonths)),
        )
        .filter(
            (chip) =>
                chip.label !== 'MIX' ||
                (chip.label === 'MIX' &&
                    chipMapperData?.mixCatalogFlag &&
                    chipMapperData?.mixGlobalFlag),
        )
        .filter((chip) => chip.label !== 'LOW_IN_STOCK_LABEL')
        .filter(
            (chip) =>
                chip.type !== 'lowStock' ||
                (chipMapperData?.showLowStockChip &&
                    !chipMapperData?.listingCard &&
                    chip.type === 'lowStock' &&
                    String(chip.id) === String(chipMapperData?.currentVariant?.id)),
        );
    if (
        chipMapperData?.showLowStockChip &&
        chipMapperData?.listingCard &&
        chips.some((chip) => chip.type === 'lowStock')
    ) {
        filteredChips.push({
            type: 'lowStock',
            tk: 'LOW_STOCK_CHIP',
        });
    }
    if (chips.some((chip) => chip.label === 'LOW_IN_STOCK_LABEL')) {
        filteredChips.push({
            type: 'lowStock',
            tk: 'LOW_IN_STOCK_LABEL',
        });
    }
    if (
        filteredChips.some((chip: ChipProps) => chip.type === 'discountPercentage') &&
        filteredChips.some((chip) => chip.tk === 'CHIP_DEAL_LABEL')
    ) {
        filteredChips = filteredChips.filter((chip) => chip.tk !== 'CHIP_DEAL_LABEL');
    }
    return filteredChips;
};

const ChipMapper = ({
    chips,
    className,
    selectedRentalPlan,
    mixCatalogFlag,
    mixGlobalFlag,
    showProductDiscountPercentageChip,
    showLowStockChip,
    currentVariant,
    listingCard,
}: ChipMapperProps & EnhancedChipMapperProps): JSX.Element[] | null => {
    if (chips && chips.length) {
        const filteredChips = filterChips({
            chips,
            chipMapperData: {
                selectedRentalPlan,
                mixCatalogFlag,
                mixGlobalFlag,
                showProductDiscountPercentageChip,
                showLowStockChip,
                currentVariant,
                listingCard,
            },
        });
        return filteredChips.map((chip, index) => (
            <Chip key={`${chip.label || chip.tk}-${index}`} {...chip} className={className} />
        ));
    }
    return null;
};

export default ChipMapper;

import React, { useState } from 'react';

import useMount from 'ggApp/shared/hooks/useMount';
import { defaultLanguageByCountry } from 'ggApp/modules/store/constants';

import { getStoreList, getLanguageList } from './utils';
import { Wrap, MapDotIcon, StoreDropDown, MessageIcon, LanguageDropDown } from './styles';

const DropDowns = ({
    className,
    activeStoreCode,
    activeLanguage,
    setRedirectionCountry,
    setRedirectionLanguage,
    translationKeys,
}) => {
    const [storeList, setStoreList] = useState([]);
    const [selectedStore, setSelectedStore] = useState({});
    const [languageList, setLanguageList] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState({});

    const setRedirectionValues = (store, lang) => {
        setRedirectionCountry(store);
        setRedirectionLanguage(lang);
    };

    const updateLanguageList = (selectedStoreValue, selectedLanguageValue) => {
        const languages = getLanguageList({
            selectedStoreValue,
            selectedLanguageValue,
            translationKeys,
            activeLanguage,
        });
        setLanguageList(languages);
        setSelectedLanguage(languages[0]);
        return languages;
    };

    const updateStoreList = (selectedStoreValue) => {
        const stores = getStoreList({
            selectedStoreValue,
            translationKeys,
            activeLanguage,
        });
        setStoreList(stores);
        setSelectedStore(stores[0]);
        return stores;
    };

    const handleChangeStore = (selectedStoreValue) => {
        const languages = updateLanguageList(selectedStoreValue, selectedLanguage.value);
        const storeHasLanguage = languages.some(
            (option) => option.value === selectedLanguage.value,
        );
        const stores = updateStoreList(selectedStoreValue);
        if (storeHasLanguage) {
            setRedirectionValues(stores[0].value, languages[0].value);
        } else {
            const defaultLanguage = defaultLanguageByCountry[selectedStoreValue.toUpperCase()];
            const newLanguageList = updateLanguageList(selectedStoreValue, defaultLanguage);
            setRedirectionValues(stores[0].value, newLanguageList[0].value);
        }
    };

    const handleChangeLanguage = (selectedLanguageValue) => {
        const stores = updateStoreList(selectedStore.value);
        const languages = updateLanguageList(selectedStore.value, selectedLanguageValue);
        setRedirectionValues(stores[0].value, languages[0].value);
    };

    useMount(() => {
        const stores = updateStoreList(activeStoreCode);
        const languages = updateLanguageList(activeStoreCode, activeLanguage);
        setRedirectionValues(stores[0].value, languages[0].value);
    }, [activeLanguage]);

    return (
        <Wrap className={className}>
            <div data-testid="country_redirection_store_dropdown">
                <StoreDropDown
                    currentLabel={selectedStore.label}
                    handleChange={handleChangeStore}
                    selected={selectedStore.value}
                    optionsList={storeList}
                    iconComponent={MapDotIcon}
                />
            </div>
            <div data-testid="country_redirection_locale_dropdown">
                <LanguageDropDown
                    currentLabel={selectedLanguage.label}
                    handleChange={handleChangeLanguage}
                    selected={selectedLanguage.value}
                    optionsList={languageList}
                    iconComponent={MessageIcon}
                />
            </div>
        </Wrap>
    );
};

export default DropDowns;

import axios from 'ggApp/utils/requests';

interface QueryParams {
    endpoint: string;
    body?: object;
}

type Method = 'get' | 'post' | 'patch' | 'delete';

interface ApiParams extends QueryParams {
    method: Method;
}

type ApiCall = (query: QueryParams) => Promise<any>;

type Adapter<D extends string> = {
    [key in D]: ApiCall;
};

const api: Adapter<Method> = {
    get: async ({ endpoint }) => axios.get(endpoint),
    post: async ({ endpoint, body }) => axios.post(endpoint, body),
    patch: async ({ endpoint, body }) => axios.patch(endpoint, body),
    delete: async ({ endpoint }) => axios.delete(endpoint),
};

export const client = ({ endpoint, method, ...rest }: ApiParams): Promise<any> =>
    api[method]({ endpoint, ...rest });

import styled, { SimpleInterpolation } from 'styled-components';

export const Text = styled.p`
    ${({ theme }): SimpleInterpolation => theme.typography.TinyParagraph};
    margin-left: ${(props): string => props.theme.spacers.M};
    color: ${(props) => props.theme.colors['Level 5']};
    cursor: text;
`;

export const ButtonLink = styled.button`
    ${({ theme }): SimpleInterpolation => theme.typography.TinyParagraph};
    color: currentColor;
    text-decoration: underline;
    cursor: pointer;
`;

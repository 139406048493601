import { datadogRum } from '@datadog/browser-rum';

import { USER_LOGOUT, LOGIN } from 'ggApp/modules/auth/actionTypes';
import { SIGN_IN, SIGN_UP } from 'ggApp/modules/auth/v3/state/actionTypes';

const isServer = __IS_SERVER__;

export default function logoutMiddleware() {
    return (next) => (action) => {
        if (!isServer) {
            const { type, data } = action;
            const { id } = data || {};

            if ([LOGIN, SIGN_IN, SIGN_UP].includes(type)) {
                datadogRum.setUser({
                    id: String(id),
                });
            }
            if (type === USER_LOGOUT) {
                datadogRum.removeUser();
            }
        }
        next(action);
    };
}

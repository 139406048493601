import { MODULE_NAME } from '../constants';

export const ADD_TO_CART_SUCCESS = `${MODULE_NAME}/ADD_TO_CART_SUCCESS`;
export const REMOVE_FROM_CART_SUCCESS = `${MODULE_NAME}/REMOVE_FROM_CART_SUCCESS`;

export const GET_ORDERS = `${MODULE_NAME}/GET_ORDERS`;
export const GET_ORDERS_SUCCESS = `${MODULE_NAME}/GET_ORDERS_SUCCESS`;
export const GET_ORDERS_ERROR = `${MODULE_NAME}/GET_ORDERS_ERROR`;

export const CANCEL_ORDER = `${MODULE_NAME}/CANCEL_ORDER`;
export const CANCEL_ORDER_SUCCESS = `${MODULE_NAME}/CANCEL_ORDER_SUCCESS`;
export const CANCEL_ORDER_ERROR = `${MODULE_NAME}/CANCEL_ORDER_ERROR`;

export const UPDATE_ORDER_STEP_SUCCESS = `${MODULE_NAME}/UPDATE_ORDER_STEP_SUCCESS`;
export const PURCHASE_SUCCESS = `${MODULE_NAME}/PURCHASE_SUCCESS`;
export const UPDATE_ORDER_OPTION_SUCCESS = `${MODULE_NAME}/UPDATE_ORDER_OPTION_SUCCESS`;

export const RESET_ORDER_TO_DEFAULT_STATE = `${MODULE_NAME}/RESET_ORDER_TO_DEFAULT_STATE`;

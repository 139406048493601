import { priceDecimalizer } from 'ggApp/modules/criteo/adapters';

export const mapCriteoProductPayload = (cart) => {
    return cart.items
        ? cart.items.map((product) => ({
              id: product.variant.id,
              price: priceDecimalizer(product.priceCents),
              quantity: product.quantity,
          }))
        : [];
};

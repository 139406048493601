import {
    INIT_COOKIE_POLICY_SETTINGS,
    UPDATE_COOKIE_POLICY_SETTINGS,
    CONSENT_COOKIE_POLICY,
} from 'ggApp/modules/cookiePolicy/state/constants/actionTypes';
import { SET_REQUEST_STATUS } from 'ggApp/modules/requestStatus/actionTypes';
import { LOADING_STATUS, READY_STATUS } from 'ggApp/modules/requestStatus/constants';
import { loadLuxScript, speedCurveEnabled } from 'ggApp/utils/speedcurve/lux';

const luxMiddleware = () => (next) => (action) => {
    if (!speedCurveEnabled) {
        next(action);
        return;
    }

    switch (action.type) {
        case INIT_COOKIE_POLICY_SETTINGS:
        case UPDATE_COOKIE_POLICY_SETTINGS:
        case CONSENT_COOKIE_POLICY:
            if (action.statisticsCookieConsent === true) {
                loadLuxScript();
            }
            break;
        case SET_REQUEST_STATUS:
            switch (action.status) {
                case LOADING_STATUS:
                    if (window.LUX) window.LUX.init();
                    break;
                case READY_STATUS:
                    if (window.LUX) window.LUX.send();
                    break;
                default:
            }
            break;
        default:
    }

    next(action);
};

export default luxMiddleware;

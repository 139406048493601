/* eslint-disable */
import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';
import reducer from './reducers';

// components:
export { default as OrderComponentSummary } from './components/Summary';
export { default as OrderComponentTable } from './components/Table';
export { default as OrderComponentTableMobile } from './components/TableMobile';
export { default as OrderComponentTableList } from './components/TableList';
export { default as OrderComponentTableItem } from './components/TableItem';
export {
    default as OrderComponentTableItemMobile,
} from './components/TableItemMobile';
export {
    default as OrderComponentTableSummary,
} from './components/TableSummary';
export {
    default as OrderComponentSingleProduct,
} from './components/SingleProduct';

// --- rest:
export default {
    actions,
    constants,
    reducer,
    selectors,
};

import { TypePolicies } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import {
    ContractAction,
    MixSwapShippingProgress,
    SubscriptionAction,
    Maybe,
} from 'ggApp/types/graphql';
import { shippingProgressVar } from '../../clientFactory/variables';
import mergeArrayValuesByField from '../../utils/mergeCacheHelpers/mergeArrayValuesByField';

const typePolicies: TypePolicies = {
    Query: {
        fields: {
            subscriptionCollection: relayStylePagination(['filter']),
        },
    },
    MixSummary: {
        keyFields: ['contractId'],
    },
    MixCartOrder: {
        keyFields: ['orderNumber'],
    },
    Swap: {
        fields: {
            shippingProgress: {
                read(): Maybe<MixSwapShippingProgress> {
                    return shippingProgressVar();
                },
            },
        },
    },
    BasicContract: {
        fields: {
            billingTerms: {
                merge: true,
            },
            durationTerms: {
                merge: true,
            },
            actions: {
                merge: mergeArrayValuesByField<ContractAction>('__typename'),
            },
        },
    },
    Flex: {
        fields: {
            paymentMethod: {
                merge: true,
            },
        },
    },
    SubscriptionDetails: {
        fields: {
            paymentMethod: {
                merge: true,
            },
            subscriptionActions: {
                merge: mergeArrayValuesByField<SubscriptionAction>('__typename'),
            },
        },
    },
};

export default typePolicies;

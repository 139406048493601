import styled, { css } from 'styled-components';

/* TODO: Create seperate color profiles with final color fallback
based on element type (headline, paragraph, etc); */
const colorStyle = css`
    color: ${({ color, theme, colorToken }) => color || theme.colors[colorToken] || theme.colors.Foreground};
`;

const HugeHeadline = styled.h1`
    ${colorStyle}
    ${({ theme }) => css(theme.typography.HugeHeadline)}
    font-size: 80px;
    line-height: 1;
`;

const SuperHeadline = styled.h1`
    ${colorStyle}
    ${({ theme }) => css(theme.typography.SuperHeadline)}
`;

const Headline = styled.h1`
    ${colorStyle}
    ${({ theme }) => css(theme.typography.Headline)}
`;

const SubHeadline = styled.h2`
    ${colorStyle}
    ${({ theme }) => css(theme.typography.Subheadline)}
`;

const DefaultHeadline = styled.span`
    ${colorStyle}
    ${({ theme }) => css(theme.typography.Subheadline)}
`;

const SmallHeadline = styled.h3`
    ${colorStyle}
    ${({ theme }) => css(theme.typography.SuperTinyHeadline)}
`;
const NormalHeadline = styled.span`
    ${colorStyle}
    ${({ theme }) => css(theme.typography.SuperTinyHeadline)}
`;
const SmallCapsHeadline = styled.label`
    ${colorStyle}
    ${({ theme }) => css(theme.typography.SmallCapsHeadline)}
`;

const LargeParagraph = styled.p`
    ${colorStyle}
    ${({ theme }) => css(theme.typography.LargeParagraph)}
    font-size: 24px;
    font-weight: 400;
`;

const Paragraph = styled.p`
    ${colorStyle}
    ${({ theme }) => css(theme.typography.Paragraph)}
`;

const TinyParagraph = styled.p`
    ${colorStyle}
    ${({ theme }) => css(theme.typography.TinyParagraph)}
`;

export {
    HugeHeadline,
    SuperHeadline,
    Headline,
    SubHeadline,
    DefaultHeadline,
    SmallHeadline,
    NormalHeadline,
    SmallCapsHeadline,
    LargeParagraph,
    Paragraph,
    colorStyle,
    TinyParagraph,
};

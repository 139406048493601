import {
    PRODUCT_WIDGET,
    PRODUCT_WIDGET_FAILURE,
    PRODUCT_WIDGET_SUCCESS,
} from 'ggApp/modules/productWidget/constants';
import { extractProductsFromWidgets } from 'ggApp/modules/productWidget/adapters';

const defaultState = {
    loading: null,
    error: {},
    data: [],
    products: [],
};

export function productWidgetReducer(state = defaultState, action) {
    switch (action.type) {
        case PRODUCT_WIDGET:
            return {
                ...state,
                loading: true,
                error: {},
            };

        case PRODUCT_WIDGET_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data || [],
                products: extractProductsFromWidgets(action.data || []),
            };

        case PRODUCT_WIDGET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
}

import { pushDataLayer } from 'ggApp/modules/tracking/actions';
import { ANALYTIC_EVENTS } from 'ggApp/modules/gaAnalytics/constants';

import { HookType } from 'ggApp/modules/stepper/utils/enums';

export const GA_TRANSITION_EVENTS = HookType;

const EVENT_IDENTIFIERS = {
    enter: 'enter',
    close: 'exit.modal_close',
    cancel: 'exit.cancel',
    leave: 'exit.confirm',
    skip: 'exit.skip',
    success: 'exit.success',
    flowChange: 'exit.flow_change',
};

const defaultStepIdentifier = (stepIdentifier) => stepIdentifier;

export const defaultEventIdentifier = (eventIdentifier) => EVENT_IDENTIFIERS[eventIdentifier];

const dispatchGAEvent = ({
    stepName,
    flowName,
    transitionType,
    eventCategory,
    eventProperty,
    stepIdentifierMapper = defaultStepIdentifier,
    eventIdentifierMapper = defaultEventIdentifier,
}) => {
    if (!stepName || !transitionType || !eventCategory) {
        return false;
    }

    const stepIdentifier = stepIdentifierMapper(stepName);
    const eventIdentifier = eventIdentifierMapper(transitionType);

    pushDataLayer({
        event: ANALYTIC_EVENTS.gtmEvent,
        eventCategory,
        eventAction: stepIdentifier,
        eventLabel: eventIdentifier,
        eventProperty: {
            current_flow: flowName,
            ...(eventProperty ?? {}),
        },
    });

    return true;
};

export default dispatchGAEvent;

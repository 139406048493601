import styled from 'styled-components';

import Link from 'ggApp/shared/containers/FrontshopLink';

const Wrap = styled(Link)`
    position: relative;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.Foreground};
    transition-property: all;
`;

export { Wrap };

import {
    PREFETCH_SEARCH_LISTING_DATA,
    PREFETCH_SEARCH_LISTING_DATA_SUCCESS,
    PREFETCH_SEARCH_LISTING_DATA_ERROR,
    FETCH_SEARCH_LISTING_DATA,
    FETCH_SEARCH_LISTING_DATA_SUCCESS,
    FETCH_SEARCH_LISTING_DATA_ERROR,
} from '../actionTypes';

const defaultState = {
    loading: null,
    error: {},
    defaultProducts: [],
    products: [],
    query: '',
    totalItems: 0,
};

export default function(state = defaultState, action) {
    switch (action.type) {
        case PREFETCH_SEARCH_LISTING_DATA:
        case FETCH_SEARCH_LISTING_DATA: {
            return { ...state, loading: true };
        }

        case PREFETCH_SEARCH_LISTING_DATA_SUCCESS: {
            const defaultProducts = action.payload || [];
            return { ...state, loading: false, defaultProducts };
        }

        case FETCH_SEARCH_LISTING_DATA_SUCCESS: {
            const products = action.payload.products || [];
            const query = action.payload.queryStr || '';
            const totalItems = action.payload.totalItems || 0;
            return {
                ...state,
                loading: false,
                products,
                query,
                totalItems,
            };
        }

        case PREFETCH_SEARCH_LISTING_DATA_ERROR:
        case FETCH_SEARCH_LISTING_DATA_ERROR:
            return { ...state, loading: false, error: action.error };

        default:
            return state;
    }
}

import {
    CREATE_ADYEN_PAYMENT_SESSION,
    CREATE_ADYEN_PAYMENT_SESSION_SUCCESS,
    CREATE_ADYEN_PAYMENT_SESSION_ERROR,
} from '../actionTypes';

const defaultState = {
    loading: null,
    error: {},
    data: null,
};

export default function(state = defaultState, action) {
    switch (action.type) {
        case CREATE_ADYEN_PAYMENT_SESSION:
            return {
                ...state,
                loading: true,
                error: {},
            };

        case CREATE_ADYEN_PAYMENT_SESSION_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data,
            };

        case CREATE_ADYEN_PAYMENT_SESSION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
}

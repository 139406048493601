import { combineReducers } from 'redux';

import productBySlug from './productBySlug';
import productById from './productById';
import currentlySelected from './currentlySelected';
import list from './list';
import pagination from './pagination';
import waitingList from './waitingList';
import relatedProductsBySlug from './relatedProductsBySlug';
import promotions from './promotions';
import listProductsGraphql from './listProductsGraphql';

export default combineReducers({
    productBySlug,
    productById,
    selectedVariantId: currentlySelected,
    list,
    waitingList,
    pagination,
    relatedProductsBySlug,
    promotions,
    listProductsGraphql,
});

import pick from 'lodash/pick';
import mapValues from 'lodash/mapValues';

export function toBooleanFlag(flag) {
    switch (flag) {
        case true:
        case 'true':
        case '1':
            return true;
        default:
            return false;
    }
}

// FlagData (src/ggApp/types/common.ts)
export const composeFlagsDataForEventTracking = (flags) => {
    return mapValues(flags, (flag) =>
        pick(flag, ['flagID', 'flagKey', 'flagSnapshotID', 'segmentID', 'variantID', 'variantKey']),
    );
};

import React, { Component } from 'react';
import { Portal } from 'react-portal';
import PropTypes from 'prop-types';

import Modal, { modalClassNamesPropType } from './modal';
import { ANIMATION_DURATION_IN_MS } from './styles';

const closedState = {
    isRendered: false,
    isShown: false,
};

const openedState = {
    isRendered: true,
    isShown: true,
};

const closingAnimationState = {
    isRendered: true,
    isShown: false,
};

/**
 * ModalRoot
 * A component to render modal window as a child
 * with forwarding actions to open and close the modal.
 * @prop {bool} defaultOpened
 * @prop {node} children
 * @prop {func} renderActions
 * @prop {func} onOpen
 * @prop {func} onClose
 * @prop {func} onClosed
 * @prop {string} width
 * @prop {string} maxWidth
 * @prop {number} animationDuration
 * @prop {func} modalRef
 * @prop {boolean} skipOpenModal
 */
class ModalRoot extends Component {
    constructor(props) {
        super(props);

        this.state = props.defaultOpened ? openedState : closedState;
    }

    componentDidMount() {
        this.setModalRef(this);
    }

    componentWillUnmount() {
        this.setModalRef(null);
    }

    setModalRef = (modalInstance) => {
        const { modalRef } = this.props;

        if (typeof modalRef === 'function') {
            modalRef(modalInstance);
        }
    };

    openModal = (payload) => {
        const { skipOpenModal, onOpen } = this.props;

        if (!skipOpenModal) {
            this.setState(openedState);
        }

        if (typeof onOpen === 'function') {
            onOpen(payload);
        }
    };

    closeModal = (payload) => {
        const { onClose } = this.props;

        let shouldBeClosed = true;

        if (typeof onClose === 'function') {
            shouldBeClosed = onClose(payload);
        }

        if (shouldBeClosed === false) {
            return;
        }

        this.setState(closingAnimationState);
    };

    handleExitAnimationDone = () => {
        const { onClosed } = this.props;

        this.setState(closedState);

        if (typeof onClosed === 'function') {
            onClosed();
        }
    };

    render() {
        const {
            children,
            renderActions,
            height,
            width,
            maxWidth,
            animationDuration,
            className,
            classNames,
            closeIcon,
            withBackgroundColor,
            shouldCloseOnClick,
        } = this.props;
        const { isRendered, isShown } = this.state;

        if (isRendered) {
            return (
                <Portal>
                    <Modal
                        withBackgroundColor={withBackgroundColor}
                        className={className}
                        classNames={classNames}
                        renderActions={renderActions}
                        isShown={isShown}
                        height={height}
                        width={width}
                        maxWidth={maxWidth}
                        animationDuration={animationDuration}
                        onCloseIconClick={this.closeModal}
                        onExitAnimationDone={this.handleExitAnimationDone}
                        closeIcon={closeIcon}
                        onBackgroundClick={(shouldCloseOnClick && this.closeModal) || undefined}
                    >
                        {children}
                    </Modal>
                </Portal>
            );
        }

        return null;
    }
}

ModalRoot.propTypes = {
    defaultOpened: PropTypes.bool,
    className: PropTypes.string,
    classNames: modalClassNamesPropType,
    children: PropTypes.node,
    renderActions: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onClosed: PropTypes.func,
    height: PropTypes.string,
    width: PropTypes.string,
    maxWidth: PropTypes.string,
    animationDuration: PropTypes.number,
    modalRef: PropTypes.func.isRequired,
    closeIcon: PropTypes.bool,
    skipOpenModal: PropTypes.bool,
    withBackgroundColor: PropTypes.bool,
    shouldCloseOnClick: PropTypes.bool,
};

ModalRoot.defaultProps = {
    onOpen: () => {},
    onClose: () => {},
    onClosed: () => {},
    defaultOpened: false,
    className: undefined,
    classNames: {},
    children: null,
    renderActions: undefined,
    animationDuration: ANIMATION_DURATION_IN_MS,
    height: '',
    width: '',
    maxWidth: '',
    closeIcon: false,
    skipOpenModal: false,
    withBackgroundColor: true,
    shouldCloseOnClick: true,
};

export default ModalRoot;

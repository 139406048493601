import { branch, compose, renderNothing, withProps } from 'recompose';
import { connect } from 'react-redux';
import ClickOutside from 'react-click-outside';
import { createStructuredSelector } from 'reselect';

import ggRouter from 'ggApp/modules/router';
import {
    reorderedStoreList as reorderedStoreListSelector,
    storeActive as storeActiveSelector,
} from 'ggApp/modules/store/selectors';
import { translate, keys } from 'ggApp/modules/translator/selectors';

import StoreSelector from './StoreSelector';

const enhance = compose(
    connect(
        createStructuredSelector({
            routes: ggRouter.selectors.routes,
            params: ggRouter.selectors.params,
            reorderedStoreList: reorderedStoreListSelector,
            storeActive: storeActiveSelector,
            translationKeys: keys,
        }),
    ),
    withProps(({ reorderedStoreList, translationKeys, storeActive }) => ({
        filteredStoreList: reorderedStoreList.map(({ value, label }) => ({
            value,
            label: translate(translationKeys, storeActive.activeLanguage, label),
        })),
    })),
    branch(
        ({ filteredStoreList, storeActive: { code } }) =>
            filteredStoreList.length === 0 ||
            filteredStoreList.findIndex(({ value }) => value === code),
        renderNothing,
    ),
    ClickOutside,
);

export default enhance(StoreSelector);

/* eslint-disable */
import React from 'react';
import CircleLoader from 'ggApp/shared/components/CircleLoader';
import Overlay from './styles';

function LoadingOverlay() {
    return (
        <Overlay>
            <CircleLoader />
        </Overlay>

    );
}

export default LoadingOverlay;

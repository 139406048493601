/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-return-assign */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import debounce from 'ggApp/utils/debounce';
import { Gap, NextArrow, PrevArrow, Root, List } from './styles';

class Carousel extends PureComponent {
    constructor(props) {
        super(props);
        this.listRef = React.createRef();
        this.debouncedSetInnerWidth = debounce(this.setInnerWidth, 200);
        this.state = {
            productIndex: 0,
            perPage: 4,
            containerWidth: 0,
            cardWidth: 320,
            cardMargin: 16,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.debouncedSetInnerWidth);
        if (this.listRef.current) {
            this.setInnerWidth();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.debouncedSetInnerWidth);
    }

    setInnerWidth = () => {
        if (this.listRef.current && this.listRef.current.children.length > 0) {
            const containerWidth = this.listRef.current.offsetWidth || 0;
            const cardWidth =
                this.listRef.current.firstChild && this.listRef.current.firstChild.offsetWidth;
            let cardMargin = 0;

            if (this.listRef.current.children.length > 1) {
                const [childA, childB] = this.listRef.current.children;
                cardMargin = childB.offsetLeft - childA.offsetLeft - cardWidth;
            }

            const perPage = Math.floor(
                (containerWidth + this.state.cardMargin) / (cardWidth + this.state.cardMargin),
            );

            if (this.state.perPage !== perPage || this.state.cardWidth !== cardWidth) {
                this.setState({
                    perPage: Math.max(perPage, 1),
                    containerWidth,
                    cardWidth,
                    cardMargin,
                });
            }
        }
    };

    goNext = () => {
        const { productIndex, perPage } = this.state;
        const {
            children: { length: lastIndex },
        } = this.props;
        if (lastIndex - perPage === productIndex) {
            this.setState({ productIndex: 0 });
            return;
        }
        this.setState({
            productIndex: Math.min(productIndex + perPage, lastIndex - perPage),
        });
    };

    goPrev = () => {
        const { productIndex, perPage } = this.state;
        this.setState({ productIndex: Math.max(productIndex - perPage, 0) });
    };

    render() {
        const { children, className } = this.props;
        const { productIndex, perPage, containerWidth, cardWidth, cardMargin } = this.state;
        const indexPosition = productIndex * (cardWidth + cardMargin);
        if (children.length === 0) return null;
        return (
            <Root ref={(el) => (this.rootRef = el)} className={className}>
                <List
                    style={{
                        transform: `translate3d(${-indexPosition}px, 0, 0)`,
                    }}
                    ref={this.listRef}
                >
                    {children}
                    <Gap
                        width={Math.max(
                            containerWidth + cardMargin - (cardWidth + cardMargin) * perPage,
                            0,
                        )}
                    />
                </List>
                <NextArrow onClick={this.goNext} disabled={perPage >= children.length} />
                <PrevArrow onClick={this.goPrev} disabled={productIndex === 0} />
            </Root>
        );
    }
}

Carousel.propTypes = {
    cardProps: PropTypes.shape({}),
};

Carousel.defaultProps = {
    cardProps: null,
    children: [],
};

export default Carousel;

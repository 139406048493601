import { sendStructEvent } from '../../api';

interface PayloadModel {
    eventAction: 'add_to_wishlist' | 'remove_from_wishlist';
    eventLabel: 'add_to_wishlist' | 'remove_from_wishlist';
    eventProperty: {
        productData: {
            name: string;
            brand: string;
            sub_category: string;
            productSku: string;
            productVariant: number;
            subscriptionLength: number;
            price: string;
            non_discountedPrice: string;
            discountAmount: number;
            discountPercentage: number;
            imageUrl: string;
        };
    };
}

export const sendWishListEvent = (payload: PayloadModel): void => {
    const { eventProperty, eventLabel, eventAction } = payload;

    sendStructEvent({
        eventCategory: 'wishlist',
        eventAction,
        eventLabel,
        eventProperty,
    });
};

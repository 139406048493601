import {
    enrichAnalyticsUser,
    mapMarketingParameters,
    sendAnalyticsEvent,
} from '@devsbb/analytics-client';
import CookiesManager from 'ggApp/utils/CookiesManager';
import {
    ANALYTICS_SESSION_COOKIE_KEY,
    ANALYTICS_TRIGGERED_PATHNAME,
    ANALYTICS_SESSION_TRIGGERED,
    ANALYTICS_DEFAULT_TRAITS,
} from 'ggApp/utils/CookiesManager/constants';
import { SegmentEvents } from '../events';

export const trackAdCampaignAnalytics = (): void => {
    const cookiesManager: any = new CookiesManager();
    cookiesManager.set(ANALYTICS_SESSION_TRIGGERED, 'false');
    const traits = mapMarketingParameters(window.location.href, document.referrer);

    const userId = cookiesManager.get('user_id');

    if (JSON.stringify(cookiesManager.get(ANALYTICS_DEFAULT_TRAITS)) !== JSON.stringify(traits)) {
        enrichAnalyticsUser({
            ...traits,
            ...(userId ? { id: userId } : {}),
        });
    }
    // Send ad campaign analytics only once per 30 minute session
    if (cookiesManager.get(ANALYTICS_SESSION_COOKIE_KEY) === 'true') {
        return;
    }

    cookiesManager.set(ANALYTICS_SESSION_COOKIE_KEY, 'true', { maxAge: 30 * 60 }); // convert 30 minutes in seconds

    if (traits) {
        cookiesManager.set(ANALYTICS_DEFAULT_TRAITS, JSON.stringify(traits));
        sendAnalyticsEvent(SegmentEvents.sessionStarted, {}, { segment: { traits } });
        cookiesManager.set(ANALYTICS_SESSION_TRIGGERED, 'true');
        cookiesManager.set(ANALYTICS_TRIGGERED_PATHNAME, window.location.pathname);
    }
};

import { deleteOrder } from 'ggApp/shared/context/Order/cookies';
import loadConfiguration from 'ggApp/utils/configuration';

import { client } from './client';
import { APISelection, LineItemInput } from '../integration';

const { APP_CONFIG } = loadConfiguration();
const { apiV2Url } = APP_CONFIG;

export const lineItem: APISelection<{
    items: Array<LineItemInput>;
    item: LineItemInput;
    callback: ((arg1?: string) => void) | null;
}> = {
    add: async ({ orderNumber, customizeColorFlag, customizePlanFlag }, event) => {
        const itemPayload = event?.items.map((item: LineItemInput) => ({
            any_variant: false,
            variant_sku: item.variant.sku,
            quantity: item.quantity,
            committed_months: item.minimumMonths,
        }));

        const includeProduct =
            customizeColorFlag || customizePlanFlag ? '?include_product=true' : '';

        return client({
            endpoint: `${apiV2Url}/orders/${orderNumber}/line_items${includeProduct}`,
            method: 'post',
            body: { items: itemPayload },
        }).then((res): object => {
            if (event?.callback && typeof event?.callback === 'function') {
                event?.callback(res.data.order?.number);
            }
            return { order: res.data.order, items: itemPayload };
        });
    },
    delete: (
        { orderNumber, orderMode, activeStore, customizeColorFlag, customizePlanFlag },
        event,
    ) => {
        const { id } = event?.item || {};
        const includeProduct =
            customizeColorFlag || customizePlanFlag ? '?include_product=true' : '';

        return client({
            endpoint: `orders/${orderNumber}/line_items/${id}${includeProduct}`,
            method: 'delete',
        }).then((res): object => {
            if (!res.data) {
                deleteOrder(orderMode, activeStore.code);
            }
            return res.data;
        });
    },
    update: async ({ orderNumber, customizeColorFlag, customizePlanFlag }, event) => {
        const { id } = event?.item || {};

        const includeProduct =
            customizeColorFlag || customizePlanFlag ? '?include_product=true' : '';

        return client({
            endpoint: `orders/${orderNumber}/line_items/${id}${includeProduct}`,
            method: 'patch',
            body: event?.item || {},
        }).then((res): object => {
            if (event?.callback && typeof event?.callback === 'function') {
                event?.callback();
            }
            return res.data;
        });
    },
};

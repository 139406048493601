import { ActiveStore } from 'ggApp/modules/store';
import { CURRENCIES_ASSOCIATION } from 'ggApp/modules/store/constants';
import { formatPriceByLanguage } from 'ggApp/utils/money';
import { DEFAULT_GCASH_AMOUNT } from 'ggApp/shared/screens/GroverCash/constants';

export type CashAmount = {
    inCents: number;
    formatted: string;
};

export const getAmountData = (
    activeStore: ActiveStore,
    inCents = DEFAULT_GCASH_AMOUNT,
): CashAmount => {
    const amount = {
        inCents,
        formatted: formatPriceByLanguage(
            `${inCents / 100}`,
            CURRENCIES_ASSOCIATION[activeStore.default_currency],
            activeStore.activeLanguage,
        ),
    };
    return amount;
};

// LOCAL IMPORTS
import { getRefreshToken } from 'ggApp/modules/auth/utils';
import { getCookiesFromConfig } from 'ggApp/utils/CookiesManager';

import createAxiosInstance from './axiosFactory';
import { refreshAccessToken } from './refresh';

// CONSTANTS
const UNAUTHORIZED_HTTP_STATUS = 401;
// TODO: refactor refresh token mechanism, to use WWW-Authenticate header
// const REVOKED_TOKEN_AUTHENTICATION_HEADER =
//     'Bearer realm="Doorkeeper", error="invalid_token", error_description="The access token was revoked"';

// MODULE GLOBAL VARIABLES
const axiosInstance = createAxiosInstance();

let refreshTokenPromise = null;

function interceptorErrorHandler(error) {
    const { config: originalRequestConfig, status, data } = error.response ?? {};

    if (status !== UNAUTHORIZED_HTTP_STATUS) {
        return Promise.reject(error);
    }

    const cookiesManager = getCookiesFromConfig(originalRequestConfig);
    const refreshToken = getRefreshToken(cookiesManager);
    // TODO: refactor refresh token mechanism, to use WWW-Authenticate header:
    // const authenticateHeader = headers['WWW-Authenticate'];
    // const isRevokedToken = authenticateHeader === REVOKED_TOKEN_AUTHENTICATION_HEADER;
    const twoFactorAuthIsRequired = Boolean(data?.['2fa_required']);

    if (!refreshToken || twoFactorAuthIsRequired) {
        return Promise.reject(error);
    }

    if (!refreshTokenPromise) {
        refreshTokenPromise = refreshAccessToken(cookiesManager);
    }
    // to take new token from cookies in the headersInterceptor
    originalRequestConfig.headers.Authorization = undefined;

    return refreshTokenPromise.then(() => axiosInstance.request(originalRequestConfig));
}

export default function createRefreshTokenInterceptor() {
    return [(response) => response, interceptorErrorHandler];
}

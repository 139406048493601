/* eslint-disable */
import {
   GET_CATEGORY_BY_ID_SUCCESS,
} from './../actionTypes';

export default function (state = {}, action) {
  switch (action.type) {

    case GET_CATEGORY_BY_ID_SUCCESS: {
        const category = action.data;
        return category;
    }

    default:
      return state;
  }
}
import { formatPattern } from 'react-router';
import urlJoin from 'url-join';
import qs from 'query-string';

import { FLOW_NAMES } from 'ggApp/modules/auth/v3/constants';

const isServer = __IS_SERVER__;

export const IsUrlsSlashEquals = (firstUrl, secondUrl) =>
    firstUrl === secondUrl || `${firstUrl}/` === secondUrl || `${secondUrl}/` === firstUrl;

export const onEnterAuthFactory = ({
    matchPath,
    isUserRequired,
    redirectPath,
    allowBackRedirection = false,
}) => (nextState, replace, callback) => {
    const { location, routes, params } = nextState;
    const { state, pathname, query } = location;
    const rootRoute = routes.filter((route) => route.path)[0];
    const rootPathname = formatPattern(rootRoute.path, params);

    if (isServer && state && IsUrlsSlashEquals(urlJoin(rootPathname, matchPath), pathname)) {
        const { isLoggedIn } = state ?? {};
        const fullPath = urlJoin(rootPathname, redirectPath);
        const newQuery = { ...query };

        if (allowBackRedirection) {
            newQuery.redirection = pathname;
        }

        if (isLoggedIn) {
            if (isUserRequired) {
                replace({
                    pathname: fullPath,
                    query: newQuery,
                    state,
                });
            }
            callback();
        } else {
            if (!isUserRequired) {
                replace({
                    pathname: fullPath,
                    query: newQuery,
                    state,
                });
            }
            callback();
        }
        return;
    }
    callback();
};

export const onEnterHome = onEnterAuthFactory({
    matchPath: '',
    isUserRequired: true,
    redirectPath: 'for-you',
});

export const onEnterForYou = onEnterAuthFactory({
    matchPath: 'for-you',
    isUserRequired: false,
    redirectPath: '',
});

export const onEnterFavorites = onEnterAuthFactory({
    matchPath: 'favorites',
    isUserRequired: false,
    redirectPath: '',
});

export const onEnterVerify = (nextState, replace, callback) => {
    const { location, params } = nextState;
    const { store, language } = params;
    const { state } = location;

    const authPath = `/${store}-${language}/auth`;
    const query = {
        flow: FLOW_NAMES.IDENTITY_VERIFICATION,
        redirection: urlJoin(
            authPath,
            `?${qs.stringify({ flow: FLOW_NAMES.IDENTITY_VERIFICATION })}`,
        ),
    };

    replace({
        pathname: authPath,
        query,
        state,
    });

    callback();
};

export const onEnterBankAccountVerify = onEnterAuthFactory({
    matchPath: 'verify-bank-account',
    isUserRequired: false,
    redirectPath: 'auth',
    allowBackRedirection: true,
});

export const getBusinessRedirectPath = (storeCode, langCode) =>
    storeCode.startsWith('business_') ? `/${storeCode}/your-tech` : `/${storeCode}-${langCode}`;

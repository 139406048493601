import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { GA_TRANSITION_EVENTS } from 'ggApp/modules/stepsFlowProvider';
import { isFunction } from 'ggApp/utils/function';
import CloseSmall from 'ggApp/shared/icons/CloseSmall';

import AuthProvider from 'ggApp/modules/auth/v3/components/AuthProvider';
import { isAppUser } from 'ggApp/routes/_wrap/MobileWrapper/utils';
import { fireEvent } from 'ggApp/modules/inwebview_api';
import { POST_EVENTS } from 'ggApp/modules/inwebview_api/constants';
import { isNativeAuthEnabled } from 'ggApp/modules/inwebview_api/nativeAuthEvents';

import { FLOW_NAMES } from '../../constants';

import { StyledModal, CloseButton } from './styles';

class AuthModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalClosing: false,
            isNativeAuthActive: false,
        };
    }

    async componentDidMount() {
        const { flow } = this.props;
        const nativeAuthEnabled = await isNativeAuthEnabled();
        const flowsToSkipNativeAuth = [
            FLOW_NAMES.SIGN_IN_FROM_CHECKOUT,
            FLOW_NAMES.VERIFICATION_PROFILE,
            FLOW_NAMES.VERIFICATION_IN_PROGRESS,
            FLOW_NAMES.VERIFICATION_ERROR,
            FLOW_NAMES.PHONE_NUMBER_VERIFICATION,
        ];
        this.setState({
            isNativeAuthActive:
                nativeAuthEnabled && isAppUser() && !flowsToSkipNativeAuth.includes(flow),
        });
    }

    createStepsFlowProviderRef = (stepsFlowProvider) => {
        this.stepsFlowProvider = stepsFlowProvider;
    };

    createModalRef = (modal) => {
        const { modalRef } = this.props;
        this.modal = modal;

        if (isFunction(modalRef)) {
            modalRef(modal);
        }
    };

    handleClosing = (payload = {}) => {
        const { dispatchGAEvent } = this.stepsFlowProvider || {};
        const { onClose, checkStoreAndUserType } = this.props;

        // Dispatch GA event only if modal is closed manually
        if (!payload.autoClose && isFunction(dispatchGAEvent)) {
            dispatchGAEvent(GA_TRANSITION_EVENTS.close);
        }

        if (isFunction(onClose)) {
            onClose(payload);
        }

        checkStoreAndUserType();
    };

    closeModal = (payload) => {
        this.setState({
            modalClosing: true,
        });

        if (this.modal) {
            this.modal.closeModal(payload);
        }
    };

    handleClosed = (payload) => {
        const { onClosed } = this.props;

        this.setState({
            modalClosing: false,
        });

        if (isFunction(onClosed)) {
            onClosed(payload);
        }
    };

    handleSuccess = () => {
        const { onSuccess } = this.props;

        this.closeModal({
            autoClose: true,
        });

        if (isFunction(onSuccess)) {
            onSuccess();
        }
    };

    handleCancel = () => {
        this.closeModal({
            autoClose: true,
        });
    };

    handleOpenModal = async () => {
        const { onOpen } = this.props;
        const { isNativeAuthActive } = this.state;

        if (isNativeAuthActive) {
            fireEvent(POST_EVENTS.NAVIGATE_TO_LOGIN);
        } else {
            onOpen();
        }
    };

    render() {
        const { newCheckoutFlag, flow, options, withBackgroundColor } = this.props;
        const { modalClosing, isNativeAuthActive } = this.state;

        return (
            <div data-testid="auth-modal">
                <StyledModal
                    modalRef={this.createModalRef}
                    onOpen={this.handleOpenModal}
                    onClose={this.handleClosing}
                    onClosed={this.handleClosed}
                    skipOpenModal={isNativeAuthActive}
                    shouldCloseOnClick={!newCheckoutFlag}
                    withBackgroundColor={withBackgroundColor}
                >
                    <CloseButton
                        onClick={this.closeModal}
                        iconSvgCode={CloseSmall}
                        outlined
                        size="small"
                        data-testid="auth-close-button"
                    />
                    <AuthProvider
                        flow={flow}
                        stepsFlowProviderRef={this.createStepsFlowProviderRef}
                        onSuccess={this.handleSuccess}
                        onCancel={this.handleCancel}
                        options={options}
                        newCheckoutOverlay={newCheckoutFlag}
                        modalClosing={modalClosing}
                    />
                </StyledModal>
            </div>
        );
    }
}

AuthModal.propTypes = {
    onSuccess: PropTypes.func,
    modalRef: PropTypes.func.isRequired,
    flow: PropTypes.oneOf(Object.keys(FLOW_NAMES)),
    options: PropTypes.shape({}),
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onClosed: PropTypes.func,
    checkStoreAndUserType: PropTypes.func,
    newCheckoutFlag: PropTypes.bool,
};

AuthModal.defaultProps = {
    onSuccess: () => {},
    onOpen: () => {},
    onClose: () => {},
    onClosed: () => {},
    checkStoreAndUserType: () => {},
    flow: null,
    options: undefined,
    newCheckoutFlag: false,
};

export default AuthModal;

import styled, { css, keyframes } from 'styled-components';

import omitProps from 'ggApp/utils/omitProps';
import { lighten } from 'ggApp/utils/color/lighten';

export const Intersection = styled.div`
    max-height: inherit;
    max-width: inherit;
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    width: 100%;
    height: 100%;
`;

const ShimmerEffectAnimation = keyframes`
    0% {
        background-color: #ededed;
        background-image: none;
    }
    50% {
        background-image: linear-gradient(90deg, ${lighten(0.035, '#f1f1f1')}, #ededed, #ededed);
    }
    55% {
        background-image: linear-gradient(90deg, #ededed, ${lighten(0.035, '#f1f1f1')}, #ededed);
    }
    60% {
        background-image: linear-gradient(90deg, #ededed, #ededed, ${lighten(0.035, '#f1f1f1')});
    }
    61%, 100% {
        background-image: none;
        background-color: #ededed;
    }
`;

export const ShimmerBackground = styled(omitProps('loading', 'animationDelay')('div'))`
    display: ${({ loading }) => loading ? 'flex' : 'none'};
    justify-content: inherit;
    align-items: inherit;
    width: 100%;
    height: 100%;
    max-height: inherit;
    max-width: inherit;
    animation-name: ${ShimmerEffectAnimation};
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(1, 0, 1, 0);
    animation-delay: ${({ animationDelay }) => animationDelay}s;
`;

const FadeInAnimation = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const ImageContainer = styled(omitProps('loading')('div'))`
    display: ${({ loading }) => !loading ? 'flex' : 'none'};
    justify-content: inherit;
    align-items: inherit;
    width: 100%;
    height: 100%;
    max-height: inherit;
    max-width: inherit;
    ${({ loading }) => !loading && css`
        animation-name: ${FadeInAnimation};
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    `}
`;

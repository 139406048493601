import { pushDataLayer } from 'ggApp/modules/tracking/actions';
import { md5Hasher } from 'ggApp/modules/criteo/adapters';
import { mapCriteoProductPayload } from './mappers';

export const registerCriteoCartUpdate = (user, cart) => {
    if (!user) return;
    const userEmail = user.email ? md5Hasher(user.email) : '';
    const eventPayload = {
        event: 'crto_basketpage',
        crto: {
            email: userEmail,
            list: 'basket',
            products: mapCriteoProductPayload(cart),
        },
    };
    if (eventPayload) {
        pushDataLayer(eventPayload);
    }
};

export const registerCriteoOrderConfirmation = (user, cart) => {
    const userEmail = user.email ? md5Hasher(user.email) : '';
    const eventPayload = {
        event: 'crto_transactionpage',
        list: 'confirmation',
        crto: {
            email: userEmail,
            transactionid: cart.orderNumber,
            products: mapCriteoProductPayload(cart),
        },
    };
    if (eventPayload) {
        pushDataLayer(eventPayload);
    }
};

// @flow

import CookiesManager from 'ggApp/utils/CookiesManager';
import type { CookiePayload } from './flowTypes/settingsPayload';
import { COOKIE_CONSENT_NAME } from './constants';

const ONE_YEAR_EXPIRATION_TIME_IN_SECONDS = 365 * 24 * 60 * 60;

// Flow types

/**
 * getCookieConsentValue
 * This method loads cookie consent values from cookie
 * @returns {?CookiePayload} cookiePayload
 */
export function getCookieConsentValue(): ?CookiePayload {
    const cookiesManager = new CookiesManager();
    const cookiePayload: ?CookiePayload = cookiesManager.get(COOKIE_CONSENT_NAME);

    return cookiePayload;
}

/**
 * setCookieConsentValue
 * This method sets cookie policy values to the cookie
 * Cookie expiration time is one year
 * @param {CookiePayload} cookiePayload
 */
export function setCookieConsentValue(cookiePayload: CookiePayload): void {
    const cookiesManager = new CookiesManager();
    cookiesManager.set(COOKIE_CONSENT_NAME, cookiePayload, {
        maxAge: ONE_YEAR_EXPIRATION_TIME_IN_SECONDS,
    });
}

export const formatCookieConsent = (cookieConsentState) => {
    return [
        {
            name: 'functional',
            value: cookieConsentState?.preferenceCookie ? 'YES' : 'NO',
            description: 'Functional cookies',
        },
        {
            name: 'performance',
            value: cookieConsentState?.statisticsCookie ? 'YES' : 'NO',
            description: 'Performance & Analytics cookies',
        },
        {
            name: 'profiling',
            value: cookieConsentState?.marketingCookie ? 'YES' : 'NO',
            description: 'Profiling & Targeting cookies',
        },
        {
            name: 'strict',
            value: cookieConsentState?.explicitConsent ? 'YES' : 'NO',
            description: 'Strict cookies',
        },
    ];
};

export const formatCookieConsentState = (cookieAttributes) => {
    const response = {
        explicitConsent: true,
        preferenceCookie: false,
        statisticsCookie: false,
        marketingCookie: false,
    };
    cookieAttributes.map((item) => {
        if (item.name.toLowerCase().includes('functional')) {
            response.preferenceCookie = item.value === 'YES';
        }
        if (item.name.toLowerCase().includes('performance')) {
            response.statisticsCookie = item.value === 'YES';
        }
        if (item.name.toLowerCase().includes('profiling')) {
            response.marketingCookie = item.value === 'YES';
        }
        return response;
    });
    return response;
};

import { MixDevice, SubscriptionCard } from 'ggApp/types/graphql';
import ActionTypes from './actionTypes';
import { VaultAction } from './actions';
import {
    VaultState,
    VaultStorageKeys,
    MixSubscriptionsVaultState,
    SubscriptionsVaultState,
} from './types';

export const defaultState: VaultState = {
    mixDevices: new Map(),
    subscriptions: new Map(),
    badges: new Map(),
};

function reducer(state: VaultState = defaultState, action: VaultAction): VaultState {
    switch (action.type) {
        case ActionTypes.SET_VAULT_ITEM: {
            const { key, data } = action.payload;
            const isMixDevices = key === VaultStorageKeys.mixDevices;

            const item = isMixDevices ? (data as MixDevice) : (data as SubscriptionCard);

            const prevState = isMixDevices
                ? Array.from(state[key] as MixSubscriptionsVaultState)
                : Array.from(state[key] as SubscriptionsVaultState);

            const newState = new Map(prevState);

            newState.set(item.id, item);

            return {
                ...state,
                [key]: newState,
            };
        }

        case ActionTypes.SET_VAULT_ITEMS: {
            const { key, data } = action.payload;
            const isMixDevices = key === VaultStorageKeys.mixDevices;
            const items = isMixDevices ? (data as MixDevice[]) : (data as SubscriptionCard[]);

            const prevState = isMixDevices
                ? Array.from(state[key] as MixSubscriptionsVaultState)
                : Array.from(state[key] as SubscriptionsVaultState);

            const newState = new Map(prevState);

            items.forEach((item) => {
                newState.set(item.id, item);
            });

            return {
                ...state,
                [key]: newState,
            };
        }

        case ActionTypes.SET_SHOULD_SHOW_BADGE: {
            const { id, shouldShow } = action.payload;
            const newState = new Map(Array.from(state.badges));

            newState.set(id, shouldShow);

            return {
                ...state,
                badges: newState,
            };
        }

        default:
            return state;
    }
}

export default reducer;

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ActiveStore, storeActive } from 'ggApp/modules/store/selectors';

import { TranslatorComponent_Text as Translator } from 'ggApp/modules/translator';
import { WidgetData, WidgetProduct } from '../utils/types';
import { productAdapter } from '../utils/productAdapter';
import TopProducts from './components/TopProducts';
import HandPickedProducts from './components/HandPickedProduct';
import CountdownWidget from './components/CountdownWidget';
import { ProductCarousel, CategoryWidget, SubHeadline } from './styles';

export enum ProductWidgetStyles {
    topProducts = 'topProducts',
    productCarousel = 'productCarousel',
    category = 'category',
    handpicked = 'handpicked',
    countdown = 'countdown',
}

interface ProductWidgetMapperProps {
    className: string;
    store: {
        default_currency: string;
        activeLanguage: string;
        code: string;
    };
    widgetData: WidgetData;
    productImpressionCallbacks: (firstProductIndex: number) => void;
    widgetListProducts: WidgetProduct[];
    testId?: string;
    favoriteList: number[];
    ctaViewAll?: boolean;
    alternativeTitle?: string | null;
    hideTimerWhenActualDateOutOfRange?: boolean;
    isDealsOfTheWeekWidget?: boolean;
}

const ProductWidgetMapper = ({
    className,
    store,
    widgetData,
    productImpressionCallbacks,
    widgetListProducts,
    testId,
    favoriteList,
    ctaViewAll,
    alternativeTitle,
    hideTimerWhenActualDateOutOfRange = false,
    isDealsOfTheWeekWidget = false,
}: ProductWidgetMapperProps): JSX.Element | null => {
    const { default_currency: defaultCurrency, activeLanguage } = store;
    const isProductInFavoriteList = (productId: number) => {
        return favoriteList?.includes(productId);
    };
    if (!widgetData?.products?.length || !widgetListProducts?.length) {
        return null;
    }
    const firstProductIndex = widgetListProducts.findIndex(
        (product: WidgetProduct) => product.id === widgetData.products[0].id,
    );
    const formattedProducts = widgetData?.products.map((product) =>
        productAdapter({
            product,
            defaultCurrency,
            activeLanguage,
            favorite: isProductInFavoriteList(parseInt(product.id, 10)),
        }),
    );

    const countdownWidgetProps = {
        className,
        properties: widgetData?.properties,
        products: formattedProducts,
        cta: widgetData.cta,
        testId,
        productImpressionCallbacks: productImpressionCallbacks(firstProductIndex),
        ctaViewAll,
        title: alternativeTitle ?? 'COUNTDOWN_WIDGET_TITLE',
        hideTimerWhenActualDateOutOfRange,
        isDealsOfTheWeekWidget,
    };

    switch (widgetData.style) {
        case ProductWidgetStyles.countdown:
            return <CountdownWidget {...countdownWidgetProps} />;
        case ProductWidgetStyles.topProducts:
            return (
                <>
                    <SubHeadline>
                        <Translator tk="DIVISION_LINE_FOR_YOU_FEED" />
                    </SubHeadline>
                    <TopProducts
                        className={className}
                        products={formattedProducts}
                        productImpressionCallbacks={productImpressionCallbacks(firstProductIndex)}
                        testId={testId}
                    />
                </>
            );
        case ProductWidgetStyles.productCarousel:
            return (
                <ProductCarousel
                    className={className}
                    products={formattedProducts}
                    title={alternativeTitle ?? widgetData.title}
                    cta={widgetData.cta}
                    productImpressionCallbacks={productImpressionCallbacks(firstProductIndex)}
                    firstIndexProduct={firstProductIndex}
                    testId={testId}
                    ctaViewAll={ctaViewAll}
                />
            );
        case ProductWidgetStyles.category:
            return (
                <CategoryWidget
                    className={className}
                    products={formattedProducts}
                    category={widgetData.category}
                    position={widgetData.position}
                    widgetType="horizontal"
                    cta={widgetData.cta}
                    productImpressionCallbacks={productImpressionCallbacks(firstProductIndex)}
                    firstIndexProduct={firstProductIndex}
                    testId={testId}
                    ctaViewAll={ctaViewAll}
                />
            );
        case ProductWidgetStyles.handpicked:
            return (
                <HandPickedProducts
                    className={className}
                    product={formattedProducts[0]}
                    productImpressionCallbacks={productImpressionCallbacks(firstProductIndex)}
                    firstIndexProduct={firstProductIndex}
                    testId={testId}
                    activeStore={store}
                />
            );
        default:
            return null;
    }
};

type SelectorProps = {
    locale: string;
    userId: string;
    storeActive: ActiveStore;
};

const enhance = connect<SelectorProps, {}, ProductWidgetMapperProps>(
    createStructuredSelector({
        store: storeActive,
    }),
);

export default enhance(ProductWidgetMapper);

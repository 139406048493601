import axios from 'ggApp/utils/requests';
import CookiesManager from 'ggApp/utils/CookiesManager';
import { fireEvent } from 'ggApp/modules/inwebview_api';
import { SET_COOKIES, REMOVE_ORDER_COOKIES } from 'ggApp/utils/CookiesManager/constants';

import { ORDER_NUMBER_COOKIE, ORDER_TOKEN_COOKIE } from './constants';

export function getOrderNumberCookie(storeCode) {
    return `${storeCode}_${ORDER_NUMBER_COOKIE}`;
}

export function getOrderTokenCookie(storeCode) {
    return `${storeCode}_${ORDER_TOKEN_COOKIE}`;
}

export function getOrder(storeCode, cookiesManager = new CookiesManager()) {
    let orderNumber = cookiesManager.get(getOrderNumberCookie(storeCode));
    let orderToken = cookiesManager.get(getOrderTokenCookie(storeCode));

    // Failed to load order number from the store-based cookie
    // fallback to the legacy cookie name
    // also here we return undefined for order cookies when orderNumber is "null" -
    // this way we let the app know that there is no order saved in cookies
    if (!orderNumber || orderNumber === 'null') {
        orderNumber = cookiesManager.get(ORDER_NUMBER_COOKIE);
        orderToken = cookiesManager.get(ORDER_TOKEN_COOKIE);

        if (orderNumber && orderToken) {
            return {
                orderNumber,
                orderToken,
            };
        }

        // There is no order saved in cookies
        return {
            orderNumber: undefined,
            orderToken: undefined,
        };
    }

    return {
        orderNumber,
        orderToken,
    };
}

export function saveOrder(
    storeCode,
    orderNumber,
    orderToken,
    cookiesManager = new CookiesManager(),
) {
    const orderNumberKey = getOrderNumberCookie(storeCode);
    const orderTokenKey = getOrderTokenCookie(storeCode);

    cookiesManager.set(orderNumberKey, orderNumber);

    if (orderToken) {
        cookiesManager.set(orderTokenKey, orderToken);
    }

    // some code still relies on an Axios instance default headers
    // being set for each request. Can be deleted, once all order-related
    // requests will be either implicitly setting headers themselfs,
    // or relying on the Axios instance interceptor
    if (typeof orderNumber === 'string' && typeof orderToken === 'string') {
        axios.defaults.headers['X-Grover-Order-Number'] = orderNumber;
        axios.defaults.headers['X-Grover-Order-Token'] = orderToken;
    }

    if (window.ReactNativeWebView) {
        fireEvent(SET_COOKIES, {
            action: {
                [orderNumberKey]: orderNumber,
                [orderTokenKey]: orderToken,
            },
        });
    }
}

export function deleteOrder(storeCode) {
    const cookiesManager = new CookiesManager();
    const orderNumberKey = getOrderNumberCookie(storeCode);
    const orderTokenKey = getOrderTokenCookie(storeCode);
    cookiesManager.remove(orderNumberKey, { path: '/' });
    cookiesManager.remove(orderTokenKey, { path: '/' });

    // also clear order information,
    // if it was store in old cookie names
    cookiesManager.remove(ORDER_NUMBER_COOKIE, { path: '/' });
    cookiesManager.remove(ORDER_TOKEN_COOKIE, { path: '/' });

    // some code still relies on an Axios instance default headers
    // being set for each request. Can be deleted, once all order-related
    // requests will be either implicitly setting headers themselfs,
    // or relying on the Axios instance interceptor
    delete axios.defaults.headers['X-Grover-Order-Number'];
    delete axios.defaults.headers['X-Grover-Order-Token'];

    if (window.ReactNativeWebView) {
        fireEvent(REMOVE_ORDER_COOKIES, {
            action: [orderNumberKey, orderTokenKey],
        });
    }
}

import styled, { css } from 'styled-components';

const Chip = styled.div<{ $textColor: string; $backgroundColor: string }>`
    position: relative;
    border-radius: 24px;
    display: inline-block;
    vertical-align: top;
    margin-right: ${({ theme }) => theme.spacers.S};
    background: ${({ $backgroundColor }) => $backgroundColor};
    color: ${({ $textColor }) => $textColor};
`;

const ChipTypography = styled.span`
    margin: 3px 6px;
    ${({ theme }) => css(theme.typography.SmallCapsHeadline)};
    font-size: 8.5px;
    line-height: 10px;
    display: flex;
`;

export { Chip, ChipTypography };

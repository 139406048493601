/* eslint-disable */
import { createSelector } from 'reselect';
import objectPath from 'object-path';

import {
    MODULE_NAME,
    INLINE_ACCESS_TOKEN_QUERY_PARAM,
    INLINE_ORDER_NUMBER_QUERY_PARAM,
} from '../constants';

export const moduleState = state => state[MODULE_NAME] || {};

export const params = createSelector(
    moduleState,
    m => m.params || {},
);

export const storeParam = createSelector(
    params,
    p => p.store || 'de',
);

export const langParam = createSelector(
    params,
    p => p.language || '',
);

export const location = createSelector(
    moduleState,
    m => m.location || {},
);

export const locationQuery = createSelector(
    location,
    l => (l && l.query) || {},
);

export const locationQueryToken = createSelector(
    locationQuery,
    query => query.token || null,
);

export const locationSearch = createSelector(
    location,
    l => (l && l.search) || '',
);

export const locationPathname = createSelector(
    location,
    l => (l && l.pathname) || '',
);

export const locationState = createSelector(
    location,
    l => (l && l.state) || {},
);

export const routes = createSelector(
    moduleState,
    m => (m && m.routes && m.routes.length ? m.routes : []),
);

export const routeProps = createSelector(
    moduleState,
    m => (m && m.routes && m.routes.length ? m.routes[m.routes.length - 1] : {}),
);

export const storeCode = state => objectPath.get(state.router.params.store);

export const inlineAccessToken = createSelector(
    locationQuery,
    query => query[INLINE_ACCESS_TOKEN_QUERY_PARAM],
);
export const inlineOrderNumber = createSelector(
    locationQuery,
    query => query[INLINE_ORDER_NUMBER_QUERY_PARAM],
);

export const overlayIsOpen = createSelector(
    locationQuery,
    query => Boolean(query.overlay),
);

import { combineReducers } from 'redux';

import fetching from './fetching';
import active from './active';
import error from './error';
import list from './list';
import singleOrder from './singleOrder';
import unavailableSkuList from './unavailableSkuList';

export default combineReducers({
    fetching,
    active,
    error,
    list,
    singleOrder,
    unavailableSkuList,
});

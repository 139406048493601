import React from 'react';
import memoize from 'fast-memoize';

import { extractCallbacksFromProps } from 'ggApp/modules/tracking/hocs/withListEventHandlers';
import rgba from 'ggApp/utils/color/rgba';
import { PureTranslator } from 'ggApp/modules/translator/components';

import { Container, Header, Title, Wrap, WidgetProductCardComponent, Cta } from './styled';

class CategoryWidget extends React.PureComponent {
    handleClickOnProduct = memoize((index, position) => () => {
        const { onClickProduct } = this.props;
        onClickProduct(index, position);
    });

    getCardType() {
        const { widgetType, userDevice } = this.props;
        if (widgetType === 'vertical' && userDevice === 'mobile') return 'horizontal';
        return widgetType;
    }

    getWidgetStyles = () => {
        const { accentColor } = this.props;
        if (!accentColor) return {};
        return {
            backgroundColor: rgba(accentColor, 0.15),
            textColor: accentColor,
        };
    };

    getCategoryInfo = () => {
        const { category } = this.props;
        if (!category) {
            return {
                iconPath: null,
                name: null,
            };
        }
        return {
            iconPath: category.iconSvgPath,
            name: category.title,
        };
    };

    render() {
        const {
            className,
            products,
            cardStyle,
            cta,
            productImpressionCallbacks,
            onClickCTA,
            widgetType,
            testId,
            position,
        } = this.props;
        const cardType = this.getCardType();
        const { name } = this.getCategoryInfo();
        return (
            <Container className={className} data-testid={testId}>
                <Header>{name && <Title>{name}</Title>}</Header>
                <Wrap vertical={widgetType === 'vertical'}>
                    {(products || []).map((product, index) => (
                        <WidgetProductCardComponent
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${product?.slug} - ${index}`}
                            product={product}
                            onClick={this.handleClickOnProduct(index, position ?? 0)}
                            withAccentColorText={false}
                            cardType={cardType}
                            cardStyle={cardStyle}
                            {...extractCallbacksFromProps(productImpressionCallbacks, index)}
                        />
                    ))}
                </Wrap>
                {cta && (
                    <Cta
                        external={!cta.internal}
                        target="_self"
                        linkTo={cta.url}
                        text={<PureTranslator tk={cta.label} />}
                        onClick={onClickCTA}
                    />
                )}
            </Container>
        );
    }
}

export default CategoryWidget;

CategoryWidget.defaultProps = {
    accentColor: null,
    className: null,
    category: {},
    onClickProduct: () => null,
};

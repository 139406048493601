import styled from 'styled-components';

export const CountdownWrapper = styled.div<{ isPDP?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: ${({ isPDP }) => (isPDP ? 'center' : 'flex-start')};
    margin: ${({ theme, isPDP }) => (isPDP ? '16px 0' : `0 0 ${theme.spacers.L}`)};

    ${({ isPDP, theme }) => theme.superQuery.all.and.minWidth.tabletMin.css`
        margin: ${isPDP ? 0 : `0 0 ${theme.spacers.L}`};
        justify-content: flex-end;
    `};
`;

export const CountdownText = styled.p<{ isPDP?: boolean }>`
    text-transform: ${({ isPDP }) => (isPDP ? 'none' : 'uppercase')};
    font-weight: ${({ isPDP }) => (isPDP ? '400' : '800')};
    color: ${({ isPDP, theme }) => (isPDP ? theme.colors.Foreground : theme.colors.Color1)};
    margin-right: 8px;
    ${({ isPDP, theme }) => (isPDP ? theme.typography.Paragraph : theme.typography.Subheadline)};
`;

export const CountdownTime = styled.div`
    font-feature-settings: 'tnum';
    ${({ theme }) => theme.typography.Subheadline};
    font-weight: 800;
    color: ${({ theme }) => theme.colors.Color1};
`;

import {
    GET_CONTENTFUL_PAGE,
    GET_CONTENTFUL_PAGE_SUCCESS,
    GET_CONTENTFUL_PAGE_ERROR,
} from '../constants';

const initialState = false;

export function loading(state = initialState, action) {
    const { type } = action;

    switch (type) {
        case GET_CONTENTFUL_PAGE:
            return true;

        case GET_CONTENTFUL_PAGE_SUCCESS:
        case GET_CONTENTFUL_PAGE_ERROR:
            return false;

        default:
            return state;
    }
}

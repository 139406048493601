/* eslint-disable */
/**
 * Creates a function that is restricted to invoking `func` once.
 * @param {function} fn Function which should be call only one time
 * @example
 * @returns function which can be called onky one time
 */
export default function callOnce(fn) {
    let executed = false;
    return (...args) => {
        if (!executed) {
            executed = true;
            fn(...args);
        }
    }
}

import styled from 'styled-components';
import { Modal } from 'ggApp/modules/modal/components/v3';

const MODAL_CONTAINER_CLASS_NAME = 'ReturnModalContainer';
const MODAL_CONTENT_CLASS_NAME = 'ReturnModalContent';


const StyledModal = styled(Modal).attrs({
    classNames: {
        content: MODAL_CONTENT_CLASS_NAME,
        container: MODAL_CONTAINER_CLASS_NAME,
    },
})`
    background: rgba(255,255,255, 0) !important;
    & .${MODAL_CONTAINER_CLASS_NAME} {
        background: none;
        box-shadow: none;
    }
`;

export default StyledModal;

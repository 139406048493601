/* eslint-disable */
import { useEffect } from 'react';
import axios from 'ggApp/utils/requests';
import { getOrder } from 'ggApp/modules/order/storage';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ggStore from 'ggApp/modules/store';

function DefaultHeadersSetter(props) {
    const { activeStore: { code } } = props;
    useEffect(() => {
        const { orderNumber, orderToken } = getOrder(code);

        if (orderNumber) {
            axios.defaults.headers['X-Grover-Order-Number'] = orderNumber;
        }
        if (orderToken) {
            axios.defaults.headers['X-Grover-Order-Token'] = orderToken;
        }
    }, [code, props.location.pathname]);
    return props.children;
}

export default connect(
    createStructuredSelector({
        activeStore: ggStore.selectors.storeActive,
    }),
)(DefaultHeadersSetter);

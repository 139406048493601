/* eslint-disable radix */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
// @flow

function numberFormat(decimals, decPoint, thousandsSep, number) {
    decimals = decimals || 0;
    number = parseFloat(number);

    if (!decPoint) {
        decPoint = ',';
    }
    if (!thousandsSep) {
        thousandsSep = '.';
    }

    if (isNaN(number) || !isFinite(number)) {
        number = 0;
    }

    let roundedNumber = Math.round(Math.abs(number) * Math.pow(10, decimals)).toString();
    // add zeros to decimalString if number of decimals indicates it
    roundedNumber =
        number < 1 && number > -1 && roundedNumber.length <= decimals
            ? Array(decimals - roundedNumber.length + 1).join('0') + roundedNumber
            : roundedNumber;
    let numbersString = decimals ? roundedNumber.slice(0, decimals * -1) : roundedNumber.slice(0);
    const checknull = parseInt(numbersString) || 0;

    // check if the value is less than one to prepend a 0
    numbersString = checknull === 0 ? '0' : numbersString;
    const decimalsString = decimals ? roundedNumber.slice(decimals * -1) : '';

    let formattedNumber = '';
    while (numbersString.length > 3) {
        formattedNumber = thousandsSep + numbersString.slice(-3) + formattedNumber;
        numbersString = numbersString.slice(0, -3);
    }

    return (
        (number < 0 ? '-' : '') +
        numbersString +
        formattedNumber +
        (decimalsString ? decPoint + decimalsString : '')
    );
}

export function format(
    number: number | null,
    decPoint: string | null,
    thouSep: string | null,
): string {
    if (typeof number !== 'number') return '-';
    if (isNaN(number) || !isFinite(number)) {
        return '-';
    }
    const price = number / 100;
    return numberFormat(2, decPoint, thouSep, price);
}

export function formatIfNumber(
    input: string | number,
    decPoint: string | null,
    thouSep: string | null,
): string {
    return typeof input === 'number' ? format(input, decPoint, thouSep) : input;
}

const dotRegExp = /\./;

export function roundTo90(number: number | null): string {
    if (typeof number !== 'number') return '-';
    if (isNaN(number) || !isFinite(number)) {
        return '-';
    }
    const price = number / 100;
    return (Math.max(Math.round(price), 1) - 0.1).toFixed(2).replace(dotRegExp, ',');
}

export function formatPrice(
    price: string,
    currencySymbol: string = '€',
    storeCode: string,
): string {
    switch (storeCode) {
        case 'de':
        case 'usa':
        case 'uk':
            return `${currencySymbol}${price}`;
        default:
            return `${price}${currencySymbol}`;
    }
}

export function formatPriceByLanguage(
    price: string | number,
    currencySymbol: string = '€',
    activeLanguage: string,
): string {
    if (typeof price !== 'number' && typeof price !== 'string') {
        return undefined;
    }

    switch (activeLanguage) {
        case 'en':
            return `${currencySymbol}${formatIfNumber(price, '.', ',')}`;
        case 'de':
            return `${formatIfNumber(price, ',', '.')} ${currencySymbol}`;
        default:
            return `${formatIfNumber(price, ',', '.')} ${currencySymbol}`;
    }
}

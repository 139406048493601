import React from 'react';
import PropTypes from 'prop-types';

import Link from 'ggApp/shared/containers/FrontshopLink';
import ReviewIOLogo from 'ggApp/shared/icons/ReviewIOLogo';
import Stars from 'ggApp/shared/icons/Stars';
import { useTrustedScore } from './useTrustedScore';
import { scoreToPercentage } from './utils';
import { Container, Score } from './styles';

export const ReviewIO = ({ className, withLink, noStars, color }) => {
    const { trustedScore } = useTrustedScore();

    const percentage = scoreToPercentage(trustedScore);
    return (
        <Container className={className} data-testid="review-io" noStars={noStars}>
            {withLink ? (
                <Link
                    to="https://www.reviews.io/company-reviews/store/grover"
                    external
                    target="_blank"
                >
                    <ReviewIOLogo color={color} />
                </Link>
            ) : (
                <ReviewIOLogo color={color} />
            )}
            {!noStars && <Stars percentage={percentage} />}
            <Score>{trustedScore} / 5</Score>
        </Container>
    );
};

ReviewIO.propTypes = {
    className: PropTypes.string,
    withLink: PropTypes.bool,
};

ReviewIO.defaultProps = {
    className: '',
    withLink: false,
};

/* eslint-disable */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-bitwise */
function utf8Encode(argString) {
    if (argString === null || typeof argString === 'undefined') {
        return '';
    }

    const string = `${argString}`; // .replace(/\r\n/g, "\n").replace(/\r/g, "\n");
    let utftext = '';
    let start = 0;
    let end = 0;
    const stringl = string.length;
    for (let n = 0; n < stringl; n += 1) {
        let c1 = string.charCodeAt(n);
        let enc = null;

        if (c1 < 128) {
            end += 1;
        } else if (c1 > 127 && c1 < 2048) {
            enc = String.fromCharCode((c1 >> 6) | 192, (c1 & 63) | 128);
        } else if ((c1 & 0xf800) !== 0xd800) {
            enc = String.fromCharCode(
                (c1 >> 12) | 224,
                ((c1 >> 6) & 63) | 128,
                (c1 & 63) | 128,
            );
        } else {
            // surrogate pairs
            if ((c1 & 0xfc00) !== 0xd800) {
                throw new RangeError(`Unmatched trail surrogate at ${n}`);
            }
            const c2 = string.charCodeAt(n += 1);
            if ((c2 & 0xfc00) !== 0xdc00) {
                throw new RangeError(`Unmatched lead surrogate at ${n - 1}`);
            }
            c1 = ((c1 & 0x3ff) << 10) + (c2 & 0x3ff) + 0x10000;
            enc = String.fromCharCode(
                (c1 >> 18) | 240,
                ((c1 >> 12) & 63) | 128,
                ((c1 >> 6) & 63) | 128,
                (c1 & 63) | 128,
            );
        }
        if (enc !== null) {
            if (end > start) {
                utftext += string.slice(start, end);
            }
            utftext += enc;
            start = n + 1;
            end = n + 1;
        }
    }

    if (end > start) {
        utftext += string.slice(start, stringl);
    }

    return utftext;
}

export function md5Hasher(string = '') {
    if (!string) return null;
    let str = string.toLowerCase();

    const rotateLeft = function rotateLeft(lValue, iShiftBits) {
        return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
    };

    const addUnsigned = function addUnsigned(lX, lY) {
        const lX8 = lX & 0x80000000;
        const lY8 = lY & 0x80000000;
        const lX4 = lX & 0x40000000;
        const lY4 = lY & 0x40000000;
        const lResult = (lX & 0x3fffffff) + (lY & 0x3fffffff);
        if (lX4 & lY4) {
            return lResult ^ 0x80000000 ^ lX8 ^ lY8;
        }
        if (lX4 | lY4) {
            if (lResult & 0x40000000) {
                return lResult ^ 0xc0000000 ^ lX8 ^ lY8;
            }
            return lResult ^ 0x40000000 ^ lX8 ^ lY8;
        }
        return lResult ^ lX8 ^ lY8;
    };

    const fFunc = function fFunc(x, y, z) {
        return (x & y) | (~x & z);
    };
    const gFunc = function gFunc(x, y, z) {
        return (x & z) | (y & ~z);
    };
    const hFunc = function hFunc(x, y, z) {
        return x ^ y ^ z;
    };
    const iFunc = function iFunc(x, y, z) {
        return y ^ (x | ~z);
    };

    const ffFunc = function ffFunc(a, b, c, d, x, s, ac) {
        let aParam = a;
        aParam = addUnsigned(aParam, addUnsigned(addUnsigned(fFunc(b, c, d), x), ac));
        return addUnsigned(rotateLeft(aParam, s), b);
    };

    const ggFunc = function ggFunc(a, b, c, d, x, s, ac) {
        let aParam = a;
        aParam = addUnsigned(aParam, addUnsigned(addUnsigned(gFunc(b, c, d), x), ac));
        return addUnsigned(rotateLeft(aParam, s), b);
    };

    const hhFunc = function hhFunc(a, b, c, d, x, s, ac) {
        let aParam = a;
        aParam = addUnsigned(aParam, addUnsigned(addUnsigned(hFunc(b, c, d), x), ac));
        return addUnsigned(rotateLeft(aParam, s), b);
    };

    const iiFunc = function iiFunc(a, b, c, d, x, s, ac) {
        let aParam = a;
        aParam = addUnsigned(aParam, addUnsigned(addUnsigned(iFunc(b, c, d), x), ac));
        return addUnsigned(rotateLeft(aParam, s), b);
    };

    const convertToWordArray = function convertToWordArray(str) {
        let lWordCount;
        const lMessageLength = str.length;
        const lNumberOfWordsTemp1 = lMessageLength + 8;
        const lNumberOfWordsTemp2 = (lNumberOfWordsTemp1 - (lNumberOfWordsTemp1 % 64)) / 64;
        const lNumberOfWords = (lNumberOfWordsTemp2 + 1) * 16;
        const lWordArray = new Array(lNumberOfWords - 1);
        let lBytePosition = 0;
        let lByteCount = 0;
        while (lByteCount < lMessageLength) {
            lWordCount = (lByteCount - (lByteCount % 4)) / 4;
            lBytePosition = (lByteCount % 4) * 8;
            lWordArray[lWordCount] |= (str.charCodeAt(lByteCount) << lBytePosition);
            lByteCount += 1;
        }
        lWordCount = (lByteCount - (lByteCount % 4)) / 4;
        lBytePosition = (lByteCount % 4) * 8;
        lWordArray[lWordCount] |= (0x80 << lBytePosition);
        lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
        lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
        return lWordArray;
    };

    const wordToHex = function wordToHex(lValue) {
        let wordToHexValue = '';
        let wordToHexValueTemp = '';
        let lByte;
        let lCount;
        for (lCount = 0; lCount <= 3; lCount += 1) {
            lByte = (lValue >>> (lCount * 8)) & 255;
            wordToHexValueTemp = `0${lByte.toString(16)}`;
            wordToHexValue
                += wordToHexValueTemp.substr(wordToHexValueTemp.length - 2, 2);
        }
        return wordToHexValue;
    };

    let x = [];
    let k;
    let AA;
    let BB;
    let CC;
    let DD;
    let a;
    let b;
    let c;
    let d;
    const S11 = 7;
    const S12 = 12;
    const S13 = 17;
    const S14 = 22;
    const S21 = 5;
    const S22 = 9;
    const S23 = 14;
    const S24 = 20;
    const S31 = 4;
    const S32 = 11;
    const S33 = 16;
    const S34 = 23;
    const S41 = 6;
    const S42 = 10;
    const S43 = 15;
    const S44 = 21;

    str = utf8Encode(str);
    x = convertToWordArray(str);
    a = 0x67452301;
    b = 0xefcdab89;
    c = 0x98badcfe;
    d = 0x10325476;

    const xl = x.length;
    for (k = 0; k < xl; k += 16) {
        AA = a;
        BB = b;
        CC = c;
        DD = d;
        a = ffFunc(a, b, c, d, x[k + 0], S11, 0xd76aa478);
        d = ffFunc(d, a, b, c, x[k + 1], S12, 0xe8c7b756);
        c = ffFunc(c, d, a, b, x[k + 2], S13, 0x242070db);
        b = ffFunc(b, c, d, a, x[k + 3], S14, 0xc1bdceee);
        a = ffFunc(a, b, c, d, x[k + 4], S11, 0xf57c0faf);
        d = ffFunc(d, a, b, c, x[k + 5], S12, 0x4787c62a);
        c = ffFunc(c, d, a, b, x[k + 6], S13, 0xa8304613);
        b = ffFunc(b, c, d, a, x[k + 7], S14, 0xfd469501);
        a = ffFunc(a, b, c, d, x[k + 8], S11, 0x698098d8);
        d = ffFunc(d, a, b, c, x[k + 9], S12, 0x8b44f7af);
        c = ffFunc(c, d, a, b, x[k + 10], S13, 0xffff5bb1);
        b = ffFunc(b, c, d, a, x[k + 11], S14, 0x895cd7be);
        a = ffFunc(a, b, c, d, x[k + 12], S11, 0x6b901122);
        d = ffFunc(d, a, b, c, x[k + 13], S12, 0xfd987193);
        c = ffFunc(c, d, a, b, x[k + 14], S13, 0xa679438e);
        b = ffFunc(b, c, d, a, x[k + 15], S14, 0x49b40821);
        a = ggFunc(a, b, c, d, x[k + 1], S21, 0xf61e2562);
        d = ggFunc(d, a, b, c, x[k + 6], S22, 0xc040b340);
        c = ggFunc(c, d, a, b, x[k + 11], S23, 0x265e5a51);
        b = ggFunc(b, c, d, a, x[k + 0], S24, 0xe9b6c7aa);
        a = ggFunc(a, b, c, d, x[k + 5], S21, 0xd62f105d);
        d = ggFunc(d, a, b, c, x[k + 10], S22, 0x2441453);
        c = ggFunc(c, d, a, b, x[k + 15], S23, 0xd8a1e681);
        b = ggFunc(b, c, d, a, x[k + 4], S24, 0xe7d3fbc8);
        a = ggFunc(a, b, c, d, x[k + 9], S21, 0x21e1cde6);
        d = ggFunc(d, a, b, c, x[k + 14], S22, 0xc33707d6);
        c = ggFunc(c, d, a, b, x[k + 3], S23, 0xf4d50d87);
        b = ggFunc(b, c, d, a, x[k + 8], S24, 0x455a14ed);
        a = ggFunc(a, b, c, d, x[k + 13], S21, 0xa9e3e905);
        d = ggFunc(d, a, b, c, x[k + 2], S22, 0xfcefa3f8);
        c = ggFunc(c, d, a, b, x[k + 7], S23, 0x676f02d9);
        b = ggFunc(b, c, d, a, x[k + 12], S24, 0x8d2a4c8a);
        a = hhFunc(a, b, c, d, x[k + 5], S31, 0xfffa3942);
        d = hhFunc(d, a, b, c, x[k + 8], S32, 0x8771f681);
        c = hhFunc(c, d, a, b, x[k + 11], S33, 0x6d9d6122);
        b = hhFunc(b, c, d, a, x[k + 14], S34, 0xfde5380c);
        a = hhFunc(a, b, c, d, x[k + 1], S31, 0xa4beea44);
        d = hhFunc(d, a, b, c, x[k + 4], S32, 0x4bdecfa9);
        c = hhFunc(c, d, a, b, x[k + 7], S33, 0xf6bb4b60);
        b = hhFunc(b, c, d, a, x[k + 10], S34, 0xbebfbc70);
        a = hhFunc(a, b, c, d, x[k + 13], S31, 0x289b7ec6);
        d = hhFunc(d, a, b, c, x[k + 0], S32, 0xeaa127fa);
        c = hhFunc(c, d, a, b, x[k + 3], S33, 0xd4ef3085);
        b = hhFunc(b, c, d, a, x[k + 6], S34, 0x4881d05);
        a = hhFunc(a, b, c, d, x[k + 9], S31, 0xd9d4d039);
        d = hhFunc(d, a, b, c, x[k + 12], S32, 0xe6db99e5);
        c = hhFunc(c, d, a, b, x[k + 15], S33, 0x1fa27cf8);
        b = hhFunc(b, c, d, a, x[k + 2], S34, 0xc4ac5665);
        a = iiFunc(a, b, c, d, x[k + 0], S41, 0xf4292244);
        d = iiFunc(d, a, b, c, x[k + 7], S42, 0x432aff97);
        c = iiFunc(c, d, a, b, x[k + 14], S43, 0xab9423a7);
        b = iiFunc(b, c, d, a, x[k + 5], S44, 0xfc93a039);
        a = iiFunc(a, b, c, d, x[k + 12], S41, 0x655b59c3);
        d = iiFunc(d, a, b, c, x[k + 3], S42, 0x8f0ccc92);
        c = iiFunc(c, d, a, b, x[k + 10], S43, 0xffeff47d);
        b = iiFunc(b, c, d, a, x[k + 1], S44, 0x85845dd1);
        a = iiFunc(a, b, c, d, x[k + 8], S41, 0x6fa87e4f);
        d = iiFunc(d, a, b, c, x[k + 15], S42, 0xfe2ce6e0);
        c = iiFunc(c, d, a, b, x[k + 6], S43, 0xa3014314);
        b = iiFunc(b, c, d, a, x[k + 13], S44, 0x4e0811a1);
        a = iiFunc(a, b, c, d, x[k + 4], S41, 0xf7537e82);
        d = iiFunc(d, a, b, c, x[k + 11], S42, 0xbd3af235);
        c = iiFunc(c, d, a, b, x[k + 2], S43, 0x2ad7d2bb);
        b = iiFunc(b, c, d, a, x[k + 9], S44, 0xeb86d391);
        a = addUnsigned(a, AA);
        b = addUnsigned(b, BB);
        c = addUnsigned(c, CC);
        d = addUnsigned(d, DD);
    }

    const temp = wordToHex(a) + wordToHex(b) + wordToHex(c) + wordToHex(d);

    return temp.toLowerCase();
}

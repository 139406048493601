import React from 'react';

const ModalWrapComponent = ({ route, children }) => (
    <section>
        {route.modalRoutes}
        <section className="ggModalRest">{children}</section>
    </section>
);

export default ModalWrapComponent;

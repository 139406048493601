/* eslint-disable */
import {
    GET_PRODUCTS_SUCCESS,
} from './../actionTypes';

const defaultState = {
  current_page: null,
  next_page: null,
  prev_page: null,
  total_pages: null,
};

export default function (state = defaultState, action) {
  switch (action.type) {

    case GET_PRODUCTS_SUCCESS:
      return Object.assign({}, state, action.data.pagination);

    default:
      return state;
  }
}

/* eslint-disable */
import Bowser from 'bowser';
import throttle from 'ggApp/utils/throttle';
import breakpoints from './breakpoints';

class Observer {
    constructor(userAgent) {
        if (userAgent) {
            const result = Bowser.getParser(userAgent);
            this.currentDevice = result.getPlatformType();
        }
    }

    /**
     * callback
     * @description Store callback
     * @function _callback
     * @property
     * @private
     */
    _callback = () => void(0);
    /**
     * devices
     * @description Device list
     * @property
     */
    get devices() {
        return Object.keys(breakpoints);
    }

    /**
     * getCurrentDevice
     * @description get current device name
     * @property
     * @example {@lang javascript}
     * const observer = new Observer();
     * const device = observer.getCurrentDevice;
     */
    get getCurrentDevice() {
        if (window && window.matchMedia) {
            return this.devices.find(device => {
                const { min, max, } = breakpoints[device];
                return this.queryViewPort(min, max);
            });
        }
        if (this.currentDevice) {
            const device = this.devices.find(device => {
                return this.currentDevice === device;
            });
            return device
        }
        return this.devices[this.devices.length - 1];
    }

    /**
     * queryViewPort
     * @description query min and max width against current viewport
     * @function queryViewPort
     * @example {@lang javascript}
     * const observer = new Observer();
     * const matchesCriteria = observer.queryViewPort(0, 320);
     * @param {number} minWidth - Minimum device width
     * @param {number} maxWidth - Maximum device width
     * @return {boolean}
     */
    queryViewPort(minWidth, maxWidth) {
        const query = `(min-width: ${minWidth}px)` + (!maxWidth ? '' : ` and (max-width: ${maxWidth}px)`);
        return window.matchMedia(query).matches;
    }

    /**
     * triggerCallback
     * @description throttled function to handle callback requests
     * @property
     * @example {@lang javascript}
     * const observer = new Observer();
     * observer.triggerCallback((device) => console.log(device));
     * @return {function} throttled function
     */
    triggerCallback = throttle(() => this._callback(this.getCurrentDevice), 100);

    /**
     * onViewPortChange
     * @description notifies when the current viewport parameters changes
     * @function
     * @example {@lang javascript}
     * const observer = new Observer();
     * observer.onViewPortChange(function(device) { console.log(device); });
     * @return {function}
     */
    onViewPortChange(callback) {
        this._callback = callback;
        window.addEventListener('resize', this.triggerCallback);
    }

    /**
     * reset
     * @description reset Observer
     * @function
     * @example {@lang javascript}
     * Observer.reset(function(device) { console.log(device); });
     * @return {function}
     */
    reset() {
        window.removeEventListener('resize', this.triggerCallback);
    }
}

export default Observer;

/* eslint-disable */
import React, { Component, } from 'react';
import Helmet from 'react-helmet';

class TouchIconLinks extends Component {
    render() {
        return (
            <Helmet>
                <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
                <link rel="apple-touch-icon" sizes="57x57" href="/apple-touch-icon-57x57.png" />
                <link rel="apple-touch-icon" sizes="60x60" href="/apple-touch-icon-60x60.png" />
                <link rel="apple-touch-icon" sizes="72x72" href="/apple-touch-icon-72x72.png" />
                <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon-76x76.png" />
                <link rel="apple-touch-icon" sizes="114x114" href="/apple-touch-icon-114x114.png" />
                <link rel="apple-touch-icon" sizes="120x120" href="/apple-touch-icon-120x120.png" />
                <link rel="apple-touch-icon" sizes="144x144" href="/apple-touch-icon-144x144.png" />
                <link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon-152x152.png" />
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon-180x180.png" />
                <link rel="icon" type="image/png" href="/favicon-16x16.png" sizes="16x16" />
                <link rel="icon" type="image/png" href="/favicon-32x32.png" sizes="32x32" />
                <link rel="icon" type="image/png" href="/favicon-64x64.png" sizes="64x64" />
                <link rel="icon" type="image/png" href="/android-chrome-192x192.png" sizes="192x192" />
                <meta name="msapplication-square70x70logo" content="/smalltile.png" />
                <meta name="msapplication-square150x150logo" content="/mediumtile.png" />
                <meta name="msapplication-wide310x150logo" content="/widetile.png" />
                <meta name="msapplication-square310x310logo" content="/largetile.png" />
            </Helmet>
        );
    }
}

export { TouchIconLinks, };

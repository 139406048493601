import { DEFAULT_PRODUCT_CONDITION } from 'ggApp/modules/product/constants';

export function rentalPlanMapper(plan) {
    if (!plan) return undefined;
    return {
        minimumMonths: plan.minimum_term_months,
        planId: plan.id,
        price: plan.rental_plan_price_cents,
        oldPrice: plan.old_price,
        buyout: plan.buyout && {
            monthsToOwn: plan.buyout.months_to_own && {
                monthsAfterRrp: plan.buyout.months_to_own.months_after_rrp,
                monthsRequired: plan.buyout.months_to_own.months_required,
                straightPrice: plan.buyout.months_to_own.straight_price_cents,
                marketPrice: plan.buyout.months_to_own.market_price_cents,
            },
        },
        formattedFallbackPrice: plan.rental_plan_price,
        label: 'rentalPlanMapper',
        condition: plan.item_condition_name || DEFAULT_PRODUCT_CONDITION,
    };
}

export function rentalPlanAdapter({ selectedMinDuration, rentalPlans, cheapestRentalPlan }) {
    let rentalPlan = cheapestRentalPlan;

    if (selectedMinDuration && Array.isArray(rentalPlans)) {
        const filteredRentalPlan = rentalPlans.find(
            (el) => el.minimum_term_months === selectedMinDuration,
        );

        if (filteredRentalPlan) {
            rentalPlan = filteredRentalPlan;
        }
    }

    return rentalPlanMapper(rentalPlan, 'Month', {});
}

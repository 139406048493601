import styled, { css } from 'styled-components';

import { Paragraph } from 'ggApp/shared/common/Typography';

const textLineHeight = 1.4;
const textNumberOfLinesDesktop = 2 * textLineHeight;
const textNumberOfLinesMobile = 4 * textLineHeight;

const animateInTimingFunction = css`
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
`;
const animateOutTimingFunction = css`
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
`;

const hiddenState = css`
    opacity: 0;
    transform: scale(0.9);
`;

const shownState = css`
    opacity: 1;
    transform: scale(1);
`;

const Animation = css`
    transition-property: all;
    transition-duration: ${(props) => props.animationDuration || 200}ms;

    ${hiddenState};
    opacity: ${(props) => (props.backgroundColor ? 1 : 0)};

    &.snackbar-enter-active {
        ${animateInTimingFunction}
        ${shownState}
    }

    &.snackbar-enter-done {
        ${shownState}
    }

    &.snackbar-exit-active {
        ${animateOutTimingFunction}
        ${hiddenState}
    }

    &.snackbar-exit-done {
        ${hiddenState}
    }
`;

export const Container = styled.div`
    min-height: 64px;
    padding: 16px 16px 16px 24px;
    margin: 8px;
    border-radius: 8px;
    background-color: ${({ isDarkMode, backgroundColor }) =>
        backgroundColor || (isDarkMode && 'rgba(255,255,255,0.95)') || 'rgba(51,51,51,0.95)'};
    overflow: hidden;
    display: flex;
    align-items: ${({ realign }) => (realign ? 'flex-start' : 'center')};
    ${(props) => props.theme.superQuery.all.and.maxWidth.mobileMax.css`
        padding: 16px;
    `};
    ${Animation};
`;

export const Text = styled(Paragraph)`
    flex-grow: 1;
    line-height: ${textLineHeight}em !important;
    max-height: ${textNumberOfLinesDesktop}em;
    overflow: hidden;
    color: ${({ theme, textColor }) => textColor || theme.colors.Background};
    ${(props) => props.theme.superQuery.all.and.maxWidth.mobileMax.css`
        max-height: ${textNumberOfLinesMobile}em;
    `}
`;

export const ActionsContainer = styled.div`
    margin-left: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ${(props) => props.theme.superQuery.all.and.maxWidth.mobileMax.css`
        margin-left: 0;
        margin-top: 16px;
        justify-content: flex-start;
    `}
`;

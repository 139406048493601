import React from 'react';
import { withTheme } from 'styled-components';
import { IconPropTypes } from 'ggApp/types/propTypes';

const TwitterIcon = ({ className, size, color, theme }) => {
    const fill = color || theme.colors.Foreground;
    return (
        <svg
            className={className}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.30455 3.87305L5.95288 5.08326C7.48528 7.94374 9.67305 8.82129 11.0357 8.99412V8.48206C11.0357 6.00664 13.0424 3.99992 15.5178 3.99992C17.9932 3.99992 20 6.00664 20 8.48206V9.99992C20 16.6287 15.3005 20.9999 9.89283 20.9999C7.51633 20.9999 5.83757 20.2246 4.74403 19.4146C4.20234 19.0133 3.81106 18.6089 3.55073 18.2974C3.42041 18.1415 3.32225 18.0081 3.25385 17.9087C3.21962 17.8591 3.19276 17.8178 3.17297 17.7864C3.16308 17.7707 3.15494 17.7574 3.14853 17.7468L3.14019 17.7328L3.13698 17.7274L3.13561 17.725L3.13499 17.7239C3.13499 17.7239 3.1344 17.7229 3.99997 17.2221L3.1344 17.7229L2.26609 16.2221H3.99997C5.10394 16.2221 5.75382 16.2179 6.39261 16.0505C7.00169 15.8908 7.66683 15.5608 8.67833 14.7741L9.90621 16.3528C8.77485 17.2327 7.86527 17.732 6.89963 17.9851C6.72286 18.0314 6.54648 18.0687 6.36965 18.0987C7.15941 18.575 8.30396 18.9999 9.89283 18.9999C14.128 18.9999 18 15.5933 18 9.99992V8.48206C18 7.11121 16.8887 5.99992 15.5178 5.99992C14.147 5.99992 13.0357 7.11121 13.0357 8.48206V10.8787L12.1642 10.9916C10.5724 11.198 7.42887 10.5993 5.09342 7.45762C5.00819 7.77709 4.98711 8.10751 5.03125 8.43629C5.13498 9.2088 5.61656 10.087 6.71151 10.844L5.57415 12.4891C4.09767 11.4684 3.23996 10.1244 3.04904 8.70244C2.85841 7.28263 3.35032 5.89966 4.35155 4.86134L5.30455 3.87305Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.46085 12.88C6.03895 12.797 6.55708 12.486 6.79989 12.3096L7.97543 13.9276C7.61208 14.1916 6.78353 14.7106 5.74504 14.8597C4.63691 15.0188 3.34111 14.7419 2.25177 13.481L3.76522 12.1735C4.36169 12.8639 4.95239 12.953 5.46085 12.88Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.7863 5.48317C19.9032 5.34124 19.9879 5.11989 19.9865 4.7421L21.9865 4.7347C21.989 5.40619 21.8374 6.13862 21.3302 6.75453C20.8214 7.37252 20.0665 7.73739 19.1357 7.89714L18.7974 5.92596C19.4141 5.82011 19.6712 5.62304 19.7863 5.48317Z"
                fill={fill}
            />
        </svg>
    );
};

TwitterIcon.propTypes = {
    ...IconPropTypes,
};

TwitterIcon.defaultProps = {
    className: '',
    size: '24px',
};

export default withTheme(TwitterIcon);

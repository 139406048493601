import load from 'loadjs';
import loadConfiguration from 'ggApp/utils/configuration/configuration';
import { EventModel } from './types';

import { APP_CONTEXT, WEB_APPLICATION_CONTEXT } from './constants';

const { SNOWPLOW_TRACKER_NAME, SNOWPLOW_STATIC_JS_URL } = loadConfiguration();

declare global {
    interface Window {
        ReactNativeWebView?: {
            postMessage: (args: any) => void;
        };
        GlobalSnowplowNamespace: any[];
        __SP__: any;
    }
}

export const getPlatformContext = (): string =>
    window.ReactNativeWebView ? APP_CONTEXT : WEB_APPLICATION_CONTEXT;

export const isManualPageviewNeeded = (pathname: string): boolean => {
    const ROUTES_WITH_MANUAL_PAGEVIEW = ['/landing'];
    return ROUTES_WITH_MANUAL_PAGEVIEW.some((route) => pathname.includes(route));
};

export const loadSnowplow = () => {
    return new Promise<void>((resolve, reject) => {
        if (load.isDefined(SNOWPLOW_TRACKER_NAME)) {
            resolve();
            return;
        }
        load(SNOWPLOW_STATIC_JS_URL, SNOWPLOW_TRACKER_NAME, {
            error: reject,
            success: resolve,
        });
    });
};

type EventField = 'eventCategory' | 'eventAction' | 'eventLabel' | 'eventProperty';
export function normalize(data: EventModel): EventModel {
    const normalizeData = Object.keys(data).reduce((result: any, key) => {
        // eslint-disable-next-line no-param-reassign
        result[key] =
            typeof data[key as EventField] === 'object'
                ? JSON.stringify(data[key as EventField])
                : data[key as EventField];
        return result;
    }, {} as EventModel);
    const { eventCategory, eventAction, eventLabel, eventProperty } = normalizeData;
    return { eventCategory, eventAction, eventLabel, eventProperty };
}

/* eslint-disable */
import {
    GET_PRODUCT_BY_ID,
    GET_PRODUCT_BY_ID_SUCCESS,
    GET_PRODUCT_BY_ID_ERROR,
} from './../actionTypes';

const defaultState = {
    data: {},
    loading: false,
    error: {},
};

export default function (state = defaultState, action) {
  switch (action.type) {

    case GET_PRODUCT_BY_ID: {
        return {
            ...state,
            loading: true,
            error: {},
        }
    }

    case GET_PRODUCT_BY_ID_SUCCESS: {
        const product = action.data;
        return {
            ...state,
            loading: false,
            data: {
                [product.slug]: product,
            },
        };
    }

    case GET_PRODUCT_BY_ID_ERROR: {
        return {
            ...state,
            loading: false,
            error: action.error,
        }
    }

    default:
      return state;
  }
}

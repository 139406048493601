import qs from 'query-string';

import {
    DEFAULT_VALUE_RENTAL_PLAN,
    DEFAULT_VALUE_SORT,
    DEFAULT_VALUE_MIXABLE,
    SPEC_FILTER_RANGE_QS_KEY,
    SPEC_FILTER_MATCH_QS_KEY,
} from 'ggApp/modules/filter/constants';
import { SegmentEvents } from '../../../../../packages/analytics/events';

export const rangeFilterValueMatch = (value1, value2) =>
    value1.min === value2.min && value1.max === value2.max;

const updateRangeFilterValues = (oldValues, newValue) =>
    oldValues.find((v) => rangeFilterValueMatch(v, newValue))
        ? // remove value
          oldValues.filter((v) => !rangeFilterValueMatch(v, newValue))
        : // add value
          [...oldValues, newValue];

const updateMatchFilterValues = (oldValues, newValue) =>
    oldValues.includes(newValue)
        ? oldValues.filter((oldValue) => oldValue !== newValue)
        : oldValues.concat(newValue);

export const updateSpecFilterValues = (name, value, specs = [], isRange) =>
    specs.find((spec) => spec.name === name)
        ? specs.map((spec) =>
              spec.name === name
                  ? {
                        ...spec,
                        values: isRange
                            ? updateRangeFilterValues(spec.values, value)
                            : updateMatchFilterValues(spec.values, value),
                    }
                  : spec,
          )
        : // add spec and value
          [...specs, { name, values: [value] }];

export const getFilterEventDetails = (name, value, enabledSpecFilters, activeFilters) => {
    const isSpecFilter = enabledSpecFilters.some((spec) => spec.name === name);
    let eventName;
    let eventProperties = { [name]: value };

    switch (name) {
        case 'sort':
            eventName = SegmentEvents.productFilterSorted;
            break;
        case 'rental_plan':
            eventName = SegmentEvents.filterRentalPlanSelected;
            eventProperties = {
                [name]: Number(value),
            };
            break;
        case 'in_stock':
            eventName = SegmentEvents.filterProductAvailabilitySelected;
            eventProperties = {
                availability: Boolean(JSON.parse(value)),
            };
            break;
        case 'price':
            eventName = SegmentEvents.filterPriceSelected;
            eventProperties = {
                [`${name}_max`]: Number(value.max) / 100,
                [`${name}_min`]: Number(value.min) / 100,
            };
            break;
        case 'brands':
            eventName = SegmentEvents.filterBrandsSelected;
            eventProperties = {
                [name]: (activeFilters?.[name] || []).includes(value)
                    ? activeFilters?.[name].filter((brand) => brand !== value)
                    : (activeFilters?.[name] || []).concat(value),
            };
            break;
        case 'deal':
            eventName = SegmentEvents.filterDealsSelected;
            eventProperties = { deals: value };
            break;
        default:
            break;
    }

    if (isSpecFilter) {
        const hasGrouping = typeof value === 'object' && 'min' in value && 'max' in value;
        const specFilterKey = hasGrouping ? SPEC_FILTER_RANGE_QS_KEY : SPEC_FILTER_MATCH_QS_KEY;

        eventName = SegmentEvents.filterSpecsSelected;
        eventProperties = {
            specs: updateSpecFilterValues(name, value, activeFilters?.[specFilterKey], hasGrouping),
        };
    }

    return {
        eventName,
        eventProperties,
    };
};

export function filtersCompose({ activeFilters, enabledSpecFilters, name, value }) {
    const newFilters = { ...activeFilters };

    switch (name) {
        case 'sort':
        case 'deal':
            newFilters[name] = value;
            break;

        case 'rental_plan':
        case 'in_stock':
            newFilters[name] = value;
            delete newFilters.price_min;
            delete newFilters.price_max;
            break;

        case 'mixable':
            newFilters[name] = value;
            delete newFilters.price_min;
            delete newFilters.price_max;
            delete newFilters.rental_plan;
            break;

        case 'price':
            newFilters[`${name}_min`] = value.min;
            newFilters[`${name}_max`] = value.max;
            break;

        case 'brands':
            newFilters[name] = (activeFilters[name] || []).includes(value)
                ? activeFilters[name].filter((brand) => brand !== value)
                : (activeFilters[name] || []).concat(value);
            break;

        default:
            break;
    }

    const isSpecFilter = enabledSpecFilters.some((spec) => spec.name === name);

    if (isSpecFilter) {
        const hasGrouping = typeof value === 'object' && 'min' in value && 'max' in value;
        const specFilterKey = hasGrouping ? SPEC_FILTER_RANGE_QS_KEY : SPEC_FILTER_MATCH_QS_KEY;

        newFilters[specFilterKey] = updateSpecFilterValues(
            name,
            value,
            newFilters[specFilterKey],
            hasGrouping,
        );
    }

    return newFilters;
}

export const filtersToQuery = ({
    activeFilters,
    listOfFilters,
    name,
    value,
    locationPathname,
    availabilityFilterEnabled,
}) => {
    const newFilters = activeFilters;
    const defaultAvailability =
        locationPathname.includes('trending') ||
        locationPathname.includes('deals') ||
        availabilityFilterEnabled;

    const defaultValues = {
        rental_plan: DEFAULT_VALUE_RENTAL_PLAN,
        price_min: listOfFilters?.['price']?.min,
        price_max: listOfFilters?.['price']?.max,
        in_stock: defaultAvailability,
        mixable: DEFAULT_VALUE_MIXABLE,
        sort: DEFAULT_VALUE_SORT,
        deal: false,
    };

    if (
        name === 'price' &&
        defaultValues.price_min === value.min &&
        defaultValues.price_max === value.max
    ) {
        delete newFilters.price_min;
        delete newFilters.price_max;
    } else if (defaultValues[name] === value) {
        delete newFilters[name];
    }

    [SPEC_FILTER_RANGE_QS_KEY, SPEC_FILTER_MATCH_QS_KEY].forEach((key) => {
        if (newFilters[key]) {
            newFilters[key] = newFilters[key]
                .filter(({ values }) => values.length > 0)
                .map((spec) => JSON.stringify(spec));
        }
    });

    const string = qs.stringify(newFilters, { arrayFormat: 'bracket' });

    return string;
};

export enum VerificationState {
    Failed = 'failed',
    InProgress = 'in_progress',
    NotStarted = 'not_started',
    Verified = 'verified',
}

export enum BankAccountSnapshotProvider {
    Tink = 'tink',
    Kontomatik = 'kontomatik',
    Plaid = 'plaid',
}

import { useEffect, useState } from 'react';
import { Locale } from 'ggApp/types/common';
import { getOrderFromCookiesBasedStore } from 'ggApp/shared/context/Order/cookies';
import { sendSitewideBannerEvent } from 'snowplow';
import { ActiveStore } from 'ggApp/modules/store';

export const useSitewideBanner = (
    sitewideBannerData: any,
    activeStore: ActiveStore,
    userId: number | undefined,
    initDone: boolean | undefined,
): {
    showBanner: boolean;
    bannerText: string;
    handleBannerClose: () => void;
    handleBannerClick: () => void;
} => {
    const [showBanner, setShowBanner] = useState(false);

    const bannerText: string | undefined =
        sitewideBannerData?.variantAttachment?.text?.[activeStore.activeLanguage as Locale];

    const setLocalStorageEntry = (value: { expiry: Date; value: boolean }) =>
        localStorage.setItem('showSitewideBanner', JSON.stringify(value));

    const getLocalStore = () => {
        const defaultValue = { expiry: null, value: true };
        if (typeof window === 'undefined') return defaultValue;
        const actualValue = JSON.parse(localStorage.getItem('showSitewideBanner') || '{}');
        if (typeof actualValue.value === 'undefined') return defaultValue;
        return actualValue;
    };

    useEffect(() => {
        let showSitewideBannerEntry = getLocalStore();

        const hasExpired =
            showSitewideBannerEntry.expiry &&
            Date.now() > Date.parse(showSitewideBannerEntry.expiry);

        if (hasExpired) {
            showSitewideBannerEntry = { expiry: null, value: true };
        }

        setShowBanner(showSitewideBannerEntry.value);
        setLocalStorageEntry(showSitewideBannerEntry);
    }, [bannerText]);

    useEffect(() => {
        if (bannerText && initDone && showBanner) {
            const orderID = getOrderFromCookiesBasedStore(activeStore.code) ?? null;
            const userID = userId ?? null;
            const eventPayload = {
                eventAction: 'view',
                eventLabel: bannerText,
                eventProperty: {
                    store: activeStore.store_id,
                    orderID,
                    userID,
                    bannerType: 'siteWideBanner',
                },
            };
            sendSitewideBannerEvent(eventPayload);
        }
    }, [showBanner, bannerText, activeStore.code, activeStore.store_id, userId, initDone]);

    if (!sitewideBannerData || !bannerText) {
        return {
            showBanner: false,
            bannerText: '',
            handleBannerClose: () => undefined,
            handleBannerClick: () => undefined,
        };
    }

    const handleBannerClose = () => {
        const expiry = new Date();
        expiry.setDate(expiry.getDate() + 14);

        setLocalStorageEntry({ expiry, value: false });
        setShowBanner(false);
    };

    const handleBannerClick = () => {
        const orderID = getOrderFromCookiesBasedStore(activeStore.code) ?? null;
        const userID = userId ?? null;
        const eventPayload = {
            eventAction: 'click',
            eventLabel: bannerText,
            eventProperty: {
                store: activeStore.store_id,
                orderID,
                userID,
                bannerType: 'siteWideBanner',
            },
        };
        sendSitewideBannerEvent(eventPayload);
    };

    return {
        showBanner,
        bannerText,
        handleBannerClose,
        handleBannerClick,
    };
};

import styled, { css } from 'styled-components';

import { Headline, Paragraph } from 'ggApp/shared/common/Typography';
import ShimmerEffect from 'ggApp/shared/components/shimmerEffect/v2';
import { TransformingImage } from 'ggApp/shared/components/resolutionImage/TransformingImage/TransformingImage';
import FavoriteButtonComponent from 'ggApp/shared/components/cards/favoriteButton';

const ShimmerTransformer = ShimmerEffect(TransformingImage);

const colorStyle = css`
    color: ${({ theme, textColor }) => textColor || theme.colors.Foreground};
`;

const RootComponent = styled.div`
    display: grid;
    grid-column: 1/-1;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    border-width: 2px;
    border-style: solid;
    position: relative;
    background: ${props => props.theme.palette.grey[4]};
    border: none;
    ${colorStyle}
    &:hover,
    &:active {
        cursor: pointer;
    }
    border-radius: 8px;
    padding: 64px;
    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
        margin-left: ${theme.spacers.L};
        margin-right: ${theme.spacers.L};
    `};
`;

const CardChipBlock = styled.div`
    position: absolute;
    top: ${props => props.theme.spacers.L};
    right: ${props => props.theme.spacers.L};
    left: ${props => props.theme.spacers.L};
`;

const ProductImage = styled(ShimmerTransformer)``;

const DetailsSection = styled.div`
    grid-column-start: 6;
    grid-column-end: 13;
    align-self: center;
`;

const ProductName = styled(Headline)`
    margin-bottom: ${props => props.theme.spacers.L};
`;

const FavoriteButton = styled(FavoriteButtonComponent)`
    position: absolute;
    right: ${props => props.theme.spacers.L};
    top: ${props => props.theme.spacers.L};
`;

const ProductAttributes = styled(Paragraph)`
    margin-bottom: ${props => props.theme.spacers.L};
`;

const ImageContainer = styled.div`
    grid-column-start: 1;
    grid-column-end: 5;
    max-width: 100%;
    min-height: 80%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px 0px;
    position: relative;
`;

export {
    RootComponent,
    CardChipBlock,
    ProductImage,
    DetailsSection,
    ProductName,
    ProductAttributes,
    FavoriteButton,
    ImageContainer,
};

/* eslint-disable */
const defaultState = null;

export default function (state = defaultState, action) {
  switch (action.type) {

    case 'SET_ACTIVE_USER_PAYMENT_METHOD_ID':
      return action.id;

    default:
      return state;
  }
}

import { homeAddress, shippingAddress } from './address';
import { paymentMethod } from './paymentMethod';
import { lineItem } from './lineItem';
import { order } from './order';
import { CheckoutAPI } from '../integration';

export const restAPI: CheckoutAPI = {
    order,
    paymentMethod,
    homeAddress,
    shippingAddress,
    lineItem,
};

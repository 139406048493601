import { createSelector } from 'reselect';
import { orderActive as orderActiveSelector } from 'ggApp/modules/order/selectors';
import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import { formatPrice } from 'ggApp/modules/gaAnalytics/utils';
import { ANALYTIC_EVENTS } from 'ggApp/modules/gaAnalytics/constants';
import { user as userSelector } from 'ggApp/modules/auth/selectors';
import { getDeliveryEstimation } from 'staticStoreConfig';

const generalOrderInfo = (order, user, storeId) => {
    const { voucher } = order;
    return {
        orderID: order.id,
        userID: user.id,
        store: storeId,
        shipping: formatPrice(order.shipment_total_cents),
        orderValue: order.item_total,
        ...(voucher &&
            voucher.name && { voucher: voucher.name, voucherDiscount: voucher.discount }),
    };
};

const productInfo = (deliveryTime) => (product) => {
    const { variant, total, quantity } = product;
    const { name, sku, category = {} } = variant.product;
    const [subCategory] = category.permalink?.split('/').slice(-1) ?? '';
    return {
        name,
        productSKU: sku,
        productVariant: variant.id,
        price: total,
        subscriptionLength: product.minimum_term_months,
        quantity,
        deliveryTime,
        sub_category: subCategory,
    };
};

const getSkus = (products) => {
    if (products.length === 1) {
        return (
            (products[0].variant &&
                products[0].variant.product &&
                products[0].variant.product.sku) ||
            ''
        );
    }
    return products.map((p) => (p.variant && p.variant.product && p.variant.product.sku) || '');
};

const purchaseSelectorV2 = createSelector(
    orderActiveSelector,
    userSelector,
    activeStoreSelector,
    (order, user, store) => {
        const products = order.items || [];
        const deliveryEstimates = getDeliveryEstimation(order.store_code);
        const deliveryTime = deliveryEstimates
            ? `${deliveryEstimates.from}-${deliveryEstimates.to}`
            : '';
        return {
            event: ANALYTIC_EVENTS.purchase,
            eventCategory: 'checkout',
            eventAction: 'orderSubmitted',
            eventLabel: getSkus(products),
            eventProperty: {
                ...generalOrderInfo(order, user, store.store_id),
                productData: products.map(productInfo(deliveryTime)),
            },
        };
    },
);

export default purchaseSelectorV2;

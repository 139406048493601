import { useMemo } from 'react';

import ACCOUNT_TYPES from 'ggApp/modules/auth/v3/constants/accountTypes';

export default function useYourCardTab({ storeCode, isMobileUser, user }) {
    const hasProperStoreCode = storeCode === 'de';
    const isNotBusinessCustomer = user.user_type !== ACCOUNT_TYPES.BUSINESS_CUSTOMER;

    const shouldShowYourCardTab = useMemo(() => {
        return Boolean(hasProperStoreCode && isMobileUser && isNotBusinessCustomer);
    }, [hasProperStoreCode, isMobileUser, isNotBusinessCustomer]);

    return shouldShowYourCardTab;
}

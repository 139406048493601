import React, { useEffect, useState, useContext, useCallback } from 'react';

import { isFunction } from 'ggApp/utils/function';
import { CookiesManagerContext } from 'ggApp/utils/CookiesManager';
import {
    IP_COUNTRY_COOKIE_NAME,
    AUTOMATIC_REDIRECTION_COOKIE_NAME,
} from 'ggApp/utils/countryIPCookie/constants';
import CloseSmall from 'ggApp/shared/icons/CloseSmall';
import Box from 'ggApp/shared/icons/Box';
import { isAppUser } from 'ggApp/routes/_wrap/MobileWrapper/utils';
import { buildCountryRedirectionUrl } from 'ggApp/utils/urlBuilder/urls';

import { getBannerText } from './bannerTextDictionary';
import {
    StyledModal,
    Wrap,
    Header,
    HeaderText,
    CloseButton,
    Description,
    SelectText,
    StoreLangDropDowns,
    WarningCard,
    Warning,
    Cta,
} from './styles';

const ONE_YEAR_EXPIRATION_TIME_IN_SECONDS = 365 * 24 * 60 * 60;

const CountryRedirection = ({
    activeStore: { country_code: countryCode, activeLanguage, code } = {},
    modalRef,
    redirectus,
    urlParams,
}) => {
    const [modal, setModal] = useState(null);
    const [redirectionCountry, setRedirectionCountry] = useState(code);
    const [redirectionLanguage, setRedirectionLanguage] = useState(activeLanguage);
    const cookiesManager = useContext(CookiesManagerContext);
    const ipCookie = cookiesManager.get(IP_COUNTRY_COOKIE_NAME);
    const redirectionCookie = cookiesManager.get(AUTOMATIC_REDIRECTION_COOKIE_NAME);
    const { headingTxt, descriptionTxt, selectTxt, warningTxt, ctaTxt } = getBannerText(
        redirectionLanguage,
    );
    const setRedirectionCookie = useCallback(() => {
        cookiesManager.set(
            AUTOMATIC_REDIRECTION_COOKIE_NAME,
            `${redirectionCountry}-${redirectionLanguage}`,
            {
                maxAge: ONE_YEAR_EXPIRATION_TIME_IN_SECONDS,
            },
        );
    }, [cookiesManager, redirectionCountry, redirectionLanguage]);

    const createModalRef = (m) => {
        setModal(m);

        if (isFunction(modalRef)) {
            modalRef(modal);
        }
    };

    useEffect(() => {
        if (
            modal &&
            ipCookie !== countryCode &&
            !redirectionCookie &&
            !isAppUser() &&
            countryCode !== 'US'
        ) {
            modal.openModal();
        }
    }, [countryCode, ipCookie, modal, redirectionCookie, redirectus]);

    const closeModal = useCallback(() => {
        setRedirectionCookie();
        if (modal) {
            modal.closeModal();
        }
    }, [modal, setRedirectionCookie]);

    const handleRedirection = () => {
        setRedirectionCookie();
        const redirectUrl = buildCountryRedirectionUrl({
            activeStoreCode: code,
            referralCode: urlParams.referralCode,
            selectedCountryCode: redirectionCountry,
            selectedLanguageCode: redirectionLanguage,
        });
        redirectus(redirectUrl);
        if (modal) {
            modal.closeModal();
        }
        // needed to fetch the store and translations data for the new location.
        window.location.reload();
    };

    return (
        <StyledModal modalRef={createModalRef} shouldCloseOnClick={false}>
            <Wrap>
                <Header>
                    <HeaderText>{headingTxt}</HeaderText>
                    <CloseButton
                        onClick={closeModal}
                        data-testid="country_redirection_close_button"
                    >
                        <CloseSmall />
                    </CloseButton>
                </Header>
                <Description>{descriptionTxt}</Description>
                <SelectText>{selectTxt}</SelectText>
                <StoreLangDropDowns
                    activeStoreCode={code}
                    activeLanguage={activeLanguage}
                    setRedirectionCountry={setRedirectionCountry}
                    setRedirectionLanguage={setRedirectionLanguage}
                />
                <WarningCard>
                    <Box color="#EDA210" />
                    <Warning>{warningTxt}</Warning>
                </WarningCard>
                <Cta
                    fullWidth
                    color="red"
                    onClick={handleRedirection}
                    data-testid="country_redirection_confirm_button"
                >
                    {ctaTxt}
                </Cta>
            </Wrap>
        </StyledModal>
    );
};

export default CountryRedirection;

import { compose, branch, renderNothing } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { spaFeatureFlag } from 'ggApp/modules/featureFlag/selectors';
import {
    getRecentlyViewedProducts,
    getProductsGraphQLData as getProductsGraphQLDataAction,
} from 'ggApp/modules/product/actions';
import { params as routerParams } from 'ggApp/modules/router/selectors';
import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import { userId as userIdSelector } from 'ggApp/modules/auth/selectors';
import RecentlyView from './RecentlyView';

const enhance = compose(
    connect(
        createStructuredSelector({
            routerParams,
            spaFeatureFlag,
            activeStore: activeStoreSelector,
            userID: userIdSelector,
        }),
        (dispatch) => ({
            getProducts: bindActionCreators(getRecentlyViewedProducts, dispatch),
            getProductsGraphQLData: bindActionCreators(getProductsGraphQLDataAction, dispatch),
        }),
    ),
    branch(
        ({ location: { pathname } }) =>
            // render nothing on confirmation and welcome page
            /confirmation|welcome|\/mix|how-it-works|proud-renters|comparison|black-friday-signup|black-friday-thanks/.test(
                pathname,
            ),
        renderNothing,
    ),
);

export default enhance(RecentlyView);

/* eslint-disable import/prefer-default-export */
import moment from 'moment';

export function serializeMomentDate(momentDate) {
    if (!moment.isMoment(momentDate)) {
        throw new Error('The argument is not a moment object');
    }

    if (!momentDate.isValid()) {
        throw new Error('The argument is not a valid date');
    }

    return momentDate.format('DD-MM-YYYY');
}

/* eslint-disable */
export const detectUserNumberPrefix = (user, phonePrefixes) => {
  let
    prefix,
    userPhone = user.phone || '';

  phonePrefixes.forEach((p) => {
    if ( userPhone.indexOf(p.id) !== -1 ) prefix = p.id;
  });

  return {
    prefix: prefix,
    number: userPhone.replace(prefix, ''),
  };
}

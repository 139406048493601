import { GET_FEATURE_FLAGS, GET_FEATURE_FLAGS_SUCCESS } from '../../featureFlag/constants';

const defaultState = {
    loading: null,
    error: {},
    data: null,
};

export default function(state = defaultState, action) {
    switch (action.type) {
        case GET_FEATURE_FLAGS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.response,
            };

        case GET_FEATURE_FLAGS:
            return {
                ...state,
                loading: true,
                data: null,
            };

        default:
            return state;
    }
}

function TwoFactorAuthenticationError(
    message = 'Two factor authentication is required',
    fileName,
    lineNumber,
) {
    Error.call(this, message, fileName, lineNumber);

    if (Error.captureStackTrace) {
        Error.captureStackTrace(this, TwoFactorAuthenticationError);
    } else {
        this.stack = new Error().stack;
    }
}

TwoFactorAuthenticationError.prototype = Object.create(Error.prototype);

export { TwoFactorAuthenticationError };

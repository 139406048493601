/* eslint-disable */
// @flow
import { PARAM_NAME, } from './../constants';
import { push, ReduxRouterAction, } from 'redux-router';

/* WARNING: These actions are deprecated.
 * Do not use them for the new codebase.
 * Do not add any new actions.
 * Use `modal/state/actions` instead.
*/

type ActionHandler = (
    dispatch: (action: ReduxRouterAction) => null,
    getState: () => any
) => any;

/**
 * It opens modal window with specified name and can add data to 'location.state' or 'location.query'.
 * NOTE: In 'location.query' fields will be added with 'modal_' prefix.
 * WARNING: This action is deprecated. Do not use it with the new code.
 * @export
 * @param {string} path
 * @param {{}} [state={}]
 * @param {{}} [queryState={}]
 * @returns {ActionHandler} ActionHandler
 */
export function open(name: string, state: {} = {}, queryState: {} = {}): ActionHandler {
  return (dispatch, getState) => {
    const queryObject = typeof name === 'string'
        ? ({ [PARAM_NAME]: name, })
        : name;

    const modifiedQueryState = {};
    Object.keys(queryState).forEach(key => {
        modifiedQueryState[`${PARAM_NAME}_${key}`] = queryState[key];
    });

    const
      ggRouter = getState().router,
      newQuery = Object.assign({}, ggRouter.location.query, queryObject, modifiedQueryState),
      newState = Object.assign({}, ggRouter.location.state, state);

    dispatch(push({
      pathname: ggRouter.location.pathname,
      query: newQuery,
      state: newState,
    }));

  };
}

/**
 * It closes modal window and removes all params starts from 'modal' from router.location.query
 * WARNING: This action is deprecated. Do not use it with the new code.
 * @export
 * @returns {ActionHandler}
 */
export function close(): ActionHandler {
  return (dispatch, getState) => {
    const {
        query,
        pathname,
    } = getState().router.location;

    const newQuery = {};

    // It copies params from previous query state
    // if there is no 'modal' substring in param name.
    Object.keys(query).forEach((param) => {
        if (param.indexOf(PARAM_NAME) === -1) {
            newQuery[param] = query[param];
        }
    });

    dispatch(push({
      pathname,
      query: newQuery,
      state: {},
    }));

  };
}

/**
 * constructAction
 * WARNING: This action is deprecated. Do not use it with the new code.
 * @param {(string | ActionHandler)} routeOrActionHandler
 * @param {*} action
 * @param {string} defaultRoute
 * @returns {() => ActionHandler}
 */
export function constructAction(routeOrActionHandler: string | ActionHandler, action: any, defaultRoute: string): () => ActionHandler {
    return (dispatch, getState) => {
        if (typeof routeOrActionHandler === 'string' || !routeOrActionHandler) {
            const route = routeOrActionHandler || defaultRoute;

            return () => dispatch(action(route));
        }

        return routeOrActionHandler;
    }
}

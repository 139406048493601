import styled, { css, createGlobalStyle } from 'styled-components';

export const OverlaySwitcherContainer = styled.div`
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
`;

export const OverlaySwitcherBackground = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: visibility $transition-duration ease, opacity $transition-duration ease;
    background: ${(props) => props.theme.colors.Background};
    z-index: 2;

    ${(props) => props.visible && css`
        visibility: visible;
        opacity: 1;
    `}
`;

export const OverlaySwitcherWrap = styled.div`
    display: block;
    background-color: transparent;
    visibility: hidden;
    opacity: 0;
    transition: visibility $transition-duration ease, opacity $transition-duration ease;
    position: fixed;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    touch-action: auto !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;

    ${(props) => props.visible && css`
        visibility: visible;
        opacity: 1;
    `}
`;

export const OverlaySwitcherContentWrap = styled.div`
    height: auto;
    background: ${(props) => props.theme.colors.Background};

    ${(props) => props.hide && css`
        z-index: -1;
    `}
`;

export const GlobalDisableScroll = createGlobalStyle`
    .disableScroll {
        overflow-y: hidden;
        -webkit-overflow-scrolling: auto;
        overscroll-behavior-y: contain;
    }
`;

/* eslint-disable */
const defaultState = '';

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'BRAINTREE_GET_AUTH_TOKEN_SUCCESS':
      return action.data;

    default:
      return state;
  }
}

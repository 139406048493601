import axios from 'ggApp/utils/requests';
import { Address } from 'ggApp/types/graphql';
import { APISelection } from '../integration';

export const shippingAddress: APISelection<Address> = {
    update: ({ orderNumber }, { address }: { address: { id: number } }): Promise<void | any> => {
        if (!address || !orderNumber) return Promise.resolve();

        return axios
            .patch(`/orders/${orderNumber}/address`, {
                shipping_address_id: address.id,
            })
            .then((res) => res.data);
    },
};

export const homeAddress: APISelection<Address> = {
    update: ({ orderNumber }, { address }: { address: { id: number } }): Promise<void | any> => {
        if (!address || !orderNumber) return Promise.resolve();

        return axios
            .patch(`/orders/${orderNumber}/address`, {
                billing_address_id: address.id,
            })
            .then((res) => res.data);
    },
};

import styled from 'styled-components';

import { screens } from 'ggApp/utils/mixins/screens';

export const Grid = styled.div`
    margin: ${({ noMargin }) => noMargin ? '0' : '0 32px'};
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;

    @media (${screens.phone}) and (${screens.tabletMax}) {
        margin: ${({ noMargin }) => noMargin ? '0' : '0 16px'};
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 8px;
        grid-row-gap: 8px;
    }
`;

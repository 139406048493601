enum ActionTypes {
    IS_SUMMARY_LOADING = 'IS_SUMMARY_LOADING',
    IS_FILTERS_LOADING = 'IS_FILTERS_LOADING',
    IS_DETAILS_LOADING = 'IS_DETAILS_LOADING',
}

export type ActionType = keyof typeof ActionTypes;

export interface LoadersAction {
    type: ActionType;
    payload: boolean;
}

export default ActionTypes;

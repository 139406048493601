import styled from 'styled-components';

import MapDot from 'ggApp/shared/icons/MapDot';

export const Icon = styled(MapDot)`
    height: 24px;
    width: 24px;
    margin-right: ${({ theme }) => theme.spacers.M};
`;

export const CrawlableStoreLink = styled.a`
    display: none;
`;

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
    DateTime: any;
    /** JSON serialize */
    JSON: any;
    /** An ISO 8601-encoded datetime */
    ISO8601DateTime: any;
    /** Arbitrary object */
    Object: any;
    DateISOString: any;
    /** An ISO 8601-encoded date */
    ISO8601Date: any;
    /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
    DateTimeISO: any;
    /** Money scalar type */
    Money: any;
    /** Any type */
    AnyScalar: any;
    /** Grover App Navigation custom scalar type */
    GroverAppNavigation: any;
    /** The `Upload` scalar type represents a file upload. */
    Upload: any;
};

export type AccessoriesReturnStep = BasicReturnStep & {
    __typename?: 'AccessoriesReturnStep';
    accessories: Array<Scalars['String']>;
    description: Scalars['String'];
    headline: Scalars['String'];
    name: Scalars['String'];
    warning?: Maybe<Scalars['String']>;
};

export type AccountBasicModel = {
    __typename?: 'AccountBasicModel';
    amount: Scalars['Money'];
    currency: Scalars['String'];
    uuid: Scalars['String'];
    accountUuid: Scalars['String'];
};

export type AccountDcPaymentModel = {
    __typename?: 'AccountDcPaymentModel';
    referenceId: Scalars['String'];
    transactionId: Scalars['String'];
    method: Scalars['String'];
    currency: Scalars['String'];
};

export type AccountManualBookingModel = {
    __typename?: 'AccountManualBookingModel';
    orderNumber: Scalars['String'];
    otpLink: Scalars['String'];
};

export type AccountMenuHint = {
    __typename?: 'AccountMenuHint';
    url: Scalars['String'];
    urlText: Scalars['String'];
    text?: Maybe<Scalars['String']>;
};

export type AccountMenuLink = {
    __typename?: 'AccountMenuLink';
    title: Scalars['String'];
    description: Scalars['String'];
    url: Scalars['String'];
    targetBlank: Scalars['Boolean'];
    external: Scalars['Boolean'];
    key: Scalars['String'];
    isHidden: Scalars['Boolean'];
};

export type AccountModel = {
    __typename?: 'AccountModel';
    uuid: Scalars['ID'];
    walletUuid: Scalars['String'];
    type: Scalars['String'];
    deleted: Scalars['Boolean'];
};

export enum AccountOpeningRequestStatus {
    INITIATED = 'INITIATED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    REJECTED = 'REJECTED',
}

export type AccountPayByInvoiceModel = {
    __typename?: 'AccountPayByInvoiceModel';
    viban: Scalars['String'];
};

export type AccountPaymentServiceModel = {
    __typename?: 'AccountPaymentServiceModel';
    gateway: Scalars['String'];
    referenceId: Scalars['String'];
    transactionId: Scalars['String'];
    transactionToken?: Maybe<Scalars['String']>;
};

export type AccountReservation = {
    __typename?: 'AccountReservation';
    id: Scalars['String'];
    metaInfoRaw: Scalars['String'];
    loyaltyAction?: Maybe<LoyaltyActionResultReservation>;
    description?: Maybe<Scalars['String']>;
    amount: CardAmount;
    reservationType: ReservationType;
    expiresAt?: Maybe<Scalars['String']>;
    metaInfo?: Maybe<BookingMetaInfo>;
};

export type AccountStatementInfo = {
    year: Scalars['String'];
    period: Scalars['Float'];
};

export type AccountStatementPdfResult = {
    __typename?: 'AccountStatementPDFResult';
    encodedContent: Scalars['String'];
    mimeType: Scalars['String'];
    year: Scalars['String'];
    period: Scalars['Float'];
};

export type AccountType =
    | AccountBasicModel
    | AccountPaymentServiceModel
    | AccountPayByInvoiceModel
    | AccountManualBookingModel
    | AccountDcPaymentModel
    | AccountWalletServiceModel;

export type AccountWalletServiceModel = {
    __typename?: 'AccountWalletServiceModel';
    paymentMethodId: Scalars['String'];
};

export type Action = {
    __typename?: 'Action';
    defaultMessage?: Maybe<Scalars['String']>;
    href?: Maybe<Scalars['String']>;
    text: Scalars['String'];
    type: Scalars['String'];
};

export type ActionInputType = {
    campaignUuid: Scalars['String'];
    type: ActionTypes;
    content: Scalars['AnyScalar'];
};

export type ActionMetadata = {
    __typename?: 'ActionMetadata';
    performedBy?: Maybe<Scalars['Int']>;
    comment?: Maybe<Scalars['String']>;
    reason?: Maybe<ManualGrantReason>;
    createdAt?: Maybe<Scalars['String']>;
};

/** Transaction Action Status History Enums */
export enum ActionStatus {
    COMPLETED = 'COMPLETED',
    SCHEDULED = 'SCHEDULED',
    PROCESSING = 'PROCESSING',
    FAILED = 'FAILED',
}

/** Transaction Action Type History Enums */
export enum ActionType {
    CANCEL = 'CANCEL',
    PUT_ON_HOLD = 'PUT_ON_HOLD',
    RETRY = 'RETRY',
    REFUND = 'REFUND',
}

export enum ActionTypes {
    linkAction = 'linkAction',
    copyToClipboardAction = 'copyToClipboardAction',
    launchFlowActionModel = 'launchFlowActionModel',
}

export type ActionUnionModel = LinkActionModel | CopyToClipboardActionModel | LaunchFlowActionModel;

export type ActivateCardInfo = {
    verificationToken?: Maybe<Scalars['String']>;
    cardId: Scalars['String'];
};

export type ActivateCardResult = {
    __typename?: 'ActivateCardResult';
    status: CardStatus;
};

export type ActivationCodesReportEntry = {
    __typename?: 'ActivationCodesReportEntry';
    variantId: Scalars['String'];
    durationInMonths: Scalars['Int'];
    addonName: Scalars['String'];
    codesInDB: Scalars['Int'];
    codesInOrderProcess: Scalars['Int'];
};

/** Input type for adding an account to the Billing system */
export type AddAccountInput = {
    externalCustomer: Scalars['String'];
    gateway: Gateway;
    referenceId: Scalars['String'];
    transactionId: Scalars['String'];
    currency: Scalars['String'];
    transactionToken?: Maybe<Scalars['String']>;
};

export type AddKeyInfo = {
    /** Device id */
    deviceId: Scalars['String'];
    /** Signed string */
    signature: Scalars['String'];
    /** Public part of ECDSA-P256 keypair that will be used for signature verification of CRPs later on */
    devicePublicKey: Scalars['String'];
    keyPurpose: KeyPurpose;
};

export type AddKeyResult = {
    __typename?: 'AddKeyResult';
    id: Scalars['String'];
};

export type AddLocaleInput = {
    version: Scalars['ID'];
    locale: Scalars['String'];
    content: Scalars['String'];
    summary: Scalars['String'];
};

export type AddonProvider = {
    __typename?: 'AddonProvider';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    active?: Maybe<Scalars['Boolean']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AddonsAddon = {
    id: Scalars['ID'];
};

export type AddonsApiEvent = {
    __typename?: 'AddonsApiEvent';
    id: Scalars['String'];
    error: Scalars['String'];
    originalMessageValue: Scalars['String'];
    messageValue: Scalars['String'];
    topic: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
};

export type AddonsExternalAddon = AddonsAddon & {
    __typename?: 'AddonsExternalAddon';
    id: Scalars['ID'];
    provider?: Maybe<AddonProvider>;
};

export type AddonsInternalAddon = AddonsAddon & {
    __typename?: 'AddonsInternalAddon';
    id: Scalars['ID'];
    productSku: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    product?: Maybe<Product>;
    rentalPlan?: Maybe<AddonsRentalPlan>;
};

export type AddonsMainProduct = {
    __typename?: 'AddonsMainProduct';
    id: Scalars['ID'];
    storeCode: Scalars['String'];
    productSku: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    product?: Maybe<Product>;
    addons?: Maybe<Array<AddonsAddon>>;
    rentalPlan?: Maybe<AddonsRentalPlan>;
};

export type AddonsRentalPlan = {
    __typename?: 'AddonsRentalPlan';
    id: Scalars['ID'];
    length: Scalars['Int'];
    price: Price;
};

/** Input type for adding a pay-by-invoice account to the Billing system */
export type AddPayByInvoiceAccountInput = {
    externalCustomer: Scalars['String'];
    currency: Scalars['String'];
    viban: Scalars['String'];
};

/** Input for the addPaymentMethod mutation. */
export type AddPaymentMethodInput = {
    option: PaymentOptionType;
    /**
     * Where to redirect the customer if authorization succeeds. The occurrences of
     * substring '{{payment-method-id}}' will be replaced with the method ID.'
     */
    successRedirectUrl: Scalars['String'];
    /**
     * Where to redirect the customer if authorization fails. The occurrences of
     * substring '{{payment-method-id}}' will be replaced with the method ID.'
     */
    errorRedirectUrl: Scalars['String'];
    /**
     * Where to redirect the customer if authorization is cancelled. The occurrences
     * of substring '{{payment-method-id}}' will be replaced with the method ID.'
     */
    cancelRedirectUrl: Scalars['String'];
    /** Applicable for cards: a token generated by the payment provider from card data. */
    cardToken?: Maybe<Scalars['String']>;
    /** The order for which the method is being added. Should be provided if the addition happens at checkout. */
    orderNumber?: Maybe<Scalars['ID']>;
};

/** Result of the addPaymentMethod mutation. */
export type AddPaymentMethodResult = {
    __typename?: 'AddPaymentMethodResult';
    /** ID of the new payment method. */
    id: Scalars['ID'];
    /** URL to redirect the user to if authorization is required by the payment provider. */
    redirectUrl?: Maybe<Scalars['String']>;
};

export type AddProductsToCampaignInput = {
    productCampaignId: Scalars['ID'];
    skus: Array<Scalars['String']>;
};

export type AddProductsToCountdownTimerInput = {
    countdownTimerId: Scalars['ID'];
    skus: Array<Scalars['String']>;
};

export type AddProductsToCountdownTimerOutput = {
    __typename?: 'AddProductsToCountdownTimerOutput';
    numberOfCreatedRecords: Scalars['Int'];
};

export type AddReferenceAccountResult = {
    __typename?: 'AddReferenceAccountResult';
    status: Scalars['String'];
};

export type Address = {
    __typename?: 'Address';
    additionalInfo?: Maybe<Scalars['String']>;
    billingAddress: Scalars['Boolean'];
    city: Scalars['String'];
    country: Scalars['String'];
    countryIso: Scalars['String'];
    id: Scalars['ID'];
    line1: Scalars['String'];
    line2?: Maybe<Scalars['String']>;
    shippingAddress: Scalars['Boolean'];
    stateAbbr?: Maybe<Scalars['String']>;
    zip: Scalars['String'];
};

export type AddressInfo = {
    street: Scalars['String'];
    streetNumber: Scalars['String'];
    city: Scalars['String'];
    postalCode: Scalars['String'];
    addressAdditionalInfo?: Maybe<Scalars['String']>;
};

export type AddressInvoice = {
    __typename?: 'AddressInvoice';
    city?: Maybe<Scalars['String']>;
    line1?: Maybe<Scalars['String']>;
    line2?: Maybe<Scalars['String']>;
    zipCode?: Maybe<Scalars['String']>;
    stateAbbr?: Maybe<Scalars['String']>;
    countryCode?: Maybe<Scalars['String']>;
    countryName?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of AddToFavorites */
export type AddToFavoritesInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    skus: Array<Scalars['String']>;
};

/** Autogenerated return type of AddToFavorites. */
export type AddToFavoritesPayload = {
    __typename?: 'AddToFavoritesPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    favorites: Array<Product>;
};

/** Input type for adding a Wallet service account (payment method) */
export type AddWalletAccountInput = {
    externalCustomer: Scalars['String'];
    currency: Scalars['String'];
    paymentMethodId: Scalars['String'];
};

export type AdjustmentInput = {
    code?: Maybe<Scalars['String']>;
};

export type AdminPerson = {
    __typename?: 'AdminPerson';
    userId: Scalars['String'];
    deviceList: Array<Device>;
    identification?: Maybe<PersonIdentification>;
    cards: Array<GroverCard>;
    accountDetails?: Maybe<PersonAccountDetails>;
};

export type AffectedRowsOutput = {
    __typename?: 'AffectedRowsOutput';
    count: Scalars['Int'];
};

export type AllocationResult = {
    __typename?: 'AllocationResult';
    success: Scalars['Boolean'];
    allocation?: Maybe<AssetAllocation>;
};

export type AnalyticsTerm = {
    __typename?: 'AnalyticsTerm';
    name: Scalars['String'];
    value: Scalars['String'];
};

export type ApplePushProvisioningInfo = {
    /** Solaris Card ID */
    cardId: Scalars['String'];
    deviceId: Scalars['String'];
    /** An array of leaf and sub-CA certificates provided by Apple */
    certificates: Array<Scalars['String']>;
    /** A single-use nonce provided by Apple */
    nonce: Scalars['String'];
    /** Nonce signature provided by Apple */
    nonceSignature: Scalars['String'];
};

export type ApplicableCarrierGraphql = {
    __typename?: 'ApplicableCarrierGraphql';
    name: CarrierName;
    service: Array<CarrierServiceGraphql>;
    description?: Maybe<Scalars['String']>;
};

export type ApplicableCarrierResultGraphql = {
    __typename?: 'ApplicableCarrierResultGraphql';
    carriers?: Maybe<Array<ApplicableCarrierGraphql>>;
    error?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ApplyDiscount */
export type ApplyDiscountInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    comment?: Maybe<Scalars['String']>;
    contractId: Scalars['ID'];
    discountAmountInCents: Scalars['Int'];
    discountReason: DiscountReason;
};

/** Autogenerated return type of ApplyDiscount. */
export type ApplyDiscountPayload = {
    __typename?: 'ApplyDiscountPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract: Contract;
};

/** Autogenerated input type of ApplyLoyaltyDiscount */
export type ApplyLoyaltyDiscountInput = {
    amountInCents: Scalars['Int'];
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    paymentId: Scalars['ID'];
};

/** Autogenerated return type of ApplyLoyaltyDiscount. */
export type ApplyLoyaltyDiscountPayload = {
    __typename?: 'ApplyLoyaltyDiscountPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    payment: PaymentsUnion;
};

/** Autogenerated input type of ApplySurpriseDiscount */
export type ApplySurpriseDiscountInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    subscriptionId: Scalars['ID'];
};

/** Autogenerated return type of ApplySurpriseDiscount. */
export type ApplySurpriseDiscountPayload = {
    __typename?: 'ApplySurpriseDiscountPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    errors: Array<Scalars['String']>;
    subscriptionDetails: SubscriptionDetails;
};

export type Asset = {
    __typename?: 'Asset';
    isDeviceLocked?: Maybe<Scalars['Boolean']>;
    productNumber: Scalars['String'];
    serialNumber?: Maybe<Scalars['String']>;
};

export type AssetAllocation = {
    __typename?: 'AssetAllocation';
    uid: Scalars['ID'];
    assetUid?: Maybe<Scalars['String']>;
    asset?: Maybe<AssetModel>;
    replacements?: Maybe<Array<ReplacementModel>>;
    reservationUid?: Maybe<Scalars['String']>;
    reservation?: Maybe<Reservation>;
    storeId?: Maybe<Scalars['Int']>;
    store?: Maybe<Store>;
    orderNumber?: Maybe<Scalars['String']>;
    orderMode?: Maybe<JunoOrderMode>;
    serialNumber?: Maybe<Scalars['String']>;
    skuVariantCode?: Maybe<Scalars['String']>;
    salesforceAssetId?: Maybe<Scalars['String']>;
    salesforceAllocationId?: Maybe<Scalars['String']>;
    salesforceSubscriptionId?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    replacedBy: ReplacementModel;
    replacement: ReplacementModel;
    shipments: Array<Shipment>;
};

export type AssetAllocationCollection = {
    __typename?: 'AssetAllocationCollection';
    count: Scalars['Float'];
    rows: Array<AssetAllocation>;
};

export type AssetAllocationFilterInput = {
    uid?: Maybe<Scalars['String']>;
    assetUid?: Maybe<Scalars['String']>;
    reservationUid?: Maybe<Scalars['String']>;
    storeId?: Maybe<Scalars['Float']>;
    orderNumber?: Maybe<Scalars['String']>;
    orderMode?: Maybe<JunoOrderMode>;
    serialNumber?: Maybe<Scalars['String']>;
    skuVariantCode?: Maybe<Scalars['String']>;
    salesforceAssetId?: Maybe<Scalars['String']>;
    salesforceAllocationId?: Maybe<Scalars['String']>;
    salesforceSubscriptionId?: Maybe<Scalars['String']>;
};

export type AssetBulkStatusUpdate = BulkUpdateParams & {
    __typename?: 'AssetBulkStatusUpdate';
    assetIds?: Maybe<Array<Scalars['String']>>;
    serialNumbers?: Maybe<Array<Scalars['String']>>;
    status: AssetStatus;
};

export type AssetBulkStatusUpdateResult = BulkUpdateError & {
    __typename?: 'AssetBulkStatusUpdateResult';
    success?: Maybe<Scalars['Boolean']>;
    errors?: Maybe<Array<AssetUpdateError>>;
    bulkError?: Maybe<Scalars['String']>;
    update?: Maybe<AssetBulkStatusUpdate>;
};

export type AssetBulkUpdateInput = {
    assetIds?: Maybe<Array<Scalars['String']>>;
    serialNumbers?: Maybe<Array<Scalars['String']>>;
    status: AssetStatus;
};

export type AssetBulkWarehouseCodeUpdate = BulkUpdateParams & {
    __typename?: 'AssetBulkWarehouseCodeUpdate';
    assetIds?: Maybe<Array<Scalars['String']>>;
    serialNumbers?: Maybe<Array<Scalars['String']>>;
    warehouseCode: WarehouseCode;
};

export type AssetBulkWarehouseCodeUpdateInput = {
    assetIds?: Maybe<Array<Scalars['String']>>;
    serialNumbers?: Maybe<Array<Scalars['String']>>;
    warehouseCode: WarehouseCode;
};

export type AssetBulkWarehouseCodeUpdateResult = BulkUpdateError & {
    __typename?: 'AssetBulkWarehouseCodeUpdateResult';
    success?: Maybe<Scalars['Boolean']>;
    errors?: Maybe<Array<AssetUpdateError>>;
    bulkError?: Maybe<Scalars['String']>;
    update?: Maybe<AssetBulkWarehouseCodeUpdate>;
};

export type AssetCollection = {
    __typename?: 'AssetCollection';
    count: Scalars['Float'];
    rows: Array<AssetModel>;
};

export enum AssetCondition {
    New = 'New',
    Agan = 'Agan',
    Damaged = 'Damaged',
    Locked = 'Locked',
    Incomplete = 'Incomplete',
    Irreparable = 'Irreparable',
    Warranty = 'Warranty',
    Used = 'Used',
}

export type AssetEventModel = {
    __typename?: 'AssetEventModel';
    uid: Scalars['ID'];
    assetUid: Scalars['String'];
    skuVariantCode: Scalars['String'];
    serialNumber: Scalars['String'];
    description: Scalars['String'];
    warehouseCode: Scalars['String'];
    createdAt?: Maybe<Scalars['DateTime']>;
    deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AssetFilterInput = {
    uid?: Maybe<Scalars['String']>;
    supplierName?: Maybe<Scalars['String']>;
    capitalSourceName?: Maybe<Scalars['String']>;
    serialNumber?: Maybe<Scalars['String']>;
    stockUid?: Maybe<Scalars['String']>;
    status?: Maybe<AssetStatus>;
    condition?: Maybe<AssetCondition>;
    salesforceId?: Maybe<Scalars['String']>;
    salesforceStatus?: Maybe<Scalars['String']>;
    purchaseOrderNumber?: Maybe<Scalars['String']>;
    purchaseRequestName?: Maybe<Scalars['String']>;
    purchaseRequestItemName?: Maybe<Scalars['String']>;
    skuVariantCode?: Maybe<Scalars['String']>;
    warehouseCode?: Maybe<Scalars['String']>;
    orderNumber?: Maybe<Scalars['String']>;
};

export type AssetModel = {
    __typename?: 'AssetModel';
    isDeviceLocked?: Maybe<Scalars['Boolean']>;
    serialNumber?: Maybe<Scalars['String']>;
    uid: Scalars['ID'];
    skuUid?: Maybe<Scalars['String']>;
    skuVariantCode?: Maybe<Scalars['String']>;
    stockUid?: Maybe<Scalars['String']>;
    stock?: Maybe<Stock>;
    name?: Maybe<Scalars['String']>;
    status?: Maybe<AssetStatus>;
    statusUpdatedAt?: Maybe<Scalars['DateTime']>;
    condition?: Maybe<AssetCondition>;
    variant?: Maybe<Variant>;
    activeContract?: Maybe<Contract>;
    supplierName?: Maybe<Scalars['String']>;
    capitalSourceName?: Maybe<Scalars['String']>;
    salesforceId?: Maybe<Scalars['String']>;
    salesforceStatus?: Maybe<Scalars['String']>;
    salesforceStockUid?: Maybe<Scalars['String']>;
    salesforceSupplierId?: Maybe<Scalars['String']>;
    salesforceCapitalSourceId?: Maybe<Scalars['String']>;
    salesforceLastModifiedDate?: Maybe<Scalars['DateTime']>;
    warehouseCode?: Maybe<Scalars['String']>;
    purchaseRequest?: Maybe<PurchaseRequest>;
    purchaseRequestItem?: Maybe<PurchaseRequestItem>;
    allocations: Array<AssetAllocation>;
    events: Array<AssetEventModel>;
    lostReason?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    deletedAt?: Maybe<Scalars['DateTime']>;
    shipments: Array<Shipment>;
};

export enum AssetStatus {
    CrossSale = 'CrossSale',
    Deleted = 'Deleted',
    Donated = 'Donated',
    InStock = 'InStock',
    OnLoan = 'OnLoan',
    Purchased = 'Purchased',
    Office = 'Office',
    Reserved = 'Reserved',
    Recovered = 'Recovered',
    InDebtCollection = 'InDebtCollection',
    InRepair = 'InRepair',
    Lost = 'Lost',
    LostSolved = 'LostSolved',
    Returned = 'Returned',
    LockedDevice = 'LockedDevice',
    Irreparable = 'Irreparable',
    Incomplete = 'Incomplete',
    Warranty = 'Warranty',
    ReturnedToSupplier = 'ReturnedToSupplier',
    SentForRefurbishment = 'SentForRefurbishment',
    Selling = 'Selling',
    Sold = 'Sold',
    TransferredToWarehouse = 'TransferredToWarehouse',
    InvestigationCarrier = 'InvestigationCarrier',
    InvestigationWarehouse = 'InvestigationWarehouse',
    Insolvency = 'Insolvency',
    WaitingForRefurbishment = 'WaitingForRefurbishment',
    Refurbishing = 'Refurbishing',
    Refurbished = 'Refurbished',
    WrittenOffDc = 'WrittenOffDc',
    WrittenOffOps = 'WrittenOffOps',
    InboundQuarantine = 'InboundQuarantine',
    InboundDamaged = 'InboundDamaged',
    InboundUnallocable = 'InboundUnallocable',
    ReportedAsStolen = 'ReportedAsStolen',
}

export type AssetUpdateError = {
    __typename?: 'AssetUpdateError';
    uid?: Maybe<Scalars['ID']>;
    serialNumber?: Maybe<Scalars['String']>;
    success?: Maybe<Scalars['Boolean']>;
    status?: Maybe<AssetStatus>;
    errors?: Maybe<Array<Scalars['String']>>;
};

export type AssociateProductInput = {
    sku: Scalars['String'];
};

export type AttentionNote = TextNoteInterface & {
    __typename?: 'AttentionNote';
    text?: Maybe<Scalars['String']>;
};

export type AuthDevice = {
    __typename?: 'AuthDevice';
    createdAt: Scalars['ISO8601DateTime'];
    deviceType: AuthDeviceType;
    history: Array<AuthDeviceHistoryItem>;
    id: Scalars['ID'];
    lastLocation?: Maybe<AuthDeviceHistoryItem>;
    os: Os;
    platform: Platform;
    stale: Scalars['Boolean'];
    userAgent: Scalars['String'];
};

/** The connection type for AuthDevice. */
export type AuthDeviceConnection = {
    __typename?: 'AuthDeviceConnection';
    /** A list of edges. */
    edges?: Maybe<Array<Maybe<AuthDeviceEdge>>>;
    /** A list of nodes. */
    nodes?: Maybe<Array<Maybe<AuthDevice>>>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AuthDeviceEdge = {
    __typename?: 'AuthDeviceEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
    /** The item at the end of the edge. */
    node?: Maybe<AuthDevice>;
};

export type AuthDeviceHistoryItem = {
    __typename?: 'AuthDeviceHistoryItem';
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    countryIso?: Maybe<Scalars['String']>;
    createdAt: Scalars['ISO8601DateTime'];
    ipAddress: Scalars['String'];
    lastSeenAt: Scalars['ISO8601DateTime'];
};

export type AuthDeviceInfo = {
    deviceId: Scalars['String'];
    keyPurpose?: Maybe<KeyPurpose>;
};

export type AuthDeviceResult = {
    __typename?: 'AuthDeviceResult';
    id: Scalars['String'];
    createdAt: Scalars['DateTime'];
    type: Scalars['String'];
    expiresAt: Scalars['DateTime'];
    stringToSign: Scalars['String'];
};

export enum AuthDeviceType {
    DESKTOP = 'DESKTOP',
    MOBILE = 'MOBILE',
    OTHER = 'OTHER',
    TABLET = 'TABLET',
}

export type AuthorizeScaChallengesInfo = {
    scaChallengeId: Scalars['String'];
    deviceId: Scalars['String'];
    changeRequestId: Scalars['String'];
};

export type AutoRegenerateFields = {
    companyName?: Maybe<Scalars['Boolean']>;
    lastName?: Maybe<Scalars['Boolean']>;
    firstName?: Maybe<Scalars['Boolean']>;
    billingAddress?: Maybe<Scalars['Boolean']>;
    shippingAddress?: Maybe<Scalars['Boolean']>;
    ust?: Maybe<Scalars['Boolean']>;
};

export type AvailabilityCampaign = {
    __typename?: 'AvailabilityCampaign';
    uid: Scalars['ID'];
    type?: Maybe<AvailabilityCampaignType>;
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    status?: Maybe<AvailabilityCampaignStatus>;
    storeIds?: Maybe<Array<Scalars['Int']>>;
    supplierNames?: Maybe<Array<Scalars['String']>>;
    defaultThreshold?: Maybe<Scalars['Float']>;
    skuThresholds?: Maybe<Array<AvailabilityCampaignSkuThreshold>>;
    startDate?: Maybe<Scalars['DateTime']>;
    finishDate?: Maybe<Scalars['DateTime']>;
    startAvailability?: Maybe<AvailabilityMode>;
    finalAvailability?: Maybe<AvailabilityMode>;
    allocationDate?: Maybe<Scalars['DateTime']>;
    createdBy?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    createdAt?: Maybe<Scalars['DateTime']>;
};

export type AvailabilityCampaignCollection = {
    __typename?: 'AvailabilityCampaignCollection';
    count: Scalars['Float'];
    rows: Array<AvailabilityCampaign>;
};

export type AvailabilityCampaignFilterInput = {
    uid?: Maybe<Scalars['ID']>;
    status?: Maybe<AvailabilityCampaignStatus>;
    skuVariantCode?: Maybe<Scalars['String']>;
    storeId?: Maybe<Scalars['Float']>;
    type?: Maybe<AvailabilityCampaignType>;
};

export type AvailabilityCampaignSkuThreshold = {
    __typename?: 'AvailabilityCampaignSkuThreshold';
    skuVariantCode: Scalars['String'];
    threshold?: Maybe<Scalars['Float']>;
};

export type AvailabilityCampaignSkuThresholdInput = {
    skuVariantCode: Scalars['String'];
    threshold?: Maybe<Scalars['Float']>;
};

export enum AvailabilityCampaignStatus {
    Created = 'Created',
    Active = 'Active',
    Closed = 'Closed',
    Cancelled = 'Cancelled',
}

export enum AvailabilityCampaignType {
    PreOrder = 'PreOrder',
    Live = 'Live',
}

export type AvailabilityCampaignUpsertInput = {
    uid?: Maybe<Scalars['ID']>;
    type?: Maybe<AvailabilityCampaignType>;
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    storeIds?: Maybe<Array<Scalars['Int']>>;
    supplierNames?: Maybe<Array<Scalars['String']>>;
    defaultThreshold?: Maybe<Scalars['Float']>;
    skuThresholds?: Maybe<Array<AvailabilityCampaignSkuThresholdInput>>;
    startDate?: Maybe<Scalars['DateTime']>;
    finishDate?: Maybe<Scalars['DateTime']>;
    startAvailability?: Maybe<AvailabilityMode>;
    finalAvailability?: Maybe<AvailabilityMode>;
    allocationDate?: Maybe<Scalars['DateTime']>;
};

export enum AvailabilityMode {
    Enabled = 'Enabled',
    Automatic = 'Automatic',
    Disabled = 'Disabled',
    WaitingList = 'WaitingList',
}

export enum B2BDashboardAccessRightStateGql {
    ACTIVATED = 'ACTIVATED',
    DEACTIVATED = 'DEACTIVATED',
}

/** Mimics behavior from grover_api, to ease out migration */
export type B2BIntegerComparisonInput = {
    _eq?: Maybe<Scalars['Int']>;
    _neq?: Maybe<Scalars['Int']>;
    _gt?: Maybe<Scalars['Int']>;
    _lt?: Maybe<Scalars['Int']>;
};

/** Mimics behavior from grover_api, to ease out migration */
export type B2BStringComparisonInput = {
    _eq?: Maybe<Scalars['String']>;
    _neq?: Maybe<Scalars['String']>;
    _substr?: Maybe<Scalars['String']>;
    _sw?: Maybe<Scalars['String']>;
    _ew?: Maybe<Scalars['String']>;
};

export type BankAccountSnapshotDataKontomatikInput = {
    sessionId: Scalars['ID'];
    sessionIdSignature: Scalars['String'];
};

export type BankAccountSnapshotDataPlaidInput = {
    publicToken: Scalars['String'];
};

export type BasicAmount = {
    __typename?: 'BasicAmount';
    cents: Scalars['Int'];
    currency: Scalars['String'];
};

export type BasicContract = {
    actions?: Maybe<Array<ContractAction>>;
    activatedAt?: Maybe<Scalars['ISO8601DateTime']>;
    billingAccountId: Scalars['ID'];
    billingTerms: BillingTerms;
    cancelReturnTerm: CancelReturnTerm;
    createdAt: Scalars['ISO8601DateTime'];
    currentFlow?: Maybe<Flow>;
    customerWishCancelTerm?: Maybe<CustomerWishCancelTerm>;
    customerWishKeepChargingTerm?: Maybe<CustomerWishKeepChargingTerm>;
    damageClaimTerm?: Maybe<DamageClaimTerm>;
    durationTerms: DurationTerms;
    earlyOrderCancellationTerm: EarlyOrderCancellationTerm;
    endingTerm: EndingTerm;
    flow?: Maybe<Flows>;
    good?: Maybe<Goods>;
    goods?: Maybe<Array<Goods>>;
    id: Scalars['ID'];
    internalDebtCollectionTerm?: Maybe<InternalDebtCollectionTerm>;
    manualPauseTerm: ManualPauseTerm;
    order: Order;
    pausedAt?: Maybe<Scalars['ISO8601DateTime']>;
    revivalTerm: RevivalTerm;
    revocationTerms?: Maybe<RevocationTerms>;
    state: ContractState;
    surpriseDiscountTerm: SurpriseDiscountTerm;
    terminatedAt?: Maybe<Scalars['ISO8601DateTime']>;
    terminationComment?: Maybe<Scalars['String']>;
    terminationReason?: Maybe<ContractTerminationReason>;
    tosUpdates: Array<TosUpdate>;
    tosVersion?: Maybe<Scalars['ID']>;
    upsellTerm?: Maybe<UpsellTerm>;
    userId: Scalars['ID'];
    orderNumber: Scalars['ID'];
};

export type BasicContractGoodArgs = {
    id: Scalars['ID'];
};

export type BasicMarketingCardContentModel = {
    __typename?: 'BasicMarketingCardContentModel';
    uuid: Scalars['ID'];
    imageUrl?: Maybe<Scalars['String']>;
    largeImage?: Maybe<Scalars['Boolean']>;
    /** Markdown template. Commonly a placeholder for translations. Example: {{thankyou}}. thankyou is a translate placeholder */
    bodyTemplate: Scalars['String'];
    actions?: Maybe<Array<ActionUnionModel>>;
};

export type BasicMarketingCardModel = {
    __typename?: 'BasicMarketingCardModel';
    uuid: Scalars['ID'];
    imageUrl?: Maybe<Scalars['String']>;
    largeImage?: Maybe<Scalars['Boolean']>;
    /** Markdown template. Commonly a placeholder for translations. Example: {{thankyou}}. thankyou is a translate placeholder */
    bodyTemplate: Scalars['String'];
    actions?: Maybe<Array<ActionUnionModel>>;
    colorFunction?: Maybe<ColorFunctions>;
};

export type BasicReturnStep = {
    description: Scalars['String'];
    headline: Scalars['String'];
    name: Scalars['String'];
    warning?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of BatchCancelContracts */
export type BatchCancelContractsInput = {
    cancellationReason: ContractCancellationReason;
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contractIds: Array<Scalars['ID']>;
    debtCollectionAgency?: Maybe<DebtCollectionAgency>;
    productVariantState: ContractGoodState;
};

/** Autogenerated return type of BatchCancelContracts. */
export type BatchCancelContractsPayload = {
    __typename?: 'BatchCancelContractsPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contracts?: Maybe<Array<Contract>>;
    errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of BatchPauseContracts */
export type BatchPauseContractsInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    comment?: Maybe<Scalars['String']>;
    contractIds?: Maybe<Array<Scalars['ID']>>;
    days: Scalars['Int'];
    orderNumbers?: Maybe<Array<Scalars['ID']>>;
    reason: PauseReason;
};

/** Autogenerated return type of BatchPauseContracts. */
export type BatchPauseContractsPayload = {
    __typename?: 'BatchPauseContractsPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contracts?: Maybe<Array<Contract>>;
    errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of BatchReviveContracts */
export type BatchReviveContractsInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contractIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of BatchReviveContracts. */
export type BatchReviveContractsPayload = {
    __typename?: 'BatchReviveContractsPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contracts?: Maybe<Array<Contract>>;
    errors?: Maybe<Array<Scalars['String']>>;
};

export type BigMigrationCreateRecurrentPaymentsContractInput = {
    id: Scalars['String'];
    subscriptionPayments: Array<BigMigrationCreateRecurrentPaymentsContractPaymentInput>;
    nextPeriodNumber: Scalars['Int'];
};

export type BigMigrationCreateRecurrentPaymentsContractPaymentInput = {
    dueDate: Scalars['String'];
    period: Scalars['Int'];
    paid: Scalars['Float'];
    amount: Scalars['Float'];
    state: Scalars['String'];
    createdAt: Scalars['String'];
    updatedAt: Scalars['String'];
    referenceId?: Maybe<Scalars['String']>;
};

export type BigMigrationCreateRecurrentPaymentsInput = {
    userId: Scalars['String'];
    billingAccountId: Scalars['String'];
    orders: Array<BigMigrationCreateRecurrentPaymentsOrderInput>;
    useConsolidatedBilling: Scalars['Boolean'];
};

export type BigMigrationCreateRecurrentPaymentsOrderInput = {
    orderNumber: Scalars['String'];
    currency: Scalars['String'];
    totalInCents: Scalars['Int'];
    orderPaymentCreatedAt: Scalars['String'];
    orderPaymentUpdatedAt: Scalars['String'];
    contracts: Array<BigMigrationCreateRecurrentPaymentsContractInput>;
    lineItems: Array<Scalars['JSON']>;
    additionalData: Scalars['JSON'];
};

export type BigMigrationCreateRecurrentPaymentsPayload = {
    __typename?: 'BigMigrationCreateRecurrentPaymentsPayload';
    success: Scalars['Boolean'];
    result: Scalars['JSON'];
};

export type BigMigrationRevertRecurrentPaymentsContractInput = {
    id: Scalars['String'];
};

export type BigMigrationRevertRecurrentPaymentsInput = {
    userId: Scalars['String'];
    billingAccountId: Scalars['String'];
    orders: Array<BigMigrationRevertRecurrentPaymentsOrderInput>;
    ignoreShippingPayment: Scalars['Boolean'];
};

export type BigMigrationRevertRecurrentPaymentsOrderInput = {
    orderNumber: Scalars['String'];
    contracts: Array<BigMigrationRevertRecurrentPaymentsContractInput>;
};

export enum BillingMode {
    PREPAID = 'PREPAID',
}

export type BillingPeriod = Period & {
    __typename?: 'BillingPeriod';
    contractId: Scalars['ID'];
    number: Scalars['Int'];
    payment?: Maybe<ContractPaymentGroupModel>;
    amountDue: Price;
    discounts?: Maybe<Array<ContractDiscounts>>;
    ends: Scalars['ISO8601DateTime'];
    starts: Scalars['ISO8601DateTime'];
};

export enum BillingServicePaymentProcess {
    Basic = 'Basic',
    PaymentServiceProvider = 'PaymentServiceProvider',
    ManualBooking = 'ManualBooking',
    PayByInvoice = 'PayByInvoice',
}

export type BillingTerms = {
    __typename?: 'BillingTerms';
    basePrice: Price;
    currentPeriod?: Maybe<BillingPeriod>;
    discounts?: Maybe<Array<ContractDiscounts>>;
    nextPeriod?: Maybe<BillingPeriod>;
    period?: Maybe<BillingPeriod>;
    periodLength: Duration;
    price: Price;
    type: BillingMode;
};

export type BillingTermsPeriodArgs = {
    number: Scalars['Int'];
};

export type BlockCardInfo = {
    cardId: Scalars['String'];
};

export type BookingMetaInfo = {
    __typename?: 'BookingMetaInfo';
    transactionDate: Scalars['DateTime'];
    transactionTime: Scalars['DateTime'];
    posEntryMode: PosEntryModeTypes;
    transactionType: CardTransactionTypes;
    originalAmount: OriginalAmount;
    merchant: Merchant;
};

export type BookingsInfo = {
    page: Scalars['Float'];
    perPage?: Maybe<Scalars['Float']>;
};

export type BookPbiInput = {
    movementID?: Maybe<Scalars['ID']>;
    scheduleID?: Maybe<Scalars['ID']>;
    allocate: Scalars['Float'];
    reason: Scalars['String'];
    paymentSource: Scalars['String'];
    paymentReference: Scalars['String'];
    effectiveDate: Scalars['ISO8601Date'];
};

export type BookPbiPayload = {
    __typename?: 'BookPBIPayload';
    movement?: Maybe<Movement>;
};

export type BoolFilter = {
    equals?: Maybe<Scalars['Boolean']>;
    not?: Maybe<NestedBoolFilter>;
};

export type BulkAssociationAddonsInput = {
    associations: Array<UpdateAllAssociationInput>;
};

export type BulkAssociationAddonsResult = {
    __typename?: 'BulkAssociationAddonsResult';
    /** Taking into account DigitalOfferings ONLY */
    successfulCount: Scalars['Int'];
    /** Taking into account DigitalOfferings ONLY */
    errorRows: Array<BulkAssociationErrorRow>;
};

export type BulkAssociationErrorRow = {
    __typename?: 'BulkAssociationErrorRow';
    mainProductSku: Scalars['String'];
    storeCode: Scalars['String'];
    digitalOtpProductSkus: Array<Scalars['String']>;
};

export type BulkChargeForDamagedAssetsError = {
    __typename?: 'BulkChargeForDamagedAssetsError';
    allocationId: Scalars['String'];
    error: Scalars['String'];
};

export type BulkChargeForDamagedAssetsInput = {
    items: Array<BulkChargeForDamagedAssetsItemInput>;
};

export type BulkChargeForDamagedAssetsItemInput = {
    allocationId: Scalars['String'];
    assetId: Scalars['String'];
    amountToChargeInCents: Scalars['Float'];
    reason: Scalars['String'];
    chargeType: Scalars['String'];
};

export type BulkChargeForDamagedAssetsResult = {
    __typename?: 'BulkChargeForDamagedAssetsResult';
    message: Scalars['String'];
    failedAllocations: Array<BulkChargeForDamagedAssetsError>;
};

export type BulkDashboardAccessInput = {
    /** CompanyIds that the access should be toggled for. */
    companyIds: Array<Scalars['Int']>;
};

export type BulkDeliveryProfileRuleMatchInput = {
    type?: Maybe<DeliveryType>;
    country?: Maybe<Scalars['String']>;
    orderMode?: Maybe<DeliveryOrderMode>;
    skuProducts?: Maybe<Array<Scalars['String']>>;
    customerType?: Maybe<CustomerType>;
    warehouseCode?: Maybe<WarehouseCode>;
    userId?: Maybe<Scalars['String']>;
    orderNumber?: Maybe<Scalars['String']>;
    preferredCarrier?: Maybe<CarrierName>;
    priority?: Maybe<Scalars['Int']>;
    requestId: Scalars['String'];
};

export type BulkDeliveryProfileRuleMatchResult = {
    __typename?: 'BulkDeliveryProfileRuleMatchResult';
    profile?: Maybe<DeliveryProfile>;
    error?: Maybe<Scalars['String']>;
    requestId: Scalars['String'];
};

export type BulkUpdateError = {
    success?: Maybe<Scalars['Boolean']>;
    errors?: Maybe<Array<AssetUpdateError>>;
    bulkError?: Maybe<Scalars['String']>;
};

export type BulkUpdateParams = {
    assetIds?: Maybe<Array<Scalars['String']>>;
    serialNumbers?: Maybe<Array<Scalars['String']>>;
};

export type Bundle = {
    __typename?: 'Bundle';
    id: Scalars['Int'];
    title?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    store: Scalars['String'];
    active: Scalars['Boolean'];
    userId: Scalars['Int'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    mainProductSku: Scalars['String'];
    /** Fetches product by sku. Prefer `mainProductSku` if needed only sku */
    mainProduct?: Maybe<Product>;
    secondarySkus: Array<Scalars['String']>;
    /** Fetches product by sku. Prefer `secondarySkus` if needed only sku */
    secondaryProducts?: Maybe<Array<Maybe<Product>>>;
};

export type BundleBulkCreateInput = {
    bundles?: Maybe<Array<Maybe<BundleCreateInput>>>;
};

export type BundleCreateInput = {
    active?: Maybe<Scalars['Boolean']>;
    title?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    store: Scalars['String'];
    mainProductSku: Scalars['String'];
    secondarySkus: Array<Scalars['String']>;
};

export type BundleError = {
    __typename?: 'BundleError';
    message?: Maybe<Scalars['String']>;
    code?: Maybe<Scalars['String']>;
};

export type BundleOrderByInput = {
    active?: Maybe<SortOrder>;
    createdAt?: Maybe<SortOrder>;
    description?: Maybe<SortOrder>;
    id?: Maybe<SortOrder>;
    mainProductSku?: Maybe<SortOrder>;
    secondarySkus?: Maybe<SortOrder>;
    store?: Maybe<SortOrder>;
    title?: Maybe<SortOrder>;
    updatedAt?: Maybe<SortOrder>;
    userId?: Maybe<SortOrder>;
};

export type BundleOrError = Bundle | BundleError;

export type BundlePaginationOutput = {
    __typename?: 'BundlePaginationOutput';
    totalCount: Scalars['Int'];
    rows: Array<Bundle>;
};

export type BundlesExportTask = Task & {
    __typename?: 'BundlesExportTask';
    id: Scalars['ID'];
    createdAt: Scalars['DateTime'];
    status: TaskStatus;
    createdByUserId: Scalars['Int'];
    createdBy?: Maybe<User>;
    fileUri?: Maybe<Scalars['String']>;
    fileName: Scalars['String'];
};

export type BundleUpdateInput = {
    active?: Maybe<Scalars['Boolean']>;
    title?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    store?: Maybe<Scalars['String']>;
    mainProductSku?: Maybe<Scalars['String']>;
    secondarySkus?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BundleWhereInput = {
    AND?: Maybe<Array<BundleWhereInput>>;
    NOT?: Maybe<Array<BundleWhereInput>>;
    OR?: Maybe<Array<BundleWhereInput>>;
    active?: Maybe<BoolFilter>;
    createdAt?: Maybe<DateTimeFilter>;
    description?: Maybe<StringNullableFilter>;
    id?: Maybe<IntFilter>;
    mainProductSku?: Maybe<StringFilter>;
    secondarySkus?: Maybe<StringNullableListFilter>;
    store?: Maybe<StringFilter>;
    title?: Maybe<StringNullableFilter>;
    updatedAt?: Maybe<DateTimeFilter>;
    userId?: Maybe<IntFilter>;
};

export type BundleWhereUniqueInput = {
    id: Scalars['Int'];
};

export type BusinessAssetReturnMetadata = {
    __typename?: 'BusinessAssetReturnMetadata';
    uuid: Scalars['ID'];
    /** Work-Service Asset ID */
    assetUuid: Scalars['ID'];
    /** Juno Asset ID */
    assetId: Scalars['ID'];
    /** Loop Contract ID */
    contractId: Scalars['ID'];
    email: Scalars['String'];
    employeeUuid?: Maybe<Scalars['ID']>;
    employee?: Maybe<GroverEmployee>;
    createdBy: Scalars['ID'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
};

export type BusinessDashboard = {
    __typename?: 'BusinessDashboard';
    enabled: Scalars['Boolean'];
    /** User from GroverAPI through 'userId' */
    activatedAt?: Maybe<Scalars['DateTime']>;
    activatedBy?: Maybe<User>;
    /** User from GroverAPI through 'userId' */
    deactivatedAt?: Maybe<Scalars['DateTime']>;
    deactivatedBy?: Maybe<User>;
};

export type Buyout = {
    __typename?: 'Buyout';
    /** @deprecated Use graphql __typename instead */
    action: Scalars['String'];
    available?: Maybe<Scalars['Boolean']>;
    availableAt?: Maybe<Scalars['ISO8601DateTime']>;
    deactivated?: Maybe<Scalars['Boolean']>;
    discountOfferPercentage?: Maybe<Scalars['Int']>;
    discountOfferPrice?: Maybe<Price>;
    initialPrice?: Maybe<Price>;
    minimalCartPrice?: Maybe<Price>;
    purchasePrice?: Maybe<Price>;
    remainingMonths?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    unavailableReason?: Maybe<Scalars['String']>;
    unavailableSince?: Maybe<Scalars['ISO8601DateTime']>;
};

export type BuyoutCalculation = {
    __typename?: 'BuyoutCalculation';
    marketPriceWithMarkupInCents: Scalars['Int'];
    markup: Scalars['Float'];
    requiredMonths?: Maybe<Scalars['Int']>;
};

export type ByInvoice = PaymentMethodInterface & {
    __typename?: 'ByInvoice';
    billingAccountId?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    viban?: Maybe<Scalars['String']>;
    contracts: Array<BasicContract>;
};

/** Invoice payment method from Wallet service. Eventually will deprecate the ByInvoice type. */
export type ByInvoicePaymentMethod = PaymentMethod2 & {
    __typename?: 'ByInvoicePaymentMethod';
    id: Scalars['ID'];
    status: PaymentMethodStatus;
    /** Date/time of the method creation. Empty in case of a legacy method. */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** URL to redirect the user to if authorization is required by the payment provider. */
    redirectUrl?: Maybe<Scalars['String']>;
    /** ID of the corresponding method in Grover API. */
    groverApiId?: Maybe<Scalars['Float']>;
    /** ID of the corresponding account in Billing Service. */
    billingAccountId?: Maybe<Scalars['String']>;
    /** Contracts and subscriptions that are using this method. */
    linkedContracts: Array<FlexDetails>;
    /** Method's external ID in IXOPAY. */
    ixopayReferenceId?: Maybe<Scalars['String']>;
    /**
     * Present if the method deletion is currently forbidden, contains details about
     * the reason. Warning: currently does not return "has linked contracts", which
     * is one of the possible reasons for deletion lock. Check the linkedContracts field instead.
     */
    deletionLock?: Maybe<PaymentMethodDeletionLock>;
    /** Error code classifying the method registration failure, if any. */
    registrationErrorCode?: Maybe<TransactionErrorCode>;
    /** Returns status of this method in relation to the given order. */
    statusForOrder: PaymentMethodStatusForOrderResult;
    viban?: Maybe<Scalars['String']>;
};

/** Invoice payment method from Wallet service. Eventually will deprecate the ByInvoice type. */
export type ByInvoicePaymentMethodStatusForOrderArgs = {
    orderNumber: Scalars['String'];
};

export type CampaignFilterInputType = {
    level?: Maybe<LevelTypes>;
    status?: Maybe<StatusTypes>;
};

export type CampaignInputType = {
    name: Scalars['String'];
    type: CampaignTypes;
    level: LevelTypes;
    status?: Maybe<StatusTypes>;
    conditionUuid?: Maybe<Scalars['String']>;
    pages?: Maybe<Array<Scalars['String']>>;
    platforms?: Maybe<Array<PlatformTypes>>;
    stores?: Maybe<Array<Scalars['String']>>;
    content: Scalars['AnyScalar'];
};

export type CampaignModel = {
    __typename?: 'CampaignModel';
    uuid: Scalars['ID'];
    name: Scalars['String'];
    type: CampaignTypes;
    level: Scalars['String'];
    status: StatusTypes;
    content?: Maybe<ContentUnionModel>;
    conditionUuid?: Maybe<Scalars['String']>;
    condition?: Maybe<ConditionsContentModel>;
    translate: Scalars['JSON'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    pages?: Maybe<Array<Scalars['String']>>;
    platforms?: Maybe<Array<PlatformTypes>>;
    stores?: Maybe<Array<Scalars['String']>>;
    /** Calculates dismissed, finished and usersInCampaign metrics */
    metrics: MetricsModel;
    /** Returns goal entity */
    goal?: Maybe<GoalModel>;
};

export enum CampaignTypes {
    basicMarketingCard = 'basicMarketingCard',
    subscriptionBadge = 'subscriptionBadge',
    hint = 'hint',
    pollCard = 'pollCard',
    confirmationPollCard = 'confirmationPollCard',
}

export type CampaignUserStateModel = {
    __typename?: 'CampaignUserStateModel';
    id: Scalars['String'];
    campaignId: Scalars['String'];
    status: Scalars['String'];
    campaignName: Scalars['String'];
};

/** Autogenerated input type of CancelContract */
export type CancelContractInput = {
    cancellationComment?: Maybe<Scalars['String']>;
    cancellationReason: ContractCancellationReason;
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contractId: Scalars['ID'];
    productVariantState: ContractGoodState;
};

/** Autogenerated return type of CancelContract. */
export type CancelContractPayload = {
    __typename?: 'CancelContractPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract?: Maybe<Contract>;
    errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of CancelContractReturn */
export type CancelContractReturnInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contractId: Scalars['ID'];
};

/** Autogenerated return type of CancelContractReturn. */
export type CancelContractReturnPayload = {
    __typename?: 'CancelContractReturnPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract: Contract;
};

export type CancelOrderEarly = {
    __typename?: 'CancelOrderEarly';
    /** @deprecated Use graphql __typename instead */
    action: Scalars['String'];
    available: Scalars['Boolean'];
};

/** Autogenerated input type of CancelOrderEarly */
export type CancelOrderEarlyInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    orderNumber: Scalars['ID'];
};

/** Autogenerated return type of CancelOrderEarly. */
export type CancelOrderEarlyPayload = {
    __typename?: 'CancelOrderEarlyPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contracts: Array<Contract>;
};

export type CancelReturnTerm = {
    __typename?: 'CancelReturnTerm';
    available: Scalars['Boolean'];
    unavailabilityReason?: Maybe<CancelReturnUnavailabilityReason>;
};

export enum CancelReturnUnavailabilityReason {
    ASSETS_ALREADY_ALLOCATED = 'ASSETS_ALREADY_ALLOCATED',
    ASSETS_ALREADY_SHIPPED = 'ASSETS_ALREADY_SHIPPED',
    NEW_ASSETS_ALREADY_SHIPPED = 'NEW_ASSETS_ALREADY_SHIPPED',
    NON_RETURN_FLOW_IN_PROGRESS = 'NON_RETURN_FLOW_IN_PROGRESS',
    NO_RETURN_IN_PROGRESS = 'NO_RETURN_IN_PROGRESS',
}

export type CancelShipmentsResult = {
    __typename?: 'CancelShipmentsResult';
    uids: Array<Scalars['String']>;
};

export type CancelSubscription = {
    __typename?: 'CancelSubscription';
    available: Scalars['Boolean'];
};

/** Autogenerated input type of CancelSwap */
export type CancelSwapInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    orderNumber: Scalars['ID'];
};

/** Autogenerated return type of CancelSwap. */
export type CancelSwapPayload = {
    __typename?: 'CancelSwapPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    error?: Maybe<SwapCancellationError>;
    swap?: Maybe<Swap>;
};

export type CancelTopUpInfo = {
    topUpId: Scalars['String'];
};

export type CancelTopUpResult = {
    __typename?: 'CancelTopUpResult';
    id: Scalars['String'];
    clientSecret: Scalars['String'];
    status: TopUpStatusValues;
    acquirerErrorCode?: Maybe<Scalars['String']>;
    acquirerDeclineCode?: Maybe<Scalars['String']>;
    cancellationReason?: Maybe<TopUpCancellationReasons>;
    declineReason?: Maybe<Scalars['String']>;
};

export type Card = PaymentMethodInterface & {
    __typename?: 'Card';
    adyenContractNumber?: Maybe<Scalars['String']>;
    bankName?: Maybe<Scalars['String']>;
    billingAccountId?: Maybe<Scalars['String']>;
    brand?: Maybe<Scalars['String']>;
    countryIso?: Maybe<Scalars['String']>;
    expirationMonth?: Maybe<Scalars['String']>;
    expirationYear?: Maybe<Scalars['String']>;
    fingerprint?: Maybe<Scalars['String']>;
    firstDigits?: Maybe<Scalars['String']>;
    holderName?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    lastDigits?: Maybe<Scalars['String']>;
    level?: Maybe<Scalars['String']>;
    merchantTransactionId?: Maybe<Scalars['String']>;
    referenceId?: Maybe<Scalars['String']>;
    threedSecure?: Maybe<Scalars['Boolean']>;
    type?: Maybe<Scalars['String']>;
    contracts: Array<BasicContract>;
};

export type CardAmount = {
    __typename?: 'CardAmount';
    value: Scalars['Int'];
    unit: CardAmountUnitTypes;
    currency: Scalars['String'];
};

export enum CardAmountUnitTypes {
    cents = 'cents',
}

export type CardBlockUnblockResult = {
    __typename?: 'CardBlockUnblockResult';
    status: CardStatus;
};

export type CardBooking = {
    __typename?: 'CardBooking';
    id: Scalars['String'];
    loyaltyAction?: Maybe<LoyaltyFederatedBooking>;
    description?: Maybe<Scalars['String']>;
    date?: Maybe<Scalars['DateTime']>;
    amount: CardAmount;
    bookingType: Scalars['String'];
    recipientBic?: Maybe<Scalars['String']>;
    recipientIban?: Maybe<Scalars['String']>;
    recipientName?: Maybe<Scalars['String']>;
    senderBic?: Maybe<Scalars['String']>;
    senderIban?: Maybe<Scalars['String']>;
    senderName?: Maybe<Scalars['String']>;
    endToEndId?: Maybe<Scalars['String']>;
    transactionId?: Maybe<Scalars['String']>;
    returnTransactionId?: Maybe<Scalars['String']>;
    metaInfo?: Maybe<BookingMetaInfo>;
};

export enum CardLossReason {
    HOME_INVASION = 'HOME_INVASION',
    PICK_POCKET = 'PICK_POCKET',
    LEFT_AT_MERCHANT = 'LEFT_AT_MERCHANT',
    LOST = 'LOST',
    MISSING_AT_HOME = 'MISSING_AT_HOME',
    SUSPECTED_MERCHANT_FRAUD = 'SUSPECTED_MERCHANT_FRAUD',
    MAIL_ORDER = 'MAIL_ORDER',
    MAILBOX_THEFT = 'MAILBOX_THEFT',
    COUNTERFEIT_PLASTIC = 'COUNTERFEIT_PLASTIC',
    PREVENTIVE_BLOCK = 'PREVENTIVE_BLOCK',
}

/** Credit card payment method from Wallet service. Eventually will deprecate the Card type. */
export type CardPaymentMethod = PaymentMethod2 & {
    __typename?: 'CardPaymentMethod';
    id: Scalars['ID'];
    status: PaymentMethodStatus;
    /** Date/time of the method creation. Empty in case of a legacy method. */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** URL to redirect the user to if authorization is required by the payment provider. */
    redirectUrl?: Maybe<Scalars['String']>;
    /** ID of the corresponding method in Grover API. */
    groverApiId?: Maybe<Scalars['Float']>;
    /** ID of the corresponding account in Billing Service. */
    billingAccountId?: Maybe<Scalars['String']>;
    /** Contracts and subscriptions that are using this method. */
    linkedContracts: Array<FlexDetails>;
    /** Method's external ID in IXOPAY. */
    ixopayReferenceId?: Maybe<Scalars['String']>;
    /**
     * Present if the method deletion is currently forbidden, contains details about
     * the reason. Warning: currently does not return "has linked contracts", which
     * is one of the possible reasons for deletion lock. Check the linkedContracts field instead.
     */
    deletionLock?: Maybe<PaymentMethodDeletionLock>;
    /** Error code classifying the method registration failure, if any. */
    registrationErrorCode?: Maybe<TransactionErrorCode>;
    /** Returns status of this method in relation to the given order. */
    statusForOrder: PaymentMethodStatusForOrderResult;
    bankName?: Maybe<Scalars['String']>;
    holderName?: Maybe<Scalars['String']>;
    expiryMonth?: Maybe<Scalars['String']>;
    expiryYear?: Maybe<Scalars['String']>;
    fingerprint?: Maybe<Scalars['String']>;
    /** First six digits of the credit card's number. */
    firstSixDigits?: Maybe<Scalars['String']>;
    /** Last four digits of the credit card's number. */
    lastFourDigits?: Maybe<Scalars['String']>;
    /** Credit card's level, e.g. 'business', 'standard', 'gold', etc. */
    level?: Maybe<Scalars['String']>;
    /** Credit card's brand, e.g. 'mastercard', 'visa', etc. */
    brand?: Maybe<Scalars['String']>;
    /** Credit card's type, e.g. 'credit', etc. */
    type?: Maybe<Scalars['String']>;
    /** Credit card's country code, e.g. 'DE', 'US', etc. */
    countryCode?: Maybe<Scalars['String']>;
};

/** Credit card payment method from Wallet service. Eventually will deprecate the Card type. */
export type CardPaymentMethodStatusForOrderArgs = {
    orderNumber: Scalars['String'];
};

export enum CardReorderReason {
    HOME_INVASION = 'HOME_INVASION',
    PICK_POCKET = 'PICK_POCKET',
    LEFT_AT_MERCHANT = 'LEFT_AT_MERCHANT',
    LOST = 'LOST',
    MISSING_AT_HOME = 'MISSING_AT_HOME',
    SUSPECTED_MERCHANT_FRAUD = 'SUSPECTED_MERCHANT_FRAUD',
    MAIL_ORDER = 'MAIL_ORDER',
    MAILBOX_THEFT = 'MAILBOX_THEFT',
    COUNTERFEIT_PLASTIC = 'COUNTERFEIT_PLASTIC',
    PREVENTIVE_BLOCK = 'PREVENTIVE_BLOCK',
    BROKEN = 'BROKEN',
    OTHER = 'OTHER',
}

export enum CardStatus {
    PROCESSING = 'PROCESSING',
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED',
    BLOCKED_BY_SOLARIS = 'BLOCKED_BY_SOLARIS',
    ACTIVATION_BLOCKED_BY_SOLARIS = 'ACTIVATION_BLOCKED_BY_SOLARIS',
    CLOSED = 'CLOSED',
    CLOSED_BY_SOLARIS = 'CLOSED_BY_SOLARIS',
    LOST = 'LOST',
    STOLEN = 'STOLEN',
}

export type CardToken = {
    __typename?: 'CardToken';
    id: Scalars['String'];
    cardId: Scalars['String'];
    tokenReferenceId: Scalars['String'];
    tokenStatus: Scalars['String'];
    clientWalletAccountId: Scalars['String'];
    walletType: Scalars['String'];
    visaPanReferenceId?: Maybe<Scalars['String']>;
};

export enum CardTokenStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    DEACTIVATED = 'DEACTIVATED',
    SUSPENDED = 'SUSPENDED',
}

export enum CardTransactionTypes {
    cashAtm = 'cashAtm',
    cashManual = 'cashManual',
    purchase = 'purchase',
    cashAtmReversal = 'cashAtmReversal',
    cashManualReversal = 'cashManualReversal',
    purchaseReversal = 'purchaseReversal',
    oct = 'oct',
    creditPresentment = 'creditPresentment',
    debitPresentment = 'debitPresentment',
    forcePostTransaction = 'forcePostTransaction',
    disputeTransaction = 'disputeTransaction',
    aft = 'aft',
    other = 'other',
}

export type CardTypeUnionModel =
    | BasicMarketingCardModel
    | SubscriptionBadgeModel
    | PollCardModel
    | ConfirmationPollCardModel;

export enum CarrierName {
    Dhl = 'Dhl',
    DhlExpress = 'DhlExpress',
    Ups = 'Ups',
    Hermes2MH = 'Hermes2MH',
    DPD = 'DPD',
    Gel = 'Gel',
    Omni = 'Omni',
    CargoInternational = 'CargoInternational',
}

export enum CarrierServiceAdditionalNameType {
    AdultSignature = 'AdultSignature',
    SaturdayDelivery = 'SaturdayDelivery',
    AccessPointNotification = 'AccessPointNotification',
    DirectDeliveryOnly = 'DirectDeliveryOnly',
    SignatureRequired = 'SignatureRequired',
    AdvanceNotice = 'AdvanceNotice',
    IdentCheck = 'IdentCheck',
    NamedPersonOnly = 'NamedPersonOnly',
    PremiumInternational = 'PremiumInternational',
    VisualAgeCheck = 'VisualAgeCheck',
}

export type CarrierServiceGraphql = {
    __typename?: 'CarrierServiceGraphql';
    name: CarrierServiceNameType;
    additionalServices?: Maybe<Array<CarrierServiceAdditionalNameType>>;
};

export type CarrierServiceInputGraphql = {
    name: CarrierServiceNameType;
    additionalServices?: Maybe<Array<CarrierServiceAdditionalNameType>>;
};

export enum CarrierServiceNameType {
    Ground = 'Ground',
    Standard = 'Standard',
    OneDay = 'OneDay',
    OneDayEarly = 'OneDayEarly',
    ExpressNoon = 'ExpressNoon',
    Expedited = 'Expedited',
    Returns = 'Returns',
    EuroPacket = 'EuroPacket',
    Prio = 'Prio',
    Warenpost = 'Warenpost',
    Express = 'Express',
}

export type Cart = {
    __typename?: 'Cart';
    id: Scalars['String'];
    storeCode: Scalars['String'];
    items: Array<Maybe<CartItem>>;
    userId: Scalars['String'];
    price: CartPrices;
    mode: CartMode;
    billingAddress?: Maybe<Address>;
    shippingAddress?: Maybe<Address>;
};

export type CartAddon = {
    __typename?: 'CartAddon';
    id: Scalars['ID'];
    selectedAddonVariantId?: Maybe<Scalars['String']>;
    product: DigitalOtpProduct;
    selectedVariant?: Maybe<DigitalOtpVariant>;
};

export type CartAddressInput = {
    type?: Maybe<Scalars['String']>;
    additionalInfo?: Maybe<Scalars['String']>;
    city: Scalars['String'];
    country: Scalars['String'];
    countryIso: Scalars['String'];
    id: Scalars['ID'];
    line1: Scalars['String'];
    line2?: Maybe<Scalars['String']>;
    zip: Scalars['String'];
    state?: Maybe<Scalars['String']>;
};

export type CartItem = {
    __typename?: 'CartItem';
    id: Scalars['String'];
    addons?: Maybe<Array<Maybe<Scalars['String']>>>;
    committedMonths: Scalars['Int'];
    price: Scalars['Int'];
    quantity: Scalars['Int'];
    product: Product;
    selectedVariant: Variant;
};

export enum CartMode {
    FLEX = 'FLEX',
}

export type CartOrder = {
    __typename?: 'CartOrder';
    completedAt?: Maybe<Scalars['ISO8601DateTime']>;
    dashboardSubscriptions?: Maybe<SubscriptionCardConnection>;
    guestToken?: Maybe<Scalars['String']>;
    homeAddress?: Maybe<Address>;
    id: Scalars['ID'];
    lineItems: Array<FlexLineItem>;
    orderNumber: Scalars['String'];
    paymentMethod?: Maybe<PaymentMethod>;
    price: Price;
    pricingDetails?: Maybe<PricingDetails>;
    shippingAddress?: Maybe<Address>;
    step?: Maybe<Scalars['String']>;
    store: Store;
};

export type CartOrderDashboardSubscriptionsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

/** The connection type for CartOrder. */
export type CartOrderConnection = {
    __typename?: 'CartOrderConnection';
    /** A list of edges. */
    edges?: Maybe<Array<Maybe<CartOrderEdge>>>;
    /** A list of nodes. */
    nodes?: Maybe<Array<Maybe<CartOrder>>>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type CartOrderCreateInput = {
    orderMode?: Maybe<OrderMode>;
    lineItems: Array<Maybe<MixLineItemInput>>;
    adjustment?: Maybe<AdjustmentInput>;
    shippingDetails?: Maybe<ShippingDetails>;
    kitUuid?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type CartOrderEdge = {
    __typename?: 'CartOrderEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
    /** The item at the end of the edge. */
    node?: Maybe<CartOrder>;
};

export type CartOrderSubmitInput = {
    orderMode?: Maybe<OrderMode>;
    shippingAddress?: Maybe<CartAddressInput>;
    billingAddress?: Maybe<CartAddressInput>;
    paymentMethod?: Maybe<CartPaymentMethodInput>;
    user?: Maybe<CartUserInput>;
    store?: Maybe<CartStoreInput>;
    fraudCheckConsent?: Maybe<Scalars['Boolean']>;
    attemptReference?: Maybe<Scalars['String']>;
    profileReference?: Maybe<Scalars['String']>;
    initiateFraudCheck?: Maybe<Scalars['Boolean']>;
    creditCheckAcceptedAt?: Maybe<Scalars['String']>;
    shippingPreferences?: Maybe<ShippingPreferences>;
    companyId?: Maybe<Scalars['Int']>;
    experianCollectorData?: Maybe<ExperianCollectorDataInput>;
    employeeUuid?: Maybe<Scalars['String']>;
    metaData?: Maybe<MetaDataInput>;
};

export type CartOrderUpdateInput = {
    orderMode?: Maybe<OrderMode>;
    address?: Maybe<CartAddressInput>;
    workServiceAddressUuid?: Maybe<Scalars['ID']>;
    paymentMethod?: Maybe<CartPaymentMethodInput>;
    step?: Maybe<Scalars['String']>;
};

export type CartPaymentMethodInput = {
    id: Scalars['Int'];
    type?: Maybe<Scalars['String']>;
    billingAccountId: Scalars['String'];
};

export type CartPrices = {
    __typename?: 'CartPrices';
    total: Scalars['Int'];
    itemTotal: Scalars['Int'];
    tax?: Maybe<Scalars['Int']>;
    shippingCost?: Maybe<Scalars['Int']>;
    voucher?: Maybe<DiscountInCents>;
    taxRate?: Maybe<Scalars['Int']>;
    currency: Scalars['String'];
};

export type CartStoreInput = {
    storeId?: Maybe<Scalars['ID']>;
    storeType?: Maybe<Scalars['String']>;
    storeCode?: Maybe<Scalars['String']>;
    countryCode?: Maybe<Scalars['String']>;
    countryId?: Maybe<Scalars['Int']>;
    currency?: Maybe<Scalars['String']>;
};

export type CartUserInput = {
    userId: Scalars['Int'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    email: Scalars['String'];
    phone: Scalars['String'];
    birthdate?: Maybe<Scalars['String']>;
    userType?: Maybe<Scalars['String']>;
    language?: Maybe<Scalars['String']>;
};

export type CartVoucher = {
    __typename?: 'CartVoucher';
    code: Scalars['String'];
    discount: Price;
    recurrent: Scalars['Boolean'];
};

export type CartWithKit = {
    __typename?: 'CartWithKit';
    uuid: Scalars['ID'];
    kitUuid: Scalars['String'];
    orderId: Scalars['String'];
    customerId?: Maybe<Scalars['String']>;
    storeCode: Scalars['String'];
    createdAt: Scalars['DateTime'];
    kit?: Maybe<Kit>;
};

export type CatalogAffectedRowsOutput = {
    __typename?: 'CatalogAffectedRowsOutput';
    count: Scalars['Int'];
};

export type CatalogGrouping = {
    __typename?: 'CatalogGrouping';
    id: Scalars['ID'];
    skuList: Array<Scalars['String']>;
    name: Scalars['String'];
};

export type CatalogGroupingUpsertError = GenericError & {
    __typename?: 'CatalogGroupingUpsertError';
    code: Scalars['String'];
    message: Scalars['String'];
};

export type CatalogGroupingUpsertInput = {
    name: Scalars['String'];
    skuList: Array<Scalars['String']>;
};

export type CatalogGroupingUpsertSuccess = {
    __typename?: 'CatalogGroupingUpsertSuccess';
    success: Scalars['Boolean'];
};

export type CatalogGroupingUpsertSuccessOrError =
    | CatalogGroupingUpsertSuccess
    | CatalogGroupingUpsertError;

export type CatalogItem = {
    categoryId: Scalars['ID'];
    product: Product;
    productSku: Scalars['ID'];
    serialNumber?: Maybe<Scalars['ID']>;
    subCategoryId: Scalars['ID'];
};

export type CatalogItemVariant = {
    assetId?: Maybe<Scalars['String']>;
    categoryId: Scalars['ID'];
    product: Product;
    productSku: Scalars['ID'];
    serialNumber?: Maybe<Scalars['ID']>;
    subCategoryId: Scalars['ID'];
    variant: Variant;
    variantSku: Scalars['ID'];
};

export type CatalogRentalPlanUpsertManyFailedOutput = {
    __typename?: 'CatalogRentalPlanUpsertManyFailedOutput';
    sku: Scalars['String'];
    storeCode: Scalars['String'];
};

export type CatalogRentalPlanUpsertManyOutput = {
    __typename?: 'CatalogRentalPlanUpsertManyOutput';
    count: Scalars['Int'];
    failed: Array<CatalogRentalPlanUpsertManyFailedOutput>;
};

export type Category = {
    __typename?: 'Category';
    id: Scalars['ID'];
    name: Scalars['String'];
    permalink?: Maybe<Scalars['String']>;
    /** @deprecated Use Category.translatedTitle instead. */
    title?: Maybe<Scalars['String']>;
    titles: Scalars['JSON'];
    translatedTitle?: Maybe<Scalars['String']>;
    categoryStores: Array<CategoryStore>;
    children?: Maybe<Array<Category>>;
    /** @deprecated Use images.iconSvgPath instead. */
    iconSvgPath?: Maybe<Scalars['String']>;
    /** @deprecated Use Category.images instead. */
    imageAssets?: Maybe<Scalars['JSON']>;
    images: CategoryImages;
    /**
     * This is a the preferred field for the cover image. The one from
     * images.coverImageUrl will return the generic one, the ones on the store have
     * this information too nested.
     */
    coverImageUrl?: Maybe<Scalars['String']>;
    parentId?: Maybe<Scalars['ID']>;
    parent?: Maybe<Category>;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    productFilters?: Maybe<Array<ProductFilter>>;
    /** @deprecated Use Category > CategoryStore > defaultRentalPeriod instead. */
    defaultRentalPlanDuration?: Maybe<Scalars['Int']>;
};

export type CategoryCreateInput = {
    parentId?: Maybe<Scalars['ID']>;
    name: Scalars['String'];
    title: Languages;
    categoryStores: Array<CategoryStoreInput>;
    permalink: Scalars['String'];
    imageAssets?: Maybe<ImageAssets>;
    images?: Maybe<CategoryImagesInput>;
};

export type CategoryError = GenericError & {
    __typename?: 'CategoryError';
    code: Scalars['String'];
    message: Scalars['String'];
};

export type CategoryImages = {
    __typename?: 'CategoryImages';
    iconSvgPath?: Maybe<Scalars['String']>;
    coverImageUrl?: Maybe<Scalars['String']>;
};

export type CategoryImagesInput = {
    iconSvgPath?: Maybe<Scalars['String']>;
    coverImageUrl?: Maybe<Scalars['String']>;
};

export type CategoryMenu = {
    __typename?: 'CategoryMenu';
    mobileLinks: Array<HeaderLink>;
    categories: Array<HeaderCategory>;
    mobileOptionLinks: Array<HeaderLink>;
    localeOptions: Array<HeaderOption>;
    storeOptions: Array<HeaderOption>;
    viewAllButtonText: Scalars['String'];
    categoriesButtonText: Scalars['String'];
    logoutButtonText: Scalars['String'];
    signinButtonText: Scalars['String'];
};

export type CategoryOrError = CategoryError | Category;

export type CategoryPositionUpdateInput = {
    categoryId: Scalars['ID'];
    position: Scalars['Int'];
    storeCode: Scalars['String'];
};

export type CategoryStore = {
    __typename?: 'CategoryStore';
    categoryId: Scalars['ID'];
    storeCode: Scalars['String'];
    contentfulId?: Maybe<Scalars['String']>;
    position: Scalars['Int'];
    defaultRentalPeriod?: Maybe<Scalars['Int']>;
    active: Scalars['Boolean'];
    shippingPrice?: Maybe<Scalars['Int']>;
    /** @deprecated Use images instead. */
    imageAssets?: Maybe<Scalars['JSON']>;
    images: CategoryStoreImages;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
};

export type CategoryStoreImages = {
    __typename?: 'CategoryStoreImages';
    coverImageUrl?: Maybe<Scalars['String']>;
};

export type CategoryStoreImagesInput = {
    coverImageUrl?: Maybe<Scalars['String']>;
};

export type CategoryStoreInput = {
    storeCode: Scalars['String'];
    contentfulId?: Maybe<Scalars['String']>;
    position: Scalars['Int'];
    defaultRentalPeriod?: Maybe<Scalars['Int']>;
    active: Scalars['Boolean'];
    shippingPrice?: Maybe<Scalars['Int']>;
    imageAssets?: Maybe<CoverImageAsset>;
    images?: Maybe<CategoryStoreImagesInput>;
};

export type CategoryUpdateInput = {
    parentId?: Maybe<Scalars['ID']>;
    title?: Maybe<Languages>;
    categoryStores?: Maybe<Array<CategoryStoreInput>>;
    imageAssets?: Maybe<ImageAssets>;
    images?: Maybe<CategoryImagesInput>;
};

export type CategoryV2 = {
    __typename?: 'CategoryV2';
    id: Scalars['ID'];
    name: Scalars['String'];
    permalink: Scalars['String'];
    /** @deprecated Use CategoryV2.titles instead. */
    title: Scalars['JSON'];
    titles: Scalars['JSON'];
    translatedTitle?: Maybe<Scalars['String']>;
    categoryStores: Array<CategoryStore>;
    children?: Maybe<Array<CategoryV2>>;
    /** @deprecated Use imageAssets.iconSvgPath instead. */
    iconSvgPath?: Maybe<Scalars['String']>;
    imageAssets?: Maybe<Scalars['JSON']>;
    coverImageUrl?: Maybe<Scalars['String']>;
    parentId?: Maybe<Scalars['ID']>;
    parent?: Maybe<CategoryV2>;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    productFilters?: Maybe<Array<ProductFilter>>;
};

export type CategoryV2ChildrenArgs = {
    storeCode?: Maybe<Scalars['String']>;
};

export type ChangePaymentMethod = {
    __typename?: 'ChangePaymentMethod';
    /** @deprecated Use subscription payment method instead */
    email?: Maybe<Scalars['String']>;
    /** @deprecated Use subscription payment method instead */
    ibanFirstDigits?: Maybe<Scalars['String']>;
    /** @deprecated Use subscription payment method instead */
    ibanLastDigits?: Maybe<Scalars['String']>;
    /** @deprecated Use subscription payment method instead */
    last4Digits?: Maybe<Scalars['String']>;
    /** @deprecated Use subscription payment method instead */
    type?: Maybe<Scalars['String']>;
};

export type ChangePinInfo = {
    deviceId: Scalars['String'];
    pin: Scalars['String'];
    cardId: Scalars['String'];
};

export type ChangeRequestPending = {
    __typename?: 'ChangeRequestPending';
    changeRequestId: Scalars['String'];
    stringToSign: Scalars['String'];
};

export type ChangeTaxIdInfo = {
    taxId: Scalars['String'];
    countryCode: Scalars['String'];
    skipPicardValidation?: Maybe<Scalars['Boolean']>;
};

export type ChangeTaxIdResult = {
    __typename?: 'ChangeTaxIdResult';
    id: Scalars['String'];
};

export type CheckEmployeeApprovalInput = {
    orderNumber: Scalars['String'];
    userId: Scalars['Int'];
};

export type CheckProviderReport = {
    reportUrl: Scalars['String'];
    result?: Maybe<ProviderCheckResult>;
};

/** Autogenerated input type of ClearExpiredFlow */
export type ClearExpiredFlowInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contractId: Scalars['ID'];
};

/** Autogenerated return type of ClearExpiredFlow. */
export type ClearExpiredFlowPayload = {
    __typename?: 'ClearExpiredFlowPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract?: Maybe<Contract>;
};

export enum ColorFunctions {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
    POSITIVE = 'POSITIVE',
    CAUTION = 'CAUTION',
    NEGATIVE = 'NEGATIVE',
}

export type ColumnConflict = {
    __typename?: 'ColumnConflict';
    columnName: Scalars['String'];
    errorMessage: Scalars['String'];
};

export type ColumnOrderType = {
    column: Scalars['String'];
    order: ShippingOrderEnum;
};

export type ColumnSortType = {
    column: Scalars['String'];
    direction: OrderFulfillmentSortDirection;
};

export type CompaniesWhereInput = {
    and?: Maybe<Array<CompaniesWhereInput>>;
    id?: Maybe<B2BIntegerComparisonInput>;
    legalRepresentativeEmail?: Maybe<B2BStringComparisonInput>;
    name?: Maybe<B2BStringComparisonInput>;
    ust?: Maybe<B2BStringComparisonInput>;
    hrv?: Maybe<B2BStringComparisonInput>;
    status?: Maybe<B2BStringComparisonInput>;
    size?: Maybe<CompanySizeComparisonInput>;
    country?: Maybe<B2BStringComparisonInput>;
};

export type Company = {
    __typename?: 'Company';
    id: Scalars['ID'];
    /** @deprecated Please dont use directly, only needed for federation */
    addressId?: Maybe<Scalars['Int']>;
    /** @deprecated Please dont use directly, only needed for federation */
    forceApprovedUserId?: Maybe<Scalars['Int']>;
    name: Scalars['String'];
    ust?: Maybe<Scalars['String']>;
    hrv?: Maybe<Scalars['String']>;
    status: Scalars['String'];
    viban?: Maybe<Scalars['String']>;
    billingEmail?: Maybe<Scalars['String']>;
    authorizedByLegalRepresentative?: Maybe<Scalars['Boolean']>;
    legalRepresentativeEmail?: Maybe<Scalars['String']>;
    website?: Maybe<Scalars['String']>;
    /** @deprecated Please use the type object instead. type { id } */
    companyTypeId: Scalars['Int'];
    type: CompanyType;
    canPayByInvoice: Scalars['Boolean'];
    size?: Maybe<CompanySize>;
    address?: Maybe<CompanyAddress>;
    forceApprovedUser?: Maybe<User>;
    groverWorkEnabled?: Maybe<Scalars['Boolean']>;
    user: User;
    verificationCheck?: Maybe<CompanyVerificationCheck>;
    businessDashboard?: Maybe<BusinessDashboard>;
};

export type CompanyAddress = {
    __typename?: 'CompanyAddress';
    additionalInfo?: Maybe<Scalars['String']>;
    city: Scalars['String'];
    country: Scalars['String'];
    countryIso: Scalars['String'];
    id: Scalars['ID'];
    line1: Scalars['String'];
    line2: Scalars['String'];
    state?: Maybe<Scalars['String']>;
    stateAbbr?: Maybe<Scalars['String']>;
    zip: Scalars['String'];
};

export type CompanyConnection = {
    __typename?: 'CompanyConnection';
    edges: Array<CompanyEdge>;
    nodes: Array<Company>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type CompanyEdge = {
    __typename?: 'CompanyEdge';
    /** A cursor for use in pagination */
    cursor: Scalars['String'];
    node: Company;
};

export enum CompanySize {
    /** 1-10 */
    _1 = '_1',
    /** 11-50 */
    _11 = '_11',
    /** 51-200 */
    _51 = '_51',
    /** 201-500 */
    _201 = '_201',
    /** 501-1000 */
    _501 = '_501',
    /** 1000+ */
    _1001 = '_1001',
}

export type CompanySizeComparisonInput = {
    _eq?: Maybe<CompanySize>;
    _neq?: Maybe<CompanySize>;
};

export enum CompanyStatus {
    incomplete = 'incomplete',
    pending = 'pending',
    approved = 'approved',
    rejected = 'rejected',
    pending_documents = 'pending_documents',
    pending_documents_verification = 'pending_documents_verification',
}

export type CompanyType = {
    __typename?: 'CompanyType';
    id: Scalars['ID'];
    name: Scalars['String'];
    ustIdRequired: Scalars['Boolean'];
    individual: Scalars['Boolean'];
};

export type CompanyVerificationCheck = CheckProviderReport & {
    __typename?: 'CompanyVerificationCheck';
    firstName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    lastName?: Maybe<Scalars['String']>;
    reportUrl: Scalars['String'];
    result?: Maybe<ProviderCheckResult>;
    verificationType?: Maybe<CompanyVerificationType>;
};

export enum CompanyVerificationType {
    BANK_ACCOUNT_SNAPSHOT = 'BANK_ACCOUNT_SNAPSHOT',
    ID_VERIFICATION = 'ID_VERIFICATION',
}

export enum ComparisonType {
    contains = 'contains',
    equals = 'equals',
    in = 'in',
    notIn = 'notIn',
    lt = 'lt',
    lte = 'lte',
    gt = 'gt',
    gte = 'gte',
    not = 'not',
    startsWith = 'startsWith',
    endsWith = 'endsWith',
    notContains = 'notContains',
    notStartsWith = 'notStartsWith',
    notEndsWith = 'notEndsWith',
}

export type CompleteActivationChecklistResult = {
    __typename?: 'CompleteActivationChecklistResult';
    activationChecklistCompleted: Scalars['Boolean'];
};

/** Autogenerated input type of CompleteContract */
export type CompleteContractInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contractId: Scalars['ID'];
};

/** Autogenerated return type of CompleteContract. */
export type CompleteContractPayload = {
    __typename?: 'CompleteContractPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract?: Maybe<Contract>;
};

export type Completion = Operation & {
    __typename?: 'Completion';
    completedAt?: Maybe<Scalars['ISO8601DateTime']>;
    owner: User;
    startedAt: Scalars['ISO8601DateTime'];
    startedBy: User;
    state: InboundShipmentStates;
    updates: Array<FlowUpdate>;
};

export type ConditionInputType = {
    groverApi?: Maybe<GroverApiInputType>;
};

export type ConditionsContentModel = {
    __typename?: 'ConditionsContentModel';
    groverApi?: Maybe<GroverApiConditionModel>;
    contractService?: Maybe<ContractServiceConditionModel>;
};

export type ConfirmableReturnStep = BasicReturnStep & {
    __typename?: 'ConfirmableReturnStep';
    checkbox?: Maybe<Scalars['String']>;
    description: Scalars['String'];
    headline: Scalars['String'];
    name: Scalars['String'];
    warning?: Maybe<Scalars['String']>;
};

export type ConfirmationPollCardConfirmationStepModel = {
    __typename?: 'ConfirmationPollCardConfirmationStepModel';
    title: Scalars['String'];
    body: Scalars['String'];
    buttonLabel: Scalars['String'];
};

export type ConfirmationPollCardContentModel = {
    __typename?: 'ConfirmationPollCardContentModel';
    uuid: Scalars['ID'];
    pollSlug: Scalars['String'];
    copy: ConfirmationPollCardCopyModel;
    actions?: Maybe<Array<ActionUnionModel>>;
    imageUrl?: Maybe<Scalars['String']>;
    hasCircleDismissButton?: Maybe<Scalars['Boolean']>;
};

export type ConfirmationPollCardCopyModel = {
    __typename?: 'ConfirmationPollCardCopyModel';
    confirmationStep: ConfirmationPollCardConfirmationStepModel;
    outcome: ConfirmationPollCardItemModel;
};

export type ConfirmationPollCardItemModel = {
    __typename?: 'ConfirmationPollCardItemModel';
    title: Scalars['String'];
    body: Scalars['String'];
};

export type ConfirmationPollCardModel = {
    __typename?: 'ConfirmationPollCardModel';
    uuid: Scalars['ID'];
    pollSlug: Scalars['String'];
    copy: ConfirmationPollCardCopyModel;
    actions?: Maybe<Array<ActionUnionModel>>;
    imageUrl?: Maybe<Scalars['String']>;
    hasCircleDismissButton?: Maybe<Scalars['Boolean']>;
};

export type ConfirmAutomaticChargeInput = {
    movementID: Scalars['ID'];
    paymentSource: Scalars['String'];
    paymentMethodType: Scalars['String'];
    paymentReference: Scalars['String'];
    status: Scalars['String'];
    providerResponse: Scalars['JSON'];
};

export type ConfirmAutomaticChargePayload = {
    __typename?: 'ConfirmAutomaticChargePayload';
    movement?: Maybe<Movement>;
};

export type ConfirmRefundInput = {
    movementID: Scalars['ID'];
    paymentSource: Scalars['String'];
    paymentMethodType: Scalars['String'];
    paymentReference: Scalars['String'];
    status: Scalars['String'];
    providerResponse: Scalars['JSON'];
};

export type ConfirmRefundPayload = {
    __typename?: 'ConfirmRefundPayload';
    movement?: Maybe<Movement>;
};

export type ConfirmUpdateAmlFollowUpInfo = {
    /** Device id */
    deviceId: Scalars['String'];
    /** Signed string */
    signature: Scalars['String'];
    /** Change request id */
    changeRequestId: Scalars['String'];
};

export type ConfirmUpdateAmlFollowUpResult = {
    __typename?: 'ConfirmUpdateAmlFollowUpResult';
    id: Scalars['String'];
    status: Scalars['String'];
};

export type ConfirmUpdateMobilePhoneInfo = {
    /** Device id */
    deviceId: Scalars['String'];
    /** Signed string */
    signature: Scalars['String'];
    /** Change request id */
    changeRequestId: Scalars['String'];
    /** Country code of mobile number */
    countryCode: Scalars['String'];
    /** Body of mobile number */
    number: Scalars['String'];
};

export type ConfirmUpdateMobilePhoneResult = {
    __typename?: 'ConfirmUpdateMobilePhoneResult';
    id?: Maybe<Scalars['String']>;
    number?: Maybe<Scalars['String']>;
    verified?: Maybe<Scalars['Boolean']>;
};

export type ConnectionDetails = {
    __typename?: 'ConnectionDetails';
    hostId: Scalars['String'];
    guestId: Scalars['String'];
    defaultStoreCode: Scalars['String'];
    guestRewardValue?: Maybe<Scalars['String']>;
    hostRewardValue?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
};

export type ConnectionModel = {
    __typename?: 'ConnectionModel';
    id: Scalars['ID'];
    paymentDate?: Maybe<Scalars['DateTime']>;
    assetReceivedDate?: Maybe<Scalars['DateTime']>;
    hostEventDispatchedAt?: Maybe<Scalars['DateTime']>;
    accountCreatedAt: Scalars['DateTime'];
    status: ConnectionStatus;
    campaign: ReferralCampaign;
    step: ConnectionStep;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    deletedAt?: Maybe<Scalars['DateTime']>;
    contractActivationDate?: Maybe<Scalars['DateTime']>;
    hostId: Scalars['String'];
    guestId: Scalars['String'];
    report: Scalars['String'];
    orderNumber: Scalars['String'];
};

/** ConnectionStatus: active, revoked, deleted */
export enum ConnectionStatus {
    ACTIVE = 'ACTIVE',
    REVOKED = 'REVOKED',
}

/** Shows the current step the connection is in. AccountCreated, FirstPaymentDone, ContractActivated, Revoked */
export enum ConnectionStep {
    AccountCreated = 'AccountCreated',
    FirstPaymentDone = 'FirstPaymentDone',
    ContractActivated = 'ContractActivated',
    Revoked = 'Revoked',
}

export type ConsentAttributeInput = {
    description?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    value: UserConsentValue;
};

export type ContentUnionModel =
    | BasicMarketingCardContentModel
    | SubscriptionBadgeContentModel
    | HintContentModel
    | PollCardContentModel
    | ConfirmationPollCardContentModel;

export type Contract = Flex | Mix;

export type ContractAction =
    | Buyout
    | CancelOrderEarly
    | PrintReturnLabel
    | ReportDamage
    | SupportRequest;

export enum ContractCancellationReason {
    ASSET_LOST_DURING_TRANSIT = 'ASSET_LOST_DURING_TRANSIT',
    B2B_UPSELL = 'B2B_UPSELL',
    CANCELLATION_BEFORE_ALLOCATION = 'CANCELLATION_BEFORE_ALLOCATION',
    CANCELLED_ACCOUNT_BANKRUPTCY = 'CANCELLED_ACCOUNT_BANKRUPTCY',
    CUSTOMER_WISH = 'CUSTOMER_WISH',
    DAMAGE_CLAIM = 'DAMAGE_CLAIM',
    EARLY_ORDER_CANCELLATION = 'EARLY_ORDER_CANCELLATION',
    END_OF_CYCLE_RETURN = 'END_OF_CYCLE_RETURN',
    FAILED_DELIVERY = 'FAILED_DELIVERY',
    HANDED_OVER_TO_DCA = 'HANDED_OVER_TO_DCA',
    INTERNAL_DEBT_COLLECTION = 'INTERNAL_DEBT_COLLECTION',
    MID_CYCLE_REPLACEMENT = 'MID_CYCLE_REPLACEMENT',
    PURCHASED = 'PURCHASED',
    REPLACEMENT_WITHIN_14_DAYS = 'REPLACEMENT_WITHIN_14_DAYS',
    REVOCATION = 'REVOCATION',
    WAREHOUSE_CANCELLATION = 'WAREHOUSE_CANCELLATION',
}

/** The connection type for Contract. */
export type ContractConnection = {
    __typename?: 'ContractConnection';
    /** A list of edges. */
    edges?: Maybe<Array<Maybe<ContractEdge>>>;
    /** A list of nodes. */
    nodes?: Maybe<Array<Maybe<Contract>>>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type ContractDiscounts = InternalDiscount;

/** An edge in a connection. */
export type ContractEdge = {
    __typename?: 'ContractEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
    /** The item at the end of the edge. */
    node?: Maybe<Contract>;
};

export type ContractFilterInput = {
    countryIso?: Maybe<CountryIso>;
    flow?: Maybe<Array<Flow>>;
    flowState?: Maybe<Array<FlowState>>;
    id?: Maybe<Array<Scalars['ID']>>;
    maxBillingPrice?: Maybe<Scalars['Int']>;
    minBillingPrice?: Maybe<Scalars['Int']>;
    minDaysInCurrentProductVariantState?: Maybe<Scalars['Int']>;
    minDaysInCurrentState?: Maybe<Scalars['Int']>;
    minDaysPausedInTransit?: Maybe<Scalars['Int']>;
    orderNumber?: Maybe<Scalars['ID']>;
    productVariantState?: Maybe<Array<ContractGoodState>>;
    serialNumber?: Maybe<Scalars['ID']>;
    state?: Maybe<Array<ContractState>>;
    storeCode?: Maybe<StoreCode>;
    terminationReason?: Maybe<ContractTerminationReason>;
    type?: Maybe<ContractType>;
    userId?: Maybe<Scalars['ID']>;
};

export type ContractGoods = {
    contractId: Scalars['ID'];
    deliveredAt?: Maybe<Scalars['ISO8601DateTime']>;
    id: Scalars['ID'];
    returnedAt?: Maybe<Scalars['ISO8601DateTime']>;
    state: ContractGoodState;
};

export enum ContractGoodState {
    AWAITING_RETURN = 'AWAITING_RETURN',
    NEVER_SENT = 'NEVER_SENT',
    PROCESSING_RETURN = 'PROCESSING_RETURN',
    RESERVED = 'RESERVED',
    RETURN_REJECTED = 'RETURN_REJECTED',
    RETURN_SUCCEEDED = 'RETURN_SUCCEEDED',
    SHIPPING = 'SHIPPING',
    SHIPPING_FAILED = 'SHIPPING_FAILED',
    SHIPPING_RETURN = 'SHIPPING_RETURN',
    SHIPPING_RETURN_FAILED = 'SHIPPING_RETURN_FAILED',
    SOLD = 'SOLD',
    WITH_CUSTOMER = 'WITH_CUSTOMER',
}

/** Contract Payment Group Filter */
export type ContractPaymentGroupFilterInput = {
    externalContract?: Maybe<Scalars['String']>;
    externalCustomer?: Maybe<Scalars['String']>;
    status?: Maybe<PaymentGroupStatusTypes>;
};

export type ContractPaymentGroupModel = {
    __typename?: 'ContractPaymentGroupModel';
    uuid: Scalars['ID'];
    status: PaymentGroupStatusTypes;
    dueDate: Scalars['DateTime'];
    billingDate: Scalars['DateTime'];
    period: Scalars['Float'];
    externalCustomer: Scalars['String'];
    externalContract: Scalars['String'];
    amount: InCentsModel;
};

export type ContractServiceConditionModel = {
    __typename?: 'ContractServiceConditionModel';
    numberOfActiveMixContracts?: Maybe<Scalars['AnyScalar']>;
};

export type ContractServiceRecurrentModel = {
    __typename?: 'ContractServiceRecurrentModel';
    uuid: Scalars['ID'];
    externalCustomer: Scalars['String'];
    scopeUuid?: Maybe<Scalars['String']>;
    type: Scalars['String'];
    status: RecurrentPaymentStatusTypes;
    dueDate: Scalars['DateTime'];
    recurrentPaymentUuid: Scalars['String'];
    externalContract: Scalars['String'];
    currentPeriod: Scalars['Float'];
    paymentGroups: Array<PaymentGroupModel>;
};

export enum ContractState {
    ACTIVE = 'ACTIVE',
    CANCELLED = 'CANCELLED',
    DRAFT = 'DRAFT',
    ENDED = 'ENDED',
    FULFILLING = 'FULFILLING',
    PAUSED = 'PAUSED',
    REFUSED = 'REFUSED',
}

export enum ContractTerminationReason {
    ASSET_LOST_DURING_TRANSIT = 'ASSET_LOST_DURING_TRANSIT',
    B2B_UPSELL = 'B2B_UPSELL',
    CANCELLATION_BEFORE_ALLOCATION = 'CANCELLATION_BEFORE_ALLOCATION',
    CANCELLED_ACCOUNT_BANKRUPTCY = 'CANCELLED_ACCOUNT_BANKRUPTCY',
    COMPLETED_BY_CUSTOMER = 'COMPLETED_BY_CUSTOMER',
    CUSTOMER_WISH = 'CUSTOMER_WISH',
    DAMAGE_CLAIM = 'DAMAGE_CLAIM',
    EARLY_ORDER_CANCELLATION = 'EARLY_ORDER_CANCELLATION',
    END_OF_CYCLE_RETURN = 'END_OF_CYCLE_RETURN',
    FAILED_DELIVERY = 'FAILED_DELIVERY',
    HANDED_OVER_TO_DCA = 'HANDED_OVER_TO_DCA',
    INTERNAL_DEBT_COLLECTION = 'INTERNAL_DEBT_COLLECTION',
    MID_CYCLE_REPLACEMENT = 'MID_CYCLE_REPLACEMENT',
    PAYMENT_FAILED = 'PAYMENT_FAILED',
    PURCHASED = 'PURCHASED',
    REPLACEMENT_WITHIN_14_DAYS = 'REPLACEMENT_WITHIN_14_DAYS',
    REVOCATION = 'REVOCATION',
    RISK_DECLINED = 'RISK_DECLINED',
    UPSELL = 'UPSELL',
    WAREHOUSE_CANCELLATION = 'WAREHOUSE_CANCELLATION',
}

export enum ContractType {
    FLEX = 'FLEX',
    MIX = 'MIX',
}

export type ContractValidationResult = ValidationResult & {
    __typename?: 'ContractValidationResult';
    valid: Scalars['Boolean'];
};

export type CopyToClipboardActionModel = {
    __typename?: 'CopyToClipboardActionModel';
    text: Scalars['String'];
    value: Scalars['String'];
};

export type CountdownTimer = {
    __typename?: 'CountdownTimer';
    id: Scalars['ID'];
    name: Scalars['String'];
    storeCodes: Array<Scalars['String']>;
    products?: Maybe<Array<Product>>;
    description?: Maybe<Scalars['String']>;
    isActive: Scalars['Boolean'];
    startAt: Scalars['DateTime'];
    endAt: Scalars['DateTime'];
    createdAt: Scalars['DateTime'];
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CountdownTimerCreateInput = {
    name: Scalars['String'];
    products?: Maybe<Array<AssociateProductInput>>;
    storeCodes: Array<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    isActive: Scalars['Boolean'];
    startAt: Scalars['DateTime'];
    endAt: Scalars['DateTime'];
};

export type CountdownTimerOrderByInput = {
    name?: Maybe<Scalars['String']>;
    isActive?: Maybe<Scalars['Boolean']>;
    startAt?: Maybe<Scalars['DateTime']>;
    endAt?: Maybe<Scalars['DateTime']>;
};

export type CountdownTimerPaginationOutput = {
    __typename?: 'CountdownTimerPaginationOutput';
    totalCount: Scalars['Int'];
    rows: Array<CountdownTimer>;
};

export type CountdownTimerUpdateInput = {
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    storeCodes?: Maybe<Array<Scalars['String']>>;
    isActive?: Maybe<Scalars['Boolean']>;
    startAt?: Maybe<Scalars['DateTime']>;
    endAt?: Maybe<Scalars['DateTime']>;
};

export type CountdownTimerWhereInput = {
    name?: Maybe<Scalars['String']>;
    stores?: Maybe<Array<Scalars['String']>>;
    description?: Maybe<Scalars['String']>;
    isActive?: Maybe<Scalars['Boolean']>;
    startAt?: Maybe<Scalars['DateTime']>;
    endAt?: Maybe<Scalars['DateTime']>;
};

export type Country = {
    __typename?: 'Country';
    id: Scalars['ID'];
    iso: Scalars['String'];
    iso3: Scalars['String'];
    isoName: Scalars['String'];
    name: Scalars['String'];
    numcode: Scalars['Int'];
    statesRequired: Scalars['Boolean'];
};

export enum CountryIso {
    AT = 'AT',
    DE = 'DE',
    ES = 'ES',
    NL = 'NL',
    US = 'US',
}

export type CoverImageAsset = {
    coverImageUrl?: Maybe<Scalars['String']>;
};

export type CreateAddonAssociationInput = {
    mainProductSku: Scalars['String'];
    storeCode: Scalars['String'];
    addonsSku?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateAddonProviderInput = {
    name: Scalars['String'];
};

/** Autogenerated input type of CreateAddress */
export type CreateAddressInput = {
    additionalInfo?: Maybe<Scalars['String']>;
    billingAddress: Scalars['Boolean'];
    city: Scalars['String'];
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    countryIso: Scalars['String'];
    line1: Scalars['String'];
    line2?: Maybe<Scalars['String']>;
    shippingAddress: Scalars['Boolean'];
    stateAbbr?: Maybe<Scalars['String']>;
    userId: Scalars['ID'];
    zip: Scalars['String'];
};

export type CreateAddressInputV2 = {
    additionalInfo?: Maybe<Scalars['String']>;
    city: Scalars['String'];
    countryIso: Scalars['String'];
    line1: Scalars['String'];
    line2?: Maybe<Scalars['String']>;
    stateAbbr?: Maybe<Scalars['String']>;
    zip: Scalars['String'];
    twoFACode?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateAddress. */
export type CreateAddressPayload = {
    __typename?: 'CreateAddressPayload';
    address: Address;
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCheckoutAttempt = {
    __typename?: 'CreateCheckoutAttempt';
    reference?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateCompanyAddress */
export type CreateCompanyAddressInput = {
    additionalInfo?: Maybe<Scalars['String']>;
    city: Scalars['String'];
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    companyId: Scalars['ID'];
    countryIso: Scalars['String'];
    line1: Scalars['String'];
    line2?: Maybe<Scalars['String']>;
    stateAbbr?: Maybe<Scalars['String']>;
    zip: Scalars['String'];
};

/** Autogenerated return type of CreateCompanyAddress. */
export type CreateCompanyAddressPayload = {
    __typename?: 'CreateCompanyAddressPayload';
    address: Address;
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateConnectionResponse = {
    __typename?: 'CreateConnectionResponse';
    response: Scalars['Boolean'];
    message: ReferralCreateConnectionResponse;
};

export type CreateDigitalOfferingsInput = {
    mainProductSku: Scalars['String'];
    storeCode: Scalars['String'];
    digitalProductSkus: Array<Scalars['String']>;
};

export type CreateDigitalOfferingsResult = {
    __typename?: 'CreateDigitalOfferingsResult';
    ids: Array<Scalars['String']>;
};

export type CreateDigitalOtpProductInput = {
    description?: Maybe<Scalars['String']>;
    imageUrl: Scalars['String'];
    name: Scalars['String'];
    sku: Scalars['String'];
    slug: Scalars['String'];
    infoLink?: Maybe<Scalars['String']>;
    redeemLink?: Maybe<Scalars['String']>;
    technicalSpecs?: Maybe<Scalars['String']>;
    policyApprovalRequired: Scalars['Boolean'];
    eula?: Maybe<Scalars['String']>;
    detailedDescription?: Maybe<Scalars['String']>;
    sourceImages?: Maybe<Array<Scalars['String']>>;
    providerName: DigitalProductProvider;
    variants?: Maybe<Array<DigitalOtpVariantInput>>;
    localizations?: Maybe<Array<DigitalProductLocalizationInput>>;
};

export type CreateDigitalOtpProductResult = {
    __typename?: 'CreateDigitalOtpProductResult';
    id: Scalars['String'];
};

export type CreateDigitalOtpVariantInput = {
    digitalOtpProductId: Scalars['String'];
    originalPriceInCents?: Maybe<Scalars['Int']>;
    priceInCents: Scalars['Int'];
    currency: Scalars['String'];
    durationInMonths: Scalars['Int'];
};

export type CreateDigitalOtpVariantResult = {
    __typename?: 'CreateDigitalOtpVariantResult';
    id: Scalars['String'];
};

export type CreateDigitalProductLocalizationInput = {
    digitalProductId: Scalars['String'];
    language: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    imageUrl?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    infoLink?: Maybe<Scalars['String']>;
    redeemLink?: Maybe<Scalars['String']>;
    technicalSpecs?: Maybe<Scalars['String']>;
    eula?: Maybe<Scalars['String']>;
    detailedDescription?: Maybe<Scalars['String']>;
    sourceImages?: Maybe<Array<Scalars['String']>>;
};

export type CreateDigitalProductLocalizationResult = {
    __typename?: 'CreateDigitalProductLocalizationResult';
    id: Scalars['String'];
};

export type CreateEmployeeInput = {
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    email: Scalars['String'];
    externalId?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    address?: Maybe<WorkCreateAddressInput>;
};

export type CreateKitInput = {
    name: Scalars['String'];
    companyId: Scalars['Int'];
    description?: Maybe<Scalars['String']>;
    storeCode: Scalars['String'];
};

export type CreateKitProductInput = {
    kitUuid: Scalars['String'];
    quantity: Scalars['Int'];
    productSku: Scalars['String'];
};

export type CreateKitWithProductsInput = {
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    storeCode: Scalars['String'];
    kitProducts: Array<KitProductInput>;
};

export type CreateLegacyCompanyInput = {
    name: Scalars['String'];
    /** Needed to validate UST field. */
    store: Scalars['String'];
    company_type_id: Scalars['Float'];
    /** Deprecated, this property will be deleted */
    user_id?: Maybe<Scalars['Float']>;
    ust?: Maybe<Scalars['String']>;
    size: CompanySize;
    lead_id?: Maybe<Scalars['String']>;
    billing_email?: Maybe<Scalars['String']>;
    website?: Maybe<Scalars['String']>;
};

export type CreateManualBookingBreakdownInput = {
    type: Scalars['String'];
    ID?: Maybe<Scalars['ID']>;
    ExternalID?: Maybe<Scalars['String']>;
    allocate: Scalars['Float'];
    metadata?: Maybe<Scalars['JSON']>;
};

export type CreateManualBookingInput = {
    breakdown: Array<CreateManualBookingBreakdownInput>;
    paymentMethodType: Scalars['String'];
    paymentSource: Scalars['String'];
    paymentReference: Scalars['String'];
    reason: Scalars['String'];
    effectiveDate: Scalars['ISO8601Date'];
};

export type CreateManualBookingPayload = {
    __typename?: 'CreateManualBookingPayload';
    movement?: Maybe<Movement>;
};

/** Autogenerated input type of CreatePaymentMethod */
export type CreatePaymentMethodInput = {
    cancelUrl?: Maybe<Scalars['String']>;
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    confirmUrl?: Maybe<Scalars['String']>;
    paymentGatewayId: Scalars['Int'];
    token?: Maybe<Scalars['String']>;
    transactionKeyName?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreatePaymentMethod. */
export type CreatePaymentMethodPayload = {
    __typename?: 'CreatePaymentMethodPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    paymentMethod: PaymentMethod;
};

export type CreateResourceTypeInput = {
    name: Scalars['String'];
    description: Scalars['String'];
    slugPath: Array<Maybe<Scalars['String']>>;
};

export type CreateResourceTypePayload = {
    __typename?: 'CreateResourceTypePayload';
    resourceType?: Maybe<ResourceType>;
};

export type CreateSwapOrderInput = {
    orderMode?: Maybe<OrderMode>;
    replacedItem: ReplacedLineItemInput;
    replacementItem: MixLineItemInput;
    contractId: Scalars['String'];
    shippingAddressId?: Maybe<Scalars['Int']>;
};

export type CreateTermsAndConditionsInput = {
    storeCodes: Array<Scalars['String']>;
    locales: Array<Maybe<LocaleContentInput>>;
    daysToDecide: Scalars['Int'];
    disabledFeatures: Array<Maybe<Feature>>;
    autoAccept: Scalars['Boolean'];
};

export type CreateTopUpInfo = {
    amountValue: Scalars['Float'];
    amountCurrency: Scalars['String'];
    paymentMethodId?: Maybe<Scalars['String']>;
};

export type CreateTopUpResult = {
    __typename?: 'CreateTopUpResult';
    id: Scalars['String'];
    clientSecret: Scalars['String'];
    status: TopUpStatusValues;
    acquirerErrorCode?: Maybe<Scalars['String']>;
    acquirerDeclineCode?: Maybe<Scalars['String']>;
    cancellationReason?: Maybe<TopUpCancellationReasons>;
    declineReason?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateUserConsents */
export type CreateUserConsentsInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    consentAttributes: Array<ConsentAttributeInput>;
};

/** Autogenerated return type of CreateUserConsents. */
export type CreateUserConsentsPayload = {
    __typename?: 'CreateUserConsentsPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    userConsents: Array<UserConsent>;
};

/** Autogenerated input type of CreateWaitingListTicket */
export type CreateWaitingListTicketInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    slug: Scalars['String'];
};

/** Autogenerated return type of CreateWaitingListTicket. */
export type CreateWaitingListTicketPayload = {
    __typename?: 'CreateWaitingListTicketPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    waitingListTicket: WaitingListTicket;
};

export type CreditNote = {
    __typename?: 'CreditNote';
    id: Scalars['ID'];
    numberSequence: Scalars['String'];
    createdAt: Scalars['ISO8601DateTime'];
    url: Scalars['String'];
};

export type CreditorInfo = {
    __typename?: 'CreditorInfo';
    name?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['String']>;
    postalCode?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
    iban: Scalars['String'];
};

export type CreditorRepresentativeInfo = {
    __typename?: 'CreditorRepresentativeInfo';
    name?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['String']>;
    postalCode?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
    iban: Scalars['String'];
    caseNumber: Scalars['String'];
};

export type CursorFilterType = {
    uid: Scalars['String'];
    createdAt: Scalars['String'];
};

export enum CustomerType {
    Normal = 'Normal',
    Business = 'Business',
}

/** Autogenerated input type of CustomerUpdateCompany */
export type CustomerUpdateCompanyInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    companyId: Scalars['ID'];
    ust: Scalars['String'];
};

/** Autogenerated return type of CustomerUpdateCompany. */
export type CustomerUpdateCompanyPayload = {
    __typename?: 'CustomerUpdateCompanyPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    ust: Scalars['String'];
};

export type CustomerWishCancel = Operation & {
    __typename?: 'CustomerWishCancel';
    completedAt?: Maybe<Scalars['ISO8601DateTime']>;
    owner: User;
    startedAt: Scalars['ISO8601DateTime'];
    startedBy: User;
    state: InboundShipmentStates;
    updates: Array<FlowUpdate>;
};

export type CustomerWishCancelTerm = {
    __typename?: 'CustomerWishCancelTerm';
    available: Scalars['Boolean'];
    availableFrom?: Maybe<Scalars['ISO8601DateTime']>;
    unavailabilityReason?: Maybe<ReturnUnavailabilityReason>;
};

export type CustomerWishKeepCharging = Operation & {
    __typename?: 'CustomerWishKeepCharging';
    completedAt?: Maybe<Scalars['ISO8601DateTime']>;
    owner: User;
    startedAt: Scalars['ISO8601DateTime'];
    startedBy: User;
    state: InboundShipmentStates;
    updates: Array<FlowUpdate>;
};

export type CustomerWishKeepChargingTerm = {
    __typename?: 'CustomerWishKeepChargingTerm';
    available: Scalars['Boolean'];
    availableFrom?: Maybe<Scalars['ISO8601DateTime']>;
    unavailabilityReason?: Maybe<ReturnUnavailabilityReason>;
};

export type DamageClaim = Operation & {
    __typename?: 'DamageClaim';
    completedAt?: Maybe<Scalars['ISO8601DateTime']>;
    owner: User;
    startedAt: Scalars['ISO8601DateTime'];
    startedBy: User;
    state: InboundShipmentStates;
    updates: Array<FlowUpdate>;
};

export type DamageClaimTerm = {
    __typename?: 'DamageClaimTerm';
    available: Scalars['Boolean'];
    unavailabilityReason?: Maybe<DamageClaimUnavailabilityReason>;
};

export enum DamageClaimUnavailabilityReason {
    ALREADY_IN_PROGRESS = 'ALREADY_IN_PROGRESS',
    ANOTHER_FLOW_IN_PROGRESS = 'ANOTHER_FLOW_IN_PROGRESS',
    CONTRACT_IS_NOT_ACTIVE = 'CONTRACT_IS_NOT_ACTIVE',
}

export type DashboardAccessRights = {
    __typename?: 'DashboardAccessRights';
    uuid: Scalars['ID'];
    /** Status of access to the service */
    status: B2BDashboardAccessRightStateGql;
    companyId: Scalars['ID'];
    /** Can be null as access can be activated in reaction to system event. User from GroverAPI through 'userId' */
    activatedBy?: Maybe<User>;
    activatedAt: Scalars['DateTime'];
    /** User from GroverAPI through 'userId' */
    deactivatedBy?: Maybe<User>;
    deactivatedAt?: Maybe<Scalars['DateTime']>;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
};

export type DashboardMixDevice = {
    __typename?: 'DashboardMixDevice';
    assetId?: Maybe<Scalars['String']>;
    category: Category;
    deliveredAt?: Maybe<Scalars['ISO8601DateTime']>;
    id: Scalars['ID'];
    name: Scalars['String'];
    product: Product;
    returnedAt?: Maybe<Scalars['ISO8601DateTime']>;
    serialNumber?: Maybe<Scalars['String']>;
    state: DashboardMixDeviceState;
    subCategory: Category;
    variant: Variant;
};

/** The connection type for DashboardMixDevice. */
export type DashboardMixDeviceConnection = {
    __typename?: 'DashboardMixDeviceConnection';
    /** A list of edges. */
    edges?: Maybe<Array<Maybe<DashboardMixDeviceEdge>>>;
    /** A list of nodes. */
    nodes?: Maybe<Array<Maybe<DashboardMixDevice>>>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type DashboardMixDeviceEdge = {
    __typename?: 'DashboardMixDeviceEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
    /** The item at the end of the edge. */
    node?: Maybe<DashboardMixDevice>;
};

export enum DashboardMixDeviceState {
    AWAITING_RETURN = 'AWAITING_RETURN',
    NEVER_SENT = 'NEVER_SENT',
    PROCESSING_RETURN = 'PROCESSING_RETURN',
    RESERVED = 'RESERVED',
    RETURN_REJECTED = 'RETURN_REJECTED',
    RETURN_SUCCEEDED = 'RETURN_SUCCEEDED',
    SHIPPING = 'SHIPPING',
    SHIPPING_FAILED = 'SHIPPING_FAILED',
    SHIPPING_RETURN = 'SHIPPING_RETURN',
    SHIPPING_RETURN_FAILED = 'SHIPPING_RETURN_FAILED',
    WITH_CUSTOMER = 'WITH_CUSTOMER',
}

export type DashboardMixSubscription = {
    __typename?: 'DashboardMixSubscription';
    activatedAt?: Maybe<Scalars['ISO8601DateTime']>;
    createdAt: Scalars['ISO8601DateTime'];
    currentSwap?: Maybe<DashboardMixSwap>;
    id: Scalars['ID'];
    mixDevice?: Maybe<DashboardMixDevice>;
    mixDevices: DashboardMixDeviceConnection;
    orderNumber: Scalars['ID'];
    payments: PaymentsUnionConnection;
    returnLabelUrl?: Maybe<Scalars['String']>;
    shipmentTrackingUrl?: Maybe<Scalars['String']>;
    state: DashboardMixSubscriptionState;
    terminatedAt?: Maybe<Scalars['ISO8601DateTime']>;
    user: User;
};

export type DashboardMixSubscriptionMixDeviceArgs = {
    id: Scalars['ID'];
};

export type DashboardMixSubscriptionMixDevicesArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    states?: Maybe<Array<Scalars['String']>>;
};

export type DashboardMixSubscriptionPaymentsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

export enum DashboardMixSubscriptionState {
    ACTIVE = 'ACTIVE',
    CANCELLED = 'CANCELLED',
    DRAFT = 'DRAFT',
    ENDED = 'ENDED',
    FULFILLING = 'FULFILLING',
    PAUSED = 'PAUSED',
    REFUSED = 'REFUSED',
}

export type DashboardMixSwap = {
    __typename?: 'DashboardMixSwap';
    cancelledAt?: Maybe<Scalars['ISO8601DateTime']>;
    completedAt?: Maybe<Scalars['ISO8601DateTime']>;
    createdAt: Scalars['ISO8601DateTime'];
    expiresAt: Scalars['ISO8601DateTime'];
    id: Scalars['ID'];
    mixSubscription: DashboardMixSubscription;
    newDevice: DashboardMixDevice;
    oldDevice: DashboardMixDevice;
    orderNumber: Scalars['ID'];
    returnLabelUrl?: Maybe<Scalars['String']>;
    shipmentTrackingUrl?: Maybe<Scalars['String']>;
    shippingProgress?: Maybe<DashboardMixSwapShippingProgress>;
    state: DashboardMixSwapState;
};

export enum DashboardMixSwapShippingProgress {
    AWAITING_RETURN = 'AWAITING_RETURN',
    CHECKING_RETURN = 'CHECKING_RETURN',
    NEW_DEVICE_AWAITING_PICKUP = 'NEW_DEVICE_AWAITING_PICKUP',
    NEW_DEVICE_DELIVERED = 'NEW_DEVICE_DELIVERED',
    NEW_DEVICE_IN_TRANSIT = 'NEW_DEVICE_IN_TRANSIT',
    OLD_DEVICE_IN_TRANSIT = 'OLD_DEVICE_IN_TRANSIT',
    PROBLEM_WITH_RETURN = 'PROBLEM_WITH_RETURN',
}

export enum DashboardMixSwapState {
    CANCELLED = 'CANCELLED',
    COMPLETED = 'COMPLETED',
    EXPIRED = 'EXPIRED',
    ONGOING = 'ONGOING',
}

export type Date = {
    __typename?: 'Date';
    iso8601: Scalars['ISO8601DateTime'];
};

export type DateComparatorInput = {
    _gte?: Maybe<Scalars['DateTimeISO']>;
    _lte?: Maybe<Scalars['DateTimeISO']>;
};

export type DateComparisonInput = {
    _eq?: Maybe<Scalars['String']>;
    _gt?: Maybe<Scalars['String']>;
    _lt?: Maybe<Scalars['String']>;
    _neq?: Maybe<Scalars['String']>;
};

export type DateTimeFilter = {
    equals?: Maybe<Scalars['DateTime']>;
    gt?: Maybe<Scalars['DateTime']>;
    gte?: Maybe<Scalars['DateTime']>;
    in?: Maybe<Array<Scalars['DateTime']>>;
    lt?: Maybe<Scalars['DateTime']>;
    lte?: Maybe<Scalars['DateTime']>;
    not?: Maybe<NestedDateTimeFilter>;
    notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type DateUnion = Date | TimeEstimate;

export enum DebtCollectionAgency {
    ALEKTUM = 'ALEKTUM',
    DEBTCO = 'DEBTCO',
    JJ_MARSHALL = 'JJ_MARSHALL',
    KSP = 'KSP',
    PAIR_FINANCE = 'PAIR_FINANCE',
    SUMMIR_AR = 'SUMMIR_AR',
    TRUE_ACCORD = 'TRUE_ACCORD',
}

export type DebtorInfo = {
    __typename?: 'DebtorInfo';
    name?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['String']>;
    postalCode?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
};

export enum DefaultStoreCode {
    AT = 'AT',
    BUSINESS_AT = 'BUSINESS_AT',
    BUSINESS_DE = 'BUSINESS_DE',
    BUSINESS_ES = 'BUSINESS_ES',
    BUSINESS_NL = 'BUSINESS_NL',
    BUSINESS_US = 'BUSINESS_US',
    DE = 'DE',
    ES = 'ES',
    NL = 'NL',
    US = 'US',
}

/** Autogenerated input type of DeleteAddresses */
export type DeleteAddressesInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of DeleteAddresses. */
export type DeleteAddressesPayload = {
    __typename?: 'DeleteAddressesPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DeleteAuthDevice */
export type DeleteAuthDeviceInput = {
    clientDeviceId: Array<Scalars['ID']>;
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteAuthDevice. */
export type DeleteAuthDevicePayload = {
    __typename?: 'DeleteAuthDevicePayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteDeviceInfo = {
    id: Scalars['String'];
};

export type DeleteDeviceResult = {
    __typename?: 'DeleteDeviceResult';
    id: Scalars['String'];
};

export type DeletedKit = {
    __typename?: 'DeletedKit';
    uuid: Scalars['ID'];
};

export type DeleteLocaleInput = {
    version: Scalars['ID'];
    locale: Scalars['String'];
};

export type DeleteModel = {
    __typename?: 'DeleteModel';
    /** Number of rows affected */
    affected: Scalars['Float'];
};

/** Autogenerated input type of DeleteOnfido */
export type DeleteOnfidoInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    userId: Scalars['ID'];
};

/** Autogenerated return type of DeleteOnfido. */
export type DeleteOnfidoPayload = {
    __typename?: 'DeleteOnfidoPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

export type DeleteProductFromCountdownTimerOutput = {
    __typename?: 'DeleteProductFromCountdownTimerOutput';
    isDeleted: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteUser */
export type DeleteUserInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    userId: Scalars['ID'];
};

/** Autogenerated return type of DeleteUser. */
export type DeleteUserPayload = {
    __typename?: 'DeleteUserPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

/** More fields for this type will be defined in following PRs */
export type DeliveredEof = IExternalOrderFulfillment & {
    __typename?: 'DeliveredEOF';
    uid: Scalars['ID'];
    orderNumber: Scalars['String'];
    orderMode?: Maybe<OrderFulfillmentOrderMode>;
    userId: Scalars['String'];
    userType?: Maybe<UserTypes>;
    storeId?: Maybe<Scalars['Int']>;
    storeCode?: Maybe<Scalars['String']>;
    storeType?: Maybe<StoreTypes>;
    storeCountryId?: Maybe<Scalars['Int']>;
    storeCountryCode?: Maybe<Scalars['String']>;
    state: OrderFulfillmentState;
    stateUpdatedAt: Scalars['DateTime'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    deletedAt?: Maybe<Scalars['DateTime']>;
    order?: Maybe<MixCartOrder>;
    /** Collection of assets belonging to an order */
    assets?: Maybe<Array<OrderAsset>>;
    shippingPreferences?: Maybe<OrderShippingPreferences>;
    employee?: Maybe<GroverEmployee>;
    shippingAddress?: Maybe<EofShippingAddress>;
    /** Collection of shipments belonging to an order */
    shipments?: Maybe<Array<OrderShipment>>;
};

export type DeliveryEstimates = {
    __typename?: 'DeliveryEstimates';
    from?: Maybe<Scalars['Int']>;
    to?: Maybe<Scalars['Int']>;
};

export enum DeliveryOrderMode {
    Flex = 'Flex',
    FlexLegacy = 'FlexLegacy',
    Mix = 'Mix',
    Swap = 'Swap',
}

export type DeliveryProfile = {
    __typename?: 'DeliveryProfile';
    uid: Scalars['ID'];
    name: Scalars['String'];
    carrier: CarrierName;
    service: CarrierServiceGraphql;
    /** Rules using this delivery profile */
    rules?: Maybe<Array<DeliveryProfileRule>>;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryProfileFilter = {
    __typename?: 'DeliveryProfileFilter';
    type?: Maybe<Array<DeliveryType>>;
    skuProducts?: Maybe<Array<Scalars['String']>>;
    orderModes?: Maybe<Array<DeliveryOrderMode>>;
    countries?: Maybe<Array<Scalars['String']>>;
    warehouseCodes?: Maybe<Array<WarehouseCode>>;
    customerTypes?: Maybe<Array<CustomerType>>;
    users?: Maybe<Array<Scalars['String']>>;
    orders?: Maybe<Array<Scalars['String']>>;
    productGroups?: Maybe<Array<SkuProductGroup>>;
};

export type DeliveryProfileFilterInput = {
    type?: Maybe<Array<DeliveryType>>;
    skuProducts?: Maybe<Array<Scalars['String']>>;
    orderModes?: Maybe<Array<DeliveryOrderMode>>;
    countries?: Maybe<Array<Scalars['String']>>;
    warehouseCodes?: Maybe<Array<WarehouseCode>>;
    customerTypes?: Maybe<Array<CustomerType>>;
    users?: Maybe<Array<Scalars['String']>>;
    orders?: Maybe<Array<Scalars['String']>>;
};

export type DeliveryProfileInput = {
    uid?: Maybe<Scalars['ID']>;
    name: Scalars['String'];
    carrier: CarrierName;
    service: CarrierServiceInputGraphql;
};

export type DeliveryProfileMatchInput = {
    type?: Maybe<DeliveryType>;
    country?: Maybe<Scalars['String']>;
    orderMode?: Maybe<DeliveryOrderMode>;
    skuProducts?: Maybe<Array<Scalars['String']>>;
    customerType?: Maybe<CustomerType>;
    warehouseCode?: Maybe<WarehouseCode>;
    userId?: Maybe<Scalars['String']>;
    orderNumber?: Maybe<Scalars['String']>;
    preferredCarrier?: Maybe<CarrierName>;
    priority?: Maybe<Scalars['Int']>;
};

export type DeliveryProfileRule = {
    __typename?: 'DeliveryProfileRule';
    uid: Scalars['ID'];
    type: RuleType;
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    status: RuleStatus;
    groupUid?: Maybe<Scalars['String']>;
    profileUid?: Maybe<Scalars['String']>;
    profile?: Maybe<Array<DeliveryProfile>>;
    priority: Scalars['Int'];
    filter: DeliveryProfileFilter;
    createdBy?: Maybe<Scalars['String']>;
    updatedBy?: Maybe<Scalars['String']>;
    expireAt?: Maybe<Scalars['DateTime']>;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryProfileRuleInput = {
    uid?: Maybe<Scalars['ID']>;
    type?: Maybe<RuleType>;
    name?: Maybe<Scalars['String']>;
    status?: Maybe<RuleStatus>;
    groupUid?: Maybe<Scalars['String']>;
    profileUid?: Maybe<Scalars['String']>;
    filter?: Maybe<DeliveryProfileFilterInput>;
    priority?: Maybe<Scalars['Int']>;
    expireAt?: Maybe<Scalars['String']>;
};

export type DeliveryProfileRuleMatchResult = {
    __typename?: 'DeliveryProfileRuleMatchResult';
    profile?: Maybe<DeliveryProfile>;
    error?: Maybe<Scalars['String']>;
};

export type DeliveryRuleConflict = {
    __typename?: 'DeliveryRuleConflict';
    level: DeliveryRuleConflictLevel;
    message: Scalars['String'];
    conflictingRules: Array<DeliveryProfileRule>;
    scenario?: Maybe<Scalars['String']>;
};

export enum DeliveryRuleConflictLevel {
    Critical = 'Critical',
    Warning = 'Warning',
    None = 'None',
}

export type DeliveryRuleFilterInput = {
    uid?: Maybe<Scalars['ID']>;
    type?: Maybe<RuleType>;
    name?: Maybe<Scalars['String']>;
    status?: Maybe<RuleStatus>;
    groupUid?: Maybe<Scalars['String']>;
    profileUid?: Maybe<Scalars['String']>;
    priority?: Maybe<Scalars['Int']>;
    expireAt?: Maybe<Scalars['String']>;
};

export type DeliveryRuleOperationResult = {
    __typename?: 'DeliveryRuleOperationResult';
    rule?: Maybe<DeliveryProfileRule>;
    conflicts: Array<DeliveryRuleConflict>;
};

export enum DeliveryType {
    Inbound = 'Inbound',
    Outbound = 'Outbound',
    Internal = 'Internal',
}

export type Device = {
    __typename?: 'Device';
    id: Scalars['String'];
    name: Scalars['String'];
    personId: Scalars['String'];
    createdAt: Scalars['String'];
};

export type DeviceActivitiesInfo = {
    fingerprint: Scalars['String'];
    activity: DeviceActivitiesStatus;
};

export type DeviceActivitiesResult = {
    __typename?: 'DeviceActivitiesResult';
    id?: Maybe<Scalars['String']>;
};

export enum DeviceActivitiesStatus {
    CONSENT_PROVIDED = 'CONSENT_PROVIDED',
    APP_START = 'APP_START',
    PASSWORD_RESET = 'PASSWORD_RESET',
}

export type DeviceMonitoringInfo = {
    status: DeviceMonitoringStatus;
};

export type DeviceMonitoringResult = {
    __typename?: 'DeviceMonitoringResult';
    id: Scalars['String'];
};

export enum DeviceMonitoringStatus {
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
}

export type DigitalOtpProduct = AddonsAddon & {
    __typename?: 'DigitalOtpProduct';
    id: Scalars['ID'];
    description?: Maybe<Scalars['String']>;
    imageUrl: Scalars['String'];
    name: Scalars['String'];
    sku: Scalars['String'];
    slug: Scalars['String'];
    infoLink?: Maybe<Scalars['String']>;
    redeemLink?: Maybe<Scalars['String']>;
    /** Specs stored in a key:value format, separated by a new line symbol */
    technicalSpecs?: Maybe<Scalars['String']>;
    policyApprovalRequired: Scalars['Boolean'];
    eula?: Maybe<Scalars['String']>;
    detailedDescription?: Maybe<Scalars['String']>;
    sourceImages: Array<Scalars['String']>;
    providerName: DigitalProductProvider;
    variants: Array<DigitalOtpVariant>;
    localizations: Array<DigitalProductLocalization>;
    active?: Maybe<Scalars['Boolean']>;
    relatedProductSkus: Array<Scalars['String']>;
};

export type DigitalOtpProductBySlugInput = {
    slug: Scalars['String'];
};

export type DigitalOtpVariant = {
    __typename?: 'DigitalOtpVariant';
    id: Scalars['String'];
    originalPriceInCents?: Maybe<Scalars['Int']>;
    priceInCents: Scalars['Int'];
    currency: Scalars['String'];
    durationInMonths: Scalars['Int'];
    digitalOtpProduct: DigitalOtpProduct;
};

export type DigitalOtpVariantInput = {
    originalPriceInCents?: Maybe<Scalars['Int']>;
    priceInCents: Scalars['Int'];
    currency: Scalars['String'];
    durationInMonths: Scalars['Int'];
};

export type DigitalProductLocalization = {
    __typename?: 'DigitalProductLocalization';
    id: Scalars['String'];
    language: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    imageUrl?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    infoLink?: Maybe<Scalars['String']>;
    redeemLink?: Maybe<Scalars['String']>;
    technicalSpecs?: Maybe<Scalars['String']>;
    eula?: Maybe<Scalars['String']>;
    detailedDescription?: Maybe<Scalars['String']>;
    sourceImages?: Maybe<Array<Scalars['String']>>;
};

export type DigitalProductLocalizationInput = {
    language: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    imageUrl?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    infoLink?: Maybe<Scalars['String']>;
    redeemLink?: Maybe<Scalars['String']>;
    eula?: Maybe<Scalars['String']>;
    detailedDescription?: Maybe<Scalars['String']>;
};

export enum DigitalProductProvider {
    GIGS = 'GIGS',
    TECH_DATA = 'TECH_DATA',
}

export type Discount = {
    amount: Price;
    recurring: Scalars['Boolean'];
};

export type DiscountInCents = {
    __typename?: 'DiscountInCents';
    code: Scalars['String'];
    discount: Scalars['Int'];
    recurrent: Scalars['Boolean'];
};

export enum DiscountReason {
    AGREEMENT_WITH_CUSTOMER = 'AGREEMENT_WITH_CUSTOMER',
    ASSET_CONDITION = 'ASSET_CONDITION',
    ASSET_NOT_AVAILABLE = 'ASSET_NOT_AVAILABLE',
    CONTRACT_CANCELLED = 'CONTRACT_CANCELLED',
    DISCOUNT_NOT_APPLIED = 'DISCOUNT_NOT_APPLIED',
    DOUBLE_CHARGES = 'DOUBLE_CHARGES',
    FAILED_DELIVERY = 'FAILED_DELIVERY',
    FREE_SHIPMENT = 'FREE_SHIPMENT',
    LATE_DELIVERY = 'LATE_DELIVERY',
    OTHER = 'OTHER',
    PRICE_CHANGED = 'PRICE_CHANGED',
    RETURN_PROCESS = 'RETURN_PROCESS',
    REVOCATION = 'REVOCATION',
    SURPRISE_DISCOUNT = 'SURPRISE_DISCOUNT',
}

export type DismissModel = {
    __typename?: 'DismissModel';
    id: Scalars['ID'];
    entity: MarketingEntityType;
    externalEntity: Scalars['String'];
    campaignUuid: Scalars['String'];
};

export type DistributionRule = {
    __typename?: 'DistributionRule';
    uid: Scalars['ID'];
    type?: Maybe<DistributionRuleType>;
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    inputType?: Maybe<DistributionRuleTypeName>;
    outputType?: Maybe<Scalars['String']>;
    relatedToUid?: Maybe<Scalars['String']>;
    priority?: Maybe<Scalars['Float']>;
    body?: Maybe<Scalars['String']>;
    stock?: Maybe<Stock>;
    deletedAt?: Maybe<Scalars['DateTime']>;
    expireAt?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    expired: Scalars['Boolean'];
};

export type DistributionRuleCollection = {
    __typename?: 'DistributionRuleCollection';
    count: Scalars['Float'];
    rows: Array<DistributionRule>;
};

export type DistributionRuleFilterInput = {
    uid?: Maybe<Scalars['ID']>;
    type?: Maybe<DistributionRuleType>;
    name?: Maybe<Scalars['String']>;
    inputType?: Maybe<DistributionRuleTypeName>;
    outputType?: Maybe<Scalars['String']>;
    relatedToUid?: Maybe<Scalars['String']>;
};

export type DistributionRuleInput = {
    uid?: Maybe<Scalars['ID']>;
    body?: Maybe<Scalars['String']>;
    type?: Maybe<DistributionRuleType>;
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    inputType?: Maybe<DistributionRuleTypeName>;
    outputType?: Maybe<Scalars['String']>;
    relatedToUid?: Maybe<Scalars['String']>;
    priority?: Maybe<Scalars['Float']>;
    expireAt?: Maybe<Scalars['DateTime']>;
};

export enum DistributionRuleType {
    AssetDistributionAutomatic = 'AssetDistributionAutomatic',
    AssetDistributionOnDemand = 'AssetDistributionOnDemand',
}

export enum DistributionRuleTypeName {
    Sku = 'Sku',
    Stock = 'Stock',
    Store = 'Store',
    Asset = 'Asset',
    Reservation = 'Reservation',
    ReservationAttempt = 'ReservationAttempt',
    StoreStockLink = 'StoreStockLink',
    StockVariantAvailability = 'StockVariantAvailability',
    StoreVariantAvailability = 'StoreVariantAvailability',
    Rule = 'Rule',
    CustomerAssetAllocation = 'CustomerAssetAllocation',
    Replacement = 'Replacement',
    ChangeSet = 'ChangeSet',
    AvailabilityCampaign = 'AvailabilityCampaign',
    ReplicationCursor = 'ReplicationCursor',
    JunoSpreadsheet = 'JunoSpreadsheet',
}

/** Autogenerated input type of DropUserPhoneNumber */
export type DropUserPhoneNumberInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    userId: Scalars['ID'];
};

/** Autogenerated return type of DropUserPhoneNumber. */
export type DropUserPhoneNumberPayload = {
    __typename?: 'DropUserPhoneNumberPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    user: User;
};

export type Duration = {
    __typename?: 'Duration';
    unit: TimeUnit;
    value: Scalars['Int'];
};

export type DurationTerms = {
    __typename?: 'DurationTerms';
    committedLength: Duration;
    rentalPlanLength: Duration;
};

export type EarlyOrderCancellationTerm = {
    __typename?: 'EarlyOrderCancellationTerm';
    available: Scalars['Boolean'];
    unavailableAfter: Scalars['ISO8601DateTime'];
};

export type EditPersonInfo = {
    deviceId: Scalars['String'];
    email?: Maybe<Scalars['String']>;
    employmentStatus?: Maybe<EmploymentStatus>;
    address?: Maybe<AddressInfo>;
    skipSmartyStreets?: Maybe<Scalars['Boolean']>;
};

export type EmployeeCsv = {
    __typename?: 'EmployeeCsv';
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    email: Scalars['String'];
    phone: Scalars['String'];
    country: Scalars['String'];
    zip: Scalars['String'];
    city: Scalars['String'];
    state: Scalars['String'];
    additionalInfo: Scalars['String'];
    line2: Scalars['String'];
    line1: Scalars['String'];
    externalId: Scalars['String'];
};

export type EmployeeImportFileEmpty = ExpectedError & {
    __typename?: 'EmployeeImportFileEmpty';
    code: Scalars['String'];
};

export type EmployeeImportFileInvalid = ExpectedError & {
    __typename?: 'EmployeeImportFileInvalid';
    code: Scalars['String'];
};

export type EmployeeImportFileNotSupportedError = ExpectedError & {
    __typename?: 'EmployeeImportFileNotSupportedError';
    code: Scalars['String'];
    supportedExtensions: Array<Scalars['String']>;
};

export type EmployeeImportFileSizeExceeded = ExpectedError & {
    __typename?: 'EmployeeImportFileSizeExceeded';
    code: Scalars['String'];
    maxSizeSupported: Scalars['String'];
};

export type EmployeeImportRequiredColumnsMissing = ExpectedError & {
    __typename?: 'EmployeeImportRequiredColumnsMissing';
    code: Scalars['String'];
    missingHeaders: Array<Scalars['String']>;
};

export type EmployeeImportRowCountExceeded = ExpectedError & {
    __typename?: 'EmployeeImportRowCountExceeded';
    code: Scalars['String'];
    maxRowCount: Scalars['Int'];
};

export type EmployeeValidateResult = {
    __typename?: 'EmployeeValidateResult';
    isValid: Scalars['Boolean'];
};

export type EmployeeValidationInput = {
    orderNumber: Scalars['String'];
    employeeId: Scalars['String'];
    userId: Scalars['Int'];
    imei?: Maybe<Scalars['String']>;
    serialNumber?: Maybe<Scalars['String']>;
};

export enum EmploymentStatus {
    EMPLOYED = 'EMPLOYED',
    UNEMPLOYED = 'UNEMPLOYED',
    PUBLIC_SECTOR_EMPLOYEE = 'PUBLIC_SECTOR_EMPLOYEE',
    PROFESSIONAL_SOLDIER = 'PROFESSIONAL_SOLDIER',
    FREELANCER = 'FREELANCER',
    HOUSEWORK = 'HOUSEWORK',
    APPRENTICE = 'APPRENTICE',
    MANAGEMENT = 'MANAGEMENT',
    RETIRED = 'RETIRED',
    STUDENT = 'STUDENT',
    SELF_EMPLOYED = 'SELF_EMPLOYED',
    MILITARY_OR_COMMUNITY_SERVICE = 'MILITARY_OR_COMMUNITY_SERVICE',
}

export type EmptyMetadata = {
    __typename?: 'EmptyMetadata';
    description: Scalars['String'];
};

/** Autogenerated input type of EnableInvoicePayments */
export type EnableInvoicePaymentsInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
};

/** Autogenerated return type of EnableInvoicePayments. */
export type EnableInvoicePaymentsPayload = {
    __typename?: 'EnableInvoicePaymentsPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
};

export type EndedNote = TextNoteInterface & {
    __typename?: 'EndedNote';
    text?: Maybe<Scalars['String']>;
};

export type EndingTerm = {
    __typename?: 'EndingTerm';
    availableAfter?: Maybe<Scalars['ISO8601DateTime']>;
    lastCommittedPeriod?: Maybe<LastCommittedPeriod>;
    timeUntilAvailable?: Maybe<Duration>;
};

export type EnergyLabel = {
    __typename?: 'EnergyLabel';
    id: Scalars['ID'];
    productSku: Scalars['String'];
    imageUrl: Scalars['String'];
    energyRating: EnergyRating;
    storeCodes: Array<Scalars['String']>;
    energySpecs: Scalars['JSON'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    product: Product;
};

export type EnergyLabelBulkCreateInput = {
    energyLabels: Array<EnergyLabelCreateInput>;
};

export type EnergyLabelCreateInput = {
    productSku: Scalars['String'];
    imageUrl: Scalars['String'];
    energyRating: Scalars['String'];
    storeCodes: Array<Scalars['String']>;
    energySpecs: Languages;
};

export type EnergyLabelError = GenericError & {
    __typename?: 'EnergyLabelError';
    code: Scalars['String'];
    message: Scalars['String'];
};

export type EnergyLabelOrderByInput = {
    id?: Maybe<SortOrder>;
};

export type EnergyLabelOrError = EnergyLabel | EnergyLabelError;

export type EnergyLabelPaginationOutput = {
    __typename?: 'EnergyLabelPaginationOutput';
    totalCount: Scalars['Int'];
    rows: Array<EnergyLabel>;
};

export type EnergyLabelUpdateInput = {
    productSku?: Maybe<Scalars['String']>;
    imageUrl?: Maybe<Scalars['String']>;
    energyRating?: Maybe<Scalars['String']>;
    storeCodes?: Maybe<Array<Scalars['String']>>;
    energySpecs?: Maybe<Languages>;
};

export type EnergyLabelWhereInput = {
    storeCode?: Maybe<Scalars['String']>;
};

export enum EnergyRating {
    A3 = 'A3',
    A2 = 'A2',
    A1 = 'A1',
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G',
}

export enum EntityType {
    subscription = 'subscription',
    product = 'product',
    order = 'order',
    card = 'card',
    shipment = 'shipment',
}

/** Matches address in event checkout.order.order_placed.v1 */
export type EofShippingAddress = {
    __typename?: 'EOFShippingAddress';
    countryIso: Scalars['String'];
    city: Scalars['String'];
    zipcode: Scalars['String'];
    country: Scalars['String'];
    address1: Scalars['String'];
    address2: Scalars['String'];
    additionalInfo?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ExecuteRakeTask */
export type ExecuteRakeTaskInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    envVariables: Array<Scalars['String']>;
    rakeTask: Scalars['String'];
};

/** Autogenerated return type of ExecuteRakeTask. */
export type ExecuteRakeTaskPayload = {
    __typename?: 'ExecuteRakeTaskPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    errors?: Maybe<Array<Scalars['String']>>;
};

export type ExistingSubscriptionDiscountOffer = SubscriptionDiscountInterface & {
    __typename?: 'ExistingSubscriptionDiscountOffer';
    discountPercentage: Scalars['Int'];
    price: Price;
};

export type ExpectedError = {
    code: Scalars['String'];
};

export type ExperianCollectorDataInput = {
    hdm?: Maybe<Scalars['String']>;
    jscPayload?: Maybe<Scalars['String']>;
};

export type ExportMutationResult = {
    __typename?: 'ExportMutationResult';
    success: Scalars['Boolean'];
    fileUrl?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ExtendContract */
export type ExtendContractInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contractId: Scalars['ID'];
    newRentalPlan: NewRentalPlanInput;
};

/** Autogenerated input type of ExtendContractPause */
export type ExtendContractPauseInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    comment?: Maybe<Scalars['String']>;
    contractId: Scalars['ID'];
    /** Additional days to extend the contract pause by. */
    days: Scalars['Int'];
    reason: PauseReason;
};

/** Autogenerated return type of ExtendContractPause. */
export type ExtendContractPausePayload = {
    __typename?: 'ExtendContractPausePayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract: Contract;
};

/** Autogenerated return type of ExtendContract. */
export type ExtendContractPayload = {
    __typename?: 'ExtendContractPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract: Contract;
};

export enum ExternalLoginProvider {
    GOOGLE = 'GOOGLE',
}

export type ExternalOrderFulfillmentConnection = {
    __typename?: 'ExternalOrderFulfillmentConnection';
    edges?: Maybe<Array<ExternalOrderFulfillmentEdge>>;
    nodes?: Maybe<Array<ExternalOrderFulfillmentUnion>>;
    pageInfo?: Maybe<OrderFulfilmentPageInfo>;
    totalCount?: Maybe<Scalars['Float']>;
    matchingCount?: Maybe<Scalars['Float']>;
};

export type ExternalOrderFulfillmentEdge = {
    __typename?: 'ExternalOrderFulfillmentEdge';
    cursor: Scalars['String'];
    node: ExternalOrderFulfillmentUnion;
};

export type ExternalOrderFulfillmentFilterInput = {
    predicates: Array<ExternalOrderFulfillmentFilterPredicateJointInput>;
    joinType: JoinType;
};

/** Modifies the default filter applied to query */
export enum ExternalOrderFulfillmentFilterModifier {
    pastOrders = 'pastOrders',
    openOrders = 'openOrders',
    default = 'default',
}

export enum ExternalOrderFulfillmentFilterPredicateAttributeType {
    orderNumber = 'orderNumber',
    typename = 'typename',
}

export type ExternalOrderFulfillmentFilterPredicateInput = {
    attribute: ExternalOrderFulfillmentFilterPredicateAttributeType;
    comparison: ComparisonType;
    type: PredicateType;
    value: Scalars['String'];
};

export type ExternalOrderFulfillmentFilterPredicateJointInput = {
    predicates?: Maybe<Array<ExternalOrderFulfillmentFilterPredicateInput>>;
    joinType: JoinType;
};

export type ExternalOrderFulfillmentUnion =
    | ReviewingOrderEof
    | PreparingToShipEof
    | ShippedEof
    | PartiallyShippedEof
    | PartiallyFailedDeliveryEof
    | FailedDeliveryEof
    | PartiallyDeliveredEof
    | DeliveredEof
    | NotImplementedEof;

export type Facet = {
    name: Scalars['String'];
};

/** More fields for this type will be defined in following PRs */
export type FailedDeliveryEof = IExternalOrderFulfillment & {
    __typename?: 'FailedDeliveryEOF';
    uid: Scalars['ID'];
    orderNumber: Scalars['String'];
    orderMode?: Maybe<OrderFulfillmentOrderMode>;
    userId: Scalars['String'];
    userType?: Maybe<UserTypes>;
    storeId?: Maybe<Scalars['Int']>;
    storeCode?: Maybe<Scalars['String']>;
    storeType?: Maybe<StoreTypes>;
    storeCountryId?: Maybe<Scalars['Int']>;
    storeCountryCode?: Maybe<Scalars['String']>;
    state: OrderFulfillmentState;
    stateUpdatedAt: Scalars['DateTime'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    deletedAt?: Maybe<Scalars['DateTime']>;
    order?: Maybe<MixCartOrder>;
    /** Collection of assets belonging to an order */
    assets?: Maybe<Array<OrderAsset>>;
    shippingPreferences?: Maybe<OrderShippingPreferences>;
    employee?: Maybe<GroverEmployee>;
    shippingAddress?: Maybe<EofShippingAddress>;
    /** Collection of shipments belonging to an order */
    shipments?: Maybe<Array<OrderShipment>>;
};

export enum Feature {
    PURCHASE_OPTION = 'PURCHASE_OPTION',
    MIX_CONTRACTS = 'MIX_CONTRACTS',
}

export type FeaturedPaymentInput = {
    failedDaysAgo?: Maybe<Scalars['Int']>;
    scheduledInDays?: Maybe<Scalars['Int']>;
};

export type FetchAddonAssociationsInput = {
    mainProductSku?: Maybe<Scalars['String']>;
    addonSku?: Maybe<Scalars['String']>;
    storeCode?: Maybe<Scalars['String']>;
    first: Scalars['Int'];
    after?: Maybe<Scalars['String']>;
};

export type FetchAddonAssociationsResult = {
    __typename?: 'FetchAddonAssociationsResult';
    associations: Array<AddonsMainProduct>;
    totalCount: Scalars['Int'];
    nextCursor: Scalars['String'];
};

export type FetchDigitalOtpProductsInput = {
    mainProductId: Scalars['ID'];
};

export type FieldReview = {
    __typename?: 'FieldReview';
    applied?: Maybe<Scalars['Boolean']>;
    currentValue: Scalars['String'];
    fieldName: Scalars['String'];
    mismatch: Scalars['Boolean'];
    previousValue?: Maybe<Scalars['String']>;
    providerValue: Scalars['String'];
    significant: Scalars['Boolean'];
};

export type FilterArgs = {
    brand?: Maybe<Array<Scalars['String']>>;
    rentalPlan?: Maybe<Scalars['Int']>;
    availability?: Maybe<Scalars['Boolean']>;
    price?: Maybe<RangeFilterInput>;
    deal?: Maybe<Scalars['Boolean']>;
    category?: Maybe<Array<Scalars['String']>>;
    structuredSpecifications?: Maybe<Array<StructuredSpecificationsInput>>;
};

export type FilterButton = {
    __typename?: 'FilterButton';
    filter: SubscriptionListFilter;
    title: Scalars['String'];
};

export type FindAndCountInput = {
    orderNumber?: Maybe<Scalars['String']>;
    orderMode?: Maybe<Scalars['String']>;
    userId?: Maybe<Scalars['Int']>;
    storeId?: Maybe<Scalars['Int']>;
};

export type FinishedModel = {
    __typename?: 'FinishedModel';
    id: Scalars['ID'];
    entity: MarketingEntityType;
    externalEntity: Scalars['String'];
    campaignUuid: Scalars['String'];
    result: Scalars['String'];
};

export type Flex = BasicContract & {
    __typename?: 'Flex';
    billingAccountId: Scalars['ID'];
    id: Scalars['ID'];
    name: Scalars['String'];
    note?: Maybe<SubscriptionNote>;
    orderNumber: Scalars['ID'];
    paymentMethod?: Maybe<PaymentMethod>;
    payments: PaymentsUnionConnection;
    currentGood: FlexDevice;
    actions?: Maybe<Array<ContractAction>>;
    activatedAt?: Maybe<Scalars['ISO8601DateTime']>;
    availableRentalPlans: Array<RentalPlanUpgradeProperties>;
    billingTerms: BillingTerms;
    cancelReturnTerm: CancelReturnTerm;
    countryIso?: Maybe<Scalars['String']>;
    createdAt: Scalars['ISO8601DateTime'];
    currentFlow?: Maybe<Flow>;
    customerWishCancelTerm?: Maybe<CustomerWishCancelTerm>;
    customerWishKeepChargingTerm?: Maybe<CustomerWishKeepChargingTerm>;
    damageClaimTerm?: Maybe<DamageClaimTerm>;
    durationTerms: DurationTerms;
    earlyOrderCancellationTerm: EarlyOrderCancellationTerm;
    endingTerm: EndingTerm;
    flow?: Maybe<Flows>;
    good: FlexDevice;
    goods: Array<FlexDevice>;
    groverCareTerms: GroverCareTerms;
    history: Array<HistoryItem>;
    internalDebtCollectionTerm?: Maybe<InternalDebtCollectionTerm>;
    manualPauseTerm: ManualPauseTerm;
    order: Order;
    pausedAt?: Maybe<Scalars['ISO8601DateTime']>;
    purchaseTerm?: Maybe<PurchaseTerm>;
    revivalTerm: RevivalTerm;
    revocationTerms?: Maybe<RevocationTerms>;
    state: ContractState;
    storeCode: Scalars['String'];
    surpriseDiscountTerm: SurpriseDiscountTerm;
    terminatedAt?: Maybe<Scalars['ISO8601DateTime']>;
    terminationComment?: Maybe<Scalars['String']>;
    terminationReason?: Maybe<ContractTerminationReason>;
    tosUpdates: Array<TosUpdate>;
    tosVersion?: Maybe<Scalars['ID']>;
    upsellTerm?: Maybe<UpsellTerm>;
    user: User;
    userId: Scalars['ID'];
    shipmentLabels?: Maybe<Array<ShipmentLabel>>;
    businessReturnMetadata?: Maybe<BusinessAssetReturnMetadata>;
};

export type FlexPaymentsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    filter?: Maybe<PaymentsFilterInput>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

export type FlexGoodArgs = {
    id: Scalars['ID'];
};

export type FlexGoodsArgs = {
    states?: Maybe<Array<ContractGoodState>>;
};

export type FlexCartLineItemMeta = LineItemMeta & {
    __typename?: 'FlexCartLineItemMeta';
    product?: Maybe<Product>;
    category?: Maybe<LineItemCategory>;
    variant: Variant;
    committedMonths: Scalars['Int'];
    price: Price;
    total: Price;
    allowPurchase?: Maybe<Scalars['Boolean']>;
    allowRentalUpgrade?: Maybe<Scalars['Boolean']>;
    discountAmount?: Maybe<Scalars['Int']>;
    voucherShare?: Maybe<Price>;
    groverCare?: Maybe<GroverCare>;
};

export type FlexDetails = Flex | SubscriptionDetails;

export type FlexDevice = CatalogItem &
    CatalogItemVariant &
    ContractGoods & {
        __typename?: 'FlexDevice';
        assetId?: Maybe<Scalars['String']>;
        contractId: Scalars['ID'];
        asset?: Maybe<AssetModel>;
        categoryId: Scalars['ID'];
        deliveredAt?: Maybe<Scalars['ISO8601DateTime']>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        product: Product;
        productSku: Scalars['ID'];
        returnedAt?: Maybe<Scalars['ISO8601DateTime']>;
        serialNumber?: Maybe<Scalars['ID']>;
        state: ContractGoodState;
        subCategoryId: Scalars['ID'];
        variant: Variant;
        variantSku: Scalars['ID'];
        shipmentLabel?: Maybe<ShipmentLabel>;
        outboundShipmentLabel?: Maybe<ShipmentLabel>;
    };

export type FlexLineItem = {
    __typename?: 'FlexLineItem';
    coreAttribute?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    imageUrl: Scalars['String'];
    meta: FlexLineItemMeta;
    name: Scalars['String'];
    quantity?: Maybe<Scalars['Int']>;
    slug?: Maybe<Scalars['String']>;
};

export type FlexLineItemMeta = {
    __typename?: 'FlexLineItemMeta';
    minimumTermMonths: Scalars['Int'];
    price: Price;
    rentalPlans: Array<RentalPlan>;
    selectedPlan?: Maybe<RentalPlan>;
    total: Price;
    variant: Variant;
};

export enum Flow {
    CANCELLATION = 'CANCELLATION',
    COMPLETION = 'COMPLETION',
    CUSTOMER_WISH_CANCEL = 'CUSTOMER_WISH_CANCEL',
    CUSTOMER_WISH_KEEP_CHARGING = 'CUSTOMER_WISH_KEEP_CHARGING',
    DAMAGE_CLAIM = 'DAMAGE_CLAIM',
    INTERNAL_DEBT_COLLECTION = 'INTERNAL_DEBT_COLLECTION',
    MANUAL_PAUSE = 'MANUAL_PAUSE',
    PURCHASE = 'PURCHASE',
    REPLACEMENT = 'REPLACEMENT',
    REVOCATION = 'REVOCATION',
    SWAPPING = 'SWAPPING',
    UPSELL = 'UPSELL',
}

export type Flows =
    | Completion
    | CustomerWishCancel
    | CustomerWishKeepCharging
    | DamageClaim
    | InternalDebtCollection
    | ManualPause
    | Purchase
    | Replacement
    | Revocation
    | Upsell;

export enum FlowState {
    AWAITING_RETURN = 'AWAITING_RETURN',
    COMPLETED = 'COMPLETED',
    EXPIRED = 'EXPIRED',
    EXTENDED = 'EXTENDED',
    FAILED = 'FAILED',
    NEW_DELIVERED = 'NEW_DELIVERED',
    OLD_RETURNED = 'OLD_RETURNED',
    PROCESSING_RETURN = 'PROCESSING_RETURN',
    SHIPPING_RETURN = 'SHIPPING_RETURN',
    STARTED = 'STARTED',
}

export type FlowUpdate = {
    __typename?: 'FlowUpdate';
    action: FlowUpdateAction;
    comment?: Maybe<Scalars['String']>;
    lengthInDays: Scalars['Int'];
    reason?: Maybe<FlowUpdateReason>;
};

export enum FlowUpdateAction {
    ENDED = 'ENDED',
    EXPIRATION_EXTENDED = 'EXPIRATION_EXTENDED',
    STARTED = 'STARTED',
}

export enum FlowUpdateReason {
    BULK_RETURN = 'BULK_RETURN',
    LOST_ORDER_INVESTIGATION = 'LOST_ORDER_INVESTIGATION',
    NOT_ABLE_TO_PAY = 'NOT_ABLE_TO_PAY',
    PAUSING_PAYMENTS_UNTIL_RETURN = 'PAUSING_PAYMENTS_UNTIL_RETURN',
}

export type ForceApproveCompanyInput = {
    id: Scalars['Float'];
};

/** Autogenerated input type of ForceApprove */
export type ForceApproveInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
};

/** Autogenerated return type of ForceApprove. */
export type ForceApprovePayload = {
    __typename?: 'ForceApprovePayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ForcePasswordReset */
export type ForcePasswordResetInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    userId: Scalars['ID'];
};

/** Autogenerated return type of ForcePasswordReset. */
export type ForcePasswordResetPayload = {
    __typename?: 'ForcePasswordResetPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    user: User;
};

export enum Gateway {
    paypal = 'paypal',
    sepa = 'sepa',
    bankcard = 'bankcard',
}

export type GenerateInvoiceResponse = {
    __typename?: 'GenerateInvoiceResponse';
    ok: Scalars['String'];
};

export type GenerateReturnLabelFailure = {
    __typename?: 'GenerateReturnLabelFailure';
    arrangementId: Scalars['String'];
    message: Scalars['String'];
};

export type GenerateReturnLabelResultUnion =
    | GenerateReturnLabelSuccess
    | GenerateReturnLabelFailure;

export type GenerateReturnLabelsResult = {
    __typename?: 'GenerateReturnLabelsResult';
    rows: Array<GenerateReturnLabelResultUnion>;
};

export type GenerateReturnLabelSuccess = {
    __typename?: 'GenerateReturnLabelSuccess';
    arrangementId: Scalars['String'];
    shipment: Shipment;
};

export type GenericError = {
    code: Scalars['String'];
    message: Scalars['String'];
};

export type GetConnectionDetailsResponse = {
    __typename?: 'GetConnectionDetailsResponse';
    response: Scalars['Boolean'];
    data?: Maybe<ConnectionDetails>;
};

export type GetPaymentOrdersFilters = {
    /** Contract ID  (first payment group for an order lacks contractId) */
    contractId?: Maybe<Scalars['String']>;
    /** Tax included */
    taxIncluded?: Maybe<Scalars['Boolean']>;
    uuid?: Maybe<Scalars['ID']>;
    group?: Maybe<Scalars['String']>;
    groupStrategy?: Maybe<Scalars['String']>;
    contractType?: Maybe<Scalars['String']>;
    walletUuid?: Maybe<Scalars['String']>;
    scopeUuid?: Maybe<Scalars['String']>;
    paymentPurpose?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    orderNumber?: Maybe<Scalars['String']>;
    dueDate?: Maybe<Scalars['DateTime']>;
    billingDate?: Maybe<Scalars['DateTime']>;
    price?: Maybe<Scalars['Money']>;
    shippingCost?: Maybe<Scalars['Money']>;
    quantity?: Maybe<Scalars['Float']>;
    period?: Maybe<Scalars['Float']>;
    status?: Maybe<PaymentGroupStatusTypes>;
    paymentStatus?: Maybe<PaymentGroupStatusTypes>;
    countryCode?: Maybe<Scalars['String']>;
    paymentType?: Maybe<Scalars['String']>;
    customerId?: Maybe<Scalars['String']>;
};

export type GetPaymentOrdersResponse = {
    __typename?: 'GetPaymentOrdersResponse';
    items: Array<PaymentOrderModel>;
    pageInfo: ListPageInfo;
};

export type GetPendingScaChallengesInfo = {
    cardId: Scalars['String'];
};

export type GetSmartAgentCasesInfo = {
    resolution: SmartAgentCaseResolutionEnum;
};

export type GetUserPaymentOrdersFilters = {
    /** Contract ID  (first payment group for an order lacks contractId) */
    contractId?: Maybe<Scalars['String']>;
    /** Tax included */
    taxIncluded?: Maybe<Scalars['Boolean']>;
    uuid?: Maybe<Scalars['ID']>;
    group?: Maybe<Scalars['String']>;
    groupStrategy?: Maybe<Scalars['String']>;
    contractType?: Maybe<Scalars['String']>;
    walletUuid?: Maybe<Scalars['String']>;
    scopeUuid?: Maybe<Scalars['String']>;
    paymentPurpose?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    orderNumber?: Maybe<Scalars['String']>;
    dueDate?: Maybe<Scalars['DateTime']>;
    billingDate?: Maybe<Scalars['DateTime']>;
    price?: Maybe<Scalars['Money']>;
    shippingCost?: Maybe<Scalars['Money']>;
    quantity?: Maybe<Scalars['Float']>;
    period?: Maybe<Scalars['Float']>;
    status?: Maybe<PaymentGroupStatusTypes>;
    paymentStatus?: Maybe<PaymentGroupStatusTypes>;
    countryCode?: Maybe<Scalars['String']>;
    paymentType?: Maybe<Scalars['String']>;
    customerId: Scalars['String'];
};

export type GoalInputType = {
    campaignUuid: Scalars['String'];
    topic: Scalars['String'];
    eventName: Scalars['String'];
    entityIdField: Scalars['String'];
    isMember: Scalars['Boolean'];
    conditions: Scalars['AnyScalar'];
};

export type GoalModel = {
    __typename?: 'GoalModel';
    uuid: Scalars['ID'];
    campaignUuid: Scalars['String'];
    topic: Scalars['String'];
    eventName: Scalars['String'];
    entityIdField: Scalars['String'];
    isMember: Scalars['Boolean'];
    conditions?: Maybe<Scalars['JSON']>;
};

export type Goods = FlexDevice | MixDevice;

export type GooglePushProvisioningInfo = {
    /** Googles Active wallet ID */
    clientWalletAccountId: Scalars['String'];
    /** The devices Stable Hardware ID */
    clientDeviceId: Scalars['String'];
    /** Solaris Device Id */
    deviceId: Scalars['String'];
    /** Grovers Card ID */
    cardId: Scalars['String'];
};

export type GraphQlFilter = {
    offset?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type GraphQlFilterPaymentGroups = {
    offset?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    /** Either Order Number or Contract ID for old records */
    id?: Maybe<Scalars['String']>;
    /** Order Number */
    orderNumber?: Maybe<Scalars['String']>;
    /** Contract ID  (first payment group for an order lacks contractId) */
    contractId?: Maybe<Scalars['String']>;
};

export type GroupBilling = {
    __typename?: 'GroupBilling';
    chosenBillingDay?: Maybe<Scalars['Float']>;
    enabled: Scalars['Boolean'];
};

export type GroupPayment = PaymentInterface & {
    __typename?: 'GroupPayment';
    remoteId?: Maybe<Scalars['String']>;
    invoiceNumber?: Maybe<Scalars['String']>;
    basePrice: Price;
    createdAt: Scalars['ISO8601DateTime'];
    discountableLoyaltyPrice: Price;
    discountedLoyaltyPrice: Price;
    paidDate?: Maybe<Scalars['ISO8601DateTime']>;
    price: Price;
    state: PaymentState;
    invoiceUrl?: Maybe<Scalars['String']>;
    byInvoice: Scalars['Boolean'];
    contracts?: Maybe<ContractConnection>;
    dueDate: DateUnion;
    id: Scalars['ID'];
};

export type GroupPaymentContractsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

export type GroverAccessToken = {
    __typename?: 'GroverAccessToken';
    accessToken: Scalars['String'];
    createdAt: Scalars['Int'];
    expiresIn: Scalars['Int'];
    refreshToken: Scalars['String'];
    scope: Scalars['String'];
};

export type GroverApiConditionModel = {
    __typename?: 'GroverApiConditionModel';
    amountOfActiveSubscriptions?: Maybe<Scalars['AnyScalar']>;
    userType?: Maybe<Scalars['AnyScalar']>;
    amountOfProcessingSubscriptions?: Maybe<Scalars['AnyScalar']>;
    subscription?: Maybe<GroverApiSubscriptionConditionModel>;
    rentsProductMoreThanOnce?: Maybe<Scalars['Boolean']>;
    payment?: Maybe<GroverApiPaymentConditionModel>;
    rentsGSMDevice?: Maybe<Scalars['Boolean']>;
    rentsEScooter?: Maybe<Scalars['Boolean']>;
};

export type GroverApiInputType = {
    amountOfActiveSubscriptions?: Maybe<Scalars['AnyScalar']>;
    userType?: Maybe<UserTypes>;
    amountOfProcessingSubscriptions?: Maybe<Scalars['AnyScalar']>;
    subscription?: Maybe<GroverApiSubscriptionConditionInputType>;
    rentsProductMoreThanOnce?: Maybe<Scalars['Boolean']>;
    rentsGSMDevice?: Maybe<Scalars['Boolean']>;
    rentsEScooter?: Maybe<Scalars['Boolean']>;
};

export type GroverApiPaymentConditionModel = {
    __typename?: 'GroverApiPaymentConditionModel';
    dateIsEstimated?: Maybe<Scalars['AnyScalar']>;
    state?: Maybe<Scalars['AnyScalar']>;
    type?: Maybe<Scalars['AnyScalar']>;
};

export type GroverApiSubscriptionConditionInputType = {
    eligibleForReturn?: Maybe<Scalars['Boolean']>;
    canUpgradePlan?: Maybe<Scalars['Boolean']>;
};

export type GroverApiSubscriptionConditionModel = {
    __typename?: 'GroverApiSubscriptionConditionModel';
    eligibleForReturn?: Maybe<Scalars['AnyScalar']>;
    canUpgradePlan?: Maybe<Scalars['AnyScalar']>;
};

export type GroverAsset = {
    __typename?: 'GroverAsset';
    /** Juno asset id */
    assetId?: Maybe<Scalars['ID']>;
    contractId: Scalars['ID'];
    uuid: Scalars['ID'];
    externalId: Scalars['ID'];
    updatedBy?: Maybe<Scalars['ID']>;
    source: Scalars['String'];
    /** Product name */
    name: Scalars['String'];
    employeeId?: Maybe<Scalars['ID']>;
    companyId: Scalars['ID'];
    categoryId: Scalars['String'];
    subCategoryId: Scalars['String'];
    brandName: Scalars['String'];
    assetStatus: WorkAssetStatus;
    serialNumber?: Maybe<Scalars['String']>;
    orderNumber: Scalars['String'];
    price: Price;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    terminatedAt?: Maybe<Scalars['DateTime']>;
    activatedAt?: Maybe<Scalars['DateTime']>;
    returnAvailableAfter?: Maybe<Scalars['DateTime']>;
    contract: Contract;
    productSku?: Maybe<Scalars['String']>;
    variantSku?: Maybe<Scalars['String']>;
    product: Product;
    asset?: Maybe<AssetModel>;
    employee?: Maybe<GroverEmployee>;
    returnMetadata?: Maybe<BusinessAssetReturnMetadata>;
};

export type GroverCard = {
    __typename?: 'GroverCard';
    id: Scalars['String'];
    createdAt: Scalars['DateTime'];
    deliveryEstimatedAt: Scalars['DateTime'];
    reissuedAt?: Maybe<Scalars['DateTime']>;
    status: Scalars['String'];
    internalStatus: Scalars['String'];
    pinSetAt?: Maybe<Scalars['DateTime']>;
    name: Scalars['String'];
    maskedPan?: Maybe<Scalars['String']>;
    expirationDate?: Maybe<Scalars['String']>;
    newCardOrdered?: Maybe<Scalars['Boolean']>;
    shouldRetainPin?: Maybe<Scalars['Boolean']>;
    reorderingReason?: Maybe<Scalars['String']>;
    cardTokens: Array<CardToken>;
};

export type GroverCardCardTokensArgs = {
    walletType: Scalars['String'];
    status: Array<CardTokenStatus>;
};

export type GroverCare = {
    __typename?: 'GroverCare';
    coverage: Scalars['Int'];
    price: Price;
};

export type GroverCareTerms = {
    __typename?: 'GroverCareTerms';
    coverage: Scalars['Int'];
    price: Price;
};

export type GroverEmployee = {
    __typename?: 'GroverEmployee';
    /** Used for federation, don't use it directly */
    orderNumber?: Maybe<Scalars['String']>;
    companyId: Scalars['ID'];
    uuid: Scalars['ID'];
    name: Scalars['String'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    email: Scalars['String'];
    phone?: Maybe<Scalars['String']>;
    externalId?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    updatedBy?: Maybe<Scalars['ID']>;
    assets?: Maybe<Array<GroverAsset>>;
    address?: Maybe<Address>;
    monthlySubscriptionTotal: Array<Price>;
};

export type GroverLoginInfo = {
    /** Email */
    email: Scalars['String'];
    /** Password */
    password: Scalars['String'];
};

export type GroverLoginResult = {
    __typename?: 'GroverLoginResult';
    authToken?: Maybe<Scalars['String']>;
    refreshToken?: Maybe<Scalars['String']>;
    correctCredentials?: Maybe<Scalars['Boolean']>;
};

export type Header = {
    __typename?: 'Header';
    store: Scalars['String'];
    locale: HeaderLocale;
    consumer: HeaderConsumer;
    storeId: Scalars['String'];
    ssr: Scalars['Boolean'];
    homeLink: HeaderLink;
    links: Array<HeaderLink>;
    userBar: UserBar;
    categoryMenu: CategoryMenu;
    loggedIn: Scalars['Boolean'];
    topLevelCategoriesTitle: Scalars['String'];
    searchCategoriesShowAllLabel: Scalars['String'];
};

export type HeaderCategory = {
    __typename?: 'HeaderCategory';
    id: Scalars['String'];
    link: HeaderLink;
    permalink: Scalars['String'];
    coverImageUrl?: Maybe<Scalars['String']>;
    children?: Maybe<Array<HeaderCategory>>;
};

export enum HeaderConsumer {
    catalog = 'catalog',
    payments = 'payments',
    dashboard = 'dashboard',
}

export type HeaderLink = {
    __typename?: 'HeaderLink';
    text: Scalars['String'];
    url: Scalars['String'];
    targetBlank: Scalars['Boolean'];
    external: Scalars['Boolean'];
    showInMobile: Scalars['Boolean'];
    showInDesktop: Scalars['Boolean'];
    svgPath?: Maybe<Scalars['String']>;
    preventDefault?: Maybe<Scalars['Boolean']>;
};

export enum HeaderLocale {
    en = 'en',
    de = 'de',
    nl = 'nl',
    es = 'es',
}

export type HeaderOption = {
    __typename?: 'HeaderOption';
    value: Scalars['String'];
    label: Scalars['String'];
};

export type HeaderSearchResult = {
    __typename?: 'HeaderSearchResult';
    sections: Array<SearchResultSection>;
    showAllLink: HeaderLink;
};

export enum HeaderStore {
    de = 'de',
    nl = 'nl',
    es = 'es',
    at = 'at',
    us = 'us',
}

export type HeaderUser = {
    __typename?: 'HeaderUser';
    id: Scalars['Int'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    email: Scalars['String'];
    verified: Scalars['Boolean'];
    userType: Scalars['String'];
};

export type HintContentModel = {
    __typename?: 'HintContentModel';
    uuid: Scalars['ID'];
    /** Markdown template. Commonly a placeholder for translations. Example: {{thankyou}}. thankyou is a translate placeholder */
    bodyTemplate: Scalars['String'];
};

export type HintModel = {
    __typename?: 'HintModel';
    uuid: Scalars['ID'];
    /** Markdown template. Commonly a placeholder for translations. Example: {{thankyou}}. thankyou is a translate placeholder */
    bodyTemplate: Scalars['String'];
};

export type HistoryItem = {
    __typename?: 'HistoryItem';
    fromState: ContractState;
    metadata: HistoryMetadata;
    timestamp: Scalars['ISO8601DateTime'];
    toState: ContractState;
};

export type HistoryMetadata = EmptyMetadata | TriggerEventMetadata | UserActionMetadata;

export type IdComparisonInput = {
    _eq?: Maybe<Scalars['ID']>;
};

export type IExternalOrderFulfillment = {
    uid: Scalars['ID'];
    orderNumber: Scalars['String'];
    orderMode?: Maybe<OrderFulfillmentOrderMode>;
    userId: Scalars['String'];
    userType?: Maybe<UserTypes>;
    storeId?: Maybe<Scalars['Int']>;
    storeCode?: Maybe<Scalars['String']>;
    storeType?: Maybe<StoreTypes>;
    storeCountryId?: Maybe<Scalars['Int']>;
    storeCountryCode?: Maybe<Scalars['String']>;
    state: OrderFulfillmentState;
    stateUpdatedAt: Scalars['DateTime'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    deletedAt?: Maybe<Scalars['DateTime']>;
    order?: Maybe<MixCartOrder>;
    /** Collection of assets belonging to an order */
    assets?: Maybe<Array<OrderAsset>>;
    shippingPreferences?: Maybe<OrderShippingPreferences>;
    employee?: Maybe<GroverEmployee>;
    shippingAddress?: Maybe<EofShippingAddress>;
};

export type ImageAssets = {
    iconSvgPath?: Maybe<Scalars['String']>;
    coverImageUrl?: Maybe<Scalars['String']>;
};

export enum ImageFolders {
    ENERGY_LABELS = 'ENERGY_LABELS',
    VARIANTS = 'VARIANTS',
}

export type ImageUploadData = {
    __typename?: 'ImageUploadData';
    originalImageName: Scalars['String'];
    cloudinaryUrl: Scalars['String'];
    url: Scalars['String'];
};

export type ImageUploadError = {
    __typename?: 'ImageUploadError';
    code: Scalars['String'];
    message: Scalars['String'];
};

export type ImageUploadResult = ImageUploadData | ImageUploadError;

export type ImportEmployeesError = {
    __typename?: 'ImportEmployeesError';
    columnConflicts: Array<ColumnConflict>;
    employee: EmployeeCsv;
};

export type ImportEmployeesFromS3 = {
    shouldUpsert: Scalars['Boolean'];
    key: Scalars['String'];
};

export type ImportEmployeesResponse =
    | EmployeeImportRowCountExceeded
    | EmployeeImportFileEmpty
    | EmployeeImportFileSizeExceeded
    | EmployeeImportRequiredColumnsMissing
    | EmployeeImportFileNotSupportedError
    | ImportEmployeesResult
    | EmployeeImportFileInvalid;

export type ImportEmployeesResult = {
    __typename?: 'ImportEmployeesResult';
    errors: Array<ImportEmployeesError>;
    updatedCount: Scalars['Int'];
    createdCount: Scalars['Int'];
};

export enum InboundShipmentStates {
    AWAITING_RETURN = 'AWAITING_RETURN',
    COMPLETED = 'COMPLETED',
    EXPIRED = 'EXPIRED',
    PROCESSING_RETURN = 'PROCESSING_RETURN',
    SHIPPING_RETURN = 'SHIPPING_RETURN',
    STARTED = 'STARTED',
}

export type InCentsModel = {
    __typename?: 'InCentsModel';
    inCents: Scalars['ID'];
    currency: Scalars['String'];
};

export type InfoBoxInfo = {
    /** Checks whether the boxes that were moved from the app to picard should be fetched */
    fetchNewBoxes?: Maybe<Scalars['Boolean']>;
};

export type InfoBoxResult = {
    __typename?: 'InfoBoxResult';
    type: InformationBoxType;
    title: Scalars['String'];
    body: Scalars['String'];
    name: Scalars['String'];
    navigation?: Maybe<Scalars['GroverAppNavigation']>;
};

export enum InformationBoxType {
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    NEUTRAL = 'NEUTRAL',
    NEUTRAL_WARNING = 'NEUTRAL_WARNING',
}

export enum InfraRoutingDecision {
    NO = 'NO',
    UNKNOWN = 'UNKNOWN',
    YES = 'YES',
}

export type InfraRoutingDecisionComparisonInput = {
    _eq?: Maybe<InfraRoutingDecision>;
    _neq?: Maybe<InfraRoutingDecision>;
};

export type IntegerComparisonInput = {
    _eq?: Maybe<Scalars['Int']>;
    _gt?: Maybe<Scalars['Int']>;
    _lt?: Maybe<Scalars['Int']>;
    _neq?: Maybe<Scalars['Int']>;
};

export type InternalDebtCollection = Operation & {
    __typename?: 'InternalDebtCollection';
    completedAt?: Maybe<Scalars['ISO8601DateTime']>;
    owner: User;
    startedAt: Scalars['ISO8601DateTime'];
    startedBy: User;
    state: InboundShipmentStates;
    updates: Array<FlowUpdate>;
};

export type InternalDebtCollectionTerm = {
    __typename?: 'InternalDebtCollectionTerm';
    available: Scalars['Boolean'];
    availableFrom?: Maybe<Scalars['ISO8601DateTime']>;
    unavailabilityReason?: Maybe<ReturnUnavailabilityReason>;
};

export type InternalDiscount = Discount & {
    __typename?: 'InternalDiscount';
    amount: Price;
    comment?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    name: Scalars['String'];
    percentage?: Maybe<Scalars['String']>;
    reason?: Maybe<DiscountReason>;
    recurring: Scalars['Boolean'];
};

export type InternalRentalPlan = {
    __typename?: 'InternalRentalPlan';
    id: Scalars['ID'];
    terms: Array<RentalPlanTerm>;
    storeCode: Scalars['String'];
    currency: Scalars['String'];
    resourceSku: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    relatedProduct?: Maybe<Product>;
};

export type InternalRentalPlanEdge = {
    __typename?: 'InternalRentalPlanEdge';
    cursor: Scalars['String'];
    node: InternalRentalPlan;
};

export type IntFilter = {
    equals?: Maybe<Scalars['Int']>;
    gt?: Maybe<Scalars['Int']>;
    gte?: Maybe<Scalars['Int']>;
    in?: Maybe<Array<Scalars['Int']>>;
    lt?: Maybe<Scalars['Int']>;
    lte?: Maybe<Scalars['Int']>;
    not?: Maybe<NestedIntFilter>;
    notIn?: Maybe<Array<Scalars['Int']>>;
};

export type Invoice = {
    __typename?: 'Invoice';
    id: Scalars['ID'];
    user: User;
    url: Scalars['String'];
    internalUrl: Scalars['String'];
    createdAt: Scalars['ISO8601DateTime'];
    number: Scalars['String'];
    status: InvoiceStatus;
    dueOn: Scalars['ISO8601DateTime'];
    consolidated: Scalars['Boolean'];
    country: Scalars['String'];
    fromDate?: Maybe<Scalars['ISO8601DateTime']>;
    toDate?: Maybe<Scalars['ISO8601DateTime']>;
    monthlyTotal: Price;
    billingAddress: AddressInvoice;
    shippingAddress: AddressInvoice;
    paymentMethod: InvoicePaymentMethod;
    paymentType: Scalars['String'];
    creditNotes?: Maybe<Array<CreditNote>>;
    contracts?: Maybe<Array<InvoiceContract>>;
};

export type InvoiceConnection = {
    __typename?: 'InvoiceConnection';
    edges?: Maybe<Array<Maybe<InvoiceEdge>>>;
    nodes?: Maybe<Array<Invoice>>;
    pageInfo: PageInfo;
};

export type InvoiceContract = {
    __typename?: 'InvoiceContract';
    id: Scalars['String'];
};

/** An edge in a connection. */
export type InvoiceEdge = {
    __typename?: 'InvoiceEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
    /** The item at the end of the edge. */
    node?: Maybe<Invoice>;
};

export enum InvoiceEventType {
    CREATED = 'CREATED',
    PERIODDATESUPDATED = 'PERIODDATESUPDATED',
    REGENERATED = 'REGENERATED',
}

export enum InvoicePaymentMethod {
    invoice = 'invoice',
    creditCard = 'creditCard',
    paypal = 'paypal',
    sepa = 'sepa',
    unknown = 'unknown',
}

export type InvoicesFilterInput = {
    invoiceNumber?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    dateCreated?: Maybe<Scalars['ISO8601DateTime']>;
    fromDateCreated?: Maybe<Scalars['ISO8601DateTime']>;
    toDateCreated?: Maybe<Scalars['ISO8601DateTime']>;
    paymentDue?: Maybe<Scalars['ISO8601DateTime']>;
    userId?: Maybe<Scalars['Int']>;
    consolidated?: Maybe<Scalars['Boolean']>;
    customerType?: Maybe<Scalars['String']>;
    orderId?: Maybe<Scalars['String']>;
    countryCode?: Maybe<Scalars['String']>;
};

export enum InvoiceStatus {
    created = 'created',
    partiallyCanceled = 'partiallyCanceled',
    canceled = 'canceled',
    unknown = 'unknown',
}

export type ItemMetaInput = {
    committedMonths: Scalars['Int'];
    variant?: Maybe<LineItemVariantInput>;
    price?: Maybe<Scalars['Float']>;
    allowPurchase?: Maybe<Scalars['Boolean']>;
    allowRentalUpgrade?: Maybe<Scalars['Boolean']>;
    discountAmount?: Maybe<Scalars['Int']>;
    rentalPlanId?: Maybe<Scalars['Int']>;
    groverCareCoverage?: Maybe<Scalars['Int']>;
};

export enum JoinType {
    AND = 'AND',
    OR = 'OR',
}

export enum JunoOrderMode {
    FlexLegacy = 'FlexLegacy',
    Flex = 'Flex',
    Mix = 'Mix',
    Swap = 'Swap',
}

export type Jwe = {
    alg: Scalars['String'];
    enc: Scalars['String'];
};

export type Jwk = {
    kty: Scalars['String'];
    n: Scalars['String'];
    e: Scalars['String'];
    alg: Scalars['String'];
    kid: Scalars['String'];
};

export enum KeyPurpose {
    RESTRICTED = 'RESTRICTED',
    UNRESTRICTED = 'UNRESTRICTED',
}

export type KeywordFilter = {
    __typename?: 'KeywordFilter';
    id: Scalars['ID'];
    name: Scalars['String'];
    position?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
    type: ProductFilterType;
    category?: Maybe<Category>;
    storeCodes: Array<Scalars['String']>;
};

export type KeywordFilterOrError = KeywordFilter | ProductFilterError;

export type Kit = {
    __typename?: 'Kit';
    uuid: Scalars['ID'];
    name: Scalars['String'];
    companyId: Scalars['Int'];
    description?: Maybe<Scalars['String']>;
    state: KitState;
    storeCode: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    kitProducts: Array<KitProduct>;
    numberOfKitProducts: Scalars['Int'];
    company?: Maybe<Company>;
    store?: Maybe<Store>;
    createdBy?: Maybe<User>;
    updatedBy?: Maybe<User>;
    /** Experimental: Don't use yet until Grover API deprecation. */
    commonRentalPlansV2: Array<KitCommonRentalPlan>;
    commonRentalPlans: Array<KitCommonRentalPlan>;
};

export type KitCommonRentalPlan = {
    __typename?: 'KitCommonRentalPlan';
    total: Price;
    duration: Duration;
};

export type KitEdge = {
    __typename?: 'KitEdge';
    node?: Maybe<Kit>;
    cursor: Scalars['String'];
};

export type KitProduct = {
    __typename?: 'KitProduct';
    uuid: Scalars['ID'];
    quantity: Scalars['Int'];
    productSku: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    kitUuid: Scalars['ID'];
    kit: Kit;
    storeCode: Scalars['String'];
    product?: Maybe<Product>;
};

export type KitProductInput = {
    quantity: Scalars['Int'];
    productSku: Scalars['String'];
};

export type KitsFilterInput = {
    predicates: Array<KitsFilterPredicateJointInput>;
    joinType?: Maybe<JoinType>;
};

export enum KitsFilterPredicateAttributeType {
    name = 'name',
    companyId = 'companyId',
    description = 'description',
    state = 'state',
    storeCode = 'storeCode',
    createdBy = 'createdBy',
    updatedBy = 'updatedBy',
    createdAt = 'createdAt',
}

export type KitsFilterPredicateInput = {
    attribute: KitsFilterPredicateAttributeType;
    comparison: ComparisonType;
    type: PredicateType;
    value: Scalars['String'];
};

export type KitsFilterPredicateJointInput = {
    predicates: Array<KitsFilterPredicateInput>;
    joinType?: Maybe<JoinType>;
};

export type KitSortInput = {
    uuid?: Maybe<SortOrder>;
    name?: Maybe<SortOrder>;
    createdAt?: Maybe<SortOrder>;
    updatedAt?: Maybe<SortOrder>;
    createdBy?: Maybe<SortOrder>;
    storeCode?: Maybe<SortOrder>;
    state?: Maybe<SortOrder>;
};

export enum KitState {
    enabled = 'enabled',
    disabled = 'disabled',
}

export type Languages = {
    es?: Maybe<Scalars['String']>;
    en?: Maybe<Scalars['String']>;
    de?: Maybe<Scalars['String']>;
    nl?: Maybe<Scalars['String']>;
};

export type LastCommittedPeriod = Period & {
    __typename?: 'LastCommittedPeriod';
    ends: Scalars['ISO8601DateTime'];
    number: Scalars['Int'];
    starts: Scalars['ISO8601DateTime'];
};

export type LaunchFlowActionModel = {
    __typename?: 'LaunchFlowActionModel';
    flow: Scalars['String'];
};

export enum LedgerAction {
    LEDGER_READ_ALL = 'LEDGER_READ_ALL',
    LEDGER_READ_RESOURCE = 'LEDGER_READ_RESOURCE',
    LEDGER_READ_MOVEMENT = 'LEDGER_READ_MOVEMENT',
    LEDGER_READ_TRANSACTION = 'LEDGER_READ_TRANSACTION',
    LEDGER_AUTOMATIC_CHARGE = 'LEDGER_AUTOMATIC_CHARGE',
    LEDGER_MANUAL_BOOKING = 'LEDGER_MANUAL_BOOKING',
    LEDGER_PBI_CHARGE = 'LEDGER_PBI_CHARGE',
    LEDGER_REFUND = 'LEDGER_REFUND',
    LEDGER_AUTOMATIC_CHARGE_CONFIRM = 'LEDGER_AUTOMATIC_CHARGE_CONFIRM',
    LEDGER_PBI_CHARGE_CONFIRM = 'LEDGER_PBI_CHARGE_CONFIRM',
    LEDGER_REFUND_CONFIRM = 'LEDGER_REFUND_CONFIRM',
    LEDGER_ACTIONS_ALL = 'LEDGER_ACTIONS_ALL',
}

export enum LevelTypes {
    root = 'root',
    subscription = 'subscription',
    orderPayment = 'orderPayment',
    subscriptionPayment = 'subscriptionPayment',
}

export type LineItem = {
    __typename?: 'LineItem';
    id: Scalars['ID'];
    imageUrl?: Maybe<Scalars['String']>;
    minimumTermMonths: Scalars['Int'];
    name: Scalars['String'];
    price: Price;
    quantity: Scalars['Int'];
    total: Price;
    variant: Variant;
};

export type LineItemAddInput = {
    committedMonths: Scalars['Int'];
    variantSku: Scalars['String'];
    discountAmount?: Maybe<Scalars['Int']>;
    quantity?: Maybe<Scalars['Int']>;
    groverCareCoverage?: Maybe<Scalars['Int']>;
    variantId?: Maybe<Scalars['ID']>;
    rentalPlanId?: Maybe<Scalars['Int']>;
};

export type LineItemCatalogMeta = LineItemMeta & {
    __typename?: 'LineItemCatalogMeta';
    product?: Maybe<Product>;
    category?: Maybe<LineItemCategory>;
    variant: Variant;
    groverCare?: Maybe<GroverCare>;
};

export type LineItemCategory = {
    __typename?: 'LineItemCategory';
    categoryId?: Maybe<Scalars['ID']>;
    subCategoryId?: Maybe<Scalars['ID']>;
};

export type LineItemCategoryInput = {
    categoryId?: Maybe<Scalars['ID']>;
    subCategoryId: Scalars['ID'];
};

export type LineItemMeta = {
    product?: Maybe<Product>;
    variant: Variant;
    groverCare?: Maybe<GroverCare>;
};

export type LineItemProduct = {
    __typename?: 'LineItemProduct';
    id?: Maybe<Scalars['ID']>;
    sku?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
    coreAttribute?: Maybe<Scalars['String']>;
};

export type LineItemProductInput = {
    sku: Scalars['String'];
};

export type LineItemUpdateInput = {
    id: Scalars['Int'];
    quantity?: Maybe<Scalars['Int']>;
    meta?: Maybe<ItemMetaInput>;
};

export type LineItemVariant = {
    __typename?: 'LineItemVariant';
    id?: Maybe<Scalars['ID']>;
    sku?: Maybe<Scalars['ID']>;
    colorCodes?: Maybe<Array<Scalars['String']>>;
    colorTitle?: Maybe<Scalars['String']>;
    imageUrl?: Maybe<Scalars['String']>;
    mixable?: Maybe<Scalars['Boolean']>;
};

export type LineItemVariantInput = {
    id?: Maybe<Scalars['ID']>;
    sku: Scalars['String'];
};

export type LinkActionModel = {
    __typename?: 'LinkActionModel';
    text: Scalars['String'];
    path: Scalars['String'];
};

export type ListPageInfo = {
    __typename?: 'ListPageInfo';
    total: Scalars['Int'];
    hasNextPage: Scalars['Boolean'];
    nextOffset: Scalars['Int'];
};

export type LocaleContent = {
    __typename?: 'LocaleContent';
    content: Scalars['String'];
    summary: Scalars['String'];
    locale: Scalars['String'];
};

export type LocaleContentInput = {
    content: Scalars['String'];
    summary: Scalars['String'];
    locale: Scalars['String'];
};

export enum LogisticsDimensionsUnit {
    Inches = 'Inches',
    Centimeters = 'Centimeters',
}

export enum LogisticsHazmatClass {
    Class1 = 'Class1',
    Class2 = 'Class2',
    Class3 = 'Class3',
    Class4 = 'Class4',
    Class5 = 'Class5',
    Class6 = 'Class6',
    Class7 = 'Class7',
    Class8 = 'Class8',
    Class9 = 'Class9',
}

export enum LogisticsIdentifier {
    IMEI = 'IMEI',
    SerialNumber = 'SerialNumber',
    VIN = 'VIN',
}

export type LoopSimulatorModel = {
    __typename?: 'LoopSimulatorModel';
    recurrentPaymentUuid: Scalars['String'];
    externalSubscription: Scalars['String'];
};

export type LoyaltyActionResultBooking = {
    __typename?: 'LoyaltyActionResultBooking';
    id: Scalars['String'];
    uniqueKey: Scalars['String'];
    userId: Scalars['String'];
    type: LoyaltyActionType;
    amount: Scalars['Int'];
    status: LoyaltyActionStatus;
    metadata: ActionMetadata;
    person: CardBooking;
};

export type LoyaltyActionResultReservation = {
    __typename?: 'LoyaltyActionResultReservation';
    id: Scalars['String'];
    uniqueKey: Scalars['String'];
    userId: Scalars['String'];
    type: LoyaltyActionType;
    amount: Scalars['Int'];
    status: LoyaltyActionStatus;
    person: AccountReservation;
};

export enum LoyaltyActionStatus {
    NEW = 'NEW',
    PROCESSED = 'PROCESSED',
    FAILED = 'FAILED',
    CANCELLED = 'CANCELLED',
}

export enum LoyaltyActionType {
    CHARGE = 'CHARGE',
    REFUND = 'REFUND',
    ADD = 'ADD',
    CANCEL = 'CANCEL',
}

export type LoyaltyAdminCreditTransactionCapture = {
    __typename?: 'LoyaltyAdminCreditTransactionCapture';
    id: Scalars['String'];
    captureId: Scalars['String'];
    creditTransactionId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
};

export type LoyaltyAdminCreditTransactionResult = {
    __typename?: 'LoyaltyAdminCreditTransactionResult';
    id: Scalars['String'];
    inputTransaction?: Maybe<Scalars['String']>;
    externalId?: Maybe<Scalars['String']>;
    userId: Scalars['String'];
    store: Scalars['String'];
    source: Scalars['String'];
    status: LoyaltyCreditTransactionStatus;
    amount: Scalars['Int'];
    expiresAt?: Maybe<Scalars['DateTime']>;
    startAt?: Maybe<Scalars['DateTime']>;
    statusUpdatesAt?: Maybe<Scalars['DateTime']>;
    action?: Maybe<LoyaltyActionResultBooking>;
    capture?: Maybe<LoyaltyAdminCreditTransactionCapture>;
};

export type LoyaltyAdminPanelResult = {
    __typename?: 'LoyaltyAdminPanelResult';
    userId: Scalars['String'];
    balance: Array<LoyaltyBalanceMultiStore>;
    transactions?: Maybe<Array<LoyaltyAdminCreditTransactionResult>>;
    rules?: Maybe<Array<LoyaltyAdminRuleResult>>;
};

export type LoyaltyAdminPanelStatisticResult = {
    __typename?: 'LoyaltyAdminPanelStatisticResult';
    earnedCreditsAmountForMonthOnCard: Scalars['Int'];
    earnedCreditsAmountForMonthOnReferral: Scalars['Int'];
    spentCreditsAmountForMonth: Scalars['Int'];
    defaultMonthlyLimitForCard: Scalars['Int'];
    defaultMonthlyLimitForReferral: Scalars['Int'];
    defaultMonthlyLimitForManualGrant: Scalars['Int'];
    totalCreditsAmountByCountry: Array<LoyaltyTotalCreditsAmountByCountryResult>;
};

export type LoyaltyAdminRuleResult = {
    __typename?: 'LoyaltyAdminRuleResult';
    id: Scalars['String'];
    name: Scalars['String'];
    rule: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    conditions?: Maybe<Array<Scalars['String']>>;
};

export type LoyaltyBalanceMultiStore = {
    __typename?: 'LoyaltyBalanceMultiStore';
    store: Scalars['String'];
    amount: Scalars['Int'];
};

export type LoyaltyBalanceResult = {
    __typename?: 'LoyaltyBalanceResult';
    userId: Scalars['String'];
    balance: Scalars['Int'];
    person: Person;
};

export enum LoyaltyCountry {
    GERMANY = 'GERMANY',
    AUSTRIA = 'AUSTRIA',
    NETHERLANDS = 'NETHERLANDS',
    USA = 'USA',
    SPAIN = 'SPAIN',
}

export type LoyaltyCreditTransactionCaptureResult = {
    __typename?: 'LoyaltyCreditTransactionCaptureResult';
    userId: Scalars['String'];
    captureId: Scalars['String'];
};

export type LoyaltyCreditTransactionCommitInfo = {
    captureId: Scalars['String'];
    amount: Scalars['Int'];
    paymentId?: Maybe<Scalars['String']>;
};

export type LoyaltyCreditTransactionResult = {
    __typename?: 'LoyaltyCreditTransactionResult';
    id: Scalars['String'];
    inputTransaction?: Maybe<Scalars['String']>;
    externalId?: Maybe<Scalars['String']>;
    userId: Scalars['String'];
    store: Scalars['String'];
    source: Scalars['String'];
    status: LoyaltyCreditTransactionStatus;
    amount: Scalars['Int'];
    expiresAt?: Maybe<Scalars['DateTime']>;
    startAt?: Maybe<Scalars['DateTime']>;
    statusUpdatesAt?: Maybe<Scalars['DateTime']>;
};

export enum LoyaltyCreditTransactionStatus {
    ACTIVE = 'ACTIVE',
    COMPLETED = 'COMPLETED',
    CANCELED = 'CANCELED',
    OVER_LIMIT = 'OVER_LIMIT',
}

export type LoyaltyFederatedBooking = {
    __typename?: 'LoyaltyFederatedBooking';
    id: Scalars['String'];
    uniqueKey: Scalars['String'];
    userId: Scalars['String'];
    type: LoyaltyActionType;
    amount: Scalars['Int'];
    status: LoyaltyActionStatus;
    metadata: ActionMetadata;
    person: CardBooking;
    overLimitAmount: Scalars['Int'];
};

export type LoyaltyFilterOptionsInfo = {
    pagination: LoyaltyPaginationInfo;
    /** Order by: [field name]: 1 | -1 */
    order?: Maybe<Scalars['AnyScalar']>;
    /** Where: [field name]: { _eq | _like | _lt | _lte | _gt | _gte : <value> } */
    where?: Maybe<Scalars['AnyScalar']>;
};

export type LoyaltyPaginationInfo = {
    page: Scalars['Int'];
    perPage: Scalars['Int'];
};

export type LoyaltyPredictedActionAmountResult = {
    __typename?: 'LoyaltyPredictedActionAmountResult';
    amount: Scalars['Int'];
};

export type LoyaltyReservationInfo = {
    id: Scalars['String'];
    posEntryMode: Scalars['String'];
    transactionType: Scalars['String'];
    categoryCode: Scalars['String'];
    value: Scalars['Float'];
    fxRate: Scalars['Float'];
    traceId: Scalars['String'];
};

export type LoyaltyShopProductResult = {
    __typename?: 'LoyaltyShopProductResult';
    id: Scalars['String'];
    externalId: Scalars['String'];
    type: Scalars['String'];
    status: Scalars['String'];
    description: Scalars['String'];
    price: Scalars['Int'];
};

export type LoyaltyShopPurchaseMetadataUnion = LoyaltyVoucherMetadata;

export type LoyaltyShopPurchaseResult = {
    __typename?: 'LoyaltyShopPurchaseResult';
    id: Scalars['String'];
    userId: Scalars['String'];
    productId: Scalars['String'];
    price: Scalars['Int'];
    metadata: LoyaltyShopPurchaseMetadataUnion;
    product: LoyaltyShopProductResult;
};

export type LoyaltyTotalCreditsAmountByCountryResult = {
    __typename?: 'LoyaltyTotalCreditsAmountByCountryResult';
    amount: Scalars['Int'];
    store: Scalars['String'];
};

export type LoyaltyUserBalanceResult = {
    __typename?: 'LoyaltyUserBalanceResult';
    country: LoyaltyCountry;
    balance: Scalars['Int'];
};

export type LoyaltyVoucherMetadata = {
    __typename?: 'LoyaltyVoucherMetadata';
    code: Scalars['String'];
    expirationDate: Scalars['DateTime'];
};

export type ManualGrantInput = {
    userId: Scalars['Int'];
    country: LoyaltyCountry;
    cashAmountInCents: Scalars['Int'];
    performedBy: Scalars['Int'];
    comment?: Maybe<Scalars['String']>;
    reason: ManualGrantReason;
};

export enum ManualGrantReason {
    DELAYED_DELIVERY = 'DELAYED_DELIVERY',
    PURCHASE_OPTION_UPSELL = 'PURCHASE_OPTION_UPSELL',
    CUSTOMER_SATISFACTION = 'CUSTOMER_SATISFACTION',
}

export type ManualPause = Operation & {
    __typename?: 'ManualPause';
    completedAt?: Maybe<Scalars['ISO8601DateTime']>;
    owner: User;
    startedAt: Scalars['ISO8601DateTime'];
    startedBy: User;
    state: ManualPauseStates;
    updates: Array<FlowUpdate>;
};

export enum ManualPauseStates {
    COMPLETED = 'COMPLETED',
    EXTENDED = 'EXTENDED',
    STARTED = 'STARTED',
}

export type ManualPauseTerm = {
    __typename?: 'ManualPauseTerm';
    available: Scalars['Boolean'];
    extensionAvailable: Scalars['Boolean'];
    extensionUnavailabilityReason?: Maybe<PauseExtensionUnavailabilityReason>;
    unavailabilityReason?: Maybe<PauseUnavailabilityReason>;
};

export type MarkCardAsLostStolenInfo = {
    cardId: Scalars['String'];
    shouldRetainPin: Scalars['Boolean'];
    shouldOrderReplacement: Scalars['Boolean'];
    lossReason: CardLossReason;
};

export type MarkCardAsLostStolenResult = {
    __typename?: 'MarkCardAsLostStolenResult';
    status: CardStatus;
};

export enum MarketingEntityType {
    user = 'user',
    subscription = 'subscription',
    subscriptionPayment = 'subscriptionPayment',
    orderPayment = 'orderPayment',
}

export type MatchFacet = Facet & {
    __typename?: 'MatchFacet';
    name: Scalars['String'];
    values: Array<MatchFacetValues>;
};

export type MatchFacetValues = {
    __typename?: 'MatchFacetValues';
    value: Scalars['String'];
    count: Scalars['Int'];
};

export type Merchant = {
    __typename?: 'Merchant';
    countryCode: Scalars['String'];
    categoryCode: Scalars['String'];
    name: Scalars['String'];
    town: Scalars['String'];
};

export type MetaDataInput = {
    marketingSourceReference?: Maybe<Scalars['String']>;
    marketingCampaignReference?: Maybe<Scalars['String']>;
};

export type MetaPersonResult = {
    __typename?: 'MetaPersonResult';
    acceptedTncAt?: Maybe<Scalars['DateTime']>;
};

export type MetricsModel = {
    __typename?: 'MetricsModel';
    /** Number of users that dismissed the campaigns */
    dismissed: Scalars['Float'];
    /** Number of users that meet the campaign goal */
    finished: Scalars['Float'];
    /** Number of users that are a member of the campaign */
    usersInCampaign: Scalars['Float'];
};

export type Mix = BasicContract & {
    __typename?: 'Mix';
    billingAccountId: Scalars['ID'];
    id: Scalars['ID'];
    orderNumber: Scalars['ID'];
    paymentMethod?: Maybe<PaymentMethod>;
    payments: PaymentsUnionConnection;
    currentGoods: Array<MixDevice>;
    actions?: Maybe<Array<ContractAction>>;
    activatedAt?: Maybe<Scalars['ISO8601DateTime']>;
    billingTerms: BillingTerms;
    cancelReturnTerm: CancelReturnTerm;
    countryIso?: Maybe<Scalars['String']>;
    createdAt: Scalars['ISO8601DateTime'];
    currentFlow?: Maybe<Flow>;
    customerWishCancelTerm?: Maybe<CustomerWishCancelTerm>;
    customerWishKeepChargingTerm?: Maybe<CustomerWishKeepChargingTerm>;
    damageClaimTerm?: Maybe<DamageClaimTerm>;
    durationTerms: DurationTerms;
    earlyOrderCancellationTerm: EarlyOrderCancellationTerm;
    endingTerm: EndingTerm;
    flow?: Maybe<Flows>;
    good: MixDevice;
    goods: Array<MixDevice>;
    groverCareTerms: GroverCareTerms;
    history: Array<HistoryItem>;
    internalDebtCollectionTerm?: Maybe<InternalDebtCollectionTerm>;
    manualPauseTerm: ManualPauseTerm;
    order: Order;
    pausedAt?: Maybe<Scalars['ISO8601DateTime']>;
    revivalTerm: RevivalTerm;
    revocationTerms?: Maybe<RevocationTerms>;
    state: ContractState;
    storeCode: Scalars['String'];
    surpriseDiscountTerm: SurpriseDiscountTerm;
    swap: Swap;
    swappingTerms: SwappingTerms;
    swaps: Array<Swap>;
    terminatedAt?: Maybe<Scalars['ISO8601DateTime']>;
    terminationComment?: Maybe<Scalars['String']>;
    terminationReason?: Maybe<ContractTerminationReason>;
    tosUpdates: Array<TosUpdate>;
    tosVersion?: Maybe<Scalars['ID']>;
    upsellTerm?: Maybe<UpsellTerm>;
    user: User;
    userId: Scalars['ID'];
    shipmentLabels?: Maybe<Array<ShipmentLabel>>;
};

export type MixPaymentsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

export type MixGoodArgs = {
    id: Scalars['ID'];
};

export type MixGoodsArgs = {
    states?: Maybe<Array<ContractGoodState>>;
};

export type MixSwapArgs = {
    orderNumber: Scalars['ID'];
};

export type MixSwapsArgs = {
    states?: Maybe<Array<SwapState>>;
};

export type MixCartOrder = {
    __typename?: 'MixCartOrder';
    orderNumber: Scalars['ID'];
    contractId?: Maybe<Scalars['String']>;
    orderMode: OrderMode;
    lineItems: Array<Maybe<MixLineItem>>;
    shippingAddressId?: Maybe<Scalars['ID']>;
    shippingAddress?: Maybe<Address>;
    billingAddressId?: Maybe<Scalars['ID']>;
    billingAddress?: Maybe<Address>;
    paymentMethodId?: Maybe<Scalars['ID']>;
    billingAccountId?: Maybe<Scalars['ID']>;
    /** @deprecated paymentMethod is deprecated. Use paymentMethodExtended or paymentMethodId and billingAccountId instead. */
    paymentMethod?: Maybe<PaymentMethodStub>;
    itemTotal: Price;
    total: Price;
    estimatedTax?: Maybe<Price>;
    currency?: Maybe<Scalars['String']>;
    guestToken?: Maybe<Scalars['String']>;
    step?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['DateISOString']>;
    completedAt?: Maybe<Scalars['DateISOString']>;
    placedAt?: Maybe<Scalars['DateISOString']>;
    vat?: Maybe<Scalars['String']>;
    netTotal?: Maybe<Price>;
    vatTotal?: Maybe<Price>;
    shippingCost?: Maybe<Price>;
    itemTotalDiscount?: Maybe<Price>;
    storeCode?: Maybe<Scalars['String']>;
    voucher?: Maybe<CartVoucher>;
    groverCareTotal: Price;
    paymentMethodExtended?: Maybe<PaymentMethod>;
};

export type MixDevice = CatalogItem &
    CatalogItemVariant &
    ContractGoods & {
        __typename?: 'MixDevice';
        assetId?: Maybe<Scalars['String']>;
        contractId: Scalars['ID'];
        asset?: Maybe<AssetModel>;
        categoryId: Scalars['ID'];
        deliveredAt?: Maybe<Scalars['ISO8601DateTime']>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        product: Product;
        productSku: Scalars['ID'];
        returnedAt?: Maybe<Scalars['ISO8601DateTime']>;
        serialNumber?: Maybe<Scalars['ID']>;
        state: ContractGoodState;
        subCategoryId: Scalars['ID'];
        swappable: Scalars['Boolean'];
        variant: Variant;
        variantSku: Scalars['ID'];
        shipmentLabel?: Maybe<ShipmentLabel>;
    };

export type MixDeviceSwappableArgs = {
    withProduct: ProductVariantInput;
};

/** The connection type for MixDevice. */
export type MixDeviceConnection = {
    __typename?: 'MixDeviceConnection';
    /** A list of edges. */
    edges?: Maybe<Array<Maybe<MixDeviceEdge>>>;
    /** A list of nodes. */
    nodes?: Maybe<Array<Maybe<MixDevice>>>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type MixDeviceEdge = {
    __typename?: 'MixDeviceEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
    /** The item at the end of the edge. */
    node?: Maybe<MixDevice>;
};

export type MixLineItem = OrderLineItem & {
    __typename?: 'MixLineItem';
    id: Scalars['ID'];
    quantity?: Maybe<Scalars['Int']>;
    meta: LineItemMeta;
    addons?: Maybe<Array<Maybe<CartAddon>>>;
};

export type MixLineItemInput = {
    meta?: Maybe<ItemMetaInput>;
    quantity?: Maybe<Scalars['Int']>;
    shippingDetails?: Maybe<ShippingDetails>;
};

export type MixSummary = {
    __typename?: 'MixSummary';
    contractId: Scalars['ID'];
    orderNumber: Scalars['ID'];
    payments: PaymentsUnionConnection;
    contract: Contract;
    devices: Array<MixDevice>;
    pastDevices?: Maybe<MixDeviceConnection>;
    state: ContractState;
    swap?: Maybe<Swap>;
};

export type MixSummaryPaymentsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

export type MixSummaryPastDevicesArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

export enum MixSwapShippingProgress {
    AWAITING_RETURN = 'AWAITING_RETURN',
    CHECKING_RETURN = 'CHECKING_RETURN',
    NEW_DEVICE_AWAITING_PICKUP = 'NEW_DEVICE_AWAITING_PICKUP',
    NEW_DEVICE_DELIVERED = 'NEW_DEVICE_DELIVERED',
    NEW_DEVICE_IN_TRANSIT = 'NEW_DEVICE_IN_TRANSIT',
    OLD_DEVICE_IN_TRANSIT = 'OLD_DEVICE_IN_TRANSIT',
    PROBLEM_WITH_RETURN = 'PROBLEM_WITH_RETURN',
}

export type MonthsToOwn = {
    __typename?: 'MonthsToOwn';
    availableSince: Scalars['ISO8601Date'];
    monthsRequired: Scalars['Int'];
    monthsToCoverRemaining: Scalars['Int'];
};

export enum Mood {
    inLove = 'inLove',
    happy = 'happy',
    neutral = 'neutral',
    sad = 'sad',
    angry = 'angry',
}

export type MoodOption = {
    __typename?: 'MoodOption';
    mood: Mood;
};

export type MoodOptions = {
    __typename?: 'MoodOptions';
    options: Array<MoodOption>;
};

export type MoodResult = {
    __typename?: 'MoodResult';
    inLoveVotes: Scalars['Int'];
    happyVotes: Scalars['Int'];
    neutralVotes: Scalars['Int'];
    sadVotes: Scalars['Int'];
    angryVotes: Scalars['Int'];
};

export type MoodResults = {
    __typename?: 'MoodResults';
    moodResults: Array<MoodResult>;
    sortedMoodResults: Array<MoodResultSorted>;
    total: TotalResults;
};

export type MoodResultSorted = {
    __typename?: 'MoodResultSorted';
    name: Scalars['String'];
    count: Scalars['Int'];
};

/** TODO: fix recursive usage as explained here https://github.com/99designs/gqlgen#how-do-i-prevent-fetching-child-objects-that-might-not-be-used */
export type Movement = {
    __typename?: 'Movement';
    ID: Scalars['String'];
    externalID: Scalars['String'];
    context: MovementContext;
    contextReason: Scalars['String'];
    paymentProcess: MovementPaymentProcess;
    paymentMethodID?: Maybe<Scalars['String']>;
    movementGroupingID?: Maybe<Scalars['String']>;
    status: MovementStatuses;
    statusDetails: Array<MovementStatusDetail>;
    errors?: Maybe<Scalars['JSON']>;
    movementResources: Array<MovementResource>;
    transaction?: Maybe<Transaction>;
    metadatas?: Maybe<Array<MovementMetadata>>;
    createdAt: Scalars['ISO8601DateTime'];
    statusSetAt: Scalars['ISO8601DateTime'];
};

export type MovementConnection = {
    __typename?: 'MovementConnection';
    totalCount: Scalars['Int'];
    edges?: Maybe<Array<MovementEdge>>;
    nodes?: Maybe<Array<Movement>>;
    pageInfo: PageInfo;
};

export enum MovementContext {
    REFUND = 'REFUND',
    PAYMENT = 'PAYMENT',
    CHARGE_BACK = 'CHARGE_BACK',
}

export type MovementEdge = {
    __typename?: 'MovementEdge';
    cursor: Scalars['ID'];
    node?: Maybe<Movement>;
};

export type MovementMetadata = {
    __typename?: 'MovementMetadata';
    paymentSource: Scalars['String'];
    paymentMethodType: Scalars['String'];
    paymentReference: Scalars['String'];
    providerResponse?: Maybe<Scalars['JSON']>;
    createdAt: Scalars['ISO8601DateTime'];
};

export enum MovementPaymentProcess {
    MANUAL = 'MANUAL',
    PAY_BY_INVOICE = 'PAY_BY_INVOICE',
    AUTOMATIC = 'AUTOMATIC',
}

export type MovementRequestEvent = {
    __typename?: 'MovementRequestEvent';
    event?: Maybe<Scalars['JSON']>;
    createdAt: Scalars['ISO8601DateTime'];
};

export type MovementResource = {
    __typename?: 'MovementResource';
    resource: Resource;
    movement: Movement;
    amountToAllocate: Scalars['Float'];
    movementID: Scalars['String'];
    resourceID: Scalars['String'];
};

export type MovementStatusDetail = {
    __typename?: 'MovementStatusDetail';
    status: MovementStatuses;
    createdAt: Scalars['ISO8601DateTime'];
    errors?: Maybe<Scalars['JSON']>;
};

export enum MovementStatuses {
    NEW = 'NEW',
    RUNNING = 'RUNNING',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
}

export type Mutation = {
    __typename?: 'Mutation';
    bulkAssociateAddons: BulkAssociationAddonsResult;
    createAddonAssociation: Scalars['Boolean'];
    removeAddonAssociations: RemoveAddonAssociationsResult;
    updateAssociationAddons: AddonsMainProduct;
    updateAssociationProvider: Array<AddonProvider>;
    createAddonProvider: Scalars['Boolean'];
    removeAddonProvider: Scalars['Boolean'];
    updateAddonProvider: Scalars['Boolean'];
    createDigitalOtpProduct: CreateDigitalOtpProductResult;
    removeDigitalOtpProduct: RemoveDigitalOtpProductResult;
    updateDigitalOtpProduct: UpdateDigitalOtpProductResult;
    createDigitalOfferings: CreateDigitalOfferingsResult;
    removeDigitalOfferings: RemoveDigitalOfferingResult;
    createDigitalOtpVariant: CreateDigitalOtpVariantResult;
    removeDigitalOtpVariant: RemoveDigitalOtpVariantResult;
    updateDigitalOtpVariant: UpdateDigitalOtpVariantResult;
    createDigitalProductLocalization: CreateDigitalProductLocalizationResult;
    removeDigitalProductLocalization: RemoveDigitalProductLocalizationResult;
    updateDigitalProductLocalization: UpdateDigitalProductLocalizationResult;
    retryAddonsApiEvent: Scalars['Boolean'];
    updateAddonsApiEvent: Scalars['String'];
    createAddressV2?: Maybe<Array<Maybe<Address>>>;
    updateConsolidatedBillingDay?: Maybe<Scalars['Float']>;
    cancelPayments: Array<PaymentOrderActionResponseItemGeneric>;
    attemptInvoiceGeneration: GenerateInvoiceResponse;
    putOnHoldPayments: Array<PaymentOrderActionResponseItemGeneric>;
    refundPayments: Array<PaymentOrderActionResponseItem>;
    retryPayments: Array<PaymentOrderActionResponseItem>;
    addAccount: AccountModel;
    /** @deprecated Consider using mutation setPaymentMethod. */
    updatePaymentMethodForContract: UpdatePaymentMethodForContractPayload;
    addPayByInvoiceAccount: AccountModel;
    addWalletAccount: AccountModel;
    bigMigrationCreateRecurrentPayments: BigMigrationCreateRecurrentPaymentsPayload;
    bigMigrationRevertRecurrentPayments: BigMigrationCreateRecurrentPaymentsPayload;
    createS3PresignedUrl: PresignedUrlCreateResult;
    parseXlsx: ParseXlsxResult;
    rentalPlanParseXlsx: RentalPlanXlsxResultOrError;
    imageUpload: ImageUploadResult;
    /**
     * Receives a list of images and return a list of images with the background
     * removed in pairs of old image URL and new image URL.
     */
    removeBackgroundBatch: Array<RemoveBackgroundBatchResult>;
    createBundle?: Maybe<BundleOrError>;
    updateBundle?: Maybe<BundleOrError>;
    upsertManyBundles: AffectedRowsOutput;
    createManyBundles: AffectedRowsOutput;
    deleteBundle?: Maybe<Bundle>;
    deleteManyBundles: AffectedRowsOutput;
    createKitWithProducts: Kit;
    createKit: Kit;
    updateKit: Kit;
    deleteKit: DeletedKit;
    toggleKitState: Kit;
    createKitProduct: KitProduct;
    deleteKitProduct: KitProduct;
    updateCart?: Maybe<Cart>;
    retailEmployeeValidate?: Maybe<EmployeeValidateResult>;
    orderCreate?: Maybe<MixCartOrder>;
    orderUpdate?: Maybe<MixCartOrder>;
    orderSubmit?: Maybe<MixCartOrder>;
    orderLineItemAdd?: Maybe<MixCartOrder>;
    addItemsToCart?: Maybe<MixCartOrder>;
    orderLineItemEdit?: Maybe<MixCartOrder>;
    orderLineItemRemove?: Maybe<MixCartOrder>;
    orderLineItemsRemove?: Maybe<MixCartOrder>;
    orderAddressAdd?: Maybe<MixCartOrder>;
    orderAddressChange?: Maybe<MixCartOrder>;
    orderPaymentMethodAdd?: Maybe<MixCartOrder>;
    orderPaymentMethodChange?: Maybe<MixCartOrder>;
    swapCreate?: Maybe<MixCartOrder>;
    swapSubmit?: Maybe<MixCartOrder>;
    orderOfferCreate?: Maybe<OrderOffer>;
    orderOfferUpdate?: Maybe<OrderOffer>;
    orderOffersDelete?: Maybe<Array<Maybe<OrderOffer>>>;
    orderOfferAccept?: Maybe<OrderOffer>;
    orderOfferReject?: Maybe<OrderOffer>;
    orderOfferLineItemEdit?: Maybe<MixCartOrder>;
    applyVoucher?: Maybe<MixCartOrder>;
    resetVoucher?: Maybe<MixCartOrder>;
    saveUserPersonalIdentification?: Maybe<Scalars['Boolean']>;
    orderAddonAddToLineItem?: Maybe<MixCartOrder>;
    orderAddonRemoveFromLineItem?: Maybe<MixCartOrder>;
    taskCancel: Task;
    taskCreateRentalPlanUpload: TaskRentalPlanUploadResult;
    taskExportRentalPlans: TaskRentalPlanExportResult;
    taskExportBundles: TaskBundlesExportResult;
    taskCreateProductsUpload: TaskProductsUploadResult;
    productFilterKeywordCreate: KeywordFilterOrError;
    productFilterRangeCreate: RangeFilterOrError;
    productFilterKeywordUpdate: KeywordFilterOrError;
    productFilterRangeUpdate: RangeFilterOrError;
    productFilterKeywordDelete: KeywordFilterOrError;
    productFilterRangeDelete: RangeFilterOrError;
    categoryCreate: CategoryOrError;
    categoryUpdate: CategoryOrError;
    categoryDelete: CategoryOrError;
    categoriesUpdatePosition: CatalogAffectedRowsOutput;
    energyLabelCreate: EnergyLabelOrError;
    energyLabelUpdate?: Maybe<EnergyLabelOrError>;
    energyLabelDelete?: Maybe<EnergyLabelOrError>;
    energyLabelsUpsertMany: CatalogAffectedRowsOutput;
    rentalPlanUpsertMany: CatalogRentalPlanUpsertManyOutput;
    rentalPlanImport: CatalogAffectedRowsOutput;
    rentalPlanDeleteMany: CatalogAffectedRowsOutput;
    updateProductLogistics: UpdateProductLogisticsSuccessOrError;
    updateProductSpecifications: UpdateProductSpecificationsSuccessOrError;
    upsertCatalogGrouping: CatalogGroupingUpsertSuccessOrError;
    regenerateInvoice?: Maybe<Scalars['JSON']>;
    bulkRegenerateInvoices?: Maybe<Scalars['JSON']>;
    /** @deprecated This mutation will be replaced with createCompany mutation - B2BP-257 */
    createLegacyCompany: Company;
    /** @deprecated This mutation will be replaced with updateCompany mutation - B2BP-257 */
    updateLegacyCompany: Company;
    forceCompanyApproval: Company;
    /**
     * Mutation owned by B2B Pillar, defined in company-service
     * @deprecated This mutation will be replaced with updateCompany mutation - B2BP-257
     */
    updateCompanyStatus: Company;
    /** Mutation owned by B2B Pillar, defined in company-service */
    syncCompanyLead: SalesforceLead;
    syncReceeve?: Maybe<Scalars['String']>;
    upsertProfile: DeliveryProfile;
    upsertRule: DeliveryRuleOperationResult;
    addToFavorites?: Maybe<AddToFavoritesPayload>;
    applyLoyaltyDiscount?: Maybe<ApplyLoyaltyDiscountPayload>;
    applySurpriseDiscount?: Maybe<ApplySurpriseDiscountPayload>;
    createAddress?: Maybe<CreateAddressPayload>;
    createCompanyAddress?: Maybe<CreateCompanyAddressPayload>;
    createPaymentMethod?: Maybe<CreatePaymentMethodPayload>;
    createUserConsents?: Maybe<CreateUserConsentsPayload>;
    createWaitingListTicket?: Maybe<CreateWaitingListTicketPayload>;
    /** Update Company mutation to be used by customers. UST is only updated when its current value is blank */
    customerUpdateCompany?: Maybe<CustomerUpdateCompanyPayload>;
    deleteAddresses?: Maybe<DeleteAddressesPayload>;
    deleteAuthDevice?: Maybe<DeleteAuthDevicePayload>;
    deleteOnfido?: Maybe<DeleteOnfidoPayload>;
    deleteUser?: Maybe<DeleteUserPayload>;
    dropUserPhoneNumber?: Maybe<DropUserPhoneNumberPayload>;
    enableCompanyPayByInvoice?: Maybe<EnableInvoicePaymentsPayload>;
    executeRakeTask?: Maybe<ExecuteRakeTaskPayload>;
    forceApproveCompany?: Maybe<ForceApprovePayload>;
    forcePasswordReset?: Maybe<ForcePasswordResetPayload>;
    providerLogin?: Maybe<ProviderLoginPayload>;
    removeFromFavorites?: Maybe<RemoveFromFavoritesPayload>;
    resetVerification?: Maybe<ResetVerificationPayload>;
    revertDiscountOffer?: Maybe<RevertDiscountOfferPayload>;
    submitBankAccountSnapshotData?: Maybe<SubmitBankAccountSnapshotDataPayload>;
    /** @deprecated Use submit_bank_account_snapshot_data instead */
    submitKontomatikSession?: Maybe<SubmitKontomatikSessionPayload>;
    submitVerificationDecision?: Maybe<SubmitVerificationDecisionPayload>;
    syncSubscriptionStatus?: Maybe<SyncSubscriptionStatusPayload>;
    syncUserPaymentMethods?: Maybe<SyncUserPaymentMethodsPayload>;
    unlockUser?: Maybe<UnlockUserPayload>;
    updateAddress?: Maybe<UpdateAddressPayload>;
    updateCompany?: Maybe<UpdateCompanyPayload>;
    updateCompanyAddress?: Maybe<CreateCompanyAddressPayload>;
    updateProduct?: Maybe<UpdateProductPayload>;
    updateSubscription?: Maybe<UpdateSubscriptionPayload>;
    updateSubscriptionTermsAndConditions?: Maybe<UpdateSubscriptionTermsAndConditionsPayload>;
    updateUser?: Maybe<UpdateUserPayload>;
    updateUserNewsletterOptInState?: Maybe<UpdateUserNewsletterOptInStatePayload>;
    updateUserTeams?: Maybe<UpdateUserTeamsPayload>;
    moveAssetsToStock: Array<AssetModel>;
    bulkStatusUpdateAsset: Array<AssetBulkStatusUpdateResult>;
    bulkWarehouseCodeUpdateAsset: Array<AssetBulkWarehouseCodeUpdateResult>;
    registerIncomingAssets: RegisterIncomingAssetsResult;
    bulkChargeForDamage: BulkChargeForDamagedAssetsResult;
    upsertAvailabilityCampaign: AvailabilityCampaign;
    deleteAvailabilityCampaign: AvailabilityCampaign;
    bumpReservationPriority: Array<Reservation>;
    createReplacement: ReplacementModel;
    deleteReplacement: ReplacementModel;
    processReallocation: ReallocationResult;
    allocateManually: AllocationResult;
    upsertDistributionRule: DistributionRule;
    deleteDistributionRule: Array<DistributionRule>;
    createStockTransfer: StockTransferModel;
    reuploadStockTransfer: StockTransferModel;
    markStockTransferAsCompleted: StockTransferModel;
    removeAssetsFromStockTransfer: StockTransferModel;
    addAssetsToStockTransfer: StockTransferModel;
    requestRefund: RequestRefundPayload;
    requestAutomaticCharge: RequestAutomaticChargePayload;
    requestBPICharge: RequestBpiChargePayload;
    createManualBooking: CreateManualBookingPayload;
    confirmRefund: ConfirmRefundPayload;
    confirmAutomaticCharge: ConfirmAutomaticChargePayload;
    bookPBI: BookPbiPayload;
    createResourceType: CreateResourceTypePayload;
    applyDiscount?: Maybe<ApplyDiscountPayload>;
    batchCancelContracts?: Maybe<BatchCancelContractsPayload>;
    batchPauseContracts?: Maybe<BatchPauseContractsPayload>;
    batchReviveContracts?: Maybe<BatchReviveContractsPayload>;
    cancelContract?: Maybe<CancelContractPayload>;
    cancelContractReturn?: Maybe<CancelContractReturnPayload>;
    cancelOrderEarly?: Maybe<CancelOrderEarlyPayload>;
    cancelSwap?: Maybe<CancelSwapPayload>;
    clearExpiredFlow?: Maybe<ClearExpiredFlowPayload>;
    completeContract?: Maybe<CompleteContractPayload>;
    extendContract?: Maybe<ExtendContractPayload>;
    extendContractPause?: Maybe<ExtendContractPausePayload>;
    pauseContract?: Maybe<PauseContractPayload>;
    reactivateContract?: Maybe<ReactivateContractPayload>;
    reallocateAsset?: Maybe<ReallocateAssetPayload>;
    removeDiscount?: Maybe<RemoveDiscountPayload>;
    reviveContract?: Maybe<ReviveContractPayload>;
    revokeContract?: Maybe<RevokeContractPayload>;
    startCustomerWishCancel?: Maybe<StartCustomerWishCancelPayload>;
    startCustomerWishKeepCharging?: Maybe<StartCustomerWishKeepChargingPayload>;
    startDamageClaim?: Maybe<StartDamageClaimPayload>;
    startInternalDebtCollection?: Maybe<StartInternalDebtCollectionPayload>;
    startPurchase?: Maybe<StartPurchasePayload>;
    startReplacement?: Maybe<StartReplacementPayload>;
    startUpsell?: Maybe<StartUpsellPayload>;
    syncProductVariants?: Maybe<SyncProductVariantsPayload>;
    updateAgreedPurchasePrice?: Maybe<UpdateAgreedPurchasePricePayload>;
    updateContractTermsAndConditions?: Maybe<UpdateContractTermsAndConditionsPayload>;
    useSurpriseDiscount?: Maybe<UseSurpriseDiscountPayload>;
    loyaltyGrantCash: LoyaltyActionResultBooking;
    loyaltyCaptureAmount: LoyaltyCreditTransactionCaptureResult;
    loyaltyCommitCapturedTransactions: LoyaltyCreditTransactionCaptureResult;
    loyaltyReleaseCapturedTransactions: LoyaltyCreditTransactionCaptureResult;
    loyaltyShopPurchase: LoyaltyShopPurchaseResult;
    /** Creates a campaign. Please make sure that you have a proper translations for bodyTemplate */
    createCampaign: UuidModel;
    /** Deletes a campaign */
    deleteCampaign: DeleteModel;
    /** All campaigns are created with PAUSED status by default, you can change the status to ACTIVE and opposite any time */
    updateCampaignStatus: CampaignModel;
    /** You can set condition after a campaign creation. This is useful if you what to update campaign conditions */
    setConditionUuid: CampaignModel;
    /** Creates a campaign action, you can add several actions for one campaign */
    createAction: UuidModel;
    /** Deletes an action */
    deleteAction: DeleteModel;
    /**
     * Creates a condition for a campaign, could be created before campaign or after,
     * but in this case you have to update conditionUuid for campaign
     */
    createCondition: UuidModel;
    /** Deletes a condition */
    deleteCondition: DeleteModel;
    /** Creates a translation */
    createTranslate: UuidModel;
    /** Updates a translation */
    updateTranslate: UpdateModel;
    /** Deletes a translation */
    deleteTranslate: DeleteModel;
    dismiss: DismissModel;
    /** Dismiss a campaign for selected entity */
    dismissCampaign: DismissModel;
    deleteDismiss: DeleteModel;
    /** Deletes a campaign dismiss for selected entity */
    deleteDismissCampaign: DeleteModel;
    deleteFinish: DeleteModel;
    /** Deletes a finished campaign for selected entity */
    deleteFinishCampaign: DeleteModel;
    /** Creates a campaign goal (Kafka event handler) */
    createGoal: UuidModel;
    /** Deletes a campaign goal (Kafka event handler) */
    deleteGoal: DeleteModel;
    /** Updates a notification trigger filter */
    updateNotificationTriggerFilter: NotificationTriggerFilter;
    /**
     * Initiates an onboarding procedure for GroverCard. That includes creating a
     * Person entity, thier credit record and pre-paid card application.
     */
    registerCardPerson: RegisterCardPersonResult;
    /** Initiates Person Identificaiton process via IDNow. */
    cardPersonIdentification: PersonIdentification;
    /** Activates physical VISA GroverCard by verification token written on it or with just confirming card details */
    activateCard: ActivateCardResult;
    /** Initiates Device Binding procedure by registering mobile device by phone number and public key. */
    registerMobilePhone: RegisterMobilePhoneResult;
    /** Finalise Device Binding process by verifying signature of SMS-TAN received after `registerMobilePhone` */
    verifyDevice: VerifyDeviceResult;
    changePin: ChangeRequestPending;
    updatePinPublicKey: UpdatePinPublicKeyResult;
    updatePin: UpdatePinResult;
    verifyChangeRequest: VerifyChangeRequestResult;
    verifyChangePin: VerifyChangeRequestResult;
    verifyDeviceAuthorisation: VerifyDeviceAuthResult;
    authoriseDevice: AuthDeviceResult;
    changeTaxId: ChangeTaxIdResult;
    accountStatement: AccountStatementPdfResult;
    smartAgentCaseUpdate: SmartAgentCasesResult;
    blockCard: CardBlockUnblockResult;
    unBlockCard: CardBlockUnblockResult;
    editPerson: ChangeRequestPending;
    /** Modifies person settings incl. language */
    cardPersonSettings?: Maybe<PersonSettings>;
    createSeizurePayment: ChangeRequestPending;
    updateSCAChallengeStatus: VerifyChangeRequestResult;
    authorizeSCAChallenge: ChangeRequestPending;
    secureCardDetails: SecureCardDetailsResult;
    referenceAccount: AddReferenceAccountResult;
    payoutReferenceAccount: ChangeRequestPending;
    /** Send pre-contractual information via email to the customer, if it has not sent before */
    sendPreContractualInformation: PreContractualInformationResult;
    acceptTNC: RegisterCardPersonResult;
    /** Set the timestamp when the user accepts the Sepa Direct Debit Mandate. */
    setSddAcceptedAt: SddAcceptedAtResult;
    markCardAsLostStolen: MarkCardAsLostStolenResult;
    reorderCard: ReorderCardResult;
    /** Set the timestamp when the user accepts the FATCA. */
    setOwnEconomicInterestSignedAtResult: OwnEconomicInterestSignedAtResult;
    /** Verify mobile phone with SMS Tan */
    verifyMobilePhone: VerifyMobilePhoneResult;
    /** Initiates update phone number by deleting the current one and adding the new one */
    updateMobilePhone: UpdateMobilePhoneResult;
    /** Initiates update phone number by deleting the current one and adding the new one */
    confirmUpdateMobilePhone: ConfirmUpdateMobilePhoneResult;
    updateTNCDocument: UpdateTncDocumentResult;
    updateTNCDocuments: Array<UpdateTncDocumentResult>;
    deleteDevice: DeleteDeviceResult;
    deleteDevices: Array<DeleteDeviceResult>;
    addKey: AddKeyResult;
    /** Grover login */
    groverLogin: GroverLoginResult;
    googlePushProvisioning: ChangeRequestPending;
    verifyGooglePushProvisioning: VerifyGooglePushProvisioningResult;
    psd2Challenge: Psd2ChallengeResult;
    verifyPSD2Challenge: VerifyPsd2ChallengeResult;
    /** Initiates update AML follow up date when user should confirm their data accuracy */
    updateAmlFollowUp: UpdateAmlFollowUpResult;
    /** Confirming AML follow up date update when user confirmed their data accuracy */
    confirmUpdateAmlFollowUp: ConfirmUpdateAmlFollowUpResult;
    createTopUp: CreateTopUpResult;
    cancelTopUp: CancelTopUpResult;
    applePushProvisioning: ChangeRequestPending;
    verifyApplePushProvisioning: VerifyApplePushProvisioningResult;
    deviceMonitoring: DeviceMonitoringResult;
    updateDeviceMonitoring: DeviceMonitoringResult;
    deviceActivities: DeviceActivitiesResult;
    trackApplePushProvisioning: TrackApplePushProvisioningResult;
    completeActivationChecklist: CompleteActivationChecklistResult;
    createVariantFlag: VariantFlagCreateManyOutput;
    updateVariantFlag: VariantFlag;
    createProductCampaign: ProductCampaign;
    updateProductCampaign: ProductCampaign;
    addProductsToCampaign: ProductCampaign;
    createCountdownTimer: CountdownTimer;
    updateCountdownTimer: CountdownTimer;
    addProductsToCountdownTimer: AddProductsToCountdownTimerOutput;
    deleteProductsFromCountdownTimer: DeleteProductFromCountdownTimerOutput;
    createCheckoutAttempt?: Maybe<CreateCheckoutAttempt>;
    getVotingTicket: Ticket;
    castVote: Vote;
    /** @deprecated It will be replaced by `createReferralConnection` */
    createConnection: Scalars['Boolean'];
    createReferralConnection: CreateConnectionResponse;
    cancelInboundShipmentsByContractId: CancelShipmentsResult;
    exportShipments: ExportMutationResult;
    generateReturnLabels: GenerateReturnLabelsResult;
    syncShipment: SyncShipmentResultType;
    updateTrackingInformation: UpdateTrackingInformationResultType;
    upsertShippingProfileRule: ShippingProfileRuleMutationResult;
    createTermsAndConditions: TermsAndConditionsLocales;
    deleteTermsAndConditions: TermsAndConditionsLocales;
    updateStoreCodes: TermsAndConditionsLocales;
    publishTermsAndConditions: TermsAndConditionsLocales;
    updateDaysToDecide: TermsAndConditionsLocales;
    addLocale: TermsAndConditionsLocales;
    deleteLocale: TermsAndConditionsLocales;
    updateLocale: TermsAndConditionsLocales;
    updateDisabledFeatures: TermsAndConditionsLocales;
    /** Adds a new Wallet payment method for the current user. */
    addPaymentMethod: AddPaymentMethodResult;
    /**
     * Prepares a Wallet payment method to be used for the given order at checkout.
     * Triggers authorization if Auth & Capture flow is required for the method/order pair.
     */
    usePaymentMethod: UsePaymentMethodResult;
    /** Deletes a payment method via Wallet. */
    deletePaymentMethod: Scalars['String'];
    /** Sets a Wallet payment method for a contract/subscription. */
    setPaymentMethod: Scalars['String'];
    /**
     * Updates Wallet payment method's contracts and subscriptions to a different
     * payment method, with the option of deleting the old one.
     */
    replacePaymentMethod: Scalars['String'];
    productsWidgetCreate: ProductsWidgetOrError;
    productsWidgetUpdate?: Maybe<ProductsWidgetOrError>;
    productsWidgetDelete?: Maybe<ProductsWidgetOrError>;
    toggleB2BDashboardAccess: DashboardAccessRights;
    bulkB2BDashboardActivate: Array<DashboardAccessRights>;
    updateWorkAsset: WorkAsset;
    removeWorkAsset: WorkAsset;
    assignEmployeeToAsset: WorkAsset;
    clearAssetEmployeeAssignment: WorkAsset;
    businessReturnAsset: WorkAsset;
    createWorkEmployee: WorkEmployee;
    updateWorkEmployee: WorkEmployee;
    removeWorkEmployee: WorkEmployee;
    assignAssetsToEmployee: WorkEmployee;
    importEmployeesPresignedUrl: WorkS3Presignedurl;
    importEmployeesFromS3: ImportEmployeesResponse;
};

export type MutationBulkAssociateAddonsArgs = {
    input: BulkAssociationAddonsInput;
};

export type MutationCreateAddonAssociationArgs = {
    input: CreateAddonAssociationInput;
};

export type MutationRemoveAddonAssociationsArgs = {
    input: RemoveAddonAssociationsInput;
};

export type MutationUpdateAssociationAddonsArgs = {
    input: UpdateAssociationAddonsInput;
};

export type MutationUpdateAssociationProviderArgs = {
    input: UpdateAssociationProviderInput;
};

export type MutationCreateAddonProviderArgs = {
    input: CreateAddonProviderInput;
};

export type MutationRemoveAddonProviderArgs = {
    id: Scalars['ID'];
};

export type MutationUpdateAddonProviderArgs = {
    input: UpdateAddonProviderInput;
};

export type MutationCreateDigitalOtpProductArgs = {
    input: CreateDigitalOtpProductInput;
};

export type MutationRemoveDigitalOtpProductArgs = {
    input: RemoveDigitalOtpProductInput;
};

export type MutationUpdateDigitalOtpProductArgs = {
    input: UpdateDigitalOtpProductInput;
};

export type MutationCreateDigitalOfferingsArgs = {
    input: CreateDigitalOfferingsInput;
};

export type MutationRemoveDigitalOfferingsArgs = {
    input: RemoveDigitalOfferingInput;
};

export type MutationCreateDigitalOtpVariantArgs = {
    input: CreateDigitalOtpVariantInput;
};

export type MutationRemoveDigitalOtpVariantArgs = {
    input: RemoveDigitalOtpVariantInput;
};

export type MutationUpdateDigitalOtpVariantArgs = {
    input: UpdateDigitalOtpVariantInput;
};

export type MutationCreateDigitalProductLocalizationArgs = {
    input: CreateDigitalProductLocalizationInput;
};

export type MutationRemoveDigitalProductLocalizationArgs = {
    input: RemoveDigitalProductLocalizationInput;
};

export type MutationUpdateDigitalProductLocalizationArgs = {
    input: UpdateDigitalProductLocalizationInput;
};

export type MutationRetryAddonsApiEventArgs = {
    input: RetryAddonsApiEventInput;
};

export type MutationUpdateAddonsApiEventArgs = {
    input: UpdateAddonsApiEventInput;
};

export type MutationCreateAddressV2Args = {
    input: CreateAddressInputV2;
};

export type MutationUpdateConsolidatedBillingDayArgs = {
    targetUserId?: Maybe<Scalars['String']>;
    dayOfMonth?: Maybe<Scalars['Float']>;
};

export type MutationCancelPaymentsArgs = {
    payments: Array<PaymentOrdersRequest>;
};

export type MutationAttemptInvoiceGenerationArgs = {
    paymentGroupId: Scalars['String'];
    userId: Scalars['Float'];
};

export type MutationPutOnHoldPaymentsArgs = {
    payments: Array<PaymentOrdersRequest>;
};

export type MutationRefundPaymentsArgs = {
    payments: Array<PaymentOrderRefundRequest>;
};

export type MutationRetryPaymentsArgs = {
    payments: Array<PaymentOrderRetryRequest>;
};

export type MutationAddAccountArgs = {
    options: AddAccountInput;
};

export type MutationUpdatePaymentMethodForContractArgs = {
    input: UpdatePaymentMethodForContractInput;
};

export type MutationAddPayByInvoiceAccountArgs = {
    options: AddPayByInvoiceAccountInput;
};

export type MutationAddWalletAccountArgs = {
    options: AddWalletAccountInput;
};

export type MutationBigMigrationCreateRecurrentPaymentsArgs = {
    input: BigMigrationCreateRecurrentPaymentsInput;
};

export type MutationBigMigrationRevertRecurrentPaymentsArgs = {
    input: BigMigrationRevertRecurrentPaymentsInput;
};

export type MutationCreateS3PresignedUrlArgs = {
    input: PresignedUrlCreateInput;
};

export type MutationParseXlsxArgs = {
    input: ParseXlsxInput;
};

export type MutationRentalPlanParseXlsxArgs = {
    input: RentalPlanXlsxInput;
};

export type MutationImageUploadArgs = {
    file: Scalars['Upload'];
    folderName?: Maybe<ImageFolders>;
    cloudinaryPublicId?: Maybe<Scalars['String']>;
    key?: Maybe<Scalars['String']>;
};

export type MutationRemoveBackgroundBatchArgs = {
    urls: Array<Scalars['String']>;
};

export type MutationCreateBundleArgs = {
    data: BundleCreateInput;
};

export type MutationUpdateBundleArgs = {
    data: BundleUpdateInput;
    where: BundleWhereUniqueInput;
};

export type MutationUpsertManyBundlesArgs = {
    data: BundleBulkCreateInput;
};

export type MutationCreateManyBundlesArgs = {
    data: BundleBulkCreateInput;
};

export type MutationDeleteBundleArgs = {
    where: BundleWhereUniqueInput;
};

export type MutationDeleteManyBundlesArgs = {
    where?: Maybe<BundleWhereInput>;
};

export type MutationCreateKitWithProductsArgs = {
    createKitWithProductsInput: CreateKitWithProductsInput;
};

export type MutationCreateKitArgs = {
    createKitInput: CreateKitInput;
};

export type MutationUpdateKitArgs = {
    updateKitInput: UpdateKitInput;
};

export type MutationDeleteKitArgs = {
    uuid: Scalars['String'];
};

export type MutationToggleKitStateArgs = {
    toggleKitStateInput: ToggleKitStateInput;
};

export type MutationCreateKitProductArgs = {
    createKitProductInput: CreateKitProductInput;
};

export type MutationDeleteKitProductArgs = {
    uuid: Scalars['String'];
};

export type MutationUpdateCartArgs = {
    cartId: Scalars['ID'];
    userId: Scalars['String'];
};

export type MutationRetailEmployeeValidateArgs = {
    input: EmployeeValidationInput;
};

export type MutationOrderCreateArgs = {
    order?: Maybe<CartOrderCreateInput>;
};

export type MutationOrderUpdateArgs = {
    orderNumber?: Maybe<Scalars['ID']>;
    step?: Maybe<Scalars['String']>;
};

export type MutationOrderSubmitArgs = {
    orderNumber?: Maybe<Scalars['ID']>;
    input?: Maybe<CartOrderSubmitInput>;
};

export type MutationOrderLineItemAddArgs = {
    orderNumber?: Maybe<Scalars['ID']>;
    item?: Maybe<MixLineItemInput>;
};

export type MutationAddItemsToCartArgs = {
    orderNumber: Scalars['ID'];
    items: Array<LineItemAddInput>;
};

export type MutationOrderLineItemEditArgs = {
    orderNumber: Scalars['ID'];
    item: LineItemUpdateInput;
};

export type MutationOrderLineItemRemoveArgs = {
    orderNumber?: Maybe<Scalars['ID']>;
    itemId?: Maybe<Scalars['Int']>;
};

export type MutationOrderLineItemsRemoveArgs = {
    orderNumber?: Maybe<Scalars['ID']>;
    itemIds: Array<Scalars['Int']>;
};

export type MutationOrderAddressAddArgs = {
    orderNumber?: Maybe<Scalars['ID']>;
    input?: Maybe<CartOrderUpdateInput>;
};

export type MutationOrderAddressChangeArgs = {
    orderNumber?: Maybe<Scalars['ID']>;
    input?: Maybe<CartOrderUpdateInput>;
};

export type MutationOrderPaymentMethodAddArgs = {
    orderNumber?: Maybe<Scalars['ID']>;
    input?: Maybe<CartOrderUpdateInput>;
};

export type MutationOrderPaymentMethodChangeArgs = {
    orderNumber?: Maybe<Scalars['ID']>;
    input?: Maybe<CartOrderUpdateInput>;
};

export type MutationSwapCreateArgs = {
    order?: Maybe<CreateSwapOrderInput>;
};

export type MutationSwapSubmitArgs = {
    orderNumber?: Maybe<Scalars['ID']>;
    input?: Maybe<CartOrderSubmitInput>;
};

export type MutationOrderOfferCreateArgs = {
    offer?: Maybe<OrderOfferCreateInput>;
};

export type MutationOrderOfferUpdateArgs = {
    offerUpdate?: Maybe<OrderOfferUpdateInput>;
};

export type MutationOrderOffersDeleteArgs = {
    ids: Array<Scalars['Int']>;
};

export type MutationOrderOfferAcceptArgs = {
    offerAccept?: Maybe<OrderOfferAcceptInput>;
};

export type MutationOrderOfferRejectArgs = {
    id?: Maybe<Scalars['Int']>;
};

export type MutationOrderOfferLineItemEditArgs = {
    orderNumber: Scalars['ID'];
    item: OfferLineItemUpdateInput;
};

export type MutationApplyVoucherArgs = {
    orderNumber?: Maybe<Scalars['ID']>;
    voucher?: Maybe<VoucherRedemptionInput>;
};

export type MutationResetVoucherArgs = {
    orderNumber?: Maybe<Scalars['ID']>;
};

export type MutationSaveUserPersonalIdentificationArgs = {
    identificationNumber: Scalars['ID'];
};

export type MutationOrderAddonAddToLineItemArgs = {
    orderNumber: Scalars['ID'];
    itemId: Scalars['ID'];
    addonId: Scalars['ID'];
    selectedAddonVariantId?: Maybe<Scalars['String']>;
};

export type MutationOrderAddonRemoveFromLineItemArgs = {
    orderNumber: Scalars['ID'];
    itemId: Scalars['ID'];
    addonId: Scalars['ID'];
};

export type MutationTaskCancelArgs = {
    id: Scalars['ID'];
};

export type MutationTaskCreateRentalPlanUploadArgs = {
    input: RentalPlanUploadTaskCreateInput;
};

export type MutationTaskExportRentalPlansArgs = {
    where?: Maybe<RentalPlanExportWhereInput>;
};

export type MutationTaskCreateProductsUploadArgs = {
    input: ProductsUploadTaskCreateInput;
};

export type MutationProductFilterKeywordCreateArgs = {
    data: ProductFilterKeywordCreateInput;
};

export type MutationProductFilterRangeCreateArgs = {
    data: ProductFilterRangeCreateInput;
};

export type MutationProductFilterKeywordUpdateArgs = {
    id: Scalars['Int'];
    data: ProductFilterKeywordUpdateInput;
};

export type MutationProductFilterRangeUpdateArgs = {
    id: Scalars['Int'];
    data: ProductFilterRangeUpdateInput;
};

export type MutationProductFilterKeywordDeleteArgs = {
    id: Scalars['Int'];
};

export type MutationProductFilterRangeDeleteArgs = {
    id: Scalars['Int'];
};

export type MutationCategoryCreateArgs = {
    data: CategoryCreateInput;
};

export type MutationCategoryUpdateArgs = {
    id: Scalars['ID'];
    data: CategoryUpdateInput;
};

export type MutationCategoryDeleteArgs = {
    id: Scalars['ID'];
};

export type MutationCategoriesUpdatePositionArgs = {
    data: Array<CategoryPositionUpdateInput>;
};

export type MutationEnergyLabelCreateArgs = {
    data: EnergyLabelCreateInput;
};

export type MutationEnergyLabelUpdateArgs = {
    id: Scalars['Int'];
    data: EnergyLabelUpdateInput;
};

export type MutationEnergyLabelDeleteArgs = {
    id: Scalars['Int'];
};

export type MutationEnergyLabelsUpsertManyArgs = {
    data: EnergyLabelBulkCreateInput;
};

export type MutationRentalPlanUpsertManyArgs = {
    rentalPlans: Array<RentalPlanUpsertInput>;
};

export type MutationRentalPlanImportArgs = {
    input: RentalPlanImportInput;
};

export type MutationRentalPlanDeleteManyArgs = {
    where: Array<RentalPlanDeleteWhereInput>;
};

export type MutationUpdateProductLogisticsArgs = {
    sku: Scalars['String'];
    data: ProductLogisticsInput;
};

export type MutationUpdateProductSpecificationsArgs = {
    sku: Scalars['String'];
    specifications: Array<ProductSpecificationInput>;
};

export type MutationUpsertCatalogGroupingArgs = {
    data: CatalogGroupingUpsertInput;
};

export type MutationRegenerateInvoiceArgs = {
    invoiceId: Scalars['String'];
    invoiceData: Scalars['JSON'];
    useLegacyNumber?: Maybe<Scalars['Boolean']>;
};

export type MutationBulkRegenerateInvoicesArgs = {
    invoiceIdsList: Array<Scalars['String']>;
    fields: Scalars['JSON'];
    useLegacyNumber?: Maybe<Scalars['Boolean']>;
    autoRegenerateFields?: Maybe<AutoRegenerateFields>;
};

export type MutationCreateLegacyCompanyArgs = {
    input: CreateLegacyCompanyInput;
};

export type MutationUpdateLegacyCompanyArgs = {
    input: UpdateLegacyCompanyInput;
};

export type MutationForceCompanyApprovalArgs = {
    input: ForceApproveCompanyInput;
};

export type MutationUpdateCompanyStatusArgs = {
    input: UpdateCompanyStatusInput;
};

export type MutationSyncCompanyLeadArgs = {
    input: SyncCompanyLeadInput;
};

export type MutationSyncReceeveArgs = {
    paymentOrderEvent?: Maybe<PaymentOrderEvent>;
};

export type MutationUpsertProfileArgs = {
    profile: DeliveryProfileInput;
};

export type MutationUpsertRuleArgs = {
    rule: DeliveryProfileRuleInput;
};

export type MutationAddToFavoritesArgs = {
    input: AddToFavoritesInput;
};

export type MutationApplyLoyaltyDiscountArgs = {
    input: ApplyLoyaltyDiscountInput;
};

export type MutationApplySurpriseDiscountArgs = {
    input: ApplySurpriseDiscountInput;
};

export type MutationCreateAddressArgs = {
    input: CreateAddressInput;
};

export type MutationCreateCompanyAddressArgs = {
    input: CreateCompanyAddressInput;
};

export type MutationCreatePaymentMethodArgs = {
    input: CreatePaymentMethodInput;
};

export type MutationCreateUserConsentsArgs = {
    input: CreateUserConsentsInput;
};

export type MutationCreateWaitingListTicketArgs = {
    input: CreateWaitingListTicketInput;
};

export type MutationCustomerUpdateCompanyArgs = {
    input: CustomerUpdateCompanyInput;
};

export type MutationDeleteAddressesArgs = {
    input: DeleteAddressesInput;
};

export type MutationDeleteAuthDeviceArgs = {
    input: DeleteAuthDeviceInput;
};

export type MutationDeleteOnfidoArgs = {
    input: DeleteOnfidoInput;
};

export type MutationDeleteUserArgs = {
    input: DeleteUserInput;
};

export type MutationDropUserPhoneNumberArgs = {
    input: DropUserPhoneNumberInput;
};

export type MutationEnableCompanyPayByInvoiceArgs = {
    input: EnableInvoicePaymentsInput;
};

export type MutationExecuteRakeTaskArgs = {
    input: ExecuteRakeTaskInput;
};

export type MutationForceApproveCompanyArgs = {
    input: ForceApproveInput;
};

export type MutationForcePasswordResetArgs = {
    input: ForcePasswordResetInput;
};

export type MutationProviderLoginArgs = {
    input: ProviderLoginInput;
};

export type MutationRemoveFromFavoritesArgs = {
    input: RemoveFromFavoritesInput;
};

export type MutationResetVerificationArgs = {
    input: ResetVerificationInput;
};

export type MutationRevertDiscountOfferArgs = {
    input: RevertDiscountOfferInput;
};

export type MutationSubmitBankAccountSnapshotDataArgs = {
    input: SubmitBankAccountSnapshotDataInput;
};

export type MutationSubmitKontomatikSessionArgs = {
    input: SubmitKontomatikSessionInput;
};

export type MutationSubmitVerificationDecisionArgs = {
    input: SubmitVerificationDecisionInput;
};

export type MutationSyncSubscriptionStatusArgs = {
    input: SyncSubscriptionStatusInput;
};

export type MutationSyncUserPaymentMethodsArgs = {
    input: SyncUserPaymentMethodsInput;
};

export type MutationUnlockUserArgs = {
    input: UnlockUserInput;
};

export type MutationUpdateAddressArgs = {
    input: UpdateAddressInput;
};

export type MutationUpdateCompanyArgs = {
    input: UpdateCompanyInput;
};

export type MutationUpdateCompanyAddressArgs = {
    input: CreateCompanyAddressInput;
};

export type MutationUpdateProductArgs = {
    input: UpdateProductInput;
};

export type MutationUpdateSubscriptionArgs = {
    input: UpdateSubscriptionInput;
};

export type MutationUpdateSubscriptionTermsAndConditionsArgs = {
    input: UpdateSubscriptionTermsAndConditionsInput;
};

export type MutationUpdateUserArgs = {
    input: UpdateUserInput;
};

export type MutationUpdateUserNewsletterOptInStateArgs = {
    input: UpdateUserNewsletterOptInStateInput;
};

export type MutationUpdateUserTeamsArgs = {
    input: UpdateUserTeamsInput;
};

export type MutationMoveAssetsToStockArgs = {
    assetUids?: Maybe<Array<Scalars['String']>>;
    serialNumbers?: Maybe<Array<Scalars['String']>>;
    stockUid: Scalars['String'];
};

export type MutationBulkStatusUpdateAssetArgs = {
    skipStatusCheck?: Maybe<Scalars['Boolean']>;
    update: Array<AssetBulkUpdateInput>;
};

export type MutationBulkWarehouseCodeUpdateAssetArgs = {
    update: Array<AssetBulkWarehouseCodeUpdateInput>;
};

export type MutationRegisterIncomingAssetsArgs = {
    purchaseRequestItemName: Scalars['String'];
    serialNumbers: Array<Scalars['String']>;
};

export type MutationBulkChargeForDamageArgs = {
    data: BulkChargeForDamagedAssetsInput;
};

export type MutationUpsertAvailabilityCampaignArgs = {
    AvailabilityCampaign: AvailabilityCampaignUpsertInput;
};

export type MutationDeleteAvailabilityCampaignArgs = {
    AvailabilityCampaign: AvailabilityCampaignFilterInput;
};

export type MutationBumpReservationPriorityArgs = {
    orderNumber?: Maybe<Scalars['String']>;
    uid?: Maybe<Scalars['String']>;
    priority: Scalars['Float'];
};

export type MutationCreateReplacementArgs = {
    allocationUid: Scalars['String'];
    reason: ReplacementReason;
    assetUid?: Maybe<Scalars['String']>;
};

export type MutationDeleteReplacementArgs = {
    replacementUid: Scalars['String'];
};

export type MutationProcessReallocationArgs = {
    orderNumber: Scalars['String'];
    serialNumber: Scalars['String'];
    status: Scalars['String'];
};

export type MutationAllocateManuallyArgs = {
    reservationUid: Scalars['String'];
    assetUid: Scalars['String'];
    skipSkuCheck?: Maybe<Scalars['Boolean']>;
    skipReservationStatusCheck?: Maybe<Scalars['Boolean']>;
};

export type MutationUpsertDistributionRuleArgs = {
    rule: DistributionRuleInput;
};

export type MutationDeleteDistributionRuleArgs = {
    rule: DistributionRuleInput;
};

export type MutationCreateStockTransferArgs = {
    refId: Scalars['String'];
    oldWarehouseCode: WarehouseCode;
    newWarehouseCode: WarehouseCode;
    serialNumbers: Array<Scalars['String']>;
};

export type MutationReuploadStockTransferArgs = {
    refId: Scalars['String'];
};

export type MutationMarkStockTransferAsCompletedArgs = {
    refId: Scalars['String'];
};

export type MutationRemoveAssetsFromStockTransferArgs = {
    refId: Scalars['String'];
    serialNumbers: Array<Scalars['String']>;
};

export type MutationAddAssetsToStockTransferArgs = {
    refId: Scalars['String'];
    serialNumbers: Array<Scalars['String']>;
};

export type MutationRequestRefundArgs = {
    input: RequestRefundInput;
};

export type MutationRequestAutomaticChargeArgs = {
    input: RequestAutomaticChargeInput;
};

export type MutationRequestBpiChargeArgs = {
    input: RequestBpiChargeInput;
};

export type MutationCreateManualBookingArgs = {
    input: CreateManualBookingInput;
};

export type MutationConfirmRefundArgs = {
    input: ConfirmRefundInput;
};

export type MutationConfirmAutomaticChargeArgs = {
    input: ConfirmAutomaticChargeInput;
};

export type MutationBookPbiArgs = {
    input: BookPbiInput;
};

export type MutationCreateResourceTypeArgs = {
    input: CreateResourceTypeInput;
};

export type MutationApplyDiscountArgs = {
    input: ApplyDiscountInput;
};

export type MutationBatchCancelContractsArgs = {
    input: BatchCancelContractsInput;
};

export type MutationBatchPauseContractsArgs = {
    input: BatchPauseContractsInput;
};

export type MutationBatchReviveContractsArgs = {
    input: BatchReviveContractsInput;
};

export type MutationCancelContractArgs = {
    input: CancelContractInput;
};

export type MutationCancelContractReturnArgs = {
    input: CancelContractReturnInput;
};

export type MutationCancelOrderEarlyArgs = {
    input: CancelOrderEarlyInput;
};

export type MutationCancelSwapArgs = {
    input: CancelSwapInput;
};

export type MutationClearExpiredFlowArgs = {
    input: ClearExpiredFlowInput;
};

export type MutationCompleteContractArgs = {
    input: CompleteContractInput;
};

export type MutationExtendContractArgs = {
    input: ExtendContractInput;
};

export type MutationExtendContractPauseArgs = {
    input: ExtendContractPauseInput;
};

export type MutationPauseContractArgs = {
    input: PauseContractInput;
};

export type MutationReactivateContractArgs = {
    input: ReactivateContractInput;
};

export type MutationReallocateAssetArgs = {
    input: ReallocateAssetInput;
};

export type MutationRemoveDiscountArgs = {
    input: RemoveDiscountInput;
};

export type MutationReviveContractArgs = {
    input: ReviveContractInput;
};

export type MutationRevokeContractArgs = {
    input: RevokeContractInput;
};

export type MutationStartCustomerWishCancelArgs = {
    input: StartCustomerWishCancelInput;
};

export type MutationStartCustomerWishKeepChargingArgs = {
    input: StartCustomerWishKeepChargingInput;
};

export type MutationStartDamageClaimArgs = {
    input: StartDamageClaimInput;
};

export type MutationStartInternalDebtCollectionArgs = {
    input: StartInternalDebtCollectionInput;
};

export type MutationStartPurchaseArgs = {
    input: StartPurchaseInput;
};

export type MutationStartReplacementArgs = {
    input: StartReplacementInput;
};

export type MutationStartUpsellArgs = {
    input: StartUpsellInput;
};

export type MutationSyncProductVariantsArgs = {
    input: SyncProductVariantsInput;
};

export type MutationUpdateAgreedPurchasePriceArgs = {
    input: UpdateAgreedPurchasePriceInput;
};

export type MutationUpdateContractTermsAndConditionsArgs = {
    input: UpdateContractTermsAndConditionsInput;
};

export type MutationUseSurpriseDiscountArgs = {
    input: UseSurpriseDiscountInput;
};

export type MutationLoyaltyGrantCashArgs = {
    input: ManualGrantInput;
};

export type MutationLoyaltyCaptureAmountArgs = {
    amount: Scalars['Int'];
};

export type MutationLoyaltyCommitCapturedTransactionsArgs = {
    commitInfo: LoyaltyCreditTransactionCommitInfo;
};

export type MutationLoyaltyReleaseCapturedTransactionsArgs = {
    captureId: Scalars['String'];
};

export type MutationLoyaltyShopPurchaseArgs = {
    productId: Scalars['String'];
};

export type MutationCreateCampaignArgs = {
    campaign: CampaignInputType;
};

export type MutationDeleteCampaignArgs = {
    uuid: Scalars['String'];
};

export type MutationUpdateCampaignStatusArgs = {
    status: StatusTypes;
    uuid: Scalars['String'];
};

export type MutationSetConditionUuidArgs = {
    conditionUuid: Scalars['String'];
    uuid: Scalars['String'];
};

export type MutationCreateActionArgs = {
    action: ActionInputType;
};

export type MutationDeleteActionArgs = {
    uuid: Scalars['String'];
};

export type MutationCreateConditionArgs = {
    condition: ConditionInputType;
};

export type MutationDeleteConditionArgs = {
    uuid: Scalars['String'];
};

export type MutationCreateTranslateArgs = {
    translate: TranslateInputType;
};

export type MutationUpdateTranslateArgs = {
    translate: TranslateUpdateInputType;
    uuid: Scalars['String'];
};

export type MutationDeleteTranslateArgs = {
    uuid: Scalars['String'];
};

export type MutationDismissArgs = {
    campaignUuid: Scalars['String'];
};

export type MutationDismissCampaignArgs = {
    externalEntity: Scalars['String'];
    entity: Scalars['String'];
    campaignUuid: Scalars['String'];
};

export type MutationDeleteDismissArgs = {
    externalCustomer: Scalars['String'];
    campaignUuid: Scalars['String'];
};

export type MutationDeleteDismissCampaignArgs = {
    externalEntity: Scalars['String'];
    entity: Scalars['String'];
    campaignUuid: Scalars['String'];
};

export type MutationDeleteFinishArgs = {
    externalCustomer: Scalars['String'];
    campaignUuid: Scalars['String'];
};

export type MutationDeleteFinishCampaignArgs = {
    externalEntity: Scalars['String'];
    entity: Scalars['String'];
    campaignUuid: Scalars['String'];
};

export type MutationCreateGoalArgs = {
    goal: GoalInputType;
};

export type MutationDeleteGoalArgs = {
    uuid: Scalars['String'];
};

export type MutationUpdateNotificationTriggerFilterArgs = {
    uuid: Scalars['ID'];
    trigger: NotificationTriggerFilterUpdateInput;
};

export type MutationRegisterCardPersonArgs = {
    info: RegisterCardPersonInfo;
};

export type MutationCardPersonIdentificationArgs = {
    info: PersonIdentificationInfo;
};

export type MutationActivateCardArgs = {
    info: ActivateCardInfo;
};

export type MutationRegisterMobilePhoneArgs = {
    info: RegisterMobilePhoneInfo;
};

export type MutationVerifyDeviceArgs = {
    info: VerifyDeviceInfo;
};

export type MutationChangePinArgs = {
    info: ChangePinInfo;
};

export type MutationUpdatePinPublicKeyArgs = {
    info: UpdatePinPublicKeyInfo;
};

export type MutationUpdatePinArgs = {
    info: UpdatePinInfo;
};

export type MutationVerifyChangeRequestArgs = {
    info: VerifyChangeRequestInfo;
};

export type MutationVerifyChangePinArgs = {
    info: VerifyChangePinInfo;
};

export type MutationVerifyDeviceAuthorisationArgs = {
    info: VerifyDeviceAuthInfo;
};

export type MutationAuthoriseDeviceArgs = {
    info: AuthDeviceInfo;
};

export type MutationChangeTaxIdArgs = {
    info: ChangeTaxIdInfo;
};

export type MutationAccountStatementArgs = {
    info: AccountStatementInfo;
};

export type MutationSmartAgentCaseUpdateArgs = {
    info: SmartAgentCasesInfo;
};

export type MutationBlockCardArgs = {
    info: BlockCardInfo;
};

export type MutationUnBlockCardArgs = {
    info: UnBlockCardInfo;
};

export type MutationEditPersonArgs = {
    info: EditPersonInfo;
};

export type MutationCardPersonSettingsArgs = {
    info: PersonSettingsInfo;
};

export type MutationCreateSeizurePaymentArgs = {
    info: SeizurePaymentInfo;
};

export type MutationUpdateScaChallengeStatusArgs = {
    info: UpdateScaChallengesInfo;
};

export type MutationAuthorizeScaChallengeArgs = {
    info: AuthorizeScaChallengesInfo;
};

export type MutationSecureCardDetailsArgs = {
    info: SecureCardDetailsInfo;
};

export type MutationReferenceAccountArgs = {
    info: ReferenceAccountInfo;
};

export type MutationPayoutReferenceAccountArgs = {
    info: PayoutReferenceAccountInfo;
};

export type MutationMarkCardAsLostStolenArgs = {
    info: MarkCardAsLostStolenInfo;
};

export type MutationReorderCardArgs = {
    info: ReorderCardInfo;
};

export type MutationVerifyMobilePhoneArgs = {
    info: VerifyMobilePhoneInfo;
};

export type MutationUpdateMobilePhoneArgs = {
    info: UpdateMobilePhoneInfo;
};

export type MutationConfirmUpdateMobilePhoneArgs = {
    info: ConfirmUpdateMobilePhoneInfo;
};

export type MutationUpdateTncDocumentArgs = {
    info: TncDocumentInfo;
};

export type MutationUpdateTncDocumentsArgs = {
    info: Array<TncDocumentInfo>;
};

export type MutationDeleteDeviceArgs = {
    info: DeleteDeviceInfo;
};

export type MutationDeleteDevicesArgs = {
    info: Array<DeleteDeviceInfo>;
};

export type MutationAddKeyArgs = {
    info: AddKeyInfo;
};

export type MutationGroverLoginArgs = {
    info: GroverLoginInfo;
};

export type MutationGooglePushProvisioningArgs = {
    info: GooglePushProvisioningInfo;
};

export type MutationVerifyGooglePushProvisioningArgs = {
    info: VerifyChangeRequestInfo;
};

export type MutationPsd2ChallengeArgs = {
    info: Psd2ChallengeInfo;
};

export type MutationVerifyPsd2ChallengeArgs = {
    info: VerifyPsd2ChallengeInfo;
};

export type MutationUpdateAmlFollowUpArgs = {
    info: UpdateAmlFollowUpInfo;
};

export type MutationConfirmUpdateAmlFollowUpArgs = {
    info: ConfirmUpdateAmlFollowUpInfo;
};

export type MutationCreateTopUpArgs = {
    info: CreateTopUpInfo;
};

export type MutationCancelTopUpArgs = {
    info: CancelTopUpInfo;
};

export type MutationApplePushProvisioningArgs = {
    info: ApplePushProvisioningInfo;
};

export type MutationVerifyApplePushProvisioningArgs = {
    info: VerifyChangeRequestInfo;
};

export type MutationDeviceMonitoringArgs = {
    info: DeviceMonitoringInfo;
};

export type MutationUpdateDeviceMonitoringArgs = {
    info: UpdateDeviceMonitoringInfo;
};

export type MutationDeviceActivitiesArgs = {
    info: DeviceActivitiesInfo;
};

export type MutationTrackApplePushProvisioningArgs = {
    info: TrackApplePushProvisioningInfo;
};

export type MutationCreateVariantFlagArgs = {
    variantFlagCreateInput: Array<VariantFlagCreateInput>;
};

export type MutationUpdateVariantFlagArgs = {
    variantFlagUpdateInput: VariantFlagUpdateInput;
    id: Scalars['ID'];
};

export type MutationCreateProductCampaignArgs = {
    productCampaignCreateInput: ProductCampaignCreateInput;
};

export type MutationUpdateProductCampaignArgs = {
    productCampaignUpdateInput: ProductCampaignUpdateInput;
    id: Scalars['ID'];
};

export type MutationAddProductsToCampaignArgs = {
    addProducstToCampaignInput: AddProductsToCampaignInput;
};

export type MutationCreateCountdownTimerArgs = {
    countdownTimerCreateInput: CountdownTimerCreateInput;
};

export type MutationUpdateCountdownTimerArgs = {
    countdownTimerUpdateInput: CountdownTimerUpdateInput;
    id: Scalars['ID'];
};

export type MutationAddProductsToCountdownTimerArgs = {
    addProductsToCountdownTimerInput: AddProductsToCountdownTimerInput;
};

export type MutationDeleteProductsFromCountdownTimerArgs = {
    productSku: Scalars['String'];
    countdownTimerId: Scalars['ID'];
};

export type MutationCreateCheckoutAttemptArgs = {
    orderNumber?: Maybe<Scalars['String']>;
    sessionId?: Maybe<Scalars['String']>;
};

export type MutationGetVotingTicketArgs = {
    slug: Scalars['String'];
    entityId?: Maybe<Scalars['String']>;
    entityType?: Maybe<EntityType>;
    meta?: Maybe<Scalars['JSON']>;
    userId?: Maybe<Scalars['Int']>;
};

export type MutationCastVoteArgs = {
    ticket: Scalars['String'];
    voteSlug: Scalars['String'];
    comment?: Maybe<Scalars['String']>;
    userId?: Maybe<Scalars['Int']>;
};

export type MutationCreateConnectionArgs = {
    referralCode: Scalars['String'];
};

export type MutationCreateReferralConnectionArgs = {
    referralCode: Scalars['String'];
};

export type MutationCancelInboundShipmentsByContractIdArgs = {
    contractId: Scalars['String'];
};

export type MutationExportShipmentsArgs = {
    filter?: Maybe<Array<ShipmentFilterType>>;
};

export type MutationGenerateReturnLabelsArgs = {
    returnReason: Scalars['String'];
    packageArrangements: Array<PackageArrangementType>;
};

export type MutationSyncShipmentArgs = {
    shipmentId: Scalars['String'];
};

export type MutationUpdateTrackingInformationArgs = {
    trackingNumber?: Maybe<Scalars['String']>;
    carrier?: Maybe<ShipmentCarrier>;
    shipmentId: Scalars['String'];
};

export type MutationUpsertShippingProfileRuleArgs = {
    uid?: Maybe<Scalars['String']>;
    expireAt?: Maybe<Scalars['DateTimeISO']>;
    deletedAt?: Maybe<Scalars['DateTimeISO']>;
    orderMode?: Maybe<Array<Scalars['String']>>;
    customerType?: Maybe<Array<Scalars['String']>>;
    warehouseCode?: Maybe<Array<Scalars['String']>>;
    countries: Array<Scalars['String']>;
    skuProducts: Array<Scalars['String']>;
    state: Scalars['String'];
    priority: Scalars['Float'];
    shipmentType: Scalars['String'];
    category: Scalars['String'];
    profile: Scalars['Float'];
    description?: Maybe<Scalars['String']>;
    name: Scalars['String'];
};

export type MutationCreateTermsAndConditionsArgs = {
    input: CreateTermsAndConditionsInput;
};

export type MutationDeleteTermsAndConditionsArgs = {
    version: Scalars['ID'];
};

export type MutationUpdateStoreCodesArgs = {
    input: UpdateStoreCodesInput;
};

export type MutationPublishTermsAndConditionsArgs = {
    version: Scalars['ID'];
};

export type MutationUpdateDaysToDecideArgs = {
    input: UpdateDaysToDecideInput;
};

export type MutationAddLocaleArgs = {
    input: AddLocaleInput;
};

export type MutationDeleteLocaleArgs = {
    input: DeleteLocaleInput;
};

export type MutationUpdateLocaleArgs = {
    input: UpdateLocaleInput;
};

export type MutationUpdateDisabledFeaturesArgs = {
    input: UpdateDisabledFeaturesInput;
};

export type MutationAddPaymentMethodArgs = {
    input: AddPaymentMethodInput;
};

export type MutationUsePaymentMethodArgs = {
    input: UsePaymentMethodInput;
};

export type MutationDeletePaymentMethodArgs = {
    id: Scalars['ID'];
    userId?: Maybe<Scalars['ID']>;
};

export type MutationSetPaymentMethodArgs = {
    input: SetPaymentMethodInput;
    userId?: Maybe<Scalars['ID']>;
};

export type MutationReplacePaymentMethodArgs = {
    input: ReplacePaymentMethodInput;
    userId?: Maybe<Scalars['ID']>;
};

export type MutationProductsWidgetCreateArgs = {
    data: ProductsWidgetCreateInput;
};

export type MutationProductsWidgetUpdateArgs = {
    id: Scalars['Int'];
    data: ProductsWidgetUpdateInput;
};

export type MutationProductsWidgetDeleteArgs = {
    id: Scalars['Int'];
};

export type MutationToggleB2BDashboardAccessArgs = {
    toggleB2BDashboardAccessInput: ToggleDashboardAccessInput;
};

export type MutationBulkB2BDashboardActivateArgs = {
    bulkB2BDashboardAccessInput: BulkDashboardAccessInput;
};

export type MutationUpdateWorkAssetArgs = {
    updateAssetInput: UpdateAssetInput;
};

export type MutationRemoveWorkAssetArgs = {
    uuid: Scalars['String'];
};

export type MutationAssignEmployeeToAssetArgs = {
    assetUuid: Scalars['String'];
    employeeUuid: Scalars['String'];
};

export type MutationClearAssetEmployeeAssignmentArgs = {
    assetUuid: Scalars['String'];
};

export type MutationBusinessReturnAssetArgs = {
    input: ReturnAssetInput;
};

export type MutationCreateWorkEmployeeArgs = {
    createEmployeeInput: CreateEmployeeInput;
};

export type MutationUpdateWorkEmployeeArgs = {
    updateEmployeeInput: UpdateEmployeeInput;
};

export type MutationRemoveWorkEmployeeArgs = {
    uuid: Scalars['String'];
};

export type MutationAssignAssetsToEmployeeArgs = {
    employeeUuid: Scalars['String'];
    assetUuids: Array<Scalars['String']>;
};

export type MutationImportEmployeesFromS3Args = {
    options: ImportEmployeesFromS3;
};

export type NestedBoolFilter = {
    equals?: Maybe<Scalars['Boolean']>;
    not?: Maybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
    equals?: Maybe<Scalars['DateTime']>;
    gt?: Maybe<Scalars['DateTime']>;
    gte?: Maybe<Scalars['DateTime']>;
    in?: Maybe<Array<Scalars['DateTime']>>;
    lt?: Maybe<Scalars['DateTime']>;
    lte?: Maybe<Scalars['DateTime']>;
    not?: Maybe<NestedDateTimeFilter>;
    notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type NestedIntFilter = {
    equals?: Maybe<Scalars['Int']>;
    gt?: Maybe<Scalars['Int']>;
    gte?: Maybe<Scalars['Int']>;
    in?: Maybe<Array<Scalars['Int']>>;
    lt?: Maybe<Scalars['Int']>;
    lte?: Maybe<Scalars['Int']>;
    not?: Maybe<NestedIntFilter>;
    notIn?: Maybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
    contains?: Maybe<Scalars['String']>;
    endsWith?: Maybe<Scalars['String']>;
    equals?: Maybe<Scalars['String']>;
    gt?: Maybe<Scalars['String']>;
    gte?: Maybe<Scalars['String']>;
    in?: Maybe<Array<Scalars['String']>>;
    lt?: Maybe<Scalars['String']>;
    lte?: Maybe<Scalars['String']>;
    not?: Maybe<NestedStringFilter>;
    notIn?: Maybe<Array<Scalars['String']>>;
    startsWith?: Maybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
    contains?: Maybe<Scalars['String']>;
    endsWith?: Maybe<Scalars['String']>;
    equals?: Maybe<Scalars['String']>;
    gt?: Maybe<Scalars['String']>;
    gte?: Maybe<Scalars['String']>;
    in?: Maybe<Array<Scalars['String']>>;
    lt?: Maybe<Scalars['String']>;
    lte?: Maybe<Scalars['String']>;
    not?: Maybe<NestedStringNullableFilter>;
    notIn?: Maybe<Array<Scalars['String']>>;
    startsWith?: Maybe<Scalars['String']>;
};

export type NethoneOrder = {
    __typename?: 'NethoneOrder';
    orderNumber?: Maybe<Scalars['String']>;
    sessionId?: Maybe<Scalars['String']>;
    userId?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['DateTime']>;
};

export type NewRentalPlanInput = {
    extendedCommittedMonths: Scalars['Int'];
    /** New rental plan price in cents, without previous discounts */
    priceInCents: Scalars['Int'];
};

export type NextPaymentNote = {
    __typename?: 'NextPaymentNote';
    date?: Maybe<Scalars['ISO8601DateTime']>;
    paymentMethod?: Maybe<PaymentMethod>;
};

export type Node = {
    id: Scalars['ID'];
};

export enum NotificationChannel {
    EMAIL = 'EMAIL',
    SMS = 'SMS',
    PUSH = 'PUSH',
}

export type NotificationEnrichMapping = {
    __typename?: 'NotificationEnrichMapping';
    uuid: Scalars['ID'];
    enrichType: Scalars['String'];
    mapping: Scalars['JSON'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
};

export type NotificationSendResult = Node & {
    __typename?: 'NotificationSendResult';
    id: Scalars['ID'];
    correlation: Scalars['String'];
    channel: NotificationChannel;
    enrichType: Scalars['String'];
    status: NotificationSendResultStatus;
    template: Scalars['String'];
    address: Scalars['String'];
    userId: Scalars['String'];
    user?: Maybe<User>;
    body?: Maybe<Scalars['String']>;
    reason?: Maybe<Scalars['String']>;
    triggerFilter?: Maybe<NotificationTriggerFilter>;
    enrichContext: Scalars['JSON'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
};

export enum NotificationSendResultStatus {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    SKIPPED = 'SKIPPED',
}

export type NotificationTriggerFilter = {
    __typename?: 'NotificationTriggerFilter';
    id: Scalars['ID'];
    name: Scalars['String'];
    thirtyDayVolume: Scalars['Int'];
    totalSent: Scalars['Int'];
    deferInSeconds: Scalars['Float'];
    enrichType: Scalars['String'];
    status: NotificationTriggerFilterStatus;
    template: Scalars['String'];
    channels: Array<NotificationChannel>;
    conditions: Scalars['JSON'];
    enrichMapping?: Maybe<NotificationEnrichMapping>;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
};

export type NotificationTriggerFilterListInput = {
    name?: Maybe<Scalars['String']>;
    status?: Maybe<NotificationTriggerFilterStatus>;
    channel?: Maybe<NotificationChannel>;
};

export enum NotificationTriggerFilterStatus {
    ACTIVE = 'ACTIVE',
    DISABLED = 'DISABLED',
}

export type NotificationTriggerFilterUpdateInput = {
    name?: Maybe<Scalars['String']>;
    status?: Maybe<NotificationTriggerFilterStatus>;
};

/** More fields for this type will be defined in following PRs */
export type NotImplementedEof = IExternalOrderFulfillment & {
    __typename?: 'NotImplementedEOF';
    uid: Scalars['ID'];
    orderNumber: Scalars['String'];
    orderMode?: Maybe<OrderFulfillmentOrderMode>;
    userId: Scalars['String'];
    userType?: Maybe<UserTypes>;
    storeId?: Maybe<Scalars['Int']>;
    storeCode?: Maybe<Scalars['String']>;
    storeType?: Maybe<StoreTypes>;
    storeCountryId?: Maybe<Scalars['Int']>;
    storeCountryCode?: Maybe<Scalars['String']>;
    state: OrderFulfillmentState;
    stateUpdatedAt: Scalars['DateTime'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    deletedAt?: Maybe<Scalars['DateTime']>;
    order?: Maybe<MixCartOrder>;
    /** Collection of assets belonging to an order */
    assets?: Maybe<Array<OrderAsset>>;
    shippingPreferences?: Maybe<OrderShippingPreferences>;
    employee?: Maybe<GroverEmployee>;
    shippingAddress?: Maybe<EofShippingAddress>;
};

export type NumberComparatorInput = {
    _eq?: Maybe<Scalars['Float']>;
    _neq?: Maybe<Scalars['Float']>;
};

export type OfferItemMetaInput = {
    price: Scalars['Float'];
    committedMonths?: Maybe<Scalars['Int']>;
    product?: Maybe<LineItemProductInput>;
    category?: Maybe<LineItemCategoryInput>;
    variant?: Maybe<LineItemVariantInput>;
    allowPurchase?: Maybe<Scalars['Boolean']>;
    allowRentalUpgrade?: Maybe<Scalars['Boolean']>;
    discountAmount?: Maybe<Scalars['Int']>;
    rentalPlanId?: Maybe<Scalars['Int']>;
};

export type OfferLineItemUpdateInput = {
    id: Scalars['Int'];
    quantity?: Maybe<Scalars['Int']>;
    meta?: Maybe<OfferItemMetaInput>;
};

export type OffsetPaginationInput = {
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type OnboardingStatusResult = {
    __typename?: 'OnboardingStatusResult';
    cardStatus: Scalars['String'];
};

export type OnfidoCheck = CheckProviderReport & {
    __typename?: 'OnfidoCheck';
    reportUrl: Scalars['String'];
    result?: Maybe<ProviderCheckResult>;
};

export type Operation = {
    completedAt?: Maybe<Scalars['ISO8601DateTime']>;
    owner: User;
    startedAt: Scalars['ISO8601DateTime'];
    startedBy: User;
    updates: Array<FlowUpdate>;
};

export type Order = {
    __typename?: 'Order';
    /** @deprecated Legacy field, please use placedAt field instead. */
    completedAt?: Maybe<Scalars['ISO8601DateTime']>;
    /** @deprecated Legacy field, please use discountTotal field instead. */
    discountAmount: Price;
    /** @deprecated Will be removed. */
    guestToken?: Maybe<Scalars['String']>;
    /** @deprecated Legacy field, please use billingAddress field instead. */
    homeAddress?: Maybe<Address>;
    id: Scalars['ID'];
    /** @deprecated Legacy field, please use itemTotal field instead. */
    itemsTotal: Price;
    /** @deprecated Legacy field, please use items field. */
    lineItems: Array<LineItem>;
    /** @deprecated Will be removed. Switch to using id. */
    number: Scalars['String'];
    /** @deprecated Legacy field, please use total and currency fields instead. */
    price: Price;
    /** @deprecated Legacy field, please use shippingCost field instead. */
    shippingPrice: Price;
    /** @deprecated Will be removed. */
    step?: Maybe<Scalars['String']>;
    /** @deprecated Legacy field, please use storeCode field instead. */
    store: Store;
    itemTotal?: Maybe<Scalars['Int']>;
    total?: Maybe<Scalars['Int']>;
    shippingCost?: Maybe<Scalars['Int']>;
    userId?: Maybe<Scalars['Int']>;
    currency?: Maybe<Scalars['String']>;
    type?: Maybe<OrderType>;
    status?: Maybe<Scalars['String']>;
    estimatedTax?: Maybe<Scalars['Int']>;
    paymentMethodId?: Maybe<Scalars['Int']>;
    billingAddress?: Maybe<Address>;
    shippingAddress?: Maybe<Address>;
    discountTotal?: Maybe<Scalars['Int']>;
    storeCode?: Maybe<Scalars['String']>;
    placedAt?: Maybe<Scalars['DateTime']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    items: Array<Maybe<OrderItem>>;
};

export type OrderAsset = {
    __typename?: 'OrderAsset';
    assetUid?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    productSku?: Maybe<Scalars['String']>;
    variantSku?: Maybe<Scalars['String']>;
    warehouseGoodsOrderId?: Maybe<Scalars['String']>;
    warehouseGoodsOrderState?: Maybe<WarehouseGoodsOrderStatus>;
    warehouseCode?: Maybe<Scalars['String']>;
    contractId?: Maybe<Scalars['String']>;
    contractState?: Maybe<ContractState>;
    replacementCreatedAt?: Maybe<Scalars['String']>;
    replacementUid?: Maybe<Scalars['String']>;
    subscriptionId?: Maybe<Scalars['String']>;
    reservationUid?: Maybe<Scalars['String']>;
    reservationStatus?: Maybe<ReservationStatus>;
    reservationCreatedAt?: Maybe<Scalars['String']>;
    stockId?: Maybe<Scalars['String']>;
    serialNumber?: Maybe<Scalars['String']>;
    allocationUid?: Maybe<Scalars['String']>;
    /** ID of the latest shipment related to an asset */
    shipmentUid?: Maybe<Scalars['String']>;
    lifecycleEventUids?: Maybe<Array<Scalars['String']>>;
    createdAt?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    /** Variant from GroverAPI through 'variantSku' */
    variant?: Maybe<Variant>;
    /** Asset from work-service through 'assetUid' */
    workAsset?: Maybe<WorkAsset>;
};

export enum OrderByDirection {
    ASC = 'ASC',
    DESC = 'DESC',
}

export type OrderFulfillment = {
    __typename?: 'OrderFulfillment';
    uid: Scalars['ID'];
    orderNumber: Scalars['String'];
    orderMode?: Maybe<Scalars['String']>;
    userId: Scalars['String'];
    userType?: Maybe<Scalars['String']>;
    /** User from GroverAPI through 'userId' */
    user?: Maybe<User>;
    storeId?: Maybe<Scalars['Int']>;
    storeCode?: Maybe<Scalars['String']>;
    storeType?: Maybe<Scalars['String']>;
    storeCountryId?: Maybe<Scalars['Int']>;
    storeCountryCode?: Maybe<Scalars['String']>;
    state: OrderFulfillmentState;
    stateUpdatedAt: Scalars['DateTime'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    deletedAt?: Maybe<Scalars['DateTime']>;
    /** Collection of assets belonging to an order */
    assets?: Maybe<Array<OrderAsset>>;
    /**
     * JSON stringified version of a collection of assets belonging to an order
     * @deprecated Replaced by the 'assets' field
     */
    orderAssets?: Maybe<Scalars['String']>;
    /** Collection of shipments belonging to an order */
    shipments?: Maybe<Array<OrderShipment>>;
    /**
     * JSON stringified version of a collection of shipments belonging to an order
     * @deprecated Replaced by the 'shipments' field
     */
    orderShipments?: Maybe<Scalars['String']>;
    shippingPreferences?: Maybe<OrderShippingPreferences>;
    lifecycleEvents?: Maybe<Array<OrderFulfillmentLifecycleEvent>>;
};

export type OrderFulfillmentCollection = {
    __typename?: 'OrderFulfillmentCollection';
    rows: Array<OrderFulfillment>;
    totalCount: Scalars['Int'];
};

export type OrderFulfillmentConnection = {
    __typename?: 'OrderFulfillmentConnection';
    edges: Array<OrderFulfillmentEdge>;
    pageInfo?: Maybe<OrderFulfillmentPageInfo>;
};

export type OrderFulfillmentEdge = {
    __typename?: 'OrderFulfillmentEdge';
    node: OrderFulfillment;
    cursor: OrderFulfillmentPaginationCursor;
};

export type OrderFulfillmentFilterInput = {
    orderNumber?: Maybe<StringComparatorInput>;
    orderMode?: Maybe<OrderModeComparatorInput>;
    state?: Maybe<OrderFulfillmentStateComparatorInput>;
    userId?: Maybe<StringComparatorInput>;
    userType?: Maybe<UserTypeComparatorInput>;
    storeId?: Maybe<NumberComparatorInput>;
    storeCountryCode?: Maybe<StringComparatorInput>;
    createdAt?: Maybe<TimestampComparatorInput>;
    updatedAt?: Maybe<TimestampComparatorInput>;
};

export type OrderFulfillmentLifecycleEvent = {
    __typename?: 'OrderFulfillmentLifecycleEvent';
    uid: Scalars['ID'];
    orderNumber: Scalars['String'];
    name: OrderFulfillmentLifecycleEventState;
    description?: Maybe<Scalars['String']>;
    location?: Maybe<Scalars['String']>;
    timestamp: Scalars['DateTime'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    deletedAt?: Maybe<Scalars['DateTime']>;
    aggregationUpdate?: Maybe<Scalars['String']>;
    metadata?: Maybe<Scalars['String']>;
    sourceEntityUid?: Maybe<Scalars['ID']>;
};

export enum OrderFulfillmentLifecycleEventState {
    OrderPlaced = 'OrderPlaced',
    ContractAssetAssigned = 'ContractAssetAssigned',
    ContractDraft = 'ContractDraft',
    ContractActivated = 'ContractActivated',
    ContractReactivated = 'ContractReactivated',
    ContractRefused = 'ContractRefused',
    ContractCancelled = 'ContractCancelled',
    ContractEnded = 'ContractEnded',
    ContractPaused = 'ContractPaused',
    ContractFulfilling = 'ContractFulfilling',
    ContractUpdate = 'ContractUpdate',
    PaymentFailed = 'PaymentFailed',
    PaymentPartiallyPaid = 'PaymentPartiallyPaid',
    PaymentPaid = 'PaymentPaid',
    PaymentPartiallyRefunded = 'PaymentPartiallyRefunded',
    PaymentRefunded = 'PaymentRefunded',
    PaymentCancelled = 'PaymentCancelled',
    PaymentUpdateReceived = 'PaymentUpdateReceived',
    RiskDecisionApproved = 'RiskDecisionApproved',
    RiskDecisionDeclined = 'RiskDecisionDeclined',
    RiskDecisionManualReview = 'RiskDecisionManualReview',
    RiskDecisionError = 'RiskDecisionError',
    RiskDecisionUpdate = 'RiskDecisionUpdate',
    AllocationPending = 'AllocationPending',
    ReservationUpdate = 'ReservationUpdate',
    ReservationPaid = 'ReservationPaid',
    ReservationApproved = 'ReservationApproved',
    ReservationCancelled = 'ReservationCancelled',
    ReservationDeclined = 'ReservationDeclined',
    ReservationDeleted = 'ReservationDeleted',
    AllocationPartiallyFulfilled = 'AllocationPartiallyFulfilled',
    AllocationFulfilled = 'AllocationFulfilled',
    ReplacementAllocated = 'ReplacementAllocated',
    WarehouseOrderSent = 'WarehouseOrderSent',
    WarehouseOrderProcessing = 'WarehouseOrderProcessing',
    WarehouseOrderCancelled = 'WarehouseOrderCancelled',
    WarehouseOrderAccepted = 'WarehouseOrderAccepted',
    ShipmentCreated = 'ShipmentCreated',
    ShipmentInTransit = 'ShipmentInTransit',
    ShipmentReadyForPickupByReceiver = 'ShipmentReadyForPickupByReceiver',
    ShipmentFailedToDeliver = 'ShipmentFailedToDeliver',
    ShipmentCancelled = 'ShipmentCancelled',
    ShipmentDelivered = 'ShipmentDelivered',
}

export enum OrderFulfillmentOrderMode {
    FLEX_LEGACY = 'FLEX_LEGACY',
    FLEX = 'FLEX',
    MIX = 'MIX',
    SWAP = 'SWAP',
}

export type OrderFulfillmentPageInfo = {
    __typename?: 'OrderFulfillmentPageInfo';
    beginningCursor?: Maybe<OrderFulfillmentPaginationCursor>;
    endCursor?: Maybe<OrderFulfillmentPaginationCursor>;
};

export type OrderFulfillmentPaginationCursor = {
    __typename?: 'OrderFulfillmentPaginationCursor';
    uid: Scalars['ID'];
    createdAt: Scalars['String'];
};

export enum OrderFulfillmentSortDirection {
    DESC = 'DESC',
    ASC = 'ASC',
}

export enum OrderFulfillmentState {
    Initial = 'Initial',
    OrderPlaced = 'OrderPlaced',
    AllocationPending = 'AllocationPending',
    AllocationPartiallyFulfilled = 'AllocationPartiallyFulfilled',
    AllocationFulfilled = 'AllocationFulfilled',
    WarehouseOrderSent = 'WarehouseOrderSent',
    WarehouseOrderProcessing = 'WarehouseOrderProcessing',
    WarehouseOrderAccepted = 'WarehouseOrderAccepted',
    ShipmentsCreated = 'ShipmentsCreated',
    ShipmentsInTransit = 'ShipmentsInTransit',
    ShipmentsReadyForPickupByReceiver = 'ShipmentsReadyForPickupByReceiver',
    ShipmentsCancelled = 'ShipmentsCancelled',
    ShipmentsFailedToDeliver = 'ShipmentsFailedToDeliver',
    ShipmentsDelivered = 'ShipmentsDelivered',
    ShipmentsPartiallyInTransit = 'ShipmentsPartiallyInTransit',
    ShipmentsPartiallyCancelled = 'ShipmentsPartiallyCancelled',
    ShipmentsPartiallyFailedToDeliver = 'ShipmentsPartiallyFailedToDeliver',
    ShipmentsPartiallyDelivered = 'ShipmentsPartiallyDelivered',
    OrderRefused = 'OrderRefused',
    OrderCancelled = 'OrderCancelled',
    OrderCompleted = 'OrderCompleted',
    OrderPaused = 'OrderPaused',
}

export type OrderFulfillmentStateComparatorInput = {
    _eq?: Maybe<OrderFulfillmentState>;
    _neq?: Maybe<OrderFulfillmentState>;
    _in?: Maybe<Array<OrderFulfillmentState>>;
    _nin?: Maybe<Array<OrderFulfillmentState>>;
};

export type OrderFulfilmentPageInfo = {
    __typename?: 'OrderFulfilmentPageInfo';
    startCursor?: Maybe<Scalars['String']>;
    endCursor?: Maybe<Scalars['String']>;
    hasPreviousPage: Scalars['Boolean'];
    hasNextPage: Scalars['Boolean'];
};

export type OrderItem = {
    __typename?: 'OrderItem';
    quantity?: Maybe<Scalars['Int']>;
    price?: Maybe<Scalars['Int']>;
    total?: Maybe<Scalars['Int']>;
    discountAmount?: Maybe<Scalars['Int']>;
    committedMonths?: Maybe<Scalars['Int']>;
    rentalPlanId?: Maybe<Scalars['Int']>;
    categoryId?: Maybe<Scalars['Int']>;
    subCategoryId?: Maybe<Scalars['Int']>;
    product?: Maybe<Product>;
    variant?: Maybe<Variant>;
    groverCare?: Maybe<GroverCare>;
};

export type OrderLineItem = {
    id: Scalars['ID'];
    quantity?: Maybe<Scalars['Int']>;
};

export enum OrderMode {
    MIX = 'MIX',
    FLEX = 'FLEX',
    SWAP = 'SWAP',
}

export type OrderModeComparatorInput = {
    _eq?: Maybe<OrderFulfillmentOrderMode>;
    _neq?: Maybe<OrderFulfillmentOrderMode>;
};

export type OrderOffer = {
    __typename?: 'OrderOffer';
    id: Scalars['ID'];
    name: Scalars['String'];
    status: OrderOfferStatus;
    companyAccount: User;
    createdAt: Scalars['DateISOString'];
    expiresIn?: Maybe<Scalars['DateISOString']>;
    updatedAt?: Maybe<Scalars['DateISOString']>;
    owner: User;
    order?: Maybe<MixCartOrder>;
    assetsTotal?: Maybe<Scalars['Float']>;
    assetsCount?: Maybe<Scalars['Int']>;
    waitingFor?: Maybe<Scalars['Int']>;
    rejectedBy?: Maybe<User>;
};

export type OrderOfferAcceptInput = {
    id: Scalars['Int'];
    companyId: Scalars['Int'];
    orderPlacementInput: CartOrderSubmitInput;
};

export type OrderOfferCreateInput = {
    name: Scalars['String'];
    companyAccountId: Scalars['Int'];
    storeCode: Scalars['String'];
    shippingCost: Scalars['Float'];
};

export type OrderOfferEdge = {
    __typename?: 'OrderOfferEdge';
    cursor: Scalars['String'];
    node?: Maybe<Array<Maybe<OrderOffer>>>;
};

export type OrderOfferFiltersInput = {
    status?: Maybe<OrderOfferStatus>;
    companyAccountId?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['DateISOString']>;
    updatedAt?: Maybe<Scalars['DateISOString']>;
    expiresIn?: Maybe<Scalars['DateISOString']>;
    ownerId?: Maybe<Scalars['Int']>;
    orderNumber?: Maybe<Scalars['String']>;
};

export type OrderOffersConnection = {
    __typename?: 'OrderOffersConnection';
    edges?: Maybe<OrderOfferEdge>;
    nodes?: Maybe<Array<Maybe<OrderOffer>>>;
    pageInfo?: Maybe<PageInfo>;
    totalCount?: Maybe<Scalars['Int']>;
    matchingCount?: Maybe<Scalars['Int']>;
};

export enum OrderOfferStatus {
    DRAFT = 'DRAFT',
    ACTIVE = 'ACTIVE',
    ACCEPTED = 'ACCEPTED',
    EXPIRED = 'EXPIRED',
    REJECTED = 'REJECTED',
}

export type OrderOfferUpdateInput = {
    id: Scalars['Int'];
    status?: Maybe<OrderOfferStatus>;
    companyAccountId?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
    shippingCost?: Maybe<Scalars['Float']>;
};

export type OrderPayment = PaymentInterface & {
    __typename?: 'OrderPayment';
    remoteId?: Maybe<Scalars['String']>;
    invoiceNumber?: Maybe<Scalars['String']>;
    basePrice: Price;
    createdAt: Scalars['ISO8601DateTime'];
    discountableLoyaltyPrice: Price;
    discountedLoyaltyPrice: Price;
    paidDate?: Maybe<Scalars['ISO8601DateTime']>;
    price: Price;
    state: PaymentState;
    invoiceUrl?: Maybe<Scalars['String']>;
    byInvoice: Scalars['Boolean'];
    dueDate: DateUnion;
    id: Scalars['ID'];
    order: OrderUnion;
    marketingOrderPaymentData: Array<HintModel>;
};

export type OrdersFilterInput = {
    cancellable: Scalars['Boolean'];
};

export type OrderShipment = {
    __typename?: 'OrderShipment';
    shipmentUid: Scalars['String'];
    trackingNumber?: Maybe<Scalars['String']>;
    trackingURL?: Maybe<Scalars['String']>;
    carrier?: Maybe<Scalars['String']>;
    service?: Maybe<ShipmentServiceType>;
    type?: Maybe<Scalars['String']>;
    flow?: Maybe<Scalars['String']>;
    state: ShipmentEventState;
    assetUids?: Maybe<Array<Scalars['String']>>;
    lifecycleEventUids?: Maybe<Array<Scalars['String']>>;
    createdAt?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    shipment?: Maybe<Shipment>;
};

export type OrderShippingPreferences = {
    __typename?: 'OrderShippingPreferences';
    shipToName?: Maybe<Scalars['String']>;
    includeCompanyName?: Maybe<Scalars['Boolean']>;
};

export enum OrderType {
    normal = 'normal',
    business = 'business',
}

export type OrderUnion = MixCartOrder | Order;

export type OriginalAmount = {
    __typename?: 'OriginalAmount';
    value: Scalars['Float'];
    currency: Scalars['String'];
    fxRate: Scalars['Float'];
};

export enum Os {
    ANDROID = 'ANDROID',
    IOS = 'IOS',
    LINUX = 'LINUX',
    MAC_OS = 'MAC_OS',
    OTHER = 'OTHER',
    WINDOWS = 'WINDOWS',
}

export type OwnEconomicInterestSignedAtResult = {
    __typename?: 'OwnEconomicInterestSignedAtResult';
    ownEconomicInterestSignedAt: Scalars['DateTime'];
};

export type PackageArrangementType = {
    id: Scalars['String'];
    carrier?: Maybe<ShipmentCarrier>;
    ignoreCarrierRules?: Maybe<ShippingIgnoreCarrierRulesOptions>;
    dimensions?: Maybe<PackageDimensions>;
    assetUids: Array<Scalars['String']>;
    weight?: Maybe<Scalars['Float']>;
};

export type PackageDimensions = {
    height: Scalars['Float'];
    length: Scalars['Float'];
    width: Scalars['Float'];
};

export type PackageItem = {
    __typename?: 'PackageItem';
    allocationUid?: Maybe<Scalars['String']>;
    assetAllocation?: Maybe<AssetAllocation>;
    uid: Scalars['ID'];
    skuVariant?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    assetUid?: Maybe<Scalars['String']>;
    salesforceAssetId?: Maybe<Scalars['String']>;
    serialNumber?: Maybe<Scalars['String']>;
    contractId?: Maybe<Scalars['String']>;
    orderNumber?: Maybe<Scalars['String']>;
    quantity: Scalars['Float'];
    description?: Maybe<Scalars['String']>;
    shipmentUid?: Maybe<Scalars['String']>;
    shipment?: Maybe<Shipment>;
    variant?: Maybe<Variant>;
};

export type PackageItemCollection = {
    __typename?: 'PackageItemCollection';
    count: Scalars['Float'];
    rows: Array<PackageItem>;
};

export type PackageItemFilter = {
    uid?: Maybe<Scalars['String']>;
    shipmentUid?: Maybe<Scalars['String']>;
    skuVariant?: Maybe<Scalars['String']>;
    orderNumber?: Maybe<Scalars['String']>;
    contractId?: Maybe<Scalars['String']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
    __typename?: 'PageInfo';
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean'];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']>;
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']>;
};

export type PaginatedKitConnection = {
    __typename?: 'PaginatedKitConnection';
    edges?: Maybe<Array<Maybe<KitEdge>>>;
    nodes?: Maybe<Array<Kit>>;
    pageInfo?: Maybe<PageInfo>;
    totalCount?: Maybe<Scalars['Int']>;
    matchingCount?: Maybe<Scalars['Int']>;
};

export type PaginatedRentalPlans = {
    __typename?: 'PaginatedRentalPlans';
    edges?: Maybe<Array<InternalRentalPlanEdge>>;
    totalCount: Scalars['Int'];
    pageInfo: PageInfo;
};

export type PaginatedTasks = {
    __typename?: 'PaginatedTasks';
    rows: Array<Task>;
    totalCount: Scalars['Int'];
};

export type Pagination = {
    __typename?: 'Pagination';
    currentPage: Scalars['Int'];
    nextPage?: Maybe<Scalars['Int']>;
    prevPage?: Maybe<Scalars['Int']>;
    totalPages: Scalars['Int'];
    totalItems: Scalars['Int'];
};

export type PaginationInput = {
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type PaginationInputType = {
    limit?: Maybe<Scalars['Float']>;
    offset?: Maybe<Scalars['Float']>;
};

export type Panda = {
    __typename?: 'Panda';
    name: Scalars['ID'];
    favoriteFood?: Maybe<Scalars['String']>;
};

export type ParsedXlsx = {
    __typename?: 'ParsedXlsx';
    json: Scalars['JSON'];
};

export type ParseXlsxError = {
    __typename?: 'ParseXlsxError';
    code: Scalars['String'];
    message: Scalars['String'];
};

export type ParseXlsxInput = {
    fileName: Scalars['String'];
    bucket: Scalars['String'];
    mapper: Scalars['JSON'];
};

export type ParseXlsxResult = ParsedXlsx | ParseXlsxError;

/** More fields for this type will be defined in following PRs */
export type PartiallyDeliveredEof = IExternalOrderFulfillment & {
    __typename?: 'PartiallyDeliveredEOF';
    uid: Scalars['ID'];
    orderNumber: Scalars['String'];
    orderMode?: Maybe<OrderFulfillmentOrderMode>;
    userId: Scalars['String'];
    userType?: Maybe<UserTypes>;
    storeId?: Maybe<Scalars['Int']>;
    storeCode?: Maybe<Scalars['String']>;
    storeType?: Maybe<StoreTypes>;
    storeCountryId?: Maybe<Scalars['Int']>;
    storeCountryCode?: Maybe<Scalars['String']>;
    state: OrderFulfillmentState;
    stateUpdatedAt: Scalars['DateTime'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    deletedAt?: Maybe<Scalars['DateTime']>;
    order?: Maybe<MixCartOrder>;
    /** Collection of assets belonging to an order */
    assets?: Maybe<Array<OrderAsset>>;
    shippingPreferences?: Maybe<OrderShippingPreferences>;
    employee?: Maybe<GroverEmployee>;
    shippingAddress?: Maybe<EofShippingAddress>;
    /** Collection of shipments belonging to an order */
    shipments?: Maybe<Array<OrderShipment>>;
};

/** More fields for this type will be defined in following PRs */
export type PartiallyFailedDeliveryEof = IExternalOrderFulfillment & {
    __typename?: 'PartiallyFailedDeliveryEOF';
    uid: Scalars['ID'];
    orderNumber: Scalars['String'];
    orderMode?: Maybe<OrderFulfillmentOrderMode>;
    userId: Scalars['String'];
    userType?: Maybe<UserTypes>;
    storeId?: Maybe<Scalars['Int']>;
    storeCode?: Maybe<Scalars['String']>;
    storeType?: Maybe<StoreTypes>;
    storeCountryId?: Maybe<Scalars['Int']>;
    storeCountryCode?: Maybe<Scalars['String']>;
    state: OrderFulfillmentState;
    stateUpdatedAt: Scalars['DateTime'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    deletedAt?: Maybe<Scalars['DateTime']>;
    order?: Maybe<MixCartOrder>;
    /** Collection of assets belonging to an order */
    assets?: Maybe<Array<OrderAsset>>;
    shippingPreferences?: Maybe<OrderShippingPreferences>;
    employee?: Maybe<GroverEmployee>;
    shippingAddress?: Maybe<EofShippingAddress>;
    /** Collection of shipments belonging to an order */
    shipments?: Maybe<Array<OrderShipment>>;
};

/** More fields for this type will be defined in following PRs */
export type PartiallyShippedEof = IExternalOrderFulfillment & {
    __typename?: 'PartiallyShippedEOF';
    uid: Scalars['ID'];
    orderNumber: Scalars['String'];
    orderMode?: Maybe<OrderFulfillmentOrderMode>;
    userId: Scalars['String'];
    userType?: Maybe<UserTypes>;
    storeId?: Maybe<Scalars['Int']>;
    storeCode?: Maybe<Scalars['String']>;
    storeType?: Maybe<StoreTypes>;
    storeCountryId?: Maybe<Scalars['Int']>;
    storeCountryCode?: Maybe<Scalars['String']>;
    state: OrderFulfillmentState;
    stateUpdatedAt: Scalars['DateTime'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    deletedAt?: Maybe<Scalars['DateTime']>;
    order?: Maybe<MixCartOrder>;
    /** Collection of assets belonging to an order */
    assets?: Maybe<Array<OrderAsset>>;
    shippingPreferences?: Maybe<OrderShippingPreferences>;
    employee?: Maybe<GroverEmployee>;
    shippingAddress?: Maybe<EofShippingAddress>;
    /** Collection of shipments belonging to an order */
    shipments?: Maybe<Array<OrderShipment>>;
};

export type Partner = {
    __typename?: 'Partner';
    id: Scalars['ID'];
    name: Scalars['String'];
    stores: Array<Store>;
};

export type PartnerWhereInput = {
    name?: Maybe<StringComparisonInput>;
};

/** Autogenerated input type of PauseContract */
export type PauseContractInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    comment?: Maybe<Scalars['String']>;
    contractId: Scalars['ID'];
    days: Scalars['Int'];
    reason: PauseReason;
};

/** Autogenerated return type of PauseContract. */
export type PauseContractPayload = {
    __typename?: 'PauseContractPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract: Contract;
};

export type PausedNote = TextNoteInterface & {
    __typename?: 'PausedNote';
    text?: Maybe<Scalars['String']>;
};

export enum PauseExtensionUnavailabilityReason {
    MAX_DAYS_PAUSED_REACHED = 'MAX_DAYS_PAUSED_REACHED',
    PAUSE_NOT_IN_PROGRESS = 'PAUSE_NOT_IN_PROGRESS',
}

export enum PauseReason {
    BULK_RETURN = 'BULK_RETURN',
    LOST_ORDER_INVESTIGATION = 'LOST_ORDER_INVESTIGATION',
    NOT_ABLE_TO_PAY = 'NOT_ABLE_TO_PAY',
    PAUSING_PAYMENTS_UNTIL_RETURN = 'PAUSING_PAYMENTS_UNTIL_RETURN',
}

export enum PauseUnavailabilityReason {
    ALREADY_IN_PROGRESS = 'ALREADY_IN_PROGRESS',
    ANOTHER_FLOW_IN_PROGRESS = 'ANOTHER_FLOW_IN_PROGRESS',
    CONTRACT_IS_NOT_ACTIVE = 'CONTRACT_IS_NOT_ACTIVE',
}

export type PaymentAccount = {
    __typename?: 'PaymentAccount';
    id?: Maybe<Scalars['String']>;
    walletUuid?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    deleted?: Maybe<Scalars['Boolean']>;
};

export type PaymentAmount = {
    value: Scalars['Float'];
    unit: Scalars['String'];
    currency: Scalars['String'];
};

export type PaymentDetails = {
    __typename?: 'PaymentDetails';
    account: PaymentAccount;
    reference: Scalars['String'];
    gateway: Scalars['String'];
};

export enum PaymentEnum {
    GROUP_PAYMENT = 'GROUP_PAYMENT',
    ORDER_PAYMENT = 'ORDER_PAYMENT',
    PURCHASE_PAYMENT = 'PURCHASE_PAYMENT',
    SUBSCRIPTION_PAYMENT = 'SUBSCRIPTION_PAYMENT',
}

export type PaymentEnumTypeComparisonInput = {
    _eq?: Maybe<PaymentEnum>;
    _neq?: Maybe<PaymentEnum>;
};

export type PaymentGateway = {
    __typename?: 'PaymentGateway';
    flowType: PaymentGatewayFlowType;
    id: Scalars['ID'];
    meta?: Maybe<PaymentGatewayMeta>;
    name: Scalars['String'];
    type: PaymentGatewayType;
};

export enum PaymentGatewayFlowType {
    CREDIT_CARD = 'CREDIT_CARD',
    REDIRECT = 'REDIRECT',
}

export type PaymentGatewayMeta = {
    __typename?: 'PaymentGatewayMeta';
    provider?: Maybe<Scalars['String']>;
    publicKey?: Maybe<Scalars['String']>;
};

export enum PaymentGatewayType {
    BANKCARD = 'BANKCARD',
    PAYPAL = 'PAYPAL',
    SEPA = 'SEPA',
}

export type PaymentGroupModel = {
    __typename?: 'PaymentGroupModel';
    uuid: Scalars['ID'];
    walletUuid: Scalars['String'];
    scopeUuid?: Maybe<Scalars['String']>;
    dueDate: Scalars['String'];
    billingDate: Scalars['String'];
    total: Scalars['Money'];
    currency: Scalars['String'];
    status: PaymentGroupStatusTypes;
    amountPaid: Scalars['Money'];
    transactions: Array<TransactionModel>;
    paymentModifiers: Array<PaymentModifierModel>;
    paymentOrders: Array<PaymentOrderModel>;
    wallet: WalletModel;
    invoice?: Maybe<PaymentsInvoice>;
};

export type PaymentGroupsModel = {
    __typename?: 'PaymentGroupsModel';
    group: Scalars['ID'];
    walletUuid: Scalars['String'];
    scopeUuid?: Maybe<Scalars['String']>;
    status: PaymentGroupStatusTypes;
    transactions: Array<TransactionModel>;
    modifiers: Array<PaymentModifierModel>;
    paymentOrders: Array<PaymentOrderModel>;
    wallet: WalletModel;
};

export enum PaymentGroupStatusTypes {
    new = 'new',
    failed = 'failed',
    partialPaid = 'partialPaid',
    paid = 'paid',
    partialRefund = 'partialRefund',
    refund = 'refund',
    pending = 'pending',
    cancelled = 'cancelled',
    unknown = 'unknown',
}

export type PaymentInterface = {
    basePrice: Price;
    createdAt: Scalars['ISO8601DateTime'];
    discountableLoyaltyPrice: Price;
    discountedLoyaltyPrice: Price;
    paidDate?: Maybe<Scalars['ISO8601DateTime']>;
    price: Price;
    state: PaymentState;
    invoiceNumber?: Maybe<Scalars['String']>;
    remoteId?: Maybe<Scalars['String']>;
};

export type PaymentMethod = ByInvoice | Card | Paypal | Sepa;

/** Payment method information coming from Wallet service. Eventually will deprecate the PaymentMethod type. */
export type PaymentMethod2 = {
    id: Scalars['ID'];
    status: PaymentMethodStatus;
    /** Date/time of the method creation. Empty in case of a legacy method. */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** URL to redirect the user to if authorization is required by the payment provider. */
    redirectUrl?: Maybe<Scalars['String']>;
    /** ID of the corresponding method in Grover API. */
    groverApiId?: Maybe<Scalars['Float']>;
    /** ID of the corresponding account in Billing Service. */
    billingAccountId?: Maybe<Scalars['String']>;
    /** Contracts and subscriptions that are using this method. */
    linkedContracts: Array<FlexDetails>;
    /** Method's external ID in IXOPAY. */
    ixopayReferenceId?: Maybe<Scalars['String']>;
    /**
     * Present if the method deletion is currently forbidden, contains details about
     * the reason. Warning: currently does not return "has linked contracts", which
     * is one of the possible reasons for deletion lock. Check the linkedContracts field instead.
     */
    deletionLock?: Maybe<PaymentMethodDeletionLock>;
    /** Error code classifying the method registration failure, if any. */
    registrationErrorCode?: Maybe<TransactionErrorCode>;
    /** Returns status of this method in relation to the given order. */
    statusForOrder: PaymentMethodStatusForOrderResult;
};

/** Payment method information coming from Wallet service. Eventually will deprecate the PaymentMethod type. */
export type PaymentMethod2StatusForOrderArgs = {
    orderNumber: Scalars['String'];
};

/** 3-D Secure-related data, sent directly to the payment provider. */
export type PaymentMethod3dsData = {
    javaEnabled?: Maybe<Scalars['String']>;
    language?: Maybe<Scalars['String']>;
    colorDepth?: Maybe<Scalars['String']>;
    screenHeight?: Maybe<Scalars['String']>;
    screenWidth?: Maybe<Scalars['String']>;
    timezone?: Maybe<Scalars['String']>;
};

/** Information about a deletion lock set on a payment method. */
export type PaymentMethodDeletionLock = {
    __typename?: 'PaymentMethodDeletionLock';
    reason: PaymentMethodDeletionLockReason;
};

export enum PaymentMethodDeletionLockReason {
    /** A new order has been made with the payment method and is currently in processing. */
    NEW_ORDER = 'NEW_ORDER',
    /** Deletion of the payment method is not supported at the moment. */
    NOT_SUPPORTED = 'NOT_SUPPORTED',
}

export type PaymentMethodInterface = {
    billingAccountId?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    contracts: Array<BasicContract>;
};

/** Payment method authorization info in relation to an order. */
export type PaymentMethodOrderAuthorization = {
    __typename?: 'PaymentMethodOrderAuthorization';
    status: PaymentMethodOrderAuthorizationStatus;
    /** URL to redirect the user to if the payment provider requires it for authorization. */
    redirectUrl?: Maybe<Scalars['String']>;
    /** Indicates whether authorization attempts limit for the order was reached. Only present if authorization was failed. */
    attemptLimitReached?: Maybe<Scalars['Boolean']>;
};

export enum PaymentMethodOrderAuthorizationStatus {
    DRAFT = 'DRAFT',
    REQUIRED = 'REQUIRED',
    PENDING = 'PENDING',
    PENDING_REDIRECT = 'PENDING_REDIRECT',
    FAILED = 'FAILED',
    AUTHORIZED = 'AUTHORIZED',
    CAPTURED = 'CAPTURED',
    PENDING_CAPTURE = 'PENDING_CAPTURE',
    RELEASED = 'RELEASED',
}

export type PaymentMethodPresenter = {
    __typename?: 'PaymentMethodPresenter';
    /** @deprecated Use ChangePaymentMethod instead */
    action: Scalars['String'];
    /** @deprecated Use ChangePaymentMethod instead */
    email?: Maybe<Scalars['String']>;
    /** @deprecated Use subscription payment method instead */
    ibanFirstDigits?: Maybe<Scalars['String']>;
    /** @deprecated Use subscription payment method instead */
    ibanLastDigits?: Maybe<Scalars['String']>;
    /** @deprecated Use ChangePaymentMethod instead */
    last4Digits?: Maybe<Scalars['String']>;
    /** @deprecated Use ChangePaymentMethod instead */
    type?: Maybe<Scalars['String']>;
};

/** Filter for the paymentMethods query */
export type PaymentMethodsFilterType = {
    userId?: Maybe<Scalars['String']>;
    /** Whether or not it should return invalid methods, defaults to false. */
    includeInvalid?: Maybe<Scalars['Boolean']>;
};

export enum PaymentMethodStatus {
    DRAFT = 'DRAFT',
    PENDING = 'PENDING',
    PENDING_REDIRECT = 'PENDING_REDIRECT',
    ERROR = 'ERROR',
    ACTIVE = 'ACTIVE',
    DELETED = 'DELETED',
    INVALID = 'INVALID',
}

/** Result of the statusForOrder query. */
export type PaymentMethodStatusForOrderResult = {
    __typename?: 'PaymentMethodStatusForOrderResult';
    /** Authorization status, if any. Applicable in Authorize & Capture flow. */
    authorization?: Maybe<PaymentMethodOrderAuthorization>;
    /** @deprecated Use authorization.attemptLimitReached field. */
    attemptLimitReached?: Maybe<Scalars['Boolean']>;
};

export type PaymentMethodStub = {
    __typename?: 'PaymentMethodStub';
    billingAccountId?: Maybe<Scalars['ID']>;
    paymentMethodId?: Maybe<Scalars['ID']>;
};

export type PaymentModifierModel = {
    __typename?: 'PaymentModifierModel';
    amount: Scalars['Money'];
    currency: Scalars['String'];
    uuid: Scalars['ID'];
    group: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    type: Scalars['String'];
    unit: Scalars['String'];
};

export type PaymentOption = {
    __typename?: 'PaymentOption';
    /** @deprecated This field is present for compatibility with the legacy frontend and should be avoided. */
    id: Scalars['ID'];
    /** @deprecated This field is present for compatibility with the legacy frontend and should be avoided. This name is not localized and the consumer is encouraged to render a localized version based on the `type` field. */
    name: Scalars['String'];
    type: PaymentOptionType;
    /** @deprecated This field is present for compatibility with the legacy frontend and should be avoided. */
    flowType: PaymentOptionFlow;
    /** Whether this option's methods should go through the Auth & Capture flow. */
    requiresAuthorization: Scalars['Boolean'];
    /** IXOPAY-specific data. */
    ixopay: PaymentOptionIxopay;
};

export enum PaymentOptionFlow {
    REDIRECT = 'REDIRECT',
    CREDIT_CARD = 'CREDIT_CARD',
}

export type PaymentOptionIxopay = {
    __typename?: 'PaymentOptionIxopay';
    /** Integration key needed for the payments.js IXOPAY library. */
    publicKey?: Maybe<Scalars['String']>;
};

export enum PaymentOptionType {
    BANKCARD = 'BANKCARD',
    PAYPAL = 'PAYPAL',
    SEPA = 'SEPA',
}

export type PaymentOrderActionResponseItem = {
    __typename?: 'PaymentOrderActionResponseItem';
    amount: Scalars['Money'];
    currency: Scalars['String'];
    uuid: Scalars['ID'];
    accountFrom?: Maybe<Scalars['String']>;
    accountTo?: Maybe<Scalars['String']>;
    parent?: Maybe<Scalars['String']>;
    type: Scalars['String'];
    status: Scalars['String'];
    gatewayResponse?: Maybe<Scalars['JSON']>;
    createdAt: Scalars['DateTime'];
    failedReason?: Maybe<Scalars['String']>;
    refundReason?: Maybe<RefundReason>;
    proxied: Scalars['Boolean'];
    paymentOrderUuid: Scalars['String'];
};

export type PaymentOrderActionResponseItemGeneric = {
    __typename?: 'PaymentOrderActionResponseItemGeneric';
    paymentOrderUuid: Scalars['String'];
};

export enum PaymentOrderContractType {
    FLEX = 'FLEX',
    MIX = 'MIX',
}

export enum PaymentOrderCurrencyEnum {
    EUR = 'EUR',
    USD = 'USD',
    GCR = 'GCR',
}

export type PaymentOrderEvent = {
    id: Scalars['String'];
    payment_group_id: Scalars['String'];
    customer_id: Scalars['String'];
    type: PaymentOrderType;
    subscription_payment_type: PaymentOrderSubscriptionPaymentType;
    amount_in_cents: Scalars['Int'];
    currency: PaymentOrderCurrencyEnum;
    billing_service_payment_process: BillingServicePaymentProcess;
    payment_method: Scalars['String'];
    contract_id: Scalars['String'];
    contract_type: PaymentOrderContractType;
    period_number: Scalars['Int'];
    order_id: Scalars['String'];
    consolidated: Scalars['Boolean'];
    status: PaymentOrderStatusEnum;
    country_code: Scalars['String'];
    failed_reason: Scalars['String'];
    previous_failed_count: Scalars['Int'];
    attempts_to_pay: Scalars['Int'];
    due_date: Scalars['ISO8601DateTime'];
    created_at: Scalars['ISO8601DateTime'];
    updated_at: Scalars['ISO8601DateTime'];
};

export type PaymentOrderHistoryResponse = {
    __typename?: 'PaymentOrderHistoryResponse';
    history: Array<PaymentOrderModel>;
    count: Scalars['Int'];
};

export type PaymentOrderModel = {
    __typename?: 'PaymentOrderModel';
    amount: Scalars['Money'];
    currency: Scalars['String'];
    uuid: Scalars['ID'];
    group: Scalars['String'];
    groupStrategy: Scalars['String'];
    contractType: Scalars['String'];
    walletUuid: Scalars['String'];
    scopeUuid?: Maybe<Scalars['String']>;
    paymentPurpose?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    orderNumber?: Maybe<Scalars['String']>;
    contractId?: Maybe<Scalars['String']>;
    dueDate: Scalars['DateTime'];
    billingDate: Scalars['DateTime'];
    lineItems?: Maybe<Array<Scalars['JSON']>>;
    additionalData?: Maybe<Scalars['JSON']>;
    price: Scalars['Money'];
    shippingCost: Scalars['Money'];
    quantity: Scalars['Float'];
    period?: Maybe<Scalars['Float']>;
    taxIncluded: Scalars['Boolean'];
    status: PaymentGroupStatusTypes;
    paymentStatus: PaymentGroupStatusTypes;
    countryCode: Scalars['String'];
    paymentType: PaymentOrderPaymentTypes;
    attempts?: Maybe<Array<Scalars['DateTime']>>;
    actions?: Maybe<Array<TransactionActionModel>>;
    wallet?: Maybe<WalletModel>;
    amountWithTax?: Maybe<Scalars['Money']>;
    managedByScheduler: Scalars['Boolean'];
    migratedAt?: Maybe<Scalars['DateTime']>;
    paymentGroup: PaymentGroupModel;
    paymentDetails: PaymentDetails;
    billingPeriod: BillingPeriod;
    ledgerResource?: Maybe<Resource>;
};

export enum PaymentOrderPaymentTypes {
    subscription = 'subscription',
    shipping = 'shipping',
    purchase = 'purchase',
}

export type PaymentOrderRefundRequest = {
    id: Scalars['String'];
    currency: Scalars['String'];
    amount: Scalars['Float'];
    reason: Scalars['String'];
    comment: Scalars['String'];
    transactionUuid: Scalars['String'];
};

export type PaymentOrderRetryRequest = {
    id: Scalars['String'];
    currency: Scalars['String'];
    amount: Scalars['Float'];
    reason: Scalars['String'];
    comment: Scalars['String'];
    paymentGroupId: Scalars['String'];
    retryDate: Scalars['String'];
};

export type PaymentOrdersRequest = {
    id: Scalars['String'];
    currency: Scalars['String'];
    amount: Scalars['Float'];
    reason: Scalars['String'];
    comment: Scalars['String'];
};

export enum PaymentOrderStatusEnum {
    new = 'new',
    failed = 'failed',
    partial_paid = 'partial_paid',
    paid = 'paid',
    partial_refund = 'partial_refund',
    refund = 'refund',
    pending = 'pending',
    cancelled = 'cancelled',
}

export enum PaymentOrderSubscriptionPaymentType {
    FIRST = 'FIRST',
    RECURRENT = 'RECURRENT',
}

export type PaymentOrderTotals = {
    __typename?: 'PaymentOrderTotals';
    totalAmountPaid: Array<PaymentOrderTotalStat>;
    totalAmountPlan: Array<PaymentOrderTotalStat>;
    totalAmountDue: Array<PaymentOrderTotalStat>;
};

export type PaymentOrderTotalStat = {
    __typename?: 'PaymentOrderTotalStat';
    amount: Scalars['String'];
    currency: Scalars['String'];
};

export enum PaymentOrderType {
    subscription = 'subscription',
    shipping = 'shipping',
    purchase = 'purchase',
}

export type PaymentsFilterInput = {
    defaultStoreCode?: Maybe<DefaultStoreCode>;
    groupPaymentId?: Maybe<Scalars['ID']>;
    state?: Maybe<Array<PaymentState>>;
    subscriptionId?: Maybe<Scalars['ID']>;
};

export type PaymentsInvoice = {
    __typename?: 'PaymentsInvoice';
    paymentGroupId: Scalars['ID'];
    publicUrl?: Maybe<Scalars['String']>;
    internalUrl?: Maybe<Scalars['String']>;
    invoiceId?: Maybe<Scalars['String']>;
    invoiceSequenceNumber?: Maybe<Scalars['String']>;
};

export type PaymentsSummaryByStateUnion = PaymentSummaryMultiple | PaymentSummarySingle;

/** The connection type for PaymentsSummaryByStateUnion. */
export type PaymentsSummaryByStateUnionConnection = {
    __typename?: 'PaymentsSummaryByStateUnionConnection';
    /** A list of edges. */
    edges?: Maybe<Array<Maybe<PaymentsSummaryByStateUnionEdge>>>;
    /** A list of nodes. */
    nodes?: Maybe<Array<Maybe<PaymentsSummaryByStateUnion>>>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PaymentsSummaryByStateUnionEdge = {
    __typename?: 'PaymentsSummaryByStateUnionEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
    /** The item at the end of the edge. */
    node?: Maybe<PaymentsSummaryByStateUnion>;
};

export enum PaymentState {
    DUE = 'DUE',
    FAILED = 'FAILED',
    PAID = 'PAID',
    SCHEDULED = 'SCHEDULED',
}

export type PaymentStateTypeComparisonInput = {
    _eq?: Maybe<PaymentState>;
    _neq?: Maybe<PaymentState>;
};

export type PaymentSummaryInterface = {
    state: PaymentSummaryState;
    total: Price;
};

export type PaymentSummaryMultiple = PaymentSummaryInterface & {
    __typename?: 'PaymentSummaryMultiple';
    count: Scalars['Int'];
    state: PaymentSummaryState;
    total: Price;
};

export type PaymentSummarySingle = PaymentSummaryInterface & {
    __typename?: 'PaymentSummarySingle';
    payment: PaymentsUnion;
    state: PaymentSummaryState;
    total: Price;
};

export enum PaymentSummaryState {
    DUE = 'DUE',
    FAILED = 'FAILED',
    SCHEDULED = 'SCHEDULED',
}

export type PaymentsUnion = GroupPayment | OrderPayment | PurchasePayment | SubscriptionPayment;

/** The connection type for PaymentsUnion. */
export type PaymentsUnionConnection = {
    __typename?: 'PaymentsUnionConnection';
    /** A list of edges. */
    edges?: Maybe<Array<Maybe<PaymentsUnionEdge>>>;
    /** A list of nodes. */
    nodes?: Maybe<Array<Maybe<PaymentsUnion>>>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PaymentsUnionEdge = {
    __typename?: 'PaymentsUnionEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
    /** The item at the end of the edge. */
    node?: Maybe<PaymentsUnion>;
};

export type PaymentsWhereInput = {
    and?: Maybe<Array<PaymentsWhereInput>>;
    dueDate?: Maybe<DateComparisonInput>;
    state?: Maybe<PaymentStateTypeComparisonInput>;
    type?: Maybe<PaymentEnumTypeComparisonInput>;
};

export type PayoutReferenceAccountInfo = {
    amount: PaymentAmount;
    description: Scalars['String'];
    deviceId: Scalars['String'];
};

export type Paypal = PaymentMethodInterface & {
    __typename?: 'Paypal';
    addressConfirmed?: Maybe<Scalars['Boolean']>;
    billingAccountId?: Maybe<Scalars['String']>;
    email: Scalars['String'];
    firstName?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    lastName?: Maybe<Scalars['String']>;
    merchantTransactionId?: Maybe<Scalars['String']>;
    referenceId?: Maybe<Scalars['String']>;
    shippingAddress?: Maybe<Scalars['String']>;
    shippingCity?: Maybe<Scalars['String']>;
    shippingCountry?: Maybe<Scalars['String']>;
    shippingFirstname?: Maybe<Scalars['String']>;
    shippingPostcode?: Maybe<Scalars['String']>;
    verified?: Maybe<Scalars['Boolean']>;
    contracts: Array<BasicContract>;
};

/** Address type for Paypal accounts' billing and shipping addresses. */
export type PaypalAddress = {
    __typename?: 'PaypalAddress';
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    confirmed?: Maybe<Scalars['Boolean']>;
    address1?: Maybe<Scalars['String']>;
    address2?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    postcode?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
    /** 2-letter uppercase ISO code for the address country. */
    countryCode?: Maybe<Scalars['String']>;
};

/** Paypal payment method from Wallet service. Eventually will deprecate the Paypal type. */
export type PaypalPaymentMethod = PaymentMethod2 & {
    __typename?: 'PaypalPaymentMethod';
    id: Scalars['ID'];
    status: PaymentMethodStatus;
    /** Date/time of the method creation. Empty in case of a legacy method. */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** URL to redirect the user to if authorization is required by the payment provider. */
    redirectUrl?: Maybe<Scalars['String']>;
    /** ID of the corresponding method in Grover API. */
    groverApiId?: Maybe<Scalars['Float']>;
    /** ID of the corresponding account in Billing Service. */
    billingAccountId?: Maybe<Scalars['String']>;
    /** Contracts and subscriptions that are using this method. */
    linkedContracts: Array<FlexDetails>;
    /** Method's external ID in IXOPAY. */
    ixopayReferenceId?: Maybe<Scalars['String']>;
    /**
     * Present if the method deletion is currently forbidden, contains details about
     * the reason. Warning: currently does not return "has linked contracts", which
     * is one of the possible reasons for deletion lock. Check the linkedContracts field instead.
     */
    deletionLock?: Maybe<PaymentMethodDeletionLock>;
    /** Error code classifying the method registration failure, if any. */
    registrationErrorCode?: Maybe<TransactionErrorCode>;
    /** Returns status of this method in relation to the given order. */
    statusForOrder: PaymentMethodStatusForOrderResult;
    accountName?: Maybe<Scalars['String']>;
    verified?: Maybe<Scalars['Boolean']>;
    billingAddress: PaypalAddress;
    shippingAddress: PaypalAddress;
};

/** Paypal payment method from Wallet service. Eventually will deprecate the Paypal type. */
export type PaypalPaymentMethodStatusForOrderArgs = {
    orderNumber: Scalars['String'];
};

export type Period = {
    ends: Scalars['ISO8601DateTime'];
    number: Scalars['Int'];
    starts: Scalars['ISO8601DateTime'];
};

export type Person = {
    __typename?: 'Person';
    userId: Scalars['String'];
    loyaltyBalance: LoyaltyBalanceResult;
    hasSpentGroverCash: Scalars['Boolean'];
    hasGroverCashHistory: Scalars['Boolean'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    identification?: Maybe<PersonIdentification>;
    taxInfo?: Maybe<TaxInfoResult>;
    address: PersonAddress;
    screeningStatus?: Maybe<PersonScreeningStatus>;
    riskClassificationStatus?: Maybe<PersonRiskClassificationStatus>;
    vettingStatus?: Maybe<PersonVettingStatus>;
    cards: Array<GroverCard>;
    accountDetails?: Maybe<PersonAccountDetails>;
    accountBalance?: Maybe<PersonAccountBalance>;
    mobilePhone: PersonMobilePhone;
    bookings: Array<CardBooking>;
    reservations: Array<AccountReservation>;
    statements: Array<Statement>;
    birthdate?: Maybe<Scalars['DateTime']>;
    employmentStatus?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    nationality?: Maybe<Scalars['String']>;
    referenceAccount?: Maybe<ReferenceAccountResult>;
    sddAcceptedAt?: Maybe<Scalars['DateTime']>;
    ownEconomicInterestSignedAt?: Maybe<Scalars['DateTime']>;
    sddMandates: Array<SddMandate>;
    postBoxItems: Array<PostBoxItem>;
    amlFollowUpDate?: Maybe<Scalars['DateTime']>;
    deviceList: Array<Device>;
    shouldUpdatePersonalInfo: Scalars['Boolean'];
    activationChecklistCompleted: Scalars['Boolean'];
    hasFirstTransaction: Scalars['Boolean'];
    accountOpeningRequestStatus?: Maybe<AccountOpeningRequestStatus>;
};

export type PersonBookingsArgs = {
    info: BookingsInfo;
};

export type PersonReservationsArgs = {
    info: BookingsInfo;
};

export type PersonPostBoxItemsArgs = {
    info: PostBoxItemsInfo;
};

export type PersonAccountBalance = {
    __typename?: 'PersonAccountBalance';
    availableBalance: Scalars['Float'];
    unit: Scalars['String'];
    currency: Scalars['String'];
};

export type PersonAccountDetails = {
    __typename?: 'PersonAccountDetails';
    iban: Scalars['String'];
    bic: Scalars['String'];
    status: Scalars['String'];
    lockingStatus?: Maybe<Scalars['String']>;
    lockingReasons?: Maybe<Array<Scalars['String']>>;
    closureReasons?: Maybe<Array<Scalars['String']>>;
};

export type PersonAddress = {
    __typename?: 'PersonAddress';
    line1: Scalars['String'];
    line2?: Maybe<Scalars['String']>;
    postalCode: Scalars['String'];
    city: Scalars['String'];
    country?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
};

export type PersonalIdentification = {
    __typename?: 'PersonalIdentification';
    identificationNumber: Scalars['String'];
    identificationSubType?: Maybe<PersonalIdentificationSubType>;
    identificationType: PersonalIdentificationType;
    user: User;
};

export enum PersonalIdentificationSubType {
    DNI = 'DNI',
    NIE = 'NIE',
}

export enum PersonalIdentificationType {
    NATIONAL_ID = 'NATIONAL_ID',
}

export type PersonIdentification = {
    __typename?: 'PersonIdentification';
    id: Scalars['String'];
    reference: Scalars['String'];
    url: Scalars['String'];
    status: Scalars['String'];
    completedAt: Scalars['String'];
    language: Scalars['String'];
};

export type PersonIdentificationInfo = {
    acceptedTncAt: Scalars['DateTime'];
};

export type PersonMobilePhone = {
    __typename?: 'PersonMobilePhone';
    id?: Maybe<Scalars['String']>;
    number?: Maybe<Scalars['String']>;
    verified?: Maybe<Scalars['Boolean']>;
};

export enum PersonRiskClassificationStatus {
    NOT_SCORED = 'NOT_SCORED',
    POTENTIAL_RISK = 'POTENTIAL_RISK',
    NORMAL_RISK = 'NORMAL_RISK',
    INFORMATION_REQUESTED = 'INFORMATION_REQUESTED',
    INFORMATION_RECEIVED = 'INFORMATION_RECEIVED',
    RISK_ACCEPTED = 'RISK_ACCEPTED',
    RISK_REJECTED = 'RISK_REJECTED',
    CUSTOMER_UNRESPONSIVE = 'CUSTOMER_UNRESPONSIVE',
    SCORING_NOT_REQUIRED = 'SCORING_NOT_REQUIRED',
}

export enum PersonScreeningStatus {
    NOT_SCREENED = 'NOT_SCREENED',
    POTENTIAL_MATCH = 'POTENTIAL_MATCH',
    SCREENED_ACCEPTED = 'SCREENED_ACCEPTED',
    SCREENED_DECLINED = 'SCREENED_DECLINED',
}

export type PersonSettings = {
    __typename?: 'PersonSettings';
    language: Scalars['String'];
};

export type PersonSettingsInfo = {
    language: Scalars['String'];
};

export enum PersonVettingStatus {
    NOT_VETTED = 'NOT_VETTED',
    NO_MATCH = 'NO_MATCH',
    POTENTIAL_MATCH = 'POTENTIAL_MATCH',
    INFORMATION_REQUESTED = 'INFORMATION_REQUESTED',
    INFORMATION_RECEIVED = 'INFORMATION_RECEIVED',
    RISK_ACCEPTED = 'RISK_ACCEPTED',
    RISK_REJECTED = 'RISK_REJECTED',
    CUSTOMER_UNRESPONSIVE = 'CUSTOMER_UNRESPONSIVE',
    VETTING_NOT_REQUIRED = 'VETTING_NOT_REQUIRED',
}

export type Plaid = ProviderInterface & {
    __typename?: 'Plaid';
    linkToken: Scalars['String'];
    requestId: Scalars['String'];
    tracingId: Scalars['String'];
};

export enum Platform {
    CHROME = 'CHROME',
    EDGE = 'EDGE',
    FIREFOX = 'FIREFOX',
    GROVER_APP = 'GROVER_APP',
    OTHER = 'OTHER',
    SAFARI = 'SAFARI',
}

export enum PlatformTypes {
    app = 'app',
    web = 'web',
}

export type Poll = {
    __typename?: 'Poll';
    id: Scalars['ID'];
    mode: PollMode;
    name: Scalars['String'];
    slug: Scalars['String'];
    description: Scalars['String'];
    entityType?: Maybe<EntityType>;
    activeVersion?: Maybe<PollVersion>;
    version?: Maybe<PollVersion>;
    pollVersions?: Maybe<Array<PollVersion>>;
};

export type PollVersionArgs = {
    version: Scalars['Int'];
};

export type PollCardCommentStepModel = {
    __typename?: 'PollCardCommentStepModel';
    title: Scalars['String'];
    body: Scalars['String'];
    buttonLabel: Scalars['String'];
};

export type PollCardContentModel = {
    __typename?: 'PollCardContentModel';
    uuid: Scalars['ID'];
    pollSlug: Scalars['String'];
    copy: PollCardCopyModel;
    actions?: Maybe<Array<ActionUnionModel>>;
};

export type PollCardCopyModel = {
    __typename?: 'PollCardCopyModel';
    voteStep: PollCardItemModel;
    commentStep: PollCardCommentStepModel;
    positiveOutcome: PollCardItemModel;
    negativeOutcome: PollCardItemModel;
};

export type PollCardItemModel = {
    __typename?: 'PollCardItemModel';
    title: Scalars['String'];
    body: Scalars['String'];
};

export type PollCardModel = {
    __typename?: 'PollCardModel';
    uuid: Scalars['ID'];
    pollSlug: Scalars['String'];
    copy: PollCardCopyModel;
    actions?: Maybe<Array<ActionUnionModel>>;
};

export enum PollMode {
    singleChoice = 'singleChoice',
    thumbs = 'thumbs',
    mood = 'mood',
}

export type PollOptions = SingleChoiceOptions | ThumbsOptions | MoodOptions;

export type PollOrderInput = {
    createdAt?: Maybe<OrderByDirection>;
    updatedAt?: Maybe<OrderByDirection>;
};

export type PollResults = SingleChoiceResults | ThumbsResults | MoodResults;

export type PollsConnection = {
    __typename?: 'PollsConnection';
    pageInfo: PageInfo;
    edges: Array<PollsEdge>;
};

export type PollsEdge = {
    __typename?: 'PollsEdge';
    node: Poll;
    cursor: Scalars['String'];
};

export type PollsFilterInput = {
    mode?: Maybe<PollMode>;
    name?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
    entityType?: Maybe<EntityType>;
};

export type PollVersion = {
    __typename?: 'PollVersion';
    id: Scalars['ID'];
    poll: Poll;
    version: Scalars['Int'];
    options: PollOptions;
    description: Scalars['String'];
    createdAt: Scalars['DateTime'];
    results?: Maybe<PollResults>;
};

export type PollVersionResultsArgs = {
    filter?: Maybe<Scalars['JSON']>;
};

export enum PosEntryModeTypes {
    chip = 'chip',
    contactless = 'contactless',
    magStripe = 'magStripe',
    cardNotPresent = 'cardNotPresent',
    manualPanEntry = 'manualPanEntry',
    eCommerce = 'eCommerce',
    credentialOnFile = 'credentialOnFile',
    unknown = 'unknown',
}

export type PostBoxDocument = {
    __typename?: 'PostBoxDocument';
    id: Scalars['String'];
    date: Scalars['DateTime'];
    contentType: Scalars['String'];
    size: Scalars['Float'];
    type: PostBoxDocumentType;
};

export type PostBoxDocumentDownloadInfo = {
    id: Scalars['String'];
};

export type PostBoxDocumentDownloadResult = {
    __typename?: 'PostBoxDocumentDownloadResult';
    encodedContent: Scalars['String'];
    contentType: Scalars['String'];
};

export enum PostBoxDocumentType {
    accountStatement = 'accountStatement',
    balanceConfirmation = 'balanceConfirmation',
    customerInformation = 'customerInformation',
    dunningInformation = 'dunningInformation',
    loanSchedule = 'loanSchedule',
    securitiesInvoice = 'securitiesInvoice',
    securitiesEvent = 'securitiesEvent',
    securitiesEventNotification = 'securitiesEventNotification',
    depotStatementMIFID = 'depotStatementMIFID',
    exPostCostInformation = 'exPostCostInformation',
    depotStatement = 'depotStatement',
    assetsAcquisition = 'assetsAcquisition',
    assetsAcquisitionCost = 'assetsAcquisitionCost',
    profitTaxStatement = 'profitTaxStatement',
    yearlyTaxStatement = 'yearlyTaxStatement',
    taxSettlementCalculation = 'taxSettlementCalculation',
}

export type PostBoxItem = {
    __typename?: 'PostBoxItem';
    id: Scalars['String'];
    createdAt: Scalars['DateTime'];
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    customerNotification: Scalars['Boolean'];
    customerConfirmation: Scalars['Boolean'];
    document: PostBoxDocument;
};

export type PostBoxItemsCreatedAtFilter = {
    gte?: Maybe<Scalars['DateTime']>;
    lte?: Maybe<Scalars['DateTime']>;
};

export type PostBoxItemsFilter = {
    createdAt?: Maybe<PostBoxItemsCreatedAtFilter>;
};

export type PostBoxItemsInfo = {
    page: Scalars['Float'];
    perPage?: Maybe<Scalars['Float']>;
    filter?: Maybe<PostBoxItemsFilter>;
};

export type PreContractualInformationResult = {
    __typename?: 'PreContractualInformationResult';
    preContractualInfoSentAt: Scalars['DateTime'];
};

export enum PredicateType {
    string = 'string',
    number = 'number',
    date = 'date',
}

export type Preorder = {
    __typename?: 'Preorder';
    id: Scalars['ID'];
    variantSkus: Array<Scalars['String']>;
    storeCodes: Array<Scalars['String']>;
    startDate: Scalars['DateTime'];
    endDate: Scalars['DateTime'];
    allocationDate?: Maybe<Scalars['DateTime']>;
    variants: Array<Variant>;
};

/** More fields for this type will be defined in following PRs */
export type PreparingToShipEof = IExternalOrderFulfillment & {
    __typename?: 'PreparingToShipEOF';
    uid: Scalars['ID'];
    orderNumber: Scalars['String'];
    orderMode?: Maybe<OrderFulfillmentOrderMode>;
    userId: Scalars['String'];
    userType?: Maybe<UserTypes>;
    storeId?: Maybe<Scalars['Int']>;
    storeCode?: Maybe<Scalars['String']>;
    storeType?: Maybe<StoreTypes>;
    storeCountryId?: Maybe<Scalars['Int']>;
    storeCountryCode?: Maybe<Scalars['String']>;
    state: OrderFulfillmentState;
    stateUpdatedAt: Scalars['DateTime'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    deletedAt?: Maybe<Scalars['DateTime']>;
    order?: Maybe<MixCartOrder>;
    /** Collection of assets belonging to an order */
    assets?: Maybe<Array<OrderAsset>>;
    shippingPreferences?: Maybe<OrderShippingPreferences>;
    employee?: Maybe<GroverEmployee>;
    shippingAddress?: Maybe<EofShippingAddress>;
};

export type PresignedUrl = {
    __typename?: 'PresignedUrl';
    fileUri: Scalars['String'];
};

export type PresignedUrlCreateInput = {
    fileName: Scalars['String'];
    bucket: Scalars['String'];
};

export type PresignedUrlCreateResult = PresignedUrl | PresignedUrlError;

export type PresignedUrlError = {
    __typename?: 'PresignedUrlError';
    code: Scalars['String'];
    message: Scalars['String'];
};

export type Price = {
    __typename?: 'Price';
    currency: Scalars['String'];
    inCents: Scalars['Int'];
};

export type PriceInput = {
    currency: Scalars['String'];
    inCents: Scalars['Int'];
};

export type PricingDetails = {
    __typename?: 'PricingDetails';
    discountAmount: Price;
    itemsTotal: Price;
    shippingPrice: Price;
    voucherCode?: Maybe<Scalars['String']>;
};

export type PrintReturnLabel = {
    __typename?: 'PrintReturnLabel';
    /** @deprecated Use grapql __typename instead */
    action: Scalars['String'];
    availableAfter?: Maybe<Scalars['ISO8601DateTime']>;
    remainingMonths?: Maybe<Scalars['String']>;
    unavailableReason?: Maybe<Scalars['String']>;
};

export type ProcessingNote = TextNoteInterface & {
    __typename?: 'ProcessingNote';
    text?: Maybe<Scalars['String']>;
};

export type Product = {
    __typename?: 'Product';
    sku: Scalars['ID'];
    bundle?: Maybe<Bundle>;
    energyLabel?: Maybe<EnergyLabel>;
    categoryId?: Maybe<Scalars['ID']>;
    category: Category;
    logistics?: Maybe<ProductLogistics>;
    rentalPlans: Array<RentalPlan>;
    cheapestRentalPlan?: Maybe<RentalPlan>;
    accessories?: Maybe<Array<Scalars['String']>>;
    available: Scalars['Boolean'];
    brandName: Scalars['String'];
    buyoutCalculation: BuyoutCalculation;
    cloudinaryId: Scalars['String'];
    coreAttribute?: Maybe<Scalars['String']>;
    createdAt: Scalars['ISO8601DateTime'];
    highlight?: Maybe<ProductHighlight>;
    id: Scalars['ID'];
    imageUrl: Scalars['String'];
    /** Field used for PDP */
    inStores?: Maybe<Array<Store>>;
    isNew: Scalars['Boolean'];
    marketPrice: Price;
    name: Scalars['String'];
    originalImageUrl: Scalars['String'];
    originalPictures: Array<Scalars['String']>;
    /** @deprecated Use promotional_labels (plural) instead */
    promotionalLabel?: Maybe<Scalars['String']>;
    promotionalLabels: Array<Scalars['String']>;
    /** Field used for PDP */
    relatedProducts?: Maybe<Array<Product>>;
    returnSteps: Array<ReturnSteps>;
    slug: Scalars['String'];
    specifications?: Maybe<Scalars['JSON']>;
    updatedAt: Scalars['ISO8601DateTime'];
    variants: Array<Variant>;
    productCampaigns?: Maybe<Array<ProductCampaign>>;
    countdownTimers?: Maybe<Array<CountdownTimer>>;
};

export type ProductRentalPlansArgs = {
    minLengthInMonths?: Maybe<Scalars['Int']>;
};

export type ProductBuyoutCalculationArgs = {
    monthlyPriceInCents: Scalars['Int'];
    oneTimeDiscountInCents: Scalars['Int'];
    recurrentDiscountInCents: Scalars['Int'];
};

export type ProductRelatedProductsArgs = {
    inStock?: Maybe<Scalars['Boolean']>;
    similarProductsCount?: Maybe<Scalars['Int']>;
};

export type ProductVariantsArgs = {
    ean?: Maybe<Scalars['String']>;
};

export type ProductCampaign = {
    __typename?: 'ProductCampaign';
    id: Scalars['ID'];
    name: Scalars['String'];
    storeCodes: Array<Scalars['String']>;
    products?: Maybe<Array<Product>>;
    description?: Maybe<Scalars['String']>;
    isActive: Scalars['Boolean'];
    createdAt: Scalars['DateTime'];
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductCampaignCreateInput = {
    name: Scalars['String'];
    products?: Maybe<Array<AssociateProductInput>>;
    storeCodes: Array<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    isActive: Scalars['Boolean'];
};

export type ProductCampaignOrderByInput = {
    name?: Maybe<Scalars['String']>;
    isActive?: Maybe<Scalars['Boolean']>;
};

export type ProductCampaignPaginationOutput = {
    __typename?: 'ProductCampaignPaginationOutput';
    totalCount: Scalars['Int'];
    rows: Array<ProductCampaign>;
};

export type ProductCampaignUpdateInput = {
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    storeCodes?: Maybe<Array<Scalars['String']>>;
    isActive?: Maybe<Scalars['Boolean']>;
};

export type ProductCampaignWhereInput = {
    name?: Maybe<Scalars['String']>;
    storeCodes?: Maybe<Array<Scalars['String']>>;
    isActive?: Maybe<Scalars['Boolean']>;
};

export type ProductContentImageSuggestion = {
    __typename?: 'ProductContentImageSuggestion';
    originalUrl: Scalars['String'];
    thumbnailUrl: Scalars['String'];
};

export type ProductContentSuggestion = {
    __typename?: 'ProductContentSuggestion';
    specifications?: Maybe<Array<ProductSpecificationSuggestion>>;
    variants: Array<ProductContentSuggestionVariant>;
};

export type ProductContentSuggestionVariant = {
    __typename?: 'ProductContentSuggestionVariant';
    id: Scalars['ID'];
    sku: Scalars['String'];
    images?: Maybe<Array<ProductContentImageSuggestion>>;
};

export type ProductFilter = RangeFilter | KeywordFilter;

export type ProductFilterError = GenericError & {
    __typename?: 'ProductFilterError';
    code: Scalars['String'];
    message: Scalars['String'];
};

export type ProductFilterKeywordCreateInput = {
    name: Scalars['String'];
    label?: Maybe<Scalars['String']>;
    position?: Maybe<Scalars['Int']>;
    categoryId: Scalars['String'];
    storeCodes: Array<Scalars['String']>;
};

export type ProductFilterKeywordUpdateInput = {
    name?: Maybe<Scalars['String']>;
    label?: Maybe<Scalars['String']>;
    position?: Maybe<Scalars['Int']>;
    categoryId?: Maybe<Scalars['String']>;
    storeCodes?: Maybe<Array<Scalars['String']>>;
};

export type ProductFilterOrderByInput = {
    id?: Maybe<SortOrder>;
    position?: Maybe<SortOrder>;
};

export type ProductFilterRangeConfigCreateInput = {
    unit?: Maybe<Scalars['String']>;
    bucketSize?: Maybe<Scalars['Float']>;
    bucketMin?: Maybe<Scalars['Float']>;
    bucketMax?: Maybe<Scalars['Float']>;
};

export type ProductFilterRangeCreateInput = {
    name: Scalars['String'];
    position?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
    categoryId: Scalars['String'];
    storeCodes: Array<Scalars['String']>;
    rangeFilterConfig: ProductFilterRangeConfigCreateInput;
};

export type ProductFilterRangeUpdateInput = {
    name?: Maybe<Scalars['String']>;
    position?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
    categoryId?: Maybe<Scalars['String']>;
    storeCodes?: Maybe<Array<Scalars['String']>>;
    rangeFilterConfig?: Maybe<ProductFilterRangeConfigCreateInput>;
};

export enum ProductFilterType {
    RANGE = 'RANGE',
    KEYWORD = 'KEYWORD',
}

export type ProductFilterWhereInput = {
    id?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
    label?: Maybe<Scalars['String']>;
    categoryId?: Maybe<Scalars['String']>;
    storeCodes?: Maybe<Array<Scalars['String']>>;
    type?: Maybe<ProductFilterType>;
};

export type ProductHighlight = {
    __typename?: 'ProductHighlight';
    categoryId?: Maybe<Scalars['Int']>;
    id: Scalars['ID'];
    storeCode: Scalars['String'];
    trial?: Maybe<Scalars['Boolean']>;
};

export type ProductLogistics = {
    __typename?: 'ProductLogistics';
    weight?: Maybe<Scalars['Float']>;
    height?: Maybe<Scalars['Float']>;
    length?: Maybe<Scalars['Float']>;
    width?: Maybe<Scalars['Float']>;
    dimensionsUnit?: Maybe<LogisticsDimensionsUnit>;
    hazmatClass?: Maybe<LogisticsHazmatClass>;
    piCode?: Maybe<Scalars['String']>;
    unCode?: Maybe<Scalars['String']>;
    sectionCode?: Maybe<Scalars['String']>;
    identifier?: Maybe<LogisticsIdentifier>;
    hsCode?: Maybe<Scalars['String']>;
};

export type ProductLogisticsInput = {
    weight?: Maybe<Scalars['Float']>;
    height?: Maybe<Scalars['Float']>;
    length?: Maybe<Scalars['Float']>;
    width?: Maybe<Scalars['Float']>;
    dimensionsUnit?: Maybe<LogisticsDimensionsUnit>;
    hazmatClass?: Maybe<LogisticsHazmatClass>;
    piCode?: Maybe<Scalars['String']>;
    unCode?: Maybe<Scalars['String']>;
    sectionCode?: Maybe<Scalars['String']>;
    identifier?: Maybe<LogisticsIdentifier>;
    hsCode?: Maybe<Scalars['String']>;
};

export type ProductSpecificationInput = {
    language: Scalars['String'];
    items: Array<ProductSpecificationItemInput>;
};

export type ProductSpecificationItemInput = {
    key: Scalars['String'];
    value: Scalars['String'];
};

export type ProductSpecificationSuggestion = {
    __typename?: 'ProductSpecificationSuggestion';
    name: Scalars['String'];
    recommendedValue?: Maybe<Scalars['String']>;
    storedValue?: Maybe<Scalars['String']>;
};

export type ProductsQuery = {
    __typename?: 'ProductsQuery';
    products: Array<Product>;
    facets?: Maybe<Array<Facet>>;
    pagination: Pagination;
    categories?: Maybe<Array<Category>>;
};

export type ProductsUploadTask = Task & {
    __typename?: 'ProductsUploadTask';
    id: Scalars['ID'];
    createdAt: Scalars['DateTime'];
    status: TaskStatus;
    createdByUserId: Scalars['Int'];
    createdBy?: Maybe<User>;
    fileUri: Scalars['String'];
    fileName: Scalars['String'];
    errors?: Maybe<Array<Scalars['String']>>;
};

export type ProductsUploadTaskCreateInput = {
    fileUri: Scalars['String'];
    fileName: Scalars['String'];
};

export type ProductsWhereInput = {
    slugs?: Maybe<Array<Scalars['String']>>;
};

export type ProductsWidget = {
    __typename?: 'ProductsWidget';
    id: Scalars['ID'];
    name: Scalars['String'];
    active: Scalars['Boolean'];
    type: WidgetType;
    url?: Maybe<Scalars['String']>;
    position: Scalars['Float'];
    storeCodes: Array<Scalars['String']>;
    productSkus: Array<Scalars['String']>;
    properties?: Maybe<Scalars['JSON']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    products?: Maybe<Array<Product>>;
    countdown?: Maybe<CountdownTimer>;
};

export type ProductsWidgetProductsArgs = {
    storeCode?: Maybe<Scalars['String']>;
};

export type ProductsWidgetCreateInput = {
    name: Scalars['String'];
    type: Scalars['String'];
    active: Scalars['Boolean'];
    position: Scalars['Float'];
    url?: Maybe<Scalars['String']>;
    storeCodes: Array<Scalars['String']>;
    productSkus?: Maybe<Array<Scalars['String']>>;
    properties?: Maybe<WidgetProperties>;
};

export type ProductsWidgetError = GenericError & {
    __typename?: 'ProductsWidgetError';
    code: Scalars['String'];
    message: Scalars['String'];
};

export type ProductsWidgetOrderByInput = {
    id?: Maybe<SortOrder>;
    name?: Maybe<SortOrder>;
    position?: Maybe<SortOrder>;
};

export type ProductsWidgetOrError = ProductsWidget | ProductsWidgetError;

export type ProductsWidgetPaginationOutput = {
    __typename?: 'ProductsWidgetPaginationOutput';
    totalCount: Scalars['Int'];
    rows: Array<ProductsWidget>;
};

export type ProductsWidgetUpdateInput = {
    name?: Maybe<Scalars['String']>;
    active?: Maybe<Scalars['Boolean']>;
    position?: Maybe<Scalars['Float']>;
    url?: Maybe<Scalars['String']>;
    storeCodes?: Maybe<Array<Scalars['String']>>;
    productSkus?: Maybe<Array<Scalars['String']>>;
    type?: Maybe<Scalars['String']>;
    properties?: Maybe<WidgetProperties>;
};

export type ProductsWidgetWhereInput = {
    name?: Maybe<Scalars['String']>;
    active?: Maybe<Scalars['Boolean']>;
    storeCode?: Maybe<Scalars['String']>;
    type?: Maybe<WidgetType>;
};

export type ProductVariantCount = {
    __typename?: 'ProductVariantCount';
    companyId: Scalars['ID'];
    count: Scalars['Int'];
};

export type ProductVariantInput = {
    categoryId: Scalars['ID'];
    productSku: Scalars['ID'];
    serialNumber?: Maybe<Scalars['ID']>;
    subCategoryId: Scalars['ID'];
    variantSku: Scalars['ID'];
};

export type ProductVariantsCountInput = {
    companyIds: Array<Scalars['ID']>;
    state: Array<ContractState>;
    type: ContractType;
};

export type ProductWhereInput = {
    ean?: Maybe<StringComparisonInput>;
    id?: Maybe<IntegerComparisonInput>;
    sku?: Maybe<StringComparisonInput>;
    slug?: Maybe<StringComparisonInput>;
};

export type ProfileFilterInput = {
    uid?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    carrier?: Maybe<CarrierName>;
};

export enum ProviderCheckResult {
    CLEAR = 'CLEAR',
    CONSIDER = 'CONSIDER',
}

export type ProviderInterface = {
    tracingId: Scalars['String'];
};

/** Autogenerated input type of ProviderLogin */
export type ProviderLoginInput = {
    authCode: Scalars['String'];
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    oauthProvider: ExternalLoginProvider;
    twoFaCode?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ProviderLogin. */
export type ProviderLoginPayload = {
    __typename?: 'ProviderLoginPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    token: GroverAccessToken;
};

export type Psd2ChallengeInfo = {
    challengeId: Scalars['String'];
};

export type Psd2ChallengeResult = {
    __typename?: 'PSD2ChallengeResult';
    personId: Scalars['String'];
    redirectUrl: Scalars['String'];
};

export type Purchase = Operation & {
    __typename?: 'Purchase';
    completedAt?: Maybe<Scalars['ISO8601DateTime']>;
    owner: User;
    startedAt: Scalars['ISO8601DateTime'];
    startedBy: User;
    state: PurchaseState;
    updates: Array<FlowUpdate>;
};

export enum PurchaseMode {
    CUSTOM_PRICE = 'CUSTOM_PRICE',
    MONTHS_TO_OWN = 'MONTHS_TO_OWN',
    /** Old way of purchasing with Salesforce using a fixed price */
    OLD = 'OLD',
}

export type PurchasePayment = PaymentInterface & {
    __typename?: 'PurchasePayment';
    remoteId?: Maybe<Scalars['String']>;
    invoiceNumber?: Maybe<Scalars['String']>;
    basePrice: Price;
    createdAt: Scalars['ISO8601DateTime'];
    discountableLoyaltyPrice: Price;
    discountedLoyaltyPrice: Price;
    paidDate?: Maybe<Scalars['ISO8601DateTime']>;
    price: Price;
    state: PaymentState;
    invoiceUrl?: Maybe<Scalars['String']>;
    contract?: Maybe<Contract>;
    datePaid?: Maybe<Scalars['ISO8601DateTime']>;
    dueDate: DateUnion;
    id: Scalars['ID'];
    /** @deprecated Use Contract field */
    subscriptionDetails?: Maybe<SubscriptionDetails>;
};

export type PurchaseRequest = {
    __typename?: 'PurchaseRequest';
    salesforceId?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    purchaseOrderNumber?: Maybe<Scalars['String']>;
};

export type PurchaseRequestItem = {
    __typename?: 'PurchaseRequestItem';
    salesforceId?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
};

export enum PurchaseState {
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    STARTED = 'STARTED',
}

export type PurchaseTerm = {
    __typename?: 'PurchaseTerm';
    available: Scalars['Boolean'];
    availableAt?: Maybe<Scalars['ISO8601DateTime']>;
    currentPrice: Price;
    deactivated: Scalars['Boolean'];
    initialPrice: Price;
    maxCustomPrice?: Maybe<Price>;
    monthsRequired: Scalars['Int'];
    priceAgreedOn: Scalars['Boolean'];
    purchaseMode: PurchaseMode;
    remainingMonths: Scalars['Int'];
    unavailabilityReason?: Maybe<PurchaseUnavailabilityReason>;
    unavailableSince?: Maybe<Scalars['ISO8601DateTime']>;
};

export enum PurchaseUnavailabilityReason {
    ANOTHER_FLOW_IN_PROGRESS = 'ANOTHER_FLOW_IN_PROGRESS',
    B2B_CONTRACT_NOT_COMPLETED = 'B2B_CONTRACT_NOT_COMPLETED',
    CONTRACT_HAS_BEEN_PURCHASED = 'CONTRACT_HAS_BEEN_PURCHASED',
    CONTRACT_HAS_OUTSTANDING_DEBT = 'CONTRACT_HAS_OUTSTANDING_DEBT',
    CONTRACT_IS_NOT_ACTIVE = 'CONTRACT_IS_NOT_ACTIVE',
    NOT_AT_FINAL_OFFER = 'NOT_AT_FINAL_OFFER',
    PURCHASE_OPTION_DEACTIVATED = 'PURCHASE_OPTION_DEACTIVATED',
    RENT_IS_ZERO = 'RENT_IS_ZERO',
    TOS_EXCLUDES_PURCHASE_OPTION = 'TOS_EXCLUDES_PURCHASE_OPTION',
}

export type QualitativeShippingSummaryType = {
    __typename?: 'QualitativeShippingSummaryType';
    successfulDeliveryAddresses: Array<Scalars['String']>;
    successfulDeliveryAtAccessPointAddresses: Array<Scalars['String']>;
    failedDeliveryAddresses: Array<Scalars['String']>;
    attemptedDeliveryAddressesLeadingToAccessPoint: Array<Scalars['String']>;
};

export type QuantitativeShippingSummaryType = {
    __typename?: 'QuantitativeShippingSummaryType';
    successfulDeliveries: Scalars['Float'];
    successfulDeliveriesByProfile: Array<ShippingSummaryDeliveryProfileMetricType>;
    successfulDeliveriesByAccessPoint: Scalars['Float'];
    failedDeliveries: Scalars['Float'];
    idVerificationAt?: Maybe<Scalars['DateTimeISO']>;
};

export type Query = {
    __typename?: 'Query';
    fetchAddons: AddonsMainProduct;
    fetchAddonAssociations: FetchAddonAssociationsResult;
    fetchAddonAssociation: AddonsMainProduct;
    fetchAddonProviders: Array<AddonProvider>;
    digitalOtpVariantById?: Maybe<DigitalOtpVariant>;
    digitalOtpProducts: Array<DigitalOtpProduct>;
    digitalOtpProductBySlug: DigitalOtpProduct;
    digitalOtpVariants: Array<DigitalOtpVariant>;
    digitalProductLocalizations: Array<DigitalProductLocalization>;
    activationCodesReport: Array<ActivationCodesReportEntry>;
    addonsApiEvents: Array<AddonsApiEvent>;
    paymentGroups: Array<PaymentGroupsModel>;
    paymentGroup: PaymentGroupModel;
    wallet: WalletModel;
    wallets: Array<WalletModel>;
    walletByUserId: WalletModel;
    getAllPayments: GetPaymentOrdersResponse;
    getPaymentById: PaymentOrderModel;
    getAllPaymentStats: PaymentOrderTotals;
    getUserPaymentStats: PaymentOrderTotals;
    getPaymentOrderHistory: PaymentOrderHistoryResponse;
    getPaymentOrderCancelReasons: Array<Reason>;
    getPaymentOrderRetryReasons: Array<Reason>;
    getPaymentOrderRefundReasons: Array<Reason>;
    getPaymentOrderHoldReasons: Array<Reason>;
    recurrentPayment: RecurrentPaymentModel;
    recurrentPayments: Array<RecurrentPaymentModel>;
    contractRecurrentPayment: ContractServiceRecurrentModel;
    contractPaymentGroups: Array<ContractPaymentGroupModel>;
    bundle?: Maybe<Bundle>;
    bundles: BundlePaginationOutput;
    kit?: Maybe<Kit>;
    kits: PaginatedKitConnection;
    cartWithKit?: Maybe<CartWithKit>;
    cartWithKitByOrderId?: Maybe<CartWithKit>;
    getActiveCampaignsForUser: Array<CampaignUserStateModel>;
    getCampaignsForUser: Array<CampaignUserStateModel>;
    getCartById?: Maybe<Cart>;
    getCartByUser?: Maybe<Cart>;
    getCartPrices?: Maybe<CartPrices>;
    retailEmployeeApprovalStatus?: Maybe<Scalars['Boolean']>;
    cart?: Maybe<MixCartOrder>;
    orderOffer?: Maybe<OrderOffer>;
    orderOfferByOrder?: Maybe<OrderOffer>;
    orderOffersPaginated?: Maybe<OrderOffersConnection>;
    tasks: PaginatedTasks;
    task?: Maybe<Task>;
    productFilter?: Maybe<ProductFilter>;
    productFilters: Array<ProductFilter>;
    categories: Array<Category>;
    category?: Maybe<Category>;
    /** @deprecated Use Query.categories: [Category!]! */
    categoriesPerStore: Array<CategoryV2>;
    energyLabel?: Maybe<EnergyLabel>;
    energyLabels: EnergyLabelPaginationOutput;
    rentalPlans: PaginatedRentalPlans;
    productContentSuggestion?: Maybe<ProductContentSuggestion>;
    catalogGrouping?: Maybe<CatalogGrouping>;
    catalogGroupings: Array<CatalogGrouping>;
    invoice?: Maybe<Invoice>;
    invoices?: Maybe<InvoiceConnection>;
    invoiceEventPayload?: Maybe<Scalars['JSON']>;
    company: Company;
    companies: CompanyConnection;
    companyTypes: Array<CompanyType>;
    /** Recommendations associated with a SKU */
    recommendations: RecommendationResponse;
    deliveryProfiles: Array<DeliveryProfile>;
    matchProfile: DeliveryProfileRuleMatchResult;
    matchCarriers: ApplicableCarrierResultGraphql;
    bulkMatchProfile: Array<BulkDeliveryProfileRuleMatchResult>;
    deliveryRules: Array<DeliveryProfileRule>;
    dashboardGroupPayment?: Maybe<GroupPayment>;
    dashboardMixSubscription?: Maybe<DashboardMixSubscription>;
    favorites: Array<Product>;
    featuredPayment?: Maybe<PaymentsSummaryByStateUnion>;
    legacySubscriptions?: Maybe<SubscriptionDetailsConnection>;
    order?: Maybe<CartOrder>;
    partners: Array<Partner>;
    paymentGateways: Array<PaymentGateway>;
    paymentSummaries: PaymentsSummaryByStateUnionConnection;
    payments?: Maybe<PaymentsUnionConnection>;
    product?: Maybe<Product>;
    products?: Maybe<Array<Product>>;
    recentlyViewed: Array<Product>;
    store?: Maybe<Store>;
    storeCollection?: Maybe<StoreOffsetCollection>;
    stores?: Maybe<StoreConnection>;
    subscriptionCollection?: Maybe<SubscriptionListEntryConnection>;
    subscriptionDetails?: Maybe<FlexDetails>;
    subscriptionSummary: Array<SubscriptionSummary>;
    subscriptionsRequiringDecision: Array<SubscriptionDetails>;
    teams: Array<Team>;
    user?: Maybe<User>;
    userExists: Scalars['Boolean'];
    users?: Maybe<UserConnection>;
    verification?: Maybe<Verification>;
    verifications?: Maybe<VerificationConnection>;
    header: Header;
    search: HeaderSearchResult;
    assets: AssetCollection;
    assetSerialNumber: SerialNumberLookup;
    assetSuppliers: Array<Scalars['String']>;
    assetCapitalSources: Array<Scalars['String']>;
    assetWarehouseCodes: Array<Scalars['String']>;
    availabilityCampaigns: AvailabilityCampaignCollection;
    reservations: ReservationCollection;
    stocks: Array<Stock>;
    stockCollection: StockCollection;
    assetAllocations: Array<AssetAllocation>;
    assetAllocationCollection: AssetAllocationCollection;
    stockVariantAvailability: StockVariantAvailabilityCollection;
    storeVariantAvailability: StoreVariantAvailabilityCollection;
    storeStockConfiguration: StoreStockConfigurationGraphql;
    distributionRules: DistributionRuleCollection;
    stockTransfers: StockTransferCollection;
    resource?: Maybe<Resource>;
    resources?: Maybe<ResourceConnection>;
    movement?: Maybe<Movement>;
    movements?: Maybe<MovementConnection>;
    transaction?: Maybe<Transaction>;
    transactions?: Maybe<TransactionConnection>;
    contract: Contract;
    contracts?: Maybe<ContractConnection>;
    contractsRequiringDecision: Array<Contract>;
    productVariantsCount: Array<ProductVariantCount>;
    validateMixProductSelection: ContractValidationResult;
    loyaltyActionList: Array<LoyaltyActionResultBooking>;
    loyaltyPredictedActionAmount: LoyaltyPredictedActionAmountResult;
    loyaltyCreditTransactionList: Array<LoyaltyCreditTransactionResult>;
    loyaltyBalance: LoyaltyBalanceResult;
    loyaltyShopProductList: Array<LoyaltyShopProductResult>;
    loyaltyShopPurchaseList: Array<LoyaltyShopPurchaseResult>;
    loyaltyAdminInfo: LoyaltyAdminPanelResult;
    loyaltyAdminTransactions: Array<LoyaltyAdminCreditTransactionResult>;
    loyaltyAdminRules: Array<LoyaltyAdminRuleResult>;
    loyaltyAdminPanelStatistic: LoyaltyAdminPanelStatisticResult;
    /** Returns marketing data for the user level */
    MarketingContent: Array<CardTypeUnionModel>;
    /** Returns a campaign by uuid */
    campaign: CampaignModel;
    /** Returns all campaigns, can be filtered and paginated (default limit 100) */
    campaignList: Array<CampaignModel>;
    /** Returns last 100 campaign dismisses */
    findAllDismisses: Array<DismissModel>;
    /** Returns last 100 finished campaigns */
    findAllFinished: Array<FinishedModel>;
    notificationSendResults: SendResultConnection;
    notificationSendResult?: Maybe<NotificationSendResult>;
    /** Returns all notification trigger filters */
    notificationTriggerFilters: Array<NotificationTriggerFilter>;
    /** Returns notification trigger filter by uuid */
    notificationTriggerFilter: NotificationTriggerFilter;
    /** @deprecated Use 'orderFulfillments' instead */
    orderFulfillmentCollection: OrderFulfillmentCollection;
    orderFulfillments: OrderFulfillmentCollection;
    orderFulfillmentsConnection: OrderFulfillmentConnection;
    orderFulfillmentsCount: Scalars['Float'];
    externalOrderFulfillmentConnection: ExternalOrderFulfillmentConnection;
    externalOrderFulfillment?: Maybe<ExternalOrderFulfillmentUnion>;
    getOrder?: Maybe<Order>;
    allPandas?: Maybe<Array<Maybe<Panda>>>;
    brokenPanda?: Maybe<Array<Maybe<Panda>>>;
    throwPanda?: Maybe<Array<Maybe<Panda>>>;
    panda?: Maybe<Panda>;
    person?: Maybe<Person>;
    /** Returns list of settlements where Grover Card is available */
    settlements: Array<Settlement>;
    smartAgentCases: Array<SmartAgentCasesResult>;
    seizures: Array<SeizureResult>;
    pendingSCAChallenges: Array<ScaChallengesResult>;
    postBoxDocumentDownload: PostBoxDocumentDownloadResult;
    sddMandate: SddMandateResult;
    onboardingStatus: OnboardingStatusResult;
    sddMandateDownload: SddMandateDownloadResult;
    /** Returns additional person data, not bound to Solaris Bank person */
    metaPerson?: Maybe<MetaPersonResult>;
    tncDocuments: Array<TncDocumentResult>;
    cardInfoBoxes: Array<InfoBoxResult>;
    riskApproval: RiskApprovalResult;
    picardPersonByUserId?: Maybe<AdminPerson>;
    findVariantFlagById?: Maybe<VariantFlag>;
    findAllVariantFlags: VariantFlagPaginationOutput;
    findProductCampaignById?: Maybe<ProductCampaign>;
    findAllProductCampaigns: ProductCampaignPaginationOutput;
    findCountdownTimerById?: Maybe<CountdownTimer>;
    findAllCountdownTimers: CountdownTimerPaginationOutput;
    checkoutAttempt?: Maybe<NethoneOrder>;
    getVote: Vote;
    votes: VotesConnection;
    isUsersFirstPollVote: Scalars['Boolean'];
    poll: Poll;
    polls: Array<Poll>;
    pollCollection: PollsConnection;
    validateCode: ReferralCodeValidation;
    getConnectionDetails: GetConnectionDetailsResponse;
    getCurrentReferralCampaign: ReferralCampaign;
    searchProducts: ProductsQuery;
    shipments: ShipmentCollection;
    userShippingSummary: UserShippingSummaryType;
    shippingRequests: ShippingRequestCollection;
    packageItems: PackageItemCollection;
    shippingAddresses: ShippingAddressCollection;
    shippingProfile: Scalars['String'];
    shippingProfileRules: ShippingProfileRulesCollection;
    termsAndConditions?: Maybe<TermsAndConditions>;
    termsAndConditionsLocales?: Maybe<TermsAndConditionsLocales>;
    latestTermsAndConditions?: Maybe<TermsAndConditions>;
    termsAndConditionsList?: Maybe<TermsAndConditionsConnection>;
    /**
     * Returns Wallet payment method. Method ID can be Wallet-issued ID, billing
     * account ID, Grover API ID or IXOPAY reference ID.
     */
    paymentMethod?: Maybe<PaymentMethod2>;
    /** Lists payment methods (legacy and Wallet) of a user identified by the request token or by the provided user ID. */
    paymentMethods: Array<PaymentMethod2>;
    /** Returns payment options (payment method types) available to the current user. */
    paymentOptions: Array<PaymentOption>;
    productsWidget?: Maybe<ProductsWidget>;
    productsWidgets: ProductsWidgetPaginationOutput;
    B2BDashboardAccessRight: DashboardAccessRights;
    workAssets: WorkAssetsConnection;
    workAsset?: Maybe<WorkAsset>;
    workEmployees: WorkEmployeeConnection;
    workEmployee?: Maybe<WorkEmployee>;
    /** Finds the employees that match by name, phone or email */
    workEmployeesByPersonalInfo: Array<WorkEmployee>;
    workAddress?: Maybe<Address>;
};

export type QueryFetchAddonsArgs = {
    sku: Scalars['String'];
    store: Scalars['String'];
    committedMonths: Scalars['Int'];
};

export type QueryFetchAddonAssociationsArgs = {
    input: FetchAddonAssociationsInput;
};

export type QueryFetchAddonAssociationArgs = {
    id: Scalars['ID'];
};

export type QueryFetchAddonProvidersArgs = {
    associationId?: Maybe<Scalars['ID']>;
};

export type QueryDigitalOtpVariantByIdArgs = {
    variantId: Scalars['ID'];
};

export type QueryDigitalOtpProductsArgs = {
    input?: Maybe<FetchDigitalOtpProductsInput>;
};

export type QueryDigitalOtpProductBySlugArgs = {
    input: DigitalOtpProductBySlugInput;
};

export type QueryDigitalOtpVariantsArgs = {
    digitalOtpProductId: Scalars['String'];
};

export type QueryDigitalProductLocalizationsArgs = {
    digitalOtpProductId: Scalars['String'];
};

export type QueryPaymentGroupsArgs = {
    filter: GraphQlFilterPaymentGroups;
};

export type QueryPaymentGroupArgs = {
    uuid: Scalars['String'];
};

export type QueryWalletArgs = {
    uuid: Scalars['String'];
};

export type QueryWalletsArgs = {
    filter: GraphQlFilter;
};

export type QueryWalletByUserIdArgs = {
    userId: Scalars['String'];
};

export type QueryGetAllPaymentsArgs = {
    filter: GetPaymentOrdersFilters;
    limit: Scalars['Int'];
    offset: Scalars['Int'];
};

export type QueryGetPaymentByIdArgs = {
    id: Scalars['String'];
};

export type QueryGetAllPaymentStatsArgs = {
    filter: GetPaymentOrdersFilters;
};

export type QueryGetUserPaymentStatsArgs = {
    filter: GetUserPaymentOrdersFilters;
};

export type QueryGetPaymentOrderHistoryArgs = {
    contractId: Scalars['String'];
};

export type QueryRecurrentPaymentArgs = {
    uuid: Scalars['String'];
};

export type QueryRecurrentPaymentsArgs = {
    filter: GraphQlFilter;
};

export type QueryContractRecurrentPaymentArgs = {
    externalContract: Scalars['String'];
};

export type QueryContractPaymentGroupsArgs = {
    filter: ContractPaymentGroupFilterInput;
};

export type QueryBundleArgs = {
    where: BundleWhereUniqueInput;
};

export type QueryBundlesArgs = {
    cursor?: Maybe<BundleWhereUniqueInput>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    orderBy?: Maybe<BundleOrderByInput>;
    where?: Maybe<BundleWhereInput>;
};

export type QueryKitArgs = {
    uuid: Scalars['String'];
};

export type QueryKitsArgs = {
    paginationInput: RelayPaginationInput;
    sortInput?: Maybe<KitSortInput>;
    filterInput?: Maybe<KitsFilterInput>;
};

export type QueryCartWithKitArgs = {
    uuid: Scalars['String'];
};

export type QueryCartWithKitByOrderIdArgs = {
    orderId: Scalars['String'];
};

export type QueryGetActiveCampaignsForUserArgs = {
    userId: Scalars['String'];
};

export type QueryGetCampaignsForUserArgs = {
    userId: Scalars['String'];
};

export type QueryGetCartByIdArgs = {
    cartId: Scalars['ID'];
};

export type QueryGetCartByUserArgs = {
    userIdentifier: Scalars['String'];
};

export type QueryGetCartPricesArgs = {
    cartId: Scalars['ID'];
    userBillingAddressId: Scalars['String'];
};

export type QueryRetailEmployeeApprovalStatusArgs = {
    input?: Maybe<CheckEmployeeApprovalInput>;
};

export type QueryCartArgs = {
    orderNumber?: Maybe<Scalars['ID']>;
};

export type QueryOrderOfferArgs = {
    id?: Maybe<Scalars['Int']>;
};

export type QueryOrderOfferByOrderArgs = {
    orderNumber?: Maybe<Scalars['ID']>;
};

export type QueryOrderOffersPaginatedArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    filters?: Maybe<OrderOfferFiltersInput>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

export type QueryTasksArgs = {
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    where: TasksWhereInput;
};

export type QueryTaskArgs = {
    id: Scalars['ID'];
};

export type QueryProductFilterArgs = {
    id: Scalars['Int'];
};

export type QueryProductFiltersArgs = {
    where?: Maybe<ProductFilterWhereInput>;
    orderBy?: Maybe<ProductFilterOrderByInput>;
};

export type QueryCategoriesArgs = {
    storeCode?: Maybe<Scalars['String']>;
    parentId?: Maybe<Scalars['ID']>;
};

export type QueryCategoryArgs = {
    id?: Maybe<Scalars['ID']>;
    permalink?: Maybe<Scalars['String']>;
};

export type QueryCategoriesPerStoreArgs = {
    storeCode: Scalars['String'];
};

export type QueryEnergyLabelArgs = {
    id: Scalars['Int'];
};

export type QueryEnergyLabelsArgs = {
    where?: Maybe<EnergyLabelWhereInput>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    orderBy?: Maybe<EnergyLabelOrderByInput>;
};

export type QueryRentalPlansArgs = {
    where?: Maybe<RentalPlanWhereInput>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
};

export type QueryProductContentSuggestionArgs = {
    sku: Scalars['String'];
};

export type QueryCatalogGroupingArgs = {
    name: Scalars['String'];
};

export type QueryInvoiceArgs = {
    id: Scalars['String'];
};

export type QueryInvoicesArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    filter?: Maybe<InvoicesFilterInput>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

export type QueryInvoiceEventPayloadArgs = {
    id: Scalars['String'];
    invoiceType?: Maybe<InvoiceEventType>;
};

export type QueryCompanyArgs = {
    id?: Maybe<Scalars['ID']>;
    viban?: Maybe<Scalars['String']>;
};

export type QueryCompaniesArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<CompaniesWhereInput>;
};

export type QueryCompanyTypesArgs = {
    country: Scalars['String'];
};

export type QueryRecommendationsArgs = {
    sku: Scalars['String'];
};

export type QueryDeliveryProfilesArgs = {
    filter?: ProfileFilterInput;
    offset?: Maybe<Scalars['Float']>;
    limit?: Maybe<Scalars['Float']>;
};

export type QueryMatchProfileArgs = {
    filter: DeliveryProfileMatchInput;
};

export type QueryMatchCarriersArgs = {
    filter: DeliveryProfileMatchInput;
};

export type QueryBulkMatchProfileArgs = {
    filters: Array<BulkDeliveryProfileRuleMatchInput>;
};

export type QueryDeliveryRulesArgs = {
    filter?: DeliveryRuleFilterInput;
    offset?: Maybe<Scalars['Float']>;
    limit?: Maybe<Scalars['Float']>;
};

export type QueryDashboardGroupPaymentArgs = {
    id: Scalars['ID'];
};

export type QueryFeaturedPaymentArgs = {
    input: FeaturedPaymentInput;
};

export type QueryLegacySubscriptionsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    filter?: Maybe<SubscriptionFilterInput>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

export type QueryOrderArgs = {
    orderNumber: Scalars['String'];
};

export type QueryPartnersArgs = {
    where?: Maybe<PartnerWhereInput>;
};

export type QueryPaymentSummariesArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    input: FeaturedPaymentInput;
    last?: Maybe<Scalars['Int']>;
};

export type QueryPaymentsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    subscriptionId?: Maybe<Scalars['ID']>;
    where?: Maybe<PaymentsWhereInput>;
};

export type QueryProductArgs = {
    id?: Maybe<Scalars['ID']>;
    where?: Maybe<ProductWhereInput>;
};

export type QueryProductsArgs = {
    skus?: Maybe<Array<Scalars['String']>>;
    where?: Maybe<ProductsWhereInput>;
    withCategory?: Maybe<Scalars['Boolean']>;
};

export type QueryRecentlyViewedArgs = {
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type QueryStoreArgs = {
    id?: Maybe<Scalars['ID']>;
    storeCode?: Maybe<Scalars['String']>;
};

export type QueryStoreCollectionArgs = {
    filter?: Maybe<StoresFilterInput>;
    pagination?: Maybe<OffsetPaginationInput>;
};

export type QueryStoresArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    enabledOnFooter?: Maybe<Scalars['Boolean']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    storeType?: Maybe<StoreTypes>;
};

export type QuerySubscriptionCollectionArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    filter: SubscriptionListFilterInput;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    searchTerm?: Maybe<Scalars['String']>;
    userId?: Maybe<Scalars['Int']>;
};

export type QuerySubscriptionDetailsArgs = {
    id?: Maybe<Scalars['ID']>;
    number?: Maybe<Scalars['String']>;
};

export type QuerySubscriptionsRequiringDecisionArgs = {
    version: Scalars['ID'];
};

export type QueryUserArgs = {
    id?: Maybe<Scalars['ID']>;
};

export type QueryUserExistsArgs = {
    email: Scalars['String'];
};

export type QueryUsersArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<UsersWhereInput>;
};

export type QueryVerificationArgs = {
    id: Scalars['ID'];
};

export type QueryVerificationsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    filter?: Maybe<VerificationsFilterInput>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

export type QueryHeaderArgs = {
    consumer: HeaderConsumer;
    ssr?: Maybe<Scalars['Boolean']>;
};

export type QuerySearchArgs = {
    consumer: HeaderConsumer;
    q: Scalars['String'];
};

export type QueryAssetsArgs = {
    search?: Maybe<Scalars['String']>;
    offset?: Maybe<Scalars['Float']>;
    limit?: Maybe<Scalars['Float']>;
    filter?: Maybe<AssetFilterInput>;
};

export type QueryAssetSerialNumberArgs = {
    serialNumber: Scalars['String'];
    sku?: Maybe<Scalars['String']>;
};

export type QueryAvailabilityCampaignsArgs = {
    offset?: Maybe<Scalars['Float']>;
    limit?: Maybe<Scalars['Float']>;
    filter?: Maybe<AvailabilityCampaignFilterInput>;
};

export type QueryReservationsArgs = {
    offset?: Maybe<Scalars['Float']>;
    limit?: Maybe<Scalars['Float']>;
    filter?: Maybe<ReservationFilterInput>;
};

export type QueryStocksArgs = {
    offset?: Maybe<Scalars['Float']>;
    limit?: Maybe<Scalars['Float']>;
    name?: Maybe<Scalars['String']>;
    uid?: Maybe<Scalars['String']>;
};

export type QueryStockCollectionArgs = {
    offset?: Maybe<Scalars['Float']>;
    limit?: Maybe<Scalars['Float']>;
    filter?: Maybe<StockFilterInput>;
};

export type QueryAssetAllocationsArgs = {
    limit?: Maybe<Scalars['Float']>;
    filter?: Maybe<AssetAllocationFilterInput>;
};

export type QueryAssetAllocationCollectionArgs = {
    offset?: Maybe<Scalars['Float']>;
    limit?: Maybe<Scalars['Float']>;
    filter?: Maybe<AssetAllocationFilterInput>;
};

export type QueryStockVariantAvailabilityArgs = {
    offset?: Maybe<Scalars['Float']>;
    limit?: Maybe<Scalars['Float']>;
    filter?: Maybe<StockVariantAvailabilityFilterInput>;
};

export type QueryStoreVariantAvailabilityArgs = {
    offset?: Maybe<Scalars['Float']>;
    limit?: Maybe<Scalars['Float']>;
    filter?: Maybe<StoreVariantAvailabilityFilterInput>;
};

export type QueryDistributionRulesArgs = {
    offset?: Maybe<Scalars['Float']>;
    limit?: Maybe<Scalars['Float']>;
    filter?: Maybe<DistributionRuleFilterInput>;
};

export type QueryStockTransfersArgs = {
    offset?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    filter?: Maybe<StockTransferFilterInput>;
};

export type QueryResourceArgs = {
    id: Scalars['ID'];
};

export type QueryResourcesArgs = {
    first?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['ID']>;
};

export type QueryMovementArgs = {
    id: Scalars['ID'];
};

export type QueryMovementsArgs = {
    first?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['ID']>;
};

export type QueryTransactionArgs = {
    id: Scalars['ID'];
};

export type QueryTransactionsArgs = {
    first?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['ID']>;
};

export type QueryContractArgs = {
    id: Scalars['ID'];
};

export type QueryContractsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    filter?: Maybe<ContractFilterInput>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

export type QueryContractsRequiringDecisionArgs = {
    version: Scalars['ID'];
};

export type QueryProductVariantsCountArgs = {
    filter: ProductVariantsCountInput;
};

export type QueryValidateMixProductSelectionArgs = {
    products: Array<ProductVariantInput>;
};

export type QueryLoyaltyPredictedActionAmountArgs = {
    reservation: LoyaltyReservationInfo;
};

export type QueryLoyaltyAdminInfoArgs = {
    userId: Scalars['String'];
};

export type QueryLoyaltyAdminTransactionsArgs = {
    filterInfo: LoyaltyFilterOptionsInfo;
};

export type QueryLoyaltyAdminRulesArgs = {
    filterInfo: LoyaltyFilterOptionsInfo;
};

export type QueryLoyaltyAdminPanelStatisticArgs = {
    userId?: Maybe<Scalars['String']>;
    startDate: Scalars['String'];
};

export type QueryMarketingContentArgs = {
    platform?: Maybe<PlatformTypes>;
    page?: Maybe<Scalars['String']>;
};

export type QueryCampaignArgs = {
    campaignUuid: Scalars['String'];
};

export type QueryCampaignListArgs = {
    pagination?: Maybe<PaginationInputType>;
    filter?: Maybe<CampaignFilterInputType>;
};

export type QueryNotificationSendResultsArgs = {
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<SendResultWhereInput>;
};

export type QueryNotificationSendResultArgs = {
    id: Scalars['ID'];
};

export type QueryNotificationTriggerFiltersArgs = {
    filter?: Maybe<NotificationTriggerFilterListInput>;
};

export type QueryNotificationTriggerFilterArgs = {
    uuid: Scalars['ID'];
};

export type QueryOrderFulfillmentCollectionArgs = {
    filter?: Maybe<FindAndCountInput>;
    offset?: Maybe<Scalars['Float']>;
    limit?: Maybe<Scalars['Float']>;
};

export type QueryOrderFulfillmentsArgs = {
    filter?: Maybe<OrderFulfillmentFilterInput>;
    offset?: Maybe<Scalars['Float']>;
    limit?: Maybe<Scalars['Float']>;
    sort?: Maybe<Array<ColumnSortType>>;
    search?: Maybe<Scalars['String']>;
};

export type QueryOrderFulfillmentsConnectionArgs = {
    filter?: Maybe<OrderFulfillmentFilterInput>;
    after?: Maybe<CursorFilterType>;
    before?: Maybe<CursorFilterType>;
    limit?: Maybe<Scalars['Float']>;
};

export type QueryOrderFulfillmentsCountArgs = {
    filter?: Maybe<OrderFulfillmentFilterInput>;
};

export type QueryExternalOrderFulfillmentConnectionArgs = {
    paginationInput?: Maybe<RelayPaginationInput>;
    filterInput?: Maybe<ExternalOrderFulfillmentFilterInput>;
    modifier?: Maybe<ExternalOrderFulfillmentFilterModifier>;
};

export type QueryExternalOrderFulfillmentArgs = {
    uuid?: Maybe<Scalars['String']>;
    orderNumber?: Maybe<Scalars['String']>;
};

export type QueryGetOrderArgs = {
    orderNumber: Scalars['String'];
};

export type QueryPandaArgs = {
    name: Scalars['ID'];
};

export type QuerySettlementsArgs = {
    country: Scalars['String'];
};

export type QuerySmartAgentCasesArgs = {
    info: GetSmartAgentCasesInfo;
};

export type QueryPendingScaChallengesArgs = {
    info: GetPendingScaChallengesInfo;
};

export type QueryPostBoxDocumentDownloadArgs = {
    info: PostBoxDocumentDownloadInfo;
};

export type QuerySddMandateArgs = {
    store: Scalars['String'];
};

export type QuerySddMandateDownloadArgs = {
    info: SddMandateDownloadInfo;
};

export type QueryCardInfoBoxesArgs = {
    info?: Maybe<InfoBoxInfo>;
};

export type QueryRiskApprovalArgs = {
    info: RiskApprovalInfo;
};

export type QueryPicardPersonByUserIdArgs = {
    userId: Scalars['String'];
};

export type QueryFindVariantFlagByIdArgs = {
    id: Scalars['ID'];
};

export type QueryFindAllVariantFlagsArgs = {
    orderBy?: Maybe<VariantFlagOrderByInput>;
    offset?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    where?: Maybe<VariantFlagWhereInput>;
};

export type QueryFindProductCampaignByIdArgs = {
    id: Scalars['ID'];
};

export type QueryFindAllProductCampaignsArgs = {
    orderBy?: Maybe<ProductCampaignOrderByInput>;
    offset?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    where?: Maybe<ProductCampaignWhereInput>;
};

export type QueryFindCountdownTimerByIdArgs = {
    id: Scalars['ID'];
};

export type QueryFindAllCountdownTimersArgs = {
    orderBy?: Maybe<CountdownTimerOrderByInput>;
    offset?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    where?: Maybe<CountdownTimerWhereInput>;
};

export type QueryCheckoutAttemptArgs = {
    reference: Scalars['String'];
};

export type QueryGetVoteArgs = {
    ticket: Scalars['String'];
};

export type QueryVotesArgs = {
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    order?: Maybe<VoteOrderInput>;
    where?: Maybe<VoteWhereInput>;
};

export type QueryIsUsersFirstPollVoteArgs = {
    pollSlug: Scalars['String'];
};

export type QueryPollArgs = {
    slug: Scalars['String'];
};

export type QueryPollsArgs = {
    pagination?: Maybe<PaginationInput>;
    filter?: Maybe<PollsFilterInput>;
};

export type QueryPollCollectionArgs = {
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    order?: Maybe<PollOrderInput>;
    filter?: Maybe<PollsFilterInput>;
};

export type QueryValidateCodeArgs = {
    input: ReferralCodeValidationInput;
};

export type QuerySearchProductsArgs = {
    query?: Maybe<Scalars['String']>;
    filters?: Maybe<FilterArgs>;
    page?: Maybe<Scalars['Int']>;
    resultsPerPage?: Maybe<Scalars['Int']>;
    sort?: Maybe<Sort>;
};

export type QueryShipmentsArgs = {
    sort?: Maybe<Array<ColumnOrderType>>;
    offset?: Maybe<Scalars['Float']>;
    limit?: Maybe<Scalars['Float']>;
    search?: Maybe<Scalars['String']>;
    filter?: Maybe<Array<ShipmentFilterType>>;
};

export type QueryUserShippingSummaryArgs = {
    userId: Scalars['String'];
};

export type QueryShippingRequestsArgs = {
    search?: Maybe<Scalars['String']>;
    offset?: Maybe<Scalars['Float']>;
    limit?: Maybe<Scalars['Float']>;
    filter?: Maybe<ShippingRequestFilterType>;
};

export type QueryPackageItemsArgs = {
    offset?: Maybe<Scalars['Float']>;
    limit?: Maybe<Scalars['Float']>;
    filter?: Maybe<PackageItemFilter>;
};

export type QueryShippingAddressesArgs = {
    offset?: Maybe<Scalars['Float']>;
    limit?: Maybe<Scalars['Float']>;
    filter?: Maybe<ShippingAddressFilterType>;
};

export type QueryShippingProfileArgs = {
    filter: ShippingProfileFilterType;
};

export type QueryShippingProfileRulesArgs = {
    sort?: Maybe<Array<ColumnOrderType>>;
    offset?: Maybe<Scalars['Float']>;
    limit?: Maybe<Scalars['Float']>;
    filter?: Maybe<Array<ShippingProfileRuleFilterType>>;
};

export type QueryTermsAndConditionsArgs = {
    version: Scalars['ID'];
};

export type QueryTermsAndConditionsLocalesArgs = {
    version: Scalars['ID'];
};

export type QueryLatestTermsAndConditionsArgs = {
    storeCode: Scalars['String'];
};

export type QueryTermsAndConditionsListArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    filter?: Maybe<TermsAndConditionsFilterInput>;
};

export type QueryPaymentMethodArgs = {
    id: Scalars['ID'];
    userId?: Maybe<Scalars['String']>;
    includeDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryPaymentMethodsArgs = {
    filter?: Maybe<PaymentMethodsFilterType>;
};

export type QueryProductsWidgetArgs = {
    id: Scalars['Int'];
};

export type QueryProductsWidgetsArgs = {
    where?: Maybe<ProductsWidgetWhereInput>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    orderBy?: Maybe<ProductsWidgetOrderByInput>;
};

export type QueryB2BDashboardAccessRightArgs = {
    companyId: Scalars['Int'];
};

export type QueryWorkAssetsArgs = {
    paginationInput: WorkPaginationInput;
    sortInput?: Maybe<WorkAssetSortInput>;
    filterInput?: Maybe<WorkAssetsFilterInput>;
};

export type QueryWorkAssetArgs = {
    uuid: Scalars['String'];
};

export type QueryWorkEmployeesArgs = {
    paginationInput: WorkPaginationInput;
    sortInput?: Maybe<WorkEmployeeSortInput>;
    filterInput?: Maybe<WorkEmployeesFilterInput>;
};

export type QueryWorkEmployeeArgs = {
    uuid: Scalars['String'];
};

export type QueryWorkEmployeesByPersonalInfoArgs = {
    needle: Scalars['String'];
    limit?: Maybe<Scalars['Int']>;
};

export type QueryWorkAddressArgs = {
    uuid: Scalars['ID'];
};

export type RangeFacet = Facet & {
    __typename?: 'RangeFacet';
    name: Scalars['String'];
    min: Scalars['Int'];
    max: Scalars['Int'];
};

export type RangeFilter = {
    __typename?: 'RangeFilter';
    id: Scalars['ID'];
    name: Scalars['String'];
    position?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
    type: ProductFilterType;
    category?: Maybe<Category>;
    storeCodes: Array<Scalars['String']>;
    rangeFilterConfig: RangeFilterConfig;
};

export type RangeFilterConfig = {
    __typename?: 'RangeFilterConfig';
    unit?: Maybe<Scalars['String']>;
    bucketSize?: Maybe<Scalars['Float']>;
    bucketMin?: Maybe<Scalars['Float']>;
    bucketMax?: Maybe<Scalars['Float']>;
};

export type RangeFilterInput = {
    min?: Maybe<Scalars['Int']>;
    max?: Maybe<Scalars['Int']>;
};

export type RangeFilterOrError = RangeFilter | ProductFilterError;

/** Autogenerated input type of ReactivateContract */
export type ReactivateContractInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    comment?: Maybe<Scalars['String']>;
    contractId: Scalars['ID'];
};

/** Autogenerated return type of ReactivateContract. */
export type ReactivateContractPayload = {
    __typename?: 'ReactivateContractPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract: Contract;
};

/** Autogenerated input type of ReallocateAsset */
export type ReallocateAssetInput = {
    assetUid: Scalars['ID'];
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contractId: Scalars['ID'];
};

/** Autogenerated return type of ReallocateAsset. */
export type ReallocateAssetPayload = {
    __typename?: 'ReallocateAssetPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract?: Maybe<Contract>;
    errors?: Maybe<Array<Scalars['String']>>;
};

export type ReallocationResult = {
    __typename?: 'ReallocationResult';
    message: Scalars['String'];
    orderNumber: Scalars['String'];
    updatedAsset: Scalars['String'];
    replacedAllocationUid: Scalars['String'];
    reservationUid: Scalars['String'];
    replacementStatus: Scalars['String'];
    replacementReason: Scalars['String'];
};

export type Reason = {
    __typename?: 'Reason';
    name: Scalars['String'];
    value: Scalars['String'];
};

export type Recommendation = {
    __typename?: 'Recommendation';
    productSku: Scalars['String'];
    modelRank: Scalars['Int'];
    rank: Scalars['Int'];
    modelProbability: Scalars['Float'];
    categoryName: Scalars['String'];
    subcategoryName: Scalars['String'];
    isRecommendedByModel: Scalars['Boolean'];
};

export type RecommendationResponse = {
    __typename?: 'RecommendationResponse';
    modelType: Scalars['String'];
    modelVersion: Scalars['String'];
    recommendations: Array<Recommendation>;
    tracingId: Scalars['String'];
};

export type RecurrentPaymentModel = {
    __typename?: 'RecurrentPaymentModel';
    uuid: Scalars['ID'];
    externalCustomer: Scalars['String'];
    scopeUuid?: Maybe<Scalars['String']>;
    type: Scalars['String'];
    status: RecurrentPaymentStatusTypes;
    additionalData: RecurrentType;
    paymentGroups: Array<PaymentGroupModel>;
};

export enum RecurrentPaymentStatusTypes {
    active = 'active',
    failed = 'failed',
    paused = 'paused',
    finished = 'finished',
    cancelled = 'cancelled',
}

export type RecurrentType = SimpleSubscriptionModel | LoopSimulatorModel;

export type ReferenceAccountInfo = {
    iban: Scalars['String'];
};

export type ReferenceAccountResult = {
    __typename?: 'ReferenceAccountResult';
    name: Scalars['String'];
    iban: Scalars['String'];
    id: Scalars['String'];
};

export type ReferralCampaign = {
    __typename?: 'ReferralCampaign';
    hostCashAmount: Scalars['Int'];
    guestCashAmount: Scalars['Int'];
    defaultStoreCode: Scalars['String'];
};

export type ReferralCodeValidation = {
    __typename?: 'ReferralCodeValidation';
    defaultStoreCode?: Maybe<Scalars['String']>;
    hostFirstName: Scalars['String'];
    isValid: Scalars['Boolean'];
    isReferralProgramUpdatesEnabled: Scalars['Boolean'];
};

export type ReferralCodeValidationInput = {
    code: Scalars['String'];
};

/** Referral service create connection response codes. */
export enum ReferralCreateConnectionResponse {
    HasContractsOrSubscriptions = 'HasContractsOrSubscriptions',
    IsNotEligible = 'IsNotEligible',
    DifferentStoreCodes = 'DifferentStoreCodes',
    AlreadyHasConnection = 'AlreadyHasConnection',
    OwnCode = 'OwnCode',
    CreatedSuccessfully = 'CreatedSuccessfully',
    HostLimitExceeded = 'HostLimitExceeded',
}

export enum RefundReason {
    failedDelivery = 'failedDelivery',
    revocation = 'revocation',
    doubleCharges = 'doubleCharges',
    voucherDiscountNotApplied = 'voucherDiscountNotApplied',
    agreementWithCustomer = 'agreementWithCustomer',
    returnProcess = 'returnProcess',
    assetNotAvailable = 'assetNotAvailable',
    lateDelivery = 'lateDelivery',
    assetCondition = 'assetCondition',
    contractCanceled = 'contractCanceled',
    freeShipment = 'freeShipment',
    priceChanged = 'priceChanged',
}

export type RegisterCardPersonInfo = {
    email: Scalars['String'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    birthdate: Scalars['DateTime'];
    acceptedTncAt: Scalars['DateTime'];
    addressLine1?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
    streetNumber?: Maybe<Scalars['String']>;
    city: Scalars['String'];
    birthSettlement: Scalars['String'];
    birthCountry: Scalars['String'];
    postalCode: Scalars['String'];
    employmentStatus: EmploymentStatus;
    nationality: Scalars['String'];
    addressAdditionalInfo?: Maybe<Scalars['String']>;
    skipSmartyStreets?: Maybe<Scalars['Boolean']>;
};

export type RegisterCardPersonResult = {
    __typename?: 'RegisterCardPersonResult';
    id: Scalars['String'];
};

export type RegisterIncomingAssetsResult = {
    __typename?: 'RegisterIncomingAssetsResult';
    message: Scalars['String'];
    error: Scalars['Boolean'];
};

export type RegisterMobilePhoneInfo = {
    /** Country code of mobile number */
    countryCode?: Maybe<Scalars['String']>;
    /** Body of mobile number */
    number?: Maybe<Scalars['String']>;
    /** Public part of ECDSA-P256 keypair that will be used for signature verification of CRPs later on */
    devicePublicKey: Scalars['String'];
    /** Label of the device i.e. `My iPhone 12` */
    deviceName: Scalars['String'];
    keyPurpose?: Maybe<KeyPurpose>;
};

export type RegisterMobilePhoneResult = {
    __typename?: 'RegisterMobilePhoneResult';
    deviceId: Scalars['String'];
};

export type RelayPaginationInput = {
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    /** Is optional, if provided following validation is applied: min 1 and max 1000 */
    first?: Maybe<Scalars['Int']>;
    /** Is optional, if provided following validation is applied: min 1 and max 1000 */
    last?: Maybe<Scalars['Int']>;
};

export type RemoveAddonAssociationsInput = {
    ids: Array<Scalars['Int']>;
};

export type RemoveAddonAssociationsResult = {
    __typename?: 'RemoveAddonAssociationsResult';
    count: Scalars['Int'];
};

export type RemoveBackgroundBatchResult = {
    __typename?: 'RemoveBackgroundBatchResult';
    originalUrl: Scalars['String'];
    newUrl: Scalars['String'];
};

export type RemoveDigitalOfferingInput = {
    mainProductSku?: Maybe<Scalars['String']>;
    storeCode?: Maybe<Scalars['String']>;
    digitalProductSku?: Maybe<Scalars['String']>;
};

export type RemoveDigitalOfferingResult = {
    __typename?: 'RemoveDigitalOfferingResult';
    count: Scalars['Int'];
};

export type RemoveDigitalOtpProductInput = {
    id: Scalars['String'];
};

export type RemoveDigitalOtpProductResult = {
    __typename?: 'RemoveDigitalOtpProductResult';
    id: Scalars['String'];
};

export type RemoveDigitalOtpVariantInput = {
    id: Scalars['String'];
};

export type RemoveDigitalOtpVariantResult = {
    __typename?: 'RemoveDigitalOtpVariantResult';
    id: Scalars['String'];
};

export type RemoveDigitalProductLocalizationInput = {
    id: Scalars['String'];
};

export type RemoveDigitalProductLocalizationResult = {
    __typename?: 'RemoveDigitalProductLocalizationResult';
    id: Scalars['String'];
};

/** Autogenerated input type of RemoveDiscount */
export type RemoveDiscountInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contractId: Scalars['ID'];
    discountId: Scalars['ID'];
};

/** Autogenerated return type of RemoveDiscount. */
export type RemoveDiscountPayload = {
    __typename?: 'RemoveDiscountPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract: Contract;
};

/** Autogenerated input type of RemoveFromFavorites */
export type RemoveFromFavoritesInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    sku: Scalars['String'];
};

/** Autogenerated return type of RemoveFromFavorites. */
export type RemoveFromFavoritesPayload = {
    __typename?: 'RemoveFromFavoritesPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    favorites: Array<Product>;
};

export type RentalPlan = {
    __typename?: 'RentalPlan';
    id: Scalars['ID'];
    price: Price;
    oldPrice?: Maybe<Price>;
    length: Duration;
    discount?: Maybe<RentalPlanDiscount>;
    groverCarePrices: Array<RentalPlanGroverCarePrice>;
    /** @deprecated Don't use! It's for catalog proposal and will be removed soon. */
    categoryId: Scalars['String'];
};

export type RentalPlanDeleteWhereInput = {
    storeCode: Scalars['String'];
    resourceSku: Scalars['String'];
};

export type RentalPlanDiscount = {
    __typename?: 'RentalPlanDiscount';
    rentalPlanId: Scalars['Int'];
    percentage: Scalars['Float'];
    rentalPlan?: Maybe<RentalPlan>;
};

export type RentalPlanExportTask = Task & {
    __typename?: 'RentalPlanExportTask';
    id: Scalars['ID'];
    createdAt: Scalars['DateTime'];
    status: TaskStatus;
    createdByUserId: Scalars['Int'];
    createdBy?: Maybe<User>;
    fileUri?: Maybe<Scalars['String']>;
    fileName: Scalars['String'];
};

export type RentalPlanExportWhereInput = {
    and?: Maybe<Array<RentalPlanExportWhereInput>>;
    storeCode?: Maybe<StoreCodeComparatorInput>;
    resourceSkus?: Maybe<SkuComparatorInput>;
};

export type RentalPlanGroverCarePrice = {
    __typename?: 'RentalPlanGroverCarePrice';
    price: Price;
    coverage: Scalars['Int'];
};

export type RentalPlanImportInput = {
    fileUri: Scalars['String'];
    bucketName: Scalars['String'];
};

export type RentalPlanParsedXlsx = {
    __typename?: 'RentalPlanParsedXlsx';
    terms: Array<RentalPlanTermXlsx>;
    currency: Scalars['String'];
    storeCode: Scalars['String'];
    resourceSku: Scalars['String'];
    priceChangeReason?: Maybe<Scalars['String']>;
    priceChangeTag?: Maybe<Scalars['String']>;
};

export type RentalPlanTerm = {
    __typename?: 'RentalPlanTerm';
    active: Scalars['Boolean'];
    minimumTermMonths: Scalars['Int'];
    priceInCents: Scalars['Int'];
    compareAtPriceInCents?: Maybe<Scalars['Int']>;
    discount?: Maybe<Scalars['Int']>;
    createdAt?: Maybe<Scalars['DateTime']>;
};

export type RentalPlanTermInput = {
    priceInCents: Scalars['Int'];
    compareAtPriceInCents?: Maybe<Scalars['Int']>;
    minimumTermMonths: Scalars['Int'];
};

export type RentalPlanTermXlsx = {
    __typename?: 'RentalPlanTermXlsx';
    minimumTermMonths: Scalars['Int'];
    priceInCents: Scalars['Int'];
    compareAtPriceInCents?: Maybe<Scalars['Int']>;
};

export type RentalPlanUpgradeProperties = {
    __typename?: 'RentalPlanUpgradeProperties';
    basePrice: Price;
    buyoutMonthsRequired: Scalars['Int'];
    buyoutPrice: Price;
    id: Scalars['ID'];
    minimumLength: Duration;
    oldPrice?: Maybe<Price>;
    price: Price;
};

export type RentalPlanUploadError = {
    message: Scalars['String'];
};

export type RentalPlanUploadFailedTask = Task & {
    __typename?: 'RentalPlanUploadFailedTask';
    id: Scalars['ID'];
    createdAt: Scalars['DateTime'];
    status: TaskStatus;
    createdByUserId: Scalars['Int'];
    createdBy?: Maybe<User>;
    scheduledFor?: Maybe<Scalars['DateTime']>;
    fileUri: Scalars['String'];
    fileName: Scalars['String'];
    errors: Array<RentalPlanUploadError>;
};

export type RentalPlanUploadTask = Task & {
    __typename?: 'RentalPlanUploadTask';
    id: Scalars['ID'];
    createdAt: Scalars['DateTime'];
    status: TaskStatus;
    createdByUserId: Scalars['Int'];
    createdBy?: Maybe<User>;
    scheduledFor?: Maybe<Scalars['DateTime']>;
    fileUri: Scalars['String'];
    fileName: Scalars['String'];
};

export type RentalPlanUploadTaskCreateInput = {
    scheduledFor?: Maybe<Scalars['DateTime']>;
    fileUri: Scalars['String'];
    fileName: Scalars['String'];
};

export type RentalPlanUploadTaskParserError = RentalPlanUploadError & {
    __typename?: 'RentalPlanUploadTaskParserError';
    message: Scalars['String'];
    code: Scalars['Int'];
};

export type RentalPlanUploadTaskRowError = RentalPlanUploadError & {
    __typename?: 'RentalPlanUploadTaskRowError';
    message: Scalars['String'];
    row: Scalars['String'];
};

export type RentalPlanUpsertInput = {
    currency: Scalars['String'];
    storeCode: Scalars['String'];
    resourceSku: Scalars['String'];
    terms: Array<RentalPlanTermInput>;
};

export type RentalPlanWhereInput = {
    and?: Maybe<Array<RentalPlanWhereInput>>;
    storeCode?: Maybe<StoreCodeComparatorInput>;
    resourceSkus?: Maybe<SkuComparatorInput>;
};

export type RentalPlanXlsxInput = {
    fileName: Scalars['String'];
    bucket: Scalars['String'];
};

export type RentalPlanXlsxResult = {
    __typename?: 'RentalPlanXlsxResult';
    rentalPlans: Array<RentalPlanParsedXlsx>;
};

export type RentalPlanXlsxResultOrError = ParseXlsxError | RentalPlanXlsxResult;

export type ReorderCardInfo = {
    cardId: Scalars['String'];
    shouldRetainPin?: Maybe<Scalars['Boolean']>;
    shouldOrderReplacement?: Maybe<Scalars['Boolean']>;
    reorderReason: CardReorderReason;
};

export type ReorderCardResult = {
    __typename?: 'ReorderCardResult';
    status: Scalars['Boolean'];
};

export type ReplacedLineItemInput = {
    deviceId: Scalars['String'];
    meta: ItemMetaInput;
};

export type ReplacedLineItemMeta = LineItemMeta & {
    __typename?: 'ReplacedLineItemMeta';
    product?: Maybe<Product>;
    category?: Maybe<LineItemCategory>;
    variant: Variant;
    deviceId?: Maybe<Scalars['ID']>;
    replacedItem?: Maybe<LineItemCatalogMeta>;
    groverCare?: Maybe<GroverCare>;
};

export type Replacement = Operation & {
    __typename?: 'Replacement';
    completedAt?: Maybe<Scalars['ISO8601DateTime']>;
    owner: User;
    startedAt: Scalars['ISO8601DateTime'];
    startedBy: User;
    state: ReplacementState;
    updates: Array<FlowUpdate>;
};

export type ReplacementModel = {
    __typename?: 'ReplacementModel';
    uid: Scalars['ID'];
    reason?: Maybe<ReplacementReason>;
    status?: Maybe<ReplacementStatus>;
    skuVariantCode?: Maybe<Scalars['String']>;
    orderNumber?: Maybe<Scalars['String']>;
    replacedOrderNumber?: Maybe<Scalars['String']>;
    customerId?: Maybe<Scalars['Float']>;
    storeId?: Maybe<Scalars['Float']>;
    storeUid?: Maybe<Scalars['String']>;
    assetUid?: Maybe<Scalars['String']>;
    allocationUid?: Maybe<Scalars['String']>;
    reservationUid?: Maybe<Scalars['String']>;
    replacedAllocationUid: Scalars['String'];
    updatedBy?: Maybe<Scalars['String']>;
    createdBy?: Maybe<Scalars['String']>;
    statusUpdatedAt?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    deletedAt?: Maybe<Scalars['DateTime']>;
};

export enum ReplacementReason {
    Incomplete = 'Incomplete',
    Doa = 'Doa',
    IssueAtWarehouse = 'IssueAtWarehouse',
    LimitedFunction = 'LimitedFunction',
    NotRefurbished = 'NotRefurbished',
    WrongAsset = 'WrongAsset',
    SoftwareIssue = 'SoftwareIssue',
    LockedAsset = 'LockedAsset',
    Lost = 'Lost',
    CosmeticWear = 'CosmeticWear',
    Damage = 'Damage',
    FailedToDeliver = 'FailedToDeliver',
}

export enum ReplacementState {
    COMPLETED = 'COMPLETED',
    NEW_DELIVERED = 'NEW_DELIVERED',
    OLD_RETURNED = 'OLD_RETURNED',
    STARTED = 'STARTED',
}

export enum ReplacementStatus {
    Created = 'Created',
    PendingAllocation = 'PendingAllocation',
    Allocated = 'Allocated',
    WmsProcessing = 'WmsProcessing',
    Shipped = 'Shipped',
    Delivered = 'Delivered',
    FailedDelivery = 'FailedDelivery',
    Cancelled = 'Cancelled',
}

/** Input for the replacePaymentMethod mutation. */
export type ReplacePaymentMethodInput = {
    /** Wallet ID of the method to be replaced. */
    oldPaymentMethodId: Scalars['ID'];
    /** Wallet ID of the replacement method. */
    newPaymentMethodId: Scalars['ID'];
    /** Whether the old method should be deleted after the operation. */
    deleteOld: Scalars['Boolean'];
};

export type ReportDamage = {
    __typename?: 'ReportDamage';
    /** @deprecated Use grapql __typename instead */
    action: Scalars['String'];
    claimFormUrl?: Maybe<Scalars['String']>;
    unavailableReason?: Maybe<Scalars['String']>;
};

export type RequestAutomaticChargeBreakdownCreateInput = {
    value: Scalars['Float'];
    netValue: Scalars['Float'];
    taxValue: Scalars['Float'];
    taxRate: Scalars['Float'];
};

export type RequestAutomaticChargeBreakdownInput = {
    type: Scalars['String'];
    ID: Scalars['ID'];
    allocate: Scalars['Float'];
    metadata?: Maybe<Scalars['JSON']>;
    create?: Maybe<RequestAutomaticChargeBreakdownCreateInput>;
};

export type RequestAutomaticChargeInput = {
    breakdown: Array<RequestAutomaticChargeBreakdownInput>;
    scheduleID: Scalars['ID'];
    paymentMethodID: Scalars['ID'];
    country: Scalars['String'];
    currency: Scalars['String'];
};

export type RequestAutomaticChargePayload = {
    __typename?: 'RequestAutomaticChargePayload';
    movement?: Maybe<Movement>;
};

export type RequestBpiChargeBreakdownCreateInput = {
    value: Scalars['Float'];
    taxRate: Scalars['Float'];
};

export type RequestBpiChargeBreakdownInput = {
    type: Scalars['String'];
    ID: Scalars['ID'];
    allocate: Scalars['Float'];
    metadata?: Maybe<Scalars['JSON']>;
    create?: Maybe<RequestAutomaticChargeBreakdownCreateInput>;
};

export type RequestBpiChargeInput = {
    breakdown: Array<RequestAutomaticChargeBreakdownInput>;
    scheduleID: Scalars['ID'];
    paymentMethodID: Scalars['ID'];
    country: Scalars['String'];
    currency: Scalars['String'];
};

export type RequestBpiChargePayload = {
    __typename?: 'RequestBPIChargePayload';
    movement?: Maybe<Movement>;
};

export type RequestRefundBreakdownInput = {
    type: Scalars['String'];
    externalID: Scalars['ID'];
    slug?: Maybe<Scalars['String']>;
    metadata?: Maybe<Scalars['JSON']>;
    amount: Scalars['Float'];
};

export type RequestRefundInput = {
    breakdown?: Maybe<Array<RequestRefundBreakdownInput>>;
    resourceBreakdown?: Maybe<Array<RequestRefundResourceBreakdownInput>>;
    movementExternalID?: Maybe<Scalars['String']>;
    movementID?: Maybe<Scalars['String']>;
    reason: Scalars['String'];
    refundExternalID?: Maybe<Scalars['String']>;
};

export type RequestRefundPayload = {
    __typename?: 'RequestRefundPayload';
    movements: Array<Maybe<Movement>>;
};

export type RequestRefundResourceBreakdownInput = {
    ID: Scalars['ID'];
    amount: Scalars['Float'];
};

export type Reservation = {
    __typename?: 'Reservation';
    uid: Scalars['ID'];
    attemptUid?: Maybe<Scalars['String']>;
    customerId?: Maybe<Scalars['Int']>;
    stockUid?: Maybe<Scalars['String']>;
    stock?: Maybe<Stock>;
    skuUid?: Maybe<Scalars['String']>;
    /** number of days in pending allocation */
    daysPending?: Maybe<Scalars['Float']>;
    skuVariantCode?: Maybe<Scalars['String']>;
    storeId?: Maybe<Scalars['Int']>;
    storeUid?: Maybe<Scalars['String']>;
    orderId?: Maybe<Scalars['Int']>;
    orderNumber?: Maybe<Scalars['String']>;
    orderMode?: Maybe<JunoOrderMode>;
    isActive?: Maybe<Scalars['Boolean']>;
    priority?: Maybe<Scalars['Float']>;
    status?: Maybe<ReservationStatus>;
    store?: Maybe<Store>;
    statusUpdatedAt?: Maybe<Scalars['DateTime']>;
    quantity?: Maybe<Scalars['Int']>;
    expirationDate?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    deletedAt?: Maybe<Scalars['DateTime']>;
    variant: Variant;
};

export type ReservationCollection = {
    __typename?: 'ReservationCollection';
    count: Scalars['Float'];
    rows: Array<Reservation>;
};

export type ReservationFilterInput = {
    uid?: Maybe<Scalars['ID']>;
    stockUid?: Maybe<Scalars['ID']>;
    status?: Maybe<ReservationStatus>;
    orderNumber?: Maybe<Scalars['String']>;
    orderMode?: Maybe<JunoOrderMode>;
    skuVariantCode?: Maybe<Scalars['String']>;
    storeId?: Maybe<Scalars['Float']>;
    isActive?: Maybe<Scalars['Boolean']>;
};

export enum ReservationStatus {
    Pending = 'Pending',
    Confirmed = 'Confirmed',
    Approved = 'Approved',
    Paid = 'Paid',
    Fulfilled = 'Fulfilled',
    Expired = 'Expired',
    Declined = 'Declined',
    Cancelled = 'Cancelled',
    Deleted = 'Deleted',
}

export enum ReservationType {
    SEPA_CREDIT_BATCH = 'SEPA_CREDIT_BATCH',
    SEPA_CREDIT_TRANSFER = 'SEPA_CREDIT_TRANSFER',
    CARD_AUTHORIZATION = 'CARD_AUTHORIZATION',
    CLEARING_TRANSACTION = 'CLEARING_TRANSACTION',
    INTRA_CUSTOMER_TRANSFER = 'INTRA_CUSTOMER_TRANSFER',
    SEIZURE = 'SEIZURE',
}

/** Autogenerated input type of ResetVerification */
export type ResetVerificationInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    userId: Scalars['ID'];
};

/** Autogenerated return type of ResetVerification. */
export type ResetVerificationPayload = {
    __typename?: 'ResetVerificationPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    user: User;
};

export type Resource = {
    __typename?: 'Resource';
    ID: Scalars['ID'];
    externalID: Scalars['ID'];
    type: Scalars['String'];
    slug: Scalars['String'];
    value: Scalars['Float'];
    valueWithoutTax: Scalars['Float'];
    taxRate: Scalars['Float'];
    taxAmount: Scalars['Float'];
    currentAllocatedAmount: Scalars['Float'];
    currentAllocatedTaxes: Scalars['Float'];
    currency: Scalars['String'];
    countryISO: Scalars['String'];
    status: ResourceStatus;
    createdAt: Scalars['ISO8601DateTime'];
    metadata?: Maybe<Scalars['JSON']>;
    resourceTransactions?: Maybe<Array<ResourceTransaction>>;
    resourceMovements: Array<MovementResource>;
};

export type ResourceConnection = {
    __typename?: 'ResourceConnection';
    totalCount: Scalars['Int'];
    edges?: Maybe<Array<ResourceEdge>>;
    nodes?: Maybe<Array<Resource>>;
    pageInfo: PageInfo;
};

export type ResourceEdge = {
    __typename?: 'ResourceEdge';
    cursor: Scalars['ID'];
    node?: Maybe<Resource>;
};

export enum ResourceStatus {
    PAID = 'PAID',
    UNPAID = 'UNPAID',
    PARTIALLY_PAID = 'PARTIALLY_PAID',
    PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
    REFUNDED = 'REFUNDED',
    OVERBOOKED = 'OVERBOOKED',
}

export type ResourceTransaction = {
    __typename?: 'ResourceTransaction';
    resource: Resource;
    transaction: Transaction;
    transactionID: Scalars['String'];
    resourceID: Scalars['String'];
    amount: Scalars['Float'];
    amountWithoutTax: Scalars['Float'];
    taxRate: Scalars['Float'];
    taxAmount: Scalars['Float'];
};

export type ResourceType = {
    __typename?: 'ResourceType';
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    path: Array<Scalars['String']>;
};

export type RetryAddonsApiEventInput = {
    id: Scalars['String'];
    /** If set to true, automatically archives the event in case of success */
    autoArchive: Scalars['Boolean'];
};

export type ReturnAssetInput = {
    assetUuid: Scalars['String'];
    email: Scalars['String'];
};

export type ReturnSteps = AccessoriesReturnStep | ConfirmableReturnStep | ReturnStepsOutcome;

export type ReturnStepsOutcome = {
    __typename?: 'ReturnStepsOutcome';
    name: Scalars['String'];
};

export enum ReturnUnavailabilityReason {
    ALREADY_IN_PROGRESS = 'ALREADY_IN_PROGRESS',
    ANOTHER_FLOW_IN_PROGRESS = 'ANOTHER_FLOW_IN_PROGRESS',
    CONTRACT_IS_NOT_ACTIVE = 'CONTRACT_IS_NOT_ACTIVE',
    NOT_YET_AVAILABLE = 'NOT_YET_AVAILABLE',
}

/** Autogenerated input type of RevertDiscountOffer */
export type RevertDiscountOfferInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    subscriptionId: Scalars['ID'];
};

/** Autogenerated return type of RevertDiscountOffer. */
export type RevertDiscountOfferPayload = {
    __typename?: 'RevertDiscountOfferPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    subscriptionDetails: SubscriptionDetails;
};

/** More fields for this type will be defined in following PRs */
export type ReviewingOrderEof = IExternalOrderFulfillment & {
    __typename?: 'ReviewingOrderEOF';
    uid: Scalars['ID'];
    orderNumber: Scalars['String'];
    orderMode?: Maybe<OrderFulfillmentOrderMode>;
    userId: Scalars['String'];
    userType?: Maybe<UserTypes>;
    storeId?: Maybe<Scalars['Int']>;
    storeCode?: Maybe<Scalars['String']>;
    storeType?: Maybe<StoreTypes>;
    storeCountryId?: Maybe<Scalars['Int']>;
    storeCountryCode?: Maybe<Scalars['String']>;
    state: OrderFulfillmentState;
    stateUpdatedAt: Scalars['DateTime'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    deletedAt?: Maybe<Scalars['DateTime']>;
    order?: Maybe<MixCartOrder>;
    /** Collection of assets belonging to an order */
    assets?: Maybe<Array<OrderAsset>>;
    shippingPreferences?: Maybe<OrderShippingPreferences>;
    employee?: Maybe<GroverEmployee>;
    shippingAddress?: Maybe<EofShippingAddress>;
};

export type RevivalTerm = {
    __typename?: 'RevivalTerm';
    available: Scalars['Boolean'];
    unavailabilityReason?: Maybe<RevivalUnavailabilityReason>;
};

export enum RevivalUnavailabilityReason {
    ASSET_NEVER_SHIPPED = 'ASSET_NEVER_SHIPPED',
    ASSET_NOT_WITH_CUSTOMER = 'ASSET_NOT_WITH_CUSTOMER',
    CONTRACT_NOT_TERMINATED = 'CONTRACT_NOT_TERMINATED',
}

/** Autogenerated input type of ReviveContract */
export type ReviveContractInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contractId: Scalars['ID'];
};

/** Autogenerated return type of ReviveContract. */
export type ReviveContractPayload = {
    __typename?: 'ReviveContractPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract?: Maybe<Contract>;
    errors?: Maybe<Array<Scalars['String']>>;
};

export type Revocation = Operation & {
    __typename?: 'Revocation';
    completedAt?: Maybe<Scalars['ISO8601DateTime']>;
    owner: User;
    startedAt: Scalars['ISO8601DateTime'];
    startedBy: User;
    state: InboundShipmentStates;
    updates: Array<FlowUpdate>;
};

export type RevocationTerms = {
    __typename?: 'RevocationTerms';
    availabilityPeriod: Duration;
    available: Scalars['Boolean'];
    availableUntil?: Maybe<Scalars['ISO8601DateTime']>;
    unavailabilityReason?: Maybe<RevocationUnavailabilityReason>;
};

export enum RevocationUnavailabilityReason {
    ALREADY_IN_PROGRESS = 'ALREADY_IN_PROGRESS',
    ANOTHER_FLOW_IN_PROGRESS = 'ANOTHER_FLOW_IN_PROGRESS',
    AVAILABILITY_PERIOD_ENDED = 'AVAILABILITY_PERIOD_ENDED',
    CONTRACT_TERMINATED = 'CONTRACT_TERMINATED',
    NOT_YET_AVAILABLE = 'NOT_YET_AVAILABLE',
    REVOCATION_DISABLED = 'REVOCATION_DISABLED',
}

/** Autogenerated input type of RevokeContract */
export type RevokeContractInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contractId: Scalars['ID'];
};

/** Autogenerated return type of RevokeContract. */
export type RevokeContractPayload = {
    __typename?: 'RevokeContractPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract: Contract;
};

export type RiskApprovalInfo = {
    countryIso: Scalars['String'];
};

export enum RiskApprovalOutcome {
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED',
}

export type RiskApprovalResult = {
    __typename?: 'RiskApprovalResult';
    outcome: RiskApprovalOutcome;
};

export enum RuleStatus {
    Active = 'Active',
    Disabled = 'Disabled',
}

export enum RuleType {
    Permanent = 'Permanent',
    Temporary = 'Temporary',
}

export type SalesforceLead = {
    __typename?: 'SalesforceLead';
    leadId: Scalars['String'];
    companyId: Scalars['Float'];
};

export type ScaChallengesResult = {
    __typename?: 'SCAChallengesResult';
    id: Scalars['String'];
    merchantName: Scalars['String'];
    declineChangeRequestId: Scalars['String'];
    authoriseChangeRequestId: Scalars['String'];
    amount: BasicAmount;
    expiresAt: Scalars['DateTime'];
    challengedAt: Scalars['DateTime'];
};

export type SddAcceptedAtResult = {
    __typename?: 'SddAcceptedAtResult';
    sddAcceptedAt: Scalars['DateTime'];
};

export type SddAddress = {
    __typename?: 'SddAddress';
    line1: Scalars['String'];
    line2?: Maybe<Scalars['String']>;
    city: Scalars['String'];
    zip: Scalars['String'];
    country: Scalars['String'];
    countryIso: Scalars['String'];
};

export type SddMandate = {
    __typename?: 'SDDMandate';
    reference: Scalars['String'];
    isActive: Scalars['Boolean'];
    createdAt: Scalars['DateTime'];
};

export type SddMandateDownloadInfo = {
    store: Scalars['String'];
    lang: Scalars['String'];
};

export type SddMandateDownloadResult = {
    __typename?: 'SDDMandateDownloadResult';
    encodedContent: Scalars['String'];
    mimeType: Scalars['String'];
};

export type SddMandateResult = {
    __typename?: 'SddMandateResult';
    creditorId: Scalars['String'];
    companyName: Scalars['String'];
    commercialRegisterNumber: Scalars['String'];
    commercialRegisteredDistrictCourt: Scalars['String'];
    sddAddress: SddAddress;
    creditInstitution: Scalars['String'];
};

export type SearchResultSection = {
    __typename?: 'SearchResultSection';
    title: Scalars['String'];
    items: Array<SearchResultSectionItem>;
    emptyText?: Maybe<Scalars['String']>;
    highlight?: Maybe<Scalars['String']>;
};

export type SearchResultSectionItem = {
    __typename?: 'SearchResultSectionItem';
    link: HeaderLink;
    imageUrl?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    type?: Maybe<SearchResultSectionItemType>;
    analyticsTerms: Array<AnalyticsTerm>;
};

export enum SearchResultSectionItemType {
    product = 'product',
    category = 'category',
    popularSearch = 'popularSearch',
    popularCategory = 'popularCategory',
}

export type Section = {
    __typename?: 'Section';
    filter?: Maybe<SubscriptionListFilter>;
    items: Array<SubscriptionListEntry>;
    quantity: Scalars['Int'];
    title: Scalars['String'];
};

export type SecureCardDetailsInfo = {
    deviceId: Scalars['String'];
    cardId: Scalars['String'];
    signature: Scalars['String'];
    jwk: Jwk;
    jwe: Jwe;
};

export type SecureCardDetailsResult = {
    __typename?: 'SecureCardDetailsResult';
    data: Scalars['String'];
};

export type SeizurePaymentInfo = {
    id: Scalars['String'];
    deviceId: Scalars['String'];
    amount: PaymentAmount;
};

export type SeizureResult = {
    __typename?: 'SeizureResult';
    id: Scalars['String'];
    enactmentDate?: Maybe<Scalars['DateTime']>;
    deliveryDate?: Maybe<Scalars['DateTime']>;
    authorityName?: Maybe<Scalars['String']>;
    resolutionCaseNumber?: Maybe<Scalars['String']>;
    seizureType: SeizureTypeEnum;
    status: SeizureStatusEnum;
    amount: CardAmount;
    additionalCost?: Maybe<CardAmount>;
    debtor?: Maybe<DebtorInfo>;
    creditor?: Maybe<CreditorInfo>;
    creditorRepresentative?: Maybe<CreditorRepresentativeInfo>;
};

export enum SeizureStatusEnum {
    ACTIVE = 'ACTIVE',
    FULFILLED = 'FULFILLED',
    WAITING_FOR_AUTHORITY = 'WAITING_FOR_AUTHORITY',
}

export enum SeizureTypeEnum {
    COURT_SEIZURE = 'COURT_SEIZURE',
    AUTHORITY_SEIZURE = 'AUTHORITY_SEIZURE',
    ATTACHMENT = 'ATTACHMENT',
    PRE_ATTACHMENT = 'PRE_ATTACHMENT',
}

export type SendResultChannelComparisonInput = {
    _eq?: Maybe<NotificationChannel>;
};

export type SendResultConnection = {
    __typename?: 'SendResultConnection';
    pageInfo: PageInfo;
    edges: Array<SendResultEdge>;
    nodes: Array<NotificationSendResult>;
    totalCount: Scalars['Int'];
};

export type SendResultEdge = {
    __typename?: 'SendResultEdge';
    node: NotificationSendResult;
    cursor: Scalars['String'];
};

export type SendResultStatusComparisonInput = {
    _eq?: Maybe<NotificationSendResultStatus>;
};

export type SendResultWhereInput = {
    and?: Maybe<Array<SendResultWhereInput>>;
    triggerName?: Maybe<StringComparisonInput>;
    userId?: Maybe<IdComparisonInput>;
    status?: Maybe<SendResultStatusComparisonInput>;
    channel?: Maybe<SendResultChannelComparisonInput>;
};

export type Sepa = PaymentMethodInterface & {
    __typename?: 'Sepa';
    bankName?: Maybe<Scalars['String']>;
    billingAccountId?: Maybe<Scalars['String']>;
    ibanFirstDigits?: Maybe<Scalars['String']>;
    ibanLastDigits?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    mandateId?: Maybe<Scalars['String']>;
    merchantTransactionId?: Maybe<Scalars['String']>;
    referenceId?: Maybe<Scalars['String']>;
    contracts: Array<BasicContract>;
};

/** SEPA payment method from Wallet service. Eventually will deprecate the Sepa type. */
export type SepaPaymentMethod = PaymentMethod2 & {
    __typename?: 'SepaPaymentMethod';
    id: Scalars['ID'];
    status: PaymentMethodStatus;
    /** Date/time of the method creation. Empty in case of a legacy method. */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** URL to redirect the user to if authorization is required by the payment provider. */
    redirectUrl?: Maybe<Scalars['String']>;
    /** ID of the corresponding method in Grover API. */
    groverApiId?: Maybe<Scalars['Float']>;
    /** ID of the corresponding account in Billing Service. */
    billingAccountId?: Maybe<Scalars['String']>;
    /** Contracts and subscriptions that are using this method. */
    linkedContracts: Array<FlexDetails>;
    /** Method's external ID in IXOPAY. */
    ixopayReferenceId?: Maybe<Scalars['String']>;
    /**
     * Present if the method deletion is currently forbidden, contains details about
     * the reason. Warning: currently does not return "has linked contracts", which
     * is one of the possible reasons for deletion lock. Check the linkedContracts field instead.
     */
    deletionLock?: Maybe<PaymentMethodDeletionLock>;
    /** Error code classifying the method registration failure, if any. */
    registrationErrorCode?: Maybe<TransactionErrorCode>;
    /** Returns status of this method in relation to the given order. */
    statusForOrder: PaymentMethodStatusForOrderResult;
    /** Name of the account owner. */
    accountOwner?: Maybe<Scalars['String']>;
    bankName?: Maybe<Scalars['String']>;
    ibanFirstDigits?: Maybe<Scalars['String']>;
    ibanLastDigits?: Maybe<Scalars['String']>;
    mandateId?: Maybe<Scalars['String']>;
    countryCode?: Maybe<Scalars['String']>;
};

/** SEPA payment method from Wallet service. Eventually will deprecate the Sepa type. */
export type SepaPaymentMethodStatusForOrderArgs = {
    orderNumber: Scalars['String'];
};

export type SerialNumberLookup = {
    __typename?: 'SerialNumberLookup';
    serialNumber?: Maybe<Scalars['String']>;
    exists: Scalars['Boolean'];
    sku?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
};

/** Input for the setPaymentMethod mutation. */
export type SetPaymentMethodInput = {
    /** ID of the Wallet method. */
    paymentMethodId: Scalars['ID'];
    /** IDs of the contracts and/or subscriptions being updated. */
    contractIds: Array<Scalars['ID']>;
};

export type Settlement = {
    __typename?: 'Settlement';
    name: Scalars['String'];
};

export type Shipment = {
    __typename?: 'Shipment';
    uid: Scalars['ID'];
    orderMode: Scalars['String'];
    orderNumber: Scalars['String'];
    orderItemId?: Maybe<Scalars['Float']>;
    shippingRequestUid: Scalars['String'];
    shippingRequest: ShippingRequest;
    shipmentHistory: Array<ShipmentHistoryItem>;
    trackingNumber?: Maybe<Scalars['String']>;
    trackingUrl?: Maybe<Scalars['String']>;
    shippingLabelUrl?: Maybe<Scalars['String']>;
    proofOfDeliveryUrls?: Maybe<Array<Scalars['String']>>;
    senderAddressUid?: Maybe<Scalars['String']>;
    recipientAddressUid?: Maybe<Scalars['String']>;
    pickupAddressUid?: Maybe<Scalars['String']>;
    service: Scalars['String'];
    status: ShipmentLifecycleStatus;
    carrier: ShipmentCarrier;
    shippingProfile?: Maybe<Scalars['String']>;
    length?: Maybe<Scalars['Float']>;
    width?: Maybe<Scalars['Float']>;
    height?: Maybe<Scalars['Float']>;
    weight?: Maybe<Scalars['Float']>;
    type?: Maybe<Scalars['String']>;
    trackingEvents?: Maybe<Array<ShipmentLifecycleEvent>>;
    customerType?: Maybe<Scalars['String']>;
    packages?: Maybe<Array<PackageItem>>;
    senderAddress?: Maybe<ShippingAddress>;
    recipientAddress?: Maybe<ShippingAddress>;
    pickupAddress?: Maybe<ShippingAddress>;
    /** senderAddress for inbound & recipientAddress for outbound */
    customerAddress?: Maybe<ShippingAddress>;
    company?: Maybe<Scalars['String']>;
    numberOfAssets?: Maybe<Scalars['Int']>;
    contractId?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['DateTimeISO']>;
    updatedAt?: Maybe<Scalars['DateTimeISO']>;
    deletedAt?: Maybe<Scalars['DateTimeISO']>;
    statusUpdatedAt?: Maybe<Scalars['DateTimeISO']>;
    daysSinceOpened?: Maybe<Scalars['Float']>;
    warehouseCode?: Maybe<WarehouseCode>;
};

export enum ShipmentCarrier {
    ANGEL_DE = 'ANGEL_DE',
    CARGO_INTERNATIONAL = 'CARGO_INTERNATIONAL',
    DHL = 'DHL',
    DHL_EXPRESS = 'DHL_EXPRESS',
    DPAG = 'DPAG',
    DPD = 'DPD',
    GLS = 'GLS',
    GO = 'GO',
    HERMES = 'HERMES',
    ILOXX = 'ILOXX',
    PARCEL_ONE = 'PARCEL_ONE',
    UPS = 'UPS',
}

export type ShipmentCollection = {
    __typename?: 'ShipmentCollection';
    count: Scalars['Float'];
    rows: Array<Shipment>;
};

export enum ShipmentEventState {
    Created = 'Created',
    Shipped = 'Shipped',
    Delivered = 'Delivered',
    FailedToDeliver = 'FailedToDeliver',
    Cancelled = 'Cancelled',
    Exception = 'Exception',
    ReadyForPickupByReceiver = 'ReadyForPickupByReceiver',
}

export type ShipmentFilterType = {
    assetUid?: Maybe<StringComparatorInput>;
    uid?: Maybe<StringComparatorInput>;
    orderNumber?: Maybe<StringComparatorInput>;
    orderMode?: Maybe<ShippingRequestModeComparatorInput>;
    status?: Maybe<ShippingStatusComparatorInput>;
    service?: Maybe<StringComparatorInput>;
    carrier?: Maybe<StringComparatorInput>;
    customerType?: Maybe<StringComparatorInput>;
    countryCode?: Maybe<StringComparatorInput>;
    userId?: Maybe<StringComparatorInput>;
    createdAt?: Maybe<DateComparatorInput>;
    contractId?: Maybe<StringComparatorInput>;
    serialNumber?: Maybe<StringComparatorInput>;
    trackingId?: Maybe<StringComparatorInput>;
    warehouseCode?: Maybe<StringComparatorInput>;
};

export type ShipmentHistoryItem = {
    __typename?: 'ShipmentHistoryItem';
    uid?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    type?: Maybe<ShipmentHistoryItemType>;
    timestamp?: Maybe<Scalars['DateTimeISO']>;
};

export enum ShipmentHistoryItemType {
    status_change = 'status_change',
    delivery_exception = 'delivery_exception',
}

export type ShipmentLabel = {
    __typename?: 'ShipmentLabel';
    trackingUrl?: Maybe<Scalars['String']>;
    shippingLabelUrl?: Maybe<Scalars['String']>;
    assetUid: Scalars['String'];
    type?: Maybe<ShipmentServiceType>;
    trackingStatus?: Maybe<ShipmentTrackingStatus>;
};

export type ShipmentLifecycleEvent = {
    __typename?: 'ShipmentLifecycleEvent';
    uid: Scalars['String'];
    shipmentUid?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    location?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    operation?: Maybe<ShipmentOperation>;
    timestamp?: Maybe<Scalars['String']>;
    carrier?: Maybe<Scalars['String']>;
    metadata?: Maybe<Scalars['String']>;
};

export enum ShipmentLifecycleStatus {
    Created = 'Created',
    PickAndPacking = 'PickAndPacking',
    Packed = 'Packed',
    LabelCreated = 'LabelCreated',
    PickedUpByCarrier = 'PickedUpByCarrier',
    ArrivedAtCarrierFacility = 'ArrivedAtCarrierFacility',
    ScannedAtCarrierFacility = 'ScannedAtCarrierFacility',
    ProcessingAtCarrierFacility = 'ProcessingAtCarrierFacility',
    DepartedFromCarrierFacility = 'DepartedFromCarrierFacility',
    InTransit = 'InTransit',
    OutForDelivery = 'OutForDelivery',
    ReadyForPickupByReceiver = 'ReadyForPickupByReceiver',
    FailedDeliveryCandidate = 'FailedDeliveryCandidate',
    FailedToDeliver = 'FailedToDeliver',
    DeliveryRefused = 'DeliveryRefused',
    Cancelled = 'Cancelled',
    Delivered = 'Delivered',
}

export type ShipmentOperation = UpdateTrackingInformation;

export enum ShipmentOperationName {
    TrackingDetailsUpdated = 'TrackingDetailsUpdated',
}

export enum ShipmentServiceType {
    OUTBOUND = 'OUTBOUND',
    INBOUND = 'INBOUND',
    INTERNAL = 'INTERNAL',
}

export enum ShipmentTrackingStatus {
    InTransit = 'InTransit',
    FailedToDeliver = 'FailedToDeliver',
    ReadyForPickupByReceiver = 'ReadyForPickupByReceiver',
    Delivered = 'Delivered',
}

/** More fields for this type will be defined in following PRs */
export type ShippedEof = IExternalOrderFulfillment & {
    __typename?: 'ShippedEOF';
    uid: Scalars['ID'];
    orderNumber: Scalars['String'];
    orderMode?: Maybe<OrderFulfillmentOrderMode>;
    userId: Scalars['String'];
    userType?: Maybe<UserTypes>;
    storeId?: Maybe<Scalars['Int']>;
    storeCode?: Maybe<Scalars['String']>;
    storeType?: Maybe<StoreTypes>;
    storeCountryId?: Maybe<Scalars['Int']>;
    storeCountryCode?: Maybe<Scalars['String']>;
    state: OrderFulfillmentState;
    stateUpdatedAt: Scalars['DateTime'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    deletedAt?: Maybe<Scalars['DateTime']>;
    order?: Maybe<MixCartOrder>;
    /** Collection of assets belonging to an order */
    assets?: Maybe<Array<OrderAsset>>;
    shippingPreferences?: Maybe<OrderShippingPreferences>;
    employee?: Maybe<GroverEmployee>;
    shippingAddress?: Maybe<EofShippingAddress>;
    /** Collection of shipments belonging to an order */
    shipments?: Maybe<Array<OrderShipment>>;
};

export type ShippingAddress = {
    __typename?: 'ShippingAddress';
    uid: Scalars['ID'];
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    company?: Maybe<Scalars['String']>;
    street1: Scalars['String'];
    street2?: Maybe<Scalars['String']>;
    city: Scalars['String'];
    country: Scalars['String'];
    countryCode: Scalars['String'];
    state?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    zipCode: Scalars['String'];
    shipmentUid: Scalars['String'];
    shipment?: Maybe<Shipment>;
};

export type ShippingAddressCollection = {
    __typename?: 'ShippingAddressCollection';
    count: Scalars['Float'];
    rows: Array<ShippingAddress>;
};

export type ShippingAddressFilterType = {
    uid?: Maybe<Scalars['String']>;
    shipmentUid?: Maybe<Scalars['String']>;
};

export type ShippingDetails = {
    zipcode?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
    country_code?: Maybe<Scalars['String']>;
    shippingCost?: Maybe<Scalars['Float']>;
};

export enum ShippingIgnoreCarrierRulesOptions {
    None = 'None',
    PreferredProfile = 'PreferredProfile',
    SpecialRule = 'SpecialRule',
    All = 'All',
}

export enum ShippingOrderEnum {
    DESC = 'DESC',
    ASC = 'ASC',
}

export type ShippingPreferences = {
    shipToName: Scalars['String'];
    includeCompanyName: Scalars['Boolean'];
};

export type ShippingProfileFilterType = {
    skuVariant: Scalars['String'];
    orderMode: ShippingRequestMode;
    type: ShipmentServiceType;
    countryCode: Scalars['String'];
    customerType?: Maybe<ShippingUserType>;
    preferredProfile?: Maybe<Scalars['String']>;
    orderNumber?: Maybe<Scalars['String']>;
    warehouseCode?: Maybe<Scalars['String']>;
};

export type ShippingProfileRule = {
    __typename?: 'ShippingProfileRule';
    uid: Scalars['ID'];
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    state: Scalars['String'];
    priority: Scalars['Float'];
    body: ShippingProfileRuleBody;
    outputType: Scalars['String'];
    outputValue: Scalars['Float'];
    createdAt?: Maybe<Scalars['DateTimeISO']>;
    updatedAt?: Maybe<Scalars['DateTimeISO']>;
    deletedAt?: Maybe<Scalars['DateTimeISO']>;
    expireAt?: Maybe<Scalars['DateTimeISO']>;
};

export type ShippingProfileRuleBody = {
    __typename?: 'ShippingProfileRuleBody';
    shipmentType: Scalars['String'];
    category: Scalars['String'];
    skuProducts: Array<Scalars['String']>;
    countries: Array<Scalars['String']>;
    warehouseCode?: Maybe<Array<Scalars['String']>>;
    customerType?: Maybe<Array<Scalars['String']>>;
    orderMode?: Maybe<Array<Scalars['String']>>;
};

export type ShippingProfileRuleFilterType = {
    uid?: Maybe<StringComparatorInput>;
};

export type ShippingProfileRuleMutationResult = {
    __typename?: 'ShippingProfileRuleMutationResult';
    rule: ShippingProfileRule;
};

export type ShippingProfileRulesCollection = {
    __typename?: 'ShippingProfileRulesCollection';
    count: Scalars['Float'];
    rows: Array<ShippingProfileRule>;
};

export type ShippingProgress = {
    __typename?: 'ShippingProgress';
    actions?: Maybe<Array<Action>>;
    description?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    slug: Scalars['String'];
    state: Scalars['String'];
    subSteps?: Maybe<Array<SubStep>>;
};

export type ShippingRequest = {
    __typename?: 'ShippingRequest';
    uid: Scalars['ID'];
    orderId?: Maybe<Scalars['String']>;
    orderNumber?: Maybe<Scalars['String']>;
    orderMode?: Maybe<ShippingRequestMode>;
    userId?: Maybe<Scalars['String']>;
    shipments?: Maybe<Array<Shipment>>;
    status?: Maybe<ShippingRequestStatus>;
    additionalInfo?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['DateTimeISO']>;
    updatedAt?: Maybe<Scalars['DateTimeISO']>;
    deletedAt?: Maybe<Scalars['DateTimeISO']>;
    daysSinceOpened?: Maybe<Scalars['Float']>;
    numberOfShipments?: Maybe<Scalars['Float']>;
};

export type ShippingRequestCollection = {
    __typename?: 'ShippingRequestCollection';
    count: Scalars['Float'];
    rows: Array<ShippingRequest>;
};

export type ShippingRequestFilterType = {
    uid?: Maybe<Scalars['String']>;
    orderNumber?: Maybe<Scalars['String']>;
    orderMode?: Maybe<ShippingRequestMode>;
    status?: Maybe<ShippingRequestStatus>;
};

export enum ShippingRequestMode {
    FLEX = 'FLEX',
    FLEX_LEGACY = 'FLEX_LEGACY',
    SWAP = 'SWAP',
    MIX = 'MIX',
}

export type ShippingRequestModeComparatorInput = {
    _eq?: Maybe<ShippingRequestMode>;
    _neq?: Maybe<ShippingRequestMode>;
};

export enum ShippingRequestStatus {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    CANCELLED = 'CANCELLED',
    EXCEPTION = 'EXCEPTION',
}

export type ShippingStatusComparatorInput = {
    _eq?: Maybe<ShipmentLifecycleStatus>;
    _neq?: Maybe<ShipmentLifecycleStatus>;
    _in?: Maybe<Array<ShipmentLifecycleStatus>>;
    _nin?: Maybe<Array<ShipmentLifecycleStatus>>;
};

export type ShippingSummaryDeliveryProfileMetricType = {
    __typename?: 'ShippingSummaryDeliveryProfileMetricType';
    profile: Scalars['String'];
    value: Scalars['Float'];
};

export enum ShippingUserType {
    Normal = 'Normal',
    Business = 'Business',
}

export type SimpleSubscriptionModel = {
    __typename?: 'SimpleSubscriptionModel';
    amount: Scalars['Money'];
    currency: Scalars['String'];
    recurrentPaymentUuid: Scalars['String'];
    description: Scalars['String'];
    externalCustomer: Scalars['String'];
    billingPeriod: Scalars['String'];
};

export type SingleChoiceOption = {
    __typename?: 'SingleChoiceOption';
    slug: Scalars['String'];
    label: Scalars['String'];
    weight?: Maybe<Scalars['Float']>;
};

export type SingleChoiceOptions = {
    __typename?: 'SingleChoiceOptions';
    options: Array<SingleChoiceOption>;
};

export type SingleChoiceResult = {
    __typename?: 'SingleChoiceResult';
    slug: Scalars['String'];
    votes: Scalars['Int'];
};

export type SingleChoiceResults = {
    __typename?: 'SingleChoiceResults';
    resultsPerOption: Array<SingleChoiceResult>;
    total: TotalResults;
};

export type SkuComparatorInput = {
    _eq?: Maybe<Scalars['String']>;
    _in?: Maybe<Array<Scalars['String']>>;
};

export type SkuProductGroup = {
    __typename?: 'SkuProductGroup';
    name: Scalars['String'];
    skuProducts?: Maybe<Array<Scalars['String']>>;
};

export enum SmartAgentCaseResolutionEnum {
    confirmed = 'confirmed',
    whitelisted = 'whitelisted',
    viewed = 'viewed',
    timeout = 'timeout',
    pending = 'pending',
}

export type SmartAgentCasesInfo = {
    id: Scalars['String'];
    resolution: SmartAgentCaseResolutionEnum;
};

export type SmartAgentCasesResult = {
    __typename?: 'SmartAgentCasesResult';
    id: Scalars['String'];
    merchantName: Scalars['String'];
    attemptedAt: Scalars['DateTime'];
    amount: BasicAmount;
    respondUntil: Scalars['DateTime'];
};

export enum Sort {
    RANK = 'RANK',
    PRICE_ASC = 'PRICE_ASC',
    PRICE_DESC = 'PRICE_DESC',
    NEW_ARRIVALS = 'NEW_ARRIVALS',
}

/** represents the sorting order: asc, or desc */
export enum SortOrder {
    asc = 'asc',
    desc = 'desc',
}

export type SpecFacet = Facet & {
    __typename?: 'SpecFacet';
    name: Scalars['String'];
    label?: Maybe<Scalars['String']>;
    values: Array<SpecFacetValues>;
};

export type SpecFacetValues = {
    __typename?: 'SpecFacetValues';
    value: Scalars['String'];
    count: Scalars['Int'];
    min?: Maybe<Scalars['Float']>;
    max?: Maybe<Scalars['Float']>;
};

export type StandaloneDiscountOffer = {
    __typename?: 'StandaloneDiscountOffer';
    discounts: Array<SubscriptionDiscount>;
    productImageUrl: Scalars['String'];
    subscriptionId: Scalars['ID'];
};

/** Autogenerated input type of StartCustomerWishCancel */
export type StartCustomerWishCancelInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contractId: Scalars['ID'];
};

/** Autogenerated return type of StartCustomerWishCancel. */
export type StartCustomerWishCancelPayload = {
    __typename?: 'StartCustomerWishCancelPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract: Contract;
};

/** Autogenerated input type of StartCustomerWishKeepCharging */
export type StartCustomerWishKeepChargingInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contractId: Scalars['ID'];
};

/** Autogenerated return type of StartCustomerWishKeepCharging. */
export type StartCustomerWishKeepChargingPayload = {
    __typename?: 'StartCustomerWishKeepChargingPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract: Contract;
};

/** Autogenerated input type of StartDamageClaim */
export type StartDamageClaimInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contractId: Scalars['ID'];
};

/** Autogenerated return type of StartDamageClaim. */
export type StartDamageClaimPayload = {
    __typename?: 'StartDamageClaimPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract: Contract;
};

/** Autogenerated input type of StartInternalDebtCollection */
export type StartInternalDebtCollectionInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contractId: Scalars['ID'];
};

/** Autogenerated return type of StartInternalDebtCollection. */
export type StartInternalDebtCollectionPayload = {
    __typename?: 'StartInternalDebtCollectionPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract: Contract;
};

/** Autogenerated input type of StartPurchase */
export type StartPurchaseInput = {
    agreedPurchasePriceInCents?: Maybe<Scalars['Int']>;
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contractId: Scalars['ID'];
};

/** Autogenerated return type of StartPurchase. */
export type StartPurchasePayload = {
    __typename?: 'StartPurchasePayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract: Flex;
};

/** Autogenerated input type of StartReplacement */
export type StartReplacementInput = {
    assetId: Scalars['String'];
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of StartReplacement. */
export type StartReplacementPayload = {
    __typename?: 'StartReplacementPayload';
    assetId?: Maybe<Scalars['String']>;
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract?: Maybe<Contract>;
};

/** Autogenerated input type of StartUpsell */
export type StartUpsellInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contractId: Scalars['ID'];
};

/** Autogenerated return type of StartUpsell. */
export type StartUpsellPayload = {
    __typename?: 'StartUpsellPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract: Contract;
};

export type Statement = {
    __typename?: 'Statement';
    period: Scalars['Int'];
    year: Scalars['Int'];
    month: Scalars['String'];
};

export enum StatusTypes {
    active = 'active',
    paused = 'paused',
}

export type Stock = {
    __typename?: 'Stock';
    uid: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    type?: Maybe<StockType>;
    isRecirculated?: Maybe<Scalars['Boolean']>;
    priority?: Maybe<Scalars['Float']>;
    props?: Maybe<Scalars['Object']>;
    stores?: Maybe<Array<Maybe<Store>>>;
    storeIds?: Maybe<Array<Scalars['ID']>>;
    /** takes storeId and returns priority from corresponding store-stock-link */
    storePriority?: Maybe<Scalars['Float']>;
    assets?: Maybe<Array<AssetModel>>;
    rules?: Maybe<Array<DistributionRule>>;
    inStockCount?: Maybe<Scalars['Float']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    createdAt?: Maybe<Scalars['DateTime']>;
};

export type StockStorePriorityArgs = {
    storeId?: Maybe<Scalars['ID']>;
};

export type StockCollection = {
    __typename?: 'StockCollection';
    count: Scalars['Float'];
    rows: Array<Stock>;
};

export type StockFilterInput = {
    uid?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    searchString?: Maybe<Scalars['String']>;
    type?: Maybe<Array<StockType>>;
    isRecirculated?: Maybe<Scalars['Boolean']>;
};

export type StockTransferCollection = {
    __typename?: 'StockTransferCollection';
    count: Scalars['Float'];
    rows: Array<StockTransferModel>;
};

export type StockTransferFilterInput = {
    uid?: Maybe<Scalars['String']>;
    refId?: Maybe<Scalars['String']>;
    newWarehouseCode?: Maybe<Scalars['String']>;
    oldWarehouseCode?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
};

export type StockTransferModel = {
    __typename?: 'StockTransferModel';
    uid: Scalars['ID'];
    refId: Scalars['String'];
    completedAt?: Maybe<Scalars['DateTime']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    initiatedAt: Scalars['DateTime'];
    newWarehouseCode: Scalars['String'];
    oldWarehouseCode: Scalars['String'];
    serialNumbers: Array<Scalars['String']>;
    skus: Array<StockTransferSku>;
    state: Scalars['String'];
    transferred: Array<Scalars['String']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    missingSerialNumbers: Array<Scalars['String']>;
};

export type StockTransferSku = {
    __typename?: 'StockTransferSKU';
    sku_variant_code: Scalars['String'];
    quantity: Scalars['Int'];
};

export enum StockType {
    Main = 'Main',
    Partner = 'Partner',
    Miscellaneous = 'Miscellaneous',
}

export type StockVariantAvailability = {
    __typename?: 'StockVariantAvailability';
    uid: Scalars['ID'];
    skuUid: Scalars['String'];
    skuVariantCode?: Maybe<Scalars['String']>;
    stockUid: Scalars['String'];
    stockVariantAvailability: StockVariantAvailability;
    sku: Scalars['String'];
    totalCount: Scalars['Int'];
    availableCount: Scalars['Int'];
    inStockCount: Scalars['Int'];
    onLoanCount: Scalars['Int'];
    reservedCount: Scalars['Int'];
    updatedAt?: Maybe<Scalars['DateTime']>;
    createdAt?: Maybe<Scalars['DateTime']>;
};

export type StockVariantAvailabilityCollection = {
    __typename?: 'StockVariantAvailabilityCollection';
    count: Scalars['Float'];
    rows: Array<StockVariantAvailability>;
};

export type StockVariantAvailabilityFilterInput = {
    skuVariantCode?: Maybe<Scalars['String']>;
    stockUid?: Maybe<Scalars['String']>;
    skuUid?: Maybe<Scalars['String']>;
};

export type Store = {
    __typename?: 'Store';
    code: Scalars['ID'];
    alternativeLocales?: Maybe<Array<Scalars['String']>>;
    countryId?: Maybe<Scalars['Int']>;
    countryIso: Scalars['String'];
    defaultCurrency: Scalars['String'];
    defaultLocale: Scalars['String'];
    deliveryTimeFromDays?: Maybe<Scalars['Int']>;
    deliveryTimeToDays?: Maybe<Scalars['Int']>;
    enabledOnFooter: Scalars['Boolean'];
    id: Scalars['ID'];
    name: Scalars['String'];
    offline: Scalars['Boolean'];
    shippingCost: Price;
    storeType?: Maybe<StoreTypes>;
    url: Scalars['String'];
    stocks?: Maybe<Array<Stock>>;
};

export enum StoreCode {
    AT = 'AT',
    BUSINESS_AT = 'BUSINESS_AT',
    BUSINESS_DE = 'BUSINESS_DE',
    BUSINESS_ES = 'BUSINESS_ES',
    BUSINESS_NL = 'BUSINESS_NL',
    BUSINESS_US = 'BUSINESS_US',
    DE = 'DE',
    ES = 'ES',
    NL = 'NL',
    US = 'US',
}

export type StoreCodeComparatorInput = {
    _in?: Maybe<Array<Scalars['String']>>;
};

/** The connection type for Store. */
export type StoreConnection = {
    __typename?: 'StoreConnection';
    /** A list of edges. */
    edges?: Maybe<Array<Maybe<StoreEdge>>>;
    /** A list of nodes. */
    nodes?: Maybe<Array<Maybe<Store>>>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type StoreEdge = {
    __typename?: 'StoreEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
    /** The item at the end of the edge. */
    node?: Maybe<Store>;
};

export type StoreOffsetCollection = {
    __typename?: 'StoreOffsetCollection';
    nodes: Array<Store>;
    totalCount: Scalars['Int'];
};

export type StoresFilterInput = {
    code?: Maybe<Array<Scalars['String']>>;
    id?: Maybe<Array<Scalars['ID']>>;
    storeType?: Maybe<Array<StoreTypes>>;
};

export type StoreStockConfigurationGraphql = {
    __typename?: 'StoreStockConfigurationGraphql';
    stocks?: Maybe<Array<Stock>>;
    storeStockLinks?: Maybe<Array<StoreStockLink>>;
};

export type StoreStockConfigurationGraphqlStocksArgs = {
    name?: Maybe<Scalars['String']>;
};

export type StoreStockLink = {
    __typename?: 'StoreStockLink';
    uid: Scalars['ID'];
    storeId?: Maybe<Scalars['ID']>;
    storeUid?: Maybe<Scalars['String']>;
    stockUid?: Maybe<Scalars['String']>;
    priority?: Maybe<Scalars['Float']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    createdAt?: Maybe<Scalars['DateTime']>;
};

export enum StoreTypes {
    BUSINESS = 'BUSINESS',
    GROVER_PAY = 'GROVER_PAY',
    LEGACY = 'LEGACY',
    MAIN = 'MAIN',
    RETAIL = 'RETAIL',
}

export type StoreVariantAvailability = {
    __typename?: 'StoreVariantAvailability';
    uid: Scalars['ID'];
    storeId?: Maybe<Scalars['Float']>;
    storeUid: Scalars['String'];
    skuUid: Scalars['String'];
    skuVariantCode?: Maybe<Scalars['String']>;
    availabilityMode?: Maybe<AvailabilityMode>;
    orderMode?: Maybe<JunoOrderMode>;
    mixVariantUid?: Maybe<Scalars['String']>;
    availableCount: Scalars['Int'];
    inStockCount: Scalars['Int'];
    reservedCount: Scalars['Int'];
    sku?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    createdAt?: Maybe<Scalars['DateTime']>;
};

export type StoreVariantAvailabilityCollection = {
    __typename?: 'StoreVariantAvailabilityCollection';
    count: Scalars['Float'];
    rows: Array<StoreVariantAvailability>;
};

export type StoreVariantAvailabilityFilterInput = {
    skuVariantCode?: Maybe<Scalars['String']>;
    stockUid?: Maybe<Scalars['String']>;
    skuUid?: Maybe<Scalars['String']>;
    storeId?: Maybe<Scalars['Float']>;
    availabilityMode?: Maybe<AvailabilityMode>;
};

export type StringComparatorInput = {
    _eq?: Maybe<Scalars['String']>;
    _neq?: Maybe<Scalars['String']>;
    _substr?: Maybe<Scalars['String']>;
};

export type StringComparisonInput = {
    _eq?: Maybe<Scalars['String']>;
    _ew?: Maybe<Scalars['String']>;
    _neq?: Maybe<Scalars['String']>;
    _substr?: Maybe<Scalars['String']>;
    _sw?: Maybe<Scalars['String']>;
};

export type StringFilter = {
    contains?: Maybe<Scalars['String']>;
    endsWith?: Maybe<Scalars['String']>;
    equals?: Maybe<Scalars['String']>;
    gt?: Maybe<Scalars['String']>;
    gte?: Maybe<Scalars['String']>;
    in?: Maybe<Array<Scalars['String']>>;
    lt?: Maybe<Scalars['String']>;
    lte?: Maybe<Scalars['String']>;
    not?: Maybe<NestedStringFilter>;
    notIn?: Maybe<Array<Scalars['String']>>;
    startsWith?: Maybe<Scalars['String']>;
};

export type StringNullableFilter = {
    contains?: Maybe<Scalars['String']>;
    endsWith?: Maybe<Scalars['String']>;
    equals?: Maybe<Scalars['String']>;
    gt?: Maybe<Scalars['String']>;
    gte?: Maybe<Scalars['String']>;
    in?: Maybe<Array<Scalars['String']>>;
    lt?: Maybe<Scalars['String']>;
    lte?: Maybe<Scalars['String']>;
    not?: Maybe<NestedStringNullableFilter>;
    notIn?: Maybe<Array<Scalars['String']>>;
    startsWith?: Maybe<Scalars['String']>;
};

export type StringNullableListFilter = {
    equals?: Maybe<Array<Scalars['String']>>;
    has?: Maybe<Scalars['String']>;
    hasEvery?: Maybe<Array<Scalars['String']>>;
    hasSome?: Maybe<Array<Scalars['String']>>;
    isEmpty?: Maybe<Scalars['Boolean']>;
};

export type StructuredSpecificationsInput = {
    name: Scalars['String'];
    values: Array<StructuredSpecificationsValue>;
};

export type StructuredSpecificationsValue = {
    value?: Maybe<Scalars['String']>;
    min?: Maybe<Scalars['Float']>;
    max?: Maybe<Scalars['Float']>;
};

/** Autogenerated input type of SubmitBankAccountSnapshotData */
export type SubmitBankAccountSnapshotDataInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    kontomatik?: Maybe<BankAccountSnapshotDataKontomatikInput>;
    plaid?: Maybe<BankAccountSnapshotDataPlaidInput>;
};

/** Autogenerated return type of SubmitBankAccountSnapshotData. */
export type SubmitBankAccountSnapshotDataPayload = {
    __typename?: 'SubmitBankAccountSnapshotDataPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

/** Autogenerated input type of SubmitKontomatikSession */
export type SubmitKontomatikSessionInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    sessionId: Scalars['ID'];
    sessionIdSignature: Scalars['String'];
};

/** Autogenerated return type of SubmitKontomatikSession. */
export type SubmitKontomatikSessionPayload = {
    __typename?: 'SubmitKontomatikSessionPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

/** Autogenerated input type of SubmitVerificationDecision */
export type SubmitVerificationDecisionInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    verificationDecision: VerificationDecisionInput;
};

/** Autogenerated return type of SubmitVerificationDecision. */
export type SubmitVerificationDecisionPayload = {
    __typename?: 'SubmitVerificationDecisionPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    errors: Array<Scalars['String']>;
    verification: Verification;
};

export type SubscriptionAction =
    | Buyout
    | CancelSubscription
    | ChangePaymentMethod
    | PaymentMethodPresenter
    | PrintReturnLabel
    | ReportDamage
    | SupportRequest
    | UpgradeRentalPlan;

export type SubscriptionBadgeContentModel = {
    __typename?: 'SubscriptionBadgeContentModel';
    uuid: Scalars['ID'];
    svgPath: Scalars['String'];
    /** Markdown template. Commonly a placeholder for translations. Example: {{thankyou}}. thankyou is a translate placeholder */
    bodyTemplate: Scalars['String'];
    actions?: Maybe<Array<ActionUnionModel>>;
};

export type SubscriptionBadgeModel = {
    __typename?: 'SubscriptionBadgeModel';
    uuid: Scalars['ID'];
    svgPath: Scalars['String'];
    /** Markdown template. Commonly a placeholder for translations. Example: {{thankyou}}. thankyou is a translate placeholder */
    bodyTemplate: Scalars['String'];
    actions?: Maybe<Array<ActionUnionModel>>;
};

export type SubscriptionCard = {
    __typename?: 'SubscriptionCard';
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    note?: Maybe<SubscriptionNote>;
    product: Product;
    state: SubscriptionState;
    variant: Variant;
    /** Returns marketing data for the subscription level */
    marketingSubscriptionData: Array<CardTypeUnionModel>;
};

/** The connection type for SubscriptionCard. */
export type SubscriptionCardConnection = {
    __typename?: 'SubscriptionCardConnection';
    /** A list of edges. */
    edges?: Maybe<Array<Maybe<SubscriptionCardEdge>>>;
    /** A list of nodes. */
    nodes?: Maybe<Array<Maybe<SubscriptionCard>>>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SubscriptionCardEdge = {
    __typename?: 'SubscriptionCardEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
    /** The item at the end of the edge. */
    node?: Maybe<SubscriptionCard>;
};

export type SubscriptionDetails = {
    __typename?: 'SubscriptionDetails';
    asset?: Maybe<Asset>;
    basePrice: Price;
    contractNumber?: Maybe<Scalars['String']>;
    discounts: Array<SubscriptionDiscount>;
    groverCare: SubscriptionGroverCare;
    id: Scalars['ID'];
    minimumDuration?: Maybe<Duration>;
    name?: Maybe<Scalars['String']>;
    note?: Maybe<SubscriptionNote>;
    /** @deprecated Legacy id, will be removed */
    number: Scalars['String'];
    order: Order;
    pausedAt?: Maybe<Scalars['ISO8601DateTime']>;
    paymentMethod?: Maybe<PaymentMethod>;
    payments: PaymentsUnionConnection;
    price: Price;
    product?: Maybe<Product>;
    /** @deprecated Legacy id, will be removed */
    sfId?: Maybe<Scalars['ID']>;
    /** @deprecated Legacy id, will be removed */
    sfOrderId?: Maybe<Scalars['ID']>;
    shippingAddress?: Maybe<Address>;
    shippingProgress?: Maybe<Array<ShippingProgress>>;
    state: SubscriptionState;
    subscriptionActions: Array<SubscriptionAction>;
    surpriseDiscount?: Maybe<SurpriseDiscountOffer>;
    tosUpdates: Array<TosUpdate>;
    tosVersion?: Maybe<Scalars['ID']>;
    user: User;
    variant?: Maybe<Variant>;
};

export type SubscriptionDetailsPaymentsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

/** The connection type for SubscriptionDetails. */
export type SubscriptionDetailsConnection = {
    __typename?: 'SubscriptionDetailsConnection';
    /** A list of edges. */
    edges?: Maybe<Array<Maybe<SubscriptionDetailsEdge>>>;
    /** A list of nodes. */
    nodes?: Maybe<Array<Maybe<SubscriptionDetails>>>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SubscriptionDetailsEdge = {
    __typename?: 'SubscriptionDetailsEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
    /** The item at the end of the edge. */
    node?: Maybe<SubscriptionDetails>;
};

export type SubscriptionDiscount = ExistingSubscriptionDiscountOffer | VoucherDiscountOffer;

export type SubscriptionDiscountInterface = {
    discountPercentage: Scalars['Int'];
    price: Price;
};

export type SubscriptionFilterInput = {
    id?: Maybe<Array<Scalars['ID']>>;
    minDaysPausedInTransit?: Maybe<Scalars['Int']>;
    orderNumber?: Maybe<Scalars['ID']>;
    serialNumber?: Maybe<Scalars['ID']>;
    state?: Maybe<Array<SubscriptionState>>;
    storeCode?: Maybe<DefaultStoreCode>;
    userId?: Maybe<Scalars['ID']>;
};

export type SubscriptionGroverCare = {
    __typename?: 'SubscriptionGroverCare';
    coverage: Scalars['Int'];
    price: Price;
};

export type SubscriptionListEntry = SubscriptionCard | SubscriptionStack;

/** The connection type for SubscriptionListEntry. */
export type SubscriptionListEntryConnection = {
    __typename?: 'SubscriptionListEntryConnection';
    /** A list of edges. */
    edges?: Maybe<Array<Maybe<SubscriptionListEntryEdge>>>;
    /** A list of nodes. */
    nodes?: Maybe<Array<Maybe<SubscriptionListEntry>>>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SubscriptionListEntryEdge = {
    __typename?: 'SubscriptionListEntryEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
    /** The item at the end of the edge. */
    node?: Maybe<SubscriptionListEntry>;
};

export type SubscriptionListFilter = {
    __typename?: 'SubscriptionListFilter';
    orderId?: Maybe<Scalars['ID']>;
    productId?: Maybe<Scalars['ID']>;
    state: Array<SubscriptionState>;
};

export type SubscriptionListFilterInput = {
    orderId?: Maybe<Scalars['ID']>;
    productId?: Maybe<Scalars['ID']>;
    state?: Maybe<Array<SubscriptionState>>;
};

export type SubscriptionNote =
    | AttentionNote
    | EndedNote
    | NextPaymentNote
    | PausedNote
    | ProcessingNote;

export type SubscriptionPayment = PaymentInterface & {
    __typename?: 'SubscriptionPayment';
    remoteId?: Maybe<Scalars['String']>;
    invoiceNumber?: Maybe<Scalars['String']>;
    basePrice: Price;
    createdAt: Scalars['ISO8601DateTime'];
    discountableLoyaltyPrice: Price;
    discountedLoyaltyPrice: Price;
    paidDate?: Maybe<Scalars['ISO8601DateTime']>;
    price: Price;
    state: PaymentState;
    invoiceUrl?: Maybe<Scalars['String']>;
    contract?: Maybe<Contract>;
    dueDate: DateUnion;
    id: Scalars['ID'];
    /** @deprecated Use Contract field */
    subscriptionDetails?: Maybe<SubscriptionDetails>;
    marketingSubscriptionPaymentData: Array<HintModel>;
};

export type SubscriptionStack = {
    __typename?: 'SubscriptionStack';
    filter: SubscriptionListFilter;
    product: Product;
    quantity: Scalars['Int'];
};

export enum SubscriptionState {
    active = 'active',
    cancelled = 'cancelled',
    declined = 'declined',
    ended = 'ended',
    paused = 'paused',
    processing = 'processing',
}

export type SubscriptionSummary =
    | FilterButton
    | Section
    | StandaloneDiscountOffer
    | SwapDiscountOffer;

export type SubStep = {
    __typename?: 'SubStep';
    description?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    slug: Scalars['String'];
    state: Scalars['String'];
};

export type SupportRequest = {
    __typename?: 'SupportRequest';
    /** @deprecated Use grapql __typename instead */
    action: Scalars['String'];
    orderNumber?: Maybe<Scalars['String']>;
};

export type SurpriseDiscountOffer = {
    __typename?: 'SurpriseDiscountOffer';
    newBuyoutTerms?: Maybe<MonthsToOwn>;
    percentage?: Maybe<Scalars['Int']>;
};

export type SurpriseDiscountTerm = {
    __typename?: 'SurpriseDiscountTerm';
    available: Scalars['Boolean'];
    discountAmountInCents?: Maybe<Scalars['Int']>;
    discountPercentage?: Maybe<Scalars['Int']>;
    newPriceInCents?: Maybe<Scalars['Int']>;
    newPurchaseOptionAvailableSince?: Maybe<Scalars['ISO8601DateTime']>;
    newPurchaseOptionRequiredMonths?: Maybe<Scalars['Int']>;
    unavailabilityReason?: Maybe<SurpriseDiscountUnavailabilityReason>;
};

export enum SurpriseDiscountUnavailabilityReason {
    ALREADY_HAS_RECURRING_DISCOUNT = 'ALREADY_HAS_RECURRING_DISCOUNT',
    CONTRACT_IS_NOT_ACTIVE = 'CONTRACT_IS_NOT_ACTIVE',
    NOT_A_FLEX_CONTRACT = 'NOT_A_FLEX_CONTRACT',
    USER_ALREADY_USED_SURPRISE_DISCOUNT = 'USER_ALREADY_USED_SURPRISE_DISCOUNT',
}

export type Swap = {
    __typename?: 'Swap';
    cancelledAt?: Maybe<Scalars['ISO8601DateTime']>;
    completedAt?: Maybe<Scalars['ISO8601DateTime']>;
    contractId: Scalars['ID'];
    createdAt: Scalars['ISO8601DateTime'];
    expiresAt: Scalars['ISO8601DateTime'];
    id: Scalars['ID'];
    mixSubscription: Mix;
    newDevice: MixDevice;
    oldDevice: MixDevice;
    orderNumber: Scalars['ID'];
    shipmentLabels?: Maybe<Array<ShipmentLabel>>;
    shippingLabelUrl?: Maybe<Scalars['String']>;
    shippingProgress?: Maybe<MixSwapShippingProgress>;
    state: SwapState;
    trackingUrl?: Maybe<Scalars['String']>;
    user: User;
    userId: Scalars['ID'];
};

export enum SwapCancellationError {
    DEVICE_IS_NOT_AWAITING_RETURN = 'DEVICE_IS_NOT_AWAITING_RETURN',
    SWAP_IS_NOT_ONGOING = 'SWAP_IS_NOT_ONGOING',
    SWAP_NOT_FOUND = 'SWAP_NOT_FOUND',
}

export type SwapDiscountOffer = SubscriptionDiscountInterface & {
    __typename?: 'SwapDiscountOffer';
    discountPercentage: Scalars['Int'];
    minimalCartPrice: Price;
    price: Price;
    product: Product;
    subscriptionDetails: SubscriptionDetails;
};

export type SwappingTerms = {
    __typename?: 'SwappingTerms';
    available: Scalars['Boolean'];
    availableAfter?: Maybe<Scalars['ISO8601DateTime']>;
    timeUntilAvailable?: Maybe<Duration>;
    unavailabilityReason?: Maybe<SwapUnavailabilityReason>;
};

export enum SwapState {
    CANCELLED = 'CANCELLED',
    COMPLETED = 'COMPLETED',
    CREATED = 'CREATED',
    EXPIRED = 'EXPIRED',
    ONGOING = 'ONGOING',
}

export enum SwapUnavailabilityReason {
    CONTRACT_NOT_ACTIVE = 'CONTRACT_NOT_ACTIVE',
    FLAG_DISABLED = 'FLAG_DISABLED',
    NOT_ALLOWED = 'NOT_ALLOWED',
    SWAP_ALREADY_ONGOING = 'SWAP_ALREADY_ONGOING',
    TOO_EARLY = 'TOO_EARLY',
}

export type SyncCompanyLeadInput = {
    company_id: Scalars['Float'];
};

/** Autogenerated input type of SyncProductVariants */
export type SyncProductVariantsInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    companyIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of SyncProductVariants. */
export type SyncProductVariantsPayload = {
    __typename?: 'SyncProductVariantsPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    productVariants?: Maybe<Array<FlexDevice>>;
};

export type SyncShipmentFailure = {
    __typename?: 'SyncShipmentFailure';
    message: Scalars['String'];
};

export type SyncShipmentResultType = SyncShipmentSuccess | SyncShipmentFailure;

export type SyncShipmentSuccess = {
    __typename?: 'SyncShipmentSuccess';
    events: Array<ShipmentLifecycleEvent>;
    status: ShipmentLifecycleStatus;
};

/** Autogenerated input type of SyncSubscriptionStatus */
export type SyncSubscriptionStatusInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    subscriptionId: Scalars['ID'];
};

/** Autogenerated return type of SyncSubscriptionStatus. */
export type SyncSubscriptionStatusPayload = {
    __typename?: 'SyncSubscriptionStatusPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    subscriptionDetails: SubscriptionDetails;
};

/** Autogenerated input type of SyncUserPaymentMethods */
export type SyncUserPaymentMethodsInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SyncUserPaymentMethods. */
export type SyncUserPaymentMethodsPayload = {
    __typename?: 'SyncUserPaymentMethodsPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    errors?: Maybe<Array<Scalars['String']>>;
    paymentMethods?: Maybe<Array<PaymentMethod>>;
};

export type Task = {
    id: Scalars['ID'];
    createdAt: Scalars['DateTime'];
    status: TaskStatus;
    createdByUserId: Scalars['Int'];
    createdBy?: Maybe<User>;
};

export type TaskBundlesExportResult = TaskError | BundlesExportTask;

export type TaskError = {
    __typename?: 'TaskError';
    code: Scalars['Int'];
    message: Scalars['String'];
};

export type TaskProductsUploadResult = TaskError | ProductsUploadTask;

export type TaskRentalPlanExportResult = TaskError | RentalPlanExportTask;

export type TaskRentalPlanUploadResult = TaskError | RentalPlanUploadTask;

export enum TaskStatus {
    COMPLETED = 'COMPLETED',
    WAITING = 'WAITING',
    ACTIVE = 'ACTIVE',
    DELAYED = 'DELAYED',
    FAILED = 'FAILED',
    PAUSED = 'PAUSED',
    STUCK = 'STUCK',
    CANCELLED = 'CANCELLED',
}

export type TasksWhereInput = {
    type: TaskType;
};

export enum TaskType {
    RENTAL_PLAN_UPLOAD = 'RENTAL_PLAN_UPLOAD',
    RENTAL_PLAN_EXPORT = 'RENTAL_PLAN_EXPORT',
    BUNDLES_EXPORT = 'BUNDLES_EXPORT',
    PRODUCTS_UPLOAD = 'PRODUCTS_UPLOAD',
}

export type TaxInfoResult = {
    __typename?: 'TaxInfoResult';
    taxId: Scalars['String'];
    countryCode: Scalars['String'];
};

export type Team = {
    __typename?: 'Team';
    description?: Maybe<Scalars['String']>;
    editable?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    name: Scalars['String'];
    title: Scalars['String'];
};

export type TermsAndConditions = {
    __typename?: 'TermsAndConditions';
    version: Scalars['ID'];
    status: TermsAndConditionsStatus;
    content: Scalars['String'];
    summary: Scalars['String'];
    createdAt: Scalars['DateISOString'];
    updatedAt: Scalars['DateISOString'];
    publishedAt?: Maybe<Scalars['DateISOString']>;
    createdBy: Scalars['ID'];
    storeCodes: Array<Scalars['String']>;
    daysToDecide: Scalars['Int'];
    disabledFeatures: Array<Maybe<Feature>>;
    autoAccept: Scalars['Boolean'];
};

/** The connection type for TermsAndConditions. */
export type TermsAndConditionsConnection = {
    __typename?: 'TermsAndConditionsConnection';
    /** A list of edges. */
    edges?: Maybe<Array<Maybe<TermsAndConditionsEdge>>>;
    /** A list of nodes. */
    nodes?: Maybe<Array<Maybe<TermsAndConditionsLocales>>>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type TermsAndConditionsEdge = {
    __typename?: 'TermsAndConditionsEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
    /** The item at the end of the edge. */
    node?: Maybe<TermsAndConditionsLocales>;
};

export type TermsAndConditionsFilterInput = {
    storeCode?: Maybe<Scalars['String']>;
    status?: Maybe<TermsAndConditionsStatus>;
};

export type TermsAndConditionsLocales = {
    __typename?: 'TermsAndConditionsLocales';
    version: Scalars['ID'];
    status: TermsAndConditionsStatus;
    locales: Array<LocaleContent>;
    createdAt: Scalars['DateISOString'];
    updatedAt: Scalars['DateISOString'];
    publishedAt?: Maybe<Scalars['DateISOString']>;
    createdBy: Scalars['ID'];
    storeCodes: Array<Scalars['String']>;
    daysToDecide: Scalars['Int'];
    disabledFeatures: Array<Maybe<Feature>>;
    autoAccept: Scalars['Boolean'];
};

export enum TermsAndConditionsStatus {
    CREATED = 'CREATED',
    PUBLISHED = 'PUBLISHED',
}

export type TextNoteInterface = {
    text?: Maybe<Scalars['String']>;
};

export enum ThumbsDirection {
    up = 'up',
    down = 'down',
}

export type ThumbsOption = {
    __typename?: 'ThumbsOption';
    direction: ThumbsDirection;
};

export type ThumbsOptions = {
    __typename?: 'ThumbsOptions';
    options: Array<ThumbsOption>;
};

export type ThumbsResult = {
    __typename?: 'ThumbsResult';
    upVotes: Scalars['Int'];
    downVotes: Scalars['Int'];
};

export type ThumbsResults = {
    __typename?: 'ThumbsResults';
    thumbsResults: Array<ThumbsResult>;
    total: TotalResults;
};

export type Ticket = {
    __typename?: 'Ticket';
    ticket: Scalars['String'];
    pollMode: PollMode;
    payload: PollOptions;
    entityId: Scalars['String'];
    entityType: EntityType;
    meta: Scalars['JSON'];
};

export type TimeEstimate = {
    __typename?: 'TimeEstimate';
    atLeast: Duration;
    atMost?: Maybe<Duration>;
};

export type TimestampComparatorInput = {
    _gte?: Maybe<Scalars['DateTime']>;
    _lte?: Maybe<Scalars['DateTime']>;
};

export enum TimeUnit {
    MINUTES = 'MINUTES',
    HOURS = 'HOURS',
    DAYS = 'DAYS',
    MONTHS = 'MONTHS',
    YEARS = 'YEARS',
}

export type Tink = ProviderInterface & {
    __typename?: 'Tink';
    tracingId: Scalars['String'];
    xs2aSessionKey: Scalars['String'];
    xs2aTransactionId: Scalars['String'];
};

export type TncDocumentInfo = {
    documentId: Scalars['String'];
    eventType: TncEventType;
};

export type TncDocumentResult = {
    __typename?: 'TNCDocumentResult';
    id: Scalars['String'];
    name: Scalars['String'];
    link: Scalars['String'];
    status?: Maybe<Scalars['String']>;
    deadline: Scalars['DateTime'];
};

export enum TncEventType {
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
}

export type ToggleDashboardAccessInput = {
    /** CompanyId that the access should be toggled for. */
    companyId: Scalars['Int'];
};

export type ToggleKitStateInput = {
    uuid: Scalars['String'];
    state: KitState;
};

export enum TopUpCancellationReasons {
    DUPLICATE = 'DUPLICATE',
    FRAUDULENT = 'FRAUDULENT',
    REQUESTED_BY_CUSTOMER = 'REQUESTED_BY_CUSTOMER',
    ABANDONED = 'ABANDONED',
}

export enum TopUpStatusValues {
    ACCEPTED = 'ACCEPTED',
    CONFIRMED = 'CONFIRMED',
    EXECUTED = 'EXECUTED',
    DECLINED = 'DECLINED',
    CANCELLED = 'CANCELLED',
}

export enum TosDecision {
    ACCEPTED = 'ACCEPTED',
    DECLINED = 'DECLINED',
}

export type TosUpdate = {
    __typename?: 'TosUpdate';
    createdAt: Scalars['ISO8601DateTime'];
    decision: TosDecision;
    version: Scalars['ID'];
};

export type TotalResults = {
    __typename?: 'TotalResults';
    voteCount: Scalars['Int'];
    userCount: Scalars['Int'];
};

export type TrackApplePushProvisioningInfo = {
    cardId: Scalars['String'];
};

export type TrackApplePushProvisioningResult = {
    __typename?: 'TrackApplePushProvisioningResult';
    userId: Scalars['String'];
};

export type TrackingInformationProperties = {
    __typename?: 'TrackingInformationProperties';
    carrier?: Maybe<ShipmentCarrier>;
    trackingNumber?: Maybe<Scalars['String']>;
};

export type Transaction = {
    __typename?: 'Transaction';
    ID: Scalars['String'];
    amount: Scalars['Float'];
    direction: TransactionDirection;
    effectiveDate: Scalars['ISO8601DateTime'];
    createdAt: Scalars['ISO8601DateTime'];
    transactionResources: Array<ResourceTransaction>;
    movement: Movement;
};

export type TransactionActionModel = {
    __typename?: 'TransactionActionModel';
    uuid: Scalars['ID'];
    paymentOrderUuid: Scalars['String'];
    transactionUuid?: Maybe<Scalars['String']>;
    userId: Scalars['String'];
    actionType: ActionType;
    actionReason: Scalars['String'];
    actionComment: Scalars['String'];
    amount: Scalars['Float'];
    currency: Scalars['String'];
    status: ActionStatus;
    scheduledAt?: Maybe<Scalars['DateTime']>;
    createdAt: Scalars['DateTime'];
};

export type TransactionConnection = {
    __typename?: 'TransactionConnection';
    totalCount: Scalars['Int'];
    edges?: Maybe<Array<TransactionEdge>>;
    nodes?: Maybe<Array<Transaction>>;
    pageInfo: PageInfo;
};

export enum TransactionDirection {
    IN = 'IN',
    OUT = 'OUT',
}

export type TransactionEdge = {
    __typename?: 'TransactionEdge';
    cursor: Scalars['ID'];
    node?: Maybe<Transaction>;
};

export enum TransactionErrorCode {
    OPERATION_FAILED = 'OPERATION_FAILED',
    PROVIDER_COMMUNICATION_PROBLEM = 'PROVIDER_COMMUNICATION_PROBLEM',
    INVALID_CAPTURE_AMOUNT = 'INVALID_CAPTURE_AMOUNT',
    INVALID_REFUND_AMOUNT = 'INVALID_REFUND_AMOUNT',
    TRANSACTION_NOT_REFUNDABLE = 'TRANSACTION_NOT_REFUNDABLE',
    BLOCKED_BY_RISK_CHECK = 'BLOCKED_BY_RISK_CHECK',
    TRANSACTION_DECLINED = 'TRANSACTION_DECLINED',
    TRANSACTION_EXPIRED = 'TRANSACTION_EXPIRED',
    TRANSACTION_CANCELLED = 'TRANSACTION_CANCELLED',
    TRANSACTION_CANCELLED_BY_USER = 'TRANSACTION_CANCELLED_BY_USER',
    BANKCARD_INVALID = 'BANKCARD_INVALID',
    BANKCARD_EXPIRED = 'BANKCARD_EXPIRED',
    BANKCARD_FRAUDULENT = 'BANKCARD_FRAUDULENT',
    BANKCARD_UNSUPPORTED = 'BANKCARD_UNSUPPORTED',
    BANKCARD_CLAIMED_AS_LOST = 'BANKCARD_CLAIMED_AS_LOST',
    BANKCARD_CLAIMED_AS_STOLEN = 'BANKCARD_CLAIMED_AS_STOLEN',
    BANKCARD_INSUFFICIENT_FUNDS = 'BANKCARD_INSUFFICIENT_FUNDS',
    BANKCARD_3DS_FAILED = 'BANKCARD_3DS_FAILED',
    BANKCARD_3DS_DECLINED = 'BANKCARD_3DS_DECLINED',
    PAYPAL_ACCOUNT_CLOSED = 'PAYPAL_ACCOUNT_CLOSED',
    SEPA_INVALID_IBAN = 'SEPA_INVALID_IBAN',
    SEPA_INVALID_BIC = 'SEPA_INVALID_BIC',
}

export type TransactionModel = {
    __typename?: 'TransactionModel';
    amount: Scalars['Money'];
    currency: Scalars['String'];
    uuid: Scalars['ID'];
    accountFrom?: Maybe<Scalars['String']>;
    accountTo?: Maybe<Scalars['String']>;
    parent?: Maybe<Scalars['String']>;
    type: Scalars['String'];
    status: Scalars['String'];
    gatewayResponse?: Maybe<Scalars['JSON']>;
    createdAt: Scalars['DateTime'];
    failedReason?: Maybe<Scalars['String']>;
    refundReason?: Maybe<RefundReason>;
    proxied: Scalars['Boolean'];
};

export type TranslateInputType = {
    placeholder: Scalars['String'];
    en: Scalars['String'];
    de: Scalars['String'];
    nl: Scalars['String'];
    es: Scalars['String'];
};

export type TranslateUpdateInputType = {
    placeholder?: Maybe<Scalars['String']>;
    en?: Maybe<Scalars['String']>;
    de?: Maybe<Scalars['String']>;
    nl?: Maybe<Scalars['String']>;
    es?: Maybe<Scalars['String']>;
};

export type TriggerEventMetadata = {
    __typename?: 'TriggerEventMetadata';
    name: Scalars['String'];
    rawPayload: Scalars['JSON'];
    topic: Scalars['String'];
};

export type UnBlockCardInfo = {
    cardId: Scalars['String'];
};

/** Autogenerated input type of UnlockUser */
export type UnlockUserInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    userId: Scalars['ID'];
};

/** Autogenerated return type of UnlockUser. */
export type UnlockUserPayload = {
    __typename?: 'UnlockUserPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    user: User;
};

export type UpdateAddonMainProductProviderInput = {
    name: Scalars['String'];
    active: Scalars['Boolean'];
};

export type UpdateAddonProviderInput = {
    id: Scalars['ID'];
    name: Scalars['String'];
};

export type UpdateAddonsApiEventInput = {
    id: Scalars['String'];
    messageValue?: Maybe<Scalars['String']>;
    archived?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of UpdateAddress */
export type UpdateAddressInput = {
    additionalInfo?: Maybe<Scalars['String']>;
    billingAddress?: Maybe<Scalars['Boolean']>;
    city?: Maybe<Scalars['String']>;
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    countryIso?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    line1?: Maybe<Scalars['String']>;
    line2?: Maybe<Scalars['String']>;
    shippingAddress?: Maybe<Scalars['Boolean']>;
    stateAbbr?: Maybe<Scalars['String']>;
    zip?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateAddress. */
export type UpdateAddressPayload = {
    __typename?: 'UpdateAddressPayload';
    address: Address;
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateAgreedPurchasePrice */
export type UpdateAgreedPurchasePriceInput = {
    agreedPurchasePriceInCents: Scalars['Int'];
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    companyId: Scalars['ID'];
    contractIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of UpdateAgreedPurchasePrice. */
export type UpdateAgreedPurchasePricePayload = {
    __typename?: 'UpdateAgreedPurchasePricePayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contracts: Array<Contract>;
};

export type UpdateAllAssociationInput = {
    mainProductSku: Scalars['String'];
    storeCode: Scalars['String'];
    addonsSku?: Maybe<Array<Maybe<Scalars['String']>>>;
    providers?: Maybe<Array<UpdateAddonMainProductProviderInput>>;
    digitalOtpProductSkus?: Maybe<Array<Scalars['String']>>;
};

export type UpdateAmlFollowUpInfo = {
    /** Device id */
    deviceId: Scalars['String'];
    email?: Maybe<Scalars['String']>;
};

export type UpdateAmlFollowUpResult = {
    __typename?: 'UpdateAmlFollowUpResult';
    changeRequestId: Scalars['String'];
    stringToSign: Scalars['String'];
};

export type UpdateAssetInput = {
    name?: Maybe<Scalars['String']>;
    employeeId?: Maybe<Scalars['String']>;
    companyId?: Maybe<Scalars['Int']>;
    orderNumber?: Maybe<Scalars['String']>;
    contractId?: Maybe<Scalars['String']>;
    assetStatus?: Maybe<Scalars['String']>;
    categoryId?: Maybe<Scalars['String']>;
    brandName?: Maybe<Scalars['String']>;
    serialNumber?: Maybe<Scalars['String']>;
    price?: Maybe<Scalars['String']>;
    terminatedAt?: Maybe<Scalars['DateTime']>;
    activatedAt?: Maybe<Scalars['DateTime']>;
    uuid: Scalars['String'];
};

export type UpdateAssociationAddonsInput = {
    id: Scalars['ID'];
    addonsSku?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateAssociationProviderInput = {
    associationId: Scalars['ID'];
    provider: UpdateAddonMainProductProviderInput;
};

export type UpdateCompanyDataInput = {
    companyTypeId?: Maybe<Scalars['Int']>;
    hrv?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    size?: Maybe<CompanySize>;
    status?: Maybe<Scalars['String']>;
    ust?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateCompany */
export type UpdateCompanyInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    data: UpdateCompanyDataInput;
    id: Scalars['ID'];
};

/** Autogenerated return type of UpdateCompany. */
export type UpdateCompanyPayload = {
    __typename?: 'UpdateCompanyPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    company: Company;
};

export type UpdateCompanyStatusInput = {
    status: CompanyStatus;
};

/** Autogenerated input type of UpdateContractTermsAndConditions */
export type UpdateContractTermsAndConditionsInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contractId: Scalars['ID'];
    decision: TosDecision;
    version: Scalars['ID'];
};

/** Autogenerated return type of UpdateContractTermsAndConditions. */
export type UpdateContractTermsAndConditionsPayload = {
    __typename?: 'UpdateContractTermsAndConditionsPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract: Contract;
};

export type UpdateDaysToDecideInput = {
    version: Scalars['ID'];
    daysToDecide: Scalars['Int'];
};

export type UpdateDeviceMonitoringInfo = {
    status: DeviceMonitoringStatus;
    consentId: Scalars['String'];
};

export type UpdateDigitalOtpProductInput = {
    id: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    imageUrl?: Maybe<Scalars['String']>;
    infoLink?: Maybe<Scalars['String']>;
    redeemLink?: Maybe<Scalars['String']>;
    technicalSpecs?: Maybe<Scalars['String']>;
    policyApprovalRequired?: Maybe<Scalars['Boolean']>;
    eula?: Maybe<Scalars['String']>;
    detailedDescription?: Maybe<Scalars['String']>;
    sourceImages?: Maybe<Array<Scalars['String']>>;
    name?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
    sku?: Maybe<Scalars['String']>;
    providerName?: Maybe<DigitalProductProvider>;
};

export type UpdateDigitalOtpProductResult = {
    __typename?: 'UpdateDigitalOtpProductResult';
    id: Scalars['String'];
};

export type UpdateDigitalOtpVariantInput = {
    id: Scalars['String'];
    originalPriceInCents?: Maybe<Scalars['Int']>;
    priceInCents?: Maybe<Scalars['Int']>;
    currency?: Maybe<Scalars['String']>;
    durationInMonths?: Maybe<Scalars['Int']>;
};

export type UpdateDigitalOtpVariantResult = {
    __typename?: 'UpdateDigitalOtpVariantResult';
    id: Scalars['String'];
};

export type UpdateDigitalProductLocalizationInput = {
    id: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    imageUrl?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    infoLink?: Maybe<Scalars['String']>;
    redeemLink?: Maybe<Scalars['String']>;
    technicalSpecs?: Maybe<Scalars['String']>;
    eula?: Maybe<Scalars['String']>;
    detailedDescription?: Maybe<Scalars['String']>;
    sourceImages?: Maybe<Array<Scalars['String']>>;
};

export type UpdateDigitalProductLocalizationResult = {
    __typename?: 'UpdateDigitalProductLocalizationResult';
    id: Scalars['String'];
};

export type UpdateDisabledFeaturesInput = {
    version: Scalars['ID'];
    disabledFeatures: Array<Maybe<Feature>>;
};

export type UpdateEmployeeInput = {
    uuid: Scalars['String'];
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    externalId?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    address?: Maybe<WorkUpdateAddressInput>;
};

export type UpdateImageInput = {
    url: Scalars['ID'];
};

export type UpdateKitInput = {
    uuid: Scalars['String'];
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    storeCode?: Maybe<Scalars['String']>;
};

export type UpdateLegacyCompanyInput = {
    id: Scalars['Float'];
    name?: Maybe<Scalars['String']>;
    /** Needed to validate UST field. */
    store?: Maybe<Scalars['String']>;
    company_type_id?: Maybe<Scalars['Float']>;
    ust?: Maybe<Scalars['String']>;
    size?: Maybe<CompanySize>;
    billing_email?: Maybe<Scalars['String']>;
    hrv?: Maybe<Scalars['String']>;
    viban?: Maybe<Scalars['String']>;
    rent_approximation?: Maybe<Scalars['String']>;
    legal_representative_email?: Maybe<Scalars['String']>;
    status?: Maybe<CompanyStatus>;
    website?: Maybe<Scalars['String']>;
};

export type UpdateLocaleInput = {
    version: Scalars['ID'];
    locale: Scalars['String'];
    content: Scalars['String'];
    summary: Scalars['String'];
};

export type UpdateMobilePhoneInfo = {
    /** Country code of mobile number */
    countryCode: Scalars['String'];
    /** Body of mobile number */
    number: Scalars['String'];
    /** Device id */
    deviceId: Scalars['String'];
};

export type UpdateMobilePhoneResult = {
    __typename?: 'UpdateMobilePhoneResult';
    id?: Maybe<Scalars['String']>;
    number?: Maybe<Scalars['String']>;
    verified?: Maybe<Scalars['Boolean']>;
    changeRequestId?: Maybe<Scalars['String']>;
    stringToSign?: Maybe<Scalars['String']>;
};

export type UpdateModel = {
    __typename?: 'UpdateModel';
    /** Number of rows affected */
    affected: Scalars['Float'];
};

/** Input type for updating an account for a contract in the Billing system */
export type UpdatePaymentMethodForContractInput = {
    contractId: Scalars['ID'];
    billingAccountId: Scalars['ID'];
};

export type UpdatePaymentMethodForContractPayload = {
    __typename?: 'UpdatePaymentMethodForContractPayload';
    success: Scalars['Boolean'];
};

export type UpdatePinInfo = {
    deviceId: Scalars['String'];
    encryptedData: Scalars['String'];
    signature: Scalars['String'];
    keyId: Scalars['String'];
    cardId: Scalars['String'];
};

export type UpdatePinPublicKeyInfo = {
    cardId: Scalars['String'];
};

export type UpdatePinPublicKeyResult = {
    __typename?: 'UpdatePinPublicKeyResult';
    kid: Scalars['String'];
    kty: Scalars['DateTime'];
    use: Scalars['String'];
    alg: Scalars['DateTime'];
    n: Scalars['String'];
    e: Scalars['String'];
};

export type UpdatePinResult = {
    __typename?: 'UpdatePinResult';
    success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateProduct */
export type UpdateProductInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    sku: Scalars['ID'];
    variants?: Maybe<Array<UpdateVariantInput>>;
};

export type UpdateProductLogisticsError = GenericError & {
    __typename?: 'UpdateProductLogisticsError';
    code: Scalars['String'];
    message: Scalars['String'];
};

export type UpdateProductLogisticsSuccess = {
    __typename?: 'UpdateProductLogisticsSuccess';
    success: Scalars['Boolean'];
};

export type UpdateProductLogisticsSuccessOrError =
    | UpdateProductLogisticsSuccess
    | UpdateProductLogisticsError;

/** Autogenerated return type of UpdateProduct. */
export type UpdateProductPayload = {
    __typename?: 'UpdateProductPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    product: Product;
};

export type UpdateProductSpecificationsError = GenericError & {
    __typename?: 'UpdateProductSpecificationsError';
    code: Scalars['String'];
    message: Scalars['String'];
};

export type UpdateProductSpecificationsSuccess = {
    __typename?: 'UpdateProductSpecificationsSuccess';
    success: Scalars['Boolean'];
};

export type UpdateProductSpecificationsSuccessOrError =
    | UpdateProductSpecificationsSuccess
    | UpdateProductSpecificationsError;

export type UpdateScaChallengesInfo = {
    /**
     * Follow these steps to generate the signature ->
     * https://docs.solarisbank.com/core/api/v1/#Rsj2t2Uk-put-verify-device-challenge
     */
    signature: Scalars['String'];
    deviceId: Scalars['String'];
    changeRequestId: Scalars['String'];
    scaChallengeId: Scalars['String'];
};

export type UpdateStoreCodesInput = {
    version: Scalars['ID'];
    storeCodes: Array<Scalars['String']>;
};

/** Autogenerated input type of UpdateSubscription */
export type UpdateSubscriptionInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    subscriptionId: Scalars['ID'];
};

/** Autogenerated return type of UpdateSubscription. */
export type UpdateSubscriptionPayload = {
    __typename?: 'UpdateSubscriptionPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    subscriptionDetails: SubscriptionDetails;
};

/** Autogenerated input type of UpdateSubscriptionTermsAndConditions */
export type UpdateSubscriptionTermsAndConditionsInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    decision: TosDecision;
    subscriptionId: Scalars['ID'];
    version: Scalars['ID'];
};

/** Autogenerated return type of UpdateSubscriptionTermsAndConditions. */
export type UpdateSubscriptionTermsAndConditionsPayload = {
    __typename?: 'UpdateSubscriptionTermsAndConditionsPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    errors: Array<Scalars['String']>;
    subscriptionDetails: SubscriptionDetails;
};

export type UpdateTncDocumentResult = {
    __typename?: 'UpdateTNCDocumentResult';
    id: Scalars['String'];
};

export type UpdateTrackingInformation = {
    __typename?: 'UpdateTrackingInformation';
    name: ShipmentOperationName;
    newProperties: TrackingInformationProperties;
    oldProperties: TrackingInformationProperties;
};

export type UpdateTrackingInformationFailure = {
    __typename?: 'UpdateTrackingInformationFailure';
    message: Scalars['String'];
};

export type UpdateTrackingInformationResultType =
    | UpdateTrackingInformationSuccess
    | UpdateTrackingInformationFailure;

export type UpdateTrackingInformationSuccess = {
    __typename?: 'UpdateTrackingInformationSuccess';
    events: Array<ShipmentLifecycleEvent>;
    status: ShipmentLifecycleStatus;
    carrier: ShipmentCarrier;
    trackingNumber: Scalars['String'];
};

export type UpdateUserDataInput = {
    birthdate?: Maybe<Scalars['ISO8601Date']>;
    email?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    data: UpdateUserDataInput;
    userId: Scalars['ID'];
};

/** Autogenerated input type of UpdateUserNewsletterOptInState */
export type UpdateUserNewsletterOptInStateInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    newState: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateUserNewsletterOptInState. */
export type UpdateUserNewsletterOptInStatePayload = {
    __typename?: 'UpdateUserNewsletterOptInStatePayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    user: User;
};

/** Autogenerated return type of UpdateUser. */
export type UpdateUserPayload = {
    __typename?: 'UpdateUserPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    user: User;
};

/** Autogenerated input type of UpdateUserTeams */
export type UpdateUserTeamsInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    teamIds: Array<Scalars['ID']>;
    userId: Scalars['ID'];
};

/** Autogenerated return type of UpdateUserTeams. */
export type UpdateUserTeamsPayload = {
    __typename?: 'UpdateUserTeamsPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    user: User;
};

export type UpdateVariantInput = {
    images?: Maybe<Array<UpdateImageInput>>;
    sku: Scalars['ID'];
};

export type UpgradeRentalPlan = {
    __typename?: 'UpgradeRentalPlan';
    availablePlans?: Maybe<Array<RentalPlan>>;
};

export type Upsell = Operation & {
    __typename?: 'Upsell';
    completedAt?: Maybe<Scalars['ISO8601DateTime']>;
    owner: User;
    startedAt: Scalars['ISO8601DateTime'];
    startedBy: User;
    state: InboundShipmentStates;
    updates: Array<FlowUpdate>;
};

export type UpsellTerm = {
    __typename?: 'UpsellTerm';
    available: Scalars['Boolean'];
    availableFrom?: Maybe<Scalars['ISO8601DateTime']>;
    unavailabilityReason?: Maybe<ReturnUnavailabilityReason>;
};

/** Input for the usePaymentMethod mutation. */
export type UsePaymentMethodInput = {
    /** ID of the method to use. */
    paymentMethodId: Scalars['ID'];
    /** The order for which to use the method. */
    orderNumber: Scalars['ID'];
    /** Where to redirect the customer if authorization succeeds. */
    successRedirectUrl: Scalars['String'];
    /** Where to redirect the customer if authorization fails. */
    errorRedirectUrl: Scalars['String'];
    /** Where to redirect the customer if authorization is cancelled. */
    cancelRedirectUrl: Scalars['String'];
    /** 3-D Secure-related data, sent directly to the payment provider. */
    threeDSData?: Maybe<PaymentMethod3dsData>;
};

/** Result of the usePaymentMethod mutation. */
export type UsePaymentMethodResult = {
    __typename?: 'UsePaymentMethodResult';
    /** URL to redirect the user to if authorization is required by the payment provider. */
    redirectUrl?: Maybe<Scalars['String']>;
};

export type User = {
    __typename?: 'User';
    id: Scalars['ID'];
    groupBilling?: Maybe<GroupBilling>;
    addresses?: Maybe<Array<Address>>;
    anotherOrderPayment?: Maybe<OrderPayment>;
    billingAddress?: Maybe<Address>;
    birthdate: Scalars['String'];
    canBeDeleted: Scalars['Boolean'];
    company?: Maybe<Company>;
    country?: Maybe<Country>;
    createdAt: Scalars['ISO8601DateTime'];
    defaultStore: Store;
    deletionUnavailabilityAdditionalInfo: UserDeletionUnavailabilityAdditionalInfo;
    deletionUnavailabilityReasons: Array<UserDeletionUnavailabilityReason>;
    devices?: Maybe<AuthDeviceConnection>;
    email: Scalars['String'];
    emailVerified: Scalars['Boolean'];
    failedLoginAttempts: Scalars['Int'];
    firstName: Scalars['String'];
    hasContractsOrSubscriptions: Scalars['Boolean'];
    /** @deprecated use VerificationSummary.verification_check_available type */
    idCheckAvailable: Scalars['Boolean'];
    /** @deprecated use VerificationSummary.verification_check_required type */
    idCheckRequired: Scalars['Boolean'];
    lastName: Scalars['String'];
    locked: Scalars['Boolean'];
    monthlyTotal: Price;
    newsletterOptInState: Scalars['Boolean'];
    /** when filter is cancellable US orders are excluded */
    orders?: Maybe<CartOrderConnection>;
    passwordResetForced?: Maybe<Scalars['Boolean']>;
    paymentMethods: Array<PaymentMethod>;
    payments?: Maybe<PaymentsUnionConnection>;
    permissions: Scalars['String'];
    personalIdentifications?: Maybe<Array<PersonalIdentification>>;
    phone?: Maybe<Scalars['String']>;
    phoneVerified: Scalars['Boolean'];
    resetPasswordSentAt?: Maybe<Scalars['ISO8601DateTime']>;
    shippingAddress?: Maybe<Address>;
    shouldUseSalesforce: InfraRoutingDecision;
    signupLanguage: Scalars['String'];
    subscriptionAllowance?: Maybe<Scalars['Int']>;
    subscriptionLimit?: Maybe<Scalars['Int']>;
    subscriptionSummary: Array<SubscriptionSummary>;
    subscriptionsTotal?: Maybe<Scalars['Int']>;
    swapDiscountOffer?: Maybe<SwapDiscountOffer>;
    teams?: Maybe<Array<Team>>;
    type: UserType;
    userConsents: Array<UserConsent>;
    /** @deprecated use VerificationSummary type */
    verificationState: UserVerificationState;
    verificationSummary: VerificationSummary;
    verifications?: Maybe<VerificationConnection>;
    waitingListTickets?: Maybe<Array<WaitingListTicket>>;
    /** @deprecated use VerificationSummary.BankAccountSnapshotInitData type */
    xs2a: Xs2a;
    activeSubscriptionValue: Price;
    anotherReferralContract?: Maybe<Flex>;
    contracts: ContractConnection;
    eligibleReferral: Scalars['Boolean'];
    mixSummary?: Maybe<MixSummary>;
    pendingSubscriptionValue: Price;
    loyaltyBalance: Array<LoyaltyUserBalanceResult>;
    hasGroverCashHistory: Scalars['Boolean'];
    notifications: SendResultConnection;
    votes?: Maybe<VotesConnection>;
    referralCode: Scalars['String'];
    guestConnections: Array<ConnectionModel>;
    hostConnection?: Maybe<ConnectionModel>;
    hostConnections: Array<ConnectionModel>;
};

export type UserAnotherOrderPaymentArgs = {
    orderNumber: Scalars['String'];
};

export type UserDevicesArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    clientDeviceId?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

export type UserOrdersArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    filter?: Maybe<OrdersFilterInput>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

export type UserPaymentsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    filter?: Maybe<PaymentsFilterInput>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

export type UserVerificationsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

export type UserAnotherReferralContractArgs = {
    orderNumber: Scalars['String'];
};

export type UserContractsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    filter?: Maybe<ContractFilterInput>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

export type UserLoyaltyBalanceArgs = {
    countries: Array<LoyaltyCountry>;
};

export type UserNotificationsArgs = {
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

export type UserVotesArgs = {
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    order?: Maybe<VoteOrderInput>;
    where?: Maybe<VoteWhereInput>;
};

export type UserActionMetadata = {
    __typename?: 'UserActionMetadata';
    cancellationReason?: Maybe<ContractCancellationReason>;
    user: User;
};

export type UserBar = {
    __typename?: 'UserBar';
    loginButtonText: Scalars['String'];
    signupButtonText: Scalars['String'];
    accountLink: HeaderLink;
    user?: Maybe<HeaderUser>;
    dashboardLinks?: Maybe<Array<HeaderLink>>;
    accountMenuLinks?: Maybe<Array<AccountMenuLink>>;
    hintLink?: Maybe<AccountMenuHint>;
};

/** The connection type for User. */
export type UserConnection = {
    __typename?: 'UserConnection';
    /** A list of edges. */
    edges?: Maybe<Array<Maybe<UserEdge>>>;
    /** A list of nodes. */
    nodes?: Maybe<Array<Maybe<User>>>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type UserConsent = {
    __typename?: 'UserConsent';
    description: Scalars['String'];
    id: Scalars['ID'];
    name: Scalars['String'];
    value?: Maybe<UserConsentValue>;
};

export enum UserConsentValue {
    NO = 'NO',
    NONE = 'NONE',
    YES = 'YES',
}

export type UserDeletionUnavailabilityAdditionalInfo = {
    __typename?: 'UserDeletionUnavailabilityAdditionalInfo';
    activeAllocations: Array<Scalars['ID']>;
    activeSalesforceSubscriptions: Array<Scalars['ID']>;
    nonTerminalSubscriptions: Array<Scalars['ID']>;
    processingOrders: Array<Scalars['ID']>;
};

export enum UserDeletionUnavailabilityReason {
    HAS_ACTIVE_ALLOCATIONS = 'HAS_ACTIVE_ALLOCATIONS',
    HAS_ACTIVE_SALESFORCE_SUBSCRIPTIONS = 'HAS_ACTIVE_SALESFORCE_SUBSCRIPTIONS',
    HAS_NON_TERMINAL_DASHBOARD_SUBSCRIPTIONS = 'HAS_NON_TERMINAL_DASHBOARD_SUBSCRIPTIONS',
    HAS_PROCESSING_ORDERS = 'HAS_PROCESSING_ORDERS',
    IS_SOFT_DELETED = 'IS_SOFT_DELETED',
}

/** An edge in a connection. */
export type UserEdge = {
    __typename?: 'UserEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
    /** The item at the end of the edge. */
    node?: Maybe<User>;
};

export type UserShippingSummaryType = {
    __typename?: 'UserShippingSummaryType';
    userId: Scalars['String'];
    qualitative: QualitativeShippingSummaryType;
    quantitative: QuantitativeShippingSummaryType;
};

export type UsersWhereInput = {
    and?: Maybe<Array<UsersWhereInput>>;
    companyName?: Maybe<StringComparisonInput>;
    email?: Maybe<StringComparisonInput>;
    id?: Maybe<IntegerComparisonInput>;
    name?: Maybe<StringComparisonInput>;
    phoneNumber?: Maybe<StringComparisonInput>;
    shouldUseSalesforce?: Maybe<InfraRoutingDecisionComparisonInput>;
    type?: Maybe<UserTypeComparisonInput>;
};

export enum UserType {
    BUSINESS = 'BUSINESS',
    PERSONAL = 'PERSONAL',
}

export type UserTypeComparatorInput = {
    _eq?: Maybe<UserTypes>;
    _neq?: Maybe<UserTypes>;
};

export type UserTypeComparisonInput = {
    _eq?: Maybe<UserType>;
    _neq?: Maybe<UserType>;
};

export enum UserTypes {
    private = 'private',
    business = 'business',
}

export enum UserVerificationState {
    FAILED = 'FAILED',
    IN_PROGRESS = 'IN_PROGRESS',
    NOT_STARTED = 'NOT_STARTED',
    VERIFIED = 'VERIFIED',
}

/** Autogenerated input type of UseSurpriseDiscount */
export type UseSurpriseDiscountInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contractId: Scalars['ID'];
};

/** Autogenerated return type of UseSurpriseDiscount. */
export type UseSurpriseDiscountPayload = {
    __typename?: 'UseSurpriseDiscountPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    contract: Contract;
};

export type UuidModel = {
    __typename?: 'UuidModel';
    uuid: Scalars['ID'];
};

export type ValidationResult = {
    valid: Scalars['Boolean'];
};

export type Variant = {
    __typename?: 'Variant';
    sku: Scalars['ID'];
    preorder?: Maybe<Preorder>;
    available?: Maybe<Scalars['Boolean']>;
    colorCodes?: Maybe<Array<Scalars['String']>>;
    colorTitle?: Maybe<Scalars['String']>;
    coreAttribute?: Maybe<Scalars['String']>;
    deliveryEstimates?: Maybe<DeliveryEstimates>;
    eans?: Maybe<Array<Scalars['String']>>;
    id: Scalars['ID'];
    imageUrl: Scalars['String'];
    mixable: Scalars['Boolean'];
    originalImageUrl: Scalars['String'];
    originalPictures: Array<Scalars['String']>;
    product?: Maybe<Product>;
    upcs?: Maybe<Array<Scalars['String']>>;
    flags?: Maybe<Array<VariantFlag>>;
};

export type VariantFlag = {
    __typename?: 'VariantFlag';
    id: Scalars['ID'];
    variantSku: Scalars['String'];
    name: Scalars['String'];
    store: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    isActive: Scalars['Boolean'];
    createdAt: Scalars['DateTime'];
    updatedAt?: Maybe<Scalars['DateTime']>;
    variant: Variant;
};

export type VariantFlagCreateInput = {
    name: Scalars['String'];
    variantSku: Scalars['String'];
    store: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    isActive: Scalars['Boolean'];
};

export type VariantFlagCreateManyOutput = {
    __typename?: 'VariantFlagCreateManyOutput';
    numberOfCreatedRecords: Scalars['Int'];
};

export type VariantFlagOrderByInput = {
    name?: Maybe<Scalars['String']>;
    variantSku?: Maybe<Scalars['String']>;
    store?: Maybe<Scalars['String']>;
    isActive?: Maybe<Scalars['Boolean']>;
};

export type VariantFlagPaginationOutput = {
    __typename?: 'VariantFlagPaginationOutput';
    totalCount: Scalars['Int'];
    rows: Array<VariantFlag>;
};

export type VariantFlagUpdateInput = {
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    store?: Maybe<Scalars['String']>;
    isActive?: Maybe<Scalars['Boolean']>;
};

export type VariantFlagWhereInput = {
    name?: Maybe<Scalars['String']>;
    variantSku?: Maybe<Scalars['String']>;
    store?: Maybe<Scalars['String']>;
    isActive?: Maybe<Scalars['Boolean']>;
};

export type Verification = {
    __typename?: 'Verification';
    checkProviderReport: CheckProviderReport;
    completedAt?: Maybe<Scalars['ISO8601DateTime']>;
    createdAt: Scalars['ISO8601DateTime'];
    id: Scalars['ID'];
    processedBy?: Maybe<User>;
    reviewReasons: Array<Scalars['String']>;
    reviews: Array<VerificationReview>;
    state: VerificationState;
    user: User;
    waitingForDays: Scalars['Int'];
};

export type VerificationCheck = {
    __typename?: 'VerificationCheck';
    id: Scalars['ID'];
    provider: VerificationProvider;
    providerProduct: Scalars['String'];
    remoteId: Scalars['ID'];
    result?: Maybe<ProviderCheckResult>;
    verification?: Maybe<Verification>;
    verificationType: VerificationCheckType;
};

export enum VerificationCheckType {
    BANK_ACCOUNT_SNAPSHOT = 'BANK_ACCOUNT_SNAPSHOT',
    ID_VERIFICATION = 'ID_VERIFICATION',
}

/** The connection type for Verification. */
export type VerificationConnection = {
    __typename?: 'VerificationConnection';
    /** A list of edges. */
    edges?: Maybe<Array<Maybe<VerificationEdge>>>;
    /** A list of nodes. */
    nodes?: Maybe<Array<Maybe<Verification>>>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export enum VerificationDecision {
    APPROVE = 'APPROVE',
    DECLINE = 'DECLINE',
}

export type VerificationDecisionInput = {
    decision: VerificationDecision;
    fieldsForCorrection?: Maybe<Array<Scalars['String']>>;
    verificationId: Scalars['ID'];
};

/** An edge in a connection. */
export type VerificationEdge = {
    __typename?: 'VerificationEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
    /** The item at the end of the edge. */
    node?: Maybe<Verification>;
};

export enum VerificationProvider {
    KONTOMATIK = 'KONTOMATIK',
    ONFIDO = 'ONFIDO',
    PLAID = 'PLAID',
    TINK = 'TINK',
}

export type VerificationRequest = {
    __typename?: 'VerificationRequest';
    id: Scalars['ID'];
    state: VerificationRequestState;
    verificationCheck?: Maybe<VerificationCheck>;
};

/** The connection type for VerificationRequest. */
export type VerificationRequestConnection = {
    __typename?: 'VerificationRequestConnection';
    /** A list of edges. */
    edges?: Maybe<Array<Maybe<VerificationRequestEdge>>>;
    /** A list of nodes. */
    nodes?: Maybe<Array<Maybe<VerificationRequest>>>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type VerificationRequestEdge = {
    __typename?: 'VerificationRequestEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
    /** The item at the end of the edge. */
    node?: Maybe<VerificationRequest>;
};

export enum VerificationRequestState {
    CLOSED = 'CLOSED',
    OPEN = 'OPEN',
}

export type VerificationReview = FieldReview;

export type VerificationsFilterInput = {
    state?: Maybe<VerificationState>;
    userId?: Maybe<Scalars['ID']>;
};

export enum VerificationState {
    FAILED = 'FAILED',
    PASSED = 'PASSED',
    PENDING = 'PENDING',
}

export type VerificationSummary = {
    __typename?: 'VerificationSummary';
    bankAccountSnapshotInitData?: Maybe<ProviderInterface>;
    verificationCheckAvailable: Scalars['Boolean'];
    verificationCheckRequired: Scalars['Boolean'];
    verificationRequests: VerificationRequestConnection;
    verificationState: UserVerificationState;
};

export type VerificationSummaryVerificationRequestsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

export type VerifyApplePushProvisioningResult = {
    __typename?: 'VerifyApplePushProvisioningResult';
    activationData: Scalars['String'];
    encryptedPassData: Scalars['String'];
    ephemeralPublicKey: Scalars['String'];
};

export type VerifyChangePinInfo = {
    /**
     * Follow these steps to generate the signature ->
     * https://docs.solarisbank.com/core/api/v1/#Rsj2t2Uk-put-verify-device-challenge
     */
    signature: Scalars['String'];
    deviceId: Scalars['String'];
    changeRequestId: Scalars['String'];
    cardId: Scalars['String'];
};

export type VerifyChangeRequestInfo = {
    /**
     * Follow these steps to generate the signature ->
     * https://docs.solarisbank.com/core/api/v1/#Rsj2t2Uk-put-verify-device-challenge
     */
    signature: Scalars['String'];
    deviceId: Scalars['String'];
    changeRequestId: Scalars['String'];
};

export type VerifyChangeRequestResult = {
    __typename?: 'VerifyChangeRequestResult';
    status: Scalars['String'];
};

export type VerifyDeviceAuthInfo = {
    /**
     * Follow these steps to verify the created challenge for the given device ->
     * https://docs.solarisbank.com/core/api/v1/#Rsj2t2Uk-put-verify-device-challenge
     */
    signature: Scalars['String'];
    challengeId: Scalars['String'];
};

export type VerifyDeviceAuthResult = {
    __typename?: 'VerifyDeviceAuthResult';
    status: Scalars['String'];
};

export type VerifyDeviceInfo = {
    /** Follow these steps to generate the signature -> https://docs.solarisbank.com/core/api/v1/#2EGVRkd1-put-verify-device */
    signature: Scalars['String'];
    deviceId: Scalars['String'];
};

export type VerifyDeviceResult = {
    __typename?: 'VerifyDeviceResult';
    status: Scalars['String'];
};

export type VerifyGooglePushProvisioningResult = {
    __typename?: 'VerifyGooglePushProvisioningResult';
    opc: Scalars['String'];
};

export type VerifyMobilePhoneInfo = {
    /** Country code of mobile number */
    countryCode: Scalars['String'];
    /** Body of mobile number */
    number: Scalars['String'];
    /** SMS token */
    token: Scalars['String'];
};

export type VerifyMobilePhoneResult = {
    __typename?: 'VerifyMobilePhoneResult';
    id?: Maybe<Scalars['String']>;
    number?: Maybe<Scalars['String']>;
    verified?: Maybe<Scalars['Boolean']>;
};

export type VerifyPsd2ChallengeInfo = {
    challengeId: Scalars['String'];
};

export type VerifyPsd2ChallengeResult = {
    __typename?: 'VerifyPSD2ChallengeResult';
    personId?: Maybe<Scalars['String']>;
    redirectUrl?: Maybe<Scalars['String']>;
};

export type Vote = Node & {
    __typename?: 'Vote';
    id: Scalars['ID'];
    voteSlug: Scalars['String'];
    comment?: Maybe<Scalars['String']>;
    userId: Scalars['String'];
    entityId?: Maybe<Scalars['String']>;
    entityType?: Maybe<EntityType>;
    meta?: Maybe<Scalars['JSON']>;
    pollVersion?: Maybe<PollVersion>;
    createdAt: Scalars['DateTime'];
    user?: Maybe<User>;
};

export type VoteOrderInput = {
    createdAt?: Maybe<OrderByDirection>;
    updatedAt?: Maybe<OrderByDirection>;
};

export type VotesConnection = {
    __typename?: 'VotesConnection';
    pageInfo: PageInfo;
    edges: Array<VotesEdge>;
};

export type VotesEdge = {
    __typename?: 'VotesEdge';
    node: Vote;
    cursor: Scalars['String'];
};

export type VoteWhereInput = {
    entityId?: Maybe<Scalars['String']>;
    entityType?: Maybe<EntityType>;
    userId?: Maybe<Scalars['String']>;
    voteSlug?: Maybe<Scalars['String']>;
    pollSlug?: Maybe<Scalars['String']>;
    withComments?: Maybe<Scalars['Boolean']>;
};

export type VoucherDiscountOffer = SubscriptionDiscountInterface & {
    __typename?: 'VoucherDiscountOffer';
    discountPercentage: Scalars['Int'];
    minimalCartPrice: Price;
    price: Price;
};

export type VoucherRedemptionInput = {
    code: Scalars['String'];
    userEmail?: Maybe<Scalars['String']>;
};

export type WaitingList = {
    __typename?: 'WaitingList';
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    name: Scalars['String'];
    slug: Scalars['String'];
    store: Store;
};

export type WaitingListTicket = {
    __typename?: 'WaitingListTicket';
    id: Scalars['ID'];
    state: WaitingListTicketState;
    waitingList: WaitingList;
};

export enum WaitingListTicketState {
    INVITED = 'INVITED',
    WAITING = 'WAITING',
}

export type WalletModel = {
    __typename?: 'WalletModel';
    uuid: Scalars['ID'];
    externalCustomer: Scalars['String'];
    strategy: Scalars['String'];
    chosenBillingDay?: Maybe<Scalars['Float']>;
    accounts: Array<AccountType>;
};

export enum WarehouseCode {
    Wemalo = 'Wemalo',
    Ups = 'Ups',
    UpsSofteonEUNLRNG = 'UpsSofteonEUNLRNG',
    IngramMicroEUFLSB = 'IngramMicroEUFLSB',
    UpsSofteonUSKYLSE = 'UpsSofteonUSKYLSE',
}

export enum WarehouseGoodsOrderStatus {
    Registered = 'Registered',
    Preparing = 'Preparing',
    Created = 'Created',
    Packed = 'Packed',
    Shipped = 'Shipped',
    Cancelled = 'Cancelled',
    Failed = 'Failed',
    Delivered = 'Delivered',
}

export type WidgetProperties = {
    excludedCategories?: Maybe<Array<Scalars['String']>>;
    countdownId?: Maybe<Scalars['String']>;
};

export enum WidgetType {
    HANDPICKED_PRODUCT = 'HANDPICKED_PRODUCT',
    TOP_PRODUCTS = 'TOP_PRODUCTS',
    TOP_ORDERED_PRODUCTS = 'TOP_ORDERED_PRODUCTS',
    RANDOM_CATEGORY_TOP_PRODUCTS = 'RANDOM_CATEGORY_TOP_PRODUCTS',
    DEAL_PRODUCTS = 'DEAL_PRODUCTS',
    NEW_PRODUCTS = 'NEW_PRODUCTS',
    COUNTDOWN = 'COUNTDOWN',
}

/** Union of Work assets */
export type WorkAsset = GroverAsset;

export type WorkAssetEdge = {
    __typename?: 'WorkAssetEdge';
    cursor: Scalars['String'];
    node: WorkAsset;
};

export type WorkAssetsConnection = {
    __typename?: 'WorkAssetsConnection';
    edges?: Maybe<Array<WorkAssetEdge>>;
    nodes?: Maybe<Array<WorkAsset>>;
    pageInfo?: Maybe<WorkPageInfo>;
    totalCount?: Maybe<Scalars['Float']>;
    matchingCount?: Maybe<Scalars['Float']>;
};

export type WorkAssetsFilterInput = {
    predicates: Array<WorkAssetsFilterPredicateJointInput>;
    joinType?: Maybe<JoinType>;
};

export enum WorkAssetsFilterPredicateAttributeType {
    name = 'name',
    assetStatus = 'assetStatus',
    brandName = 'brandName',
    serialNumber = 'serialNumber',
    orderNumber = 'orderNumber',
    categoryId = 'categoryId',
    subCategoryId = 'subCategoryId',
    contractId = 'contractId',
    assignedTo = 'assignedTo',
    priceInCents = 'priceInCents',
    returnAvailableAfter = 'returnAvailableAfter',
    createdAt = 'createdAt',
}

export type WorkAssetsFilterPredicateInput = {
    attribute: WorkAssetsFilterPredicateAttributeType;
    comparison: ComparisonType;
    type: PredicateType;
    value: Scalars['String'];
};

export type WorkAssetsFilterPredicateJointInput = {
    predicates: Array<WorkAssetsFilterPredicateInput>;
    joinType?: Maybe<JoinType>;
};

export type WorkAssetSortInput = {
    uuid?: Maybe<SortOrder>;
    name?: Maybe<SortOrder>;
    assetStatus?: Maybe<SortOrder>;
    serialNumber?: Maybe<SortOrder>;
    priceInCents?: Maybe<SortOrder>;
    createdAt?: Maybe<SortOrder>;
    updatedAt?: Maybe<SortOrder>;
    terminatedAt?: Maybe<SortOrder>;
    activatedAt?: Maybe<SortOrder>;
    returnAvailableAfter?: Maybe<SortOrder>;
    assetAssignee?: Maybe<SortOrder>;
};

export enum WorkAssetStatus {
    preparing_asset = 'preparing_asset',
    preparing_to_ship = 'preparing_to_ship',
    shipping = 'shipping',
    on_rent = 'on_rent',
    awaiting_return = 'awaiting_return',
    processing_return = 'processing_return',
    shipping_return = 'shipping_return',
    purchased = 'purchased',
    no_status = 'no_status',
}

export type WorkCreateAddressInput = {
    line1: Scalars['String'];
    line2?: Maybe<Scalars['String']>;
    additionalInfo?: Maybe<Scalars['String']>;
    stateAbbr?: Maybe<Scalars['String']>;
    city: Scalars['String'];
    zip: Scalars['String'];
    countryIso: Scalars['String'];
    billingAddress: Scalars['Boolean'];
    shippingAddress: Scalars['Boolean'];
};

/** Union of Work employees */
export type WorkEmployee = GroverEmployee;

export type WorkEmployeeConnection = {
    __typename?: 'WorkEmployeeConnection';
    edges?: Maybe<Array<WorkEmployeeEdge>>;
    nodes?: Maybe<Array<WorkEmployee>>;
    pageInfo?: Maybe<WorkPageInfo>;
    totalCount?: Maybe<Scalars['Float']>;
    matchingCount?: Maybe<Scalars['Float']>;
};

export type WorkEmployeeEdge = {
    __typename?: 'WorkEmployeeEdge';
    cursor: Scalars['String'];
    node: WorkEmployee;
};

export type WorkEmployeesFilterInput = {
    predicates: Array<WorkEmployeesFilterPredicateJointInput>;
    joinType?: Maybe<JoinType>;
};

export enum WorkEmployeesFilterPredicateAttributeType {
    name = 'name',
    email = 'email',
    externalId = 'externalId',
    city = 'city',
    country = 'country',
    companyId = 'companyId',
}

export type WorkEmployeesFilterPredicateInput = {
    attribute: WorkEmployeesFilterPredicateAttributeType;
    comparison: ComparisonType;
    type: PredicateType;
    value: Scalars['String'];
};

export type WorkEmployeesFilterPredicateJointInput = {
    predicates: Array<WorkEmployeesFilterPredicateInput>;
    joinType?: Maybe<JoinType>;
};

export type WorkEmployeeSortInput = {
    uuid?: Maybe<SortOrder>;
    name?: Maybe<SortOrder>;
    externalId?: Maybe<SortOrder>;
    createdAt?: Maybe<SortOrder>;
    updatedAt?: Maybe<SortOrder>;
};

export type WorkPageInfo = {
    __typename?: 'WorkPageInfo';
    startCursor?: Maybe<Scalars['String']>;
    endCursor?: Maybe<Scalars['String']>;
    hasPreviousPage: Scalars['Boolean'];
    hasNextPage: Scalars['Boolean'];
};

export type WorkPaginationInput = {
    /** Is optional, if provided following validation is applied: min 1 and max 1000 */
    first?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    /** Is optional, if provided following validation is applied: min 1 and max 1000 */
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['String']>;
};

export type WorkS3Presignedurl = {
    __typename?: 'WorkS3Presignedurl';
    /** Url for uploading the file. */
    url: Scalars['String'];
    /** AWS Signature for the upload. Add this field to the upload for it to work. */
    signature: Scalars['String'];
    /** Algorithm used for the signature, ignore this field, only needed for information purposes. */
    algorithm: Scalars['String'];
    /** AWS generated field for the upload. Add this field to the upload for it to work. */
    policy: Scalars['String'];
    /** Key name for the file, this is the ID or name used to upload & download the file. */
    key: Scalars['String'];
    /** AWS generated field for the upload. */
    credential: Scalars['String'];
    /** AWS generated field for the upload. */
    securityToken: Scalars['String'];
    /** Raw fields gotten from AWS */
    rawFields: Scalars['String'];
};

export type WorkUpdateAddressInput = {
    line1: Scalars['String'];
    line2?: Maybe<Scalars['String']>;
    additionalInfo?: Maybe<Scalars['String']>;
    stateAbbr?: Maybe<Scalars['String']>;
    city: Scalars['String'];
    zip: Scalars['String'];
    countryIso: Scalars['String'];
    billingAddress: Scalars['Boolean'];
    shippingAddress: Scalars['Boolean'];
};

export type Xs2a = {
    __typename?: 'Xs2a';
    tracingId: Scalars['String'];
    xs2aSessionKey: Scalars['String'];
    xs2aTransactionId: Scalars['String'];
};

export type CreateSwapOrderMutationMutationVariables = Exact<{
    order?: Maybe<CreateSwapOrderInput>;
}>;

export type CreateSwapOrderMutationMutation = { __typename?: 'Mutation' } & {
    order?: Maybe<
        { __typename?: 'MixCartOrder' } & Pick<
            MixCartOrder,
            'orderNumber' | 'contractId' | 'orderMode' | 'shippingAddressId'
        > & {
                lineItems: Array<
                    Maybe<
                        { __typename?: 'MixLineItem' } & Pick<MixLineItem, 'id' | 'quantity'> & {
                                meta:
                                    | ({ __typename?: 'FlexCartLineItemMeta' } & {
                                          product?: Maybe<
                                              { __typename?: 'Product' } & Pick<
                                                  Product,
                                                  'id' | 'sku' | 'name' | 'slug' | 'coreAttribute'
                                              >
                                          >;
                                          variant: { __typename?: 'Variant' } & Pick<
                                              Variant,
                                              | 'id'
                                              | 'sku'
                                              | 'colorCodes'
                                              | 'colorTitle'
                                              | 'imageUrl'
                                              | 'mixable'
                                          >;
                                      })
                                    | ({ __typename?: 'LineItemCatalogMeta' } & {
                                          product?: Maybe<
                                              { __typename?: 'Product' } & Pick<
                                                  Product,
                                                  'id' | 'sku' | 'name' | 'slug' | 'coreAttribute'
                                              >
                                          >;
                                          variant: { __typename?: 'Variant' } & Pick<
                                              Variant,
                                              | 'id'
                                              | 'sku'
                                              | 'colorCodes'
                                              | 'colorTitle'
                                              | 'imageUrl'
                                              | 'mixable'
                                          >;
                                      })
                                    | ({ __typename?: 'ReplacedLineItemMeta' } & Pick<
                                          ReplacedLineItemMeta,
                                          'deviceId'
                                      > & {
                                              category?: Maybe<
                                                  { __typename?: 'LineItemCategory' } & Pick<
                                                      LineItemCategory,
                                                      'categoryId' | 'subCategoryId'
                                                  >
                                              >;
                                              replacedItem?: Maybe<
                                                  { __typename?: 'LineItemCatalogMeta' } & {
                                                      variant: { __typename?: 'Variant' } & Pick<
                                                          Variant,
                                                          'sku'
                                                      >;
                                                      product?: Maybe<
                                                          { __typename?: 'Product' } & Pick<
                                                              Product,
                                                              'sku'
                                                          >
                                                      >;
                                                      category?: Maybe<
                                                          {
                                                              __typename?: 'LineItemCategory';
                                                          } & Pick<
                                                              LineItemCategory,
                                                              'categoryId' | 'subCategoryId'
                                                          >
                                                      >;
                                                  }
                                              >;
                                              product?: Maybe<
                                                  { __typename?: 'Product' } & Pick<
                                                      Product,
                                                      | 'id'
                                                      | 'sku'
                                                      | 'name'
                                                      | 'slug'
                                                      | 'coreAttribute'
                                                  >
                                              >;
                                              variant: { __typename?: 'Variant' } & Pick<
                                                  Variant,
                                                  | 'id'
                                                  | 'sku'
                                                  | 'colorCodes'
                                                  | 'colorTitle'
                                                  | 'imageUrl'
                                                  | 'mixable'
                                              >;
                                          });
                            }
                    >
                >;
                itemTotal: { __typename?: 'Price' } & Pick<Price, 'currency' | 'inCents'>;
            }
    >;
};

export type SubmitSwapOrderMutationMutationVariables = Exact<{
    orderNumber?: Maybe<Scalars['ID']>;
    input?: Maybe<CartOrderSubmitInput>;
}>;

export type SubmitSwapOrderMutationMutation = { __typename?: 'Mutation' } & {
    swapSubmit?: Maybe<
        { __typename?: 'MixCartOrder' } & Pick<
            MixCartOrder,
            'orderNumber' | 'contractId' | 'orderMode' | 'shippingAddressId'
        > & {
                lineItems: Array<
                    Maybe<
                        { __typename?: 'MixLineItem' } & Pick<MixLineItem, 'id' | 'quantity'> & {
                                meta:
                                    | ({ __typename?: 'FlexCartLineItemMeta' } & {
                                          product?: Maybe<
                                              { __typename?: 'Product' } & Pick<
                                                  Product,
                                                  'id' | 'sku' | 'name' | 'slug' | 'coreAttribute'
                                              >
                                          >;
                                          variant: { __typename?: 'Variant' } & Pick<
                                              Variant,
                                              | 'id'
                                              | 'sku'
                                              | 'colorCodes'
                                              | 'colorTitle'
                                              | 'imageUrl'
                                              | 'mixable'
                                          >;
                                      })
                                    | ({ __typename?: 'LineItemCatalogMeta' } & {
                                          product?: Maybe<
                                              { __typename?: 'Product' } & Pick<
                                                  Product,
                                                  'id' | 'sku' | 'name' | 'slug' | 'coreAttribute'
                                              >
                                          >;
                                          variant: { __typename?: 'Variant' } & Pick<
                                              Variant,
                                              | 'id'
                                              | 'sku'
                                              | 'colorCodes'
                                              | 'colorTitle'
                                              | 'imageUrl'
                                              | 'mixable'
                                          >;
                                      })
                                    | ({ __typename?: 'ReplacedLineItemMeta' } & Pick<
                                          ReplacedLineItemMeta,
                                          'deviceId'
                                      > & {
                                              category?: Maybe<
                                                  { __typename?: 'LineItemCategory' } & Pick<
                                                      LineItemCategory,
                                                      'categoryId' | 'subCategoryId'
                                                  >
                                              >;
                                              replacedItem?: Maybe<
                                                  { __typename?: 'LineItemCatalogMeta' } & {
                                                      variant: { __typename?: 'Variant' } & Pick<
                                                          Variant,
                                                          'sku'
                                                      >;
                                                      product?: Maybe<
                                                          { __typename?: 'Product' } & Pick<
                                                              Product,
                                                              'sku'
                                                          >
                                                      >;
                                                      category?: Maybe<
                                                          {
                                                              __typename?: 'LineItemCategory';
                                                          } & Pick<
                                                              LineItemCategory,
                                                              'categoryId' | 'subCategoryId'
                                                          >
                                                      >;
                                                  }
                                              >;
                                              product?: Maybe<
                                                  { __typename?: 'Product' } & Pick<
                                                      Product,
                                                      | 'id'
                                                      | 'sku'
                                                      | 'name'
                                                      | 'slug'
                                                      | 'coreAttribute'
                                                  >
                                              >;
                                              variant: { __typename?: 'Variant' } & Pick<
                                                  Variant,
                                                  | 'id'
                                                  | 'sku'
                                                  | 'colorCodes'
                                                  | 'colorTitle'
                                                  | 'imageUrl'
                                                  | 'mixable'
                                              >;
                                          });
                            }
                    >
                >;
            }
    >;
};

export type CompanyQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyQuery = { __typename?: 'Query' } & {
    company: { __typename?: 'Company' } & Pick<
        Company,
        'authorizedByLegalRepresentative' | 'hrv' | 'id' | 'name' | 'status' | 'ust'
    > & { user: { __typename?: 'User' } & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'> };
};

export type CompanyTypesQueryVariables = Exact<{
    country: Scalars['String'];
}>;

export type CompanyTypesQuery = { __typename?: 'Query' } & {
    companyTypes: Array<
        { __typename?: 'CompanyType' } & Pick<
            CompanyType,
            'id' | 'individual' | 'name' | 'ustIdRequired'
        >
    >;
};

export type CreateCompanyMutationVariables = Exact<{
    input: CreateLegacyCompanyInput;
}>;

export type CreateCompanyMutation = { __typename?: 'Mutation' } & {
    createLegacyCompany: { __typename?: 'Company' } & Pick<
        Company,
        'name' | 'status' | 'size' | 'ust' | 'companyTypeId'
    >;
};

export type UserQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type UserQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & Pick<
            User,
            'id' | 'firstName' | 'lastName' | 'birthdate' | 'email' | 'phone' | 'phoneVerified'
        >
    >;
};

export type MutationMutationVariables = Exact<{
    orderNumber?: Maybe<Scalars['String']>;
    sessionId?: Maybe<Scalars['String']>;
}>;

export type MutationMutation = { __typename?: 'Mutation' } & {
    createCheckoutAttempt?: Maybe<
        { __typename?: 'CreateCheckoutAttempt' } & Pick<CreateCheckoutAttempt, 'reference'>
    >;
};

export type GetProductFiltersQueryVariables = Exact<{
    permalink?: Maybe<Scalars['String']>;
}>;

export type GetProductFiltersQuery = { __typename?: 'Query' } & {
    category?: Maybe<
        { __typename?: 'Category' } & {
            productFilters?: Maybe<
                Array<
                    | ({ __typename?: 'RangeFilter' } & Pick<
                          RangeFilter,
                          'id' | 'name' | 'label' | 'type'
                      > & {
                              rangeFilterConfig: { __typename?: 'RangeFilterConfig' } & Pick<
                                  RangeFilterConfig,
                                  'unit' | 'bucketMin' | 'bucketMax' | 'bucketSize'
                              >;
                          })
                    | ({ __typename?: 'KeywordFilter' } & Pick<
                          KeywordFilter,
                          'id' | 'name' | 'label' | 'type'
                      >)
                >
            >;
        }
    >;
};

export type CreateWaitingListTicketMutationVariables = Exact<{
    input: CreateWaitingListTicketInput;
}>;

export type CreateWaitingListTicketMutation = { __typename?: 'Mutation' } & {
    createWaitingListTicket?: Maybe<
        { __typename?: 'CreateWaitingListTicketPayload' } & Pick<
            CreateWaitingListTicketPayload,
            'clientMutationId'
        > & {
                waitingListTicket: { __typename?: 'WaitingListTicket' } & Pick<
                    WaitingListTicket,
                    'id' | 'state'
                > & {
                        waitingList: { __typename?: 'WaitingList' } & Pick<
                            WaitingList,
                            'id' | 'slug'
                        >;
                    };
            }
    >;
};

export type GetProductsDataQueryVariables = Exact<{
    skus: Array<Scalars['String']> | Scalars['String'];
    productCustomizationServiceDataFetchingEnabled: Scalars['Boolean'];
    showProductEnergyLabelEnabled: Scalars['Boolean'];
}>;

export type GetProductsDataQuery = { __typename?: 'Query' } & {
    products?: Maybe<
        Array<
            { __typename?: 'Product' } & Pick<Product, 'id' | 'sku'> & {
                    rentalPlans?: Maybe<
                        { __typename?: 'RentalPlan' } & Pick<RentalPlan, 'id'> & {
                                length: { __typename?: 'Duration' } & Pick<Duration, 'value'>;
                                discount?: Maybe<
                                    { __typename?: 'RentalPlanDiscount' } & Pick<
                                        RentalPlanDiscount,
                                        'percentage'
                                    >
                                >;
                            }
                    >;
                    energyLabel?: Maybe<
                        { __typename?: 'EnergyLabel' } & Pick<EnergyLabel, 'id' | 'energyRating'>
                    >;
                    variants?: Maybe<
                        { __typename?: 'Variant' } & Pick<Variant, 'id'> & {
                                flags?: Maybe<
                                    Array<
                                        { __typename?: 'VariantFlag' } & Pick<
                                            VariantFlag,
                                            'name' | 'isActive'
                                        >
                                    >
                                >;
                                preorder?: Maybe<
                                    { __typename?: 'Preorder' } & PreorderProductFragment
                                >;
                            }
                    >;
                    countdownTimers?: Maybe<
                        { __typename?: 'CountdownTimer' } & Pick<
                            CountdownTimer,
                            'id' | 'startAt' | 'endAt' | 'isActive' | 'storeCodes'
                        >
                    >;
                    inStores?: Maybe<
                        Array<
                            { __typename?: 'Store' } & Pick<
                                Store,
                                | 'alternativeLocales'
                                | 'code'
                                | 'defaultCurrency'
                                | 'defaultLocale'
                                | 'deliveryTimeFromDays'
                                | 'deliveryTimeToDays'
                                | 'id'
                                | 'name'
                            >
                        >
                    >;
                }
        >
    >;
};

export type GetProductsWidgetsQueryVariables = Exact<{
    where?: Maybe<ProductsWidgetWhereInput>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
}>;

export type GetProductsWidgetsQuery = { __typename?: 'Query' } & {
    productsWidgets: { __typename?: 'ProductsWidgetPaginationOutput' } & Pick<
        ProductsWidgetPaginationOutput,
        'totalCount'
    > & {
            rows: Array<
                { __typename?: 'ProductsWidget' } & Pick<
                    ProductsWidget,
                    'type' | 'name' | 'properties' | 'url'
                > & {
                        products?: Maybe<
                            Array<
                                { __typename?: 'Product' } & Pick<
                                    Product,
                                    | 'id'
                                    | 'sku'
                                    | 'slug'
                                    | 'name'
                                    | 'promotionalLabels'
                                    | 'isNew'
                                    | 'coreAttribute'
                                    | 'originalImageUrl'
                                    | 'brandName'
                                > & {
                                        variants: Array<
                                            { __typename?: 'Variant' } & Pick<
                                                Variant,
                                                'id' | 'mixable'
                                            > & {
                                                    flags?: Maybe<
                                                        Array<
                                                            { __typename?: 'VariantFlag' } & Pick<
                                                                VariantFlag,
                                                                'name' | 'isActive'
                                                            >
                                                        >
                                                    >;
                                                }
                                        >;
                                        rentalPlans: Array<
                                            { __typename?: 'RentalPlan' } & Pick<
                                                RentalPlan,
                                                'id'
                                            > & {
                                                    discount?: Maybe<
                                                        {
                                                            __typename?: 'RentalPlanDiscount';
                                                        } & Pick<RentalPlanDiscount, 'percentage'>
                                                    >;
                                                    price: { __typename?: 'Price' } & Pick<
                                                        Price,
                                                        'inCents'
                                                    >;
                                                    length: { __typename?: 'Duration' } & Pick<
                                                        Duration,
                                                        'unit' | 'value'
                                                    >;
                                                    oldPrice?: Maybe<
                                                        { __typename?: 'Price' } & Pick<
                                                            Price,
                                                            'inCents'
                                                        >
                                                    >;
                                                }
                                        >;
                                        category: { __typename?: 'Category' } & Pick<
                                            Category,
                                            'iconSvgPath' | 'id' | 'title' | 'permalink'
                                        > & {
                                                parent?: Maybe<
                                                    { __typename?: 'Category' } & Pick<
                                                        Category,
                                                        'iconSvgPath' | 'id' | 'title' | 'permalink'
                                                    >
                                                >;
                                            };
                                    }
                            >
                        >;
                    }
            >;
        };
};

export type CastReturnReasonMutationVariables = Exact<{
    voteSlug: Scalars['String'];
    ticket: Scalars['String'];
}>;

export type CastReturnReasonMutation = { __typename?: 'Mutation' } & {
    castVote: { __typename?: 'Vote' } & Pick<Vote, 'voteSlug'>;
};

export type GetContractDeviceLockStatusQueryVariables = Exact<{
    contractId: Scalars['ID'];
    goodId: Scalars['ID'];
}>;

export type GetContractDeviceLockStatusQuery = { __typename?: 'Query' } & {
    contract:
        | ({ __typename?: 'Flex' } & {
              good: { __typename?: 'FlexDevice' } & {
                  asset?: Maybe<
                      { __typename?: 'AssetModel' } & Pick<
                          AssetModel,
                          'serialNumber' | 'isDeviceLocked'
                      >
                  >;
              };
          })
        | ({ __typename?: 'Mix' } & {
              good: { __typename?: 'MixDevice' } & Pick<MixDevice, 'id'> & {
                      asset?: Maybe<
                          { __typename?: 'AssetModel' } & Pick<
                              AssetModel,
                              'serialNumber' | 'isDeviceLocked'
                          >
                      >;
                  };
          });
};

export type GetReturnReasonOptionsMutationVariables = Exact<{
    slug: Scalars['String'];
    subscriptionId: Scalars['String'];
}>;

export type GetReturnReasonOptionsMutation = { __typename?: 'Mutation' } & {
    getVotingTicket: { __typename?: 'Ticket' } & Pick<Ticket, 'ticket'> & {
            payload:
                | ({ __typename?: 'SingleChoiceOptions' } & {
                      options: Array<
                          { __typename?: 'SingleChoiceOption' } & Pick<
                              SingleChoiceOption,
                              'label' | 'slug'
                          >
                      >;
                  })
                | { __typename?: 'ThumbsOptions' }
                | { __typename?: 'MoodOptions' };
        };
};

export type GetSubscriptionDeviceLockStatusQueryVariables = Exact<{
    contractId?: Maybe<Scalars['ID']>;
}>;

export type GetSubscriptionDeviceLockStatusQuery = { __typename?: 'Query' } & {
    subscriptionDetails?: Maybe<
        | { __typename?: 'Flex' }
        | ({ __typename?: 'SubscriptionDetails' } & {
              asset?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'isDeviceLocked'>>;
          })
    >;
};

export type YourOptionsQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type YourOptionsQuery = { __typename?: 'Query' } & {
    subscriptionDetails?: Maybe<
        | { __typename?: 'Flex' }
        | ({ __typename: 'SubscriptionDetails' } & Pick<SubscriptionDetails, 'id'> & {
                  basePrice: { __typename?: 'Price' } & Pick<Price, 'currency' | 'inCents'>;
                  minimumDuration?: Maybe<
                      { __typename?: 'Duration' } & Pick<Duration, 'unit' | 'value'>
                  >;
                  payments: { __typename?: 'PaymentsUnionConnection' } & {
                      nodes?: Maybe<
                          Array<
                              Maybe<
                                  | { __typename?: 'GroupPayment' }
                                  | ({ __typename?: 'OrderPayment' } & Pick<OrderPayment, 'state'>)
                                  | { __typename?: 'PurchasePayment' }
                                  | ({ __typename?: 'SubscriptionPayment' } & Pick<
                                        SubscriptionPayment,
                                        'state'
                                    >)
                              >
                          >
                      >;
                  };
                  subscriptionActions: Array<
                      | { __typename?: 'Buyout' }
                      | { __typename?: 'CancelSubscription' }
                      | { __typename?: 'ChangePaymentMethod' }
                      | { __typename?: 'PaymentMethodPresenter' }
                      | { __typename?: 'PrintReturnLabel' }
                      | { __typename?: 'ReportDamage' }
                      | { __typename?: 'SupportRequest' }
                      | ({ __typename?: 'UpgradeRentalPlan' } & {
                            availablePlans?: Maybe<
                                Array<
                                    { __typename?: 'RentalPlan' } & Pick<RentalPlan, 'id'> & {
                                            price: { __typename?: 'Price' } & Pick<
                                                Price,
                                                'currency' | 'inCents'
                                            >;
                                        }
                                >
                            >;
                        })
                  >;
              })
    >;
};

export type ApplySurpriseDiscountMutationVariables = Exact<{
    input: ApplySurpriseDiscountInput;
}>;

export type ApplySurpriseDiscountMutation = { __typename?: 'Mutation' } & {
    applySurpriseDiscount?: Maybe<
        { __typename?: 'ApplySurpriseDiscountPayload' } & Pick<
            ApplySurpriseDiscountPayload,
            'errors'
        >
    >;
};

export type CompleteContractMutationVariables = Exact<{
    input: CompleteContractInput;
}>;

export type CompleteContractMutation = { __typename?: 'Mutation' } & {
    completeContract?: Maybe<
        { __typename?: 'CompleteContractPayload' } & Pick<
            CompleteContractPayload,
            'clientMutationId'
        > & {
                contract?: Maybe<
                    | ({ __typename?: 'Flex' } & Pick<Flex, 'id'>)
                    | ({ __typename?: 'Mix' } & Pick<Mix, 'id'>)
                >;
            }
    >;
};

export type ContractReturnStepsQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type ContractReturnStepsQuery = { __typename?: 'Query' } & {
    contract:
        | ({ __typename?: 'Flex' } & Pick<Flex, 'id'> & {
                  goods: Array<
                      { __typename?: 'FlexDevice' } & Pick<FlexDevice, 'id'> & {
                              product: { __typename?: 'Product' } & Pick<Product, 'id'> & {
                                      returnSteps: Array<
                                          | ({ __typename?: 'AccessoriesReturnStep' } & Pick<
                                                AccessoriesReturnStep,
                                                | 'name'
                                                | 'accessories'
                                                | 'description'
                                                | 'headline'
                                                | 'warning'
                                            >)
                                          | ({ __typename?: 'ConfirmableReturnStep' } & Pick<
                                                ConfirmableReturnStep,
                                                | 'name'
                                                | 'checkbox'
                                                | 'description'
                                                | 'headline'
                                                | 'warning'
                                            >)
                                          | ({ __typename?: 'ReturnStepsOutcome' } & Pick<
                                                ReturnStepsOutcome,
                                                'name'
                                            >)
                                      >;
                                  };
                          }
                  >;
              })
        | ({ __typename?: 'Mix' } & Pick<Mix, 'id'> & {
                  goods: Array<
                      { __typename?: 'MixDevice' } & Pick<MixDevice, 'id'> & {
                              product: { __typename?: 'Product' } & Pick<Product, 'id'> & {
                                      returnSteps: Array<
                                          | ({ __typename?: 'AccessoriesReturnStep' } & Pick<
                                                AccessoriesReturnStep,
                                                | 'name'
                                                | 'accessories'
                                                | 'description'
                                                | 'headline'
                                                | 'warning'
                                            >)
                                          | ({ __typename?: 'ConfirmableReturnStep' } & Pick<
                                                ConfirmableReturnStep,
                                                | 'name'
                                                | 'checkbox'
                                                | 'description'
                                                | 'headline'
                                                | 'warning'
                                            >)
                                          | ({ __typename?: 'ReturnStepsOutcome' } & Pick<
                                                ReturnStepsOutcome,
                                                'name'
                                            >)
                                      >;
                                  };
                          }
                  >;
              });
};

export type CurrentMixSwapQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentMixSwapQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id'> & {
                mixSummary?: Maybe<
                    { __typename?: 'MixSummary' } & Pick<MixSummary, 'contractId'> & {
                            swap?: Maybe<
                                { __typename?: 'Swap' } & Pick<
                                    Swap,
                                    'id' | 'shippingLabelUrl' | 'orderNumber'
                                > & {
                                        oldDevice: { __typename?: 'MixDevice' } & Pick<
                                            MixDevice,
                                            'id'
                                        > & {
                                                product: { __typename?: 'Product' } & Pick<
                                                    Product,
                                                    'id' | 'name'
                                                > & {
                                                        returnSteps: Array<
                                                            | ({
                                                                  __typename?: 'AccessoriesReturnStep';
                                                              } & Pick<
                                                                  AccessoriesReturnStep,
                                                                  | 'name'
                                                                  | 'accessories'
                                                                  | 'description'
                                                                  | 'headline'
                                                                  | 'warning'
                                                              >)
                                                            | ({
                                                                  __typename?: 'ConfirmableReturnStep';
                                                              } & Pick<
                                                                  ConfirmableReturnStep,
                                                                  | 'name'
                                                                  | 'checkbox'
                                                                  | 'description'
                                                                  | 'headline'
                                                                  | 'warning'
                                                              >)
                                                            | ({
                                                                  __typename?: 'ReturnStepsOutcome';
                                                              } & Pick<ReturnStepsOutcome, 'name'>)
                                                        >;
                                                    };
                                                variant: { __typename?: 'Variant' } & Pick<
                                                    Variant,
                                                    'id' | 'imageUrl'
                                                >;
                                            };
                                    }
                            >;
                        }
                >;
            }
    >;
};

export type FlexReturnLabelUrlQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type FlexReturnLabelUrlQuery = { __typename?: 'Query' } & {
    contract:
        | ({ __typename?: 'Flex' } & Pick<Flex, 'id'> & {
                  shipmentLabels?: Maybe<
                      Array<
                          { __typename?: 'ShipmentLabel' } & Pick<
                              ShipmentLabel,
                              'shippingLabelUrl'
                          > & { id: ShipmentLabel['assetUid'] }
                      >
                  >;
              })
        | { __typename?: 'Mix' };
};

export type MixSwapReturnLabelQueryVariables = Exact<{ [key: string]: never }>;

export type MixSwapReturnLabelQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id'> & {
                mixSummary?: Maybe<
                    { __typename?: 'MixSummary' } & Pick<MixSummary, 'contractId'> & {
                            swap?: Maybe<
                                { __typename?: 'Swap' } & Pick<Swap, 'id' | 'shippingLabelUrl'>
                            >;
                        }
                >;
            }
    >;
};

export type SurpriseDiscountQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type SurpriseDiscountQuery = { __typename?: 'Query' } & {
    subscriptionDetails?: Maybe<
        | { __typename?: 'Flex' }
        | ({ __typename?: 'SubscriptionDetails' } & Pick<SubscriptionDetails, 'id'> & {
                  surpriseDiscount?: Maybe<
                      { __typename?: 'SurpriseDiscountOffer' } & Pick<
                          SurpriseDiscountOffer,
                          'percentage'
                      > & {
                              newBuyoutTerms?: Maybe<
                                  { __typename?: 'MonthsToOwn' } & Pick<
                                      MonthsToOwn,
                                      'availableSince' | 'monthsRequired' | 'monthsToCoverRemaining'
                                  >
                              >;
                          }
                  >;
              })
    >;
};

export type TermsAndConditionsQueryVariables = Exact<{
    version: Scalars['ID'];
}>;

export type TermsAndConditionsQuery = { __typename?: 'Query' } & {
    termsAndConditions?: Maybe<
        { __typename?: 'TermsAndConditions' } & Pick<TermsAndConditions, 'content' | 'publishedAt'>
    >;
};

export type ApplyLoyaltyDiscountMutationVariables = Exact<{
    input: ApplyLoyaltyDiscountInput;
}>;

export type ApplyLoyaltyDiscountMutation = { __typename?: 'Mutation' } & {
    applyLoyaltyDiscount?: Maybe<
        { __typename?: 'ApplyLoyaltyDiscountPayload' } & {
            payment:
                | { __typename?: 'GroupPayment' }
                | { __typename?: 'OrderPayment' }
                | { __typename?: 'PurchasePayment' }
                | ({ __typename?: 'SubscriptionPayment' } & {
                      discountableLoyaltyPrice: { __typename?: 'Price' } & Pick<
                          Price,
                          'inCents' | 'currency'
                      >;
                      discountedLoyaltyPrice: { __typename?: 'Price' } & Pick<
                          Price,
                          'inCents' | 'currency'
                      >;
                      price: { __typename?: 'Price' } & Pick<Price, 'inCents' | 'currency'>;
                  });
        }
    >;
};

export type ContractPaymentMethodQueryVariables = Exact<{
    contractId: Scalars['ID'];
}>;

export type ContractPaymentMethodQuery = { __typename?: 'Query' } & {
    contract:
        | ({ __typename?: 'Flex' } & Pick<Flex, 'id'> & {
                  paymentMethod?: Maybe<
                      | ({ __typename?: 'ByInvoice' } & Pick<ByInvoice, 'billingAccountId'> & {
                                id: ByInvoice['billingAccountId'];
                            })
                      | ({ __typename?: 'Card' } & Pick<
                            Card,
                            'billingAccountId' | 'brand' | 'lastDigits'
                        > & { id: Card['billingAccountId'] })
                      | ({ __typename?: 'Paypal' } & Pick<Paypal, 'billingAccountId' | 'email'> & {
                                id: Paypal['billingAccountId'];
                            })
                      | ({ __typename?: 'Sepa' } & Pick<
                            Sepa,
                            'billingAccountId' | 'ibanLastDigits'
                        > & { id: Sepa['billingAccountId'] })
                  >;
              })
        | ({ __typename?: 'Mix' } & Pick<Mix, 'id'> & {
                  paymentMethod?: Maybe<
                      | ({ __typename?: 'ByInvoice' } & Pick<ByInvoice, 'billingAccountId'> & {
                                id: ByInvoice['billingAccountId'];
                            })
                      | ({ __typename?: 'Card' } & Pick<
                            Card,
                            'billingAccountId' | 'brand' | 'lastDigits'
                        > & { id: Card['billingAccountId'] })
                      | ({ __typename?: 'Paypal' } & Pick<Paypal, 'billingAccountId' | 'email'> & {
                                id: Paypal['billingAccountId'];
                            })
                      | ({ __typename?: 'Sepa' } & Pick<
                            Sepa,
                            'billingAccountId' | 'ibanLastDigits'
                        > & { id: Sepa['billingAccountId'] })
                  >;
              });
};

export type PaymentMethodsQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentMethodsQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id'> & {
                paymentMethods: Array<
                    | ({ __typename?: 'ByInvoice' } & Pick<ByInvoice, 'billingAccountId'> & {
                              paymentMethodId: ByInvoice['id'];
                              id: ByInvoice['billingAccountId'];
                          })
                    | ({ __typename?: 'Card' } & Pick<
                          Card,
                          | 'billingAccountId'
                          | 'brand'
                          | 'lastDigits'
                          | 'holderName'
                          | 'expirationYear'
                          | 'expirationMonth'
                          | 'type'
                      > & { paymentMethodId: Card['id']; id: Card['billingAccountId'] })
                    | ({ __typename?: 'Paypal' } & Pick<Paypal, 'billingAccountId' | 'email'> & {
                              paymentMethodId: Paypal['id'];
                              id: Paypal['billingAccountId'];
                          })
                    | ({ __typename?: 'Sepa' } & Pick<
                          Sepa,
                          'billingAccountId' | 'ibanLastDigits'
                      > & { paymentMethodId: Sepa['id']; id: Sepa['billingAccountId'] })
                >;
            }
    >;
};

export type SubscriptionPaymentMethodQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type SubscriptionPaymentMethodQuery = { __typename?: 'Query' } & {
    subscriptionDetails?: Maybe<
        | { __typename?: 'Flex' }
        | ({ __typename?: 'SubscriptionDetails' } & Pick<SubscriptionDetails, 'id' | 'sfId'> & {
                  paymentMethod?: Maybe<
                      | { __typename?: 'ByInvoice' }
                      | ({ __typename?: 'Card' } & Pick<Card, 'billingAccountId' | 'lastDigits'>)
                      | ({ __typename?: 'Paypal' } & Pick<Paypal, 'billingAccountId' | 'email'>)
                      | ({ __typename?: 'Sepa' } & Pick<
                            Sepa,
                            'billingAccountId' | 'ibanLastDigits'
                        >)
                  >;
              })
    >;
};

export type UpdatePaymentMethodForContractMutationVariables = Exact<{
    input: UpdatePaymentMethodForContractInput;
}>;

export type UpdatePaymentMethodForContractMutation = { __typename?: 'Mutation' } & {
    updatePaymentMethodForContract: { __typename?: 'UpdatePaymentMethodForContractPayload' } & Pick<
        UpdatePaymentMethodForContractPayload,
        'success'
    >;
};

export type CancelSwapMutationVariables = Exact<{
    input: CancelSwapInput;
}>;

export type CancelSwapMutation = { __typename?: 'Mutation' } & {
    cancelSwap?: Maybe<
        { __typename?: 'CancelSwapPayload' } & Pick<
            CancelSwapPayload,
            'clientMutationId' | 'error'
        > & {
                swap?: Maybe<
                    { __typename?: 'Swap' } & Pick<
                        Swap,
                        'cancelledAt' | 'createdAt' | 'id' | 'orderNumber' | 'state'
                    > & {
                            newDevice: { __typename?: 'MixDevice' } & Pick<MixDevice, 'state'>;
                            oldDevice: { __typename?: 'MixDevice' } & Pick<MixDevice, 'state'>;
                        }
                >;
            }
    >;
};

export type DismissCampaignMutationVariables = Exact<{
    externalEntity: Scalars['String'];
    entity: Scalars['String'];
    campaignUuid: Scalars['String'];
}>;

export type DismissCampaignMutation = { __typename?: 'Mutation' } & {
    dismissCampaign: { __typename?: 'DismissModel' } & Pick<
        DismissModel,
        'entity' | 'externalEntity' | 'campaignUuid'
    >;
};

export type DismissMarketingContentMutationVariables = Exact<{
    campaignUuid: Scalars['String'];
}>;

export type DismissMarketingContentMutation = { __typename?: 'Mutation' } & {
    dismiss: { __typename?: 'DismissModel' } & Pick<DismissModel, 'campaignUuid'>;
};

export type GetVotingTicketThumbsMutationMutationVariables = Exact<{
    slug: Scalars['String'];
}>;

export type GetVotingTicketThumbsMutationMutation = { __typename?: 'Mutation' } & {
    getVotingTicket: { __typename?: 'Ticket' } & Pick<Ticket, 'pollMode' | 'ticket'>;
};

export type MarketingContentQueryVariables = Exact<{
    page?: Maybe<Scalars['String']>;
    platform?: Maybe<PlatformTypes>;
}>;

export type MarketingContentQuery = { __typename?: 'Query' } & {
    MarketingContent: Array<
        | ({ __typename?: 'BasicMarketingCardModel' } & Pick<
              BasicMarketingCardModel,
              'uuid' | 'imageUrl' | 'bodyTemplate' | 'largeImage' | 'colorFunction'
          > & {
                  actions?: Maybe<
                      Array<
                          | ({ __typename?: 'LinkActionModel' } & Pick<
                                LinkActionModel,
                                'text' | 'path'
                            >)
                          | ({ __typename?: 'CopyToClipboardActionModel' } & Pick<
                                CopyToClipboardActionModel,
                                'text' | 'value'
                            >)
                          | { __typename?: 'LaunchFlowActionModel' }
                      >
                  >;
              })
        | { __typename?: 'SubscriptionBadgeModel' }
        | ({ __typename?: 'PollCardModel' } & Pick<PollCardModel, 'pollSlug' | 'uuid'> & {
                  copy: { __typename?: 'PollCardCopyModel' } & {
                      voteStep: { __typename?: 'PollCardItemModel' } & Pick<
                          PollCardItemModel,
                          'title' | 'body'
                      >;
                      commentStep: { __typename?: 'PollCardCommentStepModel' } & Pick<
                          PollCardCommentStepModel,
                          'title' | 'body' | 'buttonLabel'
                      >;
                      positiveOutcome: { __typename?: 'PollCardItemModel' } & Pick<
                          PollCardItemModel,
                          'title' | 'body'
                      >;
                      negativeOutcome: { __typename?: 'PollCardItemModel' } & Pick<
                          PollCardItemModel,
                          'title' | 'body'
                      >;
                  };
              })
        | ({ __typename?: 'ConfirmationPollCardModel' } & Pick<
              ConfirmationPollCardModel,
              'pollSlug' | 'uuid' | 'imageUrl' | 'hasCircleDismissButton'
          > & {
                  copy: { __typename?: 'ConfirmationPollCardCopyModel' } & {
                      confirmationStep: {
                          __typename?: 'ConfirmationPollCardConfirmationStepModel';
                      } & Pick<
                          ConfirmationPollCardConfirmationStepModel,
                          'title' | 'body' | 'buttonLabel'
                      >;
                      outcome: { __typename?: 'ConfirmationPollCardItemModel' } & Pick<
                          ConfirmationPollCardItemModel,
                          'title' | 'body'
                      >;
                  };
              })
    >;
};

export type MixDeviceDetailsQueryVariables = Exact<{
    contractId: Scalars['ID'];
    id: Scalars['ID'];
}>;

export type MixDeviceDetailsQuery = { __typename?: 'Query' } & {
    contract:
        | { __typename?: 'Flex' }
        | ({ __typename?: 'Mix' } & Pick<Mix, 'id' | 'createdAt' | 'tosVersion'> & {
                  good: { __typename?: 'MixDevice' } & Pick<
                      MixDevice,
                      'id' | 'state' | 'returnedAt' | 'deliveredAt' | 'contractId'
                  > & {
                          product: { __typename?: 'Product' } & Pick<Product, 'name'> & {
                                  category: { __typename?: 'Category' } & Pick<Category, 'name'> & {
                                          parent?: Maybe<
                                              { __typename?: 'Category' } & Pick<Category, 'name'>
                                          >;
                                      };
                              };
                          variant: { __typename?: 'Variant' } & Pick<
                              Variant,
                              'id' | 'imageUrl' | 'sku'
                          >;
                      };
              });
};

export type MixDevicesFilterQueryVariables = Exact<{
    after?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
}>;

export type MixDevicesFilterQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id'> & {
                mixSummary?: Maybe<
                    { __typename?: 'MixSummary' } & Pick<MixSummary, 'contractId'> & {
                            pastDevices?: Maybe<
                                { __typename?: 'MixDeviceConnection' } & Pick<
                                    MixDeviceConnection,
                                    'totalCount'
                                > & {
                                        nodes?: Maybe<
                                            Array<
                                                Maybe<
                                                    { __typename: 'MixDevice' } & Pick<
                                                        MixDevice,
                                                        'id' | 'returnedAt'
                                                    > & {
                                                            product: {
                                                                __typename?: 'Product';
                                                            } & Pick<
                                                                Product,
                                                                | 'id'
                                                                | 'imageUrl'
                                                                | 'name'
                                                                | 'sku'
                                                                | 'slug'
                                                            >;
                                                            variant: {
                                                                __typename?: 'Variant';
                                                            } & Pick<
                                                                Variant,
                                                                'id' | 'imageUrl' | 'sku'
                                                            >;
                                                        }
                                                >
                                            >
                                        >;
                                        pageInfo: { __typename?: 'PageInfo' } & Pick<
                                            PageInfo,
                                            | 'startCursor'
                                            | 'endCursor'
                                            | 'hasNextPage'
                                            | 'hasPreviousPage'
                                        >;
                                    }
                            >;
                        }
                >;
            }
    >;
};

export type MixPaymentsCardQueryVariables = Exact<{ [key: string]: never }>;

export type MixPaymentsCardQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id'> & {
                mixSummary?: Maybe<
                    { __typename?: 'MixSummary' } & Pick<MixSummary, 'contractId'> & {
                            payments: {
                                __typename?: 'PaymentsUnionConnection';
                            } & PaymentsFragmentFragment;
                        }
                >;
            }
    >;
};

export type SubscriptionActionsQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type SubscriptionActionsQuery = { __typename?: 'Query' } & {
    subscriptionDetails?: Maybe<
        | { __typename?: 'Flex' }
        | ({ __typename?: 'SubscriptionDetails' } & Pick<SubscriptionDetails, 'id'> & {
                  subscriptionActions: Array<
                      | ({ __typename?: 'Buyout' } & Pick<Buyout, 'type'>)
                      | { __typename?: 'CancelSubscription' }
                      | { __typename?: 'ChangePaymentMethod' }
                      | { __typename?: 'PaymentMethodPresenter' }
                      | { __typename?: 'PrintReturnLabel' }
                      | { __typename?: 'ReportDamage' }
                      | { __typename?: 'SupportRequest' }
                      | { __typename?: 'UpgradeRentalPlan' }
                  >;
              })
    >;
};

export type SubscriptionNameQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type SubscriptionNameQuery = { __typename?: 'Query' } & {
    subscriptionDetails?: Maybe<
        | ({ __typename?: 'Flex' } & Pick<Flex, 'id'> & { contractName: Flex['name'] })
        | ({ __typename?: 'SubscriptionDetails' } & Pick<SubscriptionDetails, 'id' | 'name'>)
    >;
};

export type SubscriptionsFilterProductQueryVariables = Exact<{
    productId: Scalars['ID'];
}>;

export type SubscriptionsFilterProductQuery = { __typename?: 'Query' } & {
    product?: Maybe<{ __typename?: 'Product' } & Pick<Product, 'id' | 'name' | 'coreAttribute'>>;
};

export type SubscriptionSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type SubscriptionSummaryQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id'> & {
                subscriptionSummary: Array<
                    | ({ __typename?: 'FilterButton' } & Pick<FilterButton, 'title'> & {
                              filterConfig: { __typename?: 'SubscriptionListFilter' } & Pick<
                                  SubscriptionListFilter,
                                  'state' | 'orderId' | 'productId'
                              >;
                          })
                    | ({ __typename?: 'Section' } & Pick<Section, 'title' | 'quantity'> & {
                              filter?: Maybe<
                                  { __typename?: 'SubscriptionListFilter' } & Pick<
                                      SubscriptionListFilter,
                                      'state'
                                  >
                              >;
                              items: Array<
                                  | ({ __typename?: 'SubscriptionCard' } & Pick<
                                        SubscriptionCard,
                                        'id' | 'name' | 'state'
                                    > & {
                                            note?: Maybe<
                                                | ({ __typename?: 'AttentionNote' } & Pick<
                                                      AttentionNote,
                                                      'text'
                                                  >)
                                                | ({ __typename?: 'EndedNote' } & Pick<
                                                      EndedNote,
                                                      'text'
                                                  >)
                                                | ({ __typename?: 'NextPaymentNote' } & Pick<
                                                      NextPaymentNote,
                                                      'date'
                                                  > & {
                                                          paymentMethod?: Maybe<
                                                              | { __typename: 'ByInvoice' }
                                                              | { __typename: 'Card' }
                                                              | { __typename: 'Paypal' }
                                                              | { __typename: 'Sepa' }
                                                          >;
                                                      })
                                                | ({ __typename?: 'PausedNote' } & Pick<
                                                      PausedNote,
                                                      'text'
                                                  >)
                                                | ({ __typename?: 'ProcessingNote' } & Pick<
                                                      ProcessingNote,
                                                      'text'
                                                  >)
                                            >;
                                            variant: { __typename?: 'Variant' } & Pick<
                                                Variant,
                                                'id' | 'colorCodes' | 'colorTitle' | 'imageUrl'
                                            >;
                                        })
                                  | ({ __typename?: 'SubscriptionStack' } & Pick<
                                        SubscriptionStack,
                                        'quantity'
                                    > & {
                                            filter: {
                                                __typename?: 'SubscriptionListFilter';
                                            } & Pick<
                                                SubscriptionListFilter,
                                                'state' | 'orderId' | 'productId'
                                            >;
                                            product: { __typename?: 'Product' } & Pick<
                                                Product,
                                                'id' | 'name' | 'imageUrl'
                                            >;
                                        })
                              >;
                          })
                    | { __typename?: 'StandaloneDiscountOffer' }
                    | { __typename?: 'SwapDiscountOffer' }
                >;
                mixSummary?: Maybe<
                    { __typename?: 'MixSummary' } & Pick<
                        MixSummary,
                        'contractId' | 'orderNumber' | 'state'
                    > & {
                            contract:
                                | ({ __typename?: 'Flex' } & {
                                      endingTerm: { __typename?: 'EndingTerm' } & Pick<
                                          EndingTerm,
                                          'availableAfter'
                                      > & {
                                              timeUntilAvailable?: Maybe<
                                                  { __typename?: 'Duration' } & Pick<
                                                      Duration,
                                                      'unit' | 'value'
                                                  >
                                              >;
                                          };
                                  })
                                | ({ __typename?: 'Mix' } & {
                                      endingTerm: { __typename?: 'EndingTerm' } & Pick<
                                          EndingTerm,
                                          'availableAfter'
                                      > & {
                                              timeUntilAvailable?: Maybe<
                                                  { __typename?: 'Duration' } & Pick<
                                                      Duration,
                                                      'unit' | 'value'
                                                  >
                                              >;
                                          };
                                  });
                            devices: Array<
                                { __typename?: 'MixDevice' } & Pick<MixDevice, 'id'> & {
                                        product: { __typename?: 'Product' } & Pick<
                                            Product,
                                            'id' | 'name'
                                        >;
                                        variant: { __typename?: 'Variant' } & Pick<
                                            Variant,
                                            'id' | 'imageUrl'
                                        >;
                                    }
                            >;
                            pastDevices?: Maybe<
                                { __typename?: 'MixDeviceConnection' } & Pick<
                                    MixDeviceConnection,
                                    'totalCount'
                                >
                            >;
                            swap?: Maybe<
                                { __typename?: 'Swap' } & Pick<
                                    Swap,
                                    'id' | 'orderNumber' | 'trackingUrl' | 'shippingProgress'
                                > & {
                                        newDevice: { __typename?: 'MixDevice' } & Pick<
                                            MixDevice,
                                            'id' | 'state'
                                        > & {
                                                product: { __typename?: 'Product' } & Pick<
                                                    Product,
                                                    'id' | 'name'
                                                >;
                                                variant: { __typename?: 'Variant' } & Pick<
                                                    Variant,
                                                    'id' | 'imageUrl'
                                                >;
                                            };
                                        oldDevice: { __typename?: 'MixDevice' } & Pick<
                                            MixDevice,
                                            'id' | 'state'
                                        > & {
                                                product: { __typename?: 'Product' } & Pick<
                                                    Product,
                                                    'id' | 'name'
                                                >;
                                                variant: { __typename?: 'Variant' } & Pick<
                                                    Variant,
                                                    'id' | 'imageUrl'
                                                >;
                                            };
                                    }
                            >;
                        }
                >;
            }
    >;
};

export type SwappingTermsQueryVariables = Exact<{
    mixContractId: Scalars['ID'];
}>;

export type SwappingTermsQuery = { __typename?: 'Query' } & {
    contract:
        | { __typename?: 'Flex' }
        | ({ __typename?: 'Mix' } & {
              swappingTerms: { __typename?: 'SwappingTerms' } & Pick<
                  SwappingTerms,
                  'available' | 'availableAfter' | 'unavailabilityReason'
              > & {
                      timeUntilAvailable?: Maybe<
                          { __typename?: 'Duration' } & Pick<Duration, 'unit' | 'value'>
                      >;
                  };
          });
};

export type CastSatisfactionSurveyVoteMutationVariables = Exact<{
    mood: Scalars['String'];
    ticket: Scalars['String'];
    comment?: Maybe<Scalars['String']>;
}>;

export type CastSatisfactionSurveyVoteMutation = { __typename?: 'Mutation' } & {
    castVote: { __typename?: 'Vote' } & Pick<
        Vote,
        'voteSlug' | 'comment' | 'userId' | 'entityId' | 'entityType'
    >;
};

export type GetSatisfactionSurveyVotingTicketMutationVariables = Exact<{
    slug: Scalars['String'];
    entityId?: Maybe<Scalars['String']>;
}>;

export type GetSatisfactionSurveyVotingTicketMutation = { __typename?: 'Mutation' } & {
    getVotingTicket: { __typename?: 'Ticket' } & Pick<
        Ticket,
        'ticket' | 'entityId' | 'entityType' | 'pollMode'
    > & {
            payload:
                | { __typename?: 'SingleChoiceOptions' }
                | { __typename?: 'ThumbsOptions' }
                | ({ __typename?: 'MoodOptions' } & {
                      options: Array<{ __typename?: 'MoodOption' } & Pick<MoodOption, 'mood'>>;
                  });
        };
};

export type EnergyLabelQueryQueryVariables = Exact<{
    productId: Scalars['ID'];
}>;

export type EnergyLabelQueryQuery = { __typename?: 'Query' } & {
    product?: Maybe<
        { __typename?: 'Product' } & {
            energyLabel?: Maybe<
                { __typename?: 'EnergyLabel' } & Pick<
                    EnergyLabel,
                    'id' | 'energyRating' | 'imageUrl' | 'energySpecs'
                >
            >;
        }
    >;
};

export type CheckContractsQueryVariables = Exact<{
    contractsFilter?: Maybe<ContractFilterInput>;
    product: ProductVariantInput;
}>;

export type CheckContractsQuery = { __typename?: 'Query' } & {
    contracts?: Maybe<
        { __typename?: 'ContractConnection' } & {
            nodes?: Maybe<
                Array<
                    Maybe<
                        | { __typename: 'Flex' }
                        | ({ __typename: 'Mix' } & Pick<Mix, 'orderNumber' | 'state'> & {
                                  swappingTerms: { __typename?: 'SwappingTerms' } & Pick<
                                      SwappingTerms,
                                      'available' | 'availableAfter' | 'unavailabilityReason'
                                  > & {
                                          timeUntilAvailable?: Maybe<
                                              { __typename?: 'Duration' } & Pick<
                                                  Duration,
                                                  'unit' | 'value'
                                              >
                                          >;
                                      };
                                  goods: Array<
                                      { __typename: 'MixDevice' } & Pick<
                                          MixDevice,
                                          | 'id'
                                          | 'contractId'
                                          | 'categoryId'
                                          | 'subCategoryId'
                                          | 'state'
                                          | 'swappable'
                                      > & {
                                              variant: { __typename?: 'Variant' } & Pick<
                                                  Variant,
                                                  'id' | 'imageUrl' | 'coreAttribute' | 'sku'
                                              >;
                                              product: { __typename?: 'Product' } & Pick<
                                                  Product,
                                                  | 'id'
                                                  | 'imageUrl'
                                                  | 'name'
                                                  | 'sku'
                                                  | 'slug'
                                                  | 'coreAttribute'
                                              >;
                                          }
                                  >;
                              })
                    >
                >
            >;
        }
    >;
};

export type CartSummaryFragment = { __typename?: 'MixCartOrder' } & Pick<
    MixCartOrder,
    'orderNumber' | 'orderMode' | 'shippingAddressId' | 'billingAddressId' | 'currency'
> & {
        paymentMethod?: Maybe<
            { __typename?: 'PaymentMethodStub' } & Pick<
                PaymentMethodStub,
                'paymentMethodId' | 'billingAccountId'
            >
        >;
        itemTotal: { __typename?: 'Price' } & Pick<Price, 'inCents' | 'currency'>;
        total: { __typename?: 'Price' } & Pick<Price, 'inCents' | 'currency'>;
        lineItems: Array<
            Maybe<
                { __typename?: 'MixLineItem' } & Pick<MixLineItem, 'id' | 'quantity'> & {
                        meta:
                            | ({ __typename?: 'FlexCartLineItemMeta' } & Pick<
                                  FlexCartLineItemMeta,
                                  'committedMonths'
                              > & {
                                      total: { __typename?: 'Price' } & Pick<
                                          Price,
                                          'inCents' | 'currency'
                                      >;
                                      price: { __typename?: 'Price' } & Pick<
                                          Price,
                                          'inCents' | 'currency'
                                      >;
                                      product?: Maybe<
                                          { __typename?: 'Product' } & Pick<
                                              Product,
                                              'id' | 'sku' | 'name' | 'slug' | 'coreAttribute'
                                          >
                                      >;
                                      variant: { __typename?: 'Variant' } & Pick<
                                          Variant,
                                          | 'id'
                                          | 'sku'
                                          | 'colorTitle'
                                          | 'colorCodes'
                                          | 'imageUrl'
                                          | 'mixable'
                                      >;
                                  })
                            | ({ __typename?: 'LineItemCatalogMeta' } & {
                                  category?: Maybe<
                                      { __typename?: 'LineItemCategory' } & Pick<
                                          LineItemCategory,
                                          'categoryId' | 'subCategoryId'
                                      >
                                  >;
                                  product?: Maybe<
                                      { __typename?: 'Product' } & Pick<
                                          Product,
                                          'id' | 'sku' | 'name' | 'slug' | 'coreAttribute'
                                      >
                                  >;
                                  variant: { __typename?: 'Variant' } & Pick<
                                      Variant,
                                      | 'id'
                                      | 'sku'
                                      | 'colorTitle'
                                      | 'colorCodes'
                                      | 'imageUrl'
                                      | 'mixable'
                                  >;
                              })
                            | ({ __typename?: 'ReplacedLineItemMeta' } & {
                                  product?: Maybe<
                                      { __typename?: 'Product' } & Pick<
                                          Product,
                                          'id' | 'sku' | 'name' | 'slug' | 'coreAttribute'
                                      >
                                  >;
                                  variant: { __typename?: 'Variant' } & Pick<
                                      Variant,
                                      | 'id'
                                      | 'sku'
                                      | 'colorTitle'
                                      | 'colorCodes'
                                      | 'imageUrl'
                                      | 'mixable'
                                  >;
                              });
                    }
            >
        >;
    };

export type AddressAddMutationVariables = Exact<{
    orderNumber?: Maybe<Scalars['ID']>;
    input?: Maybe<CartOrderUpdateInput>;
}>;

export type AddressAddMutation = { __typename?: 'Mutation' } & {
    order?: Maybe<{ __typename?: 'MixCartOrder' } & CartSummaryFragment>;
};

export type AddressChangeMutationVariables = Exact<{
    orderNumber?: Maybe<Scalars['ID']>;
    input?: Maybe<CartOrderUpdateInput>;
}>;

export type AddressChangeMutation = { __typename?: 'Mutation' } & {
    order?: Maybe<{ __typename?: 'MixCartOrder' } & CartSummaryFragment>;
};

export type OrderAddLineItemMutationVariables = Exact<{
    orderNumber?: Maybe<Scalars['ID']>;
    item?: Maybe<MixLineItemInput>;
}>;

export type OrderAddLineItemMutation = { __typename?: 'Mutation' } & {
    order?: Maybe<{ __typename?: 'MixCartOrder' } & CartSummaryFragment>;
};

export type EditLineItemMutationVariables = Exact<{
    orderNumber: Scalars['ID'];
    item: LineItemUpdateInput;
}>;

export type EditLineItemMutation = { __typename?: 'Mutation' } & {
    order?: Maybe<{ __typename?: 'MixCartOrder' } & CartSummaryFragment>;
};

export type RemoveLineItemMutationVariables = Exact<{
    orderNumber?: Maybe<Scalars['ID']>;
    itemId?: Maybe<Scalars['Int']>;
}>;

export type RemoveLineItemMutation = { __typename?: 'Mutation' } & {
    order?: Maybe<{ __typename?: 'MixCartOrder' } & CartSummaryFragment>;
};

export type GetOrderQueryVariables = Exact<{
    orderNumber: Scalars['ID'];
}>;

export type GetOrderQuery = { __typename?: 'Query' } & {
    order?: Maybe<{ __typename?: 'MixCartOrder' } & CartSummaryFragment>;
};

export type OrderCreateMutationVariables = Exact<{
    order?: Maybe<CartOrderCreateInput>;
}>;

export type OrderCreateMutation = { __typename?: 'Mutation' } & {
    order?: Maybe<{ __typename?: 'MixCartOrder' } & CartSummaryFragment>;
};

export type OrderSubmitMutationVariables = Exact<{
    orderNumber?: Maybe<Scalars['ID']>;
    input?: Maybe<CartOrderSubmitInput>;
}>;

export type OrderSubmitMutation = { __typename?: 'Mutation' } & {
    order?: Maybe<{ __typename?: 'MixCartOrder' } & CartSummaryFragment>;
};

export type OrderUpdateMutationVariables = Exact<{
    orderNumber?: Maybe<Scalars['ID']>;
    step?: Maybe<Scalars['String']>;
}>;

export type OrderUpdateMutation = { __typename?: 'Mutation' } & {
    order?: Maybe<{ __typename?: 'MixCartOrder' } & CartSummaryFragment>;
};

export type OrderValidateQueryVariables = Exact<{
    products: Array<ProductVariantInput> | ProductVariantInput;
}>;

export type OrderValidateQuery = { __typename?: 'Query' } & {
    validateMixProductSelection: { __typename?: 'ContractValidationResult' } & Pick<
        ContractValidationResult,
        'valid'
    >;
};

export type AddPaymentMethodMutationVariables = Exact<{
    orderNumber?: Maybe<Scalars['ID']>;
    input?: Maybe<CartOrderUpdateInput>;
}>;

export type AddPaymentMethodMutation = { __typename?: 'Mutation' } & {
    order?: Maybe<{ __typename?: 'MixCartOrder' } & CartSummaryFragment>;
};

export type ChangePaymentMethodMutationVariables = Exact<{
    orderNumber?: Maybe<Scalars['ID']>;
    input?: Maybe<CartOrderUpdateInput>;
}>;

export type ChangePaymentMethodMutation = { __typename?: 'Mutation' } & {
    order?: Maybe<{ __typename?: 'MixCartOrder' } & CartSummaryFragment>;
};

export type PreorderProductFragment = { __typename?: 'Preorder' } & Pick<
    Preorder,
    'id' | 'allocationDate'
>;

export type CreateUserConsentsMutationVariables = Exact<{
    consentAttributes: Array<ConsentAttributeInput> | ConsentAttributeInput;
}>;

export type CreateUserConsentsMutation = { __typename?: 'Mutation' } & {
    createUserConsents?: Maybe<
        { __typename?: 'CreateUserConsentsPayload' } & {
            userConsents: Array<
                { __typename?: 'UserConsent' } & Pick<UserConsent, 'name' | 'value'>
            >;
        }
    >;
};

export type CardOnboardingStatusQueryVariables = Exact<{ [key: string]: never }>;

export type CardOnboardingStatusQuery = { __typename?: 'Query' } & {
    onboardingStatus: { __typename: 'OnboardingStatusResult' } & Pick<
        OnboardingStatusResult,
        'cardStatus'
    >;
};

export type CardWaitingListQueryVariables = Exact<{ [key: string]: never }>;

export type CardWaitingListQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename: 'User' } & Pick<User, 'id'> & {
                waitingListTickets?: Maybe<
                    Array<
                        { __typename: 'WaitingListTicket' } & Pick<
                            WaitingListTicket,
                            'id' | 'state'
                        > & {
                                waitingList: { __typename: 'WaitingList' } & Pick<
                                    WaitingList,
                                    'id' | 'slug'
                                >;
                            }
                    >
                >;
            }
    >;
};

export type UserConsentsQueryVariables = Exact<{
    id?: Maybe<Scalars['ID']>;
}>;

export type UserConsentsQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id'> & {
                userConsents: Array<
                    { __typename?: 'UserConsent' } & Pick<
                        UserConsent,
                        'description' | 'id' | 'name' | 'value'
                    >
                >;
            }
    >;
};

export type GroverWorkEnabledQueryVariables = Exact<{
    companyId?: Maybe<Scalars['ID']>;
}>;

export type GroverWorkEnabledQuery = { __typename?: 'Query' } & {
    company: { __typename?: 'Company' } & {
        businessDashboard?: Maybe<
            { __typename?: 'BusinessDashboard' } & Pick<BusinessDashboard, 'enabled'>
        >;
    };
};

export type MixWaitingListQueryVariables = Exact<{ [key: string]: never }>;

export type MixWaitingListQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id'> & {
                waitingListTickets?: Maybe<
                    Array<
                        { __typename?: 'WaitingListTicket' } & Pick<
                            WaitingListTicket,
                            'id' | 'state'
                        > & {
                                waitingList: { __typename?: 'WaitingList' } & Pick<
                                    WaitingList,
                                    'id' | 'slug'
                                >;
                            }
                    >
                >;
            }
    >;
};

export type ProductPreorderInfoQueryVariables = Exact<{
    productSku?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type ProductPreorderInfoQuery = { __typename?: 'Query' } & {
    products?: Maybe<
        Array<
            { __typename?: 'Product' } & Pick<Product, 'id' | 'slug'> & {
                    variants: Array<
                        { __typename?: 'Variant' } & Pick<Variant, 'id'> & {
                                preorder?: Maybe<
                                    { __typename?: 'Preorder' } & PreorderProductFragment
                                >;
                            }
                    >;
                }
        >
    >;
};

export type CompanyDashboardQueryQueryVariables = Exact<{
    userId: Scalars['ID'];
}>;

export type CompanyDashboardQueryQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & Pick<User, 'email' | 'type'> & {
                company?: Maybe<
                    { __typename?: 'Company' } & Pick<
                        Company,
                        | 'name'
                        | 'billingEmail'
                        | 'ust'
                        | 'legalRepresentativeEmail'
                        | 'size'
                        | 'status'
                        | 'website'
                    > & {
                            type: { __typename?: 'CompanyType' } & Pick<CompanyType, 'name'>;
                            address?: Maybe<
                                { __typename?: 'CompanyAddress' } & Pick<
                                    CompanyAddress,
                                    'city' | 'country' | 'state' | 'zip' | 'line1' | 'line2'
                                >
                            >;
                        }
                >;
            }
    >;
};

export type GetCountdownTimerByIdQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetCountdownTimerByIdQuery = { __typename?: 'Query' } & {
    findCountdownTimerById?: Maybe<
        { __typename?: 'CountdownTimer' } & Pick<
            CountdownTimer,
            'id' | 'name' | 'storeCodes' | 'startAt' | 'endAt' | 'isActive'
        >
    >;
};

export type GCashDataQueryVariables = Exact<{
    storeCode: DefaultStoreCode;
}>;

export type GCashDataQuery = { __typename?: 'Query' } & {
    loyaltyBalance: { __typename?: 'LoyaltyBalanceResult' } & Pick<LoyaltyBalanceResult, 'balance'>;
    user?: Maybe<
        { __typename?: 'User' } & {
            hostConnection?: Maybe<
                { __typename?: 'ConnectionModel' } & Pick<
                    ConnectionModel,
                    'id' | 'paymentDate' | 'assetReceivedDate' | 'accountCreatedAt' | 'status'
                > & {
                        campaign: { __typename?: 'ReferralCampaign' } & Pick<
                            ReferralCampaign,
                            'guestCashAmount'
                        >;
                    }
            >;
            guestConnections: Array<
                { __typename?: 'ConnectionModel' } & Pick<
                    ConnectionModel,
                    'id' | 'paymentDate' | 'assetReceivedDate' | 'accountCreatedAt' | 'status'
                > & {
                        campaign: { __typename?: 'ReferralCampaign' } & Pick<
                            ReferralCampaign,
                            'hostCashAmount'
                        >;
                    }
            >;
            payments?: Maybe<
                { __typename?: 'PaymentsUnionConnection' } & {
                    pageInfo: { __typename?: 'PageInfo' } & Pick<
                        PageInfo,
                        'hasNextPage' | 'endCursor'
                    >;
                    nodes?: Maybe<
                        Array<
                            Maybe<
                                | { __typename?: 'GroupPayment' }
                                | { __typename?: 'OrderPayment' }
                                | { __typename?: 'PurchasePayment' }
                                | ({ __typename?: 'SubscriptionPayment' } & Pick<
                                      SubscriptionPayment,
                                      'id' | 'remoteId'
                                  > & {
                                          discountableLoyaltyPrice: { __typename?: 'Price' } & Pick<
                                              Price,
                                              'inCents' | 'currency'
                                          >;
                                          discountedLoyaltyPrice: { __typename?: 'Price' } & Pick<
                                              Price,
                                              'inCents' | 'currency'
                                          >;
                                          basePrice: { __typename?: 'Price' } & Pick<
                                              Price,
                                              'inCents' | 'currency'
                                          >;
                                          price: { __typename?: 'Price' } & Pick<
                                              Price,
                                              'inCents' | 'currency'
                                          >;
                                          dueDate:
                                              | ({ __typename?: 'Date' } & Pick<Date, 'iso8601'>)
                                              | ({ __typename?: 'TimeEstimate' } & {
                                                    atLeast: { __typename?: 'Duration' } & Pick<
                                                        Duration,
                                                        'value' | 'unit'
                                                    >;
                                                });
                                          subscriptionDetails?: Maybe<
                                              { __typename?: 'SubscriptionDetails' } & Pick<
                                                  SubscriptionDetails,
                                                  'id' | 'name' | 'state'
                                              > & {
                                                      variant?: Maybe<
                                                          { __typename?: 'Variant' } & Pick<
                                                              Variant,
                                                              'id' | 'imageUrl'
                                                          > & {
                                                                  product?: Maybe<
                                                                      { __typename?: 'Product' } & {
                                                                          category: {
                                                                              __typename?: 'Category';
                                                                          } & Pick<
                                                                              Category,
                                                                              'name'
                                                                          >;
                                                                      }
                                                                  >;
                                                              }
                                                      >;
                                                  }
                                          >;
                                      })
                            >
                        >
                    >;
                }
            >;
            contracts: { __typename?: 'ContractConnection' } & {
                nodes?: Maybe<
                    Array<
                        Maybe<
                            | ({ __typename?: 'Flex' } & Pick<Flex, 'id'> & {
                                      goods: Array<
                                          { __typename?: 'FlexDevice' } & Pick<FlexDevice, 'id'> & {
                                                  variant: { __typename?: 'Variant' } & Pick<
                                                      Variant,
                                                      'id' | 'imageUrl'
                                                  > & {
                                                          product?: Maybe<
                                                              { __typename?: 'Product' } & Pick<
                                                                  Product,
                                                                  'name'
                                                              > & {
                                                                      category: {
                                                                          __typename?: 'Category';
                                                                      } & Pick<Category, 'name'>;
                                                                  }
                                                          >;
                                                      };
                                              }
                                      >;
                                      groverCareTerms: { __typename?: 'GroverCareTerms' } & Pick<
                                          GroverCareTerms,
                                          'coverage'
                                      > & {
                                              price: { __typename?: 'Price' } & Pick<
                                                  Price,
                                                  'currency' | 'inCents'
                                              >;
                                          };
                                      payments: { __typename?: 'PaymentsUnionConnection' } & {
                                          nodes?: Maybe<
                                              Array<
                                                  Maybe<
                                                      | { __typename?: 'GroupPayment' }
                                                      | { __typename?: 'OrderPayment' }
                                                      | { __typename?: 'PurchasePayment' }
                                                      | ({
                                                            __typename?: 'SubscriptionPayment';
                                                        } & Pick<SubscriptionPayment, 'id'> & {
                                                                discountableLoyaltyPrice: {
                                                                    __typename?: 'Price';
                                                                } & Pick<
                                                                    Price,
                                                                    'inCents' | 'currency'
                                                                >;
                                                                discountedLoyaltyPrice: {
                                                                    __typename?: 'Price';
                                                                } & Pick<
                                                                    Price,
                                                                    'inCents' | 'currency'
                                                                >;
                                                                basePrice: {
                                                                    __typename?: 'Price';
                                                                } & Pick<
                                                                    Price,
                                                                    'inCents' | 'currency'
                                                                >;
                                                                price: {
                                                                    __typename?: 'Price';
                                                                } & Pick<
                                                                    Price,
                                                                    'inCents' | 'currency'
                                                                >;
                                                                dueDate:
                                                                    | ({
                                                                          __typename?: 'Date';
                                                                      } & Pick<Date, 'iso8601'>)
                                                                    | ({
                                                                          __typename?: 'TimeEstimate';
                                                                      } & {
                                                                          atLeast: {
                                                                              __typename?: 'Duration';
                                                                          } & Pick<
                                                                              Duration,
                                                                              'value' | 'unit'
                                                                          >;
                                                                      });
                                                            })
                                                  >
                                              >
                                          >;
                                      };
                                  })
                            | { __typename?: 'Mix' }
                        >
                    >
                >;
            };
        }
    >;
};

export type CastVoteSatisfactionMutationMutationVariables = Exact<{
    voteSlug: Scalars['String'];
    ticket: Scalars['String'];
    comment?: Maybe<Scalars['String']>;
}>;

export type CastVoteSatisfactionMutationMutation = { __typename?: 'Mutation' } & {
    castVote: { __typename?: 'Vote' } & Pick<
        Vote,
        'voteSlug' | 'comment' | 'userId' | 'entityId' | 'entityType'
    >;
};

export type CheckReferralCodeQueryVariables = Exact<{
    code: Scalars['String'];
}>;

export type CheckReferralCodeQuery = { __typename?: 'Query' } & {
    validateCode: { __typename?: 'ReferralCodeValidation' } & Pick<
        ReferralCodeValidation,
        'isValid' | 'defaultStoreCode'
    >;
};

export type CreateConnectionMutationVariables = Exact<{
    code: Scalars['String'];
}>;

export type CreateConnectionMutation = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'createConnection'
>;

export type GetCurrentReferralCampaignQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentReferralCampaignQuery = { __typename?: 'Query' } & {
    getCurrentReferralCampaign: { __typename?: 'ReferralCampaign' } & Pick<
        ReferralCampaign,
        'defaultStoreCode' | 'guestCashAmount' | 'hostCashAmount'
    >;
};

export type GetGuestConnectionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGuestConnectionsQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & {
            guestConnections: Array<
                { __typename?: 'ConnectionModel' } & Pick<
                    ConnectionModel,
                    'id' | 'status' | 'hostEventDispatchedAt'
                > & {
                        campaign: { __typename?: 'ReferralCampaign' } & Pick<
                            ReferralCampaign,
                            'hostCashAmount'
                        >;
                    }
            >;
        }
    >;
};

export type GetHostNameQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetHostNameQuery = { __typename?: 'Query' } & {
    user?: Maybe<{ __typename?: 'User' } & Pick<User, 'firstName' | 'lastName'>>;
};

export type ReferralCodeQueryVariables = Exact<{ [key: string]: never }>;

export type ReferralCodeQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & Pick<User, 'referralCode'> & {
                defaultStore: { __typename?: 'Store' } & Pick<Store, 'code'>;
            }
    >;
};

export type PriceFragmentFragment = { __typename?: 'Price' } & Pick<Price, 'inCents' | 'currency'>;

type DetailsCardPaymentMethodFragmentByInvoiceFragment = { __typename?: 'ByInvoice' };

type DetailsCardPaymentMethodFragmentCardFragment = { __typename?: 'Card' } & Pick<
    Card,
    'lastDigits' | 'billingAccountId'
>;

type DetailsCardPaymentMethodFragmentPaypalFragment = { __typename?: 'Paypal' } & Pick<
    Paypal,
    'email' | 'billingAccountId'
>;

type DetailsCardPaymentMethodFragmentSepaFragment = { __typename?: 'Sepa' } & Pick<
    Sepa,
    'ibanLastDigits' | 'billingAccountId'
>;

export type DetailsCardPaymentMethodFragmentFragment =
    | DetailsCardPaymentMethodFragmentByInvoiceFragment
    | DetailsCardPaymentMethodFragmentCardFragment
    | DetailsCardPaymentMethodFragmentPaypalFragment
    | DetailsCardPaymentMethodFragmentSepaFragment;

export type PaymentMethodSubscriptionDetailsCardQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type PaymentMethodSubscriptionDetailsCardQuery = { __typename?: 'Query' } & {
    subscriptionDetails?: Maybe<
        | ({ __typename?: 'Flex' } & Pick<Flex, 'id' | 'name' | 'orderNumber' | 'createdAt'> & {
                  contractState: Flex['state'];
              } & {
                  billingTerms: { __typename?: 'BillingTerms' } & {
                      price: { __typename?: 'Price' } & PriceFragmentFragment;
                      basePrice: { __typename?: 'Price' } & PriceFragmentFragment;
                      periodLength: { __typename?: 'Duration' } & Pick<Duration, 'value' | 'unit'>;
                      nextPeriod?: Maybe<
                          { __typename?: 'BillingPeriod' } & Pick<BillingPeriod, 'starts'>
                      >;
                  };
                  durationTerms: { __typename?: 'DurationTerms' } & {
                      committedLength: { __typename?: 'Duration' } & Pick<
                          Duration,
                          'unit' | 'value'
                      >;
                  };
                  contractPaymentMethod?: Maybe<
                      | ({
                            __typename?: 'ByInvoice';
                        } & DetailsCardPaymentMethodFragmentByInvoiceFragment)
                      | ({ __typename?: 'Card' } & DetailsCardPaymentMethodFragmentCardFragment)
                      | ({ __typename?: 'Paypal' } & DetailsCardPaymentMethodFragmentPaypalFragment)
                      | ({ __typename?: 'Sepa' } & DetailsCardPaymentMethodFragmentSepaFragment)
                  >;
                  availableRentalPlans: Array<
                      { __typename?: 'RentalPlanUpgradeProperties' } & Pick<
                          RentalPlanUpgradeProperties,
                          'id'
                      >
                  >;
                  goods: Array<
                      { __typename?: 'FlexDevice' } & Pick<FlexDevice, 'id'> & {
                              product: { __typename?: 'Product' } & Pick<Product, 'id'> & {
                                      category: { __typename?: 'Category' } & Pick<
                                          Category,
                                          'id' | 'iconSvgPath'
                                      >;
                                  };
                          }
                  >;
              })
        | ({ __typename?: 'SubscriptionDetails' } & Pick<
              SubscriptionDetails,
              'id' | 'sfId' | 'state' | 'number' | 'contractNumber'
          > & {
                  product?: Maybe<
                      { __typename?: 'Product' } & Pick<Product, 'id' | 'name'> & {
                              category: { __typename?: 'Category' } & Pick<
                                  Category,
                                  'id' | 'name' | 'iconSvgPath'
                              >;
                          }
                  >;
                  basePrice: { __typename?: 'Price' } & Pick<Price, 'currency' | 'inCents'>;
                  shippingAddress?: Maybe<
                      { __typename?: 'Address' } & Pick<
                          Address,
                          'id' | 'city' | 'line1' | 'line2' | 'zip'
                      >
                  >;
                  asset?: Maybe<
                      { __typename?: 'Asset' } & Pick<Asset, 'serialNumber' | 'productNumber'>
                  >;
                  order: { __typename?: 'Order' } & Pick<Order, 'id' | 'number' | 'completedAt'>;
                  minimumDuration?: Maybe<
                      { __typename?: 'Duration' } & Pick<Duration, 'value' | 'unit'>
                  >;
                  paymentMethod?: Maybe<
                      | ({
                            __typename?: 'ByInvoice';
                        } & DetailsCardPaymentMethodFragmentByInvoiceFragment)
                      | ({ __typename?: 'Card' } & DetailsCardPaymentMethodFragmentCardFragment)
                      | ({ __typename?: 'Paypal' } & DetailsCardPaymentMethodFragmentPaypalFragment)
                      | ({ __typename?: 'Sepa' } & DetailsCardPaymentMethodFragmentSepaFragment)
                  >;
                  subscriptionActions: Array<
                      | { __typename?: 'Buyout' }
                      | { __typename?: 'CancelSubscription' }
                      | { __typename?: 'ChangePaymentMethod' }
                      | ({ __typename?: 'PaymentMethodPresenter' } & Pick<
                            PaymentMethodPresenter,
                            'type' | 'email' | 'last4Digits'
                        > & { action: 'PaymentMethodPresenter' })
                      | { __typename?: 'PrintReturnLabel' }
                      | { __typename?: 'ReportDamage' }
                      | { __typename?: 'SupportRequest' }
                      | ({ __typename?: 'UpgradeRentalPlan' } & {
                            availablePlans?: Maybe<
                                Array<
                                    { __typename?: 'RentalPlan' } & Pick<RentalPlan, 'id'> & {
                                            price: { __typename?: 'Price' } & PriceFragmentFragment;
                                        }
                                >
                            >;
                        })
                  >;
              })
    >;
};

export type SubscriptionDetailsCardQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type SubscriptionDetailsCardQuery = { __typename?: 'Query' } & {
    subscriptionDetails?: Maybe<
        | ({ __typename?: 'Flex' } & Pick<Flex, 'id' | 'name' | 'orderNumber' | 'createdAt'> & {
                  contractState: Flex['state'];
              } & {
                  billingTerms: { __typename?: 'BillingTerms' } & {
                      price: { __typename?: 'Price' } & PriceFragmentFragment;
                      basePrice: { __typename?: 'Price' } & PriceFragmentFragment;
                      periodLength: { __typename?: 'Duration' } & Pick<Duration, 'value' | 'unit'>;
                      nextPeriod?: Maybe<
                          { __typename?: 'BillingPeriod' } & Pick<BillingPeriod, 'starts'>
                      >;
                  };
                  durationTerms: { __typename?: 'DurationTerms' } & {
                      committedLength: { __typename?: 'Duration' } & Pick<
                          Duration,
                          'unit' | 'value'
                      >;
                  };
                  contractPaymentMethod?: Maybe<
                      | ({
                            __typename?: 'ByInvoice';
                        } & DetailsCardPaymentMethodFragmentByInvoiceFragment)
                      | ({ __typename?: 'Card' } & DetailsCardPaymentMethodFragmentCardFragment)
                      | ({ __typename?: 'Paypal' } & DetailsCardPaymentMethodFragmentPaypalFragment)
                      | ({ __typename?: 'Sepa' } & DetailsCardPaymentMethodFragmentSepaFragment)
                  >;
                  availableRentalPlans: Array<
                      { __typename?: 'RentalPlanUpgradeProperties' } & Pick<
                          RentalPlanUpgradeProperties,
                          'id'
                      >
                  >;
                  goods: Array<
                      { __typename?: 'FlexDevice' } & Pick<FlexDevice, 'id'> & {
                              product: { __typename?: 'Product' } & Pick<Product, 'id'> & {
                                      category: { __typename?: 'Category' } & Pick<
                                          Category,
                                          'id' | 'iconSvgPath'
                                      >;
                                  };
                          }
                  >;
              })
        | ({ __typename?: 'SubscriptionDetails' } & Pick<
              SubscriptionDetails,
              'id' | 'sfId' | 'state' | 'number' | 'contractNumber'
          > & {
                  product?: Maybe<
                      { __typename?: 'Product' } & Pick<Product, 'id' | 'name'> & {
                              category: { __typename?: 'Category' } & Pick<
                                  Category,
                                  'id' | 'name' | 'iconSvgPath'
                              >;
                          }
                  >;
                  basePrice: { __typename?: 'Price' } & Pick<Price, 'currency' | 'inCents'>;
                  shippingAddress?: Maybe<
                      { __typename?: 'Address' } & Pick<
                          Address,
                          'id' | 'city' | 'line1' | 'line2' | 'zip'
                      >
                  >;
                  asset?: Maybe<
                      { __typename?: 'Asset' } & Pick<Asset, 'serialNumber' | 'productNumber'>
                  >;
                  order: { __typename?: 'Order' } & Pick<Order, 'id' | 'number' | 'completedAt'>;
                  minimumDuration?: Maybe<
                      { __typename?: 'Duration' } & Pick<Duration, 'value' | 'unit'>
                  >;
                  paymentMethod?: Maybe<
                      | ({
                            __typename?: 'ByInvoice';
                        } & DetailsCardPaymentMethodFragmentByInvoiceFragment)
                      | ({ __typename?: 'Card' } & DetailsCardPaymentMethodFragmentCardFragment)
                      | ({ __typename?: 'Paypal' } & DetailsCardPaymentMethodFragmentPaypalFragment)
                      | ({ __typename?: 'Sepa' } & DetailsCardPaymentMethodFragmentSepaFragment)
                  >;
                  subscriptionActions: Array<
                      | { __typename?: 'Buyout' }
                      | { __typename?: 'CancelSubscription' }
                      | { __typename?: 'ChangePaymentMethod' }
                      | ({ __typename?: 'PaymentMethodPresenter' } & Pick<
                            PaymentMethodPresenter,
                            'type' | 'email' | 'last4Digits'
                        > & { action: 'PaymentMethodPresenter' })
                      | { __typename?: 'PrintReturnLabel' }
                      | { __typename?: 'ReportDamage' }
                      | { __typename?: 'SupportRequest' }
                      | ({ __typename?: 'UpgradeRentalPlan' } & {
                            availablePlans?: Maybe<
                                Array<
                                    { __typename?: 'RentalPlan' } & Pick<RentalPlan, 'id'> & {
                                            price: { __typename?: 'Price' } & PriceFragmentFragment;
                                        }
                                >
                            >;
                        })
                  >;
              })
    >;
};

export type DeletePaymentMethodMutationVariables = Exact<{
    billingAccountId: Scalars['ID'];
}>;

export type DeletePaymentMethodMutation = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'deletePaymentMethod'
>;

type LinkedContractsFragmentByInvoiceFragment = { __typename?: 'ByInvoice' } & {
    contracts: Array<
        | ({ __typename?: 'Flex' } & {
              goods: Array<
                  { __typename?: 'FlexDevice' } & {
                      product: { __typename?: 'Product' } & Pick<Product, 'name'> & {
                              category: { __typename?: 'Category' } & Pick<Category, 'iconSvgPath'>;
                          };
                  }
              >;
              billingTerms: { __typename?: 'BillingTerms' } & {
                  nextPeriod?: Maybe<
                      { __typename?: 'BillingPeriod' } & Pick<BillingPeriod, 'starts'> & {
                              amountDue: { __typename?: 'Price' } & Pick<
                                  Price,
                                  'inCents' | 'currency'
                              >;
                          }
                  >;
              };
          })
        | ({ __typename?: 'Mix' } & {
              goods: Array<{ __typename?: 'MixDevice' }>;
              billingTerms: { __typename?: 'BillingTerms' } & {
                  nextPeriod?: Maybe<
                      { __typename?: 'BillingPeriod' } & Pick<BillingPeriod, 'starts'> & {
                              amountDue: { __typename?: 'Price' } & Pick<
                                  Price,
                                  'inCents' | 'currency'
                              >;
                          }
                  >;
              };
          })
    >;
};

type LinkedContractsFragmentCardFragment = { __typename?: 'Card' } & {
    contracts: Array<
        | ({ __typename?: 'Flex' } & {
              goods: Array<
                  { __typename?: 'FlexDevice' } & {
                      product: { __typename?: 'Product' } & Pick<Product, 'name'> & {
                              category: { __typename?: 'Category' } & Pick<Category, 'iconSvgPath'>;
                          };
                  }
              >;
              billingTerms: { __typename?: 'BillingTerms' } & {
                  nextPeriod?: Maybe<
                      { __typename?: 'BillingPeriod' } & Pick<BillingPeriod, 'starts'> & {
                              amountDue: { __typename?: 'Price' } & Pick<
                                  Price,
                                  'inCents' | 'currency'
                              >;
                          }
                  >;
              };
          })
        | ({ __typename?: 'Mix' } & {
              goods: Array<{ __typename?: 'MixDevice' }>;
              billingTerms: { __typename?: 'BillingTerms' } & {
                  nextPeriod?: Maybe<
                      { __typename?: 'BillingPeriod' } & Pick<BillingPeriod, 'starts'> & {
                              amountDue: { __typename?: 'Price' } & Pick<
                                  Price,
                                  'inCents' | 'currency'
                              >;
                          }
                  >;
              };
          })
    >;
};

type LinkedContractsFragmentPaypalFragment = { __typename?: 'Paypal' } & {
    contracts: Array<
        | ({ __typename?: 'Flex' } & {
              goods: Array<
                  { __typename?: 'FlexDevice' } & {
                      product: { __typename?: 'Product' } & Pick<Product, 'name'> & {
                              category: { __typename?: 'Category' } & Pick<Category, 'iconSvgPath'>;
                          };
                  }
              >;
              billingTerms: { __typename?: 'BillingTerms' } & {
                  nextPeriod?: Maybe<
                      { __typename?: 'BillingPeriod' } & Pick<BillingPeriod, 'starts'> & {
                              amountDue: { __typename?: 'Price' } & Pick<
                                  Price,
                                  'inCents' | 'currency'
                              >;
                          }
                  >;
              };
          })
        | ({ __typename?: 'Mix' } & {
              goods: Array<{ __typename?: 'MixDevice' }>;
              billingTerms: { __typename?: 'BillingTerms' } & {
                  nextPeriod?: Maybe<
                      { __typename?: 'BillingPeriod' } & Pick<BillingPeriod, 'starts'> & {
                              amountDue: { __typename?: 'Price' } & Pick<
                                  Price,
                                  'inCents' | 'currency'
                              >;
                          }
                  >;
              };
          })
    >;
};

type LinkedContractsFragmentSepaFragment = { __typename?: 'Sepa' } & {
    contracts: Array<
        | ({ __typename?: 'Flex' } & {
              goods: Array<
                  { __typename?: 'FlexDevice' } & {
                      product: { __typename?: 'Product' } & Pick<Product, 'name'> & {
                              category: { __typename?: 'Category' } & Pick<Category, 'iconSvgPath'>;
                          };
                  }
              >;
              billingTerms: { __typename?: 'BillingTerms' } & {
                  nextPeriod?: Maybe<
                      { __typename?: 'BillingPeriod' } & Pick<BillingPeriod, 'starts'> & {
                              amountDue: { __typename?: 'Price' } & Pick<
                                  Price,
                                  'inCents' | 'currency'
                              >;
                          }
                  >;
              };
          })
        | ({ __typename?: 'Mix' } & {
              goods: Array<{ __typename?: 'MixDevice' }>;
              billingTerms: { __typename?: 'BillingTerms' } & {
                  nextPeriod?: Maybe<
                      { __typename?: 'BillingPeriod' } & Pick<BillingPeriod, 'starts'> & {
                              amountDue: { __typename?: 'Price' } & Pick<
                                  Price,
                                  'inCents' | 'currency'
                              >;
                          }
                  >;
              };
          })
    >;
};

export type LinkedContractsFragmentFragment =
    | LinkedContractsFragmentByInvoiceFragment
    | LinkedContractsFragmentCardFragment
    | LinkedContractsFragmentPaypalFragment
    | LinkedContractsFragmentSepaFragment;

export type PaymentMethodsWithContractsQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentMethodsWithContractsQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id'> & {
                paymentMethods: Array<
                    | ({ __typename?: 'ByInvoice' } & Pick<ByInvoice, 'billingAccountId'> & {
                              id: ByInvoice['billingAccountId'];
                          } & LinkedContractsFragmentByInvoiceFragment)
                    | ({ __typename?: 'Card' } & Pick<
                          Card,
                          | 'billingAccountId'
                          | 'brand'
                          | 'lastDigits'
                          | 'holderName'
                          | 'expirationYear'
                          | 'expirationMonth'
                          | 'type'
                      > & { id: Card['billingAccountId'] } & LinkedContractsFragmentCardFragment &
                          LinkedContractsFragmentCardFragment)
                    | ({ __typename?: 'Paypal' } & Pick<Paypal, 'billingAccountId' | 'email'> & {
                              id: Paypal['billingAccountId'];
                          } & LinkedContractsFragmentPaypalFragment &
                          LinkedContractsFragmentPaypalFragment)
                    | ({ __typename?: 'Sepa' } & Pick<
                          Sepa,
                          'billingAccountId' | 'ibanLastDigits'
                      > & { id: Sepa['billingAccountId'] } & LinkedContractsFragmentSepaFragment &
                          LinkedContractsFragmentSepaFragment)
                >;
            }
    >;
};

export type UserBillingInfoQueryVariables = Exact<{ [key: string]: never }>;

export type UserBillingInfoQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id' | 'email'> & {
                groupBilling?: Maybe<
                    { __typename?: 'GroupBilling' } & Pick<
                        GroupBilling,
                        'chosenBillingDay' | 'enabled'
                    >
                >;
            }
    >;
};

export type GetRecommendationEngineProductsQueryVariables = Exact<{
    sku: Scalars['String'];
}>;

export type GetRecommendationEngineProductsQuery = { __typename?: 'Query' } & {
    recommendations: { __typename?: 'RecommendationResponse' } & Pick<
        RecommendationResponse,
        'tracingId'
    > & {
            recommendations: Array<
                { __typename?: 'Recommendation' } & Pick<Recommendation, 'productSku'>
            >;
        };
};

export type GetRecommendedProductDetailsQueryVariables = Exact<{
    skus: Array<Scalars['String']> | Scalars['String'];
    showProductEnergyLabelEnabled: Scalars['Boolean'];
}>;

export type GetRecommendedProductDetailsQuery = { __typename?: 'Query' } & {
    products?: Maybe<
        Array<
            { __typename?: 'Product' } & Pick<
                Product,
                | 'id'
                | 'sku'
                | 'slug'
                | 'name'
                | 'promotionalLabels'
                | 'isNew'
                | 'coreAttribute'
                | 'originalImageUrl'
                | 'brandName'
            > & {
                    category: { __typename?: 'Category' } & Pick<
                        Category,
                        'id' | 'title' | 'permalink'
                    >;
                    variants: Array<
                        { __typename?: 'Variant' } & Pick<Variant, 'id' | 'mixable'> & {
                                flags?: Maybe<
                                    Array<
                                        { __typename?: 'VariantFlag' } & Pick<
                                            VariantFlag,
                                            'name' | 'isActive'
                                        >
                                    >
                                >;
                            }
                    >;
                    rentalPlans: Array<
                        { __typename?: 'RentalPlan' } & Pick<RentalPlan, 'id'> & {
                                discount?: Maybe<
                                    { __typename?: 'RentalPlanDiscount' } & Pick<
                                        RentalPlanDiscount,
                                        'percentage'
                                    >
                                >;
                                price: { __typename?: 'Price' } & Pick<Price, 'inCents'>;
                                length: { __typename?: 'Duration' } & Pick<
                                    Duration,
                                    'unit' | 'value'
                                >;
                                oldPrice?: Maybe<{ __typename?: 'Price' } & Pick<Price, 'inCents'>>;
                            }
                    >;
                    energyLabel?: Maybe<
                        { __typename?: 'EnergyLabel' } & Pick<EnergyLabel, 'energyRating'>
                    >;
                }
        >
    >;
};

export type ProductBundleQueryQueryVariables = Exact<{
    productId: Scalars['ID'];
}>;

export type ProductBundleQueryQuery = { __typename?: 'Query' } & {
    product?: Maybe<
        { __typename?: 'Product' } & Pick<Product, 'available' | 'name' | 'sku' | 'slug'> & {
                rentalPlans: Array<
                    { __typename?: 'RentalPlan' } & Pick<RentalPlan, 'id'> & {
                            length: { __typename?: 'Duration' } & Pick<Duration, 'unit' | 'value'>;
                            price: { __typename?: 'Price' } & Pick<Price, 'inCents'>;
                            oldPrice?: Maybe<{ __typename?: 'Price' } & Pick<Price, 'inCents'>>;
                        }
                >;
                variants: Array<
                    { __typename?: 'Variant' } & Pick<Variant, 'id' | 'sku' | 'imageUrl'>
                >;
                bundle?: Maybe<
                    { __typename?: 'Bundle' } & Pick<
                        Bundle,
                        'id' | 'title' | 'description' | 'active'
                    > & {
                            secondaryProducts?: Maybe<
                                Array<
                                    Maybe<
                                        { __typename?: 'Product' } & Pick<
                                            Product,
                                            'available' | 'name' | 'sku' | 'slug'
                                        > & {
                                                rentalPlans: Array<
                                                    { __typename?: 'RentalPlan' } & Pick<
                                                        RentalPlan,
                                                        'id'
                                                    > & {
                                                            length: {
                                                                __typename?: 'Duration';
                                                            } & Pick<Duration, 'unit' | 'value'>;
                                                            price: { __typename?: 'Price' } & Pick<
                                                                Price,
                                                                'inCents'
                                                            >;
                                                            oldPrice?: Maybe<
                                                                { __typename?: 'Price' } & Pick<
                                                                    Price,
                                                                    'inCents'
                                                                >
                                                            >;
                                                        }
                                                >;
                                                variants: Array<
                                                    { __typename?: 'Variant' } & Pick<
                                                        Variant,
                                                        'id' | 'sku' | 'imageUrl'
                                                    >
                                                >;
                                            }
                                    >
                                >
                            >;
                        }
                >;
            }
    >;
};

export type CastFeedbackVoteMutationMutationVariables = Exact<{
    vote: Scalars['String'];
    ticket: Scalars['String'];
    comment?: Maybe<Scalars['String']>;
}>;

export type CastFeedbackVoteMutationMutation = { __typename?: 'Mutation' } & {
    castVote: { __typename?: 'Vote' } & Pick<Vote, 'voteSlug'>;
};

export type CompanyVibanQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyVibanQuery = { __typename?: 'Query' } & {
    company: { __typename?: 'Company' } & Pick<Company, 'id' | 'name' | 'viban'>;
};

export type GroupPaymentQueryVariables = Exact<{
    contractsPerPage: Scalars['Int'];
    cursor?: Maybe<Scalars['String']>;
    groupPaymentId: Scalars['ID'];
}>;

export type GroupPaymentQuery = { __typename?: 'Query' } & {
    dashboardGroupPayment?: Maybe<
        { __typename?: 'GroupPayment' } & Pick<
            GroupPayment,
            'id' | 'byInvoice' | 'invoiceNumber' | 'invoiceUrl' | 'state'
        > & {
                contracts?: Maybe<
                    { __typename?: 'ContractConnection' } & Pick<
                        ContractConnection,
                        'totalCount'
                    > & {
                            pageInfo: { __typename?: 'PageInfo' } & Pick<
                                PageInfo,
                                'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'
                            >;
                            nodes?: Maybe<
                                Array<
                                    Maybe<
                                        | ({ __typename?: 'Flex' } & Pick<
                                              Flex,
                                              'id' | 'orderNumber' | 'name'
                                          > & {
                                                  billingTerms: { __typename?: 'BillingTerms' } & {
                                                      price: { __typename?: 'Price' } & Pick<
                                                          Price,
                                                          'currency' | 'inCents'
                                                      >;
                                                  };
                                                  goods: Array<
                                                      { __typename?: 'FlexDevice' } & Pick<
                                                          FlexDevice,
                                                          'id' | 'assetId' | 'contractId' | 'name'
                                                      > & {
                                                              product: {
                                                                  __typename?: 'Product';
                                                              } & {
                                                                  category: {
                                                                      __typename?: 'Category';
                                                                  } & Pick<Category, 'iconSvgPath'>;
                                                              };
                                                          }
                                                  >;
                                              })
                                        | { __typename?: 'Mix' }
                                    >
                                >
                            >;
                        }
                >;
                dueDate:
                    | ({ __typename: 'Date' } & Pick<Date, 'iso8601'>)
                    | ({ __typename: 'TimeEstimate' } & {
                          atLeast: { __typename?: 'Duration' } & Pick<Duration, 'unit' | 'value'>;
                          atMost?: Maybe<
                              { __typename?: 'Duration' } & Pick<Duration, 'unit' | 'value'>
                          >;
                      });
                price: { __typename?: 'Price' } & Pick<Price, 'currency' | 'inCents'>;
            }
    >;
};

export type PaymentsQueryVariables = Exact<{
    firstPayments: Scalars['Int'];
    cursor?: Maybe<Scalars['String']>;
}>;

export type PaymentsQuery = { __typename?: 'Query' } & {
    payments?: Maybe<{ __typename?: 'PaymentsUnionConnection' } & PaymentsFragmentFragment>;
};

export type PaymentsDetailsQueryVariables = Exact<{
    userId: Scalars['ID'];
}>;

export type PaymentsDetailsQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id'> & {
                activeSubscriptionValue: { __typename?: 'Price' } & Pick<
                    Price,
                    'currency' | 'inCents'
                >;
                monthlyTotal: { __typename?: 'Price' } & Pick<Price, 'currency' | 'inCents'>;
            }
    >;
};

export type PaymentsFragmentFragment = { __typename?: 'PaymentsUnionConnection' } & Pick<
    PaymentsUnionConnection,
    'totalCount'
> & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'
        >;
        nodes?: Maybe<
            Array<
                Maybe<
                    | ({ __typename?: 'GroupPayment' } & Pick<GroupPayment, 'id' | 'state'> & {
                              contracts?: Maybe<
                                  { __typename?: 'ContractConnection' } & Pick<
                                      ContractConnection,
                                      'totalCount'
                                  >
                              >;
                              dueDate:
                                  | ({ __typename?: 'Date' } & Pick<Date, 'iso8601'>)
                                  | ({ __typename?: 'TimeEstimate' } & {
                                        atLeast: { __typename?: 'Duration' } & Pick<
                                            Duration,
                                            'unit' | 'value'
                                        >;
                                        atMost?: Maybe<
                                            { __typename?: 'Duration' } & Pick<
                                                Duration,
                                                'unit' | 'value'
                                            >
                                        >;
                                    });
                              price: { __typename?: 'Price' } & Pick<Price, 'currency' | 'inCents'>;
                          })
                    | ({ __typename?: 'OrderPayment' } & Pick<
                          OrderPayment,
                          'id' | 'state' | 'byInvoice' | 'invoiceNumber' | 'invoiceUrl'
                      > & {
                              price: { __typename?: 'Price' } & Pick<Price, 'currency' | 'inCents'>;
                              basePrice: { __typename?: 'Price' } & Pick<
                                  Price,
                                  'currency' | 'inCents'
                              >;
                              dueDate:
                                  | ({ __typename?: 'Date' } & Pick<Date, 'iso8601'>)
                                  | ({ __typename?: 'TimeEstimate' } & {
                                        atLeast: { __typename?: 'Duration' } & Pick<
                                            Duration,
                                            'unit' | 'value'
                                        >;
                                        atMost?: Maybe<
                                            { __typename?: 'Duration' } & Pick<
                                                Duration,
                                                'unit' | 'value'
                                            >
                                        >;
                                    });
                              order:
                                  | ({ __typename?: 'MixCartOrder' } & Pick<
                                        MixCartOrder,
                                        'orderMode' | 'orderNumber' | 'storeCode'
                                    > & {
                                            itemTotal: { __typename?: 'Price' } & Pick<
                                                Price,
                                                'currency' | 'inCents'
                                            >;
                                            itemTotalDiscount?: Maybe<
                                                { __typename?: 'Price' } & Pick<
                                                    Price,
                                                    'currency' | 'inCents'
                                                >
                                            >;
                                            mixLineItems: Array<
                                                Maybe<
                                                    { __typename?: 'MixLineItem' } & Pick<
                                                        MixLineItem,
                                                        'quantity'
                                                    > & {
                                                            meta:
                                                                | ({
                                                                      __typename?: 'FlexCartLineItemMeta';
                                                                  } & {
                                                                      price: {
                                                                          __typename?: 'Price';
                                                                      } & Pick<
                                                                          Price,
                                                                          'currency' | 'inCents'
                                                                      >;
                                                                      product?: Maybe<
                                                                          {
                                                                              __typename?: 'Product';
                                                                          } & Pick<Product, 'name'>
                                                                      >;
                                                                  })
                                                                | ({
                                                                      __typename?: 'LineItemCatalogMeta';
                                                                  } & {
                                                                      product?: Maybe<
                                                                          {
                                                                              __typename?: 'Product';
                                                                          } & Pick<Product, 'name'>
                                                                      >;
                                                                  })
                                                                | ({
                                                                      __typename?: 'ReplacedLineItemMeta';
                                                                  } & {
                                                                      product?: Maybe<
                                                                          {
                                                                              __typename?: 'Product';
                                                                          } & Pick<Product, 'name'>
                                                                      >;
                                                                  });
                                                        }
                                                >
                                            >;
                                            shippingCost?: Maybe<
                                                { __typename?: 'Price' } & Pick<
                                                    Price,
                                                    'currency' | 'inCents'
                                                >
                                            >;
                                            total: { __typename?: 'Price' } & Pick<
                                                Price,
                                                'currency' | 'inCents'
                                            >;
                                        })
                                  | ({ __typename?: 'Order' } & Pick<
                                        Order,
                                        'number' | 'completedAt'
                                    > & {
                                            discountAmount: { __typename?: 'Price' } & Pick<
                                                Price,
                                                'currency' | 'inCents'
                                            >;
                                            shippingPrice: { __typename?: 'Price' } & Pick<
                                                Price,
                                                'currency' | 'inCents'
                                            >;
                                            price: { __typename?: 'Price' } & Pick<
                                                Price,
                                                'currency' | 'inCents'
                                            >;
                                            lineItems: Array<
                                                { __typename?: 'LineItem' } & Pick<
                                                    LineItem,
                                                    'name' | 'quantity'
                                                > & {
                                                        price: { __typename?: 'Price' } & Pick<
                                                            Price,
                                                            'inCents' | 'currency'
                                                        >;
                                                    }
                                            >;
                                        });
                              marketingOrderPaymentData: Array<
                                  { __typename?: 'HintModel' } & Pick<
                                      HintModel,
                                      'uuid' | 'bodyTemplate'
                                  >
                              >;
                          })
                    | ({ __typename?: 'PurchasePayment' } & Pick<
                          PurchasePayment,
                          'state' | 'datePaid' | 'invoiceUrl'
                      > & {
                              dueDate:
                                  | ({ __typename?: 'Date' } & Pick<Date, 'iso8601'>)
                                  | ({ __typename?: 'TimeEstimate' } & {
                                        atLeast: { __typename?: 'Duration' } & Pick<
                                            Duration,
                                            'unit' | 'value'
                                        >;
                                        atMost?: Maybe<
                                            { __typename?: 'Duration' } & Pick<
                                                Duration,
                                                'unit' | 'value'
                                            >
                                        >;
                                    });
                              price: { __typename?: 'Price' } & Pick<Price, 'currency' | 'inCents'>;
                              basePrice: { __typename?: 'Price' } & Pick<
                                  Price,
                                  'currency' | 'inCents'
                              >;
                              contract?: Maybe<
                                  | ({ __typename?: 'Flex' } & Pick<Flex, 'id' | 'name'> & {
                                            goods: Array<
                                                { __typename?: 'FlexDevice' } & Pick<
                                                    FlexDevice,
                                                    'id'
                                                > & {
                                                        product: { __typename?: 'Product' } & Pick<
                                                            Product,
                                                            'id'
                                                        > & {
                                                                category: {
                                                                    __typename?: 'Category';
                                                                } & Pick<Category, 'iconSvgPath'>;
                                                            };
                                                    }
                                            >;
                                        })
                                  | ({ __typename?: 'Mix' } & Pick<Mix, 'id'>)
                              >;
                              subscriptionDetails?: Maybe<
                                  { __typename?: 'SubscriptionDetails' } & Pick<
                                      SubscriptionDetails,
                                      'id' | 'name'
                                  > & {
                                          product?: Maybe<
                                              { __typename?: 'Product' } & Pick<Product, 'id'> & {
                                                      category: { __typename?: 'Category' } & Pick<
                                                          Category,
                                                          'iconSvgPath'
                                                      >;
                                                  }
                                          >;
                                      }
                              >;
                          })
                    | ({ __typename?: 'SubscriptionPayment' } & Pick<
                          SubscriptionPayment,
                          'id' | 'state' | 'invoiceUrl'
                      > & {
                              price: { __typename?: 'Price' } & Pick<Price, 'currency' | 'inCents'>;
                              basePrice: { __typename?: 'Price' } & Pick<
                                  Price,
                                  'currency' | 'inCents'
                              >;
                              dueDate:
                                  | ({ __typename?: 'Date' } & Pick<Date, 'iso8601'>)
                                  | ({ __typename?: 'TimeEstimate' } & {
                                        atLeast: { __typename?: 'Duration' } & Pick<
                                            Duration,
                                            'unit' | 'value'
                                        >;
                                        atMost?: Maybe<
                                            { __typename?: 'Duration' } & Pick<
                                                Duration,
                                                'unit' | 'value'
                                            >
                                        >;
                                    });
                              contract?: Maybe<
                                  | ({ __typename?: 'Flex' } & Pick<Flex, 'id' | 'name'> & {
                                            goods: Array<
                                                { __typename?: 'FlexDevice' } & Pick<
                                                    FlexDevice,
                                                    'id'
                                                > & {
                                                        product: { __typename?: 'Product' } & Pick<
                                                            Product,
                                                            'id'
                                                        > & {
                                                                category: {
                                                                    __typename?: 'Category';
                                                                } & Pick<Category, 'iconSvgPath'>;
                                                            };
                                                    }
                                            >;
                                        })
                                  | ({ __typename?: 'Mix' } & Pick<Mix, 'id'>)
                              >;
                              subscriptionDetails?: Maybe<
                                  { __typename?: 'SubscriptionDetails' } & Pick<
                                      SubscriptionDetails,
                                      'id' | 'name'
                                  > & {
                                          product?: Maybe<
                                              { __typename?: 'Product' } & Pick<Product, 'id'> & {
                                                      category: { __typename?: 'Category' } & Pick<
                                                          Category,
                                                          'iconSvgPath'
                                                      >;
                                                  }
                                          >;
                                      }
                              >;
                              marketingSubscriptionPaymentData: Array<
                                  { __typename?: 'HintModel' } & Pick<
                                      HintModel,
                                      'uuid' | 'bodyTemplate'
                                  >
                              >;
                          })
                >
            >
        >;
    };

export type PaymentsFragmentWithoutSubscriptionDetailsFragment = {
    __typename?: 'PaymentsUnionConnection';
} & Pick<PaymentsUnionConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'
        >;
        nodes?: Maybe<
            Array<
                Maybe<
                    | { __typename?: 'GroupPayment' }
                    | ({ __typename?: 'OrderPayment' } & Pick<
                          OrderPayment,
                          'id' | 'state' | 'invoiceUrl'
                      > & {
                              price: { __typename?: 'Price' } & Pick<Price, 'currency' | 'inCents'>;
                              basePrice: { __typename?: 'Price' } & Pick<
                                  Price,
                                  'currency' | 'inCents'
                              >;
                              dueDate:
                                  | ({ __typename?: 'Date' } & Pick<Date, 'iso8601'>)
                                  | ({ __typename?: 'TimeEstimate' } & {
                                        atLeast: { __typename?: 'Duration' } & Pick<
                                            Duration,
                                            'unit' | 'value'
                                        >;
                                        atMost?: Maybe<
                                            { __typename?: 'Duration' } & Pick<
                                                Duration,
                                                'unit' | 'value'
                                            >
                                        >;
                                    });
                              order:
                                  | ({ __typename?: 'MixCartOrder' } & Pick<
                                        MixCartOrder,
                                        'orderNumber'
                                    >)
                                  | ({ __typename?: 'Order' } & Pick<
                                        Order,
                                        'id' | 'number' | 'completedAt'
                                    > & {
                                            discountAmount: { __typename?: 'Price' } & Pick<
                                                Price,
                                                'currency' | 'inCents'
                                            >;
                                            shippingPrice: { __typename?: 'Price' } & Pick<
                                                Price,
                                                'currency' | 'inCents'
                                            >;
                                            price: { __typename?: 'Price' } & Pick<
                                                Price,
                                                'currency' | 'inCents'
                                            >;
                                            lineItems: Array<
                                                { __typename?: 'LineItem' } & Pick<
                                                    LineItem,
                                                    'name' | 'quantity'
                                                > & {
                                                        price: { __typename?: 'Price' } & Pick<
                                                            Price,
                                                            'inCents' | 'currency'
                                                        >;
                                                    }
                                            >;
                                        });
                              marketingOrderPaymentData: Array<
                                  { __typename?: 'HintModel' } & Pick<
                                      HintModel,
                                      'uuid' | 'bodyTemplate'
                                  >
                              >;
                          })
                    | ({ __typename?: 'PurchasePayment' } & Pick<
                          PurchasePayment,
                          'id' | 'state' | 'datePaid' | 'invoiceUrl'
                      > & {
                              dueDate:
                                  | ({ __typename?: 'Date' } & Pick<Date, 'iso8601'>)
                                  | ({ __typename?: 'TimeEstimate' } & {
                                        atLeast: { __typename?: 'Duration' } & Pick<
                                            Duration,
                                            'unit' | 'value'
                                        >;
                                        atMost?: Maybe<
                                            { __typename?: 'Duration' } & Pick<
                                                Duration,
                                                'unit' | 'value'
                                            >
                                        >;
                                    });
                              price: { __typename?: 'Price' } & Pick<Price, 'currency' | 'inCents'>;
                              basePrice: { __typename?: 'Price' } & Pick<
                                  Price,
                                  'currency' | 'inCents'
                              >;
                          })
                    | ({ __typename?: 'SubscriptionPayment' } & Pick<
                          SubscriptionPayment,
                          'id' | 'state' | 'invoiceUrl'
                      > & {
                              price: { __typename?: 'Price' } & Pick<Price, 'currency' | 'inCents'>;
                              basePrice: { __typename?: 'Price' } & Pick<
                                  Price,
                                  'currency' | 'inCents'
                              >;
                              dueDate:
                                  | ({ __typename?: 'Date' } & Pick<Date, 'iso8601'>)
                                  | ({ __typename?: 'TimeEstimate' } & {
                                        atLeast: { __typename?: 'Duration' } & Pick<
                                            Duration,
                                            'unit' | 'value'
                                        >;
                                        atMost?: Maybe<
                                            { __typename?: 'Duration' } & Pick<
                                                Duration,
                                                'unit' | 'value'
                                            >
                                        >;
                                    });
                              contract?: Maybe<{ __typename: 'Flex' } | { __typename: 'Mix' }>;
                              marketingSubscriptionPaymentData: Array<
                                  { __typename?: 'HintModel' } & Pick<
                                      HintModel,
                                      'uuid' | 'bodyTemplate'
                                  >
                              >;
                          })
                >
            >
        >;
    };

export type SubscriptionPaymentsQueryVariables = Exact<{
    id: Scalars['ID'];
    firstPayments: Scalars['Int'];
    cursor?: Maybe<Scalars['String']>;
}>;

export type SubscriptionPaymentsQuery = { __typename?: 'Query' } & {
    subscriptionDetails?: Maybe<
        | ({ __typename?: 'Flex' } & Pick<Flex, 'id'> & { contractName: Flex['name'] } & {
                  goods: Array<
                      { __typename?: 'FlexDevice' } & Pick<FlexDevice, 'id'> & {
                              product: { __typename?: 'Product' } & Pick<Product, 'id'> & {
                                      category: { __typename?: 'Category' } & Pick<
                                          Category,
                                          'iconSvgPath'
                                      >;
                                  };
                          }
                  >;
              })
        | ({ __typename?: 'SubscriptionDetails' } & Pick<SubscriptionDetails, 'id' | 'name'> & {
                  product?: Maybe<
                      { __typename?: 'Product' } & Pick<Product, 'id'> & {
                              category: { __typename?: 'Category' } & Pick<Category, 'iconSvgPath'>;
                          }
                  >;
              })
    >;
    payments?: Maybe<
        {
            __typename?: 'PaymentsUnionConnection';
        } & PaymentsFragmentWithoutSubscriptionDetailsFragment
    >;
};

export type SubscriptionPaymentsDetailsQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type SubscriptionPaymentsDetailsQuery = { __typename?: 'Query' } & {
    subscriptionDetails?: Maybe<
        | ({ __typename?: 'Flex' } & Pick<Flex, 'id'> & {
                  contractName: Flex['name'];
                  contractState: Flex['state'];
              } & {
                  note?: Maybe<
                      | ({ __typename?: 'AttentionNote' } & Pick<AttentionNote, 'text'>)
                      | ({ __typename?: 'EndedNote' } & Pick<EndedNote, 'text'>)
                      | ({ __typename?: 'NextPaymentNote' } & Pick<NextPaymentNote, 'date'> & {
                                paymentMethod?: Maybe<
                                    | { __typename: 'ByInvoice' }
                                    | { __typename: 'Card' }
                                    | { __typename: 'Paypal' }
                                    | { __typename: 'Sepa' }
                                >;
                            })
                      | ({ __typename?: 'PausedNote' } & Pick<PausedNote, 'text'>)
                      | ({ __typename?: 'ProcessingNote' } & Pick<ProcessingNote, 'text'>)
                  >;
                  goods: Array<
                      { __typename?: 'FlexDevice' } & Pick<FlexDevice, 'id'> & {
                              variant: { __typename?: 'Variant' } & Pick<
                                  Variant,
                                  'id' | 'colorCodes' | 'colorTitle' | 'imageUrl'
                              >;
                          }
                  >;
                  billingTerms: { __typename?: 'BillingTerms' } & {
                      price: { __typename?: 'Price' } & Pick<Price, 'currency' | 'inCents'>;
                  };
              })
        | ({ __typename?: 'SubscriptionDetails' } & Pick<
              SubscriptionDetails,
              'id' | 'name' | 'state'
          > & {
                  note?: Maybe<
                      | ({ __typename?: 'AttentionNote' } & Pick<AttentionNote, 'text'>)
                      | ({ __typename?: 'EndedNote' } & Pick<EndedNote, 'text'>)
                      | ({ __typename?: 'NextPaymentNote' } & Pick<NextPaymentNote, 'date'> & {
                                paymentMethod?: Maybe<
                                    | { __typename: 'ByInvoice' }
                                    | { __typename: 'Card' }
                                    | { __typename: 'Paypal' }
                                    | { __typename: 'Sepa' }
                                >;
                            })
                      | ({ __typename?: 'PausedNote' } & Pick<PausedNote, 'text'>)
                      | ({ __typename?: 'ProcessingNote' } & Pick<ProcessingNote, 'text'>)
                  >;
                  variant?: Maybe<
                      { __typename?: 'Variant' } & Pick<
                          Variant,
                          'id' | 'colorCodes' | 'colorTitle' | 'imageUrl'
                      >
                  >;
                  price: { __typename?: 'Price' } & Pick<Price, 'currency' | 'inCents'>;
              })
    >;
};

export type DeleteAuthDeviceMutationVariables = Exact<{
    input: DeleteAuthDeviceInput;
}>;

export type DeleteAuthDeviceMutation = { __typename?: 'Mutation' } & {
    deleteAuthDevice?: Maybe<
        { __typename?: 'DeleteAuthDevicePayload' } & Pick<
            DeleteAuthDevicePayload,
            'clientMutationId'
        >
    >;
};

export type UpdateUserNewsletterOptInStateMutationVariables = Exact<{
    input: UpdateUserNewsletterOptInStateInput;
}>;

export type UpdateUserNewsletterOptInStateMutation = { __typename?: 'Mutation' } & {
    updateUserNewsletterOptInState?: Maybe<
        { __typename?: 'UpdateUserNewsletterOptInStatePayload' } & Pick<
            UpdateUserNewsletterOptInStatePayload,
            'clientMutationId'
        > & { user: { __typename?: 'User' } & Pick<User, 'newsletterOptInState'> }
    >;
};

export type AccountSecurityQueryVariables = Exact<{
    userId: Scalars['ID'];
}>;

export type AccountSecurityQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & Pick<
            User,
            'id' | 'email' | 'emailVerified' | 'phone' | 'phoneVerified'
        >
    >;
};

export type CardQueryQueryVariables = Exact<{ [key: string]: never }>;

export type CardQueryQuery = { __typename?: 'Query' } & {
    person?: Maybe<
        { __typename?: 'Person' } & {
            cards: Array<{ __typename?: 'GroverCard' } & Pick<GroverCard, 'id'>>;
        }
    >;
};

export type DevicesQueryVariables = Exact<{ [key: string]: never }>;

export type DevicesQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id'> & {
                devices?: Maybe<
                    { __typename?: 'AuthDeviceConnection' } & {
                        nodes?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'AuthDevice' } & Pick<
                                        AuthDevice,
                                        | 'deviceType'
                                        | 'id'
                                        | 'createdAt'
                                        | 'os'
                                        | 'platform'
                                        | 'stale'
                                    > & {
                                            lastLocation?: Maybe<
                                                { __typename?: 'AuthDeviceHistoryItem' } & Pick<
                                                    AuthDeviceHistoryItem,
                                                    | 'city'
                                                    | 'country'
                                                    | 'countryIso'
                                                    | 'ipAddress'
                                                    | 'lastSeenAt'
                                                    | 'createdAt'
                                                >
                                            >;
                                        }
                                >
                            >
                        >;
                    }
                >;
            }
    >;
};

export type DevicesWithHistoryQueryVariables = Exact<{ [key: string]: never }>;

export type DevicesWithHistoryQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id'> & {
                devices?: Maybe<
                    { __typename?: 'AuthDeviceConnection' } & {
                        nodes?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'AuthDevice' } & Pick<
                                        AuthDevice,
                                        'deviceType' | 'id' | 'os' | 'platform' | 'stale'
                                    > & {
                                            history: Array<
                                                { __typename?: 'AuthDeviceHistoryItem' } & Pick<
                                                    AuthDeviceHistoryItem,
                                                    | 'city'
                                                    | 'country'
                                                    | 'countryIso'
                                                    | 'createdAt'
                                                    | 'ipAddress'
                                                    | 'lastSeenAt'
                                                >
                                            >;
                                            lastLocation?: Maybe<
                                                { __typename?: 'AuthDeviceHistoryItem' } & Pick<
                                                    AuthDeviceHistoryItem,
                                                    | 'city'
                                                    | 'country'
                                                    | 'countryIso'
                                                    | 'ipAddress'
                                                    | 'lastSeenAt'
                                                    | 'createdAt'
                                                >
                                            >;
                                        }
                                >
                            >
                        >;
                    }
                >;
            }
    >;
};

export type PersonalInformationQueryVariables = Exact<{ [key: string]: never }>;

export type PersonalInformationQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & Pick<
            User,
            | 'id'
            | 'birthdate'
            | 'email'
            | 'emailVerified'
            | 'firstName'
            | 'lastName'
            | 'phone'
            | 'phoneVerified'
        >
    >;
};

export type UserNewsletterOptInStateQueryQueryVariables = Exact<{
    userId: Scalars['ID'];
}>;

export type UserNewsletterOptInStateQueryQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & Pick<User, 'email' | 'emailVerified' | 'newsletterOptInState'>
    >;
};

export type YourProfileQueryQueryVariables = Exact<{
    userId: Scalars['ID'];
}>;

export type YourProfileQueryQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & Pick<
            User,
            'id' | 'email' | 'firstName' | 'lastName' | 'verificationState' | 'type'
        > & {
                hostConnection?: Maybe<
                    { __typename?: 'ConnectionModel' } & Pick<ConnectionModel, 'paymentDate'>
                >;
                company?: Maybe<
                    { __typename?: 'Company' } & Pick<Company, 'status'> & {
                            businessDashboard?: Maybe<
                                { __typename?: 'BusinessDashboard' } & Pick<
                                    BusinessDashboard,
                                    'enabled'
                                >
                            >;
                        }
                >;
                guestConnections: Array<
                    { __typename?: 'ConnectionModel' } & Pick<ConnectionModel, 'paymentDate'>
                >;
            }
    >;
};

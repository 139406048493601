import {
    LineItemVariantInput,
    LineItemCategoryInput,
    LineItemProductInput,
    MixLineItemInput,
} from 'ggApp/types/graphql';

export const getProductInput = ({
    id,
    sku,
    name,
    coreAttribute,
    slug,
}: LineItemProductInput): LineItemProductInput => ({
    id,
    sku,
    name,
    coreAttribute,
    slug,
});
export const getVariantInput = ({
    id,
    sku,
    colorTitle,
    colorCodes,
    imageUrl,
    mixable,
}: LineItemVariantInput): LineItemVariantInput => ({
    id,
    sku,
    colorTitle,
    colorCodes,
    imageUrl,
    mixable,
});

export const convertProductInput = ({
    core_attribute: coreAttribute,
    frontname: name,
    sku,
    id,
    slug,
}: any): LineItemProductInput => ({
    coreAttribute,
    name,
    slug,
    id,
    sku,
});

export const convertVariantInput = (variant: any): LineItemVariantInput => ({
    id: variant.id,
    sku: variant.sku,
    colorTitle: variant.property?.title,
    colorCodes: variant.property?.codes,
    imageUrl: variant.pictures[0].image_urls?.small,
    mixable: variant.mixable,
});

export const convertCategoryInput = (product: any): LineItemCategoryInput => ({
    categoryId: product.category?.category_id ?? null,
    subCategoryId: product.category?.sub_category_id ?? null,
});

export const constructLineItem = ({
    product,
    variant,
    quantity = 1,
    committedMonths = 1,
}: any): MixLineItemInput => {
    return {
        meta: {
            committedMonths,
            category: convertCategoryInput(product),
            product: convertProductInput(product),
            variant: convertVariantInput(variant),
        },
        quantity,
    };
};

/* eslint-disable */
import ggStore from 'ggApp/modules/store';

import React, { PureComponent, } from 'react';
import { connect, } from 'react-redux';
import { Link, browserHistory, withRouter, } from 'react-router';
import { createStructuredSelector, } from 'reselect';

const constructPathname = (storeCode, path) => {
    const relativePath = [
        storeCode,
        path,
    ].join('');
    return relativePath.charAt(0) === '/' ? relativePath : `/${relativePath}`;
};

class LinkWithLanguage extends PureComponent {
  addStoreToLink(link) {
    const {
      activeStore,
    } = this.props;

    if(!link) return;

    if(typeof link === 'string') return constructPathname(
      activeStore.code,
      link
    );

    return Object.assign({}, link, {
      pathname: constructPathname(
        activeStore.code,
        link.pathname,
      ),
    })
  }

  render() {
    const
      { to, goBack, router, external, } = this.props;

    if(goBack) {
      return (
        <div {...this.props} onClick={browserHistory.goBack}>
          {this.props.children}
        </div>
      )
    }

    if(external) {
      return (
        <a {...this.props} href={to}>
          {this.props.children}
        </a>
      );
    }

    const renderRealLink = (to, props) => {
      let className = props.className || '';

      if(router && router.isActive && router.isActive(to)) {
        className = `${props.className} active`;
      }

      return (
          <a className={className} href={to} data-testid="real-link">
          {props.children}
        </a>
      );
    }
    const linkWithStore = this.addStoreToLink(to)
    return !this.props.real ? (
        <Link {...this.props} to={linkWithStore}>
          {this.props.children}
        </Link>
      ) : (
        renderRealLink(linkWithStore, this.props)
      )
  }
}


export default withRouter(
  connect(
    createStructuredSelector({
      activeStore: ggStore.selectors.storeActive,
    })
  )(LinkWithLanguage)
)

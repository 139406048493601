import { useState, useEffect } from 'react';

export default function useYourTechPointer({ pathname, user, company }) {
    const [shouldShowYourTechPointer, setShouldShowYourTechPointer] = useState(false);

    const isOrderConfirmation = Boolean(pathname.match('/confirmation'));

    const isBusinessCustomer = user.user_type === 'business_customer';
    const isCompanyApproved = company.status === 'approved';

    useEffect(() => {
        if (isBusinessCustomer && !isCompanyApproved) return;

        setShouldShowYourTechPointer(isOrderConfirmation);
    }, [isBusinessCustomer, isCompanyApproved, isOrderConfirmation]);

    return shouldShowYourTechPointer;
}

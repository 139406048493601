import React from 'react';
import PropTypes from 'prop-types';

import Card from './Card';

const CardMapper = ({ steps, carousel, cardWidth }) =>
    steps.map((item) => (
        <Card
            carouselStyle={carousel}
            key={item.title}
            title={item.title}
            description={item.description}
            imageUrl={item.icon.url}
            cardWidth={cardWidth}
        />
    ));

CardMapper.propTypes = {
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.node,
        }),
    ),
    carousel: PropTypes.bool,
};
CardMapper.defaultProps = {
    steps: [],
    carousel: false,
};

export default CardMapper;

import * as selectors from './selectors';
import reducer from './reducers';
import * as constants from './constants';

// components:
// Legacy export
// eslint-disable-next-line camelcase
export { default as TranslatorComponent_Text } from './components/Translator';

// New export
export * from './components';
export * from './hooks';
export const { translationFunctionSelector } = selectors;

// --- rest:
export default { reducer, constants, selectors };

import {
    Guard,
    OrderModes,
    PhoneVerificationPendingType,
    PhoneRequestVerificationOrigin,
} from 'ggApp/shared/context/Order/types';
import flows from 'ggApp/modules/newCheckout/constants/flowNames';
import { getOrderFromCookies } from 'ggApp/shared/context/Order/cookies';

const ADD_MIX_ITEM_ERROR = 'New item can not be added';

interface GuardContext {
    orderMode: 'MIX' | 'FLEX';
    cart: {
        homeAddressId?: number;
        shippingAddressId?: number;
        paymentMethod?: { id: number };
        items: Array<any>;
    };
    activeStore: {
        code: string;
    };
    user: {
        id: string;
    };
    updatedUserData: boolean;
    userDataFetched: boolean;
    returningFromRedirect: boolean;
    newCheckoutFlow: string;
    userAddresses: Array<object>;
    userPayments: Array<object>;
    hasPrefilledOrder: boolean;
    cartLoaded: boolean;
    phoneVerificationPending: PhoneVerificationPendingType;
}

export const guards: Guard<GuardContext> = {
    canFetchOrder: ({ activeStore, orderMode, cartLoaded, user }) => {
        const hasCode = activeStore.code;
        const hasCookie = getOrderFromCookies(orderMode, activeStore.code);

        if (!user?.id && orderMode === OrderModes.Mix) {
            return false;
        }

        return Boolean(hasCode && hasCookie && !cartLoaded);
    },
    isEmptyOrGuest: ({ cart, user }) => {
        const isEmpty = Object.keys(cart).length === 0;
        const isGuest = !user?.id;
        return Boolean(isEmpty || isGuest);
    },
    shouldFetchUserData: ({ user, updatedUserData, userDataFetched }) => {
        return Boolean(user?.id && !updatedUserData && userDataFetched);
    },
    canPrefillOrderData: ({
        orderMode,
        updatedUserData,
        hasPrefilledOrder,
        userAddresses,
        userPayments,
        cart,
        activeStore,
    }) => {
        const canPrefillAddressData = !cart.shippingAddressId && userAddresses.length > 0;
        const canPrefillPaymentMethods = !cart.paymentMethod?.id && userPayments.length > 0;
        return Boolean(
            orderMode === OrderModes.Flex &&
                activeStore?.code !== 'us' &&
                cart.items &&
                updatedUserData &&
                !hasPrefilledOrder &&
                (canPrefillAddressData || canPrefillPaymentMethods),
        );
    },
    readyForReview: ({ cart, newCheckoutFlow }) => {
        const readyForReviewWithHomeAddress = Boolean(
            newCheckoutFlow === flows.CHECKOUT_WITH_HOME_ADDRESS &&
                cart &&
                cart.shippingAddressId &&
                cart.homeAddressId &&
                cart.paymentMethod?.id,
        );
        const readyForReviewNoHomeAddress = Boolean(
            newCheckoutFlow === flows.CHECKOUT_NO_HOME_ADDRESS &&
                cart &&
                cart.shippingAddressId &&
                cart.paymentMethod?.id,
        );
        return Boolean(readyForReviewWithHomeAddress || readyForReviewNoHomeAddress);
    },
    emptyPaymentMethod: ({ cart, newCheckoutFlow }) => {
        const readyForPaymentWithHomeAddress = Boolean(
            newCheckoutFlow === flows.CHECKOUT_WITH_HOME_ADDRESS &&
                cart &&
                cart.shippingAddressId &&
                cart.homeAddressId,
        );
        const readyForPaymentNoHomeAddress = Boolean(
            newCheckoutFlow === flows.CHECKOUT_NO_HOME_ADDRESS && cart && cart.shippingAddressId,
        );
        return Boolean(readyForPaymentWithHomeAddress || readyForPaymentNoHomeAddress);
    },
    emptyHomeAddress: ({ cart, newCheckoutFlow }) => {
        const readyForHomeAddress = Boolean(
            newCheckoutFlow === flows.CHECKOUT_WITH_HOME_ADDRESS && cart && cart.shippingAddressId,
        );
        return Boolean(readyForHomeAddress);
    },
    returningFromRedirect: ({ redirectionFlow }) => {
        return redirectionFlow.enabled;
    },
    isCheckoutInactive: ({ isCheckoutActive }) => {
        return !isCheckoutActive;
    },
    twoFaRequired: (context: any, event: any) =>
        event.data?.response?.status === 422 &&
        event.data?.response?.data?.['2fa_required'] === true,
    isHomeAddressUpdate: ({ phoneVerificationPending }): boolean =>
        phoneVerificationPending.origin === PhoneRequestVerificationOrigin.HomeAddressAdd,
    isShippingAddressUpdate: ({ phoneVerificationPending }): boolean =>
        phoneVerificationPending.origin === PhoneRequestVerificationOrigin.ShippingAddressAdd,
    errorBased4thItem: (ctx: GuardContext, event: { data: { message: string }[] }): boolean => {
        const [error] = event?.data ?? [];
        return error && error.message === ADD_MIX_ITEM_ERROR;
    },
};

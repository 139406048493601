/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import asyncComponent from 'ggApp/shared/components/async';
import React from 'react';

import {
    ModalComponent_Router as ModalRouter,
    ModalComponent_Route as ModalRoute,
} from 'ggApp/modules/modal';

const AuthModal_Login = asyncComponent(() => import('ggApp/modules/auth/modals/CheckoutLogin'));
const AuthModal_Signup = asyncComponent(() => import('ggApp/modules/auth/modals/CheckoutSignup'));
const AuthModal_Recovery = asyncComponent(() => import('ggApp/modules/auth/modals/recovery'));
const AuthModal_Update = asyncComponent(() => import('ggApp/modules/auth/modals/update'));

const AddressModal_Edit = asyncComponent(() => import('ggApp/modules/address/modals/edit'));
const AddressModal_Create = asyncComponent(() => import('ggApp/modules/address/modals/create'));
const AddressModal_Select = asyncComponent(() => import('ggApp/modules/address/modals/select'));

const PaymentModal_Create = asyncComponent(() => import('ggApp/modules/payment/modals/create'));
const OrderModal_ConfirmCancel = asyncComponent(() =>
    import('ggApp/shared/modals/order/ConfirmCancel'),
);

const SubscriptionModal_ChangePaymentMethod = asyncComponent(() =>
    import('ggApp/shared/modals/subscription/changePaymentMethod'),
);
const SubscriptionModal_AddPaymentMethod = asyncComponent(() =>
    import('ggApp/shared/modals/subscription/addPaymentMethod'),
);
const SubscriptionModal_Purchase = asyncComponent(() =>
    import('ggApp/shared/modals/subscription/Purchase'),
);

const Auth_Modal_Login = asyncComponent(() => import('ggApp/shared/modals/auth/Login'));
const Auth_Modal_Recovery = asyncComponent(() => import('ggApp/shared/modals/auth/recovery'));

const RegistrationModal = asyncComponent(() => import('ggApp/shared/modals/onboarding'));
const MainSignupModal = asyncComponent(() => import('ggApp/modules/auth/modals/mainSignup'));

// These routes are deprecated. Don't use them for creating new modal windows.
// The new modal component is in ggApp/modules/modal/components/v3/modal

// prettier-ignore
const routes = (
  <ModalRouter>
    <ModalRoute
      withTransitions
      path="login"
      component={AuthModal_Login}
    />
    <ModalRoute
      withTransitions
      path="signup"
      component={AuthModal_Signup}
    />
    <ModalRoute
      withTransitions
      path="recovery"
      component={AuthModal_Recovery}
    />
    <ModalRoute
      withTransitions
      path="update"
      component={AuthModal_Update}
    />
    <ModalRoute
      withTransitions
      path="address-edit"
      component={AddressModal_Edit}
    />
    <ModalRoute
      withTransitions
      path="address-create"
      component={AddressModal_Create}
    />
    <ModalRoute
      withTransitions
      path="address-select"
      component={AddressModal_Select}
    />
    <ModalRoute
      withTransitions
      noMobileVerticalPadding
      path="payment-create"
      component={PaymentModal_Create}
    />

    <ModalRoute
      withTransitions
      dark
      path="order-confirm-cancel"
      component={OrderModal_ConfirmCancel}
    />
    <ModalRoute
      withTransitions
      dark
      path="subscription-change-payment-method"
      component={SubscriptionModal_ChangePaymentMethod}
    />
    <ModalRoute
      withTransitions
      dark
      path="subscription-add-payment-method"
      component={SubscriptionModal_AddPaymentMethod}
    />
    <ModalRoute
      withTransitions
      dark
      path="subscription-purchase"
      component={SubscriptionModal_Purchase}
    />

	<ModalRoute
      withTransitions
      path="main-login"
      component={Auth_Modal_Login}
	/>
    <ModalRoute
      withTransitions
      path="main-signup"
      component={MainSignupModal}
    />
    <ModalRoute
      withTransitions
      path="main-recovery"
      component={Auth_Modal_Recovery}
    />
    <ModalRoute
        withTransitions
        component={RegistrationModal}
        path="onboarding"
        narrow
    />
  </ModalRouter>
);

export default routes;

import { getDeliveryEstimation } from 'staticStoreConfig';

import { pushDataLayer, sendToGtm } from 'ggApp/modules/tracking/actions';
import { currentProduct, listData as productList } from 'ggApp/modules/product';
import { ANALYTIC_EVENTS } from 'ggApp/modules/gaAnalytics/constants';
import {
    getReferralEvent,
    shareReferralsEvent,
} from 'ggApp/modules/gaAnalytics/selectors/referrals';

import { widgetListProducts as widgetListProductsSelector } from 'ggApp/modules/productWidget/selectors';
import { searchOverlayEventSelector } from 'ggApp/modules/gaAnalytics/selectors/searchOverlay';
import { user as userSelector } from 'ggApp/modules/auth/selectors';
import { location as locationSelector } from 'ggApp/modules/router/selectors';
import { getProductSharingEvent } from 'ggApp/modules/gaAnalytics/selectors/sharing';
import purchaseSelectorV2 from 'ggApp/modules/gaAnalytics/selectors/purchase';
import { getKeyForVoucherError } from 'ggApp/modules/form/components/voucherFields';
import { translationFunctionSelector } from 'ggApp/modules/translator';
import { sendDataToSnowplow } from 'ggApp/modules/tracking/actions/snowplow';
import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';

import { getOrderFromCookiesBasedStore as getOrderFromCookies } from 'ggApp/shared/context/Order/cookies';

import {
    purchaseSelector,
    addToCartOrderSelector,
    addToCartProductSelector,
    checkoutOptionsSelector,
    checkoutSelector,
    detailedProductImpressionSelectorV2,
    productClickEventSelector,
    productImpressionSelector,
    removeFromCartOrderSelector,
    productFavoriteEventSelector,
    accountMenuEventSelector,
    gPageProductSelector,
    snowplowProductImpressionsSelector,
    productAddedAnalyticsSelector,
    productViewedAnalyticsSelector,
    addToCartSnowplowSelector,
    removeFromCartSnowplowSelector,
    productImpressionSnowplowSelector,
    productClickEventSnowplowSelector,
    cartContentChangeSnowplowSelector,
    cartContentChangeActionSelector,
} from 'ggApp/modules/gaAnalytics/selectors';

import {
    sendVideoPdpEvent,
    sendBundleImpression,
    sendProductImpressionEvent,
    sendCategoryMarketingCardClick,
    sendAddToCartEvent,
    sendWishListEvent,
    sendFooterCtaClickEvent,
    sendLandingPageCtaClickEvent,
    sendCartContentChange,
    sendSubscriptionLengthSliderEvent,
} from 'snowplow';

import { trackProductAdded } from 'analytics/product/trackProductAdded';
import { trackProductViewed } from 'analytics/product/trackProductViewed';
import { trackSegmentWishListEvent } from 'analytics/product/trackSegmentWishListEvent';

import { trackWidgetClicked } from 'analytics/product/trackWidgetClicked';
import { teaserEventMapper } from '../selectors/teaser';

// legacy
// will be removed, when integration with Blaze is completed
export const registerProductDetailedImpression = ({
    productSelector,
    productVariantSelector,
    rentalPlanSelector,
} = {}) => (dispatch, getState) => {
    const eventPayload = detailedProductImpressionSelectorV2({
        productSelector,
        productVariantSelector,
        rentalPlanSelector,
    })(getState());
    pushDataLayer(eventPayload);
};

export const registerProductDetailedImpressionV2 = () => (dispatch, getState) => {
    const snowplowPayload = snowplowProductImpressionsSelector(getState());
    // TODO: @analytics Remove snowplow event
    const analyticsPayload = productViewedAnalyticsSelector(getState());
    const newPayload = {
        ...analyticsPayload,
        product_id: analyticsPayload.product_variant,
    };
    trackProductViewed(newPayload);
    sendProductImpressionEvent(snowplowPayload);
    sendToGtm(snowplowPayload);
};
export const registerSubLengthEvent = () => (dispatch, getState) => {
    const snowplowPayload = snowplowProductImpressionsSelector(getState());
    // TODO: @analytics Remove snowplow event
    const analyticsPayload = productViewedAnalyticsSelector(getState());
    trackProductViewed({
        ...analyticsPayload,
        product_id: analyticsPayload.product_variant,
    });
    const adjustedPayload = {
        ...snowplowPayload,
        eventAction: 'subscriptionSlider',
    };
    sendSubscriptionLengthSliderEvent(adjustedPayload);
};

export const registerAddToCart = (productId, order) => (dispatch, getState) => {
    const eventPayload = productId
        ? addToCartOrderSelector(productId, order)(getState())
        : addToCartProductSelector(getState());
    if (eventPayload) {
        const snowplowPayload = addToCartSnowplowSelector(eventPayload, order)(getState());
        sendToGtm(eventPayload);
        sendToGtm(snowplowPayload);
        sendAddToCartEvent(snowplowPayload);

        // TODO: @analytics Remove snowplow event
        const analyticsPayload = productAddedAnalyticsSelector(eventPayload, order)(getState());
        trackProductAdded(analyticsPayload);
    }
};

export const registerRemoveFromCart = (product, quantity, order) => (dispatch, getState) => {
    const eventPayload = removeFromCartOrderSelector(product, quantity)(getState());
    if (eventPayload) {
        pushDataLayer(eventPayload);
        const snowplowPayload = removeFromCartSnowplowSelector(
            eventPayload,
            product?.variant?.mixable,
            order,
        )(getState());
        pushDataLayer(snowplowPayload);
    }
};

export const registerProductImpression = (listName, position, listSelector = productList) => (
    dispatch,
    getState,
) => {
    const eventPayloadGA = productImpressionSelector(listName, position, getState(), listSelector);
    const eventPayloadSnowplow = productImpressionSnowplowSelector(
        listName,
        position,
        listSelector,
    )(getState());
    if (eventPayloadGA) {
        pushDataLayer(eventPayloadGA);
    }
    if (eventPayloadSnowplow) {
        pushDataLayer(eventPayloadSnowplow);
    }
};

export const registerClickEvent = (listName, position, listSelector = productList) => (
    dispatch,
    getState,
) => {
    const eventPayloadGA = productClickEventSelector(listName, position, getState(), listSelector);
    const eventPayloadSnowplow = productClickEventSnowplowSelector(
        listName,
        position,
        listSelector,
    )(getState());
    if (eventPayloadGA) {
        pushDataLayer(eventPayloadGA);
    }
    if (eventPayloadSnowplow) {
        pushDataLayer(eventPayloadSnowplow);
    }
};

export const registerTeaserClickEvent = ({ widgetName, title, url, ctaViewAll }) => () => {
    const clickType = ctaViewAll ? 'viewAll' : 'cta';
    const eventPayload = teaserEventMapper({
        title,
        widgetName,
        eventAction: 'widgetClick',
        url,
        clickType,
    });
    pushDataLayer(eventPayload);
    trackWidgetClicked({
        ...eventPayload.eventProperty,
        widgetName: eventPayload.eventCategory,
        eventAction: 'Widget Clicked',
        eventLabel: 'Widget Clicked',
    });
};

export const registerTeaserClickProductEvent = ({
    widgetName,
    title,
    url,
    firstIndexProduct,
    productIndex,
}) => (dispatch, getState) => {
    const products = widgetListProductsSelector(getState());
    const product = products[firstIndexProduct + productIndex];
    const eventPayload = teaserEventMapper({
        title,
        widgetName,
        eventAction: 'widgetClick',
        url,
        clickType: 'pdp',
        product,
    });
    pushDataLayer(eventPayload);
    trackWidgetClicked({
        ...eventPayload.eventProperty,
        widget_name: eventPayload.eventCategory,
        eventAction: 'Widget Clicked',
        eventLabel: 'Widget Clicked',
    });
};

export const registerTeaserImpressionEvent = ({ widgetName, title, url }) => () => {
    const eventPayload = teaserEventMapper({
        title,
        widgetName,
        eventAction: 'widgetImpression',
        url,
    });
    pushDataLayer(eventPayload);
};

export const registerFavoritesEvent = ({
    isFavorite,
    product,
    currentVariant,
    selectedRentalPlan,
    isLoggedIn,
}) => {
    if (isFavorite === undefined) return;
    const eventPayload = productFavoriteEventSelector({
        isFavorite,
        product,
        currentVariant,
        selectedRentalPlan,
    });
    if (eventPayload) {
        sendWishListEvent(eventPayload);
        if (isLoggedIn) {
            trackSegmentWishListEvent({
                ...eventPayload,
                eventAction: isFavorite
                    ? 'Product Added to Wishlist'
                    : 'Product Removed from Wishlist',
                eventLabel: isFavorite
                    ? 'Product Added to Wishlist'
                    : 'Product Removed from Wishlist',
            });
        }
        sendToGtm({ ...eventPayload, event: eventPayload.eventLabel });
    }
};

export const buildAccountMenuEventFunction = (actionEvent) => () => {
    if (actionEvent === undefined) return;
    const eventPayload = accountMenuEventSelector(actionEvent);
    if (eventPayload) {
        pushDataLayer(eventPayload);
    }
};

export const registerCheckout = (order, step) => () => {
    const eventPayload = checkoutSelector(order, step);
    if (eventPayload) {
        pushDataLayer(eventPayload);
    }
};

export const registerCheckoutOptions = (order, step, option) => () => {
    const eventPayload = checkoutOptionsSelector(order, step, option);
    if (eventPayload) {
        pushDataLayer(eventPayload);
    }
};

export const registerVoucherValidation = ({ eventLabel, orderNumber, userID, voucher, error }) => (
    dispatch,
    getState,
) => {
    const text = error && translationFunctionSelector(getState())(getKeyForVoucherError(error));
    if (eventLabel) {
        pushDataLayer({
            event: ANALYTIC_EVENTS.newCheckout,
            eventCategory: 'checkout',
            eventAction: 'voucherValidation',
            eventLabel,
            eventProperty: {
                orderID: orderNumber,
                userID,
                voucher,
                error_key: text,
            },
        });
    }
};
// todo: will be renamed once newcheckout is released on 100%
export const registerVoucherValidationV2 = ({
    eventLabel,
    orderNumber,
    userID,
    voucher,
    error,
}) => {
    if (eventLabel) {
        pushDataLayer({
            event: ANALYTIC_EVENTS.newCheckout,
            eventCategory: 'checkout',
            eventAction: 'voucherValidation',
            eventLabel,
            eventProperty: {
                orderID: orderNumber,
                userID,
                voucher,
                error_key: error,
            },
        });
    }
};

export const registerBenefitsClick = (benefitTitle, state) => {
    if (benefitTitle && state) {
        pushDataLayer({
            event: ANALYTIC_EVENTS.gtmEvent,
            eventCategory: 'pdp',
            eventAction: 'benefits',
            eventLabel: state,
            eventProperty: benefitTitle,
        });
    }
};

export const registerToolTipActivation = (location, product, orderActive) => {
    const step = location.pathname.split('/').pop();
    const productSKU = product && product.variant && product.variant.product.sku;
    const eventPayload = {
        event: ANALYTIC_EVENTS.gtmEvent,
        eventCategory: 'checkout',
        eventAction: 'tooltip',
        eventLabel: 'mietKauf',
        eventProperty: {
            step,
            productSKU,
            orderID: orderActive.number,
        },
    };
    pushDataLayer(eventPayload);
};

export const registerReferralEvent = ({ eventLabel, eventAction }) => {
    const eventPayload = getReferralEvent({
        eventLabel,
        eventAction,
    });
    pushDataLayer(eventPayload);
};

export const registerReferralSharingEvent = ({ eventLabel, eventAction }) => (
    dispatch,
    getState,
) => {
    const user = userSelector(getState());

    const eventPayload = shareReferralsEvent({
        eventLabel,
        eventAction,
        userID: (user && user.id) || null,
    });

    pushDataLayer(eventPayload);
};

export const setGaUserID = (userId) => {
    if (window && window.ga && userId) {
        window.ga('set', 'userId', userId);
        window.ga('send', 'event', 'authentication', 'user-id available');
    }
};

export const registerSearchOverlayEvent = (eventAction, product = null) => (dispatch, getState) => {
    const eventPayload = searchOverlayEventSelector(eventAction, product)(getState());
    pushDataLayer(eventPayload);
};

export const registerReferralPdpSharing = (listName, eventName, socialNetwork) => (
    dispatch,
    getState,
) => {
    const eventPayload = getProductSharingEvent({ eventName, listName, socialNetwork })(getState);
    pushDataLayer(eventPayload);
};

export const registerGProductListClickEvent = (widgetData) => (dispatch, getState) => {
    const user = userSelector(getState());
    const eventPayload = {
        event: ANALYTIC_EVENTS.gtmEvent,
        eventCategory: 'componentClick',
        eventAction: widgetData.widgetType,
        eventLabel: widgetData.title,
        eventProperty: {
            clickType: 'CTA',
            targetURL: widgetData.cta && widgetData.cta.linkTo,
            userID: (user && user.id) || null,
            contentfulDetails: {
                title: widgetData.title,
                header: widgetData.header || null,
                description: widgetData.description || null,
                productIds: widgetData.productIDs,
            },
        },
    };
    pushDataLayer(eventPayload);
};

export const registerGProductListImpressionEvent = (widgetData) => (dispatch, getState) => {
    const user = userSelector(getState());
    const location = locationSelector(getState());
    const eventPayload = {
        event: ANALYTIC_EVENTS.gtmEvent,
        eventCategory: 'componentImpression',
        eventAction: widgetData.widgetType,
        eventLabel: widgetData.title,
        eventProperty: {
            pageURL: location.pathname,
            userID: (user && user.id) || null,
            contentfulDetails: {
                title: widgetData.title,
                header: widgetData.header || null,
                description: widgetData.description || null,
                productIds: widgetData.productIDs,
            },
        },
    };
    pushDataLayer(eventPayload);
};

export const registerGPageProductClickEvent = (widgetData, product, productPosition) => (
    dispatch,
    getState,
) => {
    const user = userSelector(getState());
    const activeStore = activeStoreSelector(getState()) || {};
    const location = locationSelector(getState());
    const orderID = getOrderFromCookies(activeStore?.code);
    const deliveryEstimates = getDeliveryEstimation(activeStore.code);
    const eventPayload = {
        event: ANALYTIC_EVENTS.gtmEvent,
        eventCategory: 'productDiscovery',
        eventAction: 'productClick',
        eventLabel: widgetData.title,
        eventProperty: {
            orderID,
            userID: (user && user.id) || null,
            list: location.pathname,
            position: productPosition,
            productData: {
                ...gPageProductSelector(product),
                deliveryTime:
                    (deliveryEstimates && `${deliveryEstimates.from}-${deliveryEstimates.to}`) ||
                    null,
            },
        },
    };
    pushDataLayer(eventPayload);
};

export const registerGPageProductImpressionEvent = (widgetData, product, productPosition) => (
    dispatch,
    getState,
) => {
    const user = userSelector(getState());
    const location = locationSelector(getState());
    const eventPayload = {
        event: ANALYTIC_EVENTS.gtmEvent,
        eventCategory: 'productImpressions',
        eventAction: widgetData.widgetType,
        eventLabel: widgetData.title,
        eventProperty: {
            listURL: location.pathname,
            position: productPosition,
            ...gPageProductSelector(product),
            userID: (user && user.id) || null,
        },
    };
    pushDataLayer(eventPayload);
};

export const registerPurchaseEvent = () => (dispatch, getState) => {
    const payload = purchaseSelectorV2(getState());
    pushDataLayer(payload);
};

export const registerLegacyEePurchaseEvent = (cart, activeStore) => {
    const payload = purchaseSelector(cart, activeStore);
    pushDataLayer(payload);
};

export const registerVideoPdpEvent = (videoStep) => (dispatch, getState) => {
    const user = userSelector(getState()) || {};
    const product = currentProduct(getState());

    sendVideoPdpEvent({
        event: ANALYTIC_EVENTS.productPage,
        videoStep,
        userID: user.id || null,
        productSKU: product.sku,
    });
};

export const registerAvailabilityOfProduct = (eventLabel, eventProperty = {}) => {
    const payload = {
        event: ANALYTIC_EVENTS.availabilityService,
        eventCategory: 'checkout',
        eventAction: 'availability_service',
        eventLabel,
        eventProperty,
    };
    pushDataLayer(payload);
};

export const registerMixLpClick = ({ orderID, sku, userID = null, from }) => {
    const payload = {
        event: ANALYTIC_EVENTS.mixLpClick,
        eventCategory: 'mixLpClick',
        eventAction: from,
        eventLabel: sku,
        eventProperty: {
            userID,
            orderID,
        },
    };
    sendDataToSnowplow(payload);
};

export const registerBundleImpression = ({ isBundleAvailable, bundleID, bundleContent }) => (
    dispatch,
    getState,
) => {
    const user = userSelector(getState());
    const bundleProductNames = bundleContent?.products?.map(({ name }) => name);
    const bundleProductSkus = bundleContent?.products?.map(({ sku }) => sku);
    const bundlesPrice = bundleContent?.products?.map(({ planPrice }) => planPrice / 100);
    const eventLabel = isBundleAvailable ? bundleProductSkus[0] : 'Out of stock';
    const payload = {
        eventLabel,
        eventProperty: {
            userID: user?.id,
            productName: bundleProductNames[0],
            bundleID,
            productsInBundle: bundleContent?.products?.length,
            productData: {
                bundleProductNames,
                bundleProductSkus,
                bundlesPrice,
                subscriptionLength: bundleContent?.products[0]?.planLength || null,
                total_price: bundleContent?.rawTotalBundlePrice / 100,
                non_discountedPrice: bundleContent?.rawTotalOldBundlePrice / 100,
            },
        },
    };
    sendBundleImpression(payload);
};

export const registerAddBundleToCart = ({ bundleID, bundleContent, orderID }) => (
    dispatch,
    getState,
) => {
    const user = userSelector(getState());
    const bundleProductNames = bundleContent?.products?.map(({ name }) => name);
    const bundleProductSkus = bundleContent?.products?.map(({ sku }) => sku);
    const bundlesPrice = bundleContent?.products?.map(({ planPrice }) => planPrice / 100);
    const payload = {
        event: ANALYTIC_EVENTS.addBundleToCart,
        eventCategory: 'checkout',
        eventAction: 'addBundleToCart',
        eventLabel: bundleProductSkus[0],
        eventProperty: {
            orderID,
            userID: user?.id,
            bundleID,
            productData: {
                bundleProductNames,
                bundleProductSkus,
                bundlesPrice,
                subscriptionLength: bundleContent?.products[0]?.planLength || null,
                total_price: bundleContent?.rawTotalBundlePrice / 100,
                non_discountedPrice: bundleContent?.rawTotalOldBundlePrice / 100,
            },
        },
    };
    sendDataToSnowplow(payload);
};

export const registerPaginationEvent = ({ loadingType, productsPerPage, totalVisibleProducts }) => {
    const payload = {
        event: ANALYTIC_EVENTS.pagination,
        eventCategory: 'productDiscovery',
        eventAction: 'pagination',
        eventLabel: loadingType === 'eager' ? 'loadButtonClicked' : 'infiniteScroll',
        eventProperty:
            loadingType === 'eager'
                ? {
                      new_products_loaded: totalVisibleProducts,
                      total_products_on_page: totalVisibleProducts + productsPerPage,
                  }
                : true,
    };
    sendDataToSnowplow(payload);
};

export const registerCategoryMarketingCardClick = ({ cardText }) => (dispatch, getState) => {
    const user = userSelector(getState()) || {};
    const activeStore = activeStoreSelector(getState()) || {};
    const location = locationSelector(getState()) || {};
    const orderID = getOrderFromCookies(activeStore?.code);

    const payload = {
        event: ANALYTIC_EVENTS.subCatMarketingCardClick,
        eventCategory: 'productDiscovery',
        eventAction: 'subcat_mkt_card_click',
        eventLabel: cardText,
        eventProperty: {
            store: activeStore?.code,
            userID: user?.id || null,
            orderID,
            subcategory: location.pathname,
        },
    };
    sendCategoryMarketingCardClick(payload);
};

export const registerFooterCtaClick = ({ eventLabel, eventCategory }) => (dispatch, getState) => {
    const user = userSelector(getState()) || {};
    const { code, store_id: store } = activeStoreSelector(getState()) || {};
    const orderID = getOrderFromCookies(code) || '';

    const payload = {
        event: ANALYTIC_EVENTS.footerCtaClick,
        eventAction: 'footerCtaClick',
        eventLabel,
        eventCategory,
        eventProperty: {
            store,
            userID: user?.id || null,
            orderID,
        },
    };
    sendFooterCtaClickEvent(payload);
};

export const registerLandingPageCtaClick = ({ eventLabel }) => (dispatch, getState) => {
    const user = userSelector(getState()) || {};
    const { code, store_id: store } = activeStoreSelector(getState()) || {};
    const orderID = getOrderFromCookies(code);

    const payload = {
        event: ANALYTIC_EVENTS.landingPageCtaClick,
        eventAction: 'landing_page_cta',
        eventLabel,
        eventCategory: 'landingPage',
        eventProperty: {
            store,
            userID: user?.id || null,
            orderID,
        },
    };
    sendLandingPageCtaClickEvent(payload);
};

export const registerCartContentChange = (item, cart) => (dispatch, getState) => {
    const eventAction = cartContentChangeActionSelector(item);

    const snowplowPayload = cartContentChangeSnowplowSelector(item, cart)(getState());
    if (snowplowPayload && eventAction) {
        sendCartContentChange({ ...snowplowPayload, eventAction });
    }
};

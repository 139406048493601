
    var doc = {"kind":"Document","definitions":[{"kind":"EnumTypeDefinition","name":{"kind":"Name","value":"MixSwapShippingProgress"},"directives":[],"values":[{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"AWAITING_RETURN"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"CHECKING_RETURN"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"NEW_DEVICE_AWAITING_PICKUP"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"NEW_DEVICE_DELIVERED"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"NEW_DEVICE_IN_TRANSIT"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"OLD_DEVICE_IN_TRANSIT"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"PROBLEM_WITH_RETURN"},"directives":[]}]},{"kind":"ObjectTypeExtension","name":{"kind":"Name","value":"Swap"},"interfaces":[],"directives":[],"fields":[{"kind":"FieldDefinition","name":{"kind":"Name","value":"shippingProgress"},"arguments":[],"type":{"kind":"NamedType","name":{"kind":"Name","value":"MixSwapShippingProgress"}},"directives":[]}]}],"loc":{"start":0,"end":273}};
    doc.loc.source = {"body":"enum MixSwapShippingProgress {\n    AWAITING_RETURN\n    CHECKING_RETURN\n    NEW_DEVICE_AWAITING_PICKUP\n    NEW_DEVICE_DELIVERED\n    NEW_DEVICE_IN_TRANSIT\n    OLD_DEVICE_IN_TRANSIT\n    PROBLEM_WITH_RETURN\n}\n\nextend type Swap {\n    shippingProgress: MixSwapShippingProgress\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    

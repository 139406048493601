import { GET_USER } from 'ggApp/modules/auth/actionTypes';

export const POST_EVENTS = {
    SET_APP_DATA: 'SET_APP_DATA',
    GET_USER,
    WEB_VIEW_LOADED: 'WEB_VIEW_LOADED',
    RATING_CLICKED: 'RATING_CLICKED',
    RATING_SUCCESS: 'RATING_SUCCESS',
    CLIPBOARD_WRITE: 'CLIPBOARD_WRITE',
    REFRESH_CARD_TAB: 'REFRESH_CARD_TAB',
    ORDER_GROVER_CARD: 'ORDER_GROVER_CARD',
    USER_LOGIN: 'USER_LOGIN',
    USER_LOGOUT: 'USER_LOGOUT',
    TOKENS_UPDATED: 'TOKENS_UPDATED',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    OPEN_CHECKOUT: 'OPEN_CHECKOUT',
    OPEN_CHECKOUT_AUTH: 'OPEN_CHECKOUT_AUTH',
    NAVIGATE_TO_LOGIN: 'NAVIGATE_TO_LOGIN',
    REQUEST_LOGOUT: 'REQUEST_LOGOUT',
    REDIRECT_TO_CARD_TAB: 'REDIRECT_TO_CARD_TAB',
    REQUEST_NATIVE_AUTH_STATE: 'REQUEST_NATIVE_AUTH_STATE',
    NATIVE_AUTH_STATE_UPDATED: 'NATIVE_AUTH_STATE_UPDATED',
    STORE_CHANGED: 'STORE_CHANGED',
    SET_ACTIVE_STORE_LANGUAGE: 'SET_ACTIVE_STORE_LANGUAGE',
};

export const LINKS = {
    CARD: '/card',
};

import { assign } from 'xstate';

import {
    orderMapper,
    fillAddress,
    fillPayment,
} from 'ggApp/shared/context/Order/helpers/orderMapper';
import { getUserLists, addressList } from 'ggApp/shared/context/Order/services/common/getUserLists';
import { vouchers } from 'ggApp/shared/context/Order/services/common/vouchers';
import { userAddress } from 'ggApp/shared/context/Order/services/common/userAddress';
import { deleteOrder } from 'ggApp/shared/context/Order/cookies';
import { ApiCollection, Mode, MachineConfig } from 'ggApp/shared/context/Order/types';

import { getStepFromState } from './tracking/utils';
import { cartServiceAPI } from './cartServiceAPI';
import { restAPI } from './restAPI';
import {
    OrderMode,
    withAddressRefresh,
    StoreDefinition,
    withPaymentRefresh,
    removeLineItems,
    updateAddressesFromContext,
    updatePaymentFromContext,
    removeProductsAndSubmitVoucher,
    CheckoutMachine,
    withAutoVoucherRedemption,
    Operation,
} from './integration';
import { guards } from './guards';

const apis: ApiCollection<OrderMode> = {
    FLEX: restAPI,
    MIX: cartServiceAPI,
};

export const createService = ({
    orderMode,
    storeCode,
}: {
    orderMode: Mode<OrderMode>;
    storeCode?: string;
}): object => {
    const api = storeCode === 'us' ? cartServiceAPI : apis[orderMode];

    return {
        getOrder: api.order.get,
        createOrder: withAutoVoucherRedemption(api.order.add as Operation<any>, vouchers.submit),
        submitOrder: api.order.submit,
        addLineItem: withAutoVoucherRedemption(api.lineItem.add as Operation<any>, vouchers.submit),
        updateLineItem: api.lineItem.update,
        removeLineItem: api.lineItem.delete,
        removeLineItems: removeLineItems(api.lineItem.delete),
        updatePaymentMethod: api.paymentMethod.update,
        updateShippingAddress: withAddressRefresh(api.shippingAddress.update),
        updateHomeAddress: withAddressRefresh(api.homeAddress.update),
        editAddress: addressList.update,
        addAddress: addressList.add,
        submitVoucher: vouchers.submit,
        submitVoucherWithAvailableProducts: removeProductsAndSubmitVoucher(
            removeLineItems(api.lineItem.delete),
            vouchers.submit,
        ),
        resetVoucher: vouchers.delete,
        getUserAddresses: getUserLists.addresses,
        getUserPayments: withPaymentRefresh(getUserLists.payments, api.paymentMethod.update),
        userAddressUpdateShipping: userAddress.updateShipping,
        userAddressUpdateHome: userAddress.updateHome,
        updateAddressesFromContext: updateAddressesFromContext(
            api.shippingAddress.update,
            api.homeAddress.update,
        ),
        updatePaymentFromContext: updatePaymentFromContext(api.paymentMethod.update),
    };
};

export const createConfig = (orderMode: Mode<OrderMode>, storeCode: string): MachineConfig => ({
    services: createService({ orderMode, storeCode }),
    guards,
    actions: {
        updateOverlayState: assign({
            isOverlayActive: (context: {}, event: any) => event.isOverlayActive,
        }),
        updateShouldFetchUserData: assign({
            userDataFetched: () => true,
        }),
        updateStore: assign({
            activeStore: (
                context: { activeStore: StoreDefinition },
                event: { activeStore?: StoreDefinition } = {},
            ) => {
                return {
                    ...event.activeStore,
                };
            },
        }),
        resetFromRedirect: assign({
            redirectionFlow: () => {
                return { enabled: false };
            },
        }),
        updatedUserData: assign({
            updatedUserData: () => {
                return true;
            },
        }),
        updateCheckoutFlow: assign({
            newCheckoutFlow: (context: CheckoutMachine, event: { data?: object } = {}) =>
                event.data,
        }),
        updateFlags: assign({
            customizeColorFlag: (context: CheckoutMachine, event: { customizeColorFlag?: any }) =>
                event.customizeColorFlag,
            customizePlanFlag: (context: CheckoutMachine, event: { customizePlanFlag?: any }) =>
                event.customizePlanFlag,
        }),
        updateUser: assign({
            user: (context: CheckoutMachine, event: { data?: object } = {}) => event.data,
            userAddresses: (context: any, event: { data?: any } = {}) => {
                return context.userAddresses.map((a: any) => {
                    const address = { ...a };
                    address['is_current_ship_address?'] = event?.data?.ship_address?.id
                        ? event?.data?.ship_address?.id === a.id
                        : a['is_current_ship_address?'];
                    address['is_current_bill_address?'] = event?.data?.bill_address?.id
                        ? event?.data?.bill_address?.id === a.id
                        : a['is_current_bill_address?'];
                    return address;
                });
            },
        }),
        setEditMode: assign({
            inEditMode: () => {
                return true;
            },
        }),
        updateUserPayments: assign({
            userPayments: (
                context: { userPayments: Array<any> },
                event: { data?: object; type?: string } = {},
            ) => event.data,
        }),
        updateUserAddresses: assign({
            userAddresses: (
                context: { userAddresses: Array<any>; activeStore?: StoreDefinition },
                event: { data?: Array<any>; type?: string } = {},
            ) =>
                (event.data || []).filter(
                    (i) => i.country_id === context.activeStore?.country_id ?? null,
                ),
        }),
        addressError: assign({
            addressError: (context, event: { data: any }) =>
                event.data?.response?.data?.base ?? null,
        }),
        phoneVerificationConfirmError: assign({
            phoneVerificationConfirmError: (context, event: { data: any }) =>
                event.data?.response?.data?.base ?? null,
        }),
        setPhoneVerificationOrigin: assign({
            phoneVerificationPending: (context: any, event: { data: any; type: string }) => ({
                ...context.phoneVerificationPending,
                origin: event.type,
            }),
        }),
        setPhoneVerificationPending: assign({
            phoneVerificationPending: (context: any, event: { data: any; type: string }) => ({
                ...context.phoneVerificationPending,
                requestPayload: JSON.parse(event.data?.config?.data ?? '{}'),
                timestamp: Date.now(),
                timeoutSeconds: event.data?.response?.data?.resend_code_timeout_seconds,
                phoneLastDigits: event.data?.response?.data?.phone_last_digits,
            }),
        }),
        clearPhoneVerificationPending: assign({
            phoneVerificationConfirmError: () => null,
            phoneVerificationPending: () => ({}),
        }),
        voucherError: assign({
            voucherError: (context, event: { data: any }) => {
                return event.data?.response?.data?.base ?? null;
            },
        }),
        getUnavailableSku: assign({
            unavailableSku: (context, event: { data: any }) => event.data?.response?.data?.base,
        }),
        updateOrder: assign({
            cartLoaded: () => true,
            orderNumber: (context, event: { data?: any }) =>
                (event?.data?.order?.number || event?.data?.order?.orderNumber) ??
                context.orderNumber,
            cart: (
                context: {
                    orderMode: 'MIX' | 'FLEX';
                },
                event: { type: string; data?: any },
            ) => {
                if (
                    !event.data ||
                    (orderMode === 'MIX' && !event?.data?.order?.lineItems?.length)
                ) {
                    return {};
                }
                return {
                    ...orderMapper({
                        ...context,
                        order: event.data?.order ?? {},
                        mode: orderMode,
                    }),
                };
            },
        }),
        enableCheckout: assign({
            isCheckoutActive: (): boolean => true,
        }),
        disableCheckout: assign({
            isCheckoutActive: (): boolean => false,
        }),
        updateSteps: assign({
            steps: (context: object, event, machine: { state: object }) => {
                const { state } = machine;
                const prevStep = getStepFromState(state);
                const { steps } = context as { steps: string[] };
                if (prevStep) {
                    steps.push(prevStep);
                }
                return steps;
            },
        }),
        resetSteps: assign({
            steps: (context: object, event, machine: { state: object }) => {
                const { state } = machine;
                const prevStep = getStepFromState(state);
                return [prevStep];
            },
        }),
        resetCart: assign({
            cart: () => {
                return {};
            },
            cartLoaded: false,
            previewVoucher: () => {},
        }),
        clearCookies: (context: any) => {
            deleteOrder(context.orderMode, context.activeStore.code);
        },
        prefillCartAddress: assign({
            cart: ({ cart, userAddresses }) => {
                return {
                    ...fillAddress({ cart, userAddresses }),
                };
            },
        }),
        prefillCartPayment: assign({
            cart: ({ cart, userPayments }) => {
                return cart.paymentMethod
                    ? cart
                    : {
                          ...fillPayment({ cart, userPayments }),
                      };
            },
        }),
        hasPrefilledOrder: assign({
            hasPrefilledOrder: () => true,
        }),
        setFormSubmitting: assign({
            formSubmitting: () => true,
        }),
        clearFormSubmitting: assign({
            formSubmitting: () => false,
        }),
        log: (context: object, event: object): void => console.log(context, event),
        error: assign({
            error: (context, event: { error: any }): boolean => Boolean(event.error),
        }),
        success: assign({ success: (): boolean => true }),
        setPreviewVoucher: assign({
            previewVoucher: (context, event) => {
                return {
                    code: event.code,
                    description: event.description,
                    discount: event.discount,
                };
            },
        }),
    },
});

import axios from 'axios';
import loadConfiguration from 'ggApp/utils/configuration';

const { APP_CONFIG } = loadConfiguration();
const { flagr } = APP_CONFIG;

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

const axiosInstance = axios.create({
    baseURL: flagr,
    headers: JSON.parse(JSON.stringify(headers)),
});

export default axiosInstance;

import { combineReducers } from 'redux';
import { pages } from './pages';
import { loading } from './loading';
import { error } from './error';

export const contentfulReducers = combineReducers({
    loading,
    error,
    pages,
});

/* eslint-disable @typescript-eslint/camelcase */
import ClientCookies from 'universal-cookie';

import { requestRefreshToken, RefreshTokenResult } from 'ggApp/utils/requests/refreshToken';
import { getRefreshToken, saveAuthCredentials } from 'ggApp/modules/auth/utils';
import { isPromise } from 'ggApp/utils/promise/isPromise';
import { addEventListenerWithPromise, fireEvent } from 'ggApp/modules/inwebview_api';
import { POST_EVENTS } from 'ggApp/modules/inwebview_api/constants';
import { isAppUser } from 'ggApp/routes/_wrap/MobileWrapper/utils';
import { isNativeAuthEnabled } from 'ggApp/modules/inwebview_api/nativeAuthEvents';

export class PromiseManager {
    private static instance: Promise<RefreshTokenResult> | null = null;

    static get promise(): Promise<RefreshTokenResult> | null {
        return this.instance;
    }

    static set promise(value: Promise<RefreshTokenResult> | null) {
        if (!isPromise(value) && value !== null) {
            throw new TypeError(`Type of passed value "${typeof value}" is not supported`);
        }

        if (value === null) {
            this.instance = null;
        } else {
            this.instance = value.finally(() => {
                // Auto-cleaning up global promise instance after promise is resolved or rejected
                this.instance = null;
            });
        }
    }
}

function updateTokensAndNotifyApp({
    accessToken,
    refreshToken,
    expiresInSeconds,
    cookiesManager,
    isAppBasedRefreshEnabled = false,
}: RefreshTokenResult & { cookiesManager: ClientCookies; isAppBasedRefreshEnabled?: boolean }) {
    saveAuthCredentials(
        accessToken,
        refreshToken,
        expiresInSeconds,
        cookiesManager,
        !isAppBasedRefreshEnabled,
    );
}

async function refreshAccessTokenManually(cookiesManager: ClientCookies) {
    const refreshToken = getRefreshToken(cookiesManager) ?? '';

    if (!refreshToken) {
        throw new Error('Unauthorized');
    }

    const result = await requestRefreshToken(refreshToken, cookiesManager);

    updateTokensAndNotifyApp({ ...result, cookiesManager });

    return result;
}

export async function refreshAccessToken(
    cookiesManager: ClientCookies,
    options?: {
        refreshTokenTimeout: number;
        nativeRefreshAvailabilityTimeout: number;
    },
): Promise<RefreshTokenResult> {
    const { refreshTokenTimeout, nativeRefreshAvailabilityTimeout } = options ?? {
        refreshTokenTimeout: 30000,
        nativeRefreshAvailabilityTimeout: 500,
    };

    if (PromiseManager.promise) {
        return PromiseManager.promise;
    }

    if (!isAppUser()) {
        PromiseManager.promise = refreshAccessTokenManually(cookiesManager);
        return PromiseManager.promise;
    }

    PromiseManager.promise = isNativeAuthEnabled(nativeRefreshAvailabilityTimeout).then(
        (isAppBasedRefreshEnabled) => {
            if (!isAppBasedRefreshEnabled) {
                return refreshAccessTokenManually(cookiesManager);
            }

            const nativeRefreshPromise = addEventListenerWithPromise(POST_EVENTS.TOKENS_UPDATED, {
                timeout: refreshTokenTimeout,
            })
                .then(({ refreshToken, authToken: accessToken, maxAge: expiresInSeconds }) => ({
                    accessToken,
                    refreshToken,
                    expiresInSeconds,
                }))
                .then((result) => {
                    updateTokensAndNotifyApp({
                        ...result,
                        cookiesManager,
                        isAppBasedRefreshEnabled,
                    });
                    return result;
                });

            fireEvent(POST_EVENTS.REFRESH_TOKEN);

            return nativeRefreshPromise;
        },
    );

    return PromiseManager.promise;
}

export const MODULE_NAME = 'productWidget';

export const PRODUCT_WIDGET = `${MODULE_NAME}/PRODUCT_WIDGET`;
export const PRODUCT_WIDGET_SUCCESS = `${MODULE_NAME}/PRODUCT_WIDGET_SUCCESS`;
export const PRODUCT_WIDGET_FAILURE = `${MODULE_NAME}/PRODUCT_WIDGET_FAILURE`;

export const AB_WIDGET_KEY = {
    primary: 'primary',
    secondary: 'secondary',
    disabled: 'disabled',
};

import { compose, mapProps } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { storeActive } from 'ggApp/modules/store/selectors';
import ggTranslator from 'ggApp/modules/translator';
import { translate } from 'ggApp/modules/translator/selectors';

import Chip from './Chip';

export default compose(
    connect(
        createStructuredSelector({
            translations: ggTranslator.selectors.keys,
            store: storeActive,
        }),
    ),
    mapProps(({ translations, store: { activeLanguage }, tk, label, ...rest }) => {
        return {
            ...rest,
            label: label || translate(translations, activeLanguage, tk),
            tk,
        };
    }),
)(Chip);

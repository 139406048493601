/* eslint-disable */
import PropTypes from 'prop-types';

export const authActionsPropTypes = PropTypes.shape({
    changePassword: PropTypes.func,
    confirmEmail: PropTypes.func,
    confirmPhone: PropTypes.func,
    getUser: PropTypes.func,
    getUserDispatch: PropTypes.func,
    getUserRequest: PropTypes.func,
    login: PropTypes.func,
    logout: PropTypes.func,
    phoneVerificationInputHide: PropTypes.func,
    phoneVerificationInputShow: PropTypes.func,
    phoneVerificationSetAsyncErrors: PropTypes.func,
    resetPassword: PropTypes.func,
    resetPasswordRecovery: PropTypes.func,
    sendPasswordRecovery: PropTypes.func,
    signup: PropTypes.func,
    subscribeToNewsletter: PropTypes.func,
    update: PropTypes.func,
    updatePhone: PropTypes.func,
});

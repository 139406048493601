// @ts-nocheck
import axios from 'ggApp/utils/requests';
import { fireEvent } from 'ggApp/modules/inwebview_api';
import CookiesManager from 'ggApp/utils/CookiesManager';
import ClientCookies from 'universal-cookie';
import { SET_COOKIES, REMOVE_ORDER_COOKIES } from 'ggApp/utils/CookiesManager/constants';
import { ORDER_NUMBER_COOKIE, ORDER_TOKEN_COOKIE } from 'ggApp/modules/order/constants';
import { OrderModes } from '../types';

export const getOrderNumberCookieName = (orderMode: string, storeCode: string) => {
    if (orderMode === OrderModes.Flex) {
        return `${storeCode}_${ORDER_NUMBER_COOKIE}`;
    }
    return `${storeCode}_mix_${ORDER_NUMBER_COOKIE}`;
};

export const getOrderTokenCookieName = (orderMode: string, storeCode: string) => {
    if (orderMode === OrderModes.Flex) {
        return `${storeCode}_${ORDER_TOKEN_COOKIE}`;
    }
    return `${storeCode}_mix_${ORDER_TOKEN_COOKIE}`;
};

export const getOrderFromCookies = (orderMode: string, storeCode: string) => {
    const cookiesManager = new CookiesManager() as ClientCookies;
    const orderNumber = cookiesManager.get(getOrderNumberCookieName(orderMode, storeCode));
    return orderNumber;
};

interface SaveOrderInput {
    orderMode: string;
    storeCode: string;
    orderNumber: string;
    orderToken?: string;
}

export const saveOrder = ({ orderMode, storeCode, orderNumber, orderToken }: SaveOrderInput) => {
    const cookiesManager = new CookiesManager() as ClientCookies;
    const orderNumberKey = getOrderNumberCookieName(orderMode, storeCode);
    const orderTokenKey = getOrderTokenCookieName(orderMode, storeCode);

    cookiesManager.set(orderNumberKey, orderNumber);

    if (orderToken) {
        cookiesManager.set(orderTokenKey, orderToken);
    }

    // note from ggApp/modules/order/storage:
    // some code still relies on an Axios instance default headers
    // being set for each request. Can be deleted, once all order-related
    // requests will be either implicitly setting headers themselfs,
    // or relying on the Axios instance interceptor
    if (typeof orderNumber === 'string' && typeof orderToken === 'string') {
        axios.defaults.headers['X-Grover-Order-Number'] = orderNumber;
        axios.defaults.headers['X-Grover-Order-Token'] = orderToken;
    }

    if (window.ReactNativeWebView) {
        fireEvent(SET_COOKIES, {
            action: {
                [orderNumberKey]: orderNumber,
                [orderTokenKey]: orderToken,
            },
        });
    }
};

export const deleteOrder = (orderMode: string, storeCode: string) => {
    const cookiesManager = new CookiesManager() as ClientCookies;
    const orderNumberKey = getOrderNumberCookieName(orderMode, storeCode);
    const orderTokenKey = getOrderTokenCookieName(orderMode, storeCode);
    cookiesManager.remove(orderNumberKey, { path: '/' });
    cookiesManager.remove(orderTokenKey, { path: '/' });

    if (window.ReactNativeWebView) {
        fireEvent(REMOVE_ORDER_COOKIES, {
            action: [orderNumberKey, orderTokenKey],
        });
    }
};

export const getOrderTypeFromCookies = (storeCode: string) => {
    const cookiesManager = new CookiesManager() as ClientCookies;
    const flexOrder = cookiesManager.get(getOrderNumberCookieName(OrderModes.Flex, storeCode));
    const mixOrder = cookiesManager.get(getOrderNumberCookieName(OrderModes.Mix, storeCode));

    // In app cookie sometimes return string;
    if (flexOrder && flexOrder !== 'undefined' && flexOrder !== 'null') {
        return OrderModes.Flex;
    }
    if (mixOrder && mixOrder !== 'undefined' && mixOrder !== 'null') {
        return OrderModes.Mix;
    }
    return null;
};

export const deleteOldCookies = (orderMode: string, storeCode: string) => {
    const existingCookieType = getOrderTypeFromCookies(storeCode);
    if (!existingCookieType) return;
    if (orderMode !== existingCookieType) {
        deleteOrder(existingCookieType, storeCode);
    }
};

export const getOrderFromCookiesBasedStore = (storeCode: string) => {
    const orderMode = getOrderTypeFromCookies(storeCode);
    if (!orderMode) return null;
    return getOrderFromCookies(orderMode, storeCode);
};

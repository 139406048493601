import fetchComponentData from 'ggApp/routes/_fetch-dependencies';
import { setLoadingStatus, setReadyStatus } from 'ggApp/modules/requestStatus/actions';
import { fireEvent } from 'ggApp/modules/inwebview_api';

const ROUTE_CHANGED = 'routeChanged';

const extractComponentsFromRoutes = (routes) =>
    routes.reduce((result, route) => {
        const { component, components: _component } = route;
        if (component || _component) {
            result.push(component || _component);
        }
        return result;
    }, []);

const frontshopOnChange = (dispatch) => (prevState, nextState, replace, callback) => {
    const newPath = nextState.location.pathname;

    if (!dispatch || newPath === prevState.location.pathname) {
        return callback();
    }

    fireEvent(ROUTE_CHANGED, { path: newPath });

    const { params, location, routes } = nextState;
    const components = extractComponentsFromRoutes(routes);

    dispatch(setLoadingStatus());

    return fetchComponentData(dispatch, components, params, location)
        .then(() => {
            callback();
            dispatch(setReadyStatus());
        })
        .catch((error) => {
            callback(error);
            dispatch(setReadyStatus());
        });
};

export default frontshopOnChange;

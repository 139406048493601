import styled from 'styled-components';
import { SonntagButtonV2 } from '@getgrover/ui';
import { Modal } from 'ggApp/modules/modal/components/v3';

const MODAL_CONTAINER_CLASS_NAME = 'signUpModalContainer';
const MODAL_CONTENT_CLASS_NAME = 'signUpModalContent';
const MODAL_ACTIONS_CONTAINER_CLASS_NAME = 'signUpActionsContainer';

const MODAL_PADDING_SIZE = 8;

export const StyledModal = styled(Modal).attrs({
    classNames: {
        content: MODAL_CONTENT_CLASS_NAME,
        container: MODAL_CONTAINER_CLASS_NAME,
        actionsContainer: MODAL_ACTIONS_CONTAINER_CLASS_NAME,
    },
})`
    /* Using this hack to override base styles */
    && {
        padding: ${MODAL_PADDING_SIZE}px;
    }

    & .${MODAL_CONTAINER_CLASS_NAME} {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        min-width: 384px;
        min-height: 640px;

        @media only screen and (max-width: ${384 + 2 * MODAL_PADDING_SIZE}px) {
            min-width: 100%;
        }

        @media only screen and (max-height: ${640 + 2 * MODAL_PADDING_SIZE}px) {
            min-height: 100%;
        }
    }

    & .${MODAL_CONTENT_CLASS_NAME} {
        padding: 0;
        user-select: none;
        display: flex;
        flex-direction: column;
    }
`;

export const CloseButton = styled(SonntagButtonV2)`
  position: absolute;
  z-index: 1;
  right: ${({ theme }) => theme.spacers.L};
  top: ${({ theme }) => theme.spacers.XL};

`;

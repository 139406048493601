import styled, {css} from 'styled-components';

const EnergyClass = styled.div`
        width: ${props => props.width}px;
        height: ${props => props.height}px;
        background-image: url(${props => props.icon});
        background-position: 0 50%;
        background-repeat: no-repeat;
        margin-right: 16px;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
`;

const TextWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const ToolTipText = styled.div`
    display: flex;
    align-items: flex-end;
    ${({theme}) => css(theme.typography.Paragraph)};
    color: ${({ theme }) => theme.colors.Foreground};
    &:before {
        content: " ";
        white-space: pre;
    }
    span {
        text-decoration: underline;
        cursor: help;
    }
`;

export {
    Container,
    TextWrap,
    ToolTipText,
    EnergyClass
};

import styled, { css } from 'styled-components';

import { elevation, elevationTransition } from 'ggApp/utils/mixins';
import ChevronSmall from 'ggApp/shared/icons/Chevrons/Small';
import ChevronLarge from 'ggApp/shared/icons/Chevrons/Large';

import Options from './Options';

const Wrap = styled.div`
    position: relative;
    background-color: transparent;
    height: 48px;
    width: 100%;
    cursor: pointer;
    border: 2px solid ${({ theme }) => theme.colors['Level 1']};
    color: ${({ theme }) => theme.colors.Foreground};
    border-radius: 24px;
    display: flex;
    padding: ${({ theme }) => theme.spacers.L};
    align-items: center;
    ${elevationTransition(true)}
    &:hover {
        ${elevation(3, true)};
    }
    &:active {
        ${elevation(1, true)}
    }
    ${({ dropDownIsOpen }) => dropDownIsOpen
        && css`
            &:hover {
                ${elevation(0, false)}
            }
        `}
    ${({ isMinimal }) => isMinimal
        && css`
            width: unset;
            border-radius: unset;
            &:hover {
                ${elevation(0, true)}
            }
        `}
        ${({ isInteractive }) => !isInteractive
        && css`
            pointer-events: none;
        `}

`;

const Text = styled.span`
  ${({theme}) => css(theme.typography.SuperTinyHeadline)};
`;

const SmallArrow = styled(ChevronSmall)`
    height: 16px;
    width: 16px;
    margin-right: 8px;
    margin-left: auto;
    ${({ isMinimal }) => isMinimal
        && css`
            margin-left: 8px;
        `}
`;

const LargeArrow = styled(ChevronLarge)`
    height: 24px;
    width: 24px;
    margin-left: auto;
`;

const DropDownOptions = styled(Options)`
    ${({ isOpen }) => isOpen && css`
            width: 101%;
            border: none;
            &:hover {
                transition-property: all;
            }
        `}
`;

export {
    Wrap, Text, SmallArrow, LargeArrow, DropDownOptions,
};

const sizes = {
    mobileMin: '0px',
    mobileMax: '767px',
    tabletMin: '768px',
    tabletMax: '1023px',
    desktopMin: '1024px',
    desktopMax: '1279px',
    largeDesktopMin: '1280px',
    largeDesktopMax: '1599px',
    widescreenMin: '1600px',
};

export const screens = {
    mobile: `(min-width: ${sizes.mobileMin}) and (max-width: ${sizes.mobileMax})`,
    mobileMax: `(max-width: ${sizes.mobileMax})`,
    tablet: `(min-width: ${sizes.tabletMin}) and (max-width: ${sizes.tabletMax})`,
    tabletMax: `(max-width: ${sizes.tabletMax})`,
    desktop: `(min-width: ${sizes.desktopMin})`,
    desktopMax: `(max-width: ${sizes.desktopMax})`,
    largeDesktop: `(min-width: ${sizes.largeDesktopMin}) and (max-width: ${sizes.largeDesktopMax})`,
    widescreenMin: `(min-width: ${sizes.widescreenMin})`,
};

export const screenSizes = {
    mobile: `(min-width: ${sizes.mobileMin}) and (max-width: ${sizes.mobileMax})`,
    mobileMax: `(max-width: ${sizes.mobileMax})`,
    tablet: `(min-width: ${sizes.tabletMin}) and (max-width: ${sizes.tabletMax})`,
    tabletMax: `(max-width: ${sizes.tabletMax})`,
    desktop: `(min-width: ${sizes.desktopMin}) and (max-width: ${sizes.desktopMax})`,
    desktopMax: `(max-width: ${sizes.desktopMax})`,
    largeDesktop: `(min-width: ${sizes.largeDesktopMin}) and (max-width: ${sizes.largeDesktopMax})`,
    widescreenMin: `(min-width: ${sizes.widescreenMin})`,
};

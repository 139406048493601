import React from 'react';
import PropTypes from 'prop-types';

import { registerFavoritesEvent as registerSnowplowFavoritesEvent } from 'ggApp/modules/gaAnalytics/actions';

import withIntersectionObserver from 'ggApp/modules/tracking/hocs/withIntersectionObserver';
import withOnClickEvent from 'ggApp/modules/tracking/hocs/withOnClickEvent';
import { TranslatorComponent_Text as Translator } from 'ggApp/modules/translator';
import Cta from 'ggApp/shared/components/widgets/cta';
import { product as productUrlBuilder } from 'ggApp/utils/urlBuilder/frontshop';
import PriceBlock from 'ggApp/shared/components/cards/Price';
import { mappedProductDefaultProps, mappedProductPropTypes } from 'ggApp/modules/product/constants';
import ChipMapper from 'ggApp/shared/components/cards/Chips';

import {
    CardChipBlock,
    DetailsSection,
    FavoriteButton,
    ProductAttributes,
    ProductImage,
    ProductName,
    RootComponent,
    ImageContainer,
} from './styles';

export default class HandPickedProductCardComponent extends React.PureComponent {
    onFavoriteCb = ({ isFavorite, isLoggedIn } = {}) => {
        const { product = {} } = this.props;

        registerSnowplowFavoritesEvent({ isFavorite, product, isLoggedIn });
    };

    render() {
        const {
            className,
            product: {
                id,
                chips,
                productName,
                productCoreAttributes,
                slug,
                price,
                oldPrice,
                originalImage,
            },
            onClick,
            hidePrices,
        } = this.props;

        return (
            <RootComponent className={className} onClick={onClick}>
                <ImageContainer>
                    <ProductImage
                        className={className}
                        src={originalImage}
                        alt={productName}
                        transformations={[
                            { effect: 'trim' },
                            { quality: 'auto', fetchFormat: 'auto' },
                            { background: 'rgb:f7f7f7' },
                        ]}
                    />
                </ImageContainer>

                {chips && (
                    <CardChipBlock>
                        <ChipMapper chips={chips} />
                    </CardChipBlock>
                )}
                <DetailsSection>
                    <ProductName>{productName}</ProductName>
                    {productCoreAttributes && (
                        <ProductAttributes>{productCoreAttributes}</ProductAttributes>
                    )}

                    {!hidePrices ? (
                        <PriceBlock oldPrice={oldPrice} price={price} handpicked />
                    ) : null}

                    <Cta
                        target="_self"
                        linkTo={productUrlBuilder(slug)}
                        text={<Translator tk="HAND_PICKED_PRODUCT_CARD_CTA_LABEL" />}
                    />
                </DetailsSection>
                <FavoriteButton id={id} slug={slug} onClickCallback={this.onFavoriteCb} />
            </RootComponent>
        );
    }
}

HandPickedProductCardComponent.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    product: mappedProductPropTypes,
    hidePrices: PropTypes.bool,
};

HandPickedProductCardComponent.defaultProps = {
    className: '',
    onClick: () => {},
    product: mappedProductDefaultProps,
    hidePrices: false,
};

export const HandPickedProductCardWithTracking = withOnClickEvent(
    withIntersectionObserver(HandPickedProductCardComponent),
);

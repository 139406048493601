/* eslint-disable */
import { MODULE_NAME, } from './constants';

export const GET_PAYMENT_GATEWAYS = `${MODULE_NAME}/GET_PAYMENT_GATEWAYS`;
export const GET_PAYMENT_GATEWAYS_SUCCESS = `${MODULE_NAME}/GET_PAYMENT_GATEWAYS_SUCCESS`;
export const GET_PAYMENT_GATEWAYS_ERROR = `${MODULE_NAME}/GET_PAYMENT_GATEWAYS_ERROR`;

export const GET_PAYMENT_LIST = `${MODULE_NAME}/GET_PAYMENT_LIST`;
export const GET_PAYMENT_LIST_SUCCESS = `${MODULE_NAME}/GET_PAYMENT_LIST_SUCCESS`;
export const GET_PAYMENT_LIST_ERROR = `${MODULE_NAME}/GET_PAYMENT_LIST_ERROR`;

export const SUBMIT_PAYMENT = `${MODULE_NAME}/SUBMIT_PAYMENT`;
export const SUBMIT_PAYMENT_SUCCESS = `${MODULE_NAME}/SUBMIT_PAYMENT_SUCCESS`;
export const SUBMIT_PAYMENT_ERROR = `${MODULE_NAME}/SUBMIT_PAYMENT_ERROR`;

export const SAVE_PAYMENT_DATA = `${MODULE_NAME}/SAVE_PAYMENT_DATA`;
export const RESET_SUBMITTED_PAYMENT_ERROR = `${MODULE_NAME}/RESET_SUBMITTED_PAYMENT_ERROR`;
export enum SegmentEvents {
    productFilterSorted = 'Product List Sorted',
    filterPriceSelected = 'Filter Price Selected',
    filterRentalPlanSelected = 'Filter Rental Plan Selected',
    filterProductAvailabilitySelected = 'Filter Product Availability Selected',
    filterBrandsSelected = 'Filter Brands Selected',
    filterSpecsSelected = 'Filter Specs Selected',
    filterDealsSelected = 'Filter Deals Selected',
    searchEntered = 'Search Entered',
    searchInputExited = 'Search Exited',
    searchTermUsed = 'Search Term Used',
    searchCTAClicked = 'Search CTA Clicked',
    searchProductClicked = 'Search Product Clicked',
    loginStarted = 'Login Started',
    loginSuccess = 'Login Success',
    phoneAdded = 'Phone Added',
    sessionStarted = 'Session Started',
    phoneSkipped = 'Phone Skipped',
}
export type GPagesButtonClickEventProperties = {
    store_id: number;
    button: string;
};

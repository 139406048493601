/* eslint-disable no-console */
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import loadConfiguration from 'ggApp/utils/configuration/index';

const {
    DATADOG_CLIENT_TOKEN,
    DATADOG_RUM_CLIENT_TOKEN,
    DATADOG_RUM_APP_ID,
    DATADOG_ENV,
    DD_ENV,
    DD_VERSION,
} = loadConfiguration();

export const initDatadog = () => {
    // remove DATADOG_ENV after https://github.com/devsbb/devops/pull/599
    const ddEnv = DD_ENV || DATADOG_ENV || '';

    datadogLogs.init({
        clientToken: DATADOG_CLIENT_TOKEN || '',
        site: 'datadoghq.eu',
        forwardErrorsToLogs: true,
        sampleRate: 100,
        service: 'frontshop',
        env: ddEnv,
        version: `${ddEnv}-${DD_VERSION || ''}`,
        forwardConsoleLogs: ['warn'],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        beforeSend: (log: any) => {
            if (!['staging', 'production'].includes(ddEnv)) {
                console.group('--- DD');
                console.log(log);
                console.groupEnd();
                return false;
            }

            return true;
        },
    });
};

export const initDatadogRum = () => {
    // remove DATADOG_ENV after https://github.com/devsbb/devops/pull/599
    const ddEnv = DD_ENV || DATADOG_ENV || '';

    datadogRum.init({
        applicationId: DATADOG_RUM_APP_ID || '',
        clientToken: DATADOG_RUM_CLIENT_TOKEN || '',
        site: 'datadoghq.eu',
        service: 'frontshop',
        env: ddEnv,
        version: `${ddEnv}-${DD_VERSION || ''}`,
        sampleRate: 5,
        trackInteractions: true,
        // remove this line to disable session recording
        defaultPrivacyLevel: 'mask-user-input',
        beforeSend: () => {
            if (['staging', 'production'].includes(ddEnv)) {
                return true;
            }

            return false;
        },
    });

    // remove this line to disable session recording
    datadogRum.startSessionReplayRecording();
};

import React, { useRef } from 'react';

import { useFeatureFlags } from 'ggApp/modules/featureFlag/hooks/useFeatureFlags';
import { HOMEPAGE_MC1_AB_TEST } from 'ggApp/modules/featureFlag';
import { registerTeaserClickEvent, registerTeaserImpressionEvent } from 'ggApp/modules/gaAnalytics';
import useIntersection from 'ggApp/shared/hooks/useIntersection';
import { WIDGET_NAMES } from 'ggApp/modules/gaAnalytics/constants';

import { CarouselCardProps } from '../types';

import {
    Wrapper,
    LinkWrapper,
    ImageWrapper,
    Image,
    ContentWrap,
    Content,
    Header,
    Body,
} from './styles';

export const MobileCarouselCard = ({
    isTablet,
    campaignBannerContent: {
        title,
        text,
        textColor,
        cta,
        carouselImage,
        carouselMobileImage,
        carouselTabletImage,
        backgroundColor,
    },
    clickClassName,
    className,
}: CarouselCardProps) => {
    const homepageMc1TestEnabled = useFeatureFlags();
    const carouselCardRef = useRef(null);

    const sliderImage = isTablet ? carouselTabletImage?.url : carouselMobileImage?.url;

    const link =
        homepageMc1TestEnabled?.isFlagEnabled(HOMEPAGE_MC1_AB_TEST) && cta.alternativeURL
            ? cta.alternativeURL
            : cta.url;

    useIntersection({
        node: carouselCardRef,
        onExitCallback: null,
        onEnterCallback: registerTeaserImpressionEvent({
            widgetName: WIDGET_NAMES.marketing1,
            url: link,
            title,
        }),
    });

    const handleCtaCLick = registerTeaserClickEvent({
        widgetName: WIDGET_NAMES.marketing1,
        title,
        url: link,
        ctaViewAll: false,
    });

    return (
        <Wrapper className={className} ref={carouselCardRef}>
            <LinkWrapper
                className={clickClassName}
                external={!cta.internal}
                target="_self"
                to={link}
                onClick={handleCtaCLick}
            >
                <ImageWrapper>
                    <Image
                        imgUrls={sliderImage ?? carouselImage?.url}
                        src={sliderImage ?? carouselImage?.url}
                        lazy={false}
                    />
                </ImageWrapper>
                <ContentWrap backgroundColor={backgroundColor}>
                    <Content>
                        <Header textColor={textColor}>{title}</Header>
                        <Body textColor={textColor} html={text} />
                    </Content>
                </ContentWrap>
            </LinkWrapper>
        </Wrapper>
    );
};

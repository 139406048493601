import PropTypes from 'prop-types';

export default function If(props) {
    const { children, condition } = props;

    if (!condition) {
        return null;
    }

    return children;
}

If.propTypes = {
    /*
        Since any expression can be passed down
        and will be evaluated in the boolean context
    */
    // eslint-disable-next-line react/forbid-prop-types
    condition: PropTypes.any.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

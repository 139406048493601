import {
    saveOrder,
    getOrderFromCookies,
    deleteOldCookies,
} from 'ggApp/shared/context/Order/cookies';

import { APISelection } from '../../integration';
import createOrderMutation from './orderCreate.graphql';
import submitOrderMutation from './orderSubmit.graphql';
import getOrderQuery from './getOrder.graphql';
import { constructLineItem } from '../utils/lineItemFormat';
import { orderSubmitFormat } from '../utils/orderSubmitFormat';

export const order: APISelection<any> = {
    get: async ({ activeStore, user }) => {
        const orderNumber =
            getOrderFromCookies('MIX', activeStore.code) ||
            getOrderFromCookies('FLEX', activeStore.code) ||
            null;

        if (!orderNumber) return null;
        if (activeStore.code !== 'us' && (!user || !user.id)) return null; // mix order is not avaialble for guest

        try {
            const { CartServiceClient } = await import('../client');
            const { data, errors } = await CartServiceClient.query({
                query: getOrderQuery,
                variables: { orderNumber },
            });

            if (errors && errors.length) {
                const [error] = errors;
                throw error;
            }

            if (!data?.order) {
                throw new Error('Order is not found!');
            }

            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    add: async (context, event) => {
        const { orderMode, activeStore } = context;
        const { items, callback } = event;
        const item = items?.[0];
        const orderDetails = {
            lineItems: [constructLineItem(item)],
            orderMode,
            adjustment: item?.adjustment,
        };
        const { CartServiceClient } = await import('../client');

        const { data } = await CartServiceClient.mutation({
            mutation: createOrderMutation,
            variables: { order: orderDetails },
        });
        const { order: { orderNumber } = {} } = data;
        deleteOldCookies('MIX', activeStore.code);
        saveOrder({ orderMode: 'MIX', storeCode: activeStore.code, orderNumber });
        if (callback && typeof callback === 'function') {
            callback();
        }
        return data;
    },
    submit: async (context) => {
        try {
            const { CartServiceClient } = await import('../client');
            const { orderNumber } = context;
            const input = orderSubmitFormat(context);
            const { data } = await CartServiceClient.mutation({
                mutation: submitOrderMutation,
                variables: { orderNumber, input },
            });

            return data;
        } catch (e) {
            console.error(e);
            return null;
        }
    },
};

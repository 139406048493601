/* eslint-disable */
import { combineReducers } from 'redux';

import intercomHMAC from './intercomHMAC';
import unreadMessages from './unreadMessages';

export const intercomReducers = combineReducers({
  intercomHMAC,
  unreadMessages,
});

import { GET_PRODUCTS_GRAPHQL_SUCCESS } from '../actionTypes';

const defaultState = {
    loading: null,
    error: {},
    data: [],
};

export default function(state = defaultState, action) {
    switch (action.type) {
        case GET_PRODUCTS_GRAPHQL_SUCCESS: {
            const merged = state?.data
                ? state.data.concat(action.data.products)
                : action.data.products;
            return {
                ...state,
                loading: false,
                data: merged,
            };
        }

        default:
            return state;
    }
}

import React from 'react';
import { withTheme } from 'styled-components';
import { IconPropTypes } from 'ggApp/types/propTypes';

const LinkedInIcon = ({ className, size, color, theme }) => {
    const fill = color || theme.colors.Foreground;
    return (
        <svg
            className={className}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4ZM6 2C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V6C22 3.79086 20.2091 2 18 2H6Z"
                fill={fill}
            />
            <path fillRule="evenodd" clipRule="evenodd" d="M9 11L9 18H7L7 11H9Z" fill={fill} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 13.5C11 11.567 12.567 10 14.5 10C16.433 10 18 11.567 18 13.5V18H16V13.5C16 12.6716 15.3284 12 14.5 12C13.6716 12 13 12.6716 13 13.5V18H11V13.5Z"
                fill={fill}
            />
            <path
                d="M9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8Z"
                fill={fill}
            />
        </svg>
    );
};

LinkedInIcon.propTypes = {
    ...IconPropTypes,
};

LinkedInIcon.defaultProps = {
    className: '',
    size: '24px',
};

export default withTheme(LinkedInIcon);

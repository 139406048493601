/* eslint-disable */
import axios from 'ggApp/utils/requests'

export function paymentGatewayList_get(orderNumber) {

  return axios.get('/adyen/recurrings/gateways', {
      headers: {
          'X-Grover-Order-Number': orderNumber,
          'X-Grover-Order-Token': undefined, /* explicitly overwriting `axios` default headers */
      }
  })
}

export function paymentList_get() {
  return axios.get('/adyen/recurrings')
}

export function submitPayment_post(data, options = {}) {
  const { paymentType, iban, holderName, countryCode, agreementAccepted, } = data

  return axios.post('/adyen/recurrings', {
    payment_type: paymentType,
    agreement_accepted: agreementAccepted,
    bank: {
      iban,
      owner_name: holderName,
      country_code: countryCode,
    },
  }, options)
}

export function submitCreditCard_post(data, options = {}) {
  const { paymentType, encryptedCard, } = data

  return axios.post('/adyen/recurrings', {
    payment_type: paymentType,
    card: encryptedCard,
  }, options)
}

import { makeVar } from '@apollo/client';

import { Maybe, MixSwapShippingProgress, ContractGoodState } from 'ggApp/types/graphql';

export const MixDeviceStatusKeymap: Record<string, MixSwapShippingProgress | undefined> = {
    [ContractGoodState.AWAITING_RETURN]: MixSwapShippingProgress.AWAITING_RETURN,
    [ContractGoodState.SHIPPING_RETURN]: MixSwapShippingProgress.OLD_DEVICE_IN_TRANSIT,
    [ContractGoodState.RETURN_REJECTED]: MixSwapShippingProgress.PROBLEM_WITH_RETURN,
    [ContractGoodState.PROCESSING_RETURN]: MixSwapShippingProgress.CHECKING_RETURN,
    [ContractGoodState.RESERVED]: MixSwapShippingProgress.NEW_DEVICE_AWAITING_PICKUP,
    [ContractGoodState.SHIPPING]: MixSwapShippingProgress.NEW_DEVICE_IN_TRANSIT,
    [ContractGoodState.WITH_CUSTOMER]: MixSwapShippingProgress.NEW_DEVICE_DELIVERED,
};

export const shippingProgressVar = makeVar<Maybe<MixSwapShippingProgress>>(null);

import React from 'react';
import { IconPropTypes } from 'ggApp/types/propTypes';
import { Svg, Path, Rect, Circle } from 'react-native-svg';

const MasterCard = ({ className, size }) => (
    <Svg
        className={className}
        width="36"
        height={size}
        viewBox="0 0 36 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path d="M0 0H36V24H0V0Z" fill="white" />
        <Rect width="36" height="24" rx="4" fill="white" />
        <Rect
            x="0.5"
            y="0.5"
            width="35"
            height="23"
            rx="3.5"
            stroke="#333333"
            strokeOpacity="0.07"
        />
        <Circle cx="14" cy="12" r="6" fill="#D82F2C" />
        <Circle cx="22" cy="12" r="6" fill="#EAA33F" />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 16.4723C19.2275 15.3736 20 13.777 20 12C20 10.223 19.2275 8.62647 18 7.52783C16.7725 8.62647 16 10.223 16 12C16 13.777 16.7725 15.3736 18 16.4723Z"
            fill="#ED6A2D"
        />
    </Svg>
);

MasterCard.propTypes = {
    ...IconPropTypes,
};

MasterCard.defaultProps = {
    className: '',
    size: '24px',
    color: '#333333',
};

export default MasterCard;

import React from 'react';
import { withTheme } from 'styled-components';
import { Svg, ClipPath, Path, Rect } from 'react-native-svg';

const Stars = ({
    theme,
    backgroundColor = theme.colors['Level 3'],
    fillColor = '#FFC700',
    percentage,
}) => (
    <Svg width="90" height="14" viewBox="0 0 100 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ClipPath id="stars">
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.11986 4.33747L14 4.96556L10.43 8.27435L11.3262 13L7 10.7075L2.67376 13L3.57 8.27435L0 4.96556L4.88014 4.33747L7 0L9.11986 4.33747Z"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.11986 4.33747L34 4.96556L30.43 8.27435L31.3262 13L27 10.7075L22.67376 13L23.57 8.27435L20 4.96556L24.88014 4.33747L27 0L29.11986 4.33747Z"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M47.11986 4.33747L54 4.96556L50.43 8.27435L51.3262 13L47 10.7075L42.67376 13L43.57 8.27435L40 4.96556L44.88014 4.33747L47 0L49.11986 4.33747Z"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M67.11986 4.33747L74 4.96556L70.43 8.27435L71.3262 13L67 10.7075L62.67376 13L63.57 8.27435L60 4.96556L64.88014 4.33747L67 0L69.11986 4.33747Z"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M87.11986 4.33747L94 4.96556L90.43 8.27435L91.3262 13L87 10.7075L82.67376 13L83.57 8.27435L80 4.96556L84.88014 4.33747L87 0L89.11986 4.33747Z"
            />
        </ClipPath>

        <Rect
            x="0"
            y="0"
            fill={backgroundColor}
            width="100%"
            height="100%"
            clipPath="url(#stars)"
        />
        <Rect
            x="0"
            y="0"
            fill={fillColor}
            width={percentage}
            height="100%"
            clipPath="url(#stars)"
        />
    </Svg>
);

export default withTheme(Stars);

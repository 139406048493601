import React from 'react';
import { Svg, Path } from 'react-native-svg';
import { IconPropTypes } from 'ggApp/types/propTypes';
import { withTheme } from 'styled-components';

const ForYouOutlineIcon = ({ color, size, className, theme }) => {
    const fill = color || theme.colors.Foreground;
    return (
        <Svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.6352 13.185L19.6361 13.1808C19.9306 11.8938 20.0865 10.3012 19.9276 8.80822C19.7686 7.31485 19.32 6.13215 18.6132 5.37248C17.8584 4.66809 16.6804 4.22153 15.1887 4.06513C13.6977 3.9088 12.1063 4.06759 10.821 4.36308L10.8143 4.36461C9.0842 4.75614 7.46116 5.90883 6.20404 7.54869C4.94746 9.18786 4.16089 11.1833 4.01118 13.0385C3.88442 14.6753 4.70096 16.4225 6.13804 17.8043L6.16627 17.8315L6.19342 17.8597C7.57496 19.2964 9.32158 20.1129 10.958 19.9867C12.6495 19.8482 14.6629 19.0242 16.377 17.7068C18.1005 16.3823 19.2813 14.7474 19.6352 13.185ZM11.117 21.9803C8.71273 22.1678 6.4139 20.9745 4.75179 19.246C3.02327 17.5838 1.82991 15.285 2.01741 12.8807C2.38265 8.33775 5.79867 3.44908 10.3729 2.41393C13.2869 1.744 17.6385 1.66783 20.0291 3.95885C22.3182 6.35533 22.2537 10.7069 21.5858 13.6268C20.6073 17.9471 15.4139 21.6307 11.117 21.9803ZM16.6854 11.6971C16.6854 14.1201 14.7235 16.0819 12.3006 16.0819C9.88071 16.0819 7.91585 14.1211 7.91585 11.6971C7.91585 9.27615 9.87964 7.31236 12.3006 7.31236C13.4823 7.31236 14.5561 7.78218 15.3427 8.54018L13.9551 9.98015L15.3408 8.53831C16.1698 9.335 16.6854 10.4592 16.6854 11.6971ZM14.6854 11.6971C14.6854 13.0155 13.619 14.0819 12.3006 14.0819C10.9842 14.0819 9.91585 13.0155 9.91585 11.6971C9.91585 10.3807 10.9842 9.31236 12.3006 9.31236C12.9432 9.31236 13.5254 9.56609 13.9551 9.98015C14.4063 10.4137 14.6854 11.0233 14.6854 11.6971Z"
                fill={fill}
            />
        </Svg>
    );
};

ForYouOutlineIcon.propTypes = {
    ...IconPropTypes,
};

ForYouOutlineIcon.defaultProps = {
    className: '',
    size: '24px',
};

export default withTheme(ForYouOutlineIcon);

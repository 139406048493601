import { client } from './client';
import { APISelection, PaymentStub } from '../integration';

export const paymentMethod: APISelection<PaymentStub> = {
    update: async ({ orderNumber }, event) => {
        const { paymentMethodId, paymentMethodType } = event;

        return client({
            endpoint: `/orders/${orderNumber}/payment_method`,
            method: 'patch',
            body: {
                payment_method_id: paymentMethodId,
                payment_method_type: paymentMethodType,
            },
        }).then((res) => res.data);
    },
};

import { useEffect } from 'react';
import { browserHistory } from 'react-router';
import { THIRTY_DAYS_EXPIRATION_TIME_IN_DAYS } from 'ggApp/modules/auth/utils';
import CookiesManager from 'ggApp/utils/CookiesManager';

const Session = () => {
    const queryParameters = ['utm_medium', 'utm_campaign', 'oid', 'affid', 'utm_source', 'fbclid'];

    useEffect(() => {
        const location = browserHistory.getCurrentLocation();
        queryParameters.forEach((param) => {
            if (location.query[param]) {
                const queryParamValue = String(location.query[param]);
                if (queryParamValue) {
                    const cookiesManager = new CookiesManager();
                    cookiesManager.set(param, queryParamValue, {
                        maxAge: THIRTY_DAYS_EXPIRATION_TIME_IN_DAYS,
                    });
                }
            }
        });
    }, [queryParameters]);
    return null;
};

export default Session;

/* eslint-disable */
import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_ERROR,

  CANCEL_ORDER,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_ERROR,

} from './../actionTypes';
import { actionsByStatus, } from './../associations';
import { CURRENCIES_ASSOCIATION, } from 'ggApp/modules/store/constants';

const defaultState = {
  loading: null,
  error: {},
  data: [],
};

export default function (state = defaultState, action) {
  switch (action.type) {

    case GET_ORDERS:
    case CANCEL_ORDER:
      return Object.assign({}, state, {
        loading: true,
      })

    case GET_ORDERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: (action.data || []).reverse().map(o => Object.assign({}, o, {
          currencySymbol: CURRENCIES_ASSOCIATION[o.currency] || '€',
          ui: actionsByStatus(o.state),
        })),
      })

    case CANCEL_ORDER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: state.data.map(o =>
          o.number === action.data.number
            ? Object.assign({}, o, action.data, {
              ui: actionsByStatus(action.data.state),
            })
            : o
        ),
      })

    case GET_ORDERS_ERROR:
    case CANCEL_ORDER_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      })

    default:
      return state;
  }
}

/* eslint-disable */
import React, { PureComponent } from 'react';
import CircleLoader from 'ggApp/shared/components/CircleLoader';
import StyledModal from "./styles";

class LoadingModal extends PureComponent {
    createModalRef = (e) => {
        this.modal = e;
    };

    componentWillReceiveProps(nextProps, nextContext) {
        const { loading } = this.props;
        const { loading: nextLoading } = nextProps;
        if (loading !== nextLoading) {
            if (this.modal) {
                if (nextLoading) {
                    this.modal.openModal();
                } else {
                    this.modal.closeModal();
                }
            }
        }
    }

    onClose = (payload) => !payload;

    render() {
        return (
            <StyledModal onClose={this.onClose} modalRef={this.createModalRef}/>
        );
    }
}

export default LoadingModal;

import React, { useState, useRef, useCallback } from 'react';
import useUnmount from 'ggApp/shared/hooks/useUnmount';
import useIntersection from 'ggApp/shared/hooks/useIntersection';

import { Intersection, ShimmerBackground, ImageContainer } from './styles';

const DELAY_VALUES = [0, 0.2, 0.4, 0.6, 0.8, 1, 1.2, 1.4, 1.6];

const withShimmerEffect = (ImageComponent) => ({ animationDelay, onLoad, ...props }) => {
    const [loading, setLoading] = useState(false);
    const [entered, setEntered] = useState(false);
    const [timerId, setTimerId] = useState(null);
    const intersectionRef = useRef(null);

    const onLoadHandler = useCallback(() => {
        setLoading(false);
        clearInterval(timerId);
        if (typeof onLoad === 'function') {
            onLoad();
        }
    }, [onLoad, timerId]);

    const componentEntered = useCallback(() => {
        setEntered(true);
        if (!timerId) {
            const id = setTimeout(() => setLoading(true), 200);
            setTimerId(id);
        }
    }, [timerId]);

    const handleError = useCallback(() => {
        setLoading(true);
        clearInterval(timerId);
    }, [timerId]);

    useIntersection({
        node: intersectionRef,
        onEnterCallback: componentEntered,
        options: {
            threshold: 0.01,
            rootMargin: '250px',
        },
    });

    useUnmount(() => {
        clearInterval(timerId);
    });

    let animation = animationDelay;
    if (!animationDelay) {
        animation = DELAY_VALUES[Math.floor(Math.random() * DELAY_VALUES.length)];
    }

    return (
        <Intersection ref={intersectionRef}>
            {entered && (
                <ImageContainer loading={loading}>
                    <ImageComponent onLoad={onLoadHandler} onError={handleError} {...props} />
                </ImageContainer>
            )}
            <ShimmerBackground animationDelay={animation} loading={loading} />
        </Intersection>
    );
};

export default withShimmerEffect;

/* eslint-disable */

export default function plurString(translationString, values) {
    if (!translationString || typeof translationString !== 'string') return;
    const chunkWithValues = translationString.split('}}');
    const result = [chunkWithValues[0]];
    for (let i = 1; i < chunkWithValues.length; i++) {
        let key = chunkWithValues[i - 1].split('{{').slice(-1)[0];
        let value = values[key];
        if (value || typeof value === 'number') {
            result.push(chunkWithValues[i].replace(/\[([^|]*)\|([^\]]*)\]/g, value === 1 ? '$1' : '$2'));
        } else {
            result.push(chunkWithValues[i]);
        }
    }

    return result.join('}}');
}


/* eslint-disable */
import {
  SUBSCRIBE_TO_NEWSLETTER,
  SUBSCRIBE_TO_NEWSLETTER_SUCCESS,
  SUBSCRIBE_TO_NEWSLETTER_ERROR,
} from './../actionTypes';

const defaultState = {
  loading: null,
  error: {},
  data: false,
};


export default function (state = defaultState, action) {
  switch (action.type) {

    case SUBSCRIBE_TO_NEWSLETTER:
      return Object.assign({}, state, {
        loading: true,
        error: {},
      })

    case SUBSCRIBE_TO_NEWSLETTER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.data,
      })

    case SUBSCRIBE_TO_NEWSLETTER_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      })

    default:
      return state;
  }
}

import { sendStructEvent } from '../../api';

interface PayloadModel {
    productSKU: string;
    userID?: number;
    videoStep: string;
}

export const sendVideoPdpEvent = (payload: PayloadModel): void => {
    const { productSKU, userID = null, videoStep } = payload;
    sendStructEvent({
        eventCategory: 'productPage',
        eventAction: 'productVideo',
        eventLabel: videoStep,
        eventProperty: {
            productSKU,
            userID,
        },
    });
};

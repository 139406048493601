import styled from 'styled-components';

export const ReviewCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 24px 30px;
    min-width: 300px;
    background-color: ${({ theme }) => theme.colors.Background};
    margin-right: 16px;
    border-radius: 8px;
`;

export const ReviewText = styled.div`
    display: flex;
    flex-direction: column;
    flex: auto;
`;

export const ReviewerDetails = styled.div`
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
`;

export const ReviewerName = styled.span`
    margin-right: 5px;
`;

export const VerifiedWrapper = styled.div`
    display: flex;
    margin-bottom: 10px;
    align-items: center;
`;

export const VerifiedText = styled.span`
    font-size: 12px;
`;

export const ReviewContent = styled.div`
    line-height: 20px;
    margin-bottom: 20px;
`;

export const AddressAndDate = styled.div`
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
`;

export const Address = styled.div`
    margin-right: 4px;
`;

/* eslint-disable */
import parseToRgb from "../helpers/parseToRgb";
import rgbToHsl from "../helpers/rgbToHsl";
import {toColorString,} from "./hslaHelpers";

export function lighten(amount, color) {
    const hslColor = rgbToHsl(parseToRgb(color));
    return toColorString({
        ...hslColor,
        lightness: guard(0, 1, hslColor.lightness + parseFloat(amount)),
    })
}

export const guard = (lowerBoundary, upperBoundary, value) => Math.max(lowerBoundary, Math.min(upperBoundary, value));

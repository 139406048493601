import axios from 'ggApp/utils/requests';

export const getUserLists: any = {
    addresses: async () => {
        const addresses = await axios.get('/addresses').then((res) => res.data?.addresses ?? []);
        return addresses;
    },
    payments: async () => {
        const payments = await axios
            .get('/user/payment_methods')
            .then((res) => res.data?.payment_methods ?? []);
        return payments;
    },
};

export const addressList: any = {
    add: async (context, data) => {
        const { address, code } = data;
        return axios
            .post('/addresses', { address, ...({ '2fa_code': code } || {}) })
            .then((res: any) => res.data?.addresses ?? []);
    },
    update: async (context, data) => {
        const { address } = data;
        const { id } = address;

        return axios
            .patch(`/addresses/${id}`, { address })
            .then((res: any) => res.data?.addresses ?? []);
    },
};

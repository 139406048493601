import ActionTypes, { LoadersAction } from './actionTypes';

export interface LoadersState {
    [ActionTypes.IS_DETAILS_LOADING]: boolean;
    [ActionTypes.IS_FILTERS_LOADING]: boolean;
    [ActionTypes.IS_SUMMARY_LOADING]: boolean;
}

export const defaultState = {
    [ActionTypes.IS_DETAILS_LOADING]: true,
    [ActionTypes.IS_FILTERS_LOADING]: true,
    [ActionTypes.IS_SUMMARY_LOADING]: true,
};

function reducer(state: LoadersState = defaultState, action: LoadersAction): LoadersState {
    switch (action.type) {
        case ActionTypes.IS_FILTERS_LOADING:
        case ActionTypes.IS_SUMMARY_LOADING:
        case ActionTypes.IS_DETAILS_LOADING:
            return {
                ...state,
                [action.type]: action.payload,
            };

        default:
            return state;
    }
}

export default reducer;

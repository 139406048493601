import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { mainTransition } from 'ggApp/modules/router/actions';

import NotFound from './NotFound';

const enhance = compose(
    connect(null, (dispatch) => ({
        mainTransition: bindActionCreators(mainTransition, dispatch),
    })),
);

export default enhance(NotFound);

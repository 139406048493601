import React from 'react';

import { BottomSheet } from 'ggApp/shared/common/BottomSheet';
import AccountMenu from './AccountMenu';
import { MobileAccountMenuProps } from './types';
import { Line } from './styles';

export const MobileAccountMenu = ({
    isOpen,
    setIsOpen,
    onCloseMenu,
}: MobileAccountMenuProps): JSX.Element => {
    return (
        <BottomSheet title={<Line />} shouldShow={isOpen} setShouldShow={setIsOpen}>
            <AccountMenu onCloseMenu={onCloseMenu} />
        </BottomSheet>
    );
};

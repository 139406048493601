/* eslint-disable */
import {
    GET_HMAC,
    GET_HMAC_SUCCESS,
    GET_HMAC_ERROR,
} from '../constants/actionTypes';

const defaultState = {
    loading: null,
    error: {},
    data: null,
};


export default function (state = defaultState, action) {
    switch (action.type) {
    case GET_HMAC:
        return Object.assign({}, state, {
            loading: true,
            error: {},
        });

    case GET_HMAC_SUCCESS:
        return Object.assign({}, state, {
            loading: false,
            data: action.data,
        });

    case GET_HMAC_ERROR:
        return Object.assign({}, state, {
            loading: false,
            error: action.error,
        });

    default:
        return state;
    }
}

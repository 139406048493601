import parseUrl from 'url-parse';

function toRelativeUrl(url) {
    if (typeof url !== 'string') {
        return undefined;
    }

    let urlString = url;

    // It needs to remove hostname from links like `example.com/foo/bar` or `127.0.0.1:3300/foo`,
    // because by default they are not removed.
    if (!(url.startsWith('http://') || url.startsWith('https://') || url.startsWith('/'))) {
        urlString = `http://${urlString}`;
    }

    // handle links like `//grover.com/foo/bar`
    // url-parse cannot handle above format properly
    if (url.startsWith('//')) urlString = url.replace(/^.{2}/g, 'http://');

    const parsedObject = parseUrl(urlString, {}, true);

    // removes http or https
    parsedObject.set('protocol', '');
    // removes domain name or ip address
    parsedObject.set('hostname', '');
    // removes port
    parsedObject.set('port', '');

    return parsedObject.toString();
}

export default toRelativeUrl;

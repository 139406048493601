import { GET_CATEGORY_BY_ID_SUCCESS } from 'ggApp/modules/category/actionTypes';
import { GET_FAVORITES_PRODUCTS_SUCCESS } from 'ggApp/modules/favorites/actionTypes';
import { buildProductDictionary } from 'ggApp/modules/listing/utils';
import { GET_PRODUCT_BY_ID_SUCCESS, GET_PRODUCTS_SUCCESS } from 'ggApp/modules/product/actionTypes';
import { extractProductsFromWidgets, PRODUCT_WIDGET_SUCCESS } from 'ggApp/modules/productWidget';
import objectPath from 'object-path';

const defaultState = {
    // { [productId]: product }
    productsById: {},
    // { [slug]: productId }
    idsBySlug: {},
};

export default function(state = defaultState, action) {
    switch (action.type) {
        case GET_CATEGORY_BY_ID_SUCCESS: {
            const {
                productsById: newProductsById,
                idsBySlug: newIdsBySlug,
            } = buildProductDictionary(
                objectPath.get(action, ['data', 'contentful_record', 'featured']),
            );
            return {
                productsById: { ...state.productsById, ...newProductsById },
                idsBySlug: { ...state.idsBySlug, ...newIdsBySlug },
            };
        }
        case PRODUCT_WIDGET_SUCCESS: {
            const {
                productsById: newProductsById,
                idsBySlug: newIdsBySlug,
            } = buildProductDictionary(extractProductsFromWidgets(action.data));
            return {
                productsById: { ...state.productsById, ...newProductsById },
                idsBySlug: { ...state.idsBySlug, ...newIdsBySlug },
            };
        }
        case GET_PRODUCT_BY_ID_SUCCESS: {
            const {
                productsById: newProductsById,
                idsBySlug: newIdsBySlug,
            } = buildProductDictionary([action.data, ...(action.data.similar_products || [])]);
            return {
                productsById: { ...state.productsById, ...newProductsById },
                idsBySlug: { ...state.idsBySlug, ...newIdsBySlug },
            };
        }
        case GET_FAVORITES_PRODUCTS_SUCCESS:
        case GET_PRODUCTS_SUCCESS: {
            const {
                productsById: newProductsById,
                idsBySlug: newIdsBySlug,
            } = buildProductDictionary(action.data.products);
            const orderedProducts =
                action?.data?.loadOrder === 'previous'
                    ? {
                          productsById: { ...newProductsById, ...state.productsById },
                          idsBySlug: { ...newIdsBySlug, ...state.idsBySlug },
                      }
                    : {
                          productsById: { ...state.productsById, ...newProductsById },
                          idsBySlug: { ...state.idsBySlug, ...newIdsBySlug },
                      };
            return orderedProducts;
        }
        default:
            return state;
    }
}

import { PaymentsAction, PayloadInterface } from './actionTypes';

export type PaymentsState = PayloadInterface;

export const defaultState = {
    shouldShow: false,
    content: null,
};

function reducer(state: PayloadInterface = defaultState, action: PaymentsAction): PayloadInterface {
    return { ...state, ...action.payload };
}

export default reducer;

import { isFunction } from 'ggApp/utils/function';
import { SNOWPLOW_EVENT, SNOWPLOW_NAMESPACE } from 'snowplow';
import {
    ANALYTIC_EVENTS,
    SUBSCRIPTION_EVENTS,
    PAYMENTS_EVENTS,
    PROFILE_EVENTS,
} from '../../gaAnalytics/constants';

export function pushSnowplow(...data) {
    if (isFunction(window[SNOWPLOW_NAMESPACE])) {
        window[SNOWPLOW_NAMESPACE](...data);
    }
}

function sendGtmEventToSnowplow(data) {
    const normalizeData = Object.keys(data).reduce((result, key) => {
        // eslint-disable-next-line no-param-reassign
        result[key] = typeof data[key] === 'object' ? JSON.stringify(data[key]) : data[key];
        return result;
    }, {});
    const { eventCategory, eventAction, eventLabel, eventProperty, eventValue } = normalizeData;
    pushSnowplow(
        SNOWPLOW_EVENT.structEvent,
        eventCategory,
        eventAction,
        eventLabel,
        eventProperty,
        eventValue,
    );
}

export function sendDataToSnowplow(data) {
    if (!window || !window[SNOWPLOW_NAMESPACE]) return;

    const isStructureEvent = [
        ANALYTIC_EVENTS.gtmEvent,
        ANALYTIC_EVENTS.purchase,
        ANALYTIC_EVENTS.pdpView,
        ANALYTIC_EVENTS.basket,
        ANALYTIC_EVENTS.productImpressionSnowplow,
        ANALYTIC_EVENTS.productClickSnowplow,
        ANALYTIC_EVENTS.productPage,
        ANALYTIC_EVENTS.cartHighlightInRegistration,
        ANALYTIC_EVENTS.paypalTest,
        ANALYTIC_EVENTS.loggedOutReferrals,
        ANALYTIC_EVENTS.loggedInReferrals,
        ANALYTIC_EVENTS.availabilityService,
        ANALYTIC_EVENTS.homepageCards,
        ANALYTIC_EVENTS.newCheckout,
        ANALYTIC_EVENTS.mixLpClick,
        ANALYTIC_EVENTS.bundleImpression,
        ANALYTIC_EVENTS.addBundleToCart,
        ANALYTIC_EVENTS.pagination,
        ...Object.values(SUBSCRIPTION_EVENTS),
        ...Object.values(PAYMENTS_EVENTS),
        ...Object.values(PROFILE_EVENTS),
    ].includes(data.event);

    if (isStructureEvent) {
        sendGtmEventToSnowplow(data);
    }
}

import { setContext } from '@apollo/client/link/context';
import { ApolloLink } from '@apollo/client';

function createDefaultContextMiddleware(cookiesManager: any): ApolloLink {
    const contextLink = setContext(() => ({
        cookiesManager,
    }));

    return contextLink;
}

export default createDefaultContextMiddleware;

/* eslint-disable */
import styled, { css } from 'styled-components';
import { elevation, transition } from 'ggApp/utils/mixins';
import ArrowNextIcon from './images/arrowNext.svg';
import ArrowPrevIcon from './images/arrowPrev.svg';
import { screens } from 'ggApp/utils/mixins/screens';

export const Gap = styled.div`
    display: inline-block;
    max-height: 100%;
    min-width: ${({ width }) => width}px;
    max-width: 100%;
`;


const Arrow = styled.button`
    background-size: auto auto;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 45px;
    height: 45px;
    visibility: hidden;
    opacity: 0;
    background-color: #fff;
    border-radius: 50%;    
    ${transition('all', 'medium-mobile', 'standard')};
    ${elevation(2, false)};
    @media (hover: hover) {
        &:hover {
            ${elevation(3, false)};
        }
    }
    cursor: pointer;    
`;

export const NextArrow = styled(Arrow)`
    background-image: url(${ArrowNextIcon});
    position: absolute;
    top: calc(50% - 22.5px);
    right: 16px
`;

export const PrevArrow = styled(Arrow)`
    background-image: url(${ArrowPrevIcon});
    position: absolute;
    top: calc(50% - 22.5px);
    left: 16px
`;


export const Root = styled.div`
  position: relative;
  width: auto;
  overflow-x: scroll;
  display: flex;  
  padding: 10px 0 10px 10px;
  margin: -10px -16px -10px -10px;
  scroll-padding: ${({ theme }) => theme.spacers.L};
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar { display: none; width:  0 !important; }
  scrollbar-width: none;
  
  @media not all and (any-pointer: coarse) {
    overflow-x: hidden;
      &:hover { 
          ${Arrow}:not(:disabled) {
              visibility: visible;
              opacity: 1;
          }
      }
  }
`;

export const List = styled.div`
  display: flex;
  overflow: visible;
  transition: transform 500ms cubic-bezier(0.64, 0.04, 0.35, 1);
  width: 100%;
  @media (hover: hover) {
    width: auto;
  }

  @media (${screens.phone}) and (${screens.tabletMax}) {
      padding: 0 ${({ theme }) => theme.spacers.L};
  }
`;
import { createContext } from 'react';
import { StoreDefinition } from './services/integration';
import { PhoneVerificationPendingType } from './types';

export interface OrderContextProps {
    children?: React.ReactElement | React.ReactNode;
    activeStore?: StoreDefinition;
    countryName?: string;
    countryId?: string | number;
    user?: {
        id: string;
        phone?: string;
        ship_address?: any;
    };
    current?: any;
    send?: any;
    cart?: any;
    orderNumber?: string;
    unavailableSku: Array<string>;
    orderMode: string;
    redirectionFlow?: any;
    locationQuery?: any;
    userAddresses?: Array<any>;
    userPayments?: Array<any>;
    storeParam: string;
    locationSearch: string;
    registerAddToCart: () => void;
    registerRemoveFromCart: () => void;
    registerCartContentChange: () => void;
    newCheckoutFlag: { variantKey: string };
    cartLoaded: boolean;
    newCheckoutFlagData: {
        variantKey: string;
    };
    getNewCheckoutFeatureFlag: () => object;
    phoneVerificationConfirmError?: string;
    phoneVerificationPending: PhoneVerificationPendingType;
    formSubmitting: boolean;
    previewVoucher?: {
        code?: string;
        description?: string;
        discount?: {
            type?: string;
            amountOff?: number;
            percentOff?: number;
        };
    };
    customizeColorFlag: boolean;
    customizePlanFlag: boolean;
    linkProductsInCartFlag: boolean;
}

const defaultValue = {
    orderMode: 'FLEX',
    unavailableSku: [],
    userAddresses: [],
    storeParam: 'de',
};

export const OrderContext = createContext<OrderContextProps>(defaultValue);

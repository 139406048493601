import { ApolloClient, InMemoryCache, NormalizedCacheObject, ApolloLink } from '@apollo/client';

import httpMiddleware from '../links/http';
import errorMiddleware from '../links/errorMiddleware';
import headersMiddleware from '../links/headersMiddleware';
import refreshTokenMiddleware from '../links/refreshTokenMiddleware';
import createDefaultContextMiddleware from '../links/defaultContextMiddleware';

import introspectionQueryResultData from '../codegen/fragmentMatcherIntrospection';

import typeDefs from '../types/typeDefs.graphql';
import typePolicies from '../types/typePolicies';

const { possibleTypes } = introspectionQueryResultData;

function createClient(cookiesManager: any): ApolloClient<NormalizedCacheObject> {
    const client = new ApolloClient({
        cache: new InMemoryCache({
            possibleTypes,
            typePolicies,
        }),
        link: ApolloLink.from([
            createDefaultContextMiddleware(cookiesManager),
            headersMiddleware,
            refreshTokenMiddleware,
            errorMiddleware,
            // NOTE: HTTP Middleware should be in the end of the list
            httpMiddleware,
        ]),
        defaultOptions: {
            query: { fetchPolicy: 'no-cache', errorPolicy: 'all' },
            mutate: { fetchPolicy: 'no-cache', errorPolicy: 'all' },
            watchQuery: { fetchPolicy: 'no-cache', errorPolicy: 'all' },
        },
        typeDefs,
    });

    return client;
}

export default createClient;

/* eslint-disable */
import {
    GET_PRODUCT_BY_ID_SUCCESS,
} from './../actionTypes';

const defaultState = {
    data: {},
};

export default function (state = defaultState, action) {
  switch (action.type) {

    case GET_PRODUCT_BY_ID_SUCCESS: {
        const product = action.data;
        return {
            ...state,
            data: {
                [product.id]: product,
            },
        };
    }

    default:
      return state;
  }
}

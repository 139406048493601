import CookiesManager from 'ggApp/utils/CookiesManager';
import { defaultLanguageByCountry } from 'ggApp/modules/store/constants';

const ONE_YEAR_EXPIRATION_TIME_IN_SECONDS = 365 * 24 * 60 * 60;

export function setLocaleCookie(language, cookiesManager = new CookiesManager()) {
    cookiesManager.set('locale', language, {
        maxAge: ONE_YEAR_EXPIRATION_TIME_IN_SECONDS,
    });
}

export const getStoreDefaultLanguage = (storeCode) => {
    const code = storeCode.toUpperCase();
    return defaultLanguageByCountry[code];
};

export const isUSStore = (activeStore) =>
    // eslint-disable-next-line camelcase
    activeStore?.code === 'us' || activeStore?.country_code === 'US';

export const isUSBusinessStoreCode = (storeCode) => storeCode === 'business_us';

/* eslint-disable */
/** Extracts the dependecies from the list of components
 * @argument {string} type – field name to look for in the component
 */
function discoverComponentDependencies(type) {
    /** Reducer that constucts an array of all dependencies
     * @argument {array} components - a list of React components matched for the current route
     */
    return function componentDependenciesReducer(components) {
        return components.reduce(
            (prev, current) => (current ? (current[type] || []).concat(prev) : prev), [],
        );
    };
}

const criticalDependenciesReducer = discoverComponentDependencies('needsBeforeOthers');
const normalDependenciesReducer = discoverComponentDependencies('needs');

const proceed = (error) => ({
    error,
});

const preloadComponents = (components) => components.map(
    (component) => new Promise((resolve, reject) => {
        if (component && component.preload && typeof component.preload === 'function') {
            return component.preload()
                .then(({ default: loadedComponent }) => {
                    resolve(loadedComponent);
                })
                .catch((error) => {
                    console.error('can not load module', error);
                    reject(error);
                });
        }
        return resolve(component);
    }),
);

const wrapNeed = (dispatch, params, location, req) => (need) => {
    const dispatchResult = dispatch(need(params, location, req));
    const thenable = dispatchResult && dispatchResult.then;

    if (thenable) {
        return dispatchResult.catch(proceed);
    }

    return dispatchResult;
};

export default function fetchComponentData(dispatch, routeComponents, params, location, req) {
    return Promise.all(preloadComponents(routeComponents)).then((components) => {
        const needs = normalDependenciesReducer(components);
        const needsBeforeOthers = criticalDependenciesReducer(components);
        // Launch critical dependencies promises (if any)
        const importantPromises = needsBeforeOthers.map(wrapNeed(dispatch, params, location, req));
        return Promise.all(importantPromises)
            .then((resolvedImportantPromises) => {
                const promises = needs
                    // Launch normal dependencies promises (if any)
                    .map(wrapNeed(dispatch, params, location, req))
                // Provide the conusmer with results of all dependencies promises
                    .concat(resolvedImportantPromises);
                return Promise.all(promises);
            });
    });
}

/* eslint-disable */
import React from 'react';
import { darkTheme } from 'ggApp/shared/themes';
import { ThemeProvider } from 'styled-components';
import {
    Overlay,
    StandardLogo,
    Banner,
    Title,
    SubTitle,
    ContinueButton,
    Divider,
    BrowserBlock,
    BrowserHead,
    BrowserLogo,
    BrowserName,
    DownloadButton,
    Description,
    Content,
} from './styles';

function UnsupportedBrowserBanner({
    children,
    browserList = [],
    title,
    subtitle,
    browserButtonText,
    continueText,
    visible,
    divider,
    continueOnClick,
}) {
    return (
        <>
            {
                visible && <ThemeProvider theme={darkTheme}>
                    <Overlay visible={visible}>
                        <StandardLogo color="rgba(255, 255, 255, 0.5)" fillLogo />
                        <Banner>
                            <Title>{title}</Title>
                            <SubTitle>
                                {subtitle}
                                <ContinueButton
                                    onClick={continueOnClick}
                                >
                                    {continueText}
                                </ContinueButton>
                            </SubTitle>
                            <Divider>{divider}</Divider>
                            {browserList.map(({
                                logo, name, link, description,
                            }) => (
                                <BrowserBlock key={name}>
                                    <BrowserHead>
                                        <BrowserLogo src={logo} />
                                        <BrowserName>{name}</BrowserName>
                                        <DownloadButton to={link} target="_blank">
                                            {browserButtonText}
                                        </DownloadButton>
                                    </BrowserHead>
                                    <Description>{description}</Description>
                                </BrowserBlock>
                            ))}
                        </Banner>
                    </Overlay>
                </ThemeProvider>
            }
            <Content visible={!visible}>
                {children}
            </Content>
        </>
    );
}

export default UnsupportedBrowserBanner;

const defaultState = null;

export default function(state = defaultState, action: { type: string; data: string }) {
    switch (action.type) {
        case 'STORE_ERROR':
            return action.data;
        case 'RESET_ERROR':
            return null;
        default:
            return state;
    }
}

import styled from 'styled-components';

import { screens } from 'ggApp/utils/mixins/screens';

import ProductCarouselWidgetWithEvents from './components/ProductCarousel';
import { CategoryWidgetWithEvents } from './components/CategoryWidget';

export const ProductCarousel = styled(ProductCarouselWidgetWithEvents)``;

export const CategoryWidget = styled(CategoryWidgetWithEvents)`
    grid-column: auto / span 6;

    @media (${screens.phone}) and (${screens.tabletMax}) {
        grid-column: 1 / -1;
    }
`;

export const SubHeadline = styled.h2`
    ${({ theme }) => theme.typography.Headline};
    margin-bottom: ${({ theme }) => theme.spacers.L};
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.Foreground};
    font-size: 32px;
    line-height: 1.3;

    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
        ${theme.typography.Subheadline};
        padding: 0 ${theme.spacers.L};
    `};
`;

import React, { useReducer, useMemo } from 'react';

import { ThemeProvider } from 'ggApp/shared/components/ThemeContext';
import { darkTheme } from 'ggApp/shared/themes';

import { internalToolsReducer, initialState } from './reducer';

export const internalToolsContext = React.createContext();
const { Provider } = internalToolsContext;

export const InternalToolsContextProvider = ({ children }) => {
    const [internalToolsState, dispatch] = useReducer(internalToolsReducer, {
        ...initialState,
    });

    const { highlightLokalise, darkModePreview } = internalToolsState;

    const data = useMemo(
        () => ({
            highlightLokalise,
            toggleHighlightLokalise: () => dispatch({ type: 'toggleHighlightLokalise' }),
            darkModePreview,
            toggleDarkModePreview: () => dispatch({ type: 'toggleDarkModePreview' }),
        }),
        [highlightLokalise, darkModePreview],
    );

    if (darkModePreview === true) {
        return (
            <Provider value={data}>
                <ThemeProvider theme={darkTheme}>{children}</ThemeProvider>
            </Provider>
        );
    }
    return <Provider value={data}>{children}</Provider>;
};

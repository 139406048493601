export enum TransitionDirection {
    backward = 'backward',
    forward = 'forward',
}

export enum GroupState {
    completed = 'completed',
    current = 'current',
    upcoming = 'upcoming',
}

export enum HookType {
    enter = 'enter',
    close = 'close',
    leave = 'leave',
    skip = 'skip',
    cancel = 'cancel',
    success = 'success',
    flowChange = 'flowChange',
}

import thunkMiddleware from 'redux-thunk';
import addToCartEventMiddleware from './addToCartEvent';
import promise from './promise';
import logoutMiddleware from './logout';
import loginMiddleware from './login';
import removeFromCartEventMiddleware from './removeFromCartEvent';
import checkoutEventMiddleware from './checkoutEvent';
import checkoutOptionsEventMiddleware from './checkoutOptionsEvent';
import inWebViewAPIMiddleware from './inWebViewAPI';
import authMiddleware from './auth';
import luxMiddleware from './lux';
import datadogAddUserIDMiddleware from './datadogAddUserID';
import analyticsPageView from './analyticsPageView';

export default [
    promise,
    thunkMiddleware,
    addToCartEventMiddleware,
    logoutMiddleware,
    loginMiddleware,
    removeFromCartEventMiddleware,
    checkoutEventMiddleware,
    checkoutOptionsEventMiddleware,
    inWebViewAPIMiddleware,
    authMiddleware,
    luxMiddleware,
    datadogAddUserIDMiddleware,
    analyticsPageView,
];

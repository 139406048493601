/* eslint-disable */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import {
    Root,
    DivisionLabel,
} from './styles';


export default class DivisionLine extends PureComponent {
    render() {
        const {
            title,
            className,
            tag,
        } = this.props;

        return (
            <Root className={className}>
                <DivisionLabel tag={tag}>
                    {title}
                </DivisionLabel>
            </Root>
        );
    }
}

DivisionLine.propTypes = {
    className: PropTypes.string,
    title: PropTypes.node,
    tag: PropTypes.string,
};

DivisionLine.defaultProps = {
    className: undefined,
    title: null,
    tag: 'label',
};

import { INIT_PENDING, INIT_DONE } from '../actionTypes';

const defaultState = {
    pending: false,
    done: false,
};

export default function(state = defaultState, action) {
    switch (action.type) {
        case INIT_PENDING:
            return {
                ...state,
                pending: true,
            };

        case INIT_DONE:
            return {
                ...state,
                pending: false,
                done: true,
            };

        default:
            return state;
    }
}

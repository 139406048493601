/* eslint-disable */
import { combineReducers, } from 'redux';
import list from './list';
import active from './active';
import phonePrefixes from './phonePrefixes';

export default combineReducers({
  active,
  phonePrefixes,
  list,
});
/* eslint-disable */
/**
 * Debounce
 * @param {function} fn Function to debounce
 * @param {number} timeout Timeout interval
 * @example
 * debounce(function() { console.log('debounced') }, 300);
 * @returns debounced function
 */
export default function debounce(fn, timeout) {
    let timerId;
    return function (...args) {
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            fn(...args);
            timerId = null;
        }, timeout);
    }
}

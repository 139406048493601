/* eslint-disable */
const defaultState = {
  billing: null,
  shipping: null,
};

export default function (state = defaultState, action) {
  switch (action.type) {

    case 'SET_ACTIVE_ADDRESS':
      return Object.assign({}, state, {
        [action.addressType.toLowerCase()]: action.addressId,
      });


    default:
      return state;
  }
}

/* eslint-disable */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

const withOnClickEvent = (BaseComponent) => {
    const displayName = BaseComponent.displayName || BaseComponent.name || 'Component';

    return class WithOnClickEvent extends PureComponent {
        static propTypes = {
            onClickCallback: PropTypes.func,
        };

        static defaultProps = {
            onClickCallback: () => void (0),
        };

        static displayName = `withOnClickEvent(${displayName})`;

        enhanceOnClick = () => {
            const { onClickCallback, onClick } = this.props;
            if (typeof onClickCallback === 'function') {
                onClickCallback();
            }
            if (typeof onClick === 'function') {
                onClick();
            }
        };

        render() {
            return (
                <BaseComponent {...this.props} onClick={this.enhanceOnClick} />
            );
        }
    };
};

export default withOnClickEvent;

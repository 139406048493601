/* eslint-disable */
import slugEditor from 'slugg';
import {
  STATUS_IN_PROGRESS,
} from './constants';

export const actionsByStatus = status => {
  const
    slug = slugEditor(status);

  switch (slug) {
    case STATUS_IN_PROGRESS:
      return ({
        actions: {
          cancel: true,
        },
      });

    default:
      return ({
        actions: {},
      });
  }
}

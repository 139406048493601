/* eslint-disable */
import { createSelector } from 'reselect';
import { LOADING_STATUS, MODULE_NAME } from './constants';

export const moduleState = (state) => state[MODULE_NAME] || {};

export const requestStatusSelector = createSelector(
    moduleState,
    ({ status }) => status,
);

export const loadingStatus = createSelector(
    requestStatusSelector,
    (status) => status === LOADING_STATUS,
);

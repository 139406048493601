// @flow
// $FlowFixMe fixing import because flow can not import this module

// Action types
import {
    INIT_COOKIE_POLICY_SETTINGS,
    UPDATE_COOKIE_POLICY_SETTINGS,
    CONSENT_COOKIE_POLICY,
} from '../constants/actionTypes';

// Flow types
import type { ReduxStorePayload, ReduxStoreState } from '../../utils/flowTypes';

// Utils
import {
    adaptCookiePayloadToReduxStorePayload,
    adaptReduxStorePayloadToCookiePayload,
} from '../../utils/adapters';

import { getCookieConsentValue, setCookieConsentValue } from '../../utils/cookieStore';

type InitCookiePolicySettingsAction = {
    type: typeof INIT_COOKIE_POLICY_SETTINGS,
} & ReduxStoreState;

type UpdateCookiePolicySettingsAction = {
    type: typeof UPDATE_COOKIE_POLICY_SETTINGS,
} & ReduxStoreState;

type ConsentCookiePolicyAction = {
    type: typeof CONSENT_COOKIE_POLICY,
} & ReduxStoreState;

export type Action =
    | InitCookiePolicySettingsAction
    | UpdateCookiePolicySettingsAction
    | ConsentCookiePolicyAction;

/**
 * saveCookieSettingsInCookiesAndGTM
 * This method save cookie consent values in cookies
 * and send these values to Google Tag Manager
 * @param {ReduxStorePayload} reduxStorePayload
 */
function saveCookieSettingsInCookiesAndGTM(reduxStorePayload: ReduxStorePayload): void {
    const cookiePayload = adaptReduxStorePayloadToCookiePayload(reduxStorePayload);

    setCookieConsentValue(cookiePayload);
}

/**
 * initCookiePolicySettings
 * This action gets cookie consent values from cookies
 * and initializes redux store with these values.
 * Also it push `gtmPayload` with these value to GTM Layer.
 * @returns {InitCookiePolicySettingsAction}
 */
export function initCookiePolicySettings(): InitCookiePolicySettingsAction {
    const cookiePayload = getCookieConsentValue();

    if (!cookiePayload) {
        return { type: INIT_COOKIE_POLICY_SETTINGS, hasConsent: false };
    }

    const reduxStorePayload = adaptCookiePayloadToReduxStorePayload(cookiePayload);

    return {
        type: INIT_COOKIE_POLICY_SETTINGS,
        hasConsent: true,
        ...reduxStorePayload,
    };
}

/**
 * consentCookiePolicy
 * This action calls when user consent with Grover Cookie Policy
 * It sets all cookie consent values in `true`
 * and saves them in cookies and GTM
 * @returns {ConsentCookiePolicyAction}
 */
export function consentCookiePolicy(): ConsentCookiePolicyAction {
    const cookieExpiry = new Date();
    cookieExpiry.setFullYear(cookieExpiry.getFullYear() + 1);
    const reduxStorePayload: ReduxStorePayload = {
        preferenceCookieConsent: true,
        statisticsCookieConsent: true,
        marketingCookieConsent: true,
        consentTimestamp: Date.now(),
        consentExpiration: cookieExpiry,
    };

    saveCookieSettingsInCookiesAndGTM(reduxStorePayload);

    return {
        type: CONSENT_COOKIE_POLICY,
        hasConsent: true,
        ...reduxStorePayload,
    };
}

/**
 * updateCookiePolicySettings
 * This action saves cookie consent values in Redux Store
 * saves them in cookies and GTM
 * @param {ReduxStorePayload} reduxStorePayload
 * @returns {UpdateCookiePolicySettingsAction}
 */
export function updateCookiePolicySettings(
    reduxStorePayload: ReduxStorePayload,
): UpdateCookiePolicySettingsAction {
    saveCookieSettingsInCookiesAndGTM(reduxStorePayload);

    return {
        type: UPDATE_COOKIE_POLICY_SETTINGS,
        hasConsent: true,
        ...reduxStorePayload,
    };
}

import React from 'react';
import PropTypes from 'prop-types';
import { sendAnalyticsEvent } from '@devsbb/analytics-client';
import { Pointer } from '@getgrover/ui';
import { ForYouOutlineIcon } from 'ggApp/shared/icons/BaseLayout/ForYou';
import { YourTechOutlineIcon } from 'ggApp/shared/icons/BaseLayout/YourTech';
import AccountOutlineIcon from 'ggApp/shared/icons/BaseLayout/Account';
import { Translator } from 'ggApp/modules/translator/components';
import useForYouPointer from 'ggApp/shared/hooks/useForYouPointer';
import useYourTechPointer from 'ggApp/shared/hooks/useYourTechPointer';
import { MobileAccountMenu } from 'ggApp/shared/common/AccountMenu';
import CardIcon from 'ggApp/shared/icons/BaseLayout/Card';
import useYourCardTab from 'ggApp/shared/hooks/useYourCardTab';

import {
    MobileTaskBarContainer,
    MobileTaskBarWrap,
    Title,
    AccountItem,
    StyledNavigationLink,
} from './styles';

function Mobile({
    location: { pathname = '', query },
    referenceFromParent,
    shouldShowMenu,
    handleToggleMenu,
    onCloseMenu,
    inWebView,
    company,
    user,
    storeCode,
    storeId,
    isMobileUser,
}) {
    const isActive = (route) => Boolean(pathname.match(route));
    const isForYou = isActive('/for-you');
    const isYourTech = isActive('/your-tech');
    const isCardTabActive = isActive('/card');
    const isRootYourTech = isYourTech && Object.keys(query).length === 0;
    const isB2BStore = storeCode.includes('business');

    const shouldShowForYouPointer = useForYouPointer({
        isYourTech: isRootYourTech,
        shouldShowMenu,
    });
    const shouldShowYourTechPointer = useYourTechPointer({ pathname, user, company });
    const shouldShowYourCardTab = useYourCardTab({ storeCode, isMobileUser, user });

    const forYouLink = (
        <StyledNavigationLink key="for-you" to="/for-you" type="catalog-link" isActive={isForYou}>
            <ForYouOutlineIcon />
            <Title>
                <Translator tk="TASKBAR_FOR_YOU" />
            </Title>
        </StyledNavigationLink>
    );

    const handleYourTechOnClick = () => {
        sendAnalyticsEvent('My Tech Clicked', { store_id: storeId });
    };

    const yourTechLink = (
        <StyledNavigationLink
            onClick={handleYourTechOnClick}
            key="your-tech"
            to="/your-tech"
            type="yourTech"
            isActive={isYourTech}
        >
            <YourTechOutlineIcon />
            <Title>
                <Translator tk={isB2BStore ? 'TASKBAR_DASHBOARD' : 'TASKBAR_YOUR_TECH'} />
            </Title>
        </StyledNavigationLink>
    );

    const yourCardLink = (
        <StyledNavigationLink key="card" to="/card" type="catalog-link" isActive={isCardTabActive}>
            <CardIcon />
            <Title>
                <Translator tk="TASKBAR_CARD" />
            </Title>
        </StyledNavigationLink>
    );

    if (!isB2BStore) {
        return null;
    }

    return (
        <MobileTaskBarContainer ref={referenceFromParent} inWebView={inWebView}>
            <MobileTaskBarWrap inWebView={inWebView}>
                {shouldShowForYouPointer && isForYou ? (
                    <Pointer text={<Translator tk="FOR_YOU_POINTER" />}>{forYouLink}</Pointer>
                ) : (
                    forYouLink
                )}
                {shouldShowYourCardTab && yourCardLink}
                {shouldShowYourTechPointer ? (
                    <Pointer text={<Translator tk="ORDER_CONFIRMATION_YOUR_TECH_POINTER" />}>
                        {yourTechLink}
                    </Pointer>
                ) : (
                    yourTechLink
                )}
                <AccountItem onClick={handleToggleMenu} tabIndex="0" role="menuitem">
                    <AccountOutlineIcon />
                    <Title>
                        <Translator tk="TASKBAR_ACCOUNT" />
                    </Title>
                </AccountItem>
            </MobileTaskBarWrap>

            <MobileAccountMenu
                isOpen={shouldShowMenu}
                setIsOpen={handleToggleMenu}
                onCloseMenu={onCloseMenu}
            />
        </MobileTaskBarContainer>
    );
}

Mobile.propTypes = {
    shouldShowMenu: PropTypes.bool,
    handleToggleMenu: PropTypes.func,
    onCloseMenu: PropTypes.func,
    location: PropTypes.shape({
        href: PropTypes.string,
        origin: PropTypes.string,
        protocol: PropTypes.string,
        host: PropTypes.string,
        hostname: PropTypes.string,
        port: PropTypes.string,
        pathname: PropTypes.string,
        search: PropTypes.string,
        hash: PropTypes.string,
        assign: PropTypes.func,
        replace: PropTypes.func,
        reload: PropTypes.func,
        toString: PropTypes.func,
    }),
    referenceFromParent: PropTypes.func,
    inWebView: PropTypes.bool,
};

Mobile.defaultProps = {
    location: {},
    shouldShowMenu: false,
    handleToggleMenu: () => {},
    onCloseMenu: () => {},
    referenceFromParent: () => {},
    inWebView: false,
};

export default Mobile;

import { pushDataLayer } from 'ggApp/modules/tracking/actions';
import { ANALYTIC_EVENTS } from 'ggApp/modules/gaAnalytics/constants';

export const triggerUnsupportedBrowserBannerEvent = (active) => {
    if (!active) {
        return;
    }
    pushDataLayer({
        eventCategory: 'internal_test',
        eventAction: 'unsupported_banner',
        eventLabel: 'active',
        event: ANALYTIC_EVENTS.gtmEvent,
    });
};

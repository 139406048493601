import { CountdownTimerType } from './types';

export const filterCountdowns = ({
    countdowns,
    activeStoreCode,
}: {
    countdowns?: CountdownTimerType[] | undefined;
    activeStoreCode?: string;
}): CountdownTimerType | null => {
    if (!countdowns || !activeStoreCode) {
        return null;
    }
    const availableCountdowns = countdowns.filter(
        (countdown) => countdown.storeCodes.includes(activeStoreCode) && countdown.isActive,
    );
    return availableCountdowns[0];
};

export const calculateTime = (endTime: string) => {
    const now = new Date().getTime();
    const end = new Date(endTime).getTime();
    const distance = end - now;
    let timeLeft = {} as Record<string, number>;
    if (distance > 0) {
        timeLeft = {
            days: Math.floor(distance / (1000 * 60 * 60 * 24)),
            hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((distance % (1000 * 60)) / 1000),
        };
    }
    return timeLeft;
};

export const displayTime = (number: number | undefined): string => {
    if (!number) {
        return '00';
    }
    if (number >= 10) {
        return String(number);
    }
    return `0${number}`;
};

/* eslint-disable */
import {
  SUBMIT_PAYMENT,
  SUBMIT_PAYMENT_SUCCESS,
  SUBMIT_PAYMENT_ERROR,
  RESET_SUBMITTED_PAYMENT_ERROR,
} from './../actionTypes';

const defaultState = {
  loading: null,
  error: {},
  data: {},
}

export default function (state = defaultState, action) {
  switch (action.type) {

    case SUBMIT_PAYMENT:
      return Object.assign({}, state, {
        loading: true,
        error: {},
      })

    case SUBMIT_PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.data,
      })

    case SUBMIT_PAYMENT_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      })

    case RESET_SUBMITTED_PAYMENT_ERROR:
      return Object.assign({}, state, {
        error: {},
      })

    default:
      return state;
  }
}

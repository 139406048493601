/* eslint-disable no-shadow */
import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { user as userSelector } from 'ggApp/modules/auth/selectors';
import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import ggAuth from 'ggApp/modules/auth';

import { bindActionCreators } from 'redux';
import { redirectus } from 'ggApp/modules/router/actions';
import B2CRedirection from './B2CRedirection';

const enhance = compose(
    connect(
        createStructuredSelector({
            user: userSelector,
            store: activeStoreSelector,
        }),
        (dispatch) => ({
            redirectus: bindActionCreators(redirectus, dispatch),
            userActions: bindActionCreators(ggAuth.actions, dispatch),
        }),
    ),
    mapProps(({ redirectus, user, store, userActions, ...rest }) => {
        const redirectionLink = `/${store.country_code.toLowerCase()}-${store.activeLanguage}`;

        return {
            ...rest,
            transitionToB2C: async () => {
                await userActions.logout();
                redirectus(redirectionLink, true);
            },
        };
    }),
);

export default enhance(B2CRedirection);

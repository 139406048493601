import React from 'react';
import PropTypes from 'prop-types';

import DisplayIf from 'ggApp/shared/components/viewport';
import { PureTranslator } from 'ggApp/modules/translator/components';
import CardMapper from './CardMapper';
import Carousel from './Carousel';

import { Wrap, Title, Cta, Grid } from './styled';

const UspCardsWidget = ({ title, cta: { label, url, external }, className, steps, onClickCTA }) => (
    <Wrap className={className}>
        {title && <Title>{title}</Title>}
        <DisplayIf tablet desktop largeDesktop widescreen>
            <Grid>
                <CardMapper steps={steps} />
            </Grid>
        </DisplayIf>
        <DisplayIf mobile>
            <Carousel carouselWidth={steps.length} cardWidth={280}>
                <CardMapper steps={steps} carousel />
            </Carousel>
        </DisplayIf>
        {url && (
            <Cta
                target="_self"
                linkTo={url}
                text={<PureTranslator tk={label} />}
                onClick={onClickCTA}
                external={external}
            />
        )}
    </Wrap>
);

UspCardsWidget.propTypes = {
    title: PropTypes.string,
    cta: PropTypes.shape({
        internal: PropTypes.bool,
        label: PropTypes.string,
        url: PropTypes.string,
    }),
    className: PropTypes.string,
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
        }),
    ),
    onClickCTA: PropTypes.func,
};
UspCardsWidget.defaultProps = {
    title: '',
    cta: {},
    className: '',
    steps: [],
    onClickCTA: () => null,
};

export default UspCardsWidget;

import { sendStructEvent } from '../../api';

interface PayloadModel {
    eventLabel: string;
    eventProperty: {
        orderID: string;
        userID: number;
        store: number;
        productData: {
            name: string;
            brand: string;
            sub_category: string;
            productSKU: string;
            productVariant: string;
            subscriptionLength: number;
            price: string | null;
            non_discountedPrice: string | null;
            discountAmount: string | null;
            discountPercentage: string | null;
            deliveryTime: string;
            imageUrl: string;
            mixable?: boolean;
            interacting_with_mix?: boolean;
        };
        mixData?: {
            mix_price: number;
            mix_cart_count: number;
        };
    };
}

export const sendAddToCartEvent = (payload: PayloadModel): void => {
    const { eventProperty, eventLabel } = payload;

    sendStructEvent({
        eventCategory: 'checkout',
        eventAction: 'addToCart',
        eventLabel,
        eventProperty,
    });
};

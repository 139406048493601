import styled, { css } from 'styled-components';

export const ShowPrice = styled.div`
    ${({ inline }) => inline && css`
        display: inline;
    `}

    * {
        display: inline;
    }
`;

import { CartPaymentMethodInput, Address } from 'ggApp/types/graphql';

export interface PaymentStub extends CartPaymentMethodInput {
    type: string;
}

export interface PaymentMethodItem {
    paymentMethodType: string;
    paymentMethodId: number;
    billingAccountId: string;
}

export type OrderMode = 'MIX' | 'FLEX';

export type Operation<R> = (context: CheckoutMachine, event?: R) => Promise<any>;

export type APISelection<R> = {
    [P in keyof Operations<R>]?: Operations<R>[P];
};

interface Operations<R> {
    get: Operation<R>;
    add: Operation<R>;
    delete: Operation<R>;
    submit: Operation<R>;
    update: Operation<R>;
    updateAddresses: Operation<R>;
    updatePayment: Operation<R>;
}

export interface ApiAccessor<T> {
    entity: keyof CheckoutAPI;
    operation: keyof Operations<T>;
    payload: T;
}

export interface LineItemInput {
    id?: number;
    variant: any;
    product: any;
    planId?: number;
    quantity: number;
    minimumMonths: number;
}

export interface CheckoutAPI {
    paymentMethod: APISelection<PaymentStub>;
    shippingAddress: APISelection<Address>;
    homeAddress: APISelection<Address>;
    lineItem: APISelection<LineItemInput>;
    order: APISelection<any>;
}

export interface CartItemModel {
    discountTotal?: number;
    itemTotal?: number;
    totalPrice?: number;
    shipmentPrice?: number;
    guestToken?: string;
    orderNumber?: string;
    storeCode?: string;
    items?: any[];
    homeAddress?: { id: number; address1: string; address2: string; city: string; country: string };
    voucher?: { name: string; discount: string; discount_cents: number };
    paymentMethod?: { discount_cents: number; discount: string; name: string };
}

export interface StoreDefinition {
    code: string;
    default_currency: string;
    type: string;
    store_id: number;
    country_code?: string;
    country_id?: string | number;
    activeLanguage: string;
}

export interface CheckoutMachine {
    cart: CartItemModel;
    orderNumber?: string;
    orderMode: OrderMode;
    error?: boolean;
    success?: boolean;
    activeStore: StoreDefinition;
    user: { id: number; email: string };
    isCheckoutActive: boolean;
    steps: string[];
    addressError?: any;
    voucherError?: any;
    unavailableSku: Array<string>;
    cartLoaded: boolean;
    userPayments: Array<any>;
    redirectionFlow?: object;
    isOverlayActive: boolean;
    userDataFetched: boolean;
    newCheckoutFlow: string;
    userAddresses: Array<object>;
    newCheckoutFlag: object;
    hasPrefilledOrder: boolean;
    phoneVerificationConfirmError?: string;
    phoneVerificationPending?: {
        requestPayload?: string;
        phoneLastDigits?: string;
    };
    formSubmitting: boolean;
    customizeColorFlag?: boolean;
    customizePlanFlag?: boolean;
    linkProductsInCartFlag?: boolean;
}

export const withAddressRefresh = (apiAddressMethod: any) => (
    context: CheckoutMachine,
    event: { data: any[] },
) => {
    const [address] = event.data;
    return apiAddressMethod(context, { address });
};

export const withPaymentRefresh = (
    getPayments: () => Promise<any[]>,
    updatePaymentInOrder: any,
) => (context: CheckoutMachine) => {
    const { cart, orderMode } = context;
    return getPayments().then((payments) => {
        if (
            !cart?.orderNumber ||
            cart?.paymentMethod ||
            !Array.isArray(payments) ||
            !payments.length
        ) {
            return payments;
        }
        let paymentMethod;

        if (orderMode === 'FLEX') {
            [paymentMethod] = payments;
        } else {
            paymentMethod = payments.find((p) => p.billing_account_id);
            if (!paymentMethod) return payments;
        }

        return updatePaymentInOrder(context, {
            paymentMethodType: paymentMethod?.type,
            paymentMethodId: paymentMethod?.id,
            billingAccountId: paymentMethod?.billing_account_id,
        }).then(() => payments);
    });
};

export const removeLineItems = (removeLineItem: any) => (
    context: CheckoutMachine,
    { items, meta }: { items: LineItemInput[]; meta?: { attempt_reference: string } },
) => {
    if (!items?.length) return Promise.resolve();
    const iteratorForRemoving = (list: LineItemInput[]) => {
        const item = list.shift();
        return removeLineItem(context, { item })
            .then((result: { order: CartItemModel }) => {
                if (list.length) {
                    return iteratorForRemoving(list);
                }
                return meta ? { meta, ...result } : result;
            })
            .catch((e: any) => {
                console.error(e);
                throw new Error('Products were not removed!');
            });
    };

    return iteratorForRemoving(items);
};

export const updateAddressesFromContext = (
    updateOrderShippingAddress: any,
    updateOrderHomeAddress: any,
) => async (context: any) => {
    const { shippingAddressId, homeAddressId, orderBillAddress, orderShipAddress } = context.cart;

    try {
        if (shippingAddressId !== null && !orderShipAddress) {
            await updateOrderShippingAddress(context, { address: { id: shippingAddressId } });
        }

        if (homeAddressId !== null && !orderBillAddress) {
            await updateOrderHomeAddress(context, { address: { id: homeAddressId } });
        }
        return Promise.resolve();
    } catch (e) {
        return Promise.resolve();
    }
};

export const updatePaymentFromContext = (updateOrderPaymentMethod: any) => (context: any) => {
    const { paymentMethod, orderPaymentMethod } = context.cart;
    if (!orderPaymentMethod && paymentMethod !== null) {
        return updateOrderPaymentMethod(context, {
            paymentMethodType: paymentMethod?.type,
            paymentMethodId: paymentMethod?.id,
            billingAccountId: paymentMethod?.billing_account_id,
        });
    }
    return Promise.resolve();
};

export const removeProductsAndSubmitVoucher = (
    remove: (context: CheckoutMachine, data: { items: LineItemInput[] }) => any,
    submit: (context: CheckoutMachine, event: { voucher: string }) => Promise<CartItemModel>,
) => async (
    context: CheckoutMachine,
    data: { unavailableItems: LineItemInput[]; voucher: string },
) => {
    const { unavailableItems, voucher } = data;
    await remove(context, { items: unavailableItems });
    return submit(context, { voucher });
};

export const withAutoVoucherRedemption = (
    orderMethod: Operation<LineItemInput> | Operation<any>,
    voucherApply: Operation<any>,
) => async (context: CheckoutMachine, event: any): Promise<CartItemModel> => {
    let orderResponse;
    const { voucher } = event;

    try {
        orderResponse = await orderMethod(context, event);
        if (!voucher || voucher === orderResponse?.order?.voucher.name) {
            return orderResponse;
        }
    } catch (e) {
        return Promise.reject(e);
    }

    try {
        const { number } = orderResponse?.order ?? {};
        const voucherResponse = await voucherApply(
            { ...context, cart: orderResponse?.order ?? {}, orderNumber: number },
            { voucher },
        );
        return voucherResponse;
    } catch (e) {
        return orderResponse;
    }
};

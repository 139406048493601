import { ErrorLink } from '@apollo/client/link/error';

const untrackedErrorStatuses = new Set([400, 401, 404]);
const untrackedGraphqlErrors = new Set(['UNAUTHENTICATED']);

const ErrorMiddleware = new ErrorLink(({ graphQLErrors, networkError, operation }) => {
    if (networkError && !untrackedErrorStatuses.has(networkError?.status)) {
        console.error(`[Network error]: ${networkError}`);
    }

    if (graphQLErrors) {
        graphQLErrors
            .filter((error) => !untrackedGraphqlErrors.has(error.extensions?.code))
            .forEach((gqlError) => {
                const { message, locations, path } = gqlError;
                console.error(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                );
            });
    }
});

export default ErrorMiddleware;

import { useState } from 'react';
import useMount from 'ggApp/shared/hooks/useMount';
import { fetchScore, DEFAULT_SCORE } from 'ggApp/shared/components/ReviewIO/fetchScore';

export const useTrustedScore = () => {
    const [score, setScore] = useState(DEFAULT_SCORE);

    useMount(() => {
        fetchScore().then(setScore);
    });

    return {
        trustedScore: score,
    };
};

import { createContext } from 'react';

import { defaultState as defaultLoadersState } from './state/loaders/reducer';
import { SubscriptionSummaryState } from './state/summary';
import { SubscriptionsFilterState } from './state/filter';
import { SubscriptionLoadersState } from './state/loaders';
import { SubscriptionVaultState } from './state/vault';
import { SubscriptionSearchState } from './state/search';
import { SubscriptionDetailsCardState } from './state/detailsCard';
import { NotificationState } from './state/notification';
import { PaymentsSheetState } from './state/payments';
import { defaultState as defaultNotificationState } from './state/notification/reducer';
import { defaultState as defaultPaymentsSheetState } from './state/payments/reducer';
import { defaultState as defaultVaultState } from './state/vault/reducer';
import { SubscriptionDataState } from './state/subscriptionData';

export type DashboardContextType = {
    paymentsSheetState: PaymentsSheetState;
    summaryState: SubscriptionSummaryState;
    filterState: SubscriptionsFilterState;
    loadersState: SubscriptionLoadersState;
    vaultState: SubscriptionVaultState;
    searchState: SubscriptionSearchState;
    detailsCardState: SubscriptionDetailsCardState;
    notificationSubSwitchingState: NotificationState;
    subscriptionDataState: SubscriptionDataState;
};

const defaultState: DashboardContextType = {
    paymentsSheetState: {
        state: defaultPaymentsSheetState,
        actions: {
            setShouldShow: (): void => {},
        },
    },
    loadersState: {
        loaders: defaultLoadersState,
        actions: {
            setLoader: (): void => {},
        },
    },
    summaryState: {
        subscriptionSummary: [],
        mixSummary: null,
        actions: {
            setSubscriptionSummary: (): void => {},
            setMixSummary: (): void => {},
        },
    },
    filterState: {
        product: undefined,
        filterItems: [],
        actions: {
            appendFilterItems: (): void => {},
            setFilterItems: (): void => {},
            setProduct: (): void => {},
            deleteProduct: (): void => {},
        },
    },
    vaultState: {
        state: defaultVaultState,
        actions: {
            setVaultItem: (): void => {},
            setVaultItems: (): void => {},
            setShouldShowBadge: (): void => {},
        },
    },
    searchState: {
        overlayIsShown: false,
        actions: {
            setOverlayIsShown: (): void => {},
        },
    },
    detailsCardState: {
        detailsCard: null,
        actions: {
            createSubscriptionDetailsCard: (): void => {},
            updateSubscriptionDetailsCard: (): void => {},
        },
    },
    notificationSubSwitchingState: {
        notification: defaultNotificationState,
        actions: {
            displayNotification: (): void => {},
            hideNotification: (): void => {},
        },
    },
    subscriptionDataState: {
        subscriptionData: null,
        actions: {
            setSubscriptionData: (): void => {},
        },
    },
};

const DashboardContext = createContext<DashboardContextType>(defaultState);

DashboardContext.displayName = 'DashboardContext';

export default DashboardContext;

// @flow

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    hasCookiePolicyConsent,
    isCookiePolicyInitialized,
    hasExplicitConsent,
    hasMarketingConsent,
    getAllConsents,
} from '../state/selectors';

const withHasCookiePolicyConsent = connect(
    createStructuredSelector({
        hasCookiePolicyConsent,
        isCookiePolicyInitialized,
        hasExplicitConsent,
        hasMarketingConsent,
        getAllConsents,
    }),
);

export { withHasCookiePolicyConsent };

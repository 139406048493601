/* eslint-disable */
import {
  GET_ADYEN_PAYMENT_INFO,
  GET_ADYEN_PAYMENT_INFO_SUCCESS,
  GET_ADYEN_PAYMENT_INFO_ERROR,
} from './../actionTypes';


const defaultState = {
  loading: null,
  error: {},
  data: {},
};

export default function (state = defaultState, action) {
  switch (action.type) {

    case GET_ADYEN_PAYMENT_INFO:
      return Object.assign({}, state, {
        loading: true,
        error: {},
      })

    case GET_ADYEN_PAYMENT_INFO_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.data,
      })

    case GET_ADYEN_PAYMENT_INFO_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      })

    default:
      return state;
  }
}

/* eslint-disable */
const defaultState = null;

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'BRAINTREE_FETCHING':
    case 'BRAINTREE_GET_AUTH_TOKEN':
    case 'BRAINTREE_CONFIRM':
    case 'BRAINTREE_AUTHORIZE_PAYPAL':
    case 'BRAINTREE_CONFIRM_PAYPAL':
      return true;

    case 'BRAINTREE_STOP_FETCHING':
    case 'BRAINTREE_GET_AUTH_TOKEN_SUCCESS':
    case 'BRAINTREE_GET_AUTH_TOKEN_FAILURE':
    case 'BRAINTREE_CONFIRM_SUCCESS':
    case 'BRAINTREE_CONFIRM_FAILURE':
    case 'BRAINTREE_AUTHORIZE_PAYPAL_FAILURE':
    case 'BRAINTREE_CONFIRM_PAYPAL_SUCCESS':
    case 'BRAINTREE_CONFIRM_PAYPAL_FAILURE':
      return false;

    default:
      return state;
  }
}

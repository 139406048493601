/* eslint-disable */
export function join(p1, p2) {
    if (
        p2.charAt(0) !== '/' &&
        p1.charAt(p1.length - 1) !== '/'
    ) {
        return [p1, p2,].join('/');
    } else {
        return [p1, p2,].join('');
    }
}
export function populate(path, params) {
    const paramsList = Object.keys(params);
    let replacedPath = path;
    paramsList.forEach((key) => {
        replacedPath = replacedPath.replace(`:${key}`, params[key]);
    });

    return replacedPath;
}

export function pathReducer(routes) {
    return routes.reduce(
        (prev, current) => {
            if(current && current.path) {
                return join(prev, current.path);
            }
            return prev;
        },
        ''
    )
}
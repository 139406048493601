import axios from 'ggApp/utils/requests';
import { initializePageVisitTagging, injectPageVisitTagging } from '@devsbb/affiliates';

import { sendDataToSnowplow } from './snowplow';

export function sendToGtm(data = {}) {
    const hasKeys = data != null && Object.keys(data).length !== 0;
    if (window && window.dataLayer && hasKeys) {
        window.dataLayer.push(data);
    }
}

export function pushDataLayer(data = {}) {
    const hasKeys = data != null && Object.keys(data).length !== 0;
    sendToGtm(data);
    if (hasKeys) {
        sendDataToSnowplow(data);
    }
}

export function sendEvent(eventName, eventData) {
    return () => {
        return axios.post(`/kinesis`, {
            stream_name: eventName,
            body: eventData,
        });
    };
}

export function pageVisitTagHandler(
    pageType,
    email,
    userId,
    cartData,
    activeStore,
    marketingCookieConsent,
) {
    if (marketingCookieConsent) {
        initializePageVisitTagging(
            {
                pageType,
                userId,
                email,
                cartSubtotal: cartData?.cartSubtotal,
                items: cartData?.items && cartData?.items.length > 0 ? cartData.items : undefined,
            },
            activeStore,
        );
        injectPageVisitTagging(activeStore);
    }
}

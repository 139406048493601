import React from 'react';
import { Svg, Path } from 'react-native-svg';

const BusinessLogoWhite = ({ className }) => (
    <Svg
        className={className}
        width="284"
        height="85"
        viewBox="0 0 284 85"
        fill="none"
        xmlns="http://www.w3.org/2000/Svg"
    >
        <Path
            d="M152.627 27.757V20.795C152.627 20.3855 152.261 20.067 151.848 20.067C146.26 20.2945 142.87 22.7061 140.946 24.7538C136.183 29.8957 136.503 36.9486 136.503 38.6778V54.1489C136.503 54.5584 136.824 54.8769 137.236 54.8769H144.29C144.703 54.8769 145.023 54.5584 145.023 54.1489V38.3137C145.023 38.3137 144.703 33.1719 147.222 30.4417C148.321 29.2586 149.879 28.6216 151.94 28.4851C152.306 28.4851 152.627 28.1665 152.627 27.757Z"
            fill="white"
        />
        <Path
            d="M282.809 27.757V20.795C282.809 20.3855 282.443 20.067 282.03 20.067C276.442 20.2945 273.006 22.7061 271.128 24.7538C266.365 29.8957 266.685 36.9486 266.685 38.6778V54.1489C266.685 54.5584 267.006 54.8769 267.418 54.8769H274.426C274.839 54.8769 275.159 54.5584 275.159 54.1489V38.3137C275.159 38.3137 274.839 33.1719 277.358 30.4417C278.457 29.2586 280.015 28.6216 282.076 28.4851C282.534 28.4851 282.809 28.1665 282.809 27.757Z"
            fill="white"
        />
        <Path
            d="M40.9967 59.7002C28.3542 59.7002 18.0935 49.5075 18.0935 36.9486C18.0935 24.3897 28.3542 14.197 40.9967 14.197C53.6393 14.197 63.9 24.3897 63.9 36.9486C63.9 49.5075 53.6393 59.7002 40.9967 59.7002ZM74.9851 16.6087C74.069 13.2415 72.649 10.1472 70.3587 7.78107C67.9767 5.50591 64.8161 4.09531 61.4722 3.18524C52.6316 0.773573 42.4626 0.864579 33.5761 2.91222C24.5522 4.91437 18.0477 10.7388 12.7342 18.0193C7.78708 24.7538 3.11482 33.1264 2.42772 41.59C1.74063 50.4631 6.18385 58.9722 12.5968 65.1151C18.7806 71.4856 27.3464 75.8994 36.2787 75.2168C44.7987 74.5343 53.2271 69.893 60.0064 65.0241C67.3354 59.7457 73.1987 53.2843 75.2142 44.3657C77.3213 35.4925 77.4129 25.3908 74.9851 16.6087Z"
            fill="white"
        />
        <Path
            d="M219.688 20.704L208.19 45.6853L196.693 20.704C196.51 20.34 196.143 20.067 195.685 20.067H188.906C188.356 20.067 188.035 20.613 188.264 21.068L203.61 54.4219C203.747 54.6949 203.976 54.8314 204.297 54.8314H212.084C212.359 54.8314 212.634 54.6494 212.771 54.4219L228.116 21.1135C228.345 20.6585 227.979 20.1125 227.475 20.1125H220.695C220.283 20.067 219.871 20.34 219.688 20.704Z"
            fill="white"
        />
        <Path
            d="M235.079 33.2629L235.17 33.0808C236.911 29.3951 240.667 27.0289 244.79 27.0289C248.912 27.0289 252.668 29.3951 254.409 33.0808L254.501 33.2629H235.079ZM243.782 19.2479C234.437 19.7484 226.834 27.2564 226.375 36.5391C225.826 47.0958 234.3 55.878 244.79 55.878C250.653 55.878 256.15 53.1023 259.585 48.4609C259.814 48.1424 259.768 47.6419 259.402 47.4144L254.043 43.9561C253.722 43.7741 253.31 43.8196 253.081 44.0926C251.065 46.5953 248.042 48.0514 244.79 48.0514C240.117 48.0514 236.041 45.0937 234.621 40.6344L234.575 40.4524H254.959H262.379C262.746 40.4524 263.066 40.1793 263.112 39.8153C263.204 39.0418 263.25 38.2682 263.25 37.4492C263.25 27.1199 254.409 18.7018 243.782 19.2479Z"
            fill="white"
        />
        <Path
            d="M171.591 48.0969C165.728 48.0969 160.964 43.3646 160.964 37.5402C160.964 31.7158 165.728 26.9834 171.591 26.9834C177.454 26.9834 182.218 31.7158 182.218 37.5402C182.218 43.3646 177.454 48.0969 171.591 48.0969ZM171.591 19.2024C161.422 19.2024 153.131 27.4385 153.131 37.5402C153.131 47.6419 161.422 55.878 171.591 55.878C181.76 55.878 190.051 47.6419 190.051 37.5402C190.051 27.4385 181.76 19.2024 171.591 19.2024Z"
            fill="white"
        />
        <Path
            d="M110.21 14.197C97.5677 14.197 87.3071 24.3897 87.3071 36.9486C87.3071 49.5075 97.5677 59.7002 110.21 59.7002C122.853 59.7002 133.114 49.5075 133.114 36.9486C133.114 35.7655 133.022 34.537 132.839 33.3539C132.793 32.9898 132.472 32.7168 132.106 32.7168H111.859C111.447 32.7168 111.126 33.0354 111.126 33.4449V40.4069C111.126 40.8164 111.447 41.1349 111.859 41.1349H124.09L124.044 41.3169C122.074 47.4144 116.394 51.4641 109.89 51.3731C101.919 51.1911 95.5064 44.5477 95.6896 36.6756C95.8729 28.8491 102.286 22.5696 110.21 22.5696C114.837 22.5696 119.188 24.7538 121.891 28.4395C122.12 28.758 122.532 28.8036 122.853 28.6215L128.67 24.8903C129.037 24.6628 129.128 24.2077 128.854 23.8437C124.594 17.7463 117.677 14.197 110.21 14.197Z"
            fill="white"
        />
        <Path
            d="M157.299 83.3164V64.9786H163.529C164.72 64.9786 165.728 65.2061 166.552 65.6156C167.377 66.0251 167.972 66.6167 168.339 67.3447C168.705 68.0728 168.934 68.8918 168.934 69.8019C168.934 70.5755 168.797 71.2125 168.522 71.7586C168.247 72.3046 167.926 72.7596 167.468 73.0782C167.01 73.3967 166.552 73.6697 166.003 73.8062V73.9427C166.598 73.9882 167.194 74.2157 167.743 74.5798C168.293 74.9438 168.751 75.4898 169.117 76.1269C169.484 76.8094 169.667 77.583 169.667 78.5385C169.667 79.4486 169.438 80.2677 169.026 80.9957C168.614 81.7238 167.972 82.3153 167.102 82.7248C166.232 83.1344 165.178 83.3619 163.85 83.3619L157.299 83.3164ZM158.994 73.2602H163.666C164.399 73.2602 165.041 73.1237 165.59 72.8051C166.14 72.4866 166.552 72.0771 166.873 71.5765C167.194 71.076 167.331 70.439 167.331 69.8019C167.331 68.8463 167.01 68.0273 166.369 67.4357C165.728 66.8442 164.766 66.5257 163.529 66.5257H158.994V73.2602ZM158.994 81.8148H163.895C165.315 81.8148 166.369 81.4962 167.056 80.8592C167.743 80.2222 168.064 79.4486 168.064 78.493C168.064 77.8105 167.881 77.1734 167.56 76.5819C167.239 75.9904 166.735 75.5808 166.14 75.2168C165.544 74.8983 164.812 74.7163 163.987 74.7163H159.04L158.994 81.8148Z"
            fill="white"
        />
        <Path
            d="M185.928 64.9786H187.623V77.1279C187.623 78.3565 187.348 79.4941 186.753 80.4952C186.157 81.4962 185.333 82.2698 184.279 82.8158C183.226 83.3619 181.989 83.6804 180.615 83.6804C179.241 83.6804 178.004 83.4074 176.95 82.8158C175.897 82.2698 175.072 81.4962 174.477 80.4952C173.881 79.5396 173.606 78.402 173.606 77.1734V65.0241H175.301V77.0369C175.301 77.9925 175.53 78.8571 175.943 79.6306C176.401 80.4042 176.996 80.9957 177.775 81.4052C178.554 81.8603 179.515 82.0423 180.569 82.0423C181.623 82.0423 182.584 81.8148 183.363 81.4052C184.142 80.9502 184.783 80.3587 185.195 79.6306C185.608 78.9026 185.837 77.9925 185.837 77.0369L185.928 64.9786Z"
            fill="white"
        />
        <Path
            d="M202.327 69.5289C202.235 68.5278 201.777 67.7088 200.953 67.0717C200.128 66.4802 199.121 66.1617 197.93 66.1617C197.059 66.1617 196.326 66.2982 195.639 66.6167C194.998 66.8897 194.494 67.2993 194.128 67.8453C193.761 68.3913 193.578 68.9374 193.578 69.6199C193.578 70.1204 193.67 70.53 193.899 70.894C194.128 71.258 194.403 71.5765 194.769 71.8496C195.135 72.1226 195.502 72.3501 195.96 72.5321C196.418 72.7141 196.83 72.8961 197.288 72.9871L199.212 73.5332C199.808 73.7152 200.403 73.8972 200.999 74.1702C201.594 74.4433 202.144 74.7163 202.602 75.1258C203.06 75.5353 203.472 75.9904 203.793 76.5364C204.114 77.0824 204.251 77.7195 204.251 78.493C204.251 79.4486 203.976 80.3587 203.472 81.1322C202.968 81.9058 202.235 82.5428 201.274 82.9979C200.312 83.4529 199.166 83.6804 197.838 83.6804C196.601 83.6804 195.502 83.4529 194.586 83.0434C193.67 82.6338 192.937 82.0423 192.387 81.3142C191.883 80.5862 191.563 79.7216 191.471 78.7661H193.212C193.257 79.4941 193.532 80.0857 193.944 80.5862C194.357 81.0867 194.906 81.4508 195.594 81.7238C196.281 81.9968 197.014 82.1333 197.838 82.1333C198.754 82.1333 199.579 81.9968 200.312 81.6783C201.044 81.3597 201.594 80.9502 202.006 80.4042C202.419 79.8581 202.648 79.2211 202.648 78.493C202.648 77.856 202.464 77.3555 202.144 76.9459C201.823 76.5364 201.365 76.1724 200.77 75.8994C200.174 75.6263 199.533 75.3533 198.754 75.1258L196.555 74.4888C195.09 74.0792 193.99 73.4877 193.166 72.7141C192.387 71.9406 191.975 70.985 191.975 69.8019C191.975 68.8009 192.25 67.9363 192.754 67.2082C193.303 66.4802 193.99 65.8887 194.906 65.4336C195.823 65.0241 196.83 64.7966 197.975 64.7966C199.121 64.7966 200.128 65.0241 200.999 65.4336C201.869 65.8431 202.602 66.4347 203.106 67.1627C203.61 67.8908 203.885 68.7099 203.93 69.6654L202.327 69.5289Z"
            fill="white"
        />
        <Path d="M210.023 64.9786V83.3164H208.328V64.9786H210.023Z" fill="white" />
        <Path
            d="M229.399 64.9786V83.3164H227.75L216.848 67.9818H216.71V83.3164H215.015V64.9786H216.664L227.612 80.3587H227.75V64.9786H229.399Z"
            fill="white"
        />
        <Path
            d="M234.392 83.3164V64.9786H245.11V66.4802H236.086V73.3967H244.561V74.8983H236.086V81.8148H245.294V83.3164H234.392Z"
            fill="white"
        />
        <Path
            d="M258.898 69.5289C258.806 68.5278 258.348 67.7088 257.524 67.0717C256.699 66.4802 255.692 66.1617 254.501 66.1617C253.63 66.1617 252.897 66.2982 252.21 66.6167C251.569 66.8897 251.065 67.2993 250.699 67.8453C250.332 68.3458 250.149 68.9374 250.149 69.6199C250.149 70.1204 250.241 70.53 250.47 70.894C250.699 71.258 250.974 71.5765 251.34 71.8496C251.706 72.1226 252.119 72.3501 252.531 72.5321C252.989 72.7141 253.401 72.8961 253.859 72.9871L255.783 73.5332C256.379 73.7152 256.974 73.8972 257.524 74.1702C258.119 74.4433 258.669 74.7163 259.127 75.1258C259.585 75.5353 259.997 75.9904 260.318 76.5364C260.639 77.0824 260.776 77.7195 260.776 78.493C260.776 79.4486 260.501 80.3587 259.997 81.1322C259.493 81.9058 258.761 82.5428 257.799 82.9979C256.837 83.4529 255.692 83.6804 254.363 83.6804C253.126 83.6804 252.027 83.4529 251.111 83.0434C250.195 82.6338 249.462 82.0423 248.912 81.3142C248.363 80.5862 248.088 79.7216 247.996 78.7661H249.737C249.783 79.4941 250.057 80.0857 250.47 80.5862C250.882 81.0867 251.432 81.4508 252.119 81.7238C252.806 81.9968 253.539 82.1333 254.363 82.1333C255.279 82.1333 256.104 81.9968 256.837 81.6783C257.57 81.3597 258.119 80.9502 258.532 80.4042C258.944 79.8581 259.127 79.2211 259.127 78.493C259.127 77.856 258.99 77.3555 258.623 76.9459C258.257 76.5364 257.844 76.1724 257.249 75.8994C256.653 75.6263 256.012 75.3533 255.234 75.1258L253.035 74.4888C251.569 74.0792 250.47 73.4877 249.691 72.7141C248.912 71.9406 248.5 70.985 248.5 69.8019C248.5 68.8009 248.775 67.9363 249.279 67.2082C249.828 66.4802 250.515 65.8887 251.432 65.4336C252.348 65.0241 253.355 64.7966 254.501 64.7966C255.646 64.7966 256.653 65.0241 257.57 65.4336C258.44 65.8431 259.173 66.4347 259.677 67.1627C260.181 67.8908 260.455 68.7099 260.501 69.6654L258.898 69.5289Z"
            fill="white"
        />
        <Path
            d="M274.93 69.5289C274.839 68.5278 274.381 67.7088 273.556 67.0717C272.732 66.4802 271.724 66.1617 270.533 66.1617C269.663 66.1617 268.93 66.2982 268.243 66.6167C267.601 66.8897 267.097 67.2993 266.731 67.8453C266.364 68.3458 266.181 68.9374 266.181 69.6199C266.181 70.1204 266.273 70.53 266.502 70.894C266.731 71.258 267.006 71.5765 267.372 71.8496C267.739 72.1226 268.151 72.3501 268.563 72.5321C269.021 72.7141 269.434 72.8961 269.892 72.9871L271.815 73.5332C272.411 73.7152 273.006 73.8972 273.556 74.1702C274.152 74.4433 274.701 74.7163 275.159 75.1258C275.617 75.5353 276.03 75.9904 276.35 76.5364C276.671 77.0824 276.808 77.7195 276.808 78.493C276.808 79.4486 276.534 80.3587 276.03 81.1322C275.526 81.9058 274.793 82.5428 273.831 82.9979C272.869 83.4529 271.724 83.6804 270.395 83.6804C269.159 83.6804 268.059 83.4529 267.143 83.0434C266.227 82.6338 265.494 82.0423 264.945 81.3142C264.395 80.5862 264.12 79.7216 264.028 78.7661H265.769C265.815 79.4941 266.09 80.0857 266.502 80.5862C266.914 81.0867 267.464 81.4508 268.151 81.7238C268.838 81.9968 269.571 82.1333 270.395 82.1333C271.312 82.1333 272.136 81.9968 272.869 81.6783C273.602 81.3597 274.152 80.9502 274.564 80.4042C274.976 79.8581 275.159 79.2211 275.159 78.493C275.159 77.856 275.022 77.3555 274.655 76.9459C274.289 76.5364 273.877 76.1724 273.281 75.8994C272.686 75.6263 272.044 75.3533 271.266 75.1258L269.067 74.4888C267.601 74.0792 266.502 73.4877 265.723 72.7141C264.944 71.9406 264.532 70.985 264.532 69.8019C264.532 68.8009 264.807 67.9363 265.311 67.2082C265.861 66.4802 266.548 65.8887 267.464 65.4336C268.38 65.0241 269.388 64.7966 270.533 64.7966C271.678 64.7966 272.686 65.0241 273.602 65.4336C274.472 65.8431 275.205 66.4347 275.709 67.1627C276.213 67.8908 276.488 68.7099 276.534 69.6654L274.93 69.5289Z"
            fill="white"
        />
    </Svg>
);

export default BusinessLogoWhite;

import { USER_LOGOUT } from 'ggApp/modules/auth/actionTypes';
import { shutdownIntercom } from 'ggApp/modules/intercom/service';
import CookiesManager from 'ggApp/utils/CookiesManager';
import { fireEvent } from 'ggApp/modules/inwebview_api';
import { isAppUser } from 'ggApp/routes/_wrap/MobileWrapper/utils';
import { SHUTDOWN_INTERCOM } from 'ggApp/modules/intercom/constants/actionTypes';

import { resetUserForBraze } from '../../packages/braze/api';

const isServer = __IS_SERVER__;

export default function logoutMiddleware() {
    return (next) => (action) => {
        if (!isServer) {
            const cookiesManager = new CookiesManager();
            const { type } = action;

            if (type === USER_LOGOUT) {
                cookiesManager.remove('user_id', { path: '/' });
                shutdownIntercom();
                resetUserForBraze();
                // App webview still uses the same event to control CS messenger
                if (isAppUser()) {
                    fireEvent(SHUTDOWN_INTERCOM);
                }
            }
        }
        next(action);
    };
}

import React from 'react';
import { IconPropTypes } from 'ggApp/types/propTypes';

const MapDot = ({ className, color, size }) => (
    <svg
        className={className}
        width={size}
        height={size}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.98819 5.55544C6.89519 6.56663 6.12158 8.11478 6.12158 10.2856C6.12158 13.2371 7.68223 15.6808 9.34803 17.4417C10.1735 18.3143 11.0016 18.9939 11.6234 19.4549C11.811 19.594 11.979 19.7126 12.1216 19.81C12.2641 19.7126 12.4322 19.594 12.6198 19.4549C13.2415 18.9939 14.0697 18.3143 14.8951 17.4417C16.5609 15.6808 18.1216 13.2371 18.1216 10.2856C18.1216 8.11478 17.348 6.56663 16.255 5.55544C15.1466 4.53003 13.6474 4 12.1216 4C10.5957 4 9.09655 4.53003 7.98819 5.55544ZM12.1216 21C11.6176 21.8637 11.6169 21.8633 11.6169 21.8633L11.6139 21.8616L11.6077 21.8579L11.5878 21.846C11.5712 21.8361 11.5481 21.8222 11.519 21.8043C11.4609 21.7685 11.3784 21.7168 11.2751 21.6495C11.0687 21.5151 10.7784 21.3181 10.4323 21.0615C9.74152 20.5494 8.81968 19.7935 7.89513 18.8161C6.06093 16.8772 4.12158 13.9637 4.12158 10.2856C4.12158 7.59937 5.09797 5.50471 6.62998 4.08736C8.14661 2.68423 10.1474 2 12.1216 2C14.0957 2 16.0965 2.68423 17.6132 4.08736C19.1452 5.50471 20.1216 7.59937 20.1216 10.2856C20.1216 13.9637 18.1822 16.8772 16.348 18.8161C15.4235 19.7935 14.5016 20.5494 13.8109 21.0615C13.4647 21.3181 13.1745 21.5151 12.968 21.6495C12.8648 21.7168 12.7823 21.7685 12.7241 21.8043C12.695 21.8222 12.672 21.8361 12.6554 21.846L12.6355 21.8579L12.6293 21.8616L12.6271 21.8628C12.6271 21.8628 12.6256 21.8637 12.1216 21ZM12.1216 21L12.6256 21.8637L12.1216 22.1578L11.6169 21.8633L12.1216 21Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.1216 8C11.017 8 10.1216 8.89543 10.1216 10C10.1216 11.1046 11.017 12 12.1216 12C13.2262 12 14.1216 11.1046 14.1216 10C14.1216 8.89543 13.2262 8 12.1216 8ZM8.12158 10C8.12158 7.79086 9.91244 6 12.1216 6C14.3307 6 16.1216 7.79086 16.1216 10C16.1216 12.2091 14.3307 14 12.1216 14C9.91244 14 8.12158 12.2091 8.12158 10Z"
            fill={color}
        />
    </svg>
);

MapDot.propTypes = {
    ...IconPropTypes,
};

MapDot.defaultProps = {
    className: undefined,
    size: '24px',
    color: '#333333',
};

export default MapDot;

import {
    GET_PRODUCT_PROMOTIONS,
    GET_PRODUCT_PROMOTIONS_SUCCESS,
    GET_PRODUCT_PROMOTIONS_ERROR,
} from '../actionTypes';

const defaultState = {
    loading: null,
    error: {},
    data: { products: [] },
};

export default function(state = defaultState, action) {
    switch (action.type) {
        case GET_PRODUCT_PROMOTIONS:
            return {
                ...state,
                loading: true,
            };
        case GET_PRODUCT_PROMOTIONS_SUCCESS: {
            const merged = state?.data?.products
                ? { products: state.data.products.concat(action.data.products) }
                : action.data;
            return {
                ...state,
                loading: false,
                data: merged,
            };
        }
        case GET_PRODUCT_PROMOTIONS_ERROR: {
            const error = action.error ?? '';
            return {
                ...state,
                loading: false,
                error,
            };
        }
        default:
            return state;
    }
}

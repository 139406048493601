/* eslint-disable import/prefer-default-export */
import axios from 'ggApp/utils/requests';
import ggAxiosConfig from 'ggApp/modules/axiosConfig';
import CookiesManager, { injectCookiesToConfig } from 'ggApp/utils/CookiesManager';
import { GET_STATIC_INFO, GET_STATIC_INFO_SUCCESS, GET_STATIC_INFO_ERROR } from '../actionTypes';

export function getStaticInfo(pageTypes, req) {
    const cookiesManager = new CookiesManager(req);
    return (dispatch, getState) => {
        if (!pageTypes || pageTypes.length === 0) return null;

        const config = ggAxiosConfig.selectors.config(getState());
        injectCookiesToConfig(cookiesManager, config);
        const param = pageTypes.join(',');

        dispatch({ type: GET_STATIC_INFO });
        return axios
            .get(`/static_pages?type=${param}`, config)
            .then((result) => {
                dispatch({
                    type: GET_STATIC_INFO_SUCCESS,
                    data: result.data,
                });
            })
            .catch(({ response: error = {} }) => {
                if (error.response) {
                    dispatch({
                        type: GET_STATIC_INFO_ERROR,
                        error: error.response,
                    });
                }
            });
    };
}

import styled from 'styled-components';

const InnerWrap = styled.div`
    ${({ theme }) => theme.maxWidth};
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    ${({inPdpScreen}) => window.ReactNativeWebView && `padding-bottom: ${inPdpScreen ? "100px" : "8px"};`}
`;

const Content = styled.div`
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
`;

export {
    InnerWrap,
    Content,
};

import styled from 'styled-components';

export const NotificationBar = styled.div`
    z-index: 9999;
    position: relative;

    div {
        font-family: Inter, sans-serif !important;
    }

    .notification {
        min-height: 40px !important;
        font-size: 16px !important;
        padding: 10px 40px 10px 10px !important;
        line-height: 1.35 !important;
        border-radius: 0 !important;
        background-color: #fff !important;
        box-shadow: none !important;
        border-top-width: 3px !important;
        height: auto !important;
    }

    .notification-success {
        border-color: #159bff !important;
    }

    .notification-dismiss {
        font-size: 17px !important;
        width: 20px !important;
        height: 20px !important;
        line-height: 21px !important;
        top: 9px !important;
        right: 7px !important;
        background-color: #8a8a8a !important;
    }
`;

export const Info = styled.div`
    margin-top: 5px;

    * {
        line-height: 1.35 !important;
        letter-spacing: 0.3px !important;
    }
`;

export const Row = styled.div`
    display: flex;
    > div {
        &:first-child {
            margin-right: 5px;
        }

        &:last-child {
            font-weight: 600;
            word-break: break-all;
        }
    }
`;

/**
 * This functions gets object and another object with the new key names.
 * It will replace key from one object with the value of the same key from another
 * only if the value is a string.
 * It won't work for nested objects.
 * @param {Object} object
 * @param {Object} keysMapper
 * @returns {Object} object with new keys
 * @example
 * const object = {
 *      foo: 'foo',
 *      bar: 'bar',
 * };
 * const mapper = {
 *      foo: 'alpha',
 *      bar: 'beta',
 * };
 * const result = renameKeys(object, mapper);
 * result == {
 *      alpha: 'foo',
 *      beta: 'bar',
 * }
 */
function renameKeys(object, keysMapper) {
    if (typeof object !== 'object' || object === null) {
        return null;
    }

    if (typeof keysMapper !== 'object' || keysMapper === null) {
        return object;
    }

    const newObject = {};

    Object.keys(object).forEach((key) => {
        const newKey = keysMapper[key];

        if (newKey && typeof newKey === 'string') {
            newObject[newKey] = object[key];
        } else {
            newObject[key] = object[key];
        }
    });

    return newObject;
}

export default renameKeys;

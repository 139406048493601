/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import ClientCookies from 'universal-cookie';
import axios from 'axios';

const isServer = __IS_SERVER__;
const devSubdomain = '.eu-staging.grover.com';

/**
 * CookiesManager constructor, usage ex:
 * const cookies = new CookiesManager(req);
 * cookies.get('user_id');
 * @param req optional, required if called server side
 * @returns {Cookies}
 * @constructor
 */
export default function CookiesManager(req = null) {
    let instance = null;

    if (isServer && req?.universalCookies) {
        instance = req.universalCookies;
    } else if (isServer) {
        throw new Error('req is empty on server, please specify it in order to use Cookies');
    } else {
        instance = new ClientCookies();
    }

    if (!instance) {
        throw new Error('CookiesManager instance is not defined');
    }

    // Injecting default options to set value
    const setCookie = instance.set.bind(instance);

    instance.set = (key, value, options) => {
        const defaultOptions = {
            path: '/',
            secure: true,
        };

        /**
         * [dev-only] Setting the `.eu-staging.grover.com` subdomain as the cookie domain in order to share attributes required by `dashboard-ui`,
         * since the same depends on frontshop's authentication route.
         */
        const { hostname } = window?.location ?? {};
        if (hostname?.includes(devSubdomain)) {
            defaultOptions.domain = devSubdomain;
        }

        return setCookie.call(instance, key, value, {
            ...defaultOptions,
            ...(options ?? {}),
        });
    };

    return instance;
}

/**
 * Axios is serializing any given config, so we can't just add  `cookiesManager` key, it will be deleted.
 * However axios also merges the defaults to config before using it. So there is a way to mix cookiesManager there, so it won't be overwrited/erased
 * @param cookiesManager: cookies manager instance
 * @param config: axios config
 * @returns {*}: updated axios config
 */
export const injectCookiesToConfig = (cookiesManager, defaultConfig) => {
    const config = defaultConfig;
    axios.defaults.transformResponse[0].cookiesManager = cookiesManager;
    config.transformResponse = axios.defaults.transformResponse;
    return config;
};

export const getCookiesFromConfig = (config) =>
    config && Array.isArray(config.transformResponse) && config.transformResponse[0].cookiesManager;

export const CookiesManagerContext = React.createContext(
    !isServer ? new CookiesManager() : undefined,
);

export const withCookiesManager = (WrappedComponent) => (props) => {
    const cookiesManager = useContext(CookiesManagerContext);
    return <WrappedComponent cookiesManager={cookiesManager} {...props} />;
};

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose, mapProps, withPropsOnChange } from 'recompose';
import { createStructuredSelector } from 'reselect';

import {
    newCheckoutEnabledSelector,
    loggedOutBrowsingFlag as loggedOutBrowsingFlagSelector,
} from 'ggApp/modules/featureFlag/selectors';

import toRelativeUrl from 'ggApp/utils/urlBuilder/toRelativeUrl';
import { isAppUser } from 'ggApp/routes/_wrap/MobileWrapper/utils';
import { redirectus as redirectAction } from 'ggApp/modules/router/actions';
import { locationQuery as locationQuerySelector } from 'ggApp/modules/router/selectors';
import { isLoggedIn as isLoggedInSelector } from 'ggApp/modules/auth/selectors';
import { initDone as initAppDoneSelector } from 'ggApp/modules/init/selectors';
import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import { keys as translationsSelector, translate } from 'ggApp/modules/translator/selectors';
import { isRedirectionOK } from './utils';

import Auth from './Auth';

const enhance = compose(
    connect(
        createStructuredSelector({
            locationQuery: locationQuerySelector,
            activeStore: activeStoreSelector,
            translations: translationsSelector,
            isLoggedIn: isLoggedInSelector,
            newCheckoutFlag: newCheckoutEnabledSelector,
            loggedOutBrowsingFlag: loggedOutBrowsingFlagSelector,
            initAppDone: initAppDoneSelector,
        }),
        (dispatch) => ({
            redirect: bindActionCreators(redirectAction, dispatch),
        }),
    ),
    withPropsOnChange(
        ['locationQuery, activeStore, translations'],
        ({ locationQuery, activeStore, translations }) => {
            const { flow, redirection, token, accessToken } = locationQuery;

            // token query param is used for legacy email of a password reset
            const accessTokenFromQuery = accessToken ?? token;

            const { code, activeLanguage } = activeStore;

            const cancellationPath = `/${code}-${activeLanguage}/`;

            const renderedInApp = isAppUser();

            // Allow redirection to absolute path if origin is Grover Work
            const redirectionPath = isRedirectionOK(renderedInApp, redirection)
                ? redirection
                : toRelativeUrl(redirection) ?? `/${code}-${activeLanguage}/`;

            const metaPageInfo = {
                title: translate(translations, activeLanguage, 'METAINFO_AUTH_TITLE'),
            };

            return {
                flow,
                redirectionPath,
                cancellationPath,
                metaPageInfo,
                accessToken: accessTokenFromQuery,
                redirectionParam: redirection,
            };
        },
    ),
    mapProps(({ locationQuery, activeStore, translations, ...restProps }) => ({
        ...restProps,
    })),
);

export default enhance(Auth);

// @flow

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    updateCookiePolicySettings,
    consentCookiePolicy,
    initCookiePolicySettings,
} from '../state/actions';

const withCookieConsentActions = connect(null, (dispatch) => ({
    initCookiePolicySettings: bindActionCreators(initCookiePolicySettings, dispatch),
    consentCookiePolicy: bindActionCreators(consentCookiePolicy, dispatch),
    updateCookiePolicySettings: bindActionCreators(updateCookiePolicySettings, dispatch),
}));

export { withCookieConsentActions };

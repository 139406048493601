import { compose, withProps } from 'recompose';
import {
    registerTeaserClickEvent,
    registerTeaserImpressionEvent,
} from 'ggApp/modules/gaAnalytics/actions';
import withIntersectionObserver from 'ggApp/modules/tracking/hocs/withIntersectionObserverV2';
import TileMarketingWidget from './TileMarketingWidget';

export { TileMarketingWidget };
export const TileMarketingWidgetWithEvents = compose(
    withProps(({ headerText, ctaLink, widgetName }) => ({
        onEnterCallback: registerTeaserImpressionEvent({
            widgetName,
            title: headerText,
            url: ctaLink,
        }),
        onClickCTA: registerTeaserClickEvent({
            widgetName,
            title: headerText,
            url: ctaLink,
        }),
    })),
    withIntersectionObserver,
)(TileMarketingWidget);

import axios from 'ggApp/utils/requests';

enum Type {
    Shipping = 'shipping',
    Billing = 'billing',
}

const createUserAddressUpdater = (type: Type) => (
    { user: { id: userId } },
    event: { data: { id: number }[]; type: string; addressType: string },
) => {
    const { data = [], addressType } = event || {};

    const [address] = data;
    if (!address) {
        return Promise.resolve(null);
    }

    return axios
        .patch(`users/${userId}/address`, {
            address_type: addressType || type,
            id: address.id,
        })
        .then((response: any) => response.data.user);
};

export const userAddress = {
    updateShipping: createUserAddressUpdater(Type.Shipping),
    updateHome: createUserAddressUpdater(Type.Billing),
};

import { ModalComponent_Wrap as ModalWrap } from 'ggApp/modules/modal';
import DefaultHeadersSetter from 'ggApp/routes/_wrap/defaultHeaders';
import MainRoutes from 'ggApp/routes/catalog';
import React from 'react';
import { Route } from 'react-router';
import frontshopOnChange from 'ggApp/utils/router/frontshopOnChange';
import RootWrap from './routes/_wrap/frontshop';
import ModalRoutes from './routes/modal';
import AuthRoutes from './routes/auth';
import VerifyRoutes from './routes/verify';
import SurveyRoutes from './routes/survey';
import TermsAndConditionsRoutes from './routes/catalog/TermsAndConditions';

const createFrontshopRoutes = (dispatch) => (
    <Route path="/:store-:language" component={RootWrap} onChange={frontshopOnChange(dispatch)}>
        <Route component={ModalWrap} modalRoutes={ModalRoutes}>
            <Route component={DefaultHeadersSetter}>
                {AuthRoutes}
                {SurveyRoutes}
                {VerifyRoutes}
                {TermsAndConditionsRoutes}
                {MainRoutes}
            </Route>
        </Route>
    </Route>
);

export default createFrontshopRoutes;

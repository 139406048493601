import React from 'react';
import { Svg, Path } from 'react-native-svg';
import { withTheme } from 'styled-components';
import { IconPropTypes } from 'ggApp/types/propTypes';

const CardIcon = ({ color, size, className, theme }) => {
    const fill = color || theme.colors.Foreground;
    return (
        <Svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V6ZM20 6H4V13H20V6ZM20 15H4V18L20 18V15ZM18 10C18 10.5523 17.5523 11 17 11H15C14.4477 11 14 10.5523 14 10V9C14 8.44772 14.4477 8 15 8H17C17.5523 8 18 8.44772 18 9V10Z"
                fill={fill}
            />
        </Svg>
    );
};

CardIcon.propTypes = {
    ...IconPropTypes,
};

CardIcon.defaultProps = {
    className: '',
    size: '24px',
};

export default withTheme(CardIcon);

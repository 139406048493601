import axios from '../utils/axiosInstance';

const prepareEntityId = (entityId) => {
    if (!entityId) {
        // TODO: this case should be handled on the
        // flag selector level: if we evaluated flag(s)
        // with random entity ID, we should treat this
        // as if flag(s) are not enabled in Flagr
        return undefined;
    }

    // Flagr accepts only strings
    return entityId.toString();
};

export const evaluateFlag = ({
    flagKey,
    ggId,
    userId,
    entityId,
    deviceId,
    storeCode,
    flavour,
    userType,
    storeType,
    individual,
}) =>
    axios.post('/evaluation', {
        flagKey,
        entityID: prepareEntityId(entityId),
        entityContext: {
            ggId,
            userId,
            deviceId,
            storeCode,
            flavour,
            userType,
            storeType,
            individual,
        },
    });

export const batchEvaluateFlags = ({
    flagKeyList,
    ggId,
    userId,
    countryCode,
    entityId,
    deviceId,
    storeCode,
    flavour,
    userType,
    storeType,
    individual,
}) =>
    axios.post('/evaluation/batch', {
        entities: [
            {
                entityID: prepareEntityId(entityId),
                entityContext: {
                    ggId,
                    userId,
                    countryCode,
                    deviceId,
                    storeCode,
                    flavour,
                    userType,
                    storeType,
                    individual,
                },
            },
        ],
        flagKeys: flagKeyList,
    });

// @flow

import { createSelector } from 'reselect';

import {
    isAndroidAppUser,
    iosAppUserMarketingConsent,
} from 'ggApp/routes/_wrap/MobileWrapper/utils';

import { COOKIE_POLICY_MODULE_NAME } from '../constants';
import type { ReduxStoreState } from '../../utils/flowTypes';

function moduleStateSelector(state: any) {
    return state[COOKIE_POLICY_MODULE_NAME] || {};
}

export const consentState: ReduxStoreState = createSelector(
    moduleStateSelector,
    (moduleState) =>
        moduleState.consentState || {
            hasConsent: false,
        },
);

export const hasCookiePolicyConsent: boolean = createSelector(consentState, (state) => {
    return (
        state.marketingCookieConsent &&
        state.preferenceCookieConsent &&
        state.statisticsCookieConsent
    );
});

export const getAllConsents = createSelector(consentState, (state) => {
    return {
        marketingCookieConsent: state.marketingCookieConsent,
        preferenceCookieConsent: state.preferenceCookieConsent,
        statisticsCookieConsent: state.statisticsCookieConsent,
    };
});

export const isCookiePolicyInitialized: boolean = createSelector(
    consentState,
    (state) => state.isInitialized,
);

export const hasMarketingConsent: boolean = createSelector(
    consentState,
    (state) => state.marketingCookieConsent || iosAppUserMarketingConsent() || isAndroidAppUser(),
);

export const hasExplicitConsent: boolean = createSelector(
    consentState,
    (state) => state.hasConsent,
);

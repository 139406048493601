/* eslint-disable react/sort-comp */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import memoize from 'fast-memoize';
import throttle from 'ggApp/utils/throttle';
import { extractCallbacksFromProps } from 'ggApp/modules/tracking/hocs/withListEventHandlers';
import { Gap, NextArrow, PrevArrow, ProductCard, Root, List } from './styles';

const CARD_MARGIN = 16;

class Carousel extends PureComponent {
    constructor(props) {
        super(props);
        this.throttledSetInnerWidth = throttle(this.setInnerWidth, 200);
        this.state = { productIndex: 0, perPage: 4, containerWidth: 0 };
    }

    // eslint-disable-next-line react/sort-comp
    setInnerWidth = () => {
        const containerWidth = this.rootRef.offsetWidth;
        const { cardWidth } = this.props;
        const perPage = Math.floor((containerWidth + CARD_MARGIN) / (cardWidth + CARD_MARGIN));
        // eslint-disable-next-line react/destructuring-assignment
        if (this.state.perPage !== perPage) {
            this.setState({
                perPage: Math.max(perPage, 1),
                containerWidth,
            });
        }
    };

    goNext = () => {
        const { productIndex, perPage } = this.state;
        const {
            products: { length: lastIndex },
        } = this.props;
        if (lastIndex - perPage === productIndex) {
            this.setState({ productIndex: 0 });
            return;
        }
        this.setState({
            productIndex: Math.min(productIndex + perPage, lastIndex - perPage),
        });
    };

    goPrev = () => {
        const { productIndex, perPage } = this.state;
        this.setState({ productIndex: Math.max(productIndex - perPage, 0) });
    };

    handleClickItem = memoize((product) => () => {
        const { onClickItem } = this.props;
        onClickItem(product);
    });

    componentDidMount() {
        if (this.rootRef) {
            this.setInnerWidth();
            window.addEventListener('resize', this.throttledSetInnerWidth);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.throttledSetInnerWidth);
    }

    render() {
        const { products, productImpressionCallbacks, cardProps, cardWidth } = this.props;
        const { productIndex, perPage, containerWidth } = this.state;
        const indexPosition = productIndex * (cardWidth + CARD_MARGIN);
        return (
            // eslint-disable-next-line no-return-assign
            <Root ref={(el) => (this.rootRef = el)}>
                <List
                    style={{
                        transform: `translate3d(${-indexPosition}px, 0, 0)`,
                    }}
                >
                    {products.map((product, index) => (
                        <ProductCard
                            cardWidth={cardWidth}
                            key={product.slug}
                            product={product}
                            cardType="vertical"
                            carousel
                            recentlyViewedSection
                            onClick={this.handleClickItem(product)}
                            {...cardProps}
                            {...extractCallbacksFromProps(productImpressionCallbacks, index)}
                        />
                    ))}
                    <Gap
                        width={Math.max(
                            containerWidth + CARD_MARGIN - (cardWidth + CARD_MARGIN) * perPage,
                            0,
                        )}
                    />
                </List>
                <NextArrow onClick={this.goNext} disabled={perPage >= products.length} />
                <PrevArrow onClick={this.goPrev} disabled={productIndex === 0} />
            </Root>
        );
    }
}

Carousel.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    cardProps: PropTypes.object,
    cardWidth: PropTypes.number,
    productImpressionCallbacks: PropTypes.arrayOf(PropTypes.func),
    products: PropTypes.arrayOf(PropTypes.shape({ slug: PropTypes.string.isRequired })),
    onClickItem: PropTypes.func,
};

Carousel.defaultProps = {
    cardProps: null,
    cardWidth: 312,
    productImpressionCallbacks: undefined,
    products: [],
    onClickItem: () => null,
};

export default Carousel;

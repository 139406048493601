import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, bindActionCreators } from 'redux';

import {
    user as userSelector,
    isLoggedIn as isLoggedInSelector,
} from 'ggApp/modules/auth/selectors';
import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import { flags as featureFlagSelector } from 'ggApp/modules/featureFlag/selectors';
import { hasCookiePolicyConsent as hasCookiePolicyConsentSelector } from 'ggApp/modules/cookiePolicy/state/selectors';
import { initDone as initDoneSelector } from 'ggApp/modules/init/selectors';
import withDeviceInfo from 'ggApp/shared/components/viewport/withDeviceInfo';

import { intercomHMAC as intercomHMACSelector } from '../../selectors';
import {
    getHMAC as getHMACAction,
    updateMessageCount as updateMessageCountAction,
} from '../../actions';
import { Intercom } from './intercom';

const enhance = compose(
    withDeviceInfo,
    connect(
        createStructuredSelector({
            user: userSelector,
            activeStore: activeStoreSelector,
            isLoggedIn: isLoggedInSelector,
            flags: featureFlagSelector,
            intercomHMAC: intercomHMACSelector,
            hasCookiePolicyConsent: hasCookiePolicyConsentSelector,
            initDone: initDoneSelector,
        }),
        (dispatch) => ({
            getHMAC: bindActionCreators(getHMACAction, dispatch),
            updateMessageCount: bindActionCreators(updateMessageCountAction, dispatch),
        }),
    ),
);

export default enhance(Intercom);

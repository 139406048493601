/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
import React, { PureComponent } from 'react';
import cn from 'classnames';
import omit from 'lodash/omit';
import LazyLoad from 'vanilla-lazyload';

import breakpoints from 'ggApp/shared/components/viewport/breakpoints';

import { Picture, Image } from './styles';

const defaultSizeValue = '100vw';

// listed dictionary of available types that comes from backend
export const SRC_WIDTH_MAP = {
    mini_square: 320,
    small_square: 640,
    dashboard_square: 960,
    medium_square: 1280,
    large_square: 1600,
    x_large_square: 1920,
    x2_large_square: 2240,
};

/**
 * @description build "sizes" img tag's attribute value from config
 * with structure similar to the breakpoint's one
 * @param sizes {object} - breakPoint params with following structure:
 * {
 *   breakpointKey: "400px",
 *   ...
 *   default: "100vw",
 * }
 * all keys are optional,
 * every breakpointKey represent part of a value of "sizes" attribute
 * with media query condition of the breakpoint
 * "default" is value that will be used if none of other media queries condition are matched
 * @returns {string} sizes attribute for img tag
 */
function buildSizeConfig(sizes) {
    if (!sizes) {
        return;
    }
    const config = Object.keys(sizes).reduce((accum, key) => {
        if (breakpoints[key]) {
            accum.push(
                `(min-width: ${breakpoints[key].min}px) and (max-width: ${breakpoints[key].max}px) ${sizes[key]}`,
            );
        }
        return accum;
    }, []);
    config.push(sizes.default ? sizes.default : defaultSizeValue);
    return config.join(', ');
}

const ResolutionImage = (
    srcSetBuildingFunction,
    srcEnhanceFunction = (x) => x,
    withWebpSrcSet = false,
) =>
    class ProductResolutionResizeImage extends PureComponent {
        constructor(props) {
            super(props);
            this.state = {
                webpSrcSet: withWebpSrcSet && srcSetBuildingFunction(props.imgUrls, 'webp'),
                srcset: srcSetBuildingFunction(props.imgUrls, 'jpg'),
                src: srcEnhanceFunction(props.src, 'jpg'),
                sizes: buildSizeConfig(props.sizes),
            };
        }

        componentDidMount() {
            this.initiateLazyLoad();
        }

        componentWillReceiveProps(nextProps) {
            const { src, imgUrls, sizes } = nextProps;
            if (this.props.imgUrls !== imgUrls) {
                this.srcIsChanged = true;
                this.updateSrc({ src, imgUrls, sizes });
            }
        }

        lazy = () => this.props.lazy && !this.srcIsChanged;

        updateSrc = ({ src, imgUrls, sizes }) => {
            this.setState({
                webpSrcSet: withWebpSrcSet && srcSetBuildingFunction(imgUrls, 'webp'),
                srcset: srcSetBuildingFunction(imgUrls, 'jpg'),
                src: srcEnhanceFunction(src),
                sizes: buildSizeConfig(sizes),
            });
        };

        initiateLazyLoad = () => {
            const { lazy } = this.props;
            if (document && lazy) {
                if (!document.lazyLoadInstance) {
                    document.lazyLoadInstance = new LazyLoad();
                }
                document.lazyLoadInstance.update();
            }
        };

        render() {
            const { className, onLoad, lazy = true, alt, refFunc, ...restProps } = this.props;
            const srcProps = {
                [this.lazy() ? 'data-src' : 'src']: this.state.src,
                [this.lazy() ? 'data-srcset' : 'srcSet']: this.state.srcset,
            };
            const webpSrcSet = {
                [this.lazy() ? 'data-srcset' : 'srcSet']: this.state.webpSrcSet,
            };

            return (
                <Picture className={className} ref={refFunc} onLoad={onLoad}>
                    {withWebpSrcSet && <source {...webpSrcSet} type="image/webp" />}
                    <Image
                        className={cn({ lazy })}
                        {...omit(restProps, [
                            'src',
                            'imgUrls',
                            'onEnterCallback',
                            'onExitCallback',
                        ])}
                        {...srcProps}
                        alt={alt}
                        sizes={this.sizes}
                    />
                </Picture>
            );
        }
    };

export default ResolutionImage;

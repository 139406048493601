import { createSelector } from 'reselect';
import { currentProduct, currentVariant, listData as productList } from 'ggApp/modules/product';
import {
    selectedRentalPlanSelector,
    currentProductRentalPlans,
} from 'ggApp/modules/rentalPlan/selectors';
import { user as userSelector } from 'ggApp/modules/auth/selectors';
import { storeActive } from 'ggApp/modules/store/selectors';
import {
    mixEnabled as mixEnabledSelector,
    showProductDiscountPercentageChip as showProductDiscountPercentageChipSelector,
    showLowStockChip as showLowStockChipSelector,
    showCountdownTimer as showCountdownTimerSelector,
} from 'ggApp/modules/featureFlag/selectors';
import {
    getOrderFromCookiesBasedStore as getOrderFromCookies,
    getOrderTypeFromCookies,
} from 'ggApp/shared/context/Order/cookies';
import { activeJson as activeFilterSelector } from 'ggApp/modules/filter/selectors';
import { ANALYTIC_EVENTS } from 'ggApp/modules/gaAnalytics/constants';
import {
    deliveryEstimates as deliveryEstimatesSelector,
    promotions as promotionsSelector,
} from 'ggApp/modules/product/selectors';
import { filterCountdowns } from 'ggApp/shared/components/CountdownTimer/utils';
import { cheapestRentalPlanInfo, formatPrice } from '../utils/index';

import {
    productCategories,
    productCurrency,
    productInfo,
    productId,
    lineItemProductInfo,
    productQuantity,
    getMixPayload,
    getProductDataForWishlist,
    getProductDataForCartContentChange,
} from './product';
import {
    cheapestRentalPlanInfoFromPlan,
    discountInfo,
    rentalPlanInfo,
    getPriceInfo,
} from './rentalPlan';
import { productDeliveryInfo } from './delivery';
import { storeInfo } from './store';

const lineItemPayload = (storeCode, quantity) => (product) => {
    const { selectedPlan: minimumMonths, priceCents: price, variant } = product;
    return {
        ...productInfo(variant),
        ...lineItemProductInfo(variant),
        ...cheapestRentalPlanInfoFromPlan({ rentalPlanPriceCents: price }),
        ...productId(variant),
        ...rentalPlanInfo({ minimumMonths, price }),
        ...productDeliveryInfo(),
        ...storeInfo(storeCode),
        ...productQuantity(product, quantity),
    };
};

const productInListPayload = (product, listName, position) => {
    if (!product.cheapest_rental_plan) return null;
    const {
        minimum_term_months: minimumMonths,
        rental_plan_price_cents: price,
        old_price: oldPrice,
    } = product.cheapest_rental_plan;
    const productVariant = (product.variants || [])[0];
    if (!productVariant) return null;
    return {
        ...productInfo(product),
        ...cheapestRentalPlanInfo(product),
        ...productId(product),
        ...rentalPlanInfo({ minimumMonths, price, oldPrice }),
        ...discountInfo({ price, oldPrice }),
        list: listName,
        position,
    };
};

export * from './delivery';
export * from './product';
export * from './rentalPlan';

export const detailedProductImpressionSelectorV2 = ({
    productSelector = currentProduct,
    productVariantSelector = currentVariant,
    rentalPlanSelector = selectedRentalPlanSelector,
}) =>
    function detailedProductImpressionSelector(state) {
        const product = productSelector(state);
        const productVariant = productVariantSelector(state);
        const rentalPlan = rentalPlanSelector(state);
        const productPayload = {
            ...productInfo(product),
            ...cheapestRentalPlanInfo(product),
            ...productId(productVariant),
            ...rentalPlanInfo(rentalPlan),
            ...discountInfo(rentalPlan),
            ...productDeliveryInfo(),
        };
        return {
            event: ANALYTIC_EVENTS.productDetailVImpression,
            ecommerce: {
                detail: {
                    products: [productPayload],
                },
            },
        };
    };
export const detailedProductImpressionSelector = createSelector(
    currentProduct,
    currentVariant,
    selectedRentalPlanSelector,
    (product, variant, rentalPlan) => {
        const productPayload = {
            ...productInfo(product),
            ...cheapestRentalPlanInfo(product),
            ...productId(variant),
            ...rentalPlanInfo(rentalPlan),
            ...discountInfo(rentalPlan),
            ...productDeliveryInfo(),
        };
        return {
            event: ANALYTIC_EVENTS.productDetailVImpression,
            ecommerce: {
                detail: {
                    products: [productPayload],
                },
            },
        };
    },
);

export const productImpressionSelector = (listName, position, state, listSelector = productList) =>
    createSelector(listSelector, (list) => {
        const product = list[position];
        if (!product) return null;
        const productVariant = (product.variants || [])[0];
        if (!productVariant) return null;
        return {
            event: ANALYTIC_EVENTS.productImpression,
            ecommerce: {
                currencyCode: productCurrency(productVariant),
                impressions: [productInListPayload(product, listName, position)],
            },
        };
    })(state);

export const productImpressionSnowplowSelector = (listName, position, listSelector = productList) =>
    createSelector(
        listSelector,
        storeActive,
        userSelector,
        activeFilterSelector,
        mixEnabledSelector,
        (list, store, user, filter, mixEnabled) => {
            const activeFilter = filter.rental_plan || 12;
            const product = list[position];
            if (!product) return null;
            const productVariant = (product.variants || [])[0];
            if (!productVariant || !product.rental_plans) return null;
            const orderNumber = getOrderFromCookies(store.code) || null;
            const { name, sku, brand, category } = product;
            const { title: categoryTitle } = category || {};
            const variant = product.variants[0];
            const { mixable, id } = variant;
            const filteredPlan = product.rental_plans.find(
                (plan) => plan.minimum_term_months.toString() === activeFilter,
            );
            const rentalPlan = filteredPlan || product.cheapest_rental_plan;
            const price = rentalPlan.rental_plan_price_cents;
            const nonDiscountedPrice = rentalPlan.old_price || price;
            const discountAmount = formatPrice(nonDiscountedPrice - price);
            const discountPercentage = Math.round(
                ((nonDiscountedPrice - price) / nonDiscountedPrice) * 100,
            );
            const deliveryTime = productDeliveryInfo();
            const orderMode = getOrderTypeFromCookies(store.code);
            const mixCartCount = orderMode === 'FLEX' ? 0 : null;
            const mixData = {
                mix_cart_count: mixCartCount,
            };
            return {
                event: ANALYTIC_EVENTS.productImpressionSnowplow,
                eventCategory: 'productDiscovery',
                eventAction: 'productImpression',
                eventLabel: sku,
                eventProperty: {
                    store: store.store_id,
                    orderID: orderNumber,
                    userID: user.id || null,
                    list: listName,
                    productData: {
                        name,
                        productSKU: sku,
                        productVariant: id,
                        brand,
                        sub_category: categoryTitle,
                        subscriptionLength: activeFilter,
                        price,
                        non_discountedPrice: nonDiscountedPrice,
                        discountAmount: discountAmount || '0.00',
                        discountPercentage: discountPercentage || 0,
                        deliveryTime: deliveryTime.dimension17,
                        ...(mixEnabled && {
                            mixable,
                        }),
                    },
                    ...(mixEnabled && mixData),
                },
            };
        },
    );

export const productClickEventSelector = (listName, position, state, listSelector = productList) =>
    createSelector(listSelector, (list) => {
        const product = list[position];
        if (!product) return null;
        return {
            event: ANALYTIC_EVENTS.productClick,
            ecommerce: {
                click: {
                    actionField: { list: listName },
                    products: [productInListPayload(product, listName, position)],
                },
            },
        };
    })(state);

export const productClickEventSnowplowSelector = (listName, position, listSelector = productList) =>
    createSelector(
        listSelector,
        storeActive,
        userSelector,
        activeFilterSelector,
        mixEnabledSelector,
        (list, store, user, filter, mixEnabled) => {
            const activeFilter = filter.rental_plan || 12;
            const product = list[position];
            if (!product || !product.rental_plans.length) return null;
            const productVariant = (product.variants || [])[0];
            if (!productVariant) return null;
            const orderNumber = getOrderFromCookies(store.code) || null;
            const { name, sku } = product;
            const variant = product.variants[0];
            const { mixable, id } = variant;
            const filteredPlan = product.rental_plans.find(
                (plan) => plan.minimum_term_months.toString() === activeFilter,
            );
            const rentalPlan = filteredPlan || product.cheapest_rental_plan;
            const price = rentalPlan.rental_plan_price_cents;
            const nonDiscountedPrice = rentalPlan.old_price || price;
            const discountAmount = formatPrice(nonDiscountedPrice - price);
            const discountPercentage = Math.round(
                ((nonDiscountedPrice - price) / nonDiscountedPrice) * 100,
            );
            const deliveryTime = productDeliveryInfo();
            const orderMode = getOrderTypeFromCookies(store.code);
            const mixCartCount = orderMode === 'FLEX' ? 0 : null;
            const mixData = {
                mix_cart_count: mixCartCount,
            };
            return {
                event: ANALYTIC_EVENTS.productClickSnowplow,
                eventCategory: 'productDiscovery',
                eventAction: 'productClick',
                eventLabel: sku,
                eventProperty: {
                    orderID: orderNumber,
                    userID: user.id || null,
                    list: listName,
                    position,
                    productData: {
                        name,
                        productSKU: sku,
                        productVariant: id,
                        subscriptionLength: activeFilter,
                        price,
                        non_discountedPrice: nonDiscountedPrice,
                        discountAmount: discountAmount || '0.00',
                        discountPercentage: discountPercentage || 0,
                        deliveryTime: deliveryTime.dimension17,
                        ...(mixEnabled && {
                            mixable,
                        }),
                    },
                    ...(mixEnabled && mixData),
                },
            };
        },
    );

export const productFavoriteEventSelector = ({
    isFavorite,
    product,
    currentVariant: variant,
    selectedRentalPlan,
}) => {
    const productData = getProductDataForWishlist({
        product,
        variant,
        selectedRentalPlan,
    });
    return {
        eventAction: isFavorite ? 'add_to_wishlist' : 'remove_from_wishlist',
        eventLabel: isFavorite ? 'add_to_wishlist' : 'remove_from_wishlist',
        eventProperty: {
            productData,
        },
    };
};

export const productEmptyFavoriteEvent = {
    eventCategory: 'wishlist',
    eventAction: 'wishlist_empty',
    eventLabel: 'wishlist_empty',
    eventNonInteraction: 'False',
    event: ANALYTIC_EVENTS.gtmEvent,
};

export const productInStockNotificationEvent = {
    eventCategory: 'not_available_feature',
    eventAction: 'not_available_click',
    eventLabel: 'not_available_click',
    eventNonInteraction: 'False',
    event: ANALYTIC_EVENTS.gtmEvent,
};

const ACCOUNT_MENU_EVENTS = {
    subscriptions: 'go_to_subscriptions',
    payment: 'go_to_payment',
    profile: 'go_to_profile',
    wishlist: 'go_to_wishlist',
    referrals: 'go_to_referrals',
    chat: 'go_to_chat',
    loggedOut: 'logged_out',
};

export const accountMenuEventSelector = (eventAction) =>
    ACCOUNT_MENU_EVENTS[eventAction] && {
        eventNonInteraction: 'False',
        event: ANALYTIC_EVENTS.gtmEvent,
        eventCategory: 'account_menu',
        eventAction: ACCOUNT_MENU_EVENTS[eventAction],
        eventLabel: ACCOUNT_MENU_EVENTS[eventAction],
    };

export const addToCartOrderSelector = (actionProductId, actionOrder) =>
    createSelector(storeActive, (store) => {
        const order = actionOrder;
        let product = null;
        if (actionProductId && order.items) {
            product = order.items.find(({ id }) => id === actionProductId);
        }
        if (!product || !actionProductId) return null;
        const { variant } = product;
        const { selectedPlan: minimumMonths, priceCents: price, oldPrice } = product;
        const productPayload = {
            ...productInfo(variant?.product),
            ...lineItemProductInfo(variant),
            ...cheapestRentalPlanInfoFromPlan({ rentalPlanPriceCents: price }),
            ...productId(variant),
            ...rentalPlanInfo({ minimumMonths, price, oldPrice }),
            ...discountInfo({ price, oldPrice }),
            ...productDeliveryInfo(),
            ...storeInfo(store.code),
            quantity: 1,
        };
        const newproductPayload = {
            ...productPayload,
            id: productPayload.variant,
        };
        return {
            event: ANALYTIC_EVENTS.addToBasket,
            ecommerce: {
                currencyCode: store.default_currency,
                orderID: order.number,
                add: {
                    products: [newproductPayload],
                },
            },
        };
    });

export const addToCartProductSelector = createSelector(
    storeActive,
    currentProduct,
    currentVariant,
    selectedRentalPlanSelector,
    (store, product, variant, rentalPlan) => {
        const productPayload = {
            ...productInfo(product),
            ...cheapestRentalPlanInfo(product),
            ...productId(variant),
            ...rentalPlanInfo(rentalPlan),
            ...discountInfo(rentalPlan),
            ...productDeliveryInfo(),
            ...storeInfo(store.code),
            quantity: 1,
        };
        const orderID = getOrderFromCookies(store.code);
        const eventName =
            variant['is_available?'] || store.code !== 'de'
                ? ANALYTIC_EVENTS.addToBasket
                : ANALYTIC_EVENTS.addToWaitingList;
        return {
            event: eventName,
            ecommerce: {
                currencyCode: store.default_currency,
                orderID,
                add: {
                    products: [productPayload],
                },
            },
            mixable: variant?.mixable ?? false,
        };
    },
);

export const removeFromCartOrderSelector = (product, quantity) =>
    createSelector(storeActive, (store) => ({
        event: ANALYTIC_EVENTS.removeFromBasket,
        ecommerce: {
            currencyCode: store.default_currency,
            remove: {
                products: [lineItemPayload(store.code, quantity)(product)],
            },
        },
    }));

export const checkoutSelector = (order, step) => ({
    event: ANALYTIC_EVENTS.eeCheckout,
    ecommerce: {
        currencyCode: productCurrency(order),
        checkout: {
            actionField: { step },
            products: order.items.map(lineItemPayload(order.store_code)),
        },
    },
});

export const purchaseSelector = (cart, activeStore) => {
    const discountCode = (cart.voucher && cart.voucher.name) || '';
    const discountAmount = formatPrice(cart.voucher && cart.voucher.discount_cents) || '';
    // eslint-disable-next-line camelcase
    const { store_type } = activeStore;
    const { orderNumber, totalPrice, shipmentPrice } = cart;
    return {
        event: ANALYTIC_EVENTS.eePurchase,
        ecommerce: {
            currencyCode: activeStore.default_currency,
            purchase: {
                actionField: {
                    id: orderNumber,
                    affiliation: store_type,
                    revenue: formatPrice(totalPrice),
                    tax: null,
                    shipping: formatPrice(shipmentPrice),
                    coupon: discountAmount,
                },
                products: (cart.items || []).map(lineItemPayload(cart.store_code)),
            },
            discountCode,
            discountAmount: formatPrice(discountAmount),
        },
    };
};

export const checkoutOptionsSelector = (order, step, option) => ({
    event: ANALYTIC_EVENTS.checkoutOptions,
    ecommerce: {
        currencyCode: productCurrency(order),
        checkout_option: {
            actionField: { step, option },
        },
    },
});

export const gPageProductSelector = (product) => {
    const {
        cheapest_rental_plan: {
            minimum_term_months: planDuration,
            rental_plan_price: price,
            old_price: oldPrice,
            rental_plan_price_cents: priceInCents,
        },
    } = product;
    const variant = product.variants && product.variants[0];
    const originalPrice = oldPrice || priceInCents;
    const discountAmount = formatPrice(originalPrice - priceInCents);
    const discountPercentage = Math.round(((originalPrice - priceInCents) / originalPrice) * 100);
    return {
        name: product.frontname,
        productSKU: product.sku,
        productVariant: variant.id,
        subscriptionLength: planDuration,
        price,
        non_discountedPrice: formatPrice(originalPrice),
        discountAmount,
        discountPercentage,
    };
};

// TODO: @analytics Remove snowplowProductImpressionsSelector and add types to productViewedAnalyticsSelector
export const productViewedAnalyticsSelector = createSelector(
    currentProduct,
    currentVariant,
    selectedRentalPlanSelector,
    deliveryEstimatesSelector,
    storeActive,
    (product, variant = {}, rentalPlan = {}, deliveryEstimates, store) => {
        const orderID = getOrderFromCookies(store.code) || undefined;
        const { sku, name } = product;
        const deliveryTime =
            (deliveryEstimates && `${deliveryEstimates.from}-${deliveryEstimates.to}`) || undefined;
        const priceInfo = getPriceInfo(rentalPlan, false);
        const categories = productCategories(product.category.permalink);

        return {
            brand: product.brand,
            currency: product.currency,
            category: categories.category,
            delivery_time: deliveryTime,
            discount_amount: Number(priceInfo.discountAmount),
            discount_percentage: Number(priceInfo.discountPercentage),
            displayed_price: Number(priceInfo.price),
            name,
            image_url: product.original_image_url,
            non_discounted_price: Number(priceInfo.non_discountedPrice),
            order_id: orderID,
            price: Number(priceInfo.price),
            product_id: sku,
            product_name: name,
            product_sku: sku,
            product_variant: String(variant.id),
            sku,
            store_id: store.store_id,
            sub_category: categories.dimension13,
            subscription_length: 3,
            variant: String(variant.id),
            variant_sku: variant.sku,
            quantity: 1,
        };
    },
);

export const snowplowProductImpressionsSelector = createSelector(
    currentProduct,
    userSelector,
    currentVariant,
    selectedRentalPlanSelector,
    deliveryEstimatesSelector,
    mixEnabledSelector,
    storeActive,
    currentProductRentalPlans,
    promotionsSelector,
    showProductDiscountPercentageChipSelector,
    showLowStockChipSelector,
    showCountdownTimerSelector,
    (
        product,
        user,
        variant = {},
        rentalPlan = {},
        deliveryEstimates,
        mixEnabled,
        store,
        productRentalPlans,
        promotions,
        showProductDiscountPercentageChip,
        showLowStockChip,
        showCountdownTimer,
    ) => {
        const orderID = getOrderFromCookies(store.code) || null;
        const {
            id,
            sku,
            name,
            brand,
            sub_category_name: subCategoryName,
            parent_category: parentCategory,
            image_url: imageUrl,
            variants,
        } = product;
        const { title: parentCategoryTitle } = parentCategory || {};
        const { minimumMonths } = rentalPlan;
        const deliveryTime =
            (deliveryEstimates && `${deliveryEstimates.from}-${deliveryEstimates.to}`) || null;
        const isMixOrder = orderID ? orderID[0] === 'M' : null;
        const instockProduct = Boolean(
            variants && variants.some((current) => current['is_available?']),
        );
        // eslint-disable-next-line camelcase
        const rental_plans_visible = productRentalPlans.map((plan) => plan.minimumMonths);
        const promotionsData = promotions?.products?.find(
            (promoProduct) => String(promoProduct.id) === String(id),
        );
        const countdown = filterCountdowns({
            countdowns: promotionsData?.countdownTimers,
            activeStoreCode: store.code,
        });
        const lowStockLabel = Boolean(
            promotionsData?.variants?.find(
                (promoVariant) =>
                    String(promoVariant?.id) === String(variant?.id) &&
                    promoVariant?.flags?.find((flag) => flag?.name === 'Low stock'),
            ) && showLowStockChip,
        );
        const discountPercentageBadge = Boolean(
            rentalPlan.oldPrice && showProductDiscountPercentageChip,
        );
        return {
            event: ANALYTIC_EVENTS.pdpView,
            eventCategory: 'productDiscovery',
            eventAction: 'pdpView',
            eventLabel: sku,
            eventProperty: {
                store: store.store_id,
                userID: user.id || null,
                orderID,
                rental_plans_visible,
                productData: {
                    name,
                    productSKU: sku,
                    brand,
                    category: parentCategoryTitle,
                    sub_category: subCategoryName,
                    productVariant: variant.id || null,
                    subscriptionLength: !isMixOrder ? minimumMonths : 3,
                    ...getPriceInfo(rentalPlan, mixEnabled && isMixOrder),
                    deliveryTime,
                    imageUrl,
                    ...(mixEnabled && {
                        mixable: variant.mixable,
                        interacting_with_mix: isMixOrder,
                    }),
                    instockProduct,
                    countdownTimer: showCountdownTimer && countdown?.id ? countdown.id : null,
                    lowStockLabel,
                    discountPercentageBadge,
                },
                ...(mixEnabled &&
                    orderID && {
                        ...getMixPayload({ count: null }),
                    }),
            },
        };
    },
);

export const addToCartSnowplowSelector = (payloadData, order) =>
    createSelector(userSelector, mixEnabledSelector, storeActive, (user, mixEnabled, store) => {
        const orderID = (order && order.orderNumber) || null;
        const productData = payloadData.ecommerce.add.products[0];
        const isMixOrder = orderID ? orderID[0] === 'M' : null;
        const subCategory = productData.dimension13 || '';
        const categoryCapitalize =
            subCategory.length && subCategory[0].toUpperCase() + subCategory.slice(1);
        const item = order.items.find((i) => i?.variant?.id === +productData.variant);
        const imageUrl = item ? item.imageUrl : null;
        return {
            event: ANALYTIC_EVENTS.basket,
            eventCategory: 'checkout',
            eventAction: 'addToCart',
            eventLabel: productData.id,
            eventProperty: {
                orderID,
                userID: user.id,
                store: store.store_id,
                productData: {
                    name: productData.name,
                    brand: productData.brand,
                    sub_category: categoryCapitalize,
                    productSKU: productData.id,
                    productVariant: productData.variant,
                    subscriptionLength: !isMixOrder ? productData.dimension12 : 3,
                    price: !isMixOrder ? productData.metric2 : null,
                    non_discountedPrice: !isMixOrder ? productData.metric3 : null,
                    discountAmount: !isMixOrder ? productData.discountAmount : null,
                    discountPercentage: !isMixOrder ? productData.discountPercentage : null,
                    deliveryTime: productData.dimension17,
                    imageUrl,
                    ...(mixEnabled && {
                        // eslint-disable-next-line no-undef
                        mixable: payloadData?.mixable,
                        interacting_with_mix: isMixOrder,
                    }),
                },
                ...(mixEnabled &&
                    orderID && {
                        ...getMixPayload({ count: isMixOrder ? order?.items?.length : 0 }),
                    }),
            },
        };
    });

// TODO: @analytics Remove addToCartSnowplowSelector and add types to productAddedAnalyticsSelector
export const productAddedAnalyticsSelector = (payloadData, order) =>
    createSelector(storeActive, currentProduct, (store, product) => {
        const orderID = order?.orderNumber;
        const productData = payloadData.ecommerce.add.products[0];
        return {
            brand: productData.brand,
            currency: product.currency,
            category: productData.category,
            delivery_time: productData.dimension17,
            discount_amount: Number(productData.discountAmount),
            discount_percentage: Number(productData.discountPercentage),
            displayed_price: Number(productData.metric2),
            name: productData.name,
            image_url: product.original_image_url,
            non_discounted_price: Number(productData.metric3),
            order_id: orderID,
            price: Number(productData.metric2),
            product_id: productData.id,
            product_name: productData.name,
            product_sku: productData.id,
            product_variant: productData.variant,
            sku: productData.id,
            store_id: store.store_id,
            sub_category: productData.dimension13 || '',
            subscription_length: Number(productData.dimension12),
            variant: productData.variant,
            quantity: 1,
        };
    });

export const removeFromCartSnowplowSelector = (payloadData, mixable, order) =>
    createSelector(userSelector, mixEnabledSelector, storeActive, (user, mixEnabled, store) => {
        const orderID = (order && order.orderNumber) || null;
        const productData = payloadData.ecommerce.remove.products[0];
        const discountAmount = formatPrice(productData.metric3 - productData.metric2);
        const discountPercentage = Math.round(
            ((productData.metric3 - productData.metric2) / productData.metric3) * 100,
        );
        const isMixOrder = orderID ? orderID[0] === 'M' : null;
        const subCategory = productData.dimension13 || '';
        const categoryCapitalize =
            subCategory.length && subCategory[0].toUpperCase() + subCategory.slice(1);
        return {
            event: ANALYTIC_EVENTS.basket,
            eventCategory: 'checkout',
            eventAction: 'removeFromCart',
            eventLabel: productData.id,
            eventProperty: {
                orderID,
                userID: user.id,
                store: store.store_id,
                productData: {
                    name: productData.name,
                    brand: productData.brand,
                    sub_category: categoryCapitalize,
                    productSKU: productData.id,
                    productVariant: productData.variant,
                    subscriptionLength: !isMixOrder ? productData.dimension12 : 3,
                    price: !isMixOrder ? productData.metric2 : null,
                    non_discountedPrice: !isMixOrder ? productData.metric3 : null,
                    discountAmount: !isMixOrder ? discountAmount : null,
                    discountPercentage: !isMixOrder ? discountPercentage : null,
                    deliveryTime: productData.dimension17,
                    ...(mixEnabled && {
                        // eslint-disable-next-line no-undef
                        mixable,
                        interacting_with_mix: isMixOrder,
                    }),
                },
                ...(mixEnabled &&
                    orderID && {
                        ...getMixPayload({ count: isMixOrder ? order?.items?.length : 0 }),
                    }),
            },
        };
    });

export const cartContentChangeActionSelector = (item) => {
    if (item.rental_plan_id || item.committed_months) {
        return 'planChanged';
    }
    if (item.variant_id) {
        return 'colorChanged';
    }
    return null;
};

export const cartContentChangeSnowplowSelector = (item, cart) =>
    createSelector(userSelector, storeActive, (user, store) => {
        if (!item || !cart.items) return null;

        const product = cart.items.find(({ id }) => id === item.id);

        if (!product) {
            return null;
        }

        const productData = getProductDataForCartContentChange({ product, item });
        const orderID = (cart && cart.orderNumber) || null;

        return {
            event: ANALYTIC_EVENTS.basket,
            eventLabel: productData.id,
            eventProperty: {
                orderID,
                userID: user.id,
                store: store.store_id,
                productData,
            },
        };
    });

import { sendStructEvent } from '../../api';
import { EventProperty } from '../../types';

interface PayloadModel {
    eventCategory: string;
    eventAction: string;
    eventLabel: string;
    eventProperty: EventProperty;
}

export const sendProductImpressionEvent = (payload: PayloadModel): void => {
    const { eventLabel, eventAction, eventProperty, eventCategory } = payload;
    sendStructEvent({
        eventCategory,
        eventAction,
        eventLabel,
        eventProperty,
    });
};

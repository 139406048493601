import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import Spinner from './Spinner.web';

const CircleLoader = ({ className, ...rest }) => (
    <Container className={className} {...rest}>
        <Spinner />
    </Container>
);

CircleLoader.propTypes = {
    className: PropTypes.string,
};

CircleLoader.defaultProps = {
    className: '',
};

export default CircleLoader;

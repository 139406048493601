import { formatPrice } from '../utils/index';

export function rentalPlanInfo(rentalPlanFromState) {
    const { minimumMonths, price, oldPrice } = rentalPlanFromState;
    const originalPrice = oldPrice || price;
    const totalPackagePrice = formatPrice(minimumMonths * price);
    return {
        price: totalPackagePrice, // total price duration * month price
        metric2: formatPrice(price), // current plan price
        metric3: formatPrice(originalPrice), // 'price' without discounts (if any)
        dimension12: minimumMonths, // duration
    };
}

export function cheapestRentalPlanInfoFromPlan(plan) {
    const planPrice = plan.rental_plan_price_cents || plan.rentalPlanPriceCents;
    return {
        metric1: formatPrice(planPrice), // cheapest plan
    };
}

export function discountInfo(rentalPlanFromState) {
    const { price, oldPrice } = rentalPlanFromState;

    const originalPrice = oldPrice || price;
    const discountAmount = formatPrice(originalPrice - price);
    const discountPercentage = Math.round(((originalPrice - price) / originalPrice) * 100);
    const discountInterval =
        originalPrice === price
            ? '0-10'
            : `${Math.floor(discountPercentage / 10) * 10}-${Math.ceil(discountPercentage / 10) *
                  10}`;
    return {
        discountAmount,
        discountPercentage,
        dimension15: discountInterval,
    };
}

export function getPriceInfo({ price, oldPrice }, isMix) {
    if (isMix) {
        return {
            discountAmount: null,
            discountPercentage: null,
            non_discountedPrice: null,
            price: null,
        };
    }
    const { discountAmount, discountPercentage } = discountInfo({ price, oldPrice });
    return {
        discountAmount,
        discountPercentage,
        non_discountedPrice: formatPrice(oldPrice || price),
        price: formatPrice(price),
    };
}

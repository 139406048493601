/* eslint-disable */
import { ANALYTIC_EVENTS, SHARING_PDP_EVENTS } from 'ggApp/modules/gaAnalytics/constants';
import {
    currentProduct as currentProductSelector,
    currentVariant as currentVariantSelector,
} from 'ggApp/modules/product/selectors';
import { formatIfNumber } from 'ggApp/utils/money';
import { formatPrice } from 'ggApp/modules/gaAnalytics/utils';
import { user as userSelector } from 'ggApp/modules/auth/selectors';
import { cheapestRentalPlanSelector } from 'ggApp/modules/rentalPlan/selectors';
import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import { getDeliveryEstimation } from 'staticStoreConfig';

export const getProductSharingEvent = ({ eventName, listName, socialNetwork }) => (getState) => {
    const state = getState();
    const store = activeStoreSelector(state);
    const product = currentProductSelector(state);
    const planFor1Month = product.rental_plans.find((plan) => plan.minimum_term_months === 1);
    const planFor12Month = product.rental_plans.find((plan) => plan.minimum_term_months === 12);
    const cheapestPlan = cheapestRentalPlanSelector(state) || {};
    const originalPrice = cheapestPlan.oldPrice || cheapestPlan.price;
    const discountAmount = formatPrice(originalPrice - cheapestPlan.price) || null;
    const discountPercentage =
        Math.round(((originalPrice - cheapestPlan.price) / originalPrice) * 100) || null;
    const user = userSelector(state);
    const currentVariant = currentVariantSelector(state);
    const deliveryEstimates =
        (currentVariant && currentVariant.delivery_estimates) || getDeliveryEstimation(store.code);
    const productForEvent = {
        productName: product.name,
        productSKU: product.sku,
        productVariant: currentVariant && currentVariant.id,
        brand: product.brand,
        category: product.category_name,
        'sub-category': product.sub_category_name,
        '1monthPrice': (planFor1Month && planFor1Month.rental_plan_price) || null,
        '12monthPrice': (planFor12Month && planFor12Month.rental_plan_price) || null,
        '1_non_discountedPrice': (planFor1Month && formatIfNumber(planFor1Month.old_price)) || null,
        '12_non_discountedPrice':
            (planFor12Month && formatIfNumber(planFor12Month.old_price)) || null,
        subscriptionLength: cheapestPlan && cheapestPlan.minimumMonths,
        discountAmount,
        discountPercentage,
        shippingTime: `${deliveryEstimates.from}-${deliveryEstimates.to}`,
        userID: (user && user.id) || null,
        ...(eventName === SHARING_PDP_EVENTS.exitBySharingEvent && {
            social_network: socialNetwork,
        }),
    };
    return {
        event: ANALYTIC_EVENTS.gtmEvent,
        eventAction: listName,
        eventCategory: 'productSharing',
        eventLabel: eventName,
        eventProperty: productForEvent,
    };
};

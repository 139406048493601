// TODO remove once store list is loaded on CI/CD step
export default [
    {
        country_code: 'DE',
        code: 'de',
        default_language: 'de',
        languages: ['en'],
        store_type: 'main',
        is_consent_cookie_modal_disabled: true,
        delivery_days_from: 1,
        delivery_days_to: 3,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: true,
        show_national_id_step: false,
    },
    {
        country_code: 'AT',
        code: 'at',
        default_language: 'de',
        languages: ['en'],
        store_type: 'main',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 5,
        delivery_days_to: 8,
        is_signup_disabled: false,
        is_business_signup_disabled: true,
        is_private_signup_disabled: false,
        enabled_in_store_selector: true,
        show_national_id_step: false,
    },
    {
        country_code: 'NL',
        code: 'nl',
        default_language: 'nl',
        languages: ['en'],
        store_type: 'main',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 5,
        delivery_days_to: 8,
        is_signup_disabled: false,
        is_business_signup_disabled: true,
        is_private_signup_disabled: false,
        enabled_in_store_selector: true,
        show_national_id_step: false,
    },
    {
        country_code: 'ES',
        code: 'es',
        default_language: 'es',
        languages: ['en'],
        store_type: 'main',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 1,
        delivery_days_to: 3,
        is_signup_disabled: false,
        is_business_signup_disabled: true,
        is_private_signup_disabled: false,
        enabled_in_store_selector: true,
        show_national_id_step: true,
    },
    {
        country_code: 'US',
        code: 'us',
        default_language: 'en',
        languages: ['en'],
        store_type: 'main',
        is_consent_cookie_modal_disabled: true,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: true,
        is_private_signup_disabled: false,
        enabled_in_store_selector: true,
        show_national_id_step: false,
    },
    {
        country_code: 'US',
        code: 'business_us',
        default_language: 'en',
        languages: ['en'],
        store_type: 'main-business',
        is_consent_cookie_modal_disabled: true,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: true,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'business',
        default_language: 'de',
        languages: ['en'],
        store_type: 'main-business',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'AT',
        code: 'business_at',
        default_language: 'de',
        languages: ['en'],
        store_type: 'main-business',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: true,
        is_business_signup_disabled: false,
        is_private_signup_disabled: true,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'NL',
        code: 'business_nl',
        default_language: 'nl',
        languages: ['en'],
        store_type: 'main-business',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: true,
        is_business_signup_disabled: false,
        is_private_signup_disabled: true,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'ES',
        code: 'business_es',
        default_language: 'es',
        languages: ['en'],
        store_type: 'main-business',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: true,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: true,
    },
    {
        country_code: 'DE',
        code: 'mediamarkt',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'ssrecords',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'actioncam24',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'camxpert',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'caseking',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'conrad',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'conrad_b2b',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'demo',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'djplanet',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'djtechnik',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'fotowichern',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'gravis_online',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'grover_go',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'hifi',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'inventorum',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'experte',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'medimax',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'mediamarkt_s10',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'AT',
        code: 'otto_austria',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: true,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'qbo',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'saturn',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'tchibo',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'tchibo_affiliate',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'thalia_online',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'thalia_online',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'AT',
        code: 'universal_online',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: true,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'AT',
        code: 'quelle_online',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: true,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'weltbild',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: true,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'samsung',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: true,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
    {
        country_code: 'DE',
        code: 'irobot_online',
        default_language: 'de',
        languages: ['en'],
        store_type: 'grover_pay',
        is_consent_cookie_modal_disabled: false,
        delivery_days_from: 3,
        delivery_days_to: 7,
        is_signup_disabled: false,
        is_business_signup_disabled: false,
        is_private_signup_disabled: false,
        enabled_in_store_selector: false,
        show_national_id_step: false,
    },
];

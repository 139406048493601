/* eslint-disable camelcase */
import { getDeliveryEstimation } from 'staticStoreConfig';

function formatPrice(price) {
    return (price / 100).toFixed(2);
}

export const productDataMapper = ({ product, storeCode }) => {
    const name = product.frontname ?? product.name ?? '';
    const productSKU = product.sku;
    const priceCents =
        product.cheapest_rental_plan?.rental_plan_price_cents ??
        product?.rentalPlans?.[0]?.price?.inCents ??
        null;
    const price = formatPrice(priceCents);
    const subscriptionLength =
        product.cheapest_rental_plan?.minimum_term_months ??
        product?.rentalPlans?.[0]?.length?.value ??
        null;
    const productVariant = product.variants?.[0]?.id ? `${product.variants?.[0].id}` : null;
    const oldPriceCents =
        product.cheapest_rental_plan?.old_price ??
        product?.rentalPlans?.[0]?.oldPrice?.inCents ??
        null;
    const non_discountedPrice = oldPriceCents ? formatPrice(oldPriceCents) : null;
    const discountAmount = non_discountedPrice ? formatPrice(oldPriceCents - priceCents) : null;
    const discountPercentage = discountAmount
        ? Math.round(((oldPriceCents - priceCents) / oldPriceCents) * 100)
        : null;
    const deliveryEstimates = getDeliveryEstimation(storeCode) ?? null;
    const deliveryTime = deliveryEstimates
        ? `${deliveryEstimates.from}-${deliveryEstimates.to}`
        : null;
    const productData = {
        name,
        productSKU,
        price,
        subscriptionLength,
        productVariant,
        non_discountedPrice,
        discountAmount,
        discountPercentage,
        deliveryTime,
    };
    return productData;
};

import React from 'react';
import { Svg, Path } from 'react-native-svg';

type IconProps = {
    size?: string | number;
    color?: string;
    className?: string;
};

const BoxIcon = ({ size = 24, color = '#333333', className }: IconProps): JSX.Element => (
    <Svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 1.90625L22 6.35069V17.6504L12 22.0949L2 17.6504V6.35069L12 1.90625ZM4 8.53933V16.3507L11 19.4618V11.6504L4 8.53933ZM13 11.6504V19.4618L20 16.3507V8.53933L13 11.6504ZM18.5378 7.00057L12 4.09489L9.96221 5.00057L16.5 7.90625L18.5378 7.00057ZM7.5 6.09488L5.46221 7.00057L12 9.90625L14.0378 9.00057L7.5 6.09488Z"
            fill={color}
        />
    </Svg>
);

export default BoxIcon;

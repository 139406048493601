import styled from 'styled-components';

import WidgetGrid from 'ggApp/shared/components/widgets/grid';
import { Card as WidgetProductCard } from 'ggApp/shared/components/cards/widgetProductCard';


const GridWrap = styled(WidgetGrid)`
    ${(props) => props.theme.superQuery.all.and.maxWidth.mobileMax.css`
        padding: 0 ${props.theme.spacers.L};
        grid-row-gap: ${props.theme.spacers.L};
        grid-auto-rows: unset;
    `};

    ${props => props.theme.superQuery.minWidth.tabletMin.and.maxWidth.tabletMax
        .css`
        margin: 0 ${({theme}) => theme.spacers.L};
    `}
`;

const ProductCard = styled(WidgetProductCard)`
    grid-column: auto / span 3;
    ${({ theme }) => theme.superQuery.all.and.maxWidth.mobileMax.css`
        padding: ${theme.spacers.L};
        grid-column-start: span 4;
    `};

    ${props => props.theme.superQuery.minWidth.tabletMin.and.maxWidth.tabletMax
        .css`
        padding: 0 ${props.theme.spacers.L};
    `}
`;

export { GridWrap, ProductCard };

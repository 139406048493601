import { addEventListenerWithPromise, fireEvent } from '.';
import { POST_EVENTS } from './constants';

export const isNativeAuthEnabled = (timeout = 500): Promise<boolean> => {
    const promise = addEventListenerWithPromise(POST_EVENTS.NATIVE_AUTH_STATE_UPDATED, { timeout })
        .then(({ nativeAuthActive }) => nativeAuthActive)
        .catch(() => false);

    fireEvent(POST_EVENTS.REQUEST_NATIVE_AUTH_STATE);

    return promise;
};

/* eslint-disable */
import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_ERROR,
} from './../actionTypes';

const defaultState = {
  orderLoading: null,
  error: {},
};

export default function (state = defaultState, action) {
  switch (action.type) {

    case GET_ORDERS:
      return Object.assign({}, state, {
        orderLoading: true,
        error: {},
      })

    case GET_ORDERS_SUCCESS:
      return Object.assign({}, state, {
        orderLoading: false,
      })

    case GET_ORDERS_ERROR:
      return Object.assign({}, state, {
        orderLoading: false,
        error: action.error,
      })

    default:
      return state;
  }
}

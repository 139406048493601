/* eslint-disable */
import styled, { css } from 'styled-components';

import { Headline } from 'ggApp/shared/common/Typography';

import WidgetGrid from 'ggApp/shared/components/widgets/grid';
import WidgetCta from 'ggApp/shared/components/widgets/cta';
import { screens } from 'ggApp/utils/mixins/screens';

const Wrap = styled.div`
    background: ${({ theme }) => theme.palette.grey[4]};
    overflow: hidden;

    ${({ theme }) => theme.superQuery.all.and.minWidth.desktopMin.css`
        border-radius: 8px;
        padding: ${theme.spacers.XL};
    `};

    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
        padding: 0;
        margin-right: 0px;
        overflow: hidden;
        padding: ${theme.spacers.XL} ${theme.spacers.L};
    `};
`;

const Title = styled(Headline)`
    margin-bottom: ${({ theme }) => theme.spacers.L};
    ${({ theme }) => theme.typography.Headline};
    font-size: 32px;
    line-height: 1.3;
    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
        ${theme.typography.Subheadline};
    `};

    ${({ theme }) => theme.superQuery.all.and.maxWidth.mobileMax.css`
        margin-left: ${theme.spacers.L};
    `};
`;

const Grid = styled(WidgetGrid)`
    && {
        margin: ${({ theme }) => theme.spacers.L} 0px 0px;
    }
`;

const Cta = styled(WidgetCta)`
    align-self: flex-start;
    margin: ${({ theme }) => theme.spacers.XL} 0 0;
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;

    &:hover {
        background-color: white;
        color: ${({ theme }) => theme.colors.Foreground};

    }
    ${({ theme }) => theme.superQuery.all.and.maxWidth.mobileMax.css`
        position: relative;
    `};

    @media (${screens.phone}) and (${screens.tabletMax}) {
        margin: ${({ theme }) => theme.spacers.L} ${({ theme }) => theme.spacers.L} 0 ${({ theme }) => theme.spacers.L};
    }
`;

export {
    Wrap, Title, Grid, Cta,
};

/* eslint-disable no-restricted-globals */
import ResolutionImage, {
    SRC_WIDTH_MAP,
} from 'ggApp/shared/components/resolutionImage/ResolutionImage';
import contentfulImage from 'ggApp/utils/contentful/image';

function buildSrcSet(src, format) {
    const scrSetAsArray = Object.keys(SRC_WIDTH_MAP).reduce((accum, key) => {
        const size = SRC_WIDTH_MAP[key];
        const config = {
            width: size,
            height: size,
            format,
        };
        const srcCropped = contentfulImage(src, config);
        if (typeof srcCropped === 'string') {
            if (!isNaN(size)) {
                accum.push(`${srcCropped} ${size}w`);
            }
        }
        return accum;
    }, []);
    return scrSetAsArray.join(', ');
}

function enhanceSrc(src, format) {
    const config = {
        width: 800,
        height: 800,
        format,
    };
    return contentfulImage(src, config);
}
/**
 * For proper explanation of srcset, sizes img attributes usage please refer to link:
 * https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images
 * in a word, srcset is attribute for img's "src" switching based on viewport size (resolution)(DPR is taken into account too)
 * ex usage:
 * <ResolutionResizeImage
 *   imgUrls={{
 *      mini_square: 'mini_square_src',
        small_square: 'small_square_src',
        dashboard_square: 'dashboard_square_src',
        medium_square: 'medium_square_src',
        large_square: 'large_square_src',
        x_large_square: 'x_large_square_src',
        x2_large_square: 'x2_large_square_src',
 *   }}
 *    sizes={{
 *      mobile: 100px,
        tablet: 100vw,
        desktop: 40vw,
        default: 100vw,
 *   }}
 * />
 */

const withWebpSrcSet = true;
const ContentfulResolutionImage = ResolutionImage(buildSrcSet, enhanceSrc, withWebpSrcSet);
export const ContentfulImageWithNoFormat = ResolutionImage(buildSrcSet, enhanceSrc);

export default ContentfulResolutionImage;

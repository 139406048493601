import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, mapProps } from 'recompose';

import { activeJson, areFiltersVisible } from 'ggApp/modules/filter/selectors';
import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import { promotions as promotionsSelector } from 'ggApp/modules/product/selectors';
import { productAdapter } from 'ggApp/utils/product';
import { DEFAULT_VALUE_RENTAL_PLAN } from 'ggApp/modules/filter/constants';

export const withProductMapper = compose(
    connect(
        createStructuredSelector({
            store: activeStoreSelector,
            activeFilters: activeJson,
            promotions: promotionsSelector,
            filtersVisible: areFiltersVisible,
        }),
    ),
    mapProps(
        ({
            store: { default_currency: defaultCurrency, activeLanguage },
            product,
            activeFilters,
            mixCatalogFlag,
            mixGlobalFlag,
            promotions,
            filtersVisible,
            ...rest
        }) => {
            let minDurationWithoutFilters = DEFAULT_VALUE_RENTAL_PLAN;

            if (product.rental_plans && !filtersVisible) {
                // Array sort mutates the data, to prevent it using spread op
                // eslint-disable-next-line camelcase
                const sortedRentalPlans = [...product.rental_plans].sort(
                    (a, b) => b.minimum_term_months - a.minimum_term_months,
                );
                // eslint-disable-next-line camelcase
                minDurationWithoutFilters = sortedRentalPlans?.[0]?.minimum_term_months;
            }

            return {
                ...rest,
                product: productAdapter({
                    product,
                    defaultCurrency,
                    activeLanguage,
                    selectedMinDuration:
                        activeFilters != null && activeFilters.rental_plan
                            ? Number(activeFilters.rental_plan)
                            : minDurationWithoutFilters,
                    promotions,
                }),
            };
        },
    ),
);

export const withProductListMapper = compose(
    connect(
        createStructuredSelector({
            store: activeStoreSelector,
            promotions: promotionsSelector,
        }),
    ),
    mapProps(
        ({
            store: { default_currency: defaultCurrency, activeLanguage },
            products,
            promotions,
            ...rest
        }) => ({
            ...rest,
            products: products.map((_product) =>
                productAdapter({
                    product: _product,
                    defaultCurrency,
                    activeLanguage,
                    promotions,
                }),
            ),
        }),
    ),
);

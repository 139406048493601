import addLineItemMutation from './lineItemAdd.graphql';
import updateLineItemMutation from './lineItemChange.graphql';
import removeLineItemMutation from './lineItemRemove.graphql';
import { APISelection, LineItemInput } from '../../integration';
import { constructLineItem } from '../utils/lineItemFormat';

export const lineItem: APISelection<{
    items: Array<LineItemInput>;
    callback: (() => void) | null;
}> = {
    add: async ({ orderNumber }, event) => {
        if (!event?.items) return null;
        const { items, callback } = event;
        const item = items?.[0];
        const { CartServiceClient } = await import('../client');
        const lItem = constructLineItem(item);
        const { data, errors } = await CartServiceClient.mutation({
            mutation: addLineItemMutation,
            variables: { orderNumber, item: lItem },
        });
        if (callback && typeof callback === 'function') {
            callback();
        }
        if (errors) {
            return Promise.reject(errors);
        }
        return data;
    },
    delete: async ({ orderNumber }, event) => {
        if (!event?.item) return null;
        const { id } = event?.item;
        const { CartServiceClient } = await import('../client');
        const { data } = await CartServiceClient.mutation({
            mutation: removeLineItemMutation,
            variables: { orderNumber, itemId: Number(id) },
        });
        return data;
    },
    update: async ({ orderNumber }, event) => {
        if (!event?.item) return null;
        const { item, callback } = event;
        const { CartServiceClient } = await import('../client');
        const { data } = await CartServiceClient.mutation({
            mutation: updateLineItemMutation,
            variables: { orderNumber, item },
        });
        if (callback && typeof callback === 'function') {
            callback();
        }
        return data;
    },
};

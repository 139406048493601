import styled from 'styled-components';
import { Image } from 'cloudinary-react';

export const ImageContainer = styled.div`
    max-height: inherit;
    max-width: inherit;
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
`;

export const CloudinaryImage = styled(Image)`
    max-height: inherit;
    max-width: 100%;
    height: auto;
    width: auto;
`;

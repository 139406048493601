import styled, { css } from 'styled-components';
import CarouselV2 from 'ggApp/shared/components/Carousel/v2/Carousel';

export const ReviewsContainer = styled.div`
    margin-bottom: 40px;
`;

export const SummaryText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    ${({ theme }) => theme.superQuery.all.and.maxWidth.desktopMin.css`
        font-size: 14px;
        flex-direction: column;
    `}
`;

export const SummaryWord = styled.span`
    font-weight: bold;
    font-size: 22px;
    margin-right: 5px;
    ${({ theme }) => theme.superQuery.all.and.maxWidth.desktopMin.css`
        font-size: 14px;
    `}
`;

export const Bold = styled.span`
    font-weight: bold;
    margin-right: 5px;
    ${({ padLeft }) => padLeft && css`
        margin-left: 5px;
    `}
`;

export const Carousel = styled(CarouselV2)`
    padding: 0px 48px;
    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
        padding: 0px;
    `}
`;

export const ReviewIOLogoWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-right: 48px;
`;

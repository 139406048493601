import React from 'react';
import { IconPropTypes } from 'ggApp/types/propTypes';

const Cart = ({ className, color, size }) => (
    <svg
        className={className}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.5416 3.78017L16.8971 8.55779C17.1414 9.05314 16.9378 9.65269 16.4424 9.89691C15.9471 10.1411 15.3475 9.93756 15.1033 9.44221L12.7427 4.65433C12.4726 4.09541 12.1495 4 12.0002 4C11.851 4 11.5279 4.09539 11.2577 4.65432L11.2543 4.66135L8.89713 9.44221C8.6529 9.93756 8.05336 10.1411 7.55801 9.89691C7.06266 9.65268 6.85909 9.05313 7.10331 8.55779L9.45889 3.78016C9.9748 2.71566 10.9008 2 12.0002 2C13.0997 2 14.0257 2.71567 14.5416 3.78017Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 10H6V16C6 17.1046 6.89543 18 8 18H16C17.1046 18 18 17.1046 18 16V10ZM4 8V16C4 18.2091 5.79086 20 8 20H16C18.2091 20 20 18.2091 20 16V8H4Z"
            fill={color}
        />
    </svg>
);

Cart.propTypes = {
    ...IconPropTypes,
};

Cart.defaultProps = {
    size: 24,
    color: '#333333',
    className: '',
};

export default Cart;

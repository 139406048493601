import styled, { css } from 'styled-components';

import { screens } from 'ggApp/utils/mixins/screens';

export const Grid = styled.div`
    margin: 0 5%;
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: 16px;

    ${({ noMargin }) => noMargin && css`
        margin: 0;
    `}
    
    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
        margin: 0;
    `}

    @media (${screens.phone}) and (${screens.tabletMax}) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-column-gap: 8px;
    }
`;

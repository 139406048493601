/* eslint-disable */
import PropTypes from 'prop-types'
import React, {PureComponent,} from 'react';

import omit from "lodash/omit";
import Observer from '@researchgate/react-intersection-observer';
import {canUseDOM,} from 'ggApp/utils/dom'

if (canUseDOM()) {
    require('intersection-observer')
}

export default BaseComponent => {
    const displayName = BaseComponent.displayName || BaseComponent.name || 'Component';

    return class WithIntersectionObserver extends PureComponent {
        static propTypes = {
            onEnterCallback: PropTypes.func,
            onExitCallback: PropTypes.func,
            threshold: PropTypes.number,
        };

        static defaultProps = {
            onEnterCallback: () => void(0),
            onExitCallback: () => void(0),
            threshold: 1,
        };

        static displayName = `withIntersectionObserver(${displayName})`;

        handleChange = ({isIntersecting, intersectionRatio,}) => {
            const {onEnterCallback, onExitCallback, threshold = 1,} = this.props;
            if (typeof onEnterCallback === 'function' && isIntersecting && intersectionRatio >= threshold) {
                onEnterCallback()
            }
            else if (typeof onExitCallback === 'function' && (!isIntersecting || intersectionRatio < threshold)) {
                onExitCallback()
            }
        };


        render() {
            const {threshold = 1,} = this.props;
            return (
                <Observer onChange={this.handleChange} threshold={threshold}>
                    <BaseComponent {...omit(this.props, ['intersectionCallback', 'threshold',])}/>
                </Observer>
            );
        }
    };
};

import { OrderContextProps } from 'ggApp/shared/context/Order/OrderContext';

export interface PaymentMethodProps {
    id: number | null;
    type: string | null;
    flowType: string | null;
    contractType: string | null;
    paymentGatewayType: string | null;
    description: string | null;
    billingAccountId: string | null;
    number: number | null;
    meta?: string;
}

interface PaymentMethodPayload {
    id: number;
    type?: string;
    flow_type: string;
    contract_type: string;
    payment_gateway_type: string;
    billing_account_id: string;
    description: string;
    number: number;
    meta: any;
}

interface PaymentMethodInput extends OrderContextProps {
    userPayments: Array<PaymentMethodPayload>;
    paymentMethodId: number;
    billingAccountId: string;
}

export const findPaymentMethodById = ({
    userPayments = [],
    paymentMethodId,
    billingAccountId,
}: PaymentMethodInput): PaymentMethodProps => {
    const payment = userPayments.find((i) => i.id === Number(paymentMethodId));

    return {
        id: payment?.id ?? null,
        type: payment?.type ?? null,
        flowType: payment?.flow_type ?? null,
        contractType: payment?.contract_type ?? null,
        paymentGatewayType: payment?.payment_gateway_type ?? null,
        description: payment?.description ?? null,
        number: payment?.number ?? null,
        meta: payment?.meta ?? null,
        billingAccountId: payment?.billing_account_id || billingAccountId || null,
    };
};

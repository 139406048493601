import styled, { css } from 'styled-components';
import Typography from 'ggApp/shared/components/typography';

const Root = styled.div`
    display: flex;
    color: ${({ theme }) => theme.colors['Level 5']};

    &::after {
        background-color: ${({ theme }) => theme.colors['Level 1']};
        content: '';
        height: 4px;
        align-self: center;
        flex: 1;
        border-radius: 2px;
        margin-left: 8px;
    }
    ${(props) => props.theme.superQuery.all.and.maxWidth.tabletMax.css`
        grid-column: 1 / span 4;
    `}
`;

const DivisionLabel = styled(Typography)`
    ${(props) => css(props.theme.typography.SmallCapsHeadline)};
`;

export {
    Root,
    DivisionLabel,
};

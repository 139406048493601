/* eslint-disable camelcase */
import styled, { css, keyframes } from 'styled-components';

import { RouterComponent_LinkWithStore } from 'ggApp/modules/router';
import { screens } from 'ggApp/utils/mixins/screens';
import Loader from './images/loaderBig.svg';

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const wrapperAnimation = css`
    animation: ${spin} 2s linear infinite;
`;

export const ButtonWrapper = styled.div`
    position: relative;
    width: 100%;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    background: transparent;
    font-family: Inter, sans-serif;
    z-index: 10;

    &:after {
        position: absolute;
        z-index: 500;
        content: ' ';

        background-color: rgba(255, 255, 255, 0.75);
        background-image: url(${Loader});
        background-position: 50% 50%;
        background-repeat: no-repeat;

        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        opacity: 0;
        visibility: hidden;

        transition: all 0.3s ease;
    }

    &:before {
        border: 3px solid #f3f3f3; /* Light grey */
        border-top: 3px solid #159bff; /* Blue */
        border-radius: 50%;
        width: 30px;
        height: 30px;
        ${wrapperAnimation}
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -15px 0 0 -15px;

        content: ' ';
        display: block;

        transition: all 0.2s ease;
        opacity: 0;
        visibility: hidden;
    }

    ${({ loading }) => loading && css`
        &:after {
            opacity: 1;
            visibility: visible;
        }
    `}
    
    ${({ disabled }) => disabled && css`
        cursor: not-allowed;
    `}
    
    ${({ fixedToBottom }) => fixedToBottom && css`
        position: fixed;
        bottom: 0;
        left: 0;
    `}
    
    ${({ autoWidth }) => autoWidth && css`
        width: auto;
    `}

    ${({ modalMarginBottom }) => modalMarginBottom && css`
        margin-bottom: 45px;
    `}
    
    ${({ dark }) => dark && css`
        &:after {
            opacity: 0;
            visibility: hidden;
        }
    `}
    
    ${({ dark, loading }) => dark && loading && css`
        pointer-events: none;
        &:before {
                opacity: 1;
                visibility: visible;
            }
    `}

    @media (${screens.phone}) and (${screens.desktopMax}) {
        ${({ fixedToBottomMobile }) => fixedToBottomMobile && css`
            position: fixed;
            bottom: 0;
            left: 0;
        `}

        ${({ noMarginMobile }) => noMarginMobile && css`
            margin-bottom: 0;
        `}
    }
`;

export const Link = styled(RouterComponent_LinkWithStore)`
    width: 100%;
    border-radius: 2px;
    cursor: pointer;
    border: none;
    outline: none;
    background: #3d02ff;
    min-height: 65px;
    text-align: center;
    position: relative;

    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.35;
    letter-spacing: 0.3px;

    transition: all 0.2s ease;

    user-select: none;
    color: #fff !important;

    &:hover {
        background: #8d74b5;
    }

    &:active {
        background: #5d3f7e;
    }

    ${({ flex }) => flex && css`
        display: flex;
        flex-direction: column;
        justify-content: center;
    `}

    ${({ disabled }) => disabled && css`
        pointer-events: none;
        background: #cbbbff;
        color: rgba(#fff, 0.5);

        &:hover,
        &:active {
            background: #3d02ff;
        }
    `}

    ${({ black }) => black && css`
        background: #000;

        &:hover {
            background: #555;
        }

        &:active {
            background: #333;
        }
    `}

    ${({ inputSize }) => inputSize && css`
        min-height: 62px;
    `}

    ${({ autoWidth }) => autoWidth && css`
        padding: 0 15px;
    `}

    ${({ paypal }) => paypal && css`
        min-height: 70px;
        background-image: url(./images/paypal.svg);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        color: transparent;
        text-indent: -9999px;

        background-color: rgba(#000, 0.05);
        border: 1px solid rgba(#000, 0.1);

        &:hover {
            background-color: rgba(#000, 0.1);
        }

        &:active {
            background-color: rgba(#000, 0.15);
        }
    `}

    ${({ noBorder }) => noBorder && css`
        border-radius: 0;
    `}

    ${({ dark }) => dark && css`
        border: 1px solid #159bff;
        background: transparent;

        &:hover,
        &:active {
            background: #159bff;
        }
    `}
    
    ${({ dark, disabled }) => dark && disabled && css`
        opacity: 0.5;
    `}

    ${({ dark, loading }) => dark && loading && css`
        opacity: 0.3;
        background: #159bff;
    `}
    
    @media (${screens.phone}) and (${screens.desktopMax}) {
        ${({ noBorderMobile }) => noBorderMobile && css`
            border-radius: 0;
            font-size: 14px;
        `}
    }
`;

export const Button = styled.button`
    width: 100%;
    border-radius: 2px;
    cursor: pointer;
    border: none;
    outline: none;
    background: #3d02ff;
    min-height: 65px;
    text-align: center;
    position: relative;

    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.35;
    letter-spacing: 0.3px;

    transition: all 0.2s ease;

    user-select: none;
    color: #fff !important;

    &:hover {
        background: #8d74b5;
    }

    &:active {
        background: #5d3f7e;
    }

    ${({ flex }) => flex && css`
        display: flex;
        flex-direction: column;
        justify-content: center;
    `}

    ${({ disabled }) => disabled && css`
        pointer-events: none;
        background: #cbbbff;
        color: rgba(#fff, 0.5);

        &:hover,
        &:active {
            background: #3d02ff;
        }
    `}

    ${({ black }) => black && css`
        background: #000;

        &:hover {
            background: #555;
        }

        &:active {
            background: #333;
        }
    `}

    ${({ inputSize }) => inputSize && css`
        min-height: 62px;
    `}

    ${({ autoWidth }) => autoWidth && css`
        padding: 0 15px;
    `}

    ${({ paypal }) => paypal && css`
        min-height: 70px;
        background-image: url(./images/paypal.svg);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        color: transparent;
        text-indent: -9999px;

        background-color: rgba(#000, 0.05);
        border: 1px solid rgba(#000, 0.1);

        &:hover {
            background-color: rgba(#000, 0.1);
        }

        &:active {
            background-color: rgba(#000, 0.15);
        }
    `}

    ${({ noBorder }) => noBorder && css`
        border-radius: 0;
    `}

    ${({ dark }) => dark && css`
        border: 1px solid #159bff;
        background: transparent;

        &:hover,
        &:active {
            background: #159bff;
        }
    `}
    
    ${({ dark, disabled }) => dark && disabled && css`
        opacity: 0.5;
    `}

    ${({ dark, loading }) => dark && loading && css`
        opacity: 0.3;
        background: #159bff;
    `}
    
    @media (${screens.phone}) and (${screens.desktopMax}) {
        ${({ noBorderMobile }) => noBorderMobile && css`
            border-radius: 0;
            font-size: 14px;
        `}
    }
`;

export const Text = styled.div`
    > div {
        display: inline;
        position: relative;

        &:after {
            content: ' ';
            display: block;
            position: absolute;
            width: 20px;
            top: 1px;
            right: 0;
            height: 100%;

            background-position: 50% 50%;
            background-size: auto 70px;
            background-repeat: no-repeat;
        }
    }

    ${({ noArrow }) => noArrow && css`
        > div {
            display: inline;
            padding-right: 0;

            &:after {
                display: none;
            }
        }
    `}
`;

/* eslint-disable */
import * as t from '../actionTypes';

const defaultState = {
    shouldBeRedirectedToBusiness: false,
};

export default function (state = defaultState, action) {
    switch (action.type) {
    case t.USER_SHOULD_BE_REDIRECTED:
        return {
            shouldBeRedirectedToBusiness: action.data,
        };

    default:
        return state;
    }
}

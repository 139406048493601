/* eslint-disable */
import {
    GET_FEATURE_FLAGS, GET_FEATURE_FLAGS_ERROR, GET_FEATURE_FLAGS_SUCCESS,
    GET_ALL_FEATURE_FLAGS, GET_ALL_FEATURE_FLAGS_SUCCESS, GET_ALL_FEATURE_FLAGS_ERROR,
} from './constants';
import CookiesManager from 'ggApp/utils/CookiesManager';

const defaultState = {
    loading: null,
    loadingFlags: true,
    error: {},
    data: {},
};


export default function (state = defaultState, action) {
    switch (action.type) {
    case GET_FEATURE_FLAGS:
        return {
            ...state,
            loading: true,
            loadingFlags: true,
        };

    case GET_FEATURE_FLAGS_SUCCESS:
        return {
            ...state,
            loading: false,
            loadingFlags: false,
            data: { ...state.data, [action.flagKey]: action.response },
        };

    case GET_FEATURE_FLAGS_ERROR:
        return {
            ...state,
            loading: false,
            loadingFlags: false,
            error: { ...state.error, [action.flagKey]: action.error },
        };

    case GET_ALL_FEATURE_FLAGS: {
        const initialFlagState = (action.flagKeys || []).reduce((result, flagKey) => {
            result[flagKey] = undefined;
            return result;
        }, {});
        return {
            ...state,
            loading: true,
            loadingFlags: true,
        };
    }
    case GET_ALL_FEATURE_FLAGS_SUCCESS: {
        if (!action.response || !action.response.evaluationResults || !Array.isArray(action.response.evaluationResults)) {
            return state;
        }
        const flagKeysData = action.response.evaluationResults.reduce((result, flagData) => {
            result[flagData.flagKey] = flagData;
            return result;
        }, {});
        const cookiesManager = new CookiesManager();
        const userID = cookiesManager.get('user_id');
        return {
            ...state,
            loading: false,
            loadingFlags: userID ? false : true,
            data: { ...state.data, ...flagKeysData },
        };
    }
    case GET_ALL_FEATURE_FLAGS_ERROR: {
        const flagKeysErrors = (action.flagKeys || []).reduce((result, flagKey) => {
            result[flagKey] = action.error;
            return result;
        }, {});
        return {
            ...state,
            loading: false,
            loadingFlags: false,
            error: { ...state.error, ...flagKeysErrors },
        };
    }
    default:
        return state;
    }
}

/* eslint-disable */
import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERROR,
} from './../actionTypes';

const defaultState = {
  loading: null,
  error: {},
  data: [],
};

export default function (state = defaultState, action) {
  switch (action.type) {

    case GET_CATEGORIES:
      return Object.assign({}, state, {
        loading: true,
        data: [],
        error: {},
      })

    case GET_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.data || [],
      })

    case GET_CATEGORIES_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      })

    default:
      return state;
  }
}

/* eslint-disable prefer-promise-reject-errors */
import qs from 'query-string';
import createApolloClient from 'graphql-client';

import ggAxiosConfig from 'ggApp/modules/axiosConfig';
import ggCategory from 'ggApp/modules/category';
import ggFilter from 'ggApp/modules/filter';
import ggRouter from 'ggApp/modules/router';
import { getBusinessRedirectPath } from 'ggApp/modules/router/utils';
import * as ggFilterSelectors from 'ggApp/modules/filter/selectors';
import CookiesManager, { injectCookiesToConfig } from 'ggApp/utils/CookiesManager';
import axios from 'ggApp/utils/requests';
import { setLocaleCookie } from 'ggApp/modules/store/utils';
import {
    list as productListSelector,
    promotions as promotionsSelector,
    currentProduct as currentProductSelector,
} from 'ggApp/modules/product/selectors';

import {
    locationQuery as locationQuerySelector,
    locationPathname as locationPathnameSelector,
} from 'ggApp/modules/router/selectors';
import {
    mixGlobalFlag as mixGlobalFlagSelector,
    mixCatalogFlag as mixCatalogFlagSelector,
    availabilityFilterEnabled as availabilityFilterEnabledSelector,
    productCustomizationServiceDataFetchingEnabled as productCustomizationServiceDataFetchingEnabledSelector,
    productCustomizationServiceDataFetchingListingPagesEnabled as productCustomizationServiceDataFetchingListingPagesEnabledSelector,
    showProductEnergyLabel as showProductEnergyLabelSelector,
} from 'ggApp/modules/featureFlag';
import {
    ADD_TO_WAITING_LIST,
    ADD_TO_WAITING_LIST_ERROR,
    ADD_TO_WAITING_LIST_SUCCESS,
    GET_PRODUCT_BY_ID,
    GET_PRODUCT_BY_ID_ERROR,
    GET_PRODUCT_BY_ID_SUCCESS,
    GET_PRODUCT_OFFER,
    GET_PRODUCT_OFFER_ERROR,
    GET_PRODUCT_OFFER_SUCCESS,
    GET_PRODUCTS,
    GET_PRODUCTS_ERROR,
    GET_PRODUCTS_SUCCESS,
    GET_RELATED_PRODUCTS,
    GET_RELATED_PRODUCTS_ERROR,
    GET_RELATED_PRODUCTS_SUCCESS,
    GET_LANDING_PAGE_PRODUCTS,
    GET_LANDING_PAGE_PRODUCTS_SUCCESS,
    GET_LANDING_PAGE_PRODUCTS_ERROR,
    SELECT_PRODUCT_PROPERTY,
    GET_RECENTLY_VIEW_PRODUCTS_SUCCESS,
    GET_PRODUCT_PROMOTIONS_SUCCESS,
    GET_PRODUCT_PROMOTIONS_ERROR,
    GET_PRODUCTS_GRAPHQL_SUCCESS,
} from '../actionTypes';
import getProductsData from '../queries/getProductsData.graphql';

const productsPerCatalogPage = 24;

const redirectToYourTech = (storeCode, langCode) => ({
    redirectLocation: {
        pathname: getBusinessRedirectPath(storeCode, langCode),
    },
});

export function getProductsGraphQLData(skus, location, req) {
    return async (dispatch, getState) => {
        try {
            const state = getState();
            const productCustomizationServiceDataFetchingEnabled = productCustomizationServiceDataFetchingEnabledSelector(
                state,
            );
            const listingDataFetchingEnabled = productCustomizationServiceDataFetchingListingPagesEnabledSelector(
                state,
            );
            const showProductEnergyLabelEnabled = showProductEnergyLabelSelector(state);

            if (!productCustomizationServiceDataFetchingEnabled && !showProductEnergyLabelEnabled) {
                return;
            }

            const addListingProducts = listingDataFetchingEnabled || showProductEnergyLabelEnabled;
            const apolloClient = createApolloClient(new CookiesManager(req));
            const promotions = promotionsSelector(getState());
            const productList = productListSelector(getState());
            const currentProduct = currentProductSelector(getState());

            const skuList = [
                ...(Array.isArray(skus) ? skus : []),
                ...(currentProduct?.sku ? [currentProduct.sku] : []),
                ...(productList?.data?.length > 0 && addListingProducts
                    ? productList.data.map(({ sku }) => sku)
                    : []),
            ].filter(
                (sku) => !promotions?.products?.some((promotion) => promotion.sku === sku) ?? true,
            );

            if (skuList.length === 0) return;

            const { data } = await apolloClient.query({
                query: getProductsData,
                variables: {
                    skus: skuList,
                    productCustomizationServiceDataFetchingEnabled,
                    showProductEnergyLabelEnabled,
                },
            });

            dispatch({ type: GET_PRODUCT_PROMOTIONS_SUCCESS, data });
            dispatch({ type: GET_PRODUCTS_GRAPHQL_SUCCESS, data });
        } catch (error) {
            dispatch({ type: GET_PRODUCT_PROMOTIONS_ERROR, error });
        }
    };
}

export function getProductById({ productSlug, store, language }, location, req) {
    if (!productSlug) return null;
    const cookiesManager = new CookiesManager(req);
    return (dispatch, getState) => {
        const query = ggRouter.selectors.locationQuery(getState());
        const redirectKey = ggRouter.constants.BUSINESS_REDIRECT_QUERY_KEY;
        const storeConfig = ggAxiosConfig.selectors.config(getState());
        const config = {
            ...storeConfig,
            headers: {
                ...storeConfig.headers,
                'X-Grover-Store': store,
                'X-Grover-Language': language,
            },
        };
        injectCookiesToConfig(cookiesManager, config);
        setLocaleCookie(language, cookiesManager);

        dispatch({ type: GET_PRODUCT_BY_ID });

        return axios
            .get(`/products/${productSlug}`, config)
            .then((result) => {
                /* eslint-disable-next-line camelcase */
                const hasRentalPlans = result?.data?.product?.rental_plans?.length > 0;

                if (query[redirectKey] && !hasRentalPlans) {
                    return redirectToYourTech(store, language);
                }

                if (!hasRentalPlans) {
                    const productNotFoundInStore = { error: null, base: 'Product not found' };
                    dispatch({
                        type: GET_PRODUCT_BY_ID_ERROR,
                        data: productNotFoundInStore,
                    });

                    // Emulate server returning 404 to redirect user to NotFound
                    return Promise.reject({
                        response: {
                            status: 404,
                            data: productNotFoundInStore,
                        },
                    });
                }

                dispatch({
                    type: GET_PRODUCT_BY_ID_SUCCESS,
                    data: result.data.product,
                });

                return result.data.product;
            })
            .catch(({ response: error = {} }) => {
                dispatch({
                    type: GET_PRODUCT_BY_ID_ERROR,
                    data: error.data,
                });

                if (query[redirectKey]) {
                    return redirectToYourTech(store, language);
                }

                if (error && error.status === 404) {
                    return Promise.reject({
                        code: error.status,
                    });
                }

                return Promise.reject(`Failed to fetch product ${productSlug}`);
            });
    };
}

export function getOffer(params, location, req) {
    const cookiesManager = new CookiesManager(req);
    return (dispatch, getState) => {
        const locationQuery = ggRouter.selectors.locationQuery(getState());
        const offer = locationQuery && locationQuery.offer;
        const config = ggAxiosConfig.selectors.config(getState());
        injectCookiesToConfig(cookiesManager, config);
        if (!offer) return null;

        dispatch({ type: GET_PRODUCT_OFFER });
        return axios
            .get(`/waiting_list_entries/offers/${offer}`, config)
            .then((result) => {
                dispatch({
                    type: GET_PRODUCT_OFFER_SUCCESS,
                    data: result.data.product,
                });
            })
            .catch(({ response: error = {} }) => {
                dispatch({
                    type: GET_PRODUCT_OFFER_ERROR,
                    data: error.data,
                });
            });
    };
}

export const selectProductVariant = (variantId) => ({
    type: SELECT_PRODUCT_PROPERTY,
    variantId,
});

export function getProducts(query = '', reset, req, loadOrder) {
    const cookiesManager = new CookiesManager(req);
    return (dispatch, getState) => {
        const config = ggAxiosConfig.selectors.config(getState());
        injectCookiesToConfig(cookiesManager, config);
        dispatch({ type: GET_PRODUCTS, reset });
        return axios
            .get(`/products?${query}`, config)
            .then((result) => {
                dispatch({
                    type: GET_PRODUCTS_SUCCESS,
                    data: {
                        loadOrder,
                        ...result?.data,
                    },
                });
                return result.data;
            })
            .catch(({ response: error = {} }) => {
                if (error.data) {
                    dispatch({
                        type: GET_PRODUCTS_ERROR,
                        error: error.data,
                    });
                }
            });
    };
}

export function getProductsForBrowse(customFilter = {}, location) {
    return (dispatch, getState) => {
        const state = getState();
        const category =
            ggCategory.selectors.getCategoryFromRouterParams(customFilter) ||
            ggCategory.selectors.currentCategoryPermalink(state);
        const filters = {
            ...(location
                ? ggFilterSelectors.parseLocationFilter(location.query || {})
                : ggFilterSelectors.activeJson(state)),
            ...(category && { category }),
        };
        const payload = {
            filter: JSON.stringify({
                in_stock: true,
                ...filters,
                ...customFilter,
            }),
            page: 1,
            per: 48,
        };
        const query = qs.stringify(payload);

        return dispatch(getProducts(query, true));
    };
}

export function getProductsByFilter(customFilter = {}, location, req) {
    return (dispatch, getState) => {
        const state = getState();
        // if location is present, then the function is used as "needs"
        const category = location
            ? ggCategory.selectors.getCategoryFromRouterParams(customFilter)
            : ggCategory.selectors.currentCategoryPermalink(state);
        const filters = {
            ...(location
                ? ggFilterSelectors.parseLocationFilter(location.query || {})
                : ggFilterSelectors.activeJson(state)),
            ...(category && { category }),
        };
        const locationPathname = locationPathnameSelector(state);
        const availabilityFilterEnabled = availabilityFilterEnabledSelector(state);
        const defaultAvailability =
            locationPathname.includes('trending') ||
            locationPathname.includes('deals') ||
            availabilityFilterEnabled;
        const payload = {
            page: req?.query?.page || location?.query?.page || null,
            per: productsPerCatalogPage,
            minimal_images: true,
            filter: JSON.stringify({
                in_stock: defaultAvailability,
                ...filters,
                ...customFilter,
            }),
        };
        const query = qs.stringify(payload);
        return dispatch(getProducts(query, true, req));
    };
}

export function getInStockProducts() {
    return (dispatch, getState) => getProductsByFilter()(dispatch, getState);
}

export function getDealProducts(params, location, req) {
    return (dispatch, getState) => {
        const state = getState();
        const filters = {
            ...(location
                ? ggFilterSelectors.parseLocationFilter(location.query || {})
                : ggFilterSelectors.activeJson(state)),
        };
        const payload = {
            page: req?.query?.page || location?.query?.page || null,
            per: productsPerCatalogPage,
            minimal_images: true,
            filter: JSON.stringify({
                in_stock: true,
                deal: true,
                ...filters,
            }),
        };
        const query = qs.stringify(payload);
        return dispatch(getProducts(query, true, req));
    };
}

export function getTrendingProducts(params, location, req) {
    return (dispatch, getState) => {
        const state = getState();
        const locationFromStore = locationQuerySelector(state);
        const mixFlag = mixCatalogFlagSelector(state);
        const mixCatalogFlag = mixGlobalFlagSelector(state);
        const filters = {
            ...(location
                ? ggFilterSelectors.parseLocationFilter(location.query || {})
                : ggFilterSelectors.activeJson(state)),
        };
        const mixFilterState = mixFlag && mixCatalogFlag && locationFromStore.mixFlow === 'active';
        const payload = {
            page: req?.query?.page || location?.query?.page || null,
            per: productsPerCatalogPage,
            minimal_images: true,
            filter: JSON.stringify({
                in_stock: true,
                mixable: mixFilterState,
                ...filters,
            }),
        };
        const query = qs.stringify(payload);
        return dispatch(getProducts(query, true, req));
    };
}

export function getProductsByPagination({
    page,
    pageSize,
    customFilter = {},
    reset,
    req,
    loadOrder,
}) {
    return (dispatch, getState) => {
        const state = getState();
        const category = ggCategory.selectors.currentCategoryPermalink(state);
        const filters = category
            ? { ...ggFilter.selectors.activeJson(state), category }
            : ggFilter.selectors.activeJson(state);
        const locationPathname = locationPathnameSelector(state);
        const availabilityFilterEnabled = availabilityFilterEnabledSelector(state);
        const defaultAvailability =
            locationPathname.includes('trending') ||
            locationPathname.includes('deals') ||
            availabilityFilterEnabled;
        const payload = {
            filter: JSON.stringify({
                in_stock: defaultAvailability,
                ...filters,
                ...customFilter,
            }),
            page,
            per: pageSize,
        };

        const query = qs.stringify(payload);
        return dispatch(getProducts(query, reset, req, loadOrder));
    };
}

export function addToWaitingList(variantId, email, firstName, lastName) {
    return (dispatch, getState) => {
        const config = ggAxiosConfig.selectors.config(getState());
        const data = {
            variant_id: variantId,
            email,
            first_name: firstName,
            last_name: lastName,
        };

        dispatch({ type: ADD_TO_WAITING_LIST });
        return axios
            .post('/waiting_list_entries', data, config)
            .then(() => {
                dispatch({
                    type: ADD_TO_WAITING_LIST_SUCCESS,
                });
            })
            .catch(({ response: error = {} }) => {
                dispatch({
                    type: ADD_TO_WAITING_LIST_ERROR,
                    data: error.data,
                });
            });
    };
}

export function getRelatedProducts(productSlug) {
    return (dispatch) => {
        if (!productSlug) return null;
        dispatch({ type: GET_RELATED_PRODUCTS });
        return axios
            .get(`/products/${productSlug}/related_products?minimal_images=true&in_stock=true`)
            .then((result) => {
                dispatch({
                    type: GET_RELATED_PRODUCTS_SUCCESS,
                    data: result.data,
                    productSlug,
                });
            })
            .catch(({ response: error = {} }) => {
                if (error.data) {
                    dispatch({
                        type: GET_RELATED_PRODUCTS_ERROR,
                        error: error.data,
                    });
                }
            });
    };
}

export function getGPageProductsByIds(productIDs) {
    return (dispatch, getState) => {
        if (!productIDs) return null;
        const payload = {
            filter: JSON.stringify({
                ids: productIDs,
            }),
            per: 40,
        };
        const query = qs.stringify(payload);
        const config = ggAxiosConfig.selectors.config(getState());
        dispatch({ type: GET_LANDING_PAGE_PRODUCTS });
        return axios
            .get(`/products?${query}`, config)
            .then((result) => {
                dispatch({
                    type: GET_LANDING_PAGE_PRODUCTS_SUCCESS,
                    data: result.data,
                });
                return result.data.products;
            })
            .catch(({ response: error = {} }) => {
                if (error.data) {
                    dispatch({
                        type: GET_LANDING_PAGE_PRODUCTS_ERROR,
                        error: error.data,
                    });
                }
            });
    };
}

export function getRecentlyViewedProducts() {
    return (dispatch, getState) => {
        const query = qs.stringify({ page: 1, per: 12 });
        const config = ggAxiosConfig.selectors.config(getState());
        return axios
            .get(`/recently_viewed_products?minimal_images=true?${query}`, config)
            .then((result) => {
                dispatch({ type: GET_RECENTLY_VIEW_PRODUCTS_SUCCESS, ...result });
                return result && result.data && result.data.products;
            })
            .catch(() => {});
    };
}

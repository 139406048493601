/* eslint-disable */
import {
    SET_CURRENT_STORE,
    SET_CURRENT_STORE_LANGUAGE,
} from 'gg-modules/store/actionTypes';
import {
    GET_STORE,
    GET_STORE_SUCCESS,
    GET_STORE_ERROR,
} from '../actionTypes';

const defaultState = {
    loading: null,
    error: null,
    data: {},
};

export default function (state = defaultState, action) {
    switch (action.type) {
    case 'SET_ACTIVE_STORE':
    case SET_CURRENT_STORE:
        return {
            loading: false,
            data: {
                ...state.data,
                ...action.store,
            },
        };

    case 'SET_ACTIVE_STORE_LANGUAGE':
    case SET_CURRENT_STORE_LANGUAGE:
        return {
            ...state,
            data: {
                ...state.data,
                activeLanguage: action.language,
            },
        };
    case GET_STORE:
        return {
            ...state,
            loading: true,
        };
    case GET_STORE_SUCCESS:
        return {
            ...state,
            loading: false,
            data: {
                ...state.data,
                ...action.store,
            },
        };

    case GET_STORE_ERROR:
        return {
            ...state,
            loading: false,
            error: action.error,
        };

    default:
        return state;
    }
}

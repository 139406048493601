import { GET_CATEGORY_BY_ID_SUCCESS } from 'ggApp/modules/category/actionTypes';
import objectPath from 'object-path';
import { extractProductsFromWidgets, PRODUCT_WIDGET_SUCCESS } from 'ggApp/modules/productWidget';
import {
    GET_PRODUCT_BY_ID_SUCCESS,
    GET_PRODUCTS_SUCCESS,
    GET_LANDING_PAGE_PRODUCTS_SUCCESS,
    GET_RECENTLY_VIEW_PRODUCTS_SUCCESS,
} from 'ggApp/modules/product/actionTypes';
import {
    ADD_TO_FAVORITES_SUCCESS,
    GET_FAVORITES_PRODUCTS,
    GET_FAVORITES_PRODUCTS_ERROR,
    GET_FAVORITES_PRODUCTS_SUCCESS,
    REMOVE_FROM_FAVORITES,
    ADD_PRODUCTS_TO_WISHLIST_STATE,
} from '../actionTypes';

const concatUniq = (arr1 = [], arr2 = []) => [
    ...new Set(
        arr1.concat(
            arr2.reduce((result, { favorite, id }) => {
                if (favorite) {
                    result.push(id);
                }
                return result;
            }, []),
        ),
    ),
];

const defaultState = {
    loading: null,
    error: {},
    data: [],
};

export default function(state = defaultState, action) {
    switch (action.type) {
        case GET_FAVORITES_PRODUCTS:
            return {
                ...state,
                loading: true,
                error: {},
                data: action.reset ? [] : state.data,
            };

        case GET_PRODUCTS_SUCCESS:
        case GET_FAVORITES_PRODUCTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: concatUniq(state.data, action.data.products || []),
            };
        }

        case GET_FAVORITES_PRODUCTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case ADD_TO_FAVORITES_SUCCESS: {
            if (!action.data) return state;
            return {
                ...state,
                data: concatUniq(state.data, action.data),
            };
        }
        case GET_CATEGORY_BY_ID_SUCCESS: {
            return {
                ...state,
                data: concatUniq(
                    state.data,
                    objectPath.get(action, ['data', 'contentful_record', 'featured']),
                ),
            };
        }
        case GET_PRODUCT_BY_ID_SUCCESS: {
            return {
                ...state,
                data: concatUniq(state.data, [
                    action.data,
                    ...(action.data.similar_products || []),
                ]),
            };
        }
        case REMOVE_FROM_FAVORITES: {
            const { id } = action;
            return {
                ...state,
                data: state.data.filter((productId) => productId !== id),
            };
        }
        case ADD_PRODUCTS_TO_WISHLIST_STATE:
        case GET_RECENTLY_VIEW_PRODUCTS_SUCCESS:
        case GET_LANDING_PAGE_PRODUCTS_SUCCESS: {
            return {
                ...state,
                data: concatUniq(state.data, action.data.products || []),
            };
        }
        default:
            return state;
    }
}

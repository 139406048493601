/* eslint-disable */
import {
    GET_SUBSCRIPTION_RETURN_LABEL,
    GET_SUBSCRIPTION_RETURN_LABEL_SUCCESS,
    GET_SUBSCRIPTION_RETURN_LABEL_ERROR,
    RESET_SUBSCRIPTION_RETURN_LABEL_SUCCESS,
} from '../actionTypes';

const defaultState = {
    loading: null,
    error: {},
    data: {
        sf_id: '',
        return_label_url: '',
        return_tracking_number: '',
        return_shipment_provider: '',
    },
};

export default function (state = defaultState, action) {
    switch (action.type) {
    case GET_SUBSCRIPTION_RETURN_LABEL:
        return {
            ...state,
            loading: true,
            error: {},
        };

    case GET_SUBSCRIPTION_RETURN_LABEL_SUCCESS:
        return {
            ...state,
            loading: false,
            data: action.data,
        };

    case GET_SUBSCRIPTION_RETURN_LABEL_ERROR:
        return {
            ...state,
            loading: false,
            error: action.error,
        };
    case RESET_SUBSCRIPTION_RETURN_LABEL_SUCCESS:
        return {
            ...state,
            data: defaultState.data,
            error: defaultState.error,
        };

    default:
        return state;
    }
}

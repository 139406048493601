import { SNOWPLOW_EVENT } from 'snowplow';
import { pushSnowplow } from 'ggApp/modules/tracking/actions/snowplow';
import { SIGN_IN, SIGN_UP } from 'ggApp/modules/auth/v3/state/actionTypes';

const isServer = __IS_SERVER__;

export default function authMiddleware() {
    return (next) => (action) => {
        if (!isServer) {
            const { type, data } = action;
            const { id } = data || {};
            if ([SIGN_IN, SIGN_UP].includes(type) && id) {
                pushSnowplow(SNOWPLOW_EVENT.setUserId, id);
            }
        }
        next(action);
    };
}

// @flow

import { combineReducers } from 'redux';
import {
    INIT_COOKIE_POLICY_SETTINGS,
    UPDATE_COOKIE_POLICY_SETTINGS,
    CONSENT_COOKIE_POLICY,
} from '../constants';
import type { ReduxStoreState } from '../../utils/flowTypes';
import type { Action } from '../actions';

const initialState: ReduxStoreState = {
    hasConsent: false,
    isInitialized: false,
};

function consentState(state: ReduxStoreState = initialState, action: Action): ReduxStoreState {
    const {
        type,
        hasConsent,
        preferenceCookieConsent,
        statisticsCookieConsent,
        marketingCookieConsent,
        consentTimestamp,
        consentExpiration,
    } = action;

    const payload = {
        hasConsent,
        preferenceCookieConsent,
        statisticsCookieConsent,
        marketingCookieConsent,
        consentTimestamp,
        consentExpiration,
    };

    switch (type) {
        case INIT_COOKIE_POLICY_SETTINGS:
            return {
                ...payload,
                isInitialized: true,
            };
        case UPDATE_COOKIE_POLICY_SETTINGS:
        case CONSENT_COOKIE_POLICY:
            return {
                ...payload,
                isInitialized: state.isInitialized,
            };

        default:
            return state;
    }
}

export const cookiePolicyReducers = combineReducers({
    consentState,
});

/* eslint-disable */
import ggStore from 'ggApp/modules/store';

export function getUrlPrefix({ storeCode, language }) {
    return `/${storeCode}-${language}`;
}

export function storeLangUrl(storeState, url = '') {
    const state = typeof storeState.getState === 'function'
        ? storeState.getState()
        : storeState;
    const {
        code: storeCode,
        activeLanguage: language,
    } = ggStore.selectors.storeActive(state);
    const prefix = getUrlPrefix({
        storeCode,
        language,
    });

    if (url.indexOf(prefix, 0) !== -1) {
        return url;
    }
    return `${prefix}${url}`;
}

/* eslint-disable */
import {
  GET_PAYMENT_GATEWAYS,
  GET_PAYMENT_GATEWAYS_SUCCESS,
  GET_PAYMENT_GATEWAYS_ERROR,
} from './../actionTypes';

const defaultState = {
  loading: null,
  error: {},
  data: [],
};

export default function (state = defaultState, action) {
  switch (action.type) {

    case GET_PAYMENT_GATEWAYS:
      return Object.assign({}, state, {
        loading: true,
        error: {},
      })

    case GET_PAYMENT_GATEWAYS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.data,
      })

    case GET_PAYMENT_GATEWAYS_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      })

    default:
      return state;
  }
}

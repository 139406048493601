/* eslint-disable */
// @flow

import { combineReducers, } from 'redux';

import paymentGateways from './paymentGateways';
import paymentMethods from './paymentMethods';
import type { AdyenPaymentState, } from './paymentMethods';
import submittedPayment from './submittedPayment';
import savedPaymentData from './savedPaymentData';

type AdyenState = {
  paymentMethods: AdyenPaymentState,
}

const adyenReducer = combineReducers({
  paymentGateways,
  paymentMethods,
  submittedPayment,
  savedPaymentData,
})

export default adyenReducer
export type AdyenSt = AdyenState

import axios from 'ggApp/utils/requests';
import { push } from 'redux-router';
import qs from 'query-string';
import createApolloClient from 'graphql-client';

import ggRouter from 'ggApp/modules/router';
import { locationPathname as locationPathnameSelector } from 'ggApp/modules/router/selectors';
import ggAxiosConfig from 'ggApp/modules/axiosConfig';
import ggCategory from 'ggApp/modules/category';
import { storeActive as storeActiveSelector } from 'ggApp/modules/store/selectors';
import {
    structuredSpecsFlagEnabled,
    availabilityFilterEnabled as availabilityFilterEnabledSelector,
} from 'ggApp/modules/featureFlag/selectors';
import CookiesManager, { injectCookiesToConfig } from 'ggApp/utils/CookiesManager';

import * as ggFilterSelectors from '../selectors';
import { filtersCompose, filtersToQuery, getFilterEventDetails } from '../utils';
import getProductFilters from '../queries/getProductFilters.graphql';
import { SPEC_FILTER_RANGE_QS_KEY, SPEC_FILTER_MATCH_QS_KEY } from '../constants';
import {
    GET_FILTERS_BY_CATEGORY,
    GET_FILTERS_BY_CATEGORY_ERROR,
    GET_FILTERS_BY_CATEGORY_SUCCESS,
    GET_STRUCTURED_SPECS_CONFIG_SUCCESS,
    GET_STRUCTURED_SPECS_CONFIG_ERROR,
} from '../actionTypes';
import { trackProductFilter } from '../../../../../packages/analytics/filter/trackProductFilter';

export function getListOfFilters({ customFilter, ...rest }, location, req) {
    const cookiesManager = new CookiesManager(req);
    return (dispatch, getState) => {
        const state = getState();
        const config = ggAxiosConfig.selectors.config(state);
        injectCookiesToConfig(cookiesManager, config);
        const category = location
            ? ggCategory.selectors.getCategoryFromRouterParams(rest)
            : ggCategory.selectors.currentCategoryPermalink(state);
        const { subCategorySlug } = location ? rest : ggRouter.selectors.params(state);
        const isSubCategory = Boolean(subCategorySlug);

        const specFilters =
            isSubCategory && structuredSpecsFlagEnabled(state)
                ? ggFilterSelectors.enabledSpecFilters(state).reduce(
                      (acc, cur) =>
                          cur.__typename === 'RangeFilter'
                              ? {
                                    ...acc,
                                    range_filters: [
                                        // eslint-disable-next-line camelcase
                                        ...(acc?.range_filters ?? []),
                                        { name: cur.name },
                                    ],
                                }
                              : {
                                    ...acc,
                                    match_filters: [
                                        // eslint-disable-next-line camelcase
                                        ...(acc?.match_filters ?? []),
                                        { name: cur.name },
                                    ],
                                },
                      {},
                  )
                : {};

        const queryString = location
            ? ggFilterSelectors.parseLocationFilter(location.query || {})
            : ggFilterSelectors.activeJson(state);

        const filteredQueryString = Object.keys(queryString)
            .filter((key) => ![SPEC_FILTER_RANGE_QS_KEY, SPEC_FILTER_MATCH_QS_KEY].includes(key))
            .reduce((obj, key) => ({ ...obj, [key]: queryString[key] }), {});

        const locationPathname = locationPathnameSelector(state);
        const availabilityFilterEnabled = availabilityFilterEnabledSelector(state);
        const defaultAvailability =
            locationPathname.includes('trending') ||
            locationPathname.includes('deals') ||
            availabilityFilterEnabled;

        const filters = {
            ...filteredQueryString,
            ...(category && { category }),
            ...specFilters,
        };

        const payload = {
            filter: JSON.stringify({
                in_stock: defaultAvailability,
                ...filters,
                ...customFilter,
            }),
        };

        const query = qs.stringify(payload);

        dispatch({ type: GET_FILTERS_BY_CATEGORY });
        return axios
            .get(`/products/filters?${query}`, config)
            .then((result) => {
                dispatch({
                    type: GET_FILTERS_BY_CATEGORY_SUCCESS,
                    data: result.data,
                });
            })
            .catch(({ response: error }) => {
                if (error?.data) {
                    dispatch({
                        type: GET_FILTERS_BY_CATEGORY_ERROR,
                        error: error.data,
                    });
                }
            });
    };
}

export function getStructuredSpecsConfig({ customFilter, ...rest }, location, req) {
    return async (dispatch, getState) => {
        try {
            const apolloClient = createApolloClient(new CookiesManager(req));

            // note that we are passing "rest" to getCategoryFromRouterParams: when transitioning
            // between categories clientside, this is a workaround for the issue that the router
            // state hasn't changed yet before this action fires
            const permalink = location
                ? ggCategory.selectors.getCategoryFromRouterParams(rest)
                : ggCategory.selectors.currentCategoryPermalink(getState());

            const { data } = await apolloClient.query({
                query: getProductFilters,
                variables: {
                    permalink,
                },
            });

            dispatch({
                type: GET_STRUCTURED_SPECS_CONFIG_SUCCESS,
                data: data?.category?.productFilters,
            });
        } catch (e) {
            dispatch({
                type: GET_STRUCTURED_SPECS_CONFIG_ERROR,
            });
        }
    };
}

export function getFiltersWithStructuredSpecsConfig(...args) {
    return async (dispatch) => {
        await dispatch(getStructuredSpecsConfig(...args));
        await dispatch(getListOfFilters(...args));
    };
}

export function onSelect(name, value) {
    return (dispatch, getState) => {
        const state = getState();
        const activeFilters = ggFilterSelectors.activeJson(state);
        const listOfFilters = ggFilterSelectors.byCategory(state).data;
        const locationQuery = ggRouter.selectors.locationQuery(state);
        const locationPathname = ggRouter.selectors.locationPathname(state);
        const availabilityFilterEnabled = availabilityFilterEnabledSelector(state);
        const enabledSpecFilters = ggFilterSelectors.enabledSpecFilters(state);

        delete locationQuery.page;
        delete locationQuery.filter;

        const filterObj = filtersCompose({
            activeFilters,
            enabledSpecFilters,
            name,
            value,
        });

        const { store_id: storeId } = storeActiveSelector(state);

        const { eventName, eventProperties } = getFilterEventDetails(
            name,
            value,
            enabledSpecFilters,
            activeFilters,
        );
        trackProductFilter(storeId, eventName, eventProperties);

        const filterQuery = filtersToQuery({
            activeFilters: filterObj,
            name,
            value,
            listOfFilters,
            locationPathname,
            availabilityFilterEnabled,
        });

        dispatch(
            push({
                pathname: locationPathname,
                query: { ...locationQuery, filter: filterQuery || undefined },
            }),
        );
    };
}

export function onSearch(query) {
    return onSelect('search', query);
}

import React from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';

import { fireEvent } from 'ggApp/modules/inwebview_api';
import { pathReducer, populate } from 'ggApp/utils/pathBuilder';
import { setLocaleCookie } from 'ggApp/modules/store/utils';

import DropDown from '../DropDown';
import { Icon, CrawlableLanguageLink } from './styled';

class LanguageSelector extends React.PureComponent {
    constructor(props) {
        super(props);
        const { orderedLanguageList, selectedIndex } = props;
        const selectedItem = orderedLanguageList && orderedLanguageList[selectedIndex];
        this.state = {
            selected: selectedItem && selectedItem.value ? selectedItem.value : null,
        };
    }

    isLegacyStore = (store) => store && store.store_type === 'legacy' && store.url;

    changeUserLanguagePreference = (langCode) => {
        const { routes, params, locationQuery } = this.props;
        setLocaleCookie(langCode);
        if (window) {
            const parameterizedPath = pathReducer(routes);
            const changedParams = {
                ...params,
                language: langCode,
            };
            let updatedPath = populate(parameterizedPath, changedParams);
            if (Object.keys(locationQuery).length) {
                updatedPath += `?${qs.stringify(locationQuery)}`;
            }
            window.location.href = updatedPath;
        }
    };

    handleChange = (value) => {
        fireEvent('SET_ACTIVE_STORE_LANGUAGE', { action: { language: value } });

        this.setState({ selected: value });
        this.changeUserLanguagePreference(value);
    };

    render() {
        const { className, orderedLanguageList, minimal, storeActive } = this.props;
        const { selected } = this.state;
        const currentLanguageLabel =
            (orderedLanguageList.find((language) => language.value === selected) || {}).label ||
            selected;
        return (
            <>
                <DropDown
                    className={className}
                    minimal={minimal}
                    currentLabel={currentLanguageLabel}
                    handleChange={this.handleChange}
                    selected={selected}
                    optionsList={orderedLanguageList}
                    iconComponent={Icon}
                />
                {orderedLanguageList.map((language) => {
                    const store = storeActive.code;
                    return (
                        <CrawlableLanguageLink
                            key={`${store}-${language.value}`}
                            href={`/${store}-${language.value}`}
                        >
                            {language.label}
                        </CrawlableLanguageLink>
                    );
                })}
            </>
        );
    }
}

LanguageSelector.propTypes = {
    className: PropTypes.string,
    orderedLanguageList: PropTypes.arrayOf(PropTypes.object),
    selectedIndex: PropTypes.number,
    storeActive: PropTypes.shape({}).isRequired,
    routes: PropTypes.arrayOf(PropTypes.object),
    params: PropTypes.shape({}),
    locationQuery: PropTypes.shape({}),
    minimal: PropTypes.bool,
};

LanguageSelector.defaultProps = {
    className: '',
    orderedLanguageList: [],
    selectedIndex: 0,
    routes: [],
    params: {},
    locationQuery: {},
    minimal: false,
};

export default LanguageSelector;

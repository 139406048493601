export const replaceTranslatedStringValues = (rawString, values) => {
    let translatedString = rawString;

    Object.keys(values).forEach((key) => {
        const regexp = new RegExp(`{{${key}}}`, 'g');
        translatedString = translatedString.replace(regexp, values[key]);
    });

    return translatedString;
};

export default replaceTranslatedStringValues;

import React from 'react';
import { withTheme } from 'styled-components';

import { DropDownOptions, Text, Wrap, SmallArrow, LargeArrow } from './styled';

class DropDown extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            shouldOpenDropDown: false,
        };
    }

    handleClickOutside = () => {
        this.setState({
            shouldOpenDropDown: false,
        });
    };

    handleDropDown = () => {
        this.setState((state) => ({
            shouldOpenDropDown: !state.shouldOpenDropDown,
        }));
    };

    render() {
        const {
            className,
            minimal,
            theme,
            currentLabel,
            handleChange,
            selected,
            optionsList,
            iconComponent: Icon,
        } = this.props;
        const { shouldOpenDropDown } = this.state;
        const isInteractive = optionsList?.length > 1;
        return (
            <Wrap
                className={className}
                dropDownIsOpen={shouldOpenDropDown}
                onClick={isInteractive ? this.handleDropDown : () => {}}
                isMinimal={minimal}
                isInteractive={isInteractive}
            >
                {!minimal && <Icon color={theme.colors.Foreground} />}
                <Text>{currentLabel}</Text>
                {isInteractive && minimal && (
                    <SmallArrow isMinimal={minimal} color={theme.colors.Foreground} />
                )}
                {isInteractive && !minimal && <LargeArrow color={theme.colors.Foreground} />}
                <DropDownOptions
                    isMinimal={minimal}
                    onChange={handleChange}
                    isOpen={shouldOpenDropDown}
                    currentValue={selected}
                    list={optionsList}
                    iconComponent={Icon}
                />
            </Wrap>
        );
    }
}

export default withTheme(DropDown);

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { keys } from 'ggApp/modules/translator/selectors';

import DropDowns from './DropDowns';

const enhance = compose(
    connect(
        createStructuredSelector({
            translationKeys: keys,
        }),
    ),
);

export default enhance(DropDowns);

import React from 'react';
import PropTypes from 'prop-types';

import { FooterTitle, Link } from './styled';

const LinkList = ({ title, list, className }) => (
    <div className={className}>
        {!!title && <FooterTitle>{title}</FooterTitle>}
        {list.length !== 0 &&
            list.map(
                (item) =>
                    item && (
                        <Link
                            key={item.link}
                            external={!item.internal}
                            to={item.link}
                            onClick={item.onClick}
                        >
                            {item.text}
                        </Link>
                    ),
            )}
    </div>
);

LinkList.propTypes = {
    className: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

LinkList.defaultProps = {
    className: '',
    title: '',
};

export default LinkList;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as ggStoreSelectors from '../../selectors';
import { CURRENCIES_ASSOCIATION } from '../../constants';

import { ShowPrice } from './styles';

@connect(
    createStructuredSelector({
        activeStore: ggStoreSelectors.storeActive,
    }),
)
export default class StoreShowPrice extends Component {
    render() {
        const {
            price,
            orderCurrencySymbol,
            currencySymbol,
            currency,
            activeStore,
            inline,
        } = this.props;
        const currencyS =
            orderCurrencySymbol || currencySymbol || CURRENCIES_ASSOCIATION[currency] || '€';

        if (price) {
            switch (activeStore.code) {
                case 'usa':
                case 'uk':
                    return (
                        <ShowPrice inline={inline}>
                            {currencyS}
                            {price}
                        </ShowPrice>
                    );

                default:
                    return (
                        <ShowPrice inline={inline}>
                            {price} {currencyS}
                        </ShowPrice>
                    );
            }
        } else {
            return null;
        }
    }
}

const flowNames = {
    SIGN_IN: 'SIGN_IN',
    PASSWORD_RECOVERY: 'PASSWORD_RECOVERY',
    PASSWORD_SETUP: 'PASSWORD_SETUP',
    PASSWORD_SETUP_ERROR: 'PASSWORD_SETUP_ERROR',

    SIGN_UP_CUSTOMER: 'SIGN_UP_CUSTOMER',
    SIGN_UP_BUSINESS: 'SIGN_UP_BUSINESS',
    SIGN_UP_BUSINESS_WITH_COMPANY_SIZE: 'SIGN_UP_BUSINESS_WITH_COMPANY_SIZE',
    SIGN_UP_INDIVIDUAL_BUSINESS: 'SIGN_UP_INDIVIDUAL_BUSINESS',
    LEGAL_REP_SIGN_UP: 'LEGAL_REP_SIGN_UP',
    COMPANY_AGENT_SIGN_UP: 'COMPANY_AGENT_SIGN_UP',

    MISSING_INFO_BUSINESS: 'MISSING_INFO_BUSINESS',
    MISSING_INFO_LEGACY_BUSINESS: 'MISSING_INFO_LEGACY_BUSINESS',
    MISSING_INFO_PRIVATE: 'MISSING_INFO_PRIVATE',
    MISSING_VERIFICATION_PRIVATE: 'MISSING_VERIFICATION_PRIVATE',
    MISSING_VERIFICATION_PRIVATE_ERROR: 'MISSING_VERIFICATION_PRIVATE_ERROR',

    INVALID_LINK: 'INVALID_LINK',
    LEGAL_VERIFICATION: 'LEGAL_VERIFICATION',
    LEGAL_VERIFICATION_ERROR: 'LEGAL_VERIFICATION_ERROR',
    AWAITING_ID_VERIFICATION: 'AWAITING_ID_VERIFICATION',
    SIGN_IN_FROM_CHECKOUT: 'SIGN_IN_FROM_CHECKOUT',
    SIGN_UP_CUSTOMER_FROM_CHECKOUT: 'SIGN_UP_CUSTOMER_FROM_CHECKOUT',
    SIGN_UP_BUSINESS_FROM_CHECKOUT: 'SIGN_UP_BUSINESS_FROM_CHECKOUT',
    PASSWORD_RECOVERY_FROM_CHECKOUT: 'PASSWORD_RECOVERY_FROM_CHECKOUT',

    VERIFICATION_ERROR: 'VERIFICATION_ERROR',
    VERIFICATION_IN_PROGRESS: 'VERIFICATION_IN_PROGRESS',
    VERIFICATION_PROFILE: 'VERIFICATION_PROFILE',

    IDENTITY_VERIFICATION: 'IDENTITY_VERIFICATION',
    IDENTITY_VERIFICATION_CAMPAIGN: 'IDENTITY_VERIFICATION_CAMPAIGN',
    IDENTITY_VERIFICATION_ERROR: 'IDENTITY_VERIFICATION_ERROR',
    BANK_ACCOUNT_VERIFICATION: 'BANK_ACCOUNT_VERIFICATION',
    BANK_ACCOUNT_VERIFICATION_ERROR: 'BANK_ACCOUNT_VERIFICATION_ERROR',
    BANK_ACCOUNT_VERIFICATION_IN_PROGRESS: 'BANK_ACCOUNT_VERIFICATION_IN_PROGRESS',
    BANK_ACCOUNT_VERIFICATION_INVALID_LINK: 'BANK_ACCOUNT_VERIFICATION_INVALID_LINK',
    BANK_ACCOUNT_VERIFICATION_ALREADY_VERIFIED: 'BANK_ACCOUNT_VERIFICATION_ALREADY_VERIFIED',

    PHONE_NUMBER_VERIFICATION: 'PHONE_NUMBER_VERIFICATION',
    PHONE_NUMBER_UPDATE: 'PHONE_NUMBER_UPDATE',
    EMAIL_UPDATE: 'EMAIL_UPDATE',
    EMAIL_UPDATE_DONE: 'EMAIL_UPDATE_DONE',
    EMAIL_UPDATE_FAIL: 'EMAIL_UPDATE_FAIL',

    PROTECTIVE_PASSWORD: 'PROTECTIVE_PASSWORD',

    TWO_FACTOR_AUTH_CODE: 'TWO_FACTOR_AUTH_CODE',
    TWO_FACTOR_AUTH_RESEND_CODE: 'TWO_FACTOR_AUTH_RESEND_CODE',

    BLACK_FRIDAY_EARLY_ACCESS: 'BLACK_FRIDAY_EARLY_ACCESS',
    BLACK_FRIDAY_SIGN_IN: 'BLACK_FRIDAY_SIGN_IN',
    NEWSLETTER: 'NEWSLETTER',
};

export default flowNames;

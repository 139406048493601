/* eslint-disable */
import Typography from 'ggApp/shared/components/typography';
import PropTypes from 'prop-types';
import React from 'react';
import omit from 'lodash/omit';
import { Cta, Button } from './styles';

export default class WidgetCta extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        clickClassName: PropTypes.string,
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        target: PropTypes.string,
        style: PropTypes.object,
        external: PropTypes.bool,
    };

    static defaultProps = {
        className: '',
        clickClassName: '',
        text: '',
        target: '_self',
        external: false,
        linkTo: null,
        style: null,
    };

    render() {
        const {
            className,
            clickClassName,
            text,
            target,
            linkTo,
            style,
            external,
            onClick,
            useAppNavigation,
        } = this.props;
        if (!linkTo) return null;

        if (useAppNavigation) {
          return (
            <Button onClick={onClick} style={style}>
              <Typography className={clickClassName} tag="h3">
                {text}
              </Typography>
            </Button>
          )
        }

        return (
            <Cta
                className={className}
                external={external}
                style={style}
                to={linkTo}
                onClick={onClick}
                {...(target && target !== '_self' && ({ target }))}
                {...omit(this.props, ['target'])}
            >
                <Typography className={clickClassName} tag="h3">
                    {text}
                </Typography>
            </Cta>
        );
    }
}

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { bindActionCreators } from 'redux';
import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import ggRouter from 'ggApp/modules/router';

import Mobile from './Mobile';

export const BOTTOM_NAV_HEIGHT = 64;

const enhance = connect(
    createStructuredSelector({
        storeActive: activeStoreSelector,
    }),
    (dispatch) => ({
        routerActions: bindActionCreators(ggRouter.actions, dispatch),
    }),
);

export default enhance(Mobile);

import { storeDictionary } from 'ggApp/modules/store/constants';
import { StoreDefinition } from '../services/integration';

export const getCheckoutCountry = (country: StoreDefinition) => {
    let language = 'en';
    let countryCode = 'de';

    if (country.activeLanguage) {
        language = country.activeLanguage;
    }

    if (country.country_code) {
        countryCode = country.country_code.toLowerCase();
    }

    return storeDictionary[language][countryCode];
};

export const NlCountryCode = 166;

export const formatZipcode = (code: string, countryId?: number): string | null => {
    if (!code) {
        return null;
    }

    if (countryId !== NlCountryCode) {
        return code.trim();
    }

    return code
        .replace(/([0-9]+)([A-Za-z])/, '$1 $2')
        .toUpperCase()
        .trim();
};

import React from 'react';
import MobileTaskBar from 'ggApp/shared/components/taskBar/Mobile';
import PropTypes from 'prop-types';
import ClickOutside from 'react-click-outside';

import { Content, Container } from './styled';

@ClickOutside
export default class MobileNavigation extends React.PureComponent {
    handleClickOutside() {
        const { closeMenu } = this.props;
        closeMenu();
    }

    render() {
        const { className, innerRef, shouldShowMenu, toggleMenu, closeMenu } = this.props;
        const inWebView = !!window.ReactNativeWebView;
        return (
            <Content className={className} ref={innerRef} inWebView={inWebView}>
                <Container>
                    <MobileTaskBar
                        inWebView={inWebView}
                        shouldShowMenu={shouldShowMenu}
                        handleToggleMenu={toggleMenu}
                        onCloseMenu={closeMenu}
                    />
                </Container>
            </Content>
        );
    }
}

MobileNavigation.propTypes = {
    innerRef: PropTypes.func,
    shouldShowMenu: PropTypes.bool,
    toggleMenu: PropTypes.func,
    closeMenu: PropTypes.func,
    className: PropTypes.string,
};

MobileNavigation.defaultProps = {
    className: '',
    innerRef: () => {},
    shouldShowMenu: false,
    toggleMenu: () => {},
    closeMenu: () => {},
};

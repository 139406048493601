/* eslint-disable */
import {PureComponent,} from 'react';
import PropTypes from 'prop-types'
import Observer from "ggApp/shared/components/viewport/observer";

class WithUserDevice extends PureComponent {
    static childContextTypes = {userDeviceFromSSR: PropTypes.string,};
    static propTypes = {userAgent: PropTypes.string,};

    constructor(props) {
        super(props);
        const {userAgent,} = props;
        let defaultState = {};
        if (userAgent) {
            this.Observer = new Observer(userAgent);
            defaultState = {
                userDeviceFromSSR: this.Observer.getCurrentDevice,
            };
        }
        this.state = defaultState;
    }

    getChildContext() {
        return {
            userDeviceFromSSR: this.state.userDeviceFromSSR,
        }
    }

    render() {
        return this.props.children
    }
}

export default WithUserDevice

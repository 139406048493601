import {
    fromPromise,
    ApolloLink,
    Observable,
    NextLink,
    Operation,
    FetchResult,
    ServerError,
} from '@apollo/client';

import { ErrorLink } from '@apollo/client/link/error';
import { getAccessToken, getRefreshToken } from 'ggApp/modules/auth/utils';
import { refreshAccessToken } from 'ggApp/utils/requests/refresh';

type ObservableAccessTokenRefresmentParameters = {
    operation: Operation;
    forward: NextLink;
};

function handleObservableAccessTokenRefreshment({
    operation,
    forward,
}: ObservableAccessTokenRefresmentParameters): Observable<
    FetchResult<Record<string, any>, Record<string, any>, Record<string, any>>
> {
    const { headers, cookiesManager } = operation.getContext();

    const refreshTokenObservable = fromPromise(refreshAccessToken(cookiesManager)).flatMap(() => {
        const refreshedAccessToken = getAccessToken(cookiesManager);
        const authorization = Boolean(refreshedAccessToken)
            ? `Bearer ${refreshedAccessToken}`
            : null;

        operation.setContext({
            headers: {
                ...headers,
                ...(Boolean(refreshedAccessToken) ? { authorization } : {}),
            },
        });

        return forward(operation);
    });

    return refreshTokenObservable;
}

const ErrorHandlingMiddleware = new ErrorLink(
    ({ graphQLErrors, networkError, operation, forward }) => {
        const { cookiesManager } = operation.getContext();
        const refreshToken = getRefreshToken(cookiesManager);

        const hasNetworkUnauthenticatedError = (networkError as ServerError)?.statusCode === 401;
        const hasGraphqlUnauthenticatedError = graphQLErrors?.some(
            (error) => error.extensions?.code === 'UNAUTHENTICATED',
        );

        if (refreshToken && (hasNetworkUnauthenticatedError || hasGraphqlUnauthenticatedError)) {
            return handleObservableAccessTokenRefreshment({ operation, forward });
        }

        return undefined;
    },
);

const BeforeQueryMiddleware = new ApolloLink((operation, forward) => {
    const { cookiesManager, accessToken } = operation.getContext();

    const cookiesAccessToken = getAccessToken(cookiesManager);
    const refreshToken = getRefreshToken(cookiesManager);

    if (refreshToken && !accessToken && !cookiesAccessToken) {
        return handleObservableAccessTokenRefreshment({ operation, forward });
    }

    return forward(operation);
});

const RefreshTokenMiddleware = ApolloLink.from([BeforeQueryMiddleware, ErrorHandlingMiddleware]);

export default RefreshTokenMiddleware;

export const MODULE_NAME = 'subscription';

export const STATUS_ACTIVE = 'active';
export const STATUS_OVERDUE = 'overdue';
export const STATUS_CLOSED = 'closed';
export const STATUS_ON_HOLD = 'on-hold';
export const STATUS_PENDING_SHIPMENT = 'pending-shipment';
export const STATUS_SHIPPED = 'shipped';

export const STATUS_ASSET_PENDING_SHIPMENT = 'pending-shipment';
export const STATUS_ASSET_SHIPPED = 'shipped';

export const STATUS_ACTIVE_SETUP = {
    circleColor: '#FF1874',
    showDate: true,
    showCross: false,
    totalPercentage: 86,
    fixedPercentage: null,
    circleRotateAngle: 25,
};

export const STATUS_OVERDUE_SETUP = {
    circleColor: '#FF0000',
    showDate: false,
    showCross: true,
    totalPercentage: 92,
    fixedPercentage: 92,
    circleRotateAngle: 14,
};

export const STATUS_CLOSED_SETUP = {
    circleColor: '#9A9A9A',
    showDate: false,
    showCross: false,
    totalPercentage: 100,
    fixedPercentage: 100,
    circleRotateAngle: 0,
};

export const STATUS_ON_HOLD_SETUP = {
    circleColor: '#fad53e',
    showDate: false,
    showCross: false,
    totalPercentage: 100,
    fixedPercentage: 100,
    circleRotateAngle: 0,
};

export const STATUS_PENDING_SHIPMENT_SETUP = {
    circleColor: 'rgba(65, 95, 255, 1)',
    showDate: false,
    showCross: false,
    totalPercentage: 100,
    fixedPercentage: 25,
    circleRotateAngle: 0,
};

export const STATUS_SHIPPED_SETUP = {
    circleColor: 'rgba(65, 95, 255, 1)',
    showDate: false,
    showCross: false,
    totalPercentage: 100,
    fixedPercentage: 50,
    circleRotateAngle: 0,
};

export const SUBSCRIPTION_ACTION_TYPES = {
    REPORT_DAMAGE: 'ReportDamage',
    RETURN: 'PrintReturnLabel',
    CHANGE_PAYMENT_METHOD: 'ChangePaymentMethod',
    PAYMENT_METHOD: 'PaymentMethodPresenter',
    SUPPORT_REQUEST: 'SupportRequest',
    RENT_TO_OWN: 'Buyout',
    UPGRADE_RENTAL_PLAN: 'UpgradeRentalPlan',
    CANCEL_SUBSCRIPTION: 'CancelSubscription',
};

export const PRODUCT_CATEGORY_COLOR = 'rgba(65, 95, 255, 1)';

export const UNAVAILABLE_REASONS = {
    IN_RETURN: 'in_return',
    IN_REPLACEMENT: 'in_replacement',
    TOO_EARLY: 'too_early',
    UNKNOWN: 'unknown_reason',
    CUSTOMER_SERVICE_HELP: 'customer_service_help',
    OUTSTANDING_PAYMENT: 'outstanding_payments',
};

export const PAYMENT_METHOD = {
    CARD: 'CreditCard',
    PAYPAL: 'PayPal',
};

export const RENT_TO_OWN = {
    MONTHS: 'months_to_own',
    OLD: 'old',
};

export function internalToolsReducer(state, action) {
    switch (action.type) {
        case 'toggleHighlightLokalise': {
            const { highlightLokalise } = state;
            if (highlightLokalise) {
                return { ...state, highlightLokalise: false };
            }
            return { ...state, highlightLokalise: true };
        }
        case 'toggleDarkModePreview': {
            const { darkModePreview } = state;
            if (darkModePreview) {
                return { ...state, darkModePreview: false };
            }
            return { ...state, darkModePreview: true };
        }
        default: {
            return { ...state };
        }
    }
}

export const initialState = {
    highlightLokalise: false,
    darkModePreview: false,
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios from 'ggApp/utils/requests';
import stepIdentifierMapper, {
    mapBISteps,
} from 'ggApp/modules/newCheckout/utils/stepIdentifierMapper';
import orderUpdateMutation from 'ggApp/shared/context/Order/services/cartServiceAPI/order/orderUpdate.graphql';
import { CheckoutMachine } from 'ggApp/shared/context/Order/services/integration';
import { OrderModes } from 'ggApp/shared/context/Order/types';
import { CHECKOUT_STEP_NAMES } from 'ggApp/modules/newCheckout/constants';
import { sendToGtm } from 'ggApp/modules/tracking/actions';
import { ANALYTIC_EVENTS } from 'ggApp/modules/gaAnalytics/constants';
import CookiesManager from 'ggApp/utils/CookiesManager';
import { IP_ADDRESS_COOKIE_NAME } from 'ggApp/utils/ipAddressCookie/constants';
import { sendCheckoutStep } from 'snowplow';
import { trackOrderSubmitted } from 'analytics/checkout/orderSubmitted';
import { formatPrice } from 'ggApp/modules/gaAnalytics/utils';
import { sendStructEvent } from 'snowplow/api';
import { initializeConversionTagging } from '@devsbb/affiliates';
import { getCheckoutFlow, getStepFromState, getDataFromContext, getOrderData } from './utils';
import { OrderItemCategory } from '../../../../../../../packages/analytics/checkout/types';

interface PayloadModel {
    newCheckoutFlag: { variantKey: string };
}

// export for testing
export enum hookTypes {
    enter = 'enter',
    close = 'exit.modal_close',
    leave = 'exit.confirm',
    stop = 'stopCheckout', // from machine state
}
export const getSubCategory = (category: string) => {
    const productCategoryTitle = category?.substring(1).split('/');
    const subCategory = productCategoryTitle?.[1] || '';
    return subCategory;
};

export const getOrderCategories = (products: OrderItemCategory[]): string[] => {
    return (products ?? []).map((p) => p?.product?.category?.title ?? '');
};
export const getOrderSubCategories = (products?: OrderItemCategory[]): string[] => {
    return (products ?? []).map((p) => getSubCategory(p.product?.category?.permalink ?? '') ?? '');
};
const getHook = (hookType: hookTypes, payload: PayloadModel) => (
    context: CheckoutMachine,
    event: any,
    machineState: any,
): void => {
    // if you know, why hook is fired for these events, fix it
    if (['REMOVE_LINE_ITEM', 'done.invoke.removeLineItem'].includes(event?.type || '')) {
        return;
    }
    const { userId, orderId } = getDataFromContext(context);
    const cookiesManager = new CookiesManager();
    const ipAddress = cookiesManager.get(IP_ADDRESS_COOKIE_NAME);
    const { newCheckoutFlag } = payload || {};
    const checkoutSteps = [...context.steps];
    const step =
        hookType === hookTypes.enter ? getStepFromState(machineState.state) : checkoutSteps.pop();
    const previousStep = checkoutSteps.pop();
    const flowName = getCheckoutFlow(newCheckoutFlag);

    const eventPayload = {
        eventAction: stepIdentifierMapper(step),
        eventLabel: hookType,
        eventCategory: 'checkout',
        eventProperty: {
            current_flow: flowName,
            orderID: orderId as string,
            userID: userId as number,
            client_ip_address: ipAddress === 'unknown' ? null : ipAddress,
            ...(previousStep && {
                previous_step: stepIdentifierMapper(previousStep),
            }),
        },
    };

    if (![CHECKOUT_STEP_NAMES.ORDER_CONFIRMATION, CHECKOUT_STEP_NAMES.ORDER_ERROR].includes(step)) {
        sendCheckoutStep(eventPayload);
    } else if (hookType === hookTypes.enter) {
        const { cart, orderNumber, activeStore } = machineState.state.context;
        let revenue = cart.items?.reduce(
            (res: number, product: { selectedPlan: number; priceCents: number }) => {
                return res + product?.selectedPlan * product?.priceCents;
            },
            0,
        );

        revenue -= cart?.discountTotal + cart?.shipmentPrice;
        const orderData = getOrderData({
            price: context.cart && (context.cart.totalPrice as number),
            items: (context.cart && context.cart.items) || [],
            isFlex: context.orderMode === OrderModes.Flex,
            storeId: context.activeStore.store_id,
        });
        sendStructEvent({
            eventAction:
                step === CHECKOUT_STEP_NAMES.ORDER_CONFIRMATION
                    ? 'submitted_order'
                    : 'submitted_order_failed',
            eventLabel: hookType,
            eventProperty: {
                store_id: context.activeStore.store_id,
                current_flow: eventPayload.eventProperty.current_flow,
                order_id: eventPayload.eventProperty.orderID,
                previous_step: eventPayload.eventProperty.previous_step,
                client_ip_address: eventPayload.eventProperty.client_ip_address,
                total_order_price: orderData.price,
                product_name: orderData.productData.name,
                product_sku: orderData.productData.productSKU,
                subscription_length: orderData.productData.subscriptionLength,
                page_url_path: window.location.pathname,
                referrer: document.referrer,
                session_id: cookiesManager.get('session_id'),
            },
        });

        trackOrderSubmitted({
            order_id: orderNumber ?? '',
            store_id: activeStore.store_id,
            revenue: parseFloat(formatPrice(revenue)),
            products:
                cart.items?.map(
                    (p: {
                        variant: { product: { brand: any; sku: any }; id: any; sku: any };
                        product: {
                            category: { title: any; permalink: string };
                            subcategory: { title: any };
                            currency: any;
                            original_image_url: any;
                            name: any;
                            id: any;
                        };
                        priceCents: any;
                        quantity: any;
                        rentalPlanId: any;
                        selectedPlan: any;
                    }) => ({
                        brand: p.variant.product.brand,
                        category: p.product.category?.title,
                        non_discounted_price: p.priceCents,
                        subcategory: p.product.subcategory?.title,
                        sub_category: getSubCategory(p.product.category?.permalink),
                        currency: p.product.currency,
                        image_url: p.product.original_image_url,
                        product_name: p.product.name,
                        price: p.priceCents,
                        product_id: p.product.id,
                        id: p.variant.id,
                        product_sku: p.variant.product.sku,
                        quantity: p.quantity,
                        rental_plan_id: p.rentalPlanId,
                        subscription_length: p.selectedPlan,
                        variant_sku: p.variant.sku,
                    }),
                ) ?? [],
            total_price: cart.itemTotal ?? 0,
            categories: getOrderCategories(cart.items),
            subCategories: getOrderSubCategories(cart.items),
            result: step === CHECKOUT_STEP_NAMES.ORDER_CONFIRMATION ? 'success' : 'failure',
            risk_check_result: null,
        });
        const { user } = context;
        const totalAmount = cart?.items?.reduce(
            (acc: number, val: { selectedPlan: number; priceCents: number; quantity: number }) =>
                acc + (val?.selectedPlan ?? 1) * val?.priceCents * val.quantity,
            0,
        );
        const conversionTagPayload = {
            userId: String(userId),
            email: user?.email,
            orderId: String(orderNumber),
            currency: String(activeStore.default_currency),
            amount: totalAmount ?? 0,
            discount: cart.discountTotal ?? 0,
            coupon: cart.voucher?.name || '',
            customerCountry: activeStore?.country_code.toLocaleUpperCase() ?? '',
            brand: cart?.items?.[0]?.variant?.product?.brand ?? '',
            category: cart?.items?.[0]?.product?.category?.title ?? '',
            items: cart?.items?.map(
                (item: {
                    variant: { product: { sku: string } };
                    priceCents: number;
                    quantity: number;
                }) => {
                    return {
                        itemId: String(item?.variant?.product?.sku),
                        unitPrice: item?.priceCents,
                        quantity: item.quantity,
                    };
                },
            ),
        };
        initializeConversionTagging(conversionTagPayload, activeStore?.code ?? '');
    }
    sendToGtm({ event: ANALYTIC_EVENTS.gtmEvent, ...eventPayload });
};
export const getTrackingHooks = (payload: PayloadModel) => {
    return {
        onEnter: getHook(hookTypes.enter, payload),
        onExit: getHook(hookTypes.leave, payload),
        onClose: getHook(hookTypes.close, payload),
    };
};

export const updateFlexOrderStepState = async ({ orderNumber, currentStep }) => {
    const trackingStep = mapBISteps(currentStep);
    return axios.patch(`orders/${orderNumber}`, { step: trackingStep }).then((res) => res.data);
};

export const updateMixOrderStepState = async ({ orderNumber, currentStep }) => {
    const trackingStep = mapBISteps(currentStep);
    try {
        const { CartServiceClient } = await import(
            'ggApp/shared/context/Order/services/cartServiceAPI/client'
        );
        const { data } = await CartServiceClient.mutation({
            mutation: orderUpdateMutation,
            variables: { orderNumber, step: trackingStep },
        });

        return data;
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const updateOrderStepState = ({ orderMode, orderNumber, currentStep }) => {
    if (orderMode === OrderModes.Flex) {
        updateFlexOrderStepState({ orderNumber, currentStep });
    }
    if (orderMode === OrderModes.Mix) {
        updateMixOrderStepState({ orderNumber, currentStep });
    }
};

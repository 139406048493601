/* eslint-disable no-nested-ternary */
import React, { useRef } from 'react';
import { ButtonV2 } from '@getgrover/ui';

import Link from 'ggApp/shared/containers/FrontshopLink';
import { useFeatureFlags } from 'ggApp/modules/featureFlag/hooks/useFeatureFlags';
import { HOMEPAGE_MC1_AB_TEST } from 'ggApp/modules/featureFlag';
import { registerTeaserClickEvent, registerTeaserImpressionEvent } from 'ggApp/modules/gaAnalytics';
import useIntersection from 'ggApp/shared/hooks/useIntersection';
import { WIDGET_NAMES } from 'ggApp/modules/gaAnalytics/constants';

import { CarouselCardProps } from '../types';

import { Wrapper, Grid, ContentWrap, Content, Header, Body, Banner, CtaWrapper } from './styles';

export const DesktopCarouselCard = ({
    campaignBannerContent: {
        title,
        text,
        textColor,
        cta,
        ctaColor,
        carouselImage,
        carouselDesktopImage,
        backgroundColor,
        backgroundType,
    },
    clickClassName,
    className,
}: CarouselCardProps) => {
    const homepageMc1TestEnabled = useFeatureFlags();
    const carouselCardRef = useRef(null);

    const bgType = backgroundType || 'solid';

    const link =
        homepageMc1TestEnabled?.isFlagEnabled(HOMEPAGE_MC1_AB_TEST) && cta.alternativeURL
            ? cta.alternativeURL
            : cta.url;

    useIntersection({
        node: carouselCardRef,
        onExitCallback: null,
        onEnterCallback: registerTeaserImpressionEvent({
            widgetName: WIDGET_NAMES.marketing1,
            url: link,
            title,
        }),
    });

    const handleCtaCLick = registerTeaserClickEvent({
        widgetName: WIDGET_NAMES.marketing1 ?? '',
        title,
        url: link,
        ctaViewAll: false,
    });

    return (
        <Wrapper className={className} ref={carouselCardRef}>
            <Link
                className={clickClassName}
                external={!cta.internal}
                target="_self"
                to={link}
                onClick={handleCtaCLick}
            >
                <Grid backgroundColor={backgroundColor}>
                    <ContentWrap backgroundColor={backgroundColor}>
                        <Content>
                            <Header textColor={textColor}>{title}</Header>
                            <Body textColor={textColor} html={text} />
                            {cta && (
                                <CtaWrapper>
                                    {ctaColor === 'White (outlined)' ? (
                                        <ButtonV2 outlined>{cta.label}</ButtonV2>
                                    ) : ctaColor === 'Grey' ? (
                                        <ButtonV2>{cta.label}</ButtonV2>
                                    ) : (
                                        <ButtonV2 color="red">{cta.label}</ButtonV2>
                                    )}
                                </CtaWrapper>
                            )}
                        </Content>
                    </ContentWrap>
                    <Banner
                        imgUrls={carouselDesktopImage?.url ?? carouselImage?.url}
                        src={carouselDesktopImage?.url ?? carouselImage?.url}
                        lazy={false}
                        backgroundImage={carouselDesktopImage?.url ?? carouselImage?.url}
                        backgroundType={bgType}
                    />
                </Grid>
            </Link>
        </Wrapper>
    );
};

import { MODULE_NAME } from '../constants';

const defaultState = {
    twoFactorAuthRequired: false,
    phoneLastDigits: null,
    resendCodeTimeoutSeconds: 0,
    resendCodeAvailableTime: Date.now(),
};

export default function(state = defaultState, action) {
    switch (action.type) {
        case `${MODULE_NAME}/SET_TWO_FACTOR_AUTHENTICATION`:
            return {
                ...state,
                ...action.data,
            };
        case `${MODULE_NAME}/CLEAR_TWO_FACTOR_AUTHENTICATION`:
            return defaultState;
        case `${MODULE_NAME}/SET_TWO_FACTOR_AUTHENTICATION_TIMEOUT`:
            return {
                ...state,
                resendCodeAvailableTime: action.data,
            };

        default:
            return state;
    }
}

import { useMemo } from 'react';

import plurString from 'ggApp/utils/plurString';

import { replaceTranslatedStringValues } from '../utils';

function useTranslatedText({ activeStoreLanguage, activeTranslatorKeys, tk, withValues }) {
    const translatedText = useMemo(() => {
        const keys = activeTranslatorKeys[activeStoreLanguage];
        const missedTranslatorKey = !(keys && typeof keys[tk] === 'string');
        const emptyTranslatorKey = !missedTranslatorKey && keys[tk].length === 0;

        if (missedTranslatorKey) {
            return tk;
        }

        if (emptyTranslatorKey) {
            return `Empty translator key for '${tk}'`;
        }

        if (withValues) {
            const pluralisedString = plurString(
                activeTranslatorKeys[activeStoreLanguage][tk],
                withValues,
            );

            return replaceTranslatedStringValues(pluralisedString, withValues);
        }

        return activeTranslatorKeys[activeStoreLanguage][tk];
    }, [withValues, activeStoreLanguage, activeTranslatorKeys, tk]);

    return translatedText;
}

export default useTranslatedText;

import React, { useState } from 'react';

import SharedReviewCarousel from 'ggApp/shared/components/ReviewIO/components/ReviewCarousel';

import { Root } from './styles';

interface ReviewsWidgetProps {
    className: string;
    userDevice: string;
}

const ReviewsWidget = ({ className, userDevice }: ReviewsWidgetProps) => {
    const [reviewsFetched, setReviewsFetched] = useState(false);
    return (
        <Root className={className} data-testid="reviewsIOWidget" reviewsFetched={reviewsFetched}>
            <SharedReviewCarousel userDevice={userDevice} setReviewsFetched={setReviewsFetched} />
        </Root>
    );
};

export default ReviewsWidget;

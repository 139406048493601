/* eslint-disable consistent-return */
import { SubmissionError } from 'redux-form';
import moment from 'moment';
import cc from 'card-validator';
import IBAN from 'iban';
import isObjEmpty from 'lodash/isEmpty';

import { adaptSignupAPIErrorsToFormErrors } from 'ggApp/modules/auth/adapters';

import { errorsFromResponse } from 'ggApp/utils/requests/errorHandler';
import { FORM_DATE_FORMAT } from '../constants';

function withDateValidation(errors) {
    if (!!errors && !!errors.birthdate) {
        return { ...errors, birthdate: errors.birthdate };
    }
    return errors;
}

export function isNumber(value) {
    return /^(0|[1-9][0-9]*)$/i.test(value);
}

export function requiredValidation(value) {
    if (!value) {
        return 'FORM_VALIDATION_ERROR_REQUIRED';
    }
}

export function cityValidation(value) {
    if (!value) {
        return 'FORM_VALIDATION_ERROR_REQUIRED';
    }

    if (/\d/i.test(value)) {
        return 'FORM_VALIDATION_ERROR_REMOVE_NUMBER';
    }
}

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export function emailValidation(value) {
    if (!value) {
        return 'FORM_VALIDATION_ERROR_REQUIRED';
    }
    if (!EMAIL_REGEX.test(value)) {
        return 'FORM_VALIDATION_ERROR_EMAIL';
    }
}

export function passwordValidation(value) {
    if (!value) {
        return 'FORM_VALIDATION_ERROR_REQUIRED';
    }
}

export function passwordConfiramtionValidation(password, passwordConfirmation) {
    if (!passwordConfirmation) {
        return 'FORM_VALIDATION_ERROR_REQUIRED';
    }
    if (passwordConfirmation.length < 8) {
        return 'FORM_VALIDATION_ERROR_PASSWORD';
    }
    if (password !== passwordConfirmation) {
        return 'FORM_VALIDATION_ERROR_PASSWORD_CONFIRMATION';
    }
}

export function numberValidation(value) {
    if (!value) {
        return 'FORM_VALIDATION_ERROR_REQUIRED';
    }
    if (!/^(0|[1-9][0-9]*)$/i.test(value)) {
        return 'FORM_VALIDATION_ERROR_NUMBER';
    }
}

export function notRequiredNumberValidation(value) {
    if (value && !isNumber(value)) {
        return 'FORM_VALIDATION_ERROR_NUMBER';
    }
}

export function phoneVerificationValidation(phone) {
    return {
        code: requiredValidation(phone.code),
        verificationCode: requiredValidation(phone.verificationCode),
        number: numberValidation(phone.number),
        isVerified: !phone.isVerified ? 'FORM_VALIDATION_ERROR_PHONE_NOT_VERIFIED' : false,
    };
}

export function datepickerValidation(value) {
    if (!value) {
        return 'FORM_VALIDATION_ERROR_REQUIRED';
    }
    if (moment(value, FORM_DATE_FORMAT).format(FORM_DATE_FORMAT) === value) {
        return 'FORM_VALIDATION_ERROR_DATE_FORMAT';
    }
}

export function serverValidation({ response: errorRes = {} }) {
    if (errorRes.data) {
        let errorObj = {};

        if (errorRes.data.base) {
            errorObj = {
                _error: errorRes.data.base,
            };
        } else {
            errorObj = withDateValidation(errorRes.data.error);
        }

        throw new SubmissionError(errorObj);
    } else {
        throw new SubmissionError({
            _error: errorRes.status
                ? `Something went wrong. Status: ${errorRes.status}`
                : 'Network connection error',
        });
    }
}

export function signupServerValidation(response) {
    const { data, status } = response;

    if (!data) {
        throw new SubmissionError({
            _error: status ? `Something went wrong. Status: ${status}` : 'Network connection error',
        });
    }

    const validationErrors = adaptSignupAPIErrorsToFormErrors(errorsFromResponse(response));

    throw new SubmissionError(validationErrors);
}

export function passwordResetServerValidation(response) {
    const { data, status } = response;
    if (!data) {
        throw new SubmissionError({
            _error: status ? `Something went wrong. Status: ${status}` : 'Network connection error',
        });
    }

    const validationErrors = errorsFromResponse(response);

    // Token exipred;
    // TODO: move this message to Translations
    if (validationErrors.reset_password_token) {
        throw new SubmissionError({
            _error: 'Password reset session expired. Please request another email!',
        });
    }

    if (validationErrors.base) {
        throw new SubmissionError({
            _error: validationErrors.base,
        });
    }

    if (!isObjEmpty(validationErrors)) {
        throw new SubmissionError(validationErrors);
    }

    throw new SubmissionError({
        _error: `Something went wrong. Status: ${status}`,
    });
}

export const validateCCNumber = ({ asBool = false, number = '' }) => {
    if (asBool) {
        return cc.number(number).isValid;
    }
    if (!number) {
        return 'FORM_VALIDATION_ERROR_REQUIRED';
    }

    if (!cc.number(number).isValid) {
        return 'FORM_VALIDATION_ERROR_INVALID_CC_NUMBER';
    }
};

export const validateCCExp = ({ asBool = false, exp = '' }) => {
    if (asBool) {
        return cc.expirationDate(exp).isValid;
    }

    if (!exp) {
        return 'FORM_VALIDATION_ERROR_REQUIRED';
    }

    if (!cc.expirationDate(exp).isValid) {
        return 'FORM_VALIDATION_ERROR_INVALID_EXPIRATION_DATE';
    }
};

export const validateCCCvv = ({ asBool = false, cvv = '' }) => {
    if (asBool) {
        return cc.cvv(cvv, cvv.length).isValid;
    }

    if (!cvv) {
        return 'FORM_VALIDATION_ERROR_REQUIRED';
    }

    if (!cc.cvv(cvv, cvv.length).isValid) {
        return 'FORM_VALIDATION_ERROR_INVALID_CVC';
    }
};

export const validateSepaIban = (iban = '') => {
    if (!iban) {
        return 'FORM_VALIDATION_ERROR_REQUIRED';
    }

    if (!IBAN.isValid(iban)) {
        return 'FORM_VALIDATION_ERROR_INVALID_IBAN';
    }
};

export const validateSepaAgreement = (agreement) => {
    if (!agreement) {
        return 'FORM_VALIDATION_ERROR_INVALID_AGREEMENT';
    }
};

export const ustValidation = (ustCode) => {
    const ustIdLength = 11;

    if (!ustCode) {
        return 'FORM_VALIDATION_ERROR_REQUIRED';
    }
    if (ustCode.length < ustIdLength) {
        return 'FORM_VALIDATION_ERROR_INVALID_UST';
    }
};

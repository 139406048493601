import { MixDevice, SubscriptionCard } from 'ggApp/types/graphql';

export enum VaultStorageKeys {
    mixDevices = 'mixDevices',
    subscriptions = 'subscriptions',
    badges = 'badges',
}

export type MixSubscriptionsVaultState = Map<string, MixDevice>;
export type SubscriptionsVaultState = Map<string, SubscriptionCard>;
export type BadgesVaultState = Map<string, boolean>;

export type VaultState = {
    [VaultStorageKeys.mixDevices]: MixSubscriptionsVaultState;
    [VaultStorageKeys.subscriptions]: SubscriptionsVaultState;
    [VaultStorageKeys.badges]: BadgesVaultState;
};

export type SetVaultItemPayload = {
    key: keyof typeof VaultStorageKeys;
    data: MixDevice | SubscriptionCard;
};

export type SetVaultItemsPayload = {
    key: keyof typeof VaultStorageKeys;
    data: MixDevice[] | SubscriptionCard[];
};

export type SetShouldShowBadgePayload = {
    id: string;
    shouldShow: boolean;
};

export function removeNotification(errorId) {
    return {
        type: 'REMOVE_NOTIFICATION',
        data: { errorId },
    };
}

export function addNotification(data) {
    return {
        type: 'ADD_NOTIFICATION',
        data,
    };
}

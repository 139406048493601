import { RedirectFlow, RedirectFlowStatus } from 'groverPaymentSDK';

export default function didReceiveRedirect(locationQuery) {
    const status = locationQuery[RedirectFlow.QueryParamPaymentStatus];
    return [RedirectFlowStatus.Success, RedirectFlowStatus.Failure].includes(status);
}

export function getRedirectFlowDetails(locationQuery) {
    const status = locationQuery[RedirectFlow.QueryParamPaymentStatus];

    if (!status) return null;

    return {
        status,
        paymentMethodTransaction: locationQuery[RedirectFlow.QueryParamPaymentId],
    };
}

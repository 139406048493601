/* eslint-disable */
import {
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_ERROR,

  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,

  USER_LOGOUT,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_ERROR,

} from './../actionTypes';

const defaultState = null;

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'AUTH_FETCHING':
    case CONFIRM_EMAIL:
    case CHANGE_PASSWORD:
    case USER_LOGOUT:
      return true;

    case 'AUTH_STOP_FETCHING':
    case CONFIRM_EMAIL_SUCCESS:
    case CONFIRM_EMAIL_ERROR:
    case CHANGE_PASSWORD_SUCCESS:
    case CHANGE_PASSWORD_ERROR:
    case USER_LOGOUT_SUCCESS:
    case USER_LOGOUT_ERROR:
      return false;

    default:
      return state;
  }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import reactClickOutside from 'react-click-outside';

import { BODY_OPEN_CLASS, HTML_OPEN_CLASS } from '../../constants';
import * as ggModalActions from '../../actions';

import { ModalRoute, Content } from './styles';

@reactClickOutside
@connect(null, (dispatch) => ({ dispatch }))
export default class GGModalRoute extends Component {
    componentDidMount() {
        document.body.classList.add(BODY_OPEN_CLASS);
        document.getElementsByTagName('html')[0].classList.add(HTML_OPEN_CLASS);

        const { noScrollTop } = this.props;

        if (!noScrollTop) {
            window.scrollTo(0, 0);
        }
        window.addEventListener('keydown', ::this.keyHandler);
    }

    componentWillUnmount() {
        document.body.classList.remove(BODY_OPEN_CLASS);
        document.getElementsByTagName('html')[0].classList.remove(HTML_OPEN_CLASS);

        window.removeEventListener('keydown', ::this.keyHandler);
    }

    keyHandler(event) {
        const { dispatch } = this.props;

        if (event.keyCode === 27) {
            dispatch(ggModalActions.close());
        }
    }

    handleClickOutside() {
        const { dispatch } = this.props;

        dispatch(ggModalActions.close());
    }

    render() {
        const {
            ggRouterState,
            withTransitions,
            dark,
            mobileOnly,
            noPadding,
            noMobileVerticalPadding,
            wide,
            narrow,
        } = this.props;
        const locationState = ggRouterState.location.state || {};

        return (
            <ModalRoute withTransitions={withTransitions} dark={dark} mobileOnly={mobileOnly}>
                <Content
                    dark={dark}
                    noPadding={noPadding}
                    noMobileVerticalPadding={noMobileVerticalPadding}
                    wide={wide}
                    narrow={narrow}
                    withTransitions={withTransitions}
                >
                    <this.props.component locationState={locationState} />
                </Content>
            </ModalRoute>
        );
    }
}

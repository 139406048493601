import React from 'react';
import { withTheme } from 'styled-components';
import { IconPropTypes } from 'ggApp/types/propTypes';

const YouTubeIcon = ({ className, size, color, theme }) => {
    const fill = color || theme.colors.Foreground;
    return (
        <svg
            className={className}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 9.53238V14.4676L14.1127 12L10 9.53238ZM8 16.2338C8 17.0111 8.84797 17.4912 9.5145 17.0913L16.5708 12.8575C17.2182 12.4691 17.2182 11.5309 16.5708 11.1425L9.5145 6.9087C8.84797 6.50878 8 6.9889 8 7.76619V16.2338Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 17.5279V6.47214C20 5.68055 19.5727 5.18634 19.1037 5.04244C17.4926 4.54815 15.0223 4 12 4C8.97771 4 6.50742 4.54815 4.8963 5.04244C4.42727 5.18634 4 5.68055 4 6.47214V17.5279C4 18.3194 4.42727 18.8137 4.8963 18.9576C6.50742 19.4518 8.97771 20 12 20C15.0223 20 17.4926 19.4518 19.1037 18.9576C19.5727 18.8137 20 18.3194 20 17.5279ZM4.3097 3.1304C2.86124 3.57478 2 4.95705 2 6.47214V17.5279C2 19.043 2.86124 20.4252 4.3097 20.8696C6.06803 21.409 8.73854 22 12 22C15.2615 22 17.932 21.409 19.6903 20.8696C21.1388 20.4252 22 19.043 22 17.5279V6.47214C22 4.95705 21.1388 3.57478 19.6903 3.1304C17.932 2.59095 15.2615 2 12 2C8.73854 2 6.06803 2.59095 4.3097 3.1304Z"
                fill={fill}
            />
        </svg>
    );
};

YouTubeIcon.propTypes = {
    ...IconPropTypes,
};

YouTubeIcon.defaultProps = {
    className: '',
    size: '24px',
};

export default withTheme(YouTubeIcon);

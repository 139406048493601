import React, { createContext, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { flags as flagsSelector } from './selectors';
import { batchFeatureFlagRequestBuilder } from './actions';

export const FeatureFlagContext = createContext();

const enhance = connect(
    (state) => ({
        flags: flagsSelector(state),
    }),
    (dispatch) => ({
        fetch: bindActionCreators(batchFeatureFlagRequestBuilder, dispatch),
    }),
);

// Provider for DashboardUI integration of feature flags
const Provider = ({ flags, fetch, children }) => {
    const featureFlags = useMemo(() => new Map(Object.entries(flags)), [flags]);
    const getFeatureFlag = useCallback((key) => featureFlags.get(key), [featureFlags]);
    const getFeatureFlagVariant = useCallback((key) => getFeatureFlag(key)?.variantKey, [
        getFeatureFlag,
    ]);
    const isFlagEnabled = useCallback((key) => getFeatureFlagVariant(key) === 'enabled', [
        getFeatureFlagVariant,
    ]);

    const value = useMemo(
        () => ({
            featureFlags,
            fetchFeatureFlags: (keys) => fetch(keys),
            getFeatureFlag,
            getFeatureFlagVariant,
            isFlagEnabled,
        }),
        [featureFlags, fetch, getFeatureFlag, getFeatureFlagVariant, isFlagEnabled],
    );

    return <FeatureFlagContext.Provider value={value}>{children}</FeatureFlagContext.Provider>;
};

export const FeatureFlagProvider = enhance(Provider);

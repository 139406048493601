/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

import { extractCallbacksFromProps } from 'ggApp/modules/tracking/hocs/withListEventHandlers';
import { HandPickedProductCardWithTracking } from 'ggApp/shared/components/cards/handPickedProductCard';
import { Card as WidgetProductCard } from 'ggApp/shared/components/cards/widgetProductCard';
import DisplayIf from 'ggApp/shared/components/viewport';
import { getOrderFromCookies } from 'ggApp/shared/context/Order/cookies';
import { getPriceInfo } from 'ggApp/modules/gaAnalytics';
import { formatProductPrice } from 'ggApp/modules/tracking/selectors';
import { getListNameByRoute } from 'ggApp/modules/gaAnalytics/utils';
import { trackProductClicked } from '../../../../../../../../packages/analytics/product/trackProductClicked';
import { getDeliveryEstimation } from '../../../../../../../../packages/staticStoreConfig';

export default class HandPickedProductWidget extends React.PureComponent {
    render() {
        const {
            className,
            product,
            accentColor,
            productImpressionCallbacks,
            goToProductPage,
            testId,
            position,
            activeStore,
            pathname,
            routeParams,
            widgetListProducts,
        } = this.props;

        const DesktopView = (
            <HandPickedProductCardWithTracking
                product={product}
                accentColor={accentColor}
                onClick={goToProductPage}
                {...extractCallbacksFromProps(productImpressionCallbacks, 0)}
            />
        );
        const MobileView = (
            <WidgetProductCard
                key={product.slug}
                product={product}
                cardType="vertical"
                position={position}
                {...extractCallbacksFromProps(productImpressionCallbacks, 0)}
                handpickedProduct
            />
        );
        const widgetClick = () => {
            const { originalProduct } = product;
            const orderID = getOrderFromCookies(activeStore?.code) || undefined;
            const { rentalPlan } = product;
            const { sku, name, variants } = originalProduct;
            const variant = variants[0] || {};
            const deliveryEstimates = getDeliveryEstimation(activeStore?.code) ?? null;
            const deliveryTime =
                (deliveryEstimates && `${deliveryEstimates.from}-${deliveryEstimates.to}`) ||
                undefined;
            const priceInfo = rentalPlan
                ? getPriceInfo(rentalPlan, false)
                : formatProductPrice(originalProduct);
            const productCategoryTitle = originalProduct.category.permalink.split('/');

            const listName = getListNameByRoute(pathname, routeParams);
            const productIndex = widgetListProducts.findIndex(
                (wProduct) => wProduct.id === product.id.toString(),
            );
            const productClickPayload = {
                brand: originalProduct?.brand ?? product?.brand ?? '',
                currency: originalProduct?.currency ?? activeStore.default_currency ?? '',
                category: productCategoryTitle[0] || '',
                delivery_time: deliveryTime,
                discount_amount: Number(priceInfo?.discountAmount),
                discount_percentage: Number(priceInfo?.discountPercentage),
                displayed_price: Number(priceInfo?.price),
                list: listName,
                position: productIndex,
                name,
                image_url:
                    originalProduct?.original_image_url ?? originalProduct?.originalImageUrl ?? '',
                non_discounted_price: priceInfo?.non_discountedPrice
                    ? Number(priceInfo.non_discountedPrice)
                    : '',
                order_id: orderID,
                price: Number(priceInfo?.price),
                product_id: sku,
                product_name: name,
                product_sku: sku,
                product_variant: String(variant.id),
                sku,
                sub_category: productCategoryTitle[1] || '',
                variant: String(variant.id),
                quantity: 1,
                variant_sku: variant.sku,
            };
            // Sends formatted product clicked (widget) data to segment
            trackProductClicked(productClickPayload);
        };
        if (!product) return null;
        return (
            <section className={className} onClick={widgetClick} data-testid={testId}>
                <DisplayIf tablet desktop largeDesktop widescreen>
                    {DesktopView}
                </DisplayIf>
                <DisplayIf mobile>{MobileView}</DisplayIf>
            </section>
        );
    }
}

HandPickedProductWidget.propTypes = {
    product: PropTypes.shape({}),
    className: PropTypes.string,
    productImpressionCallbacks: PropTypes.arrayOf(PropTypes.func),
    onClick: PropTypes.func,
};

HandPickedProductWidget.defaultProps = {
    product: null,
    className: '',
    productImpressionCallbacks: undefined,
    onClick: () => null,
};

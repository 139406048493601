import { APISelection } from '../../integration';
import addAddressMutation from './addressAdd.graphql';
import changeAddressMutation from './addressChange.graphql';
import { convertAddressPayload } from './utils';

interface AddressInput {
    address: any;
}
export const shippingAddress: APISelection<AddressInput> = {
    add: async ({ orderNumber, activeStore }, input) => {
        if (!input) return null;

        const { CartServiceClient } = await import('../client');
        const address = convertAddressPayload({
            ...input.address,
            ...activeStore,
        });
        const { data } = await CartServiceClient.mutation({
            mutation: addAddressMutation,
            variables: {
                orderNumber,
                input: {
                    address: {
                        ...address,
                        type: 'shipping',
                    },
                },
            },
        });
        return data;
    },
    update: async ({ orderNumber, activeStore }, input) => {
        if (!input) return null;
        const { CartServiceClient } = await import('../client');
        const address = convertAddressPayload({ ...input.address, ...activeStore });
        const { data } = await CartServiceClient.mutation({
            mutation: changeAddressMutation,
            variables: {
                orderNumber,
                input: {
                    address: { ...address, type: 'shipping' },
                },
            },
        });
        return data;
    },
};

export const billingAddress: APISelection<AddressInput> = {
    add: async ({ orderNumber, activeStore }, input) => {
        if (!input) return null;
        const { CartServiceClient } = await import('../client');
        const address = convertAddressPayload({ ...input.address, ...activeStore });
        const { data } = await CartServiceClient.mutation({
            mutation: addAddressMutation,
            variables: {
                orderNumber,
                input: {
                    address: {
                        ...address,
                        type: 'billing',
                    },
                },
            },
        });
        return data;
    },
    update: async ({ orderNumber, activeStore }, input) => {
        if (!input) return null;
        const { CartServiceClient } = await import('../client');

        const address = convertAddressPayload({ ...input.address, ...activeStore });
        const { data } = await CartServiceClient.mutation({
            mutation: changeAddressMutation,
            variables: { orderNumber, input: { address: { ...address, type: 'billing' } } },
        });
        return data;
    },
};

import fetchPonyfill from 'fetch-ponyfill';
import { ApolloLink, HttpLink } from '@apollo/client';
import loadConfiguration from 'ggApp/utils/configuration';

const { fetch } = fetchPonyfill();
const { APP_CONFIG } = loadConfiguration();
const { graphQLv1, graphQLv2 } = APP_CONFIG;

const HttpMiddleware = ApolloLink.split(
    ({ getContext }) => getContext().clientVersion === 1,
    new HttpLink({ fetch, uri: graphQLv1 }),
    new HttpLink({ fetch, uri: graphQLv2 }),
);

export default HttpMiddleware;

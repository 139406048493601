/* eslint-disable react/jsx-filename-extension */
import React, { useState, useMemo, useEffect, useContext } from 'react';
import callOnce from 'ggApp/utils/callOnce';
import UnsupportedBrowserBanner from 'ggApp/shared/components/UnsupportedBrowserBanner';
import { CookiesManagerContext } from 'ggApp/utils/CookiesManager';
import { smartBannerFeatureFlag } from 'ggApp/modules/featureFlag/selectors';
import { CONTINUE_WITH_UNSUPPORTED_BROWSER, checkBrowserCompat, clientUserAgent } from './helpers';
import { triggerUnsupportedBrowserBannerEvent } from './tracking';
import usePrompt from './usePrompt';

const isServer = __IS_SERVER__;

function BrowserDetect({ children, userAgent, store }) {
    const cookiesManager = useContext(CookiesManagerContext);
    const [continueAnyway, setContinueAnyway] = useState(false);
    const [prompt, promptToInstall] = usePrompt();
    const smartBannerEnabled = smartBannerFeatureFlag(store);

    useEffect(() => {
        if (prompt && smartBannerEnabled) {
            promptToInstall().catch((error) => {
                throw new Error(error);
            });
        }
    }, [prompt, promptToInstall, smartBannerEnabled]);

    const browserIsSupported = useMemo(() => {
        if (isServer) {
            const checkResult = checkBrowserCompat(userAgent, BROWSERSLIST);
            if (checkResult && typeof checkResult.result === 'boolean') {
                // eslint-disable-next-line no-param-reassign
                setContinueAnyway(cookiesManager.get(CONTINUE_WITH_UNSUPPORTED_BROWSER));
                return checkResult.result;
            }
            // in case we can't detect browser
            return true;
        }
        const checkResult = checkBrowserCompat(clientUserAgent, BROWSERSLIST);
        if (checkResult && typeof checkResult.result === 'boolean') {
            // eslint-disable-next-line no-param-reassign
            setContinueAnyway(cookiesManager.get(CONTINUE_WITH_UNSUPPORTED_BROWSER));
            return checkResult.result;
        }
        // in case we can't detect browser
        return true;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const callTracking = useMemo(() => {
        if (!isServer) {
            const triggerOnce = callOnce(triggerUnsupportedBrowserBannerEvent);
            return () => triggerOnce(!browserIsSupported);
        }
        return () => {};
    }, [browserIsSupported]);
    useEffect(() => {
        callTracking();
    });
    return (
        <UnsupportedBrowserBanner
            continueOnClick={() => {
                cookiesManager.set(CONTINUE_WITH_UNSUPPORTED_BROWSER, true);
                setContinueAnyway(true);
            }}
            // NOTE: DISABLED TEMPORARILY DUE TO ISSUES WITH CACHING HTML
            // visible={!continueAnyway && !browserIsSupported}
            visible={false}
        >
            {children}
        </UnsupportedBrowserBanner>
    );
}

export default BrowserDetect;

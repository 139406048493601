import { combineReducers } from 'redux';

import fetching from './fetching';

import activeStorePaymentMethod from './activeStorePaymentMethod';
import listStorePaymentMethods from './listStorePaymentMethods';

import activeUserPaymentMethod from './activeUserPaymentMethod';
import listUserPaymentMethods from './listUserPaymentMethods';

import oneclick from './oneclick';
import oneclickStatus from './oneclickStatus';
import oneclickErrorPaymentStatuses from './oneclickErrorPaymentStatuses';
import oneclickSessionData from './oneclickSessionData';
import oneclickSubmitDetails from './oneclickSubmitDetails';
import oneclickAdyenDropInReplacement from './oneclickAdyenDropInReplacement';

export default combineReducers({
    fetching,

    activeStorePaymentMethod,
    listStorePaymentMethods,

    activeUserPaymentMethod,
    listUserPaymentMethods,

    oneclick,
    oneclickStatus,
    oneclickErrorPaymentStatuses,
    oneclickSessionData,
    oneclickSubmitDetails,
    oneclickAdyenDropInReplacement,
});

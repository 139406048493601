import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';

import { user as userSelector } from 'ggApp/modules/auth/selectors';
import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import { params as paramsSelector } from 'ggApp/modules/router/selectors';
import { redirectus } from 'ggApp/modules/router/actions';

import CountryRedirection from './CountryRedirection';

const enhance = compose(
    connect(
        createStructuredSelector({
            urlParams: paramsSelector,
            user: userSelector,
            activeStore: activeStoreSelector,
        }),
        (dispatch) => ({
            redirectus: bindActionCreators(redirectus, dispatch),
        }),
    ),
);

export default enhance(CountryRedirection);

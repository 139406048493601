import * as t from '../actionTypes';

const defaultState = null;

export default function(state = defaultState, action) {
    switch (action.type) {
        case t.GET_USER:
        case t.USER_LOGOUT_SUCCESS:
        case t.LOGIN:
        case t.SIGN_UP:
        case 'AUTH_UPDATE_PHONE':
        case 'AUTH_CONFIRM_PHONE':
        case 'AUTH_ADDRESS_COMPANY':
            return action.data;

        default:
            return state;
    }
}

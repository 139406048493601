/* eslint-disable */
import {
    GET_SUBSCRIPTION_ACTIONS,
    GET_SUBSCRIPTION_ACTIONS_SUCCESS,
    GET_SUBSCRIPTION_ACTIONS_ERROR,
} from '../actionTypes';

const defaultState = {
    actions: [],
    error: null,
    loading: false,
};

export default function subscriptionActions(state = defaultState, reduxAction) {
    const { type, error, actions } = reduxAction;

    switch (type) {
    case GET_SUBSCRIPTION_ACTIONS:
        return {
            ...defaultState,
        };

    case GET_SUBSCRIPTION_ACTIONS_SUCCESS:
        return {
            actions,
            error: null,
            loading: false,
        };

    case GET_SUBSCRIPTION_ACTIONS_ERROR:
        return {
            ...state,
            error,
            loading: false,
        };

    default:
        return state;
    }
}

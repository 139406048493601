import { CHECKOUT_STEP_NAMES } from 'ggApp/modules/newCheckout/constants';

const STEP_IDENTIFIERS = {
    [CHECKOUT_STEP_NAMES.ORDER_CONFIRMATION]: 'orderSubmitted',
    [CHECKOUT_STEP_NAMES.HOME_ADDRESS]: 'homeAddress',
    [CHECKOUT_STEP_NAMES.ORDER_SUMMARY]: 'order_review',
    [CHECKOUT_STEP_NAMES.SHIPPING_ADDRESS]: 'shipping_address',
    [CHECKOUT_STEP_NAMES.PAYMENT_METHODS]: 'payment_methods',
    [CHECKOUT_STEP_NAMES.ORDER_ERROR]: 'submit_order_failed',
};

export default function stepIdentifierMapper(stepName) {
    return STEP_IDENTIFIERS[stepName];
}

const BI_STEP_IDENTIFIERS = {
    modalOpen: 'cart',
    [CHECKOUT_STEP_NAMES.SHIPPING_ADDRESS]: 'address',
    [CHECKOUT_STEP_NAMES.HOME_ADDRESS]: 'homeAddress',
    [CHECKOUT_STEP_NAMES.PAYMENT_METHODS]: 'payment',
    [CHECKOUT_STEP_NAMES.ORDER_SUMMARY]: 'review',
    [CHECKOUT_STEP_NAMES.ORDER_CONFIRMATION]: 'summary',
};

export function mapBISteps(stepName) {
    return BI_STEP_IDENTIFIERS[stepName];
}

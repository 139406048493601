import React, { useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';
import { Chip, ChipTypography } from './styled';
import { ChipProps } from '../types';

const CardChip = ({ label, className, type, tk }: ChipProps): JSX.Element => {
    const theme = useContext(ThemeContext);

    const backgroundColor = useMemo(() => {
        if (tk === 'PREORDER_BADGE') {
            return theme.colors.yolk120;
        }

        if (type && ['promotional', 'lowStock'].includes(type)) {
            return theme.colors.Foreground;
        }

        return theme.colors.Color1;
    }, [tk, type, theme.colors.Color1, theme.colors.yolk120, theme.colors.Foreground]);

    const textColor = useMemo(() => {
        if (type && ['promotional', 'lowStock'].includes(type)) {
            return theme.colors.Background;
        }

        return '#FFFFFF';
    }, [type, theme.colors.Background]);

    return (
        <Chip
            data-testid="chip"
            className={className}
            $backgroundColor={backgroundColor}
            $textColor={textColor}
        >
            <ChipTypography>{label}</ChipTypography>
        </Chip>
    );
};

export default CardChip;

import shortid from 'shortid';

export function errorToObj(error) {
    if (error) {
        return {
            id: shortid.generate(),
            message: error.data.error || '',
            status: error.status,
            url: error.config.url,
            level: 'error',
        };
    }

    return undefined;
}

export function errorsFromResponse(response) {
    if (response && response.data) {
        // eslint-disable-next-line no-unused-vars
        const { error, ...validationErrors } = response.data;
        return validationErrors;
    }

    return undefined;
}

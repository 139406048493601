import moment from 'moment';
import { datadogLogs } from '@datadog/browser-logs';

import { POST_EVENTS } from 'ggApp/modules/inwebview_api/constants';
import { serializeMomentDate } from 'ggApp/utils/time';
import { fireEvent } from 'ggApp/modules/inwebview_api';
import { isUSBusinessStoreCode } from 'ggApp/modules/store/utils';
import { enrichAnalyticsUser, sendAnalyticsEvent } from '@devsbb/analytics-client';
import {
    getCookieConsentValue,
    formatCookieConsent,
    adaptCookiePayloadToReduxStorePayload,
} from 'ggApp/modules/cookiePolicy/utils';
import createApolloClient from 'graphql-client';
import CreateUserConsentMutation from 'ggApp/shared/mutations/createUserConsent.graphql';
import { SegmentEvents } from '../../../../../../packages/analytics/events';
import { STEP_NAMES, DATE_FORMAT, DATE_FORMAT_US, ACCOUNT_TYPES, FLOW_NAMES } from '../constants';
import { saveAuthCredentials, saveUserIdCookie } from '../../utils';

import {
    getUserAction,
    signUpAction,
    signInAction,
    setTwoFactorAuthenticationAction,
    clearTwoFactorAuthenticationAction,
} from '../state/actionCreators';

import { mapAPIErrors, TwoFactorAuthenticationError, UntranslatedValidationError } from '../utils';

import errorHandler from './errorHandler';
import axiosRequest from './axiosRequest';

export const finalSignupUser = () => {
    fireEvent('USER_FINAL_SIGNED_UP', {});
    return Promise.resolve();
};

const mapAPIValidationErrors = (errors) => {
    const errorFieldMapper = {
        first_name: 'firstName',
        last_name: 'lastName',
        birthdate: 'dateOfBirth',
        '2fa_code': 'twoFactorAuthCode',
    };

    return mapAPIErrors(errors ?? {}, errorFieldMapper);
};

const mapTwoFactorAuthData = (data) => ({
    twoFactorAuthRequired: data?.['2fa_required'],
    phoneLastDigits: data?.['phone_last_digits'],
    resendCodeTimeoutSeconds: data?.['resend_code_timeout_seconds'],
});

const serializeUserData = (
    { firstName, lastName, email, password, dateOfBirth, accountType },
    storeCode,
) => {
    const dateFormat =
        isUSBusinessStoreCode(storeCode) || storeCode === 'us' ? DATE_FORMAT_US : DATE_FORMAT;
    const momentBirthdate = moment(dateOfBirth, dateFormat);
    const birthdate = serializeMomentDate(momentBirthdate);

    return {
        birthdate,
        email,
        password,
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        user_type: accountType,
    };
};

const handleError = errorHandler(mapAPIValidationErrors);

const fetchUserFromRequest = ({ data }) => data?.user;

const handleSuccess = (authData) => {
    const {
        access_token: accessToken,
        refresh_token: refreshToken,
        expires_in: expiresIn,
        ...otherData
    } = authData;

    saveAuthCredentials(accessToken, refreshToken, expiresIn);

    return otherData;
};

const handleTwoFactorAuthentication = (payload, error) => {
    const { dispatch, flowPayload } = payload;
    const { flowName, getStepperPayload } = flowPayload;
    const { data } = error?.response ?? {};
    const twoFactorAuthData = mapTwoFactorAuthData(data);

    if (!twoFactorAuthData.twoFactorAuthRequired) {
        return Promise.reject(error);
    }

    const { currentStepName } = getStepperPayload();

    setTwoFactorAuthenticationAction(twoFactorAuthData)(dispatch);

    if (
        currentStepName === STEP_NAMES.SIGN_IN ||
        currentStepName === STEP_NAMES.BLACK_FRIDAY_SIGN_IN ||
        flowName === FLOW_NAMES.SIGN_IN_FROM_CHECKOUT
    ) {
        return Promise.reject(new TwoFactorAuthenticationError());
    }

    return Promise.reject(error);
};

const saveUserId = (userData) => {
    if (userData.id) {
        saveUserIdCookie(userData.id);
    }

    return userData;
};

const updateUserConsent = () => {
    const apolloClient = createApolloClient({});
    const allCookies = getCookieConsentValue();
    if (allCookies) {
        const formattedCookies = adaptCookiePayloadToReduxStorePayload(allCookies);
        const cookieConsentState = {
            preferenceCookie: formattedCookies?.preferenceCookieConsent,
            statisticsCookie: formattedCookies?.statisticsCookieConsent,
            marketingCookie: formattedCookies?.marketingCookieConsent,
        };
        apolloClient.mutate({
            variables: {
                consentAttributes: formatCookieConsent(cookieConsentState),
            },
            mutation: CreateUserConsentMutation,
        });
    }
};
export const axiosUserRequest = (payload) => {
    const { flowOptions } = payload || {};

    return axiosRequest(
        {
            method: 'get',
            url: '/user',
        },
        flowOptions,
    ).then(fetchUserFromRequest);
};

export const getUser = (payload) => {
    const { dispatch } = payload || {};

    return axiosUserRequest(payload).then((user) => {
        getUserAction(user)(dispatch);

        return user;
    });
};

export const createUser = (payload = {}) => {
    const {
        formData = {},
        flowOptions,
        dispatch,
        getAllUserFeatureFlags,
        storeCode,
        storeId,
    } = payload;
    const requestData = serializeUserData(formData, storeCode);

    datadogLogs.logger.info('Sign up started');

    return axiosRequest(
        {
            method: 'post',
            url: '/users',
            data: { user: requestData },
            headers: {
                'X-Grover-Store': storeCode,
            },
        },
        flowOptions,
    )
        .then(fetchUserFromRequest)
        .then(handleSuccess)
        .then(saveUserId)
        .then((user) => {
            signUpAction(user)(dispatch);
            clearTwoFactorAuthenticationAction()(dispatch);
            getAllUserFeatureFlags();
            datadogLogs.logger.info('Sign up succeeded', { userId: user.id });

            sendAnalyticsEvent('Password Entered', { store_id: storeId });
            enrichAnalyticsUser({ id: user.id });
            sendAnalyticsEvent('Signup Completed', { store_id: storeId });

            return user;
        })
        .catch((err) => {
            if (err?.response?.data?.error?.email) {
                sendAnalyticsEvent('Sign up failed', {
                    store_id: storeId,
                    reason: 'existing_email',
                });
            }
            datadogLogs.logger.info('Sign up failed');

            return handleError(err);
        });
};

export const loginUser = (payload = {}) => {
    const {
        formData = {},
        flowOptions,
        getAllUserFeatureFlags,
        storeCode,
        dispatch,
        storeId,
    } = payload;
    const { email, password, twoFactorAuthCode } = formData;
    sendAnalyticsEvent(SegmentEvents.loginStarted, { store_id: storeId });
    const requestData = {
        email,
        password,
        grant_type: 'password',
    };

    if (twoFactorAuthCode) {
        requestData['2fa_code'] = twoFactorAuthCode;
    }

    return axiosRequest(
        {
            method: 'post',
            url: '/oauth/token',
            data: requestData,
            headers: {
                'X-Grover-Store': storeCode,
            },
        },
        flowOptions,
    )
        .then((request) => request.data ?? {})
        .then(handleSuccess)
        .then(() => axiosUserRequest(payload))
        .then(saveUserId)
        .then((user) => {
            getUserAction(user)(dispatch);
            signInAction(user)(dispatch);
            getAllUserFeatureFlags();
            updateUserConsent();
            enrichAnalyticsUser({ id: user?.id });
            sendAnalyticsEvent(SegmentEvents.loginSuccess, { store_id: storeId });
            return user;
        })
        .catch((error) => handleTwoFactorAuthentication(payload, error))
        .catch(handleError);
};

export const sendPasswordRecovery = (payload = {}) => {
    const { formData = {}, flowOptions, storeCode } = payload;
    const { email } = formData;
    return axiosRequest(
        {
            method: 'post',
            url: '/users/password',
            data: {
                user: {
                    email,
                },
            },
            headers: {
                'X-Grover-Store': storeCode,
            },
        },
        flowOptions,
    ).catch(handleError);
};

export const setNewPassword = (payload = {}) => {
    const { formData = {}, locationQuery, storeCode, flowPayload } = payload;
    const { password } = formData;
    const { changeFlow } = flowPayload;
    const resetPasswordToken = locationQuery.reset_password_token;

    if (!resetPasswordToken) {
        return Promise.reject(new Error('Missing password reset token'));
    }

    return axiosRequest({
        method: 'patch',
        url: '/users/password',
        data: {
            user: {
                password,
                reset_password_token: resetPasswordToken,
                password_confirmation: password,
            },
        },
        headers: {
            'X-Grover-Store': storeCode,
        },
    }).catch((error) => {
        const errorResponse = error.response.data || {};

        if ('reset_password_token' in errorResponse) {
            changeFlow(FLOW_NAMES.PASSWORD_SETUP_ERROR);
            return Promise.reject(error);
        }

        return handleError(error);
    });
};

export const subscribeToNewsletters = (payload = {}) => {
    const { flowOptions } = payload;

    return axiosRequest(
        {
            method: 'post',
            url: '/newsletters',
        },
        flowOptions,
    ).catch(handleError);
};

export const unsubscribeFromNewsletters = (payload = {}) => {
    const { flowOptions } = payload;

    return axiosRequest(
        {
            method: 'delete',
            url: '/newsletters',
        },
        flowOptions,
    ).catch(handleError);
};

export const transformBusinessUserIntoRegular = (payload) => {
    const { flowOptions, user = {} } = payload || {};

    return axiosRequest(
        {
            method: 'patch',
            url: `/users/${user.id}/type`,
            data: { type: ACCOUNT_TYPES.PRIVATE_CUSTOMER },
        },
        flowOptions,
    ).then(() => getUser(payload));
};

export const resetPasswordWithType = ({ email, type }) => {
    return axiosRequest({
        method: 'post',
        url: `/users/password`,
        data: {
            user: {
                email,
            },
            type,
        },
    });
};

export const confirmEmailChange = (payload = {}) => {
    const { flowOptions } = payload;

    return axiosRequest(
        {
            method: 'patch',
            url: '/users/email/change',
        },
        flowOptions,
    ).catch(handleError);
};

export const changeEmailRequest = (payload = {}) => {
    const { formData = {}, flowPayload, storeCode, dispatch } = payload;
    const { getStepperPayload } = flowPayload ?? {};
    const { newEmail, twoFactorAuthCode } = formData;

    const requestPayload = twoFactorAuthCode
        ? { '2fa_code': twoFactorAuthCode, email: newEmail }
        : { email: newEmail };

    return axiosRequest({
        method: 'post',
        url: '/users/email/reset',
        data: requestPayload,
        headers: {
            'X-Grover-Store': storeCode,
        },
    }).catch((error) => {
        const { data, status } = error?.response ?? {};
        const { currentStepName } = getStepperPayload();
        const isEmailAlreadyInUse = data?.email === 'non_unique';
        const isCodeVerificationRequired = Boolean(data?.['2fa_required']);
        const isInvalidCode = data?.['valid_code'] === false;
        const hasTooManyAttempts = Boolean(data?.['too_many_attempts']);

        if (isEmailAlreadyInUse) {
            return Promise.reject(
                new UntranslatedValidationError({
                    baseError: 'EMAIL_ALREADY_IN_USE_ERROR',
                }),
            );
        }

        if (isCodeVerificationRequired && currentStepName === STEP_NAMES.EMAIL_UPDATE) {
            const twoFactorAuthData = mapTwoFactorAuthData(data);
            setTwoFactorAuthenticationAction(twoFactorAuthData)(dispatch);
            return Promise.resolve();
        }

        if (isInvalidCode) {
            return Promise.reject(
                new UntranslatedValidationError({
                    baseError: 'PHONE_NUMBER_UPDATE_INVALID_CODE_ERROR',
                }),
            );
        }

        if (hasTooManyAttempts) {
            return Promise.reject(
                new UntranslatedValidationError({
                    baseError: 'PHONE_NUMBER_UPDATE_TOO_MANY_ATTEMPTS_ERROR',
                }),
            );
        }

        if (status === 401) {
            return Promise.reject(
                new UntranslatedValidationError({
                    baseError: 'PHONE_NUMBER_UPDATE_INVALID_TOKEN_ERROR',
                }),
            );
        }

        return handleError(error);
    });
};

export const nativeUserLogin = ({ authToken, refreshToken, maxAge }) => {
    saveAuthCredentials(authToken, refreshToken, maxAge, undefined, false);
    return (dispatch) =>
        axiosUserRequest({ flowOptions: { accessToken: authToken } }).then((user) => {
            getUserAction(user)(dispatch);
            fireEvent(POST_EVENTS.GET_USER, { action: { user } });
        });
};

import nanoid from 'nanoid';
import {
    addAnalyticsMiddleware,
    initAnalytics,
    cookiePolicyAnalyticsMiddleware,
} from '@devsbb/analytics-client';
import { trackAdCampaignAnalytics } from 'analytics/adCampaigns/trackAdCampaignAnalytics';

import CookiesManager from 'ggApp/utils/CookiesManager';

import { fireEvent } from 'ggApp/modules/inwebview_api';
import { AUTH_TOKEN_COOKIE } from 'ggApp/routes/_wrap/MobileWrapper/constants';
import {
    locationPathname,
    locationSearch,
    params as routerParamsSelector,
} from 'ggApp/modules/router/selectors';
import { matchStore } from 'ggApp/modules/store/actions';
import { storeActive } from 'ggApp/modules/store/selectors';
import { getUser, checkStoreAndUserType } from 'ggApp/modules/auth/actions';
import {
    pdpMarketPriceFeatureFlagData,
    spaFeatureFlagData,
} from 'ggApp/modules/featureFlag/selectors';
import {
    getNewCheckoutFeatureFlag,
    getAllUserFeatureFlags,
    getAllDeviceFeatureFlags,
} from 'ggApp/modules/featureFlag/actions';
import {
    dispatchPDPMarketPriceGAEvent,
    dispatchSpaModeGAEvent,
} from 'ggApp/modules/featureFlag/utils/tracking';
import { sendEvent, pageVisitTagHandler } from 'ggApp/modules/tracking/actions';
import {
    KINESIS_STREAM_FIRST_REQUEST,
    TRACKING_COOKIE_NAME,
    IP_COOKIE_NAME,
    CART_CACHE_KEY,
} from 'ggApp/modules/tracking/constants';
import setTrackingCookie from 'ggApp/modules/tracking/utils/setTrackingCookie';
import { setHeaders } from 'ggApp/modules/axiosConfig/actions';
import { DEVICE_ID_COOKIE_NAME } from 'ggApp/utils/DeviceIdCookie';
import loadConfiguration from 'ggApp/utils/configuration/configuration';

import { getNameByRoute } from 'analytics/utils';
import { INIT_PENDING, INIT_DONE } from '../actionTypes';
import { returnPageNameAndType } from '../../../../_middlewares/analyticsPageView';

function firstRequestEvent() {
    return async (dispatch, getState) => {
        try {
            const cookiesManager = new CookiesManager();
            const groverSession = cookiesManager.get(TRACKING_COOKIE_NAME);

            if (!groverSession) {
                const groverSessionId = nanoid();
                setTrackingCookie(groverSessionId);

                const state = getState();
                const storeId = storeActive(state).store_id;
                const pageId = locationPathname(state);
                const queryString = locationSearch(state);
                const userId = cookiesManager.get('user_id');
                const ipCookie = cookiesManager.get(IP_COOKIE_NAME);

                const firstRequestEventData = {
                    user_id: userId ? Number(userId) : 0,
                    session_id: groverSessionId,
                    creation_time: Math.floor(Date.now() / 1000),
                    page_id: pageId,
                    store_id: storeId,
                    user_agent: window.navigator.userAgent,
                    request_url: window.location.href,
                    query_string: queryString,
                    referral_domain: document.referrer,
                    status_code: 200,
                    ip_address: ipCookie,
                };

                const kinesisRequest = sendEvent(
                    KINESIS_STREAM_FIRST_REQUEST,
                    firstRequestEventData,
                );

                await kinesisRequest();

                cookiesManager.remove(IP_COOKIE_NAME, { path: '/' });
            }
        } catch (e) {
            console.error(`Failed to send first request event. Error: ${e}`);
        }
    };
}

function handleHeaders(dispatch) {
    const cookiesManager = new CookiesManager();

    const headers = {
        'X-Grover-Device-ID': cookiesManager.get(DEVICE_ID_COOKIE_NAME),
        'X-Grover-Session-ID': cookiesManager.get('ggId'),
        'X-Grover-User': cookiesManager.get('user_id'),
        'X-Grover-Language': cookiesManager.get('locale'),
        'X-Grover-Impersonated-User': cookiesManager.get('impersonated_user_id'),
        'X-XSS-Protections': '1; mode=block',
        // Referer: req.headers.referer,
    };
    dispatch(setHeaders(headers));
}

async function handleInitAnalytics(dispatch, getState) {
    const { SEGMENT_WRITE_KEY } = loadConfiguration();
    const mappedFeatureFlags = getState().featureFlag?.data;
    if (mappedFeatureFlags) {
        // TODO: @analytics Remove snowplow, move analytics to own folder
        await initAnalytics({
            segmentWriteKey: SEGMENT_WRITE_KEY,
            app: { name: 'frontshop' },
        }).catch(() => console.warn('Analytics failed to load.'));
    }
    // Setup analytics cookie policy middleware
    await addAnalyticsMiddleware(
        cookiePolicyAnalyticsMiddleware({
            policyGetter: () => {
                const cookiesConsent = getState().cookiePolicy.consentState;
                const activeStore = getState().store?.active?.data?.code;
                return {
                    store: activeStore,
                    cookie_consent: {
                        strict: 'yes',
                        functional: cookiesConsent?.preferenceCookieConsent ? 'yes' : 'no',
                        performance: cookiesConsent?.statisticsCookieConsent ? 'yes' : 'no',
                        profiling: cookiesConsent?.marketingCookieConsent ? 'yes' : 'no',
                    },
                };
            },
        }),
    ).then(() => {
        trackAdCampaignAnalytics();
    });
}

export function handleSpaPartnersEvent(dispatch, getState) {
    const cookiesManager = new CookiesManager();
    const state = getState();
    const spaFlagData = spaFeatureFlagData(state);
    const pdpMarketPriceData = pdpMarketPriceFeatureFlagData(state);

    dispatchSpaModeGAEvent(spaFlagData);
    dispatchPDPMarketPriceGAEvent(pdpMarketPriceData);

    const authToken = cookiesManager.get('api_access_token');
    const refreshToken = cookiesManager.get('api_refresh_token');

    fireEvent(AUTH_TOKEN_COOKIE, { authToken, refreshToken });
}

async function handlePageVisit(dispatch, getState) {
    const cookiesManager = new CookiesManager();
    const routeParams = routerParamsSelector(getState());
    const pageNameAndType = getNameByRoute();
    const pageResponseObject = returnPageNameAndType(routeParams, pageNameAndType);
    const cartData = JSON.parse(localStorage.getItem(CART_CACHE_KEY) || '{}');
    const activeStore = getState().store?.active?.data?.code;
    const cookiesConsent = getState().cookiePolicy.consentState;
    if (Object.keys(getState().auth?.user).length > 0) {
        const userEmail = getState().auth?.user?.email;
        const userId = cookiesManager.get('user_id');
        pageVisitTagHandler(
            pageResponseObject.type,
            userEmail,
            userId,
            cartData,
            activeStore,
            cookiesConsent?.marketingCookieConsent,
        );
    } else {
        pageVisitTagHandler(
            pageResponseObject.type,
            '',
            '',
            cartData,
            activeStore,
            cookiesConsent?.marketingCookieConsent,
        );
    }
    // actions that need user data other than id which we can get from the `user_id` cookie
    dispatch(checkStoreAndUserType());
    await dispatch(getNewCheckoutFeatureFlag());
}

export function initPartnerPage() {
    return async (dispatch, getState) => {
        try {
            handleHeaders(dispatch);
            dispatch({ type: INIT_PENDING });
            dispatch(firstRequestEvent());
            handleInitAnalytics(dispatch, getState);
            handleSpaPartnersEvent(dispatch, getState);
            await Promise.all([
                dispatch(matchStore()),
                dispatch(getUser()),
                dispatch(getAllUserFeatureFlags()),
                dispatch(getAllDeviceFeatureFlags()),
            ]);
            handlePageVisit(dispatch, getState);
            dispatch({ type: INIT_DONE });
        } catch (error) {
            dispatch({ type: INIT_DONE });
        }
    };
}

export function init() {
    return async (dispatch, getState) => {
        try {
            handleHeaders(dispatch);
            dispatch({ type: INIT_PENDING });
            dispatch(firstRequestEvent());
            handleInitAnalytics(dispatch, getState);
            handleSpaPartnersEvent(dispatch, getState);
            await Promise.all([
                dispatch(matchStore()),
                dispatch(getUser()),
                dispatch(getAllUserFeatureFlags()),
                dispatch(getAllDeviceFeatureFlags()),
            ]);
            handlePageVisit(dispatch, getState);

            dispatch({ type: INIT_DONE });
        } catch (e) {
            // getUser will throw on a 4xx, but that shouldn't be a showstopper
            dispatch({ type: INIT_DONE });
        }
    };
}

import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    registerTeaserClickEvent,
    registerTeaserImpressionEvent,
    registerTeaserClickProductEvent,
} from 'ggApp/modules/gaAnalytics/actions';
import { WIDGET_NAMES } from 'ggApp/modules/gaAnalytics/constants';
import withIntersectionObserver from 'ggApp/modules/tracking/hocs/withIntersectionObserverV2';
import CategoryWidget from './CategoryWidget';

export { CategoryWidget };

export const CategoryWidgetWithEvents = compose(
    connect(null, (dispatch) => ({
        _registerTeaserClickProductEvent: bindActionCreators(
            registerTeaserClickProductEvent,
            dispatch,
        ),
    })),
    withProps(
        ({
            category,
            widgetType,
            cta: { url },
            firstIndexProduct,
            _registerTeaserClickProductEvent,
            ctaViewAll,
        }) => ({
            onEnterCallback: registerTeaserImpressionEvent({
                widgetName: WIDGET_NAMES.productListing,
                title: category ? category.name : null,
                url,
            }),
            onClickCTA: registerTeaserClickEvent({
                widgetName: WIDGET_NAMES.productListing,
                title: category ? category.name : null,
                url,
                ctaViewAll,
            }),
            onClickProduct: (productIndex, position) =>
                _registerTeaserClickProductEvent({
                    widgetName: WIDGET_NAMES.productListing,
                    url,
                    title: `PRODUCT_LISTING_WIDGET_CATEGORY_${position}`,
                    firstIndexProduct,
                    productIndex,
                }),
            threshold: 0.5,
        }),
    ),
    withIntersectionObserver,
)(CategoryWidget);

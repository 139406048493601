/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { mapMarketingParameters, sendAnalyticsEvent } from '@devsbb/analytics-client';
import withWebView from 'ggApp/shared/hocs/withWebView';
import CustomerSupport from 'ggApp/modules/customerSupport';
import { ReferralConnectionCreate } from 'ggApp/modules/newReferrals';

// Store Actions
import { matchStore } from 'ggApp/modules/store/actions';
// Store Selectors
import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import { isLoggedIn as isLoggedInSelector } from 'ggApp/modules/auth/selectors';

// Category Actions
import { getCategories } from 'ggApp/modules/category/actions';

// Static Actions
import { getStaticInfo } from 'ggApp/modules/static/actions';

// FeatureFlag Actions
import {
    getAllDeviceFeatureFlags,
    getAllUserFeatureFlags,
} from 'ggApp/modules/featureFlag/actions';

import { NotificationComponents_Wrap as NotificationComponentsWrap } from 'ggApp/modules/notification';
import GaAuthenticationReporter, { GaAnalyticsGTMLoader } from 'ggApp/modules/gaAnalytics';
import { Intercom } from 'ggApp/modules/intercom';
import { CookiePolicy } from 'ggApp/modules/cookiePolicy';
import { TouchIconLinks } from 'ggApp/routes/_wrap/touchIconLinks';
import { init as initAction } from 'ggApp/modules/init/actions';

// global styles for app
import 'reset-css/reset.css';
import '../styles/app.scss';
import BusinessRedirectionModal from 'ggApp/shared/modals/BusinessRedirection';
import CountryRedirectionModal from 'ggApp/shared/modals/CountryRedirection';

import MetaTagsContainer from 'ggApp/shared/containers/metaTags';
import { SnowplowLoader } from 'snowplow';
import { BrazeLoader } from 'braze';
import { isExcludedForSeo } from 'staticStoreConfig';
import CookiesManager from 'ggApp/utils/CookiesManager';
import { browserHistory } from 'react-router';
import {
    ANALYTICS_SESSION_TRIGGERED,
    ANALYTICS_SESSION_COOKIE_KEY,
    ANALYTICS_TRIGGERED_PATHNAME,
} from 'ggApp/utils/CookiesManager/constants';
import Session from 'ggApp/modules/session/session';
import { SegmentEvents } from '../../../../../packages/analytics/events';

const CookiePolicyWithChecker = withWebView(CookiePolicy);

@connect(
    createStructuredSelector({
        activeStore: activeStoreSelector,
        isLoggedIn: isLoggedInSelector,
    }),
    (dispatch) => ({
        init: bindActionCreators(initAction, dispatch),
    }),
)
export default class App extends Component {
    static needs = [
        getCategories,
        (params, location, req) => (dispatch) => dispatch(getStaticInfo(['pdp_common'], req)),
    ];

    static needsBeforeOthers = [matchStore, getAllDeviceFeatureFlags, getAllUserFeatureFlags];

    constructor(props) {
        super(props);
        this.state = {
            loadedSegment: false,
        };
    }

    async componentDidMount() {
        const { init } = this.props;
        const cookiesManager = new CookiesManager();
        this.setState({ loadedSegment: true });
        init();
        this.routeListen = browserHistory.listen(() => {
            if (
                cookiesManager.get(ANALYTICS_TRIGGERED_PATHNAME) !== window.location.pathname &&
                cookiesManager.get(ANALYTICS_SESSION_TRIGGERED) === 'false'
            ) {
                if (cookiesManager.get(ANALYTICS_SESSION_COOKIE_KEY) === 'true') {
                    return;
                }
                const traits = mapMarketingParameters(window.location.href, document.referrer);
                sendAnalyticsEvent(SegmentEvents.sessionStarted, {}, { segment: { traits } });
            }
        });
    }

    componentWillUnmount() {
        this.routeListen();
    }

    render() {
        const { children, activeStore, isLoggedIn } = this.props;
        const { code, activeLanguage } = activeStore || {};
        const { loadedSegment } = this.state;
        const htmlLangAttribute = code && activeLanguage ? `${activeLanguage}-${code}` : null;
        const robotMetaTags = isExcludedForSeo(activeStore) ? { robots: 'noindex' } : undefined;
        return (
            <>
                {loadedSegment && (
                    <>
                        <MetaTagsContainer
                            htmlAttributes={
                                htmlLangAttribute ? { lang: htmlLangAttribute } : undefined
                            }
                            metaTags={robotMetaTags}
                        />
                        <CustomerSupport />
                        <Intercom />
                        <GaAnalyticsGTMLoader />
                        <SnowplowLoader />
                        <Session />
                        <GaAuthenticationReporter />
                        <TouchIconLinks />
                        <BrazeLoader>{children}</BrazeLoader>
                        <NotificationComponentsWrap />
                        <CookiePolicyWithChecker />
                        <BusinessRedirectionModal />
                        <CountryRedirectionModal />
                        <ReferralConnectionCreate isLoggedIn={isLoggedIn} />{' '}
                    </>
                )}
            </>
        );
    }
}

/* eslint-disable */
import * as at from '../actionTypes';

const defaultState = null;

export default function (state = defaultState, action) {
    if (action.type === at.GQL_FETCH_COMPANY && action.payload) {
        return action.payload;
    }

    return state;
}

import styled, { css } from 'styled-components';

import { screens } from 'ggApp/utils/mixins/screens';
import LayoutGrid from 'ggApp/shared/components/layout/grid';
import DivisionLine from 'ggApp/shared/components/layout/divisionLine';
import { CampaignsWidgetWithEvents } from 'ggApp/shared/components/widgets/CampaignsWidget';
import FallbackMarketingCard from 'ggApp/shared/components/widgets/CampaignsWidget/FallbackMC1';
import CampaignCarousel from 'ggApp/shared/components/widgets/CampaignCarousel';
import { TileMarketingWidgetWithEvents } from 'ggApp/shared/components/widgets/TileMarketingWidget';
import { UspCardsWidgetWithEvents } from 'ggApp/shared/components/widgets/UspCardsWidget';
import Reviews from 'ggApp/shared/components/widgets/ReviewsWidget';
import ProductWidgetMapper from 'ggApp/shared/components/widgets/ProductWidgetMapper';

const forYouElementStyles = css`
    margin: 0 5% 24px;
    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
        ${({ valuePropsOnHomepageEnabled }) => !valuePropsOnHomepageEnabled && `
            margin: 0 16px 8px;
        `}
    `}
`;

export const Campaign = styled(CampaignsWidgetWithEvents)`
    ${forYouElementStyles};
`;

export const Line = styled(DivisionLine)`
    ${forYouElementStyles};
    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
        margin: 16px;
    `}
`;

export const FallbackMC1 = styled(FallbackMarketingCard)`
    ${forYouElementStyles};
`;

export const CampaignCarouselWidget = styled(CampaignCarousel)`
    ${forYouElementStyles};
    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
        margin: 0;
    `}
`;

export const GridAligner = styled.div`
    grid-column: 1 / -1;
`

export const WidgetGrid = styled(LayoutGrid)`
    * > {
        min-height: 30vw;
    }

    ${({widgetTheme = 'default'}) => widgetTheme === 'gray' 
        ? 
            css`
            @media (${screens.phone}) and (${screens.tabletMax}) {
                margin: ${({ theme }) => theme.spacers.XXL} 0;
            }

            @media (${screens.desktopMin}) {
                padding: 0;
            }` 
        : 
            css`
            @media (${screens.phone}) and (${screens.desktopMax}) {
                padding: 0;
                margin: 0;
                margin-top: ${({ theme }) => theme.spacers.XXL};
                margin-bottom: ${({ theme }) => theme.spacers.XXL};
            }`
    }

    ${({verticalSpacerBig}) => Boolean(verticalSpacerBig)
        ? css`
            margin-top: calc(${({ theme }) => theme.spacers['4XL']} * 2);
            margin-bottom: calc(${({ theme }) => theme.spacers['4XL']} * 2);

            ${props => props.theme.superQuery.all.and.maxWidth.tabletMax.css`
                margin-top: calc(${({ theme }) => theme.spacers['3XL']} + ${({ theme }) => theme.spacers.L});
                margin-bottom: calc(${({ theme }) => theme.spacers['3XL']} + ${({ theme }) => theme.spacers.L});
            `};
            ` 
        : css`
            margin-top: ${({ theme }) => theme.spacers.XXL};
            margin-bottom: ${({ theme }) => theme.spacers.XXL};
        `}
`;

const FullWidthWidgetStyles = css`
    grid-column: 1 / -1;
`;

const SameGroupWidgetStyles = css`
    grid-column: 1 / -1;
`;

export const TileMarketingWidget = styled(TileMarketingWidgetWithEvents)`
    ${FullWidthWidgetStyles}
`;

export const UspCardsWidget = styled(UspCardsWidgetWithEvents)`
    ${FullWidthWidgetStyles}

    @media (${screens.phone}) and (${screens.tabletMax}) {
        padding: ${({ theme }) => theme.spacers.XL} 0;
    }
`;

export const ReviewsWidget = styled(Reviews)`
    ${FullWidthWidgetStyles}
`;

export const ProductWidget = styled(ProductWidgetMapper)`
    ${SameGroupWidgetStyles}
`;

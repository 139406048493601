import axios from 'axios';
import memoize from 'fast-memoize';
import { Locale } from 'ggApp/types/common';

type Review = {
    comments: string;
    date: string;
    date_created: string;
    rating: number;
    reviewer: {
        address: string;
        first_name: string;
        last_name: string;
        verified_buyer: string;
    };
    store_review_id: number;
};

export type ReviewsIODataType = {
    error?: string;
    data?: {
        stats?: {
            total_reviews?: number;
            average_rating?: string;
        };
        reviews?: Array<Review>;
        word?: string;
    };
};

const STORE_ID = 'grover';

export const filterReviewsByOrigin = (response: ReviewsIODataType, origin: string) => {
    const filteredReviews = response?.data?.reviews?.filter((review) => {
        return review?.reviewer?.address.toLowerCase().includes(origin.toLowerCase());
    });

    return {
        data: {
            ...response.data,
            reviews: filteredReviews,
        },
    };
};

const validateResponse = (response: ReviewsIODataType) => {
    if (
        response.data &&
        response.data.stats &&
        response.data.stats.total_reviews &&
        response.data.stats.average_rating &&
        response.data.reviews &&
        response.data.reviews.length > 0 &&
        response.data.word
    ) {
        return response;
    }
    return { error: 'Incomplete reviews data to render widget' };
};

export const fetchReviews = memoize((language) =>
    axios
        .get(`https://api.reviews.co.uk/merchant/latest?store=${STORE_ID}&tag=${language}`)
        .then((response) => {
            if (language === Locale.de) {
                const filteredReviews = filterReviewsByOrigin(response, 'Germany');
                return validateResponse(filteredReviews);
            }

            return validateResponse(response);
        })
        .catch((error) => {
            return { error };
        }),
);

export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        ProductFilter: ['RangeFilter', 'KeywordFilter'],
        Contract: ['Flex', 'Mix'],
        BasicContract: ['Flex', 'Mix'],
        ContractAction: [
            'Buyout',
            'CancelOrderEarly',
            'PrintReturnLabel',
            'ReportDamage',
            'SupportRequest',
        ],
        Period: ['BillingPeriod', 'LastCommittedPeriod'],
        ContractDiscounts: ['InternalDiscount'],
        Discount: ['InternalDiscount'],
        Flows: [
            'Completion',
            'CustomerWishCancel',
            'CustomerWishKeepCharging',
            'DamageClaim',
            'InternalDebtCollection',
            'ManualPause',
            'Purchase',
            'Replacement',
            'Revocation',
            'Upsell',
        ],
        Operation: [
            'Completion',
            'CustomerWishCancel',
            'CustomerWishKeepCharging',
            'DamageClaim',
            'InternalDebtCollection',
            'ManualPause',
            'Purchase',
            'Replacement',
            'Revocation',
            'Upsell',
        ],
        PaymentInterface: [
            'OrderPayment',
            'GroupPayment',
            'PurchasePayment',
            'SubscriptionPayment',
        ],
        DateUnion: ['Date', 'TimeEstimate'],
        OrderUnion: ['MixCartOrder', 'Order'],
        OrderLineItem: ['MixLineItem'],
        LineItemMeta: ['FlexCartLineItemMeta', 'LineItemCatalogMeta', 'ReplacedLineItemMeta'],
        AddonsAddon: ['DigitalOtpProduct', 'AddonsExternalAddon', 'AddonsInternalAddon'],
        PaymentMethod: ['ByInvoice', 'Card', 'Paypal', 'Sepa'],
        PaymentMethodInterface: ['ByInvoice', 'Card', 'Paypal', 'Sepa'],
        CheckProviderReport: ['CompanyVerificationCheck', 'OnfidoCheck'],
        SubscriptionNote: [
            'AttentionNote',
            'EndedNote',
            'NextPaymentNote',
            'PausedNote',
            'ProcessingNote',
        ],
        TextNoteInterface: ['AttentionNote', 'EndedNote', 'PausedNote', 'ProcessingNote'],
        CardTypeUnionModel: [
            'BasicMarketingCardModel',
            'SubscriptionBadgeModel',
            'PollCardModel',
            'ConfirmationPollCardModel',
        ],
        ActionUnionModel: [
            'LinkActionModel',
            'CopyToClipboardActionModel',
            'LaunchFlowActionModel',
        ],
        PaymentsUnion: ['GroupPayment', 'OrderPayment', 'PurchasePayment', 'SubscriptionPayment'],
        SubscriptionDiscount: ['ExistingSubscriptionDiscountOffer', 'VoucherDiscountOffer'],
        SubscriptionDiscountInterface: [
            'ExistingSubscriptionDiscountOffer',
            'VoucherDiscountOffer',
            'SwapDiscountOffer',
        ],
        SubscriptionAction: [
            'Buyout',
            'CancelSubscription',
            'ChangePaymentMethod',
            'PaymentMethodPresenter',
            'PrintReturnLabel',
            'ReportDamage',
            'SupportRequest',
            'UpgradeRentalPlan',
        ],
        SubscriptionSummary: [
            'FilterButton',
            'Section',
            'StandaloneDiscountOffer',
            'SwapDiscountOffer',
        ],
        SubscriptionListEntry: ['SubscriptionCard', 'SubscriptionStack'],
        ProviderInterface: ['Plaid', 'Tink'],
        VerificationReview: ['FieldReview'],
        CatalogItem: ['MixDevice', 'FlexDevice'],
        CatalogItemVariant: ['MixDevice', 'FlexDevice'],
        ContractGoods: ['MixDevice', 'FlexDevice'],
        HistoryMetadata: ['EmptyMetadata', 'TriggerEventMetadata', 'UserActionMetadata'],
        Node: ['NotificationSendResult', 'Vote'],
        PollOptions: ['SingleChoiceOptions', 'ThumbsOptions', 'MoodOptions'],
        PollResults: ['SingleChoiceResults', 'ThumbsResults', 'MoodResults'],
        Goods: ['FlexDevice', 'MixDevice'],
        ShipmentOperation: ['UpdateTrackingInformation'],
        ReturnSteps: ['AccessoriesReturnStep', 'ConfirmableReturnStep', 'ReturnStepsOutcome'],
        BasicReturnStep: ['AccessoriesReturnStep', 'ConfirmableReturnStep'],
        AccountType: [
            'AccountBasicModel',
            'AccountPaymentServiceModel',
            'AccountPayByInvoiceModel',
            'AccountManualBookingModel',
            'AccountDcPaymentModel',
            'AccountWalletServiceModel',
        ],
        RecurrentType: ['SimpleSubscriptionModel', 'LoopSimulatorModel'],
        Task: [
            'RentalPlanUploadTask',
            'RentalPlanExportTask',
            'BundlesExportTask',
            'ProductsUploadTask',
            'RentalPlanUploadFailedTask',
        ],
        PaymentsSummaryByStateUnion: ['PaymentSummaryMultiple', 'PaymentSummarySingle'],
        PaymentSummaryInterface: ['PaymentSummaryMultiple', 'PaymentSummarySingle'],
        FlexDetails: ['Flex', 'SubscriptionDetails'],
        ValidationResult: ['ContractValidationResult'],
        LoyaltyShopPurchaseMetadataUnion: ['LoyaltyVoucherMetadata'],
        ContentUnionModel: [
            'BasicMarketingCardContentModel',
            'SubscriptionBadgeContentModel',
            'HintContentModel',
            'PollCardContentModel',
            'ConfirmationPollCardContentModel',
        ],
        WorkAsset: ['GroverAsset'],
        ExternalOrderFulfillmentUnion: [
            'ReviewingOrderEOF',
            'PreparingToShipEOF',
            'ShippedEOF',
            'PartiallyShippedEOF',
            'PartiallyFailedDeliveryEOF',
            'FailedDeliveryEOF',
            'PartiallyDeliveredEOF',
            'DeliveredEOF',
            'NotImplementedEOF',
        ],
        IExternalOrderFulfillment: [
            'ReviewingOrderEOF',
            'PreparingToShipEOF',
            'ShippedEOF',
            'PartiallyShippedEOF',
            'PartiallyFailedDeliveryEOF',
            'FailedDeliveryEOF',
            'PartiallyDeliveredEOF',
            'DeliveredEOF',
            'NotImplementedEOF',
        ],
        Facet: ['MatchFacet', 'RangeFacet', 'SpecFacet'],
        PaymentMethod2: [
            'ByInvoicePaymentMethod',
            'CardPaymentMethod',
            'PaypalPaymentMethod',
            'SepaPaymentMethod',
        ],
        WorkEmployee: ['GroverEmployee'],
        PresignedUrlCreateResult: ['PresignedUrl', 'PresignedUrlError'],
        ParseXlsxResult: ['ParsedXlsx', 'ParseXlsxError'],
        RentalPlanXlsxResultOrError: ['ParseXlsxError', 'RentalPlanXlsxResult'],
        ImageUploadResult: ['ImageUploadData', 'ImageUploadError'],
        BundleOrError: ['Bundle', 'BundleError'],
        TaskRentalPlanUploadResult: ['TaskError', 'RentalPlanUploadTask'],
        TaskRentalPlanExportResult: ['TaskError', 'RentalPlanExportTask'],
        TaskBundlesExportResult: ['TaskError', 'BundlesExportTask'],
        TaskProductsUploadResult: ['TaskError', 'ProductsUploadTask'],
        KeywordFilterOrError: ['KeywordFilter', 'ProductFilterError'],
        GenericError: [
            'ProductFilterError',
            'CategoryError',
            'EnergyLabelError',
            'UpdateProductLogisticsError',
            'UpdateProductSpecificationsError',
            'CatalogGroupingUpsertError',
            'ProductsWidgetError',
        ],
        RangeFilterOrError: ['RangeFilter', 'ProductFilterError'],
        CategoryOrError: ['CategoryError', 'Category'],
        EnergyLabelOrError: ['EnergyLabel', 'EnergyLabelError'],
        UpdateProductLogisticsSuccessOrError: [
            'UpdateProductLogisticsSuccess',
            'UpdateProductLogisticsError',
        ],
        UpdateProductSpecificationsSuccessOrError: [
            'UpdateProductSpecificationsSuccess',
            'UpdateProductSpecificationsError',
        ],
        CatalogGroupingUpsertSuccessOrError: [
            'CatalogGroupingUpsertSuccess',
            'CatalogGroupingUpsertError',
        ],
        BulkUpdateError: ['AssetBulkStatusUpdateResult', 'AssetBulkWarehouseCodeUpdateResult'],
        BulkUpdateParams: ['AssetBulkStatusUpdate', 'AssetBulkWarehouseCodeUpdate'],
        GenerateReturnLabelResultUnion: [
            'GenerateReturnLabelSuccess',
            'GenerateReturnLabelFailure',
        ],
        SyncShipmentResultType: ['SyncShipmentSuccess', 'SyncShipmentFailure'],
        UpdateTrackingInformationResultType: [
            'UpdateTrackingInformationSuccess',
            'UpdateTrackingInformationFailure',
        ],
        ProductsWidgetOrError: ['ProductsWidget', 'ProductsWidgetError'],
        ImportEmployeesResponse: [
            'EmployeeImportRowCountExceeded',
            'EmployeeImportFileEmpty',
            'EmployeeImportFileSizeExceeded',
            'EmployeeImportRequiredColumnsMissing',
            'EmployeeImportFileNotSupportedError',
            'ImportEmployeesResult',
            'EmployeeImportFileInvalid',
        ],
        ExpectedError: [
            'EmployeeImportRowCountExceeded',
            'EmployeeImportFileEmpty',
            'EmployeeImportFileSizeExceeded',
            'EmployeeImportRequiredColumnsMissing',
            'EmployeeImportFileNotSupportedError',
            'EmployeeImportFileInvalid',
        ],
        RentalPlanUploadError: ['RentalPlanUploadTaskParserError', 'RentalPlanUploadTaskRowError'],
    },
};
export default result;

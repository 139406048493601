import React, { Children, ReactElement, ReactNode, useEffect } from 'react';
import { sendChangeLanguageEvent } from 'snowplow';

import LanguageContext from './LanguageContext';
import useDetectChangeLanguage from '../../hooks/useDetectChangeLanguage';

interface LanguageProviderProps {
    children: ReactNode;
}

const LanguageProvider = ({ children }: LanguageProviderProps): ReactElement => {
    const changedLanguage = useDetectChangeLanguage();
    const systemLanguage = navigator.languages
        ? navigator.languages[0]
        : navigator.language || navigator.userLanguage;

    useEffect(() => {
        // matches strings with pattern `aa-aa`, `bb-bb`, etc.
        const re = new RegExp(/[a-z][a-z][-][a-z][a-z]/gi);

        // If `language` is of the format `langCode-storeCode`, then it was triggered by the user through our own LanguageSelector widget,
        // but if the value of language is just `langCode`, it was most probably triggered by a browser translation event, so we'll be tracking those specifically.
        if (changedLanguage && !re.test(changedLanguage)) {
            // Send `changeLanguage` Event to Amplitudes
            sendChangeLanguageEvent({
                eventCategory: 'language',
                eventAction: 'change',
                eventLabel: 'browser',
                eventProperty: {
                    changed_language: changedLanguage,
                    system_language: systemLanguage,
                },
            });
        }
    }, [changedLanguage, systemLanguage]);

    return (
        <LanguageContext.Provider value={{ language: changedLanguage }}>
            {Children.only(children)}
        </LanguageContext.Provider>
    );
};

export default LanguageProvider;

import * as React from 'react';

interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed';
        platform: string;
    }>;
    prompt(): Promise<void>;
}

function usePrompt(): [BeforeInstallPromptEvent | null, () => void] {
    const [deferredPrompt, setState] = React.useState<BeforeInstallPromptEvent | null>(null);

    const promptToInstall = () => {
        if (deferredPrompt) {
            return deferredPrompt.prompt();
        }
        return Promise.reject(
            new Error('Tried installing before browser sent "beforeinstallprompt" event'),
        );
    };

    React.useEffect(() => {
        const ready = (e: BeforeInstallPromptEvent) => {
            e.preventDefault();
            setState(e);
        };

        window.addEventListener('beforeinstallprompt', ready as any);

        return () => {
            window.removeEventListener('beforeinstallprompt', ready as any);
        };
    }, []);

    return [deferredPrompt, promptToInstall];
}

export default usePrompt;

const getProductAnnouncementData = (sku, storeCode, announcementsFlagData) => {
    // prevent all PDP's from crashing because of malformed variant attachment JSON, the
    // correct place for validation would probably be batchFeatureFlagRequestBuilder but let's not
    // create a mess there as this is only a temp solution

    try {
        if (!announcementsFlagData?.variantAttachment) {
            return null;
        }

        const productAnnouncementData = announcementsFlagData?.variantAttachment?.announcements.find(
            ({ storeCodes, skus }) => skus.includes(sku) && storeCodes.includes(storeCode),
        );

        if (!productAnnouncementData) {
            return null;
        }

        const { pdpHeadline, pdpDescription, checkoutText } = productAnnouncementData;

        if (![pdpHeadline, pdpDescription, checkoutText].every((i) => typeof i === 'string')) {
            throw new Error();
        }

        return { pdpHeadline, pdpDescription, checkoutText };
    } catch (e) {
        const malformedJsonError = new Error(`malformed announcement flag variant attachment json`);
        console.error(malformedJsonError);

        return null;
    }
};

export default getProductAnnouncementData;

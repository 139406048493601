import * as InWebViewAPI from 'ggApp/modules/inwebview_api';
import { POST_EVENTS } from 'ggApp/modules/inwebview_api/constants';
import { saveAuthCredentials, forgetAuthCredentials } from 'ggApp/modules/auth/utils';

type USER_LOGIN_EVENT_DATA = {
    authToken: string;
    refreshToken: string;
    maxAge?: number;
};

export function initWebviewAuthListeners() {
    const isApp = !!window.ReactNativeWebView;
    if (!isApp) {
        return;
    }

    InWebViewAPI.addEventListener(POST_EVENTS.USER_LOGOUT, () => {
        forgetAuthCredentials(undefined, true);
    });

    InWebViewAPI.addEventListener(
        POST_EVENTS.TOKENS_UPDATED,
        ({ authToken, refreshToken, maxAge }: USER_LOGIN_EVENT_DATA) => {
            saveAuthCredentials(authToken, refreshToken, maxAge, undefined, false);
        },
    );
}

import React from 'react';
import PropTypes from 'prop-types';

import { Wrap } from './styled';

const IconLink = ({ className, item }) => (
    <Wrap className={className} external={!item.internal} target="_blank" to={item.link}>
        {item.icon}
    </Wrap>
);

IconLink.propTypes = {
    item: PropTypes.shape({
        internal: PropTypes.bool,
        link: PropTypes.string,
        icon: PropTypes.node,
    }),
    className: PropTypes.string,
};

IconLink.defaultProps = {
    item: {},
    className: '',
};

export default IconLink;

import { SET_RENTAL_PLAN_INDEX } from '../actionTypes';

const defaultState = 99;

export default function rentalPlanIndex(state = defaultState, action) {
    switch (action.type) {
        case SET_RENTAL_PLAN_INDEX:
            return action.rentalPlanIndex;
        default:
            return state;
    }
}

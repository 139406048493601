import React from 'react';
import { Route } from 'react-router';

import { withAuthGuard } from 'ggApp/modules/router/hocs';
import SurveyScreen from 'ggApp/shared/screens/Survey';
import Loadable from 'react-loadable';
import CircleLoader from 'ggApp/shared/components/CircleLoader';

const HaTSafterDelivery = Loadable({
    loader: async () => {
        const { HaTSSurvey } = await import(/* webpackChunkName: "HaTSSurvey" */ '@devsbb/survey');
        return HaTSSurvey;
    },
    loading: () => <CircleLoader />,
    delay: 300,
    timeout: 10000,
});

const HaTSafterReturn = Loadable({
    loader: async () => {
        const { HaTSafterReturnSurvey } = await import(
            /* webpackChunkName: "HaTSSurvey" */ '@devsbb/survey'
        );
        return HaTSafterReturnSurvey;
    },
    loading: () => <CircleLoader />,
    delay: 300,
    timeout: 10000,
});

const HaTSafterCharging = Loadable({
    loader: async () => {
        const { HaTSafterChargingSurvey } = await import(
            /* webpackChunkName: "HaTSSurvey" */ '@devsbb/survey'
        );
        return HaTSafterChargingSurvey;
    },
    loading: () => <CircleLoader />,
    delay: 300,
    timeout: 10000,
});

const SurveyRoutes = (
    <Route>
        <Route path="survey" component={SurveyScreen}>
            <Route path="hats" component={withAuthGuard(HaTSafterDelivery)} />
            <Route path="hats-ar" component={withAuthGuard(HaTSafterReturn)} />
            <Route path="hats-ac" component={withAuthGuard(HaTSafterCharging)} />
        </Route>
    </Route>
);

export default SurveyRoutes;

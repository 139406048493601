import { sendStructEvent } from '../../api';
import { EventProperty } from '../../types';

interface PayloadModel {
    eventAction: string;
    eventLabel: string;
    eventProperty: EventProperty;
}

export const sendFooterCtaClickEvent = (payload: PayloadModel): void => {
    const { eventLabel, eventAction, eventProperty } = payload;
    sendStructEvent({
        eventCategory: 'offerings',
        eventAction,
        eventLabel,
        eventProperty,
    });
};

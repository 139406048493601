import styled from 'styled-components';

import DropDown from 'ggApp/shared/components/DropDownSelectors/DropDown/index';
import Message from 'ggApp/shared/icons/Message';
import MapDot from 'ggApp/shared/icons/MapDot';

export const Wrap = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MapDotIcon = styled(MapDot)`
    height: 24px;
    width: 24px;
    margin-right: ${({ theme }) => theme.spacers.M};
`;

export const StoreDropDown = styled(DropDown)`
    margin-bottom: 16px;
`;

export const MessageIcon = styled(Message)`
    height: 24px;
    width: 24px;
    margin-right: ${({ theme }) => theme.spacers.M};
`;

export const LanguageDropDown = styled(DropDown)``;

/* eslint-disable */
export default {
    mobile: {
        min: 0,
        max: 767,
    },
    tablet: {
        min: 768,
        max: 1023,
    },
    desktop: {
        min: 1024,
        max: 1279,
    },
    largeDesktop: {
        min: 1280,
        max: 1599,
    },
    widescreen: {
        min: 1600,
    },
}

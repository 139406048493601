import styled from 'styled-components';
import { ButtonV2 } from '@getgrover/ui';

import Modal from 'ggApp/modules/modal/components/v3/modal';
import { screens } from 'ggApp/utils/mixins/screens';

const BUSINESS_REDIRECT_MODAL_CLASS_NAME = 'B2CRedirectionContainer';
const BUSINESS_REDIRECT_MODAL_CONTENT_CLASS_NAME = 'B2CRedirectionContent';

export const StyledModal = styled(Modal).attrs({
    classNames: {
        container: BUSINESS_REDIRECT_MODAL_CLASS_NAME,
        content: BUSINESS_REDIRECT_MODAL_CONTENT_CLASS_NAME,
    },
})`
    display: flex;
    flex-direction: column;

    & .${BUSINESS_REDIRECT_MODAL_CLASS_NAME} {
        width: 560px;
        height: fit-content;
        min-height: 600px;
        max-height: 67vh;
    }

    & .${BUSINESS_REDIRECT_MODAL_CONTENT_CLASS_NAME} {
        padding: 0 0 48px;
        user-select: none;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        overflow: visible;
    }
`;

export const Top = styled.section`
    display: flex;
    flex-direction: column;
`;

export const Bottom = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 300px;
    padding: 0 ${(props) => props.theme.spacers.XXL};
`;

export const Header = styled.h4`
    ${({ theme }) => theme.typography.Subheadline};
    margin-top: ${({ theme }) => theme.spacers.XL};
    margin-bottom: ${({ theme }) => theme.spacers.L};
`;

export const Description = styled.p`
    color: ${({ theme }) => theme.colors['Level 5']};
    ${({ theme }) => theme.typography.Paragraph};
`;

export const CloseButtonWrapper = styled.div`
    position: absolute;
    z-index: 1;
    right: ${({ theme }) => theme.spacers.XL};
    top: ${({ theme }) => theme.spacers.XL};
    cursor: pointer;
`;

export const CloseButton = styled(ButtonV2)`
    padding: 0;
    background: white;
`;

export const StayButton = styled(ButtonV2).attrs({
    fullWidth: true,
})`
    max-width: 464px;
    height: 56px;
    &:focus {
        background: rgba(51, 51, 51, 0.25);
    }
`;

export const LogoutButton = styled(ButtonV2).attrs({
    fullWidth: true,
})`
    max-width: 464px;
    height: 56px;
    margin-top: ${({ theme }) => theme.spacers.XL};
    background: #fe1251;
    color: #fff;
    &:focus {
        background: rgba(51, 51, 51, 0.25);
    }
`;

export const IconContainer = styled.div`
    width: 100%;
    background: rgba(51, 51, 51, 0.07);
    display: flex;
    padding: 48px 0;
    justify-content: center;
    border-radius: 10px;
    
    > img {
        transform: scale(1);
        width: 180px;
        height: 170px;
    }

    @media (${screens.phone}) {
      padding: 30px 0;
    }
`;

export const TextContainer = styled.div`
    margin: 0 auto ${({ theme }) => theme.spacers.XL} auto;
    padding: 0 ${({ theme }) => theme.spacers.L};
    > * {
        max-width: 464px;
    }
`;

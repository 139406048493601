/* eslint-disable */
import React from 'react';

import { canUseDOM } from 'ggApp/utils/dom';
import loadConfiguration from 'ggApp/utils/configuration';
import { getDefaultGoogleConsentStateFromCookie } from 'ggApp/modules/cookiePolicy/tracking';
import { getCookieConsentValue } from 'ggApp/modules/cookiePolicy/utils';

const { APP_CONFIG } = loadConfiguration();
const { gtmSrc } = APP_CONFIG;

// eslint-disable-next-line import/prefer-default-export
export class GaAnalyticsGTMLoader extends React.Component {
    constructor(props) {
        super(props);

        if (!canUseDOM() || !gtmSrc) {
            return;
        }

        const state = getCookieConsentValue();
        const consentValue = getDefaultGoogleConsentStateFromCookie(state);

        window.dataLayer = window.dataLayer || [];
        function gtag() {
            dataLayer.push(arguments);
        }
        window.gtag = gtag;

        gtag('consent', 'default', consentValue);
        gtag('set', 'url_passthrough', true);

        (function(w, d, s, l) {
            // eslint-disable-next-line no-param-reassign
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js',
            });
            const customDataLayerParam = l != 'dataLayer' ? `&l=${l}` : '';
            const f = d.getElementsByTagName(s)[0];
            const j = d.createElement(s);
            j.async = true;
            j.src = `//www.googletagmanager.com/gtm.js?${gtmSrc}${customDataLayerParam}`;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer');
    }

    shouldComponentUpdate() {
        return false;
    }

    render() {
        return null;
    }
}

/* eslint-disable */
import {
    GET_SUBSCRIPTION,
    GET_SUBSCRIPTION_SUCCESS,
    GET_SUBSCRIPTION_ERROR,
} from '../actionTypes';

const defaultState = {
    subscriptionLoading: false,
    error: {},
};

export default function (state = defaultState, action) {
    switch (action.type) {
    case GET_SUBSCRIPTION:
        return {
            ...state,
            subscriptionLoading: true,
            error: {},
        };

    case GET_SUBSCRIPTION_SUCCESS:
        return {
            ...state,
            subscriptionLoading: false,
        };

    case GET_SUBSCRIPTION_ERROR:
        return {
            ...state,
            subscriptionLoading: false,
            error: action.error,
        };

    default:
        return state;
    }
}

import { useMemo, useContext } from 'react';
import { DashboardContext } from 'ggApp/shared/components/NewDashboard/Context';

type ForYouPointerHookOptions = {
    isYourTech: boolean;
    shouldShowMenu?: boolean;
};

export default function useForYouPointer({
    isYourTech,
    shouldShowMenu = false,
}: ForYouPointerHookOptions): boolean {
    const context = useContext(DashboardContext);
    const { summaryState, loadersState, filterState } = context ?? {};
    const { subscriptionSummary, mixSummary } = summaryState ?? {};
    const { filterItems } = filterState ?? {};
    const { loaders } = loadersState ?? {};
    const isSummaryLoading = loaders?.IS_SUMMARY_LOADING;
    const hasNoSubscriptionsOnFilter = filterItems?.length === 0;
    const hasNoMixSubscription = !mixSummary;
    const hasNoSubscriptionsOnSummary = subscriptionSummary?.length === 0;

    const shouldShowForYouPointer = useMemo(() => {
        if (isYourTech && !isSummaryLoading) {
            return Boolean(
                hasNoSubscriptionsOnFilter &&
                    !shouldShowMenu &&
                    hasNoSubscriptionsOnSummary &&
                    hasNoMixSubscription,
            );
        }

        return false;
    }, [
        hasNoSubscriptionsOnFilter,
        isSummaryLoading,
        isYourTech,
        shouldShowMenu,
        hasNoMixSubscription,
        hasNoSubscriptionsOnSummary,
    ]);

    return shouldShowForYouPointer;
}

import React from 'react';

import { IconPropTypes } from 'ggApp/types/propTypes';

const CircleTick = ({ className, size, color }) => (
    <svg
        className={className}
        height={size}
        width={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 20C16.4184 20 20 16.4184 20 12C20 7.5816 16.4184 4 12 4C7.5816 4 4 7.5816 4 12C4 16.4184 7.5816 20 12 20ZM15.7657 10.9657C16.0781 10.6533 16.0781 10.1467 15.7657 9.83431C15.4533 9.5219 14.9467 9.5219 14.6343 9.83431L11.2 13.2686L9.36569 11.4343C9.05327 11.1219 8.54673 11.1219 8.23431 11.4343C7.9219 11.7467 7.9219 12.2533 8.23431 12.5657L11.2 15.5314L15.7657 10.9657Z"
            fill={color}
        />
    </svg>
);

CircleTick.propTypes = {
    ...IconPropTypes,
};

CircleTick.defaultProps = {
    className: '',
    color: '#2FD4C0',
};

export default CircleTick;

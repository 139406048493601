import { FieldMergeFunction, StoreObject } from '@apollo/client';

function isNumber(value: unknown): value is number {
    return typeof value === 'number';
}

// This functions is merging values from the old cached array with the new one
// if the values with key field already exist in the incoming one
function mergeArrayValuesByField<T extends StoreObject>(
    field: string,
): FieldMergeFunction<T[], T[]> {
    return (existing, incoming, { readField, mergeObjects }) => {
        const merged: T[] = incoming ? [...incoming] : [];
        const mapValueToIndex: Record<string, number> = {};

        incoming?.forEach((item, index) => {
            const value = readField<string>(field, item);

            if (value) {
                mapValueToIndex[value] = index;
            }
        });

        existing?.forEach((item) => {
            const value = readField<string>(field, item);

            if (value) {
                const index = mapValueToIndex[value];

                if (isNumber(index)) {
                    // Merge the new item data with the existing item data.
                    merged[index] = mergeObjects(merged[index], item);
                }
            }
        });

        return merged;
    };
}

export default mergeArrayValuesByField;

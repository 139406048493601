/* eslint-disable camelcase */
import { sendPageAnalyticsEvent, mapMarketingParameters } from '@devsbb/analytics-client';
import { datadogLogs } from '@datadog/browser-logs';

import { INIT_PENDING } from 'ggApp/modules/init/actionTypes';

import { SET_REQUEST_STATUS } from 'ggApp/modules/requestStatus/actionTypes';
import { READY_STATUS } from 'ggApp/modules/requestStatus/constants';
import { params as routerParamsSelector } from 'ggApp/modules/router/selectors';
import capitalizeFirstLetter from 'ggApp/utils/capitalizeFirstLetter';
import {
    getNameByRoute,
    haveFeatureFlagsChanged,
    hasUserIdChanged,
    getMappedFeatureFlags,
} from 'analytics/utils';
import { flagObjects } from 'ggApp/modules/featureFlag/constants';
import CookiesManager from 'ggApp/utils/CookiesManager';
import { pageVisitTagHandler } from 'ggApp/modules/tracking/actions';
import { CART_CACHE_KEY } from 'ggApp/modules/tracking/constants';

export const returnPageNameAndType = (routeParams, pageNameAndType) => {
    const responseObj = {};
    if (routeParams.subCategorySlug) {
        responseObj.type = 'sub-category';
        responseObj.name = 'Sub-category';
    } else if (routeParams.categorySlug) {
        responseObj.type = 'category';
        responseObj.name = 'Category';
    } else if (routeParams.gType && routeParams.gType.includes('explore')) {
        responseObj.type = 'landing-page';
        responseObj.name = 'Landing Page';
    } else if (pageNameAndType) {
        responseObj.type = pageNameAndType;
        responseObj.name = capitalizeFirstLetter(pageNameAndType);
    } else {
        responseObj.type = '';
        responseObj.name = '';
    }
    return responseObj;
};
const analyticsPageViewMiddleware = (store) => (next) => (action) => {
    if (
        action.type === INIT_PENDING ||
        (action.type === SET_REQUEST_STATUS && action.status === READY_STATUS)
    ) {
        const routeParams = routerParamsSelector(store.getState());
        const activeStore = store.getState()?.store.active.data.code;
        const activeLanguage = store.getState()?.store.active.data.activeLanguage;
        const storeId = store.getState()?.store.active.data.store_id;
        const pageNameAndType = getNameByRoute();
        const cookiesManager = new CookiesManager();
        const cookieConsent = store.getState()?.cookiePolicy?.consentState;
        try {
            const mappedFeatureFlags = getMappedFeatureFlags(store.getState().featureFlag.data);
            const userId = cookiesManager.get('user_id');
            const allFeatureFlags = {
                catalogUiStoreMigration: flagObjects.catalogUiStoreMigration,
                ...(haveFeatureFlagsChanged(mappedFeatureFlags) ? { ...mappedFeatureFlags } : {}),
                ...(userId && hasUserIdChanged(userId) ? { id: userId } : {}),
            };
            const traits = mapMarketingParameters(window.location.href, document.referrer) || {};

            const flagsWithTraits = { segment: { traits, ...allFeatureFlags } };

            const cartData = JSON.parse(localStorage.getItem(CART_CACHE_KEY) || '{}');
            // a hacky workaround, but looks like throwing this in the queue will make sure we have the right url, pathname, title
            setTimeout(() => {
                const pageResponseObject = returnPageNameAndType(routeParams, pageNameAndType);
                sendPageAnalyticsEvent(
                    'Page Viewed',
                    {
                        page_type: pageResponseObject.type,
                        slug: routeParams.gSlug ?? undefined,
                        store_id: storeId,
                        store: activeStore,
                        locale: activeLanguage,
                    },
                    flagsWithTraits,
                );
                if (store.getState().auth?.user !== null) {
                    const userEmail = store.getState().auth?.user?.email;
                    pageVisitTagHandler(
                        pageResponseObject.type,
                        userEmail,
                        userId,
                        cartData,
                        activeStore,
                        cookieConsent?.marketingCookieConsent,
                    );
                }
            }, 0);
        } catch (e) {
            datadogLogs.logger.error(`Failure in tracking page ${pageNameAndType}`, { e });
        }
    }

    next(action);
};

export default analyticsPageViewMiddleware;

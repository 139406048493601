import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import {
    mixCatalogFlag as mixCatalogFlagSelector,
    mixGlobalFlag as mixGlobalFlagSelector,
    showProductDiscountPercentageChip as showProductDiscountPercentageChipSelector,
    showLowStockChip as showLowStockChipSelector,
} from 'ggApp/modules/featureFlag/selectors';

import ChipMapper from './ChipMapper';

const enhance = compose(
    connect(
        createStructuredSelector({
            mixCatalogFlag: mixCatalogFlagSelector,
            mixGlobalFlag: mixGlobalFlagSelector,
            showProductDiscountPercentageChip: showProductDiscountPercentageChipSelector,
            showLowStockChip: showLowStockChipSelector,
        }),
    ),
);

export default enhance(ChipMapper);

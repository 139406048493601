import React, { useCallback, MouseEvent, useEffect, useMemo } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { sendAnalyticsEvent } from '@devsbb/analytics-client';
import { ReduxRouterAction } from 'redux-router/lib/actionCreators';
import { AccountMenu as Menu } from '@getgrover/ui';
import { openHelpCenter } from '@devsbb/cs-sdk';
import {
    user as userSelector,
    isVerifiedPrivateAccount as isVerifiedPrivateAccountSelector,
    isIncompleteBusinessAccount as isIncompleteBusinessAccountSelector,
} from 'ggApp/modules/auth/selectors';
import { redirectus as redirectAction } from 'ggApp/modules/router/actions';
import { params as routerParamsSelector } from 'ggApp/modules/router/selectors';
import {
    isMainEntry as isMainEntrySelector,
    storeActive as storeActiveSelector,
} from 'ggApp/modules/store/selectors';
import { initDone as initDoneSelector } from 'ggApp/modules/init/selectors';
import { newReferralsHostFeatureFlag as newReferralsHostFeatureFlagSelector } from 'ggApp/modules/featureFlag/selectors';

import { PureTranslator } from 'ggApp/modules/translator/components';

import { buildAccountMenuEventFunction } from 'ggApp/modules/gaAnalytics/actions';

import { ResumeRegistration } from 'ggApp/shared/common/ResumeRegistration';

import { useLazyCurrentCampaign } from 'ggApp/shared/screens/NewReferrals/hooks';

import { AccountMenuProps, ActionProps, StoreSelectedProps } from './types';

const AccountMenu = ({
    user,
    isVerifiedPrivateAccount,
    isIncompleteBusinessAccount,
    newReferralsHostFeatureFlag,
    initDone,
    activeStore,
    routerParams,
    redirect,
    onCloseMenu = (): void => {},
}: AccountMenuProps & ActionProps & StoreSelectedProps): JSX.Element => {
    const { code: storeCode, activeLanguage: locale, store_id: storeId } = activeStore;
    const {
        data: { guestCashAmount },
        loadReferralCampaign,
    } = useLazyCurrentCampaign(activeStore);

    const isBusinessStore = storeCode.startsWith('business');
    const isCatalogRoute = Boolean(routerParams?.language);
    const userName = `${user?.first_name ?? ''} ${user?.last_name ?? ''}`;

    const wishlistPath = `/${storeCode}-${locale}/favorites`;
    const yourPaymentsPath = `/${storeCode}/your-payments`;
    const yourProfilePath = `/${storeCode}/your-profile`;
    const referralsPath = `/${storeCode}-${locale}/referrals`;

    const optimisticIsIncompleteBusinessAccount = initDone ? isIncompleteBusinessAccount : false;

    useEffect(() => {
        if (!isBusinessStore) {
            loadReferralCampaign();
        }
    }, [isBusinessStore, loadReferralCampaign]);

    const onContactUsClick = useCallback(() => {
        buildAccountMenuEventFunction('chat')();
        onCloseMenu();
        openHelpCenter();
    }, [onCloseMenu]);

    const onWishlistClick = useCallback(
        (event: MouseEvent) => {
            event?.preventDefault();
            buildAccountMenuEventFunction('favorites')();
            redirect(wishlistPath, !isCatalogRoute);
            onCloseMenu();
        },
        [isCatalogRoute, redirect, wishlistPath, onCloseMenu],
    );

    const onYourPaymentsClick = useCallback(
        (event: MouseEvent) => {
            event?.preventDefault();
            buildAccountMenuEventFunction('yourPayments')();
            redirect(yourPaymentsPath, true);
            onCloseMenu();
        },
        [redirect, yourPaymentsPath, onCloseMenu],
    );

    const onYourProfileClick = useCallback(
        (event: MouseEvent) => {
            event?.preventDefault();
            sendAnalyticsEvent('Account Clicked', { store_id: storeId });
            buildAccountMenuEventFunction('yourProfile')();
            redirect(yourProfilePath, isCatalogRoute);
            onCloseMenu();
        },
        [storeId, redirect, yourProfilePath, isCatalogRoute, onCloseMenu],
    );

    const onReferralsClick = useCallback(
        (event: MouseEvent) => {
            event?.preventDefault();
            buildAccountMenuEventFunction('referrals')();
            redirect(referralsPath, !isCatalogRoute);
            onCloseMenu();
        },
        [isCatalogRoute, redirect, referralsPath, onCloseMenu],
    );

    const actionsProps = {
        Wishlist: {
            title: <PureTranslator tk="ACCOUNT_MENU_WISHLIST_TITLE" />,
            description: <PureTranslator tk="ACCOUNT_MENU_WISHLIST_DESCRIPTION" />,
            onClick: onWishlistClick,
            href: wishlistPath,
        },
        YourPayments: {
            title: <PureTranslator tk="ACCOUNT_MENU_YOUR_PAYMENTS_TITLE" />,
            description: <PureTranslator tk="ACCOUNT_MENU_YOUR_PAYMENTS_DESCRIPTION" />,
            onClick: onYourPaymentsClick,
            href: yourPaymentsPath,
        },
        ReferralCode: {
            title: <PureTranslator tk="ACCOUNT_MENU_REFERRALS_TITLE" />,
            description: (
                <PureTranslator
                    tk="ACCOUNT_MENU_REFERRALS_DESCRIPTION_WITH_VALUE"
                    withValues={{ amount: guestCashAmount.formatted }}
                />
            ),
            onClick: onReferralsClick,
            isHidden: isBusinessStore,
            href: referralsPath,
        },
        ContactUs: {
            title: <PureTranslator tk="ACCOUNT_MENU_CONTACT_US_TITLE" />,
            description: <PureTranslator tk="ACCOUNT_MENU_CONTACT_US_DESCRIPTION" />,
            onClick: onContactUsClick,
        },
        YourProfile: {
            onClick: onYourProfileClick,
            href: yourProfilePath,
        },
    };

    const actionsPropsUS = {
        Wishlist: {
            title: <PureTranslator tk="ACCOUNT_MENU_WISHLIST_TITLE" />,
            description: <PureTranslator tk="ACCOUNT_MENU_WISHLIST_DESCRIPTION" />,
            onClick: onWishlistClick,
            href: wishlistPath,
        },
        YourPayments: {
            title: <PureTranslator tk="ACCOUNT_MENU_YOUR_PAYMENTS_TITLE" />,
            description: <PureTranslator tk="ACCOUNT_MENU_YOUR_PAYMENTS_DESCRIPTION" />,
            onClick: onYourPaymentsClick,
            href: yourPaymentsPath,
        },
        ReferralCode: {
            title: <PureTranslator tk="ACCOUNT_MENU_REFERRALS_TITLE" />,
            description: <PureTranslator tk="ACCOUNT_MENU_REFERRALS_BUCKS_DESCRIPTION" />,
            onClick: onReferralsClick,
            isHidden: isBusinessStore || !newReferralsHostFeatureFlag,
            href: referralsPath,
        },
        ContactUs: {
            title: <PureTranslator tk="ACCOUNT_MENU_CONTACT_US_TITLE" />,
            description: <PureTranslator tk="ACCOUNT_MENU_CONTACT_US_DESCRIPTION" />,
            onClick: onContactUsClick,
        },
        YourProfile: {
            onClick: onYourProfileClick,
            href: yourProfilePath,
        },
    };

    const hintText = useMemo(
        () =>
            optimisticIsIncompleteBusinessAccount ? (
                <ResumeRegistration redirect={redirect} storeCode={storeCode} locale={locale} />
            ) : (
                undefined
            ),
        [locale, optimisticIsIncompleteBusinessAccount, redirect, storeCode],
    );

    return (
        <Menu
            userName={userName}
            accountEmail={user?.email}
            profileButtonText={<PureTranslator tk="ACCOUNT_MENU_YOUR_PROFILE" />}
            isAccountIncomplete={optimisticIsIncompleteBusinessAccount}
            hintText={hintText}
            isAccountVerified={isVerifiedPrivateAccount}
            actionsProps={storeCode === 'us' ? actionsPropsUS : actionsProps}
        />
    );
};

const enhance = connect<StoreSelectedProps, ActionProps, AccountMenuProps>(
    (store) => ({
        user: userSelector(store),
        activeStore: storeActiveSelector(store),
        routerParams: routerParamsSelector(store),
        isMainEntry: isMainEntrySelector(store),
        isVerifiedPrivateAccount: isVerifiedPrivateAccountSelector(store),
        isIncompleteBusinessAccount: isIncompleteBusinessAccountSelector(store),
        initDone: initDoneSelector(store),
        newReferralsHostFeatureFlag: newReferralsHostFeatureFlagSelector(store),
    }),
    (dispatch: Dispatch<ReduxRouterAction>) => ({
        redirect: bindActionCreators(redirectAction, dispatch),
    }),
);

export default enhance(AccountMenu);

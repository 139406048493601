import React from 'react';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import withDeviceInfo from 'ggApp/shared/components/viewport/withDeviceInfo';

import { keys as translationKeysSelector, translate } from 'ggApp/modules/translator/selectors';
import { storeActive } from 'ggApp/modules/store/selectors';

import {
    Wrapper,
    ContentWrap,
    Content,
    WidgetCta,
    Header,
    Body,
    LinkContent,
    Banner,
} from './styled';

export const FallbackMC1 = ({
    activeStore: { code, activeLanguage },
    className,
    translations,
    isMobileUser,
}) => {
    const fallbackImageUrl = isMobileUser
        ? 'https://res.cloudinary.com/grover/image/upload/v1622558794/marketing-cards-fallbacks/MC1_Mobile__1_.png'
        : 'https://res.cloudinary.com/grover/image/upload/v1622558817/marketing-cards-fallbacks/MC1_Desktop.png';
    const fallbackContent = {
        title: translate(translations, activeLanguage, 'MC1_FALLBACK_TITLE'),
        description: translate(translations, activeLanguage, 'MC1_FALLBACK_DESCRIPTION'),
        image: fallbackImageUrl,
        cta: {
            label: translate(translations, activeLanguage, 'MC1_FALLBACK_CTATEXT'),
            url: `${code}-${activeLanguage}${translate(
                translations,
                activeLanguage,
                'MC1_FALLBACK_CTAURL',
            )}`,
            internal: true,
        },
        textColor: '#1A1A1A',
    };

    return (
        <Wrapper className={className}>
            <Banner imgUrls={fallbackContent.image} src={fallbackContent.image} lazy={false} />
            <ContentWrap>
                <Content>
                    <Header textColor={fallbackContent.textColor}>{fallbackContent.title}</Header>
                    <Body
                        textColor={fallbackContent.textColor}
                        html={fallbackContent.description}
                    />
                    <WidgetCta
                        external={fallbackContent.cta.internal}
                        target="_self"
                        linkTo={fallbackContent.cta.url}
                        text={<LinkContent>{fallbackContent.cta.label}</LinkContent>}
                    />
                </Content>
            </ContentWrap>
        </Wrapper>
    );
};

const enhance = compose(
    withDeviceInfo,
    connect(
        createStructuredSelector({
            activeStore: storeActive,
            translations: translationKeysSelector,
        }),
    ),
    withProps(({ userDevice }) => ({
        isMobileUser: userDevice === 'mobile',
    })),
);

export default enhance(FallbackMC1);

import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';

import { isLoggedIn as isLoggedInSelector } from 'ggApp/modules/auth/selectors';
import { loadingStatus } from 'ggApp/modules/requestStatus/selectors';
import { overlayIsOpen as overlayIsOpenSelector } from 'ggApp/modules/router/selectors';
import ggProduct from 'ggApp/modules/product';
import ggAuth from 'ggApp/modules/auth';
import { loggedOutBrowsingFlag as loggedOutBrowsingFlagSelector } from 'ggApp/modules/featureFlag/selectors';
import withDeviceInfo from 'ggApp/shared/components/viewport/withDeviceInfo';
import { nativeUserLogin } from 'ggApp/modules/auth/v3/services';

import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import BaseLayout from './BaseLayout';

const enhance = compose(
    withDeviceInfo,
    connect(
        createStructuredSelector({
            activeStore: activeStoreSelector,
            isLoggedIn: isLoggedInSelector,
            overlayIsOpen: overlayIsOpenSelector,
            isProductListLoading: ggProduct.selectors.isProductListLoading,
            loading: loadingStatus,
            loggedOutBrowsingFlag: loggedOutBrowsingFlagSelector,
        }),
        (dispatch) => ({
            getUser: bindActionCreators(ggAuth.actions.getUser, dispatch),
            nativeUserLogin: bindActionCreators(nativeUserLogin, dispatch),
        }),
    ),
    withProps(({ userDevice }) => ({
        isDesktopUser: userDevice !== 'mobile' && userDevice !== 'tablet',
    })),
);

export default enhance(BaseLayout);

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { newCheckoutEnabledSelector } from 'ggApp/modules/featureFlag/selectors';

import { checkStoreAndUserType } from 'ggApp/modules/auth/actions';
import AuthModal from './AuthModal';

const enhance = compose(
    connect(
        (state, ownProps) => ({
            newCheckoutFlag: newCheckoutEnabledSelector(state, ownProps),
        }),
        (dispatch) => ({
            checkStoreAndUserType: bindActionCreators(checkStoreAndUserType, dispatch),
        }),
    ),
);

export default enhance(AuthModal);

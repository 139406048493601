import React from 'react';
import PropTypes from 'prop-types';

import { Translator } from 'ggApp/modules/translator';
import { openHelpCenter } from '@devsbb/cs-sdk';

import { FooterContainer, FooterLink, HelpButton } from './Footer.styles';

const Footer = ({ className, storeCode, activeLanguage }) => {
    return (
        <FooterContainer className={className}>
            <HelpButton onClick={openHelpCenter}>
                <Translator tk="LEGAL_LINKS_HELP_TEXT" />
            </HelpButton>

            <FooterLink href={`/${storeCode}-${activeLanguage}/g-about/datenschutzerklaerung`}>
                <Translator tk="LEGAL_LINKS_DATA_TEXT" />
            </FooterLink>

            <FooterLink href={`/${storeCode}-${activeLanguage}/g-about/agb`}>
                <Translator tk="LEGAL_LINKS_AGB_TEXT" />
            </FooterLink>
        </FooterContainer>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
    storeCode: PropTypes.string.isRequired,
    activeLanguage: PropTypes.string.isRequired,
};

Footer.defaultProps = {
    className: '',
};

export default Footer;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import { LokaliseText } from 'ggApp/modules/translator/components/styles';
import { internalToolsContext } from 'ggApp/shared/context/internalTools/context';

import { keys as keysSelector } from '../../selectors';
import useTranslatedText from '../../hooks/useTranslatedText';

/**
 *
 * @deprecated use PureTranslator instead, because it renders just a string without wrapped span node.
 * @class Translator
 * @extends {Component}
 */
const Translator = ({
    className,
    style,
    activeStoreLanguage,
    activeTranslatorKeys,
    tk,
    withValues,
}) => {
    const internalToolsData = useContext(internalToolsContext);
    const highlight = internalToolsData?.highlightLokalise;

    const text = useTranslatedText({
        activeStoreLanguage,
        activeTranslatorKeys,
        tk,
        withValues,
    });

    return (
        <LokaliseText
            className={className}
            style={style}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: text }}
            tk={tk}
            highlight={highlight}
        />
    );
};

Translator.propTypes = {
    activeStoreLanguage: PropTypes.string.isRequired,
    activeTranslatorKeys: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)).isRequired,
    tk: PropTypes.string.isRequired,
    style: PropTypes.shape({}),
    withValues: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

Translator.defaultProps = {
    style: undefined,
    withValues: undefined,
};

const enhance = compose(
    connect(
        createStructuredSelector({
            activeStoreLanguage: createSelector(activeStoreSelector, (s) => s.activeLanguage),
            activeTranslatorKeys: keysSelector,
        }),
    ),
);

export default enhance(Translator);

import React, { useState, useEffect, useCallback } from 'react';
import { useSwipeable } from 'react-swipeable';

import ReactResizeDetector from 'react-resize-detector';

import useMount from 'ggApp/shared/hooks/useMount';
import useHover from 'ggApp/shared/hooks/useHover';

import PropTypes from 'prop-types';
import {
    OverflowWrapper,
    CarouselWrapper,
    DesktopCarouselCard,
    MobileCarouselCard,
    ControlsWrapper,
    Next,
    Prev,
    DotsContainer,
    CarouselIndexDot,
} from './styles';

export const CampaignCarousel = ({ cards, userDevice, options }) => {
    const { autoplay } = options || {};
    const uniqueCards = JSON.parse(JSON.stringify(cards));
    const isMobile = userDevice === 'mobile';
    const isTablet = userDevice === 'tablet';
    const isDesktop = !isMobile && !isTablet;

    const initialCardWidth = window.innerWidth;
    const [oneCardOffset, setOneCardOffset] = useState(initialCardWidth);

    const firstAndLast = [
        { ...uniqueCards[uniqueCards.length - 1], key: 'last-dupe' },
        ...uniqueCards,
        { ...uniqueCards[0], key: 'first-dupe' },
        { ...uniqueCards[1], key: 'second-dupe' },
    ];
    const [slides, setSlides] = useState(uniqueCards);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [offset, setOffset] = useState(0);
    const [cardWidth, setCardWidth] = useState(initialCardWidth);
    const [transition, setTransition] = useState(false);
    const [rotationCounter, setRotationCounter] = useState(0);
    const [dotSelected, setDotSelected] = useState(null);
    const [sliding, setSliding] = useState(false);
    const [hoverRef, isHovered] = useHover();

    useMount(() => {
        setSlides(firstAndLast);
        setOffset(oneCardOffset);
    });

    const resizeCardWidth = useCallback(() => {
        const newCardWidth = window.innerWidth;
        const newOneCardOffset = newCardWidth;
        const newOffsetPosition =
            currentSlideIndex === 0
                ? newOneCardOffset
                : newOneCardOffset + currentSlideIndex * newOneCardOffset;
        setCardWidth(newCardWidth);
        setOneCardOffset(newOneCardOffset);
        setOffset(newOffsetPosition);
    }, [currentSlideIndex]);

    const goForward = useCallback(() => {
        setTransition(true);
        const newIndex = currentSlideIndex + 1 > uniqueCards.length - 1 ? 0 : currentSlideIndex + 1;
        const newOffset = offset + oneCardOffset;
        setCurrentSlideIndex(newIndex);
        if (newIndex === 0) {
            setRotationCounter(1);
        }
        setOffset(newOffset);
        setTimeout(() => {
            setSliding(false);
        }, 1000);
    }, [currentSlideIndex, offset, oneCardOffset, uniqueCards.length]);

    const goBack = useCallback(() => {
        setTransition(true);
        const newIndex = currentSlideIndex - 1 < 0 ? uniqueCards.length - 1 : currentSlideIndex - 1;
        const newOffset = offset - oneCardOffset;
        setCurrentSlideIndex(newIndex);
        if (newIndex === uniqueCards.length - 1) {
            setRotationCounter(-1);
        }
        setOffset(newOffset);
        setTimeout(() => {
            setSliding(false);
        }, 1000);
    }, [currentSlideIndex, offset, oneCardOffset, uniqueCards.length]);

    const handleDotControl = (selected) => {
        if (!sliding && isDesktop) {
            setDotSelected(selected);
        }
    };

    const handleArrowControlNext = () => {
        setDotSelected(null);
        if (!sliding) {
            setSliding(true);
            goForward();
        }
    };

    const handleArrowControlPrev = () => {
        setDotSelected(null);
        if (!sliding) {
            setSliding(true);
            goBack();
        }
    };

    useEffect(() => {
        if (dotSelected !== null && dotSelected !== currentSlideIndex) {
            if (dotSelected - currentSlideIndex > 0) {
                goForward();
            } else if (dotSelected - currentSlideIndex < 0) {
                goBack();
            }
        }
    }, [currentSlideIndex, dotSelected, goBack, goForward]);

    useEffect(() => {
        let interval = null;
        if (autoplay) {
            interval = setInterval(() => {
                if (!isHovered) goForward();
            }, 10000);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [autoplay, goForward, isHovered]);

    const bind = useSwipeable({
        onSwipedLeft: () => {
            setSliding(true);
            goForward();
        },
        onSwipedRight: () => {
            setSliding(true);
            goBack();
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    useEffect(() => {
        if (
            (currentSlideIndex === 0 && rotationCounter > 0) ||
            (currentSlideIndex === uniqueCards.length - 1 && rotationCounter < 0)
        ) {
            let newOffset = oneCardOffset;
            if (rotationCounter < 0) {
                newOffset = uniqueCards.length * oneCardOffset;
            }
            setTimeout(() => {
                setTransition(false);
                setOffset(newOffset);
            }, 1000);
        }
    }, [currentSlideIndex, oneCardOffset, rotationCounter, uniqueCards.length]);

    const CarouselControls = () => (
        <>
            {isDesktop && (
                <>
                    <Next onClick={handleArrowControlNext} />
                    <Prev onClick={handleArrowControlPrev} />
                </>
            )}
            <ControlsWrapper>
                <DotsContainer>
                    {uniqueCards &&
                        uniqueCards.map((card, i) => (
                            <CarouselIndexDot
                                key={`${card.title}-dot`}
                                selected={i === currentSlideIndex}
                                onClick={() => handleDotControl(i)}
                                isMobile={isMobile}
                            />
                        ))}
                </DotsContainer>
            </ControlsWrapper>
        </>
    );

    const Carousel = (
        <>
            <CarouselWrapper
                id="carouselContent"
                offset={offset}
                transition={transition}
                ref={hoverRef}
            >
                {slides &&
                    slides.map((content) => {
                        return isDesktop ? (
                            <DesktopCarouselCard
                                key={content.key || content.title}
                                campaignBannerContent={content}
                                width={cardWidth}
                            />
                        ) : (
                            <MobileCarouselCard
                                key={content.key || content.title}
                                campaignBannerContent={content}
                                isTablet={isTablet}
                            />
                        );
                    })}
            </CarouselWrapper>
            <CarouselControls />
        </>
    );

    return (
        <>
            {isDesktop ? (
                <ReactResizeDetector
                    handleWidth
                    refreshMode="debounce"
                    refreshRate={1000}
                    onResize={resizeCardWidth}
                >
                    <OverflowWrapper>{Carousel}</OverflowWrapper>
                </ReactResizeDetector>
            ) : (
                <OverflowWrapper {...bind}> {Carousel} </OverflowWrapper>
            )}
        </>
    );
};

CampaignCarousel.propTypes = {
    cards: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            text: PropTypes.string,
            textColor: PropTypes.string,
            cta: PropTypes.shape({
                internal: PropTypes.bool,
                url: PropTypes.string,
                label: PropTypes.string,
            }),
            ctaColor: PropTypes.string,
            backgroundType: PropTypes.string,
            carouselImage: PropTypes.shape({
                url: PropTypes.string,
            }),
            carouselDesktopImage: PropTypes.shape({
                url: PropTypes.string,
            }),
            carouselTabletImage: PropTypes.shape({
                url: PropTypes.string,
            }),
            carouselMobileImage: PropTypes.shape({
                url: PropTypes.string,
            }),
            backgroundColor: PropTypes.string,
        }),
    ),
    userDevice: PropTypes.string,
    options: PropTypes.shape({
        autoplay: PropTypes.bool,
    }),
};

CampaignCarousel.defaultProps = {
    cards: [],
    userDevice: '',
    options: {},
};

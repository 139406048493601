import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ggRouter from 'ggApp/modules/router';

import { PARAM_NAME } from '../../constants';

@connect(
    createStructuredSelector({
        ggRouterState: ggRouter.selectors.moduleState,
    }),
)
export default class GGModalRouter extends Component {
    shouldComponentUpdate(nextProps) {
        const { ggRouterState } = this.props;

        return (
            ggRouterState.location.query[PARAM_NAME] !==
            nextProps.ggRouterState.location.query[PARAM_NAME]
        );
    }

    render() {
        const { ggRouterState, children } = this.props;
        const renderModal = React.Children.map(children, (child) =>
            ggRouterState.location.query[PARAM_NAME] === child.props.path
                ? React.cloneElement(child, { ...this.props })
                : null,
        );

        return renderModal.length === 0 ? null : <div>{renderModal}</div>;
    }
}

import ggRouter from 'ggApp/modules/router';

import { createSelector } from 'reselect';
import { MODULE_NAME } from '../constants';

export const moduleState = (state) => state[MODULE_NAME] || {};

export const currentCategory = createSelector(moduleState, (state) => state.category);

export const list = createSelector(moduleState, (state) => state.list || []);

export const subCategoryList = createSelector(list, (categoryList) => {
    const subCategories = categoryList.data.map((category) =>
        category.children.map((childCategory) => ({
            color: category.accent_color,
            ...childCategory,
        })),
    );
    if (subCategories.length) {
        return subCategories.reduce((a, b) => a.concat(b));
    }
    return [];
});

export const currentCategoryFromList = createSelector(
    ggRouter.selectors.params,
    list,
    (routerParams, categoryList) => {
        const { categorySlug } = routerParams;
        return categoryList.data.find((cat) => cat.permalink === `/${categorySlug}`) || {};
    },
);

export const getCategoryFromRouterParams = (routerParams) => {
    const { subCategorySlug, categorySlug } = routerParams;

    if (subCategorySlug && categorySlug) {
        return `${categorySlug}/${subCategorySlug}`;
    }
    if (categorySlug) {
        return categorySlug;
    }
    return '';
};

export const currentCategoryPermalink = createSelector(
    ggRouter.selectors.params,
    getCategoryFromRouterParams,
);

export const contentfulCategory = createSelector(currentCategory, (p) => p.contentful_record || {});

export const topProducts = createSelector(contentfulCategory, (contentful) => {
    const products = contentful.top_products;

    if (!products || products.length === 0) return null;

    return {
        products,
    };
});

export const currentSubCategory = createSelector(moduleState, (state) => state.subCategory || {});

export const currentSubCategoryName = createSelector(
    currentSubCategory,
    (currentSubCat) => currentSubCat.data && currentSubCat.data.name,
);

export const subCategoryContentfulRecord = createSelector(
    currentSubCategory,
    (subCat) => (subCat.data && subCat.data.contentful_record) || {},
);

export const subCategoryContentfulMarketingCards = createSelector(
    subCategoryContentfulRecord,
    (subCatRecord) => subCatRecord.catalog_page_content || [],
);

export const categoryContentfulMetaTags = createSelector(contentfulCategory, (contentful) => ({
    title: contentful.meta_title || null,
    description: contentful.meta_description || null,
    keywords: contentful.meta_keywords || null,
}));

export const subCategoryContentfulMetaTags = createSelector(
    subCategoryContentfulRecord,
    (contentful) => ({
        title: contentful.meta_title || null,
        description: contentful.meta_description || null,
        keywords: contentful.meta_keywords || null,
    }),
);

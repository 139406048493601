import createAxiosInstance from 'ggApp/utils/requests/axiosFactory';
import createRefreshTokenInterceptor from 'ggApp/utils/requests/refreshTokenInterceptor';
import CookiesManager, { injectCookiesToConfig } from 'ggApp/utils/CookiesManager';

const defaultAxiosInstance = createAxiosInstance();
const axiosInstanceWithoutRefreshingToken = createAxiosInstance();
defaultAxiosInstance.interceptors.response.use(...createRefreshTokenInterceptor());

function axiosRequest(config, flowOptions = {}) {
    const { accessToken } = flowOptions;

    // eslint-disable-next-line no-param-reassign
    injectCookiesToConfig(new CookiesManager(), config);

    if (accessToken) {
        const configAuthHeader = {
            ...config,
            headers: {
                ...(config.headers ?? {}),
                Authorization: `Bearer ${accessToken}`,
            },
        };

        return axiosInstanceWithoutRefreshingToken.request(configAuthHeader);
    }

    // Use the default axios instance with auto injecting auth token
    // and use the refresh token system if authToken was not provided
    return defaultAxiosInstance.request(config);
}

export default axiosRequest;

/* eslint-disable */
import hslToRgb from '../helpers/hslToRgb';

const errMsg = 'CONVERTION ERROR';

const isHsl = color => typeof color.hue === 'number'
    && typeof color.saturation === 'number'
    && typeof color.lightness === 'number'
    && (typeof color.alpha !== 'number' || typeof color.alpha === 'undefined');

const isHsla = color => typeof color.hue === 'number'
    && typeof color.saturation === 'number'
    && typeof color.lightness === 'number'
    && typeof color.alpha === 'number';

export function toColorString(color) {
    if (typeof color !== 'object') throw new Error(errMsg);
    if (isHsla(color)) return hsla(color);
    if (isHsl(color)) return hsl(color);

    throw new Error(errMsg);
}

function hsl(
    value,
    saturation,
    lightness,
) {
    if (
        typeof value === 'number'
        && typeof saturation === 'number'
        && typeof lightness === 'number'
    ) {
        return hslToHex(value, saturation, lightness);
    } if (
        typeof value === 'object'
        && saturation === undefined
        && lightness === undefined
    ) {
        return hslToHex(value.hue, value.saturation, value.lightness);
    }

    throw new Error(
        'Passed invalid arguments to hsl, please pass multiple numbers e.g. hsl(360, 0.75, 0.4) or an object e.g. rgb({ hue: 255, saturation: 0.4, lightness: 0.75 }).',
    );
}

function hsla(
    value,
    saturation,
    lightness,
    alpha,
) {
    if (
        typeof value === 'number'
        && typeof saturation === 'number'
        && typeof lightness === 'number'
        && typeof alpha === 'number'
    ) {
        return alpha >= 1
            ? hslToHex(value, saturation, lightness)
            : `rgba(${hslToRgb(value, saturation, lightness)},${alpha})`;
    } if (
        typeof value === 'object'
        && saturation === undefined
        && lightness === undefined
        && alpha === undefined
    ) {
        return value.alpha >= 1
            ? hslToHex(value.hue, value.saturation, value.lightness)
            : `rgba(${hslToRgb(value.hue, value.saturation, value.lightness)},${
                value.alpha
            })`;
    }

    throw new Error(
        'Passed invalid arguments to hsla, please pass multiple numbers e.g. hsl(360, 0.75, 0.4, 0.7) or an object e.g. rgb({ hue: 255, saturation: 0.4, lightness: 0.75, alpha: 0.7 }).',
    );
}

function colorToHex(color) {
    return numberToHex(Math.round(color * 255));
}

export function numberToHex(value) {
    const hex = value.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
}

export const reduceHexValue = (value) => {
    if (
        value.length === 7
        && value[1] === value[2]
        && value[3] === value[4]
        && value[5] === value[6]
    ) {
        return `#${value[1]}${value[3]}${value[5]}`;
    }
    return value;
};

function convertToHex(red, green, blue) {
    return reduceHexValue(`#${colorToHex(red)}${colorToHex(green)}${colorToHex(blue)}`);
}

function hslToHex(hue, saturation, lightness) {
    return hslToRgb(hue, saturation, lightness, convertToHex);
}

import React, { useCallback, useRef } from 'react';

import { PureTranslator } from 'ggApp/modules/translator/components';
import { AuthModal } from 'ggApp/modules/auth/v3/components';
import FLOW_NAMES from 'ggApp/modules/auth/v3/constants/flowNames';

import { ButtonLink, Text } from './styles';

interface ResumeRegistrationProps {
    redirect: (path: string, external: boolean) => void;
    storeCode: string;
    locale: string;
}

export const ResumeRegistration = ({
    redirect,
    storeCode,
    locale,
}: ResumeRegistrationProps): JSX.Element => {
    const authModalRef = useRef<{ openModal: () => void } | null>(null);
    const isBusinessStore = storeCode.startsWith('business');

    const createAuthModalRef = useCallback((authModal) => {
        authModalRef.current = authModal;
    }, []);

    const openAuthModal = useCallback(() => {
        if (authModalRef.current) {
            authModalRef.current.openModal();
        }
    }, []);

    const handleOnClick = useCallback(() => {
        isBusinessStore
            ? redirect(`/${storeCode}-${locale}/auth?flow=MISSING_INFO_BUSINESS`, true)
            : openAuthModal();
    }, [isBusinessStore, locale, openAuthModal, redirect, storeCode]);

    return (
        <>
            <Text>
                <PureTranslator tk="ACCOUNT_INCOMPLETE_WARNING_TEXT" />{' '}
                <ButtonLink onClick={handleOnClick}>
                    <PureTranslator tk="ACCOUNT_INCOMPLETE_RESUME_REGISTRATION_LIST" />
                </ButtonLink>
                .
            </Text>
            <AuthModal
                modalRef={createAuthModalRef}
                flow={FLOW_NAMES.MISSING_INFO_BUSINESS}
                options={{ hasSuccessSignupStep: true, isCheckout: false }}
            />
        </>
    );
};

/* eslint-disable */
const defaultState = null;

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'PAYMENT_FETCHING':
    case 'GET_PAYMENT_METHODS_BY_STORE':
    case 'GET_PAYMENT_METHODS_BY_USER':
      return true;

    case 'PAYMENT_STOP_FETCHING':
    case 'GET_PAYMENT_METHODS_BY_STORE_SUCCESS':
    case 'GET_PAYMENT_METHODS_BY_STORE_FAILURE':
    case 'GET_PAYMENT_METHODS_BY_USER_SUCCESS':
    case 'GET_PAYMENT_METHODS_BY_USER_FAILURE':
      return false;

    default:
      return state;
  }
}

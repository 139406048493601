const bannerTextDictionary = {
    de: {
        headingTxt: 'Hey Internetreisender 👋',
        descriptionTxt: 'Komm, wir bringen dich auf die richtige Seite.',
        selectTxt: 'Wähle deinen Ort und deine Sprache',
        warningTxt:
            'Zur Zeit liefern wir nur nach Deutschland, Österreich, in die Niederlande und das spanische Festland. Wir liefern außerdem innerhalb der kontinentalen USA.',
        ctaTxt: 'Auswahl bestätigen',
    },
    nl: {
        headingTxt: 'Hallo daar, webreiziger 👋',
        descriptionTxt: 'We brengen je graag naar de juiste pagina.',
        selectTxt: 'Selecteer je locatie en taal',
        warningTxt:
            'Momenteel verzenden wij uitsluitend naar Duitsland, Oostenrijk, het Spaanse vasteland en Nederland. We verzenden ook naar (het vasteland van) de Verenigde Staten.',
        ctaTxt: 'Bevestig je voorkeuren',
    },
    es: {
        headingTxt: 'Wow... Hola, viajero de la web 👋',
        descriptionTxt: 'Deja que te llevemos a la página correcta.',
        selectTxt: 'Selecciona tu localización y tu idioma.',
        warningTxt:
            'En estos momentos hacemos envíos a España, Austria, Países Bajos y España (solo península). También hacemos envíos a Estados Unidos (parte continental).',
        ctaTxt: 'Confirmar preferencias',
    },
    en: {
        headingTxt: 'Well hello, web traveler 👋',
        descriptionTxt: 'Let’s get you on the right page.',
        selectTxt: 'Select your location and language',
        warningTxt:
            'Currently we only ship to Germany, Austria, mainland Spain, and the Netherlands. We also ship to the continental United States.',
        ctaTxt: 'Confirm preferences',
    },
};

export const getBannerText = (lang) => {
    return bannerTextDictionary[lang] ? bannerTextDictionary[lang] : bannerTextDictionary.en;
};

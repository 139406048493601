import { sendStructEvent } from '../../api';

export interface ProductDataForCheckoutStepModel {
    name: string;
    productSKU: string;
    subscriptionLength: string;
    price: string;
}

interface PayloadModel {
    eventAction: string;
    eventLabel: string;
    eventProperty: {
        current_flow: string;
        userID: number;
        client_ip_address?: string;
        orderID: string;
        previous_step?: string;
        price?: string;
        productData?: ProductDataForCheckoutStepModel;
    };
}

export const sendCheckoutStep = (payload: PayloadModel): void => {
    const { eventLabel, eventAction, eventProperty } = payload;

    sendStructEvent({
        eventCategory: 'checkout',
        eventAction,
        eventLabel,
        eventProperty,
    });
};

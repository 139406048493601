import React from 'react';

export const translateTextWithComponent = (translatedString, textComponent) => {
    let replacementString = translatedString;
    let innerComponent;
    Object.keys(textComponent).forEach((key) => {
        const regex = new RegExp(`{{${key}}}`);
        replacementString = replacementString.split(regex);
        innerComponent = textComponent[key];
    });
    const firstHalf = replacementString[0];
    const secondHalf = replacementString[1];
    return (
        <>
            <React.Fragment>{firstHalf}</React.Fragment>
            {innerComponent}
            <React.Fragment>{secondHalf}</React.Fragment>
        </>
    );
};

// Utils
import axios from 'ggApp/utils/requests';

// Action types
import * as at from '../constants/actionTypes';

export function getHMAC(user) {
    return (dispatch) => {
        dispatch({ type: at.GET_HMAC });
        return axios
            .get(`/users/${user.id}/intercom_verification`)
            .then((result) => {
                dispatch({
                    type: at.GET_HMAC_SUCCESS,
                    data: result.data.hmac,
                });
                return result.data.hmac;
            })
            .catch(({ response: error = {} }) => {
                if (error.data) {
                    dispatch({
                        type: at.GET_HMAC_ERROR,
                        error: error.data,
                    });
                }
            });
    };
}

export function updateMessageCount(unreadCount) {
    return {
        type: at.GET_UNREAD_MESSAGES,
        data: unreadCount,
    };
}

import styled, {css} from 'styled-components';
import { styleConstants } from 'ggApp/utils/mixins/constants';

const { taskBarHeight } = styleConstants;

const Content = styled.div`
    visibility: visible;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    ${({ inWebView }) => !inWebView && css`
            height: ${taskBarHeight};
    `}
`;

const Container = styled.div`
    width: 100%;
`;

export {
    Content, Container,
};

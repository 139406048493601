import React, { useEffect, useState } from 'react';

import { PureTranslator } from 'ggApp/modules/translator/components';

import { CountdownTimerProps, CountdownTimerType } from './types';
import { filterCountdowns, calculateTime, displayTime } from './utils';
import { CountdownWrapper, CountdownText, CountdownTime } from './styled';

export const CountdownTimer = ({
    className,
    productPromotions,
    productSku,
    countdownById,
    activeStore,
    withText,
    countdownEndedCallback,
    countdownLoadedCallback,
    hideTimerWhenActualDateOutOfRange = false,
    isPDP = false,
}: CountdownTimerProps): JSX.Element | null => {
    const [timeLeft, setTimeLeft] = useState<Record<string, number> | null>(null);
    const [currentCountdown, setCurrentCountdown] = useState<CountdownTimerType | null>(null);
    const [currentSku, setCurrentSku] = useState<string | null>(null);

    const resetCountdown = () => {
        setCurrentCountdown(null);
        setTimeLeft(null);
    };

    useEffect(() => {
        if (productSku && currentSku !== productSku) {
            resetCountdown();
            setCurrentSku(productSku);
        }
    }, [currentSku, productSku]);

    useEffect(() => {
        if (productPromotions && activeStore?.code && productSku) {
            const countdown = filterCountdowns({
                countdowns: productPromotions?.countdownTimers,
                activeStoreCode: activeStore.code,
            });
            setCurrentCountdown(countdown);
        }
        if (countdownById) {
            setCurrentCountdown(countdownById);
        }
    }, [activeStore, countdownById, currentCountdown, productPromotions, productSku]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (currentCountdown?.endAt) {
                setTimeLeft(calculateTime(currentCountdown.endAt));
            } else {
                setTimeLeft(null);
            }
        }, 1000);
        return () => clearTimeout(timer);
    });

    const days = timeLeft?.days ? `${displayTime(timeLeft?.days)}d:` : '';
    const hours = `${displayTime(timeLeft?.hours)}h`;
    const minutes = `${displayTime(timeLeft?.minutes)}m`;
    const seconds = `${displayTime(timeLeft?.seconds)}s`;

    const timeString = `${days}${hours}:${minutes}:${seconds}`;

    const userIsInTimeRange = Boolean(
        currentCountdown?.startAt &&
            currentCountdown?.endAt &&
            Date.now() >= Date.parse(currentCountdown?.startAt) &&
            Date.now() <= Date.parse(currentCountdown?.endAt),
    );

    const hasEnoughTime = timeLeft && timeString !== '00:00:00' && timeString !== '00h:00m:00s';

    let shouldRender = hasEnoughTime;

    if (hideTimerWhenActualDateOutOfRange) {
        shouldRender = hasEnoughTime && userIsInTimeRange;
    }

    if (shouldRender) {
        if (countdownLoadedCallback) {
            countdownLoadedCallback();
        }
        return (
            <CountdownWrapper className={className} isPDP={isPDP}>
                <CountdownText isPDP={isPDP}>
                    {withText ? (
                        <>
                            <PureTranslator tk="COUNTDOWN_ENDS_IN" />{' '}
                        </>
                    ) : null}
                </CountdownText>
                <CountdownTime>{timeString}</CountdownTime>
            </CountdownWrapper>
        );
    }
    if (hasEnoughTime && countdownEndedCallback) {
        countdownEndedCallback();
    }
    return null;
};

export default CountdownTimer;

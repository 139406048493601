/* eslint-disable */
import ggStore from 'ggApp/modules/store';

const { CURRENCIES_ASSOCIATION } = ggStore.constants;
const getCurrencySymbol = (code) => CURRENCIES_ASSOCIATION[code];

export const isNotFoundOrderError = ({ response: error }) => error && error.data && error.data.base === 'Order not found';

export const isExistingItem = (order, productId) => order && order.items && order.items.find((i) => i.id === productId);

export const constructCartItemsPayload = (order) => ({
    ...order,
    currencySymbol: getCurrencySymbol(order.currency),
    items: (order.items || []).map((i) => ({
        ...i,
        variant: {
            ...i.variant,
            currencySymbol: getCurrencySymbol(i.variant.currency),
        },
    })),
});

import styled from 'styled-components';

export const Line = styled.div`
    margin: auto;
    margin-top: 12px;
    width: 35px;
    height: 4px;
    border-radius: 2px;
    background-color: ${(props): string => props.theme.colors['Level 1']};
`;

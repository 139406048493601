function UntranslatedValidationError(payload = {}) {
    const {
        message = 'Untranslated validation error occurred',
        fileName,
        lineNumber,
        ...restPayload
    } = payload;

    Error.call(this, message, fileName, lineNumber);

    if (Error.captureStackTrace) {
        Error.captureStackTrace(this, UntranslatedValidationError);
    } else {
        this.stack = new Error().stack;
    }

    this.errors = restPayload.errors ?? {};
    this.baseError = restPayload.baseError;
}

UntranslatedValidationError.prototype = Object.create(Error.prototype);

export { UntranslatedValidationError };

import { STORE_TYPE } from 'ggApp/modules/store/constants';
import ACCOUNT_TYPES from 'ggApp/modules/auth/v3/constants/accountTypes';

import { user as userSelector } from 'ggApp/modules/auth/selectors';
import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import { location as locationSelector } from 'ggApp/modules/router/selectors';

import { USER_SHOULD_BE_REDIRECTED } from '../../actionTypes';

import blacklist from './routeBlacklist';

const currentPathnameMatchesBlacklist = (pathname) => {
    if (!pathname || typeof pathname !== 'string') return false;

    return blacklist.some((current) => pathname.match(current));
};

export default (dispatch, getState) => {
    const user = userSelector(getState());
    const store = activeStoreSelector(getState());
    const { pathname } = locationSelector(getState());

    if (!user.id || !store.code) {
        return;
    }

    const isBusinessUser = user.user_type === ACCOUNT_TYPES.BUSINESS_CUSTOMER;
    // will be updated on store_type when grow-380 and grow-336 are done
    const isBusinessStore = store.code.includes(STORE_TYPE.BUSINESS);
    const isRetailOrGroverPay =
        store.store_type === STORE_TYPE.GROVER_PAY || store.store_type === STORE_TYPE.RETAIL;
    const isWrongStoreForBusinessCustomer = !isBusinessStore && !isRetailOrGroverPay;
    const pathBlacklisted = currentPathnameMatchesBlacklist(pathname);

    if (!pathBlacklisted && user.id && isBusinessUser && isWrongStoreForBusinessCustomer) {
        dispatch({
            type: USER_SHOULD_BE_REDIRECTED,
            data: true,
        });
    }
};

import styled, { css } from 'styled-components';
import GroverLogo from 'ggApp/shared/icons/GroverLogo';
import FrontshopLink from 'ggApp/shared/containers/FrontshopLink';
import { elevation, elevationTransition } from 'ggApp/utils/mixins';

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    background: #222329;
    overflow: scroll;
    padding-top: 24px;
    z-index: 10;
    @media (max-width: ${({ theme }) => theme.breakpoints.tablet.max}px) {
        padding-top: 0;
    }
`;

export const StandardLogo = styled(GroverLogo)`
    max-height: 48px;
    height: 48px;
    outline: none;
    margin: ${({ theme }) => theme.spacers.XL} 0;
    flex: 1 0 100%;
`;

export const Banner = styled.section`
    max-width: 832px;
    color: ${({ theme }) => theme.colors.Foreground};
    background: #2F3037;
    ${elevation(3, false)};
    border-radius: 8px;
    padding: 56px 32px 40px;
    margin: 0 ${({ theme }) => theme.spacers.M} ${({ theme }) => theme.spacers.XL};
    @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}px) {
        padding: ${({ theme }) => theme.spacers.L};
    }
`;
export const Title = styled.h1`
    ${({ theme }) => css(theme.typography.Headline)};
    @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}px) {
        ${({ theme }) => css(theme.typography.Subheadline)};
    }
`;
export const SubTitle = styled.p`
    ${({ theme }) => css(theme.typography.Paragraph)};
    color: ${(props) => props.theme.colors['Level 5']};
    margin-top: ${({ theme }) => theme.spacers.L};
    line-height: 24px;
    @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}px) {
        margin-top: ${({ theme }) => theme.spacers.M};
    }
`;
export const ContinueButton = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;
export const Divider = styled.div`
    margin-top: ${({ theme }) => theme.spacers.XXL};
    display: flex;
    position: relative;
    color: ${(props) => props.theme.colors.Foreground};
    ${(props) => css(props.theme.typography.SmallCapsHeadline)};
    letter-spacing: 0.1em;
    &::after {
        background-color: rgba(255, 255, 255, 0.1);
        content: "";
        height: 4px;
        align-self: center;
        flex: 1;
        border-radius: 2px;
        margin-left: 8px;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}px) {
        margin-top: ${({ theme }) => theme.spacers.L};
    }
`;
export const BrowserBlock = styled.li`
    list-style: none;
    margin-top: 8px;
    &:not(:first-of-type) {
      margin-top: ${({ theme }) => theme.spacers.XL};
    }
`;
export const BrowserHead = styled.div`
    display: flex;
    align-items: center;
    > * + * {
        margin-left: ${({ theme }) => theme.spacers.L};
        @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}px) {
            margin-left: ${({ theme }) => theme.spacers.M};
        }
    }
`;
export const BrowserLogo = styled.img`
    height: 48px;
    width: 48px;
    @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}px) {
        height: 32px;
        width: 32px;
    }
`;
export const BrowserName = styled.h2`
    flex: 1;
    ${({ theme }) => css(theme.typography.Subheadline)};
`;
export const DownloadButton = styled(FrontshopLink)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    border-radius: 24px;
    white-space: nowrap;
    cursor: pointer;
    ${elevationTransition(false)};
    padding: 0 ${({ theme }) => theme.spacers.XXL};
    &:focus {
        color: ${(props) => props.theme.colors.Background};
    }
    &:hover {
        color: ${(props) => props.theme.colors.Background};
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.55);
        ${elevation(2, false)}
    }
    &:active {
        color: ${(props) => props.theme.colors.Background};
        text-decoration: none;
        ${elevation(1, false)};
        transition-duration: 0s;
    }
    color: ${(props) => props.theme.colors.Background};
    background: ${(props) => props.theme.colors['Level 5']};
    ${(props) => css(props.theme.typography.SuperTinyHeadline)};
`;
export const Description = styled.p`
    ${({ theme }) => css(theme.typography.Paragraph)};
    color: rgba(255, 255, 255, 0.75);
    max-width: 479px;
    line-height: 24px;
    margin-left: 64px;
    margin-right: 160px;
    margin-top: ${({ theme }) => theme.spacers.M};
    @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}px) {
        margin-right: 0;
        margin-left: 0;
        margin-top: ${({ theme }) => theme.spacers.L};
    }
`;

export const Content = styled.div`
  ${({ visible }) => (visible ? css`
    visibility: visible;
    opacity: 1;
  ` : css`
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    overflow: hidden;
  `)}
`;

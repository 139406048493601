import styled, { css } from 'styled-components';
import { SonntagButtonV2 } from '@getgrover/ui';

import LanguageSelector from 'ggApp/shared/components/DropDownSelectors/Language';
import StoreSelector from 'ggApp/shared/components/DropDownSelectors/Store';
import FrontshopLink from 'ggApp/shared/containers/FrontshopLink';
import { ReviewIO as ReviewIOComponent } from 'ggApp/shared/components/ReviewIO';

import LinkListComponent from './LinkList';
import IconLinkComponent from './IconLink';

export const Badge = styled.div`
    width: 36px;
    height: 24px;
    box-sizing: border-box;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    :last-child {
        width: 97px;
    }
`;

export const Badges = styled.div`
    display: flex;
    align-items: center;

    ${Badge} + ${Badge} {
        margin-left: 16px;

        ${({ theme }) => theme.superQuery.all.and.maxWidth.mobileMax.css`
            margin-left: 10px;
        `};

        ${({ theme }) => theme.superQuery.all.and.maxWidth.of('320px').css`
            margin-left: 5px;
        `};
    }
`;

export const ContactText = styled.div`
    ${({ theme }) => css(theme.typography.Paragraph)};
    text-align: center;
    color: ${({ theme }) => theme.colors.Foreground};
`;

export const ContactButton = styled(SonntagButtonV2)`
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet.min}px) {
        margin-top: 0;
        margin-left: ${({ theme }) => theme.spacers.L};
    }
`;


export const ContactUs = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: ${({ theme }) => theme.spacers.XL};

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet.min}px) {
        flex-direction: row;
        margin-top: ${({ theme }) => theme.spacers.XXL};
    }
`;

export const Body = styled.div`
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet.min}px) {
        display: flex;
        flex-direction: row-reverse;
    }
`;

export const LinkList = styled(LinkListComponent)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacers.XXL};
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet.min}px) {
        align-items: flex-start;
    }
`;

export const IconLink = styled(IconLinkComponent)``;

export const Language = styled(LanguageSelector)``;

export const Store = styled(StoreSelector)`
    margin-top: ${({ theme }) => theme.spacers.M};
`;

export const LangStore = styled.div`
    margin-top: ${({ theme }) => theme.spacers.XL};
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet.min}px) {
        margin-top: ${({ theme }) => theme.spacers.XXL};
        flex: 0 1 25%;
    }
`;

export const SiteMap = styled.div`
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet.min}px) {
        flex: 1;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
    }
`;

export const Divider = styled.hr`
    width: 100%;
    border: 2px solid ${({ theme }) => theme.colors['Level 1']};
    border-top: none;
    margin: 0;
    margin-top: ${({ theme }) => theme.spacers.XL};
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet.min}px) {
        margin-top: ${({ theme }) => theme.spacers.XXL};
    }
`;

export const Link = styled(FrontshopLink)`
    ${({ theme }) => css(theme.typography.Paragraph)};
    color: ${({ theme }) => theme.colors.Foreground};
    display: flex;
    align-items: center;

    &:hover {
        color: ${({ theme }) => theme.colors.Foreground};
        text-decoration: underline;
    }

    &:visited {
        color: ${({ theme }) => theme.colors.Foreground};
        text-decoration: none;
    }
`;

export const ScoreAndPayMethods = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    ${Badges}, ${Link} {
        margin-top: ${({ theme }) => theme.spacers.XL};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet.min}px) {
        flex-direction: row;
        justify-content: space-between;
        ${Badges}, ${Link} {
            margin-top: 0;
        }
        margin-top: ${({ theme }) => theme.spacers.XXL};
    }
`;

export const Social = styled.div`
    display: flex;
    justify-content: space-around;
    grid-area: social;
`;

export const Legal = styled.div`
    display: flex;

    ${(props) => props.theme.superQuery.all.and.maxWidth.tabletMin.css`
        flex-flow: wrap;
        justify-content: center;

        > * {
            margin: 0 12px;
        }
    `}

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet.min}px) {
        grid-area: legal;
        justify-self: end;
        justify-content: space-around;

        > ${Link} + ${Link} {
            margin-left: ${({ theme }) => theme.spacers.L};
        }
    }
`;
export const Copyright = styled.div`
    grid-area: copyright;
    justify-self: center;
    ${({ theme }) => css(theme.typography.Paragraph)};
    color: ${({ theme }) => theme.colors.Foreground};

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet.min}px) {
        justify-self: start;
    }
`;

export const PressAndCopyright = styled.div`
    display: grid;
    grid-template-areas: 'social' 'legal' 'copyright';
    text-align: center;
    justify-content: center;

    ${Social}, ${Legal}, ${Copyright} {
        margin-top: ${({ theme }) => theme.spacers.XL};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet.min}px) {
        grid-template-areas: 'copyright social' 'legal legal';
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop.min}px) {
        grid-template-areas: 'copyright social legal';
        align-items: center;
        justify-content: initial;

        margin-top: ${({ theme }) => theme.spacers.XXL};
        ${Social}, ${Legal}, ${Copyright} {
            margin-top: 0;
        }
    }
`;

export const Root = styled.div`
    padding: ${({
        theme: {
            spacers: { L },
        },
        isApp,
    }) => (isApp ? `0 ${L} 0` : `0 ${L} 100px`)};
    color: ${({ theme }) => theme.colors.Foreground};
    background-color: ${({ theme }) => theme.colors.Background};
    z-index: 99;

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop.min}px) {
        padding: 0 5% 100px;
    }
`;

export const AppStoreProviders = styled.div`
    display: flex;
    width: 100%;
    padding-top: 24px;
    max-width: 315px;
    justify-content: space-between;

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet.min}px) {
        padding-top: 0;
    }
`;

export const ReviewIO = styled(ReviewIOComponent)`
    ${({ theme }) => theme.superQuery.all.and.maxWidth.mobileMax.css`
       margin-top: 25px;
    `}
`;

export const ModalLink = styled.button`
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: 0;
    border: 0;
    color: ${({ theme }) => theme.colors.Foreground};
    background-color: transparent;
    ${({ theme }) => theme.typography.Paragraph};

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet.min}px) {
        margin-right: 24px;
    }

    &:hover {
        text-decoration: underline;
    }
`;

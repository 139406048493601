import React from 'react';
import anime from 'animejs';
import PropTypes from 'prop-types';

import overlays from 'ggApp/shared/modals/overlays';
import Header, { HEADER_HEIGHT } from 'ggApp/shared/components/Header';
import BaseLayoutOverlaySwitcher from 'ggApp/shared/components/BaseLayout/Overlay/Switcher';
import LayoutComponentContentWrap from 'ggApp/shared/components/layout/contentWrap';
import Footer from 'ggApp/shared/components/BaseLayout/Footer';
import RecentlyViewSection from 'ggApp/shared/components/BaseLayout/RecentlyViewSection';
import LoadingModal from 'ggApp/shared/common/LoadingModal';
import MobileNavigation, { BOTTOM_NAV_HEIGHT } from 'ggApp/shared/components/navigation/Mobile';
import withWebView from 'ggApp/shared/hocs/withWebView';
import * as InWebViewAPI from 'ggApp/modules/inwebview_api';

import { POST_EVENTS } from 'ggApp/modules/inwebview_api/constants';
import { isDiscoveringCard } from 'ggApp/routes/_wrap/MobileWrapper/utils';
import { WidgetGrid, GridAligner } from 'ggApp/shared/components/ForYou/styled';

import { InnerWrap, Content } from './styled';
import { isNativeFlowActive } from './utils';

const scrollDownEvent = Symbol('scrollDownEvent');
const scrollUpEvent = Symbol('scrollUpEvent');
const minimized = Symbol('minimized');
const maximized = Symbol('maximized');
const FooterWithChecker = withWebView(Footer);

class BaseLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isTransparent: true,
        };

        this.lastScrollYPosition = 0;
        this.scrollDirection = scrollDownEvent;
        this.currentPosition = maximized;
    }

    componentDidMount() {
        InWebViewAPI.fireEvent(POST_EVENTS.WEB_VIEW_LOADED, {});

        window.addEventListener('scroll', this.onPageScroll, true);
        window.addEventListener('resize', this.onPageResize, true);

        InWebViewAPI.addEventListener(POST_EVENTS.SET_APP_DATA, this.setAppUser);

        const { nativeUserLogin } = this.props;
        InWebViewAPI.addEventListener(POST_EVENTS.USER_LOGIN, nativeUserLogin);
    }

    componentWillReceiveProps(nextProps) {
        const {
            location: { pathname },
        } = this.props;
        const {
            location: { pathname: nextPathName },
        } = nextProps;
        if (pathname !== nextPathName && window) {
            if (this.currentPosition === minimized) {
                this.animateHeader(scrollUpEvent);
                this.currentPosition = maximized;
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onPageScroll, true);
        window.removeEventListener('resize', this.onPageResize, true);
        InWebViewAPI.removeEventListener(POST_EVENTS.SET_APP_DATA, this.setAppUser);
    }

    setAppUser = () => {
        const { getUser } = this.props;

        getUser();
    };

    onPageScroll = () => {
        const { isDesktopUser, isProductListLoading } = this.props;
        const { isTransparent } = this.state;
        const { pageYOffset } = window;
        const scrolledToTop = pageYOffset === 0;
        const isScrollFromWidget = scrolledToTop && this.lastScrollYPosition !== 0;
        const minDelta = 6;
        const headerScrollDistance = 64;
        const ScrollEventStartPoint = window.innerHeight * 0.6;
        const amountScrolled = Math.abs(this.lastScrollYPosition - pageYOffset);
        const isMaximized = this.currentPosition === maximized;

        // Handling of Transparent Header in locations such as Mix Landing Page
        if (pageYOffset > headerScrollDistance && isTransparent) {
            this.setState({ isTransparent: false });
        } else if (pageYOffset < headerScrollDistance && !isTransparent) {
            this.setState({ isTransparent: true });
        }

        if (
            isDesktopUser ||
            (!isScrollFromWidget &&
                (amountScrolled <= minDelta || window.pageYOffset < ScrollEventStartPoint))
        ) {
            return;
        }
        const isScrollDown = pageYOffset > this.lastScrollYPosition;
        this.lastScrollYPosition = pageYOffset;
        this.scrollDirection = isScrollDown ? scrollDownEvent : scrollUpEvent;
        if (scrolledToTop || (isProductListLoading && !isMaximized && !isScrollDown)) {
            this.currentPosition = maximized;
            this.animateHeader(scrollUpEvent);
            this.animateNav(scrollUpEvent);
            return;
        }
        if (
            (this.scrollDirection === scrollDownEvent && this.currentPosition === minimized) ||
            (this.scrollDirection === scrollUpEvent && this.currentPosition === maximized) ||
            pageYOffset <= 0
        ) {
            return;
        }

        switch (this.scrollDirection) {
            case scrollDownEvent:
                this.currentPosition = minimized;
                this.animateHeader(scrollDownEvent);
                this.animateNav(scrollDownEvent);
                break;
            case scrollUpEvent:
                this.currentPosition = maximized;
                this.animateHeader(scrollUpEvent);
                this.animateNav(scrollUpEvent);
                break;
            default:
                break;
        }
    };

    onPageResize = () => {
        const { isDesktopUser } = this.props;
        if (this.currentPosition === minimized && !isDesktopUser) {
            this.animateHeader(scrollUpEvent);
            this.animateNav(scrollUpEvent);
            this.currentPosition = maximized;
        }
    };

    animateHeader = (direction) => {
        if (this.header && !window.ReactNativeWebView) {
            const headerAnimationSettings =
                direction === scrollDownEvent
                    ? {
                          translateY: [0, -HEADER_HEIGHT - 10],
                          easing: [0.4, 0.0, 1, 1],
                      }
                    : {
                          translateY: [-HEADER_HEIGHT - 10, 0],
                          easing: [0.0, 0.0, 0.2, 1],
                      };
            this.headerAnimation = anime({
                targets: this.header,
                duration: 250,
                ...headerAnimationSettings,
            });
        }
    };

    animateNav = (direction) => {
        if (this.mobileNav) {
            const navAnimationSettings =
                direction === scrollDownEvent
                    ? {
                          translateY: [0, BOTTOM_NAV_HEIGHT - 10],
                          easing: [0.4, 0.0, 1, 1],
                      }
                    : {
                          translateY: [BOTTOM_NAV_HEIGHT - 10, 0],
                          easing: [0.0, 0.0, 0.2, 1],
                      };
            this.navAninmation = anime({
                targets: this.mobileNav,
                duration: 250,
                ...navAnimationSettings,
            });
        }
    };

    render() {
        const {
            isLoggedIn,
            loading,
            children,
            location,
            overlayIsOpen,
            isDesktopUser,
            loggedOutBrowsingFlag,
            activeStore,
        } = this.props;
        const { isTransparent } = this.state;
        const { code, activeLanguage } = activeStore || {};

        const inHomePage =
            location.pathname === `/${code}-${activeLanguage}` ||
            location.pathname === `/${code}-${activeLanguage}/`;
        const inMixLandingPage = location.pathname.includes('/mix');
        const inForYouPage = location.pathname.includes('/for-you');
        const inPdpScreen = location.pathname.includes('/products/');
        const loggedOutAppBrowsing = !!window.ReactNativeWebView && loggedOutBrowsingFlag;
        const inNewReferralsLandingPage = !!location.pathname.match(/\/(referrals|referred)/);
        const isRecentlyViewedVisible = !(
            inMixLandingPage ||
            inNewReferralsLandingPage ||
            inForYouPage ||
            inHomePage
        );
        const isHeaderTransparent = isTransparent && !overlayIsOpen && !loading && inMixLandingPage;

        const hideHeader = isNativeFlowActive() || isDiscoveringCard();

        return (
            <LayoutComponentContentWrap>
                <LoadingModal loading={loading} />
                {!hideHeader ? (
                    <Header
                        headerRef={(el) => {
                            this.header = el;
                        }}
                        isLoggedIn={isLoggedIn}
                        isDesktopUser={isDesktopUser}
                        isTransparent={isHeaderTransparent}
                    />
                ) : null}
                <InnerWrap inPdpScreen={inPdpScreen}>
                    <Content>
                        <BaseLayoutOverlaySwitcher location={location} overlays={overlays}>
                            {children}
                            {isRecentlyViewedVisible && (
                                <WidgetGrid verticalSpacerBig>
                                    <GridAligner>
                                        <RecentlyViewSection location={location} />
                                    </GridAligner>
                                </WidgetGrid>
                            )}
                            <FooterWithChecker />
                        </BaseLayoutOverlaySwitcher>
                    </Content>
                </InnerWrap>
                {!isDesktopUser && (isLoggedIn || loggedOutAppBrowsing) && !overlayIsOpen && (
                    <MobileNavigation
                        navigationRef={(el) => {
                            this.mobileNav = el;
                        }}
                        location={location}
                    />
                )}
            </LayoutComponentContentWrap>
        );
    }
}

BaseLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    isLoggedIn: PropTypes.bool.isRequired,
    location: PropTypes.shape({
        href: PropTypes.string,
        origin: PropTypes.string,
        protocol: PropTypes.string,
        host: PropTypes.string,
        hostname: PropTypes.string,
        port: PropTypes.string,
        pathname: PropTypes.string,
        search: PropTypes.string,
        hash: PropTypes.string,
        assign: PropTypes.func,
        replace: PropTypes.func,
        reload: PropTypes.func,
        toString: PropTypes.func,
    }).isRequired,
    isProductListLoading: PropTypes.bool,
    overlayIsOpen: PropTypes.bool.isRequired,
    isDesktopUser: PropTypes.bool,
};

BaseLayout.defaultProps = {
    children: null,
    isDesktopUser: true,
    isProductListLoading: false,
};

export default BaseLayout;

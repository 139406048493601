import React from 'react';
import { IconPropTypes } from 'ggApp/types/propTypes';
import { withTheme } from 'styled-components';

const FacebookIcon = ({ className, size, color, theme }) => {
    const fill = color || theme.colors.Foreground;
    return (
        <svg
            className={className}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4ZM6 2C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V6C22 3.79086 20.2091 2 18 2H6Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9167 10C11.9167 8.34315 13.2598 7 14.9167 7H17V9H14.9167C14.3644 9 13.9167 9.44772 13.9167 10V12H17V14H13.9167V20H11.9167V14H10V12H11.9167V10Z"
                fill={fill}
            />
        </svg>
    );
};

FacebookIcon.propTypes = {
    ...IconPropTypes,
};

FacebookIcon.defaultProps = {
    className: '',
    size: '24px',
};

export default withTheme(FacebookIcon);

/* eslint-disable */
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { storeActive } from 'ggApp/modules/store/selectors';
import { renderNothing, branch } from 'recompose';

import Price from './Price';

export default connect(
    createStructuredSelector({
        activeLanguage: createSelector(
            storeActive,
            ({ activeLanguage }) => activeLanguage,
        ),
    }),
)(Price);

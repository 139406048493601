import { pushDataLayer } from 'ggApp/modules/tracking/actions';
import { ANALYTIC_EVENTS } from 'ggApp/modules/gaAnalytics/constants';
import { SPA_FEATURE_FLAG_KEY } from '../constants';

export const dispatchSpaModeGAEvent = (data) => {
    if (data && data.variantKey && data.variantKey === 'enabled') {
        pushDataLayer({
            event: ANALYTIC_EVENTS.gtmEvent,
            eventCategory: 'internal_test',
            eventAction: SPA_FEATURE_FLAG_KEY,
            eventLabel: 'active',
        });
    }
};

export const dispatchPDPMarketPriceGAEvent = (data) => {
    if (data && data.variantKey) {
        pushDataLayer({
            eventCategory: 'internal_test',
            eventAction: 'retail_price',
            eventLabel: data.variantKey === 'enabled' ? 'active' : 'inactive',
            event: ANALYTIC_EVENTS.gtmEvent,
        });
    }
};

export const dispatchDiscountBannerEvent = (flagValue, place) => {
    if (!flagValue) {
        return;
    }
    if (flagValue === 'legacy' || flagValue === 'disabled') {
        return;
    }
    const eventLabel = `${flagValue}_${place}`;
    pushDataLayer({
        event: ANALYTIC_EVENTS.gtmEvent,
        eventCategory: 'internal_test',
        eventAction: '6_aprilvoucher_test',
        eventLabel,
    });
};

export const dispatchReferralsEvent = ({ label, isLoggedIn }) => {
    pushDataLayer({
        event: isLoggedIn ? 'Logged In referrals' : 'Logged out referrals',
        eventCategory: 'internal_test',
        eventAction: isLoggedIn ? 'Logged In referrals' : 'loggedOut_referrals',
        eventLabel: label,
    });
};

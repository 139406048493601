/* eslint-disable */
import withDeviceInfo from 'ggApp/shared/components/viewport/withDeviceInfo';
import React, { PureComponent } from "react"; // eslint-disable-line
import PropTypes from 'prop-types';

class DisplayIf extends PureComponent {
    static propTypes = {
        mobile: PropTypes.bool,
        tablet: PropTypes.bool,
        desktop: PropTypes.bool,
        largeDesktop: PropTypes.bool,
        widescreen: PropTypes.bool,
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node,
        ]).isRequired,
        userDevice: PropTypes.string,
    };

    static defaultProps = {
        mobile: false,
        tablet: false,
        desktop: false,
        largeDesktop: false,
        widescreen: false,
        userDevice: undefined,
    };

    render() {
        const { userDevice, children } = this.props;
        if (userDevice && this.props[userDevice]) return children;
        return null;
    }
}

export default withDeviceInfo(DisplayIf);

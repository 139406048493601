import {
    OperationVariables,
    QueryTuple,
    useLazyQuery,
    useMutation,
    useQuery,
} from '@apollo/client';
import { Mutation, Query, User } from 'ggApp/types/graphql';
import moment from 'moment';

import { useState } from 'react';
import { ActiveStore } from 'ggApp/modules/store';
import { DEFAULT_TOTAL_NUMBER_OF_REFERRALS } from 'ggApp/shared/screens/GroverCash/constants';
import GET_GUEST_CONNECTIONS from '../queries/guestConnections.graphql';
import GET_CURRENT_CAMPAIGN from '../queries/currentReferralCampaign.graphql';
import CHECK_CODE from '../queries/checkCode.graphql';
import GET_REFERRAL_CODE from '../queries/referralCode.graphql';
import CREATE_REFERRAL_CONNECTION from '../queries/createReferralConnection.graphql';

import { CashAmount, getAmountData } from '../utils/cashAmount';

type ReferralCampaignData = Pick<Query, 'getCurrentReferralCampaign'>;
type CheckCodeData = Pick<Query, 'validateCode'>;
type UserData = Pick<User, 'referralCode' | 'defaultStore'>;
type CreateReferralConnectionData = Pick<Mutation, 'createConnection'>;
type UseCurrentCampaignReturn = {
    hostCashAmount: CashAmount;
    guestCashAmount: CashAmount;
};
type GuestConnectionsData = Pick<Query, 'user'>;
export type UseGamificationGuestConnectionsReturn = {
    count: number;
    total: CashAmount;
    limit: CashAmount;
};

export const useCurrentCampaign = (activeStore: ActiveStore) => {
    const [campaignData, setCampaignData] = useState<UseCurrentCampaignReturn>({
        hostCashAmount: getAmountData(activeStore),
        guestCashAmount: getAmountData(activeStore),
    });
    const result = useQuery<ReferralCampaignData>(GET_CURRENT_CAMPAIGN, {
        fetchPolicy: 'cache-first',
        onCompleted: (data) => {
            setCampaignData({
                hostCashAmount: getAmountData(
                    activeStore,
                    data.getCurrentReferralCampaign?.hostCashAmount,
                ),
                guestCashAmount: getAmountData(
                    activeStore,
                    data.getCurrentReferralCampaign?.guestCashAmount,
                ),
            });
        },
    });
    return { ...result, data: campaignData };
};

export const useLazyCurrentCampaign = (activeStore: ActiveStore) => {
    const [campaignData, setCampaignData] = useState<UseCurrentCampaignReturn>({
        hostCashAmount: getAmountData(activeStore),
        guestCashAmount: getAmountData(activeStore),
    });
    const [loadReferralCampaign, result] = useLazyQuery<ReferralCampaignData>(
        GET_CURRENT_CAMPAIGN,
        {
            fetchPolicy: 'cache-first',
            onCompleted: (data) => {
                setCampaignData({
                    hostCashAmount: getAmountData(
                        activeStore,
                        data.getCurrentReferralCampaign?.hostCashAmount,
                    ),
                    guestCashAmount: getAmountData(
                        activeStore,
                        data.getCurrentReferralCampaign?.guestCashAmount,
                    ),
                });
            },
        },
    );
    return { ...result, data: campaignData, loadReferralCampaign };
};

export const useValidateCode = (code: string) => {
    const result = useQuery<CheckCodeData>(CHECK_CODE, {
        variables: { code },
        skip: !code,
    });
    return { ...result, data: result.data?.validateCode };
};

export const useReferralCode = (): QueryTuple<UserData, OperationVariables> => {
    const result = useLazyQuery(GET_REFERRAL_CODE);
    return [result[0], { ...result[1], data: result[1].data?.user }];
};

export const useReferralConnection = () => {
    return useMutation<CreateReferralConnectionData>(CREATE_REFERRAL_CONNECTION);
};

export const useGamificationGuestConnections = (activeStore: ActiveStore) => {
    const { loading: loadingCampaign, data: campaignData } = useCurrentCampaign(activeStore);
    const { data, ...result } = useQuery<GuestConnectionsData>(GET_GUEST_CONNECTIONS, {
        skip: loadingCampaign,
    });

    const currentMoment = moment();

    const realGCashConnections =
        data?.user?.guestConnections?.filter(
            (c) =>
                c.status === 'ACTIVE' &&
                currentMoment.diff(moment(c.hostEventDispatchedAt), 'days') >= 14,
        ) || [];

    const total = realGCashConnections.reduce(
        (totalAmount, guestConnection) => totalAmount + guestConnection.campaign.hostCashAmount,
        0,
    );

    // TODO: implement proper solution for calculating referrals limit in `referral-service`
    const limit = campaignData.hostCashAmount.inCents * DEFAULT_TOTAL_NUMBER_OF_REFERRALS;

    const guestConnections: UseGamificationGuestConnectionsReturn = {
        count: realGCashConnections.length || 0,
        total: getAmountData(activeStore, total || 0),
        limit: getAmountData(activeStore, limit),
    };

    return { ...result, data: guestConnections };
};

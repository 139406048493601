/* eslint-disable */

import {
  GET_PRODUCT_OFFER,
  GET_PRODUCT_OFFER_SUCCESS,
  GET_PRODUCT_OFFER_ERROR,
} from './../actionTypes';

const defaultState = {
  offers: {},
  lodaing: null,
  error: {},
};
export default function (state = defaultState, action) {
  switch (action.type) {
    case GET_PRODUCT_OFFER_SUCCESS: {
      return {
        ...state,
        lodaing: false,
        offers: {
          ...state.offers,
          [action.data.id]: action.data,
        },
      }
    }

    case GET_PRODUCT_OFFER:
      return {
        ...state,
        lodaing: true,
      };

    case GET_PRODUCT_OFFER_ERROR: {
      return {
        ...state,
        lodaing: false,
        error: action.data,
      }
    }
    default:
      return state;
  }
}
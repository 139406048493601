import styled, { css } from 'styled-components';

import Typography from 'ggApp/shared/components/typography';
import ContentfulResolutionImage from 'ggApp/shared/components/resolutionImage/ContentfulResolutionImage';
import Link from 'ggApp/shared/containers/FrontshopLink';
import { screens } from 'ggApp/utils/mixins/screens';

export const Wrapper = styled.div``;

export const LinkWrapper = styled(Link)`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-decoration: none;
`;

export const ImageWrapper = styled.div`
    height: 160px;
    max-height: 160px;
    @media (${screens.tablet}) and (${screens.desktopMax}) {
        height: 200px;
        max-height: 200px;
    }
`;

export const Image = styled(ContentfulResolutionImage)`
    > img {
        object-fit: cover;
        height: '100%';
    }
`;

export const ContentWrap = styled.div`
    display: flex;
    flex: 1;
    align-items: flex-start;
    background-color: ${({ backgroundColor, theme }) =>
        backgroundColor || `${theme.colors.Background}`};
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 20px 56px 20px;
`;

export const Header = styled.h2`
    ${({ theme }) => css(theme.typography.Subheadline)};
    color: ${({ textColor }) => textColor || '#ffffff'};
`;

export const Body = styled(Typography)`
    margin-top: 16px;
    ${({ theme }) => css(theme.typography.Paragraph)};
    line-height: 24px !important;
    color: ${({ textColor }) => textColor || '#ffffff'};
    * {
        color: ${({ textColor }) => textColor || '#ffffff'};
    }
`;

import { init } from '@devsbb/cs-sdk';
import { useEffectOnce } from 'react-use';

const CustomerSupport = (): null => {
    useEffectOnce(() => {
        init();
    });

    return null;
};

export default CustomerSupport;

import React from 'react';
import PropTypes from 'prop-types';

import * as InWebViewAPI from 'ggApp/modules/inwebview_api';
import CookiesManager from 'ggApp/utils/CookiesManager';
import { DEVICE_NAVIGATION_CLICK } from 'ggApp/routes/_wrap/MobileWrapper/constants';

import Content from './Content';
import Background from './styled';

const TOGGLE_ACCOUNT_MENU = 'toggleAccountMenu';
const CLOSE_ACCOUNT_MENU = 'closeAccountMenu';
const ONE_YEAR_EXPIRATION_TIME_IN_SECONDS = 365 * 24 * 60 * 60;

const COMMON_PATHS = ['/your-tech', '/your-payments', '/your-profile', '/card', '/for-you'];
const SPA_REDIRECTION_PATHS = [...COMMON_PATHS, '/business/your-tech'];
const ACCOUNT_MENU_PATHS = [...COMMON_PATHS, '/referrals', '/invite-friend', '/favorites'];
const WITHOUT_REDIRECTUS_PATHS = ['auth', '/auth'];

class MobileNavigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldShowMenu: false,
        };
    }

    componentDidMount() {
        const {
            storeActive: { activeLanguage },
        } = this.props;

        const cookiesManager = new CookiesManager();
        cookiesManager.set('locale', activeLanguage, {
            maxAge: ONE_YEAR_EXPIRATION_TIME_IN_SECONDS,
        });
    }

    toggleMenu = () => {
        this.setState((prevState) => ({
            shouldShowMenu: !prevState.shouldShowMenu,
        }));
    };

    closeMenu = () => {
        this.setState({ shouldShowMenu: false });
    };

    componentWillMount = () => {
        InWebViewAPI.addEventListener(TOGGLE_ACCOUNT_MENU, this.toggleMenu);
        InWebViewAPI.addEventListener(CLOSE_ACCOUNT_MENU, this.closeMenu);
        InWebViewAPI.addEventListener(DEVICE_NAVIGATION_CLICK, this.handleAppNavigation);
    };

    componentWillUnmount = () => {
        InWebViewAPI.removeEventListener(TOGGLE_ACCOUNT_MENU, this.toggleMenu);
        InWebViewAPI.removeEventListener(CLOSE_ACCOUNT_MENU, this.closeMenu);
        InWebViewAPI.removeEventListener(DEVICE_NAVIGATION_CLICK, this.handleAppNavigation);
    };

    pathsMapper = ({ code, path, activeLanguage }) => {
        const supportedCatalogPaths = [
            '/card',
            '/for-you',
            '/favorites',
            '/invite-friend',
            '/referrals',
            '/g-about',
            '/g-brand',
            '/g-collection',
            '/g-explore',
            '/g-kaufen',
            '/g-mieten',
            '/g-product',
            '/g-review',
            '/g-stories',
        ];
        const supportedSpaPaths = ['/your-tech', '/your-payments', '/your-profile'];

        if (supportedCatalogPaths.some((subroute) => path.includes(subroute))) {
            return `/${code}-${activeLanguage}${path}`;
        }

        if (supportedSpaPaths.some((subroute) => path.includes(subroute))) {
            return `/${code}${path}`;
        }

        return '';
    };

    handleAppNavigation = ({ path }) => {
        const {
            routerActions,
            location: { pathname },
            storeActive: { activeLanguage, code },
        } = this.props;

        const isSPARedirectionPath = SPA_REDIRECTION_PATHS.some((redirectionPath) =>
            pathname.includes(redirectionPath),
        );
        const isAccountMenuPath = ACCOUNT_MENU_PATHS.some(
            (accountMenuPath) => path === accountMenuPath,
        );
        const isRedirectusAllowed = !WITHOUT_REDIRECTUS_PATHS.some(
            (redirectusPath) => path === redirectusPath,
        );

        if (isRedirectusAllowed && (isSPARedirectionPath || isAccountMenuPath)) {
            const nextRoutePath = this.pathsMapper({ code, path, activeLanguage });

            return routerActions.redirectus(nextRoutePath, true);
        }
        return routerActions.mainTransition(path, true);
    };

    render() {
        const {
            className,
            location: { pathname = '' },
            navigationRef,
        } = this.props;
        const { shouldShowMenu } = this.state;

        if (/products/.test(pathname)) return null;
        return (
            <Background shouldShow={shouldShowMenu}>
                <Content
                    className={className}
                    innerRef={navigationRef}
                    shouldShowMenu={shouldShowMenu}
                    closeMenu={this.closeMenu}
                    toggleMenu={this.toggleMenu}
                />
            </Background>
        );
    }
}

MobileNavigation.propTypes = {
    className: PropTypes.string,
    navigationRef: PropTypes.func,
    location: PropTypes.shape({
        href: PropTypes.string,
        origin: PropTypes.string,
        protocol: PropTypes.string,
        host: PropTypes.string,
        hostname: PropTypes.string,
        port: PropTypes.string,
        pathname: PropTypes.string,
        search: PropTypes.string,
        hash: PropTypes.string,
        assign: PropTypes.func,
        replace: PropTypes.func,
        reload: PropTypes.func,
        toString: PropTypes.func,
    }),
    storeActive: PropTypes.shape({
        activeLanguage: PropTypes.string,
    }),
};

MobileNavigation.defaultProps = {
    className: '',
    navigationRef: () => {},
    location: {},
    storeActive: {},
};

export default MobileNavigation;

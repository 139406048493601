/* eslint-disable */
import { combineReducers, } from 'redux';

import fetching from './fetching';

import main from './main';
import creditCard from './creditCard';
import paypal from './paypal';
import authToken from './authToken';

export default combineReducers({
  fetching,
  main,
  creditCard,
  paypal,
  authToken,
});
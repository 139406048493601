/* eslint-disable */
const defaultState = null;

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'GET_ORDER':
    case 'UPDATE_ORDER':
    case 'COMPLETE_ORDER':
      return true;

    case 'GET_ORDER_SUCCESS':
    case 'GET_ORDER_FAILURE':
    case 'UPDATE_ORDER_SUCCESS':
    case 'UPDATE_ORDER_FAILURE':
    case 'COMPLETE_ORDER_FAILURE':
      return false;

    default:
      return state;
  }
}

import { Machine } from 'xstate';

import stepNames from 'ggApp/modules/newCheckout/constants/stepNames';

import { CheckoutMachine } from './services/integration';
import { createConfig } from './services';
import { MachineConfig } from './types';

interface MachineCreation extends CheckoutMachine {
    options: MachineConfig;
}

export const createNewCheckoutMachine = ({
    cart = {},
    orderMode,
    options,
    activeStore,
    user,
    redirectionFlow,
    newCheckoutFlow,
    newCheckoutFlag,
    customizeColorFlag,
    customizePlanFlag,
    linkProductsInCartFlag,
}: MachineCreation): unknown => {
    const defaults: MachineConfig = options || createConfig(orderMode, activeStore.code);
    return Machine<CheckoutMachine>(
        {
            id: 'checkout',
            initial: 'idle',
            context: {
                cart,
                orderMode,
                activeStore,
                user,
                error: false,
                success: false,
                cartLoaded: false,
                addressError: undefined,
                phoneVerificationConfirmError: undefined,
                phoneVerificationPending: {},
                voucherError: undefined,
                unavailableSku: [],
                redirectionFlow,
                isCheckoutActive: false,
                steps: [],
                isOverlayActive: false,
                userDataFetched: false,
                newCheckoutFlow,
                userAddresses: [],
                userPayments: [],
                hasPrefilledOrder: false,
                newCheckoutFlag,
                customizeColorFlag,
                customizePlanFlag,
                formSubmitting: false,
                previewVoucher: {},
                linkProductsInCartFlag,
            },
            on: {
                SUBMIT: {
                    target: 'next',
                    actions: ['submit'],
                },
                SET_USER: {
                    target: 'userData',
                    actions: ['updateUser'],
                },
                UPDATE_ACTIVE_STORE: {
                    actions: ['updateStore'],
                },
                UPDATE_FLAGS: {
                    actions: ['updateFlags'],
                },
                UPDATE_CHECKOUT_FLOW: {
                    actions: ['updateCheckoutFlow'],
                },
                ADD_LINE_ITEM: {
                    target: 'addItem',
                },
                UPDATE_LINE_ITEM: {
                    target: 'updateItem',
                },
                REMOVE_LINE_ITEM: {
                    target: 'removeItem',
                },
                REMOVE_LINE_ITEMS: {
                    target: 'removeItems',
                },
                SET_VOUCHER_ERROR: {
                    actions: ['voucherError'],
                },
                SET_ADDRESS_ERROR: {
                    actions: ['addressError'],
                },
                RESET_FROM_REDIRECT: {
                    actions: ['resetFromRedirect'],
                },
                BACK_TO_ORDER_REVIEW: stepNames.ORDER_SUMMARY,
                UPDATE_OVERLAY_STATE: {
                    actions: ['updateOverlayState'],
                },
                UPDATE_SHOULD_FETCH_USER_DATA: {
                    actions: ['updateShouldFetchUserData'],
                },
                STOP_CHECKOUT: {
                    target: 'idle',
                    actions: ['disableCheckout'],
                },
                START_CHECKOUT: {
                    actions: ['resetSteps', 'enableCheckout'],
                },
                RESET_CART: {
                    actions: ['resetCart'],
                },
                CREATE: {
                    target: 'create',
                },
                hasPrefilledOrder: {
                    actions: ['hasPrefilledOrder'],
                },
                SET_PREVIEW_VOUCHER: {
                    actions: ['setPreviewVoucher'],
                },
            },
            states: {
                next: {
                    id: 'next',
                    always: [
                        {
                            target: 'loadingOrder',
                            cond: 'canFetchOrder',
                        },
                        {
                            target: 'idle',
                            cond: 'isEmptyOrGuest',
                        },
                        {
                            target: 'userData',
                            cond: 'shouldFetchUserData',
                        },
                        {
                            target: 'prefillOrder',
                            cond: 'canPrefillOrderData',
                        },
                        {
                            target: stepNames.PAYMENT_METHODS,
                            cond: 'returningFromRedirect',
                        },
                        {
                            target: 'idle',
                            cond: 'isCheckoutInactive',
                        },
                        {
                            target: stepNames.ORDER_SUMMARY,
                            cond: 'readyForReview',
                        },
                        {
                            target: stepNames.PAYMENT_METHODS,
                            cond: 'emptyPaymentMethod',
                        },
                        {
                            target: stepNames.HOME_ADDRESS,
                            cond: 'emptyHomeAddress',
                        },
                        {
                            target: stepNames.SHIPPING_ADDRESS,
                        },
                    ],
                },
                idle: {
                    id: 'idle',
                    on: {
                        CREATE: {
                            target: 'create',
                        },
                        FETCH_ORDER: {
                            target: 'loadingOrder',
                        },
                        START_CHECKOUT: {
                            target: '#next',
                            actions: ['resetSteps', 'enableCheckout'],
                        },
                    },
                },
                loadingOrder: {
                    id: 'loadingOrder',
                    invoke: {
                        src: 'getOrder',
                        onDone: {
                            target: '#next',
                            actions: ['updateOrder', 'success'],
                        },
                        onError: {
                            target: '#idle',
                            actions: ['error', 'clearCookies', 'resetCart'],
                        },
                    },
                },
                userData: {
                    exit: ['updatedUserData'],
                    initial: 'loadOrder',
                    states: {
                        loadOrder: {
                            invoke: {
                                src: 'getOrder',
                                onDone: {
                                    target: 'fetchAddresses',
                                    actions: ['updateOrder'],
                                },
                                onError: {
                                    target: 'fetchAddresses',
                                    actions: ['error', 'clearCookies', 'resetCart'],
                                },
                            },
                        },
                        fetchAddresses: {
                            invoke: {
                                src: 'getUserAddresses',
                                onDone: {
                                    target: 'fetchPaymentMethods',
                                    actions: ['updateUserAddresses'],
                                },
                                onError: {
                                    target: 'fetchPaymentMethods',
                                    actions: ['error'],
                                },
                            },
                        },
                        fetchPaymentMethods: {
                            invoke: {
                                src: 'getUserPayments',
                                onDone: {
                                    target: '#next',
                                    actions: ['updateUserPayments'],
                                },
                                onError: {
                                    target: '#next',
                                    actions: ['error'],
                                },
                            },
                        },
                    },
                },
                prefillOrder: {
                    initial: 'prefillAddresses',
                    exit: 'hasPrefilledOrder',
                    states: {
                        prefillAddresses: {
                            entry: 'prefillCartAddress',
                            invoke: {
                                src: 'updateAddressesFromContext',
                                onDone: {
                                    target: 'prefillPaymentMethod',
                                },
                                onError: {
                                    target: 'prefillPaymentMethod',
                                },
                            },
                        },
                        prefillPaymentMethod: {
                            entry: 'prefillCartPayment',
                            invoke: {
                                src: 'updatePaymentFromContext',
                                onDone: {
                                    target: '#next',
                                },
                                onError: {
                                    target: '#next',
                                },
                            },
                        },
                    },
                },
                fetchPayments: {
                    id: 'fetchPayments',
                    invoke: {
                        src: 'getUserPayments',
                        onDone: {
                            target: '#next',
                            actions: ['updateUserPayments'],
                        },
                        onError: {
                            target: '#next',
                        },
                    },
                },
                create: {
                    invoke: {
                        id: 'createOrder',
                        src: 'createOrder',
                        onDone: {
                            target: '#next',
                            actions: ['updateOrder', 'registerBasketUpdate'],
                        },
                        onError: {
                            target: '#idle',
                            actions: ['error'],
                        },
                    },
                },
                addItem: {
                    invoke: {
                        src: 'addLineItem',
                        onDone: {
                            target: '#next',
                            actions: ['updateOrder', 'registerBasketUpdate'],
                        },
                        onError: [
                            {
                                target: '#loadingOrder',
                                cond: 'errorBased4thItem',
                                actions: ['error', 'log'],
                            },
                            {
                                target: '#idle',
                                actions: ['error', 'log'],
                            },
                        ],
                    },
                },
                updateItem: {
                    entry: ['registerBasketUpdate'],
                    invoke: {
                        src: 'updateLineItem',
                        onDone: {
                            target: '#next',
                            actions: ['updateOrder'],
                        },
                        onError: {
                            target: '#idle',
                            actions: ['error', 'log'],
                        },
                    },
                },
                removeItem: {
                    entry: ['registerBasketUpdate'],
                    invoke: {
                        src: 'removeLineItem',
                        onDone: {
                            target: '#next',
                            actions: ['updateOrder'],
                        },
                        onError: {
                            target: '#idle',
                            actions: ['error', 'log'],
                        },
                    },
                },
                removeItems: {
                    invoke: {
                        src: 'removeLineItems',
                        onDone: {
                            target: '#next',
                            actions: ['updateOrder'],
                        },
                        onError: {
                            target: '#review',
                            actions: ['error', 'log'],
                        },
                    },
                },
                [stepNames.SHIPPING_ADDRESS]: {
                    entry: ['onEnter'],
                    exit: ['updateSteps', 'onExit'],
                    initial: 'idle',
                    id: 'shippingAddress',
                    states: {
                        idle: {
                            on: {
                                EDIT: 'form',
                                CHANGE_ADDRESS: 'changing',
                            },
                        },
                        form: {
                            initial: 'idle',
                            id: 'shippingAddress.form',
                            on: {
                                BACK: 'idle',
                                UPDATE: '.update',
                                CREATE: '.create',
                            },
                            states: {
                                idle: {},
                                update: {
                                    entry: 'setFormSubmitting',
                                    invoke: {
                                        id: 'shippingAddressEdit',
                                        src: 'editAddress',
                                        onDone: {
                                            target: '#shippingAddressUpdate',
                                            actions: ['updateUserAddresses', 'clearFormSubmitting'],
                                        },
                                        onError: {
                                            target: 'idle',
                                            actions: ['addressError', 'clearFormSubmitting'],
                                        },
                                    },
                                },
                                create: {
                                    entry: ['setFormSubmitting'],
                                    invoke: {
                                        id: 'shippingAddressAdd',
                                        src: 'addAddress',
                                        onDone: {
                                            target: '#shippingAddressUpdate',
                                            actions: ['updateUserAddresses', 'clearFormSubmitting'],
                                        },
                                        onError: [
                                            {
                                                target: '#phoneVerification',
                                                cond: 'twoFaRequired',
                                                actions: [
                                                    'setPhoneVerificationPending',
                                                    'setPhoneVerificationOrigin',
                                                    'clearFormSubmitting',
                                                ],
                                            },
                                            {
                                                target: 'idle',
                                                actions: ['addressError', 'clearFormSubmitting'],
                                            },
                                        ],
                                    },
                                },
                            },
                        },
                        changing: {
                            id: 'shippingAddressUpdate',
                            type: 'parallel',
                            states: {
                                user: {
                                    initial: 'pending',
                                    states: {
                                        pending: {
                                            invoke: {
                                                src: 'userAddressUpdateShipping',
                                                onDone: {
                                                    target: 'success',
                                                    actions: ['updateUser'],
                                                },
                                                onError: 'success',
                                            },
                                        },
                                        success: { type: 'final' },
                                    },
                                },
                                order: {
                                    initial: 'pending',
                                    states: {
                                        pending: {
                                            invoke: {
                                                src: 'updateShippingAddress',
                                                onDone: {
                                                    target: 'success',
                                                    actions: ['updateOrder'],
                                                },
                                                onError: 'success',
                                            },
                                        },
                                        success: { type: 'final' },
                                    },
                                },
                            },
                            onDone: {
                                target: '#next',
                                actions: ['prefillCartAddress'],
                            },
                        },
                    },
                },
                [stepNames.PHONE_VERIFICATION]: {
                    initial: 'idle',
                    id: 'phoneVerification',
                    entry: ['onEnter'],
                    exit: ['updateSteps', 'onExit'],
                    states: {
                        idle: {
                            on: {
                                CONFIRM: 'confirm',
                                HELP: '#2faHelp',
                            },
                        },
                        confirm: {
                            entry: ['setFormSubmitting'],
                            invoke: [
                                {
                                    src: 'addAddress',
                                    onDone: [
                                        {
                                            target: '#homeAddressUpdate',
                                            cond: 'isHomeAddressUpdate',
                                            actions: [
                                                'updateUserAddresses',
                                                'clearPhoneVerificationPending',
                                                'clearFormSubmitting',
                                            ],
                                        },
                                        {
                                            target: '#shippingAddressUpdate',
                                            cond: 'isShippingAddressUpdate',
                                            actions: [
                                                'updateUserAddresses',
                                                'clearPhoneVerificationPending',
                                                'clearFormSubmitting',
                                            ],
                                        },
                                    ],
                                    onError: {
                                        target: 'idle',
                                        actions: [
                                            'phoneVerificationConfirmError',
                                            'clearFormSubmitting',
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                },
                [stepNames.TWOFA_HELP]: {
                    initial: 'idle',
                    id: '2faHelp',
                    states: {
                        idle: {
                            on: {
                                RESEND_CODE: 'resendCode',
                            },
                        },
                        resendCode: {
                            invoke: {
                                src: 'addAddress',
                                // note: there is intentionally no onDone handler here, as invoking addAddress again should result in a 422 and resending of the 2fa code
                                onError: [
                                    {
                                        target: '#phoneVerification',
                                        cond: 'twoFaRequired',
                                        actions: ['setPhoneVerificationPending'],
                                    },
                                ],
                            },
                        },
                    },
                    on: {
                        BACK: {
                            target: '#phoneVerification',
                        },
                    },
                },
                [stepNames.HOME_ADDRESS]: {
                    entry: ['onEnter'],
                    exit: ['updateSteps', 'onExit'],
                    initial: 'idle',
                    states: {
                        idle: {
                            on: {
                                EDIT: 'form',
                                CHANGE_ADDRESS: 'changing',
                            },
                        },
                        form: {
                            id: 'homeAddress.form',
                            initial: 'idle',
                            on: {
                                UPDATE: '.update',
                                CREATE: '.create',
                                BACK: 'idle',
                            },
                            states: {
                                idle: {},
                                update: {
                                    entry: ['setFormSubmitting'],
                                    invoke: {
                                        id: 'homeAddressEdit',
                                        src: 'editAddress',
                                        onDone: {
                                            target: '#homeAddressUpdate',
                                            actions: ['updateUserAddresses', 'clearFormSubmitting'],
                                        },
                                        onError: {
                                            target: 'idle',
                                            actions: ['addressError', 'clearFormSubmitting'],
                                        },
                                    },
                                },
                                create: {
                                    entry: ['setFormSubmitting'],
                                    invoke: {
                                        id: 'homeAddressAdd',
                                        src: 'addAddress',
                                        onDone: {
                                            target: '#homeAddressUpdate',
                                            actions: ['updateUserAddresses', 'clearFormSubmitting'],
                                        },
                                        onError: [
                                            {
                                                target: '#phoneVerification',
                                                cond: 'twoFaRequired',
                                                actions: [
                                                    'setPhoneVerificationPending',
                                                    'setPhoneVerificationOrigin',
                                                    'clearFormSubmitting',
                                                ],
                                            },
                                            {
                                                target: 'idle',
                                                actions: ['addressError', 'clearFormSubmitting'],
                                            },
                                        ],
                                    },
                                },
                            },
                        },
                        changing: {
                            id: 'homeAddressUpdate',
                            type: 'parallel',
                            states: {
                                user: {
                                    initial: 'pending',
                                    states: {
                                        pending: {
                                            invoke: {
                                                src: 'userAddressUpdateHome',
                                                onDone: {
                                                    target: 'success',
                                                    actions: ['updateUser'],
                                                },
                                                onError: 'success',
                                            },
                                        },
                                        success: { type: 'final' },
                                    },
                                },
                                order: {
                                    initial: 'pending',
                                    states: {
                                        pending: {
                                            invoke: {
                                                src: 'updateHomeAddress',
                                                onDone: {
                                                    target: 'success',
                                                    actions: ['updateOrder'],
                                                },
                                                onError: 'success',
                                            },
                                        },
                                        success: { type: 'final' },
                                    },
                                },
                            },
                            onDone: {
                                target: '#next',
                                actions: ['prefillCartAddress'],
                            },
                        },
                    },
                },
                [stepNames.PAYMENT_METHODS]: {
                    entry: ['onEnter'],
                    exit: ['updateSteps', 'onExit'],
                    on: {
                        BACK: {
                            target: stepNames.ORDER_SUMMARY,
                        },
                    },
                    initial: 'idle',
                    states: {
                        idle: {
                            on: {
                                CHANGE: 'changing',
                                CREATE: 'form',
                                ADD: 'adding',
                                ADDLEGACY: 'addingLegacy',
                            },
                        },
                        form: {
                            id: 'paymentMethod.form',
                            on: {
                                ADD: 'adding',
                                ADDLEGACY: 'addingLegacy',
                                BACK: 'idle',
                            },
                        },
                        adding: {
                            invoke: {
                                id: 'paymentMethodUpdate',
                                src: 'updatePaymentMethod',
                                onDone: {
                                    target: '#fetchPayments',
                                    actions: ['updateOrder'],
                                },
                            },
                        },
                        addingLegacy: {
                            invoke: {
                                src: 'getOrder',
                                onDone: {
                                    target: '#fetchPayments',
                                    actions: ['updateOrder', 'success'],
                                },
                                onError: {
                                    target: '#idle',
                                    actions: ['error', 'clearCookies', 'resetCart'],
                                },
                            },
                        },
                        changing: {
                            invoke: {
                                src: 'updatePaymentMethod',
                                onDone: {
                                    target: '#next',
                                    actions: ['updateOrder'],
                                },
                                onError: {
                                    actions: ['error', 'log'],
                                },
                            },
                        },
                    },
                },
                [stepNames.ORDER_SUMMARY]: {
                    entry: ['onEnter'],
                    exit: ['updateSteps', 'onExit'],
                    id: 'review',
                    initial: 'idle',
                    on: {
                        SUBMIT_ORDER: '.submit',
                        SUBMIT_AVAILABLE_ORDER: '.submitAvailable',
                        SUBMIT_VOUCHER: '.submitVoucher',
                        SUBMIT_VOUCHER_WITH_AVAILABLEP_RODUCTS:
                            '.submitVoucherWithAvailableProducts',
                        RESET_VOUCHER: '.resetVoucher',
                        UPDATE_LINE_ITEM: '.updateLineItem',
                        EDIT_SHIPPING_ADDRESS: [
                            {
                                target: 'shippingAddress',
                                actions: ['setEditMode'],
                            },
                        ],
                        EDIT_HOME_ADDRESS: [
                            {
                                target: 'homeAddress',
                                actions: ['setEditMode'],
                            },
                        ],
                        EDIT_PAYMENT_METHOD: [
                            {
                                target: 'paymentMethod',
                                actions: ['setEditMode'],
                            },
                        ],
                    },
                    states: {
                        idle: {},
                        updateLineItem: {
                            entry: ['registerBasketUpdate'],
                            invoke: {
                                id: 'updateLineItem',
                                src: 'updateLineItem',
                                onDone: {
                                    target: '#review',
                                    actions: ['updateOrder'],
                                },
                            },
                        },
                        submitVoucher: {
                            invoke: {
                                id: 'submitVoucher',
                                src: 'submitVoucher',
                                onDone: {
                                    target: '#review',
                                    actions: ['updateOrder'],
                                },
                                onError: {
                                    target: '#review',
                                    actions: ['error', 'voucherError'],
                                },
                            },
                        },
                        submitVoucherWithAvailableProducts: {
                            invoke: {
                                id: 'submitVoucherWithAvailableProducts',
                                src: 'submitVoucherWithAvailableProducts',
                                onDone: {
                                    target: '#review',
                                    actions: ['updateOrder'],
                                },
                                onError: {
                                    target: '#review',
                                    actions: ['error', 'voucherError'],
                                },
                            },
                        },
                        resetVoucher: {
                            invoke: {
                                id: 'resetVoucher',
                                src: 'resetVoucher',
                                onDone: {
                                    target: '#review',
                                    actions: ['updateOrder'],
                                },
                                onError: {
                                    target: '#review',
                                    actions: ['error'],
                                },
                            },
                        },
                        submit: {
                            invoke: {
                                id: 'submit',
                                src: 'submitOrder',
                                onDone: {
                                    target: '#confirmation',
                                    actions: ['clearCookies', 'registerBasketUpdate'],
                                },
                                onError: [
                                    {
                                        target: 'resetVoucher',
                                        actions: ['error', 'getUnavailableSku'],
                                        cond: (context, event) =>
                                            typeof event?.data?.response?.data?.base === 'object' &&
                                            event?.data?.response?.data?.base?.length > 0,
                                    },
                                    {
                                        target: '#orderError',
                                        actions: ['error'],
                                    },
                                ],
                            },
                        },
                        submitAvailable: {
                            invoke: {
                                id: 'submitAvailable',
                                src: 'removeLineItems',
                                onDone: {
                                    target: 'submit',
                                    actions: ['updateOrder'],
                                },
                                onError: {
                                    target: '#orderError',
                                    actions: ['error', 'getUnavailableSku'],
                                },
                            },
                        },
                    },
                },
                [stepNames.ORDER_ERROR]: {
                    id: 'orderError',
                    on: { BACK: 'review' },
                    entry: ['onEnter'],
                    exit: ['updateSteps', 'onExit'],
                },
                [stepNames.ORDER_CONFIRMATION]: {
                    id: 'confirmation',
                    entry: ['onEnter'],
                    exit: ['updateSteps', 'onExit', 'resetCart'],
                    on: { STEP_CHANGE: 'idle', STEP_BACK: '#review' },
                    onDone: {
                        target: 'success',
                    },
                },
                success: { id: 'success' },
            },
        },
        defaults,
    );
};

/* eslint-disable */
export const MODULE_NAME = 'navigator';
export const NAVIGATOR_OPEN_CLASS = 'ggNavigatorOpen'
export const navigatorCheckoutPages = [
  {
    name: 'address',
    title: 'NAVIGATOR_DELIVERY_ADDRESS_TITLE',
  },
  {
    name: 'payment',
    title: 'NAVIGATOR_PAYMENT_METHOD',
  },
  {
    name: 'overview',
    title: 'NAVIGATOR_SUMMARY_TITLE',
  },
];
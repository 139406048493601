/* eslint-disable */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    registerClickEvent,
    registerProductImpression,
} from 'ggApp/modules/gaAnalytics/actions/index';
import { compose, hoistStatics, withHandlers } from 'recompose';
import memoize from 'fast-memoize';

const buildCallback = (listName, selector, callback, positionStartCount = 0) => memoize(position => () => callback(listName, positionStartCount + position, selector));

const buildCallbacksFromConfig = ([listName, selector]) => ({
    _registerProductImpression,
    _registerClickEvent,
}) => startPosition => [_registerProductImpression, _registerClickEvent].map(callback => buildCallback(listName, selector, callback, startPosition));


export const withListEventHandlers = (config) => {
    const withHandlersMethods = {};
    Object.keys(config).map(
        key => (withHandlersMethods[key] = buildCallbacksFromConfig(config[key])),
    );
    return hoistStatics(
        compose(
            connect(
                null,
                dispatch => bindActionCreators(
                    {
                        _registerProductImpression: registerProductImpression,
                        _registerClickEvent: registerClickEvent,
                    },
                    dispatch,
                ),
            ),
            withHandlers(withHandlersMethods),
        ),
    );
};

export const extractCallbacksFromProps = (
    [intersectionCallback, onClickCallback] = [undefined, undefined],
    index,
) => ({
    intersectionCallback: intersectionCallback && intersectionCallback(index),
    onClickCallback: onClickCallback && onClickCallback(index),
});

import { ADD_TO_CART_SUCCESS } from 'ggApp/modules/order/actionTypes';
import { registerAddToCart } from 'ggApp/modules/gaAnalytics/actions';
import { orderActive as orderActiveSelector } from 'ggApp/modules/order/selectors';
import { criteoCartCheckoutUpdate } from 'ggApp/modules/criteo/actions';
import { user as userSelector } from 'ggApp/modules/auth/selectors';

const addToCartEventMiddleware = (store) => (next) => (action) => {
    if (action.type === ADD_TO_CART_SUCCESS) {
        const user = userSelector(store.getState());
        const orderFromState = orderActiveSelector(store.getState());

        const activeOrder = action.cart || orderFromState;

        store.dispatch(registerAddToCart(action.productId, activeOrder));

        if (!action.cart) {
            store.dispatch(criteoCartCheckoutUpdate(user, orderFromState));
        }
    }
    next(action);
};

export default addToCartEventMiddleware;

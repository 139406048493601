import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Background, BackgroundClickTrap } from './styles';

/**
 * ModalBackground
 * This component represents background of the modal.
 * It prevents body scrolling when mounted.
 * @class ModalBackground
 * @extends {PureComponent}
 */
class ModalBackground extends PureComponent {
    handleKeyDown = () => {
        /**
         * This is a trick for avoid dumb eslint config.
         * This isn't necessary to complete accessibility
         */
    };

    /**
     * This handler calls `onBackgroundClick` only if click was raised on this component itself
     * @param {SyntheticEvent} event
     * @memberof ModalBackground
     */
    handleClick = (event) => {
        const { onBackgroundClick } = this.props;

        if (onBackgroundClick) {
            onBackgroundClick(event);
        }
    };

    preventPropagation = (e) => {
        // to stop propagation of events from portal
        // https://github.com/facebook/react/issues/11387
        if (e) {
            e.stopPropagation();
        }
    };

    render() {
        const {
            animationDuration,
            animationState,
            className,
            withBackgroundColor,
            children,
        } = this.props;
        return (
            <Background
                animationState={animationState}
                animationDuration={animationDuration}
                className={className}
                onClick={this.preventPropagation}
                onMouseEnter={this.preventPropagation}
                onMouseLeave={this.preventPropagation}
                withBackgroundColor={withBackgroundColor}
            >
                <BackgroundClickTrap
                    onClick={this.handleClick}
                    role="button"
                    tabIndex="-1"
                    onKeyDown={this.handleKeyDownBackground}
                    data-testid="modal-clicktrap"
                />
                {children}
            </Background>
        );
    }
}

ModalBackground.propTypes = {
    animationDuration: PropTypes.number,
    animationState: PropTypes.string,
    onBackgroundClick: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node,
    withBackgroundColor: PropTypes.bool,
};
ModalBackground.defaultProps = {
    onBackgroundClick: () => {},
    animationDuration: 0,
    animationState: '',
    className: '',
    children: null,
    withBackgroundColor: true,
};

export default ModalBackground;

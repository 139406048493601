import { compose, withProps, toClass } from 'recompose';

import {
    registerTeaserClickEvent,
    registerTeaserImpressionEvent,
} from 'ggApp/modules/gaAnalytics/actions';
import { WIDGET_NAMES } from 'ggApp/modules/gaAnalytics/constants';
import withIntersectionObserver from 'ggApp/modules/tracking/hocs/withIntersectionObserverV2';
import UspCardsWidget from './UspCardsWidget';

const UspCardsWidgetWithEvents = compose(
    withProps(({ title, cta: { url } }) => ({
        onEnterCallback: registerTeaserImpressionEvent({
            widgetName: WIDGET_NAMES.howItWorks,
            title,
            url,
        }),
        onClickCTA: registerTeaserClickEvent({
            widgetName: WIDGET_NAMES.howItWorks,
            title,
            url,
        }),
        threshold: 0.8,
    })),
    withIntersectionObserver,
    toClass, // for IntersectionObserver component,
)(UspCardsWidget);

export { UspCardsWidget, UspCardsWidgetWithEvents };

import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router';
import FrontshopLink from 'ggApp/shared/containers/FrontshopLink';
import { getStoreConfig } from 'staticStoreConfig';

const NavigationLink = ({
    className,
    params,
    to,
    children,
    activeStore: { code, activeLanguage },
    type,
    onClick,
    external,
    target,
    id,
}) => {
    const { store, language } = params || {};
    const fallbackStoreCode = getStoreConfig(store)?.storeCode || 'de';
    const isCatalogMode = Boolean(language);
    let path;
    switch (type) {
        case 'catalog-link':
            path = `/${code}-${activeLanguage}${to}`;
            break;
        case 'non-catalog-link':
            path = `/${code}${to}`;
            break;
        case 'countrySpecificLink':
            path = `${to}`;
            break;
        case 'groverWork':
            path = `${to}`;
            break;
        case 'yourTech':
            path = `/${code}${to}`;
            break;
        default:
            path = `/${code || fallbackStoreCode}-${activeLanguage}${to}`;
    }

    if (
        type === 'catalog-link' ||
        type === 'countrySpecificLink' ||
        type === 'groverWork' ||
        type === 'yourTech'
    ) {
        return (
            <FrontshopLink
                className={className}
                to={path}
                external={!isCatalogMode || external}
                onClick={onClick}
                target={target}
                id={id}
            >
                {children}
            </FrontshopLink>
        );
    }

    if (isCatalogMode) {
        return (
            <FrontshopLink
                className={className}
                to={path}
                external={isCatalogMode}
                onClick={onClick}
                target={target}
                id={id}
            >
                {children}
            </FrontshopLink>
        );
    }

    return code ? (
        <Link className={className} id={id} to={path} onClick={onClick} target={target}>
            {children}
        </Link>
    ) : (
        // handle links outside of store, e.g. 404
        <a className={className} id={id} href={path} onClick={onClick}>
            {children}
        </a>
    );
};

NavigationLink.propTypes = {
    className: PropTypes.string,
    id: null,
    activeStore: PropTypes.shape({
        code: PropTypes.string,
        activeLanguage: PropTypes.string,
    }),
    params: PropTypes.shape({}),
    children: PropTypes.node,
    type: PropTypes.string,
    to: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    external: PropTypes.bool,
    target: PropTypes.string,
};

NavigationLink.defaultProps = {
    className: '',
    id: null,
    activeStore: {},
    params: {},
    children: null,
    type: '',
    onClick: () => {},
    external: false,
    target: undefined,
};

export default NavigationLink;

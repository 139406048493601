import React from 'react';
import { Svg, Path, Rect } from 'react-native-svg';
import { IconPropTypes } from 'ggApp/types/propTypes';

const PayPal = ({ className, width, height, border }) => (
    <Svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 36 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <Rect width="36" height="24" rx="4" fill="white" />
        {border && (
            <Rect
                x="0.5"
                y="0.5"
                width="35"
                height="23"
                rx="3.5"
                stroke="#333333"
                strokeOpacity="0.07"
            />
        )}
        <Path
            d="M9.42 9.01512C9.22161 8.82282 8.98388 8.67585 8.72318 8.58435C8.46249 8.49285 8.18504 8.459 7.91 8.48512H5.91C5.8401 8.48648 5.77288 8.5122 5.71993 8.55784C5.66698 8.60349 5.63163 8.66619 5.62 8.73512L4.73 14.0151C4.72717 14.0404 4.72973 14.066 4.7375 14.0902C4.74527 14.1144 4.75808 14.1366 4.77509 14.1555C4.79209 14.1744 4.81291 14.1895 4.83616 14.1998C4.85941 14.2101 4.88458 14.2153 4.91 14.2151H5.91C5.96982 14.2125 6.02751 14.1921 6.07562 14.1565C6.12374 14.1208 6.16009 14.0716 6.18 14.0151L6.4 12.5751C6.40743 12.5062 6.44017 12.4424 6.4919 12.3963C6.54363 12.3501 6.61065 12.3247 6.68 12.3251H7.33C7.61752 12.3583 7.9088 12.3321 8.18572 12.2479C8.46265 12.1637 8.7193 12.0235 8.93971 11.8359C9.16013 11.6483 9.33959 11.4174 9.46695 11.1575C9.5943 10.8976 9.66682 10.6143 9.68 10.3251C9.73634 10.1011 9.74224 9.86743 9.69727 9.64089C9.65231 9.41435 9.55761 9.20061 9.42 9.01512ZM8.15 10.4051C8.04 11.1451 7.47 11.1451 6.93 11.1451H6.62L6.83 9.75512C6.83684 9.71442 6.85746 9.6773 6.88841 9.64999C6.91936 9.62269 6.95876 9.60684 7 9.60512H7.14C7.51 9.60512 7.87 9.60512 8.04 9.81512C8.10225 9.89849 8.14454 9.99504 8.16361 10.0973C8.18268 10.1996 8.17802 10.3049 8.15 10.4051Z"
            fill="#253B80"
        />
        <Path
            d="M14.09 10.3747H13.09C13.0493 10.3769 13.0102 10.3915 12.9781 10.4166C12.9459 10.4416 12.9221 10.4758 12.91 10.5147V10.8047L12.84 10.6947C12.6886 10.5424 12.5047 10.4263 12.3021 10.3553C12.0994 10.2843 11.8833 10.2601 11.67 10.2847C11.1229 10.2974 10.5986 10.5064 10.1929 10.8736C9.78717 11.2408 9.52703 11.7416 9.45997 12.2847C9.40939 12.5514 9.41623 12.8258 9.48003 13.0896C9.54383 13.3534 9.66313 13.6006 9.82997 13.8147C9.9864 13.9879 10.1804 14.1229 10.397 14.2096C10.6137 14.2963 10.8473 14.3322 11.08 14.3147C11.3335 14.3174 11.585 14.2683 11.8189 14.1703C12.0527 14.0722 12.2641 13.9274 12.44 13.7447V14.0347C12.4371 14.0809 12.4522 14.1264 12.4819 14.1617C12.5117 14.1971 12.554 14.2197 12.6 14.2247H13.49C13.5595 14.2279 13.6277 14.2051 13.6813 14.1607C13.7349 14.1164 13.7701 14.0536 13.78 13.9847L14.31 10.5747C14.3212 10.5447 14.3232 10.5121 14.3157 10.4809C14.3082 10.4498 14.2915 10.4216 14.2678 10.4001C14.2441 10.3786 14.2145 10.3646 14.1828 10.3601C14.1511 10.3556 14.1187 10.3607 14.09 10.3747ZM11.59 13.2647C11.4677 13.2715 11.3455 13.2517 11.2316 13.2069C11.1176 13.162 11.0148 13.093 10.93 13.0047C10.8569 12.9032 10.8051 12.788 10.7776 12.666C10.7501 12.544 10.7475 12.4177 10.77 12.2947C10.8016 12.0215 10.9318 11.7692 11.1361 11.5851C11.3405 11.401 11.6049 11.2978 11.88 11.2947C12.0039 11.2891 12.1275 11.3113 12.2418 11.3598C12.356 11.4082 12.4579 11.4817 12.54 11.5747C12.6218 11.6696 12.6798 11.7825 12.7094 11.9042C12.739 12.0259 12.7392 12.1529 12.71 12.2747C12.6801 12.5496 12.5488 12.8034 12.3416 12.9865C12.1344 13.1696 11.8664 13.2688 11.59 13.2647Z"
            fill="#253B80"
        />
        <Path
            d="M19.35 10.3747H18.35C18.3023 10.3741 18.2551 10.3858 18.2131 10.4085C18.1711 10.4313 18.1356 10.4644 18.11 10.5047L16.74 12.5047L16.16 10.5547C16.1412 10.4948 16.1041 10.4423 16.0538 10.4047C16.0036 10.367 15.9428 10.346 15.88 10.3447H14.88C14.8525 10.3446 14.8253 10.3511 14.8008 10.3638C14.7763 10.3765 14.7553 10.395 14.7396 10.4176C14.7238 10.4403 14.7138 10.4664 14.7104 10.4938C14.707 10.5211 14.7103 10.5489 14.72 10.5747L15.81 13.8147L14.81 15.2747C14.7915 15.3003 14.7804 15.3305 14.778 15.362C14.7756 15.3934 14.7821 15.425 14.7966 15.453C14.8111 15.481 14.8332 15.5045 14.8603 15.5207C14.8874 15.5369 14.9185 15.5452 14.95 15.5447H15.95C15.997 15.5474 16.0439 15.5377 16.086 15.5167C16.1281 15.4956 16.164 15.4639 16.19 15.4247L19.49 10.6147C19.5014 10.59 19.5066 10.5628 19.5052 10.5356C19.5038 10.5084 19.496 10.482 19.4823 10.4584C19.4685 10.4349 19.4494 10.415 19.4264 10.4005C19.4033 10.3859 19.3772 10.3771 19.35 10.3747Z"
            fill="#253B80"
        />
        <Path
            d="M24.15 9.01474C23.9512 8.82299 23.7134 8.67638 23.4528 8.58492C23.1922 8.49345 22.915 8.45928 22.64 8.48474H20.57C20.5017 8.48455 20.4359 8.51023 20.3858 8.55662C20.3357 8.60301 20.305 8.66665 20.3 8.73474L19.45 14.0147C19.4471 14.04 19.4497 14.0656 19.4575 14.0898C19.4652 14.114 19.4781 14.1363 19.4951 14.1552C19.5121 14.174 19.5329 14.1891 19.5561 14.1994C19.5794 14.2097 19.6045 14.2149 19.63 14.2147H20.69C20.738 14.2126 20.7836 14.1932 20.8185 14.1601C20.8534 14.1271 20.8752 14.0826 20.88 14.0347L21.12 12.5247C21.1294 12.4566 21.1628 12.3941 21.2141 12.3483C21.2653 12.3025 21.3312 12.2765 21.4 12.2747H22.06C22.347 12.305 22.6371 12.2766 22.9128 12.1913C23.1885 12.106 23.444 11.9656 23.6637 11.7786C23.8835 11.5915 24.063 11.3618 24.1913 11.1033C24.3195 10.8448 24.3939 10.5629 24.41 10.2747C24.4544 10.0581 24.4542 9.83462 24.4095 9.618C24.3648 9.40138 24.2765 9.19613 24.15 9.01474ZM22.87 10.4347C22.77 11.1747 22.2 11.1747 21.65 11.1747H21.34L21.56 9.78474C21.5612 9.76371 21.5667 9.74314 21.5759 9.72421C21.5851 9.70529 21.598 9.68837 21.6138 9.67443C21.6296 9.66049 21.648 9.64981 21.6679 9.643C21.6879 9.63619 21.7089 9.63338 21.73 9.63474H21.88C22.25 9.63474 22.6 9.63474 22.78 9.84474C22.8344 9.9261 22.8706 10.0183 22.8861 10.1149C22.9017 10.2116 22.8962 10.3104 22.87 10.4047V10.4347Z"
            fill="#179BD7"
        />
        <Path
            d="M28.8101 10.3752H27.8101C27.7685 10.3741 27.7279 10.3875 27.6951 10.413C27.6623 10.4386 27.6393 10.4746 27.6301 10.5152V10.8052L27.5601 10.6952C27.4111 10.5424 27.2291 10.4259 27.0279 10.3548C26.8267 10.2837 26.6119 10.2599 26.4001 10.2852C25.853 10.2979 25.3287 10.5069 24.923 10.874C24.5173 11.2412 24.2571 11.7421 24.1901 12.2852C24.134 12.5517 24.1381 12.8273 24.2022 13.092C24.2662 13.3567 24.3884 13.6037 24.5601 13.8152C24.714 13.9887 24.9061 14.1242 25.1213 14.211C25.3364 14.2977 25.5688 14.3334 25.8001 14.3152C26.0551 14.3175 26.3079 14.2682 26.5433 14.1702C26.7788 14.0723 26.992 13.9277 27.1701 13.7452V14.0352C27.1672 14.0591 27.1695 14.0834 27.1768 14.1064C27.184 14.1294 27.1961 14.1506 27.2122 14.1685C27.2283 14.1865 27.248 14.2008 27.27 14.2106C27.2921 14.2204 27.316 14.2253 27.3401 14.2252H28.2301C28.2979 14.2259 28.3637 14.2019 28.4152 14.1578C28.4667 14.1136 28.5004 14.0523 28.5101 13.9852L29.0001 10.5752C29.0051 10.5487 29.0037 10.5214 28.9961 10.4956C28.9885 10.4697 28.9748 10.4461 28.9563 10.4265C28.9377 10.407 28.9148 10.3922 28.8894 10.3832C28.8639 10.3743 28.8368 10.3715 28.8101 10.3752ZM26.3201 13.2652C26.1962 13.2727 26.0723 13.2534 25.9566 13.2085C25.841 13.1636 25.7364 13.0943 25.6501 13.0052C25.5766 12.904 25.5253 12.7885 25.4994 12.6662C25.4736 12.5438 25.4738 12.4174 25.5001 12.2952C25.5273 12.022 25.6548 11.7685 25.8579 11.5838C26.0611 11.3991 26.3255 11.2963 26.6001 11.2952C26.7274 11.284 26.8555 11.3038 26.9734 11.353C27.0914 11.4022 27.1956 11.4793 27.2772 11.5776C27.3588 11.6759 27.4154 11.7926 27.442 11.9176C27.4686 12.0425 27.4645 12.1721 27.4301 12.2952C27.4001 12.5665 27.2694 12.8167 27.0639 12.9963C26.8584 13.1759 26.5929 13.2718 26.3201 13.2652Z"
            fill="#179BD7"
        />
        <path
            d="M31.1 8.45498H30.1C30.0591 8.45211 30.0187 8.46503 29.9871 8.49108C29.9554 8.51713 29.935 8.55432 29.93 8.59498L29.13 14.015C29.1256 14.0397 29.1267 14.0651 29.1332 14.0893C29.1397 14.1135 29.1515 14.136 29.1678 14.1551C29.184 14.1742 29.2043 14.1895 29.2272 14.1999C29.25 14.2102 29.2749 14.2154 29.3 14.215H30.15C30.2178 14.2157 30.2836 14.1917 30.3351 14.1476C30.3866 14.1034 30.4203 14.0421 30.43 13.975L31.27 8.64498C31.2728 8.62104 31.2705 8.59676 31.2633 8.57377C31.256 8.55078 31.2439 8.52959 31.2279 8.51162C31.2118 8.49365 31.1921 8.47931 31.17 8.46955C31.148 8.45978 31.1241 8.45482 31.1 8.45498Z"
            fill="#179BD7"
        />
    </Svg>
);

PayPal.propTypes = {
    ...IconPropTypes,
};

PayPal.defaultProps = {
    className: '',
    width: '36px',
    height: '24px',
    color: '#333333',
    border: true,
};

export default PayPal;

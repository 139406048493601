import { compose, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { widgetListProducts } from 'ggApp/modules/productWidget/selectors';
import {
    locationPathname as locationPathnameSelector,
    params as paramsSelector,
} from 'ggApp/modules/router/selectors';
import { storeActive } from 'ggApp/modules/store/selectors';
import { mainTransition } from 'ggApp/modules/router/actions';
import { product as productUrlBuilder } from 'ggApp/utils/urlBuilder/frontshop';

import Widget from './HandpickedProduct';

const HandpickedProductWidget = compose(
    connect(
        createStructuredSelector({
            store: storeActive,
            pathname: locationPathnameSelector,
            routeParams: paramsSelector,
            widgetListProducts,
        }),
        (dispatch) => ({
            mainTransition: bindActionCreators(mainTransition, dispatch),
        }),
    ),
    withHandlers({
        goToProductPage: ({ product: { slug }, mainTransition: transitionFunc }) => () => {
            if (!slug) return;
            transitionFunc(productUrlBuilder(slug), false);
        },
    }),
)(Widget);

export default HandpickedProductWidget;

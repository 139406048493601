/* eslint-disable camelcase */
import { createSelector } from 'reselect';
import objectPath from 'object-path';
import { MODULE_NAME } from '../constants';

export const moduleState = (state) => state[MODULE_NAME] || {};

export const moduleStateData = createSelector(moduleState, (m) =>
    objectPath.get(m, 'info.data', {}),
);

export const loading = createSelector(moduleState, (m) => m.loading);

export const partnersPage = createSelector(moduleStateData, (md) => md.partners_page || {});

export const pdpCommon = createSelector(moduleStateData, (md) => md.pdp_common || {});

export const oneTimePaymentPage = createSelector(
    moduleStateData,
    (md) => md.one_time_payment_page || {},
);

export const oneTimePaymentPageTable = createSelector(
    oneTimePaymentPage,
    (ot) => ot.table_fields || {},
);

export const termsConditionsPage = createSelector(moduleStateData, (md) => md.terms_page || {});

export const oneTimePaymentResults = createSelector(
    moduleStateData,
    (md) => md.one_time_payment_results || {},
);

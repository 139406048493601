/* eslint-disable */
const defaultJpegQuality = 85;

/**
 * A wrapper around Contentful Image API
 * @param {String} imageURI
 * @param {Object} config - configuration object, for possible options
 * see https://www.contentful.com/developers/docs/references/images-api/
 */
export default function image(imageURI, {
    width,
    height,
    quality = defaultJpegQuality,
    format,
    fit,
    focus,
    progressive = false,
}) {
    if (!imageURI) return imageURI;

    const params = [
        { name: 'w', value: width },
        { name: 'h', value: height },
        { name: 'q', value: quality },
        { name: 'fm', value: format },
        { name: 'fit', value: fit },
        { name: 'f', value: focus },
        { name: 'fl', value: progressive ? 'progressive' : undefined },
    ]
        .filter(t => !!t.value)
        .map(t => `${t.name}=${t.value}`)
        .join('&');

    return `${imageURI}?${params}`;
}

import { createSelector } from 'reselect';
import qs from 'query-string';

import ggRouter from 'ggApp/modules/router';
import { MODULE_NAME, SPEC_FILTER_RANGE_QS_KEY, SPEC_FILTER_MATCH_QS_KEY } from '../constants';

export const moduleState = (state) => state[MODULE_NAME] || {};

export const byCategory = createSelector(moduleState, (module) => module.byCategory || {});

export const active = createSelector(ggRouter.selectors.locationQuery, (locationQuery) =>
    locationQuery.filter ? decodeURIComponent(locationQuery.filter) : '',
);

export const parseLocationFilter = (locationQuery) => {
    const parsed = locationQuery.filter
        ? qs.parse(locationQuery.filter, { arrayFormat: 'bracket' })
        : {};

    [SPEC_FILTER_RANGE_QS_KEY, SPEC_FILTER_MATCH_QS_KEY].forEach((key) => {
        if (parsed[key]) {
            parsed[key] = parsed[key].map((spec) => JSON.parse(spec));
        }
    });

    return parsed;
};

export const activeJson = createSelector(ggRouter.selectors.locationQuery, parseLocationFilter);

export const enabledSpecFilters = createSelector(
    moduleState,
    (module) => module.structuredSpecs.data || [],
);

export const areFiltersVisible = createSelector(
    moduleState,
    (module) => !!module.byCategory.filtersVisible,
);

export const screens = {
    phone: 'min-width: 0px',
    smallestPhoneMax: 'max-width: 320px',
    averagePhone: 'min-width: 321px',
    tabletMax: 'max-width: 767px',
    tablet: 'min-width: 768px',
    desktopMax: 'max-width: 1023px',
    desktop: 'min-width: 1024px',
    largeDesktopMax: 'max-width: 1279px',
    largeDesktop: 'min-width: 1280px',
    wideScreenMax: 'max-width: 1599px',
    wideScreen: 'min-width:1600px',
    wideDesktop: 'min-width: 1440px',
    extraWideDesktop: 'min-width: 2160px',
};

/* eslint-disable */
import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { keys as translationKeysSelector, translate } from 'ggApp/modules/translator/selectors';
import { storeActive as storeActiveSelector } from 'ggApp/modules/store/selectors';
import UnsupportedBrowserBanner
    from './UnsupportedBrowserBanner';
import chromeIcon from './chrome.svg';
import firefoxIcon from './firefox.svg';
import braveIcon from './brave.svg';
import safariIcon from './safari.svg';

const getContent = (translator) => ({
    title: translator('UNSUPPORTED_BROWSER_BANNER_TITLE'),
    subtitle: translator('UNSUPPORTED_BROWSER_BANNER_SUBTITLE'),
    continueText: translator('UNSUPPORTED_BROWSER_BANNER_CONTINUE'),
    divider: translator('UNSUPPORTED_BROWSER_BANNER_DIVIDER'),
    browserButtonText: translator('UNSUPPORTED_BROWSER_BANNER_BROWSER_BUTTON_TEXT'),
    browserList: [
        {
            name: 'Chrome',
            logo: chromeIcon,
            link: 'https://www.google.com/chrome',
            description: translator('UNSUPPORTED_BROWSER_BANNER_CHROME'),
        },
        {
            name: 'Firefox',
            logo: firefoxIcon,
            link: 'https://www.mozilla.org/de/firefox',
            description: translator('UNSUPPORTED_BROWSER_BANNER_FIREFOX'),
        },
        {
            name: 'Brave',
            logo: braveIcon,
            link: 'https://brave.com',
            description: translator('UNSUPPORTED_BROWSER_BANNER_BRAVE'),
        },
        {
            name: 'Safari',
            logo: safariIcon,
            link: 'https://www.apple.com/lae/safari/',
            description: translator('UNSUPPORTED_BROWSER_BANNER_SAFARI'),
        },
    ],
});

export default compose(
    connect(
        createStructuredSelector({
            translations: translationKeysSelector,
            activeStore: storeActiveSelector,
        }),
    ),
    mapProps(({ translations, activeStore, ...props }) => ({
        ...props,
        ...getContent((tk) => translate(translations, activeStore.activeLanguage, tk)),
    })),
)(UnsupportedBrowserBanner);

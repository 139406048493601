import loadConfiguration from 'ggApp/utils/configuration';

export const getStoreLang = (path) => {
    const storeLocale = path.split('/')[1] || '';
    return storeLocale.split('-');
};

export const getHIWPageUrl = (code, activeLanguage) => {
    const { APP_CONFIG } = loadConfiguration();
    const { shopHost } = APP_CONFIG;

    return `${shopHost}/${code}-${activeLanguage}/how-it-works`;
};

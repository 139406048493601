/* eslint-disable */
import {
  SAVE_PAYMENT_DATA,
} from './../actionTypes';

const defaultState = {
  type: '',
  data: {},
};

export default function (state = defaultState, action) {
  switch (action.type) {

    case SAVE_PAYMENT_DATA:
      return {
        type: action.paymentType,
        data: action.data,
      }

    default:
      return state;
  }
}

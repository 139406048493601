import React, { useEffect, useCallback, useState, useContext, useMemo, useRef } from 'react';
import { useSessionStorage } from 'react-use';

import { Portal } from 'react-portal';

import { trackCookieConsentAnalytics } from 'analytics/adCampaigns/trackCookieConsentAnalytics';
import asyncComponent from 'ggApp/shared/components/async';
import loadConfiguration from 'ggApp/utils/configuration';
import { PureTranslator } from 'ggApp/modules/translator';
import { ThemeContext } from 'ggApp/shared/components/ThemeContext';

import CircleLoader from 'ggApp/shared/components/CircleLoader';
import { getCookieConsentValue, formatCookieConsent } from 'ggApp/modules/cookiePolicy/utils';
import CreateUserConsentMutation from 'ggApp/shared/mutations/createUserConsent.graphql';
import { useMutation } from '@apollo/client';
import {
    registerAcceptAllCookies,
    registerNewCookiePolicy,
    registerCookiePreferences,
    userHasMarketingConsentCookie,
    showCookieBannerAfterCookieExpired,
    syncGoogleConsentState,
} from '../../tracking';
import {
    Wrapper,
    StyledSnackbar,
    AcceptButton,
    StyledLink,
    BarTitle,
    BarContent,
    SettingsButton,
    Actions,
    Divi,
    ButtonLink,
} from './styles';

const CookiePolicyModal = asyncComponent(() => import('../modal'), {
    loader: CircleLoader,
});
const { APP_CONFIG } = loadConfiguration();

export const { shopHost } = APP_CONFIG;
const cookieExpiry = new Date();
cookieExpiry.setFullYear(cookieExpiry.getFullYear() + 1);

export const CookiePolicy = ({
    consentCookiePolicy,
    isShown,
    isOnPdp,
    isOnPlp,
    isOnCart,
    countryCode,
    activeLanguage,
    isLoggedIn,
    updateCookiePolicySettings,
    consentTimeStamp,
    getAllConsents,
    userId,
    isCookiePolicyInitialized,
}) => {
    const bannerRef = useRef(null);
    const [modal, setModal] = useState(null);
    const [tempHideCookieBanner, setTempHideCookieBanner] = useSessionStorage(
        'tempHideCookiesConsent',
    );
    const theme = useContext(ThemeContext);
    const [callMutation] = useMutation(CreateUserConsentMutation);

    const getBottomPosition = useCallback(() => {
        if (isOnCart) {
            return '0px';
        }
        if (isLoggedIn && !isOnPdp) {
            return '64px';
        }
        if (isOnPdp || isOnPlp) {
            return '80px';
        }
        return '0px';
    }, [isOnCart, isLoggedIn, isOnPdp, isOnPlp]);

    const createModalRef = useCallback((newModal) => {
        setModal(newModal);
    }, []);

    const bottomPosition = getBottomPosition(isLoggedIn, isOnPdp);

    const handleClick = useCallback(() => {
        if (modal) {
            modal.openModal();
        }
    }, [modal]);

    const handleAcceptAll = useCallback(() => {
        consentCookiePolicy();
        registerAcceptAllCookies();
        setTempHideCookieBanner(true);
        trackCookieConsentAnalytics({
            strict: 'yes',
            functional: 'yes',
            performance: 'yes',
            profiling: 'yes',
        });
        const cookieConsentState = {
            preferenceCookie: true,
            statisticsCookie: true,
            marketingCookie: true,
            explicitConsent: true,
        };
        syncGoogleConsentState(cookieConsentState);
        if (userId) {
            callMutation({
                variables: {
                    consentAttributes: formatCookieConsent(cookieConsentState),
                },
            });
        }
        window.location.reload();
    }, [callMutation, consentCookiePolicy, setTempHideCookieBanner, userId]);

    const handleRejectAll = useCallback(() => {
        const reduxStorePayload = {
            preferenceCookieConsent: false,
            statisticsCookieConsent: false,
            marketingCookieConsent: false,
            consentTimestamp: Date.now(),
            consentExpiration: cookieExpiry,
        };

        registerCookiePreferences(reduxStorePayload);
        updateCookiePolicySettings(reduxStorePayload);

        setTempHideCookieBanner(true);
        trackCookieConsentAnalytics({
            strict: 'no',
            functional: 'no',
            performance: 'no',
            profiling: 'no',
        });
        const cookieConsentState = {
            preferenceCookie: false,
            statisticsCookie: false,
            marketingCookie: false,
            explicitConsent: false,
        };
        syncGoogleConsentState(cookieConsentState);
        if (userId) {
            callMutation({
                variables: {
                    consentAttributes: formatCookieConsent(cookieConsentState),
                },
            });
        }
    }, [callMutation, setTempHideCookieBanner, updateCookiePolicySettings, userId]);

    useEffect(() => {
        if (!isCookiePolicyInitialized) return;
        const consent = {
            strict: 'yes',
            functional: getAllConsents?.preferenceCookieConsent ? 'yes' : 'no',
            performance: getAllConsents?.statisticsCookieConsent ? 'yes' : 'no',
            profiling: getAllConsents?.marketingCookieConsent ? 'yes' : 'no',
        };
        trackCookieConsentAnalytics(consent);
    }, [getAllConsents, isCookiePolicyInitialized]);

    useEffect(() => {
        if (
            getAllConsents &&
            getAllConsents.preferenceCookieConsent !== undefined &&
            tempHideCookieBanner
        ) {
            const cookieConsentState = {
                preferenceCookie: getAllConsents?.preferenceCookieConsent,
                statisticsCookie: getAllConsents?.statisticsCookieConsent,
                marketingCookie: getAllConsents?.marketingCookieConsent,
                explicitConsent: true,
            };
            if (userId) {
                callMutation({
                    variables: {
                        consentAttributes: formatCookieConsent(cookieConsentState),
                    },
                });
            }
        }
    }, [callMutation, getAllConsents, isShown, tempHideCookieBanner, userId]);

    const privacyPolicyLink = useMemo(
        () => (
            <StyledLink
                to={`/${countryCode?.toLowerCase()}-${activeLanguage}/g-about/datenschutzerklaerung`}
                external
            >
                <PureTranslator tk="LEGAL_LINKS_PRIVACY_POLICY_TEXT" />
            </StyledLink>
        ),
        [countryCode, activeLanguage],
    );

    const rejectAllLink = useMemo(
        () => (
            <ButtonLink onClick={handleRejectAll}>
                <PureTranslator tk="NEW_COOKIE_POLICY_TEXT_REJECT_ALL_LINK" />
            </ButtonLink>
        ),
        [handleRejectAll],
    );

    const renderActions = useCallback(
        () => (
            <Actions>
                <AcceptButton
                    color="black"
                    small
                    onClick={handleAcceptAll}
                    data-testid="new-cookie-acceptall"
                >
                    <PureTranslator tk="NEW_COOKIE_POLICY_ACCEPT_ALL" />
                </AcceptButton>

                <SettingsButton onClick={handleClick} data-testid="new-cookie-open-modal">
                    <PureTranslator tk="NEW_COOKIE_POLICY_INDIVIDUAL_SETTINGS" />
                </SettingsButton>
            </Actions>
        ),
        [handleAcceptAll, handleClick],
    );
    const handleModalClose = useCallback(() => {
        if (modal) {
            modal.closeModal();
        }
    }, [modal]);

    useEffect(() => {
        const handler = () => {
            const bannerMargin = isOnPlp ? 20 : -40;
            const bannerHeight = (bannerRef?.current?.clientHeight ?? 0) + bannerMargin;

            document.body.style.minHeight = `${document.getElementById('groverApp')?.clientHeight +
                bannerHeight}px`;
        };

        if (isShown && !tempHideCookieBanner) {
            window.addEventListener('scroll', handler);
            registerNewCookiePolicy();
        }

        return () => {
            document.body.style.minHeight = '100%';
            window.removeEventListener('scroll', handler);
        };
    }, [isShown, tempHideCookieBanner, isOnPlp]);

    useEffect(() => {
        const existingCookies = getCookieConsentValue();
        if (existingCookies && existingCookies.consentTimestamp) {
            if (userHasMarketingConsentCookie()) {
                // trigger the cookie banner for regular users with old cookie key
                const shouldShowCookieBanner = showCookieBannerAfterCookieExpired(
                    existingCookies.consentTimestamp,
                );
                if (shouldShowCookieBanner) {
                    setTempHideCookieBanner(false);
                }
            }
        }
    }, [setTempHideCookieBanner, consentTimeStamp]);
    return (
        <Portal>
            <Wrapper
                ref={bannerRef}
                data-testid="new-cookie-snackbar"
                bottomPosition={bottomPosition}
            >
                <StyledSnackbar
                    isShown={isShown && !tempHideCookieBanner}
                    backgroundColor={theme.colors.Background}
                    textColor={theme.colors.Foreground}
                    renderActions={renderActions}
                    realign
                >
                    <BarTitle>
                        <PureTranslator tk="NEW_COOKIE_POLICY_TEXT_TITLE_V2" />
                    </BarTitle>

                    <BarContent>
                        <PureTranslator
                            tk="NEW_COOKIE_POLICY_TEXT_V2"
                            withTextComponent={{ PrivacyPolicy: privacyPolicyLink }}
                        />{' '}
                        <PureTranslator
                            tk="NEW_COOKIE_POLICY_TEXT_REJECT_ALL"
                            withTextComponent={{ RejectAll: rejectAllLink }}
                        />
                    </BarContent>
                    <Divi />
                </StyledSnackbar>
            </Wrapper>

            <CookiePolicyModal
                modalRef={createModalRef}
                handleModalClose={handleModalClose}
                acceptAll={handleAcceptAll}
                onCustomSave={setTempHideCookieBanner}
            />
        </Portal>
    );
};

import styled from 'styled-components';

import { elevation, elevationTransition } from 'ggApp/utils/mixins/elevation';

import CartIcon from 'ggApp/shared/icons/Cart';

const Wrap = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 48px;
    position: relative;
    ${elevationTransition(false)};
    ${elevation(1, false)};
    &:hover {
        ${elevation(3, false)};
    }
    cursor: pointer;
    flex-shrink: 0;
    margin-left: ${({ theme }) => theme.spacers.M};
`;

const Button = styled.div`
    background: ${({ theme }) => theme.colors.ElementBackground};
    box-sizing: border-box;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Icon = styled(CartIcon)`
    width: 24px;
    height: 24px;
    bottom: 0;
    right: 0;
`;

const UnstyledDiv = styled.div``;

export { Wrap, Button, Icon, UnstyledDiv };

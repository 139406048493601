import styled, { css } from 'styled-components';

import { elevation, elevationTransition } from 'ggApp/utils/mixins';

import Message from 'ggApp/shared/icons/Message';
import ChevronSmall from 'ggApp/shared/icons/Chevrons/Small';

const List = styled.ul`
    width: 102%;
    opacity: 0;
    top: -3px;
    left: -2px;
    visibility: hidden;
    position: absolute;
    border-radius: 24px;
    background: ${({ theme }) => theme.colors.Background};
    border: 2px solid ${({ theme }) => theme.colors['Level 1']};
    z-index: 2;
    ${elevationTransition(false)}
    ${elevation(0, false)}
    ${({ isOpen }) => isOpen
        && css`
            opacity: 1;
            visibility: visible;
            ${elevation(3, false)}
        `}
    ${({ isMinimal }) => isMinimal
        && css`
            margin: 0 12px;
            width: unset;
        `}
`;

const ListElement = styled.li`
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    color: ${({ theme }) => theme.colors.Foreground};
    cursor: pointer;
    &:hover {
        background: ${({ theme }) => theme.colors['Level 1']};
    }
    transition: background-color ease 0.3s;
    &:first-child {
        border-radius: 20px 20px 0 0;
        ${({ isMinimal }) => isMinimal
        && css`
            background: ${({ theme }) => theme.colors['Level 1']};
        `}
    }
    &:last-child {
        border-radius: 0 0 20px 20px;
    }
    &:not(:last-child) {
        border-bottom: 2px solid ${({ theme }) => theme.colors['Level 1']};
    }
`;

const Icon = styled(Message)`
    height: 24px;
    width: 24px;
    margin-right: ${({ theme }) => theme.spacers.M};
`;

const IconGap = styled.div`
    height: 24px;
    width: 24px;
    margin-right: ${({ theme }) => theme.spacers.M};
`;

const LanguageText = styled.span`
  ${({theme}) => css(theme.typography.SuperTinyHeadline)};
    padding: 12px 0;
`;

const Arrow = styled(ChevronSmall)`
    height: 24px;
    width: 24px;
    margin-right: 16px;
    margin-left: auto;
    ${({ isMinimal }) => isMinimal
        && css`
            margin-left: 16px;
        `}
`;

export {
    List,
    IconGap,
    ListElement,
    Icon,
    LanguageText,
    Arrow,
};

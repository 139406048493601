/* eslint-disable camelcase */
import { createSelector } from 'reselect';

import { currentProduct, currentProductById } from 'ggApp/modules/product/selectors';

import { rentalPlanMapper } from './utils/adapters';

export const currentProductRentalPlans = createSelector(currentProduct, (product) => {
    return product?.rental_plans?.map((plan) => rentalPlanMapper(plan));
});

export const currentProductByIdRentalPlans = createSelector(currentProductById, (product) => {
    return product?.rental_plans?.map((plan) => rentalPlanMapper(plan));
});

export const rentalPlanIndexSelector = (state) => state.rentalPlan.rentalPlanIndex;

export const selectedRentalPlanSelector = createSelector(
    currentProductRentalPlans,
    rentalPlanIndexSelector,
    (plans, index) => {
        const plan = plans[Math.min(plans.length - 1, index)];

        return plan || {};
    },
);

export const currentRentalPlanByProductIdSelector = createSelector(
    currentProductByIdRentalPlans,
    rentalPlanIndexSelector,
    (plans, index) => {
        const plan = plans[Math.min(plans.length - 1, index)];

        return plan || {};
    },
);

export const cheapestRentalPlanSelector = createSelector(currentProductRentalPlans, (rentalPlans) =>
    Array.isArray(rentalPlans) ? rentalPlans.slice(-1)[0] : undefined,
);

/* eslint-disable */
import {
  SET_HEADERS,
} from './../actionTypes';

export function setHeaders(headers) {
    return {
        type: SET_HEADERS,
        headers,
    }
}
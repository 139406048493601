import { stringify } from 'query-string';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect';

import { user as userSelector } from 'ggApp/modules/auth/selectors';
import { inlineAccessToken } from 'ggApp/modules/router/selectors';

import { mainTransition } from '../actions';

/**
 * This HOC checks the user state from `redux`
 * and redirects to `/auth` page if user is logged out.
 *
 * NOTE: If you're going to use this guard during SSR
 * you should provide `onEnter` prop to `Route` component as
 * described in the documentation of `redux-auth-wrapper`:
 * https://github.com/mjrussell/redux-auth-wrapper/tree/1.x#server-side-rendering
 * @param {*} { component, flow }
 */
export const withAuthGuard = (component, options = {}) =>
    connectedReduxRedirect({
        authenticatedSelector: (state) =>
            Boolean(userSelector(state)?.id) || Boolean(inlineAccessToken(state)),
        redirectPath: '/auth',
        redirectAction: (location) => (dispatch) => {
            const { flow } = options;
            const { pathname, query } = location;

            let redirectionPath = pathname;

            if (query || flow) {
                const payload = {
                    flow,
                    ...(query ?? {}),
                };

                redirectionPath = `${redirectionPath}?${stringify(payload)}`;
            }

            dispatch(mainTransition(redirectionPath, true));
        },
        redirectQueryParamName: 'redirection',
        wrapperDisplayName: 'AuthGuardHOC',
    })(component);

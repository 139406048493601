/**
 * This function gets `object` and for every `key` of it applies `filter` function.
 * If filter function returns `true` then this field will be in a result object.
 * @param {Object} object
 * @param {function} filter
 * @returns {Object} object with filtered properties
 * @example
 * const object = {
 *      foo: 'foo',
 *      bar: 1,
 *      gamma: {},
 * };
 * const filter = (key, value) => typeof value === 'string';
 * const result = filterProps(object, filter);
 * result == {
 *      foo: 'foo',
 * }
 */
function filterProps(object, filter) {
    if (typeof object !== 'object' || object === null) {
        return null;
    }

    const newObject = {};

    Object.keys(object).forEach((key) => {
        const value = object[key];

        const keyShouldBePresented = filter(key, value);

        if (keyShouldBePresented) {
            newObject[key] = value;
        }
    });

    return newObject;
}

export default filterProps;

import {
    GET_FILTERS_BY_CATEGORY,
    GET_FILTERS_BY_CATEGORY_SUCCESS,
    GET_FILTERS_BY_CATEGORY_ERROR,
    SET_FILTERS_VISIBLE,
} from '../actionTypes';

const defaultState = {
    loading: null,
    error: {},
    data: {},
    filtersVisible: false,
};

export default function(state = defaultState, action) {
    switch (action.type) {
        case GET_FILTERS_BY_CATEGORY:
            return {
                ...state,
                loading: true,
                error: {},
            };

        case GET_FILTERS_BY_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data || {},
            };

        case GET_FILTERS_BY_CATEGORY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case SET_FILTERS_VISIBLE:
            return {
                ...state,
                filtersVisible: action.filtersVisible,
            };

        default:
            return state;
    }
}

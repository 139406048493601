import { CartItemModel, CheckoutMachine } from 'ggApp/shared/context/Order/services/integration';

export const registerBasketEventsConnector = (
    originRegisterAddToCart: (id: number, cart: CartItemModel) => void,
    originRegisterRemoveFromCart: (item: any, quantity: number, cart: CartItemModel) => void,
    legacyPurchaseEvent: (cart: any, activeStore: any) => void,
    registerCriteoCartUpdate: (user: any, cart: any) => void,
    registerCriteoOrderConfirmation: (user: any, cart: any) => void,
    originRegisterChangeCartContent: (item: any, cart: CartItemModel) => void,
) => (
    context: CheckoutMachine,
    event: {
        type: string;
        item?: { id: number; quantity: number };
        data: {
            items: Array<{ variant_id: number }>;
        };
    },
): void => {
    const { cart, activeStore, user } = context;
    // legacy purchase event
    if (event?.type === 'done.invoke.submit') {
        legacyPurchaseEvent(cart, activeStore);
        registerCriteoOrderConfirmation(user, cart);
    }
    // createOrder
    if (event?.type === 'done.invoke.createOrder') {
        /* don't send create order event on multiple items / bundle add to cart, will ether add new event for bundles,
        or adjust event to accept multiple products */
        if (cart?.items?.length === 1) {
            originRegisterAddToCart(cart?.items[0]?.id, cart);
        }
        registerCriteoCartUpdate(user, cart);
        return;
    }
    // addLineItem
    if (event?.type === 'done.invoke.addLineItem') {
        /* don't send add to cart event on multiple items / bundle add to cart, will ether add new event for bundles,
        or adjust event to accept multiple products */
        if (event?.data?.items?.length === 1) {
            const product =
                cart.items?.find((item) => item.variant.id === event?.data?.items[0].variant_id) ||
                {};
            originRegisterAddToCart(product.id, cart);
        }
        registerCriteoCartUpdate(user, cart);
        return;
    }
    // removeLineItem
    if (event?.type === 'REMOVE_LINE_ITEM') {
        originRegisterRemoveFromCart(event.item, event?.item?.quantity - 1, cart);
        registerCriteoCartUpdate(user, cart);
        return;
    }

    if (event?.type === 'UPDATE_LINE_ITEM') {
        const eventItem = event.item ? event.item : event?.items?.[0] || {};
        const item = cart?.items?.find((i) => i.id === eventItem?.id);
        if (!item) return;

        // Should trigger originRegisterChangeCartContent event only if a device color or rental plan has been changed
        if (eventItem.committed_months || eventItem.rental_plan_id || eventItem.variant_id) {
            originRegisterChangeCartContent(eventItem, cart);
            return;
        }

        if (item.quantity <= eventItem?.quantity) {
            originRegisterAddToCart(item.id, cart);
            registerCriteoCartUpdate(user, cart);
        } else {
            originRegisterRemoveFromCart(item, eventItem?.quantity, cart);
            registerCriteoCartUpdate(user, cart);
        }
    }
};

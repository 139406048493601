/* eslint-disable */
export const MODULE_NAME = 'form';

export const FORMS_DAYS = Array
    .apply(null, Array(31))
    .map(function (_, i) {return i + 1;});

export const FORMS_MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const FORMS_MONTHS_DE = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
];
export const FORMS_YEARS = Array
    .apply(null, Array(117))
    .map(function (_, i) {return 1900 + i;})
    .reverse();

export const FORM_DATE_FORMAT = 'DD-MM-YYYY';

export const FORM_GENDERS = ['M', 'F',]

export const CUSTOM_VOUCHER_ERRORS = {
    new_customers_only: 'VOUCHER_ERROR_MESSAGE_NEW_CUSTOMERS_ONLY',
    recurring_customers_only: 'VOUCHER_ERROR_MESSAGE_RECURRING_CUSTOMERS_ONLY',
    min_3_month: 'VOUCHER_ERROR_MESSAGE_MIN_3_MONTH',
    min_6_month: 'VOUCHER_ERROR_MESSAGE_MIN_6_MONTH',
    min_9_month: 'VOUCHER_ERROR_MESSAGE_MIN_9_MONTH',
    min_12_month: 'VOUCHER_ERROR_MESSAGE_MIN_12_MONTH',
    invalid_business_store: 'VOUCHER_ERROR_MESSAGE_INVALID_BUSINESS_STORE',
    grover_main_store: 'VOUCHER_ERROR_MESSAGE_GROVER_MAIN_STORE',
    de_store_only: 'VOUCHER_ERROR_MESSAGE_DE_STORE_ONLY',
    at_store_only: 'VOUCHER_ERROR_MESSAGE_AT_STORE_ONLY',
    voucher_used_before: 'VOUCHER_ERROR_MESSAGE_VOUCHER_USED_BEFORE',
    ineligible_product: 'VOUCHER_ERROR_MESSAGE_INELIGIBLE_PRODUCT',
    ineligible_cart_number_2: 'VOUCHER_ERROR_MESSAGE_INELIGIBLE_CART_NUMBER_2',
    ineligible_cart_number_3: 'VOUCHER_ERROR_MESSAGE_INELIGIBLE_CART_NUMBER_3',
    ineligible_cart_value: 'VOUCHER_ERROR_MESSAGE_INELIGIBLE_CART_VALUE',
    customer_not_valid: 'VOUCHER_ERROR_MESSAGE_RESOURCE_NOT_FOUND',
    resource_not_found: 'VOUCHER_ERROR_MESSAGE_RESOURCE_NOT_FOUND',
    voucherify_not_responding: 'VOUCHER_ERROR_MESSAGE_VOUCHERIFY_NOT_RESPONDING',
    referrer_not_permitted_to_redeem: 'VOUCHER_ERROR_MESSAGE_REFERRER_NOT_PERMITTED',
    voucher_expired: 'VOUCHER_ERROR_MESSAGE_EXPIRED_DISABLED',
    voucher_disabled: 'VOUCHER_ERROR_MESSAGE_EXPIRED_DISABLED',
    invalid_voucher: 'VOUCHER_ERROR_MESSAGE_INVALID',
    invalid_sku: 'VOUCHER_ERROR_MESSAGE_INELIGIBLE_PRODUCT',
    gravis_online: 'VOUCHER_ERROR_MESSAGE_GRAVIS_ONLY',
    saturn_online: 'VOUCHER_ERROR_MESSAGE_SATURN_ONLY',
    mm_online: 'VOUCHER_ERROR_MESSAGE_MM_ONLY',
    ineligible_cart_number_4_max: 'VOUCHER_ERROR_MESSAGE_4_MAX',
    three_higher_grover_store: 'VOUCHER_ERROR_MESSAGE_3_HIGHER_GROVER',
    not_valid_store: 'VOUCHER_ERROR_MESSAGE_NOT_VALID_STORE',
    three_higher_invalid_store: 'VOUCHER_ERROR_MESSAGE_3_HIGHER_INVALID_STORE'
};
/* eslint-disable */
import { combineReducers, } from 'redux';

import list from './list';
import page from './page';
import pagination from './pagination';

export default combineReducers({
    list,
    page,
    pagination,
});

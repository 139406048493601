/* eslint-disable */
import { combineReducers, } from 'redux';

import category from './category';
import subCategory from './subCategory';
import list from './list';

export default combineReducers({
  subCategory,
  category,
  list,
});
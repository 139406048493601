import { REMOVE_FROM_CART_SUCCESS } from 'ggApp/modules/order/actionTypes';
import { registerRemoveFromCart } from 'ggApp/modules/gaAnalytics/actions';
import { orderActive as orderActiveSelector } from 'ggApp/modules/order/selectors';
import { criteoCartCheckoutUpdate } from 'ggApp/modules/criteo/actions';
import { user as userSelector } from 'ggApp/modules/auth/selectors';

const removeFromCartEventMiddleware = (store) => (next) => (action) => {
    if ([REMOVE_FROM_CART_SUCCESS].includes(action.type)) {
        const user = userSelector(store.getState());
        const orderFromState = orderActiveSelector(store.getState());

        const { orderNumber } = action;

        store.dispatch(registerRemoveFromCart(action.product, action.quantity, orderNumber));

        if (!action.cart) {
            store.dispatch(criteoCartCheckoutUpdate(user, orderFromState));
        }
    }
    next(action);
};

export default removeFromCartEventMiddleware;

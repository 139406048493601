/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */

import axios from 'ggApp/utils/requests';
import ggForm from 'ggApp/modules/form';
import ggRouter from 'ggApp/modules/router';
import CookiesManager from 'ggApp/utils/CookiesManager';
import { loggedOutBrowsingFlag as loggedOutBrowsingFlagSelector } from 'ggApp/modules/featureFlag/selectors';
import { isAppUser } from 'ggApp/routes/_wrap/MobileWrapper/utils';
import { resetAfterLogOut } from '@devsbb/analytics-client';

import { getStoreConfig } from 'staticStoreConfig';
import { getStoreDefaultLanguage } from 'ggApp/modules/store/utils';
import * as at from '../actionTypes';

import { createUser } from '../services';
import { adaptUserFormDataToAPIData } from '../adapters';
import {
    getAccessToken,
    saveAuthCredentials,
    forgetAuthCredentials,
    getRefreshToken,
    cleanRecentSearchTermsStorage,
} from '../utils';

import shouldRedirectBusinessCustomer from './businessCustomerRedirection';

import { storeActive as activeStoreSelector } from '../../store';
import { STORE_TYPE } from '../../store/constants';

const {
    serverValidation,
    passwordResetServerValidation,
    signupServerValidation,
} = ggForm.validations;

export function getUserDispatch(user = {}) {
    return {
        type: at.GET_USER,
        data: user,
    };
}

export function getUserRequest(config = {}) {
    return axios.get('/user', config);
}

export function phoneVerificationInputShow() {
    return {
        type: 'AUTH_SHOW_PHONE_VERIFICATION',
    };
}

export function phoneVerificationSetAsyncErrors(data) {
    return {
        type: 'AUTH_SET_PV_ASYNC_ERRORS',
        data,
    };
}

export function phoneVerificationInputHide() {
    return {
        type: 'AUTH_HIDE_PHONE_VERIFICATION',
    };
}

export function getUser(params, location, req) {
    const cookiesManager = new CookiesManager(req);
    const requestConfig = { cookiesManager };

    // This is a part of old & important logic:
    // Some parts of the `catalog` (frontshop) and `main` apps
    // depends on a `user` in app state to exist
    // but to be empty if there is no actual user (anonymous)
    // and renders based on that;
    // After this will be refactored, the authToken check here
    // can be safely removed.
    const refreshToken = getRefreshToken(cookiesManager);
    const accessToken = getAccessToken(cookiesManager);

    if (!refreshToken && !accessToken) {
        forgetAuthCredentials(cookiesManager);

        return getUserDispatch();
    }

    return async (dispatch) => {
        try {
            const res = await getUserRequest(requestConfig);
            const user = res.data.user || {};
            const userId = user?.id;
            const cookiesUserId = parseInt(cookiesManager.get('user_id'), 10);

            if (cookiesUserId && userId && cookiesUserId !== userId) {
                // mitigating the issue with force logout.
                forgetAuthCredentials(cookiesManager);
                dispatch({ type: at.USER_LOGOUT_SUCCESS, data: {} });
            } else {
                if (!cookiesManager.get('user_id', { path: '/' })) {
                    cookiesManager.set('user_id', user.id);
                }

                dispatch(getUserDispatch(user));
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                forgetAuthCredentials(cookiesManager);
            }
            // to avoid resetting user data and force user out from app
            // since we are getting a lot of network errors in webview
            if (!isAppUser()) {
                dispatch(getUserDispatch());
            }
            throw error;
        }
    };
}

export function login(data) {
    return (dispatch) =>
        axios
            .post('/oauth/token', data)
            .then((response) => {
                const { data: responseData = {} } = response;
                const { access_token, refresh_token, expires_in } = responseData;
                saveAuthCredentials(access_token, refresh_token, expires_in);
            })
            .then(() => getUserRequest())
            .then((res) => {
                dispatch({
                    type: at.LOGIN,
                    data: res.data.user,
                });
            })
            .catch(serverValidation);
}

export function signup(formData) {
    const userData = adaptUserFormDataToAPIData(formData);

    return (dispatch) =>
        createUser(userData)
            .then((user) => {
                const { access_token, refresh_token } = user;
                saveAuthCredentials(access_token, refresh_token);

                delete user.access_token;
                delete user.refresh_token;
                return user;
            })
            .then((user) => {
                dispatch({
                    type: at.SIGN_UP,
                    data: user,
                });
            })
            .catch(signupServerValidation);
}

export function update(data) {
    const newUserData = data.birth
        ? {
              ...data,
              birthdate: `${data.birth.day}-${data.birth.month}-${data.birth.year}`,
          }
        : data;

    delete newUserData.birth;
    return (dispatch) =>
        axios
            .patch('/user', { user: newUserData })
            .then((res) => {
                dispatch({
                    type: at.SIGN_UP,
                    data: res.data.user,
                });
            })
            .catch(serverValidation);
}

export function updatePhone(prefix, number) {
    return (dispatch) => {
        dispatch({ type: 'AUTH_FETCHING' });
        return axios
            .patch('/user/update_phone', {
                phone_number: `${prefix}${number}`,
            })
            .then((res) => {
                dispatch(phoneVerificationInputShow());
                dispatch({ type: 'AUTH_STOP_FETCHING' });

                dispatch({
                    type: 'AUTH_UPDATE_PHONE',
                    data: res.data.user,
                });
            })
            .catch(({ response: res = {} }) => {
                dispatch({ type: 'AUTH_STOP_FETCHING' });

                dispatch(
                    phoneVerificationSetAsyncErrors({
                        updatePhoneError: res.data.base,
                    }),
                );
            });
    };
}

export function confirmPhone(code, phoneNumber) {
    return (dispatch) => {
        dispatch({ type: 'AUTH_FETCHING' });
        return axios
            .patch('/user/confirm_phone', {
                phone_number: phoneNumber,
                confirm_code: code,
            })
            .then((res) => {
                dispatch(phoneVerificationInputHide());
                dispatch({ type: 'AUTH_STOP_FETCHING' });

                dispatch({
                    type: 'AUTH_CONFIRM_PHONE',
                    data: res.data.user,
                });
            })
            .catch(({ response: res = {} }) => {
                dispatch({ type: 'AUTH_STOP_FETCHING' });

                dispatch(
                    phoneVerificationSetAsyncErrors({
                        confirmPhoneError: res.data.base,
                    }),
                );
            });
    };
}

export function confirmEmail(user) {
    return (dispatch) => {
        dispatch({ type: at.CONFIRM_EMAIL });
        return axios
            .post('/users/confirmation', { user })
            .then((result) => {
                dispatch({
                    type: at.CONFIRM_EMAIL_SUCCESS,
                    data: result.data.user,
                });
                return result.data;
            })
            .catch((error) => {
                dispatch({
                    type: at.CONFIRM_EMAIL_ERROR,
                    error: error.response && error.response.data,
                });
                serverValidation(error);
            });
    };
}

export function changePassword(user) {
    return (dispatch) => {
        dispatch({ type: at.CHANGE_PASSWORD });
        return axios
            .patch('/users', { user })
            .then((result) => {
                dispatch({
                    type: at.CHANGE_PASSWORD_SUCCESS,
                    data: result.data.user,
                });
                return result.data;
            })
            .catch((error) => {
                dispatch({
                    type: at.CHANGE_PASSWORD_ERROR,
                    error: error.response && error.response.data,
                });
                serverValidation(error);
            });
    };
}

export function resetPassword(user) {
    return (dispatch) => {
        dispatch({ type: at.RESET_PASSWORD });
        return axios
            .patch('/users/password', user)
            .then((result) => {
                dispatch({
                    type: at.RESET_PASSWORD_SUCCESS,
                    data: result.data.user,
                });
                return result.data;
            })
            .catch((error) => {
                dispatch({
                    type: at.RESET_PASSWORD_ERROR,
                    error: error.data,
                });
                passwordResetServerValidation(error);
            });
    };
}

export function sendPasswordRecovery(user) {
    return (dispatch) =>
        axios
            .post('/users/password', { user })
            .then(() => {
                dispatch({ type: 'AUTH_PASSWORD_RECOVERY_SUCCESS' });
            })
            .catch(serverValidation);
}

export function resetPasswordRecovery() {
    return {
        type: 'AUTH_PASSWORD_RECOVERY_RESET',
    };
}

export function subscribeToNewsletter({ email }) {
    return (dispatch) => {
        dispatch({ type: at.SUBSCRIBE_TO_NEWSLETTER });
        return axios
            .post('/newsletters', { email })
            .then((result) => {
                dispatch({
                    type: at.SUBSCRIBE_TO_NEWSLETTER_SUCCESS,
                    data: true,
                });
                return result.data;
            })
            .catch(({ response: error = {} }) => {
                dispatch({
                    type: at.SUBSCRIBE_TO_NEWSLETTER_ERROR,
                    error: error.response && error.response.data,
                });
                serverValidation(error);
            });
    };
}

function isNotAppOrNotBusiness(getState, currentStore) {
    const isBusiness = currentStore.code.includes(STORE_TYPE.BUSINESS);
    const isApp = window.ReactNativeWebView;
    return !isApp || !isBusiness;
}

function redirectToCurrentStoreBasePathAndClearCache(dispatch, redirectPath) {
    return dispatch(ggRouter.actions.mainTransition(`${redirectPath}?nocache=${Date.now()}`, true));
}

function redirectToConsumerStore(dispatch, nextStore) {
    const nextStoreCode = nextStore.countryCode.toLowerCase();
    const nextLanguage = getStoreDefaultLanguage(nextStoreCode);
    const newUrl = `/${nextStoreCode}-${nextLanguage}`;
    return dispatch(ggRouter.actions.redirectus(newUrl, true));
}

export function logout(options = {}) {
    return (dispatch, getState) => {
        const { redirectPath = window.location.pathname } = options;
        const token = getAccessToken();
        const loggedOutBrowsingFlag = loggedOutBrowsingFlagSelector(getState());
        if (!token) return undefined;

        dispatch({ type: at.USER_LOGOUT });
        return axios
            .post('/oauth/revoke', { token })
            .then(() => {
                forgetAuthCredentials(undefined, true);
                cleanRecentSearchTermsStorage();
                resetAfterLogOut();

                const currentStore = activeStoreSelector(getState());
                if (isNotAppOrNotBusiness(getState, currentStore)) {
                    return redirectToCurrentStoreBasePathAndClearCache(dispatch, redirectPath);
                }

                if (!loggedOutBrowsingFlag) {
                    return null;
                }

                const nextStore = getStoreConfig(currentStore.code);
                if (!nextStore) {
                    return redirectToCurrentStoreBasePathAndClearCache(dispatch, redirectPath);
                }

                return redirectToConsumerStore(dispatch, nextStore);
            })
            .catch(({ response: error = {} }) => {
                dispatch({
                    type: at.USER_LOGOUT_ERROR,
                    error: error.data,
                });
            });
    };
}

export function checkStoreAndUserType() {
    return (dispatch, getState) => shouldRedirectBusinessCustomer(dispatch, getState);
}

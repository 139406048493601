import { createContext } from 'react';

export interface LanguageContextProps {
    language: string | null;
}

const defaultState: LanguageContextProps = {
    language: null,
};

const LanguageContext = createContext<LanguageContextProps>(defaultState);

export default LanguageContext;

/* eslint-disable */
import { defaultQueryPayload } from 'ggApp/modules/favorites/actions';
import { ADD_TO_FAVORITES } from 'ggApp/modules/favorites/actionTypes';
import {
    GET_FAVORITES_PRODUCTS,
    GET_FAVORITES_PRODUCTS_ERROR,
    GET_FAVORITES_PRODUCTS_SUCCESS,
} from '../actionTypes';

const defaultState = {
    loading: null,
    error: {},
    data: [],
};

export default function (state = defaultState, action) {
    switch (action.type) {
    case GET_FAVORITES_PRODUCTS:
        return ({
            ...state,
            loading: true,
            error: {},
            data: action.reset ? [] : state.data,
        });

    case GET_FAVORITES_PRODUCTS_SUCCESS: {
        const products = action.data.products || [];

        return ({
            ...state,
            loading: false,
            data: state.data.concat(products.map(({ id }) => id)),
        });
    }

    case GET_FAVORITES_PRODUCTS_ERROR:
        return ({
            ...state,
            loading: false,
            error: action.error,
        });

    case ADD_TO_FAVORITES: {
        if (state.data.length === defaultQueryPayload.per || state.data.includes(
            action.id,
        )) return state;
        return ({
            ...state,
            data: [...state.data, action.id],
        });
    }
    default:
        return state;
    }
}

/* eslint-disable */
import {
    SET_CURRENT_SUBSCRIPTION_ID,
    REMOVE_CURRENT_SUBSCRIPTION_ID,
} from '../actionTypes';

const defaultState = null;

export default (state = defaultState, action) => {
    if (action.type === SET_CURRENT_SUBSCRIPTION_ID) {
        return action.subscriptionId;
    }

    if (action.type === REMOVE_CURRENT_SUBSCRIPTION_ID) {
        return defaultState;
    }

    return state;
};

import { ANALYTIC_EVENTS } from 'ggApp/modules/gaAnalytics/constants';
import { productForTeaserClick } from './product';

export const teaserEventMapper = ({
    widgetName = '',
    title,
    eventAction,
    url,
    clickType,
    product,
}) => {
    const mappedProduct = product ? productForTeaserClick(product) : {};
    const eventProperty = clickType ? { destination_url: url, clickType, ...mappedProduct } : url;
    return {
        eventLabel: title,
        event: ANALYTIC_EVENTS.gtmEvent,
        eventAction,
        eventProperty,
        ...(widgetName && { eventCategory: widgetName }),
    };
};

import React from 'react';
import { Svg, Path } from 'react-native-svg';
import { IconPropTypes } from 'ggApp/types/propTypes';
import { withTheme } from 'styled-components';

const AccountOutlineIcon = ({ color, size, className, theme }) => {
    const fill = color || theme.colors.Foreground;
    return (
        <Svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.62698 16.8365C5.84531 17.1237 6.08261 17.3958 6.33702 17.6507C6.48405 17.3332 6.65695 17.0302 6.85303 16.7444C7.97706 15.1057 9.86253 14.0312 12 14.0312C14.1375 14.0312 16.0229 15.1057 17.147 16.7444C17.343 17.0302 17.516 17.3332 17.663 17.6507C17.9174 17.3958 18.1547 17.1237 18.373 16.8365C19.394 15.4932 20 13.8174 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 13.8174 4.60601 15.4932 5.62698 16.8365ZM15.1129 19.3718C15.3667 19.2645 15.6137 19.1445 15.8533 19.0126C15.9089 18.9819 15.9641 18.9507 16.0189 18.9187C15.9846 18.8165 15.9464 18.716 15.9047 18.6173C15.8152 18.4062 15.7091 18.2038 15.5879 18.0118C14.8372 16.8217 13.5102 16.0312 12 16.0312C10.4885 16.0312 9.16211 16.8217 8.41181 18.0116C8.29074 18.2037 8.18466 18.4061 8.09523 18.6173C8.05347 18.7159 8.01533 18.8164 7.98099 18.9187C8.03582 18.9506 8.09107 18.9819 8.14672 19.0126C8.38627 19.1445 8.63331 19.2645 8.88704 19.3717C9.84388 19.7763 10.8958 20 12 20C13.1042 20 14.1561 19.7763 15.1129 19.3718ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM11.9995 10.9229C12.6929 10.9229 13.255 10.3608 13.255 9.66751C13.255 8.97418 12.6929 8.41211 11.9995 8.41211C11.3062 8.41211 10.7441 8.97418 10.7441 9.66751C10.7441 10.3608 11.3062 10.9229 11.9995 10.9229ZM11.9995 12.9229C13.7975 12.9229 15.255 11.4654 15.255 9.66751C15.255 7.8696 13.7975 6.41211 11.9995 6.41211C10.2016 6.41211 8.74414 7.8696 8.74414 9.66751C8.74414 11.4654 10.2016 12.9229 11.9995 12.9229Z"
                fill={fill}
            />
        </Svg>
    );
};

AccountOutlineIcon.propTypes = {
    ...IconPropTypes,
};

AccountOutlineIcon.defaultProps = {
    className: '',
    size: '24px',
};

export default withTheme(AccountOutlineIcon);

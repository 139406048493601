/* eslint-disable */
import { GET_CONTENTFUL_PAGE, GET_CONTENTFUL_PAGE_ERROR } from '../constants';

const initialState = null;

export function error(state = initialState, action) {
    const { type, error } = action;

    switch (type) {
        case GET_CONTENTFUL_PAGE:
            return initialState;
        case GET_CONTENTFUL_PAGE_ERROR:
            return { ...error };
        default:
            return state;
    }
}

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { user as userSelector } from 'ggApp/modules/auth/selectors';

import GaAuthenticationReporter from './GaAuthenticationReporter';

const enhance = compose(
    connect(
        createStructuredSelector({
            user: userSelector,
        }),
    ),
);

export default enhance(GaAuthenticationReporter);

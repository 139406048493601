import styled, { css } from 'styled-components';
import WidgetCta from 'ggApp/shared/components/widgets/cta';
import ContentfulImage from 'ggApp/shared/components/resolutionImage/ContentfulResolutionImage';

const Root = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 16px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.Foreground};
  &:hover, &:active, &:focus{
      outline: none;
  }
  ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
    padding: 0 ${theme.spacers.L};
  `};
`;

const ImageWrap = styled.div`
  padding-bottom: 66%;
  position: relative;
  height: 0;
  overflow: hidden;
  grid-column: auto / span 6;
  border-radius: 8px;
  ${({ theme }) => theme.superQuery.all.and.maxWidth.mobileMax.css`
    grid-column: 1 / -1;
    grid-row: 1;
  `};
`;

const Image = styled(ContentfulImage)`
    border-radius: 8px;
    height: 100%;
    width: 100%;
    position: absolute;
    > img {
        object-fit: cover;
    }
`;

const Content = styled.div`
  grid-column: auto / span 6;
  padding: 32px;
  max-width: 564px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  ${({ theme }) => theme.superQuery.all.and.maxWidth.mobileMax.css`
    grid-column: 1 / -1;
    grid-row: 2;
    padding: 16px 0 0;
  `};
`;

const Cta = styled(WidgetCta)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 16px;
  background-color: ${({ theme }) => theme.colors['Level 1']};
  border-radius: 88px;
  color: ${({ theme }) => theme.colors.Foreground};
  margin-top: 16px;
  cursor: pointer;
  ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
    margin-top: 12px;
  `};
`;

const Title = styled.h2`
 ${({theme}) => css(theme.typography.Headline)};
  line-height: 45px;
  ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
    font-size: 24px;
    line-height: 29px;
  `};
`;
const Description = styled.p`
  ${({theme}) => css(theme.typography.LargeParagraph)};
  font-size: 24px;
  font-weight: 400;
  margin-top: ${({ theme }) => theme.spacers.M};
  color: ${({ theme }) => theme.colors['Level 5']};
  ${({ theme }) => theme.superQuery.all.and.maxWidth.mobileMax.css`
    font-size: 16px;
    line-height: 23px;
  `};
`;

export {
    Root,
    ImageWrap,
    Image,
    Content,
    Cta,
    Title,
    Description,
}

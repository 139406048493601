import { ANALYTIC_EVENTS } from 'ggApp/modules/gaAnalytics/constants';
import { pushDataLayer } from 'ggApp/modules/tracking/actions';
import { ONE_YEAR_EXPIRATION_TIME_IN_DAYS } from 'ggApp/modules/auth/utils';
import { adaptReduxStorePayloadToGTMPayload } from '../utils/adapters';

export const registerNewCookiePolicy = () => {
    const eventPayload = {
        event: ANALYTIC_EVENTS.gtmEvent,
        eventCategory: 'new_cookie_consent',
        eventAction: 'impression',
        eventLabel: 'impression',
    };
    pushDataLayer(eventPayload);
};

export const userHasMarketingConsentCookie = () => {
    if (window) {
        const storageData = window.localStorage.getItem('ajs_user_traits');
        if (storageData) {
            const storageDataParsed = JSON.parse(storageData);
            // for old users with old cookie
            if (storageDataParsed.marketing_consent) {
                return true;
            }
        }
    }
    return false;
};

const remainingDaysInYear = (date) => {
    const myDate = new Date(date);
    const year = myDate.getFullYear();
    const firstJan = new Date(year, 0, 1);
    const differenceInMillieSeconds = myDate.getTime() - firstJan.getTime();
    return differenceInMillieSeconds / (1000 * 60 * 60 * 24) + 1;
};

export const showCookieBannerAfterCookieExpired = (consentTimestamp) => {
    const now = new Date();
    const duedate = new Date(consentTimestamp);
    duedate.setDate(
        now.getDate() +
            ONE_YEAR_EXPIRATION_TIME_IN_DAYS -
            Math.round(remainingDaysInYear(consentTimestamp)),
    );

    const cookieTTL = duedate.valueOf();
    if (now.valueOf() > cookieTTL) {
        return true;
    }
    return false;
};

export const registerAcceptAllCookies = () => {
    const eventPayload = {
        event: ANALYTIC_EVENTS.gtmEvent,
        eventCategory: 'new_cookie_consent',
        eventAction: 'acceptAll',
        eventLabel: {
            'marketing-cookie-consent': true,
            'preference-cookie-consent': true,
            'statistics-cookie-consent': true,
        },
    };
    pushDataLayer(eventPayload);
};

export const registerCookiePreferences = (reduxStorePreferences) => {
    const preferences = adaptReduxStorePayloadToGTMPayload(reduxStorePreferences);
    const eventPayload = {
        event: ANALYTIC_EVENTS.gtmEvent,
        eventCategory: 'new_cookie_consent',
        eventAction: 'customised_prefrence',
        eventLabel: preferences,
    };
    pushDataLayer(eventPayload);
};

export const syncGoogleConsentState = (preferences) => {
    const { marketingCookie: profillingCookie, statisticsCookie: analyticsCookies } =
        preferences || {};
    const formattedPreferences = {
        ad_storage: profillingCookie ? 'granted' : 'denied',
        ad_user_data: profillingCookie ? 'granted' : 'denied',
        ad_personalization: profillingCookie ? 'granted' : 'denied',
        analytics_storage: analyticsCookies ? 'granted' : 'denied',
    };
    if (window.gtag) window.gtag('consent', 'update', formattedPreferences);
};

export const getDefaultGoogleConsentStateFromCookie = (state) => {
    const { marketingCookie: profillingCookie, statisticsCookie: analyticsCookies } = state || {};
    return state
        ? {
              ad_storage: profillingCookie ? 'granted' : 'denied',
              ad_user_data: profillingCookie ? 'granted' : 'denied',
              ad_personalization: profillingCookie ? 'granted' : 'denied',
              analytics_storage: analyticsCookies ? 'granted' : 'denied',
          }
        : {
              ad_storage: 'denied',
              ad_user_data: 'denied',
              ad_personalization: 'denied',
              analytics_storage: 'denied',
          };
};

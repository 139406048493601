import { css } from 'styled-components';

const elevationTransitionDuration = '0.2s';
const elevationTransitionCurve = 'cubic-bezier(0.4, 0.0, 0.2, 1)';

const shadowColorTransparent1 = 'rgba(0, 0, 0, 0.05)';
const shadowColorTransparent2 = 'rgba(0, 0, 0, 0.13)';

const elevationBaseShadow = `0px 0px 3px ${shadowColorTransparent1}`;
const transparentBorder = css`
    border: 2px solid rgba(0, 0, 0, 0);
`;
const greyBorder = css`
    border: 2px solid #f1f1f1;
`;

export function elevation(
    level,
    border = true,
    shadowColor1 = shadowColorTransparent1,
    shadowColor2 = shadowColorTransparent2,
) {
    switch (level) {
        case 0:
            return css`
                box-shadow: 0px 0px 0px ${shadowColor1}, 0px 0px 0px ${shadowColor2};
                ${border ? greyBorder : ''}
            `;

        case 1:
            return css`
                box-shadow: 0px 2px 5px ${shadowColor2}, ${elevationBaseShadow};
                ${border ? transparentBorder : ''}
            `;

        case 2:
            return css`
                box-shadow: 0px 4px 10px ${shadowColor2}, ${elevationBaseShadow};
                ${border ? transparentBorder : ''}
            `;

        case 3:
            return css`
                box-shadow: 0px 7px 15px ${shadowColor2}, ${elevationBaseShadow};
                ${border ? transparentBorder : ''}
            `;
        default:
            return css``;
    }
}

export function elevationTransition(border = true) {
    if (border) {
        return css`
            transition: border ${elevationTransitionDuration} ${elevationTransitionCurve},
                box-shadow ${elevationTransitionDuration} ${elevationTransitionCurve};
        `;
    }
    return css`
        transition: box-shadow ${elevationTransitionDuration} ${elevationTransitionCurve};
    `;
}

/* eslint-disable */
const buildBirthdateFromFormData = birthdateObject => `${birthdateObject.day}-${birthdateObject.month}-${birthdateObject.year}`;

const adaptUserFormDataToAPIData = (formData = {}) => {
    const isBusinessCustomer = formData.is_business_customer;

    const { company = {} } = formData;
    const { address = {} } = company;

    const apiData = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        subscribed_to_newsletter: true,
        email: formData.email,
        password: formData.password,
        password_confirmation: formData.password,
        birthdate: buildBirthdateFromFormData(formData.birth),
    };

    if (isBusinessCustomer) {
        apiData.company = {
            name: company.name,
            ust: company.ust,
            hrv: company.hrv,
            rent_approximation: company.rent_approximation,
            address: {
                address1: address.street,
                address2: address.building,
                zipcode: address.zipcode,
                city: address.city,
                country_id: address.country_id,
            },
        };
    } else {
        apiData.gender = formData.gender;
    }

    return apiData;
};

const adaptSignupAPIErrorsToFormErrors = (apiErrors = {}) => {
    const {
        company: companyErrors = {},
        base: baseErrors = [],
        ...restApiErrors
    } = apiErrors;

    const {
        address: addressErrors = {},
        base: companyBaseErrors = [],
        ...restCompanyErrors
    } = companyErrors;

    const {
        address1: street,
        address2: building,
        base: addressBaseErrors = [],
        ...restAddressErrors
    } = addressErrors;

    const allBaseErrors = [
        ...baseErrors,
        ...companyBaseErrors,
        ...addressBaseErrors,
    ];

    const formErrors = {
        company: {
            address: {
                street,
                building,
                ...restAddressErrors,
            },
            ...restCompanyErrors,
        },
        _error: allBaseErrors.join(', '),
        ...restApiErrors,
    };

    return formErrors;
};

export {
    adaptUserFormDataToAPIData,
    adaptSignupAPIErrorsToFormErrors,
};

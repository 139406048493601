import styled from 'styled-components';
import { elevation, transition } from 'ggApp/utils/mixins';
import ArrowNextIcon from 'ggApp/shared/components/Carousel/images/arrowNext.svg';
import ArrowPrevIcon from 'ggApp/shared/components/Carousel/images/arrowPrev.svg';

export const Gap = styled.div`
    display: inline-block;
    max-height: 100%;
    min-width: ${({ width }) => width}px;
    max-width: 100%;
`;

const Arrow = styled.button`
    background-size: auto auto;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 45px;
    height: 45px;
    visibility: hidden;
    opacity: 0;
    background-color: ${({ theme }) => theme.colors.Background};
    border-radius: 50%;
    ${transition('all', 'medium-mobile', 'standard')};
    ${elevation(2, false)};
    @media (hover: hover) {
        &:hover {
            ${elevation(3, false)};
        }
    }
    cursor: pointer;
`;

export const NextArrow = styled(Arrow)`
    background-image: url(${ArrowNextIcon});
    position: absolute;
    top: calc(50% - 22.5px);
    right: 16px;
`;

export const PrevArrow = styled(Arrow)`
    background-image: url(${ArrowPrevIcon});
    position: absolute;
    top: calc(50% - 22.5px);
    left: 16px;
`;

export const Root = styled.div`
    position: relative;
    width: auto;
    overflow-x: scroll;
    display: flex;
    padding: 10px 0 10px 0;
    margin: -10px 0;
    scroll-padding: ${({ theme }) => theme.spacers.L};
    scroll-snap-type: x mandatory; /* Chrome Canary */
    scroll-snap-type-x: mandatory; /* Firefox */
    scroll-snap-destination: ${({ theme }) => theme.spacers.L} 50%; /* older (Firefox/IE) */
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        display: none;
        width: 0 !important;
    }
    scrollbar-width: none;

    @media not all and (any-pointer: coarse) {
        overflow-x: hidden;
        &:hover {
            ${Arrow}:not(:disabled) {
                visibility: visible;
                opacity: 1;
            }
        }
    }
`;

export const List = styled.div`
    display: flex;
    overflow: visible;
    transition: transform 500ms cubic-bezier(0.64, 0.04, 0.35, 1);
    width: 100%;
    @media (hover: hover) {
        max-width: 100%;
    }

    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
      padding: 0 ${theme.spacers.L};
    `};
`;

import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'fast-memoize';

import { extractCallbacksFromProps } from 'ggApp/modules/tracking/hocs/withListEventHandlers';
import DisplayIf from 'ggApp/shared/components/viewport';
import Carousel from 'ggApp/shared/components/Carousel';
import { PureTranslator } from 'ggApp/modules/translator/components';

import { Wrap, Title, Cta, Grid, ProductCard } from './styles';

export default class ProductCarouselWidget extends React.PureComponent {
    handleClickOnProduct = memoize((index) => () => {
        const { onClickProduct } = this.props;
        onClickProduct(index);
    });

    render() {
        const {
            className,
            title,
            products,
            cardStyle,
            cta: { label, url },
            productImpressionCallbacks,
            testId,
            onClickCTA,
        } = this.props;

        return (
            <Wrap className={className} data-testid={testId}>
                {title ? (
                    <Title>
                        <PureTranslator tk={title} />
                    </Title>
                ) : null}
                <DisplayIf tablet desktop largeDesktop widescreen>
                    <Grid>
                        {products.map((product, index) => (
                            <ProductCard
                                key={`${product?.slug}`}
                                product={product}
                                cardType="vertical"
                                cardStyle={cardStyle}
                                onClick={this.handleClickOnProduct(index)}
                                {...extractCallbacksFromProps(productImpressionCallbacks, index)}
                            />
                        ))}
                    </Grid>
                </DisplayIf>
                <DisplayIf mobile>
                    <Carousel
                        products={products}
                        cardWidth={280}
                        cardProps={{ cardStyle }}
                        productImpressionCallbacks={productImpressionCallbacks}
                    />
                </DisplayIf>
                {url ? (
                    <Cta
                        target="_self"
                        linkTo={url}
                        text={<PureTranslator tk={label} />}
                        onClick={onClickCTA}
                    />
                ) : null}
            </Wrap>
        );
    }
}

ProductCarouselWidget.propTypes = {
    title: PropTypes.string,
    cta: PropTypes.shape({
        internal: PropTypes.bool,
        label: PropTypes.string,
        url: PropTypes.string,
    }),
    store: PropTypes.shape({}),
    cardStyle: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
    onClickCTA: PropTypes.func,
    onClickProduct: PropTypes.func,
    productImpressionCallbacks: PropTypes.arrayOf(PropTypes.func),
};

ProductCarouselWidget.defaultProps = {
    title: '',
    cta: {},
    cardStyle: undefined,
    products: [],
    store: {},
    className: '',
    onClickCTA: () => null,
    onClickProduct: () => null,
    productImpressionCallbacks: [],
};

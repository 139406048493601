import React, { memo, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { FLOW_NAMES } from 'ggApp/modules/auth/v3';
import AuthProvider from 'ggApp/modules/auth/v3/components/AuthProvider';
import MetaTagsContainer from 'ggApp/shared/containers/metaTags';
import { fireEvent } from 'ggApp/modules/inwebview_api';
import { POST_EVENTS } from 'ggApp/modules/inwebview_api/constants';
import withWebView from 'ggApp/shared/hocs/withWebView';
import CircleLoader from 'ggApp/shared/components/CircleLoader';

import Footer from './Footer';

import { Layout, Content, StyledLogo, AuthCard, Background } from './Auth.styles';

const AuthFooterWithChecker = withWebView(Footer);

const AuthScreen = ({
    flow,
    metaPageInfo,
    redirect,
    redirectionPath,
    cancellationPath,
    accessToken,
    isLoggedIn,
    redirectionParam,
    newCheckoutFlag,
    loggedOutBrowsingFlag,
    initAppDone,
}) => {
    const flowProvider = useRef(null);

    useEffect(() => {
        fireEvent(POST_EVENTS.WEB_VIEW_LOADED, {});
    }, []);

    const stepperHooks = useRef({
        onEnter: () => {},
    });

    const createFlowProviderRef = useCallback((ref) => {
        flowProvider.current = ref;
    }, []);

    const handleSuccess = useCallback(() => {
        redirect(redirectionPath, true);
    }, [redirect, redirectionPath]);

    const handleCancel = useCallback(() => {
        redirect(cancellationPath, true);
    }, [redirect, cancellationPath]);

    useEffect(() => {
        if (initAppDone && isLoggedIn && !flow && redirectionParam) {
            handleSuccess();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initAppDone]);

    return (
        <Layout>
            <MetaTagsContainer metaTags={metaPageInfo} />
            <Background />

            <Content>
                <StyledLogo />

                <AuthCard>
                    {initAppDone ? (
                        <AuthProvider
                            loggedOutBrowsingFlag={loggedOutBrowsingFlag}
                            flow={flow || FLOW_NAMES.SIGN_IN}
                            onSuccess={handleSuccess}
                            onCancel={handleCancel}
                            stepperHooks={stepperHooks.current}
                            stepsFlowProviderRef={createFlowProviderRef}
                            options={{
                                accessToken,
                                isCheckout:
                                    redirectionParam.includes('checkout') && newCheckoutFlag,
                                hasUnskippablePhoneStep: [FLOW_NAMES.PHONE_NUMBER_UPDATE].includes(
                                    flow,
                                ),
                            }}
                        />
                    ) : (
                        <CircleLoader />
                    )}
                </AuthCard>

                <AuthFooterWithChecker />
            </Content>
        </Layout>
    );
};

AuthScreen.propTypes = {
    flow: PropTypes.oneOf(Object.keys(FLOW_NAMES)),
    redirect: PropTypes.func.isRequired,
    redirectionPath: PropTypes.string.isRequired,
    metaPageInfo: PropTypes.shape({
        title: PropTypes.string.isRequired,
    }).isRequired,
    accessToken: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    redirectionParam: PropTypes.string,
    newCheckoutFlag: PropTypes.bool,
    initAppDone: PropTypes.bool.isRequired,
};

AuthScreen.defaultProps = {
    flow: undefined,
    accessToken: null,
    isLoggedIn: false,
    newCheckoutFlag: false,
    redirectionParam: '',
};

export default memo(AuthScreen);

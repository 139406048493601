/* eslint-disable */
import React, { PureComponent, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import throttle from 'ggApp/utils/throttle';

import {
    Gap, NextArrow, PrevArrow, Root, List,
} from './styled';

const CARD_MARGIN = 16;

class Carousel extends PureComponent {
    static defaultProps = {
        cardWidth: 312,
        length: 3,
    };

    static propTypes = {
        cardWidth: PropTypes.number,
        length: PropTypes.number,
    };

    constructor(props) {
        super(props);
        this.throttledSetInnerWidth = throttle(this.setInnerWidth, 200);
        this.state = { productIndex: 0, perPage: 4, containerWidth: 0 };
    }

    componentDidMount() {
        if (this.rootRef) {
            this.setInnerWidth();
            window.addEventListener('resize', this.throttledSetInnerWidth);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.throttledSetInnerWidth);
    }

    setInnerWidth = () => {
        const containerWidth = this.rootRef.offsetWidth;
        const { cardWidth } = this.props;
        const perPage = Math.floor(
            (containerWidth + CARD_MARGIN) / (cardWidth + CARD_MARGIN),
        );
        if (this.state.perPage !== perPage) {
            this.setState({
                perPage: Math.max(perPage, 1),
                containerWidth,
            });
        }
    };

    goNext = () => {
        const { productIndex, perPage } = this.state;
        const { length: lastIndex } = this.props;
        if (lastIndex - perPage === productIndex) {
            this.setState({ productIndex: 0 });
            return;
        }
        this.setState({
            productIndex: Math.min(productIndex + perPage, lastIndex - perPage),
        });
    };

    goPrev = () => {
        const { productIndex, perPage } = this.state;
        this.setState({ productIndex: Math.max(productIndex - perPage, 0) });
    };

    render() {
        const { cardWidth, children, length } = this.props;
        const { productIndex, perPage, containerWidth } = this.state;
        const indexPosition = productIndex * (cardWidth + CARD_MARGIN);
        return (
            <Root ref={(el) => (this.rootRef = el)}>
                <List
                    style={{
                        transform: `translate3d(${-indexPosition}px, 0, 0)`,
                    }}
                >
                    {Children.toArray(children).map((child) => cloneElement(child, { cardWidth }))}
                    <Gap
                        width={Math.max(
                            containerWidth
                                + CARD_MARGIN
                                - (cardWidth + CARD_MARGIN) * perPage,
                            0,
                        )}
                    />
                </List>
                <NextArrow onClick={this.goNext} disabled={perPage >= length} />
                <PrevArrow
                    onClick={this.goPrev}
                    disabled={productIndex === 0}
                />
            </Root>
        );
    }
}

export default Carousel;

/* eslint-disable no-nested-ternary */
import React, { useRef } from 'react';
import { ButtonV2 } from '@getgrover/ui';
import Link from 'ggApp/shared/containers/FrontshopLink';

import { HOMEPAGE_MC1_AB_TEST, useFeatureFlags } from 'ggApp/modules/featureFlag';
import { registerTeaserClickEvent, registerTeaserImpressionEvent } from 'ggApp/modules/gaAnalytics';
import { WIDGET_NAMES } from 'ggApp/modules/gaAnalytics/constants';
import useIntersection from 'ggApp/shared/hooks/useIntersection';

import {
    Wrapper,
    ContentWrap,
    Content,
    Header,
    Body,
    Banner,
    CtaWrapper,
    RedCTAButton,
} from './styled';

export const CampaignsWidget = ({
    campaignBannerContent,
    className,
    clickClassName,
    bannerImageUrl,
}) => {
    const homepageMc1TestEnabled = useFeatureFlags();
    const campaignWidgetRef = useRef(null);

    const { title, text, textColor, cta, ctaColor } = campaignBannerContent;

    const link =
        homepageMc1TestEnabled?.isFlagEnabled(HOMEPAGE_MC1_AB_TEST) && cta.alternativeURL
            ? cta.alternativeURL
            : cta.url;

    useIntersection({
        node: campaignWidgetRef,
        onExitCallback: null,
        onEnterCallback: registerTeaserImpressionEvent({
            widgetName: WIDGET_NAMES.marketing1,
            url: link,
            title,
        }),
    });

    if (!cta) return null;

    const handleCtaCLick = registerTeaserClickEvent({
        widgetName: WIDGET_NAMES.marketing1,
        title,
        url: link,
    });

    return (
        <Wrapper className={className} ref={campaignWidgetRef}>
            <Banner
                imgUrls={bannerImageUrl}
                src={bannerImageUrl}
                lazy={false}
                backgroundImage={bannerImageUrl}
            />
            <ContentWrap>
                <Content>
                    <Header textColor={textColor}>{title}</Header>
                    <Body textColor={textColor} html={text} />
                    <Link
                        className={clickClassName}
                        external={!cta.internal}
                        target="_self"
                        to={link}
                        onClick={handleCtaCLick}
                    >
                        <CtaWrapper>
                            {ctaColor === 'White (outlined)' ? (
                                <ButtonV2 color="white" outlined>
                                    {cta.label}
                                </ButtonV2>
                            ) : ctaColor === 'Grey' ? (
                                <ButtonV2>{cta.label}</ButtonV2>
                            ) : (
                                <RedCTAButton color="red">{cta.label}</RedCTAButton>
                            )}
                        </CtaWrapper>
                    </Link>
                </Content>
            </ContentWrap>
        </Wrapper>
    );
};

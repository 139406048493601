/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';
import { canUseDOM } from 'ggApp/utils/dom';
import isFunction from 'ggApp/utils/function/isFunction';

if (canUseDOM()) {
    // eslint-disable-next-line global-require
    require('intersection-observer');
}

const DEFAULT_OPTIONS = {
    threshold: 1,
};

const useIntersection = ({ node = {}, options = {}, onEnterCallback, onExitCallback }) => {
    const [visible, setVisibilty] = useState({});
    const isIntersecting = useRef();

    const handleObserverUpdate = useCallback(
        (entries) => {
            const [ent] = entries;

            if (isIntersecting.current !== ent.isIntersecting) {
                setVisibilty(ent);
                isIntersecting.current = ent.isIntersecting;

                const threshold = options.threshold || 1;

                if (
                    isFunction(onEnterCallback) &&
                    isIntersecting &&
                    ent.intersectionRatio >= threshold
                ) {
                    onEnterCallback();
                } else if (
                    isFunction(onExitCallback) &&
                    (!isIntersecting || ent.intersectionRatio < threshold)
                ) {
                    onExitCallback();
                }
            }
        },
        [options.threshold, onEnterCallback, onExitCallback],
    );

    useEffect(() => {
        const observer = new IntersectionObserver(handleObserverUpdate, {
            ...DEFAULT_OPTIONS,
            ...options,
        });

        const element = node.current;

        if (element) {
            observer.observe(element);
        }

        return function cleanup() {
            if (element) {
                observer.unobserve(element);
            }
        };
    }, [node, handleObserverUpdate]);

    return visible;
};

export default useIntersection;

import { combineReducers } from 'redux';
import list from './list';
import returnLabel from './returnLabel';
import singleSubscription from './singleSubscription';
import rentalPlans from './rentalPlans';
import actions from './actions';
import currentSubscriptionId from './currentSubscriptionId';

export default combineReducers({
    list,
    actions,
    returnLabel,
    singleSubscription,
    rentalPlans,
    currentSubscriptionId,
});

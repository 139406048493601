/* eslint-disable @typescript-eslint/camelcase */
import { datadogLogs } from '@datadog/browser-logs';
import { AxiosResponse } from 'axios';
import ClientCookies from 'universal-cookie';

import { forgetAuthCredentials } from 'ggApp/modules/auth/utils';
import { injectCookiesToConfig } from 'ggApp/utils/CookiesManager';
import { isAppUser } from 'ggApp/routes/_wrap/MobileWrapper/utils';
import { fireEvent } from 'ggApp/modules/inwebview_api';
import { SHUTDOWN_INTERCOM } from 'ggApp/modules/intercom/constants/actionTypes';

import createAxiosInstance from './axiosFactory';

const axiosInstance = createAxiosInstance();

export interface RefreshTokenResult {
    accessToken: string;
    refreshToken: string;
    expiresInSeconds: number;
}

export const requestRefreshToken = (
    refreshToken: string,
    cookiesManager: ClientCookies,
): Promise<RefreshTokenResult> => {
    datadogLogs.logger.info('Performed API call for refreshAccessToken');

    const config = {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
    };

    injectCookiesToConfig(cookiesManager, config);

    return axiosInstance
        .post('/oauth/token', config)
        .then((response: AxiosResponse) => {
            const {
                access_token: newAccessToken,
                refresh_token: newRefreshToken,
                expires_in: expiresInSeconds,
            } = response.data;

            if (newAccessToken && newRefreshToken) {
                datadogLogs.logger.info('Received API response for refreshAccessToken');
                return {
                    accessToken: newAccessToken,
                    refreshToken: newRefreshToken,
                    expiresInSeconds,
                };
            }

            datadogLogs.logger.error(
                `API Error: refreshAccessToken: Unauthorized ${response.statusText}`,
            );
            throw new Error('Unauthorized');
        })
        .catch((error: unknown) => {
            if (!__IS_SERVER__) {
                fireEvent(SHUTDOWN_INTERCOM);
            }

            forgetAuthCredentials(cookiesManager);

            if (isAppUser(axiosInstance?.headers)) {
                datadogLogs.logger.error(`App user force logout in refreshAccessToken: ${error}`);
            }
        });
};

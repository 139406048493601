import { ApolloLink } from '@apollo/client';

import { DEVICE_ID_COOKIE_NAME } from 'ggApp/utils/DeviceIdCookie/constant';
import filterProps from 'ggApp/utils/object/filterProps';
import { getAccessToken } from 'ggApp/modules/auth/utils';
import { getOrderTokenCookie } from 'ggApp/modules/order/storage';

interface ContextType {
    headers?: Record<string, string>;
    accessToken?: string;
    cookiesManager: any;
}

function headersFilter(_: string, value: any): boolean {
    return typeof value === 'string';
}

const HeadersMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {}, cookiesManager, accessToken }: ContextType) => {
        const cookieAccessToken = getAccessToken(cookiesManager);

        const token = accessToken ?? cookieAccessToken;

        const newHeaders = {
            ...headers,
            authorization: Boolean(token) ? `Bearer ${token}` : null,
            'X-User-Agent': (window as any).ReactNativeWebView ? 'app' : 'web',
            'X-Grover-User': cookiesManager.get('user_id'),
            'X-Grover-Store': cookiesManager.get('shop_country_code'),
            'X-Grover-Language': cookiesManager.get('locale'),
            'X-Grover-Device-ID': cookiesManager.get(DEVICE_ID_COOKIE_NAME),
            'X-Grover-Session-ID': cookiesManager.get('ggId'),
            'X-Grover-Impersonated-User-Id': cookiesManager.get('impersonated_user_id'),
            'X-Grover-Impersonated-User-Company-Id': cookiesManager.get(
                'impersonated_user_company_id',
            ),
            'X-Grover-Order-Token': cookiesManager.get(
                getOrderTokenCookie(
                    cookiesManager.get('shop_country_code') || cookiesManager.get('x-grover-store'),
                ),
            ),
        };

        return {
            headers: filterProps(newHeaders, headersFilter),
        };
    });

    return forward(operation);
});

export default HeadersMiddleware;

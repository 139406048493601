export const MODULE_NAME = 'layout';
export const LG = 1280;
export const MD = 1023;
export const SM = 768;
export const XS = 480;
export const XSS = 0;
export const BODY_OPEN_CLASS = 'ggModalOpen';
export const HTML_OPEN_CLASS = 'ggModalOpen';

export const BODY_OVERLAY_OPEN_CLASS = 'ggBodyOverlayOpen';
export const HTML_OVERLAY_OPEN_CLASS = 'ggHtmlOverlayOpen';

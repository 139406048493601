import styled from 'styled-components';
import { Card as WidgetProductCardComponent } from 'ggApp/shared/components/cards/widgetProductCard';
import { elevation, transition } from 'ggApp/utils/mixins';
import ArrowNextIcon from 'ggApp/shared/components/Carousel/images/arrowNext.svg';
import ArrowPrevIcon from 'ggApp/shared/components/Carousel/images/arrowPrev.svg';

export const ProductCard = styled(WidgetProductCardComponent)`
  scroll-snap-align: start; /* latest (Chrome 69+) */
  scroll-snap-coordinate: 0% 50%; /* older (Firefox/IE) */
  -webkit-scroll-snap-coordinate: 0% 50%; /* older (Safari) */
  :not(:nth-last-child(1)):not(:nth-last-child(2)) {
    margin-right: 16px;
  }
`;

export const Gap = styled.div`
    display: inline-block;
    max-height: 100%;
    min-width: ${({ width }) => width}px;
    max-width: 100%;
`;


const Arrow = styled.button`
    background-size: auto auto;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 45px;
    height: 45px;
    visibility: hidden;
    opacity: 0;
    background-color: #fff;
    border-radius: 50%;
    ${transition('all', 'medium-mobile', 'standard')};
    ${elevation(2, false)};
    @media (hover: hover) {
        &:hover {
            ${elevation(3, false)};
        }
    }
    cursor: pointer;
`;

export const NextArrow = styled(Arrow)`
    background-image: url(${ArrowNextIcon});
    position: absolute;
    top: calc(50% - 22.5px);
    right: 24px;
`;

export const PrevArrow = styled(Arrow)`
    background-image: url(${ArrowPrevIcon});
    position: absolute;
    top: calc(50% - 22.5px);
    left: 16px
`;


export const Root = styled.div`
  position: relative;
  width: auto;
  overflow-x: scroll;
  display: flex;
  scroll-padding: ${({ theme }) => theme.spacers.L};
  scroll-snap-type: x mandatory; /* Chrome Canary */
  scroll-snap-type-x: mandatory; /* Firefox */
  scroll-snap-destination: calc(${({ theme }) => theme.spacers.L} + 4px) 50%; /* older (Firefox/IE) */
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar { display: none; width:  0 !important; }
  scrollbar-width: none;

  ${({ theme }) => theme.superQuery.all.and.maxWidth.mobileMax.css`
      margin-right: 0;
  `};

  @media not all and (any-pointer: coarse) {
    overflow-x: hidden;
      &:hover {
          ${Arrow}:not(:disabled) {
              visibility: visible;
              opacity: 1;
          }
      }
  }

  ${({ theme }) => theme.superQuery.all.and.minWidth.tabletMin.css`
    padding: ${theme.spacers.L};
    margin: calc(${theme.spacers.L} * -1);
  `};
`;

export const List = styled.div`
  display: flex;
  overflow: visible;
  transition: transform 500ms cubic-bezier(0.64, 0.04, 0.35, 1);
  width: 100%;
  @media (hover: hover) {
    width: auto;
  }

  ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
    padding: 0 ${theme.spacers.L};
  `};
`;

import styled, { css } from 'styled-components';
import { TinyParagraph } from 'ggApp/shared/common/Typography';

export const Suffixes = styled.span`
    color: ${({ theme }) => theme.colors['Level 5']};
    display: inline;
    ${({ en }) => en
        && css`
            text-transform: lowercase;
            &:before {
                content: '';
                width: 0.3ch;
            }
        `}
`;

export const PlanPriceText = styled(TinyParagraph)`
    line-height: 13px;

    ${({ handpicked, theme }) => handpicked
        && css`
            ${theme.typography.LargeParagraph}
            font-size: 24px;
            font-weight: 400;
            margin-bottom: ${theme.spacers.L};
            line-height: 35px;
        `}
    color:  ${({ textColor, theme }) => textColor || theme.colors['Level 5']};
`;

export const OldPrice = styled.span`
    ${({ theme }) => css(theme.typography.SuperTinyHeadline)};
    color: ${({ theme }) => theme.colors.Foreground};
    ${({ handpicked }) => handpicked
        && css`
            ${props => props.theme.typography.Subheadline}
        `}

    text-decoration: line-through;

    &::before,
    &::after {
        content: '';
        display: inline-block;
        width: 0.3ch;
    }
`;

export const Price = styled.span`
    ${({ theme }) => css(theme.typography.SuperTinyHeadline)}
    color: ${({ theme }) => theme.colors.Foreground};

    white-space: nowrap;
    &::before {
        content: ' ';
    }

    ${({ withOldPrice, accentColor, textColor }) => withOldPrice
        && css`
            color: ${accentColor || textColor || '#FE1251'};
            &::before {
                content: '';
            }
        `}

    ${({ handpicked }) => handpicked
        && css`
            ${props => props.theme.typography.Headline}
        `}
`;

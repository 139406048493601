/* eslint-disable */
import { CURRENCIES_ASSOCIATION, } from 'ggApp/modules/store/constants';
import { RESET_ORDER_TO_DEFAULT_STATE, } from "../actionTypes";


const defaultState = {};

export default function (state = defaultState, action) {
  switch (action.type) {

    case 'GET_ORDER_SUCCESS': {
      const
        currencySymbol = action.data.order.currency
          ? { currencySymbol: (CURRENCIES_ASSOCIATION[action.data.order.currency] || '€'), }
          : {};

      return Object.assign({}, action.data.order, currencySymbol);
    }

    case 'UPDATE_ORDER_SUCCESS':
      return Object.assign({}, state, action.data.order);

    case RESET_ORDER_TO_DEFAULT_STATE:
        return { ...defaultState, };

    default:
      return state;
  }
}

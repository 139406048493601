import React, { useEffect, useState, useCallback } from 'react';
import { sendProductClickEvent } from 'snowplow';
import { productDataMapper } from 'snowplow/events/productClick/productDataMapper';
import { getOrderFromCookiesBasedStore } from 'ggApp/shared/context/Order/cookies';

import { PureTranslator } from 'ggApp/modules/translator';
import { Root, CardRoot, DefaultTitle, Carousel } from './styled';

const RecentlyView = ({
    className,
    spaFeatureFlag,
    getProducts,
    location: { pathname },
    routerParams = {},
    activeStore,
    userID,
    getProductsGraphQLData,
    placement = 'bottom',
}) => {
    const [productList, setProducts] = useState([]);
    const [productHash, setProductHash] = useState('');
    const [loading, setLoading] = useState(false);

    const updateProductList = useCallback(() => {
        setLoading(true);
        getProducts()
            .then((result) => {
                if (result) {
                    setProducts(result);
                    setProductHash(pathname);

                    const skuList = result.map(({ sku }) => sku);
                    getProductsGraphQLData(skuList);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [getProducts, pathname, getProductsGraphQLData]);

    useEffect(() => {
        if (productHash !== pathname) {
            updateProductList();
        }
    }, [updateProductList, pathname, productHash]);

    const handleProductClick = ({ slug }) => {
        const clickedProduct = productList.find((product) => product.slug === slug);
        const orderID = getOrderFromCookiesBasedStore(activeStore.code);
        const productData = productDataMapper({
            product: clickedProduct,
            storeCode: activeStore.code,
        });
        sendProductClickEvent({
            orderID,
            userID,
            list: 'Recently Viewed',
            productData,
        });
        if (window.scrollTo && slug === routerParams.productSlug && spaFeatureFlag) {
            window.scrollTo(0, 0);
        }
    };
    if (!productList || productList.length < 1 || loading) return null;
    return (
        <Root className={className}>
            <CardRoot placement={placement}>
                <DefaultTitle>
                    <PureTranslator tk="RECENTLY_VIEW_SECTION_TITLE" />
                </DefaultTitle>
                <Carousel products={productList} onClickItem={handleProductClick} />
            </CardRoot>
        </Root>
    );
};

export default RecentlyView;

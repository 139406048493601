import styled from 'styled-components';
import { HeaderUI } from '@devsbb/header-ui';

export const HeaderWrapper = styled(HeaderUI)`
    width: 100%;
    max-width: 1600px;
    margin: auto;

    @media (${({ theme }) => theme.screens.desktop}) {
        padding: 0 72px;
    }
`;

export const LoaderWrapper = styled.div`
    max-width: 1600px;
    margin: auto;
    padding: 12px 16px 0px;
    @media (${({ theme }) => theme.screens.desktop}) {
        padding: 0 72px;
    }
`;

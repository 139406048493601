/* eslint-disable */
import {
    SELECT_PRODUCT_PROPERTY, GET_PRODUCT_BY_ID_SUCCESS
} from './../actionTypes';

const defaultState = 0;

export default function (state = defaultState, action) {
  switch (action.type) {

    case SELECT_PRODUCT_PROPERTY: {
        return action.variantId;
    }

    case GET_PRODUCT_BY_ID_SUCCESS: {
        return defaultState;
    }

    default:
      return state;
  }
}

/* eslint-disable */
import {
    compose,
    branch,
    renderNothing,
    withProps,
} from 'recompose';

import parseToRgb from 'ggApp/utils/color/helpers/parseToRgb';

import Link from './Link';

const getAccentColorWithAlpha = (accentColor, alphaValue) => {
    const { red, green, blue } = parseToRgb(accentColor);
    return `rgba(${red},${green},${blue},${alphaValue})`;
};

const enhance = compose(
    branch(({ url }) => !url, renderNothing),
    withProps(({ accentColor }) => ({
        backgroundColor: accentColor
            ? getAccentColorWithAlpha(accentColor, 0.1)
            : null,
        shadowColor: accentColor
            ? getAccentColorWithAlpha(accentColor, 0.25)
            : null,
    })),
);

export default enhance(Link);

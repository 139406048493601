import React, { Component } from 'react';
import { browserHistory, Link, withRouter } from 'react-router';
import PropTypes from 'prop-types';

import { storeLangUrl } from 'ggApp/utils/storeLangInjection';
import { spaFeatureFlag } from 'ggApp/modules/featureFlag/selectors';
import { connect } from 'react-redux';

/** Detects if a URL is absolute
 * A dumb non-universal implementation that should be
 * used only in this component cause
 * is solved only a specific use-case
 */
const pathRegexp = /^https?:\/\//i;
const isFrontshopAbsoluteLink = (url) => pathRegexp.test(url);

@withRouter
@connect((state) => ({
    /* This is temporary while we figure out a way to
    allow the server side know the user context (App or Web)
    as window.ReactNativeWebView doesn't work for this case */
    enableSpa: window.ReactNativeWebView || spaFeatureFlag(state),
}))
class FrontshopLink extends Component {
    // eslint-disable-next-line react/static-property-placement
    static contextTypes = {
        store: PropTypes.shape({}),
    };

    renderBrowserLink = (to) => {
        const { router, children, onClick, style, target } = this.props;
        let { className } = this.props;
        const { id } = this.props;
        className = className || '';
        if (router && router.isActive && router.isActive(to)) {
            className = `${className} active`;
        }

        return (
            <a
                className={className}
                id={id}
                style={style}
                href={to}
                onClick={onClick}
                target={target}
            >
                {children}
            </a>
        );
    };

    linkOnClick = (e) => {
        e.stopPropagation();
        const { onClick } = this.props;
        if (typeof onClick === 'function') {
            onClick(e);
        }
    };

    render() {
        const { store } = this.context;
        const {
            to,
            goBack,
            external,
            className,
            target,
            style,
            onClick,
            enableSpa,
            children,
            browserLink,
            id,
        } = this.props;

        // This turnes OFF all client-side navigation via react-router
        const isBrowserLink = !enableSpa || browserLink;

        if (goBack) {
            return (
                <div
                    className={className}
                    id={id}
                    style={style}
                    onKeyPress={browserHistory.goBack}
                    onClick={browserHistory.goBack}
                    role="link"
                    tabIndex="0"
                >
                    {children}
                </div>
            );
        }

        if (external || isFrontshopAbsoluteLink(to)) {
            return (
                <a
                    id={id}
                    className={className}
                    style={style}
                    href={to}
                    target={target}
                    onClick={onClick}
                >
                    {children}
                </a>
            );
        }

        const newTo =
            typeof to === 'string'
                ? storeLangUrl(store, to)
                : { ...to, pathname: storeLangUrl(store, to.pathname) };

        return !isBrowserLink ? (
            <Link
                id={id}
                className={className}
                onClick={this.linkOnClick}
                to={newTo}
                target={target}
            >
                {children}
            </Link>
        ) : (
            this.renderBrowserLink(newTo)
        );
    }
}

FrontshopLink.propTypes = {
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    external: PropTypes.bool,
    router: PropTypes.shape({}),
    onClick: PropTypes.func,
    style: PropTypes.shape({}),
    goBack: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
    target: PropTypes.string,
    enableSpa: PropTypes.string,
    children: PropTypes.node,
    browserLink: PropTypes.bool,
};

FrontshopLink.defaultProps = {
    browserLink: false,
    external: false,
    goBack: '',
    className: '',
    id: null,
    target: '',
    enableSpa: '',
    router: null,
    onClick: null,
    style: null,
    children: null,
};

export default FrontshopLink;

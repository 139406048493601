export function buildProductDictionary(productList) {
    return (productList || []).reduce(
        (result, product) => {
            const { id, slug } = product;
            if (id == null || !slug) return result;
            return {
                productsById: { ...result.productsById, [id]: product },
                idsBySlug: { ...result.idsBySlug, [slug]: id },
            };
        },
        {
            productsById: {},
            idsBySlug: {},
        },
    );
}

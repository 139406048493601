import { LOGIN } from 'ggApp/modules/auth/actionTypes';
import { setGaUserID } from 'ggApp/modules/gaAnalytics';
import CookiesManager from 'ggApp/utils/CookiesManager';

const isServer = __IS_SERVER__;

export default function loginMiddleware() {
    return (next) => (action) => {
        if (!isServer) {
            const cookiesManager = new CookiesManager();
            const { type, data } = action;
            if (type === LOGIN) {
                if (!cookiesManager.get('user_id', { path: '/' })) {
                    cookiesManager.set('user_id', data.id);
                }
                if (!cookiesManager.get('user_type', { path: '/' })) {
                    cookiesManager.set('user_type', data.user_type);
                }
                setGaUserID(data.id);
            }
        }
        next(action);
    };
}

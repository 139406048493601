import React from 'react';
import ContentWrap from './styles';

const LayoutContentWrap = ({ className, children, minHeight }) => (
    <ContentWrap className={className} minHeight={minHeight}>
        {children}
    </ContentWrap>
);

export default LayoutContentWrap;

import React, { PureComponent } from 'react';

import { Grid } from './styles';

export default class LayoutGrid extends PureComponent {
    render() {
        const { className, style, children, noMargin } = this.props;
        return (
            <Grid className={className} noMargin={noMargin} style={style}>
                {children}
            </Grid>
        );
    }
}

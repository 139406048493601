import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import GroverTransparentLogo from 'ggApp/shared/icons/GroverTransparentLogo';

const GroverLogo = ({ theme, className }) => (
    <GroverTransparentLogo className={className} color={theme.colors['Level 3']} />
);

GroverLogo.propTypes = {
    className: PropTypes.string,
    theme: PropTypes.shape({
        colors: PropTypes.shape({}).isRequired,
    }).isRequired,
};

GroverLogo.defaultProps = {
    className: '',
};

export default withTheme(GroverLogo);

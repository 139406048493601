import { compose, withProps, branch, renderNothing } from 'recompose';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { location as locationSelector } from 'ggApp/modules/router/selectors';
import {
    userCompany as userCompanySelector,
    user as userSelector,
} from 'ggApp/modules/auth/selectors';
import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';

import withDeviceInfo from 'ggApp/shared/components/viewport/withDeviceInfo';
import Mobile from './Mobile';

const enhance = compose(
    withDeviceInfo,
    connect(
        createStructuredSelector({
            location: locationSelector,
            company: userCompanySelector,
            user: userSelector,
            activeStore: activeStoreSelector,
        }),
    ),
    withProps(({ unreadMessages: unread }) => ({
        newMessages: unread > 0,
    })),
    withProps(({ userDevice }) => ({
        isDesktopUser: userDevice !== 'mobile' && userDevice !== 'tablet',
        isMobileUser: userDevice === 'mobile',
    })),
    withProps(({ activeStore: { code, store_id: storeId } }) => ({
        storeCode: code,
        storeId,
    })),
    branch(({ isDesktopUser }) => isDesktopUser, renderNothing),
);

export default enhance(Mobile);

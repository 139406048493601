/* eslint-disable */
import React from 'react';

/**
 * Hoc for omit props
 */
const omitProps = (...propsToOmit) => Component => (props) => {
    const filteredProps = {};

    Object.keys(props).forEach((propName) => {
        if (!propsToOmit.includes(propName)) {
            filteredProps[propName] = props[propName];
        }
    });

    return <Component {...filteredProps} />;
};

export default omitProps;

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import smoothscroll from 'smoothscroll-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { browserHistory } from 'react-router';
import { ReduxRouter, reduxReactRouter } from 'redux-router';
import Loadable from 'react-loadable';
import { ApolloProvider } from '@apollo/client';

import { ThemeProvider } from 'ggApp/shared/components/ThemeContext';
import WithUserDevice from 'ggApp/shared/components/viewport/withUserDevice';
import { CartContextProvider } from 'ggApp/shared/components/CartSummary/Context';
import { OrderController } from 'ggApp/shared/context/Order/OrderController';
import { lightTheme } from 'ggApp/shared/themes';
import createFrontshopRoutes from 'ggApp/frontshopRoutes';
import reducers from 'ggApp/reducers';
import BrowserDetect from 'ggApp/utils/browserDetect/BrowserDetect';
import CookiesManager, { CookiesManagerContext } from 'ggApp/utils/CookiesManager';
import { setDeviceIdCookie } from 'ggApp/utils/DeviceIdCookie';
import createApolloClient from 'graphql-client';
import serviceWorkerRegister from 'ggApp/utils/serviceWorkerRegister';
import { InternalToolsContextProvider } from 'ggApp/shared/context/internalTools/context';
import { LanguageProvider } from 'ggApp/shared/context/language';
import { TranslatorProvider } from 'ggApp/modules/translator/components/TranslatorContext';
import { FeatureFlagProvider } from 'ggApp/modules/featureFlag';

import { initDatadog, initDatadogRum } from 'datadog';
import { initWebviewAuthListeners } from './ggApp/modules/inwebview_api/auth-listeners';
import middlewares from './_middlewares';

initDatadog();
initDatadogRum();
initWebviewAuthListeners();
smoothscroll.polyfill();
setDeviceIdCookie();

const devtoolsComposeFunction = '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__';
const reducer = combineReducers(reducers);
const composeEnhancers =
    typeof window === 'object' && window[devtoolsComposeFunction]
        ? window[devtoolsComposeFunction]({})
        : compose;
const enhancer = composeEnhancers(
    applyMiddleware(...middlewares),
    reduxReactRouter({
        createHistory: () => browserHistory,
    }),
);
// eslint-disable-next-line no-underscore-dangle
const store = createStore(reducer, window.__INITIAL_STATE__, enhancer);
const cookiesManager = new CookiesManager();
const apolloClient = createApolloClient(cookiesManager);

serviceWorkerRegister();

Loadable.preloadReady().then(() => {
    ReactDOM.render(
        <Provider store={store}>
            <CookiesManagerContext.Provider value={cookiesManager}>
                <ApolloProvider client={apolloClient}>
                    <WithUserDevice>
                        <CartContextProvider>
                            <OrderController>
                                <ThemeProvider theme={lightTheme}>
                                    <BrowserDetect store={store}>
                                        <LanguageProvider>
                                            <TranslatorProvider>
                                                <InternalToolsContextProvider>
                                                    <FeatureFlagProvider>
                                                        <ReduxRouter>
                                                            {createFrontshopRoutes(store.dispatch)}
                                                        </ReduxRouter>
                                                    </FeatureFlagProvider>
                                                </InternalToolsContextProvider>
                                            </TranslatorProvider>
                                        </LanguageProvider>
                                    </BrowserDetect>
                                </ThemeProvider>
                            </OrderController>
                        </CartContextProvider>
                    </WithUserDevice>
                </ApolloProvider>
            </CookiesManagerContext.Provider>
        </Provider>,
        document.getElementById('groverApp'),
    );
});

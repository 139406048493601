/* eslint-disable */
import Bowser from 'bowser';

export const checkBrowserCompat = (userAgent, normalizedBrowserList) => {
    if (!normalizedBrowserList) { throw new Error('browserlist is empty') }
    try {
        const browser = Bowser.getParser(userAgent);
        const result = browser.satisfies(normalizedBrowserList)
        if (typeof result !== 'boolean') { throw new Error('Can not detect browser') }
        return ({ result });
    } catch (error) {
        return ({ error });
    }
};

export const clientUserAgent = window && window.navigator && window.navigator.userAgent;
export const CONTINUE_WITH_UNSUPPORTED_BROWSER = 'continue_with_unsupported_browser';

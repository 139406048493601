import SuperQuery from '@themgoncalves/super-query';

import flatten from 'ggApp/utils/flattenObject';
import breakpoints from 'ggApp/shared/components/viewport/breakpoints';
import { screens, screenSizes } from './screens';

const flattenedBreakpoints = flatten(breakpoints);

const theme = {
    breakpoints,
    superQuery: SuperQuery(flattenedBreakpoints),
    screens,
    screenSizes,
    colors: {
        grey75: 'rgba(102, 102, 102, 0.75)',
        grey50: '#989898',
        yolk120: '#EDA210',
        red1: '#f44336',
        gray: {
            50: 'rgba(51, 51, 51, 0.5)',
            75: 'rgb(51, 51, 51, 0.75)',
            100: 'rgb(51, 51, 51)',
        },
    },
    maxWidth: {
        maxWidth: '1600px',
        margin: 'auto',
    },
};

export default theme;

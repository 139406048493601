import React from 'react';

import { Grid } from './styles';

export default class WidgetGrid extends React.PureComponent {
    render() {
        const { className, children, noMargin, testId } = this.props;
        return (
            <Grid className={className} noMargin={noMargin} data-testid={testId}>
                {children}
            </Grid>
        );
    }
}

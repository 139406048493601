/* eslint-disable */
import capitalizeFirstLetter from '../capitalizeFirstLetter';

/**
 * flattenObject
 * @function flattenObject
 * @param {object} object to be flattened
 * @example
 * const targetObj = { person: { name: 'John', age: '36' }, };
 * console.log(flattenObject(targetObj));
 * // outputs
 * // {
 * //   personName: 'Johan',
 * //   personAge: '36',
 * // }
 * @returns flattened object
 */
export default function flattenObject(object) {
    // spread the result into our return object
    return Object.assign({}, ...function _flatten(objectBit, path = '') {
        // concat everything into one level
        return !objectBit ? [] : [].concat(
            // iterate over object
            ...Object.keys(objectBit).map(
                // check if there is a nested object
                key => typeof objectBit[key] === 'object' ?
                    // call itself if there is
                    _flatten(objectBit[key], `${ path }${ key }`) :
                    // append object with it’s path as key
                    ({[`${ path }${capitalizeFirstLetter(key)}`]: objectBit[key],})
            )
        )
    }(object));
}

import styled from 'styled-components';

import { withProductListMapper } from 'ggApp/shared/components/cards/widgetProductCard/withProductAdapter';

import CarouselV1 from 'ggApp/shared/components/Carousel';

export const Root = styled.section``;

export const CardRoot = styled.div``;

export const DefaultTitle = styled.h4`
    ${({ theme }) => theme.typography.Headline};
    font-size: 32px;
    line-height: 1.3;
    color: ${({ theme }) => theme.colors.Foreground};
    margin-bottom: ${({ theme }) => theme.spacers.L};
    > span {
        font-size: inherit;
    }
    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
        ${theme.typography.Subheadline};
        margin-bottom: ${theme.spacers.L};
        padding: 0 ${theme.spacers.L};
        > span {
            font-size: inherit;
        }
    `};
`;
export const Carousel = styled(withProductListMapper(CarouselV1))`
    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
        overflow-x: scroll;
    `};
`;

/* eslint-disable */
import slugEditor from 'slugg';
import {
  STATUS_ACTIVE,
  STATUS_OVERDUE,
  STATUS_CLOSED,
  STATUS_ON_HOLD,
  STATUS_PENDING_SHIPMENT,
  STATUS_SHIPPED,

  STATUS_ACTIVE_SETUP,
  STATUS_OVERDUE_SETUP,
  STATUS_CLOSED_SETUP,
  STATUS_ON_HOLD_SETUP,
  STATUS_PENDING_SHIPMENT_SETUP,
  STATUS_SHIPPED_SETUP,
} from './constants';

export const actionsByStatus = item => {
  const
    slug = slugEditor(item.status),
    actions = {
      canChangePaymentMethod: item.eligible_for_ctas_except_label,
      canRequestReturnLabel: item.eligible_for_return_label_request,
      canPurchase: item.eligible_for_ctas_except_label,
      canClaimAnAccident: item.eligible_for_ctas_except_label,
    }

  switch (slug) {
    case STATUS_ACTIVE:
      return ({
        actions,
        setup: STATUS_ACTIVE_SETUP,
      });

    case STATUS_OVERDUE:
      return ({
        actions,
        setup: STATUS_OVERDUE_SETUP,
      });

    case STATUS_ON_HOLD:
      return ({
        actions,
        setup: STATUS_ON_HOLD_SETUP,
      });

    case STATUS_PENDING_SHIPMENT:
      return ({
        actions,
        setup: STATUS_PENDING_SHIPMENT_SETUP,
      });

    case STATUS_SHIPPED:
      return ({
        actions,
        setup: STATUS_SHIPPED_SETUP,
      });

    case STATUS_CLOSED:
      return ({
        actions,
        setup: STATUS_CLOSED_SETUP,
      });

    default:
      return {};
  }
}

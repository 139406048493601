/* eslint-disable camelcase */
import { createSelector } from 'reselect';

import { shouldShowNationalIdRegistration } from 'staticStoreConfig';
import { authWithIDVerificationFeatureFlag } from 'ggApp/modules/featureFlag/selectors';
import { storeActive } from 'ggApp/modules/store/selectors';

import { VerificationState } from '../types';
import { MODULE_NAME } from '../constants';
import { ACCOUNT_TYPES, COMPANY_STATUSES, FLOW_NAMES } from '../v3/constants';

export const moduleState = (state) => state[MODULE_NAME];

export const user = createSelector(moduleState, (state) => state.user || {});

export const phoneVerification = createSelector(moduleState, (state) => state.phoneVerification);

export const fetching = createSelector(moduleState, (state) => state.fetching);

export const passwordRecovery = createSelector(moduleState, (state) => state.passwordRecovery);

export const newsletter = createSelector(moduleState, (state) => state.newsletter);

export const userId = (state) => user(state)?.id;

export const isLoggedIn = (state) => Boolean(userId(state));

export const userPhoneNumber = createSelector(user, (userState) => {
    const { phone, phone_verified: phoneVerified } = userState ?? {};

    return phoneVerified ? `+${phone}` : null;
});

export const userCompany = createSelector(user, (userState) => {
    const { company } = userState ?? {};

    return company ?? {};
});

export const companyTypeIsIndividual = createSelector(
    userCompany,
    (company) => company.company_type?.individual ?? false,
);

export const isVerifiedLegalRepresentative = createSelector(
    userCompany,
    authWithIDVerificationFeatureFlag,
    (company, useIdVerificationFeature) => {
        // In legacy mode all business accounts are verified
        if (!useIdVerificationFeature) {
            return true;
        }

        // A company of a business account with not added documents is not verified
        return company.status !== COMPANY_STATUSES.PENDING_DOCUMENTS;
    },
);

export const isVerifiedPrivateAccount = createSelector(
    user,
    (userState) => userState?.verification_state === VerificationState.Verified,
);

export const isPrivateAccountVerificationInProgress = createSelector(
    user,
    (userState) => userState?.verification_state === VerificationState.InProgress,
);

export const verificationProvider = createSelector(
    user,
    (userState) => userState?.verification_provider,
);

export const isIncompleteBusinessAccount = createSelector(
    user,
    userCompany,
    isLoggedIn,
    isVerifiedLegalRepresentative,
    (userPayload, company, isLoggedInState, isVerifiedLegalRep) => {
        // By the agreement, unauthorized user has incomplete account
        if (!isLoggedInState) {
            return true;
        }

        const { phone, user_type: accountType } = userPayload;

        // If business customer has no company or phone number then it's incomplete account
        // Business account with pending verification is incomplete too
        if (accountType === ACCOUNT_TYPES.BUSINESS_CUSTOMER) {
            const incompleteAccountConditions = [
                !company.id,
                !phone,
                !company.address,
                !isVerifiedLegalRep,
                company.status === COMPANY_STATUSES.INCOMPLETE,
            ];

            // Account is incomplete if at least one of conditions is true
            return incompleteAccountConditions.some(Boolean);
        }

        // In the other case it's complete account;
        return false;
    },
);

export const isIncompletePrivateAccount = createSelector(
    user,
    isLoggedIn,
    storeActive,
    (userPayload, isLoggedInState, activeStore) => {
        if (!isLoggedInState) {
            return true;
        }

        const { code } = activeStore;
        const {
            user_type: accountType,
            phone,
            personal_identifications: personalIds,
        } = userPayload;
        const hasNationalId =
            Array.isArray(personalIds) &&
            personalIds.filter((x) => x.identification_type === 'national_id').length > 0;

        if (accountType === ACCOUNT_TYPES.PRIVATE_CUSTOMER) {
            if (shouldShowNationalIdRegistration(code)) {
                return !phone || !hasNationalId;
            }

            return !phone;
        }

        return false;
    },
);

export const isLegacyBusinessAccount = createSelector(user, userCompany, (userPayload, company) => {
    const { user_type: accountType } = userPayload;

    return (
        accountType === ACCOUNT_TYPES.BUSINESS_CUSTOMER &&
        company.company_type?.name.toLowerCase() === 'legacy'
    );
});

export const companyLegalApproval = createSelector(moduleState, (state) => state.company ?? {});

export const b2bRedirection = createSelector(moduleState, (state) => state.b2bRedirection);

export const authFlow = createSelector(
    isLoggedIn,
    isIncompleteBusinessAccount,
    authWithIDVerificationFeatureFlag,
    userCompany,
    isIncompletePrivateAccount,
    isLegacyBusinessAccount,
    (
        isLoggedInState,
        isIncompleteBusinessAccountState,
        authWithIDVerificationFeatureFlagState,
        userCompanyState,
        isIncompletePrivateAccountState,
        isLegacyBusinessAccountState,
    ) => {
        if (!isLoggedInState) {
            return FLOW_NAMES.SIGN_IN_FROM_CHECKOUT;
        }

        if (isIncompleteBusinessAccountState) {
            if (!authWithIDVerificationFeatureFlagState) {
                return FLOW_NAMES.MISSING_INFO_BUSINESS;
            }

            if (
                userCompanyState.status === COMPANY_STATUSES.PENDING_DOCUMENTS &&
                userCompanyState.legal_representative_email
            ) {
                return FLOW_NAMES.AWAITING_ID_VERIFICATION;
            }

            return FLOW_NAMES.MISSING_INFO_BUSINESS;
        }

        if (isLegacyBusinessAccountState) {
            return FLOW_NAMES.MISSING_INFO_LEGACY_BUSINESS;
        }

        if (isIncompletePrivateAccountState) {
            return FLOW_NAMES.MISSING_INFO_PRIVATE;
        }

        return null;
    },
);

export const twoFactorAuthentication = createSelector(
    moduleState,
    (state) => state.twoFactorAuthentication,
);

import { GET_PRODUCTS, GET_PRODUCTS_SUCCESS, GET_PRODUCTS_ERROR } from '../actionTypes';

const defaultState = {
    loading: null,
    error: {},
    data: [],
};

export default function(state = defaultState, action) {
    switch (action.type) {
        case GET_PRODUCTS:
            return { ...state, loading: true, error: {}, data: action.reset ? [] : state.data };

        case GET_PRODUCTS_SUCCESS: {
            const products = action.data.products || [];
            return {
                ...state,
                loading: false,
                data:
                    action?.data?.loadOrder === 'previous'
                        ? products.concat(state.data)
                        : state.data.concat(products),
            };
        }

        case GET_PRODUCTS_ERROR:
            return { ...state, loading: false, error: action.error };

        default:
            return state;
    }
}

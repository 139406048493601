// eslint-disable-next-line import/no-cycle
import { FlagData } from 'ggApp/types/common';
// eslint-disable-next-line import/no-cycle
import { sendStructEvent } from '../../api';

export interface FeatureFlags {
    [flagKey: string]: FlagData;
}
export interface FeatureFlagsTrackingDTO {
    [flagKey: string]: string;
}

export const formatEventPropertyPayload = (payload: FlagData[]): FeatureFlagsTrackingDTO => {
    const eventProperty: FeatureFlagsTrackingDTO = {};

    payload.forEach((item) => {
        eventProperty[item.flagKey] = item.variantKey;
    });

    return {
        ...eventProperty,
        catalogUiStoreMigration: 'frontshop',
    };
};

export const sendFeatureFlag = (payload: FlagData[]): void => {
    const eventProperty = {
        flags: formatEventPropertyPayload(payload),
    };

    sendStructEvent({
        eventCategory: 'flagr',
        eventAction: 'evaluateFeatureFlags',
        eventLabel: '',
        eventProperty,
    });
};

export const trackFeatureFlagsInBatches = (featureFlags: FeatureFlags): void => {
    const batchSize = 50;
    let batch: FlagData[] = [];

    // Using an array to batch evaluations.
    Object.values(featureFlags).forEach((flag) => {
        batch.push({ ...flag });

        if (batch.length === batchSize) {
            sendFeatureFlag(batch);
            batch = [];
        }
    });
    sendFeatureFlag(batch);
};

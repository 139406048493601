import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { keys as translationKeysSelector, translate } from 'ggApp/modules/translator/selectors';
import { storeActive } from 'ggApp/modules/store/selectors';
import { WIDGET_NAMES } from 'ggApp/modules/gaAnalytics/constants';
import { fireEvent } from 'ggApp/modules/inwebview_api';

import { Root, ImageWrap, Image, Content, Cta, Title, Description } from './styles';
import { eventsByLink } from './utils';

class TileMarketingWidget extends React.PureComponent {
    render() {
        const {
            className,
            backgroundImage,
            headerText,
            bodyText,
            ctaLink,
            ctaText,
            onClickCTA,
            fallback,
            activeStore,
            translations,
            widgetName,
            useAppNavigation,
        } = this.props;

        const cloudinaryConfig = 'c_scale,f_auto,w_1920';

        let fallbackContent = {};
        if (fallback === 'mc2') {
            fallbackContent = {
                title: translate(translations, activeStore.activeLanguage, 'MC2_FALLBACK_TITLE'),
                description: translate(
                    translations,
                    activeStore.activeLanguage,
                    'MC2_FALLBACK_DESCRIPTION',
                ),
                image: `https://res.cloudinary.com/grover/image/upload/${cloudinaryConfig}/v1621950738/marketing-cards-fallbacks/Deals_Page_Header.png`,
                ctaText: translate(
                    translations,
                    activeStore.activeLanguage,
                    'MC2_FALLBACK_CTATEXT',
                ),
                ctaLink: translate(translations, activeStore.activeLanguage, 'MC2_FALLBACK_CTAURL'),
            };
        }
        if (fallback === 'mc3') {
            fallbackContent = {
                title: translate(translations, activeStore.activeLanguage, 'MC3_FALLBACK_TITLE'),
                description: translate(
                    translations,
                    activeStore.activeLanguage,
                    'MC3_FALLBACK_DESCRIPTION',
                ),
                image: `https://res.cloudinary.com/grover/image/upload/${cloudinaryConfig}/v1621950739/marketing-cards-fallbacks/Top_Products_Header.png`,
                ctaText: translate(
                    translations,
                    activeStore.activeLanguage,
                    'MC3_FALLBACK_CTATEXT',
                ),
                ctaLink: translate(translations, activeStore.activeLanguage, 'MC3_FALLBACK_CTAURL'),
            };
        }

        const onWidgetClick = () => {
            if (useAppNavigation) {
                fireEvent(eventsByLink[ctaLink]);
            }
            onClickCTA();
        };

        return (
            <Root className={className}>
                <ImageWrap>
                    <Image
                        src={backgroundImage || fallbackContent.image}
                        imgUrls={backgroundImage || fallbackContent.image}
                        sizes={{
                            default: '100%',
                        }}
                    />
                </ImageWrap>
                <Content>
                    <Title>{headerText || fallbackContent.title}</Title>
                    <Description>{bodyText || fallbackContent.description}</Description>
                    {ctaLink && ctaText && widgetName ? (
                        <Cta
                            target="_self"
                            linkTo={ctaLink}
                            text={ctaText}
                            onClick={onWidgetClick}
                            useAppNavigation={useAppNavigation}
                        />
                    ) : (
                        <Cta
                            target="_self"
                            linkTo={fallbackContent.ctaLink}
                            text={fallbackContent.ctaText}
                        />
                    )}
                </Content>
            </Root>
        );
    }
}

TileMarketingWidget.propTypes = {
    widgetName: PropTypes.string,
    className: PropTypes.string,
    backgroundImage: PropTypes.string,
    headerText: PropTypes.string,
    bodyText: PropTypes.string,
    ctaLink: PropTypes.string,
    ctaText: PropTypes.string,
    onClickCTA: PropTypes.func,
};

TileMarketingWidget.defaultProps = {
    widgetName: WIDGET_NAMES.marketing2,
    className: '',
    backgroundImage: '',
    headerText: '',
    bodyText: '',
    ctaLink: '',
    ctaText: '',
    onClickCTA: () => null,
};

const enhance = compose(
    connect(
        createStructuredSelector({
            activeStore: storeActive,
            translations: translationKeysSelector,
        }),
    ),
);

export default enhance(TileMarketingWidget);

import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import ThemeContext from './ThemeContext';

const ThemeProvider = ({ theme, children }) => (
    <ThemeContext.Provider value={theme}>
        <StyledThemeProvider theme={theme}>{Children.only(children)}</StyledThemeProvider>
    </ThemeContext.Provider>
);

ThemeProvider.propTypes = {
    theme: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired,
    children: PropTypes.element.isRequired,
};

export default ThemeProvider;

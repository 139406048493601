/* eslint-disable */
import { MODULE_NAME, } from '../constants';

export const GET_CATEGORY_BY_ID = `${MODULE_NAME}/GET_CATEGORY_BY_ID`;
export const GET_CATEGORY_BY_ID_SUCCESS = `${MODULE_NAME}/GET_CATEGORY_BY_ID_SUCCESS`;
export const GET_CATEGORY_BY_ID_ERROR = `${MODULE_NAME}/GET_CATEGORY_BY_ID_ERROR`;

export const GET_CATEGORIES = `${MODULE_NAME}/GET_CATEGORIES`;
export const GET_CATEGORIES_SUCCESS = `${MODULE_NAME}/GET_CATEGORIES_SUCCESS`;
export const GET_CATEGORIES_ERROR = `${MODULE_NAME}/GET_CATEGORIES_ERROR`;

export const GET_SUB_CATEGORY = `${MODULE_NAME}/GET_SUB_CATEGORY`;
export const GET_SUB_CATEGORY_SUCCESS = `${MODULE_NAME}/GET_SUB_CATEGORY_SUCCESS`;
export const GET_SUB_CATEGORY_ERROR = `${MODULE_NAME}/GET_SUB_CATEGORY_ERROR`;

import { createSelector } from 'reselect';
import { composeFlagsDataForEventTracking } from './utils';

import {
    MODULE_NAME,
    SPA_FEATURE_FLAG_KEY,
    SMART_BANNER_FEATURE_FLAG_KEY,
    PDP_MARKET_PRICE_FLAG_KEY,
    AUTH_WITH_ID_VERIFICATION_KEY,
    NEW_PAYMENT_METHOD_FLOW_FEATURE_FLAG,
    DISCOUNT_BANNER,
    NEW_CHECKOUT,
    BANNER_FOR_ALL_USERS,
    YOUR_PAYMENTS_FEATURE_FLAG_KEY,
    SUBSCRIPTION_PAYMENTS_FEATURE_FLAG_KEY,
    NEW_CHECKOUT_ORDER_CUSTOMISATION_RENTAL,
    NEW_CHECKOUT_ORDER_CUSTOMISATION_COLOR,
    newCheckoutVariants,
    REASON_POLL_STEP_FEATURE_FLAG_KEY,
    GROVER_CASH_WEB_FLAG_KEY,
    NEW_REFERRALS_HOST_FLAG_KEY,
    MIX_CATALOG_FEATURE_FLAG_KEY,
    YOUR_TECH_MIX_FEATURE_FLAG_KEY,
    MIX_GLOBAL_FEATURE_FLAG_KEY,
    MIX_PRICE_INTERNAL_USERS_FLAG_KEY,
    BUSINESS_STORE_DEALS_FLAG_KEY,
    PREORDER_PRODUCTS_FEATURE_FLAG_KEY,
    ADDRESS_2FA_FLAG_KEY,
    REQUEST_OFFER_B2B_FLAG_KEY,
    APP_RATING_FEATURE_FLAG,
    SEO_CONTENT_FOR_CATALOGUE_PAGES_KEY,
    PDP_BUNDLES_COMPONENT,
    PLP_BUNDLES_COMPONENT,
    CATALOG_PAGINATION,
    SWAP_CHECKOUT_ADDRESS_FEATURE_KEY,
    MIX_SWAPPING_FEATURE_FLAG_KEY,
    THREEDS_WARNING_MESSAGE_FLAG_KEY,
    PRODUCT_ANNOUNCEMENTS_FLAG_KEY,
    RENTAL_PLAN_TESTS_FLAG_KEY,
    APP_LOGGED_OUT_BROWSING,
    PURCHASE_OPTION_AB_TEST_FLAG_KEY,
    PURCHASE_OPTION_HIDDEN_FLAG_KEY,
    PURCHASE_OPTION_EXTENDED_FAQ_FLAG_KEY,
    STRUCTURED_SPECS_FLAG_KEY,
    PRODUCT_FREE_TRIAL,
    NOTIFICATIONS_FLAG_KEY,
    MIX_LINK_ON_NAV_FLAG_KEY,
    CART_OVERLAY_VOUCHER_PREVIEW_FLAG_KEY,
    LINK_PRODUCTS_IN_CART_FLAG_KEY,
    BLOCK_MANAGED_ACCOUNT_CHECKOUT_FLAG_KEY,
    MGM_HACK_FLAG_KEY,
    REFER_A_FRIEND_FOOTER_LINK,
    AVAILABILITY_FILTER_PRE_SELECTION,
    YOUR_CARD_FEATURE_FLAG_KEY,
    CARD_LINK_ON_WEB_FLAG_KEY,
    CONTACT_NOW_CTA_LANDING_PAGE,
    YOUR_TECH_PAYMENT_METHOD_MODAL_AND_BUTTON,
    LONG_REVOCATION_PERIOD_TEST,
    SIGN_UP_BUSINESS_WITH_COMPANY_SIZE,
    CARD_LANDING_TEASER_TOGGLE,
    BLACK_FRIDAY_LANDING_PAGES,
    PRODUCT_DISCOUNT_PERCENTAGE_CHIP,
    PRODUCT_ENERGY_LABEL,
    CHECKOUT_VOUCHER_EXPLANATION,
    PRODUCT_CUSTOMIZATION_SERVICE_DATA,
    LOW_STOCK_CHIP,
    SITEWIDE_BANNER,
    COUNTDOWN_TIMERS,
    PDP_PURCHASE_OPTION_TEXT,
    PRODUCT_CUSTOMIZATION_SERVICE_DATA_LISTING_PAGES,
    RECOMMENDATION_ENGINE,
    HOW_IT_WORKS_PAGE_VARIANT,
    PURCHASE_FLOW_RETENTION_TEST_FLAG_DATA,
    SATISFACTION_SURVEY_COMPONENT,
    BLOCK_MANAGED_ACCOUNT_SELF_SERVE_PURCHASE_KEY,
    LOCKED_DEVICE_RETURN_STEP,
    ENHANCE_FLEX_CONTRACT_RENTAL_PERIOD,
    YOUR_TECH_PAYMENT_METHOD_PAGE_BUTTON,
    CATEGORIES_WIDGET_ON_HOMEPAGE,
    CHECKOUT_ORDER_REVIEW_CTA,
    CHECKOUT_CART_OVERLAY_CTA,
    OTP_ADYEN_DROP_IN_REPLACEMENT,
    B2B_SELF_CHECKOUT,
    B2B_CREATE_COMPANY_USE_SERVICE,
    COUNTDOWN_DEALS_OF_THE_WEEK,
    DEALS_OF_THE_WEEK_EXPERIMENT,
    HOMEPAGE_MC1_AB_TEST,
    FREELANCER_COMPANY_TYPE,
    B2B_OPEN_STORE,
    CARD_NATIVE_FLOW_TOGGLE,
    TERMS_AND_CONDITIONS,
    B2B_SIGNUP_PKG_ENABLED,
    TOGGLE_CARD_NEW_LANDING_PAGE,
    BLACK_FRIDAY_DEALS_FILTER,
    VALUE_PROPS_ENABLED,
    SKIP_ONFIDO_B2B,
    COMPANY_DASHBOARD_ENABLED,
    VERIFICATION_CAMPAIGN,
    GROVER_CASH_RECEIVE_PERCENTAGE,
    SUBSCRIPTION_CANCELLATION_LINK_FLAG_KEY,
} from './constants';

export const moduleState = (state) => state[MODULE_NAME] || {};

export const flags = (state) => moduleState(state).data || {};

export const flagsDataForEventTracking = (state) => {
    const featureFlags = flags(state);

    return composeFlagsDataForEventTracking(featureFlags);
};

export const featureFlagEnabled = (FLAG_KEY) => (state) => {
    const { [FLAG_KEY]: flagData } = flags(state);

    if (flagData && flagData.variantKey) {
        return flagData.variantKey === 'enabled';
    }
    return false;
};

export const spaFeatureFlag = featureFlagEnabled(SPA_FEATURE_FLAG_KEY);

export const smartBannerFeatureFlag = featureFlagEnabled(SMART_BANNER_FEATURE_FLAG_KEY);

export const pdpMarketPriceFeatureFlag = featureFlagEnabled(PDP_MARKET_PRICE_FLAG_KEY);
export const appRatingFeatureFlag = featureFlagEnabled(APP_RATING_FEATURE_FLAG);
export const authWithIDVerificationFeatureFlag = featureFlagEnabled(AUTH_WITH_ID_VERIFICATION_KEY);

export const referralBannerForAll = featureFlagEnabled(BANNER_FOR_ALL_USERS);

export const newCheckoutFlag = featureFlagEnabled(NEW_CHECKOUT);
export const yourPaymentsFeatureFlag = featureFlagEnabled(YOUR_PAYMENTS_FEATURE_FLAG_KEY);
export const subscriptionPaymentsFeatureFlag = featureFlagEnabled(
    SUBSCRIPTION_PAYMENTS_FEATURE_FLAG_KEY,
);

export const mixCatalogFlag = featureFlagEnabled(MIX_CATALOG_FEATURE_FLAG_KEY);

export const mixGlobalFlag = featureFlagEnabled(MIX_GLOBAL_FEATURE_FLAG_KEY);

export const businessStoreDealsFlag = featureFlagEnabled(BUSINESS_STORE_DEALS_FLAG_KEY);

export const businessRequestOfferFlag = featureFlagEnabled(REQUEST_OFFER_B2B_FLAG_KEY);

export const seoContentCatalogueFlag = featureFlagEnabled(SEO_CONTENT_FOR_CATALOGUE_PAGES_KEY);

export const pdpBundlesFlag = featureFlagEnabled(PDP_BUNDLES_COMPONENT);

export const plpBundlesFlag = featureFlagEnabled(PLP_BUNDLES_COMPONENT);
export const catalogPaginationFlag = featureFlagEnabled(CATALOG_PAGINATION);
export const threeDSWarningFlag = featureFlagEnabled(THREEDS_WARNING_MESSAGE_FLAG_KEY);
export const loggedOutBrowsingFlag = featureFlagEnabled(APP_LOGGED_OUT_BROWSING);

export const productFreeTrialFlag = featureFlagEnabled(PRODUCT_FREE_TRIAL);

export const featureFlagData = (FLAG_KEY) => (state) => {
    const { [FLAG_KEY]: flagData } = flags(state);
    return flagData;
};

export const featureFlagVariantKey = (FLAG_KEY) => (state) => {
    const { [FLAG_KEY]: flagData } = flags(state);
    return flagData?.variantKey;
};

export const mixEnabled = createSelector(
    mixCatalogFlag,
    mixGlobalFlag,
    (catalogMix, globalMix) => catalogMix && globalMix,
);

export const spaFeatureFlagData = featureFlagData(SPA_FEATURE_FLAG_KEY);
export const pdpMarketPriceFeatureFlagData = featureFlagData(PDP_MARKET_PRICE_FLAG_KEY);

export const discountBannerFeatureFlagData = featureFlagData(DISCOUNT_BANNER);

export const newCheckoutFlagData = featureFlagData(NEW_CHECKOUT);
export const newCheckoutOrderCustomizePlanFlag = featureFlagEnabled(
    NEW_CHECKOUT_ORDER_CUSTOMISATION_RENTAL,
);
export const newCheckoutOrderCustomizeColorFlag = featureFlagEnabled(
    NEW_CHECKOUT_ORDER_CUSTOMISATION_COLOR,
);

export const shouldEnableNewFlowSelector = (state) => {
    const flagDataSelector = featureFlagData(NEW_PAYMENT_METHOD_FLOW_FEATURE_FLAG);
    const flagData = flagDataSelector(state);

    return Boolean(flagData && flagData.variantKey === 'payment_service_flow');
};

export const newCheckoutEnabledSelector = (state) => {
    const checkoutFlagData = newCheckoutFlagData(state);
    return Boolean(
        checkoutFlagData &&
            (checkoutFlagData.variantKey === newCheckoutVariants.withHomeAddress ||
                checkoutFlagData.variantKey === newCheckoutVariants.noHomeAddress),
    );
};

export const reasonPollStepFeatureFlag = featureFlagEnabled(REASON_POLL_STEP_FEATURE_FLAG_KEY);

export const groverCashWebFeatureFlag = featureFlagEnabled(GROVER_CASH_WEB_FLAG_KEY);

export const newReferralsHostFeatureFlag = featureFlagEnabled(NEW_REFERRALS_HOST_FLAG_KEY);

export const yourTechMixFeatureFlag = featureFlagEnabled(YOUR_TECH_MIX_FEATURE_FLAG_KEY);

export const mixPriceInternalUsersFeatureFlagData = featureFlagData(
    MIX_PRICE_INTERNAL_USERS_FLAG_KEY,
);
export const mixPriceInternalUsersFeatureFlag = featureFlagEnabled(
    MIX_PRICE_INTERNAL_USERS_FLAG_KEY,
);

export const address2faFeatureFlag = featureFlagEnabled(ADDRESS_2FA_FLAG_KEY);

export const swapCheckoutAddressFlagEnabled = featureFlagEnabled(SWAP_CHECKOUT_ADDRESS_FEATURE_KEY);

export const mixSwappingFeatureFlag = featureFlagEnabled(MIX_SWAPPING_FEATURE_FLAG_KEY);

export const productAnnouncementsFlagData = featureFlagData(PRODUCT_ANNOUNCEMENTS_FLAG_KEY);

export const purchaseOptionExtendedFaqEnabled = featureFlagEnabled(
    PURCHASE_OPTION_EXTENDED_FAQ_FLAG_KEY,
);

export const structuredSpecsFlagEnabled = featureFlagEnabled(STRUCTURED_SPECS_FLAG_KEY);

export const notificationsEnabled = featureFlagEnabled(NOTIFICATIONS_FLAG_KEY);

export const mixLinkOnNavEnabled = featureFlagEnabled(MIX_LINK_ON_NAV_FLAG_KEY);

export const cartOverlayVoucherPreviewEnabled = featureFlagEnabled(
    CART_OVERLAY_VOUCHER_PREVIEW_FLAG_KEY,
);

export const linkProductsInCartFlagEnabled = featureFlagEnabled(LINK_PRODUCTS_IN_CART_FLAG_KEY);

export const blockManagedAccountCheckoutEnabled = featureFlagEnabled(
    BLOCK_MANAGED_ACCOUNT_CHECKOUT_FLAG_KEY,
);

export const blockManagedAccountSelfServePurchaseEnabled = featureFlagEnabled(
    BLOCK_MANAGED_ACCOUNT_SELF_SERVE_PURCHASE_KEY,
);

export const mgmHackFlagEnabled = featureFlagEnabled(MGM_HACK_FLAG_KEY);

export const referAFriendFooterLinkEnabled = featureFlagEnabled(REFER_A_FRIEND_FOOTER_LINK);

export const availabilityFilterEnabled = featureFlagEnabled(AVAILABILITY_FILTER_PRE_SELECTION);

export const groverCardEnabled = (state) => {
    const { [YOUR_CARD_FEATURE_FLAG_KEY]: flagData } = flags(state);
    if (flagData && flagData.variantKey) {
        return flagData.variantKey === 'visible';
    }
    return false;
};

export const cardLinkOnWebFlagEnabled = featureFlagEnabled(CARD_LINK_ON_WEB_FLAG_KEY);

export const contactNowCtaEnabled = featureFlagEnabled(CONTACT_NOW_CTA_LANDING_PAGE);

export const yourTechPaymentMethodAndModalEnabled = featureFlagEnabled(
    YOUR_TECH_PAYMENT_METHOD_MODAL_AND_BUTTON,
);

export const yourTechPaymentMethodPageEnabled = featureFlagEnabled(
    YOUR_TECH_PAYMENT_METHOD_PAGE_BUTTON,
);

export const longRevocationPeriodTestData = featureFlagData(LONG_REVOCATION_PERIOD_TEST);

export const signUpBusinessWithCompanySizeEnabled = featureFlagEnabled(
    SIGN_UP_BUSINESS_WITH_COMPANY_SIZE,
);

export const cardLandingTeaserToggleEnabled = featureFlagEnabled(CARD_LANDING_TEASER_TOGGLE);

export const blackFridayLandingPagesEnabled = featureFlagEnabled(BLACK_FRIDAY_LANDING_PAGES);

export const showProductDiscountPercentageChip = featureFlagEnabled(
    PRODUCT_DISCOUNT_PERCENTAGE_CHIP,
);

export const showProductEnergyLabel = featureFlagEnabled(PRODUCT_ENERGY_LABEL);
export const showVoucherExplanation = featureFlagData(CHECKOUT_VOUCHER_EXPLANATION);

export const productCustomizationServiceDataFetchingEnabled = featureFlagEnabled(
    PRODUCT_CUSTOMIZATION_SERVICE_DATA,
);

export const showLowStockChip = featureFlagEnabled(LOW_STOCK_CHIP);

export const sitewideBannerFlagData = featureFlagData(SITEWIDE_BANNER);

export const showCountdownTimer = featureFlagEnabled(COUNTDOWN_TIMERS);

export const productCustomizationServiceDataFetchingListingPagesEnabled = featureFlagEnabled(
    PRODUCT_CUSTOMIZATION_SERVICE_DATA_LISTING_PAGES,
);

export const recommendationEngineEnabled = featureFlagEnabled(RECOMMENDATION_ENGINE);

export const howItWorksPageVariantEnabled = featureFlagEnabled(HOW_IT_WORKS_PAGE_VARIANT);

export const purchaseFlowRetentionTestEnabled = featureFlagEnabled(
    PURCHASE_FLOW_RETENTION_TEST_FLAG_DATA,
);

export const satisfactionSurveyEnabled = featureFlagEnabled(SATISFACTION_SURVEY_COMPONENT);

export const lockedDeviceStepEnabled = featureFlagEnabled(LOCKED_DEVICE_RETURN_STEP);

export const enhanceFlexContractRentalPeriodEnabled = featureFlagEnabled(
    ENHANCE_FLEX_CONTRACT_RENTAL_PERIOD,
);

export const categoriesWidgetOnHomepageEnabled = featureFlagEnabled(CATEGORIES_WIDGET_ON_HOMEPAGE);

export const checkoutOrderReviewCTA = featureFlagData(CHECKOUT_ORDER_REVIEW_CTA);

export const checkoutCartOverlayCTA = featureFlagData(CHECKOUT_CART_OVERLAY_CTA);

export const b2bSelfCheckoutEnabled = featureFlagEnabled(B2B_SELF_CHECKOUT);

export const b2bCreateCompanyUseServiceEnabled = featureFlagEnabled(B2B_CREATE_COMPANY_USE_SERVICE);

export const countDownOfTheWeek = featureFlagData(COUNTDOWN_DEALS_OF_THE_WEEK);

export const homepageMc1AbTestEnabled = featureFlagEnabled(HOMEPAGE_MC1_AB_TEST);

export const freelancerCompanyTypeEnabled = featureFlagEnabled(FREELANCER_COMPANY_TYPE);

export const isB2BStoreOpenEnabled = featureFlagEnabled(B2B_OPEN_STORE);

export const isWaitingListDisabled = featureFlagEnabled(CARD_NATIVE_FLOW_TOGGLE);

export const dealsOfTheWeekExperimentEnabled = featureFlagEnabled(DEALS_OF_THE_WEEK_EXPERIMENT);

export const termsAndConditionsFlag = featureFlagEnabled(TERMS_AND_CONDITIONS);

export const isB2bSignupPkgEnabled = featureFlagEnabled(B2B_SIGNUP_PKG_ENABLED);

export const isCardNewLandingPageEnabled = featureFlagEnabled(TOGGLE_CARD_NEW_LANDING_PAGE);

export const blackFridayDealsFilterEnabled = featureFlagEnabled(BLACK_FRIDAY_DEALS_FILTER);

export const valuePropsWidgetEnabled = featureFlagEnabled(VALUE_PROPS_ENABLED);

export const skipOnfidoB2BFlagSelector = featureFlagEnabled(SKIP_ONFIDO_B2B);

export const companyDashboardEnabled = featureFlagEnabled(COMPANY_DASHBOARD_ENABLED);

export const verificationCampaignEnabled = featureFlagEnabled(VERIFICATION_CAMPAIGN);

export const gcPercentage = featureFlagVariantKey(GROVER_CASH_RECEIVE_PERCENTAGE);

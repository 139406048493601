import { APISelection, PaymentMethodItem } from '../../integration';
import addPaymentMethodMutation from './paymentMethodAdd.graphql';
import updatePaymentMethodMutation from './paymentMethodChange.graphql';

export const paymentMethod: APISelection<PaymentMethodItem> = {
    add: async ({ orderNumber }, item): Promise<any> => {
        const { CartServiceClient } = await import('../client');
        try {
            const { paymentMethodType, paymentMethodId, billingAccountId } = item || {};
            const method = {
                type: paymentMethodType,
                id: paymentMethodId,
                billingAccountId,
            };
            const { data } = await CartServiceClient.mutation({
                mutation: addPaymentMethodMutation,
                variables: {
                    orderNumber,
                    input: { method },
                },
            });
            return data;
        } catch (e) {
            return null;
        }
    },
    update: async ({ orderNumber }, item) => {
        const { CartServiceClient } = await import('../client');
        const { paymentMethodType, paymentMethodId, billingAccountId } = item || {};
        const method = {
            type: paymentMethodType,
            id: paymentMethodId,
            billingAccountId,
        };
        const { data } = await CartServiceClient.mutation({
            mutation: updatePaymentMethodMutation,
            variables: {
                orderNumber,
                input: { paymentMethod: method },
            },
        });
        return data;
    },
};

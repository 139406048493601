import React from 'react';
import { product as productUrlBuilder } from 'ggApp/utils/urlBuilder/frontshop';
import ChipMapper from 'ggApp/shared/components/cards/Chips';

import { EnergyClass } from 'ggApp/shared/components/cards/ProductDetailsCard/EnergyLabels/styles';
import { energyRatingIcon } from 'ggApp/shared/components/cards/ProductDetailsCard/EnergyLabels/utils/energyLabelsMapper';
import { MappedProductType } from 'ggApp/modules/product/constants/MappedProductType';
import { isAppUser } from 'ggApp/routes/_wrap/MobileWrapper/utils';

import {
    ChipsAndLabelBlock,
    Description,
    FavoriteButton,
    Image,
    ImageContainer,
    DefaultName,
    Name,
    NameContainer,
    PriceBlock,
    RootDiv,
    RootLink,
    TextWrap,
} from './verticalStyles';

type VerticalCardType = {
    className: string;
    product: MappedProductType;
    style?: React.CSSProperties;
    onClick: () => void;
    onClickCallback: ({ isFavorite }: { isFavorite: boolean }) => void;
    browserLink: boolean;
    carousel: boolean;
    handpickedProduct: boolean;
    nodeRef?: unknown;
    noCssOnImage: boolean;
    recentlyViewedSection?: boolean;
    hidePrices?: boolean;
};

export const VerticalCard = ({
    className = '',
    product: {
        id,
        productName = '',
        slug = '',
        productCoreAttributes = '',
        selectedMinDuration,
        rentalPlan,
        oldPrice,
        price,
        chips = [],
        originalImage,
        energyClass,
    },
    style,
    onClick,
    onClickCallback,
    browserLink,
    carousel,
    handpickedProduct,
    nodeRef,
    recentlyViewedSection,
    noCssOnImage = false,
    hidePrices = false,
}: VerticalCardType) => {
    const Container = slug ? RootLink : RootDiv;

    const background = handpickedProduct ? 'rgb:f7f7f7' : 'white';

    const ImageTransform = [
        { effect: 'trim' },
        { quality: 'auto', fetchFormat: 'webp' },
        { width: '300', height: '300', crop: 'pad', background },
    ];

    // Skip setting selectedMinDuration if React Native App due to bug (CNV-545)
    const isGroverApp = isAppUser();
    const minDuration = isGroverApp ? false : selectedMinDuration;

    return (
        <Container
            className={className}
            style={{ ...style }}
            to={productUrlBuilder(slug, minDuration)}
            cardType="vertical"
            onClick={onClick}
            browserLink={browserLink}
            carousel={carousel}
            handpickedProduct={handpickedProduct}
        >
            {/* Passing nodeRef to ImageContainer since it is a div and scrolls as part of the container */}
            <ImageContainer carousel={carousel} handpickedProduct={handpickedProduct} ref={nodeRef}>
                <Image
                    className={className}
                    src={originalImage}
                    alt={productName}
                    transformations={ImageTransform}
                    width="300"
                    height="300"
                    noCss={noCssOnImage}
                    style={{ height: '100%' }}
                />
            </ImageContainer>
            <ChipsAndLabelBlock>
                {chips && chips.length > 0 ? (
                    <div>
                        <ChipMapper chips={chips} selectedRentalPlan={rentalPlan} />
                    </div>
                ) : null}
                {energyClass ? (
                    <EnergyClass icon={energyRatingIcon[energyClass]} width={29} height={16} />
                ) : null}
            </ChipsAndLabelBlock>
            <TextWrap>
                <NameContainer>
                    {!carousel || recentlyViewedSection ? (
                        <Name title={productName}>{productName}</Name>
                    ) : (
                        <DefaultName title={productName}>{productName}</DefaultName>
                    )}
                </NameContainer>
                {/* eslint-disable-next-line */}
                {productCoreAttributes && <Description>{productCoreAttributes}</Description>}
                {!hidePrices ? (
                    <PriceBlock
                        oldPrice={oldPrice}
                        price={price}
                        selectedMinDuration={selectedMinDuration}
                    />
                ) : null}
            </TextWrap>
            <FavoriteButton id={id} slug={slug} onClickCallback={onClickCallback} />
        </Container>
    );
};

function ValidationError(payload = {}) {
    const { message = 'Validation error occurred', fileName, lineNumber, ...restPayload } = payload;

    Error.call(this, message, fileName, lineNumber);

    if (Error.captureStackTrace) {
        Error.captureStackTrace(this, ValidationError);
    } else {
        this.stack = new Error().stack;
    }

    this.errors = restPayload.errors ?? {};
    this.baseError = restPayload.baseError;
}

ValidationError.prototype = Object.create(Error.prototype);

export default ValidationError;

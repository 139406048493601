import styled from 'styled-components';

const Overlay = styled.div`
    background: rgba(255,255,255, 0.7) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
`;

export default Overlay;

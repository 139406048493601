/* eslint-disable */
import {
    GET_SUBSCRIPTION_RENTAL_PLAN,
    GET_SUBSCRIPTION_RENTAL_PLAN_SUCCESS,
    GET_SUBSCRIPTION_RENTAL_PLAN_ERROR,
} from '../actionTypes';

const defaultState = {
    loading: null,
    error: {},
    data: [],
};

export default function (state = defaultState, action) {
    switch (action.type) {
    case GET_SUBSCRIPTION_RENTAL_PLAN:
        return {
            ...state,
            loading: true,
            error: {},
        };

    case GET_SUBSCRIPTION_RENTAL_PLAN_SUCCESS:
        return {
            ...state,
            loading: false,
            data: action.data,
        };

    case GET_SUBSCRIPTION_RENTAL_PLAN_ERROR:
        return {
            ...state,
            loading: false,
            error: action.error,
        };

    default:
        return state;
    }
}

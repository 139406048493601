import React from 'react';
import { Route } from 'react-router';
import Loadable from 'react-loadable';
import CircleLoader from 'ggApp/shared/components/CircleLoader';
import BaseLayout from '../BaseLayout';

const TermsAndConditionsPage = Loadable({
    loader: () =>
        import(/* webpackChunkName: "TermsAndConditionsPage" */ './TermsAndConditionsPage'),
    loading: () => <CircleLoader />,
    delay: 300,
    timeout: 10000,
});

const TermsAndConditionsRoutes = (
    <Route component={BaseLayout}>
        <Route path="g-about/agb/:version" component={TermsAndConditionsPage} />
        <Route path="g-about/terms-and-conditions/:version" component={TermsAndConditionsPage} />
    </Route>
);

export default TermsAndConditionsRoutes;

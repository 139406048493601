import React from 'react';
import { PureTranslator } from 'ggApp/modules/translator/components';

export const scoreToPercentage = (trustedScore) => {
    const score = parseFloat(trustedScore);
    const percentage = Math.round(((score / 5) * 100).toFixed(3));
    return percentage.toString();
};

export const validateReviewData = (review) => {
    if (
        'reviewer' in review &&
        'first_name' in review?.reviewer &&
        'last_name' in review?.reviewer &&
        'verified_buyer' in review?.reviewer &&
        'address' in review?.reviewer &&
        'rating' in review &&
        'comments' in review &&
        'date' in review
    ) {
        return true;
    }
    return false;
};

export const trimLong = (comment) => {
    let trimmed = comment;
    if (comment.length > 200) {
        trimmed = comment.substr(0, 200);
        const trimmedOnWord = trimmed.substr(0, trimmed.lastIndexOf(' '));
        trimmed = `${trimmedOnWord}...`;
    }
    return trimmed;
};

export const checkAddress = (address) => {
    if (address.length === 0) {
        return '';
    }
    if (address[0] === ',') {
        const splitAddress = address.split(', ');
        return `${splitAddress[1]},`;
    }
    return `${address},`;
};

export const translateDate = (date) => {
    const splitDate = date.split(' ');
    const number = splitDate[0];
    const time = splitDate[1];
    let translationKey = '';
    switch (time) {
        case 'minute':
            translationKey = 'REVIEWS_MINUTE_AGO';
            break;
        case 'minutes':
            translationKey = 'REVIEWS_MINUTES_AGO';
            break;

        case 'hour':
            translationKey = 'REVIEWS_HOUR_AGO';
            break;
        case 'hours':
            translationKey = 'REVIEWS_HOURS_AGO';
            break;
        case 'day':
            translationKey = 'REVIEWS_DAY_AGO';
            break;
        case 'days':
            translationKey = 'REVIEWS_DAYS_AGO';
            break;
        case 'week':
            translationKey = 'REVIEWS_WEEK_AGO';
            break;
        case 'weeks':
            translationKey = 'REVIEWS_WEEKS_AGO';
            break;
        case 'month':
            translationKey = 'REVIEWS_MONTH_AGO';
            break;
        case 'months':
            translationKey = 'REVIEWS_MONTHS_AGO';
            break;
        default:
            break;
    }
    return (
        <PureTranslator
            tk={translationKey}
            withValues={{
                time: number,
            }}
        />
    );
};

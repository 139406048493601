/* eslint-disable */
import { useEffect } from 'react';

function useOnce(handle) {
    useEffect(
        handle,
        [],
    );
}

export default useOnce;

/* eslint-disable */
import { SET_REQUEST_STATUS } from './actionTypes';
import { READY_STATUS } from './constants';

const defaultState = {
    status: READY_STATUS,
};

export default function requestStatusReducer(state = defaultState, action) {
    switch (action.type) {
    case SET_REQUEST_STATUS: {
        return ({
            ...state,
            status: action.status,
        });
    }
    default: {
        return state;
    }
    }
}

/* eslint-disable */
const defaultState = {
  progress: null,
  asyncErrors: {
    updatePhoneError: '',
    confirmPhoneError: '',
  },
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'AUTH_SHOW_PHONE_VERIFICATION':
      return Object.assign({}, state, {
        progress: true,
      });

    case 'AUTH_HIDE_PHONE_VERIFICATION':
      return Object.assign({}, state, {
        progress: false,
      });

    case 'AUTH_SET_PV_ASYNC_ERRORS':
      return Object.assign({}, state, {
        asyncErrors: Object.assign({}, state.asyncErrors, action.data),
      });

    default:
      return state;
  }
}

import styled, { css } from 'styled-components';

export const TypographyTag = styled.div`    
    ${({ tagStyle }) => tagStyle === 'superHeadline' && css`
        ${({ theme }) => theme.typography.SuperHeadline}
    `}

    ${({ tagStyle }) => tagStyle === 'hugeHeadline' && css`
        ${({ theme }) => theme.typography.HugeHeadline}
        font-size: 80px;
        line-height: 1;
    `}

    ${({ tagStyle }) => tagStyle === 'h1' && css`
        ${({ theme }) => theme.typography.Headline}
    `}

    ${({ tagStyle }) => tagStyle === 'h2' && css`
        ${({ theme }) => theme.typography.Subheadline}    
    `}

    ${({ tagStyle }) => tagStyle === 'h3' && css`
        ${({ theme }) => theme.typography.SuperTinyHeadline}
    `}

    ${({ tagStyle }) => tagStyle === 'p' && css`
        ${({ theme }) => theme.typography.Paragraph}
    `}

    ${({ tagStyle }) => tagStyle === 'h4' && css`
        ${({ theme }) => theme.typography.TinyParagraph}
    `}

    ${({ tagStyle }) => tagStyle === 'label' && css`
        ${({ theme }) => theme.typography.SmallCapsHeadline}
    `}

    ${({ tagStyle }) => tagStyle === 'tinyLabel' && css`
        ${({ theme }) => theme.typography.SmallCapsHeadline}
        line-height: 10px;
        font-size: 8.5px;
    `}

    ${({ tagStyle }) => tagStyle === 'captionText' && css`
        ${({ theme }) => theme.typography.LargeParagraph}
        font-size: 24px;
        font-weight: 400;
    `}

    ${({ tagStyle }) => tagStyle === 'richText' && css`
        h1 {
            font-family: Inter, sans-serif;
            font-weight: bold;
            line-height: 90%;
            font-size: 36px;
            margin-bottom: 24px;
        }

        h2 {
            font-family: Inter, sans-serif;
            font-weight: bold;
            line-height: 32px;
            font-size: 24px;
        }

        h3 {
            font-family: Inter, sans-serif;
            font-weight: bold;
            line-height: 18px;
            font-size: 16px;
        }

        p {
            font-family: Inter, sans-serif;
            font-weight: normal;
            line-height: 24px;
            font-size: 16px;
        }

        h4 {
            font-family: Inter, sans-serif;
            font-weight: normal;
            line-height: 17px;
            font-size: 12px;
        }

        ol,
        ul {
            padding-left: 18px;
            margin-bottom: 12px;
            font-family: Inter, sans-serif;
            font-weight: normal;
            line-height: 24px;
            font-size: 16px;

            li {
                margin-bottom: 4px;
            }
        }

        ol {
            list-style-type: decimal;
        }

        ul {
            list-style-type: disc;
        }

        strong,
        b {
            font-weight: bold;
        }

        em {
            font-style: italic;
        }

        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            margin-bottom: 16px;
        }

        a {
            border-bottom: 1px solid;
            color: #333333;
            border-color: #333333;
            transition: all 0.1s cubic-bezier(0, 0, 0.2, 1) 0s;
            
            &:hover {
                color: rgba(51, 51, 51, 0.5);
                border-color: rgba(51, 51, 51, 0.5);
            }
        }
    `}
`;

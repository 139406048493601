import { ANALYTIC_EVENTS } from 'ggApp/modules/gaAnalytics/constants';

export const getReferralEvent = ({ eventLabel, eventAction }) => ({
    event: ANALYTIC_EVENTS.gtmEvent,
    eventCategory: 'referrals',
    eventAction,
    eventLabel,
});

export const shareReferralsEvent = ({ eventAction, eventLabel, userID }) => ({
    event: 'Refer a friend',
    eventCategory: 'referrals',
    eventAction,
    eventLabel,
    eventProperty: {
        userID,
    },
});

import styled, { css } from 'styled-components';
import { SonntagIcon } from '@getgrover/ui';

import WidgetGrid from 'ggApp/shared/components/widgets/grid';
import { Card } from 'ggApp/shared/components/cards/widgetProductCard';
import WidgetCta from 'ggApp/shared/components/widgets/cta';
import { Headline } from 'ggApp/shared/common/Typography';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background: ${props => props.theme.palette.grey[4]};
    padding: ${props => props.theme.spacers.XL};
    position: relative;
    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
        padding: ${theme.spacers.XL} ${theme.spacers.L};
        margin: 0;
    `}
    ${(props) => props.theme.superQuery.all.and.minWidth.desktopMin.css`
        border-radius: 8px;
    `};
`;

const Header = styled.div`
    display: flex;
`;

const Title = styled(Headline)`
    ${({ theme }) => theme.typography.Headline};
    font-size: 32px;
    line-height: 1.3;
    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
        ${theme.typography.Subheadline}
    `};
`;

const IconWrap = styled.div`
    position: relative;
    width: 48px;
    height: 48px;
    background: #fff;
    border-radius: 50%;
    margin-right: 8px;
`;

const Icon = styled(SonntagIcon)`
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    left: 12px;
`;

const Wrap = styled(WidgetGrid)`
    grid-row-gap: ${props => props.theme.spacers.L};
    margin: 16px 0 0 0;

    ${({ vertical }) => vertical && css`
        grid-template-columns: repeat(12, 1fr);
    `};
`;

const WidgetProductCardComponent = styled(Card)`
    ${({ cardType }) => cardType === 'horizontal'
        && css`
            grid-column: 1 / -1;
        `}
    ${({ cardType }) => cardType === 'vertical'
        && css`
            min-height: calc(30vw - 16px);
            grid-column: span 6;
        `}
`;

const Cta = styled(WidgetCta)`
    align-self: flex-start;
    margin-top: 24px;
    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMax.css`
        margin: 8px 8px 0 0 0;
    `}
`;

export {
    Container,
    Header,
    Title,
    IconWrap,
    Icon,
    Wrap,
    WidgetProductCardComponent,
    Cta,
};

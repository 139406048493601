export const MODULE_NAME = 'featureFlag';
export const GET_FEATURE_FLAGS = `${MODULE_NAME}/GET_FEATURE_FLAGS`;
export const GET_FEATURE_FLAGS_SUCCESS = `${MODULE_NAME}/GET_FEATURE_FLAGS_SUCCESS`;
export const GET_FEATURE_FLAGS_ERROR = `${MODULE_NAME}/GET_FEATURE_FLAGS_ERROR`;

export const GET_ALL_FEATURE_FLAGS = `${MODULE_NAME}/GET_ALL_FEATURE_FLAGS`;
export const GET_ALL_FEATURE_FLAGS_SUCCESS = `${MODULE_NAME}/GET_ALL_FEATURE_FLAGS_SUCCESS`;
export const GET_ALL_FEATURE_FLAGS_ERROR = `${MODULE_NAME}/GET_ALL_FEATURE_FLAGS_ERROR`;

export const SPA_FEATURE_FLAG_KEY = 'frontshop_catalog_spa';
export const SMART_BANNER_FEATURE_FLAG_KEY = 'frontshop_smart_banner';
export const PDP_MARKET_PRICE_FLAG_KEY = 'frontshop_pdp_market_price';
export const AUTH_WITH_ID_VERIFICATION_KEY = 'auth_with_id_verification';
export const APP_RATING_FEATURE_FLAG = 'app_rating';
export const NEW_PAYMENT_METHOD_FLOW_FEATURE_FLAG = 'create_payment_method_flow';

export const DISCOUNT_BANNER = 'frontshop_discount_banner';
export const NEW_CHECKOUT = 'new_checkout_v2';
export const NEW_CHECKOUT_ORDER_CUSTOMISATION_RENTAL = 'new_checkout_order_customisation_rental';
export const NEW_CHECKOUT_ORDER_CUSTOMISATION_COLOR = 'new_checkout_order_customisation_color';
export const BANNER_FOR_ALL_USERS = 'referral_banner_for_logged_out_users';
export const YOUR_PAYMENTS_FEATURE_FLAG_KEY = 'frontshop_your_payments';
export const SUBSCRIPTION_PAYMENTS_FEATURE_FLAG_KEY = 'frontshop_subscription_payments';
export const PDP_BUNDLES_COMPONENT = 'pdp_bundles_component';
export const PLP_BUNDLES_COMPONENT = 'plp_bundles_component';
export const REASON_POLL_STEP_FEATURE_FLAG_KEY = 'reason_poll_step';
export const GROVER_CASH_WEB_FLAG_KEY = 'grover_cash_web';
export const NEW_REFERRALS_HOST_FLAG_KEY = 'new_referrals_host';
export const NEW_REFERRALS_GUEST_CTA_COPY = 'new_referrals_guest_cta_copy';
export const NEW_REFERRALS_HOST_GAMIFICATION = 'new_referrals_host_gamification';
export const NEW_REFERRALS_COPY_BUTTON = 'new_referrals_copy_button';
export const MIX_CATALOG_FEATURE_FLAG_KEY = 'mix_catalog';
export const YOUR_TECH_MIX_FEATURE_FLAG_KEY = 'your_tech_mix';
export const MIX_GLOBAL_FEATURE_FLAG_KEY = 'mix';
export const MIX_PRICE_INTERNAL_USERS_FLAG_KEY = 'mix_price_internal_users';
export const BUSINESS_STORE_DEALS_FLAG_KEY = 'business_store_deals';
export const PREORDER_PRODUCTS_FEATURE_FLAG_KEY = 'preorder_products';
export const ADDRESS_2FA_FLAG_KEY = 'address_2fa';
export const REQUEST_OFFER_B2B_FLAG_KEY = 'request_offer_b2b';
export const SEO_CONTENT_FOR_CATALOGUE_PAGES_KEY = 'seo_content_for_catalogue_pages';
export const CATALOG_PAGINATION = 'catalog_pagination';
export const SWAP_CHECKOUT_ADDRESS_FEATURE_KEY = 'swap_checkout_address_feature';
export const MIX_SWAPPING_FEATURE_FLAG_KEY = 'mix_swapping';
export const THREEDS_WARNING_MESSAGE_FLAG_KEY = '3ds_warning_message';
export const PRODUCT_ANNOUNCEMENTS_FLAG_KEY = 'product_announcement';
export const RENTAL_PLAN_TESTS_FLAG_KEY = 'rental_plan_tests';
export const APP_LOGGED_OUT_BROWSING = 'app_logged_out_browsing';
export const PURCHASE_OPTION_AB_TEST_FLAG_KEY = 'purchase_option_ab_test';
export const DISPLAY_PURCHASE_OPTION_PDP_FLAG_KEY = 'display_purchase_option_pdp';
export const PURCHASE_OPTION_HIDDEN_FLAG_KEY = 'purchase_option_hidden';
export const PURCHASE_OPTION_EXTENDED_FAQ_FLAG_KEY = 'purchase_option_extended_faq';
export const STRUCTURED_SPECS_FLAG_KEY = 'structured_specs';
export const PRODUCT_FREE_TRIAL = 'product_free_trial';
export const NOTIFICATIONS_FLAG_KEY = 'your_profile_notifications';
export const MIX_LINK_ON_NAV_FLAG_KEY = 'mix_link_on_nav';
export const CART_OVERLAY_VOUCHER_PREVIEW_FLAG_KEY = 'cart_overlay_voucher_preview';
export const LINK_PRODUCTS_IN_CART_FLAG_KEY = 'link_products_in_cart';
export const BLOCK_MANAGED_ACCOUNT_CHECKOUT_FLAG_KEY = 'block_managed_account_checkout';
export const BLOCK_MANAGED_ACCOUNT_SELF_SERVE_PURCHASE_KEY =
    'block_managed_account_self_serve_purchase';
export const MGM_HACK_FLAG_KEY = 'member_get_member_hack';
export const REFER_A_FRIEND_FOOTER_LINK = 'refer_a_friend_footer_link';
export const AVAILABILITY_FILTER_PRE_SELECTION = 'availability_filter_pre_selection';
export const CARD_LINK_ON_WEB_FLAG_KEY = 'card_link_on_web';
export const YOUR_CARD_FEATURE_FLAG_KEY = 'grover_app_your_card';
export const CONTACT_NOW_CTA_LANDING_PAGE = 'contact_now_cta_landing_page';
export const YOUR_TECH_PAYMENT_METHOD_MODAL_AND_BUTTON =
    'your_tech_payment_method_modal_and_button';
export const YOUR_TECH_PAYMENT_METHOD_PAGE_BUTTON = 'your_tech_payment_method_page_button';
export const LONG_REVOCATION_PERIOD_TEST = 'long_revocation_test';
export const SIGN_UP_BUSINESS_WITH_COMPANY_SIZE = 'sign_up_business_with_company_size';
export const CARD_LANDING_TEASER_TOGGLE = 'card_landing_teaser_toggle';
export const BLACK_FRIDAY_LANDING_PAGES = 'black_friday_landing_pages';
export const PRODUCT_DISCOUNT_PERCENTAGE_CHIP = 'product_discount_percentage_chip';
export const PRODUCT_ENERGY_LABEL = 'product_energy_label';
export const CHECKOUT_VOUCHER_EXPLANATION = 'checkout_voucher_explanation';
export const PRODUCT_CUSTOMIZATION_SERVICE_DATA = 'product_customization_service_data';
export const LOW_STOCK_CHIP = 'low_stock_chip';
export const SITEWIDE_BANNER = 'sitewide_banner';
export const COUNTDOWN_TIMERS = 'countdown_timers';
export const PRODUCT_CUSTOMIZATION_SERVICE_DATA_LISTING_PAGES =
    'product_customization_service_data_listing_pages';
export const RECOMMENDATION_ENGINE = 'recommendation_engine';
export const PDP_PURCHASE_OPTION_TEXT = 'pdp_purchase_option_text';
export const HOW_IT_WORKS_PAGE_VARIANT = 'how_it_works_page_variant';
export const PURCHASE_FLOW_RETENTION_TEST_FLAG_DATA = 'purchase_flow_retention_test_v2';
export const SATISFACTION_SURVEY_COMPONENT = 'satisfaction_survey_component';
export const LOCKED_DEVICE_RETURN_STEP = 'locked_device_return_step';
export const ENHANCE_FLEX_CONTRACT_RENTAL_PERIOD = 'enhance_flex_contract_rental_period';
export const CATEGORIES_WIDGET_ON_HOMEPAGE = 'categories_widget_on_homepage';
export const CHECKOUT_ORDER_REVIEW_CTA = 'checkout_order_review_cta';
export const CHECKOUT_CART_OVERLAY_CTA = 'checkout_cart_overlay_cta';
export const OTP_ADYEN_DROP_IN_REPLACEMENT = 'otp_adyen_drop_in_replacement';
export const HOMEPAGE_MC1_AB_TEST = 'homepage_mc1_cta_ab';
export const TERMS_AND_CONDITIONS = 'terms_and_conditions';

export const newCheckoutVariants = {
    withHomeAddress: 'with-home-address',
    noHomeAddress: 'no-home-address',
    control: 'control',
};
export const flagObjects = {
    catalogUiStoreMigration: 'frontshop',
};
export const B2B_SELF_CHECKOUT = 'b2b_self_checkout';
export const B2B_CREATE_COMPANY_USE_SERVICE = 'b2b_create_company_use_service';
export const COUNTDOWN_DEALS_OF_THE_WEEK = 'countdown_deals_of_the_week';
export const DEALS_OF_THE_WEEK_EXPERIMENT = 'deals_of_the_week_experiment';
export const B2B_OPEN_STORE = 'b2b_open_store';
export const FREELANCER_COMPANY_TYPE = 'freelancer_company_type';
export const CARD_NATIVE_FLOW_TOGGLE = 'grover_card_native_flow_toggle';
export const B2B_SIGNUP_PKG_ENABLED = 'b2b_signup_pkg_enabled';
export const TOGGLE_CARD_NEW_LANDING_PAGE = 'toggle_card_new_landing_page';
export const BLACK_FRIDAY_DEALS_FILTER = 'black_friday_deals_filter';
export const APP_BASED_TOKEN_REFRESHMENT = 'app_based_token_refreshment';
export const VALUE_PROPS_ENABLED = 'value_props_on_homepage';
export const SKIP_ONFIDO_B2B = 'skip_onfido_b2b';
export const COMPANY_DASHBOARD_ENABLED = 'company-dashboard';
export const VERIFICATION_CAMPAIGN = 'verification_campaign';
export const GROVER_CASH_RECEIVE_PERCENTAGE = 'grover_cash_receive_percentage';
export const PAID_GROVER_CARE = 'grover_care_options';

export const SUBSCRIPTION_CANCELLATION_LINK_FLAG_KEY = 'subscription_cancellation_link';
export const ENCLOSED_CHECKOUT = 'enclosed_checkout';
export const PERSONAL_INFORMATION_ON_DASHBOARD = 'personal_information_dashboard_distribution';
export const ENCLOSED_CART = 'enclosed_checkout_cart';
export const ACCOUNT_SECURITY_ON_DASHBOARD = 'account_security_on_dashboard';
export const GROVER_CASH_ON_DASHBOARD = 'grover_cash_on_dashboard';
export const COMPANY_DASHBOARD_ON_DASHBOARD = 'company_dashboard_on_dashboard';
export const NOTIFICATIONS_ON_DASHBOARD = 'notifications_on_dashboard';

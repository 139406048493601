/* eslint-disable */
// {
//   data: {},
//   level: '' -> success | error | warning | info
// }

const defaultState = [];

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'ADD_NOTIFICATION':
      return state.concat([action.data,]);

    case 'REMOVE_NOTIFICATION':
      return state.filter((n, i) => n.id !== action.data.errorId);

    default:
      return state;
  }
}

import { browserHistory } from 'react-router';

import loadConfiguration from 'ggApp/utils/configuration/configuration';
import { normalize, isManualPageviewNeeded, getPlatformContext } from './utils';
import { SNOWPLOW_EVENT, SNOWPLOW_NAMESPACE } from './constants';
import { EventModel } from './types';
// eslint-disable-next-line import/no-cycle
import { FeatureFlags, trackFeatureFlagsInBatches } from './events/featureFlag';

const { SNOWPLOW_COLLECTOR_URL, SNOWPLOW_TRACKER_NAME } = loadConfiguration();

export interface SnowplowPageViewPayload {
    featureFlags: FeatureFlags;
}

export function sendStructEvent(data: EventModel): void {
    const { [SNOWPLOW_NAMESPACE]: snowplow } = window;
    const { eventCategory, eventAction, eventLabel, eventProperty } = normalize(data);
    if (typeof snowplow === 'function') {
        snowplow(SNOWPLOW_EVENT.structEvent, eventCategory, eventAction, eventLabel, eventProperty);
    }
}

export function snowplowPageView(pageviewPayload: SnowplowPageViewPayload): void {
    if (!window[SNOWPLOW_NAMESPACE]) return;

    if (pageviewPayload?.featureFlags) {
        trackFeatureFlagsInBatches(pageviewPayload.featureFlags);
    }

    window[SNOWPLOW_NAMESPACE](SNOWPLOW_EVENT.pageView);
}

export const initializePageViewTracking = (pageviewPayload: SnowplowPageViewPayload): void => {
    try {
        let lastLocation = browserHistory.getCurrentLocation().pathname;
        if (!isManualPageviewNeeded(lastLocation)) {
            snowplowPageView(pageviewPayload);
        }

        browserHistory.listen((location) => {
            if (lastLocation !== location.pathname && !isManualPageviewNeeded(location.pathname)) {
                snowplowPageView(pageviewPayload);
            }
            lastLocation = location.pathname;
        });
    } catch (e) {
        console.warn('Snowplow page view tracking failed to initialize');
    }
};

export const initializeSnowplow = (): void => {
    try {
        window.GlobalSnowplowNamespace = window.GlobalSnowplowNamespace || [];
        window.GlobalSnowplowNamespace.push(SNOWPLOW_NAMESPACE);
        // eslint-disable-next-line func-names
        window[SNOWPLOW_NAMESPACE] = function(): void {
            // eslint-disable-next-line prefer-rest-params
            (window[SNOWPLOW_NAMESPACE].q = window[SNOWPLOW_NAMESPACE].q || []).push(arguments);
        };
        window[SNOWPLOW_NAMESPACE].q = window[SNOWPLOW_NAMESPACE].q || [];
        window[SNOWPLOW_NAMESPACE](
            SNOWPLOW_EVENT.newTracker,
            SNOWPLOW_TRACKER_NAME,
            SNOWPLOW_COLLECTOR_URL,
            {
                platform: getPlatformContext(),
                encodeBase64: false,
                eventMethod: 'get',
                contexts: {
                    webPage: true,
                    performanceTiming: true,
                },
            },
        );
        window[SNOWPLOW_NAMESPACE](SNOWPLOW_EVENT.setUserIdFromCookie, 'user_id');
        window[SNOWPLOW_NAMESPACE](SNOWPLOW_EVENT.enableActivity, 10, 10);
    } catch (e) {
        console.warn('Snowplow script failed to initialize');
    }
};

import React, { Component } from 'react';

import { withCookieConsentActions } from './withCookieConsentActions';

/**
 * withCookiePolicySettingsLoader
 * This HOC inits cookie consent values from cookies on component mounting
 * @param {React.ComponentType<any>} EnhancedComponent
 * @returns {React.ComponentType<any>} Wrapped component
 */
export function withCookiePolicySettingsLoader(EnhancedComponent) {
    class CookiePolicySettingsLoaderHOC extends Component {
        componentDidMount() {
            const { initCookiePolicySettings } = this.props;
            initCookiePolicySettings();
        }

        render() {
            return <EnhancedComponent {...this.props} />;
        }
    }

    const EnhancedCookiePolicySettingsLoaderHOC = withCookieConsentActions(
        CookiePolicySettingsLoaderHOC,
    );

    return EnhancedCookiePolicySettingsLoaderHOC;
}

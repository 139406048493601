import { MODULE_NAME } from '../constants';

export const GET_SUBSCRIPTIONS = `${MODULE_NAME}/GET_SUBSCRIPTIONS`;
export const GET_SUBSCRIPTIONS_SUCCESS = `${MODULE_NAME}/GET_SUBSCRIPTIONS_SUCCESS`;
export const GET_SUBSCRIPTIONS_ERROR = `${MODULE_NAME}/GET_SUBSCRIPTIONS_ERROR`;

export const GET_SUBSCRIPTION = `${MODULE_NAME}/GET_SUBSCRIPTION`;
export const GET_SUBSCRIPTION_SUCCESS = `${MODULE_NAME}/GET_SUBSCRIPTION_SUCCESS`;
export const GET_SUBSCRIPTION_ERROR = `${MODULE_NAME}/GET_SUBSCRIPTION_ERROR`;

export const GET_SUBSCRIPTION_RENTAL_PLAN = `${MODULE_NAME}/GET_SUBSCRIPTION_RENTAL_PLAN`;
export const GET_SUBSCRIPTION_RENTAL_PLAN_SUCCESS = `${MODULE_NAME}/GET_SUBSCRIPTION_RENTAL_PLAN_SUCCESS`;
export const GET_SUBSCRIPTION_RENTAL_PLAN_ERROR = `${MODULE_NAME}/GET_SUBSCRIPTION_RENTAL_PLAN_ERROR`;

export const UPDATE_SUBSCRIPTION_RENTAL_PLAN = `${MODULE_NAME}/UPDATE_SUBSCRIPTION_RENTAL_PLAN`;
export const UPDATE_SUBSCRIPTION_RENTAL_PLAN_SUCCESS = `${MODULE_NAME}/UPDATE_SUBSCRIPTION_RENTAL_PLAN_SUCCESS`;
export const UPDATE_SUBSCRIPTION_RENTAL_PLAN_ERROR = `${MODULE_NAME}/UPDATE_SUBSCRIPTION_RENTAL_PLAN_ERROR`;

export const UPDATE_SUBSCRIPTION_PAYMENT_METHOD = `${MODULE_NAME}/UPDATE_SUBSCRIPTION_PAYMENT_METHOD`;
export const UPDATE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS = `${MODULE_NAME}/UPDATE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS`;
export const UPDATE_SUBSCRIPTION_PAYMENT_METHOD_ERROR = `${MODULE_NAME}/UPDATE_SUBSCRIPTION_PAYMENT_METHOD_ERROR`;

export const GET_SUBSCRIPTION_RETURN_LABEL = `${MODULE_NAME}/GET_SUBSCRIPTION_RETURN_LABEL`;
export const GET_SUBSCRIPTION_RETURN_LABEL_SUCCESS = `${MODULE_NAME}/GET_SUBSCRIPTION_RETURN_LABEL_SUCCESS`;
export const GET_SUBSCRIPTION_RETURN_LABEL_ERROR = `${MODULE_NAME}/GET_SUBSCRIPTION_RETURN_LABEL_ERROR`;
export const RESET_SUBSCRIPTION_RETURN_LABEL_SUCCESS = `${MODULE_NAME}/RESET_SUBSCRIPTION_RETURN_LABEL_SUCCESS`;

export const PURCHASE_SUBSCRIPTION = `${MODULE_NAME}/PURCHASE_SUBSCRIPTION`;
export const PURCHASE_SUBSCRIPTION_SUCCESS = `${MODULE_NAME}/PURCHASE_SUBSCRIPTION_SUCCESS`;
export const PURCHASE_SUBSCRIPTION_ERROR = `${MODULE_NAME}/PURCHASE_SUBSCRIPTION_ERROR`;

export const SET_SUBSCRIPTION_SYNCING_STATE = `${MODULE_NAME}/SET_SUBSCRIPTION_SYNCING_STATE`;

export const UPDATE_SUBSCRIPTION_DATA = `${MODULE_NAME}/UPDATE_SUBSCRIPTION_DATA`;

export const GET_SUBSCRIPTION_ACTIONS = `${MODULE_NAME}/GET_SUBSCRIPTION_ACTIONS`;
export const GET_SUBSCRIPTION_ACTIONS_SUCCESS = `${MODULE_NAME}/GET_SUBSCRIPTION_ACTIONS_SUCCESS`;
export const GET_SUBSCRIPTION_ACTIONS_ERROR = `${MODULE_NAME}/GET_SUBSCRIPTION_ACTIONS_ERROR`;

export const SET_CURRENT_SUBSCRIPTION_ID = `${MODULE_NAME}/SET_CURRENT_SUBSCRIPTION_ID`;
export const REMOVE_CURRENT_SUBSCRIPTION_ID = `${MODULE_NAME}/REMOVE_CURRENT_SUBSCRIPTION_ID`;

const defaultState = {
    type: undefined,
};

export default function(state = defaultState, action) {
    switch (action.type) {
        case 'COMPLETE_ORDER_FAILURE':
            return {
                type: action.data?.childError.response.data.error,
            };

        default:
            return state;
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const MetaTagsContainer = ({ metaTags, htmlAttributes, isBusinessStore }) => (
    <Helmet defaultTitle="Grover" {...(htmlAttributes && { htmlAttributes })}>
        {metaTags.title && <title>{metaTags.title}</title>}
        {!isBusinessStore && metaTags.robots && <meta name="robots" content={metaTags.robots} />}
        {isBusinessStore && <meta name="robots" content="noindex" />}
        {metaTags.description && <meta name="description" content={metaTags.description} />}
        {metaTags.keywords && <meta name="keywords" content={metaTags.keywords} />}
        {metaTags.image && <meta name="image" content={metaTags.image} />}
        {metaTags.seoImage && <meta property="og:image" content={metaTags.seoImage} />}
        {metaTags.seoImage && <meta property="og:image:type" content="image/png" />}
        {metaTags.seoImage && <meta property="og:image:width" content="1000" />}
        {metaTags.seoImage && <meta property="og:image:height" content="1000" />}
        {metaTags.themeColor && <meta name="theme-color" content={metaTags.themeColor} />}
    </Helmet>
);

MetaTagsContainer.propTypes = {
    metaTags: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        keywords: PropTypes.string,
        image: PropTypes.string,
        seoImage: PropTypes.string,
        themeColor: PropTypes.string,
        robots: PropTypes.string,
    }),
    htmlAttributes: PropTypes.shape({
        lang: PropTypes.string,
    }),
    isBusinessStore: PropTypes.bool,
};

MetaTagsContainer.defaultProps = {
    metaTags: {},
    htmlAttributes: null,
    isBusinessStore: false,
};

export default React.memo(MetaTagsContainer);

export function mapGroverAPIMarketingCard(contentfulMarketingCard) {
    const { title, description, image, type } = contentfulMarketingCard;
    const cta = contentfulMarketingCard.cta == null ? {} : contentfulMarketingCard.cta;

    return {
        ctaLink: cta.link,
        ctaText: cta.text,
        header: title,
        description,
        image: {
            url: image,
        },
        cta: {
            ctaText: cta.text,
            linkTo: cta.link,
            ctaStyle: 'secondary',
            componentType: 'button',
        },
        aspectRatio: 1,
        type,
    };
}

export function mapGroverAPIFeaturedList(featuredList) {
    return featuredList.map((item) => {
        switch (item.type) {
            case 'marketing_card':
                return mapGroverAPIMarketingCard(item);
            case 'product':
                return item;
            default:
                return null;
        }
    });
}

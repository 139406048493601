import styled from 'styled-components';

export const Root = styled.section`
    ${({ reviewsFetched }) => !reviewsFetched && `
        display: none;
    `}
    overflow-x: hidden;
    padding-top: calc(${({ theme }) => theme.spacers.XXL} + ${({ theme }) => theme.spacers.S});
    padding-bottom: calc(${({ theme }) => theme.spacers.XXL} + ${({ theme }) => theme.spacers.S});
    background-color: ${props => props.theme.palette.grey[4]};
    ${(props) => props.theme.superQuery.all.and.maxWidth.mobileMax.css`  
        padding-bottom: 24px;
        padding-left: 10px;
    `};
    ${(props) => props.theme.superQuery.all.and.minWidth.desktopMin.css`
        border-radius: 8px;
    `};
`;

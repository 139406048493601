/* eslint-disable consistent-return */
import { push, replace } from 'redux-router';
import { storeActive as activeStoreSelector } from 'ggApp/modules/store';
import { storeLangUrl } from 'ggApp/utils/storeLangInjection';

export function redirectus(url, external) {
    return (dispatch) => {
        if (external) {
            if (window.location.pathname === url) {
                // avoid the same page reloading in mobile app
                // eslint-disable-next-line no-unused-expressions
                !window.ReactNativeWebView && window.location.reload(true);
            } else {
                window.location.href = url;
            }
            return false;
        }

        dispatch(push(url));
    };
}

export function transitionTo(url, external) {
    return (dispatch, getState) => {
        const { code } = activeStoreSelector(getState());
        const nextUrl = `/${code}${url}`;

        dispatch(redirectus(nextUrl, external));
    };
}

export function mainTransition(url, external) {
    return (dispatch, getState) => {
        const state = getState();
        const nextUrl = storeLangUrl(state, url);

        dispatch(redirectus(nextUrl, external));
    };
}

export function reload() {
    window.location.reload(true);
    return { type: '' };
}

export function replaceTo(url) {
    return (dispatch, getState) => {
        const { code } = activeStoreSelector(getState());
        dispatch(replace(`/${code}${url}`));
    };
}

export function pushParams(params = {}) {
    return (dispatch, getState) => {
        const { router } = getState();
        const newQuery = {
            ...router.location.query,
            ...params,
        };

        dispatch(
            push({
                pathname: router.location.pathname,
                query: newQuery,
            }),
        );
    };
}

export function removeParams(params) {
    return (dispatch, getState) => {
        const { router } = getState();
        const { query } = router.location;
        if (!Array.isArray(params)) {
            return;
        }
        const newQuery = { ...query };
        params.forEach((p) => {
            if (newQuery[p]) {
                delete newQuery[p];
            }
        });

        dispatch(
            push({
                pathname: router.location.pathname,
                query: newQuery,
            }),
        );
    };
}

import styled, { css } from 'styled-components';

const Wrap = styled.div`
    grid-column: span 4;
    display: flex;
    background-color: ${props => props.theme.colors.ElementBackground};
    flex-direction: column;
    border: 2px solid ${props => props.theme.colors['Level 1']};
    border-radius: 8px;
    overflow: hidden;
    padding: ${({ theme }) => theme.spacers.L};
    width: 100%;
    &:focus {
        outline: none;
    }

    ${({ carousel, cardWidth }) => carousel
        && css`
            overflow: unset;
            display: inline-block;
            max-height: 100%;
            min-width: ${cardWidth}px;
            max-width: ${cardWidth}px;

            scroll-snap-align: start; /* latest (Chrome 69+) */
            scroll-snap-coordinate: 0% 50%; /* older (Firefox/IE) */
            -webkit-scroll-snap-coordinate: 0% 50%; /* older (Safari) */
            :not(:nth-last-child(1)):not(:nth-last-child(2)) {
                margin-right: 16px;
            }
        `}
`;

const Header = styled.h3`
    display: flex;
    margin-top: ${({ theme }) => theme.spacers.XXL};
    ${({ theme }) => css(theme.typography.Subheadline)};
    color: ${props => props.theme.colors.Foreground};
`;

const Image = styled.img`
    width: 100%;
`;

const Text = styled.p`
    display: flex;
    margin-top: ${({ theme }) => theme.spacers.M};
    color: ${props => props.theme.colors.Foreground};
    ${({ theme }) => css(theme.typography.Paragraph)}
`;

export {
    Wrap, Header, Image, Text,
};

/* eslint-disable */
import React from 'react';
import {
    string, func, bool, node,
} from 'prop-types';
import ScrollLock from 'react-scrolllock-configurable';
import { Container } from './styled';

/**
 * Uses ScrollLock component to prevent body scrolling on Ios.
 * Child compontents that require scroll behaivour
 * should be wrapped in react-scrollock's TouchScrollable component.
 */
const Overlay = ({
    className,
    shouldShow,
    onKeyUp,
    children,
}) => (
    <Container
        className={className}
        onKeyUp={onKeyUp}
        shouldShow={shouldShow}
    >
        <ScrollLock isActive={shouldShow} />
        {children}
    </Container>
);

Overlay.propTypes = {
    className: string,
    onKeyUp: func,
    shouldShow: bool,
    children: node,
};

Overlay.defaultProps = {
    className: '',
    onKeyUp: () => {},
    shouldShow: false,
    children: null,
};

export default Overlay;

/* eslint-disable */
import { createSelector, } from 'reselect';
import { MODULE_NAME, } from './../constants';

export const moduleState = state => state[MODULE_NAME] || {};

export const headers = createSelector(
  moduleState,
  moduleState => moduleState.headers || {}
);

export const config = createSelector(
    headers,
    headers => ({ headers, })
)
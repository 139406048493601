import styled, { css, keyframes } from 'styled-components';

import { screens } from 'ggApp/utils/mixins/screens';

const modalContentMove = keyframes`
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

const modalContentAnimation = css`
    animation: ${modalContentMove};
`;

const modalBackgroundTransition = keyframes`
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const modalBackgroundAnimation = css`
    animation: ${modalBackgroundTransition};
`;

export const ModalRoute = styled.div`
    @media (${screens.phone}) and (${screens.desktopMax}) {
        padding: 0;

        &.ggModalOpen {
            display: none;
        }

        &.ggModalRest {
            display: none;
        }
    }

    @media (${screens.desktop}) {
        position: fixed;
        z-index: 9990;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 30px;
        overflow: auto;
        background: rgba(245, 245, 245, 0.85);
        -webkit-overflow-scrolling: touch;

        ${({ withTransitions }) => withTransitions && css`
            &.__bg {
                ${modalBackgroundAnimation}
                animation-duration: 0.6s;
                animation-iteration-count: 1;
                animation-timing-function: ease;
                animation-delay: 0;
            }
        `}

        ${({ dark }) => dark && css`
            background: rgba(70, 70, 70, 0.85);
        `}

        ${({ mobileOnly }) => mobileOnly && css`
            display: none;
        `}
    }
`;

export const Content = styled.div`
    position: relative;
    margin: 0 auto;
    z-index: 2;
    background: #ffffff;
    padding: 0 100px;
    border-radius: 10px;
    border: 2px solid rgba(51, 51, 51, 0.07);
    overflow: hidden;

    ${({ dark }) => dark && css`
        background: #222329;
    `}

    ${({ noPadding }) => noPadding && css`
        padding: 0;
    `}

    @media (${screens.phone}) and (${screens.desktopMax}) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9990;
        border-radius: 0;
        border: none;
        width: 100%;
        padding: 0 15px 65px;
        box-shadow: none;
        overflow: auto;
        height: 100vh;

        ${({ dark }) => dark && css`
            padding: 0 15px;
        `}

        ${({ noMobileVerticalPadding }) => noMobileVerticalPadding && css`
            padding-top: 0;
            padding-bottom: 0;
        `}
    }

    @media (${screens.desktop}) {
        width: 710px;

        ${({ wide }) => wide && css`
            width: 70%;
        `}

        ${({ narrow }) => narrow && css`
            width: 460px;
            padding: 0 48px;
        `}

        ${({ withTransitions }) => withTransitions && css`
            ${modalContentAnimation}
            animation-duration: 0.6s;
            animation-iteration-count: 1;
            animation-timing-function: ease;
            animation-delay: 0;
        `}
    }
`;

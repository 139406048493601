import styled from 'styled-components';

export const FooterContainer = styled.footer`
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
`;

export const FooterLink = styled.a.attrs({
    rel: 'noopener noreferer',
    target: '_blank',
})`
    text-decoration: none;

    ${props => props.theme.typography.TinyParagraph}
    color: ${props => props.theme.colors['Level 3']};
    margin: 0 ${props => props.theme.spacers.L};

    :hover {
        color: ${props => props.theme.colors['Level 4']};
    }

    :active,
    :visited {
        color: ${props => props.theme.colors['Level 3']};
    }

    :first-child {
        margin-left: 0;
    }

    :last-child {
        margin-right: 0;
    }
`;

export const HelpButton = styled.button`
    text-decoration: none;

    ${props => props.theme.typography.TinyParagraph}
    color: ${props => props.theme.colors['Level 3']};
    margin: 0 ${props => props.theme.spacers.L};
    cursor: pointer;

    :hover {
        color: ${props => props.theme.colors['Level 4']};
    }

    :active,
    :visited {
        color: ${props => props.theme.colors['Level 3']};
    }
`;

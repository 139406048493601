export const MODULE_NAME = 'router';
export const INLINE_ACCESS_TOKEN_QUERY_PARAM = 'access_token';
export const INLINE_ORDER_NUMBER_QUERY_PARAM = 'order_number';

export const COMMON_QUERY_PARAM = {
    checkoutModal: 'cm',
    waitingModal: 'wm',
};

export const MODAL_QUERY_ACTIVE = 'active';

export const BUSINESS_REDIRECT_QUERY_KEY = 'businessRedirect';

export * from './propTypes';

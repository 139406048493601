import { EnergyRating } from 'ggApp/types/graphql';

import EnergyClassA1 from '../assets/energy_class_A1.svg';
import EnergyClassA2 from '../assets/energy_class_A2.svg';
import EnergyClassA3 from '../assets/energy_class_A3.svg';
import EnergyClassA from '../assets/energy_class_A.svg';
import EnergyClassB from '../assets/energy_class_B.svg';
import EnergyClassC from '../assets/energy_class_C.svg';
import EnergyClassD from '../assets/energy_class_D.svg';
import EnergyClassE from '../assets/energy_class_E.svg';
import EnergyClassF from '../assets/energy_class_F.svg';
import EnergyClassG from '../assets/energy_class_G.svg';

export const energyRatingIcon: Record<EnergyRating, SVGElement> = {
    [EnergyRating.A1]: EnergyClassA1,
    [EnergyRating.A2]: EnergyClassA2,
    [EnergyRating.A3]: EnergyClassA3,
    [EnergyRating.A]: EnergyClassA,
    [EnergyRating.B]: EnergyClassB,
    [EnergyRating.C]: EnergyClassC,
    [EnergyRating.D]: EnergyClassD,
    [EnergyRating.E]: EnergyClassE,
    [EnergyRating.F]: EnergyClassF,
    [EnergyRating.G]: EnergyClassG,
};

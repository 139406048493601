import styled, { css } from 'styled-components';

import { DesktopCarouselCardWithEvents, MobileCarouselCardWithEvents } from 'ggApp/shared/components/widgets/CampaignsWidget/CarouselMarketingCard';
import { NextArrow, PrevArrow } from 'ggApp/shared/components/Carousel/v2/styles';

export const OverflowWrapper = styled.div`
    position: relative;
    overflow-x: hidden;
`;

export const CarouselWrapper = styled.div`
    display: flex;
    overflow: visible;
    width: max-content;
    touch-action: pan-y;
    transform: ${({ offset }) => `translateX(-${offset}px)`};
    transition: ${({ transition }) => transition ? 'all 1s ease' : 'unset'};
`;

export const DesktopCarouselCard = styled(DesktopCarouselCardWithEvents)`
    margin-bottom: 16px;
    width: ${({ width }) => width? `${width}px`: '100vw'};
    min-width: 100vw;
`;

export const MobileCarouselCard = styled(MobileCarouselCardWithEvents)`
    margin-bottom: 16px;
    width: 100vw;
`;

export const ControlsWrapper = styled.div`
    position: absolute;
    bottom: 32px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const DotsContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 10px;
    border-radius: 24px;
    background-color: ${({ theme }) => theme.colors['Level 3']};
`;

export const CarouselIndexDot = styled.div`
    margin: 0px 7px;
    border-radius: 50%;
    cursor: pointer;
    width: ${({ selected }) => selected ? '10px' : '6px'};
    height: ${({ selected }) => selected ? '10px' : '6px'};
    border: ${({ theme }) => `1px solid ${theme.colors.Background}`};
    background-color: ${({ selected, theme }) => selected ? `${theme.colors.Background}` : 'none'};
    &:hover {
        ${({ isMobile, isTablet }) => !isMobile && !isTablet && css`
            background-color: ${({ selected, theme }) => selected ? `${theme.colors.Background}` : 'rgba(255, 255, 255, 0.5)'};
        `}
    }
`;

export const Next = styled(NextArrow)`
    visibility: visible;
    opacity: 1;
    right: 6px;
`;

export const Prev = styled(PrevArrow)`
    visibility: visible;
    opacity: 1;
    left: 6px;
`;
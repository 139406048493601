import { sendStructEvent } from '../../api';

interface PayloadModel {
    eventCategory: string;
    eventAction: string;
    eventLabel: string;
    eventProperty: {
        changed_language: string;
        system_language: string;
    };
}

export const sendChangeLanguageEvent = (payload: PayloadModel): void => {
    const { eventCategory, eventAction, eventLabel, eventProperty } = payload;

    sendStructEvent({
        eventCategory: eventCategory || 'language',
        eventAction: eventAction || 'change',
        eventLabel: eventLabel || '',
        eventProperty,
    });
};

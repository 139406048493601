/* eslint-disable */
/**
 * Throttle
 * @param {function} fn Function to throttle
 * @param {number} timeout Timeout interval
 * @example
 * throttle(function() { console.log('debounced') }, 300);
 * @returns throttled function
 */
export default function throttle(fn, delay) {
    let lastCall = 0;
    return function (...args) {
        const now = (new Date).getTime();
        if (now - lastCall < delay) {
            return;
        }
        lastCall = now;
        return fn(...args);
    }
}

import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import FavoritesIcon from 'ggApp/shared/icons/favorites';
import { AuthModal, FLOW_NAMES } from 'ggApp/modules/auth/v3';
import { toBooleanFlag } from 'ggApp/modules/featureFlag/utils';

import { Root } from './styles';

const FavoriteButton = ({
    id,
    isLoggedIn,
    active,
    className,
    slug,
    onClickCallback,
    removeFromFavorites,
    addToFavorites,
    theme,
}) => {
    const [favorite, setFavorite] = useState(toBooleanFlag(active));
    const [animatable, setAnimatable] = useState(false);
    const [localModal, setLocalModal] = useState(null);

    const switchFavoriteState = useCallback(() => {
        if (favorite) {
            removeFromFavorites({ slug, id });
        } else {
            addToFavorites({ slug, id });
        }
        setFavorite((is) => !is);
        setAnimatable(!favorite);
    }, [favorite, removeFromFavorites, addToFavorites, id, slug]);

    useEffect(() => {
        if (favorite !== toBooleanFlag(active)) {
            setFavorite(toBooleanFlag(active));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active]);

    const createModalRef = useCallback((modal) => {
        setLocalModal(modal);
    }, []);

    const toggleStatusLoggedIn = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            onClickCallback({ isFavorite: !favorite, isLoggedIn });
            switchFavoriteState();
        },
        [onClickCallback, favorite, isLoggedIn, switchFavoriteState],
    );

    const toggleStatusLoggedOut = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            onClickCallback({ isFavorite: !favorite, isLoggedIn });
            if (localModal) {
                localModal.openModal();
            }
        },
        [onClickCallback, localModal, favorite, isLoggedIn],
    );

    return (
        <>
            <Root
                data-testid="favorite-button-root-id"
                className={className}
                onClick={isLoggedIn ? toggleStatusLoggedIn : toggleStatusLoggedOut}
                active={favorite}
                animatable={animatable}
            >
                <FavoritesIcon color={theme.colors['Level 3']} />
            </Root>
            <AuthModal
                modalRef={createModalRef}
                flow={FLOW_NAMES.SIGN_IN}
                onSuccess={switchFavoriteState}
            />
        </>
    );
};

FavoriteButton.propTypes = {
    id: PropTypes.number.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    active: PropTypes.bool,
    className: PropTypes.string,
    slug: PropTypes.string.isRequired,
    onClickCallback: PropTypes.func,
    removeFromFavorites: PropTypes.func,
    addToFavorites: PropTypes.func,
    theme: PropTypes.shape({
        colors: PropTypes.object,
    }).isRequired,
};

FavoriteButton.defaultProps = {
    active: false,
    className: undefined,
    removeFromFavorites: () => undefined,
    addToFavorites: () => undefined,
    onClickCallback: () => undefined,
};

export default FavoriteButton;

/* eslint-disable */
import { createSelector, } from 'reselect';
import { MODULE_NAME, ADYEN_PAYMENT_CC, ADYEN_PAYMENT_SEPA, } from './constants'

export const moduleState = state => state[MODULE_NAME];

export const paymentGateways = createSelector(
  moduleState,
  moduleState => moduleState.paymentGateways
);

export const paymentMethods = createSelector(
  moduleState,
  moduleState => moduleState.paymentMethods
);

export const submittedPayment = createSelector(
  moduleState,
  moduleState => moduleState.submittedPayment
);

export const savedPaymentData = createSelector(
  moduleState,
  moduleState => moduleState.savedPaymentData
);

export const savedPaymentDataNavigator = createSelector(
  savedPaymentData,
  savedPaymentData => {
    switch(savedPaymentData.type) {
      case ADYEN_PAYMENT_CC: {
        const { card, } = savedPaymentData.data
        return [
          `Credit card / Debit card **** ${card.cardNumber.slice(-4)}`,
          `Expiration date: ${card.expiryMonth}/${card.expiryYear}`,
        ]
      }

      case ADYEN_PAYMENT_SEPA: {
        const { iban, holderName, } = savedPaymentData.data
        return [
          `IBAN: ${iban}`,
          `Account: ${holderName}`,
        ]
      }

      default:
        return []
    }
  }
);
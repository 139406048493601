const defaultState = [
    { id: '+49', country: 'de' },
    { id: '+44', country: 'gb' },
    { id: '+31', country: 'nl' },
    { id: '+43', country: 'at' },
    { id: '+1', country: 'us' },
    { id: '+41', country: 'ch' },
    { id: '+34', country: 'es' },
];

export default function() {
    return defaultState;
}

import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    registerTeaserClickEvent,
    registerTeaserImpressionEvent,
    registerTeaserClickProductEvent,
} from 'ggApp/modules/gaAnalytics/actions';
import { WIDGET_NAMES } from 'ggApp/modules/gaAnalytics/constants';
import withIntersectionObserver from 'ggApp/modules/tracking/hocs/withIntersectionObserverV2';

import ProductCarousel from './ProductCarousel';

const enhance = compose(
    connect(null, (dispatch) => ({
        _registerTeaserClickProductEvent: bindActionCreators(
            registerTeaserClickProductEvent,
            dispatch,
        ),
    })),
    withProps(
        ({
            cta: { url },
            title,
            _registerTeaserClickProductEvent,
            firstIndexProduct,
            products,
            ctaViewAll,
        }) => ({
            onEnterCallback: registerTeaserImpressionEvent({ url, title }),
            onClickCTA: registerTeaserClickEvent({
                widgetName: WIDGET_NAMES.productListing,
                url,
                title,
                ctaViewAll,
            }),
            onClickProduct: (productIndex) =>
                _registerTeaserClickProductEvent({
                    widgetName: WIDGET_NAMES.productListing,
                    url,
                    title,
                    firstIndexProduct,
                    productIndex,
                }),
            threshold: products.length > 8 ? 0.5 : 0.8,
        }),
    ),
    withIntersectionObserver,
);

export default enhance(ProductCarousel);

/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

import { elevation, elevationTransition } from 'ggApp/utils/mixins';
import NavigationLink from 'ggApp/shared/common/NavigationLink';

const Container = styled(NavigationLink)`
    height: 32px;
    padding: 0 16px;
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background: #F1F1F1;
    color: #333333;
    margin: 0;
    ${elevation(0, false)}
    ${elevationTransition(false)}
    transition-property: all;
    outline: none;
    > * {
        cursor: pointer;
    }
    &:hover,
    &:focus {
        background: white;
        color: #333333;
        ${elevation(2, false)};
    }
    &:active {
        ${elevation(1, false)}
        transition-duration: 0s;
    }
    ${({ accentColor, backgroundColor, shadowColor }) => accentColor
        && css`
        background: ${backgroundColor};
        color: ${accentColor};
        &:hover,
        &:focus {
            background: #FFFFFF;
            color: ${accentColor};
            ${elevation(2, false, shadowColor, shadowColor)};
        }
    `};
`;

export { Container };

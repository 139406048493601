import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { registerTeaserClickProductEvent } from 'ggApp/modules/gaAnalytics/actions';
import { favoriteList as favoriteListSelector } from 'ggApp/modules/favorites/selectors';

import CountdownWidget, { CountdownWidgetProps } from './CountdownWidget';

const enhance = compose<CountdownWidgetProps, {}>(
    connect(
        createStructuredSelector({
            favoriteList: favoriteListSelector,
        }),
        (dispatch) => ({
            _registerTeaserClickProductEvent: bindActionCreators(
                registerTeaserClickProductEvent,
                dispatch,
            ),
        }),
    ),
);

export default enhance(CountdownWidget);

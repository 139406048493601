const GREETING = 'GREETING';
const PASSWORD = 'PASSWORD';
const SENDING_PHONE = 'SENDING_PHONE';
const CONFIRM_PHONE = 'CONFIRM_PHONE';
const BUSINESS_DATA = 'BUSINESS_DATA';
const BUSINESS_ADDRESS = 'BUSINESS_ADDRESS';
const SUCCESS_CUSTOMER = 'SUCCESS_CUSTOMER';
const SIGN_IN = 'SIGN_IN';
const PASSWORD_RECOVERY = 'PASSWORD_RECOVERY';
const PASSWORD_RECOVERY_SUCCESS = 'PASSWORD_RECOVERY_SUCCESS';
const NEWSLETTER = 'NEWSLETTER';
const FULL_NAME = 'FULL_NAME';
const BIRTHDATE = 'BIRTHDATE';
const IDENTITY_VERIFICATION_BENEFITS = 'IDENTITY_VERIFICATION_BENEFITS';
const IDENTITY_VERIFICATION_CONSENT = 'IDENTITY_VERIFICATION_CONSENT';
const IDENTITY_VERIFICATION = 'IDENTITY_VERIFICATION';
const IDENTITY_VERIFICATION_ERROR = 'IDENTITY_VERIFICATION_ERROR';
const COMPANY_RELATIONSHIP_WRAPPER = 'COMPANY_RELATIONSHIP_WRAPPER';
const COMPANY_RELATIONSHIP = 'COMPANY_RELATIONSHIP';
const COMPANY_TYPE = 'COMPANY_TYPE';
const MISSING_COMPANY_TYPE = 'MISSING_COMPANY_TYPE';
const COMPANY_SIZE = 'COMPANY_SIZE';
const SUCCESS_LEGAL_REP_SIGNUP = 'SUCCESS_LEGAL_REP_SIGNUP';
const AWAITING_ID_VERIFICATION = 'AWAITING_ID_VERIFICATION';
const INVALID_LINK = 'INVALID_LINK';
const LEGAL_REP_EMAIL = 'LEGAL_REP_EMAIL';
const EMPLOYEE_REG_INFO = 'EMPLOYEE_REG_INFO';
const LEGAL_VERIFICATION_INITIAL = 'LEGAL_VERIFICATION_INITIAL';
const LEGAL_VERIFICATION_APPROVAL = 'LEGAL_VERIFICATION_APPROVAL';
const LEGAL_VERIFICATION_FINISH = 'LEGAL_VERIFICATION_FINISH';
const PASSWORD_SETUP = 'PASSWORD_SETUP';
const PASSWORD_SETUP_ERROR = 'PASSWORD_SETUP_ERROR';
const OVERVIEW_BUSINESS = 'OVERVIEW_BUSINESS';
const SIGN_IN_FROM_CHECKOUT = 'SIGN_IN_FROM_CHECKOUT';
const GREETING_FROM_CHECKOUT = 'SIGN_IN_GREETING_FROM_CHECKOUT';
const ERROR_SCREEN_UNDER_18 = 'ERROR_SCREEN_UNDER_18';
const VERIFICATION_ERROR_PROFILE = 'VERIFICATION_ERROR_PROFILE';
const VERIFICATION_IN_PROGRESS_PROFILE = 'VERIFICATION_IN_PROGRESS_PROFILE';
const VERIFICATION_BENEFITS_PROFILE = 'VERIFICATION_BENEFITS_PROFILE';
const PROFILE_VERIFICATION_CONFIRMATION = 'PROFILE_VERIFICATION_CONFIRMATION';
const MIX_CHECKOUT_VERIFICATION_ERROR = 'MIX_CHECKOUT_VERIFICATION_ERROR';
const PROTECTIVE_PASSWORD = 'PROTECTIVE_PASSWORD';
const PROTECTIVE_PASSWORD_DETAILS = 'PROTECTIVE_PASSWORD_DETAILS';
const TWO_FACTOR_AUTH_CODE = 'TWO_FACTOR_AUTH_CODE';
const TWO_FACTOR_AUTH_RESEND_CODE = 'TWO_FACTOR_AUTH_RESEND_CODE';
const PHONE_NUMBER_UPDATE = 'PHONE_NUMBER_UPDATE';
const EMAIL_UPDATE = 'EMAIL_UPDATE';
const PHONE_NUMBER_UPDATE_CONFIRMATION = 'PHONE_NUMBER_UPDATE_CONFIRMATION';
const PHONE_NUMBER_UPDATE_SUCCESS = 'PHONE_NUMBER_UPDATE_SUCCESS';
const EMAIL_UPDATE_SUCCESS = 'EMAIL_UPDATE_SUCCESS';
const EMAIL_UPDATE_FAILURE = 'EMAIL_UPDATE_FAILURE';
const EMAIL_UPDATE_AUTH_CODE = 'EMAIL_UPDATE_AUTH_CODE';
const EMAIL_UPDATE_CONFIRMATION_SUCCESS = 'EMAIL_UPDATE_CONFIRMATION_SUCCESS';
const IDENTITY_VERIFICATION_CONFIRMATION = 'IDENTITY_VERIFICATION_CONFIRMATION';
const IDENTITY_VERIFICATION_CONFIRMATION_CAMPAIGN = 'IDENTITY_VERIFICATION_CONFIRMATION_CAMPAIGN';
const NATIONAL_ID_REGISTRATION = 'NATIONAL_ID_REGISTRATION';
const BANK_ACCOUNT_VERIFICATION_CONSENT = 'BANK_ACCOUNT_VERIFICATION_CONSENT';
const BANK_ACCOUNT_VERIFICATION = 'BANK_ACCOUNT_VERIFICATION';
const BANK_ACCOUNT_VERIFICATION_CONFIRMATION = 'BANK_ACCOUNT_VERIFICATION_CONFIRMATION';
const BANK_ACCOUNT_VERIFICATION_ERROR = 'BANK_ACCOUNT_VERIFICATION_ERROR';
const BANK_ACCOUNT_VERIFICATION_IN_PROGRESS = 'BANK_ACCOUNT_VERIFICATION_IN_PROGRESS';
const BANK_ACCOUNT_VERIFICATION_INVALID_LINK = 'BANK_ACCOUNT_VERIFICATION_INVALID_LINK';
const BANK_ACCOUNT_VERIFICATION_ALREADY_VERIFIED = 'BANK_ACCOUNT_VERIFICATION_ALREADY_VERIFIED';
const BLACK_FRIDAY_GREETING = 'BLACK_FRIDAY_GREETING';
const BLACK_FRIDAY_SIGN_IN = 'BLACK_FRIDAY_SIGN_IN';

const stepNames = {
    GREETING,
    FULL_NAME,
    BIRTHDATE,
    PASSWORD,
    SENDING_PHONE,
    CONFIRM_PHONE,
    BUSINESS_DATA,
    BUSINESS_ADDRESS,
    SUCCESS_CUSTOMER,
    SIGN_IN,
    PASSWORD_RECOVERY,
    PASSWORD_RECOVERY_SUCCESS,
    NEWSLETTER,
    IDENTITY_VERIFICATION_BENEFITS,
    IDENTITY_VERIFICATION_CONSENT,
    IDENTITY_VERIFICATION,
    IDENTITY_VERIFICATION_ERROR,
    COMPANY_RELATIONSHIP_WRAPPER,
    COMPANY_RELATIONSHIP,
    COMPANY_TYPE,
    MISSING_COMPANY_TYPE,
    COMPANY_SIZE,
    SUCCESS_LEGAL_REP_SIGNUP,
    AWAITING_ID_VERIFICATION,
    INVALID_LINK,
    LEGAL_REP_EMAIL,
    EMPLOYEE_REG_INFO,
    LEGAL_VERIFICATION_INITIAL,
    LEGAL_VERIFICATION_APPROVAL,
    LEGAL_VERIFICATION_FINISH,
    PASSWORD_SETUP,
    PASSWORD_SETUP_ERROR,
    OVERVIEW_BUSINESS,
    SIGN_IN_FROM_CHECKOUT,
    GREETING_FROM_CHECKOUT,
    ERROR_SCREEN_UNDER_18,
    VERIFICATION_ERROR_PROFILE,
    VERIFICATION_IN_PROGRESS_PROFILE,
    VERIFICATION_BENEFITS_PROFILE,
    IDENTITY_VERIFICATION_CONFIRMATION,
    IDENTITY_VERIFICATION_CONFIRMATION_CAMPAIGN,
    PROFILE_VERIFICATION_CONFIRMATION,
    MIX_CHECKOUT_VERIFICATION_ERROR,
    PROTECTIVE_PASSWORD,
    PROTECTIVE_PASSWORD_DETAILS,
    TWO_FACTOR_AUTH_CODE,
    TWO_FACTOR_AUTH_RESEND_CODE,
    PHONE_NUMBER_UPDATE,
    EMAIL_UPDATE_AUTH_CODE,
    EMAIL_UPDATE,
    EMAIL_UPDATE_SUCCESS,
    EMAIL_UPDATE_FAILURE,
    EMAIL_UPDATE_CONFIRMATION_SUCCESS,
    PHONE_NUMBER_UPDATE_CONFIRMATION,
    PHONE_NUMBER_UPDATE_SUCCESS,
    NATIONAL_ID_REGISTRATION,
    BANK_ACCOUNT_VERIFICATION_CONSENT,
    BANK_ACCOUNT_VERIFICATION,
    BANK_ACCOUNT_VERIFICATION_CONFIRMATION,
    BANK_ACCOUNT_VERIFICATION_ERROR,
    BANK_ACCOUNT_VERIFICATION_IN_PROGRESS,
    BANK_ACCOUNT_VERIFICATION_INVALID_LINK,
    BANK_ACCOUNT_VERIFICATION_ALREADY_VERIFIED,
    BLACK_FRIDAY_GREETING,
    BLACK_FRIDAY_SIGN_IN,
};

export default stepNames;

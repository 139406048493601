import styled, { css } from 'styled-components';

import { propWithValue, modifier } from 'ggApp/utils/mixins/modifiers';
import { elevation } from 'ggApp/utils/mixins/elevation';

export const ANIMATION_DURATION_IN_MS = 200;

const animateInTimingFunction = css`
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
`;
const animateOutTimingFunction = css`
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
`;

const boxShadowTransparent = css`
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(0, 0, 0, 0);
`;
const colorHalfTransparent = 'rgba(0, 0, 0, 0.5)';

const baseHiddenState = css`
    opacity: 0;
`;

const baseShownState = css`
    opacity: 1;
`;

const containerHiddenState = css`
    transform: scale(0.9);
    ${boxShadowTransparent}
`;

const containerShownState = css`
    transform: scale(1);
    ${elevation(3, false)}
`;

const BaseAnimation = css`
    transition-property: all;
    transition-duration: ${(props) => props.animationDuration || ANIMATION_DURATION_IN_MS}ms;

    ${baseHiddenState}

    ${propWithValue('animationState', 'entering').css`
        ${animateInTimingFunction}
        ${baseShownState}
    `}

    ${propWithValue('animationState', 'entered').css`
        ${baseShownState}
    `}

    ${propWithValue('animationState', 'exiting').css`
        ${animateOutTimingFunction}
        ${baseHiddenState}
    `}

    ${propWithValue('animationState', 'exited').css`
        ${baseHiddenState}
    `}
`;

const ContainerAnimation = css`
    ${containerHiddenState}

    ${propWithValue('animationState', 'entering').css`
        ${containerShownState}
    `}

    ${propWithValue('animationState', 'entered').css`
        ${containerShownState}
    `}

    ${propWithValue('animationState', 'exiting').css`
        ${containerHiddenState}
    `}

    ${propWithValue('animationState', 'exited').css`
        ${containerHiddenState}
    `}
`;

export const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 64px;
    overflow: hidden;
    background-color: ${({ withBackgroundColor }) =>
        withBackgroundColor ? colorHalfTransparent : 'unset'};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    z-index: 9999;

    ${(props) => props.theme.superQuery.all.and.maxWidth.mobileMax.css`
        padding: 8px;
    `};

    ${BaseAnimation}
`;

export const Container = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.colors.Background};
    max-height: 80vh;
    width: ${(props) => props.width || '832px'};
    max-width: ${(props) => props.maxWidth || '832px'};
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    ${elevation(3, false)}

    ${(props) => props.theme.superQuery.all.and.maxWidth.mobileMax.css`
        min-height: 100%;
        max-height: 100%;
        min-width: 100%;
        max-width: 100%;
    `}

    ${BaseAnimation}
    ${ContainerAnimation}
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 15px;
    right: 15px;
    display: inline-block;
    cursor: pointer;
    z-index: 1;
`;

export const Content = styled.div`
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 64px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 16px;

    flex-grow: 1;

    ${(props) => props.theme.superQuery.all.and.maxWidth.mobileMax.css`
        padding-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 24px;
    `}
`;

export const ActionsContainer = styled.div`
    background-color: ${({ theme, isFixed, withBackgroundColor }) => isFixed && withBackgroundColor ? '#F1F1F1' : theme.colors.Background};
    padding: 28px 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 96px;

    flex-shrink: 0;

    ${(props) => props.theme.superQuery.all.and.maxWidth.mobileMax.css`
        padding: 16px;
        height: 80px;

        ${modifier('isFixed').css`
            height: 64px;
        `}
    `}

    ${modifier('isFixed').css`
        height: 84px;
    `}
`;

export const BackgroundClickTrap = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
`;

/**
 * capitalizeFirstLetter
 * @param {string} string to capitalize first letter
 * @example
 * capitalizeFirstLetter('hello') => 'Hello'
 * @returns first letter capitalized
 */
export default function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

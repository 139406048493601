import { GET_USER, SIGN_UP } from 'ggApp/modules/auth/actionTypes';
import { fireEvent } from 'ggApp/modules/inwebview_api';

const isServer = __IS_SERVER__;

const EVENT_WORTHY_ACTIONS = [GET_USER, SIGN_UP];

const handleClientAction = ({ type, ...action }) => {
    if (type === GET_USER && (!action.data || !action.data.id)) {
        /* eslint-disable-next-line no-useless-return */
        return;
        /* eslint-disable-next-line no-else-return */
    } else if (EVENT_WORTHY_ACTIONS.includes(type)) {
        fireEvent(type, { action });
    }
};

export default function inWebViewAPIMiddleware() {
    return (next) => (action) => {
        if (!isServer) {
            handleClientAction(action);
        }

        next(action);
    };
}

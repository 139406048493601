/* eslint-disable */
import { createSelector } from 'reselect';

import { MODULE_NAME } from '../constants';

export const moduleState = (state) => state[MODULE_NAME] || {};

export const defaultProducts = createSelector(moduleState, (state) => state.defaultProducts || []);

export const products = createSelector(moduleState, (state) => state.products || []);

export const query = createSelector(moduleState, (state) => state.query || '');

export const totalItems = createSelector(moduleState, (state) => state.totalItems || 0);

export const loading = createSelector(moduleState, (state) => state.loading);

export const searchResultType = createSelector(moduleState, (state) => state.resultType);

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import { params as routerParamsSelector } from 'ggApp/modules/router/selectors';
import NavigationLink from './NavigationLink';

export default compose(
    connect(
        createStructuredSelector({
            activeStore: activeStoreSelector,
            params: routerParamsSelector,
        }),
    ),
)(NavigationLink);

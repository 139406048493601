import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import { LokaliseText } from 'ggApp/modules/translator/components/styles';
import { internalToolsContext } from 'ggApp/shared/context/internalTools/context';

import { keys as keysSelector } from '../../selectors';
import useTranslatedText from '../../hooks/useTranslatedText';
import { translateTextWithComponent } from '../../utils/translateTextWithComponent';

const enhance = connect(
    createStructuredSelector({
        activeStoreLanguage: createSelector(activeStoreSelector, (s) => s.activeLanguage),
        activeTranslatorKeys: keysSelector,
    }),
);

const PureTranslator = ({
    activeStoreLanguage,
    activeTranslatorKeys,
    tk,
    withValues,
    withTextComponent,
}) => {
    const internalToolsData = useContext(internalToolsContext);
    const highlight = internalToolsData?.highlightLokalise;
    const text = useTranslatedText({ activeStoreLanguage, activeTranslatorKeys, tk, withValues });
    if (withTextComponent) {
        const textComponents = translateTextWithComponent(text, withTextComponent);
        return textComponents;
    }

    return (
        <LokaliseText tk={tk} highlight={highlight}>
            {text}
        </LokaliseText>
    );
};

PureTranslator.propTypes = {
    activeStoreLanguage: PropTypes.string.isRequired,
    activeTranslatorKeys: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)).isRequired,
    tk: PropTypes.string.isRequired,
    withValues: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

PureTranslator.defaultProps = {
    withValues: null,
};

export default enhance(PureTranslator);

/* eslint-disable */
import { MODULE_NAME } from '../constants';

export const GET_VOUCHER_CODE = `${MODULE_NAME}/GET_VOUCHER_CODE`;
export const GET_VOUCHER_CODE_SUCCESS = `${MODULE_NAME}/GET_VOUCHER_CODE_SUCCESS`;
export const GET_VOUCHER_CODE_ERROR = `${MODULE_NAME}/GET_VOUCHER_CODE_ERROR`;

export const GET_VOUCHER_OWNER = `${MODULE_NAME}/GET_VOUCHER_OWNER`;
export const GET_VOUCHER_OWNER_SUCCESS = `${MODULE_NAME}/GET_VOUCHER_OWNER_SUCCESS`;
export const GET_VOUCHER_OWNER_ERROR = `${MODULE_NAME}/GET_VOUCHER_OWNER_ERROR`;

export const GET_VOUCHER_LIST = `${MODULE_NAME}/GET_VOUCHER_LIST`;
export const GET_VOUCHER_LIST_SUCCESS = `${MODULE_NAME}/GET_VOUCHER_LIST_SUCCESS`;
export const GET_VOUCHER_LIST_ERROR = `${MODULE_NAME}/GET_VOUCHER_LIST_ERROR`;
import { createSelector } from 'reselect';
import { MODULE_NAME } from '../constants';

export const moduleState = (state) => state[MODULE_NAME];
export const fetching = createSelector(moduleState, (m) => m.fetching);
export const error = createSelector(moduleState, (m) => m.error);
export const orderActive = createSelector(moduleState, (m) => m.active);
export const list = createSelector(moduleState, (m) => m.list);

export const singleOrder = createSelector(moduleState, (m) => m.singleOrder);

export const unavailableSkuList = createSelector(moduleState, (m) =>
    ((m.active && m.active.items) || [])
        .map((lineItem) => lineItem && lineItem.variant && lineItem.variant.sku)
        .filter((sku) => (m.unavailableSkuList || []).includes(sku)),
);

export const isFrontshopOrder = createSelector(orderActive, (order) => !order.store_offline);

export const shouldShowPreventCheckoutModalOnError = createSelector(
    error,
    ({ type }) => type === 'block_managed_account_checkout',
);

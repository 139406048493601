import React from 'react';
import { string } from 'prop-types';

const Large = ({ className, size, color }) => (
    <svg
        className={className}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.29289 8.29289C4.68342 7.90237 5.31658 7.90237 5.70711 8.29289L12 14.5858L18.2929 8.29289C18.6834 7.90237 19.3166 7.90237 19.7071 8.29289C20.0976 8.68342 20.0976 9.31658 19.7071 9.70711L12 17.4142L4.29289 9.70711C3.90237 9.31658 3.90237 8.68342 4.29289 8.29289Z"
            fill={color}
        />
    </svg>
);

Large.propTypes = {
    className: string,
    size: string,
    color: string,
};

Large.defaultProps = {
    className: '',
    size: '24px',
    color: '#333333',
};

export default Large;

import { findAddressById } from 'ggApp/shared/context/Order/helpers/findAddressById';

export const formatAddress = ({ addressId, userAddresses, countryIso }: any) => {
    const { address1: line1, address2: line2, countryCode, ...address } = findAddressById({
        userAddresses,
        addressId,
    });
    return {
        ...address,
        line1,
        line2,
        countryIso,
    };
};
export const orderSubmitFormat = ({ cart, user, orderMode, userAddresses, activeStore }: any) => {
    const { paymentMethod, shippingAddressId } = cart;
    const countryIso = activeStore.country_code;

    const shippingAddress = formatAddress({
        userAddresses,
        addressId: shippingAddressId,
        countryIso,
    });
    const billingAddress = formatAddress({
        userAddresses,
        addressId: shippingAddressId,
        countryIso,
    });

    return {
        orderMode,
        paymentMethod: {
            id: paymentMethod.id,
            type: paymentMethod.type,
            billingAccountId: paymentMethod.billingAccountId,
        },
        shippingAddress,
        billingAddress,
        user: {
            userId: user.id,
            firstName: user.first_name,
            lastName: user.last_name,
            language: user.signup_language,
            userType: user.user_type,
            birthdate: user.birthdate,
            email: user.email,
            phone: user.phone,
        },
        store: {
            storeId: activeStore.store_id,
            storeType: activeStore.store_type,
            storeCode: activeStore.code,
            countryCode: activeStore.country_code,
            countryId: activeStore.country_id,
        },
    };
};

// @flow
import { MODULE_NAME } from '../constants';

export const CREATE_ADYEN_PAYMENT_SESSION = `${MODULE_NAME}/CREATE_ADYEN_PAYMENT_SESSION`;
export const CREATE_ADYEN_PAYMENT_SESSION_SUCCESS = `${MODULE_NAME}/CREATE_ADYEN_PAYMENT_SESSION_SUCCESS`;
export const CREATE_ADYEN_PAYMENT_SESSION_ERROR = `${MODULE_NAME}/CREATE_ADYEN_PAYMENT_SESSION_ERROR`;

export const SUBMIT_ADYEN_PAYMENT_DETAILS = `${MODULE_NAME}/SUBMIT_ADYEN_PAYMENT_DETAILS`;
export const SUBMIT_ADYEN_PAYMENT_DETAILS_SUCCESS = `${MODULE_NAME}/SUBMIT_ADYEN_PAYMENT_DETAILS_SUCCESS`;
export const SUBMIT_ADYEN_PAYMENT_DETAILS_ERROR = `${MODULE_NAME}/SUBMIT_ADYEN_PAYMENT_DETAILS_ERROR`;

export const GET_ADYEN_PAYMENT_INFO = `${MODULE_NAME}/GET_ADYEN_PAYMENT_INFO`;
export const GET_ADYEN_PAYMENT_INFO_SUCCESS = `${MODULE_NAME}/GET_ADYEN_PAYMENT_INFO_SUCCESS`;
export const GET_ADYEN_PAYMENT_INFO_ERROR = `${MODULE_NAME}/GET_ADYEN_PAYMENT_INFO_ERROR`;

export const SET_ADYEN_PAYMENT_STATUS = `${MODULE_NAME}/SET_ADYEN_PAYMENT_STATUS`;

export const GET_ADYEN_PAYMENT_NOTIFICATION_INFO = `${MODULE_NAME}/GET_ADYEN_PAYMENT_NOTIFICATION_INFO`;
export const GET_ADYEN_PAYMENT_NOTIFICATION_INFO_SUCCESS = `${MODULE_NAME}/GET_ADYEN_PAYMENT_NOTIFICATION_INFO_SUCCESS`;
export const GET_ADYEN_PAYMENT_NOTIFICATION_INFO_ERROR = `${MODULE_NAME}/GET_ADYEN_PAYMENT_NOTIFICATION_INFO_ERROR`;

import FrontshopLink from 'ggApp/shared/containers/FrontshopLink';
import styled, { css } from 'styled-components';

const Link = styled(FrontshopLink)`
    margin-top: ${({ theme }) => theme.spacers.XL};
    color: ${({ theme }) => theme.colors.Foreground};
    ${(props) => css(props.theme.typography.Paragraph)}
    cursor: pointer;
    &:hover {
        color: ${({ theme }) => theme.colors.Foreground};
        text-decoration: underline;
    }
`;

const FooterTitle = styled.span`
    color: ${({ theme }) => theme.colors.Foreground};
    ${(props) => css(props.theme.typography.SmallCapsHeadline)}
`;

export { Link, FooterTitle };

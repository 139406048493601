import CookiesManager from 'ggApp/utils/CookiesManager';

import { TRACKING_COOKIE_NAME } from '../constants';

const ONE_DAY_EXPIRATION_TIME_IN_SECONDS = 24 * 60 * 60;

const setTrackingCookie = (trackingValue, req) => {
    const cookiesManager = new CookiesManager(req);

    cookiesManager.set(TRACKING_COOKIE_NAME, trackingValue, {
        maxAge: ONE_DAY_EXPIRATION_TIME_IN_SECONDS,
        httpOnly: false,
    });
};

export default setTrackingCookie;

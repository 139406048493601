/* eslint-disable */
export default function getLanguageList(store) {
    if (!store) return [];

    let languageList = store.languages;
    if (languageList.indexOf(store.default_language) === -1) {
        languageList = [
            ...languageList,
            store.default_language,
        ];
    }

    return languageList
        .filter(l => !!l);
}

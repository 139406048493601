import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Svg, Path } from 'react-native-svg';

const ReviewIOLogo = ({ theme, className, color = theme.colors.Foreground }) => (
    <Svg
        className={className}
        width="90"
        height="14"
        viewBox="0 0 100 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M21.4722 6.94167C23.1308 6.94167 24.3158 6.08843 24.3158 4.66693V4.63539C24.3158 3.27696 23.2728 2.45526 21.488 2.45526H18.0762V6.94167H21.4722ZM16.8287 1.9343C16.8287 1.58681 17.1131 1.3024 17.4448 1.3024H21.5832C22.9405 1.3024 24.0308 1.71297 24.7264 2.40795C25.2631 2.94524 25.5791 3.71907 25.5791 4.58808L25.5785 4.61906C25.5785 6.43593 24.3462 7.49417 22.6245 7.82589L25.3735 11.3644C25.4996 11.5058 25.5785 11.6483 25.5785 11.8218C25.5785 12.154 25.2625 12.4385 24.9466 12.4385C24.6937 12.4385 24.5045 12.296 24.3626 12.1062L21.2503 8.063H18.0756V11.8066C18.0756 12.154 17.7912 12.4385 17.4443 12.4385C17.1125 12.4385 16.8281 12.154 16.8281 11.8066V1.9343H16.8287Z"
            fill={color}
        />
        <Path
            d="M28.1147 11.7277V1.9343C28.1147 1.58681 28.3992 1.3024 28.7309 1.3024H35.6019C35.9173 1.3024 36.1707 1.55527 36.1707 1.87123C36.1707 2.18718 35.9173 2.44005 35.6019 2.44005H29.3622V6.21459H34.8911C35.2071 6.21459 35.4599 6.48323 35.4599 6.78342C35.4599 7.09937 35.2071 7.35168 34.8911 7.35168H29.3622V11.222H35.6807C35.9967 11.222 36.249 11.4748 36.249 11.7902C36.249 12.1062 35.9967 12.359 35.6807 12.359H28.7309C28.3992 12.359 28.1147 12.0746 28.1147 11.7277Z"
            fill={color}
        />
        <Path
            d="M42.0967 11.9658L37.8322 2.12511C37.7849 2.0305 37.7686 1.95165 37.7686 1.8407C37.7686 1.52531 38.053 1.22513 38.401 1.22513C38.7164 1.22513 38.9378 1.43013 39.0481 1.69878L42.8711 10.8597L46.7087 1.66724C46.8197 1.44646 47.041 1.22513 47.3249 1.22513C47.6723 1.22513 47.941 1.50954 47.941 1.82549C47.941 1.92011 47.9252 1.99896 47.8937 2.07781L43.6128 11.9658C43.4709 12.2818 43.2496 12.4873 42.8863 12.4873H42.8232C42.46 12.4873 42.2229 12.2818 42.0967 11.9658Z"
            fill={color}
        />
        <Path
            d="M50.2422 1.85703C50.2422 1.50954 50.5266 1.22513 50.8583 1.22513C51.2052 1.22513 51.4897 1.50954 51.4897 1.85703V11.8081C51.4897 12.1556 51.2052 12.44 50.8583 12.44C50.5266 12.44 50.2422 12.1556 50.2422 11.8081V1.85703Z"
            fill={color}
        />
        <Path
            d="M54.5498 11.7277V1.9343C54.5498 1.58681 54.8342 1.3024 55.1654 1.3024H62.0369C62.3523 1.3024 62.6057 1.55527 62.6057 1.87123C62.6057 2.18718 62.3523 2.44005 62.0369 2.44005H55.7973V6.21459H61.3256C61.6421 6.21459 61.8944 6.48323 61.8944 6.78342C61.8944 7.09937 61.6421 7.35168 61.3256 7.35168H55.7973V11.222H62.1158C62.4312 11.222 62.684 11.4748 62.684 11.7902C62.684 12.1062 62.4312 12.359 62.1158 12.359H55.1654C54.8342 12.359 54.5498 12.0746 54.5498 11.7277Z"
            fill={color}
        />
        <Path
            d="M64.3125 2.14088C64.281 2.04627 64.25 1.95165 64.25 1.85647C64.25 1.52475 64.5496 1.22513 64.8971 1.22513C65.2131 1.22513 65.4502 1.46167 65.5448 1.74552L68.5934 10.5286L71.4837 1.71454C71.5783 1.43013 71.7681 1.22513 72.0998 1.22513H72.1792C72.4946 1.22513 72.7002 1.43013 72.7948 1.71454L75.6851 10.5286L78.7495 1.71454C78.8447 1.43013 79.0497 1.22513 79.3498 1.22513C79.6816 1.22513 79.9817 1.52475 79.9817 1.8407C79.9817 1.93532 79.9339 2.04627 79.9029 2.14088L76.3643 11.9653C76.254 12.2812 76.0332 12.5026 75.7167 12.5026H75.6221C75.3067 12.5026 75.0853 12.2812 74.9749 11.9653L72.0998 3.46777L69.2405 11.9653C69.1307 12.2812 68.9093 12.5026 68.5934 12.5026H68.4987C68.1828 12.5026 67.9615 12.297 67.8511 11.9653L64.3125 2.14088Z"
            fill={color}
        />
        <Path
            d="M81.5638 11.0327C81.4218 10.9376 81.3267 10.7635 81.3267 10.5585C81.3267 10.211 81.5959 9.94239 81.9276 9.94239C82.0853 9.94239 82.2278 10.0055 82.3061 10.0843C83.3485 10.9697 84.3916 11.396 85.7973 11.396C87.2819 11.396 88.2613 10.6058 88.2613 9.51605V9.48451C88.2613 8.4578 87.7082 7.87377 85.3867 7.38379C82.8433 6.8313 81.6742 6.00959 81.6742 4.19273V4.16175C81.6742 2.42429 83.2066 1.14471 85.3073 1.14471C86.7609 1.14471 87.8186 1.50798 88.8295 2.2345C88.9563 2.32968 89.0982 2.50314 89.0982 2.73968C89.0982 3.07197 88.8295 3.34005 88.4984 3.34005C88.3559 3.34005 88.245 3.30851 88.1351 3.22966C87.203 2.55045 86.3025 2.26604 85.2758 2.26604C83.8385 2.26604 82.9222 3.05564 82.9222 4.0508V4.08234C82.9222 5.12538 83.491 5.70941 85.9235 6.23093C88.3874 6.76822 89.5245 7.66764 89.5245 9.35779V9.38989C89.5245 11.285 87.9453 12.5173 85.75 12.5173C84.1387 12.5173 82.796 12.0116 81.5638 11.0327Z"
            fill={color}
        />
        <Path
            d="M0 6.86704C0 10.659 3.07448 13.7335 6.86648 13.7335C10.659 13.7335 13.7335 10.659 13.7335 6.86704C13.7335 3.07448 10.659 0 6.86648 0C3.07448 0 0 3.07448 0 6.86704ZM4.8998 7.7276L1.71662 5.35035H5.5807L6.86028 1.48627L8.15282 5.35035H12.0163L8.84442 7.73943L10.0902 11.6733L6.86028 9.19416L3.65401 11.6733L4.8998 7.7276Z"
            fill={color}
        />
    </Svg>
);

ReviewIOLogo.propTypes = {
    className: PropTypes.string,
};

ReviewIOLogo.defaultProps = {
    className: '',
};

export default withTheme(ReviewIOLogo);

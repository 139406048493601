/* eslint-disable */
import { createSelector } from 'reselect';
import { MODULE_NAME } from '../constants';

export const moduleState = state => state[MODULE_NAME] || {};

export const favoritePage = createSelector(
    moduleState,
    moduleState => moduleState.page,
);

export const pagination = createSelector(
    moduleState,
    moduleState => moduleState.pagination || {},
);

export const favoriteList = createSelector(
    moduleState,
    moduleState => moduleState.list.data || [],
);

import { useState, useCallback, useMemo, useEffect } from 'react';
import throttle from 'ggApp/utils/throttle';
import { Breakpoints, DEVICE_TYPE } from './types';

const breakpoints: Breakpoints = {
    mobile: {
        min: 0,
        avg: 425,
        max: 767,
    },
    tablet: {
        min: 768,
        max: 1023,
    },
    desktop: {
        min: 1024,
    },
};

export const useDeviceInfo = (): {
    deviceType: DEVICE_TYPE;
    isDeviceSmallMobile?: boolean;
} => {
    const initialDeviceType = DEVICE_TYPE.Desktop;

    const [deviceType, setDeviceType] = useState<DEVICE_TYPE>(initialDeviceType);

    const queryViewPort = (minWidth: number, maxWidth?: number) => {
        const query = `(min-width: ${minWidth}px)${
            !maxWidth ? '' : ` and (max-width: ${maxWidth}px)`
        }`;
        return window.matchMedia(query).matches;
    };

    const findMatchingDevice = useCallback(() => {
        if (window) {
            return Object.keys(breakpoints).find((device) => {
                const { min, max } = breakpoints[device as keyof Breakpoints];
                return queryViewPort(min, max);
            });
        }
        return undefined;
    }, []);

    const getDeviceType = useCallback(() => {
        const currentDevice = findMatchingDevice();
        if (currentDevice === 'mobile') {
            setDeviceType(DEVICE_TYPE.Mobile);
        } else if (currentDevice === 'tablet') {
            setDeviceType(DEVICE_TYPE.Tablet);
        } else {
            setDeviceType(DEVICE_TYPE.Desktop);
        }
    }, [findMatchingDevice]);

    const throttledGetDeviceType = useMemo(() => throttle(getDeviceType, 100), [getDeviceType]);

    useEffect(() => {
        window.addEventListener('resize', throttledGetDeviceType);
        getDeviceType();

        return () => {
            window.removeEventListener('resize', throttledGetDeviceType);
        };
    }, [getDeviceType, throttledGetDeviceType]);

    return { deviceType };
};

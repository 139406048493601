import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: ${(props) => props.noStars ? 'column' : 'row'};
`;
export const Score = styled.p`
  ${props => css`
      ${props.theme.typography.Paragraph};
      color: ${props.theme.colors['Level 3']};
      line-height: unset;
      padding-bottom: 4px;
  `}
`;

import { createSelector } from 'reselect';
import CookiesManager from 'ggApp/utils/CookiesManager';

import getLanguageList from 'ggApp/utils/storeLanguageList';

import { getAlternateLinkStores } from 'staticStoreConfig';
import { STORE_TYPE, MODULE_NAME, languageTks, storeTks } from 'ggApp/modules/store/constants';
import { params as routerParamsSelector } from 'ggApp/modules/router/selectors';

const isServer = __IS_SERVER__;

export const moduleState = (state) => state[MODULE_NAME] || {};
export const isStoreLoading = (state) => moduleState(state).active.loading;
export const failedToLoadStore = (state) => Boolean(moduleState(state).active.error);

function userDefinedLanguage(_cookiesManager) {
    if (!isServer) {
        const cookiesManager = _cookiesManager || new CookiesManager();
        return cookiesManager.get('locale');
    }

    if (_cookiesManager) {
        return _cookiesManager.get('locale');
    }

    return null;
}

function placeSelectedLanguageFirst(languages, currentLanguageIndex) {
    if (currentLanguageIndex === 0) return languages;
    const selectedLanguage = languages.splice(currentLanguageIndex, 1)[0];
    languages.unshift(selectedLanguage);
    return languages;
}

export function partnerReturnUrl(storeCode, variantId) {
    return `/partners/back_to_store?code=${storeCode}&variant_id=${variantId}`;
}

export const storeActive = createSelector(moduleState, (mState) => mState.active.data || {});

export const countryCode = createSelector(
    storeActive,
    (activeStore) => activeStore.country_code || 'DE',
);

export const isStoreBusiness = createSelector(storeActive, (activeStore) =>
    activeStore.code.startsWith('business'),
);

const isCountryUS = createSelector(countryCode, (code) => code === 'US');

export const isEUNonBusinessStore = createSelector(
    isStoreBusiness,
    isCountryUS,
    (business, countryUS) => !business && !countryUS,
);

export const isStoreReady = createSelector(storeActive, (activeStore) =>
    Boolean(activeStore.activeLanguage),
);

export const isPartnerStore = createSelector(
    storeActive,
    (store) => store.store_type === STORE_TYPE.GROVER_PAY,
);

export const isMainEntry = createSelector(storeActive, routerParamsSelector, (store, params) => {
    const { languages, activeLanguage } = store;
    const { language } = params;

    const languageList = [activeLanguage, ...languages];

    return !languageList.includes(language);
});

export const storeList = createSelector(moduleState, (mState) => mState.list || []);

export const phonePrefixes = createSelector(moduleState, (mState) => mState.phonePrefixes || {});

export const footerStoreList = createSelector(storeList, (list) =>
    list.filter((store) => (store || {}).enabled_on_footer),
);

export const userChosenLanguage = (cookiesManager) =>
    createSelector(storeActive, (store) => {
        const userDefined = userDefinedLanguage(cookiesManager);
        if (userDefined) return userDefined;

        const { default_language: defaultLanguage } = store;
        if (defaultLanguage) return defaultLanguage;

        return 'de';
    });

export const mainRouteWithStoreCode = createSelector(storeActive, (activeStore) => {
    const { code } = activeStore;

    return `/${code}`;
});

export const mainRouteWithStoreAndLanguage = createSelector(storeActive, (activeStore) => {
    const { code, activeLanguage } = activeStore;

    return `/${code}-${activeLanguage}`;
});

export const languageList = createSelector(storeActive, (activeStore) =>
    getLanguageList(activeStore),
);

export const currentLanguage = createSelector(
    storeActive,
    (activeStore) => activeStore.activeLanguage,
);

export const currentLanguageIndex = createSelector(
    currentLanguage,
    languageList,
    (activeLanguage, languages) => (languages || []).indexOf(activeLanguage),
);

export const languages = createSelector(languageList, (allLanguages) => {
    return allLanguages.map((lang) => ({
        label: languageTks[lang],
        value: lang,
    }));
});

export const stores = createSelector(getAlternateLinkStores, (linkStores) => {
    return linkStores.map(({ storeCode }) => ({
        label: storeTks[storeCode],
        value: storeCode,
    }));
});

export const currentStoreIndex = createSelector(storeActive, stores, (activeStore, store) => {
    const { code } = activeStore;
    return store.findIndex(({ value }) => value === code);
});

export const reorderedLanguageList = createSelector(languages, currentLanguageIndex, (l, c) =>
    placeSelectedLanguageFirst(l, c),
);

export const reorderedStoreList = createSelector(stores, currentStoreIndex, (l, c) =>
    placeSelectedLanguageFirst(l, c),
);

export const storeType = createSelector(storeActive, (activeStore) => activeStore.store_type);

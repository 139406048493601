import { sendStructEvent } from '../../api';

type PayloadType = {
    eventLabel: string;
    eventProperty: {
        userID?: number;
        productName: string;
        bundleID: number;
        productsInBundle: number;
        productData: {
            bundleProductNames: string[];
            bundleProductSkus: string[];
            bundlesPrice: number[];
            subscriptionLength: number;
            total_price: number;
            non_discountedPrice: number;
        };
    };
};

export const sendBundleImpression = (payload: PayloadType): void => {
    const { eventLabel, eventProperty } = payload;
    sendStructEvent({
        eventCategory: 'productDiscovery',
        eventAction: 'bundleImpression',
        eventLabel,
        eventProperty,
    });
};

import { sendStructEvent } from '../../api';

interface PayloadModel {
    eventCategory: string;
    eventAction: string;
    eventLabel: string;
    eventProperty: {
        productData: {
            changedDeviceColor: string;
            name: string;
            productSKU: string;
            price: string;
            brand: string;
            sub_category: string;
            productVariant: string;
        };
    };
}

export const sendCartContentChange = (payload: PayloadModel): void => {
    const { eventAction, eventLabel, eventProperty } = payload;

    sendStructEvent({
        eventCategory: 'checkout',
        eventAction,
        eventLabel: eventLabel || '',
        eventProperty,
    });
};

import { compose, withProps, branch, renderNothing } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';

import {
    storeActive as activeStoreSelector,
    isPartnerStore as isPartnerStoreSelector,
} from 'ggApp/modules/store/selectors';
import {
    businessStoreDealsFlag,
    cardLinkOnWebFlagEnabled as cardLinkOnWebFlagEnabledSelector,
    referAFriendFooterLinkEnabled as referAFriendFooterLinkEnabledSelector,
    newReferralsHostFeatureFlag as newReferralsHostEnabledSelector,
} from 'ggApp/modules/featureFlag/selectors';
import { registerFooterCtaClick as registerCta } from 'ggApp/modules/gaAnalytics/actions';
import Footer from './Footer';
import getFooterInfo from './info';

const enhance = compose(
    connect(
        createStructuredSelector({
            storeActive: activeStoreSelector,
            isPartnerStore: isPartnerStoreSelector,
            businessStoreDealsEnabled: businessStoreDealsFlag,
            cardLinkOnWebFlagEnabled: cardLinkOnWebFlagEnabledSelector,
            referAFriendFooterLinkEnabled: referAFriendFooterLinkEnabledSelector,
            newReferralsHostEnabled: newReferralsHostEnabledSelector,
        }),
        (dispatch) => ({
            registerFooterCtaClick: bindActionCreators(registerCta, dispatch),
        }),
    ),
    withProps(
        ({
            storeActive: { code, activeLanguage },
            businessStoreDealsEnabled,
            cardLinkOnWebFlagEnabled,
            referAFriendFooterLinkEnabled,
            registerFooterCtaClick,
            newReferralsHostEnabled,
        }) => ({
            activeLanguage,
            footerLinks: getFooterInfo(
                code,
                activeLanguage,
                businessStoreDealsEnabled,
                cardLinkOnWebFlagEnabled,
                referAFriendFooterLinkEnabled,
                registerFooterCtaClick,
                newReferralsHostEnabled,
            ),
        }),
    ),
    branch(({ isPartnerStore }) => isPartnerStore, renderNothing),
);

export default enhance(Footer);

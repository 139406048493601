/* eslint-disable */
import { GET_TRANSACTIONS, GET_TRANSACTIONS_SUCCESS, GET_TRANSACTIONS_ERROR, } from './../actionTypes';
import { CURRENCIES_ASSOCIATION, } from 'ggApp/modules/store/constants';
import { detectPayment, } from 'ggApp/utils/detectPayment';
import groupBy from 'lodash/groupBy';
import moment from 'moment';

const defaultState = {
  loading: null,
  error: {},
  data: [],
};

export default function (state = defaultState, action) {
  switch (action.type) {

    case GET_TRANSACTIONS:
      return Object.assign({}, state, {
        loading: true,
      })

    case GET_TRANSACTIONS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: groupBy(action.data.map(t => Object.assign({}, t, {
          sourceEdited: detectPayment(t.source || {}),
          currencySymbol: CURRENCIES_ASSOCIATION[t.currency] || '€',
        })), item => {
          return moment(item.date_paid).format('YYYY-MM');
        }),
      })

    case GET_TRANSACTIONS_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      })

    default:
      return state;
  }
}

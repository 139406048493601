/* eslint-disable */
import { UPDATE_ORDER_OPTION_SUCCESS } from 'ggApp/modules/order/actionTypes';
import { registerCheckoutOptions } from 'ggApp/modules/gaAnalytics/actions';

const CHECKOUT_STATUSES = ['cart', 'address', 'payment', 'summary', 'complete'];
const checkoutOptionsEventMiddleware = store => next => (action) => {
    next(action);
    if ([UPDATE_ORDER_OPTION_SUCCESS].includes(action.type)) {
        store.dispatch(
            registerCheckoutOptions(
                action.order,
                CHECKOUT_STATUSES.indexOf(action.step) + 1,
                action.option,
            ),
        );
    }
};

export default checkoutOptionsEventMiddleware;

import { MODULE_NAME } from '../../constants';

export const SIGN_UP = `${MODULE_NAME}/SIGN_UP`;

export const SIGN_IN = `${MODULE_NAME}/LOGIN`;

export const GET_USER = `${MODULE_NAME}/GET_USER`;

export const SEND_PASSWORD_RECOVERY = `${MODULE_NAME}/SEND_PASSWORD_RECOVERY`;

export const SET_TWO_FACTOR_AUTHENTICATION = `${MODULE_NAME}/SET_TWO_FACTOR_AUTHENTICATION`;
export const CLEAR_TWO_FACTOR_AUTHENTICATION = `${MODULE_NAME}/CLEAR_TWO_FACTOR_AUTHENTICATION`;
export const SET_TWO_FACTOR_AUTHENTICATION_TIMEOUT = `${MODULE_NAME}/SET_TWO_FACTOR_AUTHENTICATION_TIMEOUT`;

/* eslint-disable */
import { pushDataLayer } from 'ggApp/modules/tracking/actions';

import { priceDecimalizer, md5Hasher } from '../adapters';

export const criteoCartCheckoutUpdate = (user, order) => (dispatch, getState) => {
    const userEmail = user.email ? md5Hasher(user.email) : '';
    const eventPayload = {
        event: 'crto_basketpage',
        crto: {
            email: userEmail,
            list: "basket",
            products: (order.items || []).map(product => ({
                id: product.variant.id,
                price: priceDecimalizer(product.price_cents),
                quantity: product.quantity,
            })),
        },
    };
    if (eventPayload) {
        pushDataLayer(eventPayload);
    }
};

/* eslint-disable */
import { SET_REQUEST_STATUS } from './actionTypes';
import { LOADING_STATUS, READY_STATUS } from "./constants";

export const setLoadingStatus = () => ({
    type: SET_REQUEST_STATUS,
    status: LOADING_STATUS,
});

export const setReadyStatus = () => ({
    type: SET_REQUEST_STATUS,
    status: READY_STATUS,
});

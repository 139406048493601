/* eslint-disable react/jsx-props-no-spreading */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Observer from './observer';

function withDeviceInfo(WrappedComponent) {
    class Enhance extends PureComponent {
        constructor(props, context) {
            super(props);

            const { userDeviceFromSSR } = context;

            this.state = {
                device: userDeviceFromSSR,
            };

            this.Observer = new Observer();
        }

        componentDidMount() {
            const initialDevice = this.Observer.getCurrentDevice;

            const { device } = this.state;
            const { currentDevice } = device || {};

            if (currentDevice !== initialDevice) {
                this.setState({ device: initialDevice });
            }

            this.Observer.onViewPortChange((newDevice) => {
                this.setState({ device: newDevice });
            });
        }

        componentWillUnmount() {
            this.Observer.reset();
        }

        render() {
            const { device } = this.state;

            return <WrappedComponent userDevice={device} {...this.props} />;
        }
    }

    Enhance.contextTypes = {
        userDeviceFromSSR: PropTypes.string,
    };

    return Enhance;
}

export default withDeviceInfo;

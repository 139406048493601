import React, { useContext, ReactNode } from 'react';
import { BottomSheet as Sheet } from '@getgrover/ui';
import { ThemeContext } from 'styled-components';

interface PropTypes {
    setShouldShow: (shouldShow: boolean) => void;
    shouldShow?: boolean;
    children: ReactNode;
    title?: ReactNode;
}

export const BottomSheet = ({
    children,
    shouldShow = false,
    setShouldShow,
    title,
}: PropTypes): JSX.Element | null => {
    const theme = useContext(ThemeContext);

    if (!window || !Object.keys(window).length) {
        return null;
    }

    return (
        <Sheet
            title={title}
            marginTop={128}
            bodyStyle={{ borderRadius: '8px 8px 0 0', backgroundColor: theme.colors.Background }}
            rootStyle={{
                zIndex: '20',
            }}
            titleTypography="TinyParagraph"
            shadowTip={false}
            swipeableViewsProps={{
                containerStyle: {
                    borderRadius: '8px 8px 0 0',
                },
            }}
            onChange={setShouldShow}
            open={shouldShow}
            overlayStyle={{ opacity: 0.5 }}
        >
            {children}
        </Sheet>
    );
};

import objectPath from 'object-path';
import { createSelector } from 'reselect';
import last from 'lodash/last';

import ggAuth from 'ggApp/modules/auth';
import ggStore from 'ggApp/modules/store';
import ggProduct from 'ggApp/modules/product';
import ggOrder from 'ggApp/modules/order';
import ggRouter from 'ggApp/modules/router';
import CookiesManager from 'ggApp/utils/CookiesManager';
import { getFormattedPlanPrice, getFormattedProductCardPrice } from 'ggApp/utils/product';
import { TRACKING_COOKIE_NAME } from 'ggApp/modules/tracking/constants';
import { format } from 'ggApp/utils/money';
import { formatPrice } from 'ggApp/modules/gaAnalytics/utils';

function getProductCategory(product) {
    return [product.category_name, product.sub_category_name].filter((c) => !!c).join('/');
}

function getOrderProductsTrackingData(pageName) {
    return (order) => ({
        pageType: pageName,
        orderID: order.number,
        products: (order.items || []).map((product) => ({
            productId: product.id,
            productVariantId: product.variant.id || '',
            productName: product.variant.name || '',
            productPrice: product.rental_plan_price,
            currency: order.currency || '',
            productRentalPlanId: product.rental_plan_id || '',
            quantity: product.quantity,
        })),
    });
}
export function formatProductPrice(currentProduct) {
    const rentPlan = currentProduct.cheapest_rental_plan;
    const priceCents =
        rentPlan?.['rental_plan_price_cents'] ??
        currentProduct?.rentalPlans?.[0]?.price?.inCents ??
        null;
    const price = formatPrice(priceCents);
    const subscriptionLength =
        rentPlan?.['minimum_term_months'] ??
        currentProduct?.rentalPlans?.[0]?.length?.value ??
        null;
    const productVariant = currentProduct.variants?.[0]?.id
        ? `${currentProduct.variants?.[0].id}`
        : null;
    const oldPriceCents =
        rentPlan?.['old_price'] ?? currentProduct?.rentalPlans?.[0]?.oldPrice?.inCents ?? null;
    const nonDiscountedPrice = oldPriceCents ? formatPrice(oldPriceCents) : null;
    const discountAmount = nonDiscountedPrice ? formatPrice(oldPriceCents - priceCents) : null;
    const discountPercentage = discountAmount
        ? Math.round(((oldPriceCents - priceCents) / oldPriceCents) * 100)
        : null;
    return {
        priceCents,
        price,
        subscriptionLength,
        productVariant,
        discountPercentage,
        discountAmount,
        non_discountedPrice: nonDiscountedPrice,
    };
}

export const firstRequest = createSelector(
    ggAuth.selectors.user,
    ggStore.selectors.storeActive,
    // eslint-disable-next-line no-unused-vars
    (user, store) => ({
        status_code: 200,
        user_agent: (navigator || {}).userAgent || '',
        request_url: objectPath.get(window || {}, 'location.href', ''),
        query_string: objectPath.get(window || {}, 'location.search', ''),
        referral_domain: '',
    }),
);

export function rentalPlanView({ user, product, plan, store }) {
    const cookiesManager = new CookiesManager();
    return {
        product_id: product.id,
        user_id: user.id || null,
        session_id: cookiesManager.get(TRACKING_COOKIE_NAME),
        store_id: store.store_id,
        plan_rental_period: plan.minimumMonths || null,
        plan_price: format(plan.price) || null,
        plan_condition: plan.condition || null,
    };
}

export const productTracking = createSelector(ggProduct.selectors.currentProduct, (product) => {
    if (!product) return null;

    const rentalPlan = last(product.rental_plans) || {};
    const [variant = {}] = product.variants || [];

    return {
        pageType: 'ProductDetail',
        productId: product.id,
        productName: product.name || '',
        productBrand: product.brand || '',
        productPrice: getFormattedPlanPrice({ rentalPlan }),
        currency: product.currency || '',
        productCategory: getProductCategory(product) || '',
        productVariant: variant.id || '',
    };
});

export const cartOrderProductTracking = createSelector(
    ggOrder.selectors.orderActive,
    getOrderProductsTrackingData('Cart'),
);

export const confirmationOrderProductTracking = createSelector(
    ggOrder.selectors.orderActive,
    getOrderProductsTrackingData('ConfirmationOrder'),
);

export const checkoutLandingTracking = createSelector(
    ggRouter.selectors.locationQuery,
    ggProduct.selectors.productsById,
    (locationQuery, productsById) => {
        const currentProductSlug = locationQuery.product_id;
        const product = productsById[currentProductSlug];
        const [variant = {}] = product.variants || [];
        const rentalPlan = last(product.rental_plans) || {};

        return {
            pageType: 'CheckoutLanding',
            productId: product.id || '',
            productVariant: variant.id || '',
            productName: product.name || '',
            productBrand: product.brand || '',
            productPrice: getFormattedProductCardPrice({ rentalPlan }),
            currency: product.currency || '',
            productCategory: getProductCategory(product) || '',
        };
    },
);

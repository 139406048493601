/* eslint-disable */
import {
    GET_UNREAD_MESSAGES,
} from '../constants/actionTypes';

const defaultState = null;

export default function (state = defaultState, action) {
    switch (action.type) {
    case GET_UNREAD_MESSAGES: {
        const unreadMessages = action.data;
        return unreadMessages;
    }

    default:
        return state;
    }
}

import styled, { css, keyframes } from 'styled-components';

const activeColor = '#FE1251';

const favoriteButtonHeart = keyframes`
    0%,
    17.5% {
        transform: scale(0);
    }
`;

const favoriteButtonHeartAnimation = css`
    animation-name: ${favoriteButtonHeart};
`;

const favoriteButtonBubble = keyframes`
    15% {
        transform: scale(1);
        border-color: #cc8ef5;
        border-width: 2.25rem;
    }
    30%,
    100% {
        transform: scale(1);
        border-color: #cc8ef5;
        border-width: 0;
    }
`;

const favoriteButtonBubbleAnimation = css`
    animation-name: ${favoriteButtonBubble};
`;


const favoriteButtonSparkles = keyframes`
    0%, 20% {
    opacity: 0;
  }
  25% {
    opacity: 1;
    box-shadow: 0.3247595264rem -2.4375rem 0 0rem #ff8080, -0.3247595264rem -2.0625rem 0 0rem #ffec80, 2.1081984913rem -1.26584917rem 0 0rem #ffec80, 1.4100431801rem -1.5398549383rem 0 0rem #a6ff80, 2.3041209928rem 0.8590119036rem 0 0rem #a6ff80, 2.0830546613rem 0.1423323087rem 0 0rem #80ffc8, 0.7649933912rem 2.3370194931rem 0 0rem #80ffc8, 1.1874862855rem 1.7173410733rem 0 0rem #80caff, -1.3501898369rem 2.0552037379rem 0 0rem #80caff, -0.6022000541rem 1.99917976rem 0 0rem #a480ff, -2.4486525744rem 0.2257776503rem 0 0rem #a480ff, -1.9368615117rem 0.7760834533rem 0 0rem #ff80ee, -1.7032295617rem -1.7736635246rem 0 0rem #ff80ee, -1.7927736947rem -1.0242348366rem 0 0rem #ff8080;
  }
  100% {
    box-shadow: 0.3247595264rem -3rem 0 -0.1875rem #ff8080, -0.3247595264rem -2.625rem 0 -0.1875rem #ffec80, 2.5479787002rem -1.6165621836rem 0 -0.1875rem #ffec80, 1.849823389rem -1.8905679518rem 0 -0.1875rem #a6ff80, 2.8525179434rem 0.9841799289rem 0 -0.1875rem #a6ff80, 2.6314516119rem 0.2675003341rem 0 -0.1875rem #80ffc8, 1.0090529945rem 2.8438144813rem 0 -0.1875rem #80ffc8, 1.4315458888rem 2.2241360615rem 0 -0.1875rem #80caff, -1.5942494402rem 2.5619987261rem 0 -0.1875rem #80caff, -0.8462596573rem 2.5059747482rem 0 -0.1875rem #a480ff, -2.9970495249rem 0.3509456756rem 0 -0.1875rem #a480ff, -2.4852584623rem 0.9012514787rem 0 -0.1875rem #ff80ee, -2.1430097676rem -2.1243765377rem 0 -0.1875rem #ff80ee, -2.2325539005rem -1.3749478496rem 0 -0.1875rem #ff8080;
  }
`;

const favoriteButtonSparkleAnimation = css`
  animation-name: ${favoriteButtonSparkles}
`;

export const Root = styled.div`
   padding: 11px;
   transform: translate(16px, -16px);
   width: 52px;
   height: 52px;

   & * {
        fill: transparent;
   }
   
   path {
     transform: translate(5px, 5px);
   }
   
   @media (hover: hover) {
       &:hover {
           cursor: pointer;
           ${({ active, theme }) => !active && css`
                & * {
                   stroke: ${theme.colors.Foreground};
               }
           `};
          
       }
   }
   
   &:before, &:after {
        position: absolute;
        z-index: -1;
        top: 50%; left: 50%;
        border-radius: 50%;
        content: '';
    }

    &:before {
        box-sizing: border-box;
        margin: -2.2rem;
        border: solid 2.2rem #e2264d;
        width: 4.4rem; height: 4.4rem;
        transform: scale(0);
    }

    &:after {
        margin: -.190rem;
        width: .375rem; height: .375rem;
    }

   ${({ active }) => active && css`
      * {
        stroke: ${activeColor};
        fill: ${activeColor};
      }
   `}

   ${({ active, animatable }) => active && animatable && css`
      > * {
        ${favoriteButtonHeartAnimation}
        animation-duration: 1s;
        animation-timing-function: cubic-bezier(.17, .89, .32, 1.49);
      }

        &:before, &:after {
            animation-duration: 1s;
            animation-timing-function: ease-out;
        }

        &:before {
            will-change: transform, border-width, border-color;
            ${favoriteButtonBubbleAnimation}
        }

        &:after {
            will-change: opacity, box-shadow;
            ${favoriteButtonSparkleAnimation}
        }
   `};
`;

/* eslint-disable */
const defaultState = {
  data: [],
  fetching: null,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'GET_ADDRESS_BY_USER_SUCCESS':
    case 'ADD_ADDRESS_SUCCESS':
    case 'EDIT_ADDRESS_SUCCESS': {
        const list = action.data || [];
        return Object.assign({}, state, {
            data: list.sort((a, b) => b.id - a.id),
            fetching: false,
        })
    }

    case 'GET_ADDRESS_BY_USER':
      return Object.assign({}, state, {
        fetching: true,
      })

    case 'GET_ADDRESS_BY_USER_FAILURE':
      return Object.assign({}, state, {
        fetching: false,
      })

    default:
      return state;
  }
}

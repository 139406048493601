/* eslint-disable */
import React from 'react';
import { string, bool, node } from 'prop-types';

import { Container } from './styled';

const Link = ({
    className,
    url,
    external,
    accentColor,
    children,
    backgroundColor,
    shadowColor,
    onClick,
    type,
}) => (
    <Container
        className={className}
        to={url}
        onClick={onClick}
        external={external}
        accentColor={accentColor}
        backgroundColor={backgroundColor}
        shadowColor={shadowColor}
        type={type}
    >
        {children}
    </Container>
);

Link.propTypes = {
    className: string,
    url: string,
    external: bool,
    accentColor: string,
    children: node,
    backgroundColor: string,
    shadowColor: string,
    type: string,
};

Link.defaultProps = {
    className: '',
    url: '',
    external: false,
    accentColor: '',
    children: null,
    backgroundColor: '',
    shadowColor: '',
    type: '',
};

export default Link;

import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { isFunction } from 'ggApp/utils/function';
import { Translator } from 'ggApp/modules/translator';

import {
    StyledModal,
    Top,
    Bottom,
    Logo,
    ImageBusiness,
    Description,
    Header,
    RedirectButton,
} from './styles';

import Image1 from './Assets/BusinessHeader.svg';

const BusinessRedirectionModal = ({
    modalRef,
    transitionToBusiness,
    b2bRedirection: { shouldBeRedirectedToBusiness },
}) => {
    const [modal, setModal] = useState(null);
    const theme = useContext(ThemeContext);

    const createModalRef = (m) => {
        setModal(m);

        if (isFunction(modalRef)) {
            modalRef(modal);
        }
    };

    useEffect(() => {
        let timerId = null;
        if (shouldBeRedirectedToBusiness) {
            if (modal) {
                modal.openModal();
            }
            timerId = setTimeout(transitionToBusiness, 10000);
        }
        return () => timerId && clearInterval(timerId);
    }, [modal, shouldBeRedirectedToBusiness, transitionToBusiness]);

    return (
        <StyledModal modalRef={createModalRef} onClose={() => false}>
            <Top>
                <ImageBusiness src={Image1} alt="business flow" />
                <Logo />
            </Top>
            <Bottom>
                <Header>
                    <Translator tk="BUSINESS_REDIRECTION_HEADING" />
                </Header>
                <Description>
                    <Translator tk="BUSINESS_REDIRECTION_TEXT" />
                </Description>
                <RedirectButton
                    onClick={transitionToBusiness}
                    size="small"
                    solid
                    accentColor={theme.colors.primary}
                >
                    <Translator tk="BUSINESS_REDIRECTION_LINK" />
                </RedirectButton>
            </Bottom>
        </StyledModal>
    );
};

BusinessRedirectionModal.propTypes = {
    transitionToBusiness: PropTypes.func.isRequired,
    b2bRedirection: PropTypes.shape({
        shouldBeRedirectedToBusiness: PropTypes.bool,
    }).isRequired,
};

export default BusinessRedirectionModal;

const defaultState = null;

export default function(state = defaultState, action) {
    switch (action.type) {
        case 'UPDATE_UNAVAILABLE_SKU_LIST':
            return action.data;

        default:
            return state;
    }
}

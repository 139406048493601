import styled, { css } from 'styled-components';
import { ButtonV2 } from '@getgrover/ui';
import { Snackbar } from 'ggApp/shared/components/snackbar';
import { transition } from 'ggApp/utils/mixins';
import FrontshopLink from 'ggApp/shared/containers/FrontshopLink';
import { lightTheme, darkTheme } from 'ggApp/shared/themes';

export const Wrapper = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05), 0px -2px 10px rgba(0, 0, 0, 0.13);

    ${({ theme }) => theme.superQuery.all.and.maxWidth.tabletMin.css`
        ${transition('transform', 'long-mobile', 'in')};
        max-height: 280px;
    `}
`;

export const StyledSnackbar = styled(Snackbar)`
    margin: 0;
    max-height: inherit;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0;

    ${({ theme }) => theme.superQuery.all.and.minWidth.tabletMin.css`
        max-height: none;
    `};

    ${({ theme }) => theme.superQuery.all.and.minWidth.desktopMin.css`
        padding: 16px 64px;
    `};

    > p {
        position: relative;
        overflow: auto;
        height: 100%;
        max-height: 100%;

        ${({ theme }) => theme.superQuery.all.and.minWidth.tabletMin.css`
            max-height: fit-content;
            overflow: hidden;
        `};
    }

    > div {
        margin: 16px 0 0;
        width: 100%;
    }
`;


export const Divi = styled.span`
    display: block;
    position: sticky;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 24px;
    width: 100%;
    ${({ theme }) => theme === lightTheme && css`
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    `};
    ${({ theme }) => theme === darkTheme && css`
        background-image: linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, rgba(51, 51, 51, 1) 100%);
    `};

    ${({ theme }) => theme.superQuery.all.and.minWidth.tabletMin.css`
        display: none;
    `}
`;

export const AcceptButton = styled(ButtonV2)`
    margin-bottom: 16px;
    padding: 4px 16px;
    width: 100%;

    ${({ theme }) => theme.superQuery.all.and.minWidth.desktopMin.css`
        max-width: 200px;
        margin-bottom: 0;
    `};
`;

export const SettingsButton = styled.span`
    ${({ theme }) => theme.typography.SmallCapsHeadline};
    color: ${({ theme }) => theme.colors.Foreground};
    padding: 4px 16px;
    cursor: pointer;
`;

export const BarTitle = styled.span`
    display: block;
    ${({ theme }) => theme.typography.SuperTinyHeadline};
    color: ${({ theme }) => theme.colors.Foreground};
    margin-bottom: 8px;
`;

export const BarContent = styled.span`
    display: block;
    ${({ theme }) => theme.typography.TinyParagraph};
    color: ${({ theme }) => theme.colors['Level 5']};
`;

export const StyledLink = styled(FrontshopLink)`
    text-decoration: underline;
    color: ${({ theme }) => theme.colors['Level 5']};

    &:hover,
    &:active,
    &:visited {
        cursor: pointer;
        color: ${({ theme }) => theme.colors['Level 5']};
        text-decoration: underline;
    }
`;

export const ButtonLink = styled.button`
    ${({ theme }) => theme.typography.TinyParagraph};
    text-decoration: underline;
    color: ${({ theme }) => theme.colors['Level 5']};
    background: transparent;
    border: 0;
    cursor: pointer;
`;

export const Actions = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;

    ${({ theme }) => theme.superQuery.all.and.minWidth.desktopMin.css`
        flex-direction: row;
    `};
`;

import React from 'react';
import { IconPropTypes } from 'ggApp/types/propTypes';

const Message = ({ className, color, size }) => (
    <svg
        width={size}
        height={size}
        className={className}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 6C6.79086 6 5 7.79086 5 10V12C5 14.2091 6.79086 16 9 16H10V18.1315L13.1972 16H15C17.2091 16 19 14.2091 19 12V10C19 7.79086 17.2091 6 15 6H9ZM3 10C3 6.68629 5.68629 4 9 4H15C18.3137 4 21 6.68629 21 10V12C21 15.3137 18.3137 18 15 18H13.8028L8 21.8685V17.917C5.16229 17.441 3 14.973 3 12V10Z"
            fill={color}
        />
        <path
            d="M8.00011 9.74757C8.69175 9.74757 9.25243 10.3083 9.25243 10.9999C9.25243 11.6915 8.69175 12.2522 8.00011 12.2522C7.30847 12.2522 6.74778 11.6915 6.74778 10.9999C6.74778 10.3083 7.30847 9.74757 8.00011 9.74757Z"
            fill={color}
        />
        <path
            d="M11.9977 9.74805C12.6893 9.74805 13.25 10.3087 13.25 11.0004C13.25 11.692 12.6893 12.2527 11.9977 12.2527C11.306 12.2527 10.7454 11.692 10.7454 11.0004C10.7454 10.3087 11.306 9.74805 11.9977 9.74805Z"
            fill={color}
        />
        <path
            d="M15.9977 9.74805C16.6893 9.74805 17.25 10.3087 17.25 11.0004C17.25 11.692 16.6893 12.2527 15.9977 12.2527C15.306 12.2527 14.7454 11.692 14.7454 11.0004C14.7454 10.3087 15.306 9.74805 15.9977 9.74805Z"
            fill={color}
        />
    </svg>
);

Message.propTypes = {
    ...IconPropTypes,
};

Message.defaultProps = {
    className: '',
    color: '#333333',
    size: '24px',
};

export default Message;

/* eslint-disable no-console */
/* eslint-disable no-shadow */
import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
    getOrder as getOrderFromCookies,
    saveOrder,
    deleteOrder,
} from 'ggApp/modules/order/storage';

import axios from 'ggApp/utils/requests';
import { isNotFoundOrderError, isExistingItem } from 'ggApp/utils/cart';

export const CartContext = React.createContext({});

export const Provider = ({
    children,
    axiosConfig,
    activeStore: { code: storeCode },
    user,
    registerAddToCart,
    criteoCartCheckoutUpdate,
}) => {
    const [overlayIsVisible, setOverlayVisibility] = useState(false);
    const [cart, setCart] = useState({});
    const [unavailableSkuList, setUnavailableSkuList] = useState([]);
    const [cartLoaded, setCartLoaded] = useState(null);

    const showOverlay = useCallback(() => {
        setOverlayVisibility(true);
    }, []);

    const hideOverlay = useCallback(() => setOverlayVisibility(false), []);

    const previewOverlay = useCallback((shouldKeepOverlayOpen) => {
        setOverlayVisibility(true);
        if (!shouldKeepOverlayOpen) setTimeout(() => setOverlayVisibility(false), 2000);
    }, []);

    const { orderNumber, orderToken } = getOrderFromCookies(storeCode);

    if (typeof orderNumber === 'string' && typeof orderToken === 'string') {
        axios.defaults.headers['X-Grover-Order-Number'] = orderNumber;
        axios.defaults.headers['X-Grover-Order-Token'] = orderToken;
    }

    const fetchCart = useCallback(() => {
        if (!orderNumber) {
            return null;
        }

        return axios
            .get(`/orders/${orderNumber}`, axiosConfig)
            .then((result) => setCart(result.data.order))
            .catch((error) => {
                if (isNotFoundOrderError(error)) {
                    deleteOrder(storeCode);
                }
            })
            .finally(() => setCartLoaded(true));
    }, [setCart, orderNumber, storeCode, axiosConfig]);

    const resetCart = useCallback(() => {
        setCart({});
        if (orderNumber) {
            deleteOrder(storeCode);
        }
        setUnavailableSkuList([]);
    }, [orderNumber, storeCode]);

    const findExistingItem = useCallback((order, variantId, planId) => {
        if (order && order.items) {
            return order.items.find((i) => {
                if (planId && i.rental_plan_id) {
                    return i.variant.id === variantId && i.rental_plan_id === planId;
                }
                return i.variant.id === variantId;
            });
        }
        return null;
    }, []);

    const addItemToCart = useCallback(
        ({
            variantId,
            quantity,
            anyVariant: useAnyVariant = false,
            planId,
            shouldKeepOverlayOpen = false,
        }) => {
            const item = {
                variant_id: variantId,
                quantity,
                rental_plan_id: planId,
            };

            const createNewOrderFromItem = () => {
                const payload = {
                    any_variant: useAnyVariant,
                    ...item,
                };

                return axios
                    .post('/orders/', payload, axiosConfig)
                    .then((result) => {
                        saveOrder(
                            storeCode,
                            result.data.order.number,
                            result.data.order.guest_token,
                        );
                        setCart(result.data.order);
                        previewOverlay(shouldKeepOverlayOpen);
                        registerAddToCart();
                        criteoCartCheckoutUpdate(user, result.data.order);
                    })
                    .catch((error) => console.warn(error))
                    .finally(() => !cartLoaded && setCartLoaded(true));
            };

            const handleOrderUpdateError = (error) =>
                isNotFoundOrderError(error) && createNewOrderFromItem(item);

            if (orderNumber) {
                const existingItem = findExistingItem(cart, variantId, planId);

                if (existingItem) {
                    const itemPatch = {
                        quantity: existingItem.quantity + quantity,
                    };

                    return axios
                        .patch(
                            `/orders/${orderNumber}/line_items/${existingItem.id}`,
                            itemPatch,
                            axiosConfig,
                        )
                        .then((result) => {
                            setCart(result.data.order);
                            previewOverlay(shouldKeepOverlayOpen);
                            registerAddToCart();
                            criteoCartCheckoutUpdate(user, result.data.order);
                        })
                        .catch(handleOrderUpdateError);
                }

                return axios
                    .post(
                        `/orders/${orderNumber}/line_items`,
                        { ...item, any_variant: useAnyVariant },
                        axiosConfig,
                    )
                    .then((result) => {
                        fetchCart();
                        previewOverlay(shouldKeepOverlayOpen);
                        registerAddToCart();
                        criteoCartCheckoutUpdate(user, result.data.order);
                    })
                    .catch(handleOrderUpdateError);
            }
            return createNewOrderFromItem(item);
        },
        [
            orderNumber,
            axiosConfig,
            storeCode,
            previewOverlay,
            registerAddToCart,
            criteoCartCheckoutUpdate,
            user,
            cartLoaded,
            findExistingItem,
            cart,
            fetchCart,
        ],
    );

    const changeItemQuantity = useCallback(
        (order, itemId, quantity) => {
            axios
                .patch(`/orders/${order}/line_items/${itemId}`, {
                    quantity,
                })
                .then((result) => {
                    setCart(result.data.order);
                    criteoCartCheckoutUpdate(user, result.data.order);
                })
                .catch((error) => console.warn(error));
        },
        [criteoCartCheckoutUpdate, user],
    );

    const removeItem = useCallback(
        (productId) => {
            // we request orderNumber here as well otherwise it was undefined in this function
            // on mobile app since we are injecting cookies from app side when product is added into cart.
            const { orderNumber } = getOrderFromCookies(storeCode);
            const existingItem = isExistingItem(cart, productId);

            if (!orderNumber || !existingItem) {
                return null;
            }

            return axios
                .delete(`/orders/${orderNumber}/line_items/${existingItem.id}`, axiosConfig)
                .then((result = {}) => {
                    if (!result.data || !result.data.order) {
                        resetCart();
                    } else {
                        setCart(result.data.order);
                        criteoCartCheckoutUpdate(user, result.data.order);
                    }

                    return result;
                })
                .catch((error) => console.warn(error));
        },
        [cart, axiosConfig, criteoCartCheckoutUpdate, user, resetCart, storeCode],
    );

    const removeItems = useCallback(
        (ids = []) => {
            if (!ids.length) return Promise.resolve();
            const iterator = (idList) => {
                const id = idList.shift();
                return removeItem(id)
                    .then((result) => {
                        if (idList.length) return iterator(idList, !result);
                        return null;
                    })
                    .catch((e) => {
                        console.error(e);
                        throw new Error('Products were not removed!');
                    });
            };

            return iterator(ids);
        },
        [removeItem],
    );

    const updateItemPlan = useCallback(
        (itemId, planId) => {
            axios
                .patch(`/orders/${orderNumber}/line_items/${itemId}?include_product=true`, {
                    rental_plan_id: planId,
                })
                .then((result) => {
                    setCart(result.data.order);
                    criteoCartCheckoutUpdate(user, result.data.order);
                })
                .catch((error) => console.warn(error));
        },
        [criteoCartCheckoutUpdate, orderNumber, user],
    );

    const updateItemVariant = useCallback(
        (itemId, colorId) => {
            axios
                .patch(`/orders/${orderNumber}/line_items/${itemId}?include_product=true`, {
                    variant_id: colorId,
                })
                .then((result) => {
                    setCart(result.data.order);
                    criteoCartCheckoutUpdate(user, result.data.order);
                })
                .catch((error) => console.warn(error));
        },
        [criteoCartCheckoutUpdate, orderNumber, user],
    );

    const updateBillingAddress = useCallback(
        (addressId) => {
            axios.patch(`/orders/${orderNumber}/address`, { billing_address_id: addressId });
        },
        [orderNumber],
    );

    const updateShippingAddress = useCallback(
        (addressId) => {
            axios.patch(`/orders/${orderNumber}/address`, {
                shipping_address_id: addressId,
            });
        },
        [orderNumber],
    );

    const memoizedValue = useMemo(
        () => ({
            showOverlay,
            hideOverlay,
            fetchCart,
            addItemToCart,
            removeItem,
            changeItemQuantity,
            overlayIsVisible,
            updateItemPlan,
            updateItemVariant,
            cart,
            updateBillingAddress,
            updateShippingAddress,
            resetCart,
            removeItems,
            unavailableSkuList,
            setUnavailableSkuList,
            cartLoaded,
        }),
        [
            addItemToCart,
            cart,
            changeItemQuantity,
            fetchCart,
            hideOverlay,
            overlayIsVisible,
            removeItem,
            showOverlay,
            updateItemPlan,
            updateItemVariant,
            updateBillingAddress,
            updateShippingAddress,
            resetCart,
            removeItems,
            unavailableSkuList,
            setUnavailableSkuList,
            cartLoaded,
        ],
    );

    return <CartContext.Provider value={memoizedValue}>{children}</CartContext.Provider>;
};

Provider.propTypes = {
    children: PropTypes.node.isRequired,
    axiosConfig: PropTypes.shape({}),
    activeStore: PropTypes.shape({
        code: PropTypes.string,
    }).isRequired,
    user: PropTypes.shape({}),
    registerAddToCart: PropTypes.func,
    criteoCartCheckoutUpdate: PropTypes.func,
};

Provider.defaultProps = {
    axiosConfig: {},
    user: {},
    registerAddToCart: () => {},
    criteoCartCheckoutUpdate: () => {},
};

export const MODULE_NAME = 'payment';
export const ADYEN_AUTH_CODES = {
    AUTHORISED: 'AUTHORISED',
    REFUSED: 'REFUSED',
    CANCELLED: 'CANCELLED',
    PENDING: 'PENDING',
    ERROR: 'ERROR',
};
export const DEFAULT_CARD_MASKS = {
    cardNumberMask: '9999 9999 9999 9999',
    CvcMask: '999',
};
export const AMEX_CARD_MASKS = {
    cardNumberMask: '9999 999999 99999',
    CvcMask: '9999',
};
export const CREDIT_CARD_MASK_SYMBOL = '\u2022';

export const PAYMENT_SYSTEM_STATUS = {
    success: ADYEN_AUTH_CODES.AUTHORISED,
    failure: ADYEN_AUTH_CODES.ERROR,
};

export const PAYMENT_CARD_INPUT_IDS = {
    CARD_NUMBER: 'cardNumberField',
    EXPIRY: 'cardExpiryField',
    CVV: 'cvvField',
    CARD_HOLDER: 'cardHolderField',
};

export const PAYMENT_SENSITIVE_FIELDS = [
    PAYMENT_CARD_INPUT_IDS.CARD_NUMBER,
    PAYMENT_CARD_INPUT_IDS.CVV,
];

import { GET_STATIC_INFO, GET_STATIC_INFO_SUCCESS, GET_STATIC_INFO_ERROR } from '../actionTypes';

const defaultState = {
    loading: null,
    error: {},
    data: {
        pdp_common: {},
        user_onboarding: {},
        order_confirmation: {},
        terms_page: {},
    },
};

export default function(state = defaultState, action) {
    switch (action.type) {
        case GET_STATIC_INFO:
            return { ...state, loading: true, error: {} };

        case GET_STATIC_INFO_SUCCESS:
            return { ...state, loading: false, data: { ...state.data, ...action.data } };

        case GET_STATIC_INFO_ERROR:
            return { ...state, loading: false, error: action.error };

        default:
            return state;
    }
}

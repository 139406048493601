import * as actionTypes from '../actionTypes';

export const signUpAction = (payload) => (dispatch) =>
    dispatch({
        type: actionTypes.SIGN_UP,
        data: payload,
    });

export const signInAction = (payload) => (dispatch) =>
    dispatch({
        type: actionTypes.SIGN_IN,
        data: payload,
    });

export const getUserAction = (payload) => (dispatch) =>
    dispatch({
        type: actionTypes.GET_USER,
        data: payload,
    });

export const passwordRecoveryAction = (payload) => (dispatch) =>
    dispatch({
        type: actionTypes.SEND_PASSWORD_RECOVERY,
        data: payload,
    });

export const setTwoFactorAuthenticationAction = (payload) => (dispatch) =>
    dispatch({
        type: actionTypes.SET_TWO_FACTOR_AUTHENTICATION,
        data: payload,
    });

export const clearTwoFactorAuthenticationAction = () => (dispatch) =>
    dispatch({
        type: actionTypes.CLEAR_TWO_FACTOR_AUTHENTICATION,
    });

export const setTwoFactorAuthenticationTimeoutAction = (payload) => (dispatch) =>
    dispatch({
        type: actionTypes.SET_TWO_FACTOR_AUTHENTICATION_TIMEOUT,
        data: payload,
    });

import { compose, branch, renderNothing, withProps } from 'recompose';
import { connect } from 'react-redux';
import ClickOutside from 'react-click-outside';
import { createStructuredSelector } from 'reselect';
import { translate, keys } from 'ggApp/modules/translator/selectors';

import {
    footerStoreList as footerStoreListSelector,
    storeActive as storeActiveSelector,
    isPartnerStore as isPartnerStoreSelector,
    reorderedLanguageList as reorderedLanguageListSelector,
} from 'ggApp/modules/store/selectors';
import {
    routes as routesSelector,
    params as paramsSelector,
    locationQuery as locationQuerySelector,
} from 'ggApp/modules/router/selectors';

import LanguageSelector from './LanguageSelector';

const enhance = compose(
    connect(
        createStructuredSelector({
            storeList: footerStoreListSelector,
            storeActive: storeActiveSelector,
            isPartnerStore: isPartnerStoreSelector,
            reorderedLanguageList: reorderedLanguageListSelector,
            routes: routesSelector,
            params: paramsSelector,
            locationQuery: locationQuerySelector,
            translationKeys: keys,
        }),
    ),
    withProps(({ reorderedLanguageList, translationKeys, storeActive }) => ({
        orderedLanguageList: reorderedLanguageList.map(({ value, label }) => ({
            value,
            label: translate(translationKeys, storeActive.activeLanguage, label),
        })),
    })),
    branch(({ orderedLanguageList }) => orderedLanguageList.length === 0, renderNothing),
    ClickOutside,
);

export default enhance(LanguageSelector);
